import axios from "axios";
export default {

    getAllInvoiceCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("invoice/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},

    getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.get('/invoice/all/'+ (currentPage-1) + "/" + pageCount)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getSearchCount: function(keyword) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/invoice/search/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/invoice/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAllFullPaymentInvoiceCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("invoice/fullPayment/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},

    getAllFullPaymentPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.get('/invoice/fullPayment/all/'+ (currentPage-1) + "/" + pageCount)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getFullPaymentSearchCount: function(keyword) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/invoice/search/fullPayment/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllFullPaymentSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/invoice/search/fullPayment/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAllInvoiceHistory: function(admissionId){
        return new Promise((resolve, reject) => {
            axios.get('/invoice/history/'+ admissionId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getInvoiceDetailsById: function(invoiceId){
        return new Promise((resolve, reject) => {
            axios.get('/invoice/detailsById/'+ invoiceId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },



}