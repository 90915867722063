<template>
<div>
    <div class="container" v-if="order!=null">
        <div>
            <div class="row delivery">
                <div class="col mt-5">
                    <img height="60" :src="logo" style="margin-left: 0;" />
                </div>
                <div class="col-4 mt-5 order-nu" style="text-align:right">
                    <span class="">order number#</span> <span class=""> {{ order.orderNumber }} </span>
                </div>
                <div class="col-12 mt-5">
                    <h1 class="a"> {{ order.type.name }} full pack</h1>
                </div>
            </div>
            <div class="row delivery">

                <div class="col-1 mt-5 ">
                </div>
            </div>
        </div>
        <div>
            <div class="row user_to">
                <div class="col-12 mt-2" style="font-size: 1rem;">
                    <div style="font-weight: bold;">
                        <span> From :</span>
                    </div>
                    <div>
                        {{organization}},
                    </div>
                    <div>
                        <span class="user_to">48-1-26, Annai Therasa Street,</span>
                    </div>
                    <div>
                        <span class="user_to">Bethaniapuram, Madurai,</span>
                    </div>
                    <div>
                        <span class="user_to">Tamil Nadu 625016.</span>
                    </div>
                    <div>
                       PHONE : <span class="user_to"> 7550008885</span>
                    </div>
                </div>
                <div class="col mt-2" style="font-size: 1rem;">
                    <div style="font-weight: bold;">
                        <span> To :</span>
                    </div>
                    <div>
                        <span class="user_to">{{ order.name }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ order.doorNo }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ order.landmark }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ order.district }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ order.state }} - {{ order.pincode }}.</span>
                    </div>
                    <div>
                       PHONE : <span class="user_to"> {{ order.contact }}<span v-if="order.contact != order.whatsapp">, {{ order.whatsapp }}</span></span>
                    </div>
                </div>

            </div>
        </div><br /><br />
        <div style="color:grey;position: absolute;bottom:10px;left: 25%;">

            Thank you for your business
        </div>
    </div>
</div>
</template>

<script>
import TeamService from "../service/TeamService";
import BookBundleService from '../service/BookBundleService';
import Account from '../service/Account.js';
import axios from 'axios';
export default {
    name: "",
    data() {
        return {
            order: null,
            organization:"",
            logo:""
        }
    },
    beforeDestroy() {
        axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
    },
    mounted() {
        console.log(this.$route.params.orderId);
        window.onafterprint = function(){
            console.log("Printing completed...");
            window.history.back();
        }
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    this.getOrder();

                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });

    },
    methods: {
        getOrganization(teamId) {
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(teamId)
                    .then((response) => {
                        this.organization=response.data.organization;
                        if(this.organization=="examsdaily")
                            this.logo="https://push-imgcdn.win/a0f3cf29-b1b9-4b4b-98da-7e139be8424c.png";
                            else if(this.organization=="edextech") {
                                this.logo="https://push-imgcdn.win/dade8093-6d06-41f4-aeb1-b246fcc729a2.png";
                            }
                            else if(this.organization=="dexter_academy") {
                                this.logo="https://push-imgcdn.win/68ed3f72-f732-4ae6-ad09-7fa1b26ab0e1.png";
                            }
                        setTimeout(() => {

                            window.print();
                        }, 200);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getOrder() {
            axios.defaults.headers.common["tenant-uuid"] = ""
            return new Promise((resolve, reject) => {
                BookBundleService.getOrder(this.$route.params.orderId)
                    .then((response) => {
                        this.order = response.data;
                        this.getOrganization(response.data.teamId);
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
    },
}
</script>

<style scoped>
@media all {
    .bottom {
        background-color: #e6e6e6;
    }

    h1.a {
        letter-spacing: 3px;
    }

    .triangle {
        position: relative;
        float: left;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .order-nu {
        box-shadow: inset 0 0 0 1000px #000000;
        border-radius: o 20px 0 20px;
        background-color: #0183ff;
        color: white;
        font-weight: bold;
        text-align: start;
        font-size: 17px;
    }

    .order-nu-1 {
        position: relative;
        top: 10px;
    }

    .triangle-right {
        border-width: 25px 0 25px 45px;
        border-color: transparent transparent transparent #0183ff;
    }

    .delivery {
        background-color: #e6e6e6;
        box-shadow: inset 0 0 0 1000px #e6e6e6;
    }

    .container {
        border: 2px solid black;
        width: 100%;
        height: 100vh;
    }

    .user_to {
        text-align: left;
        font-size: 110%;
        /* margin-left: 60px; */
    }

}
</style>
