<template>
  <div class="mt-5">
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- for loader end -->
    <b-container>
      <b-container>
        <b-row align-v="center">
          <!--add Source button start-->
          <b-col col lg="1" md="1" id="r1" sm="6">
            <b-button
              size="sm"
              variant="outline-primary"
              class="bnclr"
              v-b-modal.addsource
              @click="resetForm()"
              >AddSource</b-button
            >
            <br />
          </b-col>
          <b-col col sm="12" md="3" lg="3" id="r1">
            <input
              type="search"
              class="searchbar"
              v-model="keyword"
              placeholder="Type a name"
              v-on:keyup.enter="getSearchCount(keyword)"
            />
          </b-col>
          <b-col col lg="1" md="1" id="r1" sm="6">
            <b-button
              size="sm"
              variant="outline-primary"
              class="bnclr"
              @click="getSearchCount(keyword)"
              >Search</b-button
            >
          </b-col>
          <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageCount"
              align="fill"
              prev-text="Prev"
              next-text="Next"
              size="sm"
              class="my-0 mx-2"
              @input="switchPage(currentPage, pageCount)"
            ></b-pagination>
          </b-col>
          <b-col sm="6" md="3" lg="2" class="" id="r1">
            <label class="font mx-1">Per Page</label>
            <b-form-select
              class="br"
              id="per-page-select"
              v-model="pageCount"
              @input="switchPage(currentPage, pageCount)"
              :options="pageOptions"
              size="sm"
            >
            </b-form-select>
          </b-col>
          <b-col col lg="1" md="1" id="r1" sm="6">
            <span style="color: #0974ff; cursor: pointer" @click="refresh()"
              ><font-awesome-icon icon="sync-alt" />Refresh</span
            >
          </b-col>
        </b-row>
      </b-container>
      <!--Search Ends for source -->

      <!--Table starts for source -->

      <b-container>
        <!-- <div>
          <div v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else></div>
        </div> -->
        <b-card class="card mt-1">
          <p>Source</p>
          <hr />
          <b-table
            hover
            responsive
            :items="sources"
            :fields="fields"
            :current-page="currentPage"
            :per-page="sources.pageCount"
          >
            <template v-slot:cell(name)="s">
              {{ s.item.name }}
            </template>
            <template v-slot:cell(actions)="s">
              <b-button
              size="sm" class="mx-1"
                variant="primary"
                @click="getSource(s.item)"
                v-b-modal.viewSource
                v-b-tooltip.hover.bottomright="{ variant: 'primary' }"
                title="View Source Details"
              ><font-awesome-icon icon="eye" /></b-button>
              <!-- <b-button
                class="fa fa-trash px-3"
                variant="danger"
                @click="deleteSource(s.item.sourceId)"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Source Details"
              ></b-button> -->
              <b-button
              size="sm" class="mx-1"
                variant="info"
                @click="getSource(s.item)"
                v-b-modal.updateSource
                v-b-tooltip.hover.bottomright="{ variant: 'info' }"
                title="Update Source Details"
              ><font-awesome-icon icon="edit" /></b-button>
              <b-button
              size="sm" class="mx-1"
                v-if="s.item.archive == false"
                variant="success"
                @click="archive(s.item.sourceId)"
                v-b-tooltip.hover.bottomright="{ variant: 'success' }"
                title="Archive Source Details"
              ><font-awesome-icon icon="archive" /></b-button>
              <b-button
              size="sm" class="mx-1"
                v-if="s.item.archive == true"
                variant="warning"
                @click="unarchive(s.item.sourceId)"
                v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                title="Un Archive Source Details"
              ><font-awesome-icon icon="archive" /></b-button>
            </template>
          </b-table>
        </b-card>
      </b-container>

      <!--Table Ends for source -->

      <!--popup start for Source Register-->
      <b-container>
        <b-modal id="addsource" title="Add Source" ref="add" hide-footer>
          <div class="form-row">
            <div class="form-group">
              <label>Source Name:</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model.trim="$v.source.name.$model"
                :class="{
                  'is-invalid': $v.source.name.$error,
                  'is-valid': !$v.source.name.$invalid,
                }"
                placeholder="Enter Source Name"
              >
              </b-form-input>
              <div class="valid-feedback">Source Name is valid!</div>
              <div class="invalid-feedback">
                <span v-if="!$v.source.name.required">Source is required!</span>
              </div>
            </div>
            <br />

            <div class="form-group">
              <label>Code:</label>
              <b-form-input
                type="text"
                class="form-control"
                v-model.trim="$v.source.code.$model"
                :class="{
                  'is-invalid': $v.source.code.$error,
                  'is-valid': !$v.source.code.$invalid,
                }"
                placeholder="Enter your code"
              ></b-form-input>

              <div class="valid-feedback">Your code is valid!</div>
              <div class="invalid-feedback">
                <span v-if="!$v.source.code.required">Code is required!</span>
              </div>
            </div>
            <br />
            <!-- <div class="form-group">
              <b-form-input
                format="DD-MM-YYYY h:i:s"
                v-model="source.datetime"
                v-model.trim="$v.source.datetime.$model"
                :class="{
                  'is-invalid': $v.source.datetime.$error,
                  'is-valid': !$v.source.datetime.$invalid,
                }"
                type="datetime-local"
                min="new Time()"
              ></b-form-input>
              <div class="valid-feedback">Date Time is valid!</div>
              <div class="invalid-feedback">Date Time is invalid!
                <span v-if="!$v.source.datetime.required">Date Time is required</span>
              </div>
            </div>
            <br /> -->
            <div class="text-center">
              <b-button
                variant="outline-success"
                type="submit"
                @click="disabledButton = true,putSource()"
                :disabled="disabledButton"
                >Submit</b-button
              >
              <b-button
                variant="outline-danger"
                class="mx-3"
                @click="resetForm()"
                >Reset</b-button
              >
            </div>
          </div>
        </b-modal>
      </b-container>
    </b-container>
    <br /><br />
    <!--popup end for source Register-->

    <!--popup start for Source Update-->
    <b-container>
      <b-modal id="updateSource" title="Update Source" ref="update" hide-footer>
        <div class="form-row">
          <div class="form-group">
            <label>Source Name:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model.trim="$v.source.name.$model"
              :class="{
                'is-invalid': $v.source.name.$error,
                'is-valid': !$v.source.name.$invalid,
              }"
              placeholder="Enter Source Name"
            >
            </b-form-input>
            <div class="valid-feedback">Source Name is valid!</div>
            <div class="invalid-feedback">
              <span v-if="!$v.source.name.required">Source is required!</span>
            </div>
          </div>
          <br />

          <div class="form-group">
            <label>Code:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model.trim="$v.source.code.$model"
              :class="{
                'is-invalid': $v.source.code.$error,
                'is-valid': !$v.source.code.$invalid,
              }"
              placeholder="Enter your code"
            ></b-form-input>

            <div class="valid-feedback">Your code is valid!</div>
            <div class="invalid-feedback">
              <span v-if="!$v.source.code.required">Code is required!</span>
            </div>
          </div>
          <br />

          <div class="text-center">
            <b-button
              variant="outline-success"
              type="submit"
              @click="disabledButton = true,updateSource()"
              :disabled="disabledButton"
              >Update</b-button
            >
          </div>
        </div>
      </b-modal>
    </b-container>
    <!-- popup end for source Update-->

    <!--popup start for View Source details-->
    <div>
      <b-container>
        <div>
          <b-modal id="viewSource" title="View Source" hide-footer>
            <div class="control">
              <b-table-simple responsive>
                <b-tr>
                  <b-th>Name:</b-th>
                  <b-td>{{ source.name }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th>Code:</b-th>
                  <b-td>{{ source.code }}</b-td>
                </b-tr>
              </b-table-simple>
            </div>
          </b-modal>
        </div>
      </b-container>
      <!--popup end for View Source details-->
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  // maxLength,
  // email,
} from "vuelidate/lib/validators";
// import datetime from "vuejs-datetimepicker";
import SourceService from "../service/SourceService";
import Account from '../service/Account.js';
export default {
  name: "Source",
  components: {
    // datetime,
  },
  data() {
    return {
      disabledButton: false,
      paginationCSources: false,
      searchPaginationSources: false,
      rows: 100,
      currentPage: 1,
      pageCount: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show all" }],
      totalCount: 0,
      keyword: "",
      loading: "false",

      source: {
        name: "",
        code: "",
        // datetime: "",
      },
      sources: [],
      fields: [
        { key: "name", label: "Name" },
        { key: "code", label: "Code", class: "text-center" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  validations: {
    source: {
      name: {
        required,
        minLength: minLength(3),
      },

      code: {
        required,
        minLength: minLength(5),
      },
      // datetime: {
      //   required,
      //   minValue: (value) => {
      //     let selectedDT = new Date(value)
      //     let currentDT = new Date()
      //       if(selectedDT > currentDT){
      //         return true
      //       }else{
      //         return false
      //       }
      //   }
      // },
    },
  },
  watch: {
    keyword(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.getCount();
      }
    },
  },
  mounted() {
    let self = this;
    return new Promise((resolve, reject) => {
      Account.getAccessToken(localStorage.getItem("refreshToken"))
        .then((response) => {
          self.getAllSources();
          self.getCount();
          // self.getAllPageable();
          this.loading = true;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  methods: {

    putSource: function () {
      this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitstatus = "FAIL";
      // } else {
        if (!this.$v.$invalid) {
        this.disabledButton = true;
        this.submitstatus = "SUCCESS";
        return new Promise((resolve, reject) => {
          console.log(this.source.date)
          SourceService.putSource(this.source)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Added",
                text: "Source Added Successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$refs.add.hide();
              // this.getAllSources();
              // this.getAllPageable();
              this.getCount();
              this.disabledButton = false;
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
      else {
        this.submitstatus = "FAIL";
        this.disabledButton = false;
      }
    },
    // searchSource: function (keyword) {
    //   return new Promise((resolve, reject) => {
    //     SourceService.searchSource(keyword.toLowerCase())
    //       .then((response) => {
    //         this.sources = response.data;
    //         this.currentPage = 1;
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         reject(err);
    //       });
    //   });
    // },
        getSearchCount: function (keyword) {
      this.paginationSources = false;
      this.searchPaginationSources = true;
      this.currentPage = 1;
      return new Promise((resolve, reject) => {
        SourceService.getSearchCount(keyword.toLowerCase())
          .then((response) => {
            this.totalCount = response.data;
            console.log("Count", response.data);
            this.getAllSearchPageable(
              this.currentPage,
              this.pageCount,
              keyword.toLowerCase()
            );
            this.loading = false;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllSearchPageable: function (currentPage, pageCount, keyword) {
      return new Promise((resolve, reject) => {
        SourceService.getAllSearchPageable(
          currentPage,
          pageCount,
          //   this.agentId,
          keyword
        )
          .then((response) => {
            this.sources = response.data;
            this.loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },
    getAllSources: function () {
      return new Promise((resolve, reject) => {
        this.loading = true;
        SourceService.getAllAdminSources()
          .then((response) => {
            this.sources = response.data;
            this.loading = false;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getSource: function (source) {
      console.log("Source : ", source);
      this.source = source;
    },
    updateSource: function () {
      this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitstatus = "FAIL";
      // } else {
        if (!this.$v.$invalid) {
        this.disabledButton = true;
        this.submitstatus = "SUCCESS";
        return new Promise((resolve, reject) => {
          SourceService.updateSource(this.source)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Updated",
                text: "Source Updated Successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$refs.update.hide();
              // this.getAllSources();
              // this.getAllPageable();
              this.getCount();
              this.disabledButton = false;
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
      if (this.$v.$invalid) {
        this.submitstatus = "FAIL";
        this.disabledButton = false;
      }
    },

    deleteSource: function (sourceId) {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: 'warning',
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085D6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
          .then((result) => {
            if (result.value) {
              SourceService.deleteSource(sourceId).then((response) => {
                this.$swal("Deleted!", "Source has been deleted.", "success");
                this.getAllSources();
                this.getCount();
                this.getAllPageable();
                resolve(response);
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    archive: function (sourceId) {
      return new Promise((resolve, reject) => {
        SourceService.archive(sourceId)
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "Archive successfully!!!!!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$v.$reset();
            this.sources.push(response.data);
            this.getCount();
            console.log(response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    unarchive: function (sourceId) {
      return new Promise((resolve, reject) => {
        SourceService.unarchive(sourceId)
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "UnArchive successfully!!!!!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$v.$reset();
            this.sources.push(response.data);
            this.getCount();
            console.log(response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getCount: function () {
      this.loading = true;
      this.paginationSources = true;
      this.searchPaginationSources = false;
      this.currentPage = 1;
      this.keyword = "";
      return new Promise((resolve, reject) => {
        SourceService.getCount()
          .then((response) => {
            this.totalCount = response.data;
            this.getAllPageable(this.currentPage, this.pageCount);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllPageable: function () {
      return new Promise((resolve, reject) => {
        SourceService.getAllPageable(this.currentPage, this.pageCount)
          .then((response) => {
            this.sources = response.data;
            resolve(response);
            this.loading = false
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    refresh: function () {
      this.getCount();
      this.keyword = "";
    },
    switchPage: function (currentPage,pageCount) {
      // this.loading = true
      // this.getAllPageable(pageCount);
         this.loading = true;
      // console.log("switchPage calling");
      if (this.paginationSources) {
        this.getAllPageable(currentPage, pageCount);
      } else if (this.searchPaginationSources) {
        this.getAllSearchPageable(currentPage, pageCount, this.keyword);
      }
    },
    resetForm() {
      this.source = {
        name: null,
        code: null,
        // datetime: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
<style scoped>
.card {
  border: 3px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}
.bnclr {
  border: 1px solid #09a5ff;
  border-radius: 5px;
}
.searchbar {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09a5ff;
  margin: 1% 1%;
  padding: 2% 5%;
  background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 16px;
  padding-left: 2rem;
}
.pagination {
  display: flex;
  padding-left: 0;
  /* padding-bottom: 0px; */
  margin-bottom: 0px;
  list-style: none;
}
.font {
  font-size: 14px;
}
#per-page-select {
  border: 1px solid #09a5ff;
}
input {
  font-size: small;
}
.br {
  border: 1px solid #09a5ff;
  border-radius: 5px;
  padding: 3% 5%;
}
.row {
  --bs-gutter-x: 0rem;
}
select {
  background-color: #fff;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.px-3{
  margin-left: 2px;
  margin-bottom: 2px;
  height: 33px;
}
</style>
