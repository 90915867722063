<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- for loader end -->
    <h1>Office Details</h1>
    <b-container>
        <b-tabs pills card content-class="mt-3" justified v-model="activeTabs">
            <b-tab title="OFFICE USERS">
                <b-container>
                    <div>
                        <!-- <b-container>
                            <b-row align-v="center">
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Add User</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCallSearchCount(keyword)">Search</b-button>
                                </b-col>

                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </b-container> -->
                        <b-container>
                            <b-card class="card mt-1">
                                <b-row align-v="center">
                                    <b-col col sm="12" md="3" lg="3" id="r1">
                                        <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getUserSearchCount(keyword)" />
                                    </b-col>
                                    <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getUserSearchCount(keyword)">Search</b-button>
                                    </b-col>
                                    <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchPage(currentPage, pageCount)
                      "></b-pagination>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="3" class="" id="r1">
                                        <label class="font mx-1">Per Page</label>
                                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="
                        switchPage(currentPage, pageCount)
                      " :options="pageOptions" size="sm">
                                        </b-form-select>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="mx-2">Total Count:</label>
                                        <b-button variant="danger">{{
                      totalCount
                    }}</b-button>
                                    </b-col>
                                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportOfficeUserExcellSheet()">Download</b-button>
                                    </b-col> -->
                                </b-row>
                                <hr />
                                <b-table hover responsive :items="officeUsers" :fields="fields" :per-page="officeUsers.pageCount" :current-page="currentPage" ref="table">
                                    <template v-slot:cell(firstName)="data">
                                        {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" @click="viewEmployeeAttendance(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update" @click="employeeUpdateModalShow(data.item)">
                                            <font-awesome-icon icon="edit" />
                                        </b-button>
                                        <b-button v-if="data.item.enabled == true" size="sm" class="mx-1" variant="danger" v-b-tooltip.hover.bottomright="{ variant: 'danger' }" title="Deactivate" @click="disableEmployee(data.item)">
                                            <font-awesome-icon icon="times" />
                                        </b-button>
                                        <b-button v-if="data.item.enabled == false" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Activate" @click="enableEmployee(data.item)">
                                            <font-awesome-icon icon="check" />
                                        </b-button>
                                        <b-btn class="btn-sm activate-btn shadow irresponsive_action" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Send Password" variant="success" @click="getUserDetails(data.item)">
                                            <font-awesome-icon icon="envelope" />
                                        </b-btn>
                                        <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Office Migration" v-b-modal.officeMigrationModal @click="getTeamAndOffice(data.item)">
                                            <font-awesome-icon icon="window-close" />
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-container>
                    </div>

                    <!-- Popup Modal For Office Migration -->

                    <!-- <b-modal title="Migrate Office" hide-footer id="officeMigrationModal" ref="officeMigrationModal" no-close-on-backdrop>
                        <b-row align-v="center">
                            <b-col col lg="6" md="4" id="r1" sm="4" class="sort">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedTeam" size="sm" @change="getAllOffice(selectedTeam)">
                                    <option v-for="item in allTeams" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="6" md="4" id="r1" sm="4" class="sort">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedToOffice" size="sm">
                                    <option v-for="item in allOffice" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.officeName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </b-modal> -->

                    <b-modal title="Office Migration" id="officeMigrationModal" ref="officeMigrationModal" no-close-on-backdrop hide-footer>
                        <div class="form-row mt-3">
                            <div class="form-group my-2 px-5">
                                <label>Beneficiary Team:</label>
                                <b-form-select class="form-control my-1" v-model="selectedTeam" @change="getAllOffice(selectedTeam)">
                                    <option value="null" disabled>Select Beneficiary Team</option>
                                    <option v-for="item in allTeams" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                            <div class="form-group my-2 px-5">
                                <label>Beneficiary Office:</label>
                                <b-form-select class="form-control my-1" v-model="selectedToOffice">
                                    <option value="null" disabled>Select Beneficiary Office</option>
                                    <option v-for="item in allOffice" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.officeName }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                            <div class="text-center py-2">
                                <b-button variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,officeMigrate()">Migrate</b-button>
                                <b-button variant="outline-danger" class="mx-3" @click="resetMigration()">Cancel</b-button>
                            </div>
                        </div>
                    </b-modal>

                    <!-- Popup Modal For Office Migration -->

                    <!-- Popup modal for update Employee details START -->
                    <b-modal title="Employee Update " hide-footer ref="employeeUpdateModal" no-close-on-backdrop>
                        <div class="form-row">
                            <div class="form-group">
                                <label>First Name:</label>
                                <b-form-input disabled type="text" class="form-control" v-model.trim="$v.updateUser.firstName.$model" :class="{'is-invalid': $v.updateUser.firstName.$error,'is-valid': !$v.updateUser.firstName.$invalid,}" placeholder="Enter your First Name">
                                </b-form-input>
                                <div class="valid-feedback">Your First Name is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.firstName.required">First Name is required</span>
                                </div>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Last Name:</label>
                                <b-form-input disabled type="text" class="form-control" v-model.trim="updateUser.lastName" placeholder="Enter your Last Name">
                                </b-form-input>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>CheckinTime:</label>
                                <b-form-input type="text" class="form-control" v-model.trim="$v.updateUser.checkinTime.$model" :class="{'is-invalid': $v.updateUser.checkinTime.$error,'is-valid': !$v.updateUser.checkinTime.$invalid,}" placeholder="Enter your Checkin Time">
                                </b-form-input>
                                <div class="valid-feedback">Your Checkin Time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.checkinTime.required">Checkin Time is required</span>
                                </div>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>CheckoutTime:</label>
                                <b-form-input type="text" class="form-control" v-model.trim="$v.updateUser.checkoutTime.$model" :class="{'is-invalid': $v.updateUser.checkoutTime.$error,'is-valid': !$v.updateUser.checkinTime.$invalid,}" placeholder="Enter your Checkout Time">
                                </b-form-input>
                                <div class="valid-feedback">Your Checkout Time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.checkoutTime.required">Checkout Time is required</span>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div class="form-group mt-3">
                                <div class="text-center">
                                    <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,userDetailUpdate()">Update</b-button>
                                    <b-button variant="outline-danger" class="mx-3" @click="$refs.userUpdateModal.hide()">Cancel</b-button>
                                </div>
                            </div>
                        </div>
                    </b-modal>
                    <!-- Popup modal for update Employee details END-->

                </b-container>

            </b-tab>

            <b-tab title="DISABLED OFFICE USERS">
                <b-container>
                    <div>
                        <b-container>
                            <b-card class="card mt-1">
                                <b-row align-v="center">
                                    <b-col col sm="12" md="3" lg="3" id="r1">
                                        <input type="search" class="searchbar" v-model="disabledKeyword" placeholder="Type a name" v-on:keyup.enter="getDisabledUserSearchCount(disabledKeyword)" />
                                    </b-col>
                                    <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getDisabledUserSearchCount(disabledKeyword)">Search</b-button>
                                    </b-col>
                                    <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                                        <b-pagination v-model="disabledCurrentPage" :total-rows="totalDisabledCount" :per-page="disabledPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        disabledSwitchPage(disabledCurrentPage, disabledPageCount)
                      "></b-pagination>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="3" class="" id="r1">
                                        <label class="font mx-1">Per Page</label>
                                        <b-form-select class="br" id="per-page-select" v-model="disabledPageCount" @input="
                        disabledSwitchPage(disabledCurrentPage, disabledPageCount)
                      " :options="pageOptions" size="sm">
                                        </b-form-select>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="mx-2">Total Count:</label>
                                        <b-button variant="danger">{{
                      totalDisabledCount
                    }}</b-button>
                                    </b-col>
                                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportOfficeUserExcellSheet()">Download</b-button>
                                    </b-col> -->
                                </b-row>
                                <hr />
                                <b-table hover responsive :items="officeDisabledUsers" :fields="fields" :per-page="officeDisabledUsers.disabledPageCount" :current-page="disabledCurrentPage" ref="table">
                                    <template v-slot:cell(firstName)="data">
                                        {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" @click="viewEmployeeAttendance(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update" @click="employeeUpdateModalShow(data.item)">
                                            <font-awesome-icon icon="edit" />
                                        </b-button> -->
                                        <b-button v-if="data.item.enabled == true" size="sm" class="mx-1" variant="danger" v-b-tooltip.hover.bottomright="{ variant: 'danger' }" title="Deactivate" @click="disableEmployee(data.item)">
                                            <font-awesome-icon icon="times" />
                                        </b-button>
                                        <b-button v-if="data.item.enabled == false" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Activate" @click="enableEmployee(data.item)">
                                            <font-awesome-icon icon="check" />
                                        </b-button>
                                        <!-- <b-btn class="btn-sm activate-btn shadow irresponsive_action" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Send Password" variant="success" @click="getUserDetails(data.item)">
                                            <font-awesome-icon icon="envelope" />
                                        </b-btn> -->
                                        <!-- <b-button size="sm" class="mx-1" variant="secondary" @click="notintrestedClosed(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Notintrested">
                                            <font-awesome-icon icon="window-close" />
                                        </b-button> -->
                                    </template>
                                </b-table>
                            </b-card>
                        </b-container>
                    </div>

                    <!-- Popup modal for update Employee details START -->
                    <b-modal title="Employee Update " hide-footer ref="employeeUpdateModal" no-close-on-backdrop>
                        <div class="form-row">
                            <div class="form-group">
                                <label>First Name:</label>
                                <b-form-input disabled type="text" class="form-control" v-model.trim="$v.updateUser.firstName.$model" :class="{'is-invalid': $v.updateUser.firstName.$error,'is-valid': !$v.updateUser.firstName.$invalid,}" placeholder="Enter your First Name">
                                </b-form-input>
                                <div class="valid-feedback">Your First Name is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.firstName.required">First Name is required</span>
                                </div>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Last Name:</label>
                                <b-form-input disabled type="text" class="form-control" v-model.trim="updateUser.lastName" placeholder="Enter your Last Name">
                                </b-form-input>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>CheckinTime:</label>
                                <b-form-input type="text" class="form-control" v-model.trim="$v.updateUser.checkinTime.$model" :class="{'is-invalid': $v.updateUser.checkinTime.$error,'is-valid': !$v.updateUser.checkinTime.$invalid,}" placeholder="Enter your Checkin Time">
                                </b-form-input>
                                <div class="valid-feedback">Your Checkin Time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.checkinTime.required">Checkin Time is required</span>
                                </div>
                            </div>
                            <br />
                            <div class="form-group">
                                <label>CheckoutTime:</label>
                                <b-form-input type="text" class="form-control" v-model.trim="$v.updateUser.checkoutTime.$model" :class="{'is-invalid': $v.updateUser.checkoutTime.$error,'is-valid': !$v.updateUser.checkinTime.$invalid,}" placeholder="Enter your Checkout Time">
                                </b-form-input>
                                <div class="valid-feedback">Your Checkout Time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.updateUser.checkoutTime.required">Checkout Time is required</span>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div class="form-group mt-3">
                                <div class="text-center">
                                    <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,userDetailUpdate()">Update</b-button>
                                    <b-button variant="outline-danger" class="mx-3" @click="$refs.userUpdateModal.hide()">Cancel</b-button>
                                </div>
                            </div>
                        </div>
                    </b-modal>
                    <!-- Popup modal for update Employee details END-->

                </b-container>

            </b-tab>

            <b-tab title="QR CODE">
                <div>
                    <!-- <b-input-group size="md" class="mb-3" prepend="QR CODE DATA"> -->
                    <!-- <b-form-input v-model="qrCodeData"></b-form-input> -->
                    <b-input-group-append>
                        <b-button size="md" text="Button" variant="success" @click="generateQRCode()">Generate QR Code</b-button>
                    </b-input-group-append>
                    <!-- </b-input-group> -->
                </div>
                <div v-if="qrCodeImage">
                    <img :src="qrCodeImage" alt="QR Code" />
                    <br>
                    <b-button size="md" variant="success" @click="downloadQRCode()">Download QR Code</b-button>
                </div>
            </b-tab>
            <b-tab title="USER ATTENDANCE">
                <b-container>
                    <div>
                        <b-container>
                            <!-- <b-card class="card mt-1"> -->
                            <b-row align-v="center">
                                <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                                    <!-- <b-pagination v-model="currentPage" :total-rows="totalAttendanceCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchAttendancePage(currentPage, pageCount)
                      "></b-pagination> -->
                                </b-col>
                                <!-- <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="
                        switchAttendancePage(currentPage, pageCount)
                      " :options="pageOptions" size="sm">
                                        </b-form-select>
                                    </b-col> -->
                                <!-- <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="mx-2">Total Count:</label>
                                        <b-button variant="danger">{{
                      totalAttendanceCount
                    }}</b-button>
                                    </b-col> -->
                                <b-col sm="6" md="3" lg="2" id="r1" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedOffice" size="sm" @change="
                          getAllSortCount(
                            selectedOffice,
                            selectedDates
                          )
                        ">
                                        <b-form-select-option disabled value="Office">
                                            <p>Offices</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllOffices">
                                            <p>All Offices</p>
                                        </b-form-select-option>
                                        <option v-for="item in offices" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.officeName }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">

                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" size="sm" @change="
                          getAllSortCount(
                            selectedOffice,
                            selectedDates
                          )
                        " :options="dateSelect">
                                    </b-form-select>

                                    <!-- <b-form-datepicker v-model="selectedDate" class="mb-2" @input="getAttendanceCountByDate()"></b-form-datepicker> -->
                                    <!-- <b-button variant="primary" v-b-modal.dateRange @click="getDateRangeReset()"> Data By Date</b-button> -->
                                    <!-- <p>Value: '{{ attendanceDate(selectedDate, "fullDate") }}'</p> -->
                                    <!-- <p>{{ selectedDate }}</p> -->
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <!-- <b-button v-if="hideData!=true" variant="success" @click="showFullDetails()">ShowDetails</b-button>
                                        <b-button v-if="hideData==true" variant="danger" @click="hideFullDetails()">HideDetails</b-button> -->
                                    <!-- <b-button variant="primary" @click="exportDetails(selectedOffice,selectedDates)">Export Data</b-button> -->
                                    <b-button variant="primary" @click="exportExcelSheet()">Export Data</b-button>
                                </b-col>

                                <!-- <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <b-button variant="primary" @click="exportDetails(selectedDates)">Export Data</b-button>
                                    </b-col> -->
                                <!-- <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <b-button variant="primary" @click="exportDetails()">Export Data</b-button>
                                    </b-col> -->
                            </b-row>
                            <hr />
                            <div>
                                <b-container class="scroll-table-container">
                                    <table id="tableContent" border="1" cellpadding="1" cellspacing="1" style="display:none">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>Branch</td>
                                                <td v-for="(date, index) in dates" :key="index" colspan="2">{{ date }}</td>
                                            </tr>
                                            <!-- <tr>
                                                    <td colspan="2"></td>
                                                    <template v-for="(date, index) in dates">
                                                        <td :key="index">CheckIn</td>
                                                        <td :key="index">CheckOut</td>
                                                    </template>
                                                </tr> -->
                                            <tr v-for="(e, key) in employeeAttendance" :key="key">
                                                <td rowspan="2">{{ key }}</td>
                                                <td rowspan="2" v-if="e[0] != null">{{ e[0].officeid }}</td>
                                                <template v-for="(c,index) in e">
                                                    <td v-if="c.isCheckedIn" :key="key+'c-' + index + generateRandomNumber()">
                                                        <span v-if="c.checkIn !== null">{{ secondsRemove(c.checkIn) }}</span>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td v-else :key="key+'c-' + index + generateRandomNumber()">
                                                        <span>N/A</span>
                                                    </td>
                                                    <td v-if="c.isCheckedOut" :key="key+'c-' + index + generateRandomNumber()">
                                                        <span v-if="c.checkOut !== null">{{ secondsRemove(c.checkOut) }}</span>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td v-else :key="key+'c-' + index + generateRandomNumber()">N/A</td>
                                                </template>
                                                <template>
                                            <tr>
                                                <td v-for="(c,index) in e" :key="key+'c-' + index + generateRandomNumber()" colspan="2">{{c.status}}</td>
                                            </tr>
                                            </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- ======================================== -->
                                    <table>
                                        <tr>
                                            <th>Username</th>
                                            <th>Branch</th>
                                            <th v-for="(date, index) in dates" :key="index">
                                                {{ date }}
                                            </th>
                                        </tr>
                                        <tr v-for="(e,key) in employeeAttendance" :key="key">
                                            <th>
                                                {{ key }}
                                            </th>
                                            <th v-if="e[0] != null">
                                                {{ e[0].officeid }}
                                            </th>
                                            <th v-for="(c) in e" :key="c.createdAt">
                                        <tr>
                                            <th>
                                                <b-badge v-if="c.checkIn != null" style="background-color:#28A745;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkIn) }}
                                                </b-badge>
                                                <b-badge v-else style="background-color:#28A745;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                </b-badge>
                                            </th>
                                            <!-- <th>
                                                        </th> -->
                                            <th>
                                                <b-badge v-if="c.checkOut != null" style="background-color:#DC3545;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkOut) }}
                                                </b-badge>
                                                <b-badge v-else style="background-color:#DC3545;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                </b-badge>
                                            </th>
                                            <!-- <th>
                                                        </th> -->
                                        </tr>
                                        <tr>
                                            <th colspan="2">
                                                {{ c.status }}
                                            </th>
                                        </tr>
                                        </th>
                                        </tr>
                                    </table>
                                    <!-- <table>
                                            <tr>
                                                <th>Date</th>
                                                <th v-for="(e ,key) in  employeeAttendance" :key="e">
                                                    <span>{{ key }}</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <tr style="" v-for="(date, index) in dates" :key="index">
                                                        {{ date }}
                                                    </tr>
                                                </th>
                                                <th v-for="(e) in  employeeAttendance" :key="e">
                                                    <tr v-for="(c) in e" :key="c">
                                                        <span v-if="c.checkIn != null">
                                                            <b-badge style="background-color:#28A745;color:#fff;width: 40%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkIn) }}
                                                            </b-badge>
                                                        </span>
                                                        <span v-else>
                                                            <b-badge style="background-color:#28A745;color:#fff;width: 40%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                            </b-badge>
                                                        </span>
                                                        <span v-if="c.checkOut != null">
                                                            <b-badge style="background-color:#DC3545;color:#fff;width: 40%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkOut) }}
                                                            </b-badge>
                                                        </span>
                                                        <span v-else>
                                                            <b-badge style="background-color:#DC3545;color:#fff;width: 40%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                            </b-badge>
                                                        </span>
                                                    </tr>
                                                </th>
                                            </tr>
                                        </table> -->
                                </b-container>
                            </div>
                            <!-- <b-table hover responsive :items="employeeAttendance" :fields="Employeesfields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ attendanceDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(username)="data">
                                        {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    </template>
                                    <template v-slot:cell(checkIn)="data">
                                        <div :class="inTimeValid(data.item.checkIn,data.item) ? '' : 'invalid-time'">{{ showInTime(data.item.checkIn) }}</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                            </b> : {{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="clock" variant="danger"></b-icon>
                                            </b> : {{ data.item.checkinTimeDif }}&nbsp;hrs</div>
                                    </template>
                                    <template v-slot:cell(checkOut)="data">
                                        <div :class="outTimeValid(data.item.checkOut,data.item) ? '' : 'invalid-time'">{{ showInTime(data.item.checkOut) }}</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                            </b> : {{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="clock" variant="danger"></b-icon>
                                            </b> : {{ data.item.checkoutTimeDif }}&nbsp;hrs</div>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <div v-if="getDay(data.item.createdAt) == 'Sun' && data.item.checkIn == null && data.item.checkOut == null" class="text-black text-center bg-warning"><b>Week End</b></div>
                                        <div v-else-if="
                data.item.checkIn == null && data.item.checkOut == null
              " style="color: red">ABSENT</div>
                                        <div v-else style="color: green"><b>PRESENT</b></div>
                                    </template>

                                    <template v-slot:cell(distance)="data">
                                        <div>{{ distanceChecking(data.item.latitude, data.item.longtitude) }} mtrs</div>
                                    </template>
                                </b-table> -->
                            <!-- <b-container>
                                    <div class="table-container" style="overflow-x:auto;">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th v-for="(name, index) in employeeUniqueNames" :key="index">{{ name }}</th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                <tr v-for="(date, index) in employeeUniqueDates" :key="index">
                                                    <td>{{ date }}</td>
                                                    <td v-for="(data, columnIndex) in employeeAttendancebyDate[index]" :key="columnIndex">
                                                <tr>
                                                    <th>
                                                        <div :class="inTimeValid(data.checkIn,data) ? '' : 'invalid-time'">
                                                            <font-awesome-icon icon="sign-in-alt"></font-awesome-icon> {{ showInTime(data.checkIn) }}
                                                        </div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                                            </b> : {{ formatDistance(data.checkinDistanceDif) }} mtrs</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="clock" variant="danger"></b-icon>
                                                            </b> : {{ data.checkinTimeDif }}&nbsp;hrs</div>
                                                    </th>
                                                    <th>
                                                        <div :class="outTimeValid(data.checkOut,data) ? '' : 'invalid-time'">
                                                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> {{ showInTime(data.checkOut) }}
                                                        </div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                                            </b> : {{ formatDistance(data.checkoutDistanceDif) }} mtrs</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="clock" variant="danger"></b-icon>
                                                            </b> : {{ data.checkoutTimeDif }}&nbsp;hrs</div>
                                                    </th>
                                                </tr>
                                                <div v-if="getDay(data.createdAt) == 'Sun' && data.checkIn == null && data.checkOut == null" class="text-black text-center bg-warning"><b>Week End</b></div>
                                                <div v-else-if="data.checkIn == null && data.checkOut == null " style="color: red"><b>ABSENT</b></div>
                                                <div v-else style="color: green"><b>PRESENT</b></div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table> -->

                            <!-- <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th v-for="(name, index) in employeeUniqueNames" :key="index">{{ name }}</th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                <tr v-for="(date, index) in employeeUniqueDates" :key="index">
                                                    <td>{{ date }}</td>
                                                    <td v-for="(data, columnIndex) in employeeAttendancebyDate[index]" :key="columnIndex">
                                                <tr>
                                                    <th>
                                                        <div v-if="data && data.checkIn" :class="inTimeValid(data.checkIn,data) ? '' : 'invalid-time'">
                                                            <font-awesome-icon icon="sign-in-alt"></font-awesome-icon> {{ showInTime(data.checkIn) }}
                                                        </div>
                                                        <div v-else >-</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                                            </b> : {{ formatDistance(data.checkinDistanceDif) }} mtrs</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="clock" variant="danger"></b-icon>
                                                            </b> : {{ data.checkinTimeDif }}&nbsp;hrs</div>
                                                    </th>
                                                    <th>
                                                        <div v-if="data && data.checkOut" :class="outTimeValid(data.checkOut,data) ? '' : 'invalid-time'">
                                                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> {{ showInTime(data.checkOut) }}
                                                        </div>
                                                        <div v-else >-</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                                            </b> : {{ formatDistance(data.checkoutDistanceDif) }} mtrs</div>
                                                        <div v-if="hideData"><b>
                                                                <b-icon icon="clock" variant="danger"></b-icon>
                                                            </b> : {{ data.checkoutTimeDif }}&nbsp;hrs</div>
                                                    </th>
                                                </tr>
                                                <div v-if="data && data.createdAt && getDay(data.createdAt) == 'Sun' && data.checkIn == null && data.checkOut == null" class="text-black text-center bg-warning"><b>Week End</b></div>
                                                <div  v-else-if="data && data.checkOut && data.checkIn == null && data.checkOut == null " style="color: red"><b>ABSENT</b></div>
                                                <div v-else-if ="data && data.checkIn" style="color: green"><b>PRESENT</b></div>
                                                <div v-else-if="data && data.checkIn == null && data.checkOut == null" style="color: red"><b>ABSENT</b></div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table> -->

                            <!-- </div>
                                </b-container> -->

                            <!-- <b-table hover responsive :items="employeeAttendance" :fields="employeeAttendanceName" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table"> -->
                            <!-- <template v-slot:cell(username)="data">
                                        {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    </template> -->
                            <!-- ===========checkin============-->
                            <!-- <template v-slot:cell(checkIn)="data">
                                        <div :class="inTimeValid(data.item.checkIn) ? '' : 'invalid-time'">{{ showInTime(data.item.checkIn) }}</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                            </b> : {{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="clock" variant="danger"></b-icon>
                                            </b> : {{ data.item.checkinTimeDif }}&nbsp;hrs</div>
                                    </template> -->
                            <!--- ==============checkOut===========-->
                            <!-- <template v-slot:cell(checkOut)="data">
                                        <div :class="outTimeValid(data.item.checkOut) ? '' : 'invalid-time'">{{ showInTime(data.item.checkOut) }}</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="geo-alt" variant="danger"></b-icon>
                                            </b> : {{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                                        <div v-if="hideData"><b>
                                                <b-icon icon="clock" variant="danger"></b-icon>
                                            </b> : {{ data.item.checkoutTimeDif }}&nbsp;hrs</div>
                                    </template> -->
                            <!---============status==============-->
                            <!-- <template v-slot:cell(status)="data">
                                        <div v-if="getDay(data.item.createdAt) == 'Sun'" class="text-black text-center bg-warning"><b>Week End</b></div>
                                        <div v-else-if="
                data.item.checkIn == null && data.item.checkOut == null
              " style="color: red">ABSENT</div>
                                        <div v-else style="color: green"><b>PRESENT</b></div>
                                    </template> -->

                            <!-- <template v-slot:cell(checkinDistanceDif)="data">
                                        <div>{{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                                    </template> -->

                            <!-- <template v-slot:cell(checkoutDistanceDif)="data">
                                        <div>{{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                                    </template> -->

                            <!-- <template v-slot:cell(checkinTimeDif)="data">
                                        {{ data.item.checkinTimeDif }}&nbsp;hrs
                                    </template> -->

                            <!-- <template v-slot:cell(checkoutTimeDif)="data">
                                        {{ data.item.checkoutTimeDif }}&nbsp;hrs
                                    </template> -->

                            <!-- <template v-slot:cell(distance)="data">
                                        <div>{{ distanceChecking(data.item.latitude, data.item.longtitude) }} mtrs</div>
                                    </template> -->

                            <!-- <template v-slot:cell(status)="data">
                                        <b :class="getTextColorClass(data.item.status)">{{ data.item.status }}</b>
                                    </template> -->
                            <!-- </b-table> -->
                            <!-- </b-card> -->

                            <b-modal ref="attendanceDateRange" hide-footer id="dateRange" title="Select Date">
                                <div>
                                    <label for="from-datepicker">Choose The From date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                      'is-invalid': $v.from.$error,
                      'is-valid': !$v.from.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div>
                                    <label for="to-datepicker">Choose The To date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                      'is-invalid': $v.to.$error,
                      'is-valid': !$v.to.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div class="text-center mt-3">
                                    <b-button variant="outline-success" type="submit" @click="
                      getAllSortCount(
                        selectedOffice,
                        selectedDates
                      )
                    ">Submit</b-button>
                                    <!-- <b-button variant="outline-success" type="submit" @click="
                      getAttendanceCountByDate(selectedDates
                      )
                    ">Submit</b-button> -->
                                </div>
                            </b-modal>

                        </b-container>
                    </div>
                </b-container>
            </b-tab>

            <b-tab title="USERS SALARY DETAILS">
                <b-container>
                    <div>
                        <b-container>
                            <b-row align-v="center">
                                <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                                </b-col>
                                <b-col sm="6" md="3" lg="2" id="r1" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedOffice" size="sm" @change="
                          getAllSalarySortCount(
                            selectedOffice,
                            selectedDates
                          )
                        ">
                                        <b-form-select-option disabled value="Office">
                                            <p>Offices</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllOffices">
                                            <p>All Offices</p>
                                        </b-form-select-option>
                                        <option v-for="item in offices" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.officeName }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">

                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" size="sm" @change="
                          getAllSalarySortCount(
                            selectedOffice,
                            selectedDates
                          )
                        " :options="dateRangeSelect">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <b-button variant="primary" @click="exportSalaryExcelSheet()">Export Data</b-button>
                                </b-col>
                            </b-row>
                            <hr />
                            <div>
                                <b-container class="scroll-table-container">
                                    <table id="tableSalaryContent" border="1" cellpadding="1" cellspacing="1" style="display:none">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>Branch</td>
                                                <td>Monthly Salary</td>
                                                <td>HouseRent Allowance</td>
                                                <td>Dearness Allowance</td>
                                                <td>Special Allowance</td>
                                                <td>Gross Salary</td>
                                                <td>Leave Count</td>
                                                <td>Late Count</td>
                                                <td>Permission Count</td>
                                                <td>Leave Deduction</td>
                                                <td>Provident Fund</td>
                                                <td>Employee StateInsurance</td>
                                                <td>Other Deduction</td>
                                                <td>Earned Salary</td>
                                                <td v-for="(date, index) in dates" :key="index" colspan="2">{{ date }}</td>
                                            </tr>
                                            <tr v-for="(e, key) in employeeAttendance" :key="key">
                                                <td rowspan="2">{{ key }}</td>
                                                <td rowspan="2" v-if="e[0] != null">{{ e[0].officeid }}</td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.salaryDetails }}
                                                            <!-- Basic Salary:{{ ea.salaryDetails }}
                                                            HRA:{{ ea.houseRentAllowance }}
                                                            DA:{{ ea.dearnessAllowance }}
                                                            SA:{{ ea.specialAllowance }}
                                                            Gross:{{ ea.grossSalary }} -->
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.houseRentAllowance }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.dearnessAllowance }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.specialAllowance }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.grossSalary }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            <div v-if="ea.LeaveCount == undefined && ea.HalfLeaveCount == undefined"> {{ defaultValue }}</div>
                                                            <div v-else-if="ea.LeaveCount == undefined">{{ defaultValue + ea.HalfLeaveCount -1}}</div>
                                                            <div v-else-if="ea.HalfLeaveCount == undefined">{{ defaultValue + ea.LeaveCount -1}}</div>
                                                            <div v-else>{{ ea.LeaveCount + ea.HalfLeaveCount -1}}</div>
                                                            <!-- {{ ea.LeaveCount + ea.HalfLeaveCount }} -->
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.LateCount }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.PermissionCount }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined"> {{ defaultValue }}</div>
                                                            <div v-else-if="ea.SalaryDeduction == undefined">{{ formatCurrency(defaultValue + ea.HalfSalary)}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(defaultValue + ea.SalaryDeduction)}}</div>
                                                            <div v-else>{{ formatCurrency(ea.SalaryDeduction + ea.HalfSalary)}}</div>
                                                            <!-- {{ ea.SalaryDeduction + ea.HalfSalary }} -->
                                                            <!-- {{ formatCurrency(ea.SalaryDeduction) }} -->

                                                            <!-- <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined"> Leave Deduction:{{ defaultValue }}</div>
                                                            <div v-else-if="ea.SalaryDeduction == undefined">Leave Deduction:{{ formatCurrency(defaultValue + ea.HalfSalary)}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined">Leave Deduction:{{ formatCurrency(defaultValue + ea.SalaryDeduction)}}</div>
                                                            <div v-else>Leave Deduction:{{ formatCurrency(ea.SalaryDeduction + ea.HalfSalary)}}</div> -->
                                                            <!-- {{ ea.SalaryDeduction + ea.HalfSalary }} -->
                                                            <!-- {{ formatCurrency(ea.SalaryDeduction) }} -->
                                                            <!-- PF:{{ ea.providentFund }}
                                                            <br>
                                                            ESI:{{ ea.employeeStateInsurance }}
                                                            <br>
                                                            ODA:{{ ea.otherDeduction }} -->
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.providentFund }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.employeeStateInsurance }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            {{ ea.otherDeduction }}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td rowspan="2" v-if="e[0] != null">
                                                    <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                        <div v-if="ea.employeeId == e[0].employeeid">
                                                            <!-- <div v-if="ea.HalfSalary == undefined && ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue }}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.WeekendSalary == undefined">{{ defaultValue + ea.Salary}}</div>
                                                    <div v-else-if="ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue + ea.HalfSalary}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.Salary == undefined">{{ defaultValue + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">{{ defaultValue + ea.Salary + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.Salary == undefined">{{ defaultValue + ea.HalfSalary + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.WeekendSalary == undefined">{{ defaultValue + ea.Salary + ea.HalfSalary}}</div>
                                                    <div v-else>{{ ea.Salary + ea.HalfSalary + ea.WeekendSalary}}</div> -->

                                                            <!-- <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined">{{ ea.salaryDetails }}</div>
                                                            <div v-else-if="ea.SalaryDeduction == undefined">{{ formatCurrency(ea.salaryDetails - ea.HalfSalary)}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(ea.salaryDetails - ea.SalaryDeduction)}}</div>
                                                            <div v-else>{{ formatCurrency(ea.salaryDetails - (ea.SalaryDeduction + ea.HalfSalary))}}</div> -->

                                                            <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined">{{ (ea.grossSalary - (ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction)) }}</div>
                                                            <div v-else-if="ea.SalaryDeduction == undefined">{{ formatCurrency(ea.grossSalary - (ea.HalfSalary + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(ea.grossSalary - (ea.SalaryDeduction + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>
                                                            <div v-else>{{ formatCurrency(ea.grossSalary - (ea.SalaryDeduction + ea.HalfSalary + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>

                                                            <!-- <div v-if="ea.HalfSalary == undefined && ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue }}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined && ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary)}}</div>
                                                            <div v-else-if="ea.Salary == undefined && ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.HalfSalary)}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined && ea.Salary == undefined">{{ formatCurrency(defaultValue + ea.WeekendSalary)}}</div>
                                                            <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary + ea.WeekendSalary)}}</div>
                                                            <div v-else-if="ea.Salary == undefined">{{ formatCurrency(defaultValue + ea.HalfSalary + ea.WeekendSalary)}}</div>
                                                            <div v-else-if="ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary + ea.HalfSalary)}}</div>
                                                            <div v-else>{{ formatCurrency(ea.Salary + ea.HalfSalary + ea.WeekendSalary)}}</div> -->

                                                        </div>

                                                    </div>
                                                </td>

                                                <template v-for="(c,index) in e">
                                                    <td v-if="c.isCheckedIn" :key="key+'c-' + index + generateRandomNumber()">
                                                        <span v-if="c.checkIn !== null">{{ secondsRemove(c.checkIn) }}</span>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td v-else :key="key+'c-' + index + generateRandomNumber()">
                                                        <span>N/A</span>
                                                    </td>
                                                    <td v-if="c.isCheckedOut" :key="key+'c-' + index + generateRandomNumber()">
                                                        <span v-if="c.checkOut !== null">{{ secondsRemove(c.checkOut) }}</span>
                                                        <span v-else>N/A</span>
                                                    </td>
                                                    <td v-else :key="key+'c-' + index + generateRandomNumber()">N/A</td>
                                                </template>
                                                <template>
                                            <tr>
                                                <td v-for="(c,index) in e" :key="key+'c-' + index + generateRandomNumber()" colspan="2">{{c.status}}</td>
                                            </tr>
                                            </template>

                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- ======================================== -->
                                    <table>
                                        <tr>
                                            <th>Username</th>
                                            <th>Branch</th>
                                            <th v-for="(date, index) in dates" :key="index">
                                                {{ date }}
                                            </th>
                                            <th>Monthly Salary</th>
                                            <th>Leave Count</th>
                                            <th>Late Count</th>
                                            <th>Permission Count</th>
                                            <th>Salary Deduction</th>
                                            <th>Earned Salary</th>
                                        </tr>
                                        <tr v-for="(e,key) in employeeAttendance" :key="key">
                                            <th>
                                                {{ key }}
                                            </th>
                                            <th v-if="e[0] != null">
                                                {{ e[0].officeid }}
                                            </th>
                                            <th v-for="(c) in e" :key="c.createdAt">
                                        <tr>
                                            <th>
                                                <b-badge v-if="c.checkIn != null" style="background-color:#28A745;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkIn) }}
                                                </b-badge>
                                                <b-badge v-else style="background-color:#28A745;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                </b-badge>
                                            </th>
                                            <th>
                                                <b-badge v-if="c.checkOut != null" style="background-color:#DC3545;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">{{ secondsRemove(c.checkOut) }}
                                                </b-badge>
                                                <b-badge v-else style="background-color:#DC3545;color:#fff;width: 100%;margin-right:1em;font-family: 'Pavanam', 'sans-serif';font-weight: bolder;" pill variant="warning">N/A
                                                </b-badge>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colspan="2">
                                                {{ c.status }}
                                            </th>
                                        </tr>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    Basic Salary:{{ ea.salaryDetails }}
                                                    <br>
                                                    HRA:{{ ea.houseRentAllowance }}
                                                    <br>
                                                    DA:{{ ea.dearnessAllowance }}
                                                    <br>
                                                    SA:{{ ea.specialAllowance }}
                                                    <br>
                                                    Gross:{{ ea.grossSalary }}
                                                </div>
                                            </div>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    <div v-if="ea.LeaveCount == undefined && ea.HalfLeaveCount == undefined"> {{ defaultValue }}</div>
                                                    <div v-else-if="ea.LeaveCount == undefined">{{ defaultValue + ea.HalfLeaveCount -1}}</div>
                                                    <div v-else-if="ea.HalfLeaveCount == undefined">{{ defaultValue + ea.LeaveCount -1}}</div>
                                                    <div v-else>{{ ea.LeaveCount + ea.HalfLeaveCount -1}}</div>
                                                    <!-- {{ ea.LeaveCount + ea.HalfLeaveCount }} -->
                                                </div>

                                            </div>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    {{ ea.LateCount }}
                                                    <!-- <span>Late Count:{{ ea.LateCount }}</span>
                                                    <br>
                                                    <span>Permission Count:{{ ea.PermissionCount }}</span>
                                                    <br>
                                                    <span>Salary Details:{{ ea.salaryDetails }}</span> -->
                                                    <!-- <span>All:{{ ea }}</span> -->

                                                </div>

                                            </div>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    {{ ea.PermissionCount }}
                                                </div>

                                            </div>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined"> Leave Deduction:{{ defaultValue }}</div>
                                                    <div v-else-if="ea.SalaryDeduction == undefined">Leave Deduction:{{ formatCurrency(defaultValue + ea.HalfSalary)}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">Leave Deduction:{{ formatCurrency(defaultValue + ea.SalaryDeduction)}}</div>
                                                    <div v-else>Leave Deduction:{{ formatCurrency(ea.SalaryDeduction + ea.HalfSalary)}}</div>
                                                    <!-- {{ ea.SalaryDeduction + ea.HalfSalary }} -->
                                                    <!-- {{ formatCurrency(ea.SalaryDeduction) }} -->
                                                    PF:{{ ea.providentFund }}
                                                    <br>
                                                    ESI:{{ ea.employeeStateInsurance }}
                                                    <br>
                                                    ODA:{{ ea.otherDeduction }}
                                                </div>
                                            </div>
                                        </th>
                                        <th v-if="e[0] != null">
                                            <div v-for="(ea,index) in officeUsersSalary" :key="index">
                                                <div v-if="ea.employeeId == e[0].employeeid">
                                                    <!-- <div v-if="ea.HalfSalary == undefined && ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue }}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.WeekendSalary == undefined">{{ defaultValue + ea.Salary}}</div>
                                                    <div v-else-if="ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue + ea.HalfSalary}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.Salary == undefined">{{ defaultValue + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">{{ defaultValue + ea.Salary + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.Salary == undefined">{{ defaultValue + ea.HalfSalary + ea.WeekendSalary}}</div>
                                                    <div v-else-if="ea.WeekendSalary == undefined">{{ defaultValue + ea.Salary + ea.HalfSalary}}</div>
                                                    <div v-else>{{ ea.Salary + ea.HalfSalary + ea.WeekendSalary}}</div> -->

                                                    <!-- <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined">{{ ea.salaryDetails }}</div>
                                                    <div v-else-if="ea.SalaryDeduction == undefined">{{ formatCurrency(ea.salaryDetails - ea.HalfSalary)}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(ea.salaryDetails - ea.SalaryDeduction)}}</div>
                                                    <div v-else>{{ formatCurrency(ea.salaryDetails - (ea.SalaryDeduction + ea.HalfSalary))}}</div> -->

                                                    <div v-if="ea.SalaryDeduction == undefined && ea.HalfSalary == undefined">{{ (ea.grossSalary - (ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction)) }}</div>
                                                    <div v-else-if="ea.SalaryDeduction == undefined">{{ formatCurrency(ea.grossSalary - (ea.HalfSalary + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(ea.grossSalary - (ea.SalaryDeduction + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>
                                                    <div v-else>{{ formatCurrency(ea.grossSalary - (ea.SalaryDeduction + ea.HalfSalary + ea.providentFund + ea.employeeStateInsurance + ea.otherDeduction))}}</div>

                                                    <!-- <div v-if="ea.HalfSalary == undefined && ea.Salary == undefined && ea.WeekendSalary == undefined">{{ defaultValue }}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary)}}</div>
                                                    <div v-else-if="ea.Salary == undefined && ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.HalfSalary)}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined && ea.Salary == undefined">{{ formatCurrency(defaultValue + ea.WeekendSalary)}}</div>
                                                    <div v-else-if="ea.HalfSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary + ea.WeekendSalary)}}</div>
                                                    <div v-else-if="ea.Salary == undefined">{{ formatCurrency(defaultValue + ea.HalfSalary + ea.WeekendSalary)}}</div>
                                                    <div v-else-if="ea.WeekendSalary == undefined">{{ formatCurrency(defaultValue + ea.Salary + ea.HalfSalary)}}</div>
                                                    <div v-else>{{ formatCurrency(ea.Salary + ea.HalfSalary + ea.WeekendSalary)}}</div> -->

                                                </div>

                                            </div>
                                        </th>
                                        </tr>
                                    </table>
                                </b-container>
                            </div>
                            <b-modal ref="attendanceSalaryDateRange" hide-footer id="dateRange" title="Select Date">
                                <div>
                                    <label for="from-datepicker">Choose The From date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                      'is-invalid': $v.from.$error,
                      'is-valid': !$v.from.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <!-- <div>
                                    <label for="to-datepicker">Choose The To date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                      'is-invalid': $v.to.$error,
                      'is-valid': !$v.to.$invalid,
                    }" type="date"></b-form-input>
                                </div> -->
                                <div class="text-center mt-3">
                                    <b-button variant="outline-success" type="submit" @click="
                      getAllSalarySortCount(
                        selectedOffice,
                        selectedDates
                      )
                    ">Submit</b-button>
                                </div>
                            </b-modal>

                        </b-container>
                    </div>
                </b-container>

            </b-tab>

            <!-- ================================LEAVE REQUEST================================= -->
            <b-tab title="LEAVE REQUEST" id="leaveRequest">
                <b-container>
                    <b-card class="card mt-1">
                        <b-row align-v="center">
                            <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="requsetTotalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchRequestPage(currentPage, pageCount)
                      "></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="3" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="
                        switchRequestPage(currentPage, pageCount)
                      " :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="mx-2">Total Count:</label>
                                <b-button variant="danger">{{
                      requsetTotalCount
                    }}</b-button>
                            </b-col>
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportOfficeUserExcellSheet()">Download</b-button>
                                    </b-col> -->
                        </b-row>
                        <hr />
                        <b-table hover responsive :items="requestDetails" :fields="requestFields" :per-page="requestDetails.pageCount" :current-page="currentPage" ref="table" align-v="center">
                            <!-- <template v-slot:cell(firstName)="data">
                                        {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    </template> -->
                            <template v-slot:cell(description)="data">
                                <span v-if="data.item.description.length > 50">
                                    {{ data.item.description.slice(0, 30) }}
                                </span>
                                <span v-else>
                                    {{ data.item.description }}
                                </span>
                            </template>
                            <template v-slot:cell(action)="data">
                                <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" @click="showViewModel(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="approved" @click="showResponseModal(data.item)">
                                    <font-awesome-icon icon="check" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="danger" v-b-tooltip.hover.bottomright="{ variant: 'danger' }" title="reject" @click="showRejectModal(selectedRequestRow.requestId)" v-b-modal.updateCall>
                                    <font-awesome-icon icon="times" />
                                </b-button>
                                <!-- <b-button size="sm" class="mx-1" variant="secondary" @click="notintrestedClosed(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Notintrested">
                                            <font-awesome-icon icon="window-close" />
                                        </b-button> -->
                            </template>
                        </b-table>
                        <!-- ===================VIEW REQUEST MODEL================ -->
                        <b-modal v-model="viewRequestModel" hide-footer title="Request" size="lg">
                            <!-- <div class="d-block text-center"> -->
                            <b-form>
                                <b-form-group id="input-group-1" label="User Name:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="selectedRequestRow.username" type="text" placeholder="User NAME" disabled></b-form-input>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="selectedRequestRow.name" type="text" placeholder="Name" disabled></b-form-input>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Reasons Brief:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="selectedRequestRow.requestBrief" type="text" placeholder="brief" disabled></b-form-input>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Leave From:" label-for="input-1">
                                    <b-form-datepicker id="example-i18n-picker" v-model="selectedRequestRow.leaveFrom" disabled class="mb-2"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Leave To:" label-for="input-1">
                                    <b-form-datepicker id="example-i18n-picker" v-model="selectedRequestRow.leaveTo" disabled class="mb-2"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Reason:" label-for="input-1">
                                    <b-form-textarea id="textarea" v-model="selectedRequestRow.description" placeholder="Reasons..." rows="3" max-rows="6" disabled></b-form-textarea>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Status:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="selectedRequestRow.status" type="text" placeholder="status" disabled></b-form-input>
                                </b-form-group>
                                <!-- </div> -->
                                <div style="text-align: right" class="mt-3">
                                    <b-button class="mx-4 px-4" variant="outline-danger" @click="showRejectModal(selectedRequestRow.requestId)">Reject</b-button>
                                    <b-button class="mx-2" variant="outline-warning">Approved</b-button>
                                </div>
                            </b-form>
                        </b-modal>
                        <!-- ==================RESPONSE MODAL=================== -->
                        <b-modal v-model="responseModel" hide-footer title="Response" size="md">
                            <!-- <div class="d-block text-center"> -->
                            <b-form>
                                <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="selectedRequestRow.name" type="text" placeholder="Name" disabled></b-form-input>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Leave From:" label-for="input-1">
                                    <b-form-datepicker id="example-i18n-picker" v-model="selectedRequestRow.approvedDateFrom" class="mb-2"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group id="input-group-1" label="Leave To:" label-for="input-1">
                                    <b-form-datepicker id="example-i18n-picker" v-model="selectedRequestRow.approvedDateTo" class="mb-2"></b-form-datepicker>
                                </b-form-group>
                                <!-- </div> -->
                                <div style="text-align: right" class="mt-3">
                                    <b-button class="mx-2" variant="outline-warning" @click="approvedRequest()">Approved</b-button>
                                </div>
                            </b-form>
                        </b-modal>
                        <!-- ===============REJECT MODEL=========== -->
                        <b-modal v-model="rejectModal" hide-footer title="Request" size="lg">
                            <b-form-group id="input-group-1" label="Rejected Reason:" label-for="input-1">
                                <b-form-textarea id="textarea" v-model="rejectReason" placeholder="Reasons..." rows="3" max-rows="6" required></b-form-textarea>
                            </b-form-group>
                            <b-button class="mx-4 px-4" variant="outline-danger" @click="rejectRequest()">Reject</b-button>
                        </b-modal>
                    </b-card>
                </b-container>
            </b-tab>
        </b-tabs>
    </b-container>
</div>
</template>

<script>
import * as XLSX from "xlsx";
import QRCode from "qrcode";
import moment from "moment-timezone";
import Account from '../service/Account.js';
import OfficeService from "../service/OfficeService"
import TeamService from "../service/TeamService";
import AttendanceEmployeeService from "../service/AttendanceEmployeeService"
import LeaveRequestService from "../service/LeaveRequestService";
import {
    required,
    email,
    minLength,
    maxLength,
    numeric,
} from "vuelidate/lib/validators";
import axios from 'axios';
export default {
    name: "OfficeDetails",
    components: {},
    data() {
        return {
            activeTabs: 0,
            tabIndex: 0,
            // exportFormat: {
            exportFormat: [{
                    name: "Naveen",
                    branch: "IT",
                    checkIn: "10:00",
                    checkOut: "19:00",
                    status: "PRESENT"
                },
                {
                    name: "Jana",
                    branch: "IT",
                    checkIn: "10:05",
                    checkOut: "19:10",
                    status: "PRESENT"
                },
            ],
            // },
            startDate: "2023-07-01",
            endDate: "2023-07-10",
            dates: [], // This array will hold the generated dates
            userDetails: [],
            // filteredEmployeeAttendanceName: [],
            selectedOffice: "Office",
            offices: [],
            employeeAttendanceName: [],
            disabledButton: false,
            employeeUniqueNames: [],
            employeeUniqueDates: [],
            hideData: false,
            data: "",
            paginationOffice: true,
            searchPagination: false,
            paginationAttendance: true,
            employeeAttendance: [],
            employeeAttendancebyDate: [],
            selectedDate: null,
            selectedDates: null,

            //Office Migration
            selectedTeam: "Team",
            allTeams: "",
            allOffice: "",
            selectedToOffice: "Office",
            currentTeam: "",
            selectedEmployee: "",
            //Office Migration

            //Salary Details//
            totalSalaryAttendanceCount: 0,
            officeUsersSalary: [],
            employeeSalary: [],
            defaultValue: 0,

            //Salary Details//

            // // restServerLocation : 'https://api-encore.examsdaily.in',
            // restServerLocation: 'http://localhost:8088',
            resourceLocation: "https://api-encore.examsdaily.in/uploads/reports/",
            // resourceLocation: "http://localhost:8088/uploads/reports/",
            loading: false,
            keyword: "",
            disabledKeyword: "",
            // for Office User pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            disabledCurrentPage: 1,
            disabledPageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            officeUsers: [],
            officeDisabledUsers: [],
            officeUser: {
                officeId: "",
                name: "",
                email: "",
                empId: "",
                contact: ""
            },
            updateUser: {
                employeeId: "",
                firstName: "",
                lastName: "",
                contact: "",
                mobile: "",
            },

            totalCount: 0,
            totalDisabledCount: 0,
            totalAttendanceCount: 0,
            officeAddress: "",
            qrCodeImage: null,
            qrCodeData: "",
            selectedRow: {
                officeId: "",
                officeName: '',
                address: '',
                longtitude: '',
                latitude: '',
                branch: ''
            },

            from: "",
            to: "",
            fromValue: "",
            toValue: "",
            showDecadeNav: false,
            requsetTotalCount: 0,
            requestDetails: [],
            viewRequestModel: false,
            selectedRequestRow: {
                // approvedDateFrom: '',
                // approvedDateTo: ''
            },
            requestId: '',
            rejectModal: false,
            responseModel: false,
            rejectReason: '',
            requestFields: [{
                    key: "name",
                    label: "Name",
                },
                // {
                //     key: "employeId",
                //     label: "EMP ID",
                // },
                {
                    key: "leaveType",
                    label: "Type",
                },
                {
                    key: "requestBrief",
                    label: "Brief",
                },
                {
                    key: "leaveFrom",
                    label: "From",
                },
                {
                    key: "leaveTo",
                    label: "To",
                },
                {
                    key: "description",
                    label: "Reason",
                },
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "action",
                    label: "Action",
                }
            ],
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            dateRangeSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                // {
                //     value: "yesterday",
                //     text: "Yesterday"
                // },
                // {
                //     value: "lastWeek",
                //     text: "Past 7 days"
                // },
                // {
                //     value: "lastMonth",
                //     text: "Past 30 days"
                // },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            fields: [
                // {
                //     key: "createdAt",
                //     label: "Date",
                //     tdClass: "cellSize",
                // },
                {
                    key: "firstName",
                    label: "Name",
                },
                // {
                //     key: "employeId",
                //     label: "EMP ID",
                // },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],

            Employeesfields: [{
                    key: "createdAt",
                    label: "Date",
                }, {
                    key: "username",
                    label: "Name",
                },
                {
                    key: "checkIn",
                    label: "CheckIn",
                },
                {
                    key: "checkOut",
                    label: "CheckOut",
                },
                // {
                //     key: "checkinLat",
                //     label: "Latitude",
                // },
                // {
                //     key: "checkinLong",
                //     label: "Longitude",
                // },
                // {
                //     key: "checkinDistanceDif",
                //     label: "CheckinDistance",
                // },
                // {
                //     key: "checkoutDistanceDif",
                //     label: "CheckOutDistance",
                // },
                // {
                //     key: "checkinTimeDif",
                //     label: "CheckinTimeDif",
                // },
                // {
                //     key: "checkoutTimeDif",
                //     label: "CheckoutTimeDif",
                // },
                {
                    key: "status",
                    label: "Status",
                },
            ],

        };
    },

    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        },

        disabledKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getDisabledCount();
            }
        },
        // selectedDate(newVal, oldVal) {
        //     if (oldVal && !newVal) {
        //         console.log("=====GetAttendanceCountByDate Method Calling=====");
        //         this.getAttendanceCountByDate();
        //     }
        // },
        from(newFromDate) {
            // Parse the selected 'from' date
            const fromDate = moment(newFromDate, 'yyyy-MM-DD HH:mm');

            // Calculate the 'to' date as the next month's 21st day
            const toDate = fromDate.clone().add(1, 'month').date(20);

            // Format the 'to' date as 'yyyy-MM-DD HH:mm'
            this.to = toDate.format('yyyy-MM-DD HH:mm');
            console.log("=====From=====", newFromDate);
            console.log("=====To=====", this.to);
        },
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },

        officeUser: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            empId: {
                required,
            },
            contact: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric,
            },
        },
        office: {
            officeName: {
                required
            },
            address: {
                required
            },
            longitude: {
                required
            },
            latitude: {
                required
            },
            branch: {
                required
            },
        },

        updateUser: {
            firstName: {
                required,
                minLength: minLength(3),
            },
            checkinTime: {
                required,
                minLength: minLength(8),
                maxLength: minLength(8),
            },
            checkoutTime: {
                required,
                minLength: minLength(8),
                maxLength: minLength(8),
            },
        }
    },
    async mounted() {
        this.loading = true;
        await this.global()
        this.tabIndex = this.$route.params.tab;
        var tabValue = localStorage.getItem('tabValue');
        console.log("______TAB_____" + this.$route.params.tab);
        if (this.tabIndex == 5 || tabValue) {
            this.activeTabs = 5;
            localStorage.removeItem('tabValue');
        }
        this.routeData = this.$route.params.data;
        this.officeId = this.routeData;
        localStorage.setItem("officeId", this.routeData);
        // console.log("=====Route Data=====",this.routeData);
        await this.getOfficeDetails();
        await this.getAllOfficeDetails();
        await this.getCount();
        await this.getDisabledCount();
        await this.getAllTeams();
        await this.getRequestCount();
        // await this.getCurrentTeam(localStorage.getItem("tenant-uuid"));
        // this.selectedTeam = this.currentTeam
        // this.selectedToOffice = this.selectedRow;
        // await this.getAllOffice(this.currentTeam.teamId);
        // this.getAttendanceCount();
        // this.getOfficeDetails();
        await this.getCurrentDate();
        // resolve(response);
        // console.log("====this.selectedRow====", this.selectedRow);
        // console.log(this.selectedRow.officeId);
        this.selectedOffice = this.selectedRow;
        this.selectedDates = "today";
        await this.getAllSortCount(this.selectedRow, this.selectedDates);
        await this.getAllSalarySortCount(this.selectedRow, this.selectedDates);
        this.loading = false;
    },
    computed: {
        filteredEmployeeAttendanceName() {
            // Use a Set to remove duplicates from employeeAttendanceName
            const uniqueNames = [...new Set(this.employeeAttendanceName)];
            // const self = this;
            // self.employeeUniqueNames = uniqueNames;
            // console.log("=====employeeUniqueNames=====", self.employeeUniqueNames);
            return uniqueNames;
        },

        uniqueDates() {
            // Extract unique dates from employeeAttendance array
            //   const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry.createdAt))];
            const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry.createdAt.split('T')[0]))];
            return uniqueDates;
        },

    },
    created() {
        this.generateDateRange();
    },
    methods: {
        generateRandomNumber() {
            return Math.random();
        },
        exportExcelSheet() {
            // Get a reference to the HTML table
            var tableElement = document.getElementById('tableContent');

            // Convert the HTML table to an Excel worksheet
            var worksheet = XLSX.utils.table_to_sheet(tableElement);

            // Define the cell style for center alignment (change as needed)
            var cellStyle = {
                alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                },
            };

            // Apply the style to the cells in the worksheet
            var range = XLSX.utils.decode_range(worksheet['!ref']);
            for (var R = range.s.r; R <= range.e.r; ++R) {
                for (var C = range.s.c; C <= range.e.c; ++C) {
                    var cellRef = XLSX.utils.encode_cell({
                        r: R,
                        c: C
                    });
                    if (!worksheet[cellRef]) continue;

                    worksheet[cellRef].s = cellStyle;
                }
            }

            // Create a new workbook and add the worksheet
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Convert the workbook to an Excel binary buffer
            var excelBuffer = XLSX.write(workbook, {
                type: 'array'
            });

            // Create a Blob from the buffer
            var blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            // Create a URL from the Blob
            var url = URL.createObjectURL(blob);

            // Create a temporary link and trigger the download
            var a = document.createElement('a');
            a.href = url;
            let fromDate = this.convertDateFormat(this.data.from.slice(0, 10));
            let toDate = this.convertDateFormat(this.data.to.slice(0, 10));
            console.log("=---fromDate--=", fromDate);
            console.log("=---toDate--=", toDate);
            a.download = fromDate + '-to-' + toDate + '-checkin-report.xlsx'; // Set the filename for the download
            a.click();

            // Clean up the URL and the temporary link
            URL.revokeObjectURL(url);
        },
        convertDateFormat(inputDate) {
            var parts = inputDate.split('-');
            console.log("====parts===");
            // Rearrange the parts to the desired format
            var outputDate = parts[2] + '/' + parts[1] + '/' + parts[0];
            console.log("----outputDate---", outputDate);
            return outputDate;
        },
        secondsRemove(timeString) {
            let timeWithoutSeconds = timeString.substring(0, 5);
            return timeWithoutSeconds;
        },
        generateDateRange() {
            const startDate = new Date(this.data.from.slice(0, 10));
            const endDate = new Date(this.data.to.slice(0, 10));
            const dateArray = [];

            // Iterate through each date between the start date and end date
            let currentDate = new Date(startDate);
            while (currentDate <= endDate) {
                dateArray.push(currentDate.toISOString().split("T")[0]);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            this.dates = dateArray;
        },
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getTextColorClass(value) {
        //     // console.log("=====Value=====",value);
        //     // Define your conditions and corresponding CSS classes here
        //     if (value === 'Absent') {
        //         return 'red-text';
        //     } else if (value === 'present') {
        //         return 'green-text';
        //     } else {
        //         return 'default-text';
        //     }
        // },

        showInTime(time) {
            // console.log("__________________________________" + time);
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const timeFormat = timeObj.format("h:mm A");
                return timeFormat;
            } else {
                return "N/A";
            }
        },
        // inTimeValid(time) {
        //     // console.log("=====OfficeInTime=====", officeInTimeHour);
        //     if (time != null) {
        //         const timeObj = moment(time, "HH:mm:ss");
        //         const hour = timeObj.format("H");
        //         const minute = timeObj.format("m");
        //         if (hour <= 10) {
        //             return hour < 10 || (hour == 9 && minute <= 0);
        //         } else {
        //             return false;
        //         }
        //     }
        //     // console.log("_______________IN______________" + this.showInTime);
        // },
        // outTimeValid(time) {
        //     if (time != null) {
        //         const timeObj = moment(time, "HH:mm:ss");
        //         const hour = timeObj.format("H");
        //         // console.log("++++++++++M++++" + hour);
        //         if (hour < 19) {
        //             return false;
        //         } else {
        //             return true;
        //         }
        //     }
        //     // console.log("_______________Out______________" + this.showOutTime);
        // },

        inTimeValid(time, obj) {
            if (time != null) {
                // console.log("________condition_______"+ (10 < 10 || 10 == 10 && 0 <= 0));
                const timeObj = moment(time, "HH:mm:ss");
                const hourStr = timeObj.format("H");
                const hour = parseInt(hourStr, 10);
                const minuteStr = timeObj.format("m");
                const minute = parseInt(minuteStr, 10);
                const officeInTimeObj = moment(obj.officeInTime, "HH:mm:ss");
                const officeHourStr = officeInTimeObj.format("H");
                const officeHour = parseInt(officeHourStr, 10);
                const officeMinuteStr = officeInTimeObj.format("m");
                const officeMinute = parseInt(officeMinuteStr, 10);
                // console.log( obj.officeInTime,"________OFFICE HR MM_________"+officeHour,officeMinute);
                if (hour <= officeHour) {
                    // console.log("________value_______"+ typeof hour+" "+typeof officeHour+" "+typeof hour+" "+typeof officeMinute+" "+typeof minute+" "+typeof officeMinute);
                    // console.log("________condition_______"+(hour < officeHour || (hour == officeHour && minute <= officeMinute)));
                    return hour < officeHour || (hour == officeHour && minute <= officeMinute);
                } else {
                    return false;
                }
            }
            // console.log("_______________IN______________" + this.showInTime);
        },
        outTimeValid(time, obj) {
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const hourStr = timeObj.format("H");
                const hour = parseInt(hourStr, 10);
                const minuteStr = timeObj.format("m");
                const minute = parseInt(minuteStr, 10);
                const officeOutTimeObj = moment(obj.officeOutTime, "HH:mm:ss");
                const officeHourStr = officeOutTimeObj.format("H");
                const officeHour = parseInt(officeHourStr, 10);
                const officeMinuteStr = officeOutTimeObj.format("m");
                const officeMinute = parseInt(officeMinuteStr, 10);
                // console.log("++++++++++M++++" + (hour <= officeHour && minute <= officeMinute)+hour,officeHour,minute,officeMinute);
                if (officeHour <= hour && officeMinute <= minute) {
                    return true;
                } else {
                    return false;
                }
            }
            // console.log("_______________Out______________" + this.showOutTime);
        },

        getDay(dateString) {
            const dateParts = dateString.split(" ")[0].split("-");
            const year = parseInt(dateParts[0], 10);
            const month = parseInt(dateParts[1], 10) - 1;
            const day = parseInt(dateParts[2], 10);
            const date = new Date(year, month, day);
            const dayOfWeek = date.getDay();
            switch (dayOfWeek) {
                case 0:
                    return "Sun";
                case 1:
                    return "Mon";
                case 2:
                    return "Tue";
                case 3:
                    return "Wed";
                case 4:
                    return "Thu";
                case 5:
                    return "Fri";
                case 6:
                    return "Sat";
                default:
                    break;
            }
        },

        getCurrentDate() {
            this.selectedDate = new Date();
            // console.log("=====SelectedDate=====", this.selectedDate);
        },
        // attendanceDate: function (date, dateType) {
        //     const date1 = new Date(date);
        //     if (dateType === "fullDate") {
        //         return moment(date1).format("D-MM-YYYY");
        //     }
        // },
        attendanceDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        getCount: function () {
            this.currentPage = 1;
            // this.allSortAttendance = false;
            // this.paginationAttendance = true;
            this.paginationOffice = true;
            this.searchPagination = false;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getCount(this.officeId)
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("=====Total Count=====", response.data)
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllPageable(currentPage, pageCount, this.officeId)
                    .then((response) => {
                        this.officeUsers = response.data;
                        this.loading = false;
                        // console.log("=====Office Users=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getUserSearchCount: function (keyword) {
            // console.log("=====keyword=====", keyword);
            this.currentPage = 1;
            this.searchPagination = true;
            this.paginationOffice = false;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getUserSearchCount(this.officeId, keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("search pagecount", response.data);
                        this.getAllUserSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllUserSearchPageable: function (currentPage, pageCount, keyword) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllUserSearchPageable(
                        currentPage,
                        pageCount,
                        this.officeId,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.officeUsers = response.data;
                        console.log(response.data, "search page");
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            // this.loading = true;
            if (this.paginationOffice) {
                this.getAllPageable(currentPage, pageCount);
                this.loading = false;
                // console.log("this.paginationOffice", this.paginationOffice);
            } else if (this.searchPagination) {
                this.getAllUserSearchPageable(currentPage, pageCount, this.keyword);
                // console.log(this.searchPagination, "searchPagination");
            }
        },

        //Disabled Users Pagination Start

        getDisabledCount: function () {
            this.disabledCurrentPage = 1;
            // this.allSortAttendance = false;
            // this.paginationAttendance = true;
            this.paginationOffice = true;
            this.searchPagination = false;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getDisabeldCount(this.officeId)
                    .then((response) => {
                        this.totalDisabledCount = response.data;
                        // console.log("=====Total Count=====", response.data)
                        this.getAllDisabledPageable(this.disabledCurrentPage, this.disabledPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllDisabledPageable: function (disabledCurrentPage, disabledPageCount) {
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllDisabledPageable(disabledCurrentPage, disabledPageCount, this.officeId)
                    .then((response) => {
                        this.officeDisabledUsers = response.data;
                        this.loading = false;
                        // console.log("=====Office Users=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getDisabledUserSearchCount: function (disabledKeyword) {
            // console.log("=====disabledKeyword=====", disabledKeyword);
            this.disabledCurrentPage = 1;
            this.searchPagination = true;
            this.paginationOffice = false;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getDisabledUserSearchCount(this.officeId, disabledKeyword.toLowerCase())
                    .then((response) => {
                        this.totalDisabledCount = response.data;
                        console.log("search pagecount", response.data);
                        this.getAllDisabledUserSearchPageable(
                            this.disabledCurrentPage,
                            this.disabledPageCount,
                            disabledKeyword.toLowerCase()
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllDisabledUserSearchPageable: function (disabledCurrentPage, disabledPageCount, disabledKeyword) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllDisabledUserSearchPageable(
                        disabledCurrentPage,
                        disabledPageCount,
                        this.officeId,
                        disabledKeyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.officeDisabledUsers = response.data;
                        console.log(response.data, "search page");
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        disabledSwitchPage: function (disabledCurrentPage, disabledPageCount) {
            // this.loading = true;
            if (this.paginationOffice) {
                this.getAllDisabledPageable(disabledCurrentPage, disabledPageCount);
                this.loading = false;
                // console.log("this.paginationOffice", this.paginationOffice);
            } else if (this.searchPagination) {
                this.getAllDisabledUserSearchPageable(disabledCurrentPage, disabledPageCount, this.disabledKeyword);
                // console.log(this.searchPagination, "searchPagination");
            }
        },

        //Disabled Users Pagination End

        getAttendanceCount: function () {
            this.currentPage = 1;
            // console.log("=====Selected Date=====", this.selectedDate)
            // var DateObject = new Date(this.selectedDate);
            var date = new Date();
            this.fromDate = new Date(date.setHours(0, 0, 0, 0));
            this.toDate = new Date(date.setHours(23, 59, 59, 0));
            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.from = this.fromDateFormat;
            this.to = this.toDateFormat;
            // console.log("=====From Date=====", this.from)
            // console.log("=====To Date=====", this.to)
            this.data = {
                officeId: this.officeId,
                from: this.from,
                to: this.to,
            }
            // console.log("=====Data=====", this.data);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAttendanceCount(this.data)
                    // axios
                    //         .post("/check/count",this.data)
                    .then((response) => {
                        this.totalAttendanceCount = response.data;
                        // console.log("=====totalAttendanceCount=====", response.data)
                        this.getAllAttendancePageable(this.currentPage, this.pageCount, this.data);
                        // this.getAllAttendancePageable(this.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getAttendanceCountByDate: function () {
        //     this.currentPage = 1;
        //     // console.log("=====Selected Date=====", this.selectedDate)
        //     // var DateObject = new Date(this.selectedDate);
        //     // var date = new Date();
        //     this.selectedFromDate = new Date(this.selectedDate);
        //     this.selectedToDate = new Date(this.selectedDate);
        //     this.fromDate = new Date(this.selectedFromDate.setHours(0, 0, 0, 0));
        //     this.toDate = new Date(this.selectedToDate.setHours(23, 59, 59, 0));
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //     );
        //     this.from = this.fromDateFormat;
        //     this.to = this.toDateFormat;
        //     // console.log("=====From Date=====", this.from)
        //     // console.log("=====To Date=====", this.to)
        //     this.data = {
        //         officeId: this.officeId,
        //         from: this.from,
        //         to: this.to,
        //     }
        //     // console.log("=====Data=====", this.data);
        //     return new Promise((resolve, reject) => {
        //         AttendanceEmployeeService.getAttendanceCount(this.data)
        //             // axios
        //             //         .post("/check/count",this.data)
        //             .then((response) => {
        //                 this.totalAttendanceCount = response.data;
        //                 // console.log("=====totalAttendanceCount=====", response.data)
        //                 this.getAllAttendancePageable(this.currentPage, this.pageCount, this.data);
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        getAllAttendancePageable: function (currentPage, pageCount) {
            // getAllAttendancePageable: function (data) {
            // console.log("=====Data=====", data);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllAttendancePageable(currentPage, pageCount, this.data)
                    // axios
                    //     .post("/check/all/sort", data)
                    .then((response) => {
                        this.employeeAttendance = response.data;
                        this.employeeAttendanceName.splice(0)

                        // console.log("=====this.filteredEmployeeAttendanceName=====", this.filteredEmployeeAttendanceName);

                        // const twoDimArray = [];
                        // for (let i = 0; i < response.data.length; i += this.filteredEmployeeAttendanceName.length) {
                        //     twoDimArray.push(response.data.slice(i, i + this.filteredEmployeeAttendanceName.length));
                        // }

                        // console.log(twoDimArray);

                        // for (var i = 0; i < response.data.length; i++) {
                        //     this.employeeAttendanceName.push(response.data[i].firstName);
                        // }

                        // for (var i = 0; i < response.data.length; i++) {
                        //     this.employeeAttendanceName.push(response.data[i].firstName);
                        //     const uniqueNames = [...new Set(this.employeeAttendanceName)];
                        //     this.employeeUniqueNames = uniqueNames;
                        // }

                        // // console.log("=====employeeUniqueNames=====", this.employeeUniqueNames.length);
                        // // this.employeeAttendancebyDate = [];
                        // this.employeeAttendancebyDate.splice(0)
                        // for (let i = 0; i < response.data.length; i += this.employeeUniqueNames.length) {
                        //     const subset = response.data.slice(i, i + this.employeeUniqueNames.length);
                        //     this.employeeAttendancebyDate.push(subset);
                        // }

                        for (var i = 0; i < response.data.length; i++) {
                            this.employeeAttendanceName.push(response.data[i].firstName);
                            // if (response.data[i] && response.data[i].firstName !== null) {
                            //     this.employeeAttendanceName.push(response.data[i].firstName);
                            // }
                            const uniqueNames = [...new Set(this.employeeAttendanceName)];
                            // const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry && entry.createdAt ? entry.createdAt.split('T')[0] : null))];
                            const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry.createdAt.split('T')[0]))];
                            this.employeeUniqueNames = uniqueNames;
                            this.employeeUniqueDates = uniqueDates;
                        }

                        console.log("=====employeeUniqueDates=====", this.employeeUniqueDates);
                        console.log("=====employeeUniqueNames=====", this.employeeUniqueNames);
                        // this.employeeAttendancebyDate = [];
                        this.employeeAttendancebyDate.splice(0)
                        for (let i = 0; i < response.data.length; i += this.employeeUniqueNames.length) {
                            const subset = response.data.slice(i, i + this.employeeUniqueNames.length);
                            this.employeeAttendancebyDate.push(subset);
                        }

                        // console.log("=====employeeAttendance=====", this.employeeAttendancebyDate);

                        // console.log("employeeAttendanceName===== ", this.employeeAttendanceName);
                        this.loading = false;
                        console.log("=====employeeAttendance=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAttendanceCountByDate: function (selectedDates) {
            if (
                this.from != "" &&
                this.to != "" &&
                selectedDates == selectedDates
            ) {
                // console.log("=====From Date=====", this.from)
                // console.log("=====To Date=====", this.to)
                this.$refs.attendanceDateRange.hide();
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));

            }
            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.from = this.fromDateFormat;
            this.to = this.toDateFormat;
            // console.log("=====From Date=====", this.from)
            // console.log("=====To Date=====", this.to)
            this.data = {
                officeId: this.officeId,
                from: this.from,
                to: this.to,
            }
            // console.log("=====Data=====", this.data);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAttendanceCount(this.data)
                    // axios
                    //         .post("/check/count",this.data)
                    .then((response) => {
                        this.totalAttendanceCount = response.data;
                        // console.log("=====totalAttendanceCount=====", response.data)
                        this.getAllAttendancePageable(this.currentPage, this.pageCount, this.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortCount: function (
            selectedOffice,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.attendanceDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedOffice == "Office" || selectedOffice == "AllOffices") {
                    // selectedOffice = {
                    //     officeId: "",
                    // };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    // let fromDate = "2021-01-01 00:01";
                    // let toDate = new Date();

                    var currentDate = new Date();
                    let fromDate = new Date(currentDate.setHours(0, 0, 0, 0));
                    let toDate = new Date(currentDate.setHours(23, 59, 59, 0));

                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    officeId: selectedOffice == "Office" || selectedOffice == "AllOffices" ? "" : selectedOffice.officeId,
                    from: from,
                    to: to,
                };
                // console.log("------selectedOffice------", selectedOffice);
                this.loading = true;
                this.currentPage = 1;
                this.allSortAttendance = true;
                this.paginationAttendance = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/check/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("=====getAllSortCount=====", response.data);
                            this.totalAttendanceCount = response.data;
                            if (selectedOffice == "AllOffices") {
                                this.getAllOfficeSortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                            } else {
                                this.getAllSortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getOfficeAllUsers(teamId) {
            // let data = {
            //     officeId: officeId
            // }
            return new Promise((resolve, reject) => {
                axios
                    .post("/user/office/all/" + teamId)
                    .then((response) => {
                        console.log("getOfficeAllUsers======", response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllOfficeUsers(officeId) {
            // let data = {
            //     officeId: officeId
            // }
            return new Promise((resolve, reject) => {
                axios
                    .post("/user/getAll/office/" + officeId)
                    .then((response) => {
                        // console.log("=====getAllOfficeUsers=====", response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        async getAllOfficeSortPageable(currentPage, pageCount, data) {
            // console.log("currentPage=======", currentPage);
            // console.log("pageCount=======", pageCount);
            console.log("=====data=====", data);
            await this.getOfficeAllUsers(localStorage.getItem('tenant-uuid'))
                .then(res => {
                    console.log("res.data-0-0-0-0-", res.data);
                    data["officeUsers"] = res.data
                })
            console.log("=====data=====", data);
            this.generateDateRange();
            return new Promise((resolve, reject) => {
                // AttendanceEmployeeService.getAllSortAttendancePageable(currentPage, pageCount, this.data)
                // axios.post("/check/all/sort/" + (currentPage-1) + "/" + pageCount, data)
                axios.post("/check/allOffice/user/Checkins/sort", data)
                    .then((response) => {
                        // console.log("=====getAllSortPageable========", response.data);
                        this.employeeAttendance = response.data;
                        this.loading = false;
                        // for (const obj of response.data) {
                        //     console.log(obj);
                        //     // this.employeeAttendance.push(obj)
                        // }
                        // this.employeeAttendanceName.splice(0)
                        // for (var i = 0; i < response.data.length; i++) {
                        //     this.employeeAttendanceName.push(response.data[i].firstName);
                        //     console.log("employeeAttendanceName===== ", response.data[i].firstName);
                        // }
                        // // console.log("employeeAttendanceName===== ", this.employeeAttendanceName);
                        // console.log("employeeAttendance===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // =================== Sort office wise users data ===================
        async getAllSortPageable(currentPage, pageCount, data) {
            // console.log("currentPage=======", currentPage);
            // console.log("pageCount=======", pageCount);
            // console.log("=====data=====", data);
            await this.getAllOfficeUsers(data.officeId)
                .then(res => {
                    // console.log("res.data-0-0-0-0-", res.data);
                    data["officeUsers"] = res.data
                })
            // console.log("=====data=====", data);
            this.generateDateRange();
            return new Promise((resolve, reject) => {
                // AttendanceEmployeeService.getAllSortAttendancePageable(currentPage, pageCount, this.data)
                // axios.post("/check/all/sort/" + (currentPage-1) + "/" + pageCount, data)
                axios.post("/check/allCheckins/sort", data)
                    .then((response) => {
                        // console.log("=====getAllSortPageable========", response.data);
                        this.employeeAttendance = response.data;
                        this.loading = false;
                        // for (const obj of response.data) {
                        //     console.log(obj);
                        //     // this.employeeAttendance.push(obj)
                        // }
                        // this.employeeAttendanceName.splice(0)
                        // for (var i = 0; i < response.data.length; i++) {
                        //     this.employeeAttendanceName.push(response.data[i].firstName);
                        //     console.log("employeeAttendanceName===== ", response.data[i].firstName);
                        // }
                        // // console.log("employeeAttendanceName===== ", this.employeeAttendanceName);
                        // console.log("employeeAttendance===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSalarySortCount: function (
            selectedOffice,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.attendanceSalaryDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedOffice == "Office" || selectedOffice == "AllOffices") {
                    // selectedOffice = {
                    //     officeId: "",
                    // };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceSalaryDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceSalaryDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    // let fromDate = "2021-01-01 00:01";
                    // let toDate = new Date();

                    var currentDate = new Date();
                    let fromDate = new Date(currentDate.setHours(0, 0, 0, 0));
                    let toDate = new Date(currentDate.setHours(23, 59, 59, 0));

                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    officeId: selectedOffice == "Office" || selectedOffice == "AllOffices" ? "" : selectedOffice.officeId,
                    from: from,
                    to: to,
                };
                // console.log("------selectedOffice------", selectedOffice);
                this.loading = true;
                this.currentPage = 1;
                this.allSortAttendance = true;
                this.paginationAttendance = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/check/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("=====getAllSortCount=====", response.data);
                            this.totalSalaryAttendanceCount = response.data;
                            if (selectedOffice == "AllOffices") {
                                this.getAllOfficeSalarySortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                            } else {
                                this.getAllSalarySortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        // =================== Sort office wise users data ===================
        async getAllSalarySortPageable(currentPage, pageCount, data) {
            // console.log("currentPage=======", currentPage);
            // console.log("pageCount=======", pageCount);
            // console.log("=====data=====", data);
            await this.getAllOfficeUsers(data.officeId)
                .then(res => {
                    // console.log("res.data-0-0-0-0-", res.data);
                    data["officeUsers"] = res.data
                })
            // console.log("=====data=====", data);
            this.generateDateRange();
            return new Promise((resolve, reject) => {
                // AttendanceEmployeeService.getAllSortAttendancePageable(currentPage, pageCount, this.data)
                // axios.post("/check/all/sort/" + (currentPage-1) + "/" + pageCount, data)
                axios.post("/check/allCheckins/sort", data)
                    .then((response) => {
                        // console.log("=====getAllSalarySortPageable========", data);
                        this.employeeAttendance = response.data;
                        this.officeUsersSalary = data.officeUsers;

                        // console.log("=====OfficeUsersSalary========", this.officeUsersSalary);
                        // console.log("=====EmployeeAttendance========", this.employeeAttendance);

                        // for (const user in this.employeeAttendance) {
                        //     // console.log("this.employeeAttendance[user]",this.employeeAttendance[user]);
                        //     for (var i = 0; i < this.employeeAttendance[user].length; i++) {
                        //         // console.log("this.employeeAttendance[user]", this.employeeAttendance[user][i].status);
                        //         const data3Value = this.employeeAttendance[user][i].status;
                        //         // console.log(`${user}'s Data3 value: ${data3Value}`);
                        //         if(this.employeeAttendance[user][i].status == 'PRESENT'){
                        //             const data2Value = this.employeeAttendance[user][i].status.length;
                        //             console.log(`${user}'s Data3 value: ${data2Value}`);
                        //         }
                        //     }
                        //     // const data3Value = this.employeeAttendance[user].status;
                        //     // console.log(`${user}'s Data3 value: ${data3Value}`);
                        // }

                        // const countByUser = {}; // Initialize an object to store the count for each user

                        // for (const user in this.employeeAttendance) {
                        //     countByUser[user] = 0; // Initialize the count for each user

                        //     for (let i = 0; i < this.employeeAttendance[user].length; i++) {
                        //         if (this.employeeAttendance[user][i].status === 'PRESENT') {
                        //             countByUser[user]++; // Increment the count for the current user

                        //             for (var j = 0; j < this.officeUsersSalary.length; j++) {
                        //                 if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[j].employeeId) {
                        //                     var dailySalary = this.officeUsersSalary[j].salaryDetails / 30;
                        //                     var salary = dailySalary * countByUser[user]

                        //                     this.officeUsersSalary[j].push({
                        //                             Salary:salary
                        //                         })
                        //                     // console.log("=====Salary Details=====",this.officeUsersSalary[j].salaryDetails);
                        //                 }
                        //             }
                        //         }
                        //     }
                        //     console.log("=====OfficeUsersSalary=====", this.officeUsersSalary);
                        //     console.log(`${user}'s number of 'PRESENT' status: ${countByUser[user]} ${dailySalary} ${salary}`);

                        //     // const dailySalary = this.officeUsersSalary.salaryDetails / 30;

                        // }

                        const countByUser = {}; // Initialize an object to store the count for each user
                        // const dailyCountByUser = {};
                        const weekendCountByUser = {};
                        const halfCountByUser = {};
                        const absentCountByUser = {};
                        const lateCountByUser = {};
                        const permissionCountByUser = {};
                        for (const user in this.employeeAttendance) {
                            countByUser[user] = 0; // Initialize the count for each user
                            // dailyCountByUser = 0;
                            weekendCountByUser[user] = 0;
                            halfCountByUser[user] = 0;
                            absentCountByUser[user] = 0;
                            lateCountByUser[user] = 0;
                            permissionCountByUser[user] = 0;
                            for (let i = 0; i < this.employeeAttendance[user].length; i++) {

                                // if(this.employeeAttendance[user][i].checkIn == null){
                                //     this.employeeAttendance[user][i].checkIn = "00:00:00";
                                // }
                                // if(this.employeeAttendance[user][i].officeInTime == null ){
                                //     this.employeeAttendance[user][i].officeInTime = "00:00:00";
                                // }
                                // console.log("=====User Checkin Time=====",this.employeeAttendance[user][i].checkIn);
                                // console.log("=====User Office Intime=====",this.employeeAttendance[user][i].officeInTime);
                                // const end = moment(this.employeeAttendance[user][i].checkIn, "HH:mm:ss")
                                // const start = moment(this.employeeAttendance[user][i].officeInTime, "HH:mm:ss")
                                // const dif = moment.duration(end.diff(start));
                                // const formattedDiff = dif.minutes() > 0 ? `${(dif.hours() + dif.minutes() / 60)} hrs` : `${dif.hours()} hrs`

                                // console.log("=====FormattedDiff=====",formattedDiff);

                                if (this.employeeAttendance[user][i].status === 'PRESENT') {
                                    countByUser[user]++; // Increment the count for the current user

                                    for (var j = 0; j < this.officeUsersSalary.length; j++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[j].employeeId) {
                                            var dailySalary = this.officeUsersSalary[j].salaryDetails / 30;
                                            var salary = dailySalary * countByUser[user];
                                            this.officeUsersSalary[j].Salary = salary;
                                            this.officeUsersSalary[j].OnedaySalary = dailySalary
                                            // this.officeUsersSalary[j].Salary = salary+salary1;
                                            // Assign the salary to the specific object in the array

                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].status === 'HALFDAY') {
                                    halfCountByUser[user]++;
                                    for (var k = 0; k < this.officeUsersSalary.length; k++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[k].employeeId) {
                                            var dailySalary1 = this.officeUsersSalary[k].salaryDetails / 30;
                                            var halfDaySalary = dailySalary1 / 2;
                                            var salary1 = halfDaySalary * halfCountByUser[user];
                                            var halfLeave = halfCountByUser[user] / 2;
                                            this.officeUsersSalary[k].HalfLeaveCount = halfLeave;

                                            // console.log("=====HalfDay Salary=====",salary1);
                                            // Assign the salary to the specific object in the array

                                            this.officeUsersSalary[k].HalfSalary = salary1;
                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].status === 'ABSENT' || this.employeeAttendance[user][i].status === 'N/A') {
                                    absentCountByUser[user]++; // Increment the count for the current user

                                    for (var l = 0; l < this.officeUsersSalary.length; l++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[l].employeeId) {
                                            var dailySalary2 = this.officeUsersSalary[l].salaryDetails / 30;
                                            // var salary2 = dailySalary2 * absentCountByUser[user];
                                            var salary2 = dailySalary2 * (absentCountByUser[user] - 1);
                                            this.officeUsersSalary[l].SalaryDeduction = salary2;
                                            var leave = absentCountByUser[user];
                                            this.officeUsersSalary[l].LeaveCount = leave;
                                            // this.officeUsersSalary[j].Salary = salary+salary1;
                                            // Assign the salary to the specific object in the array

                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].inTimeDif > 15 && this.employeeAttendance[user][i].inTimeDif <= 35 || this.employeeAttendance[user][i].outTimeDif > 15 && this.employeeAttendance[user][i].outTimeDif <= 35 && this.employeeAttendance[user][i].status != 'HALFDAY') {
                                    // if (this.employeeAttendance[user][i].late === true) {
                                    lateCountByUser[user]++;

                                    for (var m = 0; m < this.officeUsersSalary.length; m++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[m].employeeId) {
                                            var late = lateCountByUser[user];
                                            this.officeUsersSalary[m].LateCount = late;
                                        }
                                    }

                                }
                                if (this.employeeAttendance[user][i].inTimeDif >= 35 || this.employeeAttendance[user][i].outTimeDif >= 35 && this.employeeAttendance[user][i].status != 'HALFDAY') {
                                    // else if (this.employeeAttendance[user][i].permission === true) {
                                    permissionCountByUser[user]++;

                                    for (var n = 0; n < this.officeUsersSalary.length; n++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[n].employeeId) {
                                            var permission = permissionCountByUser[user];
                                            this.officeUsersSalary[n].PermissionCount = permission;
                                        }
                                    }

                                }
                                if (this.employeeAttendance[user][i].status === 'WEEKEND') {
                                    weekendCountByUser[user]++; // Increment the count for the current user

                                    for (var o = 0; o < this.officeUsersSalary.length; o++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[o].employeeId) {
                                            var dailySalary3 = this.officeUsersSalary[o].salaryDetails / 30;
                                            var salary3 = dailySalary3 * weekendCountByUser[user];
                                            this.officeUsersSalary[o].WeekendSalary = salary3;

                                        }
                                    }
                                }

                                // if (this.employeeAttendance[user][i].late === true) {
                                //         console.log(`Condition met for user ${user}, i ${i}`);

                                //         lateCountByUser[user]++;

                                //         for (var m = 0; m < this.officeUsersSalary.length; m++) {
                                //             if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[m].employeeId) {
                                //                 var late = lateCountByUser[user];
                                //                 this.officeUsersSalary[m].LateCount = late;
                                //                 console.log(`Updated late count for user ${user} in officeUsersSalary[${m}]`);
                                //             }
                                //         }
                                //     } else {
                                //         console.log(`Condition not met for user ${user}, i ${i}`);
                                //     }

                                // console.log(this.employeeAttendance[user][i].firstName, "===============Is Late===============", this.employeeAttendance[user][i]);
                                // console.log(this.employeeAttendance[user][i].firstName, "===============Is Late===============", this.employeeAttendance[user][i].inTimeDif > 15 && this.employeeAttendance[user][i].inTimeDif < 30, "=====Count=====", lateCountByUser[user]);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Time Difference===============",this.employeeAttendance[user][i].inTimeDif);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Is Late===============",this.employeeAttendance[user][i].late);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Is Permission===============",this.employeeAttendance[user][i].permission);

                            }
                            // console.log("PRESENT COUNT", `${user}'s number of 'PRESENT' status: ${countByUser[user]}`)
                            // console.log("HALFDAY COUNT", `${user}'s number of 'HALFDAY' status: ${halfCountByUser[user]}`)
                            // console.log("ABSENT COUNT", `${user}'s number of 'ABSENT' status: ${absentCountByUser[user]}`)
                            // console.log("LATE COUNT", `${user}'s number of 'LATE' status: ${lateCountByUser[user]}`)
                            // console.log("PERMISSION COUNT", `${user}'s number of 'PERMISSION' status: ${permissionCountByUser[user]}`)
                            // console.log(`${user}'s number of 'PRESENT' status: ${countByUser[user]}  ${dailySalary} ${salary} `);
                            // console.log(`${user}'s number of 'HALFDAY' status: ${halfCountByUser[user]} ${dailySalary} ${salary1}`);
                            // console.log(`${user}'s number of 'ABSENT' status: ${absentCountByUser[user]} ${dailySalary} ${salary1}`);
                            // console.log(`${user}'s number of 'LATE' status: ${lateCountByUser[user]}`);
                            // console.log(`${user}'s number of 'PERMISSION' status: ${permissionCountByUser[user]}`);
                        }

                        // console.log("=====OfficeUsersSalary=====", this.officeUsersSalary);

                        // for(var i=0;i<data.officeUsers.length;i++){
                        //     console.log("==============called=================",this.officeUsersSalary[i])
                        //     for(var j=0; j< this.officeUsersSalary.length;j++){

                        //         console.log("============== J called=================",response.data[i][j])
                        //         if(data.officeUsers[i].employeeId == response.data[i][j].employeeId){
                        //             console.log("SALARY===========",data.officeUsers[i].salaryDetails)
                        //         }
                        //     }
                        // }

                        if (data)
                            // let empData={
                            //     salary
                            // }

                            this.loading = false;
                        // for (const obj of response.data) {
                        //     console.log(obj);
                        //     // this.employeeAttendance.push(obj)
                        // }
                        // this.employeeAttendanceName.splice(0)
                        // for (var i = 0; i < response.data.length; i++) {
                        //     this.employeeAttendanceName.push(response.data[i].firstName);
                        //     console.log("employeeAttendanceName===== ", response.data[i].firstName);
                        // }
                        // // console.log("employeeAttendanceName===== ", this.employeeAttendanceName);
                        // console.log("employeeAttendance===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        async getAllOfficeSalarySortPageable(currentPage, pageCount, data) {
            // console.log("currentPage=======", currentPage);
            // console.log("pageCount=======", pageCount);
            console.log("=====data=====", data);
            await this.getOfficeAllUsers(localStorage.getItem('tenant-uuid'))
                .then(res => {
                    console.log("res.data-0-0-0-0-", res.data);
                    data["officeUsers"] = res.data
                })
            console.log("=====data=====", data);
            this.generateDateRange();
            return new Promise((resolve, reject) => {
                // AttendanceEmployeeService.getAllSortAttendancePageable(currentPage, pageCount, this.data)
                // axios.post("/check/all/sort/" + (currentPage-1) + "/" + pageCount, data)
                axios.post("/check/allOffice/user/Checkins/sort", data)
                    .then((response) => {
                        // console.log("=====getAllOfficeSalarySortPageable========", response.data);
                        this.employeeAttendance = response.data;
                        this.officeUsersSalary = data.officeUsers;

                        const countByUser = {}; // Initialize an object to store the count for each user
                        // const dailyCountByUser = {};
                        const weekendCountByUser = {};
                        const halfCountByUser = {};
                        const absentCountByUser = {};
                        const lateCountByUser = {};
                        const permissionCountByUser = {};
                        for (const user in this.employeeAttendance) {
                            countByUser[user] = 0; // Initialize the count for each user
                            // dailyCountByUser = 0;
                            weekendCountByUser[user] = 0;
                            halfCountByUser[user] = 0;
                            absentCountByUser[user] = 0;
                            lateCountByUser[user] = 0;
                            permissionCountByUser[user] = 0;
                            for (let i = 0; i < this.employeeAttendance[user].length; i++) {

                                // if(this.employeeAttendance[user][i].checkIn == null){
                                //     this.employeeAttendance[user][i].checkIn = "00:00:00";
                                // }
                                // if(this.employeeAttendance[user][i].officeInTime == null ){
                                //     this.employeeAttendance[user][i].officeInTime = "00:00:00";
                                // }
                                // console.log("=====User Checkin Time=====",this.employeeAttendance[user][i].checkIn);
                                // console.log("=====User Office Intime=====",this.employeeAttendance[user][i].officeInTime);
                                // const end = moment(this.employeeAttendance[user][i].checkIn, "HH:mm:ss")
                                // const start = moment(this.employeeAttendance[user][i].officeInTime, "HH:mm:ss")
                                // const dif = moment.duration(end.diff(start));
                                // const formattedDiff = dif.minutes() > 0 ? `${(dif.hours() + dif.minutes() / 60)} hrs` : `${dif.hours()} hrs`

                                // console.log("=====FormattedDiff=====",formattedDiff);

                                if (this.employeeAttendance[user][i].status === 'PRESENT') {
                                    countByUser[user]++; // Increment the count for the current user

                                    for (var j = 0; j < this.officeUsersSalary.length; j++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[j].employeeId) {
                                            var dailySalary = this.officeUsersSalary[j].salaryDetails / 30;
                                            var salary = dailySalary * countByUser[user];
                                            this.officeUsersSalary[j].Salary = salary;
                                            this.officeUsersSalary[j].OnedaySalary = dailySalary
                                            // this.officeUsersSalary[j].Salary = salary+salary1;
                                            // Assign the salary to the specific object in the array

                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].status === 'HALFDAY') {
                                    halfCountByUser[user]++;
                                    for (var k = 0; k < this.officeUsersSalary.length; k++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[k].employeeId) {
                                            var dailySalary1 = this.officeUsersSalary[k].salaryDetails / 30;
                                            var halfDaySalary = dailySalary1 / 2;
                                            var salary1 = halfDaySalary * halfCountByUser[user];
                                            var halfLeave = halfCountByUser[user] / 2;
                                            this.officeUsersSalary[k].HalfLeaveCount = halfLeave;

                                            // console.log("=====HalfDay Salary=====",salary1);
                                            // Assign the salary to the specific object in the array

                                            this.officeUsersSalary[k].HalfSalary = salary1;
                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].status === 'ABSENT' || this.employeeAttendance[user][i].status === 'N/A') {
                                    absentCountByUser[user]++; // Increment the count for the current user

                                    for (var l = 0; l < this.officeUsersSalary.length; l++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[l].employeeId) {
                                            var dailySalary2 = this.officeUsersSalary[l].salaryDetails / 30;
                                            // var salary2 = dailySalary2 * absentCountByUser[user];
                                            var salary2 = dailySalary2 * (absentCountByUser[user] - 1);
                                            this.officeUsersSalary[l].SalaryDeduction = salary2;
                                            var leave = absentCountByUser[user];
                                            this.officeUsersSalary[l].LeaveCount = leave;
                                            // this.officeUsersSalary[j].Salary = salary+salary1;
                                            // Assign the salary to the specific object in the array

                                        }
                                    }
                                }
                                if (this.employeeAttendance[user][i].inTimeDif > 15 && this.employeeAttendance[user][i].inTimeDif <= 35 || this.employeeAttendance[user][i].outTimeDif > 15 && this.employeeAttendance[user][i].outTimeDif <= 35 && this.employeeAttendance[user][i].status != 'HALFDAY') {
                                    // if (this.employeeAttendance[user][i].late === true) {
                                    lateCountByUser[user]++;

                                    for (var m = 0; m < this.officeUsersSalary.length; m++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[m].employeeId) {
                                            var late = lateCountByUser[user];
                                            this.officeUsersSalary[m].LateCount = late;
                                        }
                                    }

                                }
                                if (this.employeeAttendance[user][i].inTimeDif >= 35 || this.employeeAttendance[user][i].outTimeDif >= 35 && this.employeeAttendance[user][i].status != 'HALFDAY') {
                                    // else if (this.employeeAttendance[user][i].permission === true) {
                                    permissionCountByUser[user]++;

                                    for (var n = 0; n < this.officeUsersSalary.length; n++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[n].employeeId) {
                                            var permission = permissionCountByUser[user];
                                            this.officeUsersSalary[n].PermissionCount = permission;
                                        }
                                    }

                                }
                                if (this.employeeAttendance[user][i].status === 'WEEKEND') {
                                    weekendCountByUser[user]++; // Increment the count for the current user

                                    for (var o = 0; o < this.officeUsersSalary.length; o++) {
                                        if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[o].employeeId) {
                                            var dailySalary3 = this.officeUsersSalary[o].salaryDetails / 30;
                                            var salary3 = dailySalary3 * weekendCountByUser[user];
                                            this.officeUsersSalary[o].WeekendSalary = salary3;

                                        }
                                    }
                                }

                                // if (this.employeeAttendance[user][i].late === true) {
                                //         console.log(`Condition met for user ${user}, i ${i}`);

                                //         lateCountByUser[user]++;

                                //         for (var m = 0; m < this.officeUsersSalary.length; m++) {
                                //             if (this.employeeAttendance[user][i].employeeid == this.officeUsersSalary[m].employeeId) {
                                //                 var late = lateCountByUser[user];
                                //                 this.officeUsersSalary[m].LateCount = late;
                                //                 console.log(`Updated late count for user ${user} in officeUsersSalary[${m}]`);
                                //             }
                                //         }
                                //     } else {
                                //         console.log(`Condition not met for user ${user}, i ${i}`);
                                //     }

                                // console.log(this.employeeAttendance[user][i].firstName, "===============Is Late===============", this.employeeAttendance[user][i]);
                                // console.log(this.employeeAttendance[user][i].firstName, "===============Is Late===============", this.employeeAttendance[user][i].inTimeDif > 15 && this.employeeAttendance[user][i].inTimeDif < 30, "=====Count=====", lateCountByUser[user]);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Time Difference===============",this.employeeAttendance[user][i].inTimeDif);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Is Late===============",this.employeeAttendance[user][i].late);
                                //  console.log(this.employeeAttendance[user][i].firstName,"===============Is Permission===============",this.employeeAttendance[user][i].permission);

                            }
                            // console.log("PRESENT COUNT", `${user}'s number of 'PRESENT' status: ${countByUser[user]}`)
                            // console.log("HALFDAY COUNT", `${user}'s number of 'HALFDAY' status: ${halfCountByUser[user]}`)
                            // console.log("ABSENT COUNT", `${user}'s number of 'ABSENT' status: ${absentCountByUser[user]}`)
                            // console.log("LATE COUNT", `${user}'s number of 'LATE' status: ${lateCountByUser[user]}`)
                            // console.log("PERMISSION COUNT", `${user}'s number of 'PERMISSION' status: ${permissionCountByUser[user]}`)
                            // console.log(`${user}'s number of 'PRESENT' status: ${countByUser[user]}  ${dailySalary} ${salary} `);
                            // console.log(`${user}'s number of 'HALFDAY' status: ${halfCountByUser[user]} ${dailySalary} ${salary1}`);
                            // console.log(`${user}'s number of 'ABSENT' status: ${absentCountByUser[user]} ${dailySalary} ${salary1}`);
                            // console.log(`${user}'s number of 'LATE' status: ${lateCountByUser[user]}`);
                            // console.log(`${user}'s number of 'PERMISSION' status: ${permissionCountByUser[user]}`);
                        }

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getAllSortPageable: function (data) {
        //     // console.log("currentPage=======", currentPage);
        //     // console.log("pageCount=======", pageCount);
        //     console.log("=====data=====", data);
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post("/check/all/sort", data)
        //             .then((response) => {
        //                 this.employeeAttendance = response.data;
        //                 this.employeeAttendanceName.splice(0)

        //                 // for (let i = 0; i < response.data.length; i++) {
        //                 //     let employeeAttendanceName = [];

        //                 //     response.data[i].forEach((e) => {
        //                 //         employeeAttendanceName.push(e[0]);
        //                 //     });

        //                 // }

        //                 // console.log("=====employeeUniqueNames-2=====",this.employeeUniqueNames);

        //                 // this.employeeAttendancebyDate = [];
        //                 // for (let i = 0; i < response.data.length; i += 3) {
        //                 //     const subset = response.data.slice(i, i + 3);
        //                 //     this.employeeAttendancebyDate.push(subset);
        //                 // }

        //                 // console.log(this.employeeAttendancebyDate.length);

        //                 for (var i = 0; i < response.data.length; i++) {
        //                     this.employeeAttendanceName.push(response.data[i].firstName);
        //                     // if (response.data[i] && response.data[i].firstName !== null) {
        //                     //     this.employeeAttendanceName.push(response.data[i].firstName);
        //                     // }
        //                     const uniqueNames = [...new Set(this.employeeAttendanceName)];
        //                     // const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry && entry.createdAt ? entry.createdAt.split('T')[0] : null))];
        //                     const uniqueDates = [...new Set(this.employeeAttendance.map((entry) => entry.createdAt.split('T')[0]))];
        //                     this.employeeUniqueNames = uniqueNames;
        //                     this.employeeUniqueDates = uniqueDates;
        //                 }

        //                 console.log("=====employeeUniqueDates=====", this.employeeUniqueDates);
        //                 console.log("=====employeeUniqueNames=====", this.employeeUniqueNames);
        //                 // this.employeeAttendancebyDate = [];
        //                 this.employeeAttendancebyDate.splice(0)
        //                 for (let i = 0; i < response.data.length; i += this.employeeUniqueNames.length) {
        //                     const subset = response.data.slice(i, i + this.employeeUniqueNames.length);
        //                     this.employeeAttendancebyDate.push(subset);
        //                 }

        //                 console.log("=====employeeAttendancebyDate=====", this.employeeAttendancebyDate);
        //                 // console.log("=====employeeUniqueNames-2=====", this.employeeUniqueNames);

        //                 // console.log("employeeAttendanceName===== ", this.employeeAttendanceName);
        //                 // console.log("employeeAttendance===== ", response.data);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        switchAttendancePage: function (currentPage, pageCount) {
            // this.loading = true;
            if (this.paginationAttendance) {
                this.getAllAttendancePageable(currentPage, pageCount);
                this.loading = false;
                console.log("this.paginationAttendance", this.paginationAttendance);
            } else if (this.allSortAttendance) {
                this.getAllSortPageable(currentPage, pageCount, this.data);
                console.log(this.allSortAttendance, "this.allSortAttendance");
                console.log(this.paginationAttendance, "this.paginationAttendance");
            }
        },

        generateQRCode() {
            QRCode.toDataURL(this.qrCodeData, (error, url) => {
                if (error) {
                    console.error(error);
                } else {
                    this.qrCodeImage = url;
                }
            });
        },

        downloadQRCode() {
            const link = document.createElement("a");
            link.href = this.qrCodeImage;
            link.download = "qrcode.png"; // Set the desired file name

            // Trigger the download
            link.click();
        },

        getOfficeDetails() {
            return new Promise((resolve, reject) => {
                OfficeService.OfficeDetail(this.routeData)
                    .then((res) => {
                        // console.log("before asign", this.selectedRow);
                        // console.log("=====Response Data=====", res.data);
                        // this.selectedRow.officeId = res.data.officeId;
                        // this.selectedRow.officeName = res.data.officeName;
                        // this.selectedRow.address = res.data.address;
                        // this.selectedRow.latitude = res.data.latitude;
                        // this.selectedRow.longtitude = res.data.longitude;
                        // this.selectedRow.branch = res.data.branch;
                        this.selectedRow = res.data;

                        // console.log("after asign", this.selectedRow.officeId);
                        // const values = Object.values(this.selectedRow);
                        const values = Object.values(this.selectedRow.officeId);
                        this.qrCodeData = values.join('');
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log("Error Occured", err);
                        reject(err)
                    });
            });
        },

        // exportOfficeUserExcellSheet: function () {
        //     this.loading = true;
        //     // this.data = {
        //     //     officeId: this.officeId,
        //     // };
        //     // var ax = axios.create({
        //     //     baseURL: this.restServerLocation,
        //     // });

        //     // let config = {
        //     //     headers: {
        //     //         "Content-Type": "application/json",
        //     //         'tenant-uuid': '',
        //     //     }
        //     // };
        //     return new Promise((resolve, reject) => {
        //         AttendanceEmployeeService.exportOfficeUserExcellSheet(this.officeId)
        //             // ax
        //             //     .post("/user/report/export/excel/"+ this.officeId,config)
        //             .then((response) => {
        //                 // console.log("exportUserExcelSheet==== ", response.data);
        //                 // var url = response.data;
        //                 this.download(response.data);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });

        // },

        // download(fileName) {
        //     window.open(this.resourceLocation + fileName);
        // },

        viewEmployeeAttendance(item) {
            this.$router.push({
                name: 'EmployeeAttendance',
                params: {
                    data: item.name
                }
            })
        },

        // distanceChecking(lat, long) {
        //     // console.log('_____________lat' + this.selectedRow.latitude  + this.selectedRow.longtitude);
        //     const lat1 = parseFloat(lat);
        //     const lon1 = parseFloat(long);
        //     const lat2 = parseFloat(this.selectedRow.latitude);
        //     const lon2 = parseFloat(this.selectedRow.longtitude);

        //     const R = 6371; // Radius of the Earth in kilometers

        //     const dLat = this.deg2rad(lat2 - lat1);
        //     const dLon = this.deg2rad(lon2 - lon1);

        //     const a =
        //         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        //         Math.cos(this.deg2rad(lat1)) *
        //         Math.cos(this.deg2rad(lat2)) *
        //         Math.sin(dLon / 2) *
        //         Math.sin(dLon / 2);
        //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        //     const distance = R * c;

        //     const value = distance;
        //     const roundedValue = value.toFixed(2); // Round to 2 decimal places

        //     // console.log(roundedValue); // Output: 10.95

        //     // console.log("_____________Differnece" + roundedValue);
        //     return roundedValue;
        // },

        // deg2rad(deg) {
        //     return deg * (Math.PI / .180);
        // },

        formatDistance(value) {

            let roundedValue = Math.round(value * 100) / 100;

            // console.log(roundedValue);

            return roundedValue;
        },

        showFullDetails() {
            this.hideData = true;
        },
        hideFullDetails() {
            this.hideData = false;
        },

        // exportDetails: function () {
        //     // console.log("=====Export Excel Method Calling=====");
        //     this.selectedFromDate = new Date(this.selectedDate);
        //     this.selectedToDate = new Date(this.selectedDate);
        //     this.fromDate = new Date(this.selectedFromDate.setHours(0, 0, 0, 0));
        //     this.toDate = new Date(this.selectedToDate.setHours(23, 59, 59, 0));
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //     );
        //     this.from = this.fromDateFormat;
        //     this.to = this.toDateFormat;
        //     console.log("=====From Date=====", this.from)
        //     console.log("=====To Date=====", this.to)
        //     this.data = {
        //         officeId: this.officeId,
        //         from: this.from,
        //         to: this.to,
        //     }
        //     // console.log("=====Data=====", this.data);
        //     return new Promise((resolve, reject) => {
        //         AttendanceEmployeeService.AllAttendanceExportDetails(this.data)
        //             .then((response) => {
        //                 // console.log("=====AttendanceExportDetails=====",response.data);
        //                 this.download(response.data);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        exportDetails: function (selectedOffice, selectedDates) {
            console.log("=====Export Excel Method Calling=====");
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.attendanceDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedOffice == "Office" || selectedOffice == "AllOffices") {
                    selectedOffice = {
                        officeId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.attendanceDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    // let fromDate = "2021-01-01 00:01";
                    // let toDate = new Date();

                    var currentDate = new Date();
                    let fromDate = new Date(currentDate.setHours(0, 0, 0, 0));
                    let toDate = new Date(currentDate.setHours(23, 59, 59, 0));

                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    officeId: selectedOffice.officeId,
                    from: from,
                    to: to,
                };
                // if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == selectedDates
                // ) {
                //     // console.log("=====From Date=====", this.from)
                //     // console.log("=====To Date=====", this.to)
                //     this.$refs.attendanceDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));

                // }
                // this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                // );
                // this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                // );
                // this.from = this.fromDateFormat;
                // this.to = this.toDateFormat;
                // console.log("=====From Date=====", this.from)
                // console.log("=====To Date=====", this.to)
                // this.data = {
                //     officeId: this.officeId,
                //     from: this.from,
                //     to: this.to,
                // }
                console.log("=====Data=====", this.data);
                return new Promise((resolve, reject) => {
                    AttendanceEmployeeService.AllAttendanceExportDetails(this.data)
                        .then((response) => {
                            // console.log("=====AttendanceExportDetails=====",response.data);
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },

        getDateRangeReset: function () {
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        getAllOfficeDetails() {
            OfficeService.GetOfficeDetail()
                .then((res) => {
                    this.offices = res.data;
                    // console.log("=====Offices=====", this.offices);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        employeeUpdateModalShow(employee) {
            this.updateUser = {
                employeeId: employee.employeeId,
                firstName: employee.firstName,
                lastName: employee.lastName,
                checkinTime: employee.checkinTime,
                checkoutTime: employee.checkoutTime,
            }
            console.log("=====Employee=====", employee);
            this.$refs.employeeUpdateModal.show();
        },

        userDetailUpdate: function () {
            this.$v.updateUser.$touch();
            if (this.$v.updateUser.$invalid) {
                this.submitStatus = "FAIL";
            }
            // else if (this.updateNumber != this.update.contact) {
            //     // //console.log("Not same")
            //     this.$swal("Mobile number is not same");
            // }
            else {
                return new Promise((resolve, reject) => {
                    console.log("=====EmployeeId=====", this.updateUser.employeeId, );
                    AttendanceEmployeeService.updateEmployee(this.updateUser.employeeId, this.updateUser)
                        .then((response) => {
                            this.$swal(response.data);
                            this.$refs.employeeUpdateModal.hide();
                            this.getCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },

        // userDetailUpdate: function () {
        //     // console.log("==this.user===", this.updateUser);
        //     if (!this.$v.updateUser.$invalid) {
        //         return new Promise((resolve, reject) => {
        //             Account.nameUpdate(this.updateUser)
        //                 .then((response) => {
        //                     // console.log("====userDetailUpdate----", response.data);
        //                     this.userDetailUpdateRest();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     this.disabledButton = false;
        //                     reject(err);
        //                 });
        //         });
        //     }
        //     this.disabledButton = false;
        // },

        disableEmployee: function (employee) {
            // console.log("=====Employee=====",employee);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.employeeDisabled(employee.employeeId)
                    .then((response) => {
                        this.$swal(response.data);
                        this.disableUser(employee);
                        this.getCount();
                        this.getDisabledCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        enableEmployee: function (employee) {
            // console.log("=====Employee=====",employee);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.employeeEnabled(employee.employeeId)
                    .then((response) => {
                        this.$swal(response.data);
                        this.enableUser(employee);
                        this.getCount();
                        this.getDisabledCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        disableUser: function (employee) {
            // console.log("=====Employee=====",employee);
            return new Promise((resolve, reject) => {
                Account.employeeDeactivate(employee)
                    .then((response) => {
                        this.$swal(response.data);
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        enableUser: function (employee) {
            // console.log("=====Employee=====",employee);
            return new Promise((resolve, reject) => {
                Account.employeeActivate(employee)
                    .then((response) => {
                        this.$swal(response.data);
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getUserDetails: function (employee) {
            // console.log("=====Employee=====",employee);
            return new Promise((resolve, reject) => {
                Account.getUserData(employee)
                    .then((response) => {
                        this.userDetails = response.data
                        this.sendPasswordToUser(this.userDetails)
                        // console.log("=====UserDetails=====",this.userDetails);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        sendPasswordToUser(user) {
            // console.log("=====User=====", user);
            // console.log("=========Employee Password Mail Sent=========");
            this.updateUserPassword = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                contact: user.email[0],
                mobile: user.mobile[0],
            }
            // console.log("=====UpdateUserPassword=====", this.updateUserPassword);
            return new Promise((resolve, reject) => {
                Account.employeePasswordSend(this.updateUserPassword)
                    .then((response) => {
                        this.$swal({
                            title: "Password Send",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        // console.log("=====Employe Password Sent SuccessFully");
                        resolve(response);
                        // console.log("======UpdatePasswordSend Response======",response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        async getTeamAndOffice(employee) {
            this.selectedEmployee = employee
            // console.log("=====Employee=====",this.selectedEmployee );
            this.disabledButton = false;
            await this.getCurrentTeam(localStorage.getItem("tenant-uuid"));
            this.selectedTeam = this.currentTeam
            this.selectedToOffice = this.selectedRow;
            await this.getAllOffice(this.currentTeam.teamId);
        },

        getCurrentTeam: function (teamId) {
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(teamId)
                    .then((response) => {
                        this.currentTeam = response.data;
                        // console.log("=====CurrentTeam=====", this.currentTeam);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllTeams: function () {
            return new Promise((resolve, reject) => {
                TeamService.getAllTeams()
                    .then((response) => {
                        this.allTeams = response.data;
                        // console.log("=====AllTeams=====", this.allTeams);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllOffice: function () {
            // console.log("=====SelectedTeam=====", this.selectedTeam.teamId);
            return new Promise((resolve, reject) => {
                OfficeService.getAllOffice(this.selectedTeam.teamId)
                    .then((response) => {
                        this.allOffice = response.data;
                        // console.log("=====AllOffice=====", this.allOffice);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        officeMigrate: function () {
            if (this.selectedTeam == this.currentTeam && this.selectedToOffice == this.selectedRow) {
                // console.log(this.selectedTeam.name,this.currentTeam.name,"Team====if====");
                // console.log(this.selectedToOffice.officeName,this.selectedRow.officeName,"Office====if====");
                this.$swal({
                    title: "Please Change Value",
                    icon: "warning",
                    button: "ok",
                });
                this.disabledButton = false;
            } else if (this.selectedTeam != this.currentTeam && this.selectedToOffice == this.selectedRow) {
                // console.log(this.selectedTeam.name,this.currentTeam.name,"Team====elseif====");
                // console.log(this.selectedToOffice.officeName,this.selectedRow.officeName,"Office====elseif====");
                this.$swal({
                    title: "Please Select Value",
                    icon: "warning",
                    button: "ok",
                });
                this.disabledButton = false;

            } else {
                this.data = {
                    tenantId: this.selectedTeam.teamId,
                    officeId: this.selectedToOffice.officeId,
                }
                // console.log("=====SelectedEmployee=====", this.selectedEmployee);
                // console.log("=====SelectedTeam=====", this.selectedTeam.teamId);
                // console.log("=====SelectedToOffice=====", this.selectedToOffice.officeId);
                return new Promise((resolve, reject) => {
                    AttendanceEmployeeService.employeeOfficeMigration(this.selectedEmployee.employeeId, this.data)
                        .then((response) => {
                            this.$refs.officeMigrationModal.hide();
                            this.$swal(response.data);
                            this.teamMigrate();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },

        teamMigrate: function () {
            this.data = {
                teamId: this.selectedTeam.teamId,
            }
            // console.log("=====SelectedEmployee=====", this.selectedEmployee);
            // console.log("=====SelectedTeam=====", this.selectedTeam.teamId);
            // console.log("=====SelectedToOffice=====", this.selectedToOffice.officeId);
            return new Promise((resolve, reject) => {
                Account.UserTeamMigration(this.selectedEmployee.userId, this.data)
                    .then((response) => {
                        // this.$refs.officeMigrationModal.hide();
                        // this.$swal(response.data);
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        resetMigration() {
            this.$refs.officeMigrationModal.hide();
            this.disabledButton = false;
            // this.selectedTeam = null;
            // this.selectedToOffice = null;
        },

        getEmployeeDetails(employeeid) {
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getEmployeeDetails(employeeid)
                    .then((response) => {
                        console.log("=====Response=====", response.data.salaryDetails);
                        return response.data.salaryDetails;
                        // resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // ===========================METHODS BELOW IN THE CODE THOSE ARE REQUEST TAB METHODS====================

        getRequestCount() {
            this.loading = true;
            this.currentPage = 1;
            console.log("________OFFICE ID________" + this.officeId);
            LeaveRequestService.getRequestCount(this.officeId)
                .then((response) => {
                    this.requsetTotalCount = response.data;
                    // console.log("=====Total Count=====", response.data)
                    this.getAllRequestPage(this.currentPage, this.pageCount);
                })
                .catch((err) => {
                    this.loading = false;
                    console.log("________ERROR_IN_getRequestCount________" + err);
                });

        },
        getAllRequestPage: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                LeaveRequestService.GetAllPageable(currentPage, pageCount, this.officeId)
                    .then((response) => {
                        this.requestDetails = response.data;
                        this.loading = false;
                        // console.log("=====Offices=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        calculateDailySalary(salaryDetails) {
            // console.log("=====Status=====",status);
            // if(status=='PRESENT'){
            //     console.log("=====Status=====",status=='PRESENT'.length);
            // }
            const dailySalary = salaryDetails / 30;
            return dailySalary;
        },

        exportSalaryExcelSheet() {
            // Get a reference to the HTML table
            var tableElement = document.getElementById('tableSalaryContent');

            // Convert the HTML table to an Excel worksheet
            var worksheet = XLSX.utils.table_to_sheet(tableElement);

            // Define the cell style for center alignment (change as needed)
            var cellStyle = {
                alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                },
            };

            // Apply the style to the cells in the worksheet
            var range = XLSX.utils.decode_range(worksheet['!ref']);
            for (var R = range.s.r; R <= range.e.r; ++R) {
                for (var C = range.s.c; C <= range.e.c; ++C) {
                    var cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                    if (!worksheet[cellRef]) continue;

                    worksheet[cellRef].s = cellStyle;
                }
            }

            // Create a new workbook and add the worksheet
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Convert the workbook to an Excel binary buffer
            var excelBuffer = XLSX.write(workbook, { type: 'array' });

            // Create a Blob from the buffer
            var blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a URL from the Blob
            var url = URL.createObjectURL(blob);

            // Create a temporary link and trigger the download
            var a = document.createElement('a');
            a.href = url;
            let fromDate = this.convertDateFormat(this.data.from.slice(0, 10));
            let toDate = this.convertDateFormat(this.data.to.slice(0, 10));
            console.log("=---fromDate--=", fromDate);
            console.log("=---toDate--=", toDate);
            a.download = fromDate + '-to-' + toDate + '-checkin-report.xlsx'; // Set the filename for the download
            a.click();

            // Clean up the URL and the temporary link
            URL.revokeObjectURL(url);
        },

        formatCurrency(value) {
            if (typeof value === 'number') {
                if (Number.isInteger(value)) {
                    return value.toString(); // Return as a string without decimal places
                } else {
                    return value.toFixed(2); // Format with 2 decimal places
                }
            } else {
                return ''; // Handle undefined or unexpected values
            }
        },

        switchRequestPage(currentPage, pageCount) {
            this.loading = true;
            if (this.paginationOffice) {
                this.getAllRequestPage(currentPage, pageCount);
                // this.loading = false;
                // console.log("this.paginationOffice", this.paginationOffice);
            }
        },
        rejectRequest() {
            console.log("___requestId__" + this.requestId + typeof this.rejectReason);
            LeaveRequestService.DeclineLeaveRequest(this.requestId, this.rejectReason)
                .then((response) => {
                    // console.log("=====Offices=====", response.data)
                    console.log(response);
                    this.getRequestCount();
                    this.rejectModal = false;
                })
                .catch((err) => {
                    console.log("______ERROR__IN rejectRequest___________" + err);
                });
        },
        approvedRequest() {
            console.log("__________selectedRow_________" + typeof this.selectedRequestRow.approvedDateFrom);
            LeaveRequestService.ApprovedRequest(this.selectedRequestRow)
                .then((response) => {
                    console.log(response);
                    this.responseModel = false;
                    this.getRequestCount();
                })
                .catch((err) => {
                    console.log("------ERROR approvedRequest----------" + err);
                })
        },
        // ===========THOSE METHODS ARE UI FOR TABLE OF REQUEST ============
        showViewModel(dataObj) {
            this.selectedRequestRow = dataObj;
            this.viewRequestModel = true;
        },
        showResponseModal(dataObj) {
            this.responseModel = true;
            this.selectedRequestRow = dataObj;
            this.selectedRequestRow.approvedDateFrom = this.selectedRequestRow.leaveFrom;
            this.selectedRequestRow.approvedDateTo = this.selectedRequestRow.leaveTo;
            // alert(JSON.stringify(this.selectedRequestRow));

        },
        showRejectModal(id) {
            this.requestId = id;
            this.rejectModal = true;
            this.viewRequestModel = false;
        }
    },
}
</script>

<style scoped>
.scroll-table-container {
    max-height: 500px;
    overflow-y: auto;
}

th,
td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table tr,
td {
    text-align: center !important;
}

::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 180px;
}

.red-text {
    color: red;
}

.green-text {
    color: green;
}

.default-text {
    color: black;
}

.invalid-time {
    color: red;
}

table {
    border-collapse: collapse;
}

th,
td {
    border: 1px solid black;
    padding: 8px;
}

.table-container {
    overflow: hidden;
}

#column-size {
    width: 250px;
}

.align-middle tr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
