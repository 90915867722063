<template>
<div>
    <!-- Loader started -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- Loader Ended -->

    <!-- App Release Table -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" @click="resetAppRelease(),openAppReleaseModal()">Add New</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class=" searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a version" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage()"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1 ">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage()" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color:#0974ff;cursor:pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <!-- Table UI Start -->
            <b-card class="card mt-1">
                <p>App Release</p>
                <hr>
                <b-table responsive hover :items="appReleases" :fields="fields" :current-page="currentPage" :per-page="appReleases.pageCount" ref="table">
                    <template v-slot:cell(appName)="data">
                        <span class="title text-primary"> {{ data.item.appName }}</span>
                    </template>
                    <template v-slot:cell(version)="data">
                        <span class="title"> {{ data.item.version }}</span>
                    </template>
                    <template v-slot:cell(createdAt)="data">
                        <div class="cellSize">
                            {{ dateFormatChange(data.item.createdAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(releaseDate)="data">
                        <div class="cellSize">
                            {{ dateFormatChange(data.item.releaseDate, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Edit" @click="updateModalShow(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button>
                        <b-button variant="danger" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'danger' }" title="Delete" @click="deleteAppRelease(data.item)">
                            <font-awesome-icon icon="trash" />
                        </b-button>
                    </template>
                </b-table>
            </b-card>
        </b-container>
        <!-- Table UI End -->

        <!-- Create Modal Start -->
        <b-modal title="App Release" ref="appReleaseModal" no-close-on-backdrop hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>App Name:</label>
                    <b-form-select class="form-control" v-model="appRelease.appName" :class="{'is-invalid': $v.appRelease.appName.$error,'is-valid': !$v.appRelease.appName.$invalid,}">
                        <option value="null" disabled>Select your app</option>
                        <option v-for="item in appNames" :value="item.value" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
                <br />
                <div class="form-group">
                    <label>Version:</label>
                    <b-form-input class="form-control" placeholder="Enter your version number" v-model.trim="$v.appRelease.version.$model" :class="{'is-invalid': $v.appRelease.version.$error,'is-valid': !$v.appRelease.version.$invalid,}">
                    </b-form-input>
                    <!-- <div class="valid-feedback">Team Name is valid!</div> -->
                    <div class="invalid-feedback">
                        <span v-if="!$v.appRelease.version.required">Version number is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Release Date:</label>
                    <div>
                        <b-form-input v-model="appRelease.releaseDate" format="DD-MM-YYYY h:i:s" :class="{'is-invalid': $v.appRelease.releaseDate.$error,'is-valid': !$v.appRelease.releaseDate.$invalid,}" type="datetime-local" min="new Time()"></b-form-input>
						<!-- <p>{{ formattedDateTime }}</p> -->
                        <!-- <div class="valid-feedback">date time is valid!</div> -->
                        <div class="invalid-feedback">
                            <span v-if="!$v.appRelease.releaseDate.required">Invalid date or time!</span>
                        </div>
                    </div>
                </div>
				<br />
				<div class="form-group">
                    <label>Release Notes:</label>
                    <div>
                        <b-form-textarea class="form-control" v-model="appRelease.releaseNotes" :class="{'is-invalid': $v.appRelease.releaseNotes.$error,'is-valid': !$v.appRelease.releaseNotes.$invalid,}" placeholder="Please note your app changes"></b-form-textarea>
                        <!-- <div class="valid-feedback">remark is valid!</div> -->
                        <div class="invalid-feedback">
                            <span v-if="!$v.appRelease.releaseNotes.required">remark is invalid!</span>
                        </div>
                    </div>
				</div>
				<br />
                <div class="text-center">
                    <b-button v-if="createButton == true" variant="outline-success" :disabled="disabled" @click="disabled = true,createAppRelease()">Submit</b-button>
                    <b-button v-else variant="outline-primary" :disabled="disabled" @click="disabled = true,createAppRelease()">Update</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetAppRelease(),$refs.appReleaseModal.hide()">Back</b-button>
                </div>
            </div>
        </b-modal>
        <!-- Create Modal End -->
    </div>
</div>
</template>

<script>
import Account from '../service/Account.js';
import AppReleaseService from '../service/AppReleaseService'
import moment from "moment-timezone";
import {
    required,minLength
} from "vuelidate/lib/validators";
export default {
    name: 'MobileAppRelease',
    data() {
        return {
            createButton: true,
            appNames: [
                {
                    name: 'Telecaller',
                    value: 'telecaller'
                },
                {
                    name: 'Attendance',
                    value: 'attendance'
                },
            ],
            appRelease: {
                version: "",
                appName: null,
                releaseDate: "",
                releaseNotes: ""
            },
            appReleases: [],
            fields: [
                {
                    key: 'appName',
                    label: 'App Name'
                },
                {
                    key: 'version',
                    label: 'App Version'
                },
                {
                    key: 'createdAt',
                    label: 'Created Date'
                },
                {
                    key: 'releaseDate',
                    label: 'Release Date'
                },
                {
                    key: 'actions',
                    label: 'Actions'
                },
            ],
            loading: false,
            // Pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            keyword: "",
            pagination: false,
            searchPagination: false,
            disabled: false
        }
    },
	computed: {
		// formattedDateTime() {
		// 	// Convert the input value to the desired format
		// 	if (this.appRelease.releaseDate) {
		// 		console.log(this.appRelease.releaseDate);
		// 		const [date, time] = this.dateTime.split('T');
		// 		const [hours, minutes] = time.split(':');
		// 		const [formattedHours, period] = hours < 12 ? [hours, 'AM'] : [hours - 12, 'PM'];
		// 		const formattedTime = `${formattedHours}:${minutes} ${period}`;

		// 		return `${date} ${formattedTime}`;
		// 	}
		// 	return '';
		// }
	},
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    this.getCount();
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    validations: {
        appRelease: {
            version: {
                required
            },
            appName: {
                required
            },
            releaseDate: {
                required
            },
            releaseNotes: {
                required,
				minLength: minLength(5),
            }
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        }
    },
    methods: {
        resetAppRelease: function () {
            this.appRelease = {
                version: "",
                appName: null,
                releaseDate: null,
                releaseNotes: ""
            };
            this.$nextTick(() => {
                this.$v.appRelease.$reset();
            });
        },
        createAppRelease: function () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                return new Promise((resolve, reject) => {
                    AppReleaseService.appVersionUnique(this.appRelease)
                        .then((response) => {
                            resolve(response);
                            if(response.data) {
                                return new Promise((resolve, reject) => {
                                    AppReleaseService.createAppRelease(this.appRelease)
                                        .then((response) => {
                                            this.$swal({
                                                title: "Good job!",
                                                text: "App release updated",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.appReleaseModal.hide();
                                            this.resetAppRelease();
                                            this.disabled = false;
                                            this.getCount();
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'error...',
                                    text: 'version number already exist to the app!',
                                })
                                this.disabled = false;
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabled = false;
        },
        openAppReleaseModal: function () {
            this.createButton = true;
            this.$refs.appReleaseModal.show();
        },
        getCount: function () {
            this.loading = true;
            this.pagination = true;
            this.searchPagination = false;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AppReleaseService.getCount()
                    .then((response) => {
                        this.totalCount = response.data
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function () {
            return new Promise((resolve, reject) => {
                AppReleaseService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        this.appReleases = response.data;
                        this.loading = false
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function () {
            this.loading = true;
            if (this.pagination) {
                this.getAllPageable(this.currentPage, this.pageCount);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(this.currentPage, this.pageCount, this.keyword);
            }
        },
        getSearchCount: function(keyword) {
            this.pagination = false;
            this.searchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AppReleaseService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSearchPageable();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function () {
            return new Promise((resolve, reject) => {
                AppReleaseService.getAllSearchPageable(this.currentPage,this.pageCount,this.keyword.toLowerCase())
                    .then((response) => {
                        this.appReleases = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        updateModalShow: function (appRelease) {
            this.createButton = false;
            const formattedDate = moment(appRelease.releaseDate).format("YYYY-MM-DDTHH:mm");
            this.appRelease = appRelease;
            this.appRelease.releaseDate = formattedDate;
            this.$refs.appReleaseModal.show();
        },
        deleteAppRelease: function (appRelease) {
            console.log("deleteAppRelease========appRelease--------", appRelease);
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You want to delete this version release!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Yes, delete!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        return new Promise((resolve, reject) => {
                            AppReleaseService.deleteAppRelease(appRelease)
                                .then((response) => {
                                    this.$swal.fire(
                                        'Deleted!',
                                        'Your version release has been deleted.',
                                        'success'
                                    );
                                    this.getCount();
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                });
        },
        refresh() {
            if(this.keyword != "") {
                this.keyword = "";
            } else {
                this.getCount();
            }
        },
        dateFormatChange(date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09A5FF;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09A5FF;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09A5FF;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09A5FF;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
