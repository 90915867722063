<template>
<div>

    <div align="right">
        <i class="fa fa-window-close" aria-hidden="true" style="color : red;" @click="closeIcon"></i>
    </div>
    <div align="center">
        <img alt="" src="https://encore.examsdaily.in/encore-logo.png" width="50" style="" />
        <h4 style="margin-top: 20px;">Invoice Application</h4>
    </div>
    <div style="margin-left: 20px;">
        <b-form class="pt-3">
            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Name :</label><br />
                <b-form-input disabled class=" ml-5 mt-2 card_size" placeholder=" Full Name" v-model.trim="$v.invoice.userName.$model" :class="{
                    'is-invalid': $v.invoice.userName.$error,
                    'is-valid': !$v.invoice.userName.$invalid,}"></b-form-input>
            </div><br />

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Email :</label><br />
                <b-form-input class="mt-2 card_size " placeholder="Email" v-model.trim="$v.invoice.email.$model" :class="{
                    'is-invalid': $v.invoice.email.$error,
                    'is-valid': !$v.invoice.email.$invalid,}"></b-form-input>

            </div><br />

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Contact :</label><br />
                <b-form-input class=" mt-2 card_size" placeholder="Contact" v-model.trim="$v.invoice.contact.$model" :class="{
                    'is-invalid': $v.invoice.contact.$error,
                    'is-valid': !$v.invoice.contact.$invalid,}"></b-form-input>
            </div>

            <br />

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Whatsapp Number :</label><br />
                <b-form-input class="mt-2 card_size" placeholder="Whatsapp Number" v-model.trim="$v.invoice.whatsappNumber.$model" :class="{
                    'is-invalid': $v.invoice.whatsappNumber.$error,
                    'is-valid': !$v.invoice.whatsappNumber.$invalid,}"></b-form-input>
            </div>
            <div class=" mt-2 card_size">
                <span style="color: red;">*Please Enter Your Whatsapp Number</span>
            </div>
            <br />

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Course Title :</label><br />
                <b-form-input disabled class="mt-2 card_size " placeholder="Course Name" v-model.trim="$v.invoice.courseName.$model" :class="{
                    'is-invalid': $v.invoice.courseName.$error,
                    'is-valid': !$v.invoice.courseName.$invalid,}"></b-form-input>
            </div><br />

            <!-- This course is online check the courseLink for bumblebee activate the course -->
            <div class="form-group" v-if="invoice.courseType == 'Online Course'">
                <label style="float : left; margin-left: 120px;">Course Link:</label><br />
                <b-form-input disabled class="mt-2 card_size " v-model="this.invoicedata.course.courseLink"></b-form-input><br>
                <div v-if="edailyCourse == null || edailyCourse == ''" class="text-center" style="color:#FF0000">
                    **Link is not valid please check the link and create a admission for online course**
                </div>

                <div v-else>
                    <label style="float : left; margin-left: 120px;">Batch:</label><br />
                    <b-form-select class="form-control card_size" :class="{}" v-model="edCourseBatchId">
                        <option value="null" disabled>Select Batch</option>
                        <option v-for="item in this.edailyCourse.batch" :value="item.batchId" v-bind:key="item.batchId">
                            <strong>{{ item.batchName }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div><br>

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Course Fee :</label><br />
                <b-form-input disabled class="mt-2 card_size" id="totalval" placeholder="Course Fee" v-model.trim="$v.invoice.autualPrice.$model" :class="{
                    'is-invalid': $v.invoice.autualPrice.$error,
                    'is-valid': !$v.invoice.autualPrice.$invalid,}"></b-form-input>
            </div><br />
            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Discount amount :</label><br />
                <b-form-input disabled class="mt-2 card_size" id="inideposit" placeholder="Discount amount" v-model.trim="$v.invoice.discountAmount.$model" @input="discountlimit()" :class="{
                    'is-invalid': $v.invoice.discountAmount.$error,
                    'is-valid': !$v.invoice.discountAmount.$invalid,}"></b-form-input>
                <div class=" mt-2 card_size" v-if="$v.invoice.discountAmount.$error">
                    <span style="color: red;">*Maximum Discount Amount exceeds</span>
                </div>
            </div><br />
            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Payment Options:</label><br />
                <b-form-select class="form-control card_size" v-model="$v.invoice.paymentOption.$model" :options="paymentOptions" :class="{'is-invalid': $v.invoice.paymentOption.$error,'is-valid': !$v.invoice.paymentOption.$invalid,}" @change="paymentOpt()"></b-form-select>
            </div><br />
            <div class="form-group" v-if="invoice.paymentOption === 'partPayment'">

                <label style="float : left; margin-left: 120px;">Advance amount :</label><br />
                <b-form-input class="mt-2 card_size1" id="inideposit" placeholder="Advance Amount" v-model="$v.partPayment.advanceAmount.$model" @input="updateDue" :class="{
                    'is-invalid': $v.partPayment.advanceAmount.$error,
                    'is-valid': !$v.partPayment.advanceAmount.$invalid,}"></b-form-input>
                <label style="float : left; margin-left: 120px;">Balance amount :</label><br />
                <b-form-input class="mt-2 card_size1" id="remainingval" disabled placeholder="Balance amount" v-model="$v.partPayment.balanceAmount.$model" :class="{
                    'is-invalid': $v.partPayment.balanceAmount.$error,
                    'is-valid': !$v.partPayment.balanceAmount.$invalid,}"></b-form-input>
                <label style="float : left; margin-left: 120px;">Next Due Date :</label><br />
                <b-form-input class="mt-2 card_size1" format="DD-MM-YYYY h:i:s" v-model="$v.partPayment.dueDate.$model" :class="{
                        'is-invalid': $v.partPayment.dueDate.$error,
                    'is-valid': !$v.partPayment.dueDate.$invalid,}" type="datetime-local" min="new Time()"></b-form-input>
            </div>
            <div class="form-group" v-if="invoice.paymentOption === 'fullPayment'">

                <label style="float : left; margin-left: 120px;">Total amount :</label><br />
                <b-form-input class="mt-2 card_size1" disabled id="inideposit" type="number" placeholder="Total Amount" v-model="$v.fullPayment.totalAmount.$model" :class="{
                        'is-invalid': $v.fullPayment.totalAmount.$error,
                    'is-valid': !$v.fullPayment.totalAmount.$invalid,}"></b-form-input>
            </div>

            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Course Type:</label><br />
                <b-form-input disabled class="mt-2 card_size" placeholder="Course Mode" v-model.trim="$v.invoice.courseType.$model" :class="{
                    'is-invalid': $v.invoice.courseType.$error,
                    'is-valid': !$v.invoice.courseType.$invalid,}"></b-form-input>
            </div><br />
            <div class="form-group">
                <label style="float : left; margin-left: 120px;">organization:</label><br />
                <b-form-input class="mt-2 card_size" placeholder="Course Mode" disabled v-model.trim="$v.invoice.organization.$model" :class="{
                    'is-invalid': $v.invoice.organization.$error,
                    'is-valid': !$v.invoice.organization.$invalid,}"></b-form-input>
            </div><br />
            <div class="form-group">
                <label style="float : left; margin-left: 120px;">Payment Method:</label><br />
                <b-form-select class="form-control card_size" v-model="$v.invoice.paymentMethod.$model" :options="paymentMethods" :class="{'is-invalid': $v.invoice.paymentMethod.$error,'is-valid': !$v.invoice.paymentMethod.$invalid,}"></b-form-select>
            </div><br />
            <b-row>
                <b-col align="center">
                    <b-button variant="outline-primary" :disabled="disabledButton"  @click="disabledButton = true,invoiceAdd()">Submit</b-button>
                </b-col>
            </b-row>

        </b-form>
    </div>
</div>
</template>

<script>
import axios from "axios";
import {
    required,
    minLength,
    maxLength,
    email,
    between

} from "vuelidate/lib/validators";

export default {
    name: "InvoiceApply",
    props: ['invoicedata', 'organization', 'courseType', 'disableButton'],
    data() {
        return {
            edailyCourse: {
                courseId: '',
                courseTitle: '',
                batch: []
            },
            edCourseBatchId: null,
            disabledButton: false,
            invoice: {
                userName: "",
                email: "",
                contact: "",
                alternativeContact: "",
                organization: "",
                courseName: "",
                courseType: "",
                autualPrice: 0,
                tenantId: null,
                discountAmount: null,
                whatsappNumber: '',
                paymentOption: null,
                paymentMethod: null,
                courseId:"",
                courseLink:"",

            },
            modelValue: "",
            // paymentOption: null,
            paymentOptions: [{
                    value: null,
                    text: "Select Payment Option",
                    disabled: true,
                },
                {
                    value: 'fullPayment',
                    text: 'Full Payment',
                },
                {
                    value: 'partPayment',
                    text: 'Part Payment',
                }
            ],
            // paymentMethod: null,
            paymentMethods: [{
                    value: null,
                    text: "Select Payment Method",
                    disabled: true,
                },
                {
                    value: 'link',
                    text: 'Payment Via Link',
                },
                {
                    value: 'card',
                    text: 'Card',
                },
                {
                    value: 'upi',
                    text: 'UPI',
                },
                {
                    value: 'cash',
                    text: 'Cash',
                }
            ],
            partPayment: {
                dueDate: '',
                advanceAmount: null,
                balanceAmount: null,
            },
            fullPayment: {
                totalAmount: null
            },
            amountCalc: null,
        }
    },

    validations: {
        invoice: {
            userName: {
                required,
                minLength: minLength(3),
            },
            email: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },

            },

            courseName: {
                required
            },
            autualPrice: {
                required,
                notZero(value) {
                    return value !== 0;
                }
            },
            courseType: {
                required
            },
            organization: {
                required
            },
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),

            },
            whatsappNumber: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            discountAmount: {
                required,
                // between: between(0, this.invoicedata.branch.discountPrice),
                validDiscount: function (value) {
                    if (value === null || value === undefined) {
                        return true; // Don't validate if value is null or undefined
                    }
                    return value >= 0 && value <= this.invoicedata.branch.discountPrice;
                },
            },
            paymentOption: {
                required
            },
            paymentMethod: {
                required
            }

        },
        partPayment: {
            dueDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            advanceAmount: {
                required
            },
            balanceAmount: {
                required
            }
        },
        fullPayment: {
            totalAmount: {
                required
            },

        },

    },
    computed: {
        discountValidation() {
            return {
                required,
                between: between(0, this.invoicedata.branch.discountPrice),
            };
        },
    },
    mounted() {
        console.log("=======++++",this.invoicedata);
        this.modelValue = this.disableButton
        this.invoice.tenantId = localStorage.getItem('tenant-uuid')
        this.invoice.userName = this.invoicedata.name
        this.invoice.contact = this.invoicedata.contact
        this.invoice.courseName = this.invoicedata.course.name
        this.invoice.courseId = this.invoicedata.course.courseId
        this.invoice.courseLink = this.invoicedata.course.courseLink

        this.invoice.autualPrice = this.invoicedata.course.price
        this.invoice.courseType = this.courseType
        this.invoice.alternativeContact = this.invoicedata.alternativeContact
        this.invoice.organization = this.organization
        this.invoice.discountAmount = this.invoicedata.branch.discountPrice;
        this.fullPayment.totalAmount = this.invoice.autualPrice - this.invoice.discountAmount
        if(this.invoice.courseType == 'Online Course') {
            this.CourseLinkCheckBumblebee();
        }
    },

    methods: {
        CourseLinkCheckBumblebee() {
            var bumblebeeRest = axios.create({
                baseURL: this.$bumblebeeRest,
            });
            let data = {
                coursePackUrl : this.invoice.courseLink
            }
            console.log("bumblebeeRest.defaults.headers",bumblebeeRest.defaults.headers);
            // let common = bumblebeeRest.defaults.headers.common["tenant-uuid"] = null;
            // let { tenant-uuid, ...common } = myObject;
            delete bumblebeeRest.defaults.headers.common["tenant-uuid"]
            delete bumblebeeRest.defaults.headers.common["Authorization"]
            console.log("bumblebeeRest.defaults.headers",bumblebeeRest.defaults.headers);

            return new Promise((resolve, reject) => {
                bumblebeeRest
                    .post("course/getBy/coursePackUrl",data)
                    .then((response) => {
                        console.log("course/get/coursePackUrl===========",response.data);
                        this.edailyCourse = response.data;
                        resolve(response);
                    })
                    .catch((err) => {

                        reject(err);
                    });
            });
        },
        closeModal: function () {
            this.$emit('close-modal');
        },
        closeIcon: function () {
            this.invoice.courseType = null;
            this.$emit('close-icon');
        },
        updateDue() {
            console.log(this.partPayment.advanceAmount, "++++++", this.partPayment.balanceAmount, "++++", this.amountCalc, this.fullPayment.totalAmount);
            const total = parseFloat(this.fullPayment.totalAmount) || 0;
            const val2 = parseFloat(this.partPayment.advanceAmount) || 0;
            this.partPayment.balanceAmount = total - val2;
        },
        // discountlimit() {
        //    console.log(this.invoice.discountAmount+"==>>"+this.invoicedata.branch.discountPrice);
        //    this.fullPayment.totalAmount = this.invoice.autualPrice - this.invoice.discountAmount
        //    console.log(this.fullPayment.totalAmount);
        //    if(this.invoicedata.branch.discountPrice < this.invoice.discountAmount){
        //     console.log("hdfkshkfhksdhfk");
        //     // this.fullPayment.totalAmount = this.invoice.autualPrice - this.invoice.discountAmount
        //    }
        // },
        paymentOpt() {
            if (this.invoice.paymentOption == 'fullPayment') {
                console.log(this.amountCalc, "this.total");
                // this.fullPayment.totalAmount= this.amountCalc
                // this.partPayment.advanceAmount = null,
                // this.partPayment.balanceAmount = null,
                // this.partPayment.dueDate = "",
                // console.log("partPayment",this.partPayment);
                // console.log("fullPayment",this.fullPayment);
            }
            // else if(this.invoice.paymentOption == 'partPayment'){
            //     console.log(this.amountCalc,"this.total");
            //     this.amountCalc = this.fullPayment.totalAmount
            //     // this.fullPayment.totalAmount = null,
            //     console.log(this.amountCalc,"this.total");
            //     console.log("partPayment",this.partPayment);
            //     console.log("fullPayment", this.fullPayment);
            // }
        },
        invoiceAdd: function () {
            console.log("=====InvoiceAdd Function Calling=====")
            // console.log('data======', this.invoice)
            // console.log('partPayment======', this.partPayment)
            console.log('INVOICE======', this.$v.invoice.$invalid)
            console.log('PartPayment======', this.$v.partPayment.$invalid)
            console.log('FullPayment======', this.$v.fullPayment.$invalid)
            console.log("=====Condition=====",this.$v.fullPayment.$invalid || this.$v.partPayment.$invalid || this.$v.invoice.$invalid);
            this.$v.fullPayment.$touch()
            this.$v.partPayment.$touch()
            this.$v.invoice.$touch();
            if (this.$v.fullPayment.$invalid || this.$v.invoice.$invalid) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Kindly please fill all with the valid data...",
                });
                this.disabledButton = false;
            }
            else {
            if(this.invoice.courseType == 'Online Course') {
                if(this.edailyCourse == "" || this.edailyCourse.courseId == ''  ) {
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please check the course Link",
                    });
                    return;
                } else if(this.edCourseBatchId == null) {
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please select the batch for the course",
                    });
                    return;
                }
                this.disabledButton = false;

            }
            if (this.invoice.paymentOption == 'fullPayment') {
                if (!this.$v.invoice.$invalid) {

                    if (!this.$v.fullPayment.$invalid) {
                        this.partPayment.advanceAmount = null,
                            this.partPayment.balanceAmount = null,
                            this.partPayment.dueDate = "";
                        if (this.invoice.paymentMethod != 'link') {
                            this.admissionInsert();
                        } else {
                            this.paymentLinkSend();

                        }

                        console.log('if==paymentOption', this.invoice.paymentOption);
                    }
                }
            } else if (this.invoice.paymentOption == 'partPayment') {

                if (!this.$v.invoice.$invalid) {

                    if (!this.$v.partPayment.$invalid) {
                        this.amountCalc = this.fullPayment.totalAmount;
                        this.fullPayment.totalAmount = null;
                        if (this.invoice.paymentMethod != 'link') {
                            this.admissionInsert();
                        } else {
                            this.paymentLinkSend();
                        }
                        console.log('elseif==paymentOption', this.invoice.paymentOption);
                    }
                }
            }
            }
        },
        admissionInsert: function () {
            console.log("==================method",this.invoice.paymentMethod);
            let activateUserId = null;
            let activateUserName = null;
            let edCourseId = null;
            let edCourseBatchId = null;
            let currentUser = JSON.parse(localStorage.getItem('current_user'))
            if(this.invoice.courseType == 'Online Course') {
                activateUserId = currentUser.id;
                activateUserName = currentUser.username;
                edCourseId = this.edailyCourse.courseId
                edCourseBatchId = this.edCourseBatchId
            }
            this.agentId = localStorage.getItem('agentId')
            let data = {
                enquiryId: this.invoicedata.enquiryId,
                callId: this.invoicedata.callId,
                name: this.invoicedata.name,
                contact: this.invoicedata.contact,
                alternativeContact: this.invoice.alternativeContact,
                email: this.invoice.email,
                age: this.invoicedata.age,
                qualification: this.invoicedata.qualification,
                course: this.invoicedata.course,
                source: this.invoicedata.source,
                mode: this.invoicedata.mode,
                branch: this.invoicedata.branch,
                courseType: this.courseType,
                discountPrice: this.invoice.discountAmount,
                paymentOption: this.invoice.paymentOption,
                paymentMethod: this.invoice.paymentMethod,
                dueDate: this.partPayment.dueDate,
                whatsappNumber: this.invoice.whatsappNumber,
                advanceAmount: this.partPayment.advanceAmount,
                balanceAmount: this.partPayment.balanceAmount,
                totalAmount: this.invoice.autualPrice - this.invoice.discountAmount ||  this.invoice.autualPrice,
                actualPrice :this.invoicedata.totalAmount || this.invoice.autualPrice,
                activateUserId: activateUserId,
                activateUserName: activateUserName,
                edCourseId : edCourseId,
                edCourseBatchId : edCourseBatchId

            };
            console.log(data);
            // if(this.invoice.paymentMethod == "link"){
            //                     this.paymentLinkSend()
            //                 }else{
            //                     this.invoiceCreate();
            //                 }
            return new Promise((resolve, reject) => {
                axios
                    .post("/admission/insert/" + this.agentId, data)
                    .then((response) => {
                        // this.$swal(response.data);
                        this.$swal({
                            title: "Success",
                            text: "Admission successfully",
                            icon: "success",
                            allowOutsideClick: false,
                        }).then(() => {
                            this.closeModal();
                        });
                        console.log(response.data.admissionId, "admissionId");
                        this.invoice.admissionId = response.data.admissionId;
                        if (this.invoice.paymentMethod == "link") {
                            this.paymentLinkSend()
                        } else {
                            this.invoiceCreate();
                        }
                        this.disabledButton = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.disabledButton = false;
                        reject(err);
                    });
            });
        },
        paymentLinkSend() {
            let activateUserId = null;
            let activateUserName = null;
            let edCourseId = null;
            let edCourseBatchId = null;
            let currentUser = JSON.parse(localStorage.getItem('current_user'))
            if(this.invoice.courseType == 'Online Course') {
                activateUserId = currentUser.id;
                activateUserName = currentUser.username;
                edCourseId = this.edailyCourse.courseId
                edCourseBatchId = this.edCourseBatchId
            }

            let data = {
                userName: this.invoicedata.name,
                email: this.invoice.email,
                whatsappNumber: this.invoice.whatsappNumber,
                amount: this.fullPayment.totalAmount !== null ? this.fullPayment.totalAmount : this.partPayment.advanceAmount,
                // admissionId: this.invoice.admissionId,
                courseName: this.invoice.courseName,
                courseId:this.invoice.courseId,
                age : this.invoicedata.age,
                alternativeContact : this.invoicedata.alternativeContact,
                branchId : this.invoicedata.branch.branchId,
                sourceId :this.invoicedata.source.sourceId,
                qualification : this.invoicedata.qualification,
                agentId : localStorage.getItem("agentId"),
                callId : this.invoicedata.callId,
                courseLink:this.invoice.courseLink,
                courseType: this.courseType,
                contact: this.invoicedata.contact,
                actualPrice: this.invoice.autualPrice,
                discountPrice: this.invoice.discountAmount,
                totalAmount: this.fullPayment.totalAmount,
                advanceAmount: this.partPayment.advanceAmount,
                balanceAmount: this.partPayment.balanceAmount,
                paymentOption: this.invoice.paymentOption,
                paymentMethod: this.invoice.paymentMethod,
                tenantId: this.invoice.tenantId,
                dueDate: this.partPayment.dueDate,
                organization: this.invoice.organization,
                activateUserId: activateUserId,
                activateUserName: activateUserName,
                edCourseId : edCourseId,
                edCourseBatchId : edCourseBatchId
            };
            console.log(data, "============");
            // let config = {
            //     headers: {
            //         "Content-Type": "application/json",
            //         'tenant-uuid': "",
            //     }
            // };
            return new Promise((resolve, reject) => {
                axios.post("/razorpay/order", data)
                    .then((response) => {
                        console.log(response.data, "paymnet===");
                        this.$swal({
                            title: "Success",
                            text: "Payment Link Sent successfully",
                            icon: "success",
                            allowOutsideClick: false,
                        })
                        this.closeModal();
                        // .then(() => {
                        //     this.closeModal();
                        // });
                        // const paymentLink =response.data.referenceId;
                        // this.paymentLinkSendApi(paymentLink);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        paymentLinkSendApi(paymentLink) {
            let data = {
                userName: this.invoicedata.name,
                courseName: this.invoice.courseName,
                whatsappNumber: this.invoice.whatsappNumber,
                amount: this.fullPayment.totalAmount !== null ? this.fullPayment.totalAmount : this.partPayment.advanceAmount,
                paymentLink: paymentLink,
                organization: this.invoice.organization,
            };
            return new Promise((resolve, reject) => {
                axios.post("/invoice/paymentLinkSend", data, )
                    .then((response) => {
                        console.log("=======", response);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        invoiceCreate() {
            this.tenantId = localStorage.getItem('tenant-uuid');
            console.log('id==============', this.tenantId)
            console.log("this.invoice", this.invoice);
            let data = {
                userName: this.invoicedata.name,
                contact: this.invoicedata.contact,
                alternativeContact: this.invoice.alternativeContact,
                email: this.invoice.email,
                courseType: this.courseType,
                courseName: this.invoice.courseName,
                actualPrice: this.invoice.autualPrice,
                organization: this.invoice.organization,
                admissionId: this.invoice.admissionId,
                tenantId: this.tenantId,
                courseLink : this.invoice.courseLink,
                discountPrice: this.invoice.discountAmount,
                paymentOption: this.invoice.paymentOption,
                paymentMethod: this.invoice.paymentMethod,
                dueDate: this.partPayment.dueDate,
                whatsappNumber: this.invoice.whatsappNumber,
                advanceAmount: this.partPayment.advanceAmount,
                balanceAmount: this.partPayment.balanceAmount,
                totalAmount:  this.invoice.autualPrice - this.invoice.discountAmount
            };
            console.log(data, "============");
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    'tenant-uuid': this.tenantId,
                }
            };
            return new Promise((resolve, reject) => {
                axios.post("/invoice/registration", data, config)
                    .then((response) => {
                        this.$swal({
                            title: "Success",
                            text: "Invoice Sent successfully",
                            icon: "success",
                            allowOutsideClick: false,
                        })
                        this.closeModal();
                        // .then(() => {
                        //     this.closeModal();
                        // });
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        invoiceInsert: function () {
            // //console.log('data======', this.update)
            // this.tenantId = localStorage.getItem('tenant-uuid');
            // //console.log('id==============', this.tenantId)
            let data = {
                userName: this.invoicedata.name,
                contact: this.invoicedata.contact,
                alternativeContact: this.invoice.alternativeContact,
                email: this.invoice.email,
                courseType: this.courseType,
                courseName: this.invoice.courseName,
                actualPrice: this.invoice.autualPrice,
                organization: this.invoice.organization,
                admissionId: this.invoice.admissionId,
                tenantId: this.tenantId,
                discountPrice: this.invoice.discountAmount,
                paymentOption: this.invoice.paymentOption,
                paymentMethod: this.invoice.paymentMethod,
                dueDate: this.partPayment.dueDate,
                whatsappNumber: this.invoice.whatsappNumber,
                advanceAmount: this.partPayment.advanceAmount,
                balanceAmount: this.partPayment.balanceAmount,
                totalAmount: this.fullPayment.totalAmount
            };
            console.log(data, "============");
            // let config = {
            //     headers: {
            //         "Content-Type": "application/json",
            //         'tenant-uuid': "",
            //     }
            // };
            // return new Promise((resolve, reject) => {
            //     axios.post("/invoice/registration", data, config)
            //         .then((response) => {
            //             resolve(response);
            //         })
            //         .catch((err) => {
            //             reject(err);
            //         });
            // });
        },
        //     paymentMethod: function () {
        //     let data = {
        //         amount: this.fullPayment.totalAmount,
        //         currency: "INR",
        //         accept_partial: true,
        //         first_min_partial_amount: 100,
        //         expire_by: 16910970570,
        //         reference_id: "TS1996",
        //         description: "Payment for policy no #23456",
        //         customer: {
        //             name: this.invoicedata.name,
        //             contact: this.invoice.whatsappNumber,
        //             email: this.invoice.email
        //         },
        //         notify: {
        //             sms: true,
        //             email: true
        //         },
        //         reminder_enable: true,
        //         notes: {
        //             policy_name: "Jeevan Bima"
        //         },
        //         // callback_url: "https://play.google.com/store/search?q=examsdaily+app&c=apps&hl=en-IN",
        //         callback_url: "https://localhost:8080",
        //         callback_method: "get"
        //     }
        //     return new Promise((resolve, reject) => {
        //         console.log("=====Data=====", data);
        //         PaymentService.paymentMethod(data)
        //         // .then((response) => {
        //         //     // this.totalCount = response.data;
        //         //     // console.log("Count", response.data);
        //         //     // this.getAllSearchPageable(
        //         //     //     this.currentPage,
        //         //     //     this.pageCount,
        //         //     //     keyword.toLowerCase()
        //         //     // );
        //         //     this.loading = false;
        //         //     resolve(response);
        //         // })
        //         // .catch((err) => {
        //         //     reject(err);
        //         // });
        //     });
        // },
        newPaymentMethod: function () {
            fetch('https://api.razorpay.com/v1/payment_links/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa('rzp_test_qNYOJqElJUgADt:hUSKg3epNIB3OVOAvStEwtSR'),
                    'Access-Control-Allow-Origin': '*'
                },
                // body: '{\n  "amount": 1000,\n  "currency": "INR",\n  "accept_partial": true,\n  "first_min_partial_amount": 100,\n  "expire_by": 1691097057,\n  "reference_id": "TS1989",\n  "description": "Payment for policy no #23456",\n  "customer": {\n    "name": "Gaurav Kumar",\n    "contact": "+919000090000",\n    "email": "gaurav.kumar@example.com"\n  },\n  "notify": {\n    "sms": true,\n    "email": true\n  },\n  "reminder_enable": true,\n  "notes": {\n    "policy_name": "Jeevan Bima"\n  },\n  "callback_url": "https://example-callback-url.com/",\n  "callback_method": "get"\n}',
                body: JSON.stringify({
                    'amount': this.fullPayment.totalAmount,
                    'currency': 'INR',
                    'accept_partial': true,
                    'first_min_partial_amount': 100,
                    'expire_by': 16910970570,
                    'reference_id': 'TSsd1994',
                    'description': 'Payment for policy no #23456',
                    'customer': {
                        'name': this.invoicedata.name,
                        'contact': this.invoice.whatsappNumber,
                        'email': this.invoice.email
                    },
                    'notify': {
                        'sms': true,
                        'email': true
                    },
                    'reminder_enable': true,
                    'notes': {
                        'policy_name': 'Course Purchase Link'
                    },
                    'callback_url': 'https://examsdaily.in/',
                    'callback_method': 'get'
                })
            });
        },
        // paymentMethod: function(data){
        //     // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        //     const YOUR_KEY_ID = 'rzp_test_qNYOJqElJUgADt';
        //     const YOUR_KEY_SECRET = 'hUSKg3epNIB3OVOAvStEwtSR';
        //     const headers = {
        //         // 'Your-Custom-Header': 'Your-Header-Value',
        //         Authorization: `Basic ${window.btoa(`${YOUR_KEY_ID}:${YOUR_KEY_SECRET}`)}`,
        //         'Access-Control-Allow-Origin': '*',
        //         // 'Content-Type':  'application/json',
        //         // 'Access-Control-Allow-Credentials' : 'true',
        //         // 'Access-Control-Allow-Origin': '*',
        //         // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
        //         // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        //     };
        //     // var ax = axios.create({
        //     //     baseURL: "https://api.razorpay.com",
        //     // });
        //     console.log("=====Headers=====",headers);
        //     return new Promise((resolve, reject) => {
        //         axios({
        //             method: 'post',
        //             url: 'https://api.razorpay.com/v1/payment_links',
        //             data,
        //             headers
        //         })
        //         // ax
        //         //     .post('/v1/payment_links', data, {
        //         //     headers: headers,
        //         //     })
        //         // axios.jsonp('https://api.razorpay.com/v1/payment_links')
        //         .then((response) => {
        //             resolve(response);
        //         }).catch((err) => {
        //             reject(err);
        //         });
        //     });
        // },
    }

}
</script>

<style scoped>
.card_size {
    max-width: 500px;
    margin: auto;
}

.card_size1 {
    max-width: 300px;
    margin: auto;
}

.full_card {
    width: 50vw;
    border-top: 3px solid coral;
}
</style>
