<template>
<div>
    <b-container>
        <!-- pop start for Bookbundle register -->
        <b-modal id="addBookBundle" title="Book Bundle" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>BookBundle Name:</label>
                    <b-form-input class="form-control" placeholder="Enter Bookbundle Name" v-model.trim="$v.bookBundle.name.$model" :class="{
                    'is-invalid': $v.bookBundle.name.$error,
                    'is-valid': !$v.bookBundle.name.$invalid,
                  }">
                    </b-form-input>
                    <div class="valid-feedback">BookBundle Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.bookBundle.name.required">BookBundle Name is required</span>
                    </div>
                </div>
                <br />
                <!-- <div class="form-group">
                        <label>BookBundle Code:</label>
                        <b-form-input class="form-control" v-model.trim="$v.bookBundle.code.$model" :class="{
                    'is-invalid': $v.bookBundle.code.$error,
                    'is-valid': !$v.bookBundle.code.$invalid,
                  }" placeholder="Enter your Code">
                        </b-form-input>
                        <div class="valid-feedback">Your Code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.code.required">Code is required</span>
                        </div>
                    </div> -->
                <br />
                <div class="form-group">
                    <label>Description:</label>
                    <b-form-input class="form-control" v-model.trim="$v.bookBundle.description.$model" :class="{
                    'is-invalid': $v.bookBundle.description.$error,
                    'is-valid': !$v.bookBundle.description.$invalid,
                  }" placeholder="Enter description">
                    </b-form-input>
                    <div class="valid-feedback">Your description is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.bookBundle.description.required">description is required</span>
                    </div>
                </div>
                <br />
                <!-- <div class="form-group">
                    <label>Books:</label>
                    <div style="height: 100px;overflow-y: scroll;padding: 10px;background-color: rgb(225, 225, 225);">
                        <b-form-checkbox-group v-model="bookBundle.books" :options="books" class="mb-3" value-field="item" text-field="name"></b-form-checkbox-group>
                    </div>

                    <div class="valid-feedback">Your description is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.bookBundle.books.required">description is required</span>
                    </div>
                </div>
                <br /> -->

                <div class="form-group">
                    <label>BookBundle Price:</label>{{ bookBundle.price }}
                    <b-form-input class="form-control" type="number" v-model.trim="$v.bookBundle.price.$model" :class="{'is-invalid': $v.bookBundle.price.$error,'is-valid': !$v.bookBundle.price.$invalid,}" placeholder="Enter your BookBundle price">
                    </b-form-input>
                    <div class="valid-feedback">Your BookBundle Price is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.bookBundle.price.required">BookBundle Price is required</span>
                    </div>
                </div>
                <br />

                <div class="text-center">
                    <b-button variant="outline-success" type="submit" :disabled="disabledButton" @click="disabledButton = true,saveBookBundle()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!-- popup end for Course Register -->
    </b-container>
    <!-- Loader started -->
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
    <!-- Loader Ended -->
    <!-- Course Table -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!--add branch button start-->
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBookBundle @click="resetForm()">Add Book</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class=" searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1 ">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color:#0974ff;cursor:pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <p>Book Bundle</p>
                <hr>
                <b-table responsive hover :items="allBookBundle" :fields="fields" :current-page="allBookBundle.currentPage" :per-page="pageCount" ref="table">
                    <template v-slot:cell(name)="data">
                        {{data.item.name}}
                    </template>
                    <!-- <template v-slot:cell(code)="data">
                        {{data.item.bookId}}
                    </template> -->
                    <template v-slot:cell(desc)="data">
                        {{data.item.description}}
                    </template>
                    <template v-slot:cell(price)="data">
                        {{data.item.price}}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View BookBundle Details" v-b-modal.viewcourse @click="getBundle(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button>
                        <!-- v-b-modal.updatecourse @click="getBundle(data.item)" -->
                        <!-- <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Course Details">
                            <font-awesome-icon icon="edit" />
                        </b-button> -->
                        <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Course Details" v-if="data.item.archive==false" variant="success" @click="archive(data.item.bookId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Unarchive Course Details" v-if="data.item.archive==true" variant="warning" @click="unarchive(data.item.bookId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                    </template>

                </b-table>
            </b-card>
        </b-container>
    </div>
    <!-- Course Table End -->
    <!-- View Course Start -->
    <b-container>
        <b-modal id="viewcourse" title="View course" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>BookBundle Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Course Name" v-model=bookBundle.name disabled>
                    </b-form-input>

                </div>
                <br />
                <div class="form-group">
                    <label>BookBundle Code:</label>
                    <b-form-input class="form-control" v-model=bookBundle.bookId disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>BookBundle Description:</label>
                    <b-form-input class="form-control" v-model="bookBundle.description" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>BookBundle Price:</label>
                    <b-form-input class="form-control" v-model="bookBundle.price" disabled>
                    </b-form-input>
                </div>
                <br>
                <div class="form-group">
                    <label>BookBundle Books:</label>
                    <br>
                    <div class="" style="padding-left: 15px;font-weight: bold;" v-for="b in bookBundle.books" :key="b.bookId">
                        {{ b.name }}
                    </div>
                </div>
                <br />
            </div>

        </b-modal>
    </b-container>
    <!-- View Course End -->
    <!-- Update Course Start -->
    <b-container>
        <b-modal id="updatecourse" title="Update Course" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Name:</label>
                    <b-form-input class="form-control" v-model.trim="$v.bookBundle.name.$model" :class="{
                    'is-invalid': $v.bookBundle.name.$error,
                    'is-valid': !$v.bookBundle.name.$invalid,
                  }">
                    </b-form-input>
                </div>
                <!-- <div class="form-group">
                        <label>Code:</label>
                        <b-form-input class="form-control" v-model.trim="$v.course.code.$model" :class="{
                    'is-invalid': $v.course.code.$error,
                    'is-valid': !$v.course.code.$invalid,
                  }">
                        </b-form-input>
                    </div> -->
                <div class="form-group">
                    <label>Description:</label>
                    <b-form-input class="form-control" v-model.trim="$v.bookBundle.description.$model" :class="{'is-invalid': $v.bookBundle.description.$error,'is-valid': !$v.bookBundle.description.$invalid,}">
                    </b-form-input>
                </div>
                <div class="form-group">
                    <label>Course Price:</label>
                    <b-form-input class="form-control" v-model.trim="$v.bookBundle.price.$model" :class="{'is-invalid': $v.bookBundle.price.$error,'is-valid': !$v.bookBundle.price.$invalid,}">
                    </b-form-input>
                </div>
                <div class="text-center pt-3">
                    <b-button size="sm" id="submit" variant="outline-danger" :disabled="disabledButton" @click="disabledButton = true,updateCourse()">Update</b-button><br />
                </div>
            </div>
        </b-modal>
    </b-container>
    <!-- Update Course End -->
</div>
</template>

    
    
<script>
import axios from 'axios';
import {
    required,
    minLength,
} from "vuelidate/lib/validators";
import CourseService from "../service/CourseService";
import BookBundle from "../service/BookBundleService";
import Account from '../service/Account.js';
import BookBundleService from '../service/BookBundleService';
export default {
    name: "Course",
    components: {

    },
    beforeDestroy() {
        axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
    },
    data() {

        return {
            disabledButton: false,
            paginationCourses: false,
            searchPaginationCourses: false,
            //  Loader
            loading: false,
            // Pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            keyword: "",
            //  course  data render in table
            bookBundle: {
                name: "",
                code: "",
                description: "",
                price: null,
                books: [{
                        bookId: "1",
                        name: "Tamil",
                        dispatch: false
                    },
                    {
                        bookId: "2",
                        name: "English",
                        dispatch: false
                    },
                    {
                        bookId: "3",
                        name: "Maths",
                        dispatch: false
                    }
                ]
                // dprice: null,
            },
            allBookBundle: [],
            books: [

            ],
            // books:[
            //     {
            //         bookId:"1",
            //         name:"Tamil",
            //         dispatch:false
            //     },
            //     {
            //         bookId:"2",
            //         name:"English",
            //         dispatch:false
            //     },
            //     {
            //         bookId:"3",
            //         name:"Maths",
            //         dispatch:false
            //     },
            //     {
            //         bookId:"4",
            //         name:"GK",
            //         dispatch:false
            //     },
            //     {
            //         bookId:"5",
            //         name:"History",
            //         dispatch:false
            //     },
            //     {
            //         bookId:"6",
            //         name:"Geography",
            //         dispatch:false
            //     }
            // ],
            courses: [],
            fields: [
                // { key:'courseId'},
                {
                    key: 'name',
                    label: 'Name'
                },
                // {
                //     key: 'code',
                //     label: 'Code'
                // },
                {
                    key: 'desc',
                    label: 'Description'
                },
                {
                    key: 'price',
                    label: 'Price'
                },
                // {
                //     key: 'dprice',
                //     label: 'Discount Price'
                // },
                {
                    key: 'actions',
                    label: 'Actions'
                },

            ]
        };
    },
    validations: {
        bookBundle: {
            name: {
                required,
                minLength: minLength(3),
            },
            description: {
                required
            },
            price: {
                required,
            },
            books: {
                required
            }

        },

    },

    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    self.getAllCourses();
                    // self.getAllPageable();
                    self.getCount();
                    // this.loading=true
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
        // this.getAllCourses();
        // Global.refreshToken();
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        }
    },

    methods: {

        // insert method

        saveBookBundle: function () {
            this.$v.$touch();
            console.log(this.$v);
            if (!this.$v.$invalid) {
                this.disabledButton = true;
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    BookBundle.saveBookBundle(this.bookBundle)
                        .then((response) => {
                            this.$swal({
                                title: "Good job!",
                                text: "Book Bundle Added successfully!!!!!",
                                icon: "success",
                                button: "ok",
                            });

                            this.$refs.modal.hide();
                            this.getCount()
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        this.allBookBundle = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        deleteCourse: function (courseId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            CourseService.deleteCourse(courseId)
                                .then((response) => {
                                    this.$swal("Deleted!", "Courses has been deleted.", "success");
                                    this.getAllCourses();
                                    this.getCount();
                                    this.getAllPageable(this.currentPage, this.pageCount);
                                    resolve(response);
                                });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBundle: function (b) {
            // console.log("Course : ", course)
            this.bookBundle = b;
        },

        updateCourse: function () {
            this.$v.$touch();
            // if (this.$v.$invalid) {
            //     this.submitstatus = "FAIL";
            // } else {
            if (!this.$v.$invalid) {
                console.log("demo checking");
                this.disabledButton = true;
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    CourseService.updateCourse(this.course)
                        .then((response) => {
                            this.$swal('course details updated successfully!!!!!');
                            this.$refs.modal.hide();
                            // this.getAllCourses();
                            this.getCount()
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },
        archive: function (id) {
            let data={
                bookId:id
            }
            return new Promise((resolve, reject) => {
                BookBundleService.archive(data)
                    .then(response => {
                        this.$swal('Archive successfully!!!!!');
                        this.getAllPageable(this.currentPage, this.pageCount);

                        //  console.log(response.data);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        unarchive: function (id) {
            let data={
                bookId:id
            }
            return new Promise((resolve, reject) => {
                BookBundleService.unArchive(data)
                    .then(response => {
                        this.$swal('UnArchive successfully!!!!!');
                        this.getAllPageable(this.currentPage, this.pageCount);

                        //  console.log(response.data);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.paginationCourses = true;
            this.searchPaginationCourses = false;
            this.currentPage = 1;
            this.keyword = "";
            return new Promise((resolve, reject) => {
                BookBundleService.getCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            // console.log("currentPage",this.currentPage)
            // console.log("pageCount",this.pageCount)
            return new Promise((resolve, reject) => {
                BookBundleService.getAllPageable(currentPage, pageCount)
                    .then((response) => {
                        this.allBookBundle = response.data;
                        // console.log("Response",response.data)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            // this.loading = true
            // this.getAllPageable(pageCount);
            this.loading = true;
            //   console.log("switchPage calling");
            if (this.paginationCourses) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPaginationCourses) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            }
        },

        // searchCourse: function(keyword) {
        //   return new Promise((resolve, reject) => {
        //     CourseService.searchCourse(keyword.toLowerCase())
        //       .then((response) => {
        //         this.courses = response.data;
        //         // this.currentPage = 1

        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getSearchCount: function (keyword) {
            this.paginationCourses = false;
            this.searchPaginationCourses = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                CourseService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                CourseService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        //   this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.courses = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        refresh() {

            this.getCount();
            this.keyword = "";

        },
        resetForm() {
            this.bookBundle.name="";
            this.bookBundle.price="";
            this.bookBundle.description="";
            this.bookBundle.name="";
            this.disabledButton = false;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
};
</script>
    
    
<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09A5FF;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09A5FF;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09A5FF;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09A5FF;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
