<template>
<div>
    <div>
        <!-- <div class="d-flex justify-content-center align-items-center ">
                <b-card style="max-width: 20rem;" class="mb-2">
                    <span style="font-size:1.3em;font-weight:600; color:rgba(242, 6, 6, 0.819);">Your payment failed...!</span>
                    <br>
                    <br>
                    <b-card-text>
                        <span style="color:#9b9999;font-size:.8em;">Dont worry ,Please try again your payment process</span>
                    </b-card-text>

                    <b-button @click="goBack()" variant="primary">Go Back</b-button>
                    <br>
                    <br>
                    <div style="color:rgba(255, 99, 71, 0.7);">
                        <span class="">Note:</span>
                        <p style="border-style:solid; border-width: 2px;">
                            If your product is not unlocked after payment is deducted from your account, please contact us +918101234234 (9AM to 6PM).
                            Or please wait for our automated system to verify your payment and unlock your product within 15 minutes.
                        </p>
                    </div>
                </b-card>
        </div> -->

        <!-- <div class="container mt-5">
            <b-card>
                <h3 class="text-danger">Payment Failed</h3>
                <p>Sorry, your payment could not be processed successfully.</p>
                <p>Error Details:</p>
                <ul>
                    <li>Transaction ID: {{ transactionId }}</li>
                    <li>Error Code: {{ errorCode }}</li>
                    <li>Error Message: {{ errorMessage }}</li>
                </ul>
                <b-button variant="primary" @click="retryPayment">Retry Payment</b-button>
            </b-card>
        </div> -->
        <div class="d-flex justify-content-center align-items-center ">
        <div class="payment-failed">
            <b-container class="text-center">
                <b-row>
                    <b-col md="12">
                        <img class="mt-5" src="Error.gif" height="100" alt="" />
                        <img  class="mt-5" src="Errorword.gif" height="100" alt="" />
                        <h1 class="mb-4 mt-2" id="head">Payment Failed...!!!</h1>
                        <p class="text-danger" id="body">We're sorry, but your payment has failed.</p>
                        <p id="body">Please check your payment information and try again.</p>
                        <b-button pill variant="primary" @click="goBack()">Go Back</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'PaymentFailurePage',
    props: ['payId'],
    components: {

    },
    data: function () {
        return {
      transactionId: "123456789",
      errorCode: "E123",
      errorMessage: "Payment declined due to insufficient funds",

        }
    },
    methods: {
        goBack: function () {
            // this.$router.push("/")
            window.history.back();
        }
    }

}
</script >

<style scoped>
.card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.payment-failed {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#head{
        font-size:2.3em;
        font-weight:600; 
        /* color:rgb(255, 255, 255); */
        color:rgba(242, 6, 6, 0.819);

    }

#body{
        font-size:1.3em;
        font-weight:600; 
        /* color:rgb(255, 255, 255); */

    }
#amountspan{
        font-size:1.1em;
        font-weight:600; 
        color:rgb(255, 255, 255);

    }
@media screen and (max-width: 749.98px) {
    #head{
        font-size:1.3em;
        font-weight:600; 
        /* color:rgb(255, 255, 255); */
        color:rgba(242, 6, 6, 0.819);

    }
    #body{
        font-size:0.8em;
        font-weight:600; 
        /* color:rgb(255, 255, 255); */

    }
    #amountspan{
        font-size:0.9em;
        font-weight:600; 
        color:rgb(255, 255, 255);

    }
}
</style>
