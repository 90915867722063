import axios from 'axios';
export default {

    insertBatch: function (tutor) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/batch/insert", tutor, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateBatch: function (update) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/batch/update", update, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getBatchCount: function () {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/batch/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },


    getAllBatchPageable: function (currentPage, pageCount) {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/batch/all/' + (currentPage - 1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getSearchCount: function (keyword) {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/batch/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },

    getAllSearchPageable: function (currentPage, pageCount, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/batch/search/' + (currentPage - 1) + "/" + pageCount + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getAllBatches: function () {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/batch/getAll',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    archive: function (tutor) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/batch/archive/" + tutor, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function (tutor) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/batch/unarchive/" + tutor, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },

}