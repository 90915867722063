<template>
<div>
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
    <b-tabs pills card content-class="mt-3" justified>
        <b-tab title="USERS" active>
            <b-container>
                <!--popup start for Bulk User Register-->
                <b-modal size="xl" title="Add Bulk User" hide-footer id="addBulkUserModal" ref="addBulkUserModal" no-close-on-backdrop @close="resetValue">
                    <div class="form-row">
                        <div class="form-group">
                            <!-- <b-spinner v-if="loadingData" variant="primary" label="Spinning"></b-spinner> -->
                            <div class="text-center">
                                <b-spinner v-if="loadingData" variant="primary" label="Text Centered"></b-spinner>
                            </div>
                            <b-table v-if="bulkUsersTable" responsive hover :items="bulkUsers" :fields="bulkUsersFields" :current-page="currentPage" :per-page="users.pageCount" ref="table">
                                <template v-slot:cell(isValid)="data">
                                    <b-icon v-if="data.item.isValid ==true" icon="check-circle-fill" variant="success"></b-icon>
                                    <b-icon v-if="data.item.isValid ==false" icon="x-circle-fill" variant="danger"></b-icon>
                                </template>
                                <template v-slot:cell(checkinTime)="data">
                                    {{ timeChange(data.item.checkinTime)}}
                                </template>
                                <template v-slot:cell(checkoutTime)="data">
                                    {{ timeChange(data.item.checkoutTime) }}
                                </template>
                                <template v-slot:cell(reason)="data">
                                    <div class="custom-column">
                                        {{ data.item.reason }}
                                    </div>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-icon v-if="data.item.isValid ==false" icon="trash-fill" variant="danger" @click="deleteEntry(data.index)"></b-icon>
                                </template>
                            </b-table>
                            <div class="text-center">
                                <label> Select Office</label>
                                <b-form-select class="form-control" v-model="office" :class="{'is-invalid': $v.office.$error,'is-valid': !$v.office.$invalid,}">
                                    <option value="null" disabled>Select Office</option>
                                    <option v-for="item in allOffices" :value="item" v-bind:key="item.officeId">
                                        <strong>{{ item.officeName }}</strong>
                                    </option>
                                </b-form-select>
                                <br>
                                <b-button v-if="verifyButton" variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,bulkUserVerify()">Verify</b-button>
                                <b-button v-if="submitButton" variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,bulkUserCreate()">Submit</b-button>
                                <!-- <b-button variant="outline-danger" class="mx-3" @click="resetUser()">Reset</b-button> -->
                            </div>
                        </div>
                    </div>
                </b-modal>
                <!--popup ends for Bulk User Register-->

                <!--popup start for User Register-->
                <b-modal title="Add User" hide-footer id="addUserModal" ref="addUserModal" no-close-on-backdrop>
                    <div class="form-row">
                        <div class="form-group">
                            <label>First Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.user.firstName.$model" :class="{'is-invalid': $v.user.firstName.$error,'is-valid': !$v.user.firstName.$invalid,}" placeholder="Enter your First Name">
                            </b-form-input>
                            <div class="valid-feedback">Your First Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.user.firstName.required">First Name is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Last Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="user.lastName" placeholder="Enter your Last Name">
                            </b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Username:</label>
                            <b-form-input class="form-control" v-model.trim="$v.user.username.$model" :class="{'is-invalid': $v.user.username.$error,'is-valid': !$v.user.username.$invalid,}" placeholder="Enter your username">
                            </b-form-input>
                            <div class="valid-feedback">Your username is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.user.username.required">username is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Mobile:</label>
                            <b-form-input type="number" v-model="user.mobile" placeholder="Enter mobile number"></b-form-input>
                            <!-- <div class="valid-feedback">Mobile number is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.user.mobile.required">Mobile number is required</span>
                    </div> -->
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Mail Id:</label>
                            <b-form-input v-model.trim="$v.user.contact.$model" :class="{'is-invalid': $v.user.contact.$error,'is-valid': !$v.user.contact.$invalid,}" placeholder="Enter your email">
                            </b-form-input>
                            <div class="valid-feedback">Your email is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.user.contact.required">Email is required</span>
                                <span v-if="!$v.user.contact.isUnique">Enter your proper email</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Role previleges: </label>
                            <b-form-radio-group id="radio-group-1" v-model="user.role" :class="{'is-invalid': $v.user.role.$error,'is-valid': !$v.user.role.$invalid,}" name="radio-options">
                                <b-form-radio v-for="r in allRoles" :value="r" v-bind:key="r.index" v-show="r.roleName!='ROLE_EMPLOYEE'">{{r.roleName}}</b-form-radio>
                            </b-form-radio-group>
                            <div class="valid-feedback">Role add to the user</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.user.role.required">Role is required</span>
                                <!-- <span v-if="!$v.user.role.isUnique">Role your proper email</span> -->
                            </div>
                        </div>
                        <br />
                        <div class="form-group" v-if="user.role != null">
                            <div v-if="user.role.roleName == 'ROLE_COUNSELLOR' || user.role.roleName == 'ROLE_SUPERVISOR'">
                                <label> Select Branch</label>
                                <b-form-select class="form-control" v-model="branch" :class="{'is-invalid': $v.branch.$error,'is-valid': !$v.branch.$invalid,}">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allBranchesBranch" :value="item" v-bind:key="item.branchId">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                            <div v-if="user.role.roleName == 'ROLE_ADMIN_SUPERVISOR'">
                                <label>Branch:</label>
                                <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.branches.$error,'is-valid': !$v.branches.$invalid,}">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{item.name}}</strong>
                                    </option>
                                </b-form-select>&nbsp;&nbsp;
                                <div v-if="selectedBranch!=null" class="text-center">
                                    <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                                </div>
                                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="branches.length != 0">
                                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                                    </b-badge>
                                </b-card>
                                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                                    <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                                </b-card>
                            </div>
                        </div>
                        <div class="form-group" v-if="user.role != null">
                            <div v-if="user.role.roleName == 'ROLE_EMPLOYEE'">
                                <label> Select Office</label>
                                <b-form-select class="form-control" v-model="office" :class="{'is-invalid': $v.office.$error,'is-valid': !$v.office.$invalid,}">
                                    <option value="null" disabled>Select Office</option>
                                    <option v-for="item in allOffices" :value="item" v-bind:key="item.officeId">
                                        <strong>{{ item.officeName }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <div class="text-center">
                                <b-button variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,teamUserCreate()">Submit</b-button>
                                <b-button variant="outline-danger" class="mx-3" @click="resetUser()">Reset</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <!--popup end for User Register-->

                <!-- Popup modal for update user details START -->
                <b-modal title="User Update " hide-footer ref="userUpdateModal" no-close-on-backdrop>
                    <div class="form-row">
                        <div class="form-group">
                            <label>Email:</label>
                            <b-form-input v-model="updateUser.contact" disabled>
                            </b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>First Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.updateUser.firstName.$model" :class="{'is-invalid': $v.updateUser.firstName.$error,'is-valid': !$v.updateUser.firstName.$invalid,}" placeholder="Enter your First Name">
                            </b-form-input>
                            <div class="valid-feedback">Your First Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.updateUser.firstName.required">First Name is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Last Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="updateUser.lastName" placeholder="Enter your Last Name">
                            </b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Mobile:</label>
                            <b-form-input type="number" v-model="updateUser.mobile" placeholder="Enter mobile number"></b-form-input>
                            <!-- <div class="valid-feedback">Mobile number is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.updateUser.mobile.required">Mobile number is required</span>
                    </div> -->
                        </div>
                        <br />
                        <div class="form-group mt-3">
                            <div class="text-center">
                                <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,userDetailUpdate()">Update</b-button>
                                <b-button variant="outline-danger" class="mx-3" @click="$refs.userUpdateModal.hide()">Cancel</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <!-- Popup modal for update user details END-->

                <!-- Popup modal for user role privileges START -->
                <b-modal title="Roles" hide-footer hide-header size="lg" id="rolePrivilegeModal" ref="rolePrivilegeModal" no-close-on-backdrop>
                    <b-container fluid>
                        <div class="text-center" style="color:#4274d6;"><i class="fa fa-id-badge fa-2x" aria-hidden="true"></i></div>
                        <div class="text-center" style="color:#4274d6; font-weight:bold;">Role previleges</div><br>
                        <b-row>
                            <b-col cols="6" class="border border-right">
                                <div class="text-center">
                                    <span style="text-decoration: underline;color:#4274d6;font-weight:bold;">Available Roles</span><br>
                                </div>
                                <b-row class="ml-2" style="padding:1em;" v-if="notAddedUserRoles.length == 0 || (notAddedUserRoles.length==1 && notAddedUserRoles[0].roleName == 'ROLE_USER')">
                                    <div style="color:#4274d6;">Currently there are no roles available!</div>
                                </b-row>
                                <b-row v-else class="ml-2" style="padding:1em;">
                                    <div class="p-1" v-for="(a, aindex) in notAddedUserRoles" v-bind:key="aindex" v-show="a.roleName!='ROLE_USER' && a.roleName!='ROLE_SUPER_ADMIN'" style="padding-bottom:.5em;">
                                        <b-form-radio-group id="radio-group-2" v-model="addSelectedRoles" name="radio-sub-component">
                                            <b-form-radio :value="a">
                                                <div style="font-family: 'Pavanam', sans-serif;color:#4274d6;font-weight:bold;">{{a.roleName}}</div>
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </div>
                                </b-row>

                                <div class="form-group" v-if="addSelectedRoles != null">
                                    <div v-if="addSelectedRoles.roleName == 'ROLE_COUNSELLOR' || addSelectedRoles.roleName == 'ROLE_SUPERVISOR'" class="mb-3">
                                        <label> Select Branch</label>
                                        <b-form-select class="form-control" v-model="branch" :class="{'is-invalid': $v.branch.$error,'is-valid': !$v.branch.$invalid,}">
                                            <option value="null" disabled>Select Branch</option>
                                            <option v-for="item in allBranchesBranch" :value="item" v-bind:key="item.branchId">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </div>
                                    <div v-if="addSelectedRoles.roleName == 'ROLE_ADMIN_SUPERVISOR'" class="mb-3">
                                        <label>Branch:</label>
                                        <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.branches.$error,'is-valid': !$v.branches.$invalid,}">
                                            <option value="null" disabled>Select Branch</option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{item.name}}</strong>
                                            </option>
                                        </b-form-select>&nbsp;&nbsp;
                                        <div v-if="selectedBranch!=null" class="text-center">
                                            <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                                        </div>
                                        <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="branches.length != 0">
                                            <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                                            </b-badge>
                                        </b-card>
                                        <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                                            <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                                        </b-card>
                                    </div>
                                </div>

                                <div v-if="addSelectedRoles.length != 0" style="padding-bottom:1em;">
                                    <b-button variant="success" size="sm" style="display:block;width:100%;" class="rounded-0 border-0" @click="addRolesModal(addSelectedRoles,selectedUserId)">ADD</b-button>
                                </div>
                            </b-col>
                            <b-col cols="6" class="border border-right">
                                <div class="text-center">
                                    <span style="text-decoration: underline;color:#4274d6;font-weight:bold;">Added Roles</span><br>
                                </div>
                                <b-row class="ml-2" style="padding:1em;" v-if="addedUserRoles.length == 0 || (addedUserRoles.length==1 && addedUserRoles[0].roleName == 'ROLE_USER')">
                                    <div class="mx-auto" style="color:#4274d6;">Currently user previlege only!</div>
                                </b-row>
                                <b-row v-else class="ml-2" style="padding:1em;">
                                    <div v-for="(a, aindex) in  addedUserRoles" v-bind:key="aindex" v-show="a.roleName!='ROLE_USER'" style="padding-bottom:.5em;">
                                        <b-form-checkbox :value="a" v-model="deleteSelectedRoles">
                                            <div style="font-family: 'Pavanam', sans-serif;color:#4274d6;font-weight:bold;">{{a.roleName}}</div>
                                        </b-form-checkbox>
                                    </div>
                                </b-row>
                                <div v-if="deleteSelectedRoles.length != 0" style="padding-bottom:1em;">
                                    <b-button variant="danger" size="sm" style="display:block;width:100%;" class="rounded-0 border-0" @click="removeUserRoles(selectedUserId)">REMOVE</b-button>
                                </div>
                            </b-col>
                        </b-row>
                        <br>
                        <b-button variant="primary" class="float-end border-0 rounded-0" size="sm" @click="addUserModalClose">Close</b-button>
                    </b-container>
                </b-modal>
                <!-- Popup modal for user role privileges END-->
            </b-container>
            <!-- User Table Start -->

            <div class="mt-5">
                <b-container>
                    <b-row align-v="center">
                        <!--add Tutor button start-->

                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" v-b-modal.addUserModal @click="resetUser(),getAllRoles(),getAllBranches(),getOfficeDetail()">Add User</b-button>
                        </b-col>
                        <!-- <b-col col sm="12" md="3" lg="3" id="r1"> -->
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <template>
                                <div>
                                    <b-form-checkbox v-model="selectedSearchType" size="sm" value="username" :disabled="isChecked('username')">Name</b-form-checkbox>
                                    <b-form-checkbox v-model="selectedSearchType" size="sm" value="contact" :disabled="isChecked('contact')">Contact</b-form-checkbox>
                                </div>
                                <!-- <div class=" search-box">
                                <input type="search" class="searchbar" v-model="keyword" @input="keyword !== '' ? onSearch(keyword) : null" placeholder="Search..." />
                                <ul class="list-group-item" v-if="searchResults.length" style="position: absolute;z-index: 1;list-style-type: none;">
                                    <li class="searches" v-for="result in searchResults" :key="result.id">
                                        <span @click="searchByName(result)"> {{ result.username }}</span>
                                    </li>
                                </ul>
                        </div> -->
                            </template>
                            <!-- <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="teamUserSearch(keyword)" placeholder="Email/Mobile/Username to search" /> -->
                        </b-col>
                        <!-- <b-col col lg="1" md="1" id="r1" sm="6"> -->
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <template>
                                <div class="search-box">
                                    <input type="search" class="searchbar" v-model="keyword" @input="keyword !== '' ? onSearch(keyword) : null" placeholder="Search..." />
                                    <ul class="list-group-item" v-if="searchResults.length" style="position: absolute;z-index: 1;list-style-type: none;">
                                        <li class="searches" v-for="result in searchResults" :key="result.id">
                                            <span class="disabled-span" v-if="result.username == 'No User Found...!!!'"> {{ result.username }}</span>
                                            <span v-else @click="searchByName(result)"> {{ result.username }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div> -->
                                <!-- <b-form-checkbox v-model="selectedSearchType" size="sm" value="username">Name</b-form-checkbox>
                            <b-form-checkbox v-model="selectedSearchType" size="sm" value="contact">Contact</b-form-checkbox> -->
                                <!-- <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio v-model="selectedSearchType" :aria-describedby="ariaDescribedby" name="some-radios" value="username">Name</b-form-radio>
                                <b-form-radio v-model="selectedSearchType" :aria-describedby="ariaDescribedby" name="some-radios" value="contact">Contact</b-form-radio>
                            </b-form-group> -->
                                <!-- <b-form-group  v-slot="{ ariaDescribedby }">
                                <b-form-radio-group size="sm" v-model="selectedSearchType" :options="searchOptions" :aria-describedby="ariaDescribedby" button-variant="outline-primary" buttons stacked></b-form-radio-group>
                            </b-form-group> -->
                                <!-- </div> -->
                            </template>
                            <!-- <b-button size="sm" variant="outline-primary" class="bnclr" @click="teamUserSearch(keyword)">Search</b-button> -->
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage()"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage()" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1">
                        <b-row>
                            <!-- <b-col col sm="6" md="2" lg="2" id="r1"> -->
                            <b-col>
                                <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="primary">
                                    <font-awesome-icon icon="user" /> Total Users : {{totalCount}}
                                </b-btn>
                            </b-col>
                            <!-- <b-row> -->
                            <b-col>
                                <b-row>
                                    <b-col>
                                        <p>Bulk Employees:</p>
                                    </b-col>
                                    <b-col>
                                        <b-form-file v-model="file" accept=".xlsx" plain></b-form-file>
                                    </b-col>
                                    <b-col>
                                        <b-btn variant="primary" @click="uploadUsers(file),getOfficeDetail()">Upload
                                        </b-btn>
                                    </b-col>
                                    <b-col>
                                        <b-button variant="success" href="/Users.xlsx" download="Users.xlsx">Format</b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-table responsive hover :items="users" :fields="userFields" :current-page="currentPage" :per-page="users.pageCount" ref="table">
                            <template v-slot:cell(firstName)="data">
                                <span>{{ data.item.firstName }}</span>&nbsp;<span>{{ data.item.lastName }}</span>
                            </template>
                            <template v-slot:cell(username)="data">
                                {{ data.item.username }}
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="cellSize">
                                    {{ formatDate(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(email)="data">
                                <div class="custom-column">
                                    {{ data.item.email[0] }}
                                </div>
                            </template>
                            <template v-slot:cell(mobile)="data">
                                {{ data.item.mobile[0] }}
                            </template>
                            <template v-slot:cell(archive)="data">
                                <div class="cellSize">
                                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="success" v-if="!data.item.archive">
                                        <font-awesome-icon icon="check" /> Activated
                                    </b-btn>
                                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="danger" v-if="data.item.archive">
                                        <font-awesome-icon icon="cross" /> Deactivated
                                    </b-btn>
                                </div>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <div class="cellSize">
                                    <!-- <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View User Details" v-b-modal.viewUserModal>
                            <font-awesome-icon icon="eye" />
                        </b-button> -->
                                    <b-button size="sm" class="mx-1" variant="outline-primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="User update" @click="userUpdateModalShow(data.item)">
                                        <font-awesome-icon icon="edit" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="outline-secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Role Previleges" @click="rolePrivilegeModal(data.item),resetBranchSelect()">
                                        <font-awesome-icon icon="id-badge" />
                                    </b-button>

                                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Send Password" variant="success" @click="sendPasswordToUser(data.item)" v-if="data.item.email[0] != null">
                                        <font-awesome-icon icon="envelope" />
                                    </b-btn>

                                    <!-- <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button> -->
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </div>
        </b-tab>
        <b-tab title="UPDATE EMPLOYEE" @click="getAllEmployeeCount()">
            <!-- User Table Start -->

            <div class="mt-5">
                <b-container>
                    <b-row align-v="center">
                        <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                            <template>
                                <div>
                                    <b-form-checkbox v-model="selectedSearchType" size="sm" value="username" :disabled="isChecked('username')">Name</b-form-checkbox>
                                    <b-form-checkbox v-model="selectedSearchType" size="sm" value="contact" :disabled="isChecked('contact')">Contact</b-form-checkbox>
                                </div>
                            </template>
                        </b-col> -->
                        <!-- <b-col col lg="1" md="1" id="r1" sm="6"> -->
                        <!-- <b-col col sm="12" md="3" lg="3" id="r1">
                            <template>
                                <div class="search-box">
                                    <input type="search" class="searchbar" v-model="keyword" @input="keyword !== '' ? onSearch(keyword) : null" placeholder="Search..." />
                                    <ul class="list-group-item" v-if="searchResults.length" style="position: absolute;z-index: 1;list-style-type: none;">
                                        <li class="searches" v-for="result in searchResults" :key="result.id">
                                            <span @click="searchByName(result)"> {{ result.username }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </b-col> -->
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="employeeKeyword" placeholder="Type a name" v-on:keyup.enter="getEmployeeSearchCount(employeeKeyword)" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getEmployeeSearchCount(employeeKeyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentEmployeePage" :total-rows="totalEmployeeCount" :per-page="employeePageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchEmployeePage(currentEmployeePage,employeePageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="employeePageCount" @input="switchEmployeePage(currentEmployeePage,employeePageCount)" :options="employeePageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="refreshEmployee()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1">
                        <b-row>
                            <b-col>
                                <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="primary">
                                    <font-awesome-icon icon="user" /> Total Employee : {{totalEmployeeCount}}
                                </b-btn>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-table responsive hover :items="employees" :fields="userFields" :current-page="currentEmployeePage" :per-page="employees.employeePageCount" ref="table">
                            <template v-slot:cell(firstName)="data">
                                <span>{{ data.item.firstName }}</span>&nbsp;<span>{{ data.item.lastName }}</span>
                            </template>
                            <template v-slot:cell(username)="data">
                                {{ data.item.username }}
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="cefilteredUsersllSize">
                                    {{ formatDate(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(email)="data">
                                <div class="custom-column">
                                    {{ data.item.email }}
                                </div>
                            </template>
                            <template v-slot:cell(mobile)="data">
                                {{ data.item.mobile }}
                            </template>
                            <template v-slot:cell(archive)="data">
                                <div class="cellSize">
                                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="success" v-if="!data.item.archive">
                                        <font-awesome-icon icon="check" /> Activated
                                    </b-btn>
                                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="danger" v-if="data.item.archive">
                                        <font-awesome-icon icon="cross" /> Deactivated
                                    </b-btn>
                                </div>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <div class="cellSize">
                                    <b-button size="sm" class="mx-1" variant="outline-primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="User update" @click="userEmployeeUpdateModalShow(data.item)">
                                        <font-awesome-icon icon="edit" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>

                <!-- Popup modal for update user details START -->
                <b-modal title="User Employee Details Update " hide-footer ref="userEmployeeUpdateModal" no-close-on-backdrop>
                    <div class="form-row">
                        <div class="form-group">
                            <label>Email:</label>
                            <b-form-input v-model="updateEmployee.contact" disabled>
                            </b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>First Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.updateEmployee.firstName.$model" :class="{'is-invalid': $v.updateEmployee.firstName.$error,'is-valid': !$v.updateEmployee.firstName.$invalid,}" placeholder="Enter your First Name" disabled>
                            </b-form-input>
                            <div class="valid-feedback">Your First Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.updateEmployee.firstName.required">First Name is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Last Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="updateEmployee.lastName" placeholder="Enter your Last Name" disabled>
                            </b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Mobile:</label>
                            <b-form-input type="number" v-model.trim="updateEmployee.mobile" placeholder="Enter mobile number" disabled></b-form-input>
                            <!-- <div class="valid-feedback">Mobile number is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.updateUser.mobile.required">Mobile number is required</span>
                    </div> -->
                        </div>
                        <div class="form-group">
                            <label>Employe Code:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.updateEmployee.employeeCode.$model" :class="{'is-invalid': $v.updateEmployee.employeeCode.$error,'is-valid': !$v.updateEmployee.employeeCode.$invalid,}" placeholder="Enter your Employee Code">
                            </b-form-input>
                            <div class="valid-feedback">Your Employee Code valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.updateEmployee.employeeCode.required">Employee Code is required</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Salary Details:</label>
                            <b-form-input type="number" class="form-control" v-model.trim="$v.updateEmployee.salaryDetails.$model" :class="{'is-invalid': $v.updateEmployee.salaryDetails.$error,'is-valid': !$v.updateEmployee.salaryDetails.$invalid,}" placeholder="Enter your Salary Details">
                            </b-form-input>
                            <div class="valid-feedback">Your Salary Details is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.updateEmployee.salaryDetails.required">Salary Details is required</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>WeekOff:</label>
                            <b-form-input type="text" class="form-control" v-model="updateEmployee.weekOff" :class="{'is-invalid': $v.updateEmployee.weekOff.$error,'is-valid': !$v.updateEmployee.weekOff.$invalid,}" placeholder="Enter WeekOff Name">
                            </b-form-input>
                            <div class="valid-feedback">Your weekOff is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.updateEmployee.weekOff.required">WeekOff is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group mt-3">
                            <div class="text-center">
                                <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,employeeDetailUpdate()">Update</b-button>
                                <b-button variant="outline-danger" class="mx-3" @click="$refs.userEmployeeUpdateModal.hide()">Cancel</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <!-- Popup modal for update user details END-->

            </div>

        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import Account from '../service/Account.js';
import moment from "moment-timezone";
import {
    required,
    minLength,
    // maxLength,
    email
} from "vuelidate/lib/validators";
import * as XLSX from 'xlsx'
import AgentService from "../service/AgentService";
import BranchService from "../service/BranchService";
import OfficeService from "../service/OfficeService"
import AttendanceEmployeeService from "../service/AttendanceEmployeeService"

export default {
    name: "User",
    components: {},
    data() {
        return {
            // oauthServerLocation: "https://auth-encore.examsdaily.in",
            // oauthServerLocation: 'http://localhost:9088',
            // officeDetailsId:[],
            searchEmployeePagination:false,
            paginationEmployees:true,
            checkinTime: "",
            bulkUsersReason: "",
            contactList: [],
            bulkUsersDetails: [],
            bulkImport: [],
            file: null,
            loading: false,
            bulkUsersTable: false,
            verifyButton: true,
            submitButton: false,
            loadingData: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalCount: 0,
            currentEmployeePage: 1,
            employeePageCount: 5,
            employeePageOptions: [2,5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalEmployeeCount: 0,
            keyword: "",
            employeeKeyword: "",
            user: {
                firstName: "",
                lastName: "",
                username: "",
                contact: "",
                mobile: "",
                role: null,
            },
            allBranches: [],
            allBranchesBranch: [],
            selectedBranch: null,
            branch: null,
            branches: [],
            office: null,
            allOffices: [],
            users: [],
            employees: [],
            filteredUsers: [],
            bulkUsers: [],
            bulkEmployees: [],
            // bulkUsersFields: [],
            // New search Start
            searchTerm: "",
            searchResults: [],
            selectedSearchType: "username",
            searchOptions: [
                { text: 'Name', value: 'username' },
                { text: 'Contact', value: 'contact' },
            ],
            // selectedSearch: true,
            // New search Start
            userFields: [{
                    key: "firstName",
                    label: "Name",
                },
                {
                    key: "username",
                    label: "Username",
                },
                {
                    key: "createdAt",
                    label: "CreatedAt",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "mobile",
                    label: "Mobile",
                },
                {
                    key: "archive",
                    label: "status",
                },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],

            bulkUsersFields: [{
                    key: "isValid",
                    label: "IsValid",
                }, {
                    key: "firstName",
                    label: "FirstName",
                },
                {
                    key: "lastName",
                    label: "LastName",
                },
                {
                    key: "username",
                    label: "Username",
                },
                {
                    key: "contact",
                    label: "Email",
                },
                {
                    key: "mobile",
                    label: "Mobile",
                },
                {
                    key: "role",
                    label: "Role",
                },
                {
                    key: "checkinTime",
                    label: "CheckinTime",
                },
                {
                    key: "checkoutTime",
                    label: "CheckoutTime",
                },
                {
                    key: "isValid",
                    label: "Valid Data",
                },
                {
                    key: "reason",
                    label: "Reason",
                },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],
            disabledButton: false,
            allRoles: [],
            addedUserRoles: [],
            selectedUserId: "",
            notAddedUserRoles: [],
            addSelectedRoles: [],
            deleteSelectedRoles: [],
            updateUser: {
                userId: "",
                firstName: "",
                lastName: "",
                contact: "",
                mobile: "",
                // employeeCode: "",
                // salaryDetails: "",
                // weekOff: "",
            },

            updateEmployee: {
                userId: "",
                firstName: "",
                lastName: "",
                contact: "",
                mobile: "",
                employeeCode: "",
                salaryDetails: "",
                weekOff: "",
            },

            updateUserPassword: {
                userId: "",
                firstName: "",
                lastName: "",
                contact: "",
                mobile: "",
            },

            // UpdateEmployeeDetails //
            selectedEmployeeDetails: [],
        };
    },
    validations: {
        user: {
            firstName: {
                required,
                minLength: minLength(3),
            },
            username: {
                required,
                minLength: minLength(6),
            },
            mobile: {
                // Number,
                // minLength: minLength(10),
                // maxLength: maxLength(10),
            },
            contact: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            },
            role: {
                required
            }
        },
        branch: {
            required
        },
        office: {
            required
        },
        branches: {
            required
        },
        updateUser: {
            firstName: {
                required,
                minLength: minLength(3),
            },

            employeeCode: {
                required,
                minLength: minLength(3),
            },

            salaryDetails: {
                Number,
                required,
                minLength: minLength(3),
            },

            weekOff: {
                required,
                minLength: minLength(3),
            },
        },

        updateEmployee: {
            firstName: {
                required,
                minLength: minLength(3),
            },

            employeeCode: {
                required,
                minLength: minLength(6),
            },

            salaryDetails: {
                required,
                minLength: minLength(4),
            },

            weekOff: {
                required,
                minLength: minLength(6),
            },
        }
    },
    mounted() {
        this.loading = true;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.getAllUserCount();
                    // this.getAllEmployeeCount();
                    // this.getAllRoles();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.searchResults.splice(0);
                // this.searchResults = [];
                // this.searchResults.length = 0;
                this.getAllUserCount();
                // console.log("=====SearchResults=====", this.searchResults);
            }
            // if(newVal== "" || newVal == null){
            //     // console.log("New Val Is Null");
            //     // this.searchResults = [];
            //     // this.searchResults.splice(0);
            //     // this.searchResults.length = 0;
            // }
        },
        employeeKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllEmployeeCount();
            }

        },
    },
    methods: {

        // getSearchBar: function (type){
        //     console.log("=====Type=====",type);
        //     if(this.selectedSearchType == "contact"){
        //         this.selectedSearch=false;
        //     }if(this.selectedSearchType == "username"){
        //         this.selectedSearch=true;
        //     }
        //     // selectedSearchType: "username",
        //     // selectedSearch: true,
        // },

        // bulkUserCreate: function () {
        //     if (this.$v.office.$invalid) {

        //         this.$swal({
        //             title: "Please Select The Office",
        //             // text: "Please Select The Office",
        //             icon: "warning",
        //             button: "ok",
        //         });
        //         this.disabledButton = false;

        //     } else {
        //         let temp = [];
        //         for (var i = 0; i < this.bulkUsers.length; i++) {
        //             // console.log("=====Items====", this.items[0].officeName);
        //             // console.log("=====Items====", this.items[i].firstName);
        //             temp.push({
        //                 firstName: this.bulkUsers[i].firstName,
        //                 lastName: this.bulkUsers[i].lastName,
        //                 username: this.bulkUsers[i].username,
        //                 contact: this.bulkUsers[i].contact,
        //                 mobile: this.bulkUsers[i].mobile,
        //                 role: this.bulkUsers[i].role,
        //                 office: this.bulkUsers[i].office,
        //                 checkinTime: this.bulkUsers[i].checkinTime,
        //                 checkoutTime: this.bulkUsers[i].checkoutTime,
        //                 teamId: localStorage.getItem('tenant-uuid')
        //             })
        //             // this.getOfficeInfo(this.items[0].officeName)
        //             // console.log("---User----", this.bulkImport);
        //         }
        //         this.bulkImport = temp;

        //         // console.log("---bulkImport----", this.bulkImport);

        //         let data = {
        //             bulkUsers: this.bulkImport,

        //         }
        //         // console.log("data", this.users)
        //         // console.log("=====User Details=====", data)
        //         // var authAxios = axios.create({
        //         //     baseURL: this.oauthServerLocation,
        //         // });
        //         return new Promise((resolve, reject) => {
        //             // AttendanceEmployeeService.bulkOfficeEmployeeCreate(this.employee)
        //             // Account.bulkTeamUserCreate(data)
        //             // this.authAxios({
        //             //     method: 'post',
        //             //     url: 'admin/userDetails',
        //             //     data,
        //             // })
        //             Account.bulkUserCreate(data)
        //                 .then((response) => {
        //                     this.disabledButton = false;
        //                     this.bulkEmployees = response.data.bulkUsers;
        //                     this.$refs.addBulkUserModal.hide();
        //                     // for (var j = 0; j < this.officeDetailsId.length; j++) {
        //                     //     console.log("======officeDetailsId=====",this.officeDetailsId[j]);
        //                     // }
        //                     console.log("=====Data====", response.data.bulkUsers)
        //                     console.log("=====Data Length====", this.bulkEmployees.length)
        //                     // this.bulkEmployeeUserCreate();
        //                     // for (var i = 0; i < this.bulkUsersDetails.length; i++) {
        //                     // }
        //                     for (var i = 0; i < this.bulkEmployees.length, this.bulkUsersDetails.length; i++) {
        //                         // for (var j = 0; j < this.officeDetailsId.length; j++) {
        //                         this.getEmployeeUserInfo(this.bulkEmployees[i].username, this.bulkUsersDetails[i].checkinTime, this.bulkUsersDetails[i].checkoutTime);
        //                         // this.checkinTime = this.bulkUsersDetails[i].checkinTime,
        //                         // console.log("=====USER NAME START -1====", this.bulkEmployees[i].username)
        //                         console.log("=====CHECKIN TIME START -1====", this.bulkUsersDetails[i])
        //                         // console.log("=====OFFICE ID START -1====", this.officeDetails)
        //                         // }
        //                     }
        //                     this.resetValue();
        //                     // this.file = null;
        //                     // this.bulkUsers.splice(0);
        //                     // this.office = null;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     if (err.response.status == 409) {
        //                         // console.log("if=========");
        //                         this.$swal({
        //                             title: err.response.data,
        //                             icon: "error",
        //                             button: "ok",
        //                         });
        //                     }
        //                     this.disabledButton = false;
        //                     reject(err);
        //                 });
        //         });
        //     }
        // },

        isChecked(value) {
            return this.selectedSearchType === value;
        },

        bulkUserCreate: function () {
            if (this.$v.office.$invalid) {

                this.$swal({
                    title: "Please Select The Office",
                    // text: "Please Select The Office",
                    icon: "warning",
                    button: "ok",
                });
                this.disabledButton = false;

            } else {
                let temp = [];
                for (var i = 0; i < this.bulkUsers.length; i++) {
                    temp.push({
                        firstName: this.bulkUsers[i].firstName,
                        lastName: this.bulkUsers[i].lastName,
                        username: this.bulkUsers[i].username,
                        contact: this.bulkUsers[i].contact,
                        mobile: this.bulkUsers[i].mobile,
                        role: this.bulkUsers[i].role,
                        office: this.bulkUsers[i].office,
                        checkinTime: this.bulkUsers[i].checkinTime,
                        checkoutTime: this.bulkUsers[i].checkoutTime,
                        teamId: localStorage.getItem('tenant-uuid')
                    })
                }
                this.bulkImport = temp;
                let data = {
                    bulkUsers: this.bulkImport,

                }
                // console.log("=====Data=====",this.bulkUsers);
                return new Promise((resolve, reject) => {
                    Account.bulkUserCreate(data)
                        .then((response) => {
                            this.disabledButton = false;
                            this.bulkEmployees = response.data.bulkUsers;
                            this.$refs.addBulkUserModal.hide();
                            for (var i = 0; i < this.bulkEmployees.length, this.bulkUsersDetails.length; i++) {
                                // console.log("=====CheckinTime====", this.bulkUsersDetails[i].checkinTime)
                                // console.log("=====CheckoutTime====", this.bulkUsersDetails[i].checkoutTime)
                                this.getEmployeeUserInfo(this.bulkEmployees[i].username, this.bulkUsersDetails[i].checkinTime, this.bulkUsersDetails[i].checkoutTime);
                            }
                            this.resetValue();
                            resolve(response);
                        })
                        .catch((err) => {
                            if (err.response.status == 409) {
                                // console.log("if=========");
                                this.$swal({
                                    title: err.response.data,
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
        },

        // bulkUserCreate: function () {
        //     if (this.$v.office.$invalid) {

        //         this.$swal({
        //             title: "Please Select The Office",
        //             icon: "warning",
        //             button: "ok",
        //         });
        //         this.disabledButton = false;

        //     } else {
        //         let temp = [];
        //         for (var i = 0; i < this.bulkUsers.length; i++) {
        //             temp.push({
        //                 firstName: this.bulkUsers[i].firstName,
        //                 lastName: this.bulkUsers[i].lastName,
        //                 username: this.bulkUsers[i].username,
        //                 contact: this.bulkUsers[i].contact,
        //                 mobile: this.bulkUsers[i].mobile,
        //                 role: this.bulkUsers[i].role,
        //                 office: this.bulkUsers[i].office,
        //                 checkinTime: this.bulkUsers[i].checkinTime,
        //                 checkoutTime: this.bulkUsers[i].checkoutTime,
        //                 teamId: localStorage.getItem('tenant-uuid')
        //             })
        //         }
        //         this.bulkImport = temp;
        //         let data = {
        //             bulkUsers: this.bulkImport,

        //         }
        //         return new Promise((resolve, reject) => {
        //             Account.bulkUserCreate(data)
        //                 .then((response) => {
        //                     this.disabledButton = false;
        //                     this.bulkEmployees = response.data.bulkUsers;
        //                     this.$refs.addBulkUserModal.hide();
        //                     console.log("=====Data====", response.data.bulkUsers)
        //                     console.log("=====Data Length====", this.bulkEmployees.length)
        //                     for (var i = 0; i < this.bulkEmployees.length, this.bulkUsersDetails.length; i++) {
        //                         this.getEmployeeUserInfo(this.bulkEmployees[i].username, this.bulkUsersDetails[i].checkinTime, this.bulkUsersDetails[i].checkoutTime);
        //                     }
        //                     this.resetValue();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     if (err.response.status == 409) {
        //                         // console.log("if=========");
        //                         this.$swal({
        //                             title: err.response.data,
        //                             icon: "error",
        //                             button: "ok",
        //                         });
        //                     }
        //                     this.disabledButton = false;
        //                     reject(err);
        //                 });
        //         });
        //     }
        // },

        // bulkUserVerify: function () {
        //     if (this.$v.office.$invalid) {

        //         this.$swal({
        //             title: "Please Select The Office",
        //             // text: "Please Select The Office",
        //             icon: "warning",
        //             button: "ok",
        //         });
        //         this.disabledButton = false;

        //     } else {
        //         this.bulkUsersTable = false;
        //         if (this.bulkUsers.length == 0) {
        //             this.$swal({
        //                 title: "No data found",
        //                 icon: "error",
        //                 button: "ok",
        //             });
        //             return;
        //         }
        //         let temp = [];
        //         for (var i = 0; i < this.bulkUsers.length; i++) {
        //             // console.log("=====Items====", this.bulkUsers[0].officeName);
        //             // console.log("=====Items====", this.bulkUsers[i].firstName);
        //             temp.push({
        //                 firstName: this.bulkUsers[i].firstName,
        //                 lastName: this.bulkUsers[i].lastName,
        //                 username: this.bulkUsers[i].username,
        //                 contact: this.bulkUsers[i].contact,
        //                 mobile: this.bulkUsers[i].mobile,
        //                 role: this.bulkUsers[i].role,
        //                 office: this.office.officeId,
        //                 checkinTime: this.bulkUsers[i].checkinTime,
        //                 checkoutTime: this.bulkUsers[i].checkoutTime,
        //                 teamId: localStorage.getItem('tenant-uuid')
        //             })
        //             // this.getOfficeInfo(this.items[0].officeName)
        //             // console.log("---User----", this.bulkImport);
        //         }
        //         this.bulkUsers = temp;
        //         // console.log("---bulkImport----", this.bulkImport);

        //         let data = {
        //             bulkUsers: this.bulkUsers,

        //         }
        //         // console.log("data", this.users)
        //         // console.log("=====User Details=====", data)
        //         // var authAxios = axios.create({
        //         //     baseURL: this.oauthServerLocation,
        //         // });
        //         return new Promise((resolve, reject) => {
        //             // AttendanceEmployeeService.bulkOfficeEmployeeCreate(this.employee)
        //             // Account.bulkTeamUserCreate(data)
        //             // this.authAxios({
        //             //     method: 'post',
        //             //     url: 'admin/userDetails',
        //             //     data,
        //             // })
        //             Account.bulkUserVerify(data)
        //                 .then((response) => {
        //                     this.disabledButton = false;
        //                     // console.log("=====response.data.bulkUsers.length=====", response.data.bulkUsers.length);
        //                     // console.log("=====this.bulkUsers.length=====", this.bulkUsers.length);
        //                     // this.bulkUsers = response.data.bulkUsers;
        //                     // console.log("=====errorMessage=====",response.data.bulkUsers[0].errorMessage);
        //                     // this.bulkUsersTable = true
        //                     // this.$nextTick(() => {
        //                     //     this.$v.bulkUsers.$reset();
        //                     //     // this.$v.followup.errors.$clear();
        //                     // });
        //                     // this.bulkUsersTable = false;
        //                     let allValid = true;
        //                     let mailValid = true;
        //                     let mobileValid = true;
        //                     for (var i = 0; i < this.bulkUsers.length; i++) {
        //                         console.log("=====response.data.bulkUsers[i]=====", response.data.bulkUsers[i]);
        //                         console.log("=====errorMessage=====", response.data.bulkUsers[i].errorMessage);
        //                         this.bulkUsers[i].reason = response.data.bulkUsers[i].errorMessage;
        //                         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //                         var email = emailRegex.test(response.data.bulkUsers[i].contact);
        //                         if (response.data.bulkUsers[i].mobile != null) {
        //                             const mobileNumberRegex = /^[0-9]{10}$/;
        //                             var mobileNumberPattern = mobileNumberRegex.test(response.data.bulkUsers[i].mobile);
        //                             // console.log("Mobile validation=======", mobileNumberRegex.test(response.data.bulkUsers[i].mobile));
        //                         }
        //                         // const mobile = this.isValidMobileNumber(response.data.bulkUsers[i].contact);
        //                         // if (mobile) {
        //                         //     // console.log("Valid mobile number!");
        //                         //     this.bulkUsers[i].reason = "Please Enter The Valid Mobile Number";
        //                         // } else {
        //                         //     console.log("Invalid mobile number format!");
        //                         // }
        //                         // console.log("Mail validation=======", emailRegex.test(response.data.bulkUsers[i].contact));
        //                         if (email == false) {
        //                             this.bulkUsers[i].reason = "Please Enter The Correct Email Format";
        //                         }
        //                         if (mobileNumberPattern == false) {
        //                             this.bulkUsers[i].reason = "Please Enter The Correct Mobile Number";
        //                         }
        //                         //  else if (mobile == false) {
        //                         //     // this.$swal("Please Enter The Correct Email Format");
        //                         //     this.bulkUsers[i].reason = "Please Enter The Valid Mobile Number";
        //                         // }
        //                         this.bulkUsers[i].isValid = response.data.bulkUsers[i].isValid;
        //                         console.log("this.bulkUsers[i].reason=========", this.bulkUsers[i].reason);
        //                         console.log("this.bulkUsers[i].isValid=========", this.bulkUsers[i].isValid);

        //                         for (let i = 0; i < response.data.bulkUsers.length; i++) {
        //                             if (!response.data.bulkUsers[i].isValid) {
        //                                 allValid = false;
        //                                 break;
        //                             }
        //                             if (email != false) {
        //                                 mailValid = false;
        //                                 break;
        //                             }
        //                             if (mobileNumberPattern != false) {
        //                                 mobileValid = false;
        //                                 break;
        //                             }
        //                         }

        //                         if (allValid, mailValid, mobileValid) {
        //                             this.$swal({
        //                                 title: "Verified Successfully...!!!",
        //                                 icon: "success",
        //                                 button: "OK",
        //                             });
        //                             this.submitButton = true;
        //                             this.verifyButton = false;
        //                         }

        //                         if (this.bulkUsers[i].reason != null) {
        //                             this.$swal({
        //                                 title: "Please Enter Valid Data Please Check Reason",
        //                                 icon: "error",
        //                                 button: "ok",
        //                             });
        //                             this.submitButton = false
        //                             this.verifyButton = true
        //                         }

        //                         if (response.data.bulkUsers[i].isValid == false || response.data.bulkUsers[i].errorMessage != null && this.bulkUsers[i].reason != null) {
        //                             this.$swal({
        //                                 title: "Already Exists Data Found Please Check Reason",
        //                                 icon: "error",
        //                                 button: "ok",
        //                             });
        //                             this.submitButton = false
        //                             this.verifyButton = true
        //                         }

        //                         // if (this.bulkUsers[i].reason == "Please Enter The Correct Mobile Number") {
        //                         //     this.$swal({
        //                         //         title: "Please Enter The Correct Mobile Number",
        //                         //         icon: "error",
        //                         //         button: "ok",
        //                         //     });
        //                         //     this.submitButton = false
        //                         //     this.verifyButton = true
        //                         // }

        //                         // if (response.data.bulkUsers[i].isValid == true || response.data.bulkUsers[i].errorMessage == null) {
        //                         //     this.$swal({
        //                         //         title: "No Exists Data Found",
        //                         //         icon: "success",
        //                         //         button: "ok",
        //                         //     });
        //                         //     this.submitButton = true
        //                         //     this.verifyButton = false
        //                         // }
        //                     }
        //                     // alert('bulkUsers')
        //                     this.bulkUsersTable = true;
        //                     console.log("=====this.bulkUsers=====", this.bulkUsers);
        //                     // this.$swal({
        //                     //     // title: response.data,
        //                     //     icon: "success",
        //                     //     button: "ok",
        //                     // });
        //                     // this.submitButton = true
        //                     // this.verifyButton = false
        //                     // this.bulkUsersTable = true

        //                     this.file = null;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     if (err.response.status == 409) {
        //                         // this.bulkUsersTable = false;
        //                         // for (var i = 0; i < this.bulkUsers.length; i++) {
        //                         //     if (this.bulkUsers[i].username == err.response.data) {
        //                         //         data = "Username Already Exists Please Change or Delete";
        //                         //         this.bulkUsers[i].reason = data;

        //                         //     } else if (this.bulkUsers[i].contact == err.response.data) {
        //                         //         data = "Email Already Exists Please Change or Delete";
        //                         //         this.bulkUsers[i].reason = data;

        //                         //     } else if (this.bulkUsers[i].mobile == err.response.data) {
        //                         //         data = "Mobile Number Already Exists Please Change or Delete";
        //                         //         this.bulkUsers[i].reason = data;

        //                         //     }
        //                         // }
        //                         // this.bulkUsersTable = true;
        //                         // console.log("if=========",err.response.data);

        //                         this.$swal({
        //                             // title: err.response.data,
        //                             title: "Some Data Already Exists Please Check Reason",
        //                             icon: "error",
        //                             button: "ok",
        //                         });
        //                     }
        //                     this.disabledButton = false;
        //                     reject(err);
        //                 });
        //         });
        //     }
        // },

        bulkUserVerify: function () {
            if (this.$v.office.$invalid) {

                this.$swal({
                    title: "Please Select The Office",
                    // text: "Please Select The Office",
                    icon: "warning",
                    button: "ok",
                });
                this.disabledButton = false;

            } else {
                this.bulkUsersTable = false;
                if (this.bulkUsers.length == 0) {
                    this.$swal({
                        title: "No data found",
                        icon: "error",
                        button: "ok",
                    });
                    return;
                }
                let temp = [];
                for (var i = 0; i < this.bulkUsers.length; i++) {
                    // console.log("=====Items====", this.bulkUsers[0].officeName);
                    // console.log("=====Items====", this.bulkUsers[i].firstName);
                    temp.push({
                        firstName: this.bulkUsers[i].firstName,
                        lastName: this.bulkUsers[i].lastName,
                        // username: this.bulkUsers[i].username,
                        username: this.bulkUsers[i].username.trim(),
                        contact: this.bulkUsers[i].contact,
                        mobile: this.bulkUsers[i].mobile,
                        role: this.bulkUsers[i].role,
                        office: this.office.officeId,
                        checkinTime: this.bulkUsers[i].checkinTime,
                        checkoutTime: this.bulkUsers[i].checkoutTime,
                        teamId: localStorage.getItem('tenant-uuid')
                    })
                    // this.getOfficeInfo(this.items[0].officeName)
                    // console.log("---User----", this.bulkImport);
                }
                this.bulkUsers = temp;
                // console.log("---bulkImport----", this.bulkImport);

                let data = {
                    bulkUsers: this.bulkUsers,

                }
                // console.log("data", this.users)
                // console.log("=====User Details=====", data)
                // var authAxios = axios.create({
                //     baseURL: this.oauthServerLocation,
                // });
                return new Promise((resolve, reject) => {
                    // AttendanceEmployeeService.bulkOfficeEmployeeCreate(this.employee)
                    // Account.bulkTeamUserCreate(data)
                    // this.authAxios({
                    //     method: 'post',
                    //     url: 'admin/userDetails',
                    //     data,
                    // })
                    Account.bulkUserVerify(data)
                        .then((response) => {
                            this.disabledButton = false;
                            // console.log("=====response.data.bulkUsers.length=====", response.data.bulkUsers.length);
                            // console.log("=====this.bulkUsers.length=====", this.bulkUsers.length);
                            // this.bulkUsers = response.data.bulkUsers;
                            // console.log("=====errorMessage=====",response.data.bulkUsers[0].errorMessage);
                            // this.bulkUsersTable = true
                            // this.$nextTick(() => {
                            //     this.$v.bulkUsers.$reset();
                            //     // this.$v.followup.errors.$clear();
                            // });
                            // this.bulkUsersTable = false;
                            let allValid = true;
                            let mailValid = true;
                            let mobileValid = true;
                            // let inTimeValid = true;
                            for (var i = 0; i < this.bulkUsers.length; i++) {

                                // console.log("=====checkinTime=====",this.bulkUsers[i].checkinTime);

                                const decimal1 = parseFloat(this.bulkUsers[i].checkinTime);
                                if (!isNaN(decimal1) && decimal1 >= 0 && decimal1 <= 1) {
                                    const totalSeconds = decimal1 * 24 * 60 * 60;
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = Math.floor(totalSeconds % 60);

                                    this.inTimeValue = `${this.formatTimeComponent(hours)}:${this.formatTimeComponent(minutes)}:${this.formatTimeComponent(seconds)}`;
                                } else {
                                    this.inTimeValue = null;
                                }

                                const decimal2 = parseFloat(this.bulkUsers[i].checkoutTime);
                                if (!isNaN(decimal2) && decimal2 >= 0 && decimal2 <= 1) {
                                    const totalSeconds = decimal2 * 24 * 60 * 60;
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = Math.floor(totalSeconds % 60);

                                    this.outTimeValue = `${this.formatTimeComponent(hours)}:${this.formatTimeComponent(minutes)}:${this.formatTimeComponent(seconds)}`;
                                } else {
                                    this.outTimeValue = null;
                                }

                                const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
                                var inTime = timeFormatRegex.test(this.inTimeValue);
                                var outTime = timeFormatRegex.test(this.outTimeValue);

                                // console.log("=====this.InTime=====", inTime);
                                // console.log("=====this.InTimeValue=====", this.inTimeValue);
                                // console.log("=====this.outTime=====", outTime);
                                // console.log("=====this.OutTimeValue=====", this.outTimeValue);

                                // console.log("=====response.data.bulkUsers[i]=====", response.data.bulkUsers[i]);
                                // console.log("=====errorMessage=====", response.data.bulkUsers[i].errorMessage);
                                this.bulkUsers[i].reason = response.data.bulkUsers[i].errorMessage;
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                var email = emailRegex.test(response.data.bulkUsers[i].contact);
                                if (response.data.bulkUsers[i].mobile != null) {
                                    const mobileNumberRegex = /^[0-9]{10}$/;
                                    var mobileNumberPattern = mobileNumberRegex.test(response.data.bulkUsers[i].mobile);
                                    // console.log("Mobile validation=======", mobileNumberRegex.test(response.data.bulkUsers[i].mobile));
                                }
                                // const mobile = this.isValidMobileNumber(response.data.bulkUsers[i].contact);
                                // if (mobile) {
                                //     // console.log("Valid mobile number!");
                                //     this.bulkUsers[i].reason = "Please Enter The Valid Mobile Number";
                                // } else {
                                //     console.log("Invalid mobile number format!");
                                // }
                                // console.log("Mail validation=======", emailRegex.test(response.data.bulkUsers[i].contact));
                                if (email == false) {
                                    this.bulkUsers[i].reason = "Please Enter The Correct Email Format";
                                    this.bulkUsers[i].isValid = false
                                }
                                if (mobileNumberPattern == false) {
                                    this.bulkUsers[i].reason = "Please Enter The Correct Mobile Number";
                                    this.bulkUsers[i].isValid = false
                                }
                                if (inTime == false) {
                                    this.bulkUsersReason = "WrongTimeFormat"
                                    this.bulkUsers[i].reason = "Please Enter The Valid CheckinTime Format";
                                    this.bulkUsers[i].isValid = false
                                }
                                if (outTime == false) {
                                    this.bulkUsersReason = "WrongTimeFormat"
                                    this.bulkUsers[i].reason = "Please Enter The Valid CheckoutTime Format";
                                    this.bulkUsers[i].isValid = false
                                }
                                if (this.bulkUsers[i].isValid == null) {
                                    this.bulkUsers[i].isValid = response.data.bulkUsers[i].isValid;
                                }
                                // console.log("this.bulkUsers[i].reason=========", this.bulkUsers[i].reason);
                                // console.log("this.bulkUsers[i].isValid=========", this.bulkUsers[i].isValid);
                                // console.log("response.data.bulkUsers[i].isValid=========", response.data.bulkUsers[i].isValid);

                                for (let i = 0; i < response.data.bulkUsers.length; i++) {
                                    if (!response.data.bulkUsers[i].isValid) {
                                        allValid = false;
                                        break;
                                    }
                                    if (email != false) {
                                        mailValid = false;
                                        break;
                                    }
                                    if (mobileNumberPattern != false) {
                                        mobileValid = false;
                                        break;
                                    }
                                }

                                if (allValid, mailValid, mobileValid) {
                                    this.$swal({
                                        title: "Verified Successfully...!!!",
                                        icon: "success",
                                        button: "OK",
                                    });
                                    this.submitButton = true;
                                    this.verifyButton = false;
                                }

                                if (this.bulkUsers[i].reason != null) {
                                    this.$swal({
                                        title: "Please Enter Valid Data Please Check Reason",
                                        icon: "error",
                                        button: "ok",
                                    });
                                    // this.bulkUsersReason = "Check Reason"
                                    this.submitButton = false
                                    this.verifyButton = true
                                }

                                if (response.data.bulkUsers[i].isValid == false || response.data.bulkUsers[i].errorMessage != null && this.bulkUsers[i].reason != null) {
                                    this.$swal({
                                        title: "Already Exists Data Found Please Check Reason",
                                        icon: "error",
                                        button: "ok",
                                    });
                                    this.bulkUsersReason = "DataAlreadyExist"
                                    this.submitButton = false
                                    this.verifyButton = true
                                }

                                // if (this.bulkUsers[i].reason == "Please Enter The Correct Mobile Number") {
                                //     this.$swal({
                                //         title: "Please Enter The Correct Mobile Number",
                                //         icon: "error",
                                //         button: "ok",
                                //     });
                                //     this.submitButton = false
                                //     this.verifyButton = true
                                // }

                                // if (response.data.bulkUsers[i].isValid == true || response.data.bulkUsers[i].errorMessage == null) {
                                //     this.$swal({
                                //         title: "No Exists Data Found",
                                //         icon: "success",
                                //         button: "ok",
                                //     });
                                //     this.submitButton = true
                                //     this.verifyButton = false
                                // }
                            }

                            if (this.bulkUsersReason == "WrongTimeFormat") {
                                this.$swal({
                                    title: "Please Enter Valid Data Please Check Reason",
                                    icon: "error",
                                    button: "ok",
                                });
                                this.submitButton = false
                                this.verifyButton = true
                                this.bulkUsersReason = ""
                            }

                            if (this.bulkUsersReason == "DataAlreadyExist") {
                                this.$swal({
                                    title: "Already Exists Data Found Please Check Reason",
                                    icon: "error",
                                    button: "ok",
                                });
                                this.submitButton = false
                                this.verifyButton = true
                                this.bulkUsersReason = ""
                            }

                            // alert('bulkUsers')
                            this.bulkUsersTable = true;
                            // console.log("=====this.bulkUsers=====", this.bulkUsers);
                            // this.$swal({
                            //     // title: response.data,
                            //     icon: "success",
                            //     button: "ok",
                            // });
                            // this.submitButton = true
                            // this.verifyButton = false
                            // this.bulkUsersTable = true

                            this.file = null;
                            resolve(response);
                        })
                        .catch((err) => {
                            if (err.response.status == 409) {
                                // this.bulkUsersTable = false;
                                // for (var i = 0; i < this.bulkUsers.length; i++) {
                                //     if (this.bulkUsers[i].username == err.response.data) {
                                //         data = "Username Already Exists Please Change or Delete";
                                //         this.bulkUsers[i].reason = data;

                                //     } else if (this.bulkUsers[i].contact == err.response.data) {
                                //         data = "Email Already Exists Please Change or Delete";
                                //         this.bulkUsers[i].reason = data;

                                //     } else if (this.bulkUsers[i].mobile == err.response.data) {
                                //         data = "Mobile Number Already Exists Please Change or Delete";
                                //         this.bulkUsers[i].reason = data;

                                //     }
                                // }
                                // this.bulkUsersTable = true;
                                // console.log("if=========",err.response.data);

                                this.$swal({
                                    // title: err.response.data,
                                    title: "Some Data Already Exists Please Check Reason",
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
        },

        formatTimeComponent(value) {
            return value.toString().padStart(2, '0');
        },

        // isValidMobileNumber: function (number) {
        //     const mobileNumberPattern = /^[0-9]{10}$/;
        //     const cleanNumber = number.replace(/\D/g, '');
        //     return mobileNumberPattern.test(cleanNumber);
        // },

        getBulkEmployeeUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        let user = response.data;
                        // console.log("=====User Details=====", user);
                        // this.agentCreate(user);
                        this.bulkEmployeeUserCreate(user)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        bulkEmployeeUserCreate: function () {
            // console.log("=====Items====", this.items);
            for (var i = 0; i < this.items.length; i++) {
                // console.log("=====Items====", this.items[i].firstName);
                this.bulkImport.push({
                    firstName: this.items[i].firstName,
                    lastName: this.items[i].lastName,
                    username: this.items[i].username,
                    email: this.items[i].contact,
                    contact: this.items[i].mobile,
                    role: this.items[i].role,
                    officeName: this.items[i].officeName,
                    teamId: localStorage.getItem('tenant-uuid')

                })
                // console.log("---User----", this.bulkImport);
            }

            // console.log("---bulkImport----", this.bulkImport);

            let data = {
                bulkUsers: this.bulkImport,

            }
            // console.log("data", this.users)
            console.log("=====User Details=====", data)

            // console.log("=====User Details=====", this.user)
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.bulkOfficeEmployeeCreate(this.employee)
                    // Account.bulkTeamUserCreate(this.user)
                    .then((response) => {
                        this.disabledButton = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.disabledButton = false;
                        reject(err);
                    });
            });
        },

        // downloadExcel() {
        //     // Generate the Excel file content (you need to implement this logic)
        //     // const excelData = "Users.xlsx";

        //     // Create a Blob with the Excel data
        //     // const blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        //     // Create a URL for the Blob
        //     const url = window.URL.createObjectURL();

        //     // Create a link element and trigger a download
        //     const link = document.createElement("a");
        //     link.href = url;
        //     link.download = "Users.xlsx"; // Change the filename as needed
        //     link.click();

        //     // Release the object URL to free up resources
        //     window.URL.revokeObjectURL(url);
        // },

        downloadExcel() {
            //   const filename = "Users.xlsx";
            //   const fileURL = "~/public/";
            //   // Create an anchor element to trigger the download
            //   const link = document.createElement("a");
            //   link.href = fileURL;
            //   link.download = filename;
            //   // Append the anchor element to the document and trigger the download
            //   document.body.appendChild(link);
            //   link.click();
            const fileUrl = "/users.xlsx";
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = "users.xlsx";
            link.click();
        },

        uploadUsers(file) {
            // console.log("=====File=====", file);
            if (file == null) {

                this.$swal({
                    title: "Please Select The File",
                    // text: "Please Select The File",
                    icon: "warning",
                    button: "ok",
                });

            } else {
                this.loadingData = true;
                this.bulkUsersTable = true;
                this.verifyButton = true;
                this.submitButton = false;
                this.$refs.addBulkUserModal.show();
                this.file = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, {
                        type: "binary"
                    });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws);
                    const data1 = XLSX.utils.sheet_to_json(ws, {
                        header: 1
                    });
                    this.items = data;
                    this.fields = data1[0];
                    this.uploadDisable = false;

                    this.bulkUsers = this.items;
                    // this.loadingData = false
                    // this.bulkUsersFields = this.fields;

                    console.log("Data", this.items);
                    console.log("Data1", data1[0]);
                    localStorage.setItem("bulkUsersDetails", JSON.stringify(this.items))
                    // localStorage.setItem("bulkUsersDetails",this.items)
                    this.bulkUsersDetails = JSON.parse(localStorage.getItem("bulkUsersDetails"));
                    // console.log("bulkUsersDetails", this.bulkUsersDetails);
                    this.loadingData = false;
                }

                reader.readAsBinaryString(this.file);
                console.log("Reader", reader);
            }
        },
        teamUserCreate: function () {
            this.disabledButton = true;
            this.$v.user.$touch();
            this.$v.branch.$touch();
            this.$v.branches.$touch();
            if (!this.$v.user.$invalid) {
                if (this.user.role.roleName == 'ROLE_COUNSELLOR' || this.user.role.roleName == 'ROLE_SUPERVISOR') {
                    if (!this.$v.branch.$invalid) {
                        return new Promise((resolve, reject) => {
                            Account.teamUserCreate(this.user)
                                .then((response) => {
                                    this.disabledButton = false;
                                    if (response.data != null) {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    }
                                    resolve(response);
                                })
                                .catch((err) => {
                                    if (err.response.status == 409) {
                                        console.log("if=========", err.response);
                                        this.$swal({
                                            title: err.response.data,
                                            icon: "error",
                                            button: "ok",
                                        });
                                    }
                                    this.disabledButton = false;
                                    reject(err);
                                });
                        });
                    }
                } else if (this.user.role.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    if (!this.$v.branches.$invalid) {
                        return new Promise((resolve, reject) => {
                            Account.teamUserCreate(this.user)
                                .then((response) => {
                                    this.disabledButton = false;
                                    if (response.data != null) {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    }
                                    resolve(response);
                                })
                                .catch((err) => {
                                    if (err.response.status == 409) {
                                        // console.log("if=========");
                                        this.$swal({
                                            title: err.response.data,
                                            icon: "error",
                                            button: "ok",
                                        });
                                    }
                                    this.disabledButton = false;
                                    reject(err);
                                });
                        });
                    }
                } else if (this.user.role.roleName == 'ROLE_EMPLOYEE') {
                    if (!this.$v.office.$invalid) {
                        return new Promise((resolve, reject) => {
                            Account.teamUserCreate(this.user)
                                .then((response) => {
                                    this.disabledButton = false;
                                    if (response.data != null) {
                                        this.getEmployeeUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    }
                                    resolve(response);
                                })
                                .catch((err) => {
                                    if (err.response.status == 409) {
                                        // console.log("if=========");
                                        this.$swal({
                                            title: err.response.data,
                                            icon: "error",
                                            button: "ok",
                                        });
                                    }
                                    this.disabledButton = false;
                                    reject(err);
                                });
                        });
                    }
                } else {
                    return new Promise((resolve, reject) => {
                        Account.teamUserCreate(this.user)
                            .then((response) => {
                                this.disabledButton = false;
                                if (response.data != null) {
                                    if (this.user.role.roleName == 'ROLE_TELECALLER' || this.user.role.roleName == 'ROLE_ADMIN') {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    } else {
                                        this.$refs.addUserModal.hide();
                                        this.$swal({
                                            title: "Account Created",
                                            text: "Confirmation mail send to the email",
                                            icon: "success",
                                            button: "ok",
                                        });
                                        this.disabledButton = false;
                                        this.resetUser();
                                        this.getAllUserCount();
                                    }
                                }
                                resolve(response);
                            })
                            .catch((err) => {
                                if (err.response.status == 409) {
                                    this.$swal({
                                        title: err.response.data,
                                        icon: "error",
                                        button: "ok",
                                    });
                                }
                                this.disabledButton = false;
                                reject(err);
                            });
                    });
                }
                this.disabledButton = false;
            }
            this.disabledButton = false;
        },
        getOfficeInfo: function (officeName) {
            console.log("=====OfficeName=====", officeName);
            return new Promise((resolve, reject) => {
                OfficeService.getOfficeInfo(officeName)
                    .then((response) => {
                        let officeDetails = response.data.officeId;
                        // this.officeDetailsId.push(officeDetails)
                        // console.log("=====officeDetailsId=====",this.officeDetailsId);
                        console.log("=====Office Details=====", officeDetails);
                        // this.employeeCreate(user)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        let user = response.data;
                        console.log("=====User Details=====", user);
                        this.agentCreate(user);
                        // this.employeeCreate(user)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getEmployeeUserInfo: function (contact, checkinTime, checkoutTime) {
            // console.log("=====USER NAME START -2====", contact)
            // console.log("=====OFFICE ID START -2====", officeId)
            // console.log("=====CheckinTime====", checkinTime)
            // console.log("=====CheckoutTime====", checkoutTime)
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        let user = response.data;
                        // let officeId = this.office
                        // console.log("=====User Details=====", user);
                        // console.log("=====officeId=====", officeId);
                        // this.agentCreate(user);
                        this.employeeCreate(user, checkinTime, checkoutTime)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getEmployeeUserInfo: function (checkinTime, checkoutTime) {
        //     console.log("=====CONTACT LIST START====", this.contactList);

        //     // Create an array to hold all the promises for each contact
        //     const promises = [];

        //     // Loop through each contact in the contactList
        //     this.contactList.forEach((contact) => {
        //         // Create a promise for each contact and push it to the promises array
        //         promises.push(new Promise((resolve, reject) => {
        //             Account.getUserInfo(contact)
        //                 .then((response) => {
        //                     let user = response.data;
        //                     this.employeeCreate(user, checkinTime, checkoutTime);
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         }));
        //     });

        //     // Use Promise.all to handle all the promises concurrently
        //     return Promise.all(promises);
        // },

        agentCreate: function (user) {
            // console.log("agentCreate=====", this.user.role);
            let roleCounsellor = false;
            let roleSupervisor = false;
            let roleAdminSupervisor = false;
            let branch = null;
            let branches = [];
            if (this.user.role != null) {
                console.log("this.user.role-====", this.user.role);
                if (this.user.role.roleName == 'ROLE_COUNSELLOR') {
                    roleCounsellor = true;
                    branch = this.branch
                }
                if (this.user.role.roleName == 'ROLE_SUPERVISOR') {
                    roleSupervisor = true;
                    branch = this.branch
                }
                if (this.user.role.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    roleAdminSupervisor = true;
                    branches = this.branches;
                }
            }
            if (this.addSelectedRoles.roleName == 'ROLE_COUNSELLOR') {
                roleCounsellor = true;
                branch = this.branch
            }
            if (this.addSelectedRoles.roleName == 'ROLE_SUPERVISOR') {
                roleSupervisor = true;
                branch = this.branch
            }
            if (this.addSelectedRoles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                roleAdminSupervisor = true;
                branches = this.branches;
            }
            let agent = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.username,
                email: user.email[0],
                contact: user.mobile[0],
                roleCounsellor: roleCounsellor,
                roleSupervisor: roleSupervisor,
                roleAdminSupervisor: roleAdminSupervisor,
                branch: branch,
                branches: branches
            }
            return new Promise((resolve, reject) => {
                AgentService.teamAgentCreate(agent)
                    .then((response) => {
                        this.$swal({
                            title: "Account Created",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        this.disabledButton = false;
                        this.addUserRoles(agent.userId);
                        if (this.addSelectedRoles != []) {
                            this.getAllUserCount();
                        }
                        this.resetBranchSelect();
                        this.getAllBranches();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        employeeCreate: function (user, checkinTime, checkoutTime) {
            // console.log("=====USER NAME START -3====", user)
            // console.log("=====CHECKIN TIME START -3====", checkinTime)
            // console.log("=====OFFICE ID START -3====", officeId)
            // console.log("=====User Details=====", user);
            // console.log("=====Office Details=====", officeId);
            // console.log("employeeCreate=====", user.roles[0].roleName);
            let roleEmployee = false;
            // let office = null;
            if (user.roles[0] != null) {
                // console.log("this.user.role-====", user.roles[0]);
                if (user.roles[0].roleName == 'ROLE_EMPLOYEE') {
                    roleEmployee = true;
                    this.selectedOffice = this.office
                    // for (var i = 0; i < this.bulkUsersDetails.length; i++) {
                    // }
                    // console.log("=====bulkUsersDetails=====", this.bulkUsersDetails);
                    // console.log("=====Office=====", this.selectedOffice);
                }
            }
            let employee = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.username,
                email: user.email[0],
                contact: user.mobile[0],
                roleEmployee: roleEmployee,
                officeId: this.selectedOffice.officeId,
                tenantId: localStorage.getItem('tenant-uuid'),
                checkinTime: checkinTime,
                checkoutTime: checkoutTime,
                enabled: user.enabled
            }
            console.log("=====Employee Details=====", employee);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.officeEmployeeCreate(employee)
                    .then((response) => {
                        this.$swal({
                            title: "Account Created",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        this.disabledButton = false;
                        this.addUserRoles(employee.userId);
                        if (this.addSelectedRoles != []) {
                            this.getAllUserCount();
                        }
                        this.resetBranchSelect();
                        this.getAllBranches();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllUserCount: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.getAllTeamUserCount(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.totalCount = response.data;
                        if (this.currentPage != 1) {
                            this.currentPage = 1;
                        } else {
                            this.getAllUserPageable();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage() {
            this.loading = true;
            this.getAllUserPageable();
        },
        getAllUserPageable: function () {
            this.users.splice(0);
            return new Promise((resolve, reject) => {
                Account.getAllTeamUserPageable(this.pageCount, this.currentPage, localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.users = response.data;
                        // console.log("=====Users=====", this.users);
                        // const filteredUsers = [];
                        // this.filteredUsers.splice(0);
                        // for (let i = 0; i < this.users.length; i++) {
                        //     const user = this.users[i];
                        //     // Assuming roles is an array of role objects, and you want to check if any role has roleName "ROLE_EMPLOYEE"
                        //     const hasEmployeeRole = user.roles.some(role => role.roleName === "ROLE_EMPLOYEE");

                        //     if (hasEmployeeRole) {
                        //         this.filteredUsers.push(user);
                        //     }
                        // }

                        // console.log("Filtered Users:", this.filteredUsers);

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllEmployeeCount: function () {
            this.loading = true;
            this.employeeKeyword = '';
            this.searchEmployeePagination = false;
            this.paginationEmployees = true;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllTeamEmployeeCount(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.totalEmployeeCount = response.data;
                        // console.log("=====Total EmployeeCount=====", this.totalEmployeeCount);
                        if (this.currentEmployeePage != 1) {
                            this.currentEmployeePage = 1;
                        } else {
                            this.getAllEmployeePageable();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchEmployeePage(currentEmployeePage,employeePageCount) {
            // this.loading = true;
            // this.getAllEmployeePageable();
            this.loading = true;
            if (this.paginationEmployees) {
                this.getAllEmployeePageable(currentEmployeePage, employeePageCount);
                // console.log(this.paginationEmployees, "this.paginationEmployees");
            } else if (this.searchEmployeePagination) {
                this.getAllEmployeeSearchPageable(currentEmployeePage, employeePageCount, this.employeeKeyword);
                // console.log(this.searchEmployeePagination, "searchPagination");
            }
        },

        getAllEmployeePageable: function () {
            this.employees.splice(0);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllTeamEmployeePageable(this.employeePageCount, this.currentEmployeePage, localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.employees = response.data;
                        // console.log("=====Employees=====", this.employees);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getEmployeeSearchCount: function (employeeKeyword) {
            // console.log("search pagecount", employeeKeyword);
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getEmployeeSearchCount(localStorage.getItem('tenant-uuid'), employeeKeyword.toLowerCase())
                    .then((response) => {
                        this.totalEmployeeCount = response.data;
                        // console.log("search pagecount", response.data);
                        this.getAllEmployeeSearchPageable(
                            this.currentEmployeePage,
                            this.employeePageCount,
                            employeeKeyword.toLowerCase()
                        );
                        this.searchEmployeePagination = true;
                        this.paginationEmployees = false;
                        this.currentEmployeePage = 1;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllEmployeeSearchPageable: function (currentEmployeePage, employeePageCount, employeeKeyword) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getAllEmployeeSearchPageable(
                        currentEmployeePage,
                        employeePageCount,
                        localStorage.getItem('tenant-uuid'),
                        employeeKeyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.employees = response.data;
                        // console.log("=====employees=====", response.data);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        userUpdateModalShow(user) {
            this.updateUser = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                contact: user.email[0],
                mobile: user.mobile[0],
            }
            this.$refs.userUpdateModal.show();
        },

        async userEmployeeUpdateModalShow(employee) {
            this.disabledButton = false;
            // console.log("=====Employee=====", employee);
            // await this.getEmployeeById(user.id);
            // console.log("=====SelectedEmployeeDetails=====", this.selectedEmployeeDetails);
            this.updateEmployee = {
                // userId: this.selectedEmployeeDetails.userId,
                // firstName: this.selectedEmployeeDetails.firstName,
                // lastName: this.selectedEmployeeDetails.lastName,
                // contact: this.selectedEmployeeDetails.email,
                // mobile: this.selectedEmployeeDetails.contact,
                // employeeCode: this.selectedEmployeeDetails.employeeCode,
                // salaryDetails: this.selectedEmployeeDetails.salaryDetails,
                // weekOff: this.selectedEmployeeDetails.weekOff,

                userId: employee.userId,
                firstName: employee.firstName,
                lastName: employee.lastName,
                contact: employee.email,
                mobile: employee.contact,
                employeeCode: employee.employeeCode,
                salaryDetails: employee.salaryDetails,
                weekOff: employee.weekOff,

            }
            // console.log("=====UpdateEmployee=====", this.updateEmployee);
            this.$refs.userEmployeeUpdateModal.show();
        },

        getEmployeeById: function (userId) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AttendanceEmployeeService.getEmployeeUserDetails(userId)
                    .then(response => {
                        // this.selectedEmployeeDetails.splice(0);
                        this.selectedEmployeeDetails = response.data;
                        // console.log("=====selectedEmployeeDetails=====", this.selectedEmployeeDetails);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },

        userDetailUpdate: function () {
            // console.log("==this.user===", this.updateUser);
            this.$v.updateUser.firstName.$touch();
            if (!this.$v.updateUser.firstName.$invalid) {
                return new Promise((resolve, reject) => {
                    Account.nameUpdate(this.updateUser)
                        .then((response) => {
                            // console.log("====userDetailUpdate----", response.data);
                            this.userDetailUpdateRest();
                            resolve(response);
                        })
                        .catch((err) => {
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },

        employeeDetailUpdate: function () {
            // console.log("==this.updateEmployee===", this.updateEmployee);
            this.$v.updateEmployee.$touch();
            if (this.$v.updateEmployee.$invalid) {
                this.submitStatus = "FAIL";
            } else {
                return new Promise((resolve, reject) => {
                    AttendanceEmployeeService.getEmployeeCode(this.updateEmployee.employeeCode)
                        .then((response) => {
                            if (response.data == true) {
                                // console.log("=====Response Data=====", response.data);
                                AttendanceEmployeeService.updateEmployeeDetails(this.updateEmployee.userId, this.updateEmployee)
                                    .then((response) => {
                                        this.$swal({
                                            title: "Update",
                                            text: "Employee details updated successfully...!!!",
                                            icon: "success",
                                            button: "ok",
                                        });
                                        // this.getAllUserCount();
                                        this.getAllEmployeeCount();
                                        this.employeeKeyword = '';
                                        this.$refs.userEmployeeUpdateModal.hide();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        this.disabledButton = false;
                                        reject(err);
                                    });
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Employee Code is already exist!",
                                });
                            this.disabledButton = false;
                            // resolve(response);
                        });

                });
            }
            this.disabledButton = false;
        },

        async userDetailUpdateRest() {
            await this.userUpdateWithAgent();
            this.disabledButton = false;
            this.$refs.userUpdateModal.hide();
            this.$swal({
                title: "Update",
                text: "User details updated successfully...!!!",
                icon: "success",
                button: "ok",
            });
            this.getAllUserCount();
        },
        userUpdateWithAgent: function () {
            return new Promise((resolve, reject) => {
                AgentService.userUpdateWithAgent(this.updateUser)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        rolePrivilegeModal(user) {
            this.$refs.rolePrivilegeModal.show();
            this.user = user;
            this.selectedUserId = this.user.id;
            this.getUserAddedRoles(this.selectedUserId);
            this.getUserNotAddedRoles(this.selectedUserId);
            this.getAllBranches();
        },
        getUserAddedRoles: function (userId) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.getUserAddedRoles(userId)
                    .then(response => {
                        this.addedUserRoles.splice(0);
                        this.addedUserRoles = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        getUserNotAddedRoles: function (userId) {
            this.isLoading = true;
            return new Promise((resolve, reject) => {
                Account.getUserNotAddedRoles(userId)
                    .then(response => {
                        this.notAddedUserRoles = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        addRolesModal: function (roles, userId) {
            if (roles.length != 0) {
                if (!this.roleValidation(roles)) {
                    this.$v.branch.$touch();
                    this.$v.branches.$touch();
                    if (this.addSelectedRoles.roleName == 'ROLE_COUNSELLOR') {
                        if (!this.$v.branch.$invalid) {
                            this.agentCounsellorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_SUPERVISOR') {
                        if (!this.$v.branch.$invalid) {
                            this.agentSupervisorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        if (!this.$v.branches.$invalid) {
                            this.agentAdminSupervisorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_TELECALLER') {
                        this.telecallerRoleActivate(userId);
                    } else {
                        this.addUserRoles(userId);
                    }
                }
            }
        },
        agentCounsellorActivate: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branch: this.branch,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateCounsellor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentSupervisorActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branch: this.branch,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateSupervisor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentAdminSupervisorActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branches: this.branches,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateAdminSupervisor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        telecallerRoleActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            this.addUserRoles(userId);
                            this.resetBranchSelect();
                            this.getAllBranches();
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        roleValidation(roles) {
            let roleTelecaller = false;
            let roleCounsellor = false;
            let roleSupervisor = false;
            let roleAdminSupervisor = false;
            let roleName = "";
            let roleError = false;
            if (roles.roleName == 'ROLE_TELECALLER') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                        roleName = 'ROLE_COUNSELLOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                        roleName = 'ROLE_SUPERVISOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleCounsellor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_COUNSELLOR') {
                this.addedUserRoles.forEach(e => {
                    // if (e.roleName == 'ROLE_SUPERVISOR') {
                    //     roleSupervisor = true;
                    //     roleName = 'ROLE_SUPERVISOR';
                    //     roleError = true;
                    // }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                // if (roleSupervisor) {
                //     this.$swal({
                //         title: "please remove " + roleName,
                //         icon: "error",
                //         button: "ok",
                //     });
                // }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_SUPERVISOR') {
                this.addedUserRoles.forEach(e => {
                    // if (e.roleName == 'ROLE_COUNSELLOR') {
                    //     roleCounsellor = true;
                    //     roleName = 'ROLE_COUNSELLOR';
                    //     roleError = true;
                    // }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                // if (roleCounsellor) {
                //     this.$swal({
                //         title: "please remove " + roleName,
                //         icon: "error",
                //         button: "ok",
                //     });
                // }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                        roleName = 'ROLE_COUNSELLOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleCounsellor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else {
                roleError = false;
            }
            return roleError;
        },
        addUserRoles: function (userId) {
            let data = {
                roles: [],
            };
            data.roles.push({
                roleId: this.addSelectedRoles.roleId,
            });
            return new Promise((resolve, reject) => {
                Account.addSelectedUserRoles(userId, data)
                    .then(response => {
                        this.addedUserRoles = [];
                        this.notAddedUserRoles = [];
                        this.addSelectedRoles = [];
                        this.deleteSelectedRoles = [];
                        this.$swal({
                            type: "success",
                            title: "Selected roles added Successfully!"
                        });
                        this.getUserAddedRoles(userId);
                        this.getUserNotAddedRoles(userId);
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        this.$swal({
                            type: "error",
                            title: "Selected roles adding failed!"
                        });
                        reject(err);
                    });
            });
            // }
        },
        removeUserRoles: function (userId) {
            if (this.deleteSelectedRoles.length !== 0) {
                let data = {
                    roles: [],
                };
                let roleCounsellor = false;
                let roleSupervisor = false;
                let roleAdminSupervisor = false;
                for (let i = 0; i < this.deleteSelectedRoles.length; i++) {
                    data.roles.push({
                        roleId: this.deleteSelectedRoles[i].roleId,
                    });
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                    }
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                    }
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                    }
                }
                return new Promise((resolve, reject) => {
                    Account.removeSelectedUserRoles(userId, data)
                        .then(response => {
                            if (roleCounsellor) {
                                this.deactivateCounsellor(userId);
                            }
                            if (roleSupervisor) {
                                this.deactivateSupervisor(userId);
                            }
                            if (roleAdminSupervisor) {
                                this.deactivateAdminSupervisor(userId);
                            }
                            this.addedUserRoles = [];
                            this.notAddedUserRoles = [];
                            this.addSelectedRoles = [];
                            this.deleteSelectedRoles = [];
                            this.$swal({
                                type: "success",
                                title: "Selected roles removed Successfully!"
                            });
                            this.getUserAddedRoles(userId);
                            this.getUserNotAddedRoles(userId);
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            this.$swal({
                                type: "error",
                                title: "Selected roles removing failed!"
                            });
                            reject(err);
                        });
                });
            }
        },
        deactivateCounsellor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateCounsellor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deactivateSupervisor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateSupervisor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deactivateAdminSupervisor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateAdminSupervisor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // teamUserSearch(keyword) {
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //         Account.teamUserSearch(keyword.toLowerCase())
        //             .then((response) => {
        //                 this.users.splice(0);
        //                 if (response.data != null && response.data != "") {
        //                     this.users.push(response.data)
        //                 } else {
        //                     this.users = [];
        //                 }
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        teamUserSearch(keyword) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.teamUserSearch(keyword.toLowerCase())
                    .then((response) => {
                        this.users.splice(0);
                        if (response.data != null && response.data != "") {
                            this.users.push(response.data)
                        } else {
                            this.users = [];
                        }
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // onSearch(keyword) {
        //     // console.log("=====KeyWord=====",keyword);
        //     // if (keyword !== null) {
        //     // console.log("=====If Calling=====");
        //     return new Promise((resolve, reject) => {
        //         Account.teamUserSearchByName(keyword.toLowerCase())
        //             .then((response) => {
        //                 // this.searchResults.splice(0);
        //                 if (response.data != null && response.data != "") {
        //                     // this.searchResults.push(response.data)
        //                     this.searchResults = response.data
        //                     // console.log("=====SearchResults=====",this.searchResults);
        //                 } else {
        //                     this.searchResults = [];
        //                 }
        //                 // this.searchResults.splice(0);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        //     // }else{
        //     //     console.log("=====Else Calling=====");
        //     //     this.searchResults.splice(0);
        //     // }
        // },

        onSearch: function (keyword) {
            // console.log("=====Type=====",this.selectedSearchType);
            if (this.selectedSearchType == "username") {
                // console.log("=====On Search If Calling=====");
                this.onSearchByName(keyword);
            }
            if (this.selectedSearchType == "contact") {
                // console.log("=====On Search else Calling=====");
                this.onSearchByContact(keyword)
            }
        },

        onSearchByName(keyword) {
            // console.log("=====KeyWord=====",keyword);
            // if (keyword !== null) {
            // console.log("=====If Calling=====");
            this.totalCount = 0;
            return new Promise((resolve, reject) => {
                Account.teamUserSearchByName(keyword.toLowerCase())
                    .then((response) => {
                        // this.searchResults.splice(0);
                        if (response.data != null && response.data != "") {
                            // this.searchResults.push(response.data)
                            this.searchResults = response.data
                            // console.log("=====SearchResults=====",this.searchResults);
                        } else {
                            this.searchResults.splice(0);
                            // console.log("=====Name Else Calling=====");
                            // this.searchResults = [];
                            this.searchResults.push({username:"No User Found...!!!"});
                            // console.log("=====this.searchResults=====",this.searchResults);
                        }
                        // this.searchResults.splice(0);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
            // }else{
            //     console.log("=====Else Calling=====");
            //     this.searchResults.splice(0);
            // }
        },

        onSearchByContact(keyword) {
            // console.log("=====KeyWord=====",keyword);
            // if (keyword !== null) {
            // console.log("=====If Calling=====");
            this.totalCount = 0;
            return new Promise((resolve, reject) => {
                Account.teamUserSearchByContact(keyword.toLowerCase())
                    .then((response) => {
                        // this.searchResults.splice(0);
                        if (response.data != null && response.data != "") {
                            // this.searchResults.push(response.data)
                            this.searchResults = response.data
                            // console.log("=====SearchResults=====",this.searchResults);
                        } else {
                            this.searchResults.splice(0);
                            // console.log("=====Contact Else Calling=====");
                            // this.searchResults = [];
                            this.searchResults.push({username:"No User Found...!!!"});
                            // console.log("=====this.searchResults=====",this.searchResults);
                        }
                        // this.searchResults.splice(0);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
            // }else{
            //     console.log("=====Else Calling=====");
            //     this.searchResults.splice(0);
            // }
        },

        searchByName(result) {
            // if(result != null || result != ""){

            // }
            // console.log("=====Result=====",result);
            this.users.splice(0);
            this.users.push(result)
            this.searchResults.splice(0);
            // this.users = result;
            // this.users = Object.values(result);
        },

        getAllRoles() {
            return new Promise((resolve, reject) => {
                Account.getAllRoles()
                    .then((response) => {
                        if (response.data != null) {
                            this.allRoles = response.data.filter(function (e) {
                                return e.roleName !== 'ROLE_USER' && e.roleName !== 'ROLE_SUPER_ADMIN'
                            })
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        formatDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        refresh() {
            if (this.keyword != "") {
                this.keyword = "";
                this.selectedSearchType = "username";
            } else {
                this.getAllUserCount();
                this.searchResults.splice(0);
                this.selectedSearchType = "username";
            }
        },
        refreshEmployee() {
            this.employeeKeyword = "";
            this.getAllEmployeeCount();
        },
        resetBranchSelect() {
            this.allBranches = [],
                this.allBranchesBranch = [],
                this.selectedBranch = null,
                this.branch = null,
                this.branches = [],
                this.$nextTick(() => {
                    this.$v.branch.$reset();
                    this.$v.branches.$reset();
                });
        },
        resetUser() {
            this.disabledButton = false;
            this.user = {
                    firstName: "",
                    lastName: "",
                    username: "",
                    contact: "",
                },
                this.allBranches = [],
                this.allBranchesBranch = [],
                this.selectedBranch = null,
                this.branch = null,
                this.office = null,
                this.branches = [],
                this.$nextTick(() => {
                    this.$v.user.$reset();
                    this.$v.branch.$reset();
                    this.$v.branches.$reset();
                    // this.$v.selectedBranch.$reset();
                });
        },
        getAllBranches: function () {
            this.allBranches.splice(0);
            this.allBranchesBranch.splice(0);
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranchesBranch = response.data;
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addUserModalClose() {
            this.$refs.rolePrivilegeModal.hide()
            this.resetBranchSelect();
            this.selectedUserId = null;
            this.addedUserRoles = [];
            this.notAddedUserRoles = [];
            this.addSelectedRoles = [];
            this.deleteSelectedRoles = [];
        },
        removeBranches: function (item) {
            const langIndex = this.branches.findIndex(x => x === item);
            if (langIndex !== undefined)
                this.branches.splice(langIndex, 1);
            this.allBranches.push(item);
        },
        saveBranches: function () {
            if (!this.branches.some(entry => entry === this.selectedBranch)) {
                let branch = null;
                for (var i = 0; i < this.allBranches.length; i++) {
                    if (this.allBranches[i] == this.selectedBranch) {
                        this.branches.push(this.allBranches[i]);
                        branch = this.allBranches[i];
                        // this.allBranches.splice([i], 1);
                    }
                }
                this.allBranches = this.allBranches.filter(function (e) {
                    return e !== branch
                })
            }
            this.selectedBranch = null;
        },
        getOfficeDetail() {
            OfficeService.GetOfficeDetail()
                .then((res) => {
                    this.allOffices = res.data;
                    // console.log("=====Offices=====",this.offices);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        // timeChange(value) {
        //     const hours = Math.floor(value * 24);
        //     const minutes = Math.floor((value * 24 * 60) % 60);
        //     const seconds = Math.floor((value * 24 * 60 * 60) % 60);
        //     return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        // },

        timeChange(value) {
            const totalMinutes = Math.floor(value * 24 * 60);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const period = hours < 12 ? 'AM' : 'PM';

            // Convert hours to 12-hour format
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

            return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
        },

        sendPasswordToUser(user) {
            console.log("=====User=====", user);
            console.log("=========Employee Password Mail Sent=========");
            this.updateUserPassword = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                contact: user.email[0],
                mobile: user.mobile[0],
            }
            console.log("=====UpdateUserPassword=====", this.updateUserPassword);
            return new Promise((resolve, reject) => {
                Account.employeePasswordSend(this.updateUserPassword)
                    .then((response) => {
                        this.$swal({
                            title: "Password Send",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        // console.log("=====Employe Password Sent SuccessFully");
                        resolve(response);
                        // console.log("======UpdatePasswordSend Response======",response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        deleteEntry(index) {
            // console.log("=====Index=====", index);
            this.bulkUsers.splice(index, 1);
            this.bulkUsersDetails.splice(index, 1);
            // console.log("this.bulkUsers", this.bulkUsers);
            // console.log("this.bulkUsersDetails", this.bulkUsersDetails);
        },
        resetValue() {
            // this.loading = true;
            // window.location.reload();
            this.file = null;
            this.office = null;
            this.bulkUsers.splice(0);
            this.disabledButton = false
            // this.submitButton = false;
            // this.verifyButton = true;
            // // this.bulkUsers = [];
            // this.loadingData = true;
        },

    }
};
</script>

<style scoped>
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}

.cellSize {
    max-width: 120px;
}

.custom-column {
    width: 200px;
    /* Adjust the width as needed */
}

.searches:hover {
    cursor: pointer;
}
.disabled-span {
    /* color: gray; */
    pointer-events: none;
    /* opacity: 0.5; */
    /* display: inline-block; */
  }
</style>
