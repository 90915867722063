<template lang="en">
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-tabs pills card content-class="mt-3" justified>

        <b-tab title="CALL LOGS" active>
            <b-container class="mt-5">
                <b-row align-v="center">
                    <!-- <b-col col lg="1" md="1" id="r1" sm="6"> -->
                    <!-- <b-button variant="outline-primary" size="sm" disabled>
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                    <span class="mx-1">CallLog</span>
                </b-button> -->
                    <!-- </b-col> -->
                    <b-col col sm="6" md="3" lg="4" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" placeholder="search a contact here" v-on:keyup.enter="getAllSearchCount(keyword)" />
                    </b-col>
                    <b-col col lg="1" md="2" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" @input="switchPage()" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" @input="switchPage()" v-model="pageCount" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="tableRefresh">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <b-row>
                                <b-col class="my-1" lg="3" md="6" id="r1" sm="12">
                                    <b-form-select class="bnclr" v-model="selectedType" size="sm" @change="getAllCallLogSortCount(selectedType,selectedAgent,selectedDates)" :options="typeSelect">
                                    </b-form-select>
                                </b-col>
                                <b-col class="my-1" lg="3" md="6" id="r1" sm="12">
                                    <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="getAllCallLogSortCount(selectedType,selectedAgent,selectedDates)">
                                        <b-form-select-option disabled value="Agent">
                                            <p>Agents</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllAgents">
                                            <p>All Agents</p>
                                        </b-form-select-option>
                                        <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.firstName }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col class="my-1" lg="3" md="6" id="r1" sm="12">
                                    <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getAllCallLogSortCount(selectedType,selectedAgent,selectedDates)" :options="dateSelect">
                                    </b-form-select>
                                </b-col>
                                <b-col class="my-1" lg="3" md="6" id="r1" sm="12">
                                    <b-button v-if="totalCount != 0" variant="success" size="sm" @click="callLogExcelExport()">Export to excel</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <hr />
                    <b-row align-v="center">
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <b-btn class="btn-sm activate-btn shadow border-0" variant="primary">
                                <!-- <font-awesome-icon icon="user" />  -->
                                Total : {{sortTypeCount.totalCount}}
                            </b-btn>
                        </b-col>
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <b-btn class="btn-sm activate-btn shadow border-0" style="background-color:#3D9688;">
                                <!-- <font-awesome-icon icon="user" />  -->
                                Incoming : {{sortTypeCount.incomingCount}}
                            </b-btn>
                        </b-col>
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <div>
                                <b-btn class="btn-sm activate-btn shadow border-0" style="background-color:#4ca9df;">
                                    <!-- <font-awesome-icon icon="user" />  -->
                                    Total Outgoing : {{sortTypeCount.outgoingCount}}
                                </b-btn>
                            </div>
                            <div class="pt-1">
                                <b-btn class="btn-sm activate-btn shadow border-0" style="background-color:#4ca9df;">
                                    <!-- <font-awesome-icon icon="user" />  -->
                                    Unique Outgoing : {{sortTypeCount.outgoingUniqueCount}}
                                </b-btn>
                            </div>
                        </b-col>
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <b-btn class="btn-sm activate-btn shadow border-0" style="background-color:#ed2301;">
                                <!-- <font-awesome-icon icon="user" />  -->
                                Missed : {{sortTypeCount.missedCount}}
                            </b-btn>
                        </b-col>
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <b-btn class="btn-sm activate-btn shadow border-0" variant="warning">
                                <!-- Total Duration : {{sortTypeCount.totalDuration}} sec -->
                                Duration : {{countdown(sortTypeCount.totalDuration)}}
                            </b-btn>
                        </b-col>
                        <b-col class="my-1" sm="12" md="4" lg="2" id="r1">
                            <b-btn class="btn-sm activate-btn shadow border-0" variant="secondary">
                                Avg Duration : {{countdown(sortTypeCount.averageDuration)}}
                            </b-btn>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-table hover responsive :items="callLogs" :fields="fields" :per-page="callLogs.pageCount" :current-page="currentPage" ref="table">
                        <!-- <template v-slot:cell(createdAt)="data">
                    <div class="cellSize">
                        {{ dateFormat(data.item.createdAt, "fullDate") }}
                    </div>
                </template> -->

                        <template v-slot:cell(type)="data">
                            <span v-if="data.item.type == 1">
                                <img src="../../public/incoming-call.png" width="25">
                            </span>
                            <span v-else-if="data.item.type == 2">
                                <img src="../../public/outgoing-call.png" width="25">
                            </span>
                            <span v-else-if="data.item.type == 3">
                                <img src="../../public/missed-call.png" width="25">
                            </span>
                            <span v-else-if="data.item.type == 4">
                                <img src="../../public/unknown-call.png" width="35">
                            </span>
                            <span v-else>
                                <img src="../../public/call-other.png" width="35">
                            </span>
                        </template>
                        <template v-slot:cell(contact)="data">
                            <span class="text-primary contact" @click="callHistoryModalShow(data.item.contact)">{{ data.item.contact }}</span>
                            <!-- <a href="">{{ data.item.contact }}</a> -->
                        </template>
                        <template v-slot:cell(agent)="data">
                            <span>{{ data.item.agent.firstName }} {{ data.item.agent.lastName }}</span>
                        </template>
                        <template v-slot:cell(duration)="data">
                            <!-- <span>{{ data.item.duration }} sec</span> -->
                            <span v-if="data.item.duration != 0">{{formattedTime(data.item.duration)}}</span>
                            <span v-else>not connected</span>
                        </template>
                        <template v-slot:cell(date)="data">
                            <div class="cellSize">
                                {{ dateFormat(data.item.date, "fullDate") }}
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
            <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid,}" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid,}" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button variant="outline-success" type="submit" @click="getAllCallLogSortCount(selectedType,selectedAgent,selectedDates)">Submit</b-button>
                </div>
            </b-modal>
            <b-modal ref="callHistory" hide-footer hide-header size="md">
                <h5>Call History</h5>
                <div>{{currentContact}}</div>
                <hr>
                <div class="text-center" v-if="callHistoryLogs.length == 0">
                    No History Found...
                </div>
                <div v-else>
                    <div v-for="c in callHistoryLogs" :key="c.callLogId" class="py-1">
                        <div class="row">
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <span v-if="c.type == 1">
                                    <img src="../../public/incoming-call.png" width="30">
                                </span>
                                <span v-else-if="c.type == 2">
                                    <img src="../../public/outgoing-call.png" width="30">
                                </span>
                                <span v-else-if="c.type == 3">
                                    <img src="../../public/missed-call.png" width="30">
                                </span>
                                <span v-else-if="c.type == 4">
                                    <img src="../../public/unknown-call.png" width="45">
                                </span>
                                <span v-else>
                                    <img src="../../public/call-other.png" width="40">
                                </span>
                            </div>
                            <div class="col-10">
                                <div>
                                    <span>
                                        {{dateFormat(c.date,"fullDate")}}
                                    </span>
                                </div>
                                <div class="text-secondary">

                                    <span v-if="c.type == 1">
                                        <!-- incoming <span>{{ c.duration }}</span> seconds -->
                                        incoming <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                                    </span>
                                    <span v-else-if="c.type == 2">
                                        <!-- outgoing <span>{{ c.duration }}</span> seconds -->
                                        outgoing <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                                    </span>
                                    <span v-else-if="c.type == 3">
                                        missed call
                                    </span>
                                    <span v-else-if="c.type == 4">
                                        <!-- <span>{{ c.duration }}</span> seconds -->
                                        unknown call <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                                    </span>
                                    <span v-else>
                                        <span v-if="c.duration != 0">
                                            <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                                        </span>
                                        <span v-else>
                                            no seconds
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- <span v-if="c.type == 1">
                        <img src="../../public/incoming-call.png" width="20">
                    </span>
                    <span v-else-if="c.type == 2">
                        <img src="../../public/outgoing-call.png" width="20">
                    </span>
                    <span v-else-if="c.type == 3">
                        <img src="../../public/missed-call.png" width="20">
                    </span>
                    <span v-else-if="c.type == 4">
                        <img src="../../public/unknown-call.png" width="20">
                    </span>
                    <span v-else>
                        <img src="../../public/call-other.png" width="20">
                    </span>
                    <span>
                        {{dateFormat(c.date,"fullDate")}}
                    </span> -->
                            <!-- <span>
                        <vue-moments-ago prefix="" suffix="ago" :date="c.date" lang="en"></vue-moments-ago>
                    </span> -->
                        </div>
                    </div>
                </div>
            </b-modal>
        </b-tab>

        <b-tab title="AGENT STATUS" @click="getAgentLastCallLog()">
            <b-row class="text-end mx-5">

                <span style="color: #0974ff; cursor: pointer" @click="agentStatusRefresh">
                    <font-awesome-icon icon="sync-alt" />Refresh</span>

            </b-row>
            <b-table class="" responsive hover :items="lastCallLog" :fields="callLogFields" ref="table">

                <template v-slot:cell(agentname)="data">
                    <div class="text-center " style="">
                        {{ data.item[1]}}
                    </div>
                </template>

                <template v-slot:cell(lastcalllog)="data">
                    <div class=" text-center">
                        {{ agentDateFormat(data.item[2], "fullDate") }}
                    </div>
                </template>

            </b-table>

        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import axios from 'axios';
import Account from '../service/Account.js';
import moment from "moment-timezone";
import AgentService from '../service/AgentService';
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'CallLogs',
    data() {
        return {
            lastCallLog: [],
            callLogFields: [{
                    key: "",
                    label: ""
                },
                {
                    key: "agentname",
                    label: "Agent Name"
                },
                {
                    key: "lastcalllog",
                    label: "Last Call Log"
                },

            ],
            callLogs: [],
            fields: [{
                    key: "type",
                    label: "",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "agent",
                    label: "Agent",
                },
                {
                    key: "duration",
                    label: "Duration",
                    sortable: true
                },
                {
                    key: "date",
                    label: "Date",
                }
            ],
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            keyword: "",
            loading: false,
            allAgents: [],
            selectedAgent: 'Agent',
            from: "",
            to: "",
            selectedDates: null,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            selectedType: null,
            typeSelect: [{
                    value: null,
                    text: "Type",
                    disabled: true
                },
                {
                    value: 0,
                    text: "All Type"
                },
                {
                    value: 1,
                    text: "Incoming Call"
                },
                {
                    value: 2,
                    text: "Outgoing Call"
                },
                {
                    value: 3,
                    text: "Missed Call"
                },
                {
                    value: 4,
                    text: "Unknown Call"
                },
            ],
            currentContact: "",
            callHistoryLogs: [],
            sortTypeCount: {
                averageDuration: 0,
                incomingCount: 0,
                missedCount: 0,
                outgoingCount: 0,
                outgoingUniqueCount: 0,
                totalCount: 0,
                totalDuration: 0,
                unknowCount: 0,
            },
            sortingPagination: false,
            searchPagination: false,
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        }
    },
    validations: {
        from: {
            required
        },
        to: {
            required
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllCallLogSortCount(null, "AllAgents", null);
            }
        },
    },
    mounted() {

        // console.log(this.countdown(2104));
        if (localStorage.getItem("agentId") != null && localStorage.getItem("agentId") != "") {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.from = "";
                        this.to = "";
                        this.getAllCallLogSortCount(null, "AllAgents", null);
                        this.getAllAgents();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } else {
            this.$swal.fire({
                icon: 'error',
                title: 'Your role has been Removed',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    methods: {

        getAgentLastCallLog() {

            this.sortingPagination = false,
                this.searchPagination = true,
                this.loading = true;
            return new Promise((resolve, reject) => {
                AgentService.getAgentLastCallLog()
                    .then((response) => {
                        this.lastCallLog = response.data;
                        resolve(response);
                        this.loading = false;

                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        agentDateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            } else {
                return null;
            }
        },
        getAllSearchCount: function (keyword) {
            this.currentPage = 1;
            this.sortingPagination = false,
                this.searchPagination = true,
                this.loading = true;
            return new Promise((resolve, reject) => {
                axios
                    .get("admin/callLog/search/count/" + keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSearchPageable();
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/callLog/search/pageable/" + (this.currentPage - 1) + "/" + this.pageCount + "/" + this.keyword.toLowerCase())
                    .then((response) => {
                        this.callLogs = response.data;
                        console.log("------this.callLogs------", this.callLogs);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        countdown(s) {

            const d = Math.floor(s / (3600 * 24));

            s -= d * 3600 * 24;

            const h = Math.floor(s / 3600);

            s -= h * 3600;

            const m = Math.floor(s / 60);

            s -= m * 60;

            const tmp = [];

            (d) && tmp.push(d + 'ds');

            (d || h) && tmp.push(h + 'h');

            (d || h || m) && tmp.push(m + 'm');

            tmp.push(s + 's');

            return tmp.join(' ');
        },
        callLogExcelExport: function () {
            this.$swal.fire({
                title: 'Are your sure?',
                text: "Export " + this.totalCount + " data to excel sheet",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Export'
            }).then((result) => {
                if (result.isConfirmed) {
                    // this.$swal.fire({
                    //     position: 'center',
                    //     icon: 'uploaded',
                    //     title: 'It\'s take a few seconds',
                    //     showConfirmButton: false,
                    //     timer: 1000
                    // })
                    let timerInterval
                    this.$swal.fire({
                        allowOutsideClick: false,
                        title: 'Exporting',
                        html: 'It\'s take a few seconds',
                        timer: 10000,
                        timerProgressBar: true,
                        didOpen: () => {
                            this.$swal.showLoading()
                            const b = this.$swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = this.$swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    // this.loading = true;
                    console.log("========callLogExcelExport=======", this.data);
                    return new Promise((resolve, reject) => {
                        axios
                            .post("admin/callLog/sort/excel/export", this.data)
                            .then((response) => {
                                this.$swal.close();
                                console.log("callLogExcelExport=====", response.data);
                                this.download(response.data);
                                this.loading = false;
                                resolve(response);
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                }
            })
        },
        download(fileName) {
            window.open(this.$reportResourceLocation + fileName);
        },
        callHistoryModalShow(contact) {
            this.loading = true;
            let data = {
                contact: contact,
            }
            this.currentContact = contact;
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/callLog/get/call/history", data)
                    .then((response) => {
                        this.callHistoryLogs = response.data;
                        this.$refs.callHistory.show();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        agentStatusRefresh(){
            this.getAgentLastCallLog();
        },

        tableRefresh() {
            this.getDateRangeReset();
            // this.keyword
            this.selectedType = null;
            this.selectedDates = null;
            this.selectedAgent = "AllAgents";
            if (this.keyword != '') {
                this.keyword = '';
            } else {
                this.getAllCallLogSortCount(this.selectedType, this.selectedAgent, this.electedDates);
            }
        },
        getAllCallLogSortCount(selectedType, selectedAgent, selectedDates) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                var fromType;
                var toType;
                if (selectedType != null) {
                    if (selectedType == 0) {
                        fromType = 1;
                        toType = 1000;
                    } else if (selectedType == 1) {
                        fromType = selectedType;
                        toType = selectedType;
                    } else if (selectedType == 2) {
                        fromType = selectedType;
                        toType = selectedType;
                    } else if (selectedType == 3) {
                        fromType = selectedType;
                        toType = selectedType;
                    } else if (selectedType == 4) {
                        fromType = selectedType;
                        toType = selectedType;
                    }
                } else {
                    fromType = 1;
                    toType = 1000;
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$v.from.$touch();
                        this.$v.to.$touch();
                        // this.$refs.callDateRange.hide();
                        // this.fromDate = "2023-03-28 00:01";
                        // this.toDate = new Date();
                        if (this.$v.from.$invalid && this.$v.to.$invalid) {
                            this.$swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "select from date and to date",
                            });
                            return 0;
                        }
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm:ss:SSS"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm:ss:SSS"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2023-03-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format("yyyy-MM-DD HH:mm:ss:SSS");
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm:ss:SSS");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    from: from,
                    to: to,
                    fromType: fromType,
                    toType: toType
                };
                this.getAllCount();
                this.loading = true;
                this.currentPage = 1;
                this.sortingPagination = true;
                this.searchPagination = false;
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/callLog/sort/count", this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllCallLogSortPageable();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        switchPage() {
            if (this.sortingPagination) {
                this.getAllCallLogSortPageable();
            } else if (this.searchPagination) {
                this.getAllSearchPageable();
            }
        },
        getAllCallLogSortPageable() {
            return new Promise((resolve, reject) => {
                axios.post('admin/callLog/sort/pageable/' + this.currentPage + "/" + this.pageCount, this.data)
                    .then((response) => {
                        this.callLogs = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllCount: function () {
            return new Promise((resolve, reject) => {
                axios.post('admin/callLog/sort/type/count', this.data)
                    .then((response) => {
                        console.log("getAllCount==========", response.data);
                        this.sortTypeCount = {
                            averageDuration: response.data.averageDuration,
                            incomingCount: response.data.incomingCount,
                            missedCount: response.data.missedCount,
                            outgoingCount: response.data.outgoingCount,
                            outgoingUniqueCount: response.data.outgoingUniqueCount,
                            totalCount: response.data.totalCount,
                            totalDuration: response.data.totalDuration,
                            unknowCount: response.data.unknowCount,
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        formattedTime(seconds) {
            let minute = Math.floor(seconds / 60);
            let remaining_seconds = seconds % 60;
            if (minute != 0 && remaining_seconds == 0) {
                return minute + " min ";
            } else if (minute != 0) {
                return minute + " min " + remaining_seconds + " sec ";
            } else {
                return remaining_seconds + " sec ";
            }
        },
        getDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
    },
}
</script>

<style scoped>
.contact:hover {
    text-decoration: underline;
    cursor: pointer;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 150px;
}
</style>
