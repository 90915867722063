<template>
<div>
          <!-- for loader -->
          <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    <div v-if="isRoleCounsellor == false">
  
        <!-- Admission table and details start -->
        <div class="mt-5">
            <b-container>
                <b-row align-v="center">
                    <!-- admission disabled -->
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Admission</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <b-row>
                                <!-- <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr">
                    <template>
                      <b-form-select-option disabled
                        ><p>Agents</p></b-form-select-option
                      >
                    </template>
                    <option
                      v-for="item in allAgents"
                      :value="item"
                      v-bind:key="item.index"
                    >
                      <strong>{{ item.name }}</strong>
                    </option>
                  </b-form-select>
                </b-col> -->

                                <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch,
                          selectedDates
                        )
                      ">
                                        <b-form-select-option disabled value="Courses">
                                            <p>Courses</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllCourses">
                                            <p>All Courses</p>
                                        </b-form-select-option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch,
                          selectedDates
                        )
                      ">
                                        <b-form-select-option disabled value="Sources">
                                            <p>Sources</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllSources">
                                            <p>All Sources</p>
                                        </b-form-select-option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch,
                          selectedDates
                        )
                      ">
                                        <b-form-select-option disabled value="Branches">
                                            <p>Branches</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllBranches">
                                            <p>All Branches</p>
                                        </b-form-select-option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch,
                          selectedDates
                        )
                      " :options="dateSelect">>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <hr />
                    <b-table responsive hover :items="allAdmissions" :fields="fields" :current-page="currentPage" :per-page="allAdmissions.pageCount" ref="table">
                        <template v-slot:cell(admissionId)="data">
                            <span class="title"> {{ data.item.admissionId }}</span>
                        </template>
                        <template v-slot:cell(callCreatedAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.callCreatedAt, "fullDate") }}
                            </div>
                        </template>
                        <!-- <template v-slot:cell(agent)="data">
              {{ data.item.agent.firstName }}
            </template> -->
                        <template v-slot:cell(name)="data">
                            {{ data.item.name }}
                        </template>
                        <template v-slot:cell(contact)="data">
                            {{ data.item.contact }}
                            <span v-if="data.item.alternativeContact != null">,</span>
                            {{ data.item.alternativeContact }}
                        </template>
                        <template v-slot:cell(course)="data">
                            {{ data.item.course.name }}
                        </template>
                        <template v-slot:cell(source)="data">
                            {{ data.item.source.name }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(createdAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Admission Details" v-b-modal.viewAdmission @click="getAdmission(data.item)" size="sm" class="mx-1">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
            <!-- View Admission Start -->
            <b-container>
                <b-modal id="viewAdmission" no-close-on-backdrop hide-footer title="View Admission">
                    <div class="form-control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="admission.name" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input v-model="admission.contact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Alternative Contact</label>
                                <b-form-input v-model="admission.alternativeContact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input v-model="admission.age" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-input class="form-control" disabled v-model="admission.qualification">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-input class="form-control" v-model="admission.course.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-input class="form-control" v-model="admission.source.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-input class="form-control" v-model="admission.mode" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input class="form-control" v-model="admission.branch.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <b-table responsive bordered striped hover :items="followups" :fields="followupDateAndRemark">
                            <template v-slot:cell(remark)="data">
                                <span class="title"> {{ data.item.remark }}</span>
                            </template>
                            <!-- <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template> -->
                            <template v-slot:cell(createdAt)="data">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template>
                        </b-table>
                    </div>
                </b-modal>
            </b-container>
            <!-- View Admission End -->
        </div>
    </div>
    <!-- =============================================COUNSELLOR=============================================== -->
    <div v-else>
        <!-- counsellor -->
        <div class="mt-5">
            <b-container>
                <b-row align-v="center">
                    <!-- admission disabled -->
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Admission</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="counsellorKeyword" v-on:keyup.enter="getCounsellorSearchCount(counsellorKeyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCounsellorSearchCount(counsellorKeyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="counsellorSwitchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="counsellorSwitchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="counsellorRefresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <b-row>
                                <!-- <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr">
                    <template>
                      <b-form-select-option disabled
                        ><p>Agents</p></b-form-select-option
                      >
                    </template>
                    <option
                      v-for="item in allAgents"
                      :value="item"
                      v-bind:key="item.index"
                    >
                      <strong>{{ item.name }}</strong>
                    </option>
                  </b-form-select>
                </b-col> -->

                                <b-col col lg="4" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedCourses" size="sm" @change="getCounsellorCourseCount(selectedCourses)">
                                        <b-form-select-option disabled value="Courses">
                                            <p>Courses</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="allCourses">
                                            <p>All Courses</p>
                                        </b-form-select-option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="4" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedSources" size="sm" @change="getCounsellorSourceCount(selectedSources)">
                                        <b-form-select-option disabled value="Sources">
                                            <p>Sources</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="allSources">
                                            <p>All Sources</p>
                                        </b-form-select-option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <!-- <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="selectedBranches"
                    size="sm"
                    @change="getBranchCount(selectedBranches)"
                  >
                    <b-form-select-option disabled value="Branches">
                      <p>Branches</p>
                    </b-form-select-option>
                    <b-form-select-option value="allBranches">
                      <p>All Branches</p>
                    </b-form-select-option>
                    <option
                      v-for="item in allBranches"
                      :value="item"
                      v-bind:key="item.index"
                    >
                      <strong>{{ item.name }}</strong>
                    </option>
                  </b-form-select>
                </b-col> -->

                                <b-col col lg="4" md="2" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedDates" @change="getCounsellorDateCount(selectedDates)" :options="dateSelect">>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <hr /> 
                    <b-table responsive hover :items="allAdmissions" :fields="fields" :current-page="currentPage" :per-page="allAdmissions.pageCount" ref="table">
                       
                        <template v-slot:cell(admissionId)="data">
                            <span class="title"> {{ data.item.admissionId }}</span>
                        </template>
                        <template v-slot:cell(callCreatedAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.callCreatedAt, "fullDate") }}
                            </div>
                        </template>
                        <!-- <template v-slot:cell(agent)="data">
              {{ data.item.agent.firstName }}
            </template> -->
                        <template v-slot:cell(name)="data">
                            {{ data.item.name }}
                        </template>
                        <template v-slot:cell(contact)="data">
                            {{ data.item.contact }}
                            <span v-if="data.item.alternativeContact != null">,</span>
                            {{ data.item.alternativeContact }}
                        </template>
                        <template v-slot:cell(course)="data">
                            {{ data.item.course.name }}
                        </template>
                        <template v-slot:cell(source)="data">
                            {{ data.item.source.name }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(createdAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Admission Details" v-b-modal.viewAdmission @click="getAdmission(data.item)">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <b-button size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="View Payment Details" v-b-modal.viewPaymentDetails @click="getHistoryById(data.item)">
                                <i class="fas fa-receipt"></i>
                            </b-button><br />
                            <b-button size="sm" v-if="data.item.balanceAmount != 0 && data.item.balanceAmount != null " class="mt-1 fas fa-window-close" variant="secondary" @click="getInvoiceById(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" v-b-modal.partialPaymentModal title="Pending Payment Request"></b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
            <!-- View Admission Start -->
            <b-container>
                <b-modal id="viewAdmission" no-close-on-backdrop hide-footer title="View Admission">
                    <div class="form-control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="admission.name" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input v-model="admission.contact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Alternative Contact</label>
                                <b-form-input v-model="admission.alternativeContact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input v-model="admission.age" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-input class="form-control" disabled v-model="admission.qualification">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-input class="form-control" v-model="admission.course.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-input class="form-control" v-model="admission.source.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-input class="form-control" v-model="admission.mode" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input class="form-control" v-model="admission.branch.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <b-table responsive bordered striped hover :items="followups" :fields="followupDateAndRemark">
                            <template v-slot:cell(remark)="data">
                                <span class="title"> {{ data.item.remark }}</span>
                            </template>
                            <!-- <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template> -->
                            <template v-slot:cell(createdAt)="data">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template>
                        </b-table>
                    </div>
                </b-modal>
            </b-container>
            <!-- View Admission End -->

            <div>
                <!--popup start for View Payment Details-->
                <b-modal id="viewPaymentDetails" title="View Payment Details" hide-footer>
                    <div v-if="allInvoicesHistory.length != 0" class="control">
                        <b-table responsive bordered striped hover :items="allInvoicesHistory" :fields="invoiceDateAndRemark">
                            <template v-slot:cell(invoiceNumber)="data">
                                <div class="cellSize">
                                    <!-- {{ data.item.invoiceNumber }} -->
                                    <span  style="color: #0974ff;">{{ data.item.invoiceNumber }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.created_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(advanceAmount)="data">
                                <span v-if="data.item.advanceAmount == null">{{ data.item.totalAmount}}</span>
                                <span v-else>{{ data.item.advanceAmount }}</span>
                            </template>
                            <template v-slot:cell(balanceAmount)="data">
                                <span v-if="data.item.balanceAmount == null">0</span>
                                <span v-else>{{ data.item.balanceAmount }}</span>
                            </template>
                        </b-table>
                    </div>

                    <b-card v-else class="text-center">
                        <span>No data found</span>
                    </b-card>
                </b-modal>
                <!--popup end for View Payment Details-->
            </div>

            <b-modal v-model="partialPaymentModal" size="lg" hide-footer hide-header>
                    <part-payment-apply :disableButton="false" :invoicedata="invoice" :courseType="courseType" :organization="organization" @close-modal="closeModal" @close-icon="closeIcon"></part-payment-apply>
                </b-modal>

        </div>
    </div>
    <!-- Admission table and details end -->

    <!-- data range select User modal start-->
    <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedCourse,
              selectedSource,
              selectedBranch,
              selectedDates
            )
          ">Submit</b-button>
        </div>
    </b-modal>

    <!-- data range select modal User end-->

    <!-- data range select modal Counsellor start-->
    <b-modal ref="counsellorDateRange" hide-footer id="counsellorDateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="fromCounsellorDate" :class="{
            'is-invalid': $v.fromCounsellorDate.$error,
            'is-valid': !$v.fromCounsellorDate.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="toCounsellorDate" :class="{
            'is-invalid': $v.toCounsellorDate.$error,
            'is-valid': !$v.toCounsellorDate.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="getCounsellorDateRange()">Submit</b-button>
        </div>
    </b-modal>

    <!-- data range select Counsellor modal end-->

    <!-- Pdf Printing Invoice Data  Start-->

    <div id="reportpdf" class="hidden" style="width: 1000px;">
        <div class="row">
            <div class="col-11">
                <div class="row" style="background-color: #f59d9d;width: 970px">
                    <div class="card-header">
                        <b-row>
                            <b-col cols="6" id="heading1">
                                <h4><b>Invoice</b></h4>
                            </b-col>
                            <b-col cols="6" id="heading2">
                                <h4><b>{{invoiceDetailsById.organization}}</b></h4>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <!-- Billing Information -->
        <b-row class="mt-3">
            <b-col cols="12" sm="6">
                <div class="row" style="font-weight: 600; color: rgb(77, 71, 71); font-size: large;"> BILLING TO : </div>
                <div class="row mx-lg mt-sm" style="color: rgb(99, 88, 88);"> {{invoiceDetailsById.userName}} </div>
                <div class="row mx-lg mt-sm " style="color: rgb(99, 88, 88);"> {{invoiceDetailsById.email}} </div>
                <div class="row mx-lg mt-sm " style="color: rgb(99, 88, 88);"> {{invoiceDetailsById.contact}} </div>
                <div class="row mx-lg mt-sm mt-4 " style="color: rgb(99, 88, 88);"> Course Name: {{invoiceDetailsById.courseName}} </div>
                <div class="row mx-lg mt-sm " style="color: rgb(99, 88, 88);"> PLACE OF SUPPLY: TAMILNADU </div>
            </b-col>

            <b-col cols="12" sm="6">
                <div v-if="invoiceDetailsById.advanceAmount == null" class="row mx-lg mt-sm mt-3 " style="color: rgb(99, 88, 88);"> AMOUNT PAID:&nbsp;₹ {{invoiceDetailsById.totalAmount}} </div>
                <div v-else class="row mx-lg mt-sm mt-3 " style="color: rgb(99, 88, 88);"> AMOUNT PAID:&nbsp;₹ {{invoiceDetailsById.advanceAmount}} </div>
                <div class="row mx-lg mt-sm " style="color: rgb(99, 88, 88);"> INVOICE NO:{{invoiceDetailsById.invoiceNumber}} </div>
                <div v-if="invoiceDetailsById.dueDate != null" class="row mx-lg mt-sm mt-5 " style="color: rgb(99, 88, 88);"> NEXT DUE DATE: {{ callDate(invoiceDetailsById.dueDate, "onlyDate") }}</div>
                <div v-if="invoiceDetailsById.balanceAmount != null" class="row mx-lg mt-sm " style="color: rgb(99, 88, 88);"> BALANCE AMOUNT:&nbsp;₹ {{invoiceDetailsById.balanceAmount}} </div>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <div class="row" style="background-color: #f59d9d;width: 970px">
                <div class="card-header">
            <b-row>
            <b-col cols="1">
                <h6><b>Description</b></h6>
            </b-col>
            <b-col cols="6">
                <h6><b>Unit Price</b></h6>
            </b-col>
            <b-col cols="3">
                <h6><b>User Amount</b></h6>
            </b-col>
        </b-row>
    </div>
        </div>
            <b-col cols="1">
                <!-- <div><b>Description</b></div> -->
                <div class="mt-2 "><b>{{invoiceDetailsById.courseName}}</b></div>
                <div class="mt-2 "><b>PaidAmount</b></div>
                <div class="mt-2" v-if="invoiceDetailsById.balanceAmount != null"><b>BalanceAmount</b></div>
            </b-col>
            <b-col cols="6">
                <!-- <div><b>Unit Price</b></div> -->
                <div class="mt-2" v-if="invoiceDetailsById.advanceAmount == null"><b>{{invoiceDetailsById.totalAmount}}</b></div>
                <div class="mt-2" v-else><b>{{invoiceDetailsById.advanceAmount}}</b></div>
                <br>
                <br>
                <br>
                <div class="mt-5 "><b>Total Paid</b></div>
            </b-col>
            <b-col cols="2">
                <!-- <div><b>User Amount</b></div> -->
                <div class="mt-2" v-if="invoiceDetailsById.advanceAmount != null" ><b>{{invoiceDetailsById.advanceAmount}}</b></div>
                <div class="mt-2" v-else><b>{{invoiceDetailsById.totalAmount}}</b></div>
                <div class="mt-2" v-if="invoiceDetailsById.advanceAmount != null"><b>{{invoiceDetailsById.advanceAmount}}</b></div>
                <div class="mt-2" v-else><b>{{invoiceDetailsById.totalAmount}}</b></div>
                <div class="mt-2" v-if="invoiceDetailsById.balanceAmount != null"><b>{{invoiceDetailsById.balanceAmount}}</b></div>
                <div class="mt-2"><span>Tax Included</span></div>
                <div class="mt-4" v-if="invoiceDetailsById.advanceAmount != null"><b>{{invoiceDetailsById.advanceAmount}}</b></div>
                <div class="mt-5" v-else><b>{{invoiceDetailsById.totalAmount}}</b></div> 
            </b-col>
        </b-row>
    </div>

    <!-- Pdf Printing Invoice Data  End-->

</div>
</template>

<script>
// import datetime from "vuejs-datetimepicker";
import moment from "moment-timezone";
import axios from "axios";
import Account from '../service/Account.js';
import AdmissionService from "../service/AdmissionService";
import FollowupService from "../service/FollowupService";
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import InvoiceService from "../service/InvoiceService";
import PartPaymentApply from './PartPaymentApply.vue';
import TeamService from "../service/TeamService";
import {
    required
} from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
    name: "Admission",
    components: {
        PartPaymentApply
        // datetime,
    },
    data() {
        return {
            // modalRender: false,
            //object send to API from URL
            data: "",
            //switchPage condition check
            paginationAdmissions: false,
            searchPaginationAdmissions: false,
            sortCoursePaginationAdmissions: false,
            sortSourcePaginationAdmissions: false,
            sortBranchPaginationAdmissions: false,
            sortDatePaginationAdmissions: false,
            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            allAdmissions: [],
            invoiceDetailsById: [],
            organization: "",
            fromDate: "",
            toDate: "",
            // datetime: "",
            fields: [{
                    key: "createdAt",
                    label: "Admission_Date",
                    tdClass: "dateCell",
                },
                // { key: "Agent", label: "AgentName" },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                
                {
                    key: "course",
                    label: "Course"
                },
                // {
                //     key: "source",
                //     label: "Source"
                // },
                // {
                //     key: "mode",
                //     label: "Mode"
                // },
                {
                    key: "paymentMethod",
                    label: "Payment Method"
                },
                // {
                //     key: "branch",
                //     label: "Branch"
                // },
               
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            admission: {
                callCreatedAt: "",
                name: "",
                contact: "",
                alternativeContact: "",
                course: "",
                source: "",
                mode: "",
                branch: "",
                createdAt: "",
            },
            followups: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            allInvoicesHistory: [],
            invoiceDateAndRemark: [{
                    key: "invoiceNumber",
                    label: "Invoice No",
                },
                {
                    key: "created_at",
                    label: "Created Date",
                },
                {
                    key: "advanceAmount",
                    label: "Amount Paid",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
            ],

            // for loader
            loading: true,
            // for pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            partialPaymentModal: false,
            pageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            // for search in table
            keyword: "",
            counsellorKeyword: "",
            //local storage as get an agentId
            agentId: "",
            selectedCourse: "Courses",
            selectedSource: "Sources",
            selectedBranch: "Branches",
            selectedDates: null,
            selectedCourses: "Courses",
            selectedSources: "Sources",
            invoice : '',
            courseType : '',
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            from: "",
            to: "",
            fromCounsellorDate: "",
            toCounsellorDate: "",
            isRoleCounsellor: false,
        };
    },
    mounted() {
        if (localStorage.getItem("agentId") != null) {
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            this.counsellorData();

                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else {
                this.isRoleCounsellor = false;
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            // this.getAllFollowups();
                            this.getCount();
                            // this.getAllPageable(this.currentPage,this.pageCount);
                            this.getAllCourses();
                            this.getAllSources();
                            this.getAllBranches();
                            // this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Account Deactivated',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
        counsellorKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCounsellorAdmissionCount();
            }
        },
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
        fromCounsellorDate: {
            required,
        },
        toCounsellorDate: {
            required,
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async counsellorData() {
            await this.setCounsellor();
            //  console.log("counsellor count callng")
            await this.getCounsellorAdmissionCount();
            //  console.log("getAllCourses callng")
            await this.getAllCourses();
            //  console.log("getAllSources callng")
            await this.getAllSources();
            
        },
        setCounsellor() {
            this.branch = JSON.parse(localStorage.getItem("branch"));
            this.admission.branch = this.branch;
            // console.log(this.admission.branch);
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                this.isRoleCounsellor = true;
            } else {
                this.isRoleCounsellor = false;
            }
        },
        // getAllAdmissions: function () {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AdmissionService.getAllAdmissions(this.agentId)
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         console.log("allAdmissions", this.allAdmissions);
        //         this.loading = false;
        //         this.getCount();
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getAllSortCount: function (
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedCourse == "Courses" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Sources" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branches" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortAdmissions = true;
                this.paginationAdmissions = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admission/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/admission/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.allAdmissions = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAdmission: function (admission) {
            this.admission = admission;
            // console.log("admission", admission);
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(admission.callId)
                FollowupService.getFollowupCreatedDate(admission.callId)
                    .then((response) => {
                        // console.log("admission.admissionId",admission.admissionId);
                        this.followups = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getHistoryById: function (admission) {
            console.log("=====Admission=====",admission);
            this.admission = admission;
            this.admissionId = admission.admissionId
            return new Promise((resolve, reject) => {
                InvoiceService.getAllInvoiceHistory(this.admissionId)
                    .then((response) => {
                        this.allInvoicesHistory.splice(0);
                        this.allInvoicesHistory = response.data;
                        console.log("=====AllInvoicesHistory=====", this.allInvoicesHistory)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getCount: function () {
            this.loading = true;
            this.allSortAdmissions = false;
            this.paginationAdmissions = true;
            this.searchPagination = false;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            // this.loading = true;
            // this.paginationAdmissions = true;
            // this.searchPaginationAdmissions = false;
            // this.sortCoursePaginationAdmissions = false;
            // this.sortSourcePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            // this.sortDatePaginationAdmissions = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdmissionService.getCount(this.agentId)
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("getCount", this.totalCount);
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationAdmissions) {
                // console.log(this.paginationAdmissions, "paginationAdmissions");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortAdmissions) {
                // console.log(this.allSortAdmissions, "allSortAdmissions");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
            // if (this.paginationAdmissions) {
            //   this.getAllPageable(currentPage, pageCount);
            // } else if (this.searchPaginationAdmissions) {
            //   this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            // } else if (this.sortCoursePaginationAdmissions) {
            //   this.getAllCoursePageable(currentPage, pageCount, this.data);
            // } else if (this.sortSourcePaginationAdmissions) {
            //   this.getAllSourcePageable(currentPage, pageCount, this.data);
            // } else if (this.sortBranchPaginationAdmissions) {
            //   this.getAllBranchPageable(currentPage, pageCount, this.data);
            // } else if (this.sortDatePaginationAdmissions) {
            //   this.getAllDatePageable(currentPage, pageCount, this.data);
            // }
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllPageable(currentPage, pageCount, this.agentId)
                    .then((response) => {
                        this.allAdmissions = response.data;
                        // console.log("getAllPageable", this.allAdmissions);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // getDateCount: function () {
        //   // console.log("Datecount");
        //   return new Promise((resolve, reject) => {
        //     AdmissionService.getDateCount(this.agentId, this.selectedDates)
        //       .then((response) => {
        //         this.totalCount = response.data;
        //         // console.log(response.data, " Admissions Count By Dates");
        //         // this.getAllDatePageable(data);
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAllDatePageable: function (data) {
        //   // console.log("Datepageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdmissionService.getAllDatePageable(
        //       this.currentPage,
        //       this.pageCount,
        //       this.agentId,
        //       this.selectedDates,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getSearchCount: function (keyword) {
            this.loading = true;
            this.searchPagination = true;
            this.paginationAdmissions = false;
            this.allSortAdmissions = false;
            this.currentPage = 1;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            // this.paginationAdmissions = false;
            // this.searchPaginationAdmissions = true;
            // this.sortCoursePaginationAdmissions = false;
            // this.sortSourcePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            // this.sortDatePaginationAdmissions = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdmissionService.getSearchCount(this.agentId, keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCourseCount: function (selectedCourses) {
            this.loading = true;
            this.selectedSources = "Sources";
            this.selectedBranches = "Branches";
            this.selectedDates = null;
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = true;
            this.sortSourcePaginationAdmissions = false;
            this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            if (selectedCourses == "allCourses") {
                this.getCount();
            } else {
                this.data = {
                    courseId: selectedCourses.courseId,
                };
                return new Promise((resolve, reject) => {
                    AdmissionService.getCourseCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllCoursePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCoursePageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCoursePageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getSourceCount: function (selectedSources) {
            this.loading = true;
            this.selectedCourses = "Courses";
            this.selectedBranches = "Branches";
            this.selectedDates = null;
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = true;
            this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            if (selectedSources == "allSources") {
                this.getCount();
            } else {
                this.data = {
                    sourceId: selectedSources.sourceId,
                };
                return new Promise((resolve, reject) => {
                    AdmissionService.getSourceCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllSourcePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSourcePageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllSourcePageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getBranchCount: function (selectedBranches) {
            this.loading = true;
            this.selectedCourses = "Courses";
            this.selectedSources = "Sources";
            this.selectedDates = null;
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = false;
            this.sortBranchPaginationAdmissions = true;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            if (selectedBranches == "allBranches") {
                this.getCount();
            } else {
                this.data = {
                    branchId: selectedBranches.branchId,
                };
                return new Promise((resolve, reject) => {
                    AdmissionService.getBranchCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllBranchPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllBranchPageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllBranchPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getDateCount: function (selectedDates) {
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = false;
            this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = true;
            this.currentPage = 1;
            this.loading = true;
            if (selectedDates == "dateRange") {
                this.$refs.dateRange.show();
                this.selectedDates = null;
                this.getDateRangeReset();
                this.loading = false;
            } else {
                var date = new Date();
                if (selectedDates == "today") {
                    this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDates == "yesterday") {
                    this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDates == "lastWeek") {
                    this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedDates == "lastMonth") {
                    this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toDate = new Date(date.setHours(719, 59, 59, 0));
                }
                this.fromDateFormat = moment(String(this.fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.toDateFormat = moment(String(this.toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    from: this.fromDateFormat,
                    to: this.toDateFormat,
                };

                return new Promise((resolve, reject) => {
                    // console.log();
                    AdmissionService.getDateCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllDatePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getDateRange: function () {
            this.$v.from.$touch();
            this.$v.to.$touch();
            if (this.$v.from.$invalid && this.$v.to.$invalid) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
                var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                this.data = {
                    from: from,
                    to: to,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    AdmissionService.getDateCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getDateRangeReset();
                            this.getAllDatePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllDatePageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                AdmissionService.getAllDatePageable(
                        currentPage,
                        pageCount,
                        data,
                        this.agentId
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getDateRangeReset: function () {
            this.$refs.dateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        // searchName: function (keyword) {
        //   return new Promise((resolve, reject) => {
        //     AdmissionService.searchName(keyword.toLowerCase(), this.agentId)
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.getSearchCount(keyword.toLowerCase());
        //         this.currentPage = 1;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        // console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        // console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getCourseDetail: function (course) {
        //   this.loading = true;
        //   this.selectedSources = "Sources";
        //   this.selectedBranches = "Branches";
        //   this.selectedDates = "Dates";
        //   if (this.selectedCourses == "allCourses") {
        //     this.getCount();
        //   } else {
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admission/sort/course/" + this.agentId, data)
        //         .then((response) => {
        //           // console.log(response.data);
        //           this.allAdmissions = response.data;
        //           this.getCourseCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getSourceDetail: function (source) {
        //   this.loading = true;
        //   this.selectedCourses = "Courses";
        //   // this.selectedSources = "Sources";
        //   this.selectedBranches = "Branches";
        //   // this.selectedAgents = "Agents";
        //   this.selectedDates = "Dates";
        //   if (this.selectedSources == "allSources") {
        //     // console.log("All Source Calling");
        //     this.getAllAdmissions();
        //     this.getCount();
        //     this.getAllPageable();
        //   } else {
        //     // console.log("Source Calling");
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admission/sort/source/" + this.agentId, data)
        //         .then((response) => {
        //           // console.log(response.data);
        //           this.allAdmissions = response.data;
        //           this.getSourceCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getBranchDetail: function (branch) {
        //   this.loading = true;
        //   this.selectedCourses = "Courses";
        //   this.selectedSources = "Sources";
        //   // this.selectedBranches = "Branches";
        //   // this.selectedAgents = "Agents";
        //   this.selectedDates = "Dates";
        //   if (this.selectedBranches == "allBranches") {
        //     // console.log("All Branch Calling");
        //     this.getAllAdmissions();
        //     this.getCount();
        //     this.getAllPageable();
        //   } else {
        //     // console.log("Branch Calling");
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admission/sort/branch/" + this.agentId, data)
        //         .then((response) => {
        //           // console.log(response.data);
        //           this.allAdmissions = response.data;
        //           this.getBranchCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getDateDetail: function () {
        //   return new Promise((resolve, reject) => {
        //     this.loading = true;
        //     AdmissionService.getToday()
        //       .then((response) => {
        //         console.log(response.data);
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAdmissionsBetweenDates: function () {
        //   this.selectedCourses = "Courses";
        //   this.selectedSources = "Sources";
        //   this.selectedBranches = "Branches";
        //   this.selectedAgents = "Agents";
        //   if (this.selectedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //   } else {
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       AdmissionService.getByDate(this.agentId, this.selectedDates)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getDateCount();
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getDateRange: function() {
        // //   let from=new Date(this.fromDate)
        // //  console.log(from.setHours(0,0,0,0),"00000000")
        //   // let to=new Date(this.toDate);
        //   let data={
        //     fromDate:new Date(this.fromDate),
        //     toDate:new Date(this.toDate)
        //   }
        //   // console.log(data);
        //  return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       // console.log("calling Sort By Date");
        //       // AdmissionService.getToday()
        //       // console.log("Selected Date Range", this.selectedDates);
        //       AdmissionService.getDateRange(this.agentId,data)
        //         .then((response) => {
        //           // this.$refs.dateRange.hide();

        //           this.allAdmissions = response.data;
        //           // console.log(this.allAdmissions, "Admissions By Dates");
        //           // this.getDateCount();
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        // },
        // getDateRange: function () {
        //   this.from = "fromDate"
        //   this.to = "toDate"
        //   return new Promise((resolve, reject) => {
        //     this.loading = true;
        //     console.log("Selected Date Range", this.from);
        //     AdmissionService.getByDate(this.agentId, this.selectedDates)
        //       .then((response) => {
        //         console.log(response.data, "Admissions By Dates");
        //         this.allAdmissions = response.data;
        //         this.getDateCount();
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
            if (dateType === "onlyDate") {
                return moment(date1).format("D / MM / YYYY");
            }
        },
        refresh: function () {
            // this.getAllAdmissions();
            this.getCount();
            this.keyword = "";
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgents = "Agents";
            this.selectedDates = null;
        },
        // ============================Counsellor================================
        counsellorRefresh: function () {
            // this.getAllAdmissions();
            this.getCounsellorAdmissionCount();
            this.counsellorKeyword = "";
            this.selectedCourses = "Courses";
            this.selectedSources = "Sources";
            this.selectedAgents = "Agents";
            this.selectedDates = null;
        },
        getCounsellorAdmissionCount: function () {
            this.loading = true;
            this.paginationAdmissions = true;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = false;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            // console.log("getCounsellorCount Calling")
            //  this.branch = JSON.parse(localStorage.getItem("branch"))
            //  console.log(this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                AdmissionService.getCounsellorAdmissionCount(this.data)
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("count",this.totalCount)
                        this.getAllCounsellorAdmissionPageable(
                            this.currentPage,
                            this.pageCount,
                            this.data
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllCounsellorAdmissionPageable: function (currentPage, pageCount, data) {
            // console.log("getAllCounsellorAdmissionPageable calling")
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCounsellorAdmissionPageable(
                        currentPage,
                        pageCount,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        // console.log(response.data)
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        counsellorSwitchPage: function (currentPage, pageCount) {
            this.loading = true;

            if (this.paginationAdmissions) {
                // console.log("counsellorSwitchPage calling");
                this.getAllCounsellorAdmissionPageable(
                    currentPage,
                    pageCount,
                    this.data
                );
            } else if (this.searchPaginationAdmissions) {
                this.getAllCounsellorSearchPageable(
                    currentPage,
                    pageCount,
                    this.counsellorKeyword.toLowerCase(),
                    this.data
                );
            } else if (this.sortCoursePaginationAdmissions) {
                this.getAllCounsellorCoursePageable(currentPage, pageCount, this.data);
            } else if (this.sortSourcePaginationAdmissions) {
                this.getAllCounsellorSourcePageable(currentPage, pageCount, this.data);
            } else if (this.sortDatePaginationAdmissions) {
                this.getAllCounsellorDatePageable(currentPage, pageCount, this.data);
            }
        },

        getCounsellorSearchCount: function () {
            this.selectedSources = "Sources";
            this.selectedCourses = "Courses";
            this.selectedBranches = "Branches";
            this.selectedDates = null;
            // console.log("search pagecount", this.counsellorKeyword)
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                AdmissionService.getCounsellorSearchCount(
                        this.counsellorKeyword.toLowerCase(),
                        this.data
                    )
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("search pagecount", response.data)
                        this.sortCoursePaginationAdmissions = false;
                        this.sortSourcePaginationAdmissions = false;
                        this.sortBranchPaginationAdmissions = false;
                        this.sortDatePaginationAdmissions = false;
                        this.searchPaginationAdmissions = true;
                        this.paginationAdmissions = false;
                        this.currentPage = 1;
                        this.getAllCounsellorSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.counsellorKeyword.toLowerCase(),
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllCounsellorSearchPageable: function (
            currentPage,
            pageCount,
            counsellorKeyword,
            data
        ) {
            this.loading = true;
            // console.log("search page calling")
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCounsellorSearchPageable(
                        currentPage,
                        pageCount,
                        counsellorKeyword.toLowerCase(),
                        data
                    )
                    .then((response) => {
                        // console.log(response.data)
                        this.allAdmissions = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getCounsellorCourseCount: function (selectedCourses) {
            //  console.log("getCounsellorCourseCount calling")
            this.loading = true;
            this.selectedSources = "Sources";
            // this.selectedBranches = "Branches";
            this.selectedDates = null;
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortCoursePaginationAdmissions = true;
            this.sortSourcePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            if (selectedCourses == "allCourses") {
                this.getCounsellorAdmissionCount();
            } else {
                this.data = {
                    courseId: selectedCourses.courseId,
                };
                var branchId = this.branch.branchId;
                //   var courseId= selectedCourses.courseId;
                // console.log(this.data);
                return new Promise((resolve, reject) => {
                    AdmissionService.getCounsellorCourseCount(branchId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllCounsellorCoursePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorCoursePageable: function (currentPage, pageCount, data) {
            //  console.log("getAllCounsellorCoursePageable calling..")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCounsellorCoursePageable(
                        currentPage,
                        pageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorSourceCount: function (selectedSources) {
            //  console.log("getCounsellorSourceCount calling")
            this.loading = true;
            this.selectedCourses = "Courses";
            // this.selectedBranches = "Branches";
            this.selectedDates = null;
            this.paginationAdmissions = false;
            this.searchPaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = true;
            this.sortCoursePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = false;
            this.currentPage = 1;
            if (selectedSources == "allSources") {
                this.getCounsellorAdmissionCount();
            } else {
                this.data = {
                    sourceId: selectedSources.sourceId,
                };
                var branchId = this.branch.branchId;
                //   var courseId= selectedSources.courseId;
                // console.log(this.data);
                return new Promise((resolve, reject) => {
                    AdmissionService.getCounsellorSourceCount(branchId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllCounsellorSourcePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorSourcePageable: function (currentPage, pageCount, data) {
            //  console.log("getAllCounsellorSourcePageable calling..")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCounsellorSourcePageable(
                        currentPage,
                        pageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorDateCount: function (selectedDate) {
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";

            this.sortCoursePaginationAdmissions = false;
            this.sortSourcePaginationAdmissions = false;
            this.sortBranchPaginationAdmissions = false;
            this.sortDatePaginationAdmissions = true;
            this.searchPaginationAdmissions = false;
            this.paginationAdmissions = false;
            this.currentPage = 1;
            this.loading = true;
            if (selectedDate == "dateRange") {
                this.$refs.counsellorDateRange.show();
                this.selectedDate = null;
                this.getCounsellorDateRangeReset();
                this.loading = false;
            } else {
                var date = new Date();
                if (selectedDate == "today") {
                    this.fromCounsellorDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDate == "yesterday") {
                    this.fromCounsellorDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDate == "lastWeek") {
                    this.fromCounsellorDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedDate == "lastMonth") {
                    this.fromCounsellorDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(719, 59, 59, 0));
                }
                this.fromDateFormat = moment(String(this.fromCounsellorDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.toDateFormat = moment(String(this.toCounsellorDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    fromCounsellor: this.fromDateFormat,
                    toCounsellor: this.toDateFormat,
                    branchId: this.branch.branchId,
                };
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    AdmissionService.getCounsellorDateCount(branchId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllCounsellorDatePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorDatePageable: function (currentPage, pageCount, data) {
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                AdmissionService.getAllCounsellorDatePageable(
                        currentPage,
                        pageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorDateRange: function () {
            this.$v.fromCounsellorDate.$touch();
            this.$v.toCounsellorDate.$touch();
            if (
                this.$v.fromCounsellorDate.$invalid &&
                this.$v.toCounsellorDate.$invalid
            ) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.fromCounsellorDate);
                var toDateObject = new Date(this.toCounsellorDate);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var fromCounsellorDate = moment(String(fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                var toCounsellorDate = moment(String(toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    fromCounsellor: fromCounsellorDate,
                    toCounsellor: toCounsellorDate,
                    branchId: this.branch.branchId,
                };
                this.loading = true;
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    AdmissionService.getCounsellorDateCount(branchId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getCounsellorDateRangeReset();
                            this.getAllCounsellorDatePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getCounsellorDateRangeReset: function () {
            this.$refs.counsellorDateRange.hide();
            (this.fromCounsellorDate = ""),
            (this.toCounsellorDate = ""),
            this.$v.fromCounsellorDate.$reset();
            this.$v.toCounsellorDate.$reset();
        },

        async getDownload(data) {
            await this.getInvoiceDetailsById(data);
            await this.downloadInvoice();
        },

        getInvoiceDetailsById: function (data) {
            console.log("=====INVOICE ID=====", data.invoiceId);
            return new Promise((resolve, reject) => {
                InvoiceService.getInvoiceDetailsById(data.invoiceId)
                    .then((response) => {
                        // this.invoiceDetailsById.splice(0);
                        this.invoiceDetailsById = response.data;
                        console.log("=====invoiceDetailsById=====", this.invoiceDetailsById)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getInvoiceById: function (invoice) {
        console.log("============invoice========>",invoice)
         this.organizationdata();
            this.invoice = invoice;
            
        },
        closeModal: function () {
            console.log("=======closeIcon=======");
            this.partialPaymentModal = false;
            this.getCounsellorAdmissionCount();
            this.getAllCourses();
            this.getAllSources();
            this.getAllBranches();
        },
        closeIcon: function () {
            console.log("=======closeIcon=======");
            this.partialPaymentModal = false;
            this.courseType = null;
        },
        organizationdata: function () {
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.organization = response.data.organization;
                        this.partialPaymentModal = true;
                        console.log('organization==========================>',this.organization)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // downloadInvoice() {
        //     this.invoiceDoc = new jsPDF('p', 'pt', 'a4');
        //     return new Promise((resolve, reject) => {
        //         html2canvas(document.getElementById('reportpdf'), {
        //                 useCORS: true,
        //                 onclone: function (clonedDoc) {
        //                     clonedDoc.getElementById('reportpdf').style.display = 'inline-block';
        //                     clonedDoc.getElementById('reportpdf').style.maxHeight = "";
        //                     clonedDoc.getElementById('reportpdf').style.overflow = "auto";
        //                 }

        //             })
        //             .then(canvas => {
        //                 var contentWidth = canvas.width;
        //                 var contentHeight = canvas.height;
        //                 var pageHeight = contentWidth / 592.28 * 841.89;
        //                 var leftHeight = contentHeight;
        //                 var position = 0;
        //                 var imgWidth = 595.28;
        //                 var imgHeight = 595.28 / contentWidth * contentHeight;
        //                 if (leftHeight < pageHeight) {
        //                     this.invoiceDoc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 8, 20, imgWidth, imgHeight);
        //                 } else {
        //                     while (leftHeight > 0) {
        //                         this.invoiceDoc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 8, position, imgWidth, imgHeight)
        //                         leftHeight -= pageHeight;
        //                         position -= 840;
        //                         if (leftHeight > 0) {
        //                             this.invoiceDoc.addPage();
        //                         }
        //                     }
        //                 }
        //                 this.invoiceDoc.save('invoice.pdf');
        //                 resolve(canvas);
        //             })
        //             .catch(err => {
        //                 console.log(err);
        //                 reject(err);
        //             });
        //     });
        // },

        downloadInvoice() {
            this.invoiceDoc = new jsPDF('p', 'pt', 'a4');
            return new Promise((resolve, reject) => {
                // const originalViewportContent = document.querySelector('meta[name="viewport"]').content;
                // document.querySelector('meta[name="viewport"]').content = 'width=1200';
                document.getElementById('reportpdf').classList.remove('hidden');
                const container = document.getElementById('reportpdf');
                // const container = document.getElementById('reportpdf').classList.remove('hidden');

                // Temporarily store original font size and styles
                const originalStyles = Array.from(container.querySelectorAll('*')).map((element) => ({
                    element,
                    styles: window.getComputedStyle(element),
                }));

                // Apply font size to the container before rendering
                container.style.fontSize = '100%'; // Adjust the font size as needed

                html2canvas(container, {
                        useCORS: true,
                        scale: 2, // Adjust the scale as needed for better resolution
                    })
                    .then((canvas) => {
                        var contentWidth = canvas.width;
                        var contentHeight = canvas.height;
                        var pageHeight = (contentWidth / 592.28) * 841.89;
                        var leftHeight = contentHeight;
                        var position = 0;
                        var imgWidth = 595.28;
                        var imgHeight = (595.28 / contentWidth) * contentHeight;
                        if (leftHeight < pageHeight) {
                            this.invoiceDoc.addImage(
                                canvas.toDataURL('image/jpeg'),
                                'JPEG',
                                8,
                                20,
                                imgWidth,
                                imgHeight
                            );
                        } else {
                            while (leftHeight > 0) {
                                this.invoiceDoc.addImage(
                                    canvas.toDataURL('image/jpeg'),
                                    'JPEG',
                                    8,
                                    position,
                                    imgWidth,
                                    imgHeight
                                );
                                leftHeight -= pageHeight;
                                position -= 840;
                                if (leftHeight > 0) {
                                    this.invoiceDoc.addPage();
                                }
                            }
                        }

                        // Restore original font size and styles
                        originalStyles.forEach(({ element, styles }) => {
                            for (let i = 0; i < styles.length; i++) {
                                element.style.setProperty(styles[i], styles[styles[i]]);
                            }
                        });

                        this.invoiceDoc.save('invoice.pdf');
                        resolve(canvas);
                        // document.querySelector('meta[name="viewport"]').content = originalViewportContent;
                        document.getElementById('reportpdf').classList.add('hidden');
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
            });
        }

        // getCounsellorDateCount: function (selectedDates) {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   // this.selectedBranch = "Branch";
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortCoursePaginationAdmissions = false;
        //   this.sortSourcePaginationAdmissions = false;
        //   // this.sortBranchPaginationAdmissions = false;
        //   this.sortDatePaginationAdmissions = true;
        //   this.currentPage = 1;
        //   this.loading = true;
        //   if (selectedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedDates = null;
        //     this.getCounsellorDateRangeReset();
        //     this.loading = false;
        //   } else {
        //     var date = new Date();
        //     if (selectedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //       // console.log(this.fromDate,this.toDate)
        //     } else if (selectedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };

        //     return new Promise((resolve, reject) => {
        //       console.log();
        //       AdmissionService.getCounsellorDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllCounsellorDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getCounsellorDateRange: function () {

        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //      this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdmissionService.getCounsellorDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getCounsellorDateRangeReset();
        //           this.getAllCounsellorDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllCounsellorDatePageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdmissionService.getAllCounsellorDatePageable(currentPage, pageCount, data,this.agentId)
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        // getCounsellorDateRangeReset: function () {
        //   this.$refs.dateRange.hide();
        //   (this.from = ""), (this.to = ""), this.$v.from.$reset();
        //   this.$v.to.$reset();
        // },
        //   resetDate() {
        //   this.admission = {
        //     fromDate: null,
        //     toDate: null,
        //   };
        // },
    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dateCell {
    /* min-width: 80px; */
    max-width: 125px;
}

.hidden {
    display: none;
}

#heading1 {
    margin-left: -15%;
    padding-bottom: -200%;
}
</style>
