import axios from 'axios';
export default {
    saveBookBundle: function (data) {
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/bookBundle/save", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    saveBookOrder: function (data) {
        return new Promise((resolve, reject) => {
            axios
                .post("/bookOrder/save", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateBookOrder: function (data) {
        return new Promise((resolve, reject) => {
            axios
                .post("/bookOrder/update", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getOrder: function (orderId) {
        return new Promise((resolve, reject) => {
            axios
                .get("/bookOrder/get/"+orderId)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAgentOrders: function(currentPage,pageCount,data){
        return new Promise((resolve, reject) => {
            axios
                .post('/bookOrder/getAgentOrders/'+ (currentPage-1) + "/" + pageCount,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    
    getAllPageable: function(currentPage,pageCount){
        axios.defaults.headers.common["tenant-uuid"] =""
        return new Promise((resolve, reject) => {
            axios
                .post('/bookBundle/all/'+ (currentPage-1) + "/" + pageCount)
            // axios({
            //     method: 'post',
            //     url: '/bookBundle/all/'+ (currentPage-1) + "/" + pageCount, config
            // })
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCount: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/bookBundle/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAll: function(){
        // var ax = axios.create({
            //     baseURL: "http://localhost:9090",
            // }); 
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/bookBundle/getAll',
                }).then((response) => {                 
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });                    
        },
        archive: function(data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookBundle/archive',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        unArchive: function(data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookBundle/unArchive',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        archiveOrder: function(data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/archive',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        unArchiveOrder: function(data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/unArchive',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllBooked: function(currentPage,pageCount,data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/getAllBooked/'+ (currentPage-1) + "/" + pageCount,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllBookedCount: function(data){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/bookedCount/',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getBookedSearch: function(currentPage,pageCount,data,keyword){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/booked/search/'+ (currentPage-1) + "/" + pageCount + "/" + keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getBookedSearchCount: function(data,keyword){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/booked/search/count/'+keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllDispatched: function(currentPage,pageCount,data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/getAllDispatched/'+ (currentPage-1) + "/" + pageCount,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllDispatchedCount: function(data){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/dispatchedCount/',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllPartialDispatchedCount: function(data){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/partialCount/',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllPartialDispatched: function(currentPage,pageCount,data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/getAllPartial/'+ (currentPage-1) + "/" + pageCount,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getCurrentPartial: function(orderNumber){
            return new Promise((resolve, reject) => {
                axios
                    .get('/bookOrder/get/partial/'+ orderNumber)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        savePartialDelivered: function(data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/partial/delivered/save',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getPartialSearch: function(currentPage,pageCount,data,keyword){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/partial/search/'+ (currentPage-1) + "/" + pageCount + "/" + keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getPartialSearchCount: function(data,keyword){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/partial/search/count/'+keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getPendingSearch: function(currentPage,pageCount,data,keyword){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/pending/search/'+ (currentPage-1) + "/" + pageCount + "/" + keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getPendingSearchCount: function(data,keyword){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/pending/search/count/'+keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getDispatchedSearch: function(currentPage,pageCount,data,keyword){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/dispatched/search/'+ (currentPage-1) + "/" + pageCount + "/" + keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getDispatchedSearchCount: function(data,keyword){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/dispatched/search/count/'+keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllDelivered: function(currentPage,pageCount,data){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/getAllDelivered/'+ (currentPage-1) + "/" + pageCount,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getAllDeliveredCount: function(data){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/deliveredCount/',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getDeliveredSearch: function(currentPage,pageCount,data,keyword){
            return new Promise((resolve, reject) => {
                axios
                    .post('/bookOrder/delivered/search/'+ (currentPage-1) + "/" + pageCount + "/" + keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getDeliveredSearchCount: function(data,keyword){
            return new Promise((resolve, reject) => {
                axios
                .post('/bookOrder/delivered/search/count/'+keyword,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            });
        },

        saveDispatched: function (data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/bookOrder/dispatched/save", data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        savePartialDispatched: function (data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/bookOrder/partial/save", data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        saveDelivered: function (data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/bookOrder/delivered/save", data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
}