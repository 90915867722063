<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div class="container mt-3">
        <!-- Date picker START -->
        <!-- <b-modal size="sm" hide-header hide-footer ref="calenderModal" no-close-on-backdrop centered content-class="shadow"> -->
			<div v-if="calendarShow">
                <FullCalendar ref="fullCalendar" :options="calendarOptions" />
				<div class="text-center">
				</div>
			</div>
        <!-- </b-modal> -->
		<!-- Date picker END -->

		<!-- Attendance table report START -->
        <!-- <b-modal size="lg" hide-header hide-footer ref="reportTableModal" no-close-on-backdrop centered content-class="shadow"> -->
            <div class="mt-3" v-if="!calendarShow">
                <!-- <b-container> -->
                    <b-row align-v="center">
                        <b-col col lg="4" md="2" id="r1" sm="6" class="text-center">
                            <b-button size="sm" variant="primary" class="bnclr" @click="calendarShow = true">Click to Calender</b-button>
                        </b-col>
                        <!-- <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" placeholder="Type Name/Code to search" disabled/>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAttendanceSearchCount(keyword)" disabled>Search</b-button>
                        </b-col> -->
                        <b-col sm="12" md="5" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="5" lg="2" class="text-center" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="2" md="12" id="r1" sm="6" class="text-center">
                            <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                <!-- </b-container> -->
                <!-- <b-container> -->
                    <b-card class="card mt-1">
                        <hr />
                        <b-table responsive hover :items="attendances" :fields="attendanceFields" :current-page="currentPage" :per-page="attendances.pageCount" ref="table">
                            <template v-slot:cell(date)="data">
								{{ dateFormat(data.item.date, "date") }}
                            </template>
                            <template v-slot:cell(employee)="data">
                                {{ data.item.employee.firstName }}
                                <!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
                            </template>
                            <template v-slot:cell(code)="data">
                                {{ data.item.employee.code }}
                            </template>
                            <template v-slot:cell(status)="data">
                                <span v-if="data.item.status == 'ABSENT'">
                                    <b-badge style="background-color: #ff0000;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
                                    </b-badge>
                                </span>
                                <span v-else>
                                    <b-badge style="background-color: #228b22;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
                                    </b-badge>
                                </span>
                            </template>
                            <template v-slot:cell(present)="data">
                                <span v-if="data.item.present">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(absent)="data">
                                <span v-if="data.item.absent">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(fullDayPresent)="data">
                                <span v-if="data.item.fullDayPresent">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(halfDayPresent)="data">
                                <span v-if="data.item.halfDayPresent">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(outdoorDuty)="data">
                                <span v-if="data.item.outdoorDuty">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(overTime)="data">
                                <span v-if="data.item.overTime">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(holiday)="data">
                                <span v-if="data.item.holiday">
                                    <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                    </svg>
                                </span>
                                <span v-else>
                                    <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                    </svg>
                                </span>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Attendance" @click="viewAttendanceShow(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Attendance" @click="attendanceUpdateShow(data.item)">
                                    <font-awesome-icon icon="edit" />
                                </b-button>
                                <!-- <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                    <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button> -->
                            </template>
                        </b-table>
                    </b-card>
                <!-- </b-container> -->
            </div>
		<!-- </b-modal> -->
		<!-- Attendance table report END -->
    </div>
</div>
</template>

<script>
import Account from '../service/Account.js';
import AttendanceService from "../service/AttendanceService";
import moment from "moment-timezone";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
    name: 'AttendanceReport',
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                // select: this.select,
                selectable: true,
                // dayMaxEventRows: true,
                // views: {
                //     timeGrid: {
                //     dayMaxEventRows: 6
                //     }
                // },
                // events: [
                //     { title: 'event 1', date: '2022-10-18' },
                //     { title: 'event 2', date: '2022-10-21' }
                // ]
            },
            date: "",
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalCount: 0,
            attendances: [],
            attendanceFields: [{
                    key: "date",
                    label: "AttendanceDate",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "employee",
                    label: "Employee",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "code",
                    label: "code",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "status",
                    label: "Status",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "present",
                    label: "Present",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "absent",
                    label: "Absent",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "fullDayPresent",
                    label: "FullDay",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                {
                    key: "halfDayPresent",
                    label: "HalfDay",
					thClass: 'text-center',
					tdClass: 'text-center',
                },
                // {
                //     key: "outdoorDuty",
                //     label: "OD",
				// 	thClass: 'text-center',
				// 	tdClass: 'text-center',
                // },
                // {
                //     key: "overTime",
                //     label: "OT",
				// 	thClass: 'text-center',
				// 	tdClass: 'text-center',
                // },
                // {
                //     key: "holiday",
                //     label: "Holiday",
				// 	thClass: 'text-center',
				// 	tdClass: 'text-center',
                // }
            ],
			calendarShow: true,
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD', // Uses 'iso' if missing
			},
        }
    },
    watch: {
        date(newVal, oldVal) {
            if (oldVal != !newVal || oldVal == "") {
                this.attendanceReport();
            }
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    // this.$refs.calenderModal.show();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        handleDateClick: function(arg) {
            this.date = arg.dateStr;
            this.calendarShow = false;
            this.getAttendanceCount();
        },
        select: function (info) {
            alert("selected " + info.startStr + " to " + info.endStr);
        },
        async attendanceReport() {
            this.calendarShow = false;
            await this.getAttendanceCount();
        },
		calendarPickerShow() {
			this.calendarShow = true;
		},
        getAttendanceCount: function () {
            this.loading = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AttendanceService.getAttendanceCount(this.date)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAttendancePageable(this.currentPage, this.pageCount, this.date);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            this.getAllAttendancePageable(currentPage, pageCount, this.date);
        },
        getAllAttendancePageable: function (currentPage, pageCount, date) {
            return new Promise((resolve, reject) => {
                AttendanceService.getAllAttendancePageable(currentPage, pageCount, date)
                    .then((response) => {
                        this.attendances = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
		dateFormat: function (date, dateType) {
            const formatDate = new Date(date);
            if (dateType === "date") {
                return moment(formatDate).format("DD / MM / YYYY");
            }
            if (dateType === "dateTime") {
                return moment(formatDate).format("DD / MM / YYYY hh:mm a");
            }
        },
		refresh() {
            this.currentPage = 1;
			this.getAttendanceCount();
        },
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation:spin 0.5s linear infinite;
    animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
