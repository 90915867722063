import axios from 'axios';
export default{
    getAllAdmissions: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/admin/admissions',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAdmissionCount: function(){
        return new Promise((resolve, reject) => {
            axios.get('admin/admissionCount')
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllAdmissionPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.get('admin/admissionAll/'+ (currentPage-1) + "/" + pageCount)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getDateCount: function (data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/sortDate/count',data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllDatePageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/sortDate/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    searchAdmission: function(keyword){
        return new Promise((resolve, reject) => {
            axios.get('admin/searchAdmission/'+ keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAdmissionTeam: function(teamId){
        console.log("TEAMID",teamId);
        return new Promise((resolve, reject) => {
            axios.get('team/getAmissionTeam/' + teamId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

// ======ADMISSION SORTING START======

// getAdmissionCourse: function(name){
//     let config = {
//         headers: {
//             "Content-Type": "application/json"
//         }
//     };
//     let data={
//         name:name
//     }
//     return new Promise((resolve, reject) => {
//         axios
//             .post("/admin/admission/sortCourse", data, config)
//             .then(response => {
//                 this.getCourseCount(data);
//                 resolve(response);
//             })
//             .catch(err => {
//                 reject(err);
//             });
//     });
// },
// getAdmissionSource: function(name){
//     let config = {
//         headers: {
//             "Content-Type": "application/json"
//         }
//     };
//     let data={
//         name:name
//     }
//     return new Promise((resolve, reject) => {
//         axios
//             .post("/admin/admission/sortSource", data, config)
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(err => {
//                 reject(err);
//             });
//     });
// },
// getAdmissionBranch: function(name){
//     let config = {
//         headers: {
//             "Content-Type": "application/json"
//         }
//     };
//     let data={
//         name:name
//     }
//     return new Promise((resolve, reject) => {
//         axios
//             .post("/admin/admission/sortBranch", data, config)
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(err => {
//                 reject(err);
//             });
//     });
// },
// getAdmissionAgent: function(name){
//     let config = {
//         headers: {
//             "Content-Type": "application/json"
//         }
//     };
//     let data={
//         name:name
//     }
//     return new Promise((resolve, reject) => {
//         axios
//             .post("/admin/admission/sortAgent", data, config)
//             .then(response => {
//                 resolve(response);
//             })
//             .catch(err => {
//                 reject(err);
//             });
//     });
// },

// ======ADMISSION SORTING END======

// ======ADMISSION PAGINATION START======

getSearchCount: function(keyword){
    return new Promise((resolve, reject) => {
        axios.post('/admin/search/count/' + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllSearchPageable: function(currentPage,pageCount,keyword){
    return new Promise((resolve, reject) => {
        axios.post('/admin/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getCourseCount: function(course){
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortCourse/count',course)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllCoursePageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortCourse/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getSourceCount: function (source) {
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortSource/count' , source)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getAllSourcePageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortSource/all/' + (currentPage - 1) + "/" + pageCount ,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getBranchCount: function (branch) {
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortBranch/count',  branch)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getAllBranchPageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortBranch/all/' + (currentPage - 1) + "/" + pageCount ,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getAgentCount: function(agent){
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortAgent/count',agent)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllAgentPageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/admin/sortAgent/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
// ======ADMISSION PAGINATION END======

// getToday: function(){
//     return new Promise((resolve, reject) => {
//         axios({
//             method: 'get',
//             url: '/admin/sortDate',
//         }).then((response) => {
//             resolve(response);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// },

getAdmission: function(admissionId){
    return new Promise((resolve, reject) => {
        axios.post('/admin/get/'+ admissionId)
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
},

getByDate: function (selectedDates) {
    return new Promise((resolve, reject) => {
        axios
            .post('/admin/sort/date/'+  selectedDates)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}
}