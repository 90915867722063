import axios from 'axios';
export default{

    getAllEnquiries: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/adminenquiry/enquiries',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },
    getEnquiryCount: function(){
        return new Promise((resolve, reject) => {
            axios.get('adminenquiry/enquiryCount')
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllEnquiryPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.post('adminenquiry/enquiryAll/'+ (currentPage-1) + "/" + pageCount)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchEnquiry: function(keyword){
        return new Promise((resolve, reject) => {
            axios.get('adminenquiry/searchEnquiry/'+ keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // ======ENQUIRY SORTING START======

getEnquiryCourse: function(name){       
    let config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    let data={
        name:name
    }
    return new Promise((resolve, reject) => {
        axios
            .post("/adminenquiry/enquiry/sortCourse", data, config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });               
},
getEnquirySource: function(name){       
    let config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    let data={
        name:name
    }
    return new Promise((resolve, reject) => {
        axios
            .post("/adminenquiry/enquiry/sortSource", data, config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });               
},
getEnquiryBranch: function(name){       
    let config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    let data={
        name:name
    }
    return new Promise((resolve, reject) => {
        axios
            .post("/adminenquiry/enquiry/sortBranch", data, config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });               
},
getEnquiryAgent: function(name){       
    let config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    let data={
        name:name
    }
    return new Promise((resolve, reject) => {
        axios
            .post("/adminenquiry/enquiry/sortAgent", data, config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });               
},

// ======ENQUIRY SORTING END======
getSearchCount: function(keyword){
    return new Promise((resolve, reject) => {
        axios.post('adminenquiry/search/count/' + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllSearchPageable: function(currentPage,pageCount,keyword){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/search/'+ (currentPage-1) + "/" + pageCount +"/" + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getCourseCount: function(course){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/coursesort/count',course)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllCoursePageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/coursesort/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getSourceCount: function(source){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/sourcesort/count',source)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllSourcePageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/sourcesort/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getBranchCount: function(branch){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/branchsort/count',branch)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllBranchPageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/branchsort/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAgentCount: function(agent){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/agentsort/count',agent)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllAgentPageable: function(currentPage,pageCount,data){
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/agentsort/all/'+ (currentPage-1) + "/" + pageCount , data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
// Closed Admin Enquiry Start = = = = = = = = = = = = > > > > > > > > 
getAllClosed: function(){
    // var ax = axios.create({
    //     baseURL: "http://localhost:9090",
    // }); 
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: '/adminenquiry/closedenquiries',
        }).then((response) => {                 
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });                    
},
getClosedEnquiryCount: function(){
    return new Promise((resolve, reject) => {
        axios.get('adminenquiry/closedenquiryCount')
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllClosedEnquiryPageable: function(closedCurrentPage,closedPageCount){
    return new Promise((resolve, reject) => {
        axios.post('adminenquiry/closedenquiryAll/'+ (closedCurrentPage-1) + "/" + closedPageCount)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
searchClosedEnquiry: function(closedKeyword){
    return new Promise((resolve, reject) => {
        axios.get('adminenquiry/closedsearchEnquiry/'+ closedKeyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
// ======ENQUIRY SORTING START======

getClosedEnquiryCourse: function(name){       
let config = {
    headers: {
        "Content-Type": "application/json"
    }
};
let data={
    name:name
}
return new Promise((resolve, reject) => {
    axios
        .post("/adminenquiry/enquiry/closed/sortCourse", data, config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
});               
},
getClosedEnquirySource: function(name){       
let config = {
    headers: {
        "Content-Type": "application/json"
    }
};
let data={
    name:name
}
return new Promise((resolve, reject) => {
    axios
        .post("/adminenquiry/enquiry/closed/sortSource", data, config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
});               
},
getClosedEnquiryBranch: function(name){       
let config = {
    headers: {
        "Content-Type": "application/json"
    }
};
let data={
    name:name
}
return new Promise((resolve, reject) => {
    axios
        .post("/adminenquiry/enquiry/closed/sortBranch", data, config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
});               
},
getClosedEnquiryAgent: function(name){       
let config = {
    headers: {
        "Content-Type": "application/json"
    }
};
let data={
    name:name
}
return new Promise((resolve, reject) => {
    axios
        .post("/adminenquiry/enquiry/closed/sortAgent", data, config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
});               
},

// ======ENQUIRY SORTING END======
getClosedSearchCount: function(closedkeyword){
return new Promise((resolve, reject) => {
    axios.post('adminenquiry/closedsearch/count/' + closedkeyword)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getAllClosedSearchPageable: function(closedCurrentPage,closedPageCount,closedKeyword){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closedsearch/'+ (closedCurrentPage-1) + "/" + closedPageCount +"/" + closedKeyword)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getClosedCourseCount: function(course){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/coursesort/count',course)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getAllClosedCoursePageable: function(closedCurrentPage,closedPageCount,data){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/coursesort/all/'+ (closedCurrentPage-1) + "/" + closedPageCount , data)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getClosedSourceCount: function(source){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/sourcesort/count',source)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getAllClosedSourcePageable: function(closedCurrentPage,closedPageCount,data){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/sourcesort/all/'+ (closedCurrentPage-1) + "/" + closedPageCount , data)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getClosedBranchCount: function(branch){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/branchsort/count',branch)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getAllClosedBranchPageable: function(closedCurrentPage,closedPageCount,data){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/branchsort/all/'+ (closedCurrentPage-1) + "/" + closedPageCount , data)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getClosedAgentCount: function(agent){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/agentsort/count',agent)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},
getAllClosedAgentPageable: function(closedCurrentPage,closedPageCount,data){
return new Promise((resolve, reject) => {
    axios.post('/adminenquiry/closed/agentsort/all/'+ (closedCurrentPage-1) + "/" + closedPageCount , data)
    .then((response) => {
        resolve(response);
    }).catch((err) => {
        reject(err);
    });
});
},

getByDate: function (selectedDate) {
    return new Promise((resolve, reject) => {
        axios
            .post('/adminenquiry/sort/date/'+selectedDate)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
},
// getDateCount: function (selectedDate) {
//     return new Promise((resolve, reject) => {
//         axios.get('/adminenquiry/datesort/count/'+ selectedDate)
//             .then((response) => {
//                 resolve(response);
//             }).catch((err) => {
//                 reject(err);
//             });
//     });
// },
// getAllDatePageable: function (currentPage, pageCount,selectedDate, data) {
//     return new Promise((resolve, reject) => {
//         axios.post('/adminenquiry/datesort/all/' + (currentPage - 1) + "/" + pageCount + "/" +selectedDate, data)
//             .then((response) => {
//                 resolve(response);
//             }).catch((err) => {
//                 reject(err);
//             });
//     });
// },
getDateCount: function (data) {
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/sortDate/count',data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getAllDatePageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/sortDate/all/' + (currentPage - 1) + "/" + pageCount, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getByClosedDate: function (selectedClosedDate) {
    return new Promise((resolve, reject) => {
        axios
            .post('/adminenquiry/closed/sort/date/' +selectedClosedDate)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
},
// getClosedDateCount: function (selectedClosedDate) {
//     return new Promise((resolve, reject) => {
//         axios.get('/adminenquiry/closed/datesort/count/' + selectedClosedDate)
//             .then((response) => {
//                 resolve(response);
//             }).catch((err) => {
//                 reject(err);
//             });
//     });
// },
// getAllClosedDatePageable: function (currentPage, pageCount,selectedClosedDate, data) {
//     return new Promise((resolve, reject) => {
//         axios.post('/adminenquiry/closed/datesort/all/' + (currentPage - 1) + "/" + pageCount + "/" +selectedClosedDate, data)
//             .then((response) => {
//                 resolve(response);
//             }).catch((err) => {
//                 reject(err);
//             });
//     });
// },
getClosedDateCount: function (data) {
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/closed/sortDate/count',data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
getAllClosedDatePageable: function (closedCurrentPage, closedPageCount, data) {
    return new Promise((resolve, reject) => {
        axios.post('/adminenquiry/closed/sortDate/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
    });
},
}