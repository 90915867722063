<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- Attendance date rang select modal START -->
    <b-modal ref="attendanceDateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid,}" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid,}" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="getSortAttendanceCount()">Submit</b-button>
        </div>
    </b-modal>
    <!-- Attendance date rang select modal END -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <b-col col lg="4" md="1" id="r1" sm="6">
                    <b-button variant="primary" disabled>Attendance sort for excel</b-button>
                </b-col>
                <!-- <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col> -->
                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col> -->
                <b-col sm="12" md="3" lg="5" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage()"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage()" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <div class="mt-1">
                    <b-container>
                        <b-row>
                            <b-col col lg="3" md="4" id="r1" sm="4">
                                <b-form-select class="bnclr" v-model="selectedEmployee" size="sm" @change="getSortAttendanceCount()">
                                    <b-form-select-option disabled value="employee">
                                        <p>Employee</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="allEmployees">
                                        <p>All Employees</p>
                                    </b-form-select-option>
                                    <option v-for="item in allEmployees" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.firstName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="3" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getSortAttendanceCount()" :options="dateSelect">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="6" md="2" id="r1" sm="4" class="float-right">
                                <b-button size="sm" variant="success" :disabled="disabledButton" @click="disabledButton = true,exportToExcel()">Export to Excel</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <hr />
                <b-table responsive hover :items="attendances" :fields="attendanceFields" :current-page="currentPage" :per-page="attendances.pageCount" ref="table">
                    <template v-slot:cell(date)="data">
                        {{ dateFormat(data.item.date, "date") }}
                    </template>
                    <template v-slot:cell(employee)="data">
                        {{ data.item.employee.firstName }}
                    </template>
                    <template v-slot:cell(code)="data">
                        {{ data.item.employee.code }}
                    </template>
                    <template v-slot:cell(status)="data">
                        <!-- <b-form-input type="text" :value="data.value"></b-form-input> -->
                        <b-form-select class="edit shadow-sm form-control" v-model="data.value" :value="data.value" @change="userAttendanceUpdate(data.item,data.value)">
                            <option value="null">Status</option>
                            <option value="ABSENT">ABSENT</option>
                            <option value="PRESENT">PRESENT</option>
                            <option value="FULLDAYPRESENT">FULLDAYPRESENT</option>
                            <option value="HALFDAYPRESENT">HALFDAYPRESENT</option>
                        </b-form-select>
                        <!-- <span v-if="data.item.status == 'ABSENT'">
                            <b-badge style="background-color: #ff0000;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
                            </b-badge>
                        </span>
                        <span v-else>
                            <b-badge style="background-color: #228b22;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
                            </b-badge>
                        </span> -->
                    </template>
                    <template v-slot:cell(present)="data">
                        <span v-if="data.item.present">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(absent)="data">
                        <span v-if="data.item.absent">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(fullDayPresent)="data">
                        <span v-if="data.item.fullDayPresent">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(halfDayPresent)="data">
                        <span v-if="data.item.halfDayPresent">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(outdoorDuty)="data">
                        <span v-if="data.item.outdoorDuty">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(overTime)="data">
                        <span v-if="data.item.overTime">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                    <template v-slot:cell(holiday)="data">
                        <span v-if="data.item.holiday">
                            <svg style="width:20px;fill:green;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                        </span>
                        <span v-else>
                            <svg style="width:20px;fill:red;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                        </span>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>
</div>
</template>

<script>
import Account from '../service/Account'
import AttendanceService from '../service/AttendanceService';
import moment from "moment-timezone";
import {
    required
} from "vuelidate/lib/validators";
import EmployeeService from '../service/EmployeeService';

export default {
    name: 'AttendanceExcelExport',
    data() {
        return {
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalCount: 0,
            keyword: "",
            attendances: [],
            attendanceFields: [{
                    key: "date",
                    label: "AttendanceDate",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "employee",
                    label: "Employee",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "code",
                    label: "code",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "status",
                    label: "Status",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "present",
                    label: "Present",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "absent",
                    label: "Absent",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "fullDayPresent",
                    label: "FullDay",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "halfDayPresent",
                    label: "HalfDay",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                // {
                //     key: "outdoorDuty",
                //     label: "OD",
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                // {
                //     key: "overTime",
                //     label: "OT",
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // },
                // {
                //     key: "holiday",
                //     label: "Holiday",
                //     thClass: 'text-center',
                //     tdClass: 'text-center',
                // }
            ],
            selectedEmployee: "employee",
            allEmployees: [],
            selectedDates: null,
            from: "",
            to: "",
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            sortData: null,
            resourceLocation: "https://api-encore.examsdaily.in/uploads/attendance/",
            // resourceLocation: "http://localhost:8088/uploads/attendance/",
            disabledButton: false,
        }
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.getAllEmployees();
                    this.getSortAttendanceCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        getAllEmployees: function () {
            return new Promise((resolve, reject) => {
                EmployeeService.getAllEmployees()
                    .then((response) => {
                        this.allEmployees = response.data;
                        console.log("====getSortAttendanceCount=====", response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        userAttendanceUpdate(data, status) {
            let present = false;
            let absent = false;
            let fullDayPresent = false;
            let halfDayPresent = false;
            let attendanceId = data.attendanceId;
            let date = data.date;
            if (status == "PRESENT") {
                present = true;
                fullDayPresent = false;
                halfDayPresent = false
            } else if (status == "ABSENT") {
                absent = true;
                // present = false;
                // fullDayPresent = false;
                // halfDayPresent = false
            } else if (status == "FULLDAYPRESENT") {
                present = true;
                fullDayPresent = true;
            } else if (status == "HALFDAYPRESENT") {
                present = true;
                halfDayPresent = true;
            }
            for (var i = 0; i < this.attendances.length; i++) {
                if (this.attendances[i].attendanceId == data.attendanceId) {
                    this.attendances[i].status = status,
                    this.attendances[i].fullDayPresent = fullDayPresent,
                    this.attendances[i].halfDayPresent = halfDayPresent,
                    this.attendances[i].present = present
                    this.attendances[i].absent = absent
                }
            }
            let updateData = {
                attendanceId: attendanceId,
                date: date,
                status: status,
                absent: absent,
                present: present,
                fullDayPresent: fullDayPresent,
                halfDayPresent: halfDayPresent,
                outdoorDuty: false,
                overTime: false,
                holiday: false,
            };
            return new Promise((resolve, reject) => {
                AttendanceService.updateAttendance(updateData)
                    .then((response) => {
                        // this.$refs.attendanceUpdateModal.hide();
                        // this.disabledButton = false;
                        // this.getAttendanceCount();
                        this.$swal({
                            title: "Attendance Updated",
                            text: "Employee Attendance Updated successfully...",
                            icon: "success",
                            button: "ok",
                        });
                        // this.resetAttendance();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSortAttendanceCount: function () {
            this.loading = true;
            if (this.selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.attendanceDateRange.show();
                this.selectedDates = "dateBetween";
                this.loading = false;
            } else {
                let userId = "";
                if (this.selectedEmployee == 'employee' || this.selectedEmployee == 'allEmployees') {
                    userId = "";
                } else {
                    userId = this.selectedEmployee.userId;
                }
                var from;
                var to;
                if (this.selectedDates != null) {
                    var date = new Date();
                    if (this.selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(0, 0, 0, 0));
                    } else if (this.selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-24, 0, 0, 0));
                        this.toDate = new Date(date.setHours(0, 0, 0, 0));
                    } else if (this.selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-168, 0, 0, 0));
                        this.toDate = new Date(date.setHours(144, 0, 0, 0));
                    } else if (this.selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-720, 0, 0, 0));
                        this.toDate = new Date(date.setHours(696, 0, 0, 0));
                    } else if (this.from != "" && this.to != "" && this.selectedDates == "dateBetween") {
                        this.$refs.attendanceDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(0, 0, 0, 0));
                    } else if (this.from == "" && this.to == "" && this.selectedDates == "dateBetween") {
                        this.$refs.attendanceDateRange.hide();
                        this.fromDate = "2022-10-01 00:00";
                        let date = new Date();
                        this.toDate = new Date(date.setHours(0, 0, 0, 0))
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (this.selectedDates == null || this.selectedDates == "dateBetween") {
                    let fromDate = "2022-10-01 00:00";
                    let date = new Date();
                    let toDate = new Date(date.setHours(0, 0, 0, 0))
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.sortData = {
                    userId: userId,
                    from: from,
                    to: to
                }
                return new Promise((resolve, reject) => {
                    this.currentPage = 1;
                    AttendanceService.getSortAttendanceCount(this.sortData)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllSortAttendancePageable(this.currentPage, this.pageCount, this.sortData);
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        switchPage() {
            this.loading = true;
            this.getAllSortAttendancePageable(this.currentPage, this.pageCount, this.sortData);
        },
        getAllSortAttendancePageable: function (currentPage, pageCount, sortData) {
            return new Promise((resolve, reject) => {
                AttendanceService.getAllSortAttendancePageable(currentPage, pageCount, sortData)
                    .then((response) => {
                        this.attendances = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        exportToExcel: function () {
            this.loading = true;
            if (this.selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.attendanceDateRange.show();
                this.selectedDates = "dateBetween";
                this.loading = false;
                this.disabledButton = false;
            } else {
                let userId = "";
                if (this.selectedEmployee == 'employee' || this.selectedEmployee == 'allEmployees') {
                    userId = "";
                } else {
                    userId = this.selectedEmployee.userId;
                }
                var from;
                var to;
                if (this.selectedDates != null) {
                    var date = new Date();
                    if (this.selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(0, 0, 0, 0));
                    } else if (this.selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-24, 0, 0, 0));
                        this.toDate = new Date(date.setHours(0, 0, 0, 0));
                    } else if (this.selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-168, 0, 0, 0));
                        this.toDate = new Date(date.setHours(144, 0, 0, 0));
                    } else if (this.selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-720, 0, 0, 0));
                        this.toDate = new Date(date.setHours(696, 0, 0, 0));
                    } else if (this.from != "" && this.to != "" && this.selectedDates == "dateBetween") {
                        this.$refs.attendanceDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(0, 0, 0, 0));
                    } else if (this.from == "" && this.to == "" && this.selectedDates == "dateBetween") {
                        this.$refs.attendanceDateRange.hide();
                        this.fromDate = "2022-10-01 00:00";
                        let date = new Date();
                        this.toDate = new Date(date.setHours(0, 0, 0, 0))
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (this.selectedDates == null || this.selectedDates == "dateBetween") {
                    console.log("===else if===");
                    let fromDate = "2022-10-01 00:00";
                    let date = new Date();
                    let toDate = new Date(date.setHours(0, 0, 0, 0))
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.sortData = {
                    userId: userId,
                    from: from,
                    to: to
                }
                console.log("exportToExcel=======", this.sortData);
                return new Promise((resolve, reject) => {
                    AttendanceService.exportToExcel(this.sortData)
                        .then((response) => {
                            this.download(response.data);
                            this.disabledButton = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
            this.loading = false;
            this.disabledButton = false;
        },
        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },
        refresh() {
            this.disabledButton = false;
            this.currentPage = 1;
            this.selectedEmployee = 'employee';
            this.selectedDates = null;
            this.getSortAttendanceCount();
        },
        dateFormat: function (date, dateType) {
            const formatDate = new Date(date);
            if (dateType === "date") {
                return moment(formatDate).format("DD / MM / YYYY");
            }
            if (dateType === "dateTime") {
                return moment(formatDate).format("DD / MM / YYYY hh:mm a");
            }
        },
        getDateRangeReset: function () {
            this.$refs.attendanceDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
