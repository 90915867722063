import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMomentsAgo from 'vue-moments-ago'
import excel from 'vue-excel-export'

// const options = {
//   confirmButtonColor: '#41b882',
//   cancelButtonColor: '#ff7674',
// };
library.add(fas);
// Vue.use(VueSweetalert2, options);

//axios globally declare for live url or localhost
// axios.defaults.baseURL = 'http://localhost:8088';
axios.defaults.baseURL = 'https://api-encore.examsdaily.in';

Vue.prototype.$reportResourceLocation = 'https://api-encore.examsdaily.in/uploads/reports/';
// Vue.prototype.$reportResourceLocation = 'http://localhost:8088/uploads/reports/';

Vue.prototype.$bumblebeeRest = 'https://bumblebee-rest.examsdaily.in';

window.axios = axios;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-moments-ago',VueMomentsAgo);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(excel)

new Vue({
  render: h => h(App),
  router,
  axios
}).$mount('#app')