<template>
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div v-if="isSupervisor">
        <!-- <h6>BRANCH BATCH</h6> -->
        <b-container>
            <!--popup start for Branch Batch Register-->
            <b-modal id="addBranchBatch" title="Branch Batch" hide-footer ref="modal">
                <div class="form-row">
                    <!-- <div class="form-group"> -->

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Code:</label>
                            <b-form-input type="text" v-on:keyup.enter="getCodeSearch(branchBatch.code)" v-model="branchBatch.code" :class="{
                                'is-invalid': $v.branchBatch.code.$error,
                                'is-valid': !$v.branchBatch.code.$invalid,
                                }" class="form-control" placeholder="Enter Batch Code" disabled></b-form-input>
                            <!-- <div class="valid-feedback">Your code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branchBatch.code.required">Code is required</span>
                        </div> -->
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch:</label>
                            <b-form-select class="form-control" v-model="branchBatch.branch" :class="{
                                'is-invalid': $v.branchBatch.branch.$error,
                                'is-valid': !$v.branchBatch.branch.$invalid,
                                }" disabled>
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <!-- <span>
                    <b-button style="margin-left: 180px" variant="primary" @click="getCodeSearch(branchBatch.code)">Search</b-button>
                </span> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Syllabus:</label>
                            <b-form-select class="form-control" v-model="branchBatch.syllabus" :class="{
                                'is-invalid': $v.branchBatch.syllabus.$error,
                                'is-valid': !$v.branchBatch.syllabus.$invalid,
                                }" disabled>
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Level-1:</label>
                            <b-form-select class="form-control" v-model="selectedL1" :class="{
                            'is-invalid': $v.selectedL1.$error,
                            'is-valid': !$v.selectedL1.$invalid,
                            }" @change="level1Selected(selectedL1)">
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in syllabusLevel1" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.word }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <!-- <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
              <label>Level-2:</label>
              <b-form-select
                class="form-control"
                v-model="selectedL2"
                :class="{
                  'is-invalid': $v.selectedL2.$error,
                  'is-valid': !$v.selectedL2.$invalid,
                }"
                @change="level2Selected(selectedL2)"
              >
                <option value="null" disabled>Select Syllabus</option>
                <option
                  v-for="item in syllabusLevel2"
                  :value="item"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.word }}</strong>
                </option>
              </b-form-select>
            </div>
          </div> -->

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Level-2:</label>
                            <b-form-select class="form-control" v-model="selectedL2" :class="{
                                'is-invalid': $v.selectedL2.$error,
                                'is-valid': !$v.selectedL2.$invalid,
                                }">
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in syllabusLevel2" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.word }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>StudentCount:</label>
                            <b-form-input type="number" class="form-control" v-model.trim="$v.branchBatch.studentCount.$model" :class="{
                                'is-invalid': $v.branchBatch.studentCount.$error,
                                'is-valid': !$v.branchBatch.studentCount.$invalid,
                                }" placeholder="Enter your StudentCount">
                            </b-form-input>
                            <div class="valid-feedback">Your StudentCount is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.studentCount.required">StudentCount is required</span>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group"> -->
                    <!-- <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch:</label>
                        <b-form-select class="form-control" v-model="branchBatch.branch" :class="{
                  'is-invalid': $v.branchBatch.branch.$error,
                  'is-valid': !$v.branchBatch.branch.$invalid,
                }" disabled>
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Class Date:</label>
                            <!-- :class="{
                            'is-invalid': $v.branchBatch.startDate.$error,
                            'is-valid': !$v.branchBatch.startDate.$invalid,
                            }" -->
                            <!-- <b-form-datepicker format="DD-MM-YYYY" id="example-datepicker" v-model="branchBatch.classDate" class="mb-2"></b-form-datepicker> -->
                            <b-form-input format="DD/MM/YYYY" v-model="branchBatch.classDate" type="date" :class="{
                  'is-invalid': $v.branchBatch.classDate.$error,
                  'is-valid': !$v.branchBatch.classDate.$invalid,
                }"></b-form-input>
                            <div class="valid-feedback">Class Date is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.classDate.invalid">Classes must be updated within 24 hrs after completion!</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-2 px-5">
                        <div class="form-group my-2">
                            <br />
                            <label>Start Time:</label>
                            <b-form-timepicker v-model.trim="$v.branchBatch.startTime.$model" :class="{'is-invalid': $v.branchBatch.startTime.$error,'is-valid': !$v.branchBatch.startTime.$invalid,}" locale="en"></b-form-timepicker>
                            <!-- <b-form-input class="form-control" v-model.trim="$v.branchBatch.startTime.$model" :class="{
                  'is-invalid': $v.branchBatch.startTime.$error,
                  'is-valid': !$v.branchBatch.startTime.$invalid,
                }" placeholder="Enter your StartTime">
                            </b-form-input> -->
                            <div class="valid-feedback">Your StartTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.startTime.required">StartTime is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>End Time:</label>
                            <b-form-timepicker v-model.trim="$v.branchBatch.endTime.$model" :class="{'is-invalid': $v.branchBatch.endTime.$error,'is-valid': !$v.branchBatch.endTime.$invalid,}" locale="en"></b-form-timepicker>
                            <!-- <b-form-input class="form-control" v-model.trim="$v.branchBatch.endTime.$model" :class="{
                  'is-invalid': $v.branchBatch.endTime.$error,
                  'is-valid': !$v.branchBatch.endTime.$invalid,
                }" placeholder="Enter your EndTime">
                            </b-form-input> -->
                            <div class="valid-feedback">Your EndTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.endTime.required">EndTime is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <!-- <b-form-checkbox id="checkbox-1" v-model="classFeedback" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                &nbsp; Feedback (optional)
                            </b-form-checkbox> -->
                            <label>Feedback (optional)</label>
                            <div>
                                <b-form-textarea class="form-control" v-model="branchBatch.feedback" placeholder="Maximum 255 characters only allowed">
                                </b-form-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Tutor:</label>
                            <b-form-select class="form-control" v-model="branchBatch.tutor" :class="{
                  'is-invalid': $v.branchBatch.tutor.$error,
                  'is-valid': !$v.branchBatch.tutor.$invalid,
                }">
                                <option value="null" disabled>Select Tutor</option>
                                <option v-for="item in allTutors" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.userName }}</strong>
                                </option>
                                <!-- <div class="valid-feedback">Your EndTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.tutor.required">Tutor is required</span>
                            </div> -->
                            </b-form-select>
                        </div>
                    </div>
                    <br />
                    <div class="text-center">
                        <b-button variant="outline-success" :disabled="disableButton" @click="disableButton = true,putBranchBatch()">Submit</b-button>
                        <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                    </div>
                </div>
            </b-modal>
            <!--popup end for Branch Batch Register-->

            <b-modal title="Branch Batch" hide-footer>
                <div class="form-row">
                    <!-- <div class="form-group"> -->

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Code:</label>
                            <b-form-input type="text" v-on:keyup.enter="getCodeSearch(branchBatch.code)" v-model="branchBatch.code" :class="{
                  'is-invalid': $v.branchBatch.code.$error,
                  'is-valid': !$v.branchBatch.code.$invalid,
                }" class="form-control" placeholder="Enter Batch Code" disabled></b-form-input>
                            <!-- <div class="valid-feedback">Your code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branchBatch.code.required">Code is required</span>
                        </div> -->
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch:</label>
                            <b-form-select class="form-control" v-model="branchBatch.branch" :class="{
                  'is-invalid': $v.branchBatch.branch.$error,
                  'is-valid': !$v.branchBatch.branch.$invalid,
                }" disabled>
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <!-- <span>
                    <b-button style="margin-left: 180px" variant="primary" @click="getCodeSearch(branchBatch.code)">Search</b-button>
                </span> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Syllabus:</label>
                            <b-form-select class="form-control" v-model="branchBatch.syllabus" :class="{
                  'is-invalid': $v.branchBatch.syllabus.$error,
                  'is-valid': !$v.branchBatch.syllabus.$invalid,
                }" disabled>
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Level-1:</label>
                            <b-form-select class="form-control" v-model="selectedL1" :class="{
                  'is-invalid': $v.selectedL1.$error,
                  'is-valid': !$v.selectedL1.$invalid,
                }" @change="level1Selected(selectedL1)" disabled>
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in syllabusLevel1" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.word }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <!-- <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
              <label>Level-2:</label>
              <b-form-select
                class="form-control"
                v-model="selectedL2"
                :class="{
                  'is-invalid': $v.selectedL2.$error,
                  'is-valid': !$v.selectedL2.$invalid,
                }"
                @change="level2Selected(selectedL2)"
              >
                <option value="null" disabled>Select Syllabus</option>
                <option
                  v-for="item in syllabusLevel2"
                  :value="item"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.word }}</strong>
                </option>
              </b-form-select>
            </div>
          </div> -->

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Level-2:</label>
                            <b-form-select class="form-control" v-model="selectedL2" :class="{
                  'is-invalid': $v.selectedL2.$error,
                  'is-valid': !$v.selectedL2.$invalid,
                }" disabled>
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in syllabusLevel2" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.word }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>StudentCount:</label>
                            <b-form-input type="number" class="form-control" v-model.trim="$v.branchBatch.studentCount.$model" :class="{
                  'is-invalid': $v.branchBatch.studentCount.$error,
                  'is-valid': !$v.branchBatch.studentCount.$invalid,
                }" placeholder="Enter your StudentCount">
                            </b-form-input>
                            <div class="valid-feedback">Your StudentCount is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.studentCount.required">StudentCount is required</span>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group"> -->
                    <!-- <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch:</label>
                        <b-form-select class="form-control" v-model="branchBatch.branch" :class="{
                  'is-invalid': $v.branchBatch.branch.$error,
                  'is-valid': !$v.branchBatch.branch.$invalid,
                }" disabled>
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Class Date:</label>
                            <!-- :class="{
                            'is-invalid': $v.branchBatch.startDate.$error,
                            'is-valid': !$v.branchBatch.startDate.$invalid,
                            }" -->
                            <!-- <b-form-datepicker format="DD-MM-YYYY" id="example-datepicker" v-model="branchBatch.classDate" class="mb-2"></b-form-datepicker> -->
                            <b-form-input format="DD/MM/YYYY" v-model="branchBatch.classDate" type="date" :class="{
                  'is-invalid': $v.branchBatch.classDate.$error,
                  'is-valid': !$v.branchBatch.classDate.$invalid,
                }"></b-form-input>
                            <div class="valid-feedback">Class Date is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.classDate.invalid">Classes must be updated within 24 hrs after completion!</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-2 px-5">
                        <div class="form-group my-2">
                            <br />
                            <label>Start Time:</label>
                            <b-form-timepicker v-model.trim="$v.branchBatch.startTime.$model" :class="{'is-invalid': $v.branchBatch.startTime.$error,'is-valid': !$v.branchBatch.startTime.$invalid,}" locale="en"></b-form-timepicker>
                            <!-- <b-form-input class="form-control" v-model.trim="$v.branchBatch.startTime.$model" :class="{
                  'is-invalid': $v.branchBatch.startTime.$error,
                  'is-valid': !$v.branchBatch.startTime.$invalid,
                }" placeholder="Enter your StartTime">
                            </b-form-input> -->
                            <div class="valid-feedback">Your StartTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.startTime.required">StartTime is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>End Time:</label>
                            <b-form-timepicker v-model.trim="$v.branchBatch.endTime.$model" :class="{'is-invalid': $v.branchBatch.endTime.$error,'is-valid': !$v.branchBatch.endTime.$invalid,}" locale="en"></b-form-timepicker>
                            <!-- <b-form-input class="form-control" v-model.trim="$v.branchBatch.endTime.$model" :class="{
                  'is-invalid': $v.branchBatch.endTime.$error,
                  'is-valid': !$v.branchBatch.endTime.$invalid,
                }" placeholder="Enter your EndTime">
                            </b-form-input> -->
                            <div class="valid-feedback">Your EndTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.endTime.required">EndTime is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Tutor:</label>
                            <b-form-select class="form-control" v-model="branchBatch.tutor" :class="{
                  'is-invalid': $v.branchBatch.tutor.$error,
                  'is-valid': !$v.branchBatch.tutor.$invalid,
                }">
                                <option value="null" disabled>Select Tutor</option>
                                <option v-for="item in allTutors" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.userName }}</strong>
                                </option>
                                <!-- <div class="valid-feedback">Your EndTime is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branchBatch.tutor.required">Tutor is required</span>
                            </div> -->
                            </b-form-select>
                        </div>
                    </div>
                    <br />
                    <div class="text-center">
                        <b-button variant="outline-success" :disabled="disableButton" @click="disableButton = true,putBranchBatch()">Submit</b-button>
                        <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                    </div>
                </div>
            </b-modal>
        </b-container>

        <!-- <b-button
      size="sm"
      variant="outline-primary"
      class="bnclr"
      v-b-modal.addBranchBatch
      @click="resetForm()"
      >Add Batch</b-button
    > -->

        <!-- Branch Batch Table Start -->

        <div class="mt-5">
            <b-container>
                <b-row align-v="center">
                    <!--add Branch Batch button start-->

                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBranchBatch @click="resetForm()">Add Batch</b-button>
                </b-col> -->
                    <b-col col sm="12" md="4" lg="4" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <p>Batch</p>
                    <hr />
                    <b-table responsive hover :items="branchBatches" :fields="fields" :current-page="currentPage" :per-page="branchBatches.pageCount" ref="table">
                        <!-- <template v-slot:cell(branchBatchId)="data">
                        <span class="title"> {{ data.item.branchBatchId }}</span>
                    </template> -->
                        <template v-slot:cell(code)="data">
                            <!-- <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report">
                            {{ data.item.code }}
                        </span> -->
                            {{ data.item.code }}
                        </template>
                        <template v-slot:cell(syllabus)="data">
                            {{ data.item.syllabus.name }}
                        </template>
                        <template v-slot:cell(studentCount)="data">
                            {{ data.item.studentCount }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" v-b-modal.viewBranchBatch @click="getBranchBatch(data.item)">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Add class" v-b-modal.addBranchBatch @click="resetForm(), getCodeSearch(data.item)">
                                <font-awesome-icon icon="plus-circle" />
                            </b-button>
                            <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="View Batch Report" @click="batchStatus(data.item)">
                                <font-awesome-icon icon="file" />
                            </b-button>
                            <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBranchBatch(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button> -->
                            <!-- <b-button class="mx-1" v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Batch Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.batchId)">
                            <font-awesome-icon icon="archive" />
                        </b-button> -->
                        </template>
                    </b-table>
                </b-card>
            </b-container>
        </div>

        <!-- Branch Batch Table End-->

        <!-- selected Batch detailed history -->
        <b-modal id="showBatchStatusModal" scrollable size="lg" hide-footer header-bg-variant="primary" no-close-on-backdrop no-close-on-esc centered v-model="showBatchStatusModal">
            <template slot="modal-header">
                <b-col class="mt-1" cols="1"> </b-col>
                <b-col class="d-flex justify-content-center">
                    <a style="color: white;font-weight: bold;font-size: 1.4rem;text-align: center;" v-if="this.batchDetails != null">Batch: {{ this.batchDetails.code }}</a>
                    <a style="color: white;font-weight: bold;font-size: 1.4rem;text-align: center;" v-else>Batch Report</a>
                </b-col>
                <b-col cols="1">
                    <button type="button" data-dismiss="modal" aria-label="Close" @click="showBatchStatusModal = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </b-col>
            </template>
            <div>
                <b-card no-body v-if="selectedCourseContentNode != null && selectedCourseContentNode.childrens.length != 0">
                    <b-tabs pills justified card align="center">
                        <b-tab title="Syllabus Content" active>
                            <b-card-text>
                                <b-container>
                                    <b-card class="shadow border-0 mx-auto" style="background: #d0dcf5;border: 2px solid grey;border-radius: 25px;width: 100%;margin-bottom: 2em;">
                                        <div role="tablist">
                                            <b-row class="mb-3" align-v="center">
                                                <b-col cols="2">
                                                    <b-dropdown variant="warning" size="sm" class="shadow" style="">
                                                        <template slot="button-content"><span>{{ contentType }}</span></template>
                                                        <b-dropdown-item @click="filterByContentType('ALL')">All</b-dropdown-item>
                                                        <b-dropdown-item @click="filterByContentType('Video')">Video</b-dropdown-item>
                                                        <b-dropdown-item @click="filterByContentType('Quiz')">Quiz</b-dropdown-item>
                                                        <b-dropdown-item @click="filterByContentType('Material')">Material</b-dropdown-item>
                                                    </b-dropdown>
                                                </b-col>
                                                <!-- content type -->
                                                <b-col class="d-flex justify-content-center">
                                                    <a style="color: #4274d6;font-weight: bold;font-size: 1.2rem;text-align: center;" v-if="contentType == 'ALL'">Overall Content</a>
                                                    <a style="color: #4274d6;font-weight: bold;font-size: 1.2rem;text-align: center;" v-else>{{ contentType }} Content</a>
                                                </b-col>
                                            </b-row>

                                            <b-card no-body class="mb-1" v-for="(l1, l1index) in selectedCourseContentNode.childrens" v-bind:key="l1index">
                                                <!-- {{l1index}}====={{selectedCourseContentNode.childrens.length}} -->
                                                <b-card-header header-tag="header" style="
                                                cursor: pointer;
                                                border-bottom: 1px solid #d0dcf5;" role="tab" v-if="l1index != selectedCourseContentNode.childrens.length" v-b-toggle="'accordion-1' + l1index">
                                                    <i style="color: #2780b2" icon="fa-solid fa-minus" class="when-opened float-right mt-2 mr-4"></i>
                                                    <i style="color: #2780b2" icon="fa-solid fa-plus" class="when-closed when-closed float-right mt-2 mr-4"></i>
                                                    <span class="when-opened float-end mt-2 mr-4">
                                                        <font-awesome-icon icon="minus" style="color: #2780b2" />
                                                    </span>
                                                    <span class="when-closed when-closed float-end mt-2 mr-4">
                                                        <font-awesome-icon icon="plus" style="color: #2780b2" />
                                                    </span>
                                                    <b-row class="card-header" style="padding: 5px">
                                                        <b-col class="d-flex justify-content-start ml-4">
                                                            <strong style="color: grey">{{ l1.word }}</strong>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-header>
                                                <b-collapse :id="'accordion-1' + l1index" visible role="tabpanel">
                                                    <b-card-body>
                                                        <b-collapse :id="'accordion-2' + l1index" visible role="tabpanel">
                                                            <b-card-body>
                                                                <div class="table-responsive" v-if="hasContent(l1index)">
                                                                    <table class="table table-hover table-bordered table-space">
                                                                        <!-- All content only -->
                                                                        <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType == 'ALL' && (l2.video || l2.quiz || l2.resource)">
                                                                            <td style="width: 6%">
                                                                                <span v-if="l2.video">
                                                                                    <font-awesome-icon icon="video" /></span>
                                                                                <span v-if="l2.resource">
                                                                                    <font-awesome-icon icon="file" /></span>
                                                                                <span v-if="l2.quiz">
                                                                                    <font-awesome-icon icon="pen" /></span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{{ l2.word }}</span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="contentEditModal(l2)" icon="edit" style="color: #0d6efd;cursor: pointer;" />
                                                                                </span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="classRevert(l2)" icon="trash" style="color: red;cursor: pointer;" />
                                                                                </span>
                                                                            </td>
                                                                            <td style="width: 5%">
                                                                                <span>
                                                                                    <span v-if="l2.complete == true">
                                                                                        <font-awesome-icon icon="check" style="color: green" /></span>
                                                                                    <span v-if="l2.complete == undefined || l2.complete == false" @click="resetForm(),batchClassAddModal(l2.lectureId)">
                                                                                        <font-awesome-icon icon="plus-circle" style="color: #0d6efd;cursor: pointer;" /></span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <!-- Video content only -->
                                                                        <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType == 'Video' && l2.video">
                                                                            <td style="width: 5%">
                                                                                <span v-if="l2.video">
                                                                                    <font-awesome-icon icon="video" /></span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{{ l2.word }}</span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="contentEditModal(l2)" icon="edit" style="color: #0d6efd;cursor: pointer;" />
                                                                                </span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="classRevert(l2)" icon="trash" style="color: red;cursor: pointer;" />
                                                                                </span>
                                                                                <!-- <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row> -->
                                                                            </td>
                                                                            <td style="width: 5%">
                                                                                <span>
                                                                                    <span v-if="l2.complete == true">
                                                                                        <font-awesome-icon icon="check" style="color: green" /></span>
                                                                                    <span v-if="l2.complete == undefined || l2.complete == false" @click="resetForm(),batchClassAddModal(l2.lectureId)">
                                                                                        <font-awesome-icon icon="plus-circle" style="color: #0d6efd;cursor: pointer;" /></span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <!-- quiz content only -->
                                                                        <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType == 'Quiz' && l2.quiz">
                                                                            <td style="width: 5%">
                                                                                <span v-if="l2.quiz">
                                                                                    <font-awesome-icon icon="pen" /></span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{{ l2.word }}</span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="contentEditModal(l2)" icon="edit" style="color: #0d6efd;cursor: pointer;" />
                                                                                </span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="classRevert(l2)" icon="trash" style="color: red;cursor: pointer;" />
                                                                                </span>
                                                                                <b-row v-if="l2.liveVideo" class="ml-0">
                                                                                    <span style="font-size: 12px;color: steelblue;">Release Date :
                                                                                        <span style="font-weight: bold">{{l2.releaseOn}}</span>
                                                                                    </span>
                                                                                </b-row>
                                                                            </td>
                                                                            <td style="width: 5%">
                                                                                <span>
                                                                                    <span v-if="l2.complete == true">
                                                                                        <font-awesome-icon icon="check" style="color: green" />
                                                                                    </span>
                                                                                    <span v-if="l2.complete == undefined || l2.complete == false" @click="resetForm(),batchClassAddModal(l3.lectureId)">
                                                                                        <font-awesome-icon icon="plus-circle" style="color: #0d6efd;cursor: pointer;" />
                                                                                    </span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <!-- resource content only -->
                                                                        <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType == 'Material' && l2.resource">
                                                                            <td style="width: 5%">
                                                                                <span v-if="l2.resource">
                                                                                    <font-awesome-icon icon="file" /></span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{{ l2.word }}</span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="contentEditModal(l2)" icon="edit" style="color: #0d6efd;cursor: pointer;" />
                                                                                </span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon v-if="validateWithDay(l2.date)" @click="classRevert(l2)" icon="trash" style="color: red;cursor: pointer;" />
                                                                                </span>
                                                                                <b-row v-if="l2.liveVideo" class="ml-0">
                                                                                    <span style="font-size: 12px;color: steelblue;">Release Date :
                                                                                        <span style="font-weight: bold">{{l2.releaseOn}}</span>
                                                                                    </span>
                                                                                </b-row>
                                                                            </td>
                                                                            <td style="width: 5%">
                                                                                <span>
                                                                                    <span v-if="l2.complete == true">
                                                                                        <font-awesome-icon icon="check" style="color: green" />
                                                                                    </span>
                                                                                    <span v-if="l2.complete == undefined || l2.complete == false" @click="resetForm(),batchClassAddModal(l2.lectureId)">
                                                                                        <font-awesome-icon icon="plus-circle" style="color: #0d6efd;cursor: pointer;" />
                                                                                    </span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div class="text-center" v-else>
                                                                    <span class="text-secondary" style="font-weight: bold;font-size: 1.2em;">No {{ contentType }} !</span>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                        <!-- </b-card> -->
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </b-card>
                                </b-container>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Table" @click="getAllBatchDetailCount()">
                            <b-card-text>
                                <b-container>
                                    <b-row align-v="center">
                                        <b-col col sm="12" md="4" lg="4" id="r1">
                                            <input type="search" class="searchbar" v-model="batchKeyword" v-on:keyup.enter="getBatchSearchCount(batchKeyword)" placeholder="Type a name" />
                                        </b-col>
                                        <b-col col lg="2" md="1" id="r1" sm="6">
                                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBatchSearchCount(batchKeyword)">Search</b-button>
                                        </b-col>
                                        <b-col sm="8" md="3" lg="6" class="my-1" id="r1">
                                            <b-pagination v-model="batchCurrentPage" :total-rows="totalBatchCount" :per-page="batchPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchBatchPage(batchCurrentPage, batchPageCount)"></b-pagination>
                                        </b-col>
                                    </b-row>
                                    <b-row align-v="center" id="r2">
                                        <b-col sm="6" md="3" lg="4" class="" id="r1">
                                            <label class="font mx-1">Per Page</label>
                                            <b-form-select class="br" id="per-page-select" v-model="batchPageCount" @input="switchBatchPage(batchCurrentPage, batchPageCount)" :options="batchPageOptions" size="sm">
                                            </b-form-select>
                                        </b-col>
                                        <b-col col sm="6" md="2" lg="8" id="r1" class="text-center">
                                            <span style="color: #0974ff; cursor: pointer" @click="refreshBatch()">
                                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                                        </b-col>
                                    </b-row>
                                </b-container>
                                <b-container>
                                    <b-card class="card mt-1">
                                        <hr />
                                        <b-table responsive hover :items="branchBatcheDetails" :fields="supervisorBranchBatchField" :current-page="batchCurrentPage" :per-page="branchBatcheDetails.batchPageCount" ref="table">

                                            <template v-slot:cell(classDate)="data">
                                                <div class="cellSize">
                                                    {{ dateTimeFormat(data.item.classDate, "date") }}
                                                </div>
                                            </template>
                                            <template v-slot:cell(code)="data">
                                                {{ data.item.code }}
                                            </template>
                                            <template v-slot:cell(syllabusLevel2)="data">
                                                {{ data.item.syllabusLevel2 }}
                                            </template>
                                            <template v-slot:cell(tutor)="data">
                                                {{ data.item.tutor.userName }}
                                            </template>
                                            <template v-slot:cell(studentCount)="data">
                                                {{ data.item.studentCount }}
                                            </template>
                                            <template v-slot:cell(branch)="data">
                                                {{ data.item.branch.name }}
                                            </template>
                                            <template v-slot:cell(agent)="data">
                                                {{ data.item.agent.firstName }}
                                            </template>
                                            <template v-slot:cell(createdAt)="data">
                                                <div class="cellSize">
                                                    {{ dateTimeFormat(data.item.createdAt, "dateTime") }}
                                                </div>
                                            </template>
                                        </b-table>
                                    </b-card>
                                </b-container>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <b-card v-else class="text-center">
                    <span>No data found</span>
                </b-card>
            </div>
        </b-modal>

        <!-- View Branch Batch Start -->

        <b-container>
            <b-modal id="viewBranchBatch" no-close-on-backdrop hide-footer title="View Batch">
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Syllabus</label>
                            <b-form-select class="form-control" v-model="branchBatch.syllabus" disabled>
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Batch Code</label>
                            <b-form-input v-model="branchBatch.code" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Student Count</label>
                            <b-form-input v-model="branchBatch.studentCount" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-select class="form-control" v-model="branchBatch.branch" disabled>
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Start Date</label>
                            <b-form-input v-model="branchBatch.startDate" class="form-control" disabled></b-form-input>
                        </div>
                    </div>

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Start Time</label>
                            <b-form-input v-model="branchBatch.startTime" class="form-control" disabled></b-form-input>
                        </div>
                    </div>

                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>End Time</label>
                            <b-form-input v-model="branchBatch.endTime" class="form-control" disabled></b-form-input>
                        </div>
                    </div>

                    <br />
                </div>
            </b-modal>
        </b-container>
        <!-- View Branch Batch End-->

        <!-- Syllabus content edit with in 24 hours start -->
        <b-modal id="syllabusContentEdit" title="Branch Batch" hide-footer ref="syllabusContentEdit">
            <!-- {{batchDetails}} -->
            <div class="form-row">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Code :</label>
                        <b-form-input type="text" v-model="syllabusContent.code" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch :</label>
                        <b-form-select class="form-control" v-model="syllabusContent.branch" disabled>
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Level-2 :</label>
                        <b-form-input type="text" v-model="syllabusContent.word" class="form-control" disabled></b-form-input>
                        <!-- <div class="valid-feedback">Content is valid!</div> -->
                        <!-- <div class="invalid-feedback">
                            <span v-if="!$v.branchBatch.code.required">Content required</span>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Type :</label>
                        <b-form-select class="form-control" v-model="syllabusContent.type">
                            <option v-for="item in courseOptions" :value="item.value" v-bind:key="item.index">
                                <strong>{{ item.text }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div> -->
                <!-- <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Complete</label>
                        <b-form-select class="form-control" v-model="syllabusContent.complete">
                            <option v-for="item in completeOptions" :value="item.value" v-bind:key="item.index">
                                <strong>{{ item.text }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div> -->
                <!-- <div v-if="syllabusContent.complete == true">
                    {{syllabusContent.complete}}
                </div> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Class Date:</label>
                        <b-form-input format="DD/MM/YYYY" v-model="syllabusContent.date" type="date" :class="{'is-invalid': $v.syllabusContent.date.$error,'is-valid': !$v.syllabusContent.date.$invalid}"></b-form-input>
                        <div class="valid-feedback">Class Date is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.syllabusContent.date.invalid">Classes must be updated within 24 hrs after completion!</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Start Time:</label>
                        <b-form-timepicker v-model.trim="$v.syllabusContent.startTime.$model" :class="{'is-invalid': $v.syllabusContent.startTime.$error,'is-valid': !$v.syllabusContent.startTime.$invalid,}" locale="en"></b-form-timepicker>
                        <!-- <b-form-input class="form-control" v-model="syllabusContent.startTime" :class="{'is-invalid': $v.syllabusContent.startTime.$error,'is-valid': !$v.syllabusContent.startTime.$invalid}" placeholder="Enter your StartTime">
                        </b-form-input> -->
                        <div class="valid-feedback">Your StartTime is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.syllabusContent.startTime.required">StartTime is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>End Time:</label>
                        <b-form-timepicker v-model.trim="$v.syllabusContent.endTime.$model" :class="{'is-invalid': $v.syllabusContent.endTime.$error,'is-valid': !$v.syllabusContent.endTime.$invalid,}" locale="en"></b-form-timepicker>
                        <!-- <b-form-input class="form-control" v-model="syllabusContent.endTime" :class="{'is-invalid': $v.syllabusContent.endTime.$error,'is-valid': !$v.syllabusContent.endTime.$invalid}" placeholder="Enter your EndTime">
                        </b-form-input> -->
                        <div class="valid-feedback">Your EndTime is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.syllabusContent.endTime.required">EndTime is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <!-- <b-form-checkbox id="checkbox-1" v-model="classFeedback" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                &nbsp; Feedback (optional)
                            </b-form-checkbox> -->
                        <label>Feedback (optional)</label>
                        <div>
                            <b-form-textarea class="form-control" v-model="syllabusContent.feedback" placeholder="Maximum 255 characters only allowed">
                            </b-form-textarea>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Tutor :</label>
                        <b-form-select class="form-control" v-model="syllabusContent.tutorId" :class="{'is-invalid': $v.syllabusContent.tutorId.$error,'is-valid': !$v.syllabusContent.tutorId.$invalid}">
                            <!-- <option value="null" disabled>Select Tutor</option> -->
                            <option v-for="item in allTutors" :value="item.tutorId" v-bind:key="item.index">
                                <strong>{{ item.userName }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
            </div>
            <br />
            <div class="text-center">
                <b-button variant="outline-success" @click="syllabusContentUpdate()">Update</b-button>
                <b-button variant="outline-danger" class="mx-3" @click="resetSyllabusContent()">Cancel</b-button>
            </div>
        </b-modal>
        <!-- Syllabus content edit with in 24 hours end -->
    </div>

    <!-- ===================== ADMIN SUPERVISOR START ======================== -->
    <div v-if="isAdminSupervisor">
        <!-- <div>
            <b-container>
                <b-row>
                    <b-col v-for="a in adminSupervisorBranches" v-bind:key="a.branchId">
                        <b-button variant="outline-primary" class="bnclr">{{a.name}}</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div> -->
        <div class="mt-5">
            <b-container>
                <b-row align-v="center">
                    <!--add Batch button start-->
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBatch @click="resetBatchForm()">Add Batch</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getAdminSupervisorBatchSearchCount(keyword)" placeholder="Type a code" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAdminSupervisorBatchSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="adminSupervisorSwitchPage()"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="adminSupervisorSwitchPage()" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="adminSupervisorRefresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div v-if="adminSelectBranch != null" style="text-align:right">
                        <span>Branch :&nbsp;</span>
                        <b-form-select v-model="adminSelectBranch" size="sm" class="bnclr" id="bnclr2" @input="getAdminSupervisorBatchCount(adminSelectBranch)">
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in adminSupervisorBranches" :value="item" v-bind:key="item.branchId">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                    <span>Batch</span>
                    <hr />
                    <b-table responsive hover :items="batches" :fields="fields" :current-page="currentPage" :per-page="batches.pageCount" ref="table">
                        <!-- <template v-slot:cell(code)="data">
                        <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report">
                            {{ data.item.code }}
                        </span>
                    </template> -->
                        <template v-slot:cell(code)="data">
                            {{ data.item.code }}
                        </template>
                        <template v-slot:cell(syllabus)="data">
                            {{ data.item.syllabus.name }}
                        </template>
                        <template v-slot:cell(studentCount)="data">
                            {{ data.item.studentCount }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" @click="viewBatch(data.item)">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBatch(data.item)">
                                <font-awesome-icon icon="edit" />
                            </b-button>
                            <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button> -->
                            <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="View Batch Report" @click="batchReport(data.item)">
                                <font-awesome-icon icon="file" />
                            </b-button>
                            <!-- <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Batch Complete" @click="batchFinish(data.item)">
                                <font-awesome-icon icon="check" />
                            </b-button> -->
                        </template>
                    </b-table>
                </b-card>
            </b-container>

            <!--popup start for Batch Register-->
            <b-modal id="addBatch" title="Batch" hide-footer ref="modal" no-close-on-backdrop>
                <div class="form-row">
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Syllabus:</label>
                            <b-form-select class="form-control" v-model="batch.syllabus" :class="{
                  'is-invalid': $v.batch.syllabus.$error,
                  'is-valid': !$v.batch.syllabus.$invalid,
                }">
                                <option value="null" disabled>Select Syllabus</option>
                                <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Batch Code:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.batch.code.$model" :class="{
                  'is-invalid': $v.batch.code.$error,
                  'is-valid': !$v.batch.code.$invalid,
                }" placeholder="Enter your Code">
                            </b-form-input>
                            <div class="valid-feedback">Your Code is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batch.code.required">Code is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Batch Type:</label>
                            <b-form-select class="form-control" v-model="batch.batchType" :class="{'is-invalid': $v.batch.batchType.$error,'is-valid': !$v.batch.batchType.$invalid,}">
                                <option value="null" disabled>Select Batch Type</option>
                                <option v-for="item in allBatchType" :value="item.type" v-bind:key="item.index">
                                    <strong>{{ item.type }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5" v-if="!batchtTypeCreate">
                        <div class="form-group my-2">
                            <b-button class="text-center" variant="primary" size="sm" @click="batchtTypeCreate = true">Create</b-button>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                        <div class="form-group my-2">
                            <!-- <label>Batch Type:</label> -->
                            <b-form-input type="text" class="form-control" v-model.trim="$v.batchType.$model" :class="{'is-invalid': $v.batchType.$error,'is-valid': !$v.batchType.$invalid,}" placeholder="Enter batch type">
                            </b-form-input>
                            <div class="valid-feedback">Batch Type is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batchType.required">Batch Type is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                        <div class="form-group my-2">
                            <b-button class="text-center" variant="outline-primary" size="sm" @click="createBatchType()">Add</b-button>
                            <b-button class="text-center mx-2" variant="outline-danger" size="sm" @click="resetBatchType()">cancel</b-button>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>StudentCount:</label>
                            <b-form-input type="number" class="form-control" v-model.trim="$v.batch.studentCount.$model" :class="{
                  'is-invalid': $v.batch.studentCount.$error,
                  'is-valid': !$v.batch.studentCount.$invalid,
                }" placeholder="Enter your StudentCount">
                            </b-form-input>
                            <div class="valid-feedback">Your StudentCount is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batch.studentCount.required">StudentCount is required</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch:</label>
                            <b-form-select class="form-control" v-model="batch.branch" :class="{
                  'is-invalid': $v.batch.branch.$error,
                  'is-valid': !$v.batch.branch.$invalid,
                }">
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in adminSupervisorBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Start Date:</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY" v-model="batch.startDate" :class="{
                    'is-invalid': $v.batch.startDate.$error,
                    'is-valid': !$v.batch.startDate.$invalid,
                  }" type="date"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.batch.startDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Batch Timing:</label>
                            <br />
                            <label>From:</label>
                            <!-- <b-form-timepicker v-model="batch.startTime" :class="{
                  'is-invalid': $v.batch.startTime.$error,
                  'is-valid': !$v.batch.startTime.$invalid,
                }" locale="en"></b-form-timepicker> -->
                            <b-form-input v-model="batch.startTime" :class="{
                    'is-invalid': $v.batch.startTime.$error,
                    'is-valid': !$v.batch.startTime.$invalid,
                  }" type="time"></b-form-input>
                            <div class="valid-feedback">Start Time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batch.startTime.required">Start Time invalid!</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group"> -->
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>To:</label>
                            <!-- <b-form-timepicker v-model="batch.endTime" :class="{
                  'is-invalid': $v.batch.endTime.$error,
                  'is-valid': !$v.batch.endTime.$invalid,
                }" locale="en"></b-form-timepicker> -->
                            <b-form-input v-model="batch.endTime" :class="{
                    'is-invalid': $v.batch.endTime.$error,
                    'is-valid': !$v.batch.endTime.$invalid,
                  }" type="time"></b-form-input>
                            <div class="valid-feedback">End Time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batch.endTime.required">End Time invalid!</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="text-center">
                        <b-button variant="outline-success" @click="putBatch()">Submit</b-button>
                        <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                    </div>
                </div>
            </b-modal>
            <!--popup end for Batch Register-->

            <!-- View Batch Start -->
            <b-container>
                <b-modal no-close-on-backdrop hide-footer title="View Batch" ref="viewBatchModal">
                    <div class="form-control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Syllabus</label>
                                <b-form-input v-model="batch.syllabus" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Batch Code</label>
                                <b-form-input v-model="batch.code" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Student Count</label>
                                <b-form-input v-model="batch.studentCount" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input v-model="batch.branch" class="form-control" disabled></b-form-input>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Start Date</label>
                                <b-form-input v-model="batch.startDate" class="form-control" disabled></b-form-input>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Start Time</label>
                                <b-form-input v-model="batch.startTime" class="form-control" disabled></b-form-input>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>End Time</label>
                                <b-form-input v-model="batch.endTime" class="form-control" disabled></b-form-input>
                            </div>
                        </div>

                        <br />
                    </div>
                </b-modal>
            </b-container>
            <!-- View Batch End-->

            <!-- selected user detailed history -->
            <b-modal id="showBatchReportModal" scrollable size="lg" hide-footer header-bg-variant="primary" no-close-on-backdrop no-close-on-esc centered v-model="showBatchReportModal">
                <template slot="modal-header">
                    <b-col class="mt-1"><a style="color:white;font-weight:bold;font-size:1.4rem;text-align:center;" v-if="batchDetails!=null">Code : {{batchDetails.code}}</a></b-col>
                    <b-col cols="1">
                        <button type="button" class="close fa-solid fa-xmark" data-dismiss="modal" aria-label="Close" @click="showBatchReportModal = false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </b-col>
                </template>

                <b-tabs pills justified card align="center">
                    <b-tab title="Syllabus Content" active>
                        <b-card-text>
                            <b-container>
                                <b-card v-if="selectedCourseContentNode!=null && selectedCourseContentNode.childrens.length != 0" class="shadow border-0 mx-auto" style="background:#d0dcf5;border:2px solid grey;border-radius:25px;width:100%;margin-bottom:2em;">
                                    <div role="tablist">
                                        <b-row class="mb-3" align-v="center">
                                            <b-col cols="2">
                                                <b-dropdown variant="warning" size="sm" class="shadow" style="">
                                                    <template slot="button-content"><span>{{contentType}}</span></template>
                                                    <b-dropdown-item @click="filterByContentType('ALL')">All</b-dropdown-item>
                                                    <b-dropdown-item @click="filterByContentType('Video')">Video</b-dropdown-item>
                                                    <b-dropdown-item @click="filterByContentType('Quiz')">Quiz</b-dropdown-item>
                                                    <b-dropdown-item @click="filterByContentType('Material')">Material</b-dropdown-item>
                                                </b-dropdown>
                                            </b-col>
                                            <!-- content type -->
                                            <b-col class="d-flex justify-content-center">
                                                <a style="color:#4274d6;font-weight:bold;font-size:1.2rem;text-align:center;" v-if="contentType=='ALL'">Overall Content</a>
                                                <a style="color:#4274d6;font-weight:bold;font-size:1.2rem;text-align:center;" v-else>{{contentType}} Content</a>
                                            </b-col>
                                        </b-row>

                                        <b-card no-body class="mb-1" v-for="(l1, l1index) in selectedCourseContentNode.childrens" v-bind:key="l1index">
                                            <!-- {{l1index}}====={{selectedCourseContentNode.childrens.length}} -->
                                            <b-card-header header-tag="header" style="cursor:pointer;border-bottom:1px solid #d0dcf5" role="tab" v-if="l1index != selectedCourseContentNode.childrens.length" v-b-toggle="'accordion-1'+l1index">
                                                <i style="color:#2780B2;" icon="fa-solid fa-minus" class="when-opened float-right mt-2 mr-4"></i> <i style="color:#2780B2;" icon="fa-solid fa-plus" class="when-closed  when-closed float-right mt-2 mr-4"></i>
                                                <span class="when-opened float-end mt-2 mr-4">
                                                    <font-awesome-icon icon="minus" style="color:#2780B2;" />
                                                </span>
                                                <span class="when-closed  when-closed float-end mt-2 mr-4">
                                                    <font-awesome-icon icon="plus" style="color:#2780B2;" />
                                                </span>
                                                <b-row class="card-header" style="padding:5px;">
                                                    <b-col class="d-flex justify-content-start ml-4">
                                                        <strong style="color:grey;">{{l1.word}}</strong>
                                                    </b-col>
                                                </b-row>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-1'+l1index" visible role="tabpanel">
                                                <b-card-body>
                                                    <b-collapse :id="'accordion-2'+l1index" visible role="tabpanel">
                                                        <b-card-body>
                                                            <div class="table-responsive" v-if="hasContent(l1index)">
                                                                <table class="table table-hover table-bordered table-space">
                                                                    <!-- All content only -->
                                                                    <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='ALL' && (l2.video || l2.quiz || l2.resource)">
                                                                        <td style="width:6%">
                                                                            <span v-if="l2.video">
                                                                                <font-awesome-icon icon="video" /></span>
                                                                            <span v-if="l2.resource">
                                                                                <font-awesome-icon icon="file" /></span>
                                                                            <span v-if="l2.quiz">
                                                                                <font-awesome-icon icon="pen" /></span>
                                                                        </td>
                                                                        <td>
                                                                            <span>{{l2.word}}</span>
                                                                            <!-- <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row> -->
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            <span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                                <span v-if="l2.complete == undefined || l2.complete == false">
                                                                                    <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <!-- Video content only -->
                                                                    <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Video' && l2.video">
                                                                        <td style="width:5%">
                                                                            <span v-if="l2.video">
                                                                                <font-awesome-icon icon="video" /></span>
                                                                        </td>
                                                                        <td>
                                                                            <span>{{l2.word}}</span>
                                                                            <!-- <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row> -->
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            <span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                                <span v-if="l2.complete == undefined || l2.complete == false">
                                                                                    <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <!-- quiz content only -->
                                                                    <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Quiz' && l2.quiz">
                                                                        <td style="width:5%">
                                                                            <span v-if="l2.quiz">
                                                                                <font-awesome-icon icon="pen" /></span>
                                                                        </td>
                                                                        <td>
                                                                            <span>{{l2.word}}</span>
                                                                            <b-row v-if="l2.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l2.releaseOn}}</span></span></b-row>
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            <span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                                <span v-if="l2.complete == undefined || l2.complete == false">
                                                                                    <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <!-- resource content only -->
                                                                    <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Material' && l2.resource">
                                                                        <td style="width:5%">
                                                                            <span v-if="l2.resource">
                                                                                <font-awesome-icon icon="file" /></span>
                                                                        </td>
                                                                        <td>
                                                                            <span>{{l2.word}}</span>
                                                                            <b-row v-if="l2.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l2.releaseOn}}</span></span></b-row>
                                                                        </td>
                                                                        <td style="width:5%">
                                                                            <span>
                                                                                <span v-if="l2.complete == true">
                                                                                    <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                                <span v-if="l2.complete == undefined  || l2.complete == false">
                                                                                    <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" v-else>
                                                                <span class="text-secondary" style="font-weight:bold;font-size:1.2em;">No {{contentType}} !</span>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                    <!-- </b-card> -->
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-card>
                                <b-card v-else class="text-center">
                                    <span>No data found</span>
                                </b-card>
                            </b-container>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Table" @click="getAllBatchDetailCount()">
                        <b-card-text>
                            <b-container>
                                <b-row align-v="center">
                                    <b-col col sm="12" md="4" lg="4" id="r1">
                                        <input type="search" class="searchbar" v-model="batchKeyword" v-on:keyup.enter="getBatchSearchCount(batchKeyword)" placeholder="Type a name" />
                                    </b-col>
                                    <b-col col lg="2" md="1" id="r1" sm="6">
                                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBatchSearchCount(batchKeyword)">Search</b-button>
                                    </b-col>
                                    <b-col sm="8" md="3" lg="6" class="my-1" id="r1">
                                        <b-pagination v-model="batchCurrentPage" :total-rows="totalBatchCount" :per-page="batchPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchBatchPage(batchCurrentPage, batchPageCount)"></b-pagination>
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" id="r2">
                                    <b-col sm="6" md="3" lg="4" class="" id="r1">
                                        <label class="font mx-1">Per Page</label>
                                        <b-form-select class="br" id="per-page-select" v-model="batchPageCount" @input="switchBatchPage(batchCurrentPage, batchPageCount)" :options="batchPageOptions" size="sm">
                                        </b-form-select>
                                    </b-col>
                                    <b-col col sm="6" md="2" lg="8" id="r1" class="text-center">
                                        <span style="color: #0974ff; cursor: pointer" @click="refreshBatch()">
                                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <b-container>
                                <b-card class="card mt-1">
                                    <hr />
                                    <b-table responsive hover :items="branchBatcheDetails" :fields="branchBatchField" :current-page="batchCurrentPage" :per-page="branchBatcheDetails.batchPageCount" ref="table">
                                        <template v-slot:cell(code)="data">
                                            {{ data.item.code }}
                                        </template>
                                        <template v-slot:cell(syllabusLevel2)="data">
                                            {{ data.item.syllabusLevel2 }}
                                        </template>
                                        <template v-slot:cell(tutor)="data">
                                            {{ data.item.tutor.userName }}
                                        </template>
                                        <template v-slot:cell(studentCount)="data">
                                            {{ data.item.studentCount }}
                                        </template>
                                        <template v-slot:cell(branch)="data">
                                            {{ data.item.branch.name }}
                                        </template>
                                        <template v-slot:cell(agent)="data">
                                            {{ data.item.agent.name }}
                                        </template>
                                        <template v-slot:cell(createdAt)="data">
                                            <div class="cellSize">
                                                {{ dateTimeFormat(data.item.createdAt, "dateTime") }}
                                            </div>
                                        </template>
                                    </b-table>
                                </b-card>
                            </b-container>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-modal>
        </div>
    </div>
    <!-- ===================== ADMIN SUPERVISOR END ======================== -->
</div>
</template>

<script>
import axios from "axios";
import BatchService from "../service/BatchService";
import BranchService from "../service/BranchService";
import BranchBatchService from "../service/BranchBatchService";
import TutorService from "../service/TutorService";
import Account from '../service/Account.js';
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import moment from "moment-timezone";
import Swal from 'sweetalert2';

export default {
    name: "BranchBatch",
    components: {},
    data() {
        return {
            batch: {
                batchId: "",
                branch: null,
                code: "",
                batchType: null,
                createdAt: "",
                endTime: "",
                finished: "",
                startDate: "",
                startTime: "",
                studentCount: "",
                syllabus: null,
                updatedAt: "",
            },
            syllabusContent: {
                branch: null,
                code: "",
                date: "",
                startTime: "",
                endTime: "",
                syllabusContent: "",
                word: "",
                type: "",
                tutorId: "",
                complete: false,
                lectureId: "",
            },
            courseOptions: [{
                    text: 'Video',
                    value: 'video'
                },
                {
                    text: 'File',
                    value: 'file'
                },
                {
                    text: 'Quiz',
                    value: 'quiz'
                }
            ],
            completeOptions: [{
                    text: 'True',
                    value: true
                },
                {
                    text: 'False',
                    value: false
                }
            ],
            showBatchReportModal: false,
            batches: [],
            paginationBranchBatches: true,
            searchPagination: false,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot",
            }, ],
            totalCount: 0,

            // Batch Table
            paginationBatches: true,
            searchBatchPagination: false,
            batchCurrentPage: 1,
            batchPageCount: 5,
            batchPageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot",
            }, ],
            totalBatchCount: 0,
            batchKeyword: "",

            // Batch Table

            keyword: "",
            agentId: "",
            branchBatch: {
                code: "",
                syllabus: null,
                studentCount: "",
                selectedL1: "",
                selectedL2: "",
                // selectedL3: "",
                batchId: "",
                branch: null,
                classDate: "",
                startTime: "",
                endTime: "",
                feedback: "",
                tutor: null,
            },
            branchBatches: [],
            fields: [{
                    key: "code",
                    label: "Code",
                },
                {
                    key: "batchType",
                    label: "Batch Type",
                },
                {
                    key: "syllabus",
                    label: "Syllabus",
                },
                {
                    key: "studentCount",
                    label: "Student",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],
            supervisorBranchBatchField: [{
                    key: "classDate",
                    label: "ClassDate",
                },
                {
                    key: "createdAt",
                    label: "createdAt",
                },
                {
                    key: "code",
                    label: "Code",
                },
                {
                    key: "syllabusLevel2",
                    label: "Syllabus",
                },
                {
                    key: "tutor",
                    label: "Tutor",
                },
                {
                    key: "studentCount",
                    label: "Student",
                    tdClass: "text-center",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "feedback",
                    label: "Feedback",
                },
            ],
            branchBatchField: [{
                    key: "classDate",
                    label: "ClassDate",
                },
                {
                    key: "createdAt",
                    label: "createdAt",
                },
                {
                    key: "code",
                    label: "Code",
                },
                {
                    key: "syllabusLevel2",
                    label: "Syllabus",
                },
                {
                    key: "tutor",
                    label: "Tutor",
                },
                {
                    key: "studentCount",
                    label: "Student",
                    tdClass: "text-center",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "agent",
                    label: "Supervisor",
                },
            ],
            syllabusLevel1: [],
            syllabusLevel2: [],
            // syllabusLevel3: [],
            selectedL1: null,
            selectedL2: null,
            // selectedL3: null,
            selectedBranch: "Branch",
            allBranches: "",
            selectedSyllabus: "Syllabus",
            allSyllabus: "",
            selectedTutor: "Tutor",
            allTutors: [],
            selectedBatch: "Batch",
            allBatches: "",
            showBatchStatusModal: false,
            batchDetails: null,
            contentType: "ALL",
            selectedCourseContentInfo: null,
            selectedCourseContentNode: null,
            branchId: null,
            branchBatcheDetails: [],
            batchNodeDetail: null,
            isSupervisor: false,
            isAdminSupervisor: false,
            adminSelectBranch: null,
            adminSupervisorBranches: [],
            classFeedback: "not_accepted",
            allBatchType:[],
            batchType:"",
            batchtTypeCreate: false,
            disableButton: false,
        };
    },

    validations: {
        batchType: {
            required,
        },
        batch: {
            code: {
                required,
                minLength: minLength(3),
            },
            studentCount: {
                required,
                minLength: minLength(1),
            },
            batchType: {
                required
            },
            syllabus: {
                required,
            },
            branch: {
                required,
            },
            startTime: {
                required,
            },
            endTime: {
                required,
                minValue(endTime, {startDate,startTime}) {
                    let startDateTime = new Date(startDate + " " + startTime);
                    let DateOfStart = new Date(startDateTime.getTime());
                    let endDateTime = new Date(startDate + " " + endTime);
                    let DateOfEnd = new Date(endDateTime.getTime());
                    if (DateOfStart < DateOfEnd) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            startDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (new Date(selectedDT.setTime(currentDT.getTime())) >= currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
        },
        branchBatch: {
            code: {
                required,
                minLength: minLength(3),
            },
            studentCount: {
                required,
                minLength: minLength(1),
            },
            syllabus: {
                required,
            },
            // classDate: {
            //   required,
            //    minValue: (value) => {
            //     let selectedDT = new Date(value);
            //     let currentDT = new Date();
            //     if (selectedDT < currentDT) {
            //       return true;
            //     } else {
            //       return false;
            //     }
            //   },
            // },

            classDate: {
                required,
                minValue(classDate, {
                    startTime
                }) {
                    let startDate = new Date(classDate + " " + startTime);
                    let classDateOf = new Date(startDate.getTime());
                    let currentDate = new Date();
                    if (currentDate < classDateOf) {
                        return false;
                    }
                    let msBetweenDates = Math.abs(classDateOf.getTime() - currentDate.getTime())
                    // let msBetweenDates = Math.abs(startDate.getTime() - currentDate.getTime())
                    // 👇️ convert ms to hours                  min  sec   ms
                    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                    if (hoursBetweenDates <= 24) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            startTime: {
                required,
            },
            endTime: {
                required,
            },
            branch: {
                required,
            },
            tutor: {
                required,
            },
        },
        syllabusContent: {
            // word:{
            //     required
            // }
            startTime: {
                required
            },
            endTime: {
                required
            },
            tutorId: {
                required
            },
            date: {
                required,
                minValue(date, {
                    startTime
                }) {
                    let startDate = new Date(date + " " + startTime);
                    let classDateOf = new Date(startDate.getTime());
                    let currentDate = new Date();
                    if (currentDate < classDateOf) {
                        return false;
                    }
                    let msBetweenDates = Math.abs(classDateOf.getTime() - currentDate.getTime())
                    // let msBetweenDates = Math.abs(startDate.getTime() - currentDate.getTime())
                    // 👇️ convert ms to hours                  min  sec   ms
                    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
                    if (hoursBetweenDates <= 24) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
        },
        selectedL1: {
            required,
        },
        selectedL2: {
            required,
        },
        // selectedL3: {
        //   required,
        // },
    },

    mounted() {
        if (localStorage.getItem("agentId") != null) {
            if (localStorage.getItem("ROLE_ADMIN_SUPERVISOR")) {
                this.isAdminSupervisor = true;
                this.adminSupervisorBranches = JSON.parse(localStorage.getItem('branches'));
                this.getAdminSupervisor();
            } else if (localStorage.getItem("ROLE_SUPERVISOR")) {
                this.loading = true;
                this.isSupervisor = true;
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            this.supervisorData();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else {
                let self = this;
                return new Promise((resolve, reject) => {
                    Account.getAccessToken(localStorage.getItem("refreshToken"))
                        .then((response) => {
                            self.getAllBranches();
                            self.getAllSyllabus();
                            self.getAllTutors();
                            self.getAllBatches();
                            // self.putAllSyllabus();
                            //   self.getCount();
                            this.loading = true;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Account Deactivated',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (this.isAdminSupervisor) {
                if (oldVal && !newVal) {
                    this.getAdminSupervisorBatchCount();
                }
            } else if (this.isSupervisor) {
                if (oldVal && !newVal) {
                    this.getCount();
                }
            }
        },
        batchKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllBatchDetailCount();
            }
        },
    },

    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        putBatch: function () {
            this.$v.batch.$touch();
            if (this.$v.batch.$invalid) {
                this.submitstatus = "FAIL";
            } else {
                var data = {
                    code: this.batch.code
                }
                return new Promise((resolve, reject) => {
                    axios.post("batch/code/unique", data)
                        .then((response) => {
                            if (response.data == true) {
                                this.submitstatus = "SUCCESS";
                                var batch = {
                                    code: this.batch.code,
                                    batchType: this.batch.batchType,
                                    startTime: this.batch.startTime + ":00",
                                    endTime: this.batch.endTime + ":00",
                                    startDate: this.batch.startDate,
                                    studentCount: this.batch.studentCount,
                                    syllabus: this.batch.syllabus,
                                    branch: this.batch.branch
                                }
                                return new Promise((resolve, reject) => {
                                    BatchService.insertBatch(batch)
                                        .then((response) => {
                                            this.$swal({
                                                title: "Good job!",
                                                text: "Batch Added successfully!!!!!",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.modal.hide();
                                            this.getAdminSupervisorBatchCount();
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.$swal({
                                    title: "BatchCode is not unique",
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        createBatchType: function() {
            this.$v.batchType.$touch();
            if (!this.$v.batchType.$invalid) {
                this.allBatchType.splice(0);
                let data = {
                    type : this.batchType
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("batchType/create",data)
                        .then((response) => {
                            this.allBatchType = response.data;
                            this.batchtTypeCreate = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllBatchType: function() {
            this.allBatchType.splice(0);
            return new Promise((resolve, reject) => {
                axios
                    .post("batchType/getAll")
                    .then((response) => {
                        this.allBatchType = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        validateWithDay(date) {
            const then = new Date(date.replace('IST', ''));
            const now = new Date();
            const msBetweenDates = Math.abs(then.getTime() - now.getTime());
            // 👇️ convert ms to hours                  min  sec   ms
            const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            if (hoursBetweenDates <= 24) {
                return true;
            } else {
                return false;
            }
        },
        async contentEditModal(content) {
            await this.getBranchTutors(this.batchDetails.branch);
            await this.getBranchBatchDetails(this.batchDetails.batchId, content.lectureId);
            // console.log("content========== ", content);
            // console.log("this.batchDetails========== ", this.batchDetails);
            // this.syllabusContent = content;
            let type;
            let feedback;
            if (content.quiz) {
                type = "quiz";
            } else if (content.resource) {
                type = "resource";
            } else if (content.video) {
                type = "video";
            }
            if (content.feedback == undefined) {
                feedback = "";
            } else {
                this.classFeedback = "accepted"
                feedback = content.feedback;
            }
            this.syllabusContent = {
                date: this.batchDetails.classDate,
                type: type,
                word: content.word,
                tutorId: content.tutorId,
                complete: true,
                feedback: feedback,
                childrens: [],
                lectureId: content.lectureId,
                code: this.batchDetails.code,
                branch: this.batchDetails.branch,
                startTime: this.batchDetails.startTime,
                endTime: this.batchDetails.endTime,
            };
            this.showBatchStatusModal = false;
            this.$refs.syllabusContentEdit.show();
        },
        getBranchBatchDetails(batchId, lectureId) {
            let data = {
                batchId: batchId,
                lectureId: lectureId
            }
            return new Promise((resolve, reject) => {
                axios
                    .post("branchBatch/get", data)
                    .then((response) => {
                        console.log("getBranchBatchDetails====== ", response.data.classDate);
                        this.batchDetails = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        classRevert(content) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't revert the class!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        batchId: this.batchDetails.batchId,
                        lectureId: content.lectureId
                    }
                    return new Promise((resolve, reject) => {
                        axios
                            .post("branchBatch/class/revert", data)
                            .then((response) => {
                                this.showBatchStatusModal = false;
                                Swal.fire(
                                    'Deleted!',
                                    'Your class has been deleted.',
                                    'success'
                                )
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                }
            })
        },
        async getAdminSupervisor() {
            await this.global();
            this.adminSelectBranch = this.adminSupervisorBranches[0];
            await this.getAdminSupervisorBatchCount(this.adminSupervisorBranches[0]);
            await this.getAllSyllabus();
            await this.getAllBatchType();
        },
        syllabusContentUpdate: function () {
            this.$v.syllabusContent.$touch();
            if (!this.$v.syllabusContent.$invalid) {
                let video = false;
                let resource = false;
                let quiz = false;
                if (this.syllabusContent.type == "video") {
                    video = true;
                } else if (this.syllabusContent.type == "resource") {
                    resource = true;
                } else if (this.syllabusContent.type == "quiz") {
                    quiz = true;
                }
                var content = {
                    date: this.syllabusContent.date,
                    free: false,
                    quiz: quiz,
                    word: this.syllabusContent.word,
                    video: video,
                    tutorId: this.syllabusContent.tutorId,
                    complete: this.syllabusContent.complete,
                    feedback: this.syllabusContent.feedback,
                    resource: resource,
                    childrens: [],
                    lectureId: this.syllabusContent.lectureId,
                    liveVideo: false,
                    thumbnail: false,
                }
                let data = {
                    syllabusContent: content,
                    syllabusId: this.batchDetails.syllabus.syllabusId,
                    batchId: this.batchDetails.batch.batchId,
                    startTime: this.syllabusContent.startTime,
                    endTime: this.syllabusContent.endTime,
                }
                console.log("data========== ", data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/branchBatch/syllabus/content/update", data)
                        .then((response) => {
                            this.$swal("Syllabus content is updated!!!!!");
                            this.resetSyllabusContent();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        resetSyllabusContent() {
            this.syllabusContent = {
                branch: null,
                code: "",
                date: "",
                startTime: "",
                endTime: "",
                feedback: "",
                word: "",
                type: "",
                tutorId: "",
                complete: false,
                lectureId: "",
            }
            this.classFeedback = "not_accepted";
            this.$nextTick(() => {
                this.$v.syllabusContent.$reset();
            });
            this.$refs.syllabusContentEdit.hide();
        },
        getAdminSupervisorBatchCount: function () {
            this.loading = true;
            this.paginationBatches = true;
            this.searchBatchPagination = false;
            this.currentPage = 1;
            if (this.adminSelectBranch != null) {
                let data = {
                    agentId: this.agentId,
                    branch: this.adminSelectBranch
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("/batch/admin/supervisor/count", data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllAdminSupervisorBatchPageable();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllAdminSupervisorBatchPageable: function () {
            if (this.adminSelectBranch != null) {
                let data = {
                    agentId: this.agentId,
                    branch: this.adminSelectBranch
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("/batch/admin/supervisor/getAll/" + (this.currentPage - 1) + "/" + this.pageCount, data)
                        .then((response) => {
                            this.batches = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        adminSupervisorSwitchPage() {
            this.loading = true;
            console.log("this.currentPage====== ", this.currentPage);
            console.log("this.pageCount====== ", this.pageCount);
            console.log("this.branch====== ", this.branch);
            if (this.paginationBatches) {
                this.getAllAdminSupervisorBatchPageable()
            } else if (this.searchBatchPagination) {
                this.getAllAdminSupervisorBatchSearchPageable()
            }
        },
        getAdminSupervisorBatchSearchCount: function () {
            this.loading = true;
            this.paginationBatches = false;
            this.searchBatchPagination = true;
            this.currentPage = 1;
            if (this.adminSelectBranch != null) {
                let data = {
                    agentId: this.agentId,
                    branch: this.adminSelectBranch
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("/batch/admin/supervisor/search/count/" + this.keyword.toLowerCase(), data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllAdminSupervisorBatchSearchPageable();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllAdminSupervisorBatchSearchPageable: function () {
            if (this.adminSelectBranch != null) {
                let data = {
                    agentId: this.agentId,
                    branch: this.adminSelectBranch
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("/batch/admin/supervisor/search/getAll/" + (this.currentPage - 1) + "/" + this.pageCount + "/" + this.keyword.toLowerCase(), data)
                        .then((response) => {
                            this.batches = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        viewBatch: function (batch) {
            // this.resetForm();
            this.batch = {
                batchId: batch.batchId,
                branch: batch.branch.name,
                code: batch.code,
                createdAt: batch.createdAt,
                endTime: batch.endTime,
                finished: batch.finished,
                startDate: batch.startDate,
                startTime: batch.startTime,
                studentCount: batch.studentCount,
                syllabus: batch.syllabus.name,
                updatedAt: batch.updatedAt,
            }
            console.log("getBatch======== ", this.batch);
            this.$refs.viewBatchModal.show();

        },
        batchReport: function (item) {
            console.log("batchReport=======", item);
            this.batchDetails = {
                syllabusId: item.syllabus.syllabusId,
                batchId: item.batchId,
                name: item.branch.name,
                code: item.code,
                startTime: item.startTime,
                endTime: item.endTime,
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/branchBatch/get/node", this.batchDetails)
                    .then((response) => {
                        this.batchNodeDetail = response.data;
                        this.selectedCourseContentNode =
                            this.batchNodeDetail.syllabusContent;
                        this.showBatchReportModal = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        batchStatus: function (item) {
            console.log("batchStatus=======", item);
            this.batchDetails = {
                syllabusId: item.syllabus.syllabusId,
                batchId: item.batchId,
                name: item.branch.name,
                code: item.code,
                startTime: item.startTime,
                endTime: item.endTime,
                branch: item.branch,
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/branchBatch/get/node", this.batchDetails)
                    .then((response) => {
                        this.batchNodeDetail = response.data;
                        this.selectedCourseContentNode =
                            this.batchNodeDetail.syllabusContent;
                        this.showBatchStatusModal = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBatchDetailCount: function () {
            this.loading = true;
            this.paginationBranchBatches = true;
            this.searchPagination = false;
            this.batchKeyword = "";
            this.batchCurrentPage = 1;
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchDetailCount(this.batchDetails)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalBatchCount = response.data;
                        this.getAllBatchDetailPageable(
                            this.batchCurrentPage,
                            this.batchPageCount
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBatchDetailPageable: function (batchCurrentPage, batchPageCount) {
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchDetailPageable(
                        batchCurrentPage,
                        batchPageCount,
                        this.batchDetails
                    )
                    .then((response) => {
                        // console.log("branchBatcheDetails",response.data)
                        this.branchBatcheDetails = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        batchClassAddModal(lectureId) {
            let data = {
                branch: this.branch,
                code: this.batchDetails.code,
            };
            this.getBranchTutors(this.branch);
            console.log("lectureId=== ", lectureId);
            console.log("batchDetails------ ", this.batchDetails);
            return new Promise((resolve, reject) => {
                BranchBatchService.getCodeSearch(data)
                    .then((response) => {
                        if (response.data != "") {
                            this.syllabusLevel1.splice(0);
                            this.syllabusLevel2.splice(0);
                            this.showBatchStatusModal = false;
                            this.branchBatch = response.data;
                            this.$refs.modal.show();
                            var l1 = this.branchBatch.syllabus.syllabusContent;
                            for (var i = 0; i < l1.childrens.length; i++) {
                                this.syllabusLevel1.push(l1.childrens[i]);
                                var l2 = l1.childrens[i];
                                for (var j = 0; j < l2.childrens.length; j++) {
                                    // console.log("l2.childrens[j].lectureId=== ",l2.childrens[j].lectureId);
                                    if (l2.childrens[j].lectureId == lectureId) {
                                        console.log("===if===");
                                        this.selectedL1 = l1.childrens[i];
                                        this.level1Selected(this.selectedL1);
                                        console.log(l2.childrens[j]);
                                        this.selectedL2 = l2.childrens[j];
                                    }
                                    // break;
                                }
                                // break;
                                // }
                                // break;
                            }
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Batch Code doesnot exist!",
                            });
                            // this.batch.code ="";
                            this.resetForm();
                        }

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getCourseContentInfo: function () {
        //     let data = {
        //         courseId: this.selectedCourseId
        //     }
        //     return new Promise((resolve, reject) => {
        //         axios.post("/branchBatch/getContentInfo", data)
        //             .then(response => {
        //                 this.selectedCourseContentInfo = response.data;
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             })
        //     })
        // },
        filterByContentType: function (type) {
            this.contentType = type;
        },
        checkViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (
                    var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++
                ) {
                    if (
                        lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        videoViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (
                    var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++
                ) {
                    if (
                        this.selectedCourseHistory.nodeHistory[i].node.video &&
                        lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        quizViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (
                    var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++
                ) {
                    if (
                        this.selectedCourseHistory.nodeHistory[i].node.quiz &&
                        lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        resourceViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (
                    var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++
                ) {
                    if (
                        this.selectedCourseHistory.nodeHistory[i].node.resource &&
                        lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasContent(l1Index) {
            if (this.contentType == "ALL") {
                return true;
            } else if (this.contentType == "Video") {
                for (
                    var i = 0; i <
                    this.selectedCourseContentNode.childrens[l1Index]
                    .childrens.length; i++
                ) {
                    if (
                        this.selectedCourseContentNode.childrens[l1Index]
                        .childrens[i].video
                    ) {
                        return true;
                    }
                }
            } else if (this.contentType == "Quiz") {
                for (
                    var j = 0; j <
                    this.selectedCourseContentNode.childrens[l1Index]
                    .childrens.length; j++
                ) {
                    if (
                        this.selectedCourseContentNode.childrens[l1Index]
                        .childrens[j].quiz
                    ) {
                        return true;
                    }
                }
            } else if (this.contentType == "Material") {
                for (
                    var k = 0; k <
                    this.selectedCourseContentNode.childrens[l1Index]
                    .childrens.length; k++
                ) {
                    if (
                        this.selectedCourseContentNode.childrens[l1Index]
                        .childrens[k].resource
                    ) {
                        return true;
                    }
                }
            }
        },
        async supervisorData() {
            await this.setSupervisor();
            await this.getAllBranches();
            await this.getAllSyllabus();
            await this.getAllBatches();
            await this.getAllTutors();
            await this.getCount();
        },
        setSupervisor() {
            this.branch = JSON.parse(localStorage.getItem("branch"));
            this.branchBatch.branch = this.branch;
            if (localStorage.getItem("ROLE_SUPERVISOR")) {
                this.isRoleSupervisor = true;
            } else {
                this.isRoleSupervisor = false;
            }
        },

        putBranchBatch: function () {
            this.$v.branchBatch.$touch();
            if (this.$v.branchBatch.$invalid) {
                this.submitstatus = "FAIL";
                this.disableButton = false;
                console.log("if===========", this.branchBatch);
            } else {
                console.log("this.branchBatch=======", this.branchBatch);
                let data = {
                    batchId: this.branchBatch.batchId,
                    code: this.branchBatch.code,
                    syllabus: this.branchBatch.syllabus,
                    syllabusContent: this.branchBatch.syllabus.syllabusContent,
                    classDate: this.branchBatch.classDate,
                    startTime: this.branchBatch.startTime,
                    endTime: this.branchBatch.endTime,
                    feedback: this.branchBatch.feedback,
                    studentCount: this.branchBatch.studentCount,
                    branch: this.branchBatch.branch,
                    tutor: this.branchBatch.tutor,
                    syllabusLevel1: this.selectedL1.word,
                    syllabusLevel2: this.selectedL2.word,
                    // syllabusLevel3: this.selectedL3.word,
                    lectureId: this.selectedL2.lectureId,
                };
                console.log("data========= ", data);
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    BranchBatchService.insertBranchBatch(this.agentId, data)
                        .then((response) => {
                            this.$swal({
                                title: "Good job!",
                                text: "Batch Added successfully!!!!!",
                                icon: "success",
                                button: "ok",
                            });
                            this.disableButton = false;
                            this.$refs.modal.hide();
                            this.getCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },

        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSyllabus: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post("/syllabus/getAll")
                    .then((response) => {
                        this.allSyllabus = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllTutors: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                TutorService.getAllTutors()
                    .then((response) => {
                        this.allTutors = response.data;
                        // this.allTutors.forEach(e => {
                        //   console.log(e);
                        // })
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllBatches: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                BatchService.getAllBatches()
                    .then((response) => {
                        this.allBatches = response.data;
                        // console.log("allBatches", response.data);
                        // this.tutor.forEach(e => {
                        //   console.log(e);
                        // })
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getBranchBatch: function (branchBatch) {
            this.resetForm();
            this.branchBatch = branchBatch;
        },
        getCount: function () {
            this.branchId = {
                branchId: this.branch.branchId,
            };
            this.loading = true;
            this.paginationBatches = true;
            this.searchBatchPagination = false;
            this.keyword = "";
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                BranchBatchService.getBranchBatchCount(this.branchId)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBranchBatchPageable(
                        currentPage,
                        pageCount,
                        this.branchId
                    )
                    .then((response) => {
                        this.branchBatches = response.data;
                        //  console.log("branchBatches",response.data)
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationBatches) {
                this.getAllPageable(currentPage, pageCount);
                // console.log(this.paginationBatches, "this.paginationBatches");
                // console.log(this.searchBatchPagination, "this.searchBatchPagination");
            } else if (this.searchBatchPagination) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
                // console.log(this.searchBatchPagination, "this.searchBatchPagination");
                // console.log(this.paginationBatches, "this.paginationBatches");
            }
        },

        switchBatchPage: function (batchCurrentPage, batchPageCount) {
            this.loading = true;
            // console.log("switchBatchPage calling");
            if (this.paginationBranchBatches) {
                this.getAllBatchDetailPageable(batchCurrentPage, batchPageCount);
                // console.log(this.paginationBranchBatches, "this.paginationBranchBatches");
                // console.log(this.searchPagination, "this.searchPagination");
            } else if (this.searchPagination) {
                this.getAllBatchSearchPageable(
                    batchCurrentPage,
                    batchPageCount,
                    this.batchKeyword
                );
                // console.log(this.searchPagination, "this.searchPagination");
                // console.log(this.paginationBranchBatches, "this.paginationBranchBatches");
            }
        },

        getCodeSearch: function (item) {
            console.log("item====", item);
            this.batchDetails = {
                syllabusId: item.syllabus.syllabusId,
                batchId: item.batchId,
                name: item.branch.name,
                code: item.code,
                startTime: item.startTime,
                endTime: item.endTime,
            };
            let data = {
                branch: this.branch,
                code: item.code
            };
            console.log("data======", data);
            return new Promise((resolve, reject) => {
                BranchBatchService.getCodeSearch(data)
                    .then((response) => {
                        if (response.data != "") {
                            this.branchBatch = {
                                code: response.data.code,
                                syllabus: response.data.syllabus,
                                studentCount: response.data.studentCount,
                                selectedL1: response.data.selectedL1,
                                selectedL2: response.data.selectedL2,
                                // selectedL3: response.data.selectedL3,
                                batchId: response.data.batchId,
                                branch: response.data.branch,
                                startTime: response.data.startTime,
                                endTime: response.data.endTime,
                                tutor: null,
                            };
                            this.getBranchTutors(response.data.branch);
                            // this.agentId = this.branchBatch.agent.agentId;
                            //   "search Code",
                            //   this.branchBatch.syllabus.syllabusContent.childrens
                            // );
                            //   let l1 = this.branchBatch.syllabus.syllabusContent.childrens;
                            //   for (let i = 0; i < l1.length; i++) {
                            //   this.syllabusLevel1.push(l1[i]);
                            //   }
                            this.getSyllabusNode();
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Batch Code doesnot exist!",
                            });
                            // this.batch.code ="";
                            this.resetForm();
                        }

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSyllabusNode() {
            // console.log("getSyllabusNode====",item);
            this.syllabusLevel1.splice(0);
            return new Promise((resolve, reject) => {
                axios
                    .post("/branchBatch/get/node", this.batchDetails)
                    .then((response) => {
                        console.log("getSyllabusNode====== ", response.data.syllabusContent);
                        if (response.data.syllabusContent != null) {
                            let l1 = response.data.syllabusContent.childrens;
                            for (let i = 0; i < l1.length; i++) {
                                this.syllabusLevel1.push(l1[i]);
                            }
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSearchCount: function (keyword) {
            this.paginationBatches = false;
            this.searchBatchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                BatchService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                BatchService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        // this.tutorId,
                        keyword
                    )
                    .then((response) => {
                        this.branchBatches = response.data;
                        // console.log("Count", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getBatchSearchCount: function (batchKeyword) {
            this.paginationBranchBatches = false;
            this.searchPagination = true;
            this.batchCurrentPage = 1;
            return new Promise((resolve, reject) => {
                BranchBatchService.getBatchSearchCount(batchKeyword.toLowerCase())
                    .then((response) => {
                        this.totalBatchCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllBatchSearchPageable(
                            this.batchCurrentPage,
                            this.batchPageCount,
                            batchKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllBatchSearchPageable: function (batchCurrentPage, batchPageCount, batchKeyword) {
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchSearchPageable(
                        batchCurrentPage,
                        batchPageCount,
                        // this.tutorId,
                        batchKeyword
                    )
                    .then((response) => {
                        this.branchBatcheDetails = response.data;
                        // console.log("branchBatcheDetails", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getBranchTutors: function (branch) {
            this.allTutors.splice(0);
            let data = {
                branchObj: branch,
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/tutor/branch/getAll", data)
                    .then((response) => {
                        // this.allTutors.splice(0);
                        this.allTutors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // level1Selected: function (item) {
        //   this.selectedL2 = null;
        //   this.syllabusLevel2.splice(0);
        //   this.selectedL3 = null;
        //   this.syllabusLevel3.splice(0);
        //   let l2 = item.childrens;
        //   for (let i = 0; i < l2.length; i++) {
        //     this.syllabusLevel2.push(l2[i]);
        //   }
        // },

        // level2Selected: function (item) {
        //   this.selectedL3 = null;
        //   this.syllabusLevel3.splice(0);
        //   let l3 = item.childrens;
        //   for (let i = 0; i < l3.length; i++) {
        //     this.syllabusLevel3.push(l3[i]);
        //   }
        // },

        level1Selected: function (item) {
            this.selectedL2 = null;
            this.syllabusLevel2.splice(0);
            let l2 = item.childrens;
            for (let i = 0; i < l2.length; i++) {
                if (l2[i].complete == undefined) {
                    this.syllabusLevel2.push(l2[i]);
                }
            }
        },

        level2Selected: function (item) {
            let l3 = item.childrens;
            for (let i = 0; i < l3.length; i++) {
                this.syllabusLevel3.push(l3[i]);
            }
            // console.log('Childrens of selected Lvel 3', this.syllabusLevel4)
        },
        // getElementById: function () {
        //   var selected = [];
        //   for (var option of document.getElementById("syllabus").options) {
        //     if (option.selected) {
        //       selected.push(option.value);
        //     }
        //   }
        //   alert(selected);
        // },
        dateTimeFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "dateTime") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
            if (dateType === "date") {
                return moment(date1).format("D/MM/YYYY");
            }
        },

        archive: function (id) {
            return new Promise((resolve, reject) => {
                BranchBatchService.archive(id)
                    .then((response) => {
                        this.$swal("Archive successfully!!!!!");
                        this.getCount();
                        console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unarchive: function (id) {
            return new Promise((resolve, reject) => {
                BranchBatchService.unarchive(id)
                    .then((response) => {
                        this.$swal("UnArchive successfully!!!!!");
                        //  console.log(response.data);
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        refresh() {
            this.getCount();
            this.keyword = "";
            this.currentPage = 1;
        },

        refreshBatch() {
            this.getAllBatchDetailCount();
            this.batchKeyword = "";
            this.batchCurrentPage = 1;
        },
        adminSupervisorRefresh() {
            this.currentPage = 1;
            if (this.keyword == "") {
                this.getAdminSupervisorBatchCount();
            }
            this.keyword = "";
        },
        resetForm() {
            this.resetSyllabus();
            this.branchBatch = {
                code: "",
                syllabus: null,
                studentCount: "",
                selectedL1: "",
                selectedL2: "",
                // selectedL3: "",
                batchId: "",
                branch: null,
                startTime: "",
                endTime: "",
                feedback: "",
                tutor: null,
            };
            this.classFeedback = "not_accepted";
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        resetSyllabus() {
            this.selectedL1 = null,
                this.selectedL2 = null,
                // this.selectedL3 = null,

                this.$nextTick(() => {
                    this.$v.$reset();
                });

        },
        resetBatchForm() {
            this.batch = {
                batchId: "",
                branch: null,
                code: "",
                batchType: null,
                createdAt: "",
                endTime: "",
                finished: "",
                startDate: "",
                startTime: "",
                studentCount: "",
                syllabus: null,
                updatedAt: "",
            };
            this.batchtTypeCreate = false;
            this.$nextTick(() => {
                this.$v.batch.$reset();
            });
        },
        resetBatchType() {
            this.batchType = "";
            this.batchtTypeCreate = false;
            this.$nextTick(() => {
                this.$v.batchType.$reset();
            });
        }
    },
};
</script>

<style scoped>
::v-deep  .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 1rem 1rem;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

#r2 {
    padding-left: 20%;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 15%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
