<template>
<div>
    <div class="login-screen"></div>
    <b-container v-if="!oldToken && !invalidToken  && !isAccept" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="5">
            </b-col>
            <b-col style="margin-top: 5%;">
                <div class="login align-self-center" style="background-color: #ffffff; padding: 2vh; border-radius: 10px; opacity: .9;">
                    <form @submit.prevent="setPassword">
                        <div class="text-center">
                            <br>
                            <img src="encore.svg" width="56" />
                            <br><br>
                        </div>
                        <div class="text-center">
                            <b>
                                <p style="color: royalblue">Set Password</p>
                            </b>
                            <div>
                                <p style="color:#a4a4a4;">{{username}} </p>
                            </div><br>
                            <input class="form-control input" type="password" autofocus v-model="user.password" v-bind:class="{error: $v.user.password.$error, valid: $v.user.password.$dirty && !$v.user.password.$invalid}" placeholder="Password">
                            <div v-if="$v.user.$dirty">
                                <p class="error-message" v-if="!$v.user.password.required">Field is required</p>
                                <p class="error-message" v-if="!$v.user.password.minLength">Must have 6 Characters</p>
                            </div>
                            <input class="form-control input" type="password" autofocus v-model="user.confirmPassword" placeholder="Confirm Password" v-bind:class="{error: $v.user.confirmPassword.$error, valid: $v.user.confirmPassword.$dirty && !$v.user.confirmPassword.$invalid}">
                            <div v-if="$v.user.$dirty">
                                <p class="error-message" v-if="!$v.user.confirmPassword.sameAsPassword">Passwords must be identical.</p>
                            </div>
                            <br>
                            <b-button autofocus class="btn-sm btn-block login-btn" type="submit">
                                Set Password
                            </b-button>
                        </div>
                    </form>
                </div>
            </b-col>
            <b-col cols="5">
            </b-col>
        </b-row>
    </b-container>
    <b-container v-if="oldToken" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="3">
            </b-col>
            <b-col style="margin-top: 10%;">
                <div class="text-center border border-info shadow" style="background-color: #ffffff; padding: 4vh; border-radius: 10px; opacity: .9;">
                    <b-img src="linkExpired.png" fluid alt="Responsive image" style="width:6em; height:6em;"></b-img><br><br><span class="text-secondary" style="font-weight:bold;font-size:1.2em;">Sorry!! The Link you are trying to access is not valid anymore or Expired.</span>
                    <div class="text-center" style="padding-top:2em;">
                        <b-btn @click="$router.push('/')" variant="success">Home</b-btn>
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
            </b-col>
        </b-row>
    </b-container>
    <b-container v-if="invalidToken" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="3">
            </b-col>
            <b-col style="margin-top: 10%;">
                <div class="text-center border border-info shadow" style="background-color: #ffffff; padding: 4vh; border-radius: 10px; opacity: .9;">
                    <i class="fa fa-exclamation-circle fa-4x" style="color:orange;" aria-hidden="true"></i><br><br><span class="text-primary" style="font-weight:bold;font-size:1.2em;">Invalid Confirmation Link</span><br><br>
                    <span class="text-secondary" style="font-weight:bold;font-size:1.2em;">The Link you are trying to access does not exist.</span>
                    <div class="text-center" style="padding-top:2em;">
                        <b-btn @click="$router.push('/')" variant="success">Home</b-btn>
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
            </b-col>
        </b-row>
    </b-container>
     <b-container v-if="isAccept" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="3">
            </b-col>
            <b-col style="margin-top: 10%;">
                <div class="text-center border border-info shadow" style="background-color: #ffffff; padding: 4vh; border-radius: 10px; opacity: .9;">
                    <i class="fa fa-exclamation-circle fa-4x" style="color:orange;" aria-hidden="true"></i><br><br><span class="text-primary" style="font-weight:bold;font-size:1.2em;">Request To Add As a Tutor</span><br><br>
                    <span class="text-secondary" style="font-weight:bold;font-size:1.2em;">You are requested to Add as a Tutor from {{this.tutorName}}.</span>
                    <!-- <div class="text-center" style="padding-top:2em;">
                        <b-btn @click="$router.push('/')" variant="success">Home</b-btn>
                        <b-btn @click="$router.push('/')" variant="success">Home</b-btn>
                    </div> -->
                    <div class="flex justify-between" style="padding-top:2em;">
                        <b-btn @click="acceptRequest()" variant="success">Accept</b-btn>
                        <b-btn @click="denyRequest()" variant="danger">Deny</b-btn>
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import {
    required,
    sameAs,
    minLength
} from 'vuelidate/lib/validators'
import AccountApi from "@/service/Account";
import Global from "@/global/Global";
import swal from "sweetalert2";
import axios from 'axios';
import AgentService from "../service/AgentService";
import Account from '../service/Account';

const toast = swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000
});
export default {
    components: {
    },
    data: function () {
        return {
            user: {
                contact: '',
                password: '',
                confirmPassword: '',
                token: ""
            },
            username: '',
            invalidToken: false,
            oldToken: false,
            userDetails: null,
            isLoading: false,
            contact: '',
            tutorId: null,
            currentUserId: null,
            tutorToken: null,
            isAccept: false,
             userInstructorId:null,
             tutorName:null,
        }
    },
    validations: {
        user: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                sameAsPassword: sameAs('password')
            }
        }
    },
    async mounted() {
        this.user.token = this.$route.query.token;
		console.log("==this.user.token========",this.user.token);
        await this.getUserDetails();
		// await this.getInstituteDetails();
        this.user.contact = this.userDetails.username;
        // let auth = '';
        // auth = true;
        // this.$session.set('auth', auth);
    },
    methods: {
        setPassword: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log("error");
            } else {
                let data = {
                    "verificationToken": this.user.token,
                    "contact": this.user.contact,
                    "firstName": this.userDetails.firstName,
                    "lastName": this.userDetails.lastName,
                    "username": this.userDetails.username,
                    "password": this.user.password
                }
                this.isLoading = true;
                return new Promise((resolve, reject) => {
                    AccountApi.setPassword(data)
                        .then(response => {
                            // this.loginUser();
                            // this.$router.push("/");
                            this.$swal("Password set Successfully!");
                            this.doLoginProgress();
                            resolve(response);
                        })
                        .catch(err => {
                            reject(err);
                            console.log(err);
                        });
                });
            }
        },
        async doLoginProgress() {
            await this.signin()
            await this.getUserInfo(localStorage.getItem('contact'))
            await this.getTeamId();
            await this.getAgent();
            this.routeToCallsPage()
        },
        signin: function () {
            let user = {
                contact: this.user.contact,
                password: this.user.password,
            }
            return new Promise((resolve, reject) => {
                Account.login(user)
                .then((response) => {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + response.data.access_token;
                    // console.log(response.data);
                    this.contact = this.user.contact;
                    localStorage.setItem("accessToken", response.data.access_token);
                    localStorage.setItem("refreshToken", response.data.refresh_token);
                    localStorage.setItem("contact", this.user.contact);
                    localStorage.setItem("roles", 'ROLE_TELECALLER');

                    resolve(response);
                })
                .catch((err) => {
                    this.$swal({
                        title: "Bad Credential",
                        icon: "error",
                        button: "ok",
                    });
                    this.user.username = ''
                    this.user.password = ''
                    reject(err);
                });
            });
        },
        getUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                .then((response) => {
                    localStorage.setItem("current_user", JSON.stringify(response.data));
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        getTeamId() {
            return new Promise((resolve, reject) => {
                Account.getTeam(JSON.parse(localStorage.getItem('current_user')).id)
                    .then((response) => {
                        if(response.data.length == 1) {
                            console.log("if=============");
                            localStorage.setItem("tenant-uuid", response.data[0].teamId);
                            axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                        } else {
                            console.log("=============else");
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        routeToCallsPage: function () {
            let current_user = JSON.parse(localStorage.getItem('current_user'))
            let is_super_admin = false;
            let is_admin = false;
            let is_hr = false;
            let is_admin_supervisor = false;
            let is_supervisor = false;
            let is_counsellor = false;
            let is_telecaller = false;
            let is_user = false;
            current_user.roles.forEach(e => {
                if (e.roleName == 'ROLE_SUPER_ADMIN') {
                    is_super_admin = true;
                    localStorage.setItem("ROLE_SUPER_ADMIN", true)
                }
                if (e.roleName == 'ROLE_ADMIN') {
                    is_admin = true;
                    localStorage.setItem("ROLE_ADMIN", true)
                }
                if (e.roleName == 'ROLE_HR') {
                    is_hr = true;
                    localStorage.setItem("ROLE_HR", true)
                }
                if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    is_admin_supervisor = true;
                    localStorage.setItem("ROLE_ADMIN_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_SUPERVISOR') {
                    is_supervisor = true;
                    localStorage.setItem("ROLE_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_COUNSELLOR') {
                    is_counsellor = true;
                    localStorage.setItem("ROLE_COUNSELLOR", true)
                }
                if (e.roleName == 'ROLE_TELECALLER') {
                    is_telecaller = true;
                    localStorage.setItem("ROLE_TELECALLER", true)
                }
                if (e.roleName == 'ROLE_USER') {
                    is_user = true;
                    localStorage.setItem("ROLE_USER", true)
                }
            });
            if (is_super_admin) {
                localStorage.setItem("tenant-uuid", null);
                localStorage.setItem("roles", 'ROLE_SUPER_ADMIN')
                this.$router.push('/super-admin/team')
            } else if (is_admin) {
                localStorage.setItem("roles", 'ROLE_ADMIN')
                this.$router.push('/settings')
            } else if (is_hr) {
                localStorage.setItem("roles", 'ROLE_HR')
                this.$router.push('/hr/employees')
            } else if (is_admin_supervisor) {
                localStorage.setItem("roles", 'ROLE_ADMIN_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (is_supervisor) {
                localStorage.setItem("roles", 'ROLE_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (is_counsellor) {
                localStorage.setItem("roles", 'ROLE_COUNSELLOR')
                this.$router.push('/user/enquiry')
            } else if (is_telecaller) {
                localStorage.setItem("roles", 'ROLE_TELECALLER')
                this.$router.push('/user/calls')
            } else if (is_user) {
                localStorage.setItem("roles", 'ROLE_USER')
                this.$router.push('/user/attendance')
            }
        },
        getAgent: function () {
            var currentUser = JSON.parse(localStorage.getItem("current_user"));
            var userId = currentUser.id;
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != "" && response.data != null) {
                            this.agent = response.data;
                            localStorage.setItem("agentId", this.agent.agentId);
                            if (this.agent.roleAdminSupervisor) {
                                localStorage.setItem("branches", JSON.stringify(this.agent.branches))
                                localStorage.setItem("ROLE_ADMIN_SUPERVISOR", this.agent.roleAdminSupervisor)
                            }
                            if (this.agent.roleSupervisor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_SUPERVISOR", this.agent.roleSupervisor)
                            }
                            if (this.agent.roleCounsellor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_COUNSELLOR", this.agent.roleCounsellor)
                            }
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        pauseUserSession: function () {
            let onGoingQuizData = this.$session.get('onGoingQuizData');
            var index = onGoingQuizData.findIndex(({
                contact
            }) => contact === this.$session.get('current_user').email[0]);
            let data = {
                testId: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.testId : "",
                userId: this.$session.get('current_user') != null ? this.$session.get('current_user').id : "",
                userName: this.$session.get('current_user') != null ? this.$session.get('current_user').username : "",
                avatar: this.$session.get('current_user') != null ? this.$session.get('current_user').avatar : "",
                totalAnswerData: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.totalAnswerData : "",
                totalTimeLeft: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.totalTimeLeft : "",
                sectionTimeData: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.sectionTimeData : "",
            };
            let config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("quiz/session/answerdata/pause", data, config)
                    .then(response => {
                        if (this.$session.get('onGoingQuizData')[index].url != null) {
                            this.$router.push("/qpack/" + this.$session.get('onGoingQuizData')[index].url);
                        } else {
                            this.$router.push("/");
                        }
                        if (this.$session.get('onGoingQuizData').length != 0) {
                            if (index === -1) {
                                this.$session.set("onGoingQuizData", onGoingQuizData);
                            } else {
                                if (onGoingQuizData.length > 1) {
                                    onGoingQuizData.splice(index, 1);
                                    this.$session.set("onGoingQuizData", onGoingQuizData);
                                } else if (onGoingQuizData.length == 1) {
                                    this.$session.remove('onGoingQuizData');
                                } else {
                                    this.$session.remove('onGoingQuizData');
                                }
                            }
                        }
                        resolve(response);
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                        this.isLoading = false;
                    });
            });

        },

        addUserToSession: function () {
            let onGoingQuizData = this.$session.get('onGoingQuizData');
            var index = onGoingQuizData.findIndex(({
                contact
            }) => contact === this.$session.get('current_user').email[0]);
            let data = {
                testId: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.testId : "",
                userId: this.$session.get('current_user') != null ? this.$session.get('current_user').id : "",
                userName: this.$session.get('current_user') != null ? this.$session.get('current_user').username : "",
                avatar: this.$session.get('current_user') != null ? this.$session.get('current_user').avatar : "",
                totalAnswerData: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.totalAnswerData : "",
                totalTimeLeft: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.totalTimeLeft : "",
                sectionTimeData: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[index].data.sectionTimeData : "",
            };
            let config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("quiz/session/answerdata/submit", data, config)
                    .then(response => {
                        this.$router.push({
                            name: 'InlineQuizReport',
                            params: {
                                testId: this.$session.get('onGoingQuizData').length != 0 ? this.$session.get('onGoingQuizData')[0].data.testId : "",
                                answerId: response.data,
                                currentUser: this.$session.get('current_user'),
                                userRegister: true,
                                url: this.$session.get('onGoingQuizData')[index].url
                            }
                        });
                        if (this.$session.get('onGoingQuizData').length != 0) {
                            if (index === -1) {
                                this.$session.set("onGoingQuizData", onGoingQuizData);
                            } else {
                                if (onGoingQuizData.length > 1) {
                                    onGoingQuizData.splice(index, 1);
                                    this.$session.set("onGoingQuizData", onGoingQuizData);
                                } else if (onGoingQuizData.length == 1) {
                                    this.$session.remove('onGoingQuizData');
                                } else {
                                    this.$session.remove('onGoingQuizData');
                                }
                            }
                        }
                        resolve(response);
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                        this.isLoading = false;
                    });
            });

        },
        getUserDetails: function () {
            return new Promise((resolve, reject) => {
                let data = this.user;
                AccountApi.getUserDetails(data)
                    .then(response => {
                        if (response.status == 206) {
                            this.oldToken = true;
                        } else if (response.status == 208) {
                            this.invalidToken = true;
                        } else {
                            this.userDetails = response.data;
                            this.username = response.data.username;
                            this.contact=response.data.contact;
                        }
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        getInstituteDetails: function () {
            return new Promise((resolve, reject) => {
                let data = this.contact;
                AccountApi.getInstituteDetails(data)
                    .then(response => {

                        this.tutorId = response.data.tutorId;
                        this.tutorToken = response.data.confirmationToken;
                        this.userInstructorId=response.data.userInstructorId;
                        this.tutorName=response.data.instituteName
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        getUserInfo1() {
            return new Promise((resolve, reject) => {
                 if (this.$session.exists("contact")) {
                     Global.getUserInfo(this.$session)
                        .then(response => {
                            this.currentUserId = response.data.id;
                            this.currentUsername=response.data.username;
                            this.updateTutorDetail();
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });
                }
            });
        },
        updateTutorDetail: function () {
            return new Promise((resolve, reject) => {
                AccountApi.updateTutorDetail(this.currentUserId, this.tutorToken)
                    .then(response => {
                        this.isAccept = true;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        acceptRequest: function () {
            return new Promise((resolve, reject) => {
                let data = {
                    userId:this.currentUserId,
                    contact:this.contact,
                    firstName:this.$session.get('current_user').firstName,
                    lastName:this.$session.get('current_user').lastName,
                    instituteName:this.tutorName,

                }
                AccountApi.acceptRequest(data)
                    .then(response => {

                        this.addUserRoles();
                        // this.$router.push("/");
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        addUserRoles: function() {
                let data = {
                    roles: [],
                };
                data.roles.push({
                    roleId: 5,
                });
                this.createInstructor(data.roles[0].roleId)

                return new Promise((resolve, reject) => {
                    AccountApi.addSelectedUserRoles(this.currentUserId, data)
                        .then(response => {
                            toast({
                                type: "success",
                                title: "Tutor Added Successfully!"
                            });
                            this.$router.push("/");
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            toast({
                                type: "error",
                                title: "Selected roles adding failed!"
                            });
                            reject(err);
                        });
                });

        },
        createInstructor: function(roles){
            let config = {
                headers: {
                    "Content-Type": "application/json"
                }
                };
            if(roles=='5'){
            let data={
                    courseInstructorId: null,
                    instructorName:this.currentUsername,
                    avatar: null,
                    designation:null,
                    description:null,
                    published: true,
                    archived: false,
                    type: "TEXT",
                    imageContent: false,
                    userId:this.currentUserId,
                    userInstructorId:this.userInstructorId,
            }
            return new Promise((resolve, reject) => {
            axios.post("courseInstructor/add",data,config)
                .then((response) => {
                resolve(response);
                })
                .catch((err) => {
                reject(err);

                });
            });
            }

        },
        denyRequest: function () {
            this.$router.push("/");
        },

        loginUser: function () {
            return new Promise((resolve, reject) => {
                AccountApi.login(this.user)
                    .then(response => {
                        this.$session.start();
                        this.$session.set("access_token", response.data.access_token);
                        this.$session.set("refresh_token", response.data.refresh_token);
                        this.$session.set("contact", this.user.contact);
                        // cookies
                        this.$cookies.set("edaily_refresh_token", response.data.refresh_token);
                        this.$cookies.set("edaily_contact", this.user.contact);
                        if (this.tutorId == null) {
                        let userContactFound = false;
                        if (this.$session.get('onGoingQuizData') != null) {
                            if (this.$session.get('onGoingQuizData').length != 0) {
                                for (var i = 0; i < this.$session.get('onGoingQuizData').length; i++) {
                                    if (this.$session.get('onGoingQuizData').length != 0 && (this.$session.get('onGoingQuizData')[i].contact == this.user.contact)) {
                                        userContactFound = true;
                                    }
                                }
                            } else {
                                if (this.$session.get('routerPath') != null) {
                                    this.$router.replace(this.$route.query.redirect || this.$session.get('routerPath'));
                                } else {
                                    this.$router.replace(this.$route.query.redirect || "/");
                                }
                            }
                        }
                        if (userContactFound) {
                            this.getUserInfo();
                        } else {
                            if (this.$session.get('routerPath') != null) {
                                this.$router.replace(this.$route.query.redirect || this.$session.get('routerPath'));
                            } else {
                                this.$router.replace(this.$route.query.redirect || "/");
                            }
                        }
                        } else {
                            this.getUserInfo1()
                        }

                        this.isLoading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                        console.log('Error : ', err);
                    });
            });
        }
    }
}
</script>

<style scoped>
.input {
    font-size: 15px;
    margin: 15px 0;
    padding: 10px 20px;
    border-color: none;
    border-radius: 25px;
    background-color: #eee;
}

.login-screen {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.login-screen:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.login-btn {

    background-color: #0766EB;

    color: #ffff;
    border-radius: 25px;
    border-color: #0000;
    font-weight: bold;
    font-size: 18px;
}

.login {
    width: 320px;
    color: #fff;
    border: 2px solid royalblue;
}

.btn-secondary:hover {
    color: #ffff;
    background: #0766EB;
    border-color: #0000;
}

.Blink {
    animation: blinker 0.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

error {
    border-color: red;
}

.error-focus {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 13px;
    margin: 5px 0 0 5px;
}

.glow {
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px red;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px red;
    }
}
</style>
