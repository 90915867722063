<template>
<div class="Navbar">
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div id="" class="">
        <b-navbar toggleable="lg" class="nav-sty">
            <b-container fluid>
                <b-navbar-nav id="navbrand" href="#" class="mx-5">
                    <b-nav>
                        <img class="d-inline-block mt-1 logo" src="../../public/encore.svg" width="60" height="60" alt="" loading="lazy" />
                    </b-nav>
                    <b-nav class="my-3 mx-2 tc">
                        <b class="">ENCORE</b>
                    </b-nav>
                </b-navbar-nav>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-nav class="ms-auto" tabs justified>

                        <b-nav-item href="#" id="navitem" router-link to="/user/insights" v-if="isAdminSelected">
                            <font-awesome-icon icon="chart-bar" /><br />
                            <b>INSIGHTS</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/branchBatch" v-if="isSupervisorSelected || isAdminSupervisorSelected">
                            <font-awesome-icon icon="clock" /><br />
                            <b>BATCH</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/tutor" v-if="(!isAdminSelected && !isSupervisorSelected) && isAdminSupervisorSelected">
                            <font-awesome-icon icon="chalkboard-teacher" /><br />
                            <b>TUTOR</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/report" v-if="(!isAdminSelected && !isSupervisorSelected) && isAdminSupervisorSelected">
                            <font-awesome-icon icon="file" /><br />
                            <b>REPORT</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/counsellor" v-if="isAdminSupervisorSelected">
                            <font-awesome-icon icon="address-book" /><br />
                            <b>ENQUIRY</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/leads" v-if="isTelecallerSelected">
                            <font-awesome-icon icon="envelope-open-text" /><br />
                            <b>Leads</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/calls" v-if="isTelecallerSelected">
                            <font-awesome-icon icon="phone" /><br />
                            <b>CALLS</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/bookOrders" v-if="isTelecallerSelected">
                            <font-awesome-icon icon="book" /><br />
                            <b>BOOK</b>
                        </b-nav-item>
                        <b-nav-item href="#" id="navitem" router-link to="/admin/bookOrders" v-if="isAdminSelected">
                            <font-awesome-icon icon="book" /><br />
                            <b>BOOK</b>
                        </b-nav-item>
                        <b-nav-item href="#" id="navitem" router-link to="/bookCourier" v-if="isSuperAdminSelected">
                            <font-awesome-icon icon="book" /><br />
                            <b>BOOK</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/enquiry" v-if="isTelecallerSelected || isCounsellorSelected">
                            <font-awesome-icon icon="address-book" /><br />
                            <b>ENQUIRY</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/invoice" v-if="isTelecallerSelected || isCounsellorSelected">
                            <font-awesome-icon icon="file-invoice" /><br />
                            <b>INVOICE</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/followup" v-if="isTelecallerSelected || isCounsellorSelected">
                            <font-awesome-icon icon="comment-alt" /><br />
                            <b>FOLLOWUP</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/admission" v-if="isTelecallerSelected || isCounsellorSelected">
                            <font-awesome-icon icon="graduation-cap" /><br />
                            <b>ADMISSION</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/user/notintrested" v-if="isTelecallerSelected || isCounsellorSelected">
                            <font-awesome-icon icon="exclamation-triangle" /><br />
                            <b>NOTINTRESTED</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/log" v-if="isAdminSelected">
                            <font-awesome-icon icon="phone-volume" /><br />
                            <b>LOGS</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/calls" v-if="isAdminSelected">
                            <font-awesome-icon icon="phone" /><br />
                            <b>CALLS</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/followup" v-if="isAdminSelected">
                            <font-awesome-icon icon="comment-alt" /><br />
                            <b>FOLLOWUP</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/enquiry" v-if="isAdminSelected">
                            <font-awesome-icon icon="address-book" /><br />
                            <b>ENQUIRY</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/admission" v-if="isAdminSelected">
                            <font-awesome-icon icon="graduation-cap" /><br />
                            <b>ADMISSION</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/admin/notintrested" v-if="isAdminSelected">
                            <font-awesome-icon icon="exclamation-triangle" /><br />
                            <b>NOTINTRESTED</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" v-if="isAdminSelected" router-link to="/settings">
                            <!-- <i class="fad fa-cog"></i><br /> -->
                            <font-awesome-icon icon="cog" /><br />
                            <b>SETTINGS</b>
                        </b-nav-item>
                        
                        <!-- <b-nav-item href="#" id="navitem" v-if="isSuperAdminSelected" router-link to="/super-admin/Edex">
                            <font-awesome-icon icon="mobile" /><br />
                            <b>EDEX</b>
                        </b-nav-item> -->
                        
                        <b-nav-item href="#" id="navitem" v-if="isSuperAdminSelected" router-link to="/super-admin/mobileAppRelease">
                            <font-awesome-icon icon="mobile" /><br />
                            <b>APP</b>
                        </b-nav-item>


                        <b-nav-item href="#" id="navitem" v-if="isSuperAdminSelected" router-link to="/super-admin/user">
                            <!-- <i class="fad fa-cog"></i><br /> -->
                            <font-awesome-icon icon="user" /><br />
                            <b>USER</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" v-if="isSuperAdminSelected" router-link to="/super-admin/team">
                            <!-- <i class="fad fa-cog"></i><br /> -->
                            <font-awesome-icon icon="cog" /><br />
                            <b>TEAM</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" v-if="isUserSelected" router-link to="/user/attendance">
                            <font-awesome-icon icon="clipboard" /><br />
                            <b>Attendance</b>
                        </b-nav-item>

                        <b-nav-item-dropdown right menu-class="minw-none" class="lang-box" style="color: #ffff" id="navitem">
                            <template slot="button-content">
                                <font-awesome-icon icon="user" /><br /><b>{{ firstname }} {{ lastname }}<span v-if="lastname == null">&nbsp;</span></b>
                            </template>
                            <span style="font-size:15px">
                                <b-dropdown-item v-if="(IS_TELECALLER && isAdminSelected) || (IS_TELECALLER && isUserSelected)">
                                    <span @click="loginas('ROLE_TELECALLER')">Telecaller</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="(IS_COUNSELLOR && isAdminSelected) || (IS_COUNSELLOR && isUserSelected) || (IS_COUNSELLOR && isSupervisorSelected)">
                                    <span @click="loginas('ROLE_COUNSELLOR')">Counsellor</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="(IS_SUPERVISOR && isAdminSelected) || (IS_SUPERVISOR && isUserSelected) || (IS_SUPERVISOR && isCounsellorSelected)">
                                    <span @click="loginas('ROLE_SUPERVISOR')">Supervisor</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="(IS_ADMIN_SUPERVISOR && isAdminSelected) || (IS_ADMIN_SUPERVISOR && isUserSelected)">
                                    <span @click="loginas('ROLE_ADMIN_SUPERVISOR')">Supervisor Head</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="!isHRSelected && IS_HR">
                                    <span @click="loginas('ROLE_HR')">Login As HR</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="!isUserSelected && IS_USER">
                                    <span @click="loginas('ROLE_USER')">Login As User</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(IS_COURIER && !isCourierSelected)" @click="loginas('ROLE_COURIER')">
                                    <span>Login As Courier</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(IS_ADMIN && !isAdminSelected)" @click="loginas('ROLE_ADMIN')">
                                    <span>Login As Admin</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(IS_SUPER_ADMIN && !isSuperAdminSelected) && isAdminSelected" @click="loginas('ROLE_SUPER_ADMIN')">
                                    <span>Super Admin</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.passwordChange>
                                    <span>Settings</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="revoke()" >
                                    <span style="color: #134f70"><i class="fa fa-sign-out" aria-hidden="true"></i>Logout</span>
                                </b-dropdown-item>
                            </span>
                        </b-nav-item-dropdown>
                    </b-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
    <!-- <div id="nav2">
        <b-navbar>
            <b-navbar-nav href="#" class="ms-auto">
                <b-nav class="">
                    <div class="p-3 mb-2 mt-2 text-white arrow-pointer call fa fa-phone" @click="call()" style="color: #fff">
                        CLICK TO CALL
                    </div>
                </b-nav>
            </b-navbar-nav>
        </b-navbar>
    </div> -->
    <PasswordChangeAccount />
    <div>
        <b-modal id="selectTeam" title="Select Team" ref="selectTeamModal" no-close-on-backdrop hide-footer>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Team</label>
                    <b-form-select class="form-control" v-model="selectedTeam" :class="{'is-invalid': $v.selectedTeam.$error,'is-valid': !$v.selectedTeam.$invalid,}">
                        <option value="null" disabled>Select Team</option>
                        <option v-for="t in userAddedTeams" :value="t.teamId" v-bind:key="t.teamId">
                            <strong>{{ t.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="text-center mt-3 mt-2">
                <b-button type="submit" @click="multiTeamLogin()" class="" variant="outline-primary">Switch Login</b-button>
            </div>
        </b-modal>
    </div>
</div>
</template>

<script>
import axios from 'axios';
// import Swal from 'sweetalert2';
import {
    required,
} from "vuelidate/lib/validators";
import Account from '../service/Account.js';
import PasswordChangeAccount from "./PasswordChange.vue";
import AgentService from "../service/AgentService"

export default {
    name: "Navbar1",
    data() {
        return {
            firstname: "",
            lastname: "",
            publisher: false,
            user: false,
            roleName: "",
            currentRole: "",
            r: "",
            allRoles: [],
            IS_TELECALLER: false,
            IS_SUPERVISOR: false,
            IS_COUNSELLOR: false,
            IS_ADMIN_SUPERVISOR: false,
            IS_ADMIN: false,
            IS_SUPER_ADMIN: false,
            IS_HR: false,
            IS_USER: false,
            IS_COURIER:false,
            teams: [],
            selectedTeam: null,
            roleAssign:'',
            userAddedTeams: [],
            loading: false,
        };
    },
    computed: {
        isSuperAdminSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_SUPER_ADMIN") return true;
            else return false;
        },
        isAdminSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_ADMIN") return true;
            else return false;
        },
        isCounsellorSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_COUNSELLOR") return true;
            else return false;
        },
        isSupervisorSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_SUPERVISOR") return true;
            else return false;
        },
        isAdminSupervisorSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_ADMIN_SUPERVISOR") return true;
            else return false;
        },
        isTelecallerSelected: function () {
            if (localStorage.getItem('roles') == "ROLE_TELECALLER") return true;
            else return false;
        },
        isHRSelected: function() {
            if (localStorage.getItem('roles') == "ROLE_HR") return true;
            else return false;
        },
        isUserSelected: function() {
            if (localStorage.getItem('roles') == "ROLE_USER") return true;
            else return false;
        },
        isCourierSelected: function() {
            if (localStorage.getItem('roles') == "ROLE_COURIER") return true;
            else return false;
        }
    },
    components: {
        PasswordChangeAccount
    },
    validations: {
        selectedTeam: {
            required
        }
    },
    mounted() {
        if (localStorage.getItem("current_user") != null)
            this.$nextTick(() => {
                this.firstname = currentUser.firstName;
                this.lastname = currentUser.lastName;
            });

        var currentUser = JSON.parse(localStorage.getItem("current_user"));

        if (currentUser != null) {
            let roles = currentUser.roles;
            roles.forEach((e) => {
                if (e.roleName == "ROLE_TELECALLER") {
                    this.IS_TELECALLER = true;
                }
                if (e.roleName == "ROLE_COUNSELLOR") {
                    this.IS_COUNSELLOR = true;
                }
                if (e.roleName == "ROLE_SUPERVISOR") {
                    this.IS_SUPERVISOR = true;
                }
                if (e.roleName == "ROLE_ADMIN_SUPERVISOR") {
                    this.IS_ADMIN_SUPERVISOR = true;
                }
                if (e.roleName == "ROLE_ADMIN") this.IS_ADMIN = true;

                if (e.roleName == "ROLE_SUPER_ADMIN") this.IS_SUPER_ADMIN = true;

                if (e.roleName == "ROLE_HR") this.IS_HR = true;

                if (e.roleName == "ROLE_USER") this.IS_USER = true;

                if (e.roleName == "ROLE_COURIER") this.IS_COURIER = true;
            });
        }
    },

    methods: {
        call: function () {
            this.$swal("Hello Vue world!!!");
        },
        async multiTeamLogin() {
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                this.$refs.selectTeamModal.hide();
                localStorage.setItem("tenant-uuid", this.selectedTeam);
                axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                await this.getAgent();
                await this.routeToCallsPage()
            }
        },
        adminLogin() {
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                this.$refs.selectTeamModal.hide();
                if(this.roleAssign == 'ROLE_ADMIN') {
                    localStorage.setItem("tenant-uuid", this.selectedTeam);
                    axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                    localStorage.setItem("roles", "ROLE_ADMIN");
                    this.$router.push("/settings");
                } else if(this.roleAssign == 'ROLE_TELECALLER') {
                    localStorage.setItem("tenant-uuid", this.selectedTeam);
                    axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                    localStorage.setItem("roles", "ROLE_TELECALLER");
                    this.$router.push("/settings");
                    // var currentUser = JSON.parse(localStorage.getItem("current_user"));

                    // if (currentUser != null) {
                    //     let roles = currentUser.roles;
                    //     roles.forEach((e) => {
                    //         if (e.roleName == "ROLE_SUPERVISOR") {
                    //             localStorage.setItem("ROLE_SUPERVISOR", true)
                    //             this.$router.push("user/branchBatch");
                    //             return;
                    //         } else if(e.roleName == "ROLE_ADMIN_SUPERVISOR") {
                    //             localStorage.setItem("ROLE_ADMIN_SUPERVISOR", true)
                    //             this.$router.push("user/branchBatch");
                    //             return
                    //         } else if(e.roleName == "ROLE_COUNSELLOR") {
                    //             localStorage.setItem("ROLE_COUNSELLOR", true)
                    //             this.$router.push("user/enquiry");
                    //             return;
                    //         } else if(e.roleName == "ROLE_TELECALLER") {
                    //             this.$router.push("user/calls");
                    //             return;
                    //         }
                    //     });
                    // }
                }
            }
        },
        loginas: function (role) {
            console.log("loginas============",role);
            if (role == "ROLE_SUPER_ADMIN") {
                localStorage.setItem("tenant-uuid", null);
                axios.defaults.headers.common["tenant-uuid"] = null;
                localStorage.setItem("roles", "ROLE_SUPER_ADMIN");
                this.$router.push("/super-admin/team");
            } else if (role == "ROLE_ADMIN") {
                return new Promise((resolve, reject) => {
                    Account.getTeam(JSON.parse(localStorage.getItem('current_user')).id)
                        .then((response) => {
                            if (response.data.length == 1) {
                                console.log("if=============");
                                localStorage.setItem("tenant-uuid", response.data[0].teamId);
                                axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                                localStorage.setItem("roles", "ROLE_ADMIN");
                                this.$router.push("/settings");
                            } else {
                                console.log("else=============");
                                this.selectedTeam = null
                                this.$nextTick(() => {
                                    this.$v.selectedTeam.$reset();
                                });
                                this.userAddedTeams = response.data;
                                this.roleAssign = role;
                                this.getUserTeams();

                                // console.log("==inputOptions===",input);
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else if (role == "ROLE_HR") {
                localStorage.setItem("roles", role);
                this.$router.push("/hr/user");
            } else if (role == "ROLE_TELECALLER") {
                localStorage.setItem("roles", role);
                this.$router.push("/user/calls");
                // return new Promise((resolve, reject) => {
                //     Account.getTeam(JSON.parse(localStorage.getItem('current_user')).id)
                //         .then((response) => {
                //             if (response.data.length == 1) {
                //                 console.log("if=============");
                //                 localStorage.setItem("tenant-uuid", response.data[0].teamId);
                //                 axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                //                 // if (JSON.parse(localStorage.getItem('ROLE_ADMIN_SUPERVISOR'))) {
                //                 //     localStorage.setItem("roles", "ROLE_TELECALLER");
                //                 //     this.$router.push("user/branchBatch");
                //                 // } else if (JSON.parse(localStorage.getItem('ROLE_SUPERVISOR'))) {
                //                 //     localStorage.setItem("roles", "ROLE_TELECALLER");
                //                 //     this.$router.push("user/branchBatch");
                //                 // } else {
                //                     localStorage.setItem("roles", "ROLE_TELECALLER");
                //                     this.$router.push("user/calls");
                //                 // }
                //             } else {
                //                 console.log("else=============");
                //                 this.selectedTeam = null
                //                 this.$nextTick(() => {
                //                     this.$v.selectedTeam.$reset();
                //                 });
                //                 this.userAddedTeams = response.data;
                //                 this.roleAssign = role;
                //                 this.getUserTeams();
                //                 // return new Promise((resolve, reject) => {
                //                 //     axios.post("/team/getAll")
                //                 //         .then((response) => {
                //                 //             this.teams = response.data;
                //                 //             this.$refs.selectTeamModal.show();
                //                 //             this.roleAssign = role;
                //                 //             resolve(response);
                //                 //         })
                //                 //         .catch((err) => {
                //                 //             reject(err);
                //                 //         });
                //                 // });
                //             }
                //             resolve(response);
                //         })
                //         .catch((err) => {
                //             reject(err);
                //         });
                // });
            } else if (role == "ROLE_COUNSELLOR") {
                localStorage.setItem("roles", role);
                this.$router.push("/user/enquiry");
                // return new Promise((resolve, reject) => {
                //     Account.getTeamId(JSON.parse(localStorage.getItem('current_user')).id)
                //         .then((response) => {
                //             if (response.data.length == 1) {
                //                 console.log(role,"if=============");
                //                 localStorage.setItem("tenant-uuid", response.data[0].teamId);
                //                 axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                //                 localStorage.setItem("roles", role);
                //                 this.$router.push("user/enquiry");
                //             } else {
                //                 console.log(role,"else=============");
                //                 this.selectedTeam = null
                //                 this.$nextTick(() => {
                //                     this.$v.selectedTeam.$reset();
                //                 });
                //                 this.userAddedTeams = response.data;
                //                 this.roleAssign = role;
                //                 this.getUserTeams();
                //             }
                //             resolve(response);
                //         })
                //         .catch((err) => {
                //             reject(err);
                //         });
                // });
            } else if (role == "ROLE_SUPERVISOR") {
                localStorage.setItem("roles", role);
                this.$router.push("user/branchBatch");
                // return new Promise((resolve, reject) => {
                //     Account.getTeamId(JSON.parse(localStorage.getItem('current_user')).id)
                //         .then((response) => {
                //             if (response.data.length == 1) {
                //                 console.log(role,"if=============");
                //                 localStorage.setItem("tenant-uuid", response.data[0].teamId);
                //                 axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                //                 localStorage.setItem("roles", role);
                //                 this.$router.push("user/branchBatch");
                //             } else {
                //                 console.log(role,"else=============");
                //                 this.selectedTeam = null
                //                 this.$nextTick(() => {
                //                     this.$v.selectedTeam.$reset();
                //                 });
                //                 this.userAddedTeams = response.data;
                //                 this.roleAssign = role;
                //                 this.getUserTeams();
                //             }
                //             resolve(response);
                //         })
                //         .catch((err) => {
                //             reject(err);
                //         });
                // });
            } else if (role == "ROLE_ADMIN_SUPERVISOR") {
                localStorage.setItem("roles", role);
                this.$router.push("user/branchBatch");
                // console.log("role=======ROLE_ADMIN_SUPSERVISOR");
                // return new Promise((resolve, reject) => {
                //     Account.getTeamId(JSON.parse(localStorage.getItem('current_user')).id)
                //         .then((response) => {
                //             if (response.data.length == 1) {
                //                 console.log(role,"if=============");
                //                 localStorage.setItem("roles", role);
                //                 localStorage.setItem("tenant-uuid", response.data[0].teamId);
                //                 axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                //                 this.$router.push("user/branchBatch");
                //             } else {
                //                 console.log(role,"else=============");
                //                 this.selectedTeam = null
                //                 this.$nextTick(() => {
                //                     this.$v.selectedTeam.$reset();
                //                 });
                //                 this.userAddedTeams = response.data;
                //                 this.roleAssign = role;
                //                 this.getUserTeams();
                //             }
                //             resolve(response);
                //         })
                //         .catch((err) => {
                //             reject(err);
                //         });
                // });
            }else if(role== "ROLE_COURIER"){
                localStorage.setItem("roles", role);
                this.$router.push("/bookCourier");
            }
             else if (role == "ROLE_USER") {
                // alert("role====",role)
                localStorage.setItem("roles", role);
                this.$router.push("/user/attendance");
            }
            window.location.reload();
        },
        async getUserTeams() {
            this.loading = true;
            // await this.getTeamId();
            console.log("tenant-uuid======= ",axios.defaults.headers.common["tenant-uuid"]);
            await this.getAllTeams();
            await this.getUserAddedTeam();
            this.loading = false;
        },
        getTeamId() {
            return new Promise((resolve, reject) => {
                Account.getTeam(JSON.parse(localStorage.getItem('current_user')).id)
                    .then((response) => {
                        if (response.data.length == 1) {
                            console.log("if=============");
                            localStorage.setItem("tenant-uuid", response.data[0].teamId);
                            axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                        } else {
                            console.log("getTeamId======else",response.data);
                            this.userAddedTeams = response.data;
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllTeams() {
            let tenantId = "";
            if(axios.defaults.headers.common["tenant-uuid"] != null) {
                tenantId = axios.defaults.headers.common["tenant-uuid"];
                axios.defaults.headers.common["tenant-uuid"] = null
            }
            return new Promise((resolve, reject) => {
                axios.post("/team/getAll")
                    .then((response) => {
                        this.teams.splice(0);
                        this.teams = response.data;
                        if(tenantId != "") {
                            axios.defaults.headers.common["tenant-uuid"] = tenantId;
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserAddedTeam() {
            if (this.userAddedTeams != null) {
                let teamAdd = [];
                let teamNotAdd = [];

                for (var i = 0; i < this.teams.length; i++) {
                    console.log("name====== ", this.teams[i].name);
                    let isEqual = false;
                    this.userAddedTeams.forEach(e => {
                        console.log(e.teamId, " == ", this.teams[i].teamId);
                        if (e.teamId == this.teams[i].teamId) {
                            isEqual = true;
                        }
                    });
                    if (isEqual) {
                        teamAdd.push(this.teams[i]);
                    } else {
                        teamNotAdd.push(this.teams[i]);
                    }
                }
                console.log("getUserAddedTeam====splice before==== ",this.userAddedTeams);
                this.userAddedTeams.splice(0);
                this.userAddedTeams = teamAdd;
                console.log("getUserAddedTeam==== ",this.userAddedTeams);
                this.$refs.selectTeamModal.show();
            }
        },
        getAgent: function () {
            var currentUser = JSON.parse(localStorage.getItem("current_user"));
            var userId = currentUser.id;
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != "" && response.data != null) {
                            this.agent = response.data;
                            if (this.agent.roleAdminSupervisor) {
                                localStorage.setItem("branches", JSON.stringify(this.agent.branches))
                                localStorage.setItem("ROLE_ADMIN_SUPERVISOR", this.agent.roleAdminSupervisor)
                            }
                            if (this.agent.roleSupervisor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_SUPERVISOR", this.agent.roleSupervisor)
                            }
                            if (this.agent.roleCounsellor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_COUNSELLOR", this.agent.roleCounsellor)
                            }
                            localStorage.setItem("agentId", this.agent.agentId);
                            console.log("getAgent========function end");
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        routeToCallsPage: function () {
            console.log("========routeToCallsPage========");
            let current_user = JSON.parse(localStorage.getItem('current_user'))
            let is_super_admin = false;
            let is_admin = false;
            let is_hr = false;
            let is_admin_supervisor = false;
            let is_supervisor = false;
            let counsellor = false;
            let telecaller = false;
            let user = false;
            current_user.roles.forEach(e => {
                if (e.roleName == 'ROLE_SUPER_ADMIN') {
                    is_super_admin = true;
                    localStorage.setItem("ROLE_SUPER_ADMIN", true)
                }
                if (e.roleName == 'ROLE_ADMIN') {
                    is_admin = true;
                    localStorage.setItem("ROLE_ADMIN", true)
                }
                if (e.roleName == 'ROLE_HR') {
                    is_hr = true;
                    localStorage.setItem("ROLE_HR", true)
                }
                if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    is_admin_supervisor = true;
                    localStorage.setItem("ROLE_ADMIN_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_SUPERVISOR') {
                    is_supervisor = true;
                    localStorage.setItem("ROLE_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_COUNSELLOR') {
                    counsellor = true;
                    localStorage.setItem("ROLE_COUNSELLOR", true)
                }
                if (e.roleName == 'ROLE_TELECALLER') {
                    telecaller = true;
                    localStorage.setItem("ROLE_TELECALLER", true)
                }
                if (e.roleName == 'ROLE_USER') {
                    user = true;
                    localStorage.setItem("ROLE_USER", true)
                }
            });
            // return new Promise((resolve, reject) => {
            if (is_super_admin) {
                // localStorage.setItem("tenant-uuid", null);
                // localStorage.setItem("roles", 'ROLE_SUPER_ADMIN')
                // this.$router.push('/super-admin/team')
                localStorage.setItem("roles", 'ROLE_ADMIN')
                this.$router.push('/settings')
            } else if (is_admin) {
                localStorage.setItem("roles", 'ROLE_ADMIN')
                this.$router.push('/settings')
            } else if (is_hr) {
                localStorage.setItem("roles", 'ROLE_HR')
                this.$router.push('/hr/employees')
            } else if (is_admin_supervisor) {
                localStorage.setItem("roles", 'ROLE_ADMIN_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (is_supervisor) {
                localStorage.setItem("roles", 'ROLE_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (counsellor) {
                localStorage.setItem("roles", 'ROLE_COUNSELLOR')
                this.$router.push('/user/enquiry')
            } else if (telecaller) {
                localStorage.setItem("roles", 'ROLE_TELECALLER')
                this.$router.push('/user/calls')
            } else if (user) {
                localStorage.setItem("roles", 'ROLE_USER')
                this.$router.push('/user/attendace')
            }
            window.location.reload();
        },
        revoke: function () {
            var accessToken = localStorage.getItem("accessToken");
            Account.logout(accessToken);
            // localStorage.removeItem('accessToken')
            // localStorage.removeItem('refreshToken')
            // localStorage.removeItem('current_user')
            // localStorage.removeItem('contact')
            // localStorage.removeItem('roles');
            // localStorage.removeItem('restrict')
            // localStorage.removeItem('login')
            // localStorage.removeItem('agentId')
            localStorage.clear();
            this.$router.push({
                name: "Login",
            });
            window.location.reload();
        },
        // navto: function (nav) {

        //     if (nav == 'DASHBOARD') {

        //         this.$router.push({
        //             path: "/dashboard"
        //         })
        //     }
        //     if (nav == 'SETTINGS') {

        //         this.$router.push({
        //             path: "/settings"
        //         })
        //     }
        //     if (nav == 'CALLS') {

        //         this.$router.push({
        //             path: "/user/calls"
        //         })
        //     }
        //     if (nav == 'ADMISSION') {

        //         this.$router.push({
        //             path: "/user/admission"
        //         })
        //     }
        //     if (nav == 'ENQUIRY') {

        //         this.$router.push({
        //             path: "/user/enquiry"
        //         })
        //     }
        // }
    },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Dosis:wght@600&family=Monoton&family=Rampart+One&display=swap"); */
.logo {
    transition: transform .7s ease-in-out;
}
.logo:hover {
    transform: rotate(360deg);
}
.text-animation {
  background: 50% 100%/50% 50% no-repeat radial-gradient(ellipse at bottom, rgb(255, 255, 255), transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
          animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
}
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 6px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes reveal {
  80% {
    letter-spacing: 6px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@-webkit-keyframes glow {
  40% {
    text-shadow: 0 0 6px rgb(255, 255, 255);
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 6px rgb(255, 255, 255);
  }
}

.nav-sty {
    background-color: rgba(4, 142, 255, 0.87);
}

#nav2 .navbar {
    background-color: rgba(197, 197, 197, 0.664);
    height: 80%;
}

#navitem {
    border-right: 1px solid white;
}

.navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.tc {
    color: white;
    font-size: x-large;
}

.arrow-pointer {
    width: 180px;
    height: 50px;
    position: relative;
    background-color: #00aeffb9;
    /* For browsers that do not support gradients */
    background-image: linear-gradient(to right,
            rgba(4, 142, 255, 0.87),
            rgba(88, 255, 219, 0.788));
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #3094fa3d;
}

.arrow-pointer:after {
    content: "";
    position: absolute;
    left: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 25px solid rgba(4, 142, 255, 0.87);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

#navbrand {
    margin-right: 150px;
}

/* ::v-deep .dropdown-menu-right{
   margin:-13px;
   text-align: right;

} */
::v-deep .dropdown-menu {
    min-width: 8.5rem;
    margin: -20px;
}

::v-deep .nav-link {
    padding: 1.5rem 1rem;
    color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
