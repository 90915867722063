<template>
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
           <div style="color:black;font-weight: bold; position: absolute;top:55%;width:150px;left: 25%;">
            Please Wait..!
           </div>
        </div>
        <h1 class="my-5">Postal Address</h1>
        <div style="margin: 15px;box-shadow: 0px 13px 27px -5px #979797 , 0px 8px 16px -8px #979797 , 0px 6px 16px -6px #979797;">
            <div class="form-row pt-3 px-3">
            <div class="form-group py-2">
                <label>Door number</label>
                <b-form-input type="text" style="height:80px" v-model="bookOrder.doorNo" :class="{
                    'is-invalid': $v.bookOrder.doorNo.$error,
                    'is-valid': !$v.bookOrder.doorNo.$invalid,
                }" class="form-control" placeholder="Enter door number with Street"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.bookOrder.doorNo.required">Address is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-3">
            <div class="form-group my-2">
                <label>Landmark</label>
                <b-form-input type="text" v-model="bookOrder.landmark" :class="{
                    'is-invalid': $v.bookOrder.landmark.$error,
                    'is-valid': !$v.bookOrder.landmark.$invalid,
                }" class="form-control" placeholder="Enter your landmark"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.bookOrder.landmark.required">landmark is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-3">
            <div class="form-group my-2">
                <label>District</label>
                <b-form-input type="text" v-model="bookOrder.district" :class="{
                    'is-invalid': $v.bookOrder.district.$error,
                    'is-valid': !$v.bookOrder.district.$invalid,
                }" class="form-control" placeholder="Enter your district"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.bookOrder.district.required">district is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-3">
            <div class="form-group my-2">
                <label>State</label>
                <b-form-input type="text" v-model="bookOrder.state" :class="{
                    'is-invalid': $v.bookOrder.state.$error,
                    'is-valid': !$v.bookOrder.state.$invalid,
                }" class="form-control" placeholder="Enter your state"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.bookOrder.state.required">state is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-3">
            <div class="form-group my-2">
                <label>Pincode</label>
                <!-- @keydown.enter.prevent="GetDetailsByPincode()" -->
                <b-form-input type="text" v-model="bookOrder.pincode" :class="{
                    'is-invalid': $v.bookOrder.pincode.$error,
                    'is-valid': !$v.bookOrder.pincode.$invalid,
                }" class="form-control" placeholder="Enter pincode"></b-form-input>
                <div class="invalid-feedback">
                    <span v-if="!$v.bookOrder.pincode.required">Pincode is required</span>
                </div>
            </div>
        </div>
        <div class="text-center py-5">
            <b-button type="submit" :disabled="disabledButton" @click="saveOrder()" class="" variant="outline-primary">
                Close to Book
            </b-button>
        </div>
        </div>
        
    </div>
</template>
    
        
<script>
import BookBundleService from "../service/BookBundleService";
import axios from "axios";
import AgentService from "../service/AgentService"
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'BookPaymentSuccess',
    props: ['payId'],
    
    data: function () {
        return {
            disabledButton:false,
            transactionId: "123456789",
            paymentId: "",
            //   amount:"6000",
            loading:true,
            transaction: [],
            orderDetails: [],
            tenantOrderDetails: [],
            paymentStatus: "",
            errorCode: "E123",
            errorMessage: "Payment declined due to insufficient funds",
            referenceId: this.$route.query.razorpay_payment_link_reference_id,
            PaymentLinkId: this.$route.query.razorpay_payment_link_id,
            status: this.$route.query.razorpay_payment_link_status,
            organization: this.$route.query,
            linkOrder:null,
            bookBundle:null,
            bookOrder: {
                name: "",
                whatsapp: null,
                doorNo: null,
                landmark: null,
                district: null,
                state: null,
                type: null,
                payment: "",
                refNumber: "",
                remarks: "",
                pincode: null
            },
        }
    },
    validations: {
        bookOrder: {
            doorNo: {
                required,
            },
            landmark: {
                required,
            },
            district: {
                required,
            },
            state: {
                required,
            },
            pincode: {
                required,
                Number,
            }
        }
    },
    async mounted() {
        this.$swal({
            // title: "Success",
            title: "Payment Success...!!!",
            text: "please enter your postal address to complete this process",
            icon: "success",
            button: "ok",
        });
        await this.getOrderdetails();
    },
    methods: {
        updateLinkOrder: function(){
            let data = {
                linkBookOrderId: this.linkOrder.id,
                callId: this.linkOrder.callId,
               
            }
            axios.defaults.headers.common["tenant-uuid"] =""
            return new Promise((resolve, reject) => {
                axios
                    .post("/razorpay/orders/bookLink", data)
                    .then(response => {
                        console.log("RESPONSE", response.data);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        goBack: function () {
            // this.$router.push("/")
            window.history.back();
        },
        getBookBundle: function (bookId) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/bookBundle/get/"+bookId)
                    .then(response => {
                        this.bookBundle=response.data;
                        this.bookOrder.type=response.data
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getOrderdetails: function () {
            let data = {
                id: this.$route.params.linkBookOrderId
            }
            return new Promise((resolve, reject) => {
                axios
                    .post("/bookOrder/getBookLinkOrder", data)
                    .then(response => {
                        this.linkOrder=response.data;
                        this.getBookBundle(response.data.bookBundleId);
                        this.getAgent(response.data.agentId);
                        this.loading=false;
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getAgent: function (agentId) {
            axios.defaults.headers.common["tenant-uuid"] =this.linkOrder.tenant_id
            return new Promise((resolve, reject) => {
                AgentService.getAgent(agentId)
                    .then((response) => {
                        this.bookOrder.agentName=response.data.firstName+" "+response.data.lastName
                        resolve(response);
                        axios.defaults.headers.common["tenant-uuid"] =""
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveOrder: function () {
            
            this.loading=true;
            this.$v.bookOrder.$touch();
            if (this.$v.bookOrder.$invalid) {
                this.loading=false;
                // alert("insert submit failed");
            } else {
            this.disabledButton=true
            let data = {
                name: this.linkOrder.userName,
                contact: this.linkOrder.contact,
                type: this.bookOrder.type,
                whatsapp: this.linkOrder.whatsappNumber,
                doorNo: this.bookOrder.doorNo,
                landmark: this.bookOrder.landmark,
                district: this.bookOrder.district,
                state: this.bookOrder.state,
                pincode: this.bookOrder.pincode,
                remarks: this.bookOrder.remarks,
                paymentMethod: 'link',
                referenceNumber: this.linkOrder.referenceId,
                agentId: this.linkOrder.agentId,
                agentName: this.bookOrder.agentName,
                teamId: this.linkOrder.tenant_id,
                organization: this.linkOrder.organization,
                bookBundleName: this.bookOrder.type.name,
                price: this.bookOrder.type.price,
                byAgent:false,
                teamName:this.linkOrder.organization,
                callId:this.linkOrder.callId
            }
            axios.defaults.headers.common["tenant-uuid"] =this.linkOrder.tenant_id
            console.log(data);
            
            return new Promise((resolve, reject) => {
                BookBundleService.saveBookOrder(data)
                        .then((response) => {
                            this.updateLinkOrder();
                            this.loading=false;
                            this.$swal({
                                title: "Order Booked",
                                text: "BookBundle order created successfully",
                                icon: "success",
                                button: "ok",
                            });
                            this.$router.replace('/bookPurchased')
                        resolve(response);
                    })
                    .catch(err => {
               this.disabledButton=true
                        reject(err);
                    });
            });
        }
        },

    }

}
</script >
    
<style scoped>
/* .card {
        border: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    } */

.payment-failed {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
    .mobile-phone-card {
        width: 320px;
        height: 600px;
        border: none;
        border-radius: 16px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        background-color: #000;
    }
    
    .status-bar {
        height: 24px;
        background-color: #333;
    }
    
    .screen {
        background-color: #fff;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        overflow: hidden;
    }
    
    .content {
        text-align: center;
    }
    
    .home-button {
        height: 40px;
        width: 40px;
        background-color: #333;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        left: calc(50% - 20px);
    } */

#head {
    font-size: 2.3em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}

#body {
    font-size: 1.3em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}

#amountspan {
    font-size: 1.1em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
#grad1 {
    height: 100%;
    background: rgb(66, 171, 74);
    background: linear-gradient(0deg, rgba(66, 171, 74, 1) 0%, rgba(121, 235, 151, 1) 100%);
}

@media screen and (max-width: 749.98px) {
    #head {
        font-size: 1.3em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }

    #body {
        font-size: 0.9em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }

    #amountspan {
        font-size: 0.9em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }
}
</style>
    