<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- Admission table and details start -->
    <div class="mt-5">
        <div>
            <b-container>
                <b-row align-v="center">
                    <!-- admission disabled -->
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Admission</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <b-row>
                                <b-col col lg="2" md="2" id="r1" sm="4">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                        <b-form-select-option disabled value="Course">
                                            <p>Courses</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllCourses">
                                            <p>All Courses</p>
                                        </b-form-select-option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="2" md="1" id="r1" sm="3">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedMode" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                        <b-form-select-option disabled value="mode">
                                            <p>Mode</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="allModes">
                                            <p>All Modes</p>
                                        </b-form-select-option>
                                        <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                            <strong>{{ item.text }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>

                                <b-col col lg="2" md="2" id="r1" sm="4">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                        <b-form-select-option disabled value="Source">
                                            <p>Sources</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllSources">
                                            <p>All Sources</p>
                                        </b-form-select-option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="2" md="4" id="r1" sm="4">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedAgent" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                        <b-form-select-option disabled value="Agent">
                                            <p>Agents</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllAgents">
                                            <p>All Agents</p>
                                        </b-form-select-option>
                                        <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.firstName }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="2" md="2" id="r1" sm="4">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                        <b-form-select-option disabled value="Branch">
                                            Branches
                                        </b-form-select-option>
                                        <b-form-select-option value="AllBranches">
                                            <p>All Branches</p>
                                        </b-form-select-option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="2" md="2" id="r1" sm="4">
                                    <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    " :options="dateSelect">>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <br />
                    <b-table responsive hover :items="allAdmissions" :fields="fields" :current-page="currentPage" :per-page="allAdmissions.pageCount" ref="table">
                        <template v-slot:cell(admissionId)="data">
                            <span class="title"> {{ data.item.admissionId }}</span>
                        </template>
                        <template v-slot:cell(callCreatedAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.callCreatedAt, "fullDate") }}
                            </div>
                        </template>
                        <template v-slot:cell(agent)="data">
                            {{ data.item.agent.firstName }}
                        </template>
                        <template v-slot:cell(name)="data">
                            {{ data.item.name }}
                        </template>
                        <template v-slot:cell(contact)="data">
                            {{ data.item.contact }}
                            <span v-if="data.item.alternativeContact != null">,</span>
                            {{ data.item.alternativeContact }}
                        </template>
                        <template v-slot:cell(course)="data">
                            {{ data.item.course.name }}
                        </template>
                        <template v-slot:cell(source)="data">
                            {{ data.item.source.name }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(createdAt)="data">
                            <div class="dateCell">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div class="action_space">
                                <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Admission Details" v-b-modal.viewAdmission @click="getFollowups(data.item)" size="sm" class="mx-1">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="View Payment Details" v-b-modal.viewPaymentDetails @click="getHistoryById(data.item)">
                                    <i class="fas fa-receipt"></i>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
            <!-- View Admission Start -->
            <b-container>
                <b-modal id="viewAdmission" no-close-on-backdrop hide-footer title="View Admission">
                    <div class="form-control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="admission.name" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input v-model="admission.contact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Alternative Contact</label>
                                <b-form-input v-model="admission.alternativeContact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course Type</label>
                                <b-form-input v-model="admission.courseType" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input v-model="admission.age" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-input class="form-control" disabled v-model="admission.qualification">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-input class="form-control" v-model="admission.course.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-input class="form-control" v-model="admission.source.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-input class="form-control" v-model="admission.mode" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input class="form-control" v-model="admission.branch.name" disabled>
                                </b-form-input>
                            </div>
                        </div>
                        <b-table responsive bordered striped hover :items="followups" :fields="followupDateAndRemark">
                            <template v-slot:cell(remark)="data">
                                <span class="title"> {{ data.item.remark }}</span>
                            </template>
                            <!-- <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template> -->
                            <template v-slot:cell(createdAt)="data">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template>
                        </b-table>
                    </div>
                </b-modal>
            </b-container>
            <!-- View Admission End -->

            <div>
                <!--popup start for View Payment Details-->
                <b-modal id="viewPaymentDetails" title="View Payment Details" hide-footer>
                    <div v-if="allInvoicesHistory.length != 0" class="control">
                        <b-table responsive bordered striped hover :items="allInvoicesHistory" :fields="invoiceDateAndRemark">
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.created_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(advanceAmount)="data">
                                <span v-if="data.item.advanceAmount == null">{{ data.item.totalAmount}}</span>
                                <span v-else>{{ data.item.advanceAmount }}</span>
                            </template>
                            <template v-slot:cell(balanceAmount)="data">
                                <span v-if="data.item.balanceAmount == null">0</span>
                                <span v-else>{{ data.item.balanceAmount }}</span>
                            </template>
                        </b-table>
                    </div>
                    <b-card v-else class="text-center">
                        <span>No data found</span>
                    </b-card>
                </b-modal>
                <!--popup end for View Payment Details-->
            </div>

        </div>
    </div>
    <!-- Admission table and details end -->
    <!-- data range selece modal start-->
    <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedAgent,
              selectedCourse,
              selectedMode,
              selectedSource,
              selectedBranch,
              selectedDates
            )
          ">Submit</b-button>
        </div>
    </b-modal>

    <!-- data range selece modal end-->
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
import AdminAdmissionsService from "../service/AdminAdmissionsService";
import FollowupService from "../service/FollowupService";
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import InvoiceService from "../service/InvoiceService";
import moment from "moment-timezone";
import {
    required
} from "vuelidate/lib/validators";
export default {
    name: "AdminAdmissions",
    data() {
        return {

            selectedMode: "mode",
            //loading: false,
            disableButton: false,
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],

            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            selectedDates: null,

            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            from: "",
            to: "",
            allAdmissions: [],
            fromValue: "",
            toValue: "",
            data: "",
            //switchPage condition check
            paginationAdmissions: false,
            searchPaginationAdmissions: false,
            sortAgentPaginationAdmissions: false,
            sortCoursePaginationAdmissions: false,
            sortSourcePaginationAdmissions: false,
            sortBranchPaginationAdmissions: false,
            sortDatePaginationAdmissions: false,
            fields: [{
                    key: "callCreatedAt",
                    label: "Call_Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "Agent",
                    label: "AgentName"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "createdAt",
                    label: "Admission_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            admission: {
                callCreatedAt: "",
                name: "",
                contact: "",
                course: "",
                source: "",
                mode: "",
                branch: "",
                createdAt: "",
            },
            followups: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            allInvoicesHistory: [],
            invoiceDateAndRemark: [{
                    key: "invoiceNumber",
                    label: "Invoice No",
                },
                {
                    key: "created_at",
                    label: "Created Date",
                },
                {
                    key: "advanceAmount",
                    label: "Amount Paid",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
            ],
            // for loader
            loading: false,
            admissionId: null,
            isAdminAdmission: true,
            // for pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            AdminInvoicedetails: null,
            // for search in table
            keyword: "",
        };
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.getCount();
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    // this.getAdminTeam();
                    // this.loading = false;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    watch: {
        // selectedCourse() {
        //     //////console.log(this.selectedCourse)
        //     this.getCourseDetail(this.selectedCourse.name);
        // },
        // selectedSource() {
        //     //////console.log(this.selectedSource)
        //     this.getSourceDetail(this.selectedSource.name);
        // },
        // selectedBranch() {
        //     //////console.log(this.selectedBranch)
        //     this.getBranchDetail(this.selectedBranch.name);
        // },
        // selectedAgent() {
        //     //////console.log(this.selectedAgent)
        //     this.getAgentDetail(this.selectedAgent.name);
        // },
        // selectedDate() {
        //     //////console.log(this.selectedDate)
        //     this.getTodayCalls(this.selectedDate);
        // },
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllAdmissions();
                this.getCount();
                // this.getAllPageable();
            }
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },

        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortAdmissions = true;
                this.paginationAdmissions = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // //////console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/admission/all/sort/count", this.data)
                        .then((response) => {
                            // //////console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // //////console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/admission/all/sort/" + currentPage + "/" + pageCount,
                        data
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        // //////console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getCourseDetail: function (course) {
        //   this.loading = true;
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = "Dates";
        //   if (this.selectedCourse == "allCourses") {
        //     this.getCount();
        //   } else {
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admin/sortCourse", data)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getCourseCount(data);
        //           this.loading = false;
        //           //////console.log("====", response.data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getFollowups: function (admission) {
            this.admission = admission;
            // //////console.log("admission", admission);
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(admission.callId)
                FollowupService.getFollowupCreatedDate(admission.callId)
                    .then((response) => {
                        this.followups = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getHistoryById: function (admission) {
            // console.log("=====Admission=====",admission);
            this.admission = admission;
            this.admissionId = admission.admissionId
            return new Promise((resolve, reject) => {
                InvoiceService.getAllInvoiceHistory(this.admissionId)
                    .then((response) => {
                        this.allInvoicesHistory.splice(0);
                        this.allInvoicesHistory = response.data;
                        // console.log("=====Data Type=====",typeof(this.allInvoicesHistory));
                        // console.log("=====AllInvoicesHistory=====", this.allInvoicesHistory)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getSourceDetail: function (source) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   // this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = "Dates";
        //   if (this.selectedSource == "allSources") {
        //     //////console.log("All Source Calling");
        //     // this.getAllAdmissions();
        //     this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     //////console.log("Source Calling");
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admin/sortSource", data)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getSourceCount(data);
        //           this.loading = false;
        //           //////console.log("====", response.data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getBranchDetail: function (branch) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   // this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = "Dates";
        //   if (this.selectedBranch == "allBranches") {
        //     //////console.log("All Branch Calling");
        //     // this.getAllAdmissions();
        //     this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     //////console.log("Branch Calling");
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admin/sortBranch", data)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getBranchCount(data);
        //           this.loading = false;
        //           //////console.log("====", response.data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAgentDetail: function (agent) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   // this.selectedAgent = "Agent";
        //   this.selectedDates = "Dates";
        //   if (this.selectedAgent == "allAgents") {
        //     //////console.log("All Agent Calling");
        //     // this.getAllAdmissions();
        //     this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     //////console.log("Agent Calling");
        //     let data = {
        //       agentId: agent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/admin/sortAgent", data)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getAgentCount(data);
        //           this.loading = false;
        //           //////console.log("====", response.data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                // AgentService.getAllCounsellorAgents()
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getAllAdmissions: function () {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllAdmissions()
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // //////console.log("switchPage calling");
            if (this.paginationAdmissions) {
                // //////console.log(this.paginationAdmissions, "paginationAdmissions");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortAdmissions) {
                // //////console.log(this.allSortAdmissions, "allSortAdmissions");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // //////console.log(this.searchPagination, "searchPagination");
            }

            // if (this.paginationAdmissions) {
            //   this.getAllPageable(currentPage, pageCount);
            // } else if (this.searchPaginationAdmissions) {
            //   this.getAllSearchPageable(currentPage, pageCount, this.keyword.toLowerCase());
            // } else if (this.sortCoursePaginationAdmissions) {
            //   this.getAllCoursePageable(currentPage, pageCount, this.data);
            // } else if (this.sortSourcePaginationAdmissions) {
            //   this.getAllSourcePageable(currentPage, pageCount, this.data);
            // } else if (this.sortBranchPaginationAdmissions) {
            //   this.getAllBranchPageable(currentPage, pageCount, this.data);
            // } else if (this.sortAgentPaginationAdmissions) {
            //   this.getAllAgentPageable(currentPage, pageCount, this.data);
            // } else if (this.sortDatePaginationAdmissions) {
            //   this.getAllDatePageable(currentPage, pageCount, this.data);
            // }
        },
        getCount: function () {
            this.loading = true;
            this.allSortAdmissions = false;
            this.paginationAdmissions = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            // this.loading = true;
            // this.paginationAdmissions = true;
            // this.searchPaginationAdmissions = false;
            // this.sortAgentPaginationAdmissions = false;
            // this.sortCoursePaginationAdmissions = false;
            // this.sortSourcePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            // this.sortDatePaginationAdmissions = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getAdmissionCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        // //////console.log("this.totalCount", this.totalCount);
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getAllAdmissionPageable(currentPage, pageCount)
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getDateCount: function (selectedDates) {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortAgentPaginationAdmissions = false;
        //   this.sortCoursePaginationAdmissions = false;
        //   this.sortSourcePaginationAdmissions = false;
        //   this.sortBranchPaginationAdmissions = false;
        //   this.sortDatePaginationAdmissions = true;
        //   this.currentPage = 1;

        //   if (selectedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedDates = null;
        //     this.getDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format("yyyy-MM-DD HH:mm");
        //     this.toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getDateRange: function () {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //       var fromDateObject = new Date(this.from);
        //       var toDateObject = new Date(this.to);
        //       var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //       var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //       var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //       var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //       this.data = {
        //         from: from,
        //         to: to,
        //       };
        //       this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getDateRangeReset();
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getDateRangeReset: function () {
            this.$refs.dateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        // getAllDatePageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllDatePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        getSearchCount: function (keyword) {
            this.loading = true;
            this.searchPagination = true;
            this.paginationAdmissions = false;
            this.allSortAdmissions = false;
            this.currentPage = 1;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            // this.paginationAdmissions = false;
            // this.searchPaginationAdmissions = true;
            // this.sortAgentPaginationAdmissions = false;
            // this.sortCoursePaginationAdmissions = false;
            // this.sortSourcePaginationAdmissions = false;
            // this.sortBranchPaginationAdmissions = false;
            // this.sortDatePaginationAdmissions = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allAdmissions = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getCourseCount: function (selectedCourse) {
        //   this.loading = true;
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = null;
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortAgentPaginationAdmissions = false;
        //   this.sortCoursePaginationAdmissions = true;
        //   this.sortSourcePaginationAdmissions = false;
        //   this.sortBranchPaginationAdmissions = false;
        //   this.sortDatePaginationAdmissions = false;
        //   this.currentPage = 1;
        //   if (selectedCourse == "allCourses") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getCourseCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllCoursePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllCoursePageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllCoursePageable(
        //       currentPage,
        //       pageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getSourceCount: function (selectedSource) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = null;
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortAgentPaginationAdmissions = false;
        //   this.sortCoursePaginationAdmissions = false;
        //   this.sortSourcePaginationAdmissions = true;
        //   this.sortBranchPaginationAdmissions = false;
        //   this.sortDatePaginationAdmissions = false;
        //   this.currentPage = 1;
        //   if (selectedSource == "allSources") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getSourceCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllSourcePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllSourcePageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllSourcePageable(
        //       currentPage,
        //       pageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getBranchCount: function (selectedBranch) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = null;
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortAgentPaginationAdmissions = false;
        //   this.sortCoursePaginationAdmissions = false;
        //   this.sortSourcePaginationAdmissions = false;
        //   this.sortBranchPaginationAdmissions = true;
        //   this.sortDatePaginationAdmissions = false;
        //   this.currentPage = 1;
        //   if (selectedBranch == "allBranches") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getBranchCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllBranchPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllBranchPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllBranchPageable(
        //       currentPage,
        //       pageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAgentCount: function (selectedAgent) {
        //   this.loading = true;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedDates = null;
        //   this.paginationAdmissions = false;
        //   this.searchPaginationAdmissions = false;
        //   this.sortAgentPaginationAdmissions = true;
        //   this.sortCoursePaginationAdmissions = false;
        //   this.sortSourcePaginationAdmissions = false;
        //   this.sortBranchPaginationAdmissions = false;
        //   this.sortDatePaginationAdmissions = false;
        //   this.currentPage = 1;
        //   if (selectedAgent == "allAgents") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       agentId: selectedAgent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminAdmissionsService.getAgentCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllAgentPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllAgentPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.getAllAgentPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // getAdmissionsBetweenDates: function () {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   if (this.selectedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //   } else {
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       AdminAdmissionsService.getByDate(this.selectedDates)
        //         .then((response) => {
        //           this.allAdmissions = response.data;
        //           this.getDateCount();
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // searchName: function (keyword) {
        //   return new Promise((resolve, reject) => {
        //     AdminAdmissionsService.searchAdmission(keyword.toLowerCase())
        //       .then((response) => {
        //         this.allAdmissions = response.data;
        //         this.getSearchCount(keyword.toLowerCase());
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        refresh: function () {
            this.getCount();
            this.selectedCourse = "Course";
            this.selectedMode = "mode";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            // this.pageCount = "5";
            this.keyword = "";
            // this.getAllAdmissions();
        },

    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.action_space {
    min-width: 125px;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dateCell {
    /* min-width: 80px; */
    max-width: 125px;
}
</style>
