<template>
<div class=" mt-5" style="overflow: hidden;" >
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
        <div class="text-center mt-2">
            <img class="logo" src="encore.svg" width="60" height="60" alt="logo" loading="lazy" />
            <h1 class="text-animation">ENCORE</h1>
        </div>
        <b-row>
            <b-col class="px-5" md="6" sm="6" offset-md="3">
                <div>
                    <div class="center">
                        <div class="g-signin2" data-onsuccess="onSignIn" data-theme="dark" data-width="300" data-height="50" data-longtitle="true"></div>
                    </div>
                    <div class="pt-2">
                        or
                    </div>
                    <div class="form-row mt-1">
                        <div class="form-group px-5 py-3">
                            <b-input type="text" class="form-control rounded-pill form-input" v-model.trim="$v.user.username.$model" placeholder="Email/Username"></b-input>
                        </div>

                        <div class="form-group px-5 py-3">
                            <!-- <label>Password</label> -->
                            <!-- <b-input type="password" class="form-control rounded-pill" v-model.trim="$v.user.password.$model" :class="{'is-invalid':$v.user.password.$error, 'is-valid':!$v.user.password.$invalid}" placeholder="Enter your password" v-on:keyup.enter="doSignInProcess"></b-input> -->
                            <!-- <div class="form-inline"> -->
                            <!-- <div v-if="!passwordHidden">
                                    <label>
                                        <b-input type="text" class="password-field form-control d-inline rounded-pill" v-model="user.password" placeholder="Enter your password"></b-input>
                                        <span class="display-eye fa fa-eye-slash" @click="hidePassword"></span>
                                    </label>
                                </div>
                                <div v-if="passwordHidden">
                                    <label>
                                        <b-input type="password" class="password-field form-control d-inline rounded-pill" v-model="user.password" placeholder="Enter your password"></b-input>
                                        <span class="display-eye fa fa-eye" @click="showPassword"></span>
                                    </label>
                                </div> -->
                            <!-- </div> -->
                            <!-- <b-input class="form-control input" type="text" v-model="user.contact" required autofocus v-bind:class="{error: $v.user.contact.$error, valid: $v.user.contact.$dirty && !$v.user.contact.$invalid }" placeholder="Email/Mobile/Username" /> -->
                            <!-- <div v-if="$v.user.contact.$dirty">
                                   <p class="error-message" v-if="!$v.user.contact.required">Field is required</p>
                                   <p class="error-message" v-if="$v.user.contact.required && !$v.user.contact.ContactValidate">Invalid</p>
                                   <p class="error-message" v-if="$v.user.contact.required && $v.user.contact.ContactValidate && !$v.user.contact.UniqueValidate">Already Exists</p>
                              </div> -->

                            <b-input-group class="mt-3">
                                <template #append>
                                    <b-input-group-text class="form-control form-input-eye" style="cursor:pointer" @click="isPwd=!isPwd">
                                        <i v-if="isPwd" class="fa fa-eye-slash" aria-hidden="true"></i>
                                        <i v-if="!isPwd" class="fa fa-eye" aria-hidden="true"></i>
                                    </b-input-group-text>
                                </template>
                                <b-form-input class="form-control form-input" :type="isPwd ? 'password' : 'text'" v-model="user.password" placeholder="Password" v-on:keyup.enter="doSignInProcess"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="d-flex justify-content-end custom-css mt-2">
                            <b>
                                <p @click="forgotModalReset()">Forgot Password ?</p>
                            </b>
                        </div>
                    </div>
                    <div class="text-center">
                        <b-button class="px-5" type="submit" @click="doSignInProcess()" pill variant="primary">signin</b-button>
                    </div>

                    <!-- <div class="text-center " style="margin-top: 20%;">
                        <div style="display: flex; align-items: center;">
                            <b-checkbox></b-checkbox>
                            <span class="px-1">by completing this form, I agree to the <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('terms')">terms & conditions</span> and <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('policy')">privacy</span> <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('policy')"> policy</span>.</span>
                        </div>
                    </div> -->
                   

                </div>
            </b-col>
            
        </b-row>
        
        <footer class="page-footer  blue fixed-bottom" style="background-color: rgb(194, 191, 191);">

<!-- Copyright -->
<div class="footer-copyright text-center py-3">
    <span class="px-1"> © <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('terms')">Terms & conditions</span> and <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('policy')">privacy</span> <span style="color:#0066E6;cursor: pointer;" @click="termsCondition('policy')"> policy</span>.</span>

</div>
<!-- Copyright -->

</footer>

    <b-modal size="md" ref="forgotModalShow" centered hide-footer hide-header no-close-on-backdrop>
        <div class="text-center mt-2">
            <img src="encore.svg" width="60" height="60" alt="logo" loading="lazy" />
            <div class="text-center mt-2" v-if="mailSendToAccount">
                <div>
                    <p style="color:rgb(168, 172, 188);">Check your email for a link to reset your password</p>
                </div>
                <div class="py-2">
                    <b-button class="px-5" type="submit" @click="$refs.forgotModalShow.hide()" pill variant="primary">Back to login</b-button>
                </div>
            </div>
            <div v-else>
                <div class="my-2">
                    <h6>Forgot Password?</h6>
                </div>
                <div class="form-row mt-4">
                    <div class="form-group px-5">
                        <b-form-input v-model="contact" :class="{'is-invalid':$v.contact.$error, 'is-valid':!$v.contact.$invalid}" placeholder="Enter your email">
                        </b-form-input>
                        <div class="valid-feedback">Your email is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.contact.required">Email is required</span>
                            <span v-if="!$v.contact.isUnique">Enter your proper email</span>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <b-button class="mx-3 px-5" type="submit" pill variant="primary" :disabled="disabledButton" @click="disabledButton = true,userPasswordReset()">Reset</b-button>
                        <b-button class="mx-3 px-5" type="submit" pill variant="primary" @click="$refs.forgotModalShow.hide()">Back</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal id="selectTeam" title="Select Team" ref="selectTeamModal" no-close-on-backdrop hide-footer>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Team</label>
                <b-form-select class="form-control" v-model="selectedTeam" :class="{'is-invalid': $v.selectedTeam.$error,'is-valid': !$v.selectedTeam.$invalid,}">
                    <option value="null" disabled>Select Team</option>
                    <option v-for="t in userAddedTeams" :value="t.teamId" v-bind:key="t.teamId">
                        <strong>{{ t.name }}</strong>
                    </option>
                </b-form-select>
            </div>
        </div>
        <div class="text-center mt-3 mt-2">
            <b-button type="submit" @click="multiTeamLogin()" class="" variant="outline-primary">Login</b-button>
        </div>
    </b-modal>
    
</div>

</template>

<script>
import axios from "axios";
import {
    required,
    email
} from "vuelidate/lib/validators";
import AgentService from "../service/AgentService";
import Account from '../service/Account';

export default {
    name: "Login",
    props: {
        passwordText: {
            default: "",
            type: String
        },
        fieldLabel: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            loading: false,
            user: {
                username: "",
                password: "",
            },
            params: {
                client_id: "171717666890-5qr9lc7kfeim2m8jr90suh2cn51d4o1m.apps.googleusercontent.com",
            },
            renderParams: {
                width: 250,
                height: 50,
                longtitle: true,
            },
            agent: "",
            teamId: "",
            tenantId: "",
            contact: "",
            mailSendToAccount: false,
            disabledButton: false,
            passwordHidden: {
                default: false,
                type: Boolean
            },
            isPwd: true,
            selectedTeam: null,
            teams: [],
            userAddedTeams: [],
        };
    },
    validations: {
        user: {
            username: {
                required,
            },
            password: {
                required,
            },
        },
        contact: {
            required,
            email,
            isUnique(value) {
                if (value === "") return true;
                var email_regex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(email_regex.test(value));
                    }, 350 + Math.random() * 300);
                });
            },
        },
        selectedTeam: {
            required
        },
    },
    components: {},

    mounted() {},
    methods: {

        termsCondition(name) {
            console.log("=============", name);
            if (name === "terms") {
                this.$router.push('/terms');

            } else {
                this.$router.push('/policy')

            }
        },
        async multiTeamLogin() {
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                this.$refs.selectTeamModal.hide();
                localStorage.setItem("tenant-uuid", this.selectedTeam);
                axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                await this.getAgent();
                await this.routeToCallsPage()
            }
        },
        hidePassword() {
            this.passwordHidden = true;
        },
        showPassword() {
            this.passwordHidden = false;
        },
        onSuccess: function (googleUser) {
            var id_token = googleUser.getAuthResponse().id_token;
            console.log("ID Token: " + id_token);
        },
        async doSignInProcess() {
            this.$v.user.$touch();
            if (!this.$v.user.$invalid) {
                this.loading = true;
                // console.log('doSignInProcess Started')
                await this.signin()
                // console.log('doSignInProcess 1')
                await this.getUserInfo(localStorage.getItem('contact'))
                await this.getTeamId();
                console.log("this.userAddedTeams======= ", this.userAddedTeams);
                // console.log('doSignInProcess 2')
                //login id using agentid set to localstorage
                if (this.userAddedTeams.length != 0) {
                    await this.getAllTeams();
                    await this.getUserAddedTeam();
                    this.loading = false;
                } else {
                    await this.getAgent();
                    await this.routeToCallsPage()
                }
                this.loading = false;
                // console.log('doSignInProcess Ended')
            }
        },
        getUserAddedTeam() {
            if (this.userAddedTeams != null) {
                let teamAdd = [];
                let teamNotAdd = [];
                for (var i = 0; i < this.teams.length; i++) {
                    console.log("name====== ", this.teams[i].name);
                    let isEqual = false;
                    this.userAddedTeams.forEach(e => {
                        console.log(e.teamId, " == ", this.teams[i].teamId);
                        if (e.teamId == this.teams[i].teamId) {
                            isEqual = true;
                        }
                    });
                    if (isEqual) {
                        teamAdd.push(this.teams[i]);
                    } else {
                        teamNotAdd.push(this.teams[i]);
                    }
                }
                this.userAddedTeams.splice(0);
                this.userAddedTeams = teamAdd;
                this.$refs.selectTeamModal.show();
            }
        },
        signin: function () {
            let user = {
                contact: this.user.username,
                password: this.user.password
            }
            return new Promise((resolve, reject) => {
                Account.login(user)
                    .then((response) => {
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access_token;
                        this.contact = this.user.username;
                        localStorage.setItem("accessToken", response.data.access_token);
                        localStorage.setItem("refreshToken", response.data.refresh_token);
                        localStorage.setItem("contact", this.user.username);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.$swal({
                            title: "Bad Credential",
                            icon: "error",
                            button: "ok",
                        });
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        localStorage.setItem("current_user", JSON.stringify(response.data));
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getTeamId() {
            return new Promise((resolve, reject) => {
                Account.getTeam(JSON.parse(localStorage.getItem('current_user')).id)
                    .then((response) => {
                        if (response.data.length == 1) {
                            console.log("if=============");
                            localStorage.setItem("tenant-uuid", response.data[0].teamId);
                            axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
                        } else {
                            console.log("getTeamId======else", response.data);
                            this.userAddedTeams = response.data;
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllTeams() {
            return new Promise((resolve, reject) => {
                axios.post("/team/getAll")
                    .then((response) => {
                        this.teams.splice(0);
                        this.teams = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getUserAddedTeam() {
        //     return new Promise((resolve, reject) => {
        //         Account.getTeam(localStorage.getItem("current_user").id)
        //             .then((response) => {
        //                     console.log("getUserAddedTeam====",response);
        //                 if (this.userAddedTeams != null) {
        //                     for (var i = 0; i < this.teams.length; i++) {
        //                         console.log("name====== ",this.teams[i].name);
        //                         let isEqual = false;
        //                         this.userAddedTeams.forEach(e => {
        //                             console.log(e.teamId," == ",this.teams[i].teamId);
        //                             if (e.teamId == this.teams[i].teamId) {
        //                                 isEqual = true;
        //                             }
        //                         });
        //                         if (isEqual) {
        //                             userAddedTeams.push(this.teams[i]);
        //                         } else {
        //                             userNotAddedTeams.push(this.teams[i]);
        //                         }
        //                     }
        //                     console.log("===userAddedTeams===",userAddedTeams);
        //                     console.log("===userNotAddedTeams===",userNotAddedTeams);
        //                     this.userAddedTeams.splice(0);
        //                     this.userAddedTeams = userAddedTeams;
        //                     this.$refs.selectTeamModal.show();
        //                 }
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        routeToCallsPage: function () {
            console.log("========routeToCallsPage========");
            let current_user = JSON.parse(localStorage.getItem('current_user'))
            let is_super_admin = false;
            let is_admin = false;
            let is_hr = false;
            let is_admin_supervisor = false;
            let is_supervisor = false;
            let counsellor = false;
            let telecaller = false;
            let user = false;
            let courier = false;
            // let employee = false;
            current_user.roles.forEach(e => {
                if (e.roleName == 'ROLE_SUPER_ADMIN') {
                    is_super_admin = true;
                    localStorage.setItem("ROLE_SUPER_ADMIN", true)
                }
                if (e.roleName == 'ROLE_ADMIN') {
                    is_admin = true;
                    localStorage.setItem("ROLE_ADMIN", true)
                }
                if (e.roleName == 'ROLE_HR') {
                    is_hr = true;
                    localStorage.setItem("ROLE_HR", true)
                }
                if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    is_admin_supervisor = true;
                    localStorage.setItem("ROLE_ADMIN_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_SUPERVISOR') {
                    is_supervisor = true;
                    localStorage.setItem("ROLE_SUPERVISOR", true)
                }
                if (e.roleName == 'ROLE_COUNSELLOR') {
                    counsellor = true;
                    localStorage.setItem("ROLE_COUNSELLOR", true)
                }
                if (e.roleName == 'ROLE_TELECALLER') {
                    telecaller = true;
                    localStorage.setItem("ROLE_TELECALLER", true)
                }
                if (e.roleName == 'ROLE_COURIER') {
                    courier = true;
                    localStorage.setItem("ROLE_COURIER", true)
                }
                if (e.roleName == 'ROLE_USER') {
                    user = true;
                    localStorage.setItem("ROLE_USER", true)
                }
                // if (e.roleName == 'ROLE_EMPLOYEE') {
                //     employee = true;
                //     localStorage.setItem("ROLE_EMPLOYEE", true)
                // }
            });
            // return new Promise((resolve, reject) => {
            if (is_super_admin) {
                if (this.userAddedTeams.length == 0) {
                    localStorage.setItem("tenant-uuid", null);
                    localStorage.setItem("roles", 'ROLE_SUPER_ADMIN')
                    this.$router.push('/super-admin/team')
                } else {
                    localStorage.setItem("roles", 'ROLE_ADMIN')
                    this.$router.push('/settings')
                }
            } else if (is_admin) {
                localStorage.setItem("roles", 'ROLE_ADMIN')
                this.$router.push('/settings')
            } else if (is_hr) {
                localStorage.setItem("roles", 'ROLE_HR')
                this.$router.push('/hr/employees')
            } else if (is_admin_supervisor) {
                localStorage.setItem("roles", 'ROLE_ADMIN_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (is_supervisor) {
                localStorage.setItem("roles", 'ROLE_SUPERVISOR')
                this.$router.push('/user/branchBatch')
            } else if (counsellor) {
                localStorage.setItem("roles", 'ROLE_COUNSELLOR')
                this.$router.push('/user/enquiry')
            } else if (telecaller) {
                localStorage.setItem("roles", 'ROLE_TELECALLER')
                this.$router.push('/user/calls')
            } else if (courier) {
                localStorage.setItem("roles", 'ROLE_COURIER')
                this.$router.push('/bookCourier')
            } else if (user) {
                localStorage.setItem("roles", 'ROLE_USER')
                this.$router.push('/user/attendace')
            }
            // else if (employee) {
            //     console.log("=====Role Employee Calling=====");
            //     this.employeePasswordChange();
            //     localStorage.setItem("roles", 'ROLE_EMPLOYEE')
            //     this.$router.push('/user/attendace')
            // }
        },
        // employeePasswordChange: function () {
        //     console.log("=========Employee Password Change=========");
        //     var currentUser = JSON.parse(localStorage.getItem("current_user"));
        //     var userId = currentUser.id;
        //     return new Promise((resolve, reject) => {
        //         Account.employeePasswordChange(userId)
        //         .then((response) => {

        //             console.log("=====Employe Password Changed SuccessFully");
        //             resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        getAgent: function () {
            console.log("=========getAgent=========");
            var currentUser = JSON.parse(localStorage.getItem("current_user"));
            var userId = currentUser.id;
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != "" && response.data != null) {
                            this.agent = response.data;
                            if (this.agent.roleAdminSupervisor) {
                                localStorage.setItem("branches", JSON.stringify(this.agent.branches))
                                localStorage.setItem("ROLE_ADMIN_SUPERVISOR", this.agent.roleAdminSupervisor)
                            }
                            if (this.agent.roleSupervisor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_SUPERVISOR", this.agent.roleSupervisor)
                            }
                            if (this.agent.roleCounsellor) {
                                localStorage.setItem("branch", JSON.stringify(this.agent.branch))
                                localStorage.setItem("ROLE_COUNSELLOR", this.agent.roleCounsellor)
                            }
                            localStorage.setItem("agentId", this.agent.agentId);
                            console.log("getAgent========function end");
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        userPasswordReset: function () {
            this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {
                return new Promise((resolve, reject) => {
                    Account.contactCheck(this.contact)
                        .then((response) => {
                            if (response.data == true) {
                                return new Promise((resolve, reject) => {
                                    let data = {
                                        contact: this.contact
                                    }
                                    Account.passwordReset(data)
                                        .then((response) => {
                                            this.mailSendToAccount = true;
                                            this.disabledButton = false;
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.$swal({
                                    title: "Invalid Email",
                                    icon: "error",
                                    button: "ok",
                                });
                                this.disabledButton = false;
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        forgotModalReset() {
            this.disabledButton = false
            this.mailSendToAccount = false;
            this.$refs.forgotModalShow.show();
            this.contact = "";
            this.$nextTick(() => {
                this.$v.contact.$reset();
            });
        }
    },
};
</script>

<style scoped>



.form-input-eye {
    height: 40px;
    border-radius: 0 25px 25px 0;
}

.form-input {
    /* margin-right: -5px; */
    /* padding-right: 35px; */
    border-radius: 25px;
    background-color: #eee;
}

/* input {
    margin-right: -30px;
    padding-right: 35px;
}

.strong-label {
    margin-right: 10px;
    font-weight: 900;
}

.display-eye {
    cursor: pointer;
} */

.logo {
    transition: transform .7s ease-in-out;
}

.logo:hover {
    transform: rotate(360deg);
}

.text-animation {
    background: 50% 100%/50% 50% no-repeat radial-gradient(ellipse at bottom, rgb(0, 0, 0), transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    /* font-size: 10vw; */
    font-family: "Source Sans Pro", sans-serif;
    -webkit-animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
    animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
}

@-webkit-keyframes reveal {
    80% {
        letter-spacing: 8px;
    }

    100% {
        background-size: 300% 300%;
    }
}

@keyframes reveal {
    80% {
        letter-spacing: 8px;
    }

    100% {
        background-size: 300% 300%;
    }
}

@-webkit-keyframes glow {
    40% {
        text-shadow: 0 0 8px rgb(0, 0, 0);
    }
}

@keyframes glow {
    40% {
        text-shadow: 0 0 8px rgb(0, 0, 0);
    }
}

.custom-css {
    color: #0066E6;
    cursor: pointer;
    font-size: 12px;
}

.center {
    margin-top: 10%;
    text-align: center;
    align-items: center;
}

.g-signin2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

::placeholder {
    text-align: center;
}

.login {
    background-color: white;
   
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

 
</style>
