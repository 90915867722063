<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <br>
    <br>
    <h6>All Attendance Page</h6>
    <b-container>
        <div>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <b-row align-v="center">
                                <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                                    <b-pagination v-model="currentPage" :total-rows="totalAttendanceCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchAttendancePage(currentPage, pageCount)
                      "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="
                        switchAttendancePage(currentPage, pageCount)
                      " :options="pageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="mx-2">Total Count:</label>
                                    <b-button variant="danger">{{
                      totalAttendanceCount
                    }}</b-button>
                                </b-col>
                                <b-col sm="6" md="3" lg="3" class="" id="r1">
                                    <b-button variant="primary" v-b-modal.dateRange @click="getDateRangeReset()"> Data By Date</b-button>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <b-button v-if="hideData!=true" variant="success" @click="showFullDetails()">ShowDetails</b-button>
                                    <b-button v-if="hideData==true" variant="danger" @click="hideFullDetails()">HideDetails</b-button>
                                    <b-button variant="primary" @click="exportDetails(selectedDates)">Export Data</b-button>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-table hover responsive :items="allAttendance" :fields="Employeesfields" :per-page="allAttendance.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(username)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                </template>
                                <!-- ===========checkin============-->
                                <template v-slot:cell(checkIn)="data">
                                    <div :class="inTimeValid(data.item.checkIn) ? '' : 'invalid-time'">{{ showInTime(data.item.checkIn) }}</div>
                                    <div v-if="hideData"><b>
                                            <b-icon icon="geo-alt" variant="danger"></b-icon>
                                        </b> : {{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                                    <div v-if="hideData"><b>
                                            <b-icon icon="clock" variant="danger"></b-icon>
                                        </b> : {{ data.item.checkinTimeDif }}&nbsp;hrs</div>
                                </template>
                                <!--- ==============checkOut===========-->
                                <template v-slot:cell(checkOut)="data">
                                    <div :class="outTimeValid(data.item.checkOut) ? '' : 'invalid-time'">{{ showInTime(data.item.checkOut) }}</div>
                                    <div v-if="hideData"><b>
                                            <b-icon icon="geo-alt" variant="danger"></b-icon>
                                        </b> : {{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                                    <div v-if="hideData"><b>
                                            <b-icon icon="clock" variant="danger"></b-icon>
                                        </b> : {{ data.item.checkoutTimeDif }}&nbsp;hrs</div>
                                </template>
                                <!---============status==============-->
                                <template v-slot:cell(status)="data">
                                    <div v-if="getDay(data.item.createdAt) == 'Sun'" class="text-black text-center bg-warning"><b>Week End</b></div>
                                    <div v-else-if="
                data.item.checkIn == null && data.item.checkOut == null
              " style="color: red">ABSENT</div>
                                    <div v-else style="color: green"><b>PRESENT</b></div>
                                </template>
                            </b-table>
                        </b-container>
                    </div>
                </b-card>
            </b-container>
        </div>

        <b-modal ref="attendanceDateRange" hide-footer id="dateRange" title="Select Date">
                                <div>
                                    <label for="from-datepicker">Choose The From date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                      'is-invalid': $v.from.$error,
                      'is-valid': !$v.from.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div>
                                    <label for="to-datepicker">Choose The To date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                      'is-invalid': $v.to.$error,
                      'is-valid': !$v.to.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div class="text-center mt-3">
                                    <b-button variant="outline-success" type="submit" @click="
                      getAttendanceCountByDate(selectedDates
                      )
                    ">Submit</b-button>
                                </div>
                            </b-modal>

    </b-container>
</div>
</template>

<script>
import {
    required,
} from "vuelidate/lib/validators";
import moment from "moment-timezone";
import Account from '../service/Account.js';
import AllOfficeAttendanceService from "../service/AllOfficeAttendanceService";
export default {
    name: "AllAttendancePage",
    components: {},
    data() {
        return {
            hideData: false,
            // for Attendance pagination
            // rows: 100,
            allAttendance: [],
            keyword: "",
            loading: false,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalAttendanceCount: 0,

            from: "",
            to: "",
            fromValue: "",
            toValue: "",
            selectedDates: null,

            Employeesfields: [{
                    key: "username",
                    label: "Name",
                },
                {
                    key: "checkIn",
                    label: "CheckIn",
                },
                {
                    key: "checkOut",
                    label: "CheckOut",
                },
                {
                    key: "status",
                    label: "Status",
                },
            ],

        };
        
    },

    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },

    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.getAllAttendanceCount();
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },

    watch: {},

    methods: {

        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAttendanceCountByDate: function (selectedDates) {
            if (
                this.from != "" &&
                this.to != "" &&
                selectedDates == selectedDates
            ) {
                // console.log("=====From Date=====", this.from)
                // console.log("=====To Date=====", this.to)
                this.$refs.attendanceDateRange.hide();
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));

            }
            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.from = this.fromDateFormat;
            this.to = this.toDateFormat;
            // console.log("=====From Date=====", this.from)
            // console.log("=====To Date=====", this.to)
            this.data = {
                officeId: this.officeId,
                from: this.from,
                to: this.to,
            }
            // console.log("=====Data=====", this.data);
            return new Promise((resolve, reject) => {
                AllOfficeAttendanceService.getAttendanceCount(this.data)
                    // axios
                    //         .post("/check/count",this.data)
                    .then((response) => {
                        this.totalAttendanceCount = response.data;
                        // console.log("=====totalAttendanceCount=====", response.data)
                        this.getAllAttendancePageable(this.currentPage, this.pageCount, this.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllAttendanceCount: function () {
            this.currentPage = 1;
            // console.log("=====Selected Date=====", this.selectedDate)
            // var DateObject = new Date(this.selectedDate);
            var date = new Date();
            this.fromDate = new Date(date.setHours(0, 0, 0, 0));
            this.toDate = new Date(date.setHours(23, 59, 59, 0));
            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.from = this.fromDateFormat;
            this.to = this.toDateFormat;
            // console.log("=====From Date=====", this.from)
            // console.log("=====To Date=====", this.to)
            this.data = {
                officeId: this.officeId,
                from: this.from,
                to: this.to,
            }
            console.log("=====Data=====", this.data);
            return new Promise((resolve, reject) => {
                AllOfficeAttendanceService.getAttendanceCount(this.data)
                    // axios
                    //         .post("/check/count",this.data)
                    .then((response) => {
                        this.totalAttendanceCount = response.data;
                        console.log("=====totalAttendanceCount=====", response.data)
                        this.getAllAttendancePageable(this.currentPage, this.pageCount, this.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllAttendancePageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AllOfficeAttendanceService.getAllAttendancePageable(currentPage, pageCount, this.data)
                    .then((response) => {
                        this.allAttendance = response.data;
                        this.loading = false;
                        console.log("=====allAttendance=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchAttendancePage: function (currentPage, pageCount) {
            // this.loading = true;
            if (this.paginationAttendance) {
                this.getAllAttendancePageable(currentPage, pageCount);
                this.loading = false;
                // console.log("this.paginationOffice", this.paginationAttendance);
            }
        },

        showInTime(time) {
            // console.log("__________________________________" + time);
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const timeFormat = timeObj.format("h:mm A");
                return timeFormat;
            } else {
                return "N/A";
            }
        },

        inTimeValid(time) {
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const hour = timeObj.format("H");
                const minute = timeObj.format("m");
                if (hour <= 10) {
                    return hour < 10 || (hour == 9 && minute <= 0);
                } else {
                    return false;
                }
            }
            // console.log("_______________IN______________" + this.showInTime);
        },

        outTimeValid(time) {
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const hour = timeObj.format("H");
                // console.log("++++++++++M++++" + hour);
                if (hour < 19) {
                    return false;
                } else {
                    return true;
                }
            }
            // console.log("_______________Out______________" + this.showOutTime);
        },

        getDay(dateString) {
            const dateParts = dateString.split(" ")[0].split("-");
            const year = parseInt(dateParts[0], 10);
            const month = parseInt(dateParts[1], 10) - 1;
            const day = parseInt(dateParts[2], 10);
            const date = new Date(year, month, day);
            const dayOfWeek = date.getDay();
            switch (dayOfWeek) {
                case 0:
                    return "Sun";
                case 1:
                    return "Mon";
                case 2:
                    return "Tue";
                case 3:
                    return "Wed";
                case 4:
                    return "Thu";
                case 5:
                    return "Fri";
                case 6:
                    return "Sat";
                default:
                    break;
            }
        },

        formatDistance(value) {

            let roundedValue = Math.round(value * 100) / 100;

            // console.log(roundedValue);

            return roundedValue;
        },

        showFullDetails() {
            this.hideData = true;
        },

        hideFullDetails() {
            this.hideData = false;
        },

        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },

        getDateRangeReset: function () {
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

    },
}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 120px;
}

.red-text {
    color: red;
}

.green-text {
    color: green;
}

.default-text {
    color: black;
}

.invalid-time {
    color: red;
}
</style>
