<template>
<div>
    <div id="grad1">
        <!-- <div class="d-flex justify-content-center align-items-center ">
                    <b-card style="max-width: 20rem;" class="mb-2">
                        <span style="font-size:1.3em;font-weight:600; color:rgba(242, 6, 6, 0.819);">Your payment failed...!</span>
                        <br>
                        <br>
                        <b-card-text>
                            <span style="color:#9b9999;font-size:.8em;">Dont worry ,Please try again your payment process</span>
                        </b-card-text>
    
                        <b-button @click="goBack()" variant="primary">Go Back</b-button>
                        <br>
                        <br>
                        <div style="color:rgba(255, 99, 71, 0.7);">
                            <span class="">Note:</span>
                            <p style="border-style:solid; border-width: 2px;">
                                If your product is not unlocked after payment is deducted from your account, please contact us +918101234234 (9AM to 6PM).
                                Or please wait for our automated system to verify your payment and unlock your product within 15 minutes.
                            </p>
                        </div>
                    </b-card>
            </div> -->

        <!-- <div class="container mt-5">
                <b-card>
                    <h3 class="text-danger">Payment Failed</h3>
                    <p>Sorry, your payment could not be processed successfully.</p>
                    <p>Error Details:</p>
                    <ul>
                        <li>Transaction ID: {{ transactionId }}</li>
                        <li>Error Code: {{ errorCode }}</li>
                        <li>Error Message: {{ errorMessage }}</li>
                    </ul>
                    <b-button variant="primary" @click="retryPayment">Retry Payment</b-button>
                </b-card>
            </div> -->

        <div class="payment-failed">
            <b-container class="text-center">
                <b-row>
                    <b-col md="12">
                        <img src="Tick.gif" height="100" alt="" />

                        <h1 class="mb-4 mt-4" id="head">Payment Success...!!!</h1>
                        <hr>
                        <!-- <span id="body">Transaction ID: {{ transaction.paymentId }}</span> -->
                        <p class="mt-2" id="body">AMOUNT PAID</p>
                        <div v-if="paymentStatus =='Already Paid'">
                            <b-button  pill variant="warning"><span id="amountspan">AMOUNT PAID</span></b-button>
                        </div>
                        <div v-else>
                        <b-button v-if="transaction.amount == null || transaction.amount == ''" pill variant="warning"><span id="amountspan">Please Wait</span></b-button>
                        <b-button v-else pill variant="warning"><span id="amountspan">Rs.{{ transaction.amount }}</span></b-button>
                        </div>
                        <hr>
                        <p class="mt-2" id="body">Your payment done successfully...!!!</p>
                        <p style="font-size:0.7em;font-weight:600; color:rgb(255, 255, 255);">Please check your payment information on your "email" & "whatsapp".</p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- <div class="d-flex justify-content-center align-items-center ">
            <b-card class="mobile-phone-card">
            <div class="status-bar"></div>
            <div class="screen">
                <div class="content">
                    <b-container class="text-center">
                <b-row>
                    <b-col md="12">
                        <img src="Greentick.png" height="100" alt="" />
                        <br>
                        <br>
                        <h1 class="mb-4" style="font-size:2.3em;font-weight:600; color:rgba(23, 181, 3, 0.819);">Payment Success...!!!</h1>
                        <span>Transaction ID: {{ transactionId }}</span>
                        <br>
                        <br>
                        <p class="text-success">Your payment done successfully...!!!</p>
                        <p>Please check your payment information on your "email" & "whatsapp".</p>
                    </b-col>
                </b-row>
            </b-container>                   
                </div>
            </div>
            <div class="home-button"></div>
        </b-card>
        </div> -->

    </div>
</div>
</template>

    
    <script>
    import axios from "axios";

    export default {
        name: 'PaymentFailurePage',
        props: ['payId'],
        components: {
    
        },
        data: function () {
            return {
          transactionId: "123456789",
          paymentId:"",
        //   amount:"6000",
        transaction:[],
        orderDetails:[],
        tenantOrderDetails:[],
        paymentStatus:"",
          errorCode: "E123",
          errorMessage: "Payment declined due to insufficient funds",
          referenceId: this.$route.query.razorpay_payment_link_reference_id,
            PaymentLinkId: this.$route.query.razorpay_payment_link_id,
            status: this.$route.query.razorpay_payment_link_status,
            organization: this.$route.query,
    
            }
        },

        async mounted(){
        // console.log("=====Organization=====",this.$route.query),
        // console.log("referenceId=====================",this.$route.query.razorpay_payment_link_reference_id);
        // async this.saveOrderDetails() {
            await this.getOrderdetails();
            // await this.getTenantOrderDetails();
            // if(this.referenceId != localStorage.getItem("referenceId")){
            if(this.orderDetails.status != "captured"){
                console.log("=====If Calling=====");
            // await this.getOrderdetails();
            await this.saveOrder();
            }
            else{
                this.paymentStatus = 'Already Paid';
                console.log("=====Else Calling=====");
            }
        // },
        
    },
        methods: {
            goBack: function () {
                // this.$router.push("/")
                window.history.back();
            },

            getOrderdetails:function(){
                let data = {
                referenceId: this.referenceId,
                paymentLinkId: this.PaymentLinkId,
                status: this.status
            }
                return new Promise((resolve, reject) => {
                axios
                    .post("/razorpay/getOrders",data)
                    .then(response => {
                    //    console.log("RESPONSE ORDERS",response.data);
                       this.orderDetails = response.data
                    //    console.log("=====Order Details1=====",this.orderDetails.tenant_id);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
            
        // getTenantOrderDetails:function(){
        //         let data = {
        //         referenceId: this.referenceId,
        //         paymentLinkId: this.PaymentLinkId,
        //         status: this.status
        //     }
        //     let config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //         'tenant-uuid': this.orderDetails.tenant_id,
        //     }
        // }
        //         return new Promise((resolve, reject) => {
        //         axios
        //             .post("/razorpay/getOrders",data,config)
        //             .then(response => {
        //                console.log("RESPONSE TENANTORDERS",response.data);
        //                this.tenantOrderDetails = response.data
        //                console.log("=====Tenant Order Details=====",this.tenantOrderDetails.status);
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        
            saveOrder:function(){
                let data = {
                referenceId: this.referenceId,
                paymentLinkId: this.PaymentLinkId,
                status: this.status
            }
            let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': this.orderDetails.tenant_id,
            }
        };
            console.log(data);
            // console.log("=====Order Details2=====",this.orderDetails.tenant_id);
            // console.log("=====config=====",config);
                return new Promise((resolve, reject) => {
                axios
                    .post("/razorpay/orders/link",data,config)
                    .then(response => {
                       console.log("RESPONSE",response.data);
                       this.transaction = response.data
                       localStorage.setItem("referenceId", this.referenceId);
                    //    setTimeout(function(){
                    //     window.history.back();
                    //   }, 1000)                       
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        }
    
    }
    </script >

<style scoped>
/* .card {
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
} */

.payment-failed {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
.mobile-phone-card {
    width: 320px;
    height: 600px;
    border: none;
    border-radius: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    background-color: #000;
}

.status-bar {
    height: 24px;
    background-color: #333;
}

.screen {
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: hidden;
}

.content {
    text-align: center;
}

.home-button {
    height: 40px;
    width: 40px;
    background-color: #333;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 20px);
} */

#head {
    font-size: 2.3em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}

#body {
    font-size: 1.3em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}

#amountspan {
    font-size: 1.1em;
    font-weight: 600;
    color: rgb(255, 255, 255);

}

#grad1 {
    height: 100%;
    background: rgb(66, 171, 74);
    background: linear-gradient(0deg, rgba(66, 171, 74, 1) 0%, rgba(121, 235, 151, 1) 100%);
}

@media screen and (max-width: 749.98px) {
    #head {
        font-size: 1.3em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }

    #body {
        font-size: 0.9em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }

    #amountspan {
        font-size: 0.9em;
        font-weight: 600;
        color: rgb(255, 255, 255);

    }
}
</style>
