<template>
<div>
    <b-container>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
        <b-tabs v-if="isInvoicePage" pills card content-class="mt-3" justified>

            <b-tab title="PartPayment" @click="getInvoiceCount()" active>
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name or contact" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="allInvoicesData.length" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage,pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage,pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>

                    <!-- <b-card class="card mt-1" v-if="offlinePayments != ''"> -->
                    <b-card class="card mt-1" v-if="allInvoices != ''">
                        <!-- <b-form-select v-model="selected" :options="options"></b-form-select> -->
                        <!-- <b-table v-if="selected == 'fullPayment'" bordered responsive hover :items="allInvoices" :fields="fields" :current-page="currentPage" :per-page="fullPaymentInvoices.pageCount"> -->
                        <!-- <b-table bordered responsive hover :items="allInvoices" :fields="fields" :current-page="currentPage" :per-page="fullPaymentInvoices.pageCount">
                            <template v-slot:cell(invoiceNumber)="data">
                                <div class="cellSize">
                                    {{ data.item.invoiceNumber }}
                                </div>
                            </template>
                            <template v-slot:cell(userName)="data">
                                {{ data.item.userName }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
        }}<span v-if="
            data.item.whatsappNumber != null &&
            data.item.whatsappNumber != ''
          ">,</span>
                                </div>
                                <div>
                                    {{ data.item.whatsappNumber }}
                                </div>
                            </template>
                            <template v-slot:cell(courseName)="data">
                                <div class="cellSize">
                                    {{ data.item.courseName }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentId)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentId }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentMethod)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentMethod }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentOption)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentOption }}
                                </div>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.created_at, "fullDate") }}
                                </div>
                            </template>
                        </b-table> -->
                        <!-- <b-table v-else bordered responsive hover :items="partPaymentInvoiceData" :fields="pfields" :current-page="currentPage" :per-page="partPaymentInvoices.pageCount"> -->
                        <!-- <b-table bordered responsive hover :items="allInvoices" :fields="pfields" :current-page="currentPage" :per-page="allInvoices.pageCount"> -->
                        <b-table bordered responsive hover :items="allInvoicesData" :fields="pfields" :current-page="currentPage" :per-page="pageCount">
                            <template v-slot:cell(invoiceNumber)="data">
                                <div class="cellSize">
                                    <!-- {{ data.item.invoiceNumber }} -->
                                    <span  style="color: #0974ff;cursor: pointer;">{{ data.item.invoiceNumber }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(userName)="data">
                                {{ data.item.userName }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                            }}<span v-if="
                                data.item.whatsappNumber != null &&
                                data.item.whatsappNumber != ''
                              ">,</span>
                                </div>
                                <div>
                                    {{ data.item.whatsappNumber }}
                                </div>
                            </template>
                            <template v-slot:cell(courseName)="data">
                                <div class="cellSize">
                                    {{ data.item.courseName }}
                                </div>
                            </template>
                            <template v-slot:cell(advanceAmount)="data">
                                <div class="cellSize">
                                    {{ data.item.advanceAmount }}
                                </div>
                            </template>
                            <template v-slot:cell(balanceAmount)="data">
                                <div class="cellSize">
                                    {{ data.item.balanceAmount }}
                                </div>
                            </template>
                            <template v-slot:cell(totalAmount)="data">
                                <div class="cellSize">
                                    {{ data.item.actualPrice - data.item.discountPrice }}
                                </div>
                            </template>
                            <template v-slot:cell(dueDate)="data">
                                <div v-if="data.item.balanceAmount != 0" class="cellSize">
                                    {{ callDate(data.item.dueDate,"fullDate") }}
                                </div>
                                <div v-else>
                                    <span> - </span>
                                </div>
                            </template>
                            <template v-slot:cell(paymentId)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentId }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentMethod)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentMethod }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentOption)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentOption }}
                                </div>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.created_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                    <b-button size="sm" v-if="data.item.balanceAmount != 0" class="mt-1 fas fa-window-close" variant="secondary" @click="getInvoiceById(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" v-b-modal.partialPaymentModal title="Pending Payment Request"></b-button>
                                    <b-button size="sm" class="mx-1" variant="success" @click="getHistoryById(data.item)" v-b-modal.enquiryView v-b-tooltip.hover.topright="{ variant: 'success' }" title="Payment History">
                                        <i class="fas fa-receipt"></i>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>

                    </b-card>

                    <b-card v-else class="text-center">
                        <span>No data found</span>
                    </b-card>

                    <div v-if="loading" class="loader">
                        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
                    </div>
                </b-container>
                <b-modal v-model="partialPaymentModal" size="lg" hide-footer hide-header>
                    <part-payment-apply :disableButton="false" :invoicedata="invoice" :courseType="courseType" :organization="organization" @close-modal="closeModal" @close-icon="closeIcon"></part-payment-apply>
                </b-modal>
                <div>
                    <!--popup start for View Enquiry details-->
                    <b-modal id="enquiryView" title="View Enquiry" hide-footer>
                        <div class="control">
                            <b-table responsive bordered striped hover :items="allInvoicesHistory" :fields="invoiceDateAndRemark">
                                <template v-slot:cell(invoiceNumber)="data">
                                    <div class="cellSize">
                                        <!-- {{ data.item.invoiceNumber }} -->
                                        <span  style="color: #0974ff;cursor: pointer;">{{ data.item.invoiceNumber }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(created_at)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.created_at, "fullDate") }}
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </b-modal>
                    <!--popup end for View Enquiry details-->
                </div>
            </b-tab>
            <b-tab title="Full Payment" @click="getFullPaymentInvoiceCount()">
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="fullPaymentKeyword" v-on:keyup.enter="getFullPaymentSearchCount(fullPaymentKeyword)" placeholder="Type a name or contact" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getFullPaymentSearchCount(fullPaymentKeyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalFullPaymentCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchFullPaymentPage(currentPage,pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchFullPaymentPage(currentPage,pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="fullPaymentRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1" v-if="allFullPaymentInvoices != ''">
                        <b-table bordered responsive hover :items="allFullPaymentInvoices" :fields="fields" :current-page="currentPage" :per-page="pageCount">

                            <template v-slot:cell(invoiceNumber)="data">
                                <div class="cellSize">
                                    <!-- {{ data.item.invoiceNumber }} -->
                                    <span  style="color: #0974ff;cursor: pointer;">{{ data.item.invoiceNumber }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(userName)="data">
                                {{ data.item.userName }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                            }}<span v-if="
                                data.item.whatsappNumber != null &&
                                data.item.whatsappNumber != ''
                              ">,</span>
                                </div>
                                <div>
                                    {{ data.item.whatsappNumber }}
                                </div>
                            </template>
                            <template v-slot:cell(courseName)="data">
                                <div class="cellSize">
                                    {{ data.item.courseName }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentId)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentId }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentMethod)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentMethod }}
                                </div>
                            </template>
                            <template v-slot:cell(paymentOption)="data">
                                <div class="cellSize">
                                    {{ data.item.paymentOption }}
                                </div>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.created_at, "fullDate") }}
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                    <b-card v-else class="text-center">
                        <span>No data found</span>
                    </b-card>
                </b-container>
            </b-tab>

            <b-tab title="Razorpay Payment Status" @click="getAllRazorpayPaymentCount()">
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="razorpayPaymentKeyword" v-on:keyup.enter="getRazorpayPaymentSearchCount(razorpayPaymentKeyword)" placeholder="Type a name or contact" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getRazorpayPaymentSearchCount(razorpayPaymentKeyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalRazorpayPaymentCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchRazorpayPaymentPage(currentPage,pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchRazorpayPaymentPage(currentPage,pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="razorpayPaymentRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1" v-if="allRazorpayPayments != ''">
                        <b-table bordered responsive hover :items="allRazorpayPayments" :fields="razorpayFields" :current-page="currentPage" :per-page="pageCount">
                            <template v-slot:cell(courseName)="data">
                                <div class="cellSize">
                                    {{ data.item.notes.product }}
                                </div>
                            </template>
                            <template v-slot:cell(created_at)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(due_date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.dueDate, "fullDate") }}
                                </div>
                            </template>
                        </b-table>
                    </b-card>
                    <b-card v-else class="text-center">
                        <span>No data found</span>
                    </b-card>
                </b-container>
            </b-tab>

            <!-- Pdf Printing Invoice Data  Start-->

            <!-- Pdf Printing Invoice Data  End-->
        </b-tabs>
        <div class="invoiceContainer">
            <div class="invoiceContent">
        <div v-if="isReportpdf" id="reportpdf" class="hidden" style="width: 1112px;">
            <div class="alert alert-light" role="alert" style="margin-bottom: 70px;">
                <button id="backbutton" type="button" class="btn btn-outline-primary" @click="backtopage()" style="float: inline-start;">Back to page</button>
                <button id="dowmolad" class="buttonDownload" @click="downloadInvoice()" style="float: inline-end;">Download</button>
                <!-- <button id="dowmolad" type="button" class="btn btn-outline-primary" @click="downloadInvoice()" style="float: inline-end;">Download Invoice
                    &nbsp;<i class="fas fa-download"></i>
                </button> -->
            </div>
            <b-card style="width:80%;position: relative;left: 130px;">
                <div class="row" style="background-color: rgb(185, 196, 231);height: 120px;">
                    <div class="col-12 col-md-4">
                        <span style="float:left; font-size: 2em;color:#2d2df7;font-family: 'Merriweather', serif;;position: relative;top: 25px;"><b>INVOICE</b></span>
                        <!-- <span v-else style="float:left; font-size: 2em;color: antiquewhite;position: relative;top: 25px;">INVOICE</span> -->
                    </div>
                    <div class="col-12 col-md-8">
                        <img v-if="invoiceDetailsById.organization == 'dexter_academy'" src="../../public/DexterLogo.png" style="float: inline-end;width: 70px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                        <img v-else-if="invoiceDetailsById.organization == 'examsdaily'" src="https://play-lh.googleusercontent.com/WiWoF7cGNi_5m6yJw6DddT6oW9HYCuo8RUn8wRwpQwYyU65f04eZZ3JlmMWGZw4bIg" style="float: inline-end;width: 70px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                        <img v-else-if="invoiceDetailsById.organization == 'edextech'" src="../../public/EdexTechLogo.png" style="float: inline-end;width: 150px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                    </div>
                    <div>
                        <span style="float: right;font-size: 1.5em;color: #2d2df7;font-family: 'Merriweather', serif;" v-if="invoiceDetailsById.organization == 'dexter_academy'"><b>Dexter Academy</b></span>
                        <span style="float: right;font-size: 1.5em;color: #2d2df7;font-family: 'Merriweather', serif;" v-else-if="invoiceDetailsById.organization == 'examsdaily'"><b>Examsdaily</b></span>
                        <span style="float: right;font-size: 1.5em;color: #2d2df7;font-family: 'Merriweather', serif;" v-else-if="invoiceDetailsById.organization == 'edextech'"><b>EdexTech</b></span>
                    </div>
                    </div>
                <!-- <div v-else class="row" style="background-color: rgb(37,78,204);height: 120px;">
                    <div class="col-12 col-md-4">
                        <span v-if="invoiceDetailsById.organization == 'edextech'" style="float:left; font-size: 2em;color: blue;position: relative;top: 25px;">INVOICE</span>
                        <span v-else style="float:left; font-size: 2em;color: antiquewhite;position: relative;top: 25px;">INVOICE</span>
                    </div>
                    <div class="col-12 col-md-8">
                        <img v-if="invoiceDetailsById.organization == 'dexter_academy'" src="https://dexteracademy.in/wp-content/uploads/2022/12/dexture-academy-logo.png" style="float: inline-end;width: 70px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                        <img v-else-if="invoiceDetailsById.organization == 'examsdaily'" src="https://play-lh.googleusercontent.com/WiWoF7cGNi_5m6yJw6DddT6oW9HYCuo8RUn8wRwpQwYyU65f04eZZ3JlmMWGZw4bIg" style="float: inline-end;width: 70px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                        <img v-else-if="invoiceDetailsById.organization == 'edextech'" src="https://edextech.io/img/edextech.02baca65.png" style="float: inline-end;width: 100px;position: relative;right: 10px;top:8px;cursor: pointer;" />
                    </div>
                    <div>
                        <span style="float: right;font-size: 1.5em;color: antiquewhite;" v-if="invoiceDetailsById.organization == 'dexter_academy'">Dexter Academy</span>
                        <span style="float: right;font-size: 1.5em;color: antiquewhite;" v-else-if="invoiceDetailsById.organization == 'examsdaily'">Examsdaily</span>
                        <span style="float: right;font-size: 1.5em;color: blue;" v-else-if="invoiceDetailsById.organization == 'edextech'">EdexTech</span>
                    </div>
                </div> -->
                <br />
                <div>
                    <div>
                        <div>
                            <span style="float: inline-start;">BILL TO:</span> <br />
                            <span style="float: right;">INVOICE NO:</span>
                            <div>
                                <span style="float: inline-start;">  {{invoiceDetailsById.userName}} </span><br />
                                <span style="float: right;">{{invoiceDetailsById.invoiceNumber}}</span>
                                <br />
                                <span style="float: right;">GSTIN:</span><br />
                                <span style="float: right;" v-if="invoiceDetailsById.organization == 'dexter_academy'">33AYFPR6125E1ZL</span>
                                <span style="float: right;" v-else-if="invoiceDetailsById.organization == 'examsdaily'">33AAPCM7797L1ZH</span>
                                <span style="float: right;" v-else-if="invoiceDetailsById.organization == 'edextech'">33AAPCM7797L1ZH</span>
                            </div>
                            <div>
                                <span style="float: inline-start;">  {{invoiceDetailsById.email}}m </span><br />
                            </div>
                            <div>
                                <span style="float: inline-start;"> +91     {{invoiceDetailsById.contact}} </span>
                            </div>
                        </div>
                    </div>
                </div> <br />
                <hr />
                <div style="float: inline-start;">
                    <span style="font-weight: 700;">
                        <span style="position: relative;right: 55px;"> Street Address: </span> <br /> 
                        <span style="float: inline-start;"> 48-1-26, Annai Therasa Street,</span> <br /> <span style="float: inline-start;">Bethaniapuram Madurai, </span>
                        <div>
                          <span  style="float: inline-start;">  Tamil Nadu 625016 </span>
                        </div>
                    </span>
                </div><br /><br /><br /><br />
                <div>
                    <table v-if="invoiceDetailsById.balanceAmount != 0" class="paymant_table">
                        <thead style="background-color: rgb(37,78,204);color: white;">
                            <tr>
                                <th>Course Name</th>
                                <th>Payment Type</th>
                                <th>Payment Method</th>
                                <th>Course Discount</th>
                                <th v-if="invoiceDetailsById.balanceAmount != null">Balance Amount</th>
                                <th v-if="invoiceDetailsById.balanceAmount != null">Due Date</th>
                                <th v-else>paid Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="paymant_table_td">{{ invoiceDetailsById.courseName }}</td>
                                <td class="paymant_table_td">{{ invoiceDetailsById.paymentMethod }} </td>
                                <td class="paymant_table_td">{{ invoiceDetailsById.paymentOption }}</td>
                                <td class="paymant_table_td">{{ invoiceDetailsById.discountPrice }}</td>
                                <td  class="paymant_table_td"  v-if="invoiceDetailsById.balanceAmount != null"  >{{ invoiceDetailsById.balanceAmount }}</td>
                                <td class="paymant_table_td"  v-if="invoiceDetailsById.balanceAmount != null" >{{ invoiceDetailsById.dueDate |moment('YYYY-MM-DD | hh:mm A')  }}</td>
                                <td  class="paymant_table_td" v-else>  {{  invoiceDetailsById.created_at|moment('YYYY-MM-DD | hh:mm A')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table class="paymant_table">
                        <thead style="background-color: rgb(37,78,204);color: white;">
                            <th>Description</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="paymant_table_td">{{ invoiceDetailsById.courseName }}</td>
                                <td class="paymant_table_td">₹ {{invoiceDetailsById.actualPrice }}</td>
                                <td v-if="invoiceDetailsById.balanceAmount != null" class="paymant_table_td">-</td>
                                <td v-else>
                                    ₹ {{invoiceDetailsById.actualPrice }}
                                </td>
                            </tr>
                            <tr v-if="invoiceDetailsById.balanceAmount != null">
                                <td class="paymant_table_td">Paid Amount</td>
                                <td class="paymant_table_td">₹ {{invoiceDetailsById.advanceAmount }}</td>
                                <td class="paymant_table_td">₹ {{invoiceDetailsById.advanceAmount }}</td>
                            </tr>
                            <tr>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td">CGST @ 9%</td>
                                <td class="paymant_table_td" v-if="invoiceDetailsById.balanceAmount != null" >₹ {{ ((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2) }}</td>
                                <td class="paymant_table_td" v-else>₹ {{ ((invoiceDetailsById.actualPrice * 9) / 100).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td">SGST @ 9%</td>
                                <td class="paymant_table_td"  v-if="invoiceDetailsById.balanceAmount != null">₹ {{ ((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2) }}</td>
                                <td class="paymant_table_td" v-else>₹ {{ ((invoiceDetailsById.actualPrice * 9) / 100).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td">Tax Included</td>
                            </tr>
                            <tr>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td">Sub Total</td>
                                <td class="paymant_table_td"  v-if="invoiceDetailsById.balanceAmount != null" >₹ {{ (invoiceDetailsById.advanceAmount) - (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 }}</td>
                                <td class="paymant_table_td" v-else>₹ {{ (invoiceDetailsById.actualPrice) - (((invoiceDetailsById.actualPrice * 9) / 100).toFixed(2))*2 }}</td>
                            </tr>
                            <tr>
                                <td class="paymant_table_td"></td>
                                <td class="paymant_table_td">Total Tax</td>
                                <td class="paymant_table_td"  v-if="invoiceDetailsById.balanceAmount != null" >₹ {{ (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 }}</td>
                                <td  class="paymant_table_td"  v-else>₹ {{ (((invoiceDetailsById.actualPrice * 9) / 100).toFixed(2))*2 }}</td> 
                            </tr>
                            <tr v-if="invoiceDetailsById.balanceAmount != null">
                                <td class="paymant_table_td">Total Amount</td>
                                <td class="paymant_table_td">-</td>
                                <td class="paymant_table_td">₹ {{ (invoiceDetailsById.advanceAmount) - (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 + (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 }}</td>
                            </tr>
                            <tr v-else>
                                <td class="paymant_table_td">Total Amount</td>
                                <td class="paymant_table_td">-</td>
                                <td class="paymant_table_td">₹ {{ (invoiceDetailsById.actualPrice) - (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 + (((invoiceDetailsById.advanceAmount * 9) / 100).toFixed(2))*2 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <span style="font-size: xx-large;"> Thank you! </span>
                </div> 
                <br />
                <div>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" style="display:inline;">
                        <tbody>
                            <tr>
                                <td valign="top" style="padding-right:10px; padding-bottom:9px;" class="mcnFollowContentItemContainer">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowContentItem">
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                        <tbody>
                                                            <tr>

                                                                <td align="center" valign="middle" width="24" class="mcnFollowIconContent">
                                                                    <a href="" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-youtube-48.png" alt="Examsdaily Youtube" style="display:block;" height="24" width="24" class=""></a>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table align="center" border="0" cellpadding="0" cellspacing="0" style="display:inline;">
                        <tbody>
                            <tr>
                                <td valign="top" style="padding-right:10px; padding-bottom:9px;" class="mcnFollowContentItemContainer">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowContentItem">
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                        <tbody>
                                                            <tr>

                                                                <td align="center" valign="middle" width="24" class="mcnFollowIconContent">
                                                                    <a href="https://www.facebook.com/Exams-Daily-1946357562269955/" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-facebook-48.png" alt="Examsdaily Facebook" style="display:block;" height="24" width="24" class=""></a>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" style="display:inline;">
                        <tbody>
                            <tr>
                                <td valign="top" style="padding-right:10px; padding-bottom:9px;" class="mcnFollowContentItemContainer">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowContentItem">
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                        <tbody>
                                                            <tr>

                                                                <td align="center" valign="middle" width="24" class="mcnFollowIconContent">
                                                                    <a href="https://twitter.com/Examsdaily" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-twitter-48.png" alt="Examsdaily Twitter" style="display:block;" height="24" width="24" class=""></a>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" style="display:inline;">
                        <tbody>
                            <tr>
                                <td valign="top" style="padding-right:0; padding-bottom:9px;" class="mcnFollowContentItemContainer">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowContentItem">
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                        <tbody>
                                                            <tr>

                                                                <td align="center" valign="middle" width="24" class="mcnFollowIconContent">
                                                                    <a href="https://www.instagram.com/examsdaily/" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-instagram-48.png" alt="Examsdaily Instagram" style="display:block;" height="24" width="24" class=""></a>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div>
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;background-color: #FAFAFA;" width="100%" class="mcnTextContentContainer">
                        <tbody>
                            <tr>

                                <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                    <a href="https://examsdaily.in/disclaimer" target="_blank">Disclaimer</a> | <a href="https://test.examsdaily.in/private-policy" target="_blank">Privacy</a> | <a href="https://examsdaily.in/advertise" target="_blank">Advertisement</a> | <a href="https://examsdaily.in/contact" target="_blank">Contact Us</a> | <a href="https://examsdaily.in/careers" target="_blank">Join Us</a><br>
                                    <br>
                                    <strong>Our mailing address is:</strong><br>
                                    <a href="mailto:support@examsdaily.in">support@examsdaily.in</a><br>
                                    <br>
                                    <em>Copyright © </em>2023 <em v-if="invoiceDetailsById.organization == 'dexter_academy'">Dexter Academy</em><em v-else-if="invoiceDetailsById.organization == 'examsdaily'">Examsdaily</em><em v-else-if="invoiceDetailsById.organization == 'edextech'">EdexTech</em><em>, All rights reserved.</em><br>
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-card>
        </div>
        </div>
        </div>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
import moment from "moment-timezone";
import PartPaymentApply from './PartPaymentApply.vue';
import InvoiceService from "../service/InvoiceService";
import RazorpayPaymentService from "../service/RazorpayPaymentService";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
    name: "Invoice",
    components: {
        PartPaymentApply
    },
    data() {
        return {
            // for loader
            paginationInvoice: true,
            searchPagination: false,
            paginationFullPaymentInvoice: false,
            searchFullPaymentPagination: false,
            paginationRazorpayInvoice: true,
            searchRazorpayPagination: false,
            keyword: "",
            fullPaymentKeyword: "",
            razorpayPaymentKeyword: "",
            invoice: {
                userName: "",
                contact: "",
                courseName: "",
                price: "",
                alternativeContact: "",
                mode: "",
                organization: "",
            },
            partialPaymentModal: false,
            update: {
                name: "",
                contact: "",
                email: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            courseType: null,
            organization: "",
            loading: false,
            fullPaymentInvoices: [],
            totalCount: 0,
            totalFullPaymentCount: 0,
            totalRazorpayPaymentCount: 0,
            allInvoices: [],
            allFullPaymentInvoices: [],
            allRazorpayPayments: [],
            // allInvoicesData: [],
            allInvoicesHistory: [],
            invoiceDetailsById: [],
            isInvoicePage: true,
            invoiceDateAndRemark: [{
                    key: "invoiceNumber",
                    label: "Invoice No",
                },
                {
                    key: "created_at",
                    label: "Created Date",
                },
                {
                    key: "advanceAmount",
                    label: "Amount Paid",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
            ],
            fields: [{
                    key: "invoiceNumber",
                    label: "Invoice No",
                },
                {
                    key: "userName",
                    label: "User Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                // {
                //     key: "totalAmount",
                //     label: "Amount",
                // },
                {
                    key: "advanceAmount",
                    label: "Amount Paid",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "totalAmount",
                    label: "Total Amount",
                },
                {
                    key: "courseName",
                    label: "Course",
                },
                // {
                //     key: "paymentId",
                //     label: "Payment Id",
                // },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
                {
                    key: "paymentOption",
                    label: "Payment Option",
                },
                {
                    key: "created_at",
                    label: "Created Date",
                },
                // {
                //     key: "action",
                //     label: "Action",
                // },
            ],
            partPaymentInvoices: [],
            pfields: [{
                    key: "invoiceNumber",
                    label: "Invoice No",
                },
                {
                    key: "userName",
                    label: "User Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "advanceAmount",
                    label: "Amount Paid",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "totalAmount",
                    label: "Total Amount",
                },
                {
                    key: "dueDate",
                    label: "Next Due Date",
                },
                {
                    key: "courseName",
                    label: "Course",
                },
                // {
                //     key: "paymentId",
                //     label: "Payment Id",
                // },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
                {
                    key: "paymentOption",
                    label: "Payment Option",
                },
                {
                    key: "created_at",
                    label: "Created Date",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],

            razorpayFields: [{
                    key: "created_at",
                    label: "Created Date",
                },
                {
                    key: "userName",
                    label: "User Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "whatsappNumber",
                    label: "WhatsappNumber",
                },
                // {
                //     key: "captured",
                //     label: "Payment Captured",
                // },
                {
                    key: "status",
                    label: "Payment Status",
                },
                {
                    key: "advanceAmount",
                    label: "Requested Amount",
                },
                {
                    key: "balanceAmount",
                    label: "Balance Amount",
                },
                {
                    key: "productType",
                    label: "Product Type",
                },
                {
                    key: "courseName",
                    label: "Course",
                },
                {
                    key: "paymentMethod",
                    label: "Payment Method",
                },
                {
                    key: "paymentOption",
                    label: "Payment Option",
                },
                {
                    key: "due_date",
                    label: "Next Due Date",
                },
                // {
                //     key: "action",
                //     label: "Action",
                // },
            ],

            pageCount: 5,
            isReportpdf: false,
            pageOptions: [
                1,
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            rows: 100,
            currentPage: 1,
            onlinePayments: [],
            offlinePayments: [],
            checked: false,
            selected: null,
            options: [{
                    value: null,
                    text: 'Please select an Payment Option',
                    disabled: true
                },
                {
                    value: 'fullPayment',
                    text: 'Full Payment'
                },
                {
                    value: 'partPayment',
                    text: 'Part Payment'
                },
            ]
        }
    },

    computed: {
        allInvoicesData() {
            const mergedMap = new Map();
            this.allInvoices.forEach((entry) => {
                // console.log("=====Entry=====", entry);
                const existingEntry = mergedMap.get(entry.admissionId);

                if (existingEntry) {

                    if (entry.created_at > existingEntry.created_at) {
                        existingEntry.created_at = entry.created_at;
                        existingEntry.balanceAmount = entry.balanceAmount;
                        existingEntry.dueDate = entry.dueDate;
                        existingEntry.invoiceNumber = entry.invoiceNumber;
                    }
                    // Merge data as needed
                    // existingEntry.userName += `, ${entry.userName}`;
                    // existingEntry.advanceAmount += +`${entry.advanceAmount}`
                    existingEntry.advanceAmount += +`${entry.advanceAmount}`
                    // existingEntry.balanceAmount += `,${entry.balanceAmount}`
                    // existingEntry.age = Math.floor((existingEntry.age + entry.age) / 2); // Example: averaging ages
                } else {
                    // If ID is not in the map, add the entry
                    mergedMap.set(entry.admissionId, {
                        ...entry
                    });
                }
            });

            // Convert the map values back to an array
            return Array.from(mergedMap.values());
        },
    },

    mounted() {
        if (localStorage.getItem("agentId") != null) {
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            // this.getAllInvoice();
                            // this.getInvoice();
                            this.loading = true;
                            this.getInvoiceCount();
                            // this.getFullPaymentInvoiceCount();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        }
    },

    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getInvoiceCount();
            }
        },

        fullPaymentKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getFullPaymentInvoiceCount();
            }
        },
        razorpayPaymentKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllRazorpayPaymentCount();
            }
        }

    },

    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllInvoice: function () {
            // console.log("=======getAllInvoice=========");
            return new Promise((resolve, reject) => {
                axios.post('/invoice/getAll/invoice')
                    .then((response) => {
                        for (let i = 0; i < response.data.length; i++) {
                            // console.log(response.data[i]);
                            if (response.data[i].paymentMethod == 'offline') {
                                if (response.data[i].paymentOption == 'fullPayment') {
                                    this.fullPaymentInvoices.push(response.data[i])
                                    console.log(response.data[i], "=======fullPayment=========");
                                } else {
                                    this.partPaymentInvoices.push(response.data[i])
                                    console.log(response.data[i], "=======PartPayment=========");
                                }
                                this.offlinePayments.push(response.data[i])
                            } else {
                                this.onlinePayments.push(response.data[i])
                            }

                        }

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getInvoice: function () {
        //     console.log("=======getAllInvoice=========");
        //     const data = {
        //         tenantId: localStorage.getItem('tenant-uuid')
        //     }
        //     return new Promise((resolve, reject) => {
        //         axios.post('/invoice/get/invoice', data)
        //             .then((response) => {
        //                 for (let i = 0; i < response.data.length; i++) {
        //                     // console.log(response.data[i]);
        //                     if (response.data[i].paymentMethod == 'offline') {
        //                         if (response.data[i].paymentOption == 'fullPayment') {
        //                             this.fullPaymentInvoices.push(response.data[i])
        //                             console.log(response.data[i], "=======fullPayment=========");
        //                         } else {
        //                             this.partPaymentInvoices.push(response.data[i])
        //                             console.log(response.data[i], "=======PartPayment=========");
        //                         }
        //                         this.offlinePayments.push(response.data[i])
        //                     } else {
        //                         this.onlinePayments.push(response.data[i])
        //                     }

        //                 }

        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        getInvoiceCount: function () {
            // console.log("=======getAllInvoice=========");
            // const data = {
            //     tenantId: localStorage.getItem('tenant-uuid')
            // }
            this.keyword = "";
            this.paginationInvoice = true;
            this.searchPagination = false;
            return new Promise((resolve, reject) => {
                // axios.post('/invoice/get/count', data)
                InvoiceService.getAllInvoiceCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        // console.log("=====Total Count=====", this.totalCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllPageable: function (currentPage, pageCount) {
            // const tenantId = localStorage.getItem('tenant-uuid')
            return new Promise((resolve, reject) => {
                InvoiceService.getAllPageable(currentPage, pageCount)
                    .then((response) => {
                        this.allInvoices = response.data;
                        // for (let i = 0; i < response.data.length; i++) {
                        //     if (response.data[i].paymentOption == 'fullPayment') {
                        //         this.fullPaymentInvoices.push(response.data[i])
                        //         console.log(response.data[i], "=======fullPayment=========");
                        //     }
                        // }
                        // console.log("=====AllInvoices=====", this.allInvoices)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSearchCount: function (keyword) {
            this.paginationInvoice = false;
            this.searchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                InvoiceService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                InvoiceService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword
                    )
                    .then((response) => {
                        this.allInvoices = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getFullPaymentInvoiceCount: function () {
            // console.log("=======getAllFullPaymentInvoice=========");
            // const data = {
            //     tenantId: localStorage.getItem('tenant-uuid')
            // }
            this.fullPaymentKeyword = "";
            this.paginationFullPaymentInvoice = true;
            this.searchFullPaymentPagination = false;
            return new Promise((resolve, reject) => {
                InvoiceService.getAllFullPaymentInvoiceCount()
                    .then((response) => {
                        this.totalFullPaymentCount = response.data;
                        this.getAllFullPaymentPageable(this.currentPage, this.pageCount);
                        // console.log("=====Total FullPayment Count=====", this.totalFullPaymentCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllFullPaymentPageable: function (currentPage, pageCount) {
            // const tenantId = localStorage.getItem('tenant-uuid')
            return new Promise((resolve, reject) => {
                InvoiceService.getAllFullPaymentPageable(currentPage, pageCount)
                    .then((response) => {
                        this.allFullPaymentInvoices = response.data;
                        // console.log("=====AllFullPAymentInvoices=====", this.allFullPaymentInvoices)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getFullPaymentSearchCount: function (fullPaymentKeyword) {
            this.paginationFullPaymentInvoice = false;
            this.searchFullPaymentPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                InvoiceService.getFullPaymentSearchCount(fullPaymentKeyword.toLowerCase())
                    .then((response) => {
                        this.totalFullPaymentCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllFullPaymentSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            fullPaymentKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllFullPaymentSearchPageable: function (currentPage, pageCount, fullPaymentKeyword) {
            return new Promise((resolve, reject) => {
                InvoiceService.getAllFullPaymentSearchPageable(
                        currentPage,
                        pageCount,
                        fullPaymentKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allFullPaymentInvoices = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllRazorpayPaymentCount: function () {
            console.log("=======getAllRazorpayPaymentCount=========");
            this.razorpayPaymentKeyword = "";
            this.paginationRazorpayInvoice = true;
            this.searchRazorpayPagination = false;
            return new Promise((resolve, reject) => {
                RazorpayPaymentService.getRazorpayPaymentCount()
                    .then((response) => {
                        this.totalRazorpayPaymentCount = response.data;
                        this.getAllRazorpayPaymentPageable(this.currentPage, this.pageCount);
                        console.log("=====Total RazorpayPayment Count=====", this.totalRazorpayPaymentCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllRazorpayPaymentPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                RazorpayPaymentService.getAllRazorpayPaymentPageable(currentPage, pageCount)
                    .then((response) => {
                        this.allRazorpayPayments = response.data;
                        console.log("=====AllRazorpayPayments=====", this.allRazorpayPayments)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getRazorpayPaymentSearchCount: function (razorpayPaymentKeyword) {
            this.paginationRazorpayInvoice = false;
            this.searchRazorpayPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                RazorpayPaymentService.getRazorpayPaymentSearchCount(razorpayPaymentKeyword.toLowerCase())
                    .then((response) => {
                        this.totalRazorpayPaymentCount = response.data;
                        console.log("Count", response.data);
                        this.getAllRazorpayPaymentSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            razorpayPaymentKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllRazorpayPaymentSearchPageable: function (currentPage, pageCount, razorpayPaymentKeyword) {
            return new Promise((resolve, reject) => {
                RazorpayPaymentService.getAllRazorpayPaymentSearchPageable(
                        currentPage,
                        pageCount,
                        razorpayPaymentKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allRazorpayPayments = response.data;
                        console.log("=====AllRazorpayPayments=====", this.allRazorpayPayments)
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            // console.log("=====Current=====", currentPage, "=====Page=====", pageCount);
            // this.loading = true;
            console.log("switchPage calling");
            if (this.paginationInvoice) {
                console.log(this.paginationInvoice, "paginationInvoice");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
            }
        },

        switchFullPaymentPage: function (currentPage, pageCount) {
            // console.log("=====Current=====", currentPage, "=====Page=====", pageCount);
            // this.loading = true;
            console.log("switchFullPaymentPage calling");
            if (this.paginationFullPaymentInvoice) {
                console.log(this.paginationFullPaymentInvoice, "paginationFullPaymentInvoice");
                this.getAllFullPaymentPageable(currentPage, pageCount);
            } else if (this.searchFullPaymentPagination) {
                this.getAllFullPaymentSearchPageable(
                    currentPage,
                    pageCount,
                    this.fullPaymentKeyword.toLowerCase()
                );
            }
        },

        switchRazorpayPaymentPage: function (currentPage, pageCount) {
            console.log("=====Current=====", currentPage, "=====Page=====", pageCount);
            // this.loading = true;
            console.log("switchRazorpayPaymentPage calling");
            if (this.paginationRazorpayInvoice) {
                console.log(this.paginationRazorpayInvoice, "paginationRazorpayInvoice");
                this.getAllRazorpayPaymentPageable(currentPage, pageCount);
            } else if (this.searchRazorpayPagination) {
                this.getAllRazorpayPaymentSearchPageable(
                    currentPage,
                    pageCount,
                    this.razorpayPaymentKeyword.toLowerCase()
                );
            }
        },

        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
            if (dateType === "onlyDate") {
                return moment(date1).format("D / MM / YYYY");
            }
        },

        getInvoiceById: function (invoice) {
            this.invoice = invoice;
            this.partialPaymentModal = true
            // this.update = {
            //                 invoiceId: invoice.invoiceId,
            //                 callId:invoice.callId,
            //                 name: invoice.userName,
            //                 contact: invoice.contact,
            //                 email:invoice.email,
            //                 alternativeContact: invoice.alternativeContact,
            //                 age: invoice.age,
            //                 qualification: invoice.qualification,
            //                 course: invoice.course,
            //                 source: invoice.source,
            //                 mode: invoice.mode,
            //                 branch: invoice.branch,

            //                 // followup: response.data.followup
            //             };
            console.log("=====Admission=====", invoice);
            console.log("=====Update=====", this.update.email);
            // return new Promise((resolve, reject) => {
            //     InvoiceService.getFollowupCreatedDate(invoice.invoiceId)
            //         .then((response) => {
            //             this.update = {
            //             invoiceId: invoice.invoiceId,
            //                 callId:invoice.callId,
            //                 name: invoice.userName,
            //                 contact: invoice.contact,
            //                 email:invoice.email,
            //                 alternativeContact: invoice.alternativeContact,
            //                 age: invoice.age,
            //                 qualification: invoice.qualification,
            //                 course: invoice.course,
            //                 source: invoice.source,
            //                 mode: invoice.mode,
            //                 branch: invoice.branch,

            //                 // followup: response.data.followup
            //             };
            //             this.followupDateRemark = response.data;
            //             // console.log(this.followupDateRemark);
            //             // this.followup.forEach(element => {
            //             //   //console.log(element);
            //             // });
            //             resolve(response);
            //         })
            //         .catch((err) => {
            //             reject(err);
            //         });
            // });
        },

        getHistoryById: function (invoice) {
            this.invoice = invoice;
            this.admissionId = invoice.admissionId
            return new Promise((resolve, reject) => {
                InvoiceService.getAllInvoiceHistory(this.admissionId)
                    .then((response) => {
                        this.allInvoicesHistory.splice(0);
                        this.allInvoicesHistory = response.data;
                        // console.log("=====AllInvoicesHistory=====", this.allInvoicesHistory)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        closeModal: function () {
            console.log("=======closeIcon=======");
            this.partialPaymentModal = false;
            // window.location.reload();
            // this.getInvoice();
            this.getInvoiceCount();
        },
        closeIcon: function () {
            console.log("=======closeIcon=======");
            this.partialPaymentModal = false;
            this.courseType = null;
            // window.location.reload();
            // this.getInvoice();
            this.getInvoiceCount();
        },

        refresh: function () {
            this.getInvoiceCount();
            this.keyword = "";
        },

        fullPaymentRefresh: function () {
            this.getFullPaymentInvoiceCount();
            this.fullPaymentKeyword = "";
        },

        razorpayPaymentRefresh: function () {
            this.getAllRazorpayPaymentCount();
            this.razorpayPaymentKeyword = "";
        },

        async getDownload(data) {
            await this.getInvoiceDetailsById(data);
            this.isReportpdf = true;
            this.isInvoicePage = false;
            // await this.downloadInvoice();
        },
        backtopage: function () {
            this.isReportpdf = false;
            this.isInvoicePage = true;
        },
        getInvoiceDetailsById: function (data) {
            console.log("=====INVOICE ID=====", data.invoiceId);
            return new Promise((resolve, reject) => {
                InvoiceService.getInvoiceDetailsById(data.invoiceId)
                    .then((response) => {
                        // this.invoiceDetailsById.splice(0);
                        this.invoiceDetailsById = response.data;
                        console.log("=====invoiceDetailsById=====", this.invoiceDetailsById)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // downloadInvoice() {
        //     this.invoiceDoc = new jsPDF('p', 'pt', 'a4');
        //     return new Promise((resolve, reject) => {
        //         html2canvas(document.getElementById('reportpdf'), {
        //                 useCORS: true,
        //                 onclone: function (clonedDoc) {
        //                     clonedDoc.getElementById('reportpdf').style.display = 'inline-block';
        //                     clonedDoc.getElementById('reportpdf').style.maxHeight = "";
        //                     clonedDoc.getElementById('reportpdf').style.overflow = "auto";
        //                 }

        //             })
        //             .then(canvas => {
        //                 var contentWidth = canvas.width;
        //                 var contentHeight = canvas.height;
        //                 var pageHeight = contentWidth / 592.28 * 841.89;
        //                 var leftHeight = contentHeight;
        //                 var position = 0;
        //                 var imgWidth = 595.28;
        //                 var imgHeight = 595.28 / contentWidth * contentHeight;
        //                 if (leftHeight < pageHeight) {
        //                     this.invoiceDoc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 8, 20, imgWidth, imgHeight);
        //                 } else {
        //                     while (leftHeight > 0) {
        //                         this.invoiceDoc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 8, position, imgWidth, imgHeight)
        //                         leftHeight -= pageHeight;
        //                         position -= 840;
        //                         if (leftHeight > 0) {
        //                             this.invoiceDoc.addPage();
        //                         }
        //                     }
        //                 }
        //                 this.invoiceDoc.save('invoice.pdf');
        //                 resolve(canvas);
        //             })
        //             .catch(err => {
        //                 console.log(err);
        //                 reject(err);
        //             });
        //     });
        // },
        downloadInvoice() {
            this.invoiceDoc = new jsPDF('p', 'pt', 'a4');
            return new Promise((resolve, reject) => {
                // const originalViewportContent = document.querySelector('meta[name="viewport"]').content;
                // document.querySelector('meta[name="viewport"]').content = 'width=1200';
                document.getElementById('dowmolad').style.display = "none";
                document.getElementById('backbutton').style.display = "none"
                document.getElementById('reportpdf').classList.remove('hidden');
                const container = document.getElementById('reportpdf');
                // const container = document.getElementById('reportpdf').classList.remove('hidden');

                // Temporarily store original font size and styles
                const originalStyles = Array.from(container.querySelectorAll('*')).map((element) => ({
                    element,
                    styles: window.getComputedStyle(element),
                }));

                // Apply font size to the container before rendering
                container.style.fontSize = '100%'; // Adjust the font size as needed

                html2canvas(container, {
                        useCORS: true,
                        scale: 2, // Adjust the scale as needed for better resolution
                    })
                    .then((canvas) => {
                        var contentWidth = canvas.width;
                        var contentHeight = canvas.height;
                        var pageHeight = (contentWidth / 592.28) * 841.89;
                        var leftHeight = contentHeight;
                        var position = 0;
                        var imgWidth = 595.28;
                        var imgHeight = (595.28 / contentWidth) * contentHeight;
                        if (leftHeight < pageHeight) {
                            this.invoiceDoc.addImage(
                                canvas.toDataURL('image/jpeg'),
                                'JPEG',
                                8,
                                20,
                                imgWidth,
                                imgHeight
                            );
                        } else {
                            while (leftHeight > 0) {
                                this.invoiceDoc.addImage(
                                    canvas.toDataURL('image/jpeg'),
                                    'JPEG',
                                    8,
                                    position,
                                    imgWidth,
                                    imgHeight
                                );
                                leftHeight -= pageHeight;
                                position -= 840;
                                if (leftHeight > 0) {
                                    this.invoiceDoc.addPage();
                                }
                            }
                        }

                        // Restore original font size and styles
                        originalStyles.forEach(({
                            element,
                            styles
                        }) => {
                            for (let i = 0; i < styles.length; i++) {
                                element.style.setProperty(styles[i], styles[styles[i]]);
                            }
                        });

                        this.invoiceDoc.save('invoice.pdf');
                        resolve(canvas);
                        // document.querySelector('meta[name="viewport"]').content = originalViewportContent;
                        document.getElementById('reportpdf').classList.add('hidden');
                        document.getElementById('dowmolad').style.display = "block";
                        document.getElementById('backbutton').style.display = "block"
                        this.backtopage()
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
            });
        }

        //     formatEmail(email) {
        //   if (email && email.length > 15) {
        //     const firstLine = email.substring(0, 15);
        //     const secondLine = email.substring(15);
        //     return `${firstLine}\n${secondLine}`;
        //   }
        //   return email;
        // },

    }
}
</script>

<style>
.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.cellSize {
    min-width: 100px;
    max-width: 100px;
    margin-left: 20px;
}

/* .hidden {
    display: none;
} */

.buttonDownload {
    display: inline-block;
    position: relative;
    padding: 10px 25px;
    background-color: #4CC713;
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    font-size: 0.9em;
    text-align: center;
    text-indent: 15px;
    border: none;
}

.buttonDownload:hover {
    background-color: #45a21a;
    color: white;
}

.buttonDownload:before,
.buttonDownload:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 15px;
    top: 52%;
}

.buttonDownload:before {
    width: 10px;
    height: 2px;
    border-style: solid;
    border-width: 0 2px 2px;
}

.buttonDownload:after {
    width: 0;
    height: 0;
    margin-left: 0px;
    margin-top: -7px;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: transparent;
    border-top-color: inherit;
    animation: downloadArrow 1s linear infinite;
    animation-play-state: paused;
}

.paymant_table {
    width: 800px;
    border-collapse: collapse;
    margin: 50px auto;
}

.buttonDownload:hover:before {
    border-color: #cdefbd;
}

.buttonDownload:hover:after {
    border-top-color: #cdefbd;
    animation-play-state: running;
}

@keyframes downloadArrow {
    0% {
        margin-top: -7px;
        opacity: 1;
    }

    0.001% {
        margin-top: -15px;
        opacity: 0.4;
    }

    50% {
        opacity: 1;
    }

    100% {
        margin-top: 0;
        opacity: 0.4;
    }
}

.paymant_table_td,
th {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 18px;
}

#heading1 {
    margin-left: -15%;
    padding-bottom: -200%;
}
.invoiceContainer {
  width: 100%; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Hide any overflow outside the container */
}

.invoiceContent {
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Add padding if needed */
  /* Add other styles as needed */
}
</style>
