import axios from 'axios';
import btoa from 'btoa';

// var oauthServerLocation = 'http://localhost:9088';
var oauthServerLocation = "https://auth-encore.examsdaily.in";
//    var oauthServerLocation = 'http://51.15.69.75:9088';
// var oauthServerLocation = 'https://test.examsdaily.in:8443/edaily-auth';

export default {
    refreshToken() {
        var refreshToken = localStorage.getItem('refreshToken')
        this.getAccessToken(refreshToken);
    },
    getAccessToken(refreshToken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token',
                headers: {
                    'Authorization': 'Basic ' + btoa("barClientIdPassword:password"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    'grant_type': 'refresh_token',
                    'client_id': 'barClientIdPassword',
                    'refresh_token': refreshToken
                }
            }).then((response) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid');
                localStorage.setItem('accessToken',response.data.access_token)
                localStorage.setItem('refreshToken',response.data.refresh_token)
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    logout(accessToken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token/revokeById/' + accessToken,
            }).then((response) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ';
                axios.defaults.headers.common["tenant-uuid"] = '';
                localStorage.removeItem("tenant-uuid");
                console.data(response.data);
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getUserInfo(session) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/find/' + session.get("contact"),
            }).then((response) => {
                session.set('current_user', response.data);
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
}


