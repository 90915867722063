import axios from "axios";
export default{

    CreateOffice(data){
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: 'office/create',
                data: data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });     
        });
    },

    GetOfficeDetail() {
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'office/detail'
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getCount: function(){
        return new Promise((resolve, reject) => {
            axios.get("/office/count/")
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.get("/office/all/" + (currentPage-1) +"/"+ pageCount)
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    UpdateOffice(data) {
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: "office/edit",
                data: data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    DeleteOffice(data) {
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: 'office/delete',
                data: data 
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            })
        })
    },

    // OFFICE DETAILS
    OfficeDetail(id){
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        // console.log("=====ID=====",id);
        return new Promise((resolve,reject) => {
            axios({
                method: 'post',
                url: 'office/selectedoffice',
                data:{
                    officeId: id
                }
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            })  
        })
    },


    // OFFICE DETAILS
    getOfficeInfo(officeName){
        // var ax = axios.create({
        //     baseURL: baseURL
        // });
        console.log("=====OfficeName=====",officeName);
        return new Promise((resolve,reject) => {
            axios.post("/user/officeDetails/" + officeName)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            })  
        })
    },


    // SEARCH OFFICE 
    getSearchCount(keyword) {
            return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/office/search/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/office/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    // All Office Details Start

    getAllOffice: function(tenantId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': tenantId,
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/office/getAllOffice",config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    // All Office Details End

}