import axios from "axios"

export default {
    getAllAdmissions: function (agentId) {
        return new Promise((resolve, reject) => {
            axios.get('/admission/getAll/' + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCount: function (agentId) {
        return new Promise((resolve, reject) => {
            axios.get('/admission/count/' + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllPageable: function (currentPage, pageCount, agentId) {
        return new Promise((resolve, reject) => {
            axios.get('/admission/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getDateCount: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/date/count/' + agentId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllDatePageable: function (currentPage, pageCount, data, agentId) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/date/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCourseCount: function (agentId, course) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/course/count/' + agentId, course)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCoursePageable: function (currentPage, pageCount, agentId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/course/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getSourceCount: function (agentId, source) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/source/count/' + agentId, source)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllSourcePageable: function (currentPage, pageCount, agentId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/source/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getBranchCount: function (agentId, branch) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/branch/count/' + agentId, branch)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllBranchPageable: function (currentPage, pageCount, agentId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/sort/branch/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    searchName: function (keyword, agentId) {
        return new Promise((resolve, reject) => {
            axios.get('/admission/search/' + keyword + "/" + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getSearchCount: function(agentId,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/admission/search/count/'+ agentId + "/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,agentId,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/admission/search/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAdmission: function(admissionId){
        return new Promise((resolve, reject) => {
            axios.post('/admission/get/'+ admissionId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
     getByDate: function (agentId,selectedDates) {
        return new Promise((resolve, reject) => {
            axios
                .post('/admission/sort/date/'+ agentId +"/"+selectedDates)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getDateRange: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/admission/sort/dateRange/'+ agentId,data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // ===========================================Counsellor==================================================
    getCounsellorAdmissionCount: function (branch) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/count' , branch)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorAdmissionPageable: function (currentPage, pageCount,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/all/' + (currentPage - 1) + "/" + pageCount , data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCounsellorSearchCount: function(counsellorKeyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/search/count/'+ counsellorKeyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorSearchPageable: function(currentPage,pageCount,counsellorKeyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/search/'+ (currentPage-1) + "/" + pageCount  + "/" + counsellorKeyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorCourseCount: function (branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sort/course/count/' + branchId ,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorCoursePageable: function (currentPage, pageCount, branchId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sort/course/all/' + (currentPage - 1) + "/" + pageCount + "/" + branchId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCounsellorSourceCount: function (branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sort/source/count/' + branchId ,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorSourcePageable: function (currentPage, pageCount, branchId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sort/source/all/' + (currentPage - 1) + "/" + pageCount + "/" + branchId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCounsellorDateCount: function (branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sortDate/count/'+ branchId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorDatePageable: function (currentPage, pageCount,branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/counsellor/sortDate/all/' + (currentPage - 1) + "/" + pageCount + "/" +branchId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // getByCounsellorDate: function (agentId,selectedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post('/admission/sort/date/'+ agentId +"/"+selectedDates)
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },
    
    // getCounsellorDateRange: function (data) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post('/admission/counsellor/sort/dateRange/'+ data)
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },
}