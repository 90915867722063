<template>
<div class="bg">
    <nav class="navbar navbar-light bg-light">
      <span class="navbar-brand px-3">
        <span class="">tele</span> <span class="fiesta">caller</span>
      </span>
    </nav>

    <div class="container mt-5 " style="text-align: left;" >
        
      <h4 class="text-uppercase"><strong> Encore telecaller privacy policy</strong></h4>
      <br />
      <br />
      <div class="paragraph">
        Encore Telecaller is firmly committed to the security and protection of personal information of our Users and their contacts. This Privacy Policy describes how Encore Telecaller will collect, use, share, and process personal information. Capitalized terms not defined in this Privacy Policy are defined in the Encore Telecaller
        <a href="#" @click.prevent="service()" class="text-primary">Terms of Service</a>.
        <br /><br />

            By accepting the Encore Telecaller Privacy Policy and/or using the Services You consent to the collection, use, sharing and processing of personal information as described herein. If You provide us with personal information about someone else, You confirm that they are aware that You have provided their information and that they consent to our use of their information according to our Privacy Policy. You may opt-out at any time to prevent further use of the information shared via the Services.
        </div>

        <br>
        <br>

        <span class="text-h5"><strong> 1. PERSONAL INFORMATION COLLECTED</strong></span>
        <br>
        <br>
        <span class="text-h6"><strong> 1.1 USER PROFILE</strong></span>
        <br>
        <br>

        <div class="paragraph">When You create a user profile in the Services and confirm being the holder of a certain number, Encore Telecaller will collect the information provided by You. In order to create a user profile, You must register Your first name, last name and phone number. Additional information that may be provided at Your option include, but is not limited to, photo, gender, street address and zip code, country of residence, email address, professional website, Facebook page, Twitter address and a short status message. Encore Telecaller may supplement the information provided by You with information from third parties and add it to the information provided by You e.g. demographic information and additional contact information that is publicly available.
        </div>

        <br>
        <br>
        <span class="text-h6"><strong> 1.2 INSTALLATION AND USE</strong></span>
        <br>
        <br>

        <div class="paragraph">When You install and use the Services, Encore Telecaller will collect personal information from You and any devices You may use in Your interaction with our Services. This information may include e.g.: geo-location; Your IP address; device ID or unique identifier; device manufacturer and type; device and hardware settings; SIM card usage; applications installed on your device; ID for advertising; ad data, operating system; web browser; operator; IMSI; connection information; screen resolution; usage statistics; default communication applications; access to device address book; device log and event information; logs, keywords and meta data of incoming and outgoing calls and messages; version of the Services You use and other information based on Your interaction with our Services such as how the Services are being accessed (via another service, web site or a search engine); Your paired devices (such as smart watch); the pages You visit and features you use on the Services; the services and websites You engage with from the Services; content viewed by You, content You have commented on or sent to us and information about the ads You see and/or engage with; the search terms You use; order information and other usage activity and data logged by Encore Telecaller’s servers from time to time.Telecaller may collect some of this information automatically through use of cookies and You can learn more about our use of cookies in our <strong @click="cookie()" style="color:#1976D2">Cookie Policy.</strong>

<br><br>
Some information, including, but not limited to, usage information and other information that may arise from Your interaction with the Services, cannot be used to identify You, whether in combination with other information or otherwise and will not constitute personal information for the purposes of this Policy.
        </div>
        <br>
        <br>
        <span class="text-h6"><strong> 1.3 THIRD PARTY SERVICES</strong></span>
        <br>
        <br>

        <div class="paragraph">You may provide us with Your user identifier information regarding, or to enable Your usage of, certain third party services (together with a mapped photo where applicable) e.g. social networking services and payment services, in order for the Services to interoperate with such third party services. You may use such third party services to create Your user profile or log in to our Services, share information with such third party services, or to connect Your user profile with the respective third party services. Such third party services may automatically provide us with access to certain personal information retained by them about You (e.g., your payment handle, unique identification information, content viewed by You, content liked by You and information about the advertisements You have been shown or may have clicked on) and You agree that we may collect, use and retain the information provided by these third party services in accordance with this Privacy Policy. You may be able to control the personal information You allow us to have access to through the privacy settings on the respective third party service. We will never store any passwords created for any third party services.

        </div>
        <br>
        <br>
        <span class="text-h6"><strong> 1.4 CONTACT INFORMATION</strong></span>
        <br>
        <br>

        <div class="paragraph">Where the Encore Telecaller mobile applications (“<strong>Encore Telecaller Apps</strong>”) are obtained from other sources than Apple App Store or Google Play, You may share the names, numbers, Google ID’s and email addresses contained in Your address book (“<strong>Contact Information</strong>”) with Encore Telecaller by enabling the Encore Telecaller Enhanced Search Functionality. Where the Encore Telecaller Apps are obtained from Apple App Store or Google Play, we do not share any user Contact Information. In addition to Contact Information, if You choose to activate use of a third party service, such as social networks services, within the Services, Encore Telecaller may collect, store and use the list of identifiers associated with said services linked to the Contact Information in order to enhance the results shared with other Users.
            <br><br>

            Please note that no other contact information other than the phone numbers and thereto attached names, Google ID’s and email addresses will be collected and used from Your address book. Other numbers or information that may be contained in Your address book will be filtered away by our safety algorithms and will therefore not be collected by Encore Telecaller. Please also note that You can always choose not to share Contact Information with Encore Telecaller and if You have shared such information and changed Your mind, You can delist Your number or opt-out to render Your entire Contact Information unavailable for search in the Encore Telecaller database. </div>

        <br>
        <br>
        <span class="text-h6"><strong> 1.5 OTHER INFORMATION YOU MAY PROVIDE</strong></span>
        <br>
        <br>

        <div class="paragraph">You may provide us with various information in connection with Your interaction with our Services. For example, You may through tagging functionality associate a phone number, that is not registered in the Encore Telecaller database or belongs to a User, with a business or name and You may report a phone number as spam or some other attribute to be included in spam blocking directories. Encore Telecaller may also from time to time offer You the opportunity to provide information on Your experience from using the Services or to participate in contests, surveys or other promotions. Encore Telecaller will collect the information You provide in connection therewith, as well as any other information You provide through the Services or when You communicate or interact with us.
        </div>
        <br>
        <br>
        <span class="text-h5" style="text-transform: uppercase;"><strong>2. USE OF PERSONAL INFORMATION</strong></span>
        <br>
        <br>
        <span class="text-h6"><strong> 2.1 PROVIDE, IMPROVE AND PERSONALIZE OUR SERVICES</strong></span>
        <br>
        <br>

        <div class="paragraph">Encore Telecaller may use the personal information collected to provide, maintain, improve, analyze and personalize the Services to its Users, partners and third party providers. More specifically, Encore Telecaller may use such information to:
            <br><br>
            <ul><strong>a.</strong> provide smart caller ID, dialer and messaging functionality that, among other features, allow for population of unidentified numbers and other data in call or message logs, display the name associated with a certain number for incoming or outgoing calls and messages or following a manual number search against the Encore Telecaller database;
            </ul>
            <ul><strong>b.</strong> display the number associated with a certain name following a manual name search against the Encore Telecaller database, subject to that name searches will only produce a number linked to the name if the number is available for search in a public database or relates to a User who has made his or her name available for search, provide Your information to the person You reach out to when requesting a contact via the Encore Telecaller name search service, in which case Your request will be forwarded to that person via an SMS and he/she may choose whether or not to share the phone number with You at his/her own discretion;
            </ul>
            <ul><strong>c.</strong> display information based on connections a User may have in common, directly or indirectly, with other Users via a “social graph” algorithm, display the “who viewed my profile”, "who searched for me" and “availability” functionalities of the Services;
            </ul>
            <ul><strong>d.</strong> send in application push notifications and reminders and deliver messages via the Services, in which case Your messages will be kept for a limited period of time in order to deliver the messages but Encore Telecaller will not, unless specifically stated, monitor the content of Your messages;
            </ul>
            <ul><strong>e.</strong> maintain User spam lists and build a community based spam blocking directory;
            </ul>
            <ul><strong>f.</strong>enable You to use and share Your information in connection with Your registration, login or other use of third party services e.g. payment service providers, online services, social networking sites and other third party API’s; and
            </ul>
            <ul><strong>g.</strong> otherwise improve our Services, business and operations.
            </ul>
        </div>

        <span class="text-h6"><strong> 2.2 STATISTICAL DATA FROM THE SERVICES</strong></span>
        <br>
        <br>

        <div class="paragraph">We use aggregated or anonymized personal information for statistical and analytical purposes. We may come to share such data with third parties.
            <br><br>
            We do not consider personal information to include information that has been made anonymous or aggregated so that it can no longer be used to identify a specific person, whether in combination with other information or otherwise.
        </div>
        <br><br>
        <span class="text-h6"><strong>2.3 PERSONALIZE OUR ADVERTISING AND COMMUNICATIONS</strong></span>
        <br>
        <br>

        <div class="paragraph">We may use any of the information collected, as set out above, to provide You with location and interest based advertising, marketing messaging, information and services. We may also use the collected information to measure the performance of our advertising and marketing services.

            <br><br>
            We may contact You for verification purposes or with information pertaining to the Services or special offers, e.g. newsletter e-mails, SMS and similar notifications about Encore Telecaller’s and our business partners’ products and services. We also use the collected information to respond to you when you contact us. </div>

        <br><br>
        <span class="text-h6"><strong>2.4 COMPLIANCE WITH LAWS AND PREVENTION OF FRAUDULENT OR ILLEGAL ACTIVITIES</strong></span>
        <br>
        <br>

        <div class="paragraph">We may use the collected information to comply with applicable laws and to enforce our agreements and protect and defend the rights or safety of Encore Telecaller, its Users or any other person and verify provided User profile information with third party providers and ensure technical service functionality and data accuracy, perform trouble-shooting and prevent or detect fraud, security breaches or illegal activities.
        </div>

        <br>
        <br>
        <span class="text-h5" style="text-transform: uppercase;"><strong>3. SHARING AND DISCLOSURE OF PERSONAL INFORMATION</strong></span>
        <br>
        <br>
    <div class="paragraph">
        In addition to the sharing and disclosure of personal information that is included as part of the functionality of the Services as described in section 2 above, Encore Telecaller may disclose personal information if we believe such action is necessary to: <br>
        <ul>
            <strong>a.</strong>comply with the law, or legal process served on us;
        </ul>
        <ul>
            <strong>b.</strong>protect and defend our rights and the enforcement of our agreements; or
        </ul>
        <ul>
            <strong>c.</strong>protect the security and safety of Users or members of the public or other aspects of public importance, provided, of course, that such disclosure is lawful.
        </ul>
        <br>
        <br>
        We transfer information to trusted vendors, service providers, and other partners who support our business and Services, such as providing technical infrastructure services, bug testing, analyzing how our Services are used, measuring the effectiveness of ads and services and facilitating payments as well as potential partners who may wish to work with us to provide other services. Encore Telecaller will always require these third parties to take appropriate organizational and technical measures to protect personal information and to observe applicable legislation.
        <br>
        <br>
        Encore Telecaller may also share personal information with third party advertisers, agencies and networks. Such third parties may use this information for analytical and marketing purposes e.g. to provide measurement services and targeted ads and for improvement of products and services. The information may be collected by such third parties by use of cookies, or similar technologies, and You can learn more about the use of cookies in our <strong style="color:#1976D2" @click="cookie()">Cookie Policy.</strong>
        <br>
        <br>
        We may disclose and transfer Your Information to our associated or affiliated organisations or related entities and to any third party who acquires, our business, whether such acquisition is by way of merger, consolidation or purchase of all or a substantial portion of our assets.
    </div>
        <br>
        <br>
        <span class="text-h5" style="text-transform: uppercase;"><strong>4. DELISTING OF CONTACT INFORMATION AND OPT-OUT OF AD TARGETING</strong></span>
        <br>
        <br>
        <div class="paragraph">
        If a User chooses to disable the Enhanced Search Functionality, the Contact Information made available by that User is disabled and will thereafter not be available for search in the Encore Telecaller database. If any persons do not wish to have their names and phone numbers made available through the Enhanced Search or Name Search functionalities, they can exclude themselves from further queries by notifying Encore Telecaller via its website at https:\\encore.in or as set forth in the contact details below. You acknowledge and agree that Encore Telecaller may keep and process personal information related to such request in order to be able to honor the request.
        <br>
        <br>
        You can limit or opt-out of the collection and use of Your information for ad targeting by third parties via Your device settings.
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>5. INFORMATION SECURITY AND TRANSFER OF PERSONAL INFORMATION TO OTHER COUNTRIES</strong></span>
        <br>
        <br>
        <div class="paragraph">
        In order to provide the Services, Encore Telecaller will transfer, process and store personal information in a number of countries, including but not limited to India, and may also use cloud based services for this purpose. Encore Telecaller may also subcontract storage or processing of Your information to third parties located in countries other than Your home country. Information collected within one country may, for example, be transferred to and processed in another country, which may not provide the same level of protection for personal data as the country in which it was collected. You acknowledge and agree that Encore Telecaller may transfer Your personal information as described above for purposes consistent with this Privacy Policy. We take all reasonable precautions to protect personal information from misuse, loss and unauthorized access. Encore Telecaller has implemented physical, electronic, and procedural safeguards in order to protect the information, including that the information will be stored on secured servers and protected by secured networks to which access is limited to a few authorized employees and personnel. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>6. ACCESSING AND UPDATING PERSONAL INFORMATION</strong></span>
        <br>
        <br>
        <div class="paragraph">
        Encore Telecaller may on its own initiative, or at Your request, replenish, rectify or erase any incomplete, inaccurate or outdated personal information retained by Encore Telecaller in connection with the operation of the Services. When required by applicable law, You have the right to know what personal information is stored about You and to have any such information corrected or deleted on Your request. See contact details below.
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>7. MINORS</strong></span>
        <br>
        <br>
        <div class="paragraph">
        Services are not intended for or designed to attract anyone under the relevant age of consent to enter into binding legal contracts under the laws of their respective jurisdictions. Encore Telecaller does not intentionally or knowingly collect personal information through the Services from anyone under that age. We encourage parents and guardians to be involved in the online activities of their children to ensure that no personal information is collected from a child without their consent.
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>8. CHANGES TO THIS PRIVACY POLICY</strong></span>
        <br>
        <br>
        <div class="paragraph">
        Encore Telecaller may at any time with or without a separate notice change this Privacy Policy, and You are encouraged to review this Policy from time to time. In case of substantial changes, Encore Telecaller will notify the Users by push notice or via notice in the Services. Your continued use of the Services after a notice of changes has been communicated to You or published on our Services shall constitute consent to the changed policy.
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>9. COOKIES</strong></span>
        <br>
        <br>
        <div class="paragraph">
        As described in our <strong style="color:#1976D2" @click="cookie()">Cookie Policy,</strong> we use cookies, web beacons, flash cookies, HTML 5 cookies, pixel tags and other web application software methods, including mobile application identifiers, to help us recognize you across the Services, learn about your interests both on and off the Services, improve your experience, increase security, measure use and effectiveness of the Services, and serve advertising. You can control Cookies through your browser settings and other tools. By interacting with the Services, you consent to the use and placement of Cookies on your device in accordance with this Privacy Policy, which by reference incorporates our <strong @click="cookie()" style="color:#1976D2">Cookie Policy.</strong>
        <br>
        <br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>10. CONTACT</strong></span>
        <br>
        <br>
        <div class="paragraph">
        If You have any additional questions about Encore Telecaller’s Privacy Policy or want to make a request regarding certain personal information, You are encouraged to contact Encore Telecaller. The contact information is:
        <br>
        <br>
        Mathy ExamsDaily private ltd, By pass road, kalavasal, near AV mahal, Madurai-625016.
        <br>
        <br>
        You can also send Your enquiries (including in relation to any grievances to our Grievances Officer) via <strong style="color:#1976D2">email to <a>encore@examsdaily.in</a></strong>
        <br>
        <br>
        Last updated: 3 june 2023
        </div>
        <br>
        <br><br>
        <br><br>
        <br>
    </div>
</div>
</template>

<script>
export default ({
    data() {
        return {

        }
    },
    methods:{
        service(){
            this.$router.push('/terms');
        },
        cookie(){
            this.$router.push('/cookies');

        }
    }
})
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.bg {
    background-color: rgb(240, 252, 254);
    
  }

.content {
    margin-top: 30%;
}

.paragraph {
   color:rgb(96, 93, 93);
}

.tab-style {
    background-color: white;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: 'Noto Sans Avestan', sans-serif;
    font-weight: 200;
    font-size: 25px;
    color: #3c7ef7;
}

.fiesta {
    font-family: "Pacifico", cursive;

    position: relative;
    top: 1px;
    right: 6px;
    font-size: 27px;
    color: #3c7ef7;
}

</style>
