import axios from 'axios';
export default{
    putSource: function(source){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/source/insert", source, config)
                .then(response => {             
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
	},
    getAllAdminSources: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/getAll',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                       
        
    },
    getAllSources: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/getAllUnarchive',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                       
        
    },
    searchSource: function(keyword){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/search/' + keyword,
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                       
        
    },    
    getSource: function(sourceid){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/get/' + sourceid,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    },
    getSearchCount: function(keyword) {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/search/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/source/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    updateSource: function(updatesource){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/source/update", updatesource, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    deleteSource: function(sourceid){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/delete/' + sourceid,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    },
    archive: function(source){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/source/archive/"+ source, config)
                .then(response => {
                    resolve(response);
                })
                
                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function(source){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/source/unarchive/"+ source, config)
                .then(response => {
                    resolve(response);
                })
                
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCount: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/source/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/source/all/'+ (currentPage-1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
}