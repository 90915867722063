import axios from 'axios';
// var restServerLocation = 'https://api-encore.examsdaily.in';
// // var restServerLocation = 'http://localhost:8088';

export default{
    // ==========THIS METHOD FIND THE NUM OF REQUEST IS AVAILABLE=============
    getRequestCount(officeId){
        // var ax = axios.create({
        //     baseURL: restServerLocation,
        //   });
      
          let config = {
              headers: {
                  "Content-Type": "application/json",
                  'tenant-uuid': localStorage.getItem("tenant-uuid"),
              }
          };
      
        return new Promise((resolve, reject) => {
            axios.post("/leave/count/"+officeId,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
        // var ax = axios.create({
        //     baseURL:restServerLocation,
        //     headers: {
        //         "Content-Type": "application/json",
        //         'tenant-uuid': localStorage.getItem("tenant-uuid"),
        //     }            
        // }); 
        // return new Promise((resolve, reject) => {
        //     ax ({
        //         method: 'post',
        //         url: "leave/count",
        //         data: officeId         
        //     }).then((response) => {
        //         // console.log("=====> Location Service ", JSON.stringify(response));
        //         resolve(response);
        //     }).catch((err) => {
        //         // console.log("=====> Location Service err", JSON.stringify(err));
        //         reject(err);
        //     });
        // });
    },
    // ============THIS METHOD USED TO GET THE REQUEST BASED ON THE PAGINATION=============
    GetAllPageable(currentPage, pageCount, officeId){
        // var ax = axios.create({
        //     baseURL: restServerLocation,
        //   });
      
          let config = {
              headers: {
                  "Content-Type": "application/json",
                  'tenant-uuid': localStorage.getItem("tenant-uuid"),
              }
          };
      
        return new Promise((resolve, reject) => {
            axios.get("/leave/all/" + (currentPage-1) +"/"+ pageCount+"/"+ officeId,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    //=====================DECLINE THE LEAVE REQUEST ==========

    DeclineLeaveRequest(requestId, rejectedReason)                          {
        // var ax = axios.create({
        //     baseURL: restServerLocation,
        // });

        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': localStorage.getItem("tenant-uuid"),
            }
        };
        return new Promise((resolve, reject) => {
            axios.put("/leave/reject/"+requestId+"/"+rejectedReason, config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        }); 
    },

        //===================== ACCEPT THE LEAVE REQUEST ==========
        ApprovedRequest(selectedRow) {
            // var ax = axios.create({
            //     baseURL: restServerLocation,
            // });

            let config = {
                headers: {
                    "Content-Type": "application/json",
                    'tenant-uuid': localStorage.getItem("tenant-uuid"),
                }
            };

            return new Promise((resolve, reject) => {
                axios.put("/leave/approved", selectedRow, config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
            }); 
        },

        // ============GET REQUEST COUNT OF TENANT========

        GetAllRequestCount(){
            // var ax = axios.create({
            //     baseURL: restServerLocation,
            //   });
          
              let config = {
                  headers: {
                      "Content-Type": "application/json",
                      'tenant-uuid': localStorage.getItem("tenant-uuid"),
                  }
              };
          
            return new Promise((resolve, reject) => {
                axios.get("/leave/countRequest/",config)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },

        // ============GET ALL PENDING REQUEST FROM TENANT========

        GetAllPendingRequest() {
            // var ax = axios.create({
            //     baseURL: restServerLocation,
            // })

            let config = {
                headers: {
                    "Content-Type": "application/json",
                    'tenant-uuid': localStorage.getItem("tenant-uuid"),
                }
            };
            
            return new Promise((resolve, reject) => {
                axios.get("/leave/allRequestFromTenant",config)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
            });
        }
}