<template>
<div>
    <div id="bar">
        <p class="auto">
            <!-- <q-icon name="place" style="font-size: 32px;"  :class="$q.platform.is.mobile?'q-mr-md':''"/> -->
            <b-form-input type="text" id="autoc" v-model="localities" />
        </p>
        <div id="map" style="z-index:9999999999;"></div>
    </div>
</div>
</template>



<script>
export default {
    data() {
        return {
            localities: "",
        }
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize: function () {
            var self = this;
            var mapOptions = {
                // zoom: 17,
                center: {
                    lat: -33.8667,
                    lng: 151.1955,
                }
            };
            this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
                document.getElementById('bar'));
            var autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autoc'));
            autocomplete.bindTo('bounds', this.map);
            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();
                console.log(place.formatted_address, "place=====compnoet============");
                const add = place.formatted_address;
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                // self.coordinatLat = latitude;
                // self.coordinatLong = longitude;
                // self.address = place.formatted_address;
                console.log(latitude);
                console.log(longitude);
                const dataToSend = {
                    address: add,
                    lat: latitude,
                    long: longitude
                }
                self.$emit('data-emitted', dataToSend);
                // self.addOfficeDialog();
                // self.searchItem = null;
            });
        },
    }
}
</script>


<style>

    </style>
