import axios from 'axios';
export default {

    getAllCalls: function () {
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/admin/calls',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCallCount: function () {
        return new Promise((resolve, reject) => {
            axios.get('/admin/callCount')
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCallPageable: function (currentPage, pageCount) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/callAll/' + (currentPage - 1) + "/" + pageCount)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    searchCall: function (keyword) {
        return new Promise((resolve, reject) => {
            axios.get('/admin/searchCall/' + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    // ======CALL SORTING START======

    getCallCourse: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/call/sortCourse", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCallSource: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/call/sortSource", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCallBranch: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/call/sortBranch", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCallAgent: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/call/sortAgent", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // ======CALL SORTING END======

    //sort call pagination
    getCourseCount: function (course) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/courseSort/count', course)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCoursePageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/courseSort/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getSourceCount: function (source) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/sourceSort/count', source)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllSourcePageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/sourceSort/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getBranchCount: function (branch) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/branchSort/count', branch)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllBranchPageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/branchSort/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAgentCount: function (agent) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/agentSort/count', agent)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllAgentPageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/agentSort/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    //Call search pagination
    getCallSearchCount: function (keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/search/count/' + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCallSearchPageable: function (currentPage, pageCount, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/search/' + (currentPage - 1) + "/" + pageCount + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    //==================================ADMIN CLOSED CALL========================================================

    getAllClosedCalls: function () {
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/admin/closedCalls',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getClosedCallCount: function () {
        return new Promise((resolve, reject) => {
            axios.get('/admin/closedCallCount')
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedCallPageable: function (closedCurrentPage, closedPageCount) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCallAll/' + (closedCurrentPage - 1) + "/" + closedPageCount)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    searchClosedCall: function (ClosedKeyword) {
        return new Promise((resolve, reject) => {
            axios.get('/admin/searchClosedCall/' + ClosedKeyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    // ======CALL SORTING START======

    getClosedCallCourse: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/closedCall/sortCourse", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getClosedCallSource: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/closedCall/sortSource", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getClosedCallBranch: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/closedCall/sortBranch", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getClosedCallAgent: function (name) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        let data = {
            name: name
        }
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/closedCall/sortAgent", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // ======CALL SORTING END======

    //sort call pagination
    getClosedCourseCount: function (course) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/courseSort/count', course)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedCoursePageable: function (closedCurrentPage, closedPageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/courseSort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getClosedSourceCount: function (source) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/sourceSort/count', source)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedSourcePageable: function (closedCurrentPage, closedPageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/sourceSort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getClosedBranchCount: function (branch) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/branchSort/count', branch)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedBranchPageable: function (closedCurrentPage, closedPageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/branchSort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getClosedAgentCount: function (agent) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/agentSort/count', agent)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedAgentPageable: function (closedCurrentPage, closedPageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/agentSort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    //Call search pagination
    getClosedCallSearchCount: function (keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/search/count/' + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedCallSearchPageable: function (closedCurrentPage, closedPageCount, closedKeyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/search/' + (closedCurrentPage - 1) + "/" + closedPageCount + "/" + closedKeyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getByDate: function (selectedDates) {
        return new Promise((resolve, reject) => {
            axios
                .post('/admin/call/sort/date/' + selectedDates)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getDateCount: function (data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/sortDate/count', data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllDatePageable: function (currentPage, pageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/sortDate/all/' + (currentPage - 1) + "/" + pageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // getDateCount: function (selectedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/admin/call/dateSort/count/' +  selectedDates)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllDatePageable: function (currentPage, pageCount,selectedDates, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/admin/call/dateSort/all/' + (currentPage - 1) + "/" + pageCount  + "/" +selectedDates, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    getByClosedDate: function (selectedClosedDates) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/call/sort/closedDate/' + selectedClosedDates)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getDateClosedCount: function (data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/sortDate/count', data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedDatePageable: function (closedCurrentPage, closedPageCount, data) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/closedCall/sortDate/all/' + (closedCurrentPage - 1) + "/" + closedPageCount, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // getDateClosedCount: function (selectedClosedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .get('/admin/call/dateSort/sortClosedDate/'  +selectedClosedDates)
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllClosedDatePageable: function (closedCurrentPage, closedPageCount,selectedClosedDates, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/admin/call/closed/dateSort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount + "/" +selectedClosedDates, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },



    //==================================ADMIN ARCHIVED CALL========================================================


    getArchivedCallCount: function () {
        return new Promise((resolve, reject) => {
            axios.get('/admin/archivedCallCount')
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getAllArchivedCallPageable: function (archivedCurrentPage, archivedPageCount) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/archivedCallAll/' + (archivedCurrentPage - 1) + "/" + archivedPageCount)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getArchivedCallSearchCount: function (archivedKeyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/archivedCall/search/count/' + archivedKeyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllArchivedCallSearchPageable: function (archivedCurrentPage, archivedPageCount, archivedKeyword) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/archivedCall/search/' + (archivedCurrentPage - 1) + "/" + archivedPageCount + "/" + archivedKeyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

}