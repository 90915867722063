<template>
<div>
    <div class="login-screen"></div>
    <b-container v-if="!invalidToken" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="5">
            </b-col>
            <b-col style="margin-top: 5%;">
                <div class="login align-self-center" style="background-color: #ffffff; padding: 2vh; border-radius: 10px; opacity: .9;">
                    <form @submit.prevent="changePassword">
                        <div class="text-center">
                            <br>
                            <img src="encore.svg" width="56" />
                            <br><br>
                        </div>
                        <div class="text-center">
                            <b>
                                <p style="color: royalblue">Change Password</p>
                            </b>
                            <div>
                                <p style="color:#a4a4a4;">{{username}} </p>
                            </div><br>
                            <input class="form-control input" type="password" autofocus v-model="user.password" v-bind:class="{error: $v.user.password.$error, valid: $v.user.password.$dirty && !$v.user.password.$invalid}" placeholder="Password">
                            <div v-if="$v.user.$dirty">
                                <p class="error-message" v-if="!$v.user.password.required">Field is required</p>
                                <p class="error-message" v-if="!$v.user.password.minLength">Must have 6 Characters</p>
                            </div>
                            <input class="form-control input" type="password" autofocus v-model="user.confirmPassword" placeholder="Confirm Password" v-bind:class="{error: $v.user.confirmPassword.$error, valid: $v.user.confirmPassword.$dirty && !$v.user.confirmPassword.$invalid}">
                            <div v-if="$v.user.$dirty">
                                <p class="error-message" v-if="!$v.user.confirmPassword.sameAsPassword">Passwords must be identical.</p>
                            </div>
                            <br>
                            <b-button autofocus class="btn-sm btn-block login-btn" type="submit">
                                Change Password
                            </b-button>
                        </div>
                    </form>
                </div>
            </b-col>
            <b-col cols="5">
            </b-col>
        </b-row>
    </b-container>

    <b-container v-if="invalidToken" fluid style="margin-top:20px;">
        <b-row>
            <b-col cols="3">
            </b-col>
            <b-col style="margin-top: 10%;">
                <div class="text-center border border-info shadow" style="background-color: #ffffff; padding: 4vh; border-radius: 10px; opacity: .9;">
                    <i class="fa fa-exclamation-circle fa-4x" style="color:orange;" aria-hidden="true"></i><br><br><span class="text-primary" style="font-weight:bold;font-size:1.2em;">Invalid Password Reset Link</span><br><br>
                    <span class="text-secondary" style="font-weight:bold;font-size:1.2em;">The Link you are trying to access does not exist.</span>
                    <div class="text-center" style="padding-top:2em;">
                        <b-btn @click="$router.push('/')" variant="success">Home</b-btn>
                    </div>
                </div>
            </b-col>
            <b-col cols="3">
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import {
    required,
    sameAs,
    minLength
} from 'vuelidate/lib/validators'
import Account from "../service/Account";
// import Swal from "sweetalert2";

export default {
    data: function () {
        return {
            toastShow: false,
            invalidToken: false,
            oldToken: false,
            user: {
                password: '',
                confirmPassword: '',
                token: ""
            },
            username: ''
        }
    },
    validations: {
        user: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                sameAsPassword: sameAs('password')
            }
        }
    },
    async mounted() {
        this.user.token = this.$route.query.token;
        await this.getContactByToken();
    },
    methods: {
        getContactByToken: function () {
            return new Promise((resolve, reject) => {
                let data = this.user;
                Account.getContactByToken(data)
                    .then(response => {
                        if (response.status == 206) {
                            this.invalidToken = true;
                        } else {
                            this.username = response.data.username;
                        }
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        changePassword: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log("error");
            } else {
                let data = this.user;
                return new Promise((resolve, reject) => {
                    Account.changePassword(data)
                        .then(response => {
                            this.$router.replace(this.$route.query.redirect || "/");
                            this.$swal({
                                title: "Password Changed successfully",
                                type: "success",
                            });
                            resolve(response);
                        })
                        .catch(err => {
                            reject(err);
                            console.log(err);
                        });
                });
            }
        }
    }
}
</script>

<style scoped>
.input {
    font-size: 15px;
    margin: 15px 0;
    padding: 10px 20px;
    border-color: none;
    border-radius: 25px;
    background-color: #eee;
}

.login-screen {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.login-screen:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.login-btn {

    background-color: #0766EB;

    color: #ffff;
    border-radius: 25px;
    border-color: #0000;
    font-weight: bold;
    font-size: 18px;
}

.login {
    width: 320px;
    color: #fff;
    border: 2px solid royalblue;
}

.btn-secondary:hover {
    color: #ffff;
    background: #0766EB;
    border-color: #0000;
}

.Blink {
    animation: blinker 0.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.error {
    border-color: red;
}

.error-focus {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 13px;
    margin: 5px 0 0 5px;
}
</style>
