<template>
<div class="container">
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- <div class="d-flex flex-row-reverse m-3">
            <span style="color: #0974ff; cursor: pointer" >
                <font-awesome-icon icon="sync-alt" />Refresh</span>
        </div> -->

    <div class="fixed-bottom">
        <div class="d-flex flex-row-reverse">
            <button class="btn btn-primary rounded-circle floating-button" @click="toggleOptions">
                <span class="button-text">
                    <span>Switch call fields</span>
                </span>
            </button>

            <div class="button-group " v-if="showOptions">
                <button class="btn btn-primary rounded-circle sub-button mx-2" v-if="currentPath!='customfield'" v-b-tooltip.hover title="Call Custom Fields" @click="handleCall">
                    <i class="fas fa-phone"></i>
                </button>
                <button class="btn btn-primary rounded-circle sub-button" v-if="currentPath!='followupcustomfield'" v-b-tooltip.hover title="Followup Custom Fields" @click="handleFollowup">
                    <i class="fas fa-calendar"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body partition">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="card-title "> Add Custom Fields - Follow-up</span>
                        <b-button variant="primary">
                            <font-awesome-icon icon="sync-alt" @click="refresh()" />
                        </b-button>

                    </div>
                </div>
            </div>
            <div class="card field-card mt-2">
                <div class="card-body side-part">

                    <b-modal id="picklistModal" hide-header hide-footer centered v-model="modalOpen">
                        <div class="modal-header">

                            <div class="">
                                <b-button v-if=" picklistOptions.length > 0" class="btn btn-success" @click="savePicklistOptions()">Save</b-button>
                            </div>

                        </div>
                        <b-card class="mobile-app-card" :class="{ 'creative-background': picklistOptions.length == 0 }">

                            <div>
                                <div v-if="picklistOptions.length === 0" style="font-size: 20px; color: rgb(98, 98, 98); font-weight: 300; display: flex; align-items: center; justify-content: center; margin-top: 40%;">
                                    Add new options here!
                                    <span> <img src="/hand.png" style="" alt="" /></span>
                                </div>

                                <div class="">
                                    <div v-for="(option, index) in picklistOptions" :key="option" class="row mt-1" :class="{'odd-option': index % 2 === 0, 'even-option': index % 2 !== 0}">
                                        <div class="col-11 mt-2">{{ option }} </div>

                                        <span class="col-1 btn btn-danger text-end" @click="deletePicklistOption(index)">
                                            <i class="fas fa-trash-alt"></i>
                                        </span>

                                    </div>
                                </div>

                            </div>

                        </b-card>
                        <div class="input-group row mt-3 px-3">
                            <input class="form-control col-11" placeholder="New Option" v-model="newPicklistOption" />
                            <div class="col-1" v-if="newPicklistOption.length > 0">

                                <i class="fas fa-check" @click="addPicklistOption" style="color: green; position: relative; top: 10px;"></i>

                            </div>
                        </div>

                    </b-modal>

                    <div class="mt-5" style="text-align: left;">
                        <!-- <span style="font-weight: bold; font-size: 22px;">Add More Fields</span> -->

                        <div>
                            <div class="form-group ">
                                <label for="display-name-input" style=" color: rgb(93, 90, 90);">Display Name</label>
                                <input type="text" class="form-control input-field" id="display-name-input" v-model="newFieldDisplayName" required>
                            </div>

                            <div class="form-group mt-3">
                                <label for="field-name-input" style=" color: rgb(93, 90, 90);">Field Name</label>
                                <input type="text" class="form-control input-field" id="field-name-input" v-model="newFieldNameValue" required>
                            </div>

                            <div class="form-group mt-3">
                                <label for="field-type-select" style="color: rgb(93, 90, 90);">Field Type</label>
                                <div class="dropdown">
                                    <select class="form-select input-field custom-select" id="field-type-select" v-model="newFieldType">
                                        <option v-for="(type, index) in fieldTypes" :key="index" :value="type.value">{{ type.label }}</option>
                                    </select>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary mt-5" @click="addFieldFollowup">Add Field</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-6 p-2">
            <!-- <div class="card border-dark mx-auto mobile mobile-scroll">
                    <div style="background-color: rgb(18, 17, 17);">
                        <font-awesome-icon icon="circle" style="color: rgb(144, 142, 142); font-size: 10px;" />
                        <b-btn class="mx-3" style="width: 3vw; height: 1vh;"></b-btn>
                        <font-awesome-icon icon="circle" style="color: rgb(150, 147, 147);font-size: 10px;" />
                    </div>
                    <div style="background-color: white;">
                    </div> -->

            <div class="phone mx-auto ">

                <div class="phone-border">
                    <div class="phone-top">
                        <div class="phone-camera"></div>
                        <div class="phone-speaker"></div>

                    </div>
                    <div class="phone-screen">
                        <div class="phone-content">

                            <div class=" addfields">
                                <div class="p-2 header" style="background-color: #0974ff; color: white; position: sticky; top: 0;">
                                    <font-awesome-icon style="position: relative; right: 45px;" class="burger-icon" icon="bars" />
                                    <b>Add Follow-up Details</b>
                                </div>
                                <div class="mx-2">
                                    <span class="newfield mt-3">Default Fields :</span>

                                    <div class="d-flex flex-row-reverse" @click="toggleEditMode()" v-if="fields.length>0 && editMode==false">
                                        <font-awesome-icon icon="edit" style="position: relative; color:black;top: -25px; right: 5px;" />
                                    </div>
                                    <div v-if="editMode==true" @click="toggleEditMode()" class="d-flex flex-row-reverse">
                                        <font-awesome-icon icon="times-circle" style="position: relative; color:black;top: -25px; right: 5px;" />
                                    </div>

                                    <div class="form-group mt-3" style="text-align: left; ">
                                        <label for="my-input" style=" color: rgb(93, 90, 90); font-size:15px; position: relative; top: 10px;">NAME :</label>
                                        <input type="text" class="form-control input-field" id="my-input">
                                    </div>
                                    <div class="form-group mt-2" style="text-align: left; ">
                                        <label for="my-input" style=" color: rgb(93, 90, 90); font-size:15px;position: relative; top: 10px;">CONTACT :</label>
                                        <input type="number" class="form-control input-field" id="my-input">
                                    </div>

                                    <span class="newfield mt-3">Added Fields Preview :</span>

                                    <!-------------------------------------------added fields -------------------------------- -->

                                    <div v-for="(field, index) in fields" :key="index" class="mt-3 row">

                                        <div v-if="field.type === 'text'" class="form-group col-11" style="text-align: left; ">
                                            <label style=" color: rgb(93, 90, 90); font-size:15px; position: relative; top: 10px;">{{ field.name }} :</label>
                                            <input type="text" class="form-control input-field" v-model="field.value" />
                                        </div>

                                        <div v-if="field.type === 'multi_text'" class="form-group col-11" style="text-align: left; ">
                                            <label style=" color: rgb(93, 90, 90); font-size:15px; ">{{ field.name }} :</label>
                                            <textarea class="form-control" id="comment-input" rows="4" v-model="field.value"></textarea>
                                        </div>
                                        <div v-if="field.type === 'number'" class="form-group col-11" style="text-align: left;">
                                            <label style="color: rgb(93, 90, 90); font-size:15px; position: relative; top: 10px;">{{ field.name }} :</label>
                                            <input type="number" class="form-control input-field" v-model="field.value" />
                                        </div>
                                        <div v-if="field.type === 'radio'" class="form-group col-11" style="text-align: left;">
                                            <label style="color: rgb(93, 90, 90); font-size:15px; position: relative; top: 10px;">{{ field.name }}:</label>
                                            <div class="form-check" v-for="(option, optionIndex) in field.options" :key="optionIndex">
                                                <b-form-radio-group type="radio" class="form-check-input" v-model="field.value" :options="field.options" />
                                                <label class="form-check-label" :for="'option-' + optionIndex">{{ option }}</label>
                                            </div>
                                        </div>

                                        <div v-if="field.type === 'boolean'" class="form-group col-11 mt-4" style="text-align: left;">
                                            <div class="form-check">
                                                <label class="form-check-label" style="color: rgb(93, 90, 90); font-size:15px;">{{ field.name }}</label>
                                                <input type="checkbox" class="form-check-input" v-model="field.value" />
                                            </div>
                                        </div>
                                        <div v-if="field.type === 'picklist'" class="form-group col-11" style="text-align: left;">
                                            <label style="color: rgb(93, 90, 90); font-size:15px; position: relative; top: 10px;">{{ field.name }}:</label>
                                            <b-form-select class="form-control custom-select input-field" v-model="field.value" :options="field.options">
                                            </b-form-select>
                                            <div class="">
                                                <button class="btn btn-primary mt-2 " v-b-modal.picklistModal @click="openPicklistOptions(field, index)">+</button>

                                            </div>
                                        </div>

                                        <div v-if="field.type === 'date'" class="form-group col-11" style="text-align: left;">
                                            <label style="color: rgb(93, 90, 90); font-size:15px;">{{ field.name }}:</label>
                                            <input type="datetime-local" class="form-control input-field" v-model="field.value" />
                                        </div>

                                        <div v-if="editMode" class="col-1 " @click="removeField(index)">
                                            <font-awesome-icon icon="trash" style="color: rgb(228, 39, 39); position: relative; top:32px; right: 20px;" />

                                        </div>
                                        <span v-else class="d-flex flex-row-reverse col-1">
                                            <i class="fas fa-check " style="color: green; position: relative; top: 30px; right: 5px; "></i>

                                        </span>

                                    </div>
                                    <div class="text-center mt-5 pb-5">
                                        <button v-if="showSubmitButton" type="submit" class="btn btn-success mt-3" @click="saveFollowupCustomFields()">Submit</button>

                                    </div>
                                    <!----------------------------------- added field end--------------------------------------------- -->

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
// import draggable from 'vuedraggable';

import Account from '../service/Account.js';
import Swal from 'sweetalert2';
import FollowupService from '../service/FollowupService.js';

export default ({
    //     components: {
    //     draggable,
    //   },
    setup() {},
    data() {
        return {
            //         mobileData: ['phone'],
            //   dragOptions: {
            //     draggable: '.phone',
            //     handle: '.phone-top',
            //   },
            showSubmitButton: false,
            modalOpen: false,
            showOptions: false,
            selection: true,
            newFieldDisplayName: '',
            newFieldType: '',
            newFieldNameValue: '',
            newPicklistOption: '', // New property to track the value for adding new picklist options
            loading: false,
            fields: [],
            editMode: false,
            fieldTypes: [{
                    label: 'Text',
                    value: 'text'
                },
                {
                    label: 'Multi Text',
                    value: 'multi_text'
                },
                {
                    label: 'Number',
                    value: 'number'
                },
                // {
                //     label: 'Multiple Choice',
                //     value: 'radio'
                // },
                {
                    label: 'Check Box',
                    value: 'boolean'
                },
                {
                    label: 'Drop-down',
                    value: 'picklist'
                },
                {
                    label: 'Date',
                    value: 'date'
                },
            ],
            picklistDialog: false,
            picklistOptions: [],
            selectedPicklistOption: "",
            selectedPicklistIndex: -1,
            allFields: [],
            lastCallTemplateid: '',
            currentPath:''
        }
    },
    // watch: {
    //     fields: {
    //         handler(newFields) {
    //             newFields.forEach((field) => {
    //                 field.name = field.name.toUpperCase();

    //                 if (field.type.value === 'double' && field.value !== '') {
    //                     field.value = parseFloat(field.value).toFixed(2);

    //                 }
    //             });
    //         },
    //         deep: true,
    //     },
    // },
    mounted() {
        this.loading = true;
        const pathSegments = this.$route.path.split('/');
        this.currentPath = pathSegments[pathSegments.length - 1];
        console.log('currentPath', this.currentPath);
        if (localStorage.getItem("agentId") != null && localStorage.getItem("agentId") != "") {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        this.getFollowupFields();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        }
        if (this.fields.length == 0) {
            Swal.fire({
                icon: 'success',

            });
        }
    },
    methods: {
        toggleEditMode() {
            this.editMode = !this.editMode;
            console.log("=====================imode", this.editMode);

        },
        removeField(index) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to delete this field.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',

            }).then((result) => {

                if (result.value) {
                    this.fields.splice(index, 1);
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted',
                        text: 'Field has been successfully deleted.'
                    });

                }
                this.showSubmitButton = true;

            });

        },

        // showAddMoreFields() {
        //     this.customFieldCreate = !this.customFieldCreate;
        // },
        refresh() {
            this.getFollowupFields();
            this.loading = true;

        },

        getFollowupFields() {
            return new Promise((resolve, reject) => {
                FollowupService.getFollowupFields()
                    .then(response => {
                        resolve(response);
                        this.loading = false;

                        console.log("response=============>", JSON.stringify(response.data))
                        if (response.data != null) {
                            this.lastCallTemplateid = response.data.id;
                            this.fields = response.data.fields.map(field => ({
                                name: field.displayName,
                                type: field.dataType,
                                fieldValue: field.name,

                                // value: '',
                                options: field.list,
                            }));

                        }

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        saveFollowupCustomFields() {

            const requestData = {
                lastCallTemplateId: this.lastCallTemplateid,
                fields: this.fields.map(field => ({
                    dataType: field.type,
                    name: field.fieldValue,
                    displayName: field.name,
                    list: field.options,
                }))
            };

            return new Promise((resolve, reject) => {
                FollowupService.saveAllCustomFields(requestData)
                    .then(response => {

                        resolve(response);
                        console.log("response=============>")
                        Swal.fire({
                            icon: 'success',
                            title: 'Applied',
                            text: 'Custom Field Added Successfully!',
                        })
                        this.editMode = false;
                        this.showSubmitButton = false;

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addFieldFollowup() {
            if (this.newFieldDisplayName && this.newFieldType && this.newFieldNameValue) {
                let field = {
                    name: this.newFieldDisplayName.toUpperCase(),
                    type: this.newFieldType, // Wrap the type value in an object
                    fieldValue: this.camelCase(this.newFieldNameValue),
                    // value: ''
                };

                if (this.newFieldType === 'picklist') {
                    field.options = [];
                }
                this.editMode = false;
                this.showSubmitButton = true;
                this.fields.push(field);
                this.newFieldDisplayName = '';
                this.newFieldType = '';
                this.newFieldNameValue = '';
            }
        },
        camelCase(value) {
            return value.replace(/[^a-zA-Z0-9]+(\w)/g, function (_, character) {
                return character.toUpperCase();
            });
        },

        getComponent(type) {
            switch (type) {
                case 'text':

                    return 'q-input';
                case 'boolean':
                    return 'q-checkbox';
                case 'picklist':
                    return 'q-select';
                default:
                    return 'q-input';
            }
        },

        addPicklistOption() {
            const option = this.newPicklistOption;
            console.log("=======>", option);
            if (option) {
                this.picklistOptions.push(option);
                this.newPicklistOption = "";
                this.selectedPicklistOption = '';

            }
        },

        getFieldType(type) {
            return type === "picklist" ? "text" : null;
        },
        deletePicklistOption(index) {
            this.picklistOptions.splice(index, 1);
        },

        openPicklistOptions(field, index) {
            this.selectedPicklistIndex = index;
            this.picklistOptions = field.options || []; // Use field.options array for the specific field
            // this.picklistDialog = true;
        },

        savePicklistOptions() {
            this.fields[this.selectedPicklistIndex].options = this.picklistOptions; // Save options to the field's options array
            this.fields[this.selectedPicklistIndex].value = this.picklistOptions.join(', ');
            this.modalOpen = false;
            this.showSubmitButton = true;
        },

        selectPicklistOption(option) {
            this.fields[this.selectedPicklistIndex].value(option);
            // this.picklistDialog = false;
            // this.selectedPicklistOption = "";
            // this.selectedPicklistIndex = -1;
        },
        toggleOptions() {
            this.showOptions = !this.showOptions;
        },
        handleCall() {
            this.$router.push('/settings/customfield')

        },
        handleFollowup() {
            this.$router.push('/settings/followupcustomfield')
        }

    }
})
</script>
    
    
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Avestan&display=swap');

.partition {
    border-top: 10px solid #007bff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    background-color: #dde8f4;
    font-family: 'Noto Sans Avestan', sans-serif;
    font-size: 30px;
}

.newfield {
    text-align: right;
    display: flex;
    font-size: larger;
    font-weight: 600;
    color: #007bff;
}

.side-part {
    border-left: 6px solid #aaaeb2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
}

.card {
    border-radius: 10px;
}

.field-card {
    min-height: 65vh;
}

.input-field {
    border: none;
    border-bottom: 1px solid #c0c4cc;
    background: transparent;
}

/* .addfields {
        background: #dde8f4;
    } */

.input-field:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: none;
    background: transparent;

}

.mobile-app-card {
    height: 50vh;
    display: flex;
    flex-direction: column;
    border: 0;
}

.mobile-app-card-section {
    flex: 1;
    overflow-y: auto;
}

.mobile-app-card-actions {
    position: sticky;
    bottom: 0;
    display: block;
    width: 80%;
    align-items: right;
    background-color: #fff;
}

.mobile-app-card-actions.sticky-bottom {
    bottom: 0;
    top: unset;
}

.odd-option {
    background-color: lightgray;
}

.even-option {
    background-color: whitesmoke;
}

.creative-background {
    background-color: white;
    overflow: hidden;
}

.creative-background:before {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 200%;
    height: 200%;
    background-color: #b7b8ba;
    opacity: 0;
    transform: rotate(360deg);
    animation: pulseAnimation 4s ease-in-out infinite;
}

@keyframes pulseAnimation {
    0% {
        opacity: 0;
        transform: rotate(360) scale(0);
    }

    50% {
        opacity: 0.5;
        transform: rotate(360) scale(1);
    }

    100% {
        opacity: 0;
        transform: rotate(360) scale(0);
    }
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.phone {
    max-width: 20rem;
    height: 41rem;
    background-color: rgb(35, 33, 33);
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 15px 0 30px rgba(0, 0, 0, 0.3);
}

.phone-border {
    border: 8px solid rgb(35, 33, 33);
    height: 100%;
    box-sizing: border-box;
}

.phone-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: rgb(35, 33, 33);
    z-index: 1;
}

.phone-camera {
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: 40px;
}

.phone-speaker {
    width: 40px;
    margin-right: 40px;

    height: 4px;
    background-color: #fff;
    border-radius: 2px;
}

.phone-screen {
    position: relative;
    width: 100%;
    height: calc(100% - 80px);
    overflow: scroll;
    border-radius: 5px;
}

.phone-screen::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

.phone-content {
    min-height: 100%;
    background-color: #fff;
}

.phone-button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.phone-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0974ff;
    border: none;
    outline: none;
    cursor: pointer;
    /* Add any desired button styling */
}

/* floating butoon============ */
.fixed-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 50px;
}

.floating-button {
    width: 60px;
    height: 60px;
    font-size: 24px;
    animation: button-pulse 1s infinite;

}

@keyframes button-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.sub-button {
    width: 50px;
    height: 50px;
    font-size: 20px;

}

.button-group {
    position: relative;
    bottom: 75px;
}

.button-text {
    position: relative;
    right: 0px;
   
    text-align: center;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
}
</style>
