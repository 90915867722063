<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container class="mt-3">
        <!-- <b-button variant="outline-success" @click="createSyllabus()">Create</b-button> -->
        <b-container>
            <b-row align-v="center">
                <!--add Call button start-->
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="createSyllabus()">Syllabus</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getSyllabusSearchCount(keyword)" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSyllabusSearchCount(keyword)">Search</b-button>
                </b-col>

                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-card class="card mt-1">
            <p>Syllabus</p>
            <hr>
            <b-table responsive hover :items="syllabus" :fields="fields" ref="table">
                <template v-slot:cell(sylabusId)="data">
                    <span class="title"> {{data.item.sylabusId}}</span>
                </template>
                <template v-slot:cell(name)="data">
                    {{data.item.name}}
                </template>
                <template v-slot:cell(code)="data">
                    {{data.item.code}}
                </template>

                <template v-slot:cell(actions)="data">
                    <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Syllabus" @click="editSyllabus(data.item.syllabusId)">
                        <font-awesome-icon icon="edit" />
                    </b-button>
                </template>

            </b-table>
        </b-card>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
export default {
    data() {
        return {
            loading: false,
            paginationSyllabus: true,
            searchPagination: false,
            syllabus: [],
            fields: [{
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'code',
                    label: 'Code'
                },
                {
                    key: 'actions',
                    label: 'Actions'
                },

            ],
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalCount: 0,
            keyword: "",
        }
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.getAllSyllabus();
                    this.getCount();
                    this.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },

      watch: {
    keyword(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.getCount();
      }
    },
      },

    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSyllabus: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/syllabus/getAll"
                    )
                    .then((response) => {
                        this.syllabus = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.paginationSyllabus = true;
            this.searchPagination = false;
            this.keyword = "";
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        "/syllabus/syllabusCount"
                    )
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        "/syllabus/all/" + (currentPage - 1) + "/" + pageCount,
                    )
                    .then((response) => {
                        resolve(response);
                        this.syllabus = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSyllabusSearchCount: function (keyword) {
            this.searchPagination = true;
            this.paginationSyllabus = false;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/syllabus/search/count/" + keyword
                    )
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSyllabusSearchPageable(
                          this.currentPage,
                          this.pageCount,
                          keyword.toLowerCase()
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSyllabusSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/syllabus/search/" + (currentPage - 1) + "/" + pageCount + "/" + keyword
                    )
                    .then((response) => {
                        resolve(response);
                        this.syllabus = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
      this.loading = true;
      if (this.paginationSyllabus) {
        this.getAllPageable(currentPage, pageCount);
      }else if (this.searchPagination) {
        this.getAllSyllabusSearchPageable(currentPage, pageCount, this.keyword);
      }
    },

    refresh: function () {
      this.keyword = "";
      this.getCount();
    },

        createSyllabus() {
            let syllabusId = "create";
            this.$router.push('/settings/editsyllabus/' + syllabusId)
            // this.$router.push('/user/createsyllabus')
        },
        editSyllabus(syllabusId) {
            this.$router.push('/settings/editsyllabus/' + syllabusId)
        }
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

/* #calls:focus-within {
  background-color: #3177d8;
  color: #fff;
} */

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.cellSize {
    max-width: 120px;
}
</style>
