import axios from 'axios';

export default {
	userIdUniqueValidation: function (id) {
		let data = {
			userId : id,
		}
		return new Promise((resolve, reject) => {
			axios.post("employee/userId/unique",data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	teamEmployeeCreate: function (employee) {
		return new Promise((resolve, reject) => {
			axios.post("employee/create",employee)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	updateEmployee: function (employee) {
		return new Promise((resolve, reject) => {
			axios.post("employee/update",employee)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getEmployeeCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("employee/get/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getAllEmployeePageable: function (currentPage,pageCount) {
		return new Promise((resolve, reject) => {
			axios.post("employee/getAll/"+(currentPage - 1)+"/"+pageCount)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getEmployeeSearchCount: function (keyword) {
		return new Promise((resolve, reject) => {
			axios.get("employee/get/search/count/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getAllEmployeeSearchPageable: function (currentPage,pageCount,keyword) {
		return new Promise((resolve, reject) => {
			axios.post("employee/getAll/search/"+(currentPage - 1)+"/"+pageCount+"/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getAllEmployees: function () {
		return new Promise((resolve, reject) => {
			axios.post("employee/getAll")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
}