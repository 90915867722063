import axios from "axios"

export default {

    notintrestedClosed: function (agentId, call) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/insert/' + agentId, call)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    },

    updateStatusClosed(agentId, callId) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/closed/' + agentId + "/" + callId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    },

    getNotintrestedCount: function (agentId) {
        return new Promise((resolve, reject) => {
            axios.get('notintrested/notintrestedCount/' + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getAllNotintrestedPageable: function (currentPage, pageCount, agentId) {
        return new Promise((resolve, reject) => {
            axios.get('notintrested/notintrestedAll/' + (currentPage - 1) + "/" + pageCount + "/" + agentId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getSearchCount: function (agentId, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/search/count/' + agentId + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getAllSearchPageable: function (currentPage, pageCount, agentId, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/search/' + (currentPage - 1) + "/" + pageCount + "/" + agentId + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    updateStatusAsClosed(agentId, notintrested) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/revoke/' + agentId + "/" + notintrested.notintrestedId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    },

    callFollowupUpdate: function (agentId, callId, callFollowup) {
        // console.log(JSON.stringify(callFollowup));
        return new Promise((resolve, reject) => {
            axios.post("/notintrested/followup/update/" + agentId + "/" + callId, callFollowup)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getFollowupDateRemark: function (agentId, callId) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/get/followups/' + agentId + '/' + callId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getEnquiryFollowupDateRemark: function (notintrestedId) {
        return new Promise((resolve, reject) => {
            axios.post('/notintrested/get/notintrestedFollowups/' + notintrestedId)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
}
