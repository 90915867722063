<template>
    <div class="mt-5">
      <b-container >
          <div class="mt-5">
          <b-container>
            <b-row align-v="center">
              <!--add Call button start-->
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  disabled
                  >Add Call</b-button
                >
              </b-col>
              <b-col col sm="12" md="3" lg="3" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  placeholder="Type a name"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"

                  >Search</b-button
                >
              </b-col>

              <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                <b-pagination
                  prev-text="Prev"
                  next-text="Next"
                  align="fill"
                  size="sm"
                  class="my-0 mx-2"

                ></b-pagination>
              </b-col>
              <b-col sm="6" md="3" lg="2" class="" id="r1">
                <label class="font mx-1">Per Page</label>
                <b-form-select
                  class="br"
                  id="per-page-select"
                  size="sm"
                >
                </b-form-select>
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer"
                  ><i class="fa fa-sync-alt" aria-hidden="true"></i
                  >Refresh</span
                >
              </b-col>
            </b-row>
          </b-container>
          </div>

      <b-card class="card mt-1">
        <div>
        <b-container>
        <b-row>
          <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr  " value="null">
                   <template >
                      <b-form-select-option disabled value="null"><p > Agents</p></b-form-select-option>
                   </template>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                 </b-form-select>
              </b-col>

              <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                 <b-form-select class="form-control bnclr" value="null">
                   <template >
                      <b-form-select-option disabled  value="null"><p > Courses</p></b-form-select-option>
                   </template>

                <option
                  v-for="item in allCourses"
                  :value="item"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.name }}</strong>
                </option>
              </b-form-select>
              </b-col>
              <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                 <b-form-select class="form-control bnclr " value="null">
                   <template >
                      <b-form-select-option disabled value="null">Sources</b-form-select-option>
                   </template>
                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                 </b-form-select>
              </b-col>
              <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr " value="null">
                   <template >
                      <b-form-select-option disabled value="null">Branches</b-form-select-option>
                   </template>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                 </b-form-select>
              </b-col>
              <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr " value="null">
                   <template >
                      <b-form-select-option disabled value="null">Mode</b-form-select-option>
                   </template>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                 </b-form-select>
                  </b-col>
                   <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                  <b-form-select class="form-control bnclr " value="null">
                   <template >
                      <b-form-select-option disabled value="null">Date</b-form-select-option>
                   </template>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                 </b-form-select>
                   </b-col>

        </b-row>
        </b-container>
        </div>
            <hr>

            </b-card>
      </b-container>

    </div>
</template>

<script>
import Account from '../service/Account.js';
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
// import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
  export default {
    data() {
      return {
            allCourses: '',
            allSources: '',
            allBranches: '',
            allAgents:'',
      }
    },
    mounted() {
      // Set the initial number of items
      let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
            .then((response) => {
                self.getAllBranches();
                self.getAllCourses();
                // self.getAllAgents();
                self.getAllSources();
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    methods: {
         getAllCourses: function () {
      return new Promise((resolve, reject) => {
        CourseService.getAllCourses()
          .then((response) => {
            this.allCourses = response.data;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllSources: function () {
      return new Promise((resolve, reject) => {
        SourceService.getAllSources()
          .then((response) => {
            this.allSources = response.data;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllBranches: function () {
      return new Promise((resolve, reject) => {
        BranchService.getAllBranches()
          .then((response) => {
            this.allBranches = response.data;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },


    }
  }
</script>

<style scoped>

.card {
  border: 3px solid rgba(0, 0, 0, 0.125);
 box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}
.bnclr{
    border: 1px solid #09a5ff;
    border-radius: 5px;
}
.searchbar {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #09a5ff;
  margin: 1% 1%;
  padding: 2% 5%;
  background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 16px;
  padding-left: 2rem;
}
.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font{
    font-size: 14px;
}
#per-page-select{
   border: 1px solid #09a5ff;
}
input{
    font-size: small;
}
.br{
 border: 1px solid #09a5ff;
 border-radius: 5px;
 padding: 3% 5%;
}
.row {
    --bs-gutter-x: 0rem;
}
select{
    background-color:#fff;
}
.form-control{
  padding: 0.175rem 0.65rem;
  text-align: center;
}
/* .sort{
  margin-right: 10%;
} */

</style>

