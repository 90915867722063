<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-modal title="New Lead" ref="newLeadModal" no-close-on-backdrop hide-footer>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Name</label>
                <b-form-input type="text" v-model="lead.name" class="form-control" placeholder="Enter name"></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Contact</label>
                <b-form-input type="number" v-model="lead.contact" :class="{'is-invalid': $v.lead.contact.$error,'is-valid': !$v.lead.contact.$invalid,}" class="form-control" placeholder="Enter contact name"></b-form-input>
                <div class="valid-feedback">Your Contact is valid!</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.lead.contact.required">Contact is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Course</label>
                <b-form-input type="text" v-model="lead.course" class="form-control" placeholder="Enter Course name (Optional)"></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Comment</label>
                <b-form-input type="text" v-model="lead.comment" class="form-control" placeholder="Enter comment (Optional)"></b-form-input>
            </div>
        </div>
        <div class="text-center pt-4 pb-2">
            <b-button type="submit" :disabled="disabledButton" @click="newLeadCreate()" class="" variant="outline-primary">Create</b-button>
            <b-button @click="$refs.newLeadModal.hide(),resetLeadForm()" class="mx-3" variant="outline-danger">Cancel</b-button>
        </div>
    </b-modal>
    <b-modal title="Lead Call Convert" ref="modal" no-close-on-backdrop hide-footer>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Name</label>
                <b-form-input type="text" v-model="call.name" :class="{
                  'is-invalid': $v.call.name.$error,
                  'is-valid': !$v.call.name.$invalid,
                }" class="form-control" placeholder="Enter student name"></b-form-input>
                <div class="valid-feedback">Your Name is valid!</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.call.name.required">Name is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Contact</label>
                <b-form-input type="number" v-model="call.contact" :class="{
                  'is-invalid': $v.call.contact.$error,
                  'is-valid': !$v.call.contact.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>
                <div class="valid-feedback">Your contact is valid!</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.call.contact.required">contact is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Alternative Contact</label>
                <b-form-input type="number" v-model="call.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number"></b-form-input>
                <!-- <div class="valid-feedback">Your AlternativeContact is valid!</div>
              <div class="invalid-feedback">

                  <span v-if="!$v.call.alternativeContact.optionalField"
                  >contact is required</span
                >

                  <span v-if="!$v.call.alternativeContact.$error || $v.call.alternativeContact.$invalid"
                  >Invalid contact</span>
              </div> -->
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Age</label>
                <b-form-input type="number" v-model="call.age" :class="{
                  'is-invalid': $v.call.age.$error,
                  'is-valid': !$v.call.age.$invalid,
                }" class="form-control" placeholder="Enter student age"></b-form-input>
                <div class="valid-feedback">Your Age is valid!</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.call.age.required">Age is required</span>
                </div>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Qualification</label>
                <b-form-select class="form-control" v-model="call.qualification" :class="{
                  'is-invalid': $v.call.qualification.$error,
                  'is-valid': !$v.call.qualification.$invalid,
                }">
                    <option value="null" disabled>Select Qualification</option>
                    <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                        <strong>{{ q.text }}</strong>
                    </option>
                </b-form-select>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Course</label>
                <b-form-select class="form-control" v-model="call.course" :class="{
                  'is-invalid': $v.call.course.$error,
                  'is-valid': !$v.call.course.$invalid,
                }">
                    <option value="null" disabled>Select Course</option>
                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Source</label>
                <b-form-select class="form-control" v-model="call.source" :class="{
                  'is-invalid': $v.call.source.$error,
                  'is-valid': !$v.call.source.$invalid,
                }">
                    <option value="null" disabled>Select Source</option>
                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Mode</label>
                <b-form-select class="form-control" v-model="call.mode" :class="{
                  'is-invalid': $v.call.mode.$error,
                  'is-valid': !$v.call.mode.$invalid,
                }" :options="options"></b-form-select>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Branch</label>
                <b-form-select class="form-control" v-model="call.branch" :class="{
                  'is-invalid': $v.call.branch.$error,
                  'is-valid': !$v.call.branch.$invalid,
                }">
                    <option value="null" disabled>Select Branch</option>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Followup</label>
                <div>
                    <b-form-input format="DD-MM-YYYY h:i:s" v-model="call.followupDate" :class="{
                    'is-invalid': $v.call.followupDate.$error,
                    'is-valid': !$v.call.followupDate.$invalid,
                  }" type="datetime-local" min="new Time()"></b-form-input>
                    <div class="valid-feedback">date time is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.call.followupDate.required">date or time is invalid!</span>
                    </div>
                </div>
                <div>
                    <b-form-textarea class="form-control" v-model="call.remark" :class="{
                    'is-invalid': $v.call.remark.$error,
                    'is-valid': !$v.call.remark.$invalid,
                  }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                    <div class="valid-feedback">remark is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.call.remark.required">remark is invalid!</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-3 mt-2">
            <b-button type="submit" :disabled="disabledButton" @click="callInsert()" class="" variant="outline-primary">Submit</b-button>
            <b-button type="submit" @click="resetForm()" class="mx-3" variant="outline-danger">Reset</b-button>
        </div>
    </b-modal>
    <b-container class="mt-5">
        <b-row align-v="center">
            <b-col col lg="1" md="1" id="r1" sm="6">
                <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" /> -->
                <b-button variant="outline-primary" size="sm" @click="leadCreateModalShow">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                    <span class="mx-1">Lead</span>
                </b-button>
            </b-col>
            <b-col col sm="12" md="3" lg="3" id="r1">
                <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getAllAgentMultiLeadSearchCount(keyword)" />
            </b-col>
            <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllAgentMultiLeadSearchCount(keyword)">Search</b-button>
            </b-col>
            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" @input="switchPage(currentPage, pageCount)" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2"></b-pagination>
            </b-col>
            <b-col sm="6" md="3" lg="2" class="" id="r1">
                <label class="font mx-1">Per Page</label>
                <b-form-select class="br" id="per-page-select" @input="switchPage(currentPage, pageCount)" v-model="pageCount" :options="pageOptions" size="sm">
                </b-form-select>
            </b-col>
            <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="getAllAgentMultiLeadCount">
                    <font-awesome-icon icon="sync-alt" />Refresh</span>
            </b-col>
        </b-row>
    </b-container>
    <b-container>
        <b-card class="card mt-1">
            <b-table hover responsive :items="leads" :fields="fields" :per-page="leads.pageCount" :current-page="currentPage" ref="table">
                <template v-slot:cell(createdAt)="data">
                    <div class="cellSize">
                        {{ dateFormat(data.item.createdAt, "fullDate") }}
                    </div>
                </template>
                <!-- <template v-slot:cell(agent)="data">
                    <span v-if="data.item.agent != null">{{data.item.agent.name}}</span>
                    <span v-else>No agent assign</span>
                </template> -->
                <template v-slot:cell(actions)="data">
                    <b-button v-if="data.item.callId != ''" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Lead convert to call" variant="primary" @click="callModalShow(data.item)">
                        <font-awesome-icon icon="envelope-open-text" />
                    </b-button>
                </template>
            </b-table>
        </b-card>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
import LeadService from "../service/LeadsService"
import moment from "moment-timezone";
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import CallService from "../service/CallService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import qualification from "../assets/json_file/qualification.json";
export default {
    name: 'Leads',
    data() {
        return {
            leads: [],
            fields: [{
                    key: "createdAt",
                    label: "Created At",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "comment",
                    label: "Comment",
                },
                // {
                //     key: "agent",
                //     label: "Agent",
                // },
                {
                    key: "actions",
                    label: "Action",
                },
            ],
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            keyword: "",
            loading: false,
            call: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            },
            qualificationList: qualification,
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            allBranches: [],
            allSources: [],
            allCourses: [],
            disabledButton: false,
            leadId: "",
            agentId: "",
            agentLeadPagination: false,
            agentLeadSearchPagination: false,
            lead: {
                name: '',
                contact: '',
                course: '',
                comment: ''
            }
        }
    },
    validations: {
        call: {
            name: {
                required,
                minLength: minLength(3),
            },

            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            alternativeContact: {
                //   optionalField,
                //   minLength: minLength(10),
                // maxLength: maxLength(10),
                // required: requiredIf(value => value.length == 10 ),
            },
            age: {
                required,
                Number,
                minLength: minLength(2),
                maxLength: maxLength(2),
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            branch: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        lead: {
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllAgentMultiLeadCount();
            }
        },
    },
    mounted() {
        if (localStorage.getItem("agentId") != null && localStorage.getItem("agentId") != "") {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        // this.getAllAgentLeadCount();
                        this.getAllAgentMultiLeadCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } else {
            this.$swal.fire({
                icon: 'error',
                title: 'Your role has been Removed',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    methods: {
        newLeadCreate: function () {
            this.disabledButton = true;
            this.$v.lead.$touch();
            if (!this.$v.lead.contact.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    LeadService.isContactUnique(this.lead.contact)
                        .then((response) => {
                            resolve(response)
                            if (response.data == true) {
                                return new Promise((resolve, reject) => {
                                    CallService.getContact(this.lead.contact)
                                        .then((response) => {
                                            if (response.data == true) {
                                                return new Promise((resolve, reject) => {
                                                    LeadService.manualLeadCreate(this.lead,this.agentId)
                                                        .then((response) => {
                                                            // this.getAllAgentLeadCount();
                                                            this.getAllAgentMultiLeadCount();
                                                            this.$refs.newLeadModal.hide();
                                                            this.resetLeadForm();
                                                            this.loading = false;
                                                            this.disabledButton = false;
                                                            this.$swal({
                                                                title: "Good job!",
                                                                text: "New lead created..!",
                                                                icon: "success",
                                                                button: "ok",
                                                            });
                                                            resolve(response);
                                                        })
                                                        .catch((err) => {
                                                            this.loading = false;
                                                            reject(err);
                                                        });
                                                });
                                            } else {
                                                this.loading = false;
                                                this.disabledButton = false;
                                                this.$swal.fire({
                                                    icon: "error",
                                                    title: "Oops...",
                                                    text: "Contact is already exist in calls!",
                                                });
                                            }
                                            resolve(response)
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.loading = false;
                                this.disabledButton = false;
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Contact is already exist in leads!",
                                });
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        leadCreateModalShow() {
            this.resetLeadForm();
            this.$refs.newLeadModal.show();
        },
        async callModalShow(lead) {
            await this.resetForm();
            await this.getAllCourses();
            await this.getAllSources();
            await this.getAllBranches();
            console.log("leadAgentAssign========== ", lead.leadId);
            this.leadId = lead.leadId;
            this.call = {
                name: lead.name,
                contact: lead.contact,
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: lead.comment,
            }
            this.$refs.modal.show();
        },
        callInsert: function () {
            this.$v.call.$touch();
            if (this.$v.call.$invalid) {
                this.submitStatus = "FAIL";
                // alert("insert submit failed");
            } else {
                return new Promise((resolve, reject) => {
                    CallService.getContact(this.call.contact)
                        .then((response) => {
                            if (response.data == true) {
                                if (this.call.alternativeContact == "") {
                                    let data = {
                                        name: this.call.name,
                                        contact: this.call.contact,
                                        alternativeContact: this.call.alternativeContact,
                                        age: this.call.age,
                                        qualification: this.call.qualification,
                                        course: this.call.course,
                                        source: this.call.source,
                                        mode: this.call.mode,
                                        branch: this.call.branch,
                                        followupDate: this.call.followupDate,
                                        remark: this.call.remark,
                                        teamId: axios.defaults.headers.common["tenant-uuid"],
                                        leadId: this.leadId
                                    }
                                    return new Promise((resolve, reject) => {
                                        // //console.log(this.agentId);
                                        LeadService.leadConvertToCall(this.agentId, data)
                                            .then((response) => {
                                                this.$swal({
                                                    title: "Good job!",
                                                    text: response.data,
                                                    icon: "success",
                                                    button: "ok",
                                                });
                                                this.resetForm();
                                                this.$refs.modal.hide();
                                                // this.getAllAgentLeadCount();
                                                this.getAllAgentMultiLeadCount();
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                this.loading = false;
                                                reject(err);
                                            });
                                    });
                                } else {
                                    return new Promise((resolve, reject) => {
                                        CallService.getalternativeContact(
                                                this.call.alternativeContact
                                            )
                                            .then((response) => {
                                                //console.log("Response====",response.data)
                                                if (response.data == true) {
                                                    this.submitStatus = "SUCCESS";
                                                    //console.log("alternativeContact calling");
                                                    return new Promise((resolve, reject) => {
                                                        // //console.log(this.agentId);
                                                        let data = {
                                                            name: this.call.name,
                                                            contact: this.call.contact,
                                                            alternativeContact: this.call.alternativeContact,
                                                            age: this.call.age,
                                                            qualification: this.call.qualification,
                                                            course: this.call.course,
                                                            source: this.call.source,
                                                            mode: this.call.mode,
                                                            branch: this.call.branch,
                                                            followupDate: this.call.followupDate,
                                                            remark: this.call.remark,
                                                            teamId: axios.defaults.headers.common["tenant-uuid"]
                                                        }
                                                        LeadService.leadConvertToCall(this.agentId, data)
                                                            .then((response) => {
                                                                this.$swal({
                                                                    title: "Good job!",
                                                                    text: response.data,
                                                                    icon: "success",
                                                                    button: "ok",
                                                                });
                                                                this.resetForm();
                                                                this.$refs.modal.hide();
                                                                // this.getAllAgentLeadCount();
                                                                this.getAllAgentMultiLeadCount();
                                                                this.disabledButton = false;
                                                                resolve(response);
                                                            })
                                                            .catch((err) => {
                                                                this.loading = false;
                                                                reject(err);
                                                            });
                                                    });
                                                } else
                                                    this.$swal.fire({
                                                        icon: "error",
                                                        title: "Oops...",
                                                        text: "AlternativeContact is already exist!",
                                                    });
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                reject(err);
                                                this.disabledButton = false;
                                                this.loading = false;
                                            });
                                    });
                                }
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Mobile Number is already exist!",
                                });
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        // leadAssignToAgent: function () {
        //     return new Promise((resolve, reject) => {
        //         LeadService.leadAgentAssign(this.leadId)
        //             .then((response) => {
        //                 this.getAllAgentLeadCount();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        resetForm() {
            this.call = {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        // getAllAgentLeadCount: function () {
        //     this.loading = true;
        //     this.currentPage = 1;
        //     this.agentLeadPagination = true;
        //     this.agentLeadSearchPagination = false;
        //     return new Promise((resolve, reject) => {
        //         LeadService.getAllAgentLeadCount()
        //             .then((response) => {
        //                 this.totalCount = response.data;
        //                 this.getAllAgentLeadPageable(this.currentPage, this.pageCount);
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        getAllAgentMultiLeadCount: function () {
            // console.log("=====GetAllAgentMultiLeadCount Method Calling=====");
            this.loading = true;
            this.currentPage = 1;
            this.agentLeadPagination = true;
            this.agentLeadSearchPagination = false;
            return new Promise((resolve, reject) => {
                LeadService.getAllAgentMultiLeadCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("=====Total Count=====",this.totalCount);
                        this.getAllAgentMultiLeadPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // switchPage(page, count) {
        //     this.loading = true;
        //     if (this.agentLeadPagination) {
        //         this.getAllAgentLeadPageable(page, count)
        //     } else if (this.agentLeadSearchPagination) {
        //         this.getAllAgentLeadSearchPageable(page, count, this.keyword)
        //     }
        // },
        switchPage(currentPage, pageCount) {
            this.loading = true;
            if (this.agentLeadPagination) {
                this.getAllAgentMultiLeadPageable(currentPage, pageCount)
            } else if (this.agentLeadSearchPagination) {
                this.getAllAgentMultiLeadSearchPageable(currentPage, pageCount, this.keyword)
            }
        },
        // getAllAgentLeadPageable: function (page, count) {
        //     return new Promise((resolve, reject) => {
        //         LeadService.getAllAgentLeadPageable(page, count)
        //             .then((response) => {
        //                 this.leads = response.data;
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        getAllAgentMultiLeadPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                LeadService.getAllAgentMultiLeadPageable(currentPage, pageCount)
                    .then((response) => {
                        this.leads = response.data.content;
                        console.log("=====Leads=====",this.leads);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // getAllAgentLeadSearchCount: function (keyword) {
        //     this.loading = true;
        //     this.currentPage = 1;
        //     this.agentLeadPagination = true;
        //     this.agentLeadSearchPagination = false;
        //     return new Promise((resolve, reject) => {
        //         LeadService.getAllAgentLeadSearchCount(keyword.toLowerCase())
        //             .then((response) => {
        //                 this.totalCount = response.data;
        //                 this.getAllAgentLeadSearchPageable(this.currentPage, this.pageCount, this.keyword);
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllAgentLeadSearchPageable: function (currentPage, pageCount, keyword) {
        //     return new Promise((resolve, reject) => {
        //         LeadService.getAllAgentLeadSearchPageable(currentPage, pageCount, keyword.toLowerCase())
        //             .then((response) => {
        //                 this.leads = response.data;
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        getAllAgentMultiLeadSearchCount: function (keyword) {
            console.log("=====Search Method Calling=====");
            this.loading = true;
            this.currentPage = 1;
            this.agentLeadPagination = true;
            this.agentLeadSearchPagination = false;
            return new Promise((resolve, reject) => {
                LeadService.getAllAgentMultiLeadSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("=====Total Count=====",this.totalCount);
                        this.getAllAgentMultiLeadSearchPageable(this.currentPage, this.pageCount, this.keyword);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllAgentMultiLeadSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                LeadService.getAllAgentMultiLeadSearchPageable(currentPage, pageCount, keyword.toLowerCase())
                    .then((response) => {
                        this.leads = response.data;
                        // console.log("=====Leads=====",this.leads);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        resetLeadForm() {
            this.lead = {
                name: '',
                contact: '',
                course: '',
                comment: ''
            };
            this.$nextTick(() => {
                this.$v.lead.$reset();
            });
        }
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 125px;
}
</style>
