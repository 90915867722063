<template>
<!-- <template v-slot:cell(callId)="data"> -->
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- <b-container class="sticky-top" id="sticky">
        <b-row>
            <b-col col lg="2" md="1" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedCourse" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                    <b-form-select-option disabled value="Course">
                        <p>Courses</p>
                    </b-form-select-option>
                    <b-form-select-option value="AllCourses">
                        <p>All Courses</p>
                    </b-form-select-option>
                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </b-col>
            <b-col col lg="2" md="1" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedMode" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                    <b-form-select-option disabled value="mode">
                        <p>Mode</p>
                    </b-form-select-option>
                    <b-form-select-option value="allModes">
                        <p>All Modes</p>
                    </b-form-select-option>
                    <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                        <strong>{{ item.text }}</strong>
                    </option>
                </b-form-select>
            </b-col>
            <b-col col lg="2" md="1" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedSource" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                    <b-form-select-option disabled value="Source">
                        <p>Sources</p>
                    </b-form-select-option>
                    <b-form-select-option value="AllSources">
                        <p>All Sources</p>
                    </b-form-select-option>
                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </b-col>
            <b-col col lg="2" md="1" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                    <b-form-select-option disabled value="Agent">
                        <p>Agents</p>
                    </b-form-select-option>
                    <b-form-select-option value="AllAgents">
                        <p>All Agents</p>
                    </b-form-select-option>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>
            </b-col>
            <b-col col lg="2" md="2" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                    <b-form-select-option disabled value="Branch">
                        Branches
                    </b-form-select-option>
                    <b-form-select-option value="AllBranches">
                        <p>All Branches</p>
                    </b-form-select-option>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{ item.name }}</strong>
                    </option>
                </b-form-select>
            </b-col>
            <b-col col lg="2" md="2" id="r1" sm="3">
                <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            " :options="dateSelect">
                </b-form-select>
            </b-col>
        </b-row>
        <div class="mt-2">
            <b-row>
                <b-col class="text-end" col lg="12" md="2" id="r1" sm="3">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </div>
    </b-container> -->

    <!-- <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
      <b-form-select
        class="bnclr"
        v-model="selectedDates"
        size="sm"
        @change="getDateSortCount(selectedDates)"
        :options="dateSelect"
      >
      </b-form-select>
    </b-col> -->
    <!-- <br /> -->
    <!-- <b-col col lg="12" md="2" id="r1" sm="6">
      <span style="color: #0974ff; cursor: pointer" @click="refresh()"
        ><font-awesome-icon icon="sync-alt" />Refresh</span
      >
    </b-col> -->

    <!-- <br /> -->

    <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date" v-on:keyup.enter="doSortingProcess"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedAgent,
              selectedCourse,
              selectedMode,
              selectedSource,
              selectedBranch,
              selectedDates
            )
          ">Submit</b-button>
        </div>
    </b-modal>

    <b-modal ref="callReportDateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date" v-on:keyup.enter="doSortingProcess"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="
            getAllSortReportCount(
              selectedReportAgent,
              selectedReportCourse,
              selectedReportMode,
              selectedReportSource,
              selectedReportBranch,
              selectedReportDates
            )
          ">Submit</b-button>
        </div>
    </b-modal>

    <!-- <div v-if="loadCallsChart" id="calls">
      <div style="position: relative; height: 30%; width: 30%"> -->
    <!-- <bar-chart :chartData="chartData" :options="chartOptions" /> -->
    <!-- <line-chart :chartdata="chartData" :options="chartOptions" />
      </div>
    </div> -->

    <!-- <div v-if="loadFollowupChart" id="followups">
      <div style="position: relative; height: 43%; width: 43%"> -->
    <!-- <bar-chart :chartData="chartData1" :options="chartOptions" /> -->
    <!-- <line-chart :chartdata="chartData1" :options="chartOptions" />
      </div>
    </div> -->

    <!-- <div v-if="loadAdmissionChart" id="admissions">
      <div style="position: relative; height: 75.5%; width: 75.5%"> -->
    <!-- <bar-chart :chartData="chartData2" :options="chartOptions" /> -->
    <!-- <line-chart :chartdata="chartData2" :options="chartOptions" />
      </div>
    </div>
<br /> -->
    <!--
<b-container class="bv-example-row" style="background-color: red;">
  <b-row class="justify-content-md-center">
    <b-col col lg="2">

    </b-col>
    <b-col cols="12" md="auto">

    </b-col>
    <b-col col lg="2">

    </b-col>
  </b-row>

  <b-row>
    <b-col>1 of 3</b-col>
    <b-col cols="12" md="auto">Variable width content</b-col>
    <b-col col lg="2">3 of 3</b-col>
  </b-row>
</b-container> -->

    <div>
        <!-- <b-card no-body> -->
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="GRAPHS" active>

                <b-container class="sticky-top" id="sticky">
                    <b-card>
                        <b-row>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="Course">
                                        <p>Courses</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllCourses">
                                        <p>All Courses</p>
                                    </b-form-select-option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedMode" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="mode">
                                        <p>Mode</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="allModes">
                                        <p>All Modes</p>
                                    </b-form-select-option>
                                    <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                        <strong>{{ item.text }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="Source">
                                        <p>Sources</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllSources">
                                        <p>All Sources</p>
                                    </b-form-select-option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedAgent" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="Agent">
                                        <p>Agents</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllAgents">
                                        <p>All Agents</p>
                                    </b-form-select-option>
                                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.firstName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="Branch">
                                        Branches
                                    </b-form-select-option>
                                    <b-form-select-option value="AllBranches">
                                        <p>All Branches</p>
                                    </b-form-select-option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            " :options="dateSelect">
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <div class="mt-2">
                            <b-row>
                                <b-col class="text-end" col lg="12" md="2" id="r1" sm="3">
                                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-container>
                <br>
                <b-container style="width: 100%; padding: 0 0 0 0">
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <div v-if="loadCallsChart" col lg="2">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if="loadFollowupChart" cols="2" md="auto">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData1" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if="loadNotintrestedChart" col lg="2">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData5" :options="chartOptions" />
                                </div>
                            </div>
                            <!-- <div v-if="loadAdmissionChart" col lg="2">
            <div style="position: relative; height: 80%; width: 80%" id="graph">
              <line-chart :chartdata="chartData2" :options="chartOptions" />
            </div>
          </div> -->
                        </b-col>
                    </b-row>
                </b-container>

                <br />
                <br />

                <b-container style="width: 100%; padding: 0 0 0 0">
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <div v-if="loadClosedCallsChart" col lg="2">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData3" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if="loadClosedFollowupChart" cols="2" md="auto">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData4" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <!-- <div v-if="loadNotintrestedChart" col lg="2">
            <div style="position: relative; height: 80%; width: 80%" id="graph">
              <line-chart :chartdata="chartData5" :options="chartOptions" />
            </div>
          </div> -->
                            <div v-if="loadAdmissionChart" col lg="2">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData2" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if="loadEnquiryChart" col lg="2">
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData12" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <!-- <b-col>
          <div v-if="loadAgentCallChart" col lg="2">
        <div style="position: relative; height: 80%; width: 80%">
          <line-chart :chartdata="chartData6" :options="chartOptions1" />
        </div>
      </div>
        </b-col> -->
                    </b-row>

                    <br />
                    <br />
                    <h6>Agents Calls Count</h6>
                    <br />

                    <div v-if="loadAgentCallChart" col lg="2">
                        <div style="position: relative; height: 100%; width: 100%">
                            <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                            <line-chart :chartdata="chartData6" :options="chartOptions1" />
                        </div>
                    </div>

                    <br />
                    <br />
                    <h6>Agents Followups Count</h6>
                    <br />

                    <div v-if="loadAgentFollowupChart" col lg="2">
                        <div style="position: relative; height: 100%; width: 100%">
                            <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                            <line-chart :chartdata="chartData7" :options="chartOptions2" />
                        </div>
                    </div>

                    <br />
                    <br />
                    <h6>Agents Enquiry Count</h6>
                    <br />

                    <div v-if="loadAgentEnquiryChart" col lg="2">
                        <div style="position: relative; height: 100%; width: 100%">
                            <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                            <line-chart :chartdata="chartData13" :options="chartOptions2" />
                        </div>
                    </div>

                    <br />
                    <br />
                    <h6>Agents Admissions Count</h6>
                    <br />

                    <div v-if="loadAgentAdmissionChart" col lg="2">
                        <div style="position: relative; height: 100%; width: 100%">
                            <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                            <line-chart :chartdata="chartData13" :options="chartOptions2" />
                        </div>
                    </div>

                    <!-- <br />
      <br />
      <h6>Source Call Count</h6>
      <br />

      <div v-if="loadSourceCallChart" col lg="2">
        <div style="position: relative; height: 100%; width: 100%">
          <pie-chart :chartdata="chartData8" :options="chartOptions3" />
        </div>
      </div>

      <br />
      <br />
      <h6>Source Followup Count</h6>
      <br />

      <div v-if="loadSourceFollowupChart" col lg="2">
        <div style="position: relative; height: 100%; width: 100%">
          <pie-chart :chartdata="chartData9" :options="chartOptions4" />
        </div>
      </div>

      <br />
      <br />
      <h6>Source Admission Count</h6>
      <br />

      <div v-if="loadSourceAdmissionChart" col lg="2">
        <div style="position: relative; height: 100%; width: 100%">
          <pie-chart :chartdata="chartData10" :options="chartOptions5" />
        </div>
      </div>

      <br />
      <br />
      <h6>Source Notintrested Count</h6>
      <br />

      <div v-if="loadSourceNotintrestedChart" col lg="2">
        <div style="position: relative; height: 100%; width: 100%">
          <pie-chart :chartdata="chartData11" :options="chartOptions6" />
        </div>
      </div> -->
                </b-container>

                <b-container style="width: 100%; padding: 0 0 0 0">
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <br />
                            <br />
                            <h6>Source Call Count</h6>
                            <br />

                            <!-- <div v-if="loadEmptySourceCallChart" col lg="2" md="auto">
            <div style="position: relative; height: 100%; width: 100%">
              <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                Source CallChartData is empty
              </b-button> -->
                            <!-- <h6>Source CallChartData is empty</h6> -->
                            <!-- </div>
          </div> -->
                            <div col lg="2" md="auto">
                                <div style="position: relative; height: 100%; width: 100%">
                                    <!-- <b-card border-variant="info" header="Sorry...!!!" align="center" v-if="loadEmptySourceCallChart">
        <b-card-text>No data found.</b-card-text>
      </b-card> -->
                                    <b-card style="height: 20rem" bg-variant="light" header="Calls" class="text-center" v-if="loadEmptySourceCallChart">
                                        <br /><br /><br /><br />
                                        <b-card-text style="color: gray">No data available.</b-card-text>
                                    </b-card>
                                    <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                                    <pie-chart :chartdata="chartData8" :options="chartOptions3" v-if="loadSourceCallChart" />
                                </div>
                            </div>
                        </b-col>

                        <b-col>
                            <br />
                            <br />
                            <h6>Source Followup Count</h6>
                            <br />

                            <!-- <div v-if="loadEmptySourceFollowupChart" col lg="2" md="auto">
            <div style="position: relative; height: 100%; width: 100%"> -->
                            <!-- <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                Source FollowupChartData is empty
              </b-button> -->
                            <!-- <h6>Source FollowupChartData is empty</h6> -->
                            <!-- </div>
          </div> -->
                            <div col lg="2" md="auto">
                                <div style="position: relative; height: 100%; width: 100%">
                                    <!-- <b-card border-variant="info" header="Sorry...!!!" align="center" v-if="loadEmptySourceFollowupChart">
        <b-card-text>No data found.</b-card-text>
      </b-card> -->
                                    <b-card style="height: 20rem" bg-variant="light" header="Followups" class="text-center" v-if="loadEmptySourceFollowupChart">
                                        <br /><br /><br /><br />
                                        <b-card-text style="color: gray">No data available.</b-card-text>
                                    </b-card>
                                    <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                                    <pie-chart :chartdata="chartData9" :options="chartOptions4" v-if="loadSourceFollowupChart" />
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <br />
                            <br />
                            <h6>Source Admission Count</h6>
                            <br />

                            <!-- <div v-if="loadEmptySourceAdmissionChart" col lg="2" md="auto">
            <div style="position: relative; height: 100%; width: 100%"> -->
                            <!-- <b-button variant="primary" disabled>
                <b-spinner small type="grow"></b-spinner>
                Source AdmissionChartData is empty
              </b-button> -->
                            <!-- <h6>Source AdmissionChartData is empty</h6> -->
                            <!-- </div>
          </div> -->
                            <div col lg="2" md="auto">
                                <div style="position: relative; height: 100%; width: 100%">
                                    <!-- <b-card border-variant="info" header="Sorry...!!!" align="center" v-if="loadEmptySourceAdmissionChart">
        <b-card-text>No data found.</b-card-text>
      </b-card> -->
                                    <b-card style="height: 20rem" bg-variant="light" header="Admissions" class="text-center" v-if="loadEmptySourceAdmissionChart">
                                        <br /><br /><br /><br />
                                        <b-card-text style="color: gray">No data available.</b-card-text>
                                    </b-card>
                                    <!-- <div class="chart-container" style="position: relative; height:-50vh; width:50vw"> -->
                                    <pie-chart :chartdata="chartData10" :options="chartOptions5" v-if="loadSourceAdmissionChart" />
                                </div>
                            </div>
                        </b-col>

                        <b-col>
                            <br />
                            <br />
                            <h6>Source Notintrested Count</h6>
                            <br />

                            <!-- <div  col lg="2" md="auto">
            <div style="position: relative; height: 100%; width: 100%"> -->
                            <!-- <b-button variant="primary" disabled v-if="loadEmptySourceNotintrestedChart">
                <b-spinner small type="grow"></b-spinner>
                Source NotintrestedChartData is empty
              </b-button> -->
                            <!-- <h6>Source NotintrestedChartData is empty</h6> -->
                            <!-- </div>
          </div> -->
                            <div col lg="2" md="auto">
                                <div style="position: relative; height: 100%; width: 100%">
                                    <!-- <b-card border-variant="info" header="Sorry...!!!" align="center" v-if="loadEmptySourceNotintrestedChart">
        <b-card-text>No data found.</b-card-text>
      </b-card> -->
                                    <b-card style="height: 20rem" bg-variant="light" header="Notintresteds" class="text-center" v-if="loadEmptySourceNotintrestedChart">
                                        <br /><br /><br /><br />
                                        <b-card-text style="color: gray">No data available.</b-card-text>
                                    </b-card>
                                    <pie-chart :chartdata="chartData11" :options="chartOptions6" v-if="loadSourceNotintrestedChart" />
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- <bar-chart :dataset="chartData" /> -->
                <!-- <bar-chart :chartdata="chartData" :options="options" /> -->
                <!-- <line-chart :chartdata="chartData" :options="chartOptions" /> -->
                <!-- <Pie-chart :chartdata="datacollection" :options="options" /> -->
            </b-tab>

            <b-tab title="REPORTS">

                <b-container class="sticky-top" id="sticky">
                    <b-card>
                        <b-row>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportCourse" size="sm" @change="
              getAllSortReportCount(
                selectedReportAgent,
                selectedReportCourse,
                selectedReportMode,
                selectedReportSource,
                selectedReportBranch,
                selectedReportDates
              )
                 ">
                                    <b-form-select-option disabled value="Course">
                                        <p>Courses</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllCourses">
                                        <p>All Courses</p>
                                    </b-form-select-option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportMode" size="sm" @change="
                getAllSortReportCount(
                    selectedReportAgent,
                    selectedReportCourse,
                    selectedReportMode,
                    selectedReportSource,
                    selectedReportBranch,
                    selectedReportDates
                )
                                                ">
                                    <b-form-select-option disabled value="mode">
                                        <p>Mode</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="allModes">
                                        <p>All Modes</p>
                                    </b-form-select-option>
                                    <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                        <strong>{{ item.text }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportSource" size="sm" @change="
              getAllSortReportCount(
                selectedReportAgent,
                selectedReportCourse,
                selectedReportMode,
                selectedReportSource,
                selectedReportBranch,
                selectedReportDates
              )
                             ">
                                    <b-form-select-option disabled value="Source">
                                        <p>Sources</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllSources">
                                        <p>All Sources</p>
                                    </b-form-select-option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportAgent" size="sm" @change="
              getAllSortReportCount(
                selectedReportAgent,
                selectedReportCourse,
                selectedReportMode,
                selectedReportSource,
                selectedReportBranch,
                selectedReportDates
              )
               ">
                                    <b-form-select-option disabled value="Agent">
                                        <p>Agents</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllAgents">
                                        <p>All Agents</p>
                                    </b-form-select-option>
                                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.firstName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportBranch" size="sm" @change="
              getAllSortReportCount(
                selectedReportAgent,
                selectedReportCourse,
                selectedReportMode,
                selectedReportSource,
                selectedReportBranch,
                selectedReportDates
              )
              ">
                                    <b-form-select-option disabled value="Branch">
                                        Branches
                                    </b-form-select-option>
                                    <b-form-select-option value="AllBranches">
                                        <p>All Branches</p>
                                    </b-form-select-option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedReportDates" size="sm" @change="
              getAllSortReportCount(
                selectedReportAgent,
                selectedReportCourse,
                selectedReportMode,
                selectedReportSource,
                selectedReportBranch,
                selectedReportDates
              )
                " :options="dateSelect">
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <div class="mt-2">
                            <b-row>
                                <b-col class="text-end" col lg="12" md="2" id="r1" sm="3">
                                    <span style="color: #0974ff; cursor: pointer" @click="refreshReport()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-container>

                <!-- <b-card-text>TABLE CONTENTS</b-card-text> -->
                <b-tabs pills card content-class="mt-3" justified>
                    <b-tab title="CALLS" active>
                        <!-- Calls Table -->
                        <div>
                            <!-- <b-card-text>CALLS TABLE </b-card-text> -->
                            <!-- <b-collapse id="my-collapse1"> -->
                            <!-- <div class="mt-5"> -->
                            <b-card class="card mt-1">
                                <!-- <b-container> -->
                                <b-row align-v="center">
                                    <!--add Call button start-->
                                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCall @click="resetForm()" disabled>Add Call</b-button>
                        </b-col> -->
                                    <!-- <b-col col sm="12" md="4" lg="4" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  placeholder="Type a name"
                  v-on:keyup.enter="getCallSearchCount(keyword)"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getCallSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->

                                    <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                        <b-pagination v-model="currentPage" :total-rows="totalCallCount" :per-page="callsPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchCallsPage(currentPage, callsPageCount)"></b-pagination>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="font mx-1">Per Page</label>
                                        <b-form-select class="br" id="per-page-select" v-model="callsPageCount" @input="switchCallsPage(currentPage, callsPageCount)" :options="pageOptions" size="sm">
                                        </b-form-select>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="mx-2">Total Count:</label>
                                        <b-button variant="danger">{{ totalCallCount }}</b-button>
                                    </b-col>
                                    <b-col col lg="2" md="1" id="r1" sm="6">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportCallExcellSheet(selectedReportDates)">Call Reports</b-button>
                                        <b-button size="md" variant="success" class="bnclr mt-1" @click="exportCallFollowupExcellSheet(selectedReportDates)" :disabled="disableButton">Call & Followups</b-button>
                                    </b-col>
                                </b-row>
                                <!-- <b-row align-v="right">
                                    <b-col col lg="12" md="12" id="12" sm="12" class="float-end">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportCallExcellSheet(selectedDates)">Download</b-button>
                                    </b-col>
                                </b-row> -->
                                <hr />
                                <!-- </b-container> -->
                                <b-container>
                                    <!-- <b-card class="card mt-1"> -->
                                    <div></div>
                                    <!-- <hr /> -->
                                    <b-table class="my-table" bordered hover responsive :items="calls" :fields="fields" :per-page="calls.callsPageCount" :current-page="currentPage" ref="table">
                                        <template v-slot:cell(callId)="data">
                                            <span class="title"> {{ data.item.callId }}</span>
                                        </template>
                                        <template v-slot:cell(createdAt)="data">
                                            <div class="cellSize">
                                                {{ callDate(data.item.createdAt, "fullDate") }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(name)="data">
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:cell(agent)="data">
                                            {{ data.item.agent.firstName }}
                                        </template>
                                        <template v-slot:cell(contact)="data">
                                            <div>
                                                {{ data.item.contact
                          }}<span v-if="data.item.alternativeContact != null">,</span>
                                            </div>
                                            <div>
                                                {{ data.item.alternativeContact }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(course)="data">
                                            {{ data.item.course.name }}
                                        </template>
                                        <template v-slot:cell(source)="data">
                                            {{ data.item.source.name }}
                                        </template>
                                        <template v-slot:cell(branch)="data">
                                            {{ data.item.branch.name }}
                                        </template>
                                        <!-- <template v-slot:cell(action)="data"> -->

                                        <!-- <b-button
                    class="px-2"
                    variant="primary"
                    v-b-tooltip.hover.bottomright="{ variant: 'primary' }"
                    title="View"
                    v-b-modal.viewcalldetail
                    @click="getCall(data.item)"
                    ><font-awesome-icon icon="eye"
                  /></b-button> -->

                                        <!-- <b-button
                    class="px-2"
                    variant="secondary"
                    :disabled="disabledButton"
                    v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                    title="Closed"
                    @click="getCall(data.item)"
                    v-b-modal.callClosed
                    ><font-awesome-icon icon="window-close"
                  /></b-button> -->

                                        <!-- <b-button
                    v-b-tooltip.hover.bottomright="{ variant: 'success' }"
                    title="Archive"
                    variant="success"
                    class="px-2"
                    @click="archive(data.item.callId)"
                  >
                    <font-awesome-icon icon="archive" />
                  </b-button> -->

                                        <!-- <b-button
                    v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                    title="Unarchive"
                    variant="warning"
                    class="px-2"
                    @click="unArchive(data.item.callId)"
                  >
                    <font-awesome-icon icon="archive" />
                  </b-button> -->
                                        <!-- </template> -->
                                    </b-table>
                                    <!-- </b-card> -->
                                </b-container>
                            </b-card>
                            <!-- </b-collapse> -->
                        </div>
                    </b-tab>

                    <b-tab title="CLOSED CALLS">
                        <!--Closed Calls Table -->
                        <div>
                            <!-- <b-card-text>CLOSED CALLS TABLE </b-card-text> -->
                            <!-- <b-collapse id="my-collapse1"> -->
                            <!-- <div class="mt-5"> -->
                            <!-- <b-container> -->
                            <b-card class="card mt-1">
                                <b-row align-v="center">
                                    <!--add Call button start-->
                                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCall @click="resetForm()" disabled>Add Call</b-button>
                        </b-col> -->
                                    <!-- <b-col col sm="12" md="4" lg="4" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  placeholder="Type a name"
                  v-on:keyup.enter="getCallSearchCount(keyword)"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getCallSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->

                                    <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                        <b-pagination v-model="closedCurrentPage" :total-rows="totalClosedCallCount" :per-page="closedPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="
                          switchClosedCallsPage(
                            closedCurrentPage,
                            closedPageCount
                          )
                        "></b-pagination>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="3" class="" id="r1">
                                        <label class="font mx-1">Per Page</label>
                                        <b-form-select class="br" id="per-page-select" v-model="closedPageCount" @input="
                          switchClosedCallsPage(
                            closedCurrentPage,
                            closedPageCount
                          )
                        " :options="closedPageOptions" size="sm">
                                        </b-form-select>
                                    </b-col>
                                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                                        <label class="mx-2">Total Count:</label>
                                        <b-button variant="danger">{{
                        totalClosedCallCount
                      }}</b-button>
                                    </b-col>

                                    <b-col col lg="1" md="1" id="r1" sm="6">
                                        <b-button size="md" variant="success" class="bnclr" @click="exportClosedCallExcellSheet(selectedReportDates)">Download</b-button>
                                    </b-col>
                                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="refresh()"
                  ><font-awesome-icon icon="sync-alt" />Refresh</span
                >
              </b-col> -->
                                </b-row>
                                <hr />
                                <!-- </b-container> -->
                                <b-container>
                                    <!-- <b-card class="card mt-1"> -->
                                    <div></div>
                                    <!-- <hr /> -->
                                    <b-table class="my-table" bordered hover responsive :items="closedCalls" :fields="closedFields" :per-page="closedCalls.closedPageCount" :current-page="closedCurrentPage" ref="table">
                                        <template v-slot:cell(callId)="data">
                                            <span class="title"> {{ data.item.callId }}</span>
                                        </template>
                                        <template v-slot:cell(createdAt)="data">
                                            <div class="cellSize">
                                                {{ callDate(data.item.createdAt, "fullDate") }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(name)="data">
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:cell(agent)="data">
                                            {{ data.item.agent.firstName }}
                                        </template>
                                        <template v-slot:cell(contact)="data">
                                            <div>
                                                {{ data.item.contact
                          }}<span v-if="data.item.alternativeContact != null">,</span>
                                            </div>
                                            <div>
                                                {{ data.item.alternativeContact }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(course)="data">
                                            {{ data.item.course.name }}
                                        </template>
                                        <template v-slot:cell(source)="data">
                                            {{ data.item.source.name }}
                                        </template>
                                        <template v-slot:cell(branch)="data">
                                            {{ data.item.branch.name }}
                                        </template>
                                        <!-- <template v-slot:cell(action)="data"> -->

                                        <!-- <b-button
                    class="px-2"
                    variant="primary"
                    v-b-tooltip.hover.bottomright="{ variant: 'primary' }"
                    title="View"
                    v-b-modal.viewcalldetail
                    @click="getCall(data.item)"
                    ><font-awesome-icon icon="eye"
                  /></b-button> -->

                                        <!-- <b-button
                    class="px-2"
                    variant="secondary"
                    :disabled="disabledButton"
                    v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                    title="Closed"
                    @click="getCall(data.item)"
                    v-b-modal.callClosed
                    ><font-awesome-icon icon="window-close"
                  /></b-button> -->

                                        <!-- <b-button
                    v-b-tooltip.hover.bottomright="{ variant: 'success' }"
                    title="Archive"
                    variant="success"
                    class="px-2"
                    @click="archive(data.item.callId)"
                  >
                    <font-awesome-icon icon="archive" />
                  </b-button> -->

                                        <!-- <b-button
                    v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                    title="Unarchive"
                    variant="warning"
                    class="px-2"
                    @click="unArchive(data.item.callId)"
                  >
                    <font-awesome-icon icon="archive" />
                  </b-button> -->
                                        <!-- </template> -->
                                    </b-table>
                                    <!-- </b-card> -->
                                </b-container>
                            </b-card>
                            <!-- </div> -->
                            <!-- </b-collapse> -->
                        </div>
                    </b-tab>
                    <b-tab title="FOLLOWUPS">
                        <!--FOLLOWUPS Table -->

                        <!-- <b-card-text>FOLLOWUPS TABLE </b-card-text> -->
                        <!-- <b-container> -->
                        <b-card class="card mt-1">
                            <b-row align-v="center">
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
              <b-button size="sm" variant="outline-primary" class="bnclr"  disabled @click="resetForm()">Add </b-button>
              </b-col> -->
                                <!-- <b-col col sm="12" md="4" lg="4" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  v-on:keyup.enter="getSearchCount(keyword)"
                  placeholder="Type a name"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->

                                <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                    <b-pagination v-model="followupsCurrentPage" :total-rows="totalFollowupsCount" :per-page="followupsPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchFollowupsPage(
                          followupsCurrentPage,
                          followupsPageCount
                        )
                      "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="3" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="followupsPageCount" @input="
                        switchFollowupsPage(
                          followupsCurrentPage,
                          followupsPageCount
                        )
                      " :options="pageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="mx-2">Total Count:</label>
                                    <b-button variant="danger">{{
                      totalFollowupsCount
                    }}</b-button>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="md" variant="success" class="bnclr" @click="exportFollowupExcellSheet(selectedReportDates)">Download</b-button>
                                </b-col>
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="refresh()"
                  ><font-awesome-icon icon="sync-alt" />Refresh</span
                >
              </b-col> -->
                            </b-row>
                            <hr />
                            <!-- </b-container> -->
                            <b-container>
                                <!-- <b-card class="card mt-1"> -->
                                <!-- for sort dropdown start-->
                                <!-- <br /> -->
                                <!-- <hr /> -->
                                <b-table class="my-table" bordered responsive striped hover :items="followups" :fields="followupItems" :current-page="followupsCurrentPage" :per-page="followups.followupsPageCount">
                                    <template v-slot:cell(followupDate)="data">
                                        <div class="dateCell">
                                            <span v-if="expiredDate" style="color: #ff0000">
                                                {{
                            dateConvert(data.item.followupDate, "fullDate")
                          }}</span>
                                            <span v-else>{{
                          dateConvert(data.item.followupDate, "fullDate")
                        }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:cell(agent)="data">
                                        {{ data.item.agent.firstName }}
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                        }}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>
                                            {{ data.item.alternativeContact }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        {{ data.item.course.name }}
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        {{ data.item.source.name }}
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        {{ data.item.branch.name }}
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="dateCell">
                                            {{ dateConvert(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(type)="data">
                                        {{ data.item.type }}
                                    </template>
                                    <template v-slot:cell(remark)="data">
                                        {{ data.item.remark }}
                                    </template>

                                    <!-- <template v-slot:cell(action)="data">
                  <div class="cellSize">
                    <b-button
                      class="fa fa-eye px-2"
                      variant="primary"
                      v-b-tooltip.hover.bottomright="{ variant: 'primary' }"
                      title="View"
                      v-b-modal.viewDetail
                      @click="getFollowup(data.item)"
                      ><font-awesome-icon icon="eye"
                    /></b-button>
                    <b-button
                      class="px-2"
                      variant="warning"
                      v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                      title="Followup"
                      @click="getFollowup(data.item)"
                      v-b-modal.followInsert
                      ><font-awesome-icon icon="file-signature"
                    /></b-button>
                    <b-button
                      class="px-2"
                      variant="secondary"
                      v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                      title="Closed"
                      @click="getFollowup(data.item)"
                      v-b-modal.closed
                      ><font-awesome-icon icon="window-close"
                    /></b-button>
                  </div>
                </template> -->
                                </b-table>
                                <!-- </b-card> -->
                            </b-container>
                        </b-card>
                    </b-tab>
                    <b-tab title="ENQUIRIES">
                        <!--ENQUIRIES Table -->

                        <!-- <b-card-text>ENQUIRIES TABLE </b-card-text> -->

                        <b-card class="card mt-1">
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  disabled
                  >Enquiry</b-button
                >
              </b-col>
              <b-col col sm="12" md="3" lg="3" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  v-on:keyup.enter="getSearchCount(keyword)"
                  placeholder="Type a name"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->
                                <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                    <b-pagination v-model="enquiriesCurrentPage" :total-rows="totalEnquiriesCount" :per-page="enquiriesPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchEnquiriesPage(
                          enquiriesCurrentPage,
                          enquiriesPageCount
                        )
                      "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="3" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="enquiriesPageCount" :options="pageOptions" @input="
                        switchEnquiriesPage(
                          enquiriesCurrentPage,
                          enquiriesPageCount
                        )
                      " size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="mx-2">Total Count:</label>
                                    <b-button variant="danger">{{
                      totalEnquiriesCount
                    }}</b-button>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="md" variant="success" class="bnclr" @click="exportEnquiryExcellSheet(selectedReportDates)">Download</b-button>
                                </b-col>
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="refresh()"
                  ><font-awesome-icon icon="sync-alt" />Refresh</span
                >
              </b-col> -->
                            </b-row>
                            <hr />
                            <b-container>
                                <b-table class="my-table" bordered responsive hover :items="allEnquiries" :fields="enquiryFields" :current-page="enquiriesCurrentPage" :per-page="allEnquiries.enquiriesPageCount">
                                    <template v-slot:cell(enquiryId)="data">
                                        <span class="title"> {{ data.item.enquiryId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(agent)="data">
                                        <div class="cellSize">
                                            {{ data.item.agent.firstName }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                        }}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>{{ data.item.alternativeContact }}</div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        <div class="cellSize">
                                            {{ data.item.course.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        <div>
                                            {{ data.item.source.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        <div class="cellSize">
                                            {{ data.item.branch.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:cell(action)="data">
                    <div class="cellSize">
                      <b-button
                        class="px-2"
                        variant="primary"
                        v-b-modal.enquiryView
                        @click="getEnquiry(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'primary' }"
                        title="View Enquiry"
                        ><font-awesome-icon icon="eye"
                      /></b-button>
                      <b-button
                        class="px-2"
                        variant="secondary"
                        @click="enquiryClosed(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'secondary' }"
                        title="Closed Enquiry"
                        ><font-awesome-icon icon="window-close"
                      /></b-button>
                    </div>
                  </template> -->
                                </b-table>
                            </b-container>
                        </b-card>
                    </b-tab>
                    <b-tab title="NOTINTRESTED">
                        <!--NOTINTRESTED Table -->

                        <!-- <b-card-text>NOTINTRESTED TABLE </b-card-text> -->

                        <b-card class="card mt-1">
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  disabled
                  >Enquiry</b-button
                >
              </b-col>
              <b-col col sm="12" md="3" lg="3" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  v-on:keyup.enter="getSearchCount(keyword)"
                  placeholder="Type a name"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->
                                <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                    <b-pagination v-model="notintrestedCurrentPage" :total-rows="totalNotintrestedCount" :per-page="notintrestedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchNotintrestedPage(
                          notintrestedCurrentPage,
                          notintrestedPageCount
                        )
                      "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="3" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="notintrestedPageCount" :options="pageOptions" @input="
                        switchNotintrestedPage(
                          notintrestedCurrentPage,
                          notintrestedPageCount
                        )
                      " size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="mx-2">Total Count:</label>
                                    <b-button variant="danger">{{
                      totalNotintrestedCount
                    }}</b-button>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="md" variant="success" class="bnclr" @click="exportNotintrestedExcellSheet(selectedReportDates)">Download</b-button>
                                </b-col>
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="refresh()"
                  ><font-awesome-icon icon="sync-alt" />Refresh</span
                >
              </b-col> -->
                            </b-row>
                            <hr />
                            <b-container>
                                <b-table class="my-table" bordered responsive hover :items="allNotintrested" :fields="notintrestedFields" :current-page="notintrestedCurrentPage" :per-page="allNotintrested.notintrestedPageCount">
                                    <template v-slot:cell(notintrestedId)="data">
                                        <span class="title"> {{ data.item.notintrestedId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(agent)="data">
                                        <div class="cellSize">
                                            {{ data.item.agent.firstName }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                        }}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>{{ data.item.alternativeContact }}</div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        <div class="cellSize">
                                            {{ data.item.course.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        <div>
                                            {{ data.item.source.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        <div class="cellSize">
                                            {{ data.item.branch.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:cell(action)="data">
                    <div class="cellSize">
                      <b-button
                        class="px-2"
                        variant="primary"
                        v-b-modal.enquiryView
                        @click="getEnquiry(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'primary' }"
                        title="View Enquiry"
                        ><font-awesome-icon icon="eye"
                      /></b-button>
                      <b-button
                        class="px-2"
                        variant="secondary"
                        @click="enquiryClosed(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'secondary' }"
                        title="Closed Enquiry"
                        ><font-awesome-icon icon="window-close"
                      /></b-button>
                    </div>
                  </template> -->
                                </b-table>
                            </b-container>
                        </b-card>
                    </b-tab>
                    <b-tab title="ADMISSION">
                        <!--ADMISSION Table -->

                        <!-- <b-card-text>ADMISSION TABLE </b-card-text> -->

                        <b-card class="card mt-1">
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  disabled
                  >Enquiry</b-button
                >
              </b-col>
              <b-col col sm="12" md="3" lg="3" id="r1">
                <input
                  type="search"
                  class="searchbar"
                  v-model="keyword"
                  v-on:keyup.enter="getSearchCount(keyword)"
                  placeholder="Type a name"
                />
              </b-col>
              <b-col col lg="1" md="1" id="r1" sm="6">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="bnclr"
                  @click="getSearchCount(keyword)"
                  >Search</b-button
                >
              </b-col> -->
                                <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                                    <b-pagination v-model="admissionCurrentPage" :total-rows="totalAdmissionCount" :per-page="admissionPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchAdmissionPage(
                          admissionCurrentPage,
                          admissionPageCount
                        )
                      "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="3" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="admissionPageCount" :options="pageOptions" @input="
                        switchAdmissionPage(
                          admissionCurrentPage,
                          admissionPageCount
                        )
                      " size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="mx-2">Total Count:</label>
                                    <b-button variant="danger">{{
                      totalAdmissionCount
                    }}</b-button>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="md" variant="success" class="bnclr" @click="exportAdmissionExcellSheet(selectedReportDates)">Download</b-button>
                                </b-col>
                                <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                <span style="color: #0974ff; cursor: pointer" @click="refresh()"
                  ><font-awesome-icon icon="sync-alt" />Refresh</span
                >
              </b-col> -->
                            </b-row>
                            <hr />
                            <b-container>
                                <b-table class="my-table" bordered responsive hover :items="allAdmission" :fields="admissionFields" :current-page="admissionCurrentPage" :per-page="allAdmission.admissionPageCount">
                                    <template v-slot:cell(admissionId)="data">
                                        <span class="title"> {{ data.item.admissionId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(agent)="data">
                                        <div class="cellSize">
                                            {{ data.item.agent.firstName }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                        }}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>{{ data.item.alternativeContact }}</div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        <div class="cellSize">
                                            {{ data.item.course.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        <div>
                                            {{ data.item.source.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        <div class="cellSize">
                                            {{ data.item.branch.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:cell(action)="data">
                    <div class="cellSize">
                      <b-button
                        class="px-2"
                        variant="primary"
                        v-b-modal.enquiryView
                        @click="getEnquiry(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'primary' }"
                        title="View Enquiry"
                        ><font-awesome-icon icon="eye"
                      /></b-button>
                      <b-button
                        class="px-2"
                        variant="secondary"
                        @click="enquiryClosed(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'secondary' }"
                        title="Closed Enquiry"
                        ><font-awesome-icon icon="window-close"
                      /></b-button>
                    </div>
                  </template> -->
                                </b-table>
                            </b-container>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </b-tab>

            <!-- <b-tab title="AGENT STATUS" @click="agentRefresh()"> -->
                <!-- agent status start -->
                <!-- <div class="mt-2 row bg">
                    <div class="col-lg-3 col-md-4 col-sm-12 card-top">
                        <b-card class="agentcard shadow-none statusCard" style="min-height:70vh">
                            <div class="text-h2">Agent Status Details</div>
                            <b-card class="mt-5 shadow">
                                <div class="row">
                                    <div class="col-3 col-sm-4 text-start">
                                        <img class="" src="/greenoffline.png" style="width:40px" />
                                    </div>
                                    <div class="col-9 col-sm-8 text-end">
                                        <h3>{{ onlineCount }}</h3>
                                        <span>Online</span>
                                    </div>
                                </div>
                            </b-card>
                            <b-card class="mt-3 shadow">
                                <div class="row">
                                    <div class="col-3 col-sm-4 text-start">
                                        <img class="" src="/redonline.png" style="width:40px" />
                                    </div>
                                    <div class="col-9 col-sm-8 text-end">
                                        <h3>{{offlineCount}}</h3>
                                        <span>Offline</span>
                                    </div>
                                </div>
                            </b-card>
                            <b-card class="mt-3 shadow">
                                <div class="row">
                                    <div class="col-3 col-sm-4 text-start">
                                        <img class="" src="/yellowidle.png" style="width:40px" />
                                    </div>
                                    <div class="col-9 col-sm-8 text-end">
                                        <h3>{{idleCount}}</h3>
                                        <span>Idle</span>
                                    </div>
                                </div>
                            </b-card>
                            <b-card class="mt-3 shadow">
                                <div class="row">
                                    <div class="col-3 col-sm-4 text-start">
                                        <img class="" src="/greyaway.png" style="width:40px" />
                                    </div>
                                    <div class="col-9 col-sm-8 text-end">
                                        <h3>{{awayCount}}</h3>
                                        <span>Away</span>
                                    </div>
                                </div>
                            </b-card>
                        </b-card>
                    </div>
                    <div class="col-sm-9 col-md-8 col-sm-12 ">
                        <div class="text-end">
                            <select v-model="selected" class="mx-3 px-4 py-2 my-1" @change="agentStatusView(selected)">
                                <option value="SelectAll">Select All</option>
                                <option value="Online">Online</option>
                                <option value="Offline">Offline</option>
                                <option value="Idle">Idle</option>
                                <option value="Away">Away</option>
                            </select>
                            <b-button class="bg-primary border-0 ">
                                <span style="color: white; cursor: pointer" @click="agentRefresh()">Refresh</span>
                            </b-button>
                        </div>

                        <div class="table-responsive mx-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <b-table class="tableColor" responsive hover :items="agents" :fields="agentFields" ref="table">
                                        <template v-slot:cell(status)="data">
                                            <div class="text-start tableStatus " style="">
                                                <img v-if="data.item.isIdle" src="../../public/yellowidle.png" width="11">
                                                <img v-else-if="data.item.isAway" src="../../public/greyaway.png" width="11">
                                                <img v-else-if="data.item.isOffline" src="../../public/redonline.png" width="11">
                                                <img v-else-if="data.item.isOnline" src="../../public/greenoffline.png" width="11">
                                                <img v-else src="../../public/rec.png" width="11">

                                                <span v-if="data.item.isIdle" class="px-3">Idle</span>
                                                <span v-else-if="data.item.isAway" class="px-3">Away</span>
                                                <span v-else-if="data.item.isOffline" class="px-3">Offline</span>
                                                <span v-else-if="data.item.isOnline" class="px-3">Online</span>
                                                <span v-else class="px-3">unknown</span>

                                            </div>
                                        </template>
                                        <template v-slot:cell(firstName)="data">
                                            <div class="text-start tableName" style="">
                                                {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                            </div>
                                        </template>

                                        <template v-slot:cell(lastSeen)="data">
                                            <div class="tableDate" v-if="data.item.lastSeen != null">
                                                {{ agentDateFormat( data.item.lastSeen, "fullDate" ) }}
                                            </div>
                                        </template>

                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> -->
                <!-- agent status end -->

            <!-- </b-tab> -->
        </b-tabs>
        <!-- </b-card> -->
    </div>
</div>
</template>

<script>
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import AdminService from "../service/AdminService";
import AdminFollowupService from "../service/AdminFollowupService";
import AdminEnquiryService from "../service/AdminEnquiryService";
import AdminNotintrestedsService from "../service/AdminNotintrestedsService";
import AdminAdmissionsService from "../service/AdminAdmissionsService";
import axios from "axios";
import moment from "moment-timezone";
import Account from '../service/Account.js';
import {
    required
} from "vuelidate/lib/validators";
// import CallService from "../service/CallService";
// import BarChart from "../Charts/BarChart";
// import BarChart from "../Charts/BarChart.vue"
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieChart";
export default {
    name: "Insights",
    components: {
        // BarChart,
        LineChart,
        PieChart,

    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },
    data() {
        return {
            // agent status start
            selected: "SelectAll",
            agentLoading: true,
            // rows: 100,
            agentCurrentPage: 1,

            agentPageCount: 50,
            onlineCount: [],
            awayCount: [],
            offlineCount: [],
            idleCount: [],
            agentTotalCount: 0,

            agentKeyword: "",

            agent: {
                agentId: "",
                name: "",
                contact: "",
                email: "",
                branches: [],
            },
            agents: [],
            agentFields: [{
                    key: "",
                    label: ""
                },
                {
                    key: "status",
                    label: "Status"
                },
                {
                    key: "firstName",
                    label: "Name"
                },
                {
                    key: "lastSeen",
                    label: "Last Seen"
                },

            ],
            // agent status end
            selectedMode: "mode",
            loading: false,
            disableButton: false,
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            // Table Datas Start

            // Calls Table

            paginationCalls: true,
            allSortCalls: false,
            // for calls pagination
            callRows: 100,
            currentPage: 1,
            callsPageCount: 5,
            callsPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalCallCount: 0,
            calls: [],
            fields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },

                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                // {
                //   key: "action",
                //   label: "Action",
                // },
            ],
            keyword: "",

            // Closed Calls Table

            // paginationClosedCalls: true,
            // allSortClosedCalls: false,
            // for closed calls pagination
            closedRows: 100,
            closedCurrentPage: 1,
            closedPageCount: 5,
            closedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalClosedCallCount: 0,
            closedCalls: [],
            closedFields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },

                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                // {
                //   key: "action",
                //   label: "Action",
                // },
            ],
            closedKeyword: "",

            // Followups Table

            paginationFollowups: true,
            allSortFollowups: false,
            // for calls pagination
            followupRows: 100,
            followupsCurrentPage: 1,
            followupsPageCount: 5,
            followupsPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],

            expiredDate: false,
            totalFollowupsCount: 0,
            followups: [],
            followupItems: [{
                    key: "followupDate",
                    label: "Followup_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "agent",
                    label: "Agent Name"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "remark",
                    label: "Remark"
                },
                {
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "type",
                    label: "Type"
                },
                // { key: "action", label: "Action", tdClass: "cellSize" },
            ],

            // Enquiry Table

            paginationEnquiries: true,
            allSortEnquiries: false,
            // for calls pagination
            rows: 100,
            enquiriesCurrentPage: 1,
            enquiriesPageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalEnquiriesCount: 0,
            allEnquiries: [],
            enquiryFields: [{
                    key: "callCreatedAt",
                    label: "Call_Date",
                    tdClass: "cellSize",
                },
                {
                    key: "agent",
                    label: "Agent Name",
                    tdClass: "cellSize",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "course",
                    label: "Course",
                    tdClass: "cellSize",
                },
                {
                    key: "source",
                    label: "Source",
                    tdClass: "cellSize",
                },
                {
                    key: "branch",
                    label: "Branch",
                    tdClass: "cellSize",
                },
                {
                    key: "createdAt",
                    label: "Enquiry_Date",
                    tdClass: "cellSize",
                },
                // {
                //   key: "action",
                //   label: "Action",
                //   tdClass: "cellSize",
                // },
            ],

            // Notintrested Table

            paginationNotintresteds: true,
            allSortNotintresteds: false,
            // for Notintrested pagination
            notintrestedRows: 100,
            notintrestedCurrentPage: 1,
            notintrestedPageCount: 5,
            notintrestedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalNotintrestedCount: 0,
            allNotintrested: [],
            notintrestedFields: [{
                    key: "callCreatedAt",
                    label: "Call_Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "Agent",
                    label: "AgentName"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "createdAt",
                    label: "Notintrested_Date",
                    tdClass: "dateCell"
                },
                // { key: "actions", label: "Actions" },
            ],

            // Admission Table

            paginationAdmissions: true,
            allSortAdmissions: false,
            // for Admission pagination
            admissionRows: 100,
            admissionCurrentPage: 1,
            admissionPageCount: 5,
            admissionPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalAdmissionCount: 0,
            allAdmission: [],
            admissionFields: [{
                    key: "callCreatedAt",
                    label: "Call_Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "Agent",
                    label: "AgentName"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "createdAt",
                    label: "Admission_Date",
                    tdClass: "dateCell"
                },
                // { key: "actions", label: "Actions" },
            ],

            resourceLocation: "https://api-encore.examsdaily.in/uploads/reports/",
            // resourceLocation: "http://localhost:8088/uploads/reports/",
            // Table Datas End
            randomColor: [],
            loadCallsChart: false,
            loadClosedCallsChart: false,
            loadFollowupChart: false,
            loadClosedFollowupChart: false,
            loadAdmissionChart: false,
            loadNotintrestedChart: false,
            loadEnquiryChart: false,
            loadAgentCallChart: false,
            loadAgentFollowupChart: false,
            loadAgentEnquiryChart: false,
            loadAgentAdmissionChart: false,
            loadSourceCallChart: false,
            loadEmptySourceCallChart: false,
            loadSourceFollowupChart: false,
            loadEmptySourceFollowupChart: false,
            loadSourceAdmissionChart: false,
            loadEmptySourceAdmissionChart: false,
            loadSourceNotintrestedChart: false,
            loadEmptySourceNotintrestedChart: false,
            totalCount: null,
            datacollection: [],
            insightValue: [],
            selectedDates: null,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "lastThreeMonths",
                    text: "Past 90 days"
                },
                {
                    value: "allData",
                    text: "Past all days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            from: "",
            to: "",
            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            selectedReportCourse: "Course",
            selectedReportSource: "Source",
            selectedReportBranch: "Branch",
            selectedReportAgent: "Agent",
            selectedReportDates: null,
            selectedReportMode: "mode",

            chartData: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },
            chartData1: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData2: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData3: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData4: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData5: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData6: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData7: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData8: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData9: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData10: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData11: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData12: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData13: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartOptions: {
                responsive: true,
            },

            chartOptions1: {
                responsive: true,
                maintainAspectRatio: false,
                // legend: {
                //     display: true,
                //     position: 'top',
                //     labels: {
                //         fontColor: '#333'
                //     }
                // }
                legend: {
                    position: "top",
                    labels: {
                        // fontColor: 'rgb(255, 99, 132)'
                        fontColor: "#333",
                    },
                    // onHover: function() {
                    //   document.getElementById("canvas").style.cursor = 'pointer';
                    // },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden =
                            ci.getDatasetMeta(index).hidden === null ?
                            false :
                            ci.getDatasetMeta(index).hidden;

                        ci.data.datasets.forEach(function (e, i) {
                            var meta = ci.getDatasetMeta(i);

                            if (i !== index) {
                                if (!alreadyHidden) {
                                    meta.hidden = meta.hidden === null ? !meta.hidden : null;
                                } else if (meta.hidden === null) {
                                    meta.hidden = true;
                                }
                            } else if (i === index) {
                                meta.hidden = null;
                            }
                        });

                        ci.update();
                    },
                },
            },

            chartOptions2: {
                responsive: true,
                maintainAspectRatio: false,
                // legend: {
                //     display: true,
                //     position: 'top',
                //     labels: {
                //         fontColor: '#333'
                //     }
                // }
                legend: {
                    position: "top",
                    labels: {
                        // fontColor: 'rgb(255, 99, 132)'
                        fontColor: "#333",
                    },
                    // onHover: function() {
                    //   document.getElementById("canvas").style.cursor = 'pointer';
                    // },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden =
                            ci.getDatasetMeta(index).hidden === null ?
                            false :
                            ci.getDatasetMeta(index).hidden;

                        ci.data.datasets.forEach(function (e, i) {
                            var meta = ci.getDatasetMeta(i);

                            if (i !== index) {
                                if (!alreadyHidden) {
                                    meta.hidden = meta.hidden === null ? !meta.hidden : null;
                                } else if (meta.hidden === null) {
                                    meta.hidden = true;
                                }
                            } else if (i === index) {
                                meta.hidden = null;
                            }
                        });

                        ci.update();
                    },
                },
            },

            chartOptions3: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        fontColor: "#333",
                    },
                },

                //   legend: {
                //   position: 'top',
                //   labels: {
                //     // fontColor: 'rgb(255, 99, 132)'
                //     fontColor: '#333'
                //   },
                //   // onHover: function() {
                //   //   document.getElementById("canvas").style.cursor = 'pointer';
                //   // },
                //   onClick: function(e, legendItem) {
                //     var index = legendItem.datasetIndex;
                //     var ci = this.chart;
                //     var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;

                //     ci.data.datasets.forEach(function(e, i) {
                //       var meta = ci.getDatasetMeta(i);

                //       if (i !== index) {
                //         if (!alreadyHidden) {
                //           meta.hidden = meta.hidden === null ? !meta.hidden : null;
                //         } else if (meta.hidden === null) {
                //           meta.hidden = true;
                //         }
                //       } else if (i === index) {
                //         meta.hidden = null;
                //       }
                //     });

                //     ci.update();
                //   },
                // },
            },

            chartOptions4: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        fontColor: "#333",
                    },
                },
            },

            chartOptions5: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        fontColor: "#333",
                    },
                },
            },

            chartOptions6: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        fontColor: "#333",
                    },
                },
            },
        };
    },

    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.agentId = localStorage.getItem("agentId");
                    this.loading = true;
                    // agent status start
                    // self.getAgentCount();

                    // agent status end

                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    self.getCallCount();
                    self.getClosedCallCount();
                    self.getFollowupCount();
                    self.getClosedFollowupCount();
                    self.getAdmissionCount();
                    self.getNotintrestedCount();
                    self.getEnquiryCount();
                    // self.agentCount();
                    self.getAgentCallCount();
                    self.getAgentFollowupCount();
                    self.getAgentEnquiryCount();
                    self.getAgentAdmissionCount();
                    self.getSourceCallCount();
                    self.getSourceFollowupCount();
                    self.getSourceAdmissionCount();
                    self.getSourceNotintrestedCount();
                    // console.log("The accquired chartData", this.chartData)

                    self.getCallTableCount();
                    self.getClosedCallTableCount();
                    self.getFollowupTableCount();
                    self.getEnquiryTableCount();
                    self.getNotintrestedTableCount();
                    self.getAdmissionTableCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {

        // agent status start
        agentRefresh() {
            // this.getAgentCount();
            // this.agentKeyword = "";
            this.selected = "SelectAll";
            this.agentStatusView("SelectAll");
        },

        agentStatusView: function (selected) {
            this.loading = true;
            if (selected == "Online") {
                return new Promise((resolve, reject) => {
                    AgentService.getOnlineStatus()
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.agents = response.data;
                            this.loading = false;
                            // this.getAllStatus(this.agentCurrentPage, this.agentPageCount);

                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else if (selected == "Offline") {
                return new Promise((resolve, reject) => {
                    AgentService.getOfflineStatus()
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.agents = response.data;
                            this.loading = false;
                            // this.getAllStatus(this.agentCurrentPage, this.agentPageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            } else if (selected == "Idle") {
                return new Promise((resolve, reject) => {
                    AgentService.getIdleStatus()
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.agents = response.data;
                            this.loading = false;
                            // this.getAllStatus(this.agentCurrentPage, this.agentPageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            } else if (selected == "Away") {
                return new Promise((resolve, reject) => {
                    AgentService.getAwayStatus()
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.agents = response.data;
                            this.loading = false;
                            // this.getAllStatus(this.agentCurrentPage, this.agentPageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            } else if (selected == "SelectAll") {
                return new Promise((resolve, reject) => {
                    AgentService.getAllStatus()
                        .then((response) => {
                            this.agents = response.data;
                            const onlineAgents = this.agents.filter(agent => agent.isOnline === true);
                            this.onlineCount = onlineAgents.length;
                            const offlineAgents = this.agents.filter(agent => agent.isOffline === true);
                            this.offlineCount = offlineAgents.length;
                            const idleAgents = this.agents.filter(agent => agent.isIdle === true);
                            this.idleCount = idleAgents.length;
                            const awayAgents = this.agents.filter(agent => agent.isAway === true);
                            this.awayCount = awayAgents.length;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            }
        },

        getAgentCount: function () {
            this.agentLoading = true;
            this.paginationAgents = true;
            this.searchPaginationAgents = false;
            this.agentCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AgentService.getAgentCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.agentTotalCount = response.data;
                        // this.getAllStatus(this.agentCurrentPage, this.agentPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllStatus: function () {
            // console.log("agentCurrentPage",this.agentCurrentPage)
            //       console.log("agentPageCount",this.agentPageCount)

            return new Promise((resolve, reject) => {
                AgentService.getAllStatus(this.agentCurrentPage, this.agentPageCount)
                    .then((response) => {
                        // console.log("Response",response.data)
                        this.agents = response.data;
                        const onlineAgents = this.agents.filter(agent => agent.isOnline === true);
                        this.onlineCount = onlineAgents.length;
                        console.log("onlineCount", this.onlineCount);
                        this.agents = response.data;
                        const offlineAgents = this.agents.filter(agent => agent.isOffline === true);
                        this.offlineCount = offlineAgents.length;
                        console.log("offlineCount", this.offlineCount);
                        this.agents = response.data;
                        const idleAgents = this.agents.filter(agent => agent.isIdle === true);
                        this.idleCount = idleAgents.length;
                        console.log("idleCount", this.idleCount);
                        this.agents = response.data;
                        const awayAgents = this.agents.filter(agent => agent.isAway === true);
                        this.awayCount = awayAgents.length;
                        console.log("awayCount       ", this.awayCount);

                        resolve(response);
                        this.agentLoading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        agentDateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        // agent status end

        // getDateSortCount: function (selectedDates) {
        //   this.getCallDateSortCount(selectedDates);
        //   this.getClosedCallDateSortCount(selectedDates);
        //   this.getFollowupDateSortCount(selectedDates);
        //   this.getClosedFollowupDateSortCount(selectedDates);
        //   this.getAdmissionDateSortCount(selectedDates);
        //   this.getNotintrestedDateSortCount(selectedDates);
        //   this.getEnquiryDateSortCount(selectedDates);
        //   this.getAgentCallDateSortCount(selectedDates);
        //   this.getAgentFollowupDateSortCount(selectedDates);
        //   this.getSourceCallDateSortCount(selectedDates);
        //   this.getSourceFollowupDateSortCount(selectedDates);
        //   this.getSourceAdmissionDateSortCount(selectedDates);
        //   this.getSourceNotintrestedDateSortCount(selectedDates);
        // },

        // async agentCount(){

        // await this.getAgentCallCount();
        // console.log("getAgentCallCount Started");
        // await this.getAgentFollowupCount();
        // console.log("getAgentFollowupCount Started");
        // },
        async doSortingProcess() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitstatus = "FAIL";
            } else {
                this.submitstatus = "SUCCESS";
                // console.log("doSignInProcess Started");
                await this.getAllSortCount(
                    this.selectedAgent,
                    this.selectedCourse,
                    this.selectedMode,
                    this.selectedSource,
                    this.selectedBranch,
                    this.selectedDates
                );
                await this.getAllSortReportCount(
                    this.selectedReportAgent,
                    this.selectedReportCourse,
                    this.selectedReportMode,
                    this.selectedReportSource,
                    this.selectedReportBranch,
                    this.selectedReportDates
                );
                // console.log("doSignInProcess Ended");
            }
        },

        getAllSortCount: function (selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates) {
            this.getAllSortCallCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortCallTableCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates);
            this.getAllSortClosedCallCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortClosedCallTableCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates);
            this.getAllSortAgentCallCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortAgentFollowupCount(selectedAgent, selectedCourse, selectedSource, selectedBranch, selectedDates);
            this.getAllSortAgentEnquiryCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortAgentAdmissionCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortSourceCallCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortSourceFollowupCount(selectedAgent, selectedCourse, selectedSource, selectedBranch, selectedDates);
            this.getAllSortSourceAdmissionCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortSourceNotintrestedCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            this.getAllSortFollowupCount(selectedAgent, selectedCourse, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortFollowupTableCount(selectedAgent,selectedCourse,selectedSource,selectedBranch,selectedDates);
            this.getAllSortClosedFollowupCount(selectedAgent, selectedCourse, selectedSource, selectedBranch, selectedDates);
            this.getAllSortEnquiryCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortEnquiryTableCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates);
            this.getAllSortAdmissionCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortAdmissionTableCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates);
            this.getAllSortNotintrestedCount(selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates);
            // this.getAllSortNotintrestedTableCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates);
        },
        getAllSortReportCount: function (selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates) {
            this.getAllSortCallTableCount(selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates);
            this.getAllSortClosedCallTableCount(selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates);
            this.getAllSortFollowupTableCount(selectedReportAgent, selectedReportCourse, selectedReportSource, selectedReportBranch, selectedReportDates);
            this.getAllSortEnquiryTableCount(selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates);
            this.getAllSortAdmissionTableCount(selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates);
            this.getAllSortNotintrestedTableCount(selectedReportAgent, selectedReportCourse, selectedReportMode, selectedReportSource, selectedReportBranch, selectedReportDates);
        },

        getCallCount: function () {
            this.loadCallsChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/call/sort/count", this.data)
                    .then((response) => {
                        // console.log("getDateSortCount======", response.data);
                        this.totalCount = response.data;
                        // this.getAllCallPageable(this.currentPage, this.pageCount);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Calls",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "rgba(0, 200, 255, 0.2)",
                                borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData = null;
                        // console.log("The insight call data is ", insightData);
                        this.chartData = insightData;
                        // console.log("The call chart data is ", this.chartData);
                        this.loadCallsChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getCallTableCount: function () {
            // this.showCalls();
            this.loading = true;
            this.allSortCalls = false;
            this.paginationCalls = true;
            this.searchPagination = false;
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            this.selectedReportAgent = "Agent";
            this.keyword = "";
            this.selectedReportDates = null;
            this.currentPage = 1;

            return new Promise((resolve, reject) => {
                AdminService.getCallCount()
                    .then((response) => {
                        this.totalCallCount = response.data;
                        // console.log("totalCallCount",response.data)
                        this.getAllCallPageable(this.currentPage, this.callsPageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllCallPageable: function (currentPage, callsPageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllCallPageable(currentPage, callsPageCount)
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        // console.log(response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getClosedCallCount: function () {
            this.loadClosedCallsChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/closedCall/sort/count", this.data)
                    .then((response) => {
                        // console.log("getDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "ClosedCalls",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "rgba(0, 200, 255, 0.2)",
                                borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData3 = null;
                        // console.log("The insight call data is ", insightData);
                        this.chartData3 = insightData;
                        // console.log("The call chart data is ", this.chartData3);
                        this.loadClosedCallsChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getClosedCallTableCount: function () {
            this.paginationCalls = true;
            this.searchPagination = false;
            this.allSortCalls = false;
            this.closedCurrentPage = 1;
            this.selectedReportAgent = "Agent";
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branches";
            this.selectedReportDates = null;
            // this.closedKeyword = "";
            this.loading = true;

            return new Promise((resolve, reject) => {
                AdminService.getClosedCallCount()
                    .then((response) => {
                        this.totalClosedCallCount = response.data;
                        this.getAllClosedPageable(
                            this.closedCurrentPage,
                            this.closedPageCount
                        );

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedPageable: function (closedCurrentPage, closedPageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllClosedCallPageable(
                        closedCurrentPage,
                        closedPageCount
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getFollowupCount: function () {
            this.loadFollowupChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/followup/sort/count", this.data)
                    .then((response) => {
                        // console.log("getDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Followups",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "#F6F893",
                                borderColor: "#E6CE00",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData1 = null;
                        // console.log("The insight followup data is ", insightData);
                        this.chartData1 = insightData;
                        // console.log("The followup chart data is ", this.chartData);
                        this.loadFollowupChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getFollowupTableCount: function () {
            this.loading = true;
            this.expiredDate = false;
            this.allSortFollowups = false;
            this.paginationFollowups = true;
            // this.searchPagination = false;
            this.selectedReportAgent = "Agent";
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            // this.keyword = "";
            this.selectedReportDates = null;
            this.followupsCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminFollowupService.getTableCount()
                    .then((response) => {
                        this.totalFollowupsCount = response.data;
                        // console.log("totalFollowupsCount",response.data)
                        this.getAllFollowupPageable(
                            this.followupsCurrentPage,
                            this.followupsPageCount
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllFollowupPageable: function (
            followupsCurrentPage,
            followupsPageCount
        ) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllTablePageable(
                        followupsCurrentPage,
                        followupsPageCount
                    )
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllPageable", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getClosedFollowupCount: function () {
            this.loadClosedFollowupChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/closedFollowup/sort/count", this.data)
                    .then((response) => {
                        // console.log("getDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "ClosedFollowups",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "#F6F893",
                                borderColor: "#E6CE00",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData4 = null;
                        // console.log("The insight followup data is ", insightData);
                        this.chartData4 = insightData;
                        // console.log("The followup chart data is ", this.chartData4);
                        this.loadClosedFollowupChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAdmissionCount: function () {
            this.loadAdmissionChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/admission/sort/count", this.data)
                    .then((response) => {
                        // console.log("getDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Admissions",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "#D4F7CF",
                                borderColor: "#29F708",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData2 = null;
                        // console.log("The insight admission data is ", insightData);
                        this.chartData2 = insightData;
                        // console.log("The admission chart data is ", this.chartData);
                        this.loadAdmissionChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAdmissionTableCount: function () {
            this.loading = true;
            this.allSortAdmissions = false;
            this.paginationAdmissions = true;
            // this.searchPagination = false;
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            this.selectedReportAgent = "Agent";
            // this.keyword = "";
            this.selectedReportDates = null;
            this.admissionCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getAdmissionCount()
                    .then((response) => {
                        this.totalAdmissionCount = response.data;
                        // console.log("this.totalCount", this.totalCount);
                        this.getAllAdmissionPageable(
                            this.admissionCurrentPage,
                            this.admissionPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAdmissionPageable: function (
            admissionCurrentPage,
            admissionPageCount
        ) {
            return new Promise((resolve, reject) => {
                AdminAdmissionsService.getAllAdmissionPageable(
                        admissionCurrentPage,
                        admissionPageCount
                    )
                    .then((response) => {
                        this.allAdmission = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getNotintrestedCount: function () {
            this.loadNotintrestedChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.Notintrested====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/notintrested/sort/count", this.data)
                    .then((response) => {
                        // console.log("getNotintrestedDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Notintrested",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "#D4F7CF",
                                borderColor: "#29F708",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData5 = null;
                        // console.log("The insight admission data is ", insightData);
                        this.chartData5 = insightData;
                        // console.log("The admission chart data is ", this.chartData5);
                        this.loadNotintrestedChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getNotintrestedTableCount: function () {
            this.loading = true;
            this.allSortNotintresteds = false;
            this.paginationNotintresteds = true;
            // this.searchPagination = false;
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            this.selectedReportAgent = "Agent";
            // this.keyword = "";
            this.selectedReportDates = null;
            this.notintrestedCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getNotintrestedCount()
                    .then((response) => {
                        this.totalNotintrestedCount = response.data;
                        // console.log("this.totalCount", this.totalCount);
                        this.getAllNotintrestedPageable(
                            this.notintrestedCurrentPage,
                            this.notintrestedPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllNotintrestedPageable: function (
            notintrestedCurrentPage,
            notintrestedPageCount
        ) {
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getAllNotintrestedPageable(
                        notintrestedCurrentPage,
                        notintrestedPageCount
                    )
                    .then((response) => {
                        this.allNotintrested = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getEnquiryCount: function () {
            this.loadEnquiryChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.Enquiry====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/enquiry/sort/count", this.data)
                    .then((response) => {
                        // console.log("getEnquiryDateSortCount======", response.data);
                        this.totalCount = response.data;
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Enquiry",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "rgba(19, 23, 220, 0.22)",
                                borderColor: "#1317DC",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData12 = null;
                        // console.log("The insight admission data is ", insightData);
                        this.chartData12 = insightData;
                        // console.log("The admission chart data is ", this.chartData12);
                        this.loadEnquiryChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getEnquiryTableCount: function () {
            this.loading = true;
            this.allSortEnquiries = false;
            this.paginationEnquiries = true;
            // this.searchPagination = false;
            this.selectedReportCourse = "Course";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            this.selectedReportAgent = "Agent";
            // this.keyword = "";
            this.selectedReportDates = null;
            this.enquiriesCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getEnquiryCount()
                    .then((response) => {
                        this.totalEnquiriesCount = response.data;
                        this.getAllEnquiryPageable(
                            this.enquiriesCurrentPage,
                            this.enquiriesPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllEnquiryPageable: function (enquiriesCurrentPage, enquiriesPageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllEnquiryPageable(
                        enquiriesCurrentPage,
                        enquiriesPageCount,
                        this.agentId
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAgentCallCount: function () {
            this.loadAgentCallChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/agentCall/sort/count", this.data)
                    .then((response) => {
                        // console.log("getAgentCallDateSortCount======", response.data);
                        this.totalCount = response.data;

                        // let insightData = {
                        //   labels: response.data.agentNames,
                        //   datasets: [
                        //     {
                        //       label: [],
                        //       // backgroundColor: "#f87979",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       // borderColor: "rgb(0,200,255, 0.6)",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       fill: false,
                        //       borderColor: "rgb(0,200,255, 0.6)",
                        //       data: [],
                        //     },
                        //   ],
                        // };

                        let insightData = {
                            labels: response.data.dates,
                            datasets: [],
                        };

                        for (let i = 0; i < response.data.agentNames.length; i++) {
                            let callData = [];

                            response.data.agentsData[i].forEach((e) => {
                                callData.push(e[0]);
                            });

                            let gdata = {
                                fill: false,
                                borderColor: this.generateRandomColor(),
                                label: response.data.agentNames[i],
                                data: callData,
                            };
                            //  console.log(this.generateRandomColor(),"=======");
                            //        this.randomColor = this.generateRandomColor();
                            // console.log(this.randomColor,"=======");
                            // if(gdata.borderColor != null){
                            //   // insightData.datasets.push(gdata);

                            // }
                            insightData.datasets.push(gdata);
                            // console.log(insightData, "=======");
                            // let color = this.generateRandomColor();
                            let color = gdata.borderColor;
                            // console.log(color, "=======");
                            this.randomColor.push(color);

                            // console.log(color,"=======");
                        }

                        /*
            response.data.forEach((e) => {
              console.log(e[1]);
              insightData.labels.push(e[0]);
              // insightData.datasets[0].label.push(e[1]);
              insightData.datasets[0].data.push(e[1]);
            });
            // this.datacollection.datasets = null;
*/
                        // console.log("Bar Chart", insightData);
                        this.chartData6 = null;
                        // console.log("The insight Agent data is ", insightData);
                        this.chartData6 = insightData;
                        // console.log("The call Agent data is ", this.chartData6);
                        this.loadAgentCallChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAgentFollowupCount: function () {
            this.loadAgentFollowupChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/agentFollowup/sort/count", this.data)
                    .then((response) => {
                        // console.log("getAgentFollowupDateSortCount======", response.data);
                        this.totalCount = response.data;

                        // let insightData = {
                        //   labels: response.data.agentNames,
                        //   datasets: [
                        //     {
                        //       label: [],
                        //       // backgroundColor: "#f87979",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       // borderColor: "rgb(0,200,255, 0.6)",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       fill: false,
                        //       borderColor: "rgb(0,200,255, 0.6)",
                        //       data: [],
                        //     },
                        //   ],
                        // };

                        let insightData = {
                            labels: response.data.dates,
                            datasets: [],
                        };

                        for (let i = 0; i < response.data.agentNames.length; i++) {
                            // console.log(this.randomColor[i],"======2=======")
                            let callData = [];

                            response.data.agentsData[i].forEach((e) => {
                                callData.push(e[0]);
                            });

                            let gdata = {
                                fill: false,
                                // borderColor: this.generateRandomColor(),
                                borderColor: this.randomColor[i],
                                label: response.data.agentNames[i],
                                data: callData,
                            };

                            insightData.datasets.push(gdata);
                            // console.log(insightData,"======2=======")
                        }

                        /*
            response.data.forEach((e) => {
              console.log(e[1]);
              insightData.labels.push(e[0]);
              // insightData.datasets[0].label.push(e[1]);
              insightData.datasets[0].data.push(e[1]);
            });
            // this.datacollection.datasets = null;
*/
                        // console.log("Bar Chart", insightData);
                        this.chartData7 = null;
                        // console.log("The insight Agent data is ", insightData);
                        this.chartData7 = insightData;
                        // console.log("The call Agent data is ", this.chartData7);
                        this.loadAgentFollowupChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAgentEnquiryCount: function () {
            this.loadAgentEnquiryChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/agentEnquiry/sort/count", this.data)
                    .then((response) => {
                        // console.log("getAgentEnquiryDateSortCount======", response.data);
                        this.totalCount = response.data;

                        // let insightData = {
                        //   labels: response.data.agentNames,
                        //   datasets: [
                        //     {
                        //       label: [],
                        //       // backgroundColor: "#f87979",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       // borderColor: "rgb(0,200,255, 0.6)",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       fill: false,
                        //       borderColor: "rgb(0,200,255, 0.6)",
                        //       data: [],
                        //     },
                        //   ],
                        // };

                        let insightData = {
                            labels: response.data.dates,
                            datasets: [],
                        };

                        for (let i = 0; i < response.data.agentNames.length; i++) {
                            let callData = [];

                            response.data.agentsData[i].forEach((e) => {
                                callData.push(e[0]);
                            });

                            let gdata = {
                                fill: false,
                                // borderColor: this.generateRandomColor(),
                                borderColor: this.randomColor[i],
                                label: response.data.agentNames[i],
                                data: callData,
                            };

                            insightData.datasets.push(gdata);
                        }

                        /*
            response.data.forEach((e) => {
              console.log(e[1]);
              insightData.labels.push(e[0]);
              // insightData.datasets[0].label.push(e[1]);
              insightData.datasets[0].data.push(e[1]);
            });
            // this.datacollection.datasets = null;
*/
                        // console.log("Bar Chart", insightData);
                        this.chartData13 = null;
                        // console.log("The insight Agent data is ", insightData);
                        this.chartData13 = insightData;
                        // console.log("The call Agent data is ", this.chartData7);
                        this.loadAgentEnquiryChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAgentAdmissionCount: function () {
            this.loadAgentAdmissionChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/agentAdmission/sort/count", this.data)
                    .then((response) => {
                        // console.log("getAgentAdmissionDateSortCount======", response.data);
                        this.totalCount = response.data;

                        // let insightData = {
                        //   labels: response.data.agentNames,
                        //   datasets: [
                        //     {
                        //       label: [],
                        //       // backgroundColor: "#f87979",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       // borderColor: "rgb(0,200,255, 0.6)",
                        //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                        //       fill: false,
                        //       borderColor: "rgb(0,200,255, 0.6)",
                        //       data: [],
                        //     },
                        //   ],
                        // };

                        let insightData = {
                            labels: response.data.dates,
                            datasets: [],
                        };

                        for (let i = 0; i < response.data.agentNames.length; i++) {
                            let callData = [];

                            response.data.agentsData[i].forEach((e) => {
                                callData.push(e[0]);
                            });

                            let gdata = {
                                fill: false,
                                // borderColor: this.generateRandomColor(),
                                borderColor: this.randomColor[i],
                                label: response.data.agentNames[i],
                                data: callData,
                            };

                            insightData.datasets.push(gdata);
                        }

                        /*
            response.data.forEach((e) => {
              console.log(e[1]);
              insightData.labels.push(e[0]);
              // insightData.datasets[0].label.push(e[1]);
              insightData.datasets[0].data.push(e[1]);
            });
            // this.datacollection.datasets = null;
*/
                        // console.log("Bar Chart", insightData);
                        this.chartData13 = null;
                        // console.log("The insight Agent data is ", insightData);
                        this.chartData13 = insightData;
                        // console.log("The call Agent data is ", this.chartData7);
                        this.loadAgentAdmissionChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSourceCallCount: function () {
            this.loadSourceCallChart = false;
            this.loadEmptySourceCallChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/sourceCall/sort/count", this.data)
                    .then((response) => {
                        // console.log("getSourceCallDateSortCount======", response.data);
                        this.totalCount = response.data;
                        // console.log(response.data.length);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: response.data.sourceNames,
                                backgroundColor: this.pieColors(response.data.length),
                                // borderColor: "rgb(0,200,255, 0.6)",
                                // fillColor : this.getRandomColor(),
                                // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };

                        // for(let i=0; i<response.data.sourceNames; i++){

                        //     this.gdata = {
                        //     // fill: false,
                        //     backgroundColor: this.generateRandomColor(),
                        //     label: response.data.sourceNames[i],
                        //     data: this.callData
                        //   };

                        //   insightData.datasets.push(this.gdata);
                        // }
                        response.data.forEach((e) => {
                            // console.log(e[1]);
                            insightData.labels.push(e[0]);
                            // insightData.datasets[0].label.push(e[1]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;

                        if (response.data.length == "0") {
                            this.loadEmptySourceCallChart = true;
                            this.loadSourceCallChart = false;
                        } else {
                            this.loadEmptySourceCallChart = false;
                            this.loadSourceCallChart = true;
                        }
                        // console.log("Bar Chart", insightData);
                        this.chartData8 = null;
                        // console.log("The insight Source data is ", insightData);
                        this.chartData8 = insightData;
                        // console.log("The call Source data is ", this.chartData8);
                        this.loadSourceCallChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSourceFollowupCount: function () {
            this.loadSourceFollowupChart = false;
            this.loadEmptySourceFollowupChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/sourceFollowup/sort/count", this.data)
                    .then((response) => {
                        // console.log("getSourceFollowupDateSortCount======", response.data);
                        this.totalCount = response.data;
                        // console.log(response.data.length);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: response.data.sourceNames,
                                backgroundColor: this.pieColors(response.data.length),
                                // borderColor: "rgb(0,200,255, 0.6)",
                                // fillColor : this.getRandomColor(),
                                // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };

                        // for(let i=0; i<response.data.sourceNames; i++){

                        //     this.gdata = {
                        //     // fill: false,
                        //     backgroundColor: this.generateRandomColor(),
                        //     label: response.data.sourceNames[i],
                        //     data: this.callData
                        //   };

                        //   insightData.datasets.push(this.gdata);
                        // }

                        response.data.forEach((e) => {
                            // console.log(e[1]);
                            insightData.labels.push(e[0]);
                            // insightData.datasets[0].label.push(e[1]);
                            insightData.datasets[0].data.push(e[1]);
                        });

                        if (response.data.length == "0") {
                            this.loadEmptySourceFollowupChart = true;
                            this.loadSourceFollowupChart = false;
                        } else {
                            this.loadEmptySourceFollowupChart = false;
                            this.loadSourceFollowupChart = true;
                        }
                        // this.datacollection.datasets = null;

                        // console.log("Bar Chart", insightData);
                        this.chartData9 = null;
                        // console.log("The insight Source data is ", insightData);
                        this.chartData9 = insightData;
                        // console.log("The call Source data is ", this.chartData9);
                        this.loadSourceFollowupChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSourceAdmissionCount: function () {
            this.loadSourceAdmissionChart = false;
            this.loadEmptySourceAdmissionChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/sourceAdmission/sort/count", this.data)
                    .then((response) => {
                        // console.log("getSourceAdmissionDateSortCount======", response.data);
                        this.totalCount = response.data;
                        // console.log(response.data.length);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: response.data.sourceNames,
                                backgroundColor: this.pieColors(response.data.length),
                                // borderColor: "rgb(0,200,255, 0.6)",
                                // fillColor : this.getRandomColor(),
                                // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };

                        // for(let i=0; i<response.data.sourceNames; i++){

                        //     this.gdata = {
                        //     // fill: false,
                        //     backgroundColor: this.generateRandomColor(),
                        //     label: response.data.sourceNames[i],
                        //     data: this.callData
                        //   };

                        //   insightData.datasets.push(this.gdata);
                        // }

                        response.data.forEach((e) => {
                            // console.log(e[1]);
                            insightData.labels.push(e[0]);
                            // insightData.datasets[0].label.push(e[1]);
                            insightData.datasets[0].data.push(e[1]);
                        });

                        if (response.data.length == "0") {
                            this.loadEmptySourceAdmissionChart = true;
                            this.loadSourceAdmissionChart = false;
                        } else {
                            this.loadEmptySourceAdmissionChart = false;
                            this.loadSourceAdmissionChart = true;
                        }

                        // this.datacollection.datasets = null;

                        // console.log("Bar Chart", insightData);
                        this.chartData10 = null;
                        // console.log("The insight Source data is ", insightData);
                        this.chartData10 = insightData;
                        // console.log("The call Source data is ", this.chartData10);
                        this.loadSourceAdmissionChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSourceNotintrestedCount: function () {
            this.loadSourceNotintrestedChart = false;
            this.loadEmptySourceNotintrestedChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/insight/sourceNotintrested/sort/count", this.data)
                    .then((response) => {
                        // console.log(
                        //   "getSourceNotintrestedDateSortCount======",
                        //   response.data
                        // );
                        this.totalCount = response.data;
                        // console.log(response.data.length);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: response.data.sourceNames,
                                backgroundColor: this.pieColors(response.data.length),
                                // borderColor: "rgb(0,200,255, 0.6)",
                                // fillColor : this.getRandomColor(),
                                // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };

                        // for(let i=0; i<response.data.sourceNames; i++){

                        //     this.gdata = {
                        //     // fill: false,
                        //     backgroundColor: this.generateRandomColor(),
                        //     label: response.data.sourceNames[i],
                        //     data: this.callData
                        //   };

                        //   insightData.datasets.push(this.gdata);
                        // }

                        response.data.forEach((e) => {
                            // console.log(e[1]);
                            insightData.labels.push(e[0]);
                            // insightData.datasets[0].label.push(e[1]);
                            insightData.datasets[0].data.push(e[1]);
                        });

                        if (response.data.length == "0") {
                            this.loadEmptySourceNotintrestedChart = true;
                            this.loadSourceNotintrestedChart = false;
                        } else {
                            this.loadEmptySourceNotintrestedChart = false;
                            this.loadSourceNotintrestedChart = true;
                        }

                        // this.datacollection.datasets = null;

                        // console.log("Bar Chart", insightData);
                        this.chartData11 = null;
                        // console.log("The insight Source data is ", insightData);
                        this.chartData11 = insightData;
                        // console.log("The call Source data is ", this.chartData11);
                        this.loadSourceNotintrestedChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSortCallCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadCallsChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/call/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCallCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Calls",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadCallsChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortCallTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportMode,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportMode == "mode" || selectedReportMode == "allModes") {
                    selectedReportMode = "";
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }
                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    mode: selectedReportMode,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                // console.log("=====this.data=========", this.data);
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/call/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCallTableCount======", response.data);
                            this.totalCallCount = response.data;
                            // console.log("totalCallCount",response.data)
                            this.getAllSortCallPageable(
                                this.currentPage,
                                this.callsPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortCallPageable: function (currentPage, callsPageCount, data) {
            // console.log("======data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/call/all/sort/" + currentPage + "/" + callsPageCount,
                        data
                    )
                    .then((response) => {
                        this.calls = response.data;
                        // console.log("getAllSortCallPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortClosedCallCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadClosedCallsChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/closedCall/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedCallCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "ClosedCalls",
                                    // backgroundColor: "#f87979",
                                    backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData3 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData3 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadClosedCallsChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortClosedCallTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportMode,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportMode == "mode" || selectedReportMode == "allModes") {
                    selectedReportMode = "";
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    mode: selectedReportMode,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                // this.searchPagination = false;
                this.closedCurrentPage = 1;
                // this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/closedCall/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedCount======", response.data);
                            this.totalClosedCallCount = response.data;
                            this.getAllSortClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/closedCall/all/sort/" +
                        closedCurrentPage +
                        "/" +
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedCalls = response.data;
                        // console.log("getAllSortClosedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortAgentCallCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAgentCallChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allAgentCall/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAgentCallCount======", response.data);
                            this.totalCount = response.data;

                            let insightData = {
                                labels: response.data.dates,
                                datasets: [],
                            };

                            for (let i = 0; i < response.data.agentNames.length; i++) {
                                let callData = [];

                                response.data.agentsData[i].forEach((e) => {
                                    callData.push(e[0]);
                                });

                                let gdata = {
                                    fill: false,
                                    // borderColor: this.generateRandomColor(),
                                    borderColor: this.randomColor[i],
                                    label: response.data.agentNames[i],
                                    data: callData,
                                };

                                insightData.datasets.push(gdata);
                            }

                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "ClosedCalls",
                            //       // backgroundColor: "#f87979",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: []
                            //     },
                            //   ],
                            // };
                            // response.data.forEach((e) => {
                            //   // console.log(e);
                            //   insightData.labels.push(e[0]);
                            //   insightData.datasets[0].data.push(e[1]);
                            // });

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData6 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData6 = insightData;
                            // console.log("The call chart data is ", this.chartData6);
                            this.loadAgentCallChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAgentFollowupCount: function (
            selectedAgent,
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAgentFollowupChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allAgentFollowup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAgentFollowupCount======", response.data);
                            this.totalCount = response.data;

                            let insightData = {
                                labels: response.data.dates,
                                datasets: [],
                            };

                            for (let i = 0; i < response.data.agentNames.length; i++) {
                                let callData = [];

                                response.data.agentsData[i].forEach((e) => {
                                    callData.push(e[0]);
                                });

                                let gdata = {
                                    fill: false,
                                    // borderColor: this.generateRandomColor(),
                                    borderColor: this.randomColor[i],
                                    label: response.data.agentNames[i],
                                    data: callData,
                                };

                                insightData.datasets.push(gdata);
                            }

                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "ClosedCalls",
                            //       // backgroundColor: "#f87979",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: []
                            //     },
                            //   ],
                            // };
                            // response.data.forEach((e) => {
                            //   // console.log(e);
                            //   insightData.labels.push(e[0]);
                            //   insightData.datasets[0].data.push(e[1]);
                            // });

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData7 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData7 = insightData;
                            // console.log("The call chart data is ", this.chartData7);
                            this.loadAgentFollowupChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAgentEnquiryCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAgentEnquiryChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allAgentEnquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAgentEnquiryCount======", response.data);
                            this.totalCount = response.data;

                            let insightData = {
                                labels: response.data.dates,
                                datasets: [],
                            };

                            for (let i = 0; i < response.data.agentNames.length; i++) {
                                let callData = [];

                                response.data.agentsData[i].forEach((e) => {
                                    callData.push(e[0]);
                                });

                                let gdata = {
                                    fill: false,
                                    // borderColor: this.generateRandomColor(),
                                    borderColor: this.randomColor[i],
                                    label: response.data.agentNames[i],
                                    data: callData,
                                };

                                insightData.datasets.push(gdata);
                            }

                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "ClosedCalls",
                            //       // backgroundColor: "#f87979",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: []
                            //     },
                            //   ],
                            // };
                            // response.data.forEach((e) => {
                            //   // console.log(e);
                            //   insightData.labels.push(e[0]);
                            //   insightData.datasets[0].data.push(e[1]);
                            // });

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData13 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData13 = insightData;
                            // console.log("The call chart data is ", this.chartData7);
                            this.loadAgentEnquiryChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAgentAdmissionCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAgentAdmissionChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allAgentAdmission/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAgentAdmissionCount======", response.data);
                            this.totalCount = response.data;

                            let insightData = {
                                labels: response.data.dates,
                                datasets: [],
                            };

                            for (let i = 0; i < response.data.agentNames.length; i++) {
                                let callData = [];

                                response.data.agentsData[i].forEach((e) => {
                                    callData.push(e[0]);
                                });

                                let gdata = {
                                    fill: false,
                                    // borderColor: this.generateRandomColor(),
                                    borderColor: this.randomColor[i],
                                    label: response.data.agentNames[i],
                                    data: callData,
                                };

                                insightData.datasets.push(gdata);
                            }

                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "ClosedCalls",
                            //       // backgroundColor: "#f87979",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: []
                            //     },
                            //   ],
                            // };
                            // response.data.forEach((e) => {
                            //   // console.log(e);
                            //   insightData.labels.push(e[0]);
                            //   insightData.datasets[0].data.push(e[1]);
                            // });

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData13 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData13 = insightData;
                            // console.log("The call chart data is ", this.chartData7);
                            this.loadAgentAdmissionChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortSourceCallCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadSourceCallChart = false;
                this.loadEmptySourceCallChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allSourceCall/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortSourceCallCount======", response.data);
                            this.totalCount = response.data;
                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "Calls",
                            //       // backgroundColor: "#f87979",
                            //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       // borderColor: "rgb(0,200,255, 0.6)",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: [],
                            //     },
                            //   ],
                            // };

                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: response.data.sourceNames,
                                    backgroundColor: this.pieColors(response.data.length),
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    // fillColor : this.getRandomColor(),
                                    // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };

                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });

                            if (response.data.length == "0") {
                                this.loadEmptySourceCallChart = true;
                                this.loadSourceCallChart = false;
                            } else {
                                this.loadEmptySourceCallChart = false;
                                this.loadSourceCallChart = true;
                            }
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData8 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData8 = insightData;
                            // console.log("The call chart data is ", this.chartData8);
                            this.loadSourceCallChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortSourceFollowupCount: function (
            selectedAgent,
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadSourceFollowupChart = false;
                this.loadEmptySourceFollowupChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allSourceFollowup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortSourceFollowupCount======", response.data);
                            this.totalCount = response.data;
                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "Calls",
                            //       // backgroundColor: "#f87979",
                            //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       // borderColor: "rgb(0,200,255, 0.6)",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: [],
                            //     },
                            //   ],
                            // };

                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: response.data.sourceNames,
                                    backgroundColor: this.pieColors(response.data.length),
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    // fillColor : this.getRandomColor(),
                                    // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };

                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });

                            if (response.data.length == "0") {
                                this.loadEmptySourceFollowupChart = true;
                                this.loadSourceFollowupChart = false;
                            } else {
                                this.loadEmptySourceFollowupChart = false;
                                this.loadSourceFollowupChart = true;
                            }
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData9 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData9 = insightData;
                            // console.log("The call chart data is ", this.chartData9);
                            this.loadSourceFollowupChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortSourceAdmissionCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadSourceAdmissionChart = false;
                this.loadEmptySourceAdmissionChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allSourceAdmission/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortSourceAdmissionCount======", response.data);
                            this.totalCount = response.data;
                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "Calls",
                            //       // backgroundColor: "#f87979",
                            //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       // borderColor: "rgb(0,200,255, 0.6)",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: [],
                            //     },
                            //   ],
                            // };

                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: response.data.sourceNames,
                                    backgroundColor: this.pieColors(response.data.length),
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    // fillColor : this.getRandomColor(),
                                    // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };

                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });

                            if (response.data.length == "0") {
                                this.loadEmptySourceAdmissionChart = true;
                                this.loadSourceAdmissionChart = false;
                            } else {
                                this.loadEmptySourceAdmissionChart = false;
                                this.loadSourceAdmissionChart = true;
                            }

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData10 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData10 = insightData;
                            // console.log("The call chart data is ", this.chartData10);
                            this.loadSourceAdmissionChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortSourceNotintrestedCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadSourceNotintrestedChart = false;
                this.loadEmptySourceNotintrestedChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/allSourceNotintrested/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortSourceNotintrestedCount======", response.data);
                            this.totalCount = response.data;
                            // let insightData = {
                            //   labels: [],
                            //   datasets: [
                            //     {
                            //       label: "Calls",
                            //       // backgroundColor: "#f87979",
                            //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       // borderColor: "rgb(0,200,255, 0.6)",
                            //       backgroundColor: "rgba(0, 200, 255, 0.2)",
                            //       borderColor: "rgb(0,200,255, 0.6)",
                            //       data: [],
                            //     },
                            //   ],
                            // };

                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: response.data.sourceNames,
                                    backgroundColor: this.pieColors(response.data.length),
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    // fillColor : this.getRandomColor(),
                                    // backgroundColor:"rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };

                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });

                            if (response.data.length == "0") {
                                this.loadEmptySourceNotintrestedChart = true;
                                this.loadSourceNotintrestedChart = false;
                            } else {
                                this.loadEmptySourceNotintrestedChart = false;
                                this.loadSourceNotintrestedChart = true;
                            }

                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData11 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData11 = insightData;
                            // console.log("The call chart data is ", this.chartData11);
                            this.loadSourceNotintrestedChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortFollowupCount: function (
            selectedAgent,
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadFollowupChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/followup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortFollowupCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Followups",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "#F6F893",
                                    borderColor: "#E6CE00",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData1 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData1 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadFollowupChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortFollowupTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.allSortFollowups = true;
                this.paginationFollowups = false;
                // this.searchPagination = false;
                this.followupsCurrentPage = 1;
                // this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/followupTable/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortFollowupTableCount======", response.data);
                            this.totalFollowupsCount = response.data;
                            this.getAllSortFollowupPageable(
                                this.followupsCurrentPage,
                                this.followupsPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortFollowupPageable: function (
            followupsCurrentPage,
            followupsPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/insight/followupTable/all/sort/" +
                        followupsCurrentPage +
                        "/" +
                        followupsPageCount,
                        data
                    )
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllSortFollowupPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortClosedFollowupCount: function (
            selectedAgent,
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadClosedFollowupChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/closedFollowup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedFollowupCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "ClosedFollowups",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "#F6F893",
                                    borderColor: "#E6CE00",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData4 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData4 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadClosedFollowupChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortEnquiryCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadEnquiryChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/enquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortEnquiryCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Enquiry",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "rgba(19, 23, 220, 0.22)",
                                    borderColor: "#1317DC",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData12 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData12 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadEnquiryChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortEnquiryTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportMode,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportMode == "mode" || selectedReportMode == "allModes") {
                    selectedReportMode = "";
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    mode: selectedReportMode,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.allSortEnquiries = true;
                this.paginationEnquiries = false;
                // this.searchPagination = false;
                this.enquiriesCurrentPage = 1;
                // this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminenquiry/enquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortEnquiryTableCount======", response.data);
                            this.totalEnquiriesCount = response.data;
                            this.getAllSortEnquiryPageable(
                                this.enquiriesCurrentPage,
                                this.enquiriesPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortEnquiryPageable: function (
            enquiriesCurrentPage,
            enquiriesPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminenquiry/enquiry/all/sort/" +
                        enquiriesCurrentPage +
                        "/" +
                        enquiriesPageCount,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        // console.log("getAllSortEnquiryPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortAdmissionCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAdmissionChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/admission/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAdmissionCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Admissions",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "#D4F7CF",
                                    borderColor: "#29F708",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData2 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData2 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadAdmissionChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAdmissionTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportMode,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportMode == "mode" || selectedReportMode == "allModes") {
                    selectedReportMode = "";
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    mode: selectedReportMode,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.allSortAdmissions = true;
                this.paginationAdmissions = false;
                this.searchPagination = false;
                this.admissionCurrentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/admission/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortAdmissionTableCount======", response.data);
                            this.totalAdmissionCount = response.data;
                            this.getAllSortAdmissionPageable(
                                this.admissionCurrentPage,
                                this.admissionPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAdmissionPageable: function (
            admissionCurrentPage,
            admissionPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/admission/all/sort/" +
                        admissionCurrentPage +
                        "/" +
                        admissionPageCount,
                        data
                    )
                    .then((response) => {
                        this.allAdmission = response.data;
                        // console.log("getAllSortAdmissionPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortNotintrestedCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadNotintrestedChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/notintrested/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortNotintrestedCount======", response.data);
                            this.totalCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Notintrested",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "#D4F7CF",
                                    borderColor: "#29F708",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Bar Chart", insightData);
                            this.chartData5 = null;
                            // console.log("The insight call data is ", insightData);
                            this.chartData5 = insightData;
                            // console.log("The call chart data is ", this.chartData);
                            this.loadNotintrestedChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortNotintrestedTableCount: function (
            selectedReportAgent,
            selectedReportCourse,
            selectedReportMode,
            selectedReportSource,
            selectedReportBranch,
            selectedReportDates
        ) {
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
            } else {
                if (selectedReportAgent == "Agent" || selectedReportAgent == "AllAgents") {
                    selectedReportAgent = {
                        agentId: "",
                    };
                }
                if (selectedReportCourse == "Course" || selectedReportCourse == "AllCourses") {
                    selectedReportCourse = {
                        courseId: "",
                    };
                }
                if (selectedReportMode == "mode" || selectedReportMode == "allModes") {
                    selectedReportMode = "";
                }
                if (selectedReportSource == "Source" || selectedReportSource == "AllSources") {
                    selectedReportSource = {
                        sourceId: "",
                    };
                }
                if (selectedReportBranch == "Branch" || selectedReportBranch == "AllBranches") {
                    selectedReportBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                // if (selectedDates != null) {
                //   var date = new Date();
                //   if (selectedDates == "today") {
                //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "yesterday") {
                //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
                //   } else if (selectedDates == "lastWeek") {
                //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
                //   } else if (selectedDates == "lastMonth") {
                //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
                //   } else if (
                //     this.from != "" &&
                //     this.to != "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     var fromDateObject = new Date(this.from);
                //     var toDateObject = new Date(this.to);
                //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                //   } else if (
                //     this.from == "" &&
                //     this.to == "" &&
                //     selectedDates == "dateBetween"
                //   ) {
                //     this.$refs.callDateRange.hide();
                //     this.fromDate = "2021-01-01 00:01";
                //     this.toDate = new Date();
                //   }
                //   this.fromDateFormat = moment(String(this.fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   this.toDateFormat = moment(String(this.toDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   from = this.fromDateFormat;
                //   to = this.toDateFormat;
                // }

                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }

                var dateOffset = 0;
                if (selectedReportDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedReportDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedReportDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedReportDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }

                this.data = {
                    agentId: selectedReportAgent.agentId,
                    courseId: selectedReportCourse.courseId,
                    mode: selectedReportMode,
                    sourceId: selectedReportSource.sourceId,
                    branchId: selectedReportBranch.branchId,
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.allSortNotintresteds = true;
                this.paginationNotintresteds = false;
                this.searchPagination = false;
                this.notintrestedCurrentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminNotintrested/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortNotintrestedTableCount======", response.data);
                            this.totalNotintrestedCount = response.data;
                            this.getAllSortNotintrestedPageable(
                                this.notintrestedCurrentPage,
                                this.notintrestedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortNotintrestedPageable: function (
            notintrestedCurrentPage,
            notintrestedPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminNotintrested/all/sort/" +
                        notintrestedCurrentPage +
                        "/" +
                        notintrestedPageCount,
                        data
                    )
                    .then((response) => {
                        this.allNotintrested = response.data;
                        // console.log("getAllSortNotintrestedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        pieColors: function (count) {
            var pie = [];
            for (let i = 0; i < count; i++) {
                pie.push(this.generateRandomColor());
            }
            return pie;
        },

        // getRandomColorEachSource: function () {
        //   var data = [];
        //   for (var i = 0; i < length; i++) {
        //     data.push(this.getRandomColor());
        //   }
        //   return data;
        // },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        generateRandomColor: function () {
            let maxVal = 0xffffff; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            // this.randomColor = randColor;
            // console.log(this.randomColor,"=======");
            return `#${randColor.toUpperCase()}`;
        },

        getRandomColor: function () {
            var letters = "0123456789ABCDEF".split("");
            var color = "#";
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        getDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getReportDateRangeReset: function () {
            this.$refs.callReportDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        refresh: function () {
            this.selectedCourse = "Course";
            this.selectedMode = "mode";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";

            this.getCallCount();
            this.getClosedCallCount();
            this.getFollowupCount();
            this.getClosedFollowupCount();
            this.getAdmissionCount();
            this.getNotintrestedCount();
            this.getEnquiryCount();
            this.getAgentCallCount();
            this.getAgentFollowupCount();
            this.getAgentEnquiryCount();
            this.getAgentAdmissionCount();
            this.getSourceCallCount();
            this.getSourceFollowupCount();
            this.getSourceAdmissionCount();
            this.getSourceNotintrestedCount();

            // this.getCallTableCount();
            // this.getClosedCallTableCount();
            // this.getFollowupTableCount();
            // this.getEnquiryTableCount();
            // this.getNotintrestedTableCount();
            // this.getAdmissionTableCount();

            this.selectedDates = null;
        },

        refreshReport: function () {
            this.selectedReportCourse = "Course";
            this.selectedReportMode = "mode";
            this.selectedReportSource = "Source";
            this.selectedReportBranch = "Branch";
            this.selectedReportAgent = "Agent";

            this.getCallTableCount();
            this.getClosedCallTableCount();
            this.getFollowupTableCount();
            this.getEnquiryTableCount();
            this.getNotintrestedTableCount();
            this.getAdmissionTableCount();

            this.selectedReportDates = null;
        },

        // refresh: function () {
        //   // this.getAllAdmissions();
        //   this.getCount();
        //   this.selectedDates = null;
        // },

        // Table Methods And Functions

        switchCallsPage: function (currentPage, callsPageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllCallPageable(currentPage, callsPageCount);
                // console.log(this.paginationCalls, "this.paginationCalls");
                // console.log(this.allSortCalls, "this.allSortCalls");
            } else if (this.allSortCalls) {
                this.getAllSortCallPageable(currentPage, callsPageCount, this.data);
                // console.log(this.allSortCalls, "this.allSortCalls");
                // console.log(this.paginationCalls, "this.paginationCalls");
            }
            // else if (this.searchPagination) {
            //   this.getAllCallSearchPageable(currentPage, callsPageCount, this.keyword);
            //   //console.log(this.searchPagination,"searchPagination")
            // }
        },

        switchClosedCallsPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllClosedPageable(closedCurrentPage, closedPageCount);
                // console.log("this.paginationCalls===", this.paginationCalls);
                // console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.allSortCalls) {
                this.getAllSortClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
                // console.log("this.paginationCalls===", this.paginationCalls);
                // console.log("this.allSortCalls===", this.allSortCalls);
            }
            // else if (this.searchPagination) {
            //   this.getAllClosedCallSearchPageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.closedKeyword
            //   );
            //   // //console.log(this.searchPagination,"getAllClosedCallSearchPageable")
            // }
        },

        switchFollowupsPage: function (followupsCurrentPage, followupsPageCount) {
            this.loading = true;
            // console.log("switchFollowupsPage calling");
            if (this.paginationFollowups) {
                // console.log(this.paginationFollowups, "paginationFollowups");
                this.getAllFollowupPageable(followupsCurrentPage, followupsPageCount);
            } else if (this.allSortFollowups) {
                // console.log(this.allSortFollowups, "allSortFollowups");
                this.getAllSortFollowupPageable(
                    followupsCurrentPage,
                    followupsPageCount,
                    this.data
                );
            }
            //  else if (this.searchPagination) {
            //   this.getAllSearchPageable(
            //     followupsCurrentPage,
            //     followupsPageCount,
            //     this.keyword.toLowerCase()
            //   );
            //   // console.log(this.searchPagination, "searchPagination");
            // }
        },

        switchEnquiriesPage: function (enquiriesCurrentPage, enquiriesPageCount) {
            this.loading = true;
            // //console.log("switchPage calling");
            if (this.paginationEnquiries) {
                // console.log(this.paginationEnquiries, "paginationEnquiries");
                this.getAllEnquiryPageable(enquiriesCurrentPage, enquiriesPageCount);
            } else if (this.allSortEnquiries) {
                // console.log(this.allSortEnquiries, "allSortEnquiries");
                this.getAllSortEnquiryPageable(
                    enquiriesCurrentPage,
                    enquiriesPageCount,
                    this.data
                );
            }
            // else if (this.searchPagination) {
            //   this.getAllSearchPageable(
            //     enquiriesCurrentPage,
            //     enquiriesPageCount,
            //     this.keyword.toLowerCase()
            //   );
            //   // console.log(this.searchPagination, "searchPagination");
            // }
        },

        switchNotintrestedPage: function (
            notintrestedCurrentPage,
            notintrestedPageCount
        ) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationNotintresteds) {
                // console.log(this.paginationNotintresteds, "paginationNotintresteds");
                this.getAllNotintrestedPageable(
                    notintrestedCurrentPage,
                    notintrestedPageCount
                );
            } else if (this.allSortNotintresteds) {
                // console.log(this.allSortNotintresteds, "allSortNotintresteds");
                this.getAllSortNotintrestedPageable(
                    notintrestedCurrentPage,
                    notintrestedPageCount,
                    this.data
                );
            }
            // else if (this.searchPagination) {
            //   this.getAllSearchPageable(
            //     notintrestedCurrentPage,
            //     notintrestedPageCount,
            //     this.keyword.toLowerCase()
            //   );
            //   // console.log(this.searchPagination, "searchPagination");
            // }
        },

        switchAdmissionPage: function (admissionCurrentPage, admissionPageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationAdmissions) {
                // console.log(this.paginationAdmissions, "paginationAdmissions");
                this.getAllAdmissionPageable(admissionCurrentPage, admissionPageCount);
            } else if (this.allSortAdmissions) {
                // console.log(this.allSortAdmissions, "allSortAdmissions");
                this.getAllSortAdmissionPageable(
                    admissionCurrentPage,
                    admissionPageCount,
                    this.data
                );
            }
            // else if (this.searchPagination) {
            //   this.getAllSearchPageable(
            //     admissionCurrentPage,
            //     admissionPageCount,
            //     this.keyword.toLowerCase()
            //   );
            //   // console.log(this.searchPagination, "searchPagination");
            // }
        },

        //     getCount: function () {
        //   // this.showCalls();
        //   this.loading = true;
        //   this.allSortCalls = false;
        //   this.paginationCalls = true;
        //   this.searchPagination = false;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.keyword = "";
        //   this.selectedDates = null;
        //   // this.sortCoursePaginationCalls = false;
        //   // this.sortSourcePaginationCalls = false;
        //   // this.sortBranchPaginationCalls = false;
        //   // this.sortDatePaginationCalls = false;
        //   // this.sortAgentPaginationCalls = false;
        //   this.currentPage = 1;

        //   return new Promise((resolve, reject) => {
        //     AdminService.getCallCount()
        //       .then((response) => {
        //         this.totalCount = response.data;
        //         this.getAllPageable(this.currentPage, this.pageCount);
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getAllPageable: function (currentPage, pageCount) {
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllCallPageable(currentPage, pageCount)
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         // console.log(response.data)
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },

        dateConvert: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D-MM-YYYY hh:mm a");
            }
        },
        // ========================== ExportExcellSheet Methods ===========================

        exportCallFollowupExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode;
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                this.data = {
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/call/followup/report/export/excel", this.data)
                        .then((response) => {
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        exportCallExcellSheet: function (
            selectedReportDates // selectedAgent,
        ) // selectedCourse,
        // selectedSource,
        // selectedBranch,
        // selectedDates
        {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode;
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    // agentId: selectedAgent.agentId,
                    // courseId: selectedCourse.courseId,
                    // sourceId: selectedSource.sourceId,
                    // branchId: selectedBranch.branchId,
                    // from: from,
                    // to: to,
                    // offset: dateOffset,
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/call/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportCallExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },

        exportClosedCallExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode;

            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/closedCall/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportClosedCallExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        exportFollowupExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    // agentId: selectedAgent.agentId,
                    // courseId: selectedCourse.courseId,
                    // sourceId: selectedSource.sourceId,
                    // branchId: selectedBranch.branchId,
                    // from: from,
                    // to: to,
                    // offset: dateOffset,
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/followp/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportFollowupExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        exportEnquiryExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode

            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/enquiry/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportEnquiryExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        exportNotintrestedExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode;

            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/notintrested/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportNotintrestedExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        exportAdmissionExcellSheet: function (selectedReportDates) {
            this.loading = true;
            var agent = this.selectedReportAgent;
            var course = this.selectedReportCourse;
            var source = this.selectedReportSource;
            var branch = this.selectedReportBranch;
            var mode = this.selectedReportMode;

            if (selectedReportDates == "dateRange") {
                this.getReportDateRangeReset();
                this.$refs.callReportDateRange.show();
                this.selectedReportDates = "dateBetween";
                this.loading = false;
            } else {
                if (agent == "Agent" || agent == "AllAgents") {
                    agent = {
                        agentId: "",
                    };
                }
                if (course == "Course" || course == "AllCourses") {
                    course = {
                        courseId: "",
                    };
                }
                if (mode == "mode" || mode == "allModes") {
                    mode = "";
                }
                if (source == "Source" || source == "AllSources") {
                    source = {
                        sourceId: "",
                    };
                }
                if (branch == "Branch" || branch == "AllBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedReportDates != null) {
                    var date = new Date();
                    if (selectedReportDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedReportDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedReportDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedReportDates == "dateBetween"
                    ) {
                        this.$refs.callReportDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedReportDates == null || selectedReportDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                // var dateOffset = 0;
                // if (selectedDates == "lastWeek") {
                //   dateOffset = 6;
                // } else if (selectedDates == "lastMonth") {
                //   dateOffset = 29;
                // } else if (selectedDates == "lastThreeMonths") {
                //   dateOffset = 89;
                // } else if (selectedDates == "allData") {
                //   let fromDate = "2021-12-01 00:01";
                //   let toDate = new Date();
                //   let fromDateFormat = moment(String(fromDate)).format(
                //     "yyyy-MM-DD HH:mm"
                //   );
                //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                //   from = fromDateFormat;
                //   to = toDateFormat;
                //   dateOffset = this.daydiff;
                //   // console.log("this diffrent days====== ", this.daydiff);
                // }
                this.data = {
                    agentId: agent.agentId,
                    courseId: course.courseId,
                    mode: mode,
                    sourceId: source.sourceId,
                    branchId: branch.branchId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios
                        .post("/insight/admission/report/export/excel", this.data)
                        .then((response) => {
                            // console.log("exportAdmissionExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

/* #sticky{
  position: sticky;
} */

#sticky {
    /* position: fixed; */
    /* top: 0; */
    width: 100%;
    /* padding-left: 10%; */
    /* margin-right: -10%; */
    /* background-color: rgba(197, 197, 197, 0.664); */
    z-index: 100;
}

/* #calls {
  margin-left: 0%;
  margin-top: 0%;
}

#followups {
  margin-left: 30%;
  margin-top: -30%;
}
#admissions {
  margin-left: 60%;
  margin-top: -30%;
} */
@media screen and (max-width: 749.98px) {
    #graph {
        padding-left: 8%;
    }

    /* #calls {
    margin-left: 0%;
    margin-bottom: 30%;
  }

  #followups {
    max-height: 40%;
    max-width: 280%;
  }
  #admissions {
    margin-left: 0%;
    margin-top: 0%;
  } */
}

/* Table Stayle */

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 120px;
}

/* agent status start */
.statusCard {
    /* position: relative;
    left: 40px; */
    background: rgb(228, 224, 224);
}

.agentcard {
    border: 0;
}

.text-h2 {
    font-size: 28px;
}

.card-top {
    margin-top: 3%;
}

@media(min-width:640px) {
    .tableName {

        position: relative;
        left: 80px;
    }

    .tableStatus {
        position: relative;
        left: 50px;
    }

    .agenttable {
        width: 60vw;
        margin-left: 6%;
    }

}

@media(max-width:640px) {
    .tableStatus {
        width: 30vw;
    }

    .tableDate {
        width: 50vw;
    }
}

/* agent status end  */
</style>
