<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div class="mt-5">
        <b-container>
            <!--popup start for employee register-->
            <b-modal id="addEmployeeModal" title="Add Employee" hide-footer ref="addEmployeeModal" no-close-on-backdrop>
                <div class="form-row">
                    <div class="form-group">
                        <label>Search User:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="searchTeamUser" :class="{'is-invalid': $v.searchTeamUser.$error,'is-valid': !$v.searchTeamUser.$invalid,}" placeholder="Email/Mobile/Username to search" v-on:keyup.enter="getTeamUser(searchTeamUser)">
                        </b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.searchTeamUser.required">Email/Mobile/Username is required!</span>
                        </div>
                        <div class="text-center">
                            <b-button class="mt-2" variant="primary" @click="getTeamUser(searchTeamUser)">Search</b-button>
                        </div>
                    </div>
                    <div v-if="employeeForm">
                        <div class="form-group mb-3">
                        <label>Email:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.email" :class="{'is-invalid': $v.employee.email.$error,'is-valid': !$v.employee.email.$invalid,}" placeholder="Enter employee email" disabled>
                        </b-form-input>
                        <div class="valid-feedback">Email is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.email.required">Email is required!</span>
                        </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>First Name:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.firstName" :class="{'is-invalid': $v.employee.firstName.$error,'is-valid': !$v.employee.firstName.$invalid,}" placeholder="Enter employee firstname" disabled>
                            </b-form-input>
                            <div class="valid-feedback">First Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.employee.firstName.required">First Name is required!</span>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Last Name:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.lastName" placeholder="Enter employee lastname">
                            </b-form-input>
                        </div>
                        <div class="form-group mb-3">
                            <label>Code:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.code" :class="{'is-invalid': $v.employee.code.$error,'is-valid': !$v.employee.code.$invalid,}" placeholder="Enter employee code">
                            </b-form-input>
                            <div class="valid-feedback">Employee Code is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.employee.code.required">Employee Code is required!</span>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Designation:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.designation" :class="{'is-invalid': $v.employee.designation.$error,'is-valid': !$v.employee.designation.$invalid,}" placeholder="Enter employee designation">
                            </b-form-input>
                            <div class="valid-feedback">Designation is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.employee.designation.required">Designation is required!</span>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Date of Birth:</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY" v-model="employee.dob" :class="{'is-invalid': $v.employee.dob.$error,'is-valid': !$v.employee.dob.$invalid,}" type="date"></b-form-input>
                                <div class="valid-feedback">Date is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.employee.dob.required">Date is invalid!</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Gender:</label>
                            <b-form-select class="form-control" v-model="employee.gender" :class="{'is-invalid': $v.employee.gender.$error,'is-valid': !$v.employee.gender.$invalid,}">
                                <option value="" disabled>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="Female">Female</option>
                                <option value="others">Others</option>
                            </b-form-select>
                        </div>
                        <div class="form-group mb-3">
                            <label>Mobile Number:</label>
                            <b-form-input type="number" class="form-control" v-model="employee.mobile" :class="{'is-invalid': $v.employee.mobile.$error,'is-valid': !$v.employee.mobile.$invalid,}" placeholder="Enter employee mobile number">
                            </b-form-input>
                            <div class="valid-feedback">Mobile Number is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.employee.mobile.required">Mobile Number is required!</span>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Alternative Contact:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.alternativeContact" placeholder="Enter alternative contact">
                            </b-form-input>
                        </div>
                        <div class="form-group mb-3">
                            <label>Marital Status:</label>
                            <b-form-select class="form-control" v-model="employee.maritalStatus">
                                <option value="">Select Marital Status</option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="widowed">Widowed</option>
                                <option value="divorced">Divorced</option>
                            </b-form-select>
                        </div>
                        <div class="form-group mb-3">
                            <label>Date Of Joining:</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY" v-model="employee.dateOfJoining" type="date"></b-form-input>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label>Provident Fund Number:</label>
                            <b-form-input type="text" class="form-control" v-model="employee.providentFund" placeholder="Enter PF number">
                            </b-form-input>
                        </div>
                        <div class="form-group mt-3">
                            <div class="text-center">
                                <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,teamEmployeeCreate()">Submit</b-button>
                                <b-button variant="outline-danger" class="mx-3" @click="resetEmployee()">Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
            <!--popup end for employee register-->

            <!--popup start for view employee details -->
            <b-modal id="viewEmployeeModal" title="View Employee" hide-footer ref="viewEmployeeModal">
                <div class="form-row">
                    <div class="form-group mb-3">
                        <label>Email:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.email" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>First Name:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.firstName" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Last Name:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.lastName" placeholder="Enter employee lastname" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Code:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.code" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Designation:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.designation" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Date of Birth:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.dob" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Gender:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.gender" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Mobile Number:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.mobile" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Alternative Contact:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.alternativeContact" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Marital Status:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.maritalStatus" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Date Of Joining:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.dateOfJoining" disabled>
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Provident Fund Number:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.providentFund" disabled>
                        </b-form-input>
                    </div>
                </div>
            </b-modal>
            <!--popup end for view employee details -->

            <!--popup start for employee update-->
            <b-modal title="Update Employee" hide-footer ref="updateEmployeeModal" no-close-on-backdrop>
                <div class="form-row">
                    <div class="form-group mb-3">
                        <label>Email:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.email" :class="{'is-invalid': $v.employee.email.$error,'is-valid': !$v.employee.email.$invalid,}" placeholder="Enter employee email" disabled>
                        </b-form-input>
                        <div class="valid-feedback">Email is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.email.required">Email is required!</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Code:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.code" :class="{'is-invalid': $v.employee.code.$error,'is-valid': !$v.employee.code.$invalid,}" placeholder="Enter employee code" disabled>
                        </b-form-input>
                        <div class="valid-feedback">Employee Code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.code.required">Employee Code is required!</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>First Name:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.firstName" :class="{'is-invalid': $v.employee.firstName.$error,'is-valid': !$v.employee.firstName.$invalid,}" placeholder="Enter employee firstname">
                        </b-form-input>
                        <div class="valid-feedback">First Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.firstName.required">First Name is required!</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Last Name:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.lastName" placeholder="Enter employee lastname">
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Designation:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.designation" :class="{'is-invalid': $v.employee.designation.$error,'is-valid': !$v.employee.designation.$invalid,}" placeholder="Enter employee designation">
                        </b-form-input>
                        <div class="valid-feedback">Designation is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.designation.required">Designation is required!</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Date of Birth:</label>
                        <div>
                            <b-form-input format="DD-MM-YYYY" v-model="employee.dob" :class="{'is-invalid': $v.employee.dob.$error,'is-valid': !$v.employee.dob.$invalid,}" type="date"></b-form-input>
                            <div class="valid-feedback">Date is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.employee.dob.required">Date is invalid!</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Gender:</label>
                        <b-form-select class="form-control" v-model="employee.gender" :class="{'is-invalid': $v.employee.gender.$error,'is-valid': !$v.employee.gender.$invalid,}">
                            <option value="" disabled>Select Gender</option>
                            <option value="male">Male</option>
                            <option value="Female">Female</option>
                            <option value="others">Others</option>
                        </b-form-select>
                    </div>
                    <div class="form-group mb-3">
                        <label>Mobile Number:</label>
                        <b-form-input type="number" class="form-control" v-model="employee.mobile" :class="{'is-invalid': $v.employee.mobile.$error,'is-valid': !$v.employee.mobile.$invalid,}" placeholder="Enter employee mobile number">
                        </b-form-input>
                        <div class="valid-feedback">Mobile Number is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.employee.mobile.required">Mobile Number is required!</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Alternative Contact:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.alternativeContact" placeholder="Enter alternative contact">
                        </b-form-input>
                    </div>
                    <div class="form-group mb-3">
                        <label>Marital Status:</label>
                        <b-form-select class="form-control" v-model="employee.maritalStatus">
                            <option value="">Select Marital Status</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="widowed">Widowed</option>
                            <option value="divorced">Divorced</option>
                        </b-form-select>
                    </div>
                    <div class="form-group mb-3">
                        <label>Date Of Joining:</label>
                        <div>
                            <b-form-input format="DD-MM-YYYY" v-model="employee.dateOfJoining" type="date"></b-form-input>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label>Provident Fund Number:</label>
                        <b-form-input type="text" class="form-control" v-model="employee.providentFund" placeholder="Enter PF number">
                        </b-form-input>
                    </div>
                    <div class="form-group mt-3">
                        <div class="text-center">
                            <b-button variant="outline-primary" :disabled="disabledButton" @click="disabledButton = true,updateEmployee()">Update</b-button>
                            <b-button variant="outline-danger" class="mx-3" @click="$refs.updateEmployeeModal.hide(),resetEmployee()">cancel</b-button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <!--popup end for employee update-->

            <b-row align-v="center">
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addEmployeeModal @click="resetSearchTeamUser(),resetEmployee()">Employee</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getEmployeeSearchCount(keyword)" placeholder="Code/Name/Mobile/Email to search" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getEmployeeSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <hr />
                <b-table responsive hover :items="employees" :fields="fields" :current-page="currentPage" :per-page="employees.pageCount" ref="table">
                    <template v-slot:cell(code)="data">
                        {{ data.item.code }}
                    </template>
                    <template v-slot:cell(firstName)="data">
                        <div class="cellSize">
                        {{ data.item.firstName }}
                    </div>
                    </template>
                    <template v-slot:cell(designation)="data">
                        {{ data.item.designation }}
                    </template>
                    <template v-slot:cell(email)="data">
                        {{ data.item.email }}
                    </template>
                    <template v-slot:cell(dob)="data">
                        <div class="cellSize">
                        {{ dateFormat(data.item.dob, "date") }}
                    </div>
                    </template>
                    <template v-slot:cell(gender)="data">
                        {{ data.item.gender }}
                    </template>
                    <template v-slot:cell(createdAt)="data">
                        <div class="cellSize">
                            {{ dateFormat(data.item.createdAt, "dateTime") }}
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <div class="cellSize">
                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Employee Details" @click="viewEmployee(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button>
                        <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Employee Details" @click="resetEmployee(),updateEmployeeModalShow(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button>
                        <!-- <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button> -->
                    </div>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>
</div>
</template>

<script>
import Account from '../service/Account.js';
import EmployeeService from '../service/EmployeeService';
import moment from "moment-timezone";
import {
    required,
    minLength,
    maxLength,
    email,
} from "vuelidate/lib/validators";

export default {
    name: 'Employee',
    data() {
        return {
            paginationEmployee: true,
            searchPaginationEmployee: false,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalCount: 0,
            keyword: "",
            employee: {
                userId: "",
                employeeId:"",
                firstName: "",
                lastName: "",
                code: "",
                email: "",
                designation: "",
                dob: "",
                gender: "",
                mobile: "",
                alternativeContact: "",
                maritalStatus: "",
                dateOfJoining: "",
                providentFund: ""
            },
            employees: [],
            fields: [{
                    key: "code",
                    label: "Code",
                },
                {
                    key: "firstName",
                    label: "Name",
                },
                {
                    key: "designation",
                    label: "Designation",
                },
                {
                    key: "mobile",
                    label: "Mobile",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "dob",
                    label: "Date of Birth",
                    tdClass: "cellSize"
                },
                {
                    key: "gender",
                    label: "Gender",
                },
                {
                    key: "gender",
                    label: "Gender",
                },
                {
                    key: "createdAt",
                    label: "CreatedAt",
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "cellSize"
                },
            ],
            uniqueCode: false,
            uniqueEmail: false,
            searchTeamUser: "",
            disabledButton: false,
            employeeForm: false,
        }
    },
    validations: {
        searchTeamUser: {
            required,
        },
        employee: {
            userId: {
                required
            },
            firstName: {
                required
            },
            // lastName: {
            //     required
            // },
            code: {
                required,
                // minLength: minLength(5),
                // maxLength: maxLength(9),
            },
            email: {
                required,
                email
            },
            designation: {
                required
            },
            dob: {
                required
            },
            gender: {
                required
            },
            mobile: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            // alternativeContact:{
            //     required
            // },
            // maritalStatus: {
            //     required
            // },
            // dateOfJoining: {
            //     required
            // },
            // providentFund:{
            //     required
            // }
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getEmployeeCount();
            }
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.getEmployeeCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        getTeamUser: function (searchTeamUser) {
            this.$v.searchTeamUser.$touch();
            if (!this.$v.searchTeamUser.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    Account.teamUserSearch(searchTeamUser.toLowerCase())
                        .then((response) => {
                            if (response.data != null && response.data != "") {
                                this.userIdUniqueValidation(response.data);
                            } else {
                                this.$swal({
                                    title: "User not found",
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        userIdUniqueValidation: function(user) {
            return new Promise((resolve, reject) => {
                EmployeeService.userIdUniqueValidation(user.id)
                    .then((response) => {
                        if(response.data) {
                            this.employeeForm = true;
                            this.employee = {
                                userId: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email[0],
                                mobile: user.mobile[0],
                                code: "",
                                designation: "",
                                dob: "",
                                gender: "",
                                alternativeContact: "",
                                maritalStatus: "",
                                dateOfJoining: "",
                                providentFund: ""
                            }
                        } else {
                            this.$swal({
                                title: "User already exist..!",
                                icon: "error",
                                button: "ok",
                            });
                        }
                        this.loading = false;
                        this.disabledButton = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
                });
        },
        teamEmployeeCreate: function () {
            this.$v.employee.$touch()
            if (!this.$v.employee.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    EmployeeService.teamEmployeeCreate(this.employee)
                        .then((response) => {
                            this.$refs.addEmployeeModal.hide();
                            this.resetEmployee();
                            this.$swal({
                                title: "Good job!",
                                text: response.data,
                                icon: "success",
                                button: "ok",
                            });
                            this.getEmployeeCount();
                            this.loading = false;
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            if (err.response.status == 409) {
                                this.$swal({
                                    title: err.response.data,
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        getEmployeeCount: function() {
            this.loading = true;
            this.currentPage = 1;
            this.paginationEmployee = true;
            this.searchPaginationEmployee = false;
            return new Promise((resolve, reject) => {
                EmployeeService.getEmployeeCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllEmployeePageable(this.currentPage,this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage() {
            this.loading = true;
            if(this.paginationEmployee) {
                this.getAllEmployeePageable(this.currentPage,this.pageCount);
            } else if(this.searchPaginationEmployee) {
                this.getAllEmployeeSearchPageable(this.currentPage,this.pageCount);
            }
        },
        getAllEmployeePageable: function(currentPage,pageCount) {
            return new Promise((resolve, reject) => {
                EmployeeService.getAllEmployeePageable(currentPage,pageCount)
                    .then((response) => {
                        this.employees = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getEmployeeSearchCount: function() {
            this.loading = true;
            this.currentPage = 1;
            this.paginationEmployee = false;
            this.searchPaginationEmployee = true;
            return new Promise((resolve, reject) => {
                EmployeeService.getEmployeeSearchCount(this.keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllEmployeeSearchPageable(this.currentPage,this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllEmployeeSearchPageable: function(currentPage,pageCount) {
            return new Promise((resolve, reject) => {
                EmployeeService.getAllEmployeeSearchPageable(currentPage,pageCount,this.keyword.toLowerCase())
                    .then((response) => {
                        this.employees = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        updateEmployee() {
            this.$v.employee.$touch()
            if (!this.$v.employee.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    EmployeeService.updateEmployee(this.employee)
                        .then((response) => {
                            this.$refs.updateEmployeeModal.hide();
                            this.resetEmployee();
                            this.$swal({
                                title: "Good job!",
                                text: "Employee updated Successfully",
                                icon: "success",
                                button: "ok",
                            });
                            this.getEmployeeCount();
                            this.loading = false;
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            if (err.response.status == 409) {
                                this.$swal({
                                    title: err.response.data,
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        viewEmployee(employee) {
            this.employee = employee;
            this.$refs.viewEmployeeModal.show();
        },
        updateEmployeeModalShow(employee) {
            this.employee = employee;
            this.$refs.updateEmployeeModal.show();
        },
        dateFormat: function (date, dateType) {
            const formatDate = new Date(date);
            if (dateType === "date") {
                return moment(formatDate).format("DD / MM / YYYY");
            }
            if (dateType === "dateTime") {
                return moment(formatDate).format("DD / MM / YYYY hh:mm a");
            }
        },
        refresh() {
            if (this.keyword != "") {
                this.keyword = "";
            } else {
                this.getEmployeeCount();
            }
        },
        resetSearchTeamUser() {
            this.searchTeamUser = "";
            this.$nextTick(() => {
                this.$v.searchTeamUser.$reset();
            });
        },
        resetEmployee() {
            this.employeeForm = false;
            this.employee = {
                userId: "",
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
                code: "",
                designation: "",
                dob: "",
                gender: "",
                alternativeContact: "",
                maritalStatus: "",
                dateOfJoining: "",
                providentFund: ""
            };
            this.$nextTick(() => {
                this.$v.employee.$reset();
                // this.$v.searchTeamUser.$reset();
            });
        },
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}

.cellSize {
    min-width: 120px;
}
</style>
