import axios from 'axios';
export default {

    insertBranchBatch: function (agentId,tutor) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branchBatch/insert/"+ agentId, tutor, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getCodeSearch: function(data){
        return new Promise((resolve, reject) => {
            axios.post('/branchBatch/code/search',data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getBranchBatchCount: function (data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: "/batch/supervisor/branchBatch/count",data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBranchBatchPageable: function (currentPage, pageCount,data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/batch/supervisor/branchBatch/all/' + (currentPage - 1) + "/" + pageCount,data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBatchDetailCount: function (data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: "/branchBatch/count",data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBatchDetailPageable: function (currentPage, pageCount,data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/branchBatch/all/' + (currentPage - 1) + "/" + pageCount,data
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getSearchCount: function (keyword) {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branchBatch/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },

    getAllSearchPageable: function (currentPage, pageCount, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/branchBatch/search/' + (currentPage - 1) + "/" + pageCount + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getBatchSearchCount: function (keyword) {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branchBatch/searchBatch/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },

    getAllBatchSearchPageable: function (currentPage, pageCount, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/branchBatch/searchBatch/' + (currentPage - 1) + "/" + pageCount + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    archive: function (branchBatch) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branchBatch/archive/" + branchBatch, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function (branchBatch) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branchBatch/unarchive/" + branchBatch, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },

}