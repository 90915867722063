<template>
<div>
    <b-container>
        <!-- for loader -->
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
        <!-- for loader end -->
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Enquiries" active>
                <b-container>
                    <div>
                        <!-- <b-row class="my-4">
                <b-col col lg="10" style="text-align: right">
                    <b-button variant="outline-primary" v-bind:class="{ active: isShowEnquiry }" size="sm" @click="showEnquiry()">
                        Enquiries</b-button>
                </b-col>
                <b-col col lg="2">
                    <b-button variant="outline-primary" v-bind:class="{ active: isShowClosed }" size="sm" @click="showClosed()">Closed Enquiries</b-button>
                </b-col>
            </b-row> -->
                        <!-- <div v-if="isShowEnquiry"> -->
                        <!-- Enquiry table and details start -->
                        <!-- <div class="mt-5"> -->
                        <div>
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                                </b-col>
                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="pageCount" :options="pageOptions" @input="switchPage(currentPage, pageCount)" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                            <!-- </b-container>
            <b-container> -->
                            <b-card class="card mt-1">
                                <div>
                                    <b-container>
                                        <b-row>
                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedCourse" size="sm" @change="
                          getAllSortCount(
                            selectedAgent,
                            selectedCourse,
                            selectedMode,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Course">
                                                        <p>Courses</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllCourses">
                                                        <p>All Courses</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>

                                            <b-col col lg="2" md="1" id="r1" sm="3">
                                                <b-form-select class="bnclr" v-model="selectedMode" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                                    <b-form-select-option disabled value="mode">
                                                        <p>Mode</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="allModes">
                                                        <p>All Modes</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                                        <strong>{{ item.text }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>

                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedSource" size="sm" @change="
                          getAllSortCount(
                            selectedAgent,
                            selectedCourse,
                            selectedMode,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Source">
                                                        <p>Sources</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllSources">
                                                        <p>All Sources</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="2" md="4" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="
                          getAllSortCount(
                            selectedAgent,
                            selectedCourse,
                            selectedMode,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Agent">
                                                        <p>Agents</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllAgents">
                                                        <p>All Agents</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.firstName }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="
                          getAllSortCount(
                            selectedAgent,
                            selectedCourse,
                            selectedMode,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Branch">
                                                        <P>Branches</P>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllBranches">
                                                        <p>All Branches</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedDates" @change="
                          getAllSortCount(
                            selectedAgent,
                            selectedCourse,
                            selectedMode,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        " :options="dateSelect">>
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <hr />
                                <b-container>
                                    <b-table class="my-table" bordered responsive hover :items="allEnquiries" :fields="fields" :current-page="currentPage" :per-page="allEnquiries.pageCount">
                                        <template v-slot:cell(enquiryId)="data">
                                            <span class="title"> {{ data.item.enquiryId }}</span>
                                        </template>
                                        <template v-slot:cell(callCreatedAt)="data">
                                            <div class="cellSize">
                                                {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(agent)="data">
                                            <div class="cellSize">
                                                {{ data.item.agent.firstName }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(name)="data">
                                            {{ data.item.name }}
                                        </template>
                                        <template v-slot:cell(contact)="data">
                                            <div>
                                                {{ data.item.contact
                      }}<span v-if="data.item.alternativeContact != null">,</span>
                                            </div>
                                            <div>{{ data.item.alternativeContact }}</div>
                                        </template>
                                        <template v-slot:cell(course)="data">
                                            <div class="cellSize">
                                                {{ data.item.course.name }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(source)="data">
                                            <div>
                                                {{ data.item.source.name }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(branch)="data">
                                            <div class="cellSize">
                                                {{ data.item.branch.name }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(createdAt)="data">
                                            <div class="cellSize">
                                                {{ callDate(data.item.createdAt, "fullDate") }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <div class="cellSize">
                                                <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                                    <font-awesome-icon icon="eye" />
                                                </b-button>
                                                <b-button size="sm" class="mx-1" variant="secondary" @click="enquiryClosedModalShow(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Enquiry">
                                                    <font-awesome-icon icon="window-close" />
                                                </b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                </b-container>
                            </b-card>
                        </div>
                        <!-- Enquiry table and details end -->
                        <!-- </div> -->
                    </div>
                </b-container>
            </b-tab>
            <b-tab title="Closed Enquiries">
                <!-- <div v-if="isShowClosed"> -->
                <!-- Enquiry closed details table start -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!-- Enquiry disabled -->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="closedKeyword" v-on:keyup.enter="getClosedSearchCount(closedKeyword)" placeholder="Type a name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getClosedSearchCount(closedKeyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="closedCurrentPage" :total-rows="closedTotalCount" :per-page="closedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchClosedPage(closedCurrentPage, closedPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="closedPageCount" :options="closedPageOptions" @input="switchClosedPage(closedCurrentPage, closedPageCount)" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="closedRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedCourse" size="sm" @change="
                        getAllSortClosedCount(
                          selectedClosedAgent,
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      ">
                                                <b-form-select-option disabled value="Courses">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedSource" size="sm" @change="
                        getAllSortClosedCount(
                          selectedClosedAgent,
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      ">
                                                <b-form-select-option disabled value="Sources">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="4" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedClosedAgent" size="sm" @change="
                        getAllSortClosedCount(
                          selectedClosedAgent,
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      ">
                                                <b-form-select-option disabled value="Agents">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedBranch" size="sm" @change="
                        getAllSortClosedCount(
                          selectedClosedAgent,
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      ">
                                                <b-form-select-option disabled value="Branches">
                                                    <p>Branches</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedClosedDates" @change="
                        getAllSortClosedCount(
                          selectedClosedAgent,
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      " :options="dateSelect">>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />
                            <b-table bordered responsive hover :items="closedEnquiries" :fields="closedFields" :current-page="closedCurrentPage" :per-page="closedEnquiries.closedPageCount">
                                <template v-slot:cell(enquiryId)="data">
                                    <span class="title"> {{ data.item.enquiryId }}</span>
                                </template>
                                <template v-slot:cell(callCreatedAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                  }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    <div class="cellSize">
                                        {{ data.item.course.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    <div class="cellSize">
                                        {{ data.item.branch.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- Enquiry closed details table end -->
                <!-- </div> -->
            </b-tab>
        </b-tabs>

        <b-container>
            <div>
                <!--popup start for View Enquiry details-->
                <b-modal id="enquiryView" title="View Enquiry" hide-footer>
                    <div class="control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="enquiry.name" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input type="number" v-model="enquiry.contact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Alternative Contact</label>
                                <b-form-input type="number" v-model="enquiry.alternativeContact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input type="number" v-model="enquiry.age" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-input v-model="enquiry.qualification" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-select class="form-control" v-model="enquiry.course" disabled>
                                    <option value="null" disabled>Select Course</option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-select class="form-control" v-model="enquiry.source" disabled>
                                    <option value="null" disabled>Select Source</option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-input class="form-control" v-model="enquiry.mode" :options="options" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-select class="form-control" v-model="enquiry.branch" disabled>
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupDateAndRemark">
                            <template v-slot:cell(remark)="data">
                                <span class="title"> {{ data.item.remark }}</span>
                            </template>
                            <!-- <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template> -->
                            <template v-slot:cell(createdAt)="data">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template>
                        </b-table>
                    </div>
                </b-modal>
                <!--popup end for View Enquiry details-->
            </div>
        </b-container>
        <b-modal ref="EnquiryDateRange" hide-footer id="dateRange" title="Select Date">
            <div>
                <label for="from-datepicker">Choose The From date</label>
                <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
            </div>
            <div>
                <label for="to-datepicker">Choose The To date</label>
                <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date"></b-form-input>
            </div>
            <div class="text-center mt-3">
                <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedAgent,
              selectedCourse,
              selectedMode,
              selectedSource,
              selectedBranch,
              selectedDates
            )
          ">Submit</b-button>
            </div>
        </b-modal>

        <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
            <div>
                <label for="from-datepicker">Choose The From date</label>
                <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
            </div>
            <div>
                <label for="to-datepicker">Choose The To date</label>
                <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date"></b-form-input>
            </div>
            <div class="text-center mt-3">
                <b-button variant="outline-success" type="submit" @click="
            getAllSortClosedCount(
              selectedClosedAgent,
              selectedClosedCourse,
              selectedClosedSource,
              selectedClosedBranch,
              selectedClosedDates
            )
          ">Submit</b-button>
            </div>
        </b-modal>
    </b-container>
    <b-modal title="Enquiry Closed" no-close-on-backdrop ref="enquiryCloseModal" @hidden="onModalHidden" hide-footer>
        <div class="px-2 pt-2">
            <div class="form-row mt-3 px-5">
                <div class="form-row mt-3 px-3">
                    <div class="form-group my-2">
                        <label>courseType</label>
                        <div>
                            <b-form-select class="form-control" v-model="courseType" :options="courseOptions" :class="{'is-invalid': $v.courseType.$error,'is-valid': !$v.courseType.$invalid,}"></b-form-select>
                            <div class="valid-feedback">course type is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.courseType.required">please select a course type!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 mt-2 text-center">
            <b-button type="submit" @click="disableButton = true,enquiryClosed()" :disabled="disabledButton" class="px-3" variant="primary">closed To Admission</b-button>
        </div>
    </b-modal>
</div>
</template>

<script>
import Account from '../service/Account.js';
// import AdminService from "../service/AdminService"
import AdminEnquiryService from "../service/AdminEnquiryService";
import EnquiryService from "../service/EnquiryService";
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import FollowupService from "../service/FollowupService";
import qualification from "../assets/json_file/qualification.json";
import {
    required
} from "vuelidate/lib/validators";
import moment from "moment-timezone";
import axios from "axios";
export default {
    name: "AdminEnquiries",
    data() {
        return {

            selectedMode: "mode",
            //loading: false,
            disableButton: false,
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],

            paginationEnquiries: true,
            allSortEnquiries: false,
            followupDateRemark: [],
            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",

            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            //   selectedDate: null,
            selectedDates: null,
            selectedClosedCourse: "Courses",
            selectedClosedSource: "Sources",
            selectedClosedBranch: "Branches",
            selectedClosedAgent: "Agents",
            selectedClosedDates: null,

            enquiry: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                createdAt: "",
            },
            from: "",
            to: "",
            fromClosed: "",
            toClosed: "",
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                {
                    value: "today",
                    text: "Today",
                },
                {
                    value: "yesterday",
                    text: "Yesterday",
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            dateClosedSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                {
                    value: "today",
                    text: "Today",
                },
                {
                    value: "yesterday",
                    text: "Yesterday",
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            qualificationList: qualification,    
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            enquiryFollowup: {
                followupDate: "",
                remark: "",
            },
            // Closed data render in table
            closedEnquiries: [],
            closedFields: [{
                    key: "callCreatedAt",
                    label: "Call_Date",
                    tdClass: "cellSize",
                },
                {
                    key: "agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                    tdClass: "cellSize",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                    tdClass: "cellSize",
                },
                {
                    key: "source",
                    label: "Source",
                    tdClass: "cellSize",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                    tdClass: "cellSize",
                },
                {
                    key: "createdAt",
                    label: "Enquiry_Date",
                    tdClass: "cellSize",
                },
                {
                    key: "action",
                    label: "Action",
                    tdClass: "cellSize",
                },
            ],
            allEnquiries: [],
            fields: [{
                    key: "callCreatedAt",
                    label: "Call_Date",
                    tdClass: "cellSize",
                },
                {
                    key: "agent",
                    label: "Agent Name",
                    tdClass: "cellSize",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                    tdClass: "cellSize",
                },
                {
                    key: "source",
                    label: "Source",
                    tdClass: "cellSize",
                },
                {
                    key: "branch",
                    label: "Branch",
                    tdClass: "cellSize",
                },
                {
                    key: "createdAt",
                    label: "Enquiry_Date",
                    tdClass: "cellSize",
                },
                {
                    key: "action",
                    label: "Action",
                    tdClass: "cellSize",
                },
            ],
            // for loader
            loading: false,
            // for enquiry pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show all",
                },
            ],
            totalCount: 0,
            // for closed pagination
            closedRows: 100,
            closedCurrentPage: 1,
            closedPageCount: 5,
            closedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show all",
                },
            ],
            closedTotalCount: 0,
            // for closed enquiry search in table
            closedKeyword: "",
            // for enquiry search in table
            keyword: "",
            // Enquiry table show
            // isShowEnquiry: true,
            // Enquiry table hide and closed tables show
            // isShowClosed: false,

            followup: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            courseType: null,
            courseOptions: [{
                    value: null,
                    text: "select course type",
                    disabled: true,
                },
                {
                    value: 'Online Test',
                    text: 'Online Test',
                },
                {
                    value: 'Offline Test',
                    text: 'Offline Test',
                },
                {
                    value: 'Online Course',
                    text: 'Online Course',
                },
                {
                    value: 'Offline Course',
                    text: 'Offline Course',
                },
                {
                    value: 'Online Book',
                    text: 'Online Book',
                },
                {
                    value: 'Offline Book',
                    text: 'Offline Book',
                },
            ],
            disabledButton: false,
            invoice: {
                userName: "",
                contact: "",
                courseName: "",
                price: "",
                alternativeContact: "",
                mode: "",
            },
            admissionModal: false,
            userData: null,
            organization: "",

        };
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
        courseType: {
            required
        }
    },
    mounted() {
     
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.getCount();
                    this.getClosedCount();
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    this.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            // console.log("===============watch===========");
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
        closedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getClosedCount();
                this.selectedClosedAgent = "Agents";
            }
        },
    },

    methods: {
        enquiryClosed: function () {
            this.$v.courseType.$touch();
            if (!this.$v.courseType.$invalid) {
                console.log("enquiryClosed============enquiry", this.enquiry);
                console.log("enquiryClosed============courseType", this.courseType);
                this.disableButton = false;
                this.$refs.enquiryCloseModal.hide();
                let data = {
                    age: this.enquiry.age,
                    agent: this.enquiry.agent,
                    alternativeContact: this.enquiry.alternativeContact,
                    archive: this.enquiry.archive,
                    branch: this.enquiry.branch,
                    callCreatedAt: this.enquiry.callCreatedAt,
                    callId: this.enquiry.callId,
                    contact: this.enquiry.contact,
                    course: this.enquiry.course,
                    createdAt: this.enquiry.createdAt,
                    enquiryId: this.enquiry.enquiryId,
                    mode: this.enquiry.mode,
                    name: this.enquiry.name,
                    qualification: this.enquiry.qualification,
                    source: this.enquiry.source,
                    courseType: this.courseType
                }
                console.log("datadata=======", data);
                console.log("enquiry.agentId===-=-=-=-", this.enquiry.agent.agentId);
                this.updateStatusClosed(this.enquiry.agent.agentId, this.enquiry.enquiryId);
                this.$refs.enquiryCloseModal.hide();
                            this.disableButton = false;
                            this.getCount();
                            this.getClosedCount();
                return new Promise((resolve, reject) => {
                    EnquiryService.enquiryClosed(this.enquiry.agent.agentId, data)
                        .then((response) => {
                            this.$swal({
                                title: "Admission Successful",
                                text: "Enquiry closed to Admission",
                                icon: "success",
                                button: "ok",
                            });
                          
                            resolve(response);
                        })
                        .catch((err) => {
                            this.disableButton = false;
                            reject(err);
                        });
                });
            }
            this.disableButton = false;
        },
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getAllSortCount: function (
        //   selectedAgent,
        //   selectedCourse,
        //   selectedSource,
        //   selectedBranch,
        //   selectedDates
        // ) {
        //   if (selectedDates == "dateRange") {
        //     this.getDateRangeReset();
        //     this.$refs.dateRange.show();
        //     this.selectedDates = "dateBetween";
        //   } else {
        //     if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
        //       selectedAgent = {
        //         agentId: "",
        //       };
        //     }
        //     if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
        //       selectedCourse = {
        //         courseId: "",
        //       };
        //     }
        //     if (selectedSource == "Source" || selectedSource == "AllSources") {
        //       selectedSource = {
        //         sourceId: "",
        //       };
        //     }
        //     if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
        //       selectedBranch = {
        //         branchId: "",
        //       };
        //     }
        //     var from;
        //     var to;
        //     if (selectedDates != null) {
        //       var date = new Date();
        //       if (selectedDates == "today") {
        //         this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //         this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //       } else if (selectedDates == "yesterday") {
        //         this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //         this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //       } else if (selectedDates == "lastWeek") {
        //         this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //         this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //       } else if (selectedDates == "lastMonth") {
        //         this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //         this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //       } else if (
        //         this.from != "" &&
        //         this.to != "" &&
        //         selectedDates == "dateBetween"
        //       ) {
        //         this.$refs.dateRange.hide();
        //         var fromDateObject = new Date(this.from);
        //         var toDateObject = new Date(this.to);
        //         this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //         this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //       } else if (
        //         this.from == "" &&
        //         this.to == "" &&
        //         selectedDates == "dateBetween"
        //       ) {
        //         this.$refs.dateRange.hide();
        //         this.fromDate = "2021-01-01 00:01";
        //         this.toDate = new Date();
        //       }
        //       this.fromDateFormat = moment(String(this.fromDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //       );
        //       this.toDateFormat = moment(String(this.toDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //       );
        //       from = this.fromDateFormat;
        //       to = this.toDateFormat;
        //     } else if (selectedDates == null || selectedDates == "dateBetween") {
        //       let fromDate = "2021-01-01 00:01";
        //       let toDate = new Date();
        //       let fromDateFormat = moment(String(fromDate)).format(
        //         "yyyy-MM-DD HH:mm"
        //       );
        //       let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //       from = fromDateFormat;
        //       to = toDateFormat;
        //     }
        //     this.data = {
        //       agentId: selectedAgent.agentId,
        //       courseId: selectedCourse.courseId,
        //       sourceId: selectedSource.sourceId,
        //       branchId: selectedBranch.branchId,
        //       from: from,
        //       to: to,
        //     };
        //     this.loading = true;
        //     this.allSortEnquiries = true;
        //     this.paginationEnquiries = false;
        //     this.searchPagination = false;
        //     this.currentPage = 1;
        //     this.keyword = "";
        //     console.log("this.data====== ", this.data);
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/adminenquiry/enquiry/all/sort/count", this.data)
        //         .then((response) => {
        //           console.log("getAllSortCount======", response.data);
        //           this.totalCount = response.data;
        //           this.getAllSortPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.EnquiryDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.EnquiryDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.EnquiryDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortEnquiries = true;
                this.paginationEnquiries = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminenquiry/enquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminenquiry/enquiry/all/sort/" + currentPage + "/" + pageCount,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getAllSortPageable: function(currentPage, pageCount, data) {
        //   // console.log("data=======", data);
        //   return new Promise((resolve, reject) => {
        //     axios
        //       .post("/adminenquiry/enquiry/all/sort/" + currentPage + "/" + pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         console.log("getAllSortPageable===== ", response.data);
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // getEnquiriesBetweenDates: function () {
        //     return new Promise((resolve, reject) => {
        //         this.loading = true;
        //         //console.log("calling Sort By Date");
        //         // AdmissionService.getToday()
        //         //console.log("Selected Date Range", this.selectedDate);
        //         AdminEnquiryService.getByDate(this.selectedDate)
        //             .then((response) => {
        //                 //console.log(response.data, " Enquriys By Dates");
        //                 this.allEnquiries = response.data;
        //                 this.getDateCount();
        //                 this.loading = false;
        //                 this.selectedCourse = "Course"
        //                 this.selectedSource = "Source";
        //                 this.selectedBranch = "Branch";
        //                 this.selectedAgent = "Agent";
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        // getDateCount: function (selectedDate) {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.sortCoursePagination = false;
        //   this.sortSourcePagination = false;
        //   this.sortAgentPagination = false;
        //   this.sortBranchPagination = false;
        //   this.sortDatePagination = true;
        //   this.searchPagination = false;
        //   this.paginationEnquiries = false;
        //   this.currentPage = 1;

        //   if (selectedDate == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedDate = null;
        //     this.getDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedDate == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDate == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDate == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDate == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getDateRange: function () {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getDateRangeReset();
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getDateRangeReset: function () {
            this.$refs.EnquiryDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getClosedDateRangeReset: function () {
            this.$refs.dateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        // getAllDatePageable: function (currentPage, pageCount, data) {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllDatePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getClosedEnquiriesBetweenDates: function () {
        //   return new Promise((resolve, reject) => {
        //     this.loading = true;
        //     //console.log("calling Sort By Date");
        //     // AdmissionService.getToday()
        //     //console.log("Selected Date Range", this.selectedClosedDate);
        //     AdminEnquiryService.getByClosedDate(this.selectedClosedDate)
        //       .then((response) => {
        //         //console.log(response.data, " Enquriys By Dates");
        //         this.closedEnquiries = response.data;
        //         this.getClosedDateCount();
        //         this.loading = false;
        //         (this.selectedclosedCourse = "Courses"),
        //           (this.selectedclosedSource = "Sources"),
        //           (this.selectedclosedBranch = "Branches");
        //         this.selectedClosedAgent = "Agents";
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedDateCount: function (selectedClosedDate) {
        //   (this.selectedclosedCourse = "Courses"),
        //     (this.selectedclosedSource = "Sources"),
        //     (this.selectedclosedBranch = "Branches");
        //   this.selectedClosedAgent = "Agents";
        //   this.sortClosedCoursePagination = false;
        //   this.sortClosedSourcePagination = false;
        //   this.sortClosedBranchPagination = false;
        //   this.sortClosedAgentPagination = false;
        //   this.sortClosedDatePagination = false;
        //   this.searchClosedPagination = false;
        //   this.paginationClosedEnquiries = true;
        //   this.closedCurrentPage = 1;
        //   if (selectedClosedDate == "dateRange") {
        //     this.$refs.dateClosedRange.show();
        //     this.selectedClosedDate = null;
        //     this.getClosedDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedClosedDate == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDate == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDate == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedClosedDate == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedDateCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedDateRange: function () {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedDateCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getClosedDateRangeReset();
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedDateRangeReset: function () {
        //   this.$refs.dateClosedRange.hide();
        //   (this.from = ""), (this.to = ""), this.$v.from.$reset();
        //   this.$v.to.$reset();
        // },
        // getAllClosedDatePageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   this.loading = true;
        //   console.log("getAllClosedDatePageable");
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllClosedDatePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedEnquiries = response.data;
        //         console.log(this.closedEnquiries);
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // showEnquiry() {
        //     this.isShowEnquiry = true;
        //     this.isShowClosed = false;
        //     this.selectedCourse = "Course";
        //     this.selectedSource = "Source";
        //     this.selectedBranch = "Branch";
        //     this.selectedAgent = "Agent";
        //     this.selectedDate = null;
        //     this.keyword = "";
        //     this.getCount();
        // },
        // showClosed() {
        //     this.isShowEnquiry = false;
        //     this.isShowClosed = true;
        //     this.getClosedCount();
        //     this.closedKeyword = "";
        //     (this.selectedclosedCourse = "Courses"),
        //     (this.selectedclosedSource = "Sources"),
        //     (this.selectedclosedBranch = "Branches");
        //     this.selectedClosedAgent = "Agents";
        //     this.selectedClosedDates = null;
        // },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        // getCourseDetail: function (course) {
        //   if (this.selectedCourse == "AllCourse") {
        //     //console.log("All Course Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Course Calling")
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/sortCourse", data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getCourseCount(data);
        //           //console.log("====", response.data);
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.selectedAgent = "Agent";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSourceDetail: function (source) {
        //   if (this.selectedSource == "AllSource") {
        //     //console.log("source Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("source Calling")
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/sortSource", data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getSourceCount(data);
        //           //console.log("====", response.data);
        //           this.selectedBranch = "Branch";
        //           this.selectedAgent = "Agent";
        //           this.selectedCourse = "Course";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getBranchDetail: function (branch) {
        //   if (this.selectedBranch == "AllBranch") {
        //     //console.log("All Branch Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Branch Calling")
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/sortBranch", data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getBranchCount(data);
        //           //console.log("====", response.data);
        //           this.selectedSource = "Source";
        //           this.selectedAgent = "Agent";
        //           this.selectedCourse = "Course";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAgentDetail: function (agent) {
        //   if (this.selectedAgent == "AllAgent") {
        //     //console.log("All Agent Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("AgentDetail Calling")
        //     let data = {
        //       agentId: agent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/sortAgent", data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getAgentCount(data);
        //           //console.log("====", response.data);
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.selectedCourse = "Course";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                // AgentService.getAllCounsellorAgents()
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getAllEnquiries: function () {
        //     this.showEnquiry();
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //         AdminEnquiryService.getAllEnquiries()
        //             .then((response) => {
        //                 this.allEnquiries = response.data;
        //                 this.getCount();
        //                 this.loading = false;
        //                 resolve(response);
        //             }).catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        getCount: function () {
            this.loading = true;
            this.allSortEnquiries = false;
            this.paginationEnquiries = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getEnquiryCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        //console.log("this.totalCount",this.totalCount);
                        // this.sortCoursePagination = false;
                        // this.sortSourcePagination = false;
                        // this.sortBranchPagination = false;
                        // this.searchPagination = false;
                        // this.sortAgentPagination = false;
                        // this.paginationEnquiries = true;
                        // this.currentPage = 1;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllEnquiryPageable(
                        currentPage,
                        pageCount,
                        this.agentId
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // //console.log("switchPage calling");
            if (this.paginationEnquiries) {
                // console.log(this.paginationEnquiries, "paginationEnquiries");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortEnquiries) {
                // console.log(this.allSortEnquiries, "allSortEnquiries");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            }
            //   else if (this.sortCoursePagination) {
            //     this.getAllCoursePageable(currentPage, pageCount, this.data);
            //   } else if (this.sortSourcePagination) {
            //     this.getAllSourcePageable(currentPage, pageCount, this.data);
            //   } else if (this.sortBranchPagination) {
            //     this.getAllBranchPageable(currentPage, pageCount, this.data);
            //   } else if (this.sortDatePagination) {
            //     this.getAllDatePageable(currentPage, pageCount, this.data);
            //   } else if (this.sortAgentPagination) {
            //     this.getAllAgentPageable(currentPage, pageCount, this.data);
            //   }
            else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
        },
        searchName: function (keyword) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                AdminEnquiryService.searchEnquiry(keyword.toLowerCase())
                    .then((response) => {
                        this.getSearchCount(keyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // refresh: function(){
        // this.getAllEnquiries();
        // // this.getAllEnquiryPageable();
        // this.selectedCourse= "Course";
        // this.selectedSource= "Source";
        // this.selectedBranch= "Branch";
        // this.selectedAgent= "Agent";
        // this.keyword='';

        // },
        getSearchCount: function () {
            this.searchPagination = true;
            this.paginationEnquiries = false;
            this.allSortEnquiries = false;
            this.currentPage = 1;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            //console.log("search pagecount", this.keyword)
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getSearchCount(this.keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        //console.log("search pagecount", response.data)
                        // this.sortCoursePagination = false;
                        // this.sortSourcePagination = false;
                        // this.sortBranchPagination = false;
                        // this.sortAgentPagination = false;
                        // this.currentPage = 1;
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            this.loading = true;
            //console.log("search page calling")
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getCourseCount: function (selectedCourse) {
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDate = null;
        //   if (selectedCourse == "AllCourse") {
        //     //console.log("All Course Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Course Calling")
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getCourseCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortCoursePagination = true;
        //           this.sortSourcePagination = false;
        //           this.sortBranchPagination = false;
        //           this.searchPagination = false;
        //           this.sortAgentPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           this.getAllCoursePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAllCoursePageable: function (currentPage, pageCount, data) {
        //   this.loading = true;
        //   //console.log("coursepageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllCoursePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getSourceCount: function (selectedSource) {
        //   this.selectedCourse = "Course";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDate = null;
        //   if (selectedSource == "AllSource") {
        //     //console.log("All Source Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Source Calling")
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getSourceCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortCoursePagination = false;
        //           this.sortSourcePagination = true;
        //           this.sortBranchPagination = false;
        //           this.searchPagination = false;
        //           this.sortAgentPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           this.getAllSourcePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAllSourcePageable: function (currentPage, pageCount, data) {
        //   this.loading = true;
        //   //console.log("Sourcepageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllSourcePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getBranchCount: function (selectedBranch) {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedAgent = "Agent";
        //   this.selectedDate = null;
        //   if (selectedBranch == "AllBranch") {
        //     //console.log("All Branch Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Branch Calling")
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getBranchCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortCoursePagination = false;
        //           this.sortSourcePagination = false;
        //           this.sortBranchPagination = true;
        //           this.searchPagination = false;
        //           this.sortAgentPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           this.getAllBranchPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAllBranchPageable: function (currentPage, pageCount, data) {
        //   this.loading = true;
        //   //console.log("Branchpageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllBranchPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getAgentCount: function (selectedAgent) {
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedDate = null;
        //   if (selectedAgent == "AllAgent") {
        //     //console.log("All Agent Calling")
        //     this.getCount();
        //   } else {
        //     //console.log("Agent Calling")
        //     this.data = {
        //       agentId: selectedAgent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getAgentCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortCoursePagination = false;
        //           this.sortSourcePagination = false;
        //           this.sortBranchPagination = false;
        //           this.sortAgentPagination = true;
        //           this.searchPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           this.getAllAgentPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAllAgentPageable: function (currentPage, pageCount, data) {
        //   this.loading = true;
        //   //console.log("Agentpageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllAgentPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // Admin enquiry Closed Start ============ >>>>>>>>>>>

        //=================================ADMIN CLOSED ENQUIRY========================================================

        getAllSortClosedCount: function (
            selectedClosedAgent,
            selectedClosedCourse,
            selectedClosedSource,
            selectedClosedBranch,
            selectedClosedDates
        ) {
            // console.log("selectedClosedAgent=== ", selectedClosedAgent);
            // console.log("selectedClosedCourse=== ", selectedClosedCourse);
            // console.log("selectedClosedSource=== ", selectedClosedSource);
            // console.log("selectedClosedBranch=== ", selectedClosedBranch);
            // console.log("selectedClosedDates=== ", selectedClosedDates);

            if (selectedClosedDates == "dateRange") {
                this.getClosedDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedClosedDates = "dateBetween";
            } else {
                if (
                    selectedClosedAgent == "Agents" ||
                    selectedClosedAgent == "AllClosedAgents"
                ) {
                    selectedClosedAgent = {
                        agentId: "",
                    };
                }
                if (
                    selectedClosedCourse == "Courses" ||
                    selectedClosedCourse == "AllClosedCourses"
                ) {
                    selectedClosedCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedClosedSource == "Sources" ||
                    selectedClosedSource == "AllClosedSources"
                ) {
                    selectedClosedSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedClosedBranch == "Branches" ||
                    selectedClosedBranch == "AllClosedBranches"
                ) {
                    selectedClosedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedClosedDates != null) {
                    var date = new Date();
                    if (selectedClosedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedClosedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedClosedDates == null ||
                    selectedClosedDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedClosedAgent.agentId,
                    courseId: selectedClosedCourse.courseId,
                    sourceId: selectedClosedSource.sourceId,
                    branchId: selectedClosedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortEnquiries = true;
                this.paginationEnquiries = false;
                this.searchPagination = false;
                this.closedCurrentPage = 1;
                this.closedKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminenquiry/closedEnquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedCount======", response.data);
                            this.closedTotalCount = response.data;
                            this.getAllSortClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminenquiry/closedEnquiry/all/sort/" +
                        closedCurrentPage +
                        "/" +
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        // console.log("getAllSortClosedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getCourseClosedDetail: function (course) {
        //   if (this.selectedclosedCourse == "AllCourse") {
        //     //console.log("All Closed Course Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Course Calling")
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/closed/sortCourse", data)
        //         .then((response) => {
        //           this.closedEnquiries = response.data;
        //           this.getClosedCourseCount(data);
        //           //console.log("====", response.data);
        //           (this.selectedclosedSource = "Sources"),
        //           (this.selectedclosedBranch = "Branches");
        //           this.selectedClosedAgent = "Agents";
        //           this.selectedClosedDate = null;
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSourceClosedDetail: function (source) {
        //   if (this.selectedclosedSource == "AllSource") {
        //     //console.log("All Source Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed source Calling")
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/closed/sortSource", data)
        //         .then((response) => {
        //           this.closedEnquiries = response.data;
        //           this.getClosedSourceCount(data);
        //           //console.log("====", response.data);
        //           (this.selectedclosedCourse = "Courses"),
        //             (this.selectedclosedBranch = "Branches");
        //           this.selectedClosedAgent = "Agents";
        //           this.selectedClosedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getBranchClosedDetail: function (branch) {
        //   if (this.selectedclosedBranch == "AllBranch") {
        //     //console.log("All Branch Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Branch Calling")
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/adminenquiry/closed/sortBranch", data)
        //         .then((response) => {
        //           this.closedEnquiries = response.data;
        //           this.getClosedBranchCount(data);
        //           //console.log("====", response.data);
        //           (this.selectedclosedCourse = "Courses"),
        //             (this.selectedclosedSource = "Sources"),
        //             (this.selectedClosedAgent = "Agents");
        //           this.selectedClosedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getAgentClosedDetail: function (agent) {
        //     if (this.selectedclosedAgent == 'AllAgent') {
        //         //console.log("All Agent Calling")
        //         this.getClosedCount();

        //     } else {
        //         //console.log("Closed AgentDetail Calling")
        //         let data = {
        //             agentId: agent.agentId
        //         }
        //         return new Promise((resolve, reject) => {
        //             this.loading = true;
        //             axios
        //                 .post("/adminenquiry/closed/sortAgent", data)
        //                 .then((response) => {
        //                     this.closedEnquiries = response.data;
        //                     this.getClosedAgentCount(data);
        //                     //console.log("====", response.data);
        //                     this.selectedclosedCourse = "Courses",
        //                         this.selectedclosedSource = "Sources",
        //                         this.selectedclosedBranch = "Branches"
        //                     this.selectedClosedDate = "Date";
        //                     this.loading = false;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     }
        // },

        getAllClosed: function () {
            this.showClosed();
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllClosed()
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        this.getClosedEnquiryCount();

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getClosedCount: function () {
            this.paginationEnquiries = true;
            this.searchPagination = false;
            this.allSortEnquiries = false;
            this.closedCurrentPage = 1;
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedAgent = "Agents";
            this.selectedClosedDates = null;
            this.closedKeyword = "";
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getClosedEnquiryCount()
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        // this.sortClosedCoursePagination = false;
                        // this.sortClosedSourcePagination = false;
                        // this.sortClosedBranchPagination = false;
                        // this.sortClosedAgentPagination = false;
                        // this.sortClosedDatePagination = false;
                        // this.searchClosedPagination = false;
                        // this.paginationClosedEnquiries = true;
                        // this.closedCurrentPage = 1;
                        this.getAllClosedEnquiryPageable(
                            this.closedCurrentPage,
                            this.closedPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedEnquiryPageable: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllClosedEnquiryPageable(
                        closedCurrentPage,
                        closedPageCount
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchClosedPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationEnquiries) {
                this.getAllClosedEnquiryPageable(closedCurrentPage, closedPageCount);
                // console.log("this.paginationEnquiries===", this.paginationEnquiries);
                // console.log("this.allSortEnquiries===", this.allSortEnquiries);
            } else if (this.allSortEnquiries) {
                this.getAllSortClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
                // console.log("this.paginationEnquiries===", this.paginationEnquiries);
                // console.log("this.allSortEnquiries===", this.allSortEnquiries);
            } else if (this.searchPagination) {
                this.getAllClosedSearchPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.closedKeyword
                );
                // console.log(this.searchPagination, "getAllClosedSearchPageable");
            }
            //   if (this.paginationClosedEnquiries) {
            //     console.log(this.paginationEnquiries, "paginationEnquiries");
            //     this.getAllClosedEnquiryPageable(closedCurrentPage, closedPageCount);
            //   } else if (this.sortClosedCoursePagination) {
            //     //console.log("this.getAllClosedCoursePageable",this.getAllClosedCoursePageable)
            //     this.getAllClosedCoursePageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedSourcePagination) {
            //     this.getAllClosedSourcePageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedBranchPagination) {
            //     this.getAllClosedBranchPageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedDatePagination) {
            //     console.log("getAllClosedDatePageable === switch");
            //     this.getAllClosedDatePageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedAgentPagination) {
            //     this.getAllClosedAgentPageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.searchClosedPagination) {
            //     this.getAllClosedSearchPageable(
            //       closedCurrentPage,
            //       closedPageCount,
            //       this.closedKeyword.toLowerCase()
            //     );
            //   }
        },

        searchClosedEnquiry: function (closedKeyword) {
            //console.log("search closed calling")
            return new Promise((resolve, reject) => {
                this.loading = true;
                AdminEnquiryService.searchClosedEnquiry(closedKeyword.toLowerCase())
                    .then((response) => {
                        this.getClosedSearchCount(closedKeyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        refresh: function () {
            this.getCount();
            this.selectedCourse = "Course";
            this.selectedMode = "mode";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDate = null;
            this.keyword = "";
        },
        closedRefresh: function () {
            this.getClosedCount();
            this.closedKeyword = "";
            (this.selectedclosedCourse = "Courses"),
            (this.selectedclosedSource = "Sources"),
            (this.selectedclosedBranch = "Branches");
            this.selectedClosedAgent = "Agents";
            this.selectedClosedDates = null;
        },

        getClosedSearchCount: function () {
            this.searchPagination = true;
            this.paginationEnquiries = false;
            this.allSortEnquiries = false;
            this.closedCurrentPage = 1;
            this.loading = true;
            (this.selectedclosedCourse = "Courses"),
            (this.selectedclosedSource = "Sources"),
            (this.selectedclosedBranch = "Branches");
            this.selectedClosedAgent = "Agents";
            this.selectedClosedDates = null;
            //console.log("Closed search  pagecount", this.closedKeyword)
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getClosedSearchCount(
                        this.closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        //console.log("search pagecount", response.data)
                        // this.sortClosedCoursePagination = false;
                        // this.sortClosedSourcePagination = false;
                        // this.sortClosedBranchPagination = false;
                        // this.sortClosedAgentPagination = false;
                        // this.searchClosedPagination = true;
                        // this.paginationClosedEnquiries = false;
                        // this.closedCurrentPage = 1;
                        this.getAllClosedSearchPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            this.closedKeyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedSearchPageable: function (
            closedCurrentPage,
            closedPageCount,
            closedKeyword
        ) {
            this.loading = true;
            //console.log("Closed search page calling")
            return new Promise((resolve, reject) => {
                AdminEnquiryService.getAllClosedSearchPageable(
                        closedCurrentPage,
                        closedPageCount,
                        closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getClosedCourseCount: function (selectedclosedCourse) {
        //   (this.selectedclosedSource = "Sources"),
        //     (this.selectedclosedBranch = "Branches");
        //   this.selectedClosedAgent = "Agents";
        //   this.selectedClosedDate = null;
        //   if (this.selectedclosedCourse == "AllCourse") {
        //     //console.log("All Closed Course Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Course Calling")
        //     this.data = {
        //       courseId: selectedclosedCourse.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedCourseCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.sortClosedCoursePagination = true;
        //           this.sortClosedSourcePagination = false;
        //           this.sortClosedBranchPagination = false;
        //           this.sortClosedAgentPagination = false;
        //           this.searchClosedPagination = false;
        //           this.paginationClosedEnquiries = false;
        //           this.closedCurrentPage = 1;
        //           this.getAllClosedCoursePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedCoursePageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   this.loading = true;
        //   //console.log("closed coursepageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllClosedCoursePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getClosedSourceCount: function (selectedclosedSource) {
        //   (this.selectedclosedCourse = "Courses"),
        //     (this.selectedclosedBranch = "Branches");
        //   this.selectedClosedAgent = "Agents";
        //   this.selectedClosedDate = null;
        //   if (this.selectedclosedSource == "AllSource") {
        //     //console.log("All Closed Source Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Source Calling")
        //     this.data = {
        //       sourceId: selectedclosedSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedSourceCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.sortClosedCoursePagination = false;
        //           this.sortClosedSourcePagination = true;
        //           this.sortClosedBranchPagination = false;
        //           this.sortClosedAgentPagination = false;
        //           this.searchClosedPagination = false;
        //           this.paginationClosedEnquiries = false;
        //           this.closedCurrentPage = 1;
        //           this.getAllClosedSourcePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedSourcePageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   this.loading = true;
        //   //console.log("closed Sourcepageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllClosedSourcePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getClosedBranchCount: function (selectedclosedBranch) {
        //   (this.selectedclosedCourse = "Courses"),
        //     (this.selectedclosedSource = "Sources"),
        //     (this.selectedClosedAgent = "Agents"),
        //     (this.selectedClosedDate = null);
        //   if (this.selectedclosedBranch == "AllBranch") {
        //     //console.log("All Closed Branch Calling")
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Branch Calling")
        //     this.data = {
        //       branchId: selectedclosedBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedBranchCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.sortClosedCoursePagination = false;
        //           this.sortClosedSourcePagination = false;
        //           this.sortClosedBranchPagination = true;
        //           this.sortClosedAgentPagination = false;
        //           this.searchClosedPagination = false;
        //           this.paginationClosedEnquiries = false;
        //           this.closedCurrentPage = 1;
        //           this.getAllClosedBranchPageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedBranchPageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   this.loading = true;
        //   //console.log("closed Branchpageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllClosedBranchPageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getClosedAgentCount: function (selectedClosedAgent) {
        //   (this.selectedclosedCourse = "Courses"),
        //     (this.selectedclosedSource = "Sources"),
        //     (this.selectedclosedBranch = "Branches");
        //   this.selectedClosedDate = null;
        //   if (this.selectedClosedAgent == "AllAgent") {
        //     console.log("All Closed Agent Calling");
        //     this.getClosedCount();
        //   } else {
        //     //console.log("Closed Agent Calling")
        //     this.data = {
        //       agentId: selectedClosedAgent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminEnquiryService.getClosedAgentCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.sortClosedCoursePagination = false;
        //           this.sortClosedSourcePagination = false;
        //           this.sortClosedBranchPagination = true;
        //           this.sortClosedAgentPagination = false;
        //           this.searchClosedPagination = false;
        //           this.paginationClosedEnquiries = false;
        //           this.closedCurrentPage = 1;
        //           this.getAllClosedAgentPageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedAgentPageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   this.loading = true;
        //   //console.log("closed Agentpageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminEnquiryService.getAllClosedAgentPageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        getEnquiry: function (enquiry) {
            this.enquiry = enquiry;
            // console.log(this.enquiry.agent.agentId);
            // console.log("Enquiry");
            this.agentId = this.enquiry.agent.agentId;
            if (this.enquiry.callId == null) {
                return new Promise((resolve, reject) => {
                    // console.log("Counsellor enquiry");
                    FollowupService.getEnquiryFollowupDateRemark(enquiry.enquiryId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            // console.log(response.data);

                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                return new Promise((resolve, reject) => {
                    // FollowupService.getFollowupDateRemark(enquiry.callId)
                    FollowupService.getFollowupCreatedDate(enquiry.callId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            // console.log(response.data);

                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        enquiryClosedModalShow: function (enquiry) {
            this.enquiry = enquiry;
            this.$refs.enquiryCloseModal.show();
            this.userData = enquiry;
        },
        updateStatusClosed(agentId, enquiryId) {
            return new Promise((resolve, reject) => {
                //console.log("3rd",this.agentId);
                EnquiryService.updateStatusClosed(agentId, enquiryId)
                    .then((response) => {
                        // this.getCount();
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

/* .active {
    background: rgb(0, 97, 243) !important;
} */

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 100px;
    max-width: 100px;
}

/* .dateCellSize{
    min-width:100px
} */
</style>
