<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <br>
    <br>
    <b-container>
        <div>
            <b-container>
                <div class="top">
                    <!-- modal form Office details insert start-->
                    <!-- <b-modal id="officeModal" title="Add Office" ref="officeModal" no-close-on-backdrop hide-footer> -->
                    <b-modal id="officeModal" title="Add Office" ref="officeModal" style="position: absolute;
    z-index: 1000;!important" no-close-on-backdrop hide-footer>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Search office location</label>
                                <officeModal @data-emitted="handleDataFromChild"></officeModal>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="office.officeName" :class="{
                  'is-invalid': $v.office.officeName.$error,
                  'is-valid': !$v.office.officeName.$invalid,
                }" class="form-control" placeholder="Enter Office name"></b-form-input>
                                <div class="valid-feedback">Your Office Name is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.officeName.required">Office Name is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Address</label>
                                <b-form-input type="text" v-model="office.address" :class="{
                  'is-invalid': $v.office.address.$error,
                  'is-valid': !$v.office.address.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>
                                <div class="valid-feedback">Your Address is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.address.required">Address is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Longitude</label>
                                <b-form-input type="text" v-model="office.longitude" :class="{
                  'is-invalid': $v.office.longitude.$error,
                  'is-valid': !$v.office.longitude.$invalid,
                }" class="form-control" placeholder="Enter Your Longitude"></b-form-input>
                                <div class="valid-feedback">Your Longitude is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.longitude.required">Longitude is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Latitude</label>
                                <b-form-input type="text" v-model="office.latitude" :class="{
                  'is-invalid': $v.office.latitude.$error,
                  'is-valid': !$v.office.latitude.$invalid,
                }" class="form-control" placeholder="Enter Your Latitude"></b-form-input>
                                <div class="valid-feedback">Your Latitude is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.latitude.required">Latitude is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input type="text" v-model="office.branch" :class="{
                  'is-invalid': $v.office.branch.$error,
                  'is-valid': !$v.office.branch.$invalid,
                }" class="form-control" placeholder="Enter Your Branch"></b-form-input>
                                <div class="valid-feedback">Your Branch is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.branch.required">Branch is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" :disabled="disabledButton" @click="addNewOffice()" class="" variant="outline-primary">Add</b-button>
                            <!-- <b-button type="submit" class="mx-3" variant="outline-danger">Reset</b-button> -->
                        </div>
                    </b-modal>
                    <!-- modal form Office details insert end -->
                </div>

                <div class="top">
                    <!-- modal form Office details update start -->
                    <b-modal id="officeUpdateModal" title="Update Office" ref="officeUpdateModal" no-close-on-backdrop hide-footer>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="office.officeName" :class="{
                  'is-invalid': $v.office.officeName.$error,
                  'is-valid': !$v.office.officeName.$invalid,
                }" class="form-control" placeholder="Enter Office name"></b-form-input>
                                <div class="valid-feedback">Your Office Name is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.officeName.required">Office Name is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Address</label>
                                <b-form-input type="text" v-model="office.address" :class="{
                  'is-invalid': $v.office.address.$error,
                  'is-valid': !$v.office.address.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>
                                <div class="valid-feedback">Your Address is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.address.required">Address is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Longitude</label>
                                <b-form-input type="text" v-model="office.longitude" :class="{
                  'is-invalid': $v.office.longitude.$error,
                  'is-valid': !$v.office.longitude.$invalid,
                }" class="form-control" placeholder="Enter Your Longitude"></b-form-input>
                                <div class="valid-feedback">Your Longitude is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.longitude.required">Longitude is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Latitude</label>
                                <b-form-input type="text" v-model="office.latitude" :class="{
                  'is-invalid': $v.office.latitude.$error,
                  'is-valid': !$v.office.latitude.$invalid,
                }" class="form-control" placeholder="Enter Your Latitude"></b-form-input>
                                <div class="valid-feedback">Your Latitude is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.latitude.required">Latitude is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input type="text" v-model="office.branch" :class="{
                  'is-invalid': $v.office.branch.$error,
                  'is-valid': !$v.office.branch.$invalid,
                }" class="form-control" placeholder="Enter Your Branch"></b-form-input>
                                <div class="valid-feedback">Your Branch is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.office.branch.required">Branch is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" :disabled="disabledButton" @click="updateOffice()" class="" variant="outline-success">Update</b-button>
                            <!-- <b-button type="submit" class="mx-3" variant="outline-danger">Reset</b-button> -->
                        </div>
                    </b-modal>
                    <!-- modal form Office details update end -->
                </div>

                <b-row align-v="center">
                    <!--add Office button start-->
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.officeModal @click="resetForm()">Add Office</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <!-- <div id="bar">
                            <p class="auto">
                                <input v-model="searchItem" class="searchbar" type="text" id="autoc" required />
                            </p>
                            <div id="map"></div>
                        </div> -->
                        <!-- <input type="text" id="autocomplete-input" v-model="searchInput" /> -->
                        <!-- <input type="text" id="autoc" class="searchbar" /> -->
                        <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" /> -->
                        <input type="search" class="searchbar" v-model="keyword" placeholder="search by office" v-on:keyup.enter="getSearchCount(keyword)" />
                    </b-col>
                    <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" >Search</b-button>
                    </b-col> -->

                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <div>
                        <b-container>
                            <hr />
                            <b-table hover responsive :items="offices" :fields="fields" :per-page="offices.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(address)="data">
                                    <div class="cellSize">
                                        {{ data.item.address}}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div>
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" @click="viewOfficeDetail(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update" @click="getOffice(data.item)" v-b-modal.officeUpdateModal>
                                            <font-awesome-icon icon="edit" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" variant="secondary" @click="notintrestedClosed(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Notintrested">
                                        <font-awesome-icon icon="window-close" />
                                    </b-button> -->
                                    </div>
                                </template>
                            </b-table>
                        </b-container>
                    </div>
                </b-card>
            </b-container>

        </div>
    </b-container>
    <!-- for loader end -->
    <!-- <div id="bar">
        <p class="auto">
            <input type="text" id="autoc" style="height: 40px;padding: 0 7px;width: 100%;" required />
        </p>
        <div id="map"></div>
    </div> -->
</div>
</template>

<script>
import {
    required,
    email,
    minLength,
    maxLength,
    numeric,
} from "vuelidate/lib/validators";
import officeModal from "@/components/OfficeModal.vue"
import OfficeService from "../service/OfficeService"
import Account from '../service/Account.js';
export default {
    name: "Office",
    components: {
        officeModal
    },
    data() {
        return {
            // map:"",
            // google:"",
            searchItem: "",
            map: "",
            searchInput: '',
            autocomplete: null,
            disabledButton: false,
            paginationOffice: true,
            offices: [],
            calls: [],
            fields: [
                // {
                //     key: "createdAt",
                //     label: "Date",
                //     tdClass: "cellSize",
                // },
                {
                    key: "officeName",
                    label: "Office",
                },
                {
                    key: "address",
                    label: "Address",
                },
                {
                    key: "longitude",
                    label: "Longitude",
                },
                {
                    key: "latitude",
                    label: "Latitude",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            office: {
                officeName: "",
                address: "",
                longitude: null,
                latitude: null,
                branch: "",
            },

            // for Office pagination
            // rows: 100,
            keyword: "",
            loading: false,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,

            address: "",
            coordinatLat: 0,
            coordinatLong: 0,
            officeModal: false,
            userDialog: false,
            editDialog: false,
            userRow: { name: "", email: "", empId: "", contact: "" },
            selectedRow: {},
            combineObject: {
                officeId: "",
                name: "",
                email: "",
                empId: "",
                contact: ""
            },
            rows: []
        };
    },

    validations: {
        userRow: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            empId: {
                required,
            },
            contact: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric,
            },
        },
        office: {
            officeName: {
                required
            },
            address: {
                required
            },
            longitude: {
                required
            },
            latitude: {
                required
            },
            branch: {
                required
            },
        },
    },
    // created() {
    //     this.getOfficeDetail();
    // },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    // this.initialize();
                    this.getCount();
                    this.getOfficeDetail();
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },

    watch: {
        // coordinatLat(newVal, oldVal) {
        //     console.log("=====coordinatLat=====",this.coordinatLat);
        //     if (oldVal && !newVal) {
        //         console.log("=====coordinatLat Calling=====");
        //         this.addOfficeDialog();
        //     }
        // },

        keyword(newVal, oldVal) {
            // console.log("__new"+newVal, "___od"+oldVal, (oldVal && !newVal), "dsfsdf"+(oldVal), (newVal));
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
    },

    methods: {

        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        addOfficeDialog() {
            console.log("=====Add Office Dialouge Calling=====");
            this.office = {
                officeName: "",
                address: this.address,
                longitude: this.coordinatLong,
                latitude: this.coordinatLat,
                branch: "",
            };
            this.$refs.officeModal.show();
        },

        addNewOffice() {
            this.$v.$touch();
            if (!this.$v.office.$invalid) {
                OfficeService.CreateOffice(this.office)
                    .then((res) => {
                        this.$swal({
                            title: "Office Added Successfully...!!!",
                            // text: response.data,
                            icon: "success",
                            button: "ok",
                        });
                        this.getCount();
                        console.log(res.data);
                    })
                    .catch((err) => {
                        console.log("ERROR ACCURED" + err);
                    });
                // this.rows.push({ ...this.office });
                this.office = {
                    officeName: "",
                    address: "",
                    longitude: "",
                    latitude: "",
                    branch: "",
                };
                this.$refs.officeModal.hide();
            }
        },

        getOfficeDetail() {
            OfficeService.GetOfficeDetail()
                .then((res) => {
                    this.offices = res.data;
                    // console.log("=====Offices=====",this.offices);
                })
                .catch((err) => {
                    console.log(err);
                })
        },

        getCount: function () {
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                OfficeService.getCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("=====Total Count=====", response.data)
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                OfficeService.getAllPageable(currentPage, pageCount)
                    .then((response) => {
                        this.offices = response.data;
                        this.loading = false;
                        // console.log("=====Offices=====", response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            // this.loading = true;
            if (this.paginationOffice) {
                this.getAllPageable(currentPage, pageCount);
                this.loading = false;
                console.log("this.paginationOffice", this.paginationOffice);
            }
        },

        refresh: function () {
            this.getCount();
            this.keyword = "";
        },

        getOffice(office) {
            this.office = office;
            console.log("Office : ", office);
        },

        updateOffice() {
            this.$v.$touch();
            if (!this.$v.office.$invalid) {
                OfficeService.UpdateOffice(this.office)
                    .then((res) => {
                        this.$swal({
                            title: "Office Updated Successfully...!!!",
                            // text: response.data,
                            icon: "success",
                            button: "ok",
                        });
                        this.getCount();
                        console.log(res.data);
                    })
                    .catch((err) => {
                        console.log("Error Occured", err);
                    });
                this.$refs.officeUpdateModal.hide();
            }
        },

        initialize() {
            var map;
            var place;
            var apiKey = 'AIzaSyCYZ_IpuxhRS09FPMY2lfwYAxsii62f3g8';
            var mapOptions = {
                // zoom: 17,
                center: { lat: -33.8667, lng: 151.1955, }
            };
            map = new google.maps.Map(document.getElementById('map'), mapOptions);

            // Adds a Places search box. Searching for a place will center the map on that
            // location.
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
                document.getElementById('bar'));
            var autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autoc'));
            autocomplete.bindTo('bounds', map);
            autocomplete.addListener('place_changed', () => {
                place = autocomplete.getPlace();
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                this.coordinatLat = latitude;
                this.coordinatLong = longitude;
                this.address = place.formatted_address;
                console.log(this.address);
                console.log(this.coordinatLat, this.coordinatLong);
                this.addOfficeDialog();
                this.searchItem = null;
            });
            //   console.log(this.coordinatLat,this.coordinatLong);
        },

        handleDataFromChild(data) {
            this.office = {
                officeName: "",
                address: data.address,
                longitude: data.long,
                latitude: data.lat,
                branch: "",
            };
            // this.coordinatLat = data.lat;
            // this.coordinatLong = data.long;
            // this.address = data.address;
        },

        viewOfficeDetail(item) {
            this.$router.push({ name: 'OfficeDetails', params: { data: item.officeId } })
        },

        resetForm() {
            this.office = {
                officeName: "",
                address: "",
                longitude: null,
                latitude: null,
                branch: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        getSearchCount(keyword) {
            this.paginationOffice = false;
            // this.paginationCourses = false;
            // this.searchPaginationCourses = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                OfficeService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                OfficeService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        //   this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.offices = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

    },

}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

#bar {
    margin-bottom: -5%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 250px;
}

#officeModal___BV_modal_outer_ {
    z-index: 1000 !important
}
</style>
