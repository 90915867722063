import axios from "axios";
export default {
	leadConvertToCall: function(agentId, data) {
		return new Promise((resolve, reject) => {
			axios.post("lead/call/convert/"+agentId,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	manualLeadCreate: function (lead,agentId) {
		let data = {
			name: lead.name,
			contact: lead.contact,
			course: lead.course,
			comment: lead.comment,
			agentId: agentId
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/manual/create",data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	// getAllAgentLeadCount: function () {
	// 	let data = {
	// 		agentId: localStorage.getItem("agentId")
	// 	}
	// 	return new Promise((resolve, reject) => {
	// 		axios.post("lead/agent/count",data)
	// 			.then((response) => {
	// 				resolve(response);
	// 			})
	// 			.catch((err) => {
	// 				this.loading = false;
	// 				reject(err);
	// 			});
	// 	});
	// },
	getAllAgentMultiLeadCount: function () {
		let data = {
			agentId: localStorage.getItem("agentId"),
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/count",data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	// getAllAgentLeadPageable: function(page,count) {
	// 	let data = {
	// 		agentId: localStorage.getItem("agentId")
	// 	}
	// 	return new Promise((resolve, reject) => {
	// 		axios.post("lead/agent/getAll/pageable/"+(page-1)+"/"+count,data)
	// 			.then((response) => {
	// 				resolve(response);
	// 			})
	// 			.catch((err) => {
	// 				this.loading = false;
	// 				reject(err);
	// 			});
	// 	});
	// },
	getAllAgentMultiLeadPageable: function(currentPage,pageCount) {
		let data = {
			agentId: localStorage.getItem("agentId")
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/getAll/pageable/"+(currentPage-1)+"/"+pageCount,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAgentLeadSearchCount: function (keyword) {
		let data = {
			agentId: localStorage.getItem("agentId")
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/agent/search/count/"+keyword,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAgentLeadSearchPageable: function(page,count,keyword) {
		let data = {
			agentId: localStorage.getItem("agentId")
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/agent/search/pageable/"+(page-1)+"/"+count+"/"+keyword,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},

	getAllAgentMultiLeadSearchCount: function (keyword) {
		let data = {
			agentId: localStorage.getItem("agentId")
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/search/count/"+keyword,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAgentMultiLeadSearchPageable: function(page,count,keyword) {
		let data = {
			agentId: localStorage.getItem("agentId")
		}
		return new Promise((resolve, reject) => {
			axios.post("lead/search/pageable/"+(page-1)+"/"+count+"/"+keyword,data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},

	getAllLeadNotAssignCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("lead/agent/notAssign/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadNotAssignPageable: function(page,count) {
		return new Promise((resolve, reject) => {
			axios.post("lead/agent/notAssign/getAll/pageable/"+(page-1)+"/"+count)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	// leadAgentAssign: function(leadId) {
	// 	let data = {
	// 		leadId: leadId,
	// 		agentId: localStorage.getItem('agentId')
	// 	}
	// 	return new Promise((resolve, reject) => {
	// 		axios.post("lead/agent/assign",data)
	// 			.then((response) => {
	// 				resolve(response);
	// 			})
	// 			.catch((err) => {
	// 				this.loading = false;
	// 				reject(err);
	// 			});
	// 	});
	// },
	getAllLeadNotAssignSearchCount: function (keyword) {
		return new Promise((resolve, reject) => {
			axios.get("lead/agent/notAssign/search/count/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadNotAssignSearchPageable: function(page,count,keyword) {
		return new Promise((resolve, reject) => {
			axios.post("lead/agent/notAssign/search/pageable/"+(page-1)+"/"+count+"/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	isContactUnique(contact) {
		return new Promise((resolve, reject) => {
			axios.post("lead/contact/unique/"+contact)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	/* =================== ADMIN SERVICES =================*/
	getAllAssignedLeadCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("admin/lead/agent/assigned/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAssignedLeadPageable: function(page,count) {
		return new Promise((resolve, reject) => {
			axios.post("admin/lead/agent/assigned/getAll/pageable/"+(page-1)+"/"+count)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAssignedSearchCount: function (keyword) {
		return new Promise((resolve, reject) => {
			axios.get("admin/lead/agent/assigned/search/count/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllAssignedSearchPageable: function(page,count,keyword) {
		return new Promise((resolve, reject) => {
			axios.post("admin/lead/agent/assigned/search/getAll/pageable/"+(page-1)+"/"+count+"/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	leadAssignToAgentApi: function(leadId,agent) {
		let data = {
			leadId: leadId,
			agent: agent
		}
		return new Promise((resolve, reject) => {
			axios.post('admin/lead/agent/assign',data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadConvertCallCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("admin/lead/convert/call/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadConvertCallPageable: function(page,count) {
		return new Promise((resolve, reject) => {
			axios.post("admin/lead/convert/call/getAll/pageable/"+(page-1)+"/"+count)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadConvertCallSearchCount: function (keyword) {
		return new Promise((resolve, reject) => {
			axios.get("admin/lead/convert/call/search/count/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	getAllLeadConvertCallSearchPageable: function(page,count,keyword) {
		return new Promise((resolve, reject) => {
			axios.post("admin/lead/convert/call/search/getAll/pageable/"+(page-1)+"/"+count+"/"+keyword)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
	multiLeadAssignToAgentApi: function(leads,agents) {
		let data = {
			leads: leads,
			// agent: agent
			agents:agents
		}
		console.log("=====Data=====",data);
		return new Promise((resolve, reject) => {
			axios.post('admin/lead/multi/assign/agent',data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},
}