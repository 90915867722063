<template>
<div>
    <div>
        <NavBar />
    </div>
    <div>
        <router-view />
    </div>
    <div>
        <Footer />
    </div>
    <div class="floating-btn" @click="requestNotificationModel = true, loading = true, badgeDot = false, getAllPendingRequest(), isAlert = false">
        <!-- <b-button> -->
        <b-icon icon="bell" style="font-size: 25px;">
        </b-icon>
        <span class="badge" v-if="badgeDot">.</span>
        <!-- </b-button> -->
    </div>
    <b-modal v-model="requestNotificationModel" modal-class="mymodal" hide-header hide-footer size="md" scrollable header-bg-variant="red" body-class="modal-body">
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner text-center" width="30" height="30">
        </div>
        <div id="modal-div" v-if="requestDetails.length > 0">
            <b-card v-for="(r, index) in requestDetails" :key="index" class="text-center position-relative" @click="viewOfficeDetail(r)" id="card-portion">
                <!-- <b-card-section> -->
                <!-- <div class="space q-mt-sm">
                        <b-col cols="12" md="6" class="text-h5">{{ r.username }}</b-col>
                        <b-col cols="12" md="2">
                            <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" @click="viewOfficeDetail(r)">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                        </b-col>
                    </div> -->
                <!-- </b-card-section> -->
                <b-row>
                    <b-col cols="2">
                        <!-- <b-img src="/agent.png" alt="image" height="40px" width="50px"></b-img> -->
                        <!-- <div class="mb-4"><b-avatar variant="warning"></b-avatar></div> -->
                        <b-avatar :style="{ backgroundColor: randomColor()}" class="text-white"> <b>{{getInitialLetter(r.name)}}</b></b-avatar>
                    </b-col>
                    <b-col cols="9">
                        <div style="text-align: left; margin-bottom: -5px;">
                            <span style="font-size: small;"><b>{{ r.name }}</b></span>
                        </div>
                        <div style="text-align: left;">
                            <span style="font-size: small;"><b>{{ r.officeName }}</b></span>
                            <b-card-text style="margin-bottom: -5px;" class="text-muted">leave on: {{ simplyDateFormat(r.leaveFrom) }} - {{ simplyDateFormat(r.leaveTo) }}</b-card-text>
                            <div class="header">
                                <b-card-text class="text-muted">{{ truncatedText(r.requestBrief) }}</b-card-text>
                                <span class="text-muted elapsed-time" style="font-size: small;">{{ timePassed(r.createdAt) }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <div class="text-center nodata-img" v-else>
            <div class="img-nodata">
                <b-img src="/nodatafound.png" alt="image" width="210px"></b-img>
            </div>
        </div>
    </b-modal>
    <div class="fixed-top-right">
    <b-alert variant="info" show v-model="isAlert" >
        <template>
        <div class="d-flex flex-grow-1 align-items-baseline alert-header mb-xl">
            <span class="mr-auto"><strong>New Resquest</strong></span>
            <span @click="isAlert = false"><strong>X</strong></span>
        </div>
      </template>
      <br>
       <strong> {{ requesterName }}</strong> sent a Leave request.
    </b-alert>
  </div>
</div>
</template>

<script>
import NavBar from "./Navbar";
import Footer from "./Footer";
import moment from "moment";
import LeaveRequestService from '../service/LeaveRequestService';
import Account from "../service/Account";
import socket from "../router/socket";
// import Swal from 'sweetalert2';
export default {
    name: "SettingsLayout",
    data() {
        return {
            loading: false,
            requestCount: 0,
            requestNotificationModel: false,
            requestDetails: [],
            requestFields: [{
                    key: "name",
                    label: "Name",
                },
                // {
                //     key: "employeId",
                //     label: "EMP ID",
                // },
                {
                    key: "requestBrief",
                    label: "Brief",
                },
                {
                    key: "leaveFrom",
                    label: "From",
                },
                {
                    key: "leaveTo",
                    label: "To",
                },
                {
                    key: "action",
                    label: "Action",
                }
            ],
            modalBgVariant: 'secondary',
            badgeDot: false,
            requesterName: '',
            isAlert: false,
        };
    },
    components: {
        NavBar,
        Footer
    },
    watch: {
        isAlert(newValue, oldValue) {
            console.log("new"+newValue, "old"+oldValue);
            if (newValue == false) {
                this.requesterName = '';
            }
        }
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.getRequestCount();
                    this.socket();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        viewOfficeDetail(item) {
            const path = window.location.pathname;
            var currentUrl = path.split('/');
            var segmentUrl = currentUrl[1];
            const url = item.officeId;
            const targetPath = `/OfficeDetails/${url}`;
            if (segmentUrl == 'OfficeDetails') {
                if (this.$route.path !== targetPath) {
                    this.$router.push(targetPath);
                    localStorage.setItem('tabValue', 5);
                    window.location.reload();
                } else {
                    this.requestNotificationModel = false;
                }
            } else {
                this.$router.push({
                    name: 'OfficeDetails',
                    params: {
                        data: item.officeId,
                        tab: 5
                    }
                });
                this.requestNotificationModel = false;
            }
            // window.location.reload();
        },
        simplyDateFormat(date) {
            var dateArr = date.split("-");
            const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            if (dateArr[1] < 10) {
                var month = dateArr[1].charAt(1);
                return dateArr[2] + " " + monthArr[month];
            }
            return dateArr[2] + " " + monthArr[dateArr[1]];
        },
        timePassed(date) {
            return moment(date).fromNow();
        },
        getInitialLetter(name) {
            let initial = name.charAt(0);
            return initial.toUpperCase();
        },
        randomColor() {
            const hue = Math.floor(Math.random() * 360);
            const saturation = Math.floor(Math.random() * 50) + 50;
            const lightness = Math.floor(Math.random() * 30) + 50;
            const randomColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            return randomColor;
        },
        truncatedText(text) {
            const maxLength = 30;
            if (text.length > maxLength) {
                let truncatedText = text.slice(0, maxLength);
                return truncatedText.concat("...");
            }
            return text;
        },
        getRequestCount() {
            return new Promise((resolve, reject) => {
                LeaveRequestService.GetAllRequestCount()
                    .then((response) => {
                        this.requestCount = response.data;
                        // console.log("________IN getRequestCount__________" + this.requestCount);
                        if (this.requestCount > 0) {
                            this.getAllPendingRequest();
                            this.badgeDot = true;
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log("________ERROR IN getRequestCount__________" + error);
                        reject(error)
                    })
            })
        },
        getAllPendingRequest() {
            return new Promise((resolve, reject) => {
                LeaveRequestService.GetAllPendingRequest()
                    .then((response) => {
                        this.requestDetails = response.data;
                        // this.requestDetails =0;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log("_______ERROR IN getAllPendingRequest" + error);
                        reject(error);
                    })
            })
        },

        socket() {
            socket.connect();
            console.log("socket will connected");
            socket.on("response", (res) => {
                console.log("response",res.tenantId, localStorage.getItem('tenant-uuid'), (localStorage.getItem('tenant-uuid') == res.tenantId));
                if (localStorage.getItem('tenant-uuid') == res.tenantId) {
                    this.badgeDot = true;
                    this.isAlert = true;
                    this.requesterName = res.requesterName;
                }
            });
        },
        
    },
};
</script>

<style scoped>
.badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    /* color: white; */
    color: red;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
}

.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

::v-deep .mymodal {
    position: fixed;
    top: 60%;
    left: 75%;
    margin-bottom: 100px;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    display: none;
    max-height: 80%;
    overflow-y: auto;
}

@media (max-width: 768px) {
    ::v-deep .mymodal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;
    }

    .header {
        display: flex;
        flex-direction: column;
    }

    .elapsed-time {
        margin-top: -20px;
        margin-bottom: 5px;
    }
    .fixed-top-right {
        position: fixed;
        top: 1rem;
        right: 1rem;
        z-index: 1000;
        text-align: left;
        margin-left: 10px;
    }
}

.loader {
    position: fixed;
    left: 34%;
    top: 9%;
    width: 32%;
    height: 370px;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: -25px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.nodata-img {
    height: 400px;
    position: relative;
}

.img-nodata {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

::v-deep .modal-body {
    padding: 0;

}

#card-portion:hover {
    border: 3px solid rgb(195, 188, 188);
    cursor: pointer;
}
.fixed-top-right {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

/* .position-absolute {
  position: absolute !important;
} */
.alert-header {
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: -25px;
}
</style>
