<template>
<div>
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
	<div class="mt-5">
		<b-container>
			<b-row align-v="center">
				<b-col col lg="1" md="1" id="r1" sm="6">
					<b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.attendanceModal disabled>Attendance</b-button>
				</b-col>
				<b-col col sm="12" md="3" lg="3" id="r1">
					<input type="search" class="searchbar" v-model="keyword" placeholder="" disabled/>
				</b-col>
				<b-col col lg="1" md="1" id="r1" sm="6">
					<b-button size="sm" variant="outline-primary" class="bnclr" disabled>Search</b-button>
				</b-col>
				<b-col sm="12" md="3" lg="4" class="my-1" id="r1">
					<b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
				</b-col>
				<b-col sm="6" md="3" lg="2" class="" id="r1">
					<label class="font mx-1">Per Page</label>
					<b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
					</b-form-select>
				</b-col>
				<b-col col lg="1" md="1" id="r1" sm="6">
					<span style="color: #0974ff; cursor: pointer" @click="refresh()">
						<font-awesome-icon icon="sync-alt" />Refresh</span>
				</b-col>
			</b-row>
		</b-container>
		<b-container>
			<b-card class="card mt-1">
				<hr />
				<b-table responsive hover :items="attendances" :fields="attendanceFields" :current-page="currentPage" :per-page="attendances.pageCount" ref="table">
					<template v-slot:cell(date)="data">
						{{ dateFormat(data.item.date, "date") }}
					</template>
					<template v-slot:cell(employee)="data">
						{{ data.item.employee.firstName }}
						<!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
					</template>
					<template v-slot:cell(code)="data">
						{{ data.item.employee.code }}
					</template>
					<template v-slot:cell(status)="data">
						<span v-if="data.item.status == 'ABSENT'">
							<b-badge style="background-color: #ff0000;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
							</b-badge>
						</span>
						<span v-else>
							<b-badge style="background-color: #228b22;color: #fff;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" variant="warning">{{ data.item.status }}
							</b-badge>
						</span>
					</template>
					<template v-slot:cell(present)="data">
						<span v-if="data.item.present">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(absent)="data">
						<span v-if="data.item.absent">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(fullDayPresent)="data">
						<span v-if="data.item.fullDayPresent">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(halfDayPresent)="data">
						<span v-if="data.item.halfDayPresent">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(outdoorDuty)="data">
						<span v-if="data.item.outdoorDuty">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(overTime)="data">
						<span v-if="data.item.overTime">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
					<template v-slot:cell(holiday)="data">
						<span v-if="data.item.holiday">
							<svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
							</svg>
						</span>
						<span v-else>
							<svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
							</svg>
						</span>
					</template>
				</b-table>
			</b-card>
		</b-container>
	</div>
</div>
</template>

<script>
import AttendanceService from "../service/AttendanceService";
import Account from "../service/Account";
import moment from "moment-timezone";

export default {
    name: 'UserAttendance',
    data() {
        return {
			currentUser: null,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalCount: 0,
            keyword: "",
            attendance: {
                attendanceId: "",
                firstName: "",
                status: null,
            },
            attendances: [],
            attendanceFields: [{
                    key: "date",
                    label: "Date",
                },
                {
                    key: "employee",
                    label: "Employee",
                },
                {
                    key: "code",
                    label: "code",
                },
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "present",
                    label: "Present",
                },
                {
                    key: "absent",
                    label: "Absent",
                },
                {
                    key: "fullDayPresent",
                    label: "FullDay",
                },
                {
                    key: "halfDayPresent",
                    label: "HalfDay",
                },
                // {
                //     key: "outdoorDuty",
                //     label: "OD",
                // },
                // {
                //     key: "overTime",
                //     label: "OT",
                // },
                // {
                //     key: "holiday",
                //     label: "Holiday",
                // },
                // {
                //     key: "actions",
                //     label: "Actions",
                // },
            ],
            date: "",
            selectedEmployee: null,
            markEmployees: [],
            unMarkEmployees: [],
            disabledButton: false,
            markOption: false,
            attendancePageable: false,
            attendanceSearchPageable: false,
            selectedDates: null,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                // {
                //     value: "today",
                //     text: "Today",
                // },
                // {
                //     value: "yesterday",
                //     text: "Yesterday",
                // },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "lastThreeMonths",
                    text: "Past 90 days",
                },
                {
                    value: "allData",
                    text: "Past all days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true,
                },
            ],
            from: "",
            to: "",

            loadAttendancePresentChart: false,
            loadAttendanceAbsentChart: false,
            // loadAgentAttendanceChart: false,

            chartData1: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData2: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartOptions: {
                responsive: true,
            },
            status: "",
            attendanceStatus: [{
                    text: "Status",
                    value: "null",
                    disabled: true
                },
                {
                    text: "ABSENT",
                    value: "ABSENT"
                },
                {
                    text: "PRESENT",
                    value: "PRESENT"
                },
                {
                    text: "FULLDAYPRESENT",
                    value: "FULLDAYPRESENT"
                },
                {
                    text: "HALFDAYPRESENT",
                    value: "HALFDAYPRESENT"
                },
            ],
        }
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
					this.currentUser = JSON.parse(localStorage.getItem('current_user'));
                    var currentDate = new Date();
                    this.date = moment(currentDate).format("YYYY-MM-DD");
                    this.getAttendanceCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        getAttendanceCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.attendancePageable = true;
            this.attendanceSearchPageable = false;
            return new Promise((resolve, reject) => {
                AttendanceService.getUserAttendanceCount(this.date,this.currentUser)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAttendancePageable(
                            this.currentPage,
                            this.pageCount,
                            this.date
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
			this.getAllAttendancePageable(currentPage, pageCount, this.date);
        },
        getAllAttendancePageable: function (currentPage, pageCount, date) {
            return new Promise((resolve, reject) => {
                AttendanceService.getUserAttendancePageable(currentPage, pageCount, date, this.currentUser)
                    .then((response) => {
                        this.attendances = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
		dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "date") {
                return moment(date1).format("D / MM / YYYY");
            }
        },
    }
}
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
