import axios from 'axios';
export default{

    getAttendanceCount: function(data){
        // console.log("=====Data=====",data);
        // var ax = axios.create({
        //     baseURL: restServerLocation,
        //   });
      
        //   let config = {
        //       headers: {
        //           "Content-Type": "application/json",
        //           'tenant-uuid': localStorage.getItem("tenant-uuid"),
        //         //   'tenant-uuid': '',
        //       },
        //   };
      
        return new Promise((resolve, reject) => {
            // ax.post("/check/count",data,config)
            axios({
                method: 'post',
                url: '/check/allAttendanceCount',
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    
    getAllAttendancePageable: function(currentPage,pageCount,data){
        // var ax = axios.create({
        //     baseURL: restServerLocation,
        //   });
      
        //   let config = {
        //       headers: {
        //           "Content-Type": "application/json",
        //           'tenant-uuid': localStorage.getItem("tenant-uuid"),
        //         //   'tenant-uuid': '',
        //       }
        //   };
      
        return new Promise((resolve, reject) => {
            // ax({
            //     method: 'post',
            //     url: '/check/all/' + (currentPage-1) +"/"+ pageCount,data,config,
            // })
            axios.post("/check/allAttendance/" + (currentPage-1) +"/"+ pageCount,data)
            .then((response) => {
    
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

}