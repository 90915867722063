<template>
<div>
    <b-container>
        <!-- pop start for course register -->
        <b-modal id="addCourse" title="Course" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Course Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Course Name" v-model.trim="$v.course.name.$model" :class="{
                'is-invalid': $v.course.name.$error,
                'is-valid': !$v.course.name.$invalid,
              }">
                    </b-form-input>
                    <div class="valid-feedback">Course Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.course.name.required">Course Name is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Course Code:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.code.$model" :class="{
                'is-invalid': $v.course.code.$error,
                'is-valid': !$v.course.code.$invalid,
              }" placeholder="Enter your Code">
                    </b-form-input>
                    <div class="valid-feedback">Your Code is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.course.code.required">Code is required</span>
                    </div>
                </div>
                <br />

                <div class="form-group">
                    <label>Course Link:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.courseLink.$model"  :class="{'is-invalid': $v.course.courseLink.$error,'is-valid': !$v.course.courseLink.$invalid,}" placeholder="Enter your course link">
                                                                                            <!-- @input="courseLinkValidation()" -->
                    </b-form-input>
                    <div class="valid-feedback">Your Course Link is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.course.courseLink.required">Course Link is required</span>
                    </div>

                    <!-- <div v-if="course.courseLink != '' && course.courseLink != null">
                        <div v-if="edailyCourse == null || edailyCourse == ''">
                            <p style="color:#DC3545;">The link is not valid please check the above link</p>
                        </div>
                        <div v-else>
                            <p>
                                This link course is <span style="color:#198754;text-weiht:bold">**{{edailyCourse.courseTitle}}**</span>
                            </p>
                        </div>
                    </div> -->
                </div><br>

                <div class="form-group">
                    <label>Course Price:</label>
                    <b-form-input class="form-control" type="number" v-model.trim="$v.course.price.$model" :class="{'is-invalid': $v.course.price.$error,'is-valid': !$v.course.price.$invalid,}" placeholder="Enter your course price">
                    </b-form-input>
                    <div class="valid-feedback">Your Course Price is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.course.price.required">Course Price is required</span>
                    </div>
                </div>
                <br />

                <!-- <div class="form-group">
                    <label>Course Discount Price:</label>{{ course.dprice }}
                    <b-form-input class="form-control" type="number" v-model.trim="$v.course.dprice.$model" :class="{'is-invalid': $v.course.dprice.$error,'is-valid': !$v.course.dprice.$invalid,}" placeholder="Enter your course Discount price">
                    </b-form-input>
                    <div class="valid-feedback">Your Course Discount Price is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.course.dprice.required">Course Discount Price is required</span>
                    </div>
                </div>
                <br /> -->

                <div class="text-center">
                    <b-button variant="outline-success" type="submit" :disabled="disabledButton" @click="disabledButton = true,putCourse()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!-- popup end for Course Register -->
    </b-container>
    <!-- Loader started -->
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
    <!-- Loader Ended -->
    <!-- Course Table -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!--add branch button start-->
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCourse @click="resetForm()">Add Course</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class=" searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1 ">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color:#0974ff;cursor:pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <p>Course</p>
                <hr>
                <b-table responsive hover :items="courses" :fields="fields" :current-page="currentPage" :per-page="courses.pageCount" ref="table">
                    <template v-slot:cell(courseId)="data">
                        <span class="title"> {{data.item.courseId}}</span>
                    </template>
                    <template v-slot:cell(name)="data">
                        {{data.item.name}}
                    </template>
                    <template v-slot:cell(code)="data">
                        {{data.item.code}}
                    </template>
                    <template v-slot:cell(price)="data">
                        {{data.item.price}}
                    </template>
                    <!-- <template v-slot:cell(dprice)="data">
                        {{data.item.dprice}}
                    </template> -->

                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Course Details" v-b-modal.viewcourse @click="getCourse(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button>
                        <!-- <b-button  variant="danger"
               v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Course Details"

                @click="deleteCourse(data.item.courseId)"
         class="fa fa-trash px-3" >
               </b-button> -->
                        <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Course Details" v-b-modal.updatecourse @click="getCourse(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button>
                        <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Course Details" v-if="data.item.archive==false" variant="success" @click="archive(data.item.courseId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Unarchive Course Details" v-if="data.item.archive==true" variant="warning" @click="unarchive(data.item.courseId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                    </template>

                </b-table>
            </b-card>
        </b-container>
    </div>
    <!-- Course Table End -->
    <!-- View Course Start -->
    <b-container>
        <b-modal id="viewcourse" title="View course" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Course Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Course Name" v-model=course.name disabled>
                    </b-form-input>

                </div>
                <br />
                <div class="form-group">
                    <label>Course Code:</label>
                    <b-form-input class="form-control" v-model=course.code disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Course Link:</label>
                    <b-form-input class="form-control" v-model="course.courseLink" disabled placeholder="update your course link">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Course Price:</label>
                    <b-form-input class="form-control" v-model="course.price" disabled >
                    </b-form-input>
                </div>
                <br />
                <!-- <div class="form-group">
                    <label>Course Discount Price:</label>
                    <b-form-input class="form-control" v-model="course.dprice" disabled >
                    </b-form-input>
                </div>
                <br /> -->
            </div>

        </b-modal>
    </b-container>
    <!-- View Course End -->
    <!-- Update Course Start -->
    <b-container>
        <b-modal id="updatecourse" title="Update Course" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Name:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.name.$model" :class="{
                'is-invalid': $v.course.name.$error,
                'is-valid': !$v.course.name.$invalid,
              }">
                    </b-form-input>
                </div>
                <div class="form-group">
                    <label>Code:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.code.$model" :class="{
                'is-invalid': $v.course.code.$error,
                'is-valid': !$v.course.code.$invalid,
              }">
                    </b-form-input>
                </div>
                <div class="form-group">
                    <label>Course Link:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.courseLink.$model" :class="{'is-invalid': $v.course.courseLink.$error,'is-valid': !$v.course.courseLink.$invalid,}">
                    </b-form-input>
                </div>
                <div class="form-group">
                    <label>Course Price:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.price.$model" :class="{'is-invalid': $v.course.price.$error,'is-valid': !$v.course.price.$invalid,}">
                    </b-form-input>
                </div>
                <!-- <div class="form-group">
                    <label>Course Discount Price:</label>
                    <b-form-input class="form-control" v-model.trim="$v.course.dprice.$model" :class="{'is-invalid': $v.course.dprice.$error,'is-valid': !$v.course.dprice.$invalid,}">
                    </b-form-input>
                </div> -->
                <div class="text-center pt-3">
                    <b-button size="sm" id="submit" variant="outline-danger" :disabled="disabledButton" @click="disabledButton = true,updateCourse()">Update</b-button><br />
                </div>
            </div>
        </b-modal>
    </b-container>
    <!-- Update Course End -->
</div>
</template>

<script>
import {
    required,
    minLength,
} from "vuelidate/lib/validators";
import CourseService from "../service/CourseService";

import Account from '../service/Account.js';
import axios from "axios";
export default {
    name: "Course",
    components: {

    },
    data() {

        return {
            edailyCourse: {
                courseId: '',
                courseTitle: '',
                batch: []
            },
            disabledButton: false,
            paginationCourses: false,
            searchPaginationCourses: false,
            //  Loader
            loading: false,
            // Pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            keyword: "",
            //  course  data render in table
            course: {
                name: "",
                code: "",
                courseLink: "",
                price: null,
                // dprice: null,
            },
            courses: [],
            fields: [
                // { key:'courseId'},
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'code',
                    label: 'Code'
                },
                {
                    key: 'courseLink',
                    label: 'Course Link'
                },
                {
                    key: 'price',
                    label: 'Price'
                },
                // {
                //     key: 'dprice',
                //     label: 'Discount Price'
                // },
                {
                    key: 'actions',
                    label: 'Actions'
                },

            ]
        };
    },
    validations: {
        course: {
            name: {
                required,
                minLength: minLength(3),
            },
            code: {
                required,
                minLength: minLength(3),

            },
            courseLink: {
                required
            },
            price: {
                required,

            },
            // dprice:{
            //     required
            // }

        },

    },

    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    self.getAllCourses();
                    // self.getAllPageable();
                    self.getCount();
                    // this.loading=true
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
        // this.getAllCourses();
        // Global.refreshToken();
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        }
    },

    methods: {

        // insert method
        courseLinkValidation: function() {
            var bumblebeeRest = axios.create({
                baseURL: this.$bumblebeeRest,
            });
            let data = {
                coursePackUrl : this.course.courseLink
            }
            console.log("bumblebeeRest.defaults.headers",bumblebeeRest.defaults.headers);
            // let common = bumblebeeRest.defaults.headers.common["tenant-uuid"] = null;
            // let { tenant-uuid, ...common } = myObject;
            delete bumblebeeRest.defaults.headers.common["tenant-uuid"]
            delete bumblebeeRest.defaults.headers.common["Authorization"]
            console.log("bumblebeeRest.defaults.headers",bumblebeeRest.defaults.headers);

            return new Promise((resolve, reject) => {
                bumblebeeRest
                    .post("course/getBy/coursePackUrl",data)
                    .then((response) => {
                        console.log("course/get/coursePackUrl===========",response.data);
                        this.edailyCourse = response.data;
                        resolve(response);
                    })
                    .catch((err) => {

                        reject(err);
                    });
            });
        },
        putCourse: function () {
            // console.log("Call Course :",this.course)
            this.$v.$touch();
            // if (this.$v.$invalid) {
            //     this.submitstatus = "FAIL";
            // }
            //  else {
                console.log(this.$v);
            if (!this.$v.$invalid) {
                this.disabledButton = true;
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    CourseService.insertCourse(this.course)
                        .then((response) => {
                            this.$swal({
                                title: "Good job!",
                                text: "Course Added successfully!!!!!",
                                icon: "success",
                                button: "ok",
                            });

                            this.$refs.modal.hide();
                            // this.getAllCourses();
                            // this.getAllPageable();
                            this.getCount();
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                this.loading = true
                CourseService.getAllAdminCourses()
                    .then((response) => {
                        this.courses = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        deleteCourse: function (courseId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            CourseService.deleteCourse(courseId)
                                .then((response) => {
                                    this.$swal("Deleted!", "Courses has been deleted.", "success");
                                    this.getAllCourses();
                                    this.getCount();
                                    this.getAllPageable();
                                    resolve(response);
                                });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCourse: function (course) {
            // console.log("Course : ", course)
            this.course = course;
        },

        updateCourse: function () {
            this.$v.$touch();
            // if (this.$v.$invalid) {
            //     this.submitstatus = "FAIL";
            // } else {
            if (!this.$v.$invalid) {
                this.disabledButton = true;
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    CourseService.updateCourse(this.course)
                        .then((response) => {
                            this.$swal('course details updated successfully!!!!!');
                            this.$refs.modal.hide();
                            // this.getAllCourses();
                            this.getCount()
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },
        archive: function (id) {

            return new Promise((resolve, reject) => {
                CourseService.archive(id)
                    .then(response => {
                        this.$swal('Archive successfully!!!!!');
                        this.getCount()

                        //  console.log(response.data);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        unarchive: function (id) {

            return new Promise((resolve, reject) => {
                CourseService.unarchive(id)
                    .then(response => {
                        this.$swal('UnArchive successfully!!!!!');
                        this.getCount()

                        //  console.log(response.data);
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.paginationCourses = true;
            this.searchPaginationCourses = false;
            this.currentPage = 1;
            this.keyword = "";
            return new Promise((resolve, reject) => {
                CourseService.getCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function () {
            // console.log("currentPage",this.currentPage)
            // console.log("pageCount",this.pageCount)
            return new Promise((resolve, reject) => {
                CourseService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        this.courses = response.data;
                        // console.log("Response",response.data)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            // this.loading = true
            // this.getAllPageable(pageCount);
            this.loading = true;
            //   console.log("switchPage calling");
            if (this.paginationCourses) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPaginationCourses) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            }
        },

        // searchCourse: function(keyword) {
        //   return new Promise((resolve, reject) => {
        //     CourseService.searchCourse(keyword.toLowerCase())
        //       .then((response) => {
        //         this.courses = response.data;
        //         // this.currentPage = 1

        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getSearchCount: function (keyword) {
            this.paginationCourses = false;
            this.searchPaginationCourses = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                CourseService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                CourseService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        //   this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.courses = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        refresh() {

            this.getCount();
            this.keyword = "";

        },
        resetForm() {
            this.course = {
                name: null,
                code: null,
                courseLink: "",
                price:null,
                // dprice:null
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09A5FF;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09A5FF;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09A5FF;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09A5FF;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
