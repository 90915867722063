import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Login";
import Settings from "../components/Settings";

import Branch from "../components/Branch";
import Agent from "../components/Agent";
import Tutor from "../components/Tutor";
import Source from "../components/Source";
import Course from "../components/Course";
import Batch from "../components/Batch";
import ClassReport from "../components/ClassReport";
import EditSyllabus from "../components/EditSyllabus";
import CreateSyllabus from "../components/CreateSyllabus";
import Syllabus from "../components/Syllabus";
import Dashboard from "../components/Dashboard"
import AdminAdmissions from "../components/AdminAdmissions";
import AdminBookOrders from "../components/AdminBookOrders";
import AdminCalls from "../components/AdminCalls";
import AdminEnquiries from "../components/AdminEnquiries";
import TutorAdminSupervisor from "../components/TutorAdminSupervisor";
import ClassReportAdminSupervisor from "../components/ClassReportAdminSupervisor";

// import PublisherLayout from "../components/PublisherLayout";
import SettingsLayout from "../components/SettingsLayout";
// import CallsLayout from "../components/CallsLayout";
import Calls from "../components/Calls";
import BookOrders from "../components/BookOrders";
import CourierPrint from "../components/CourierPrint";
import CallsAndSms from "../components/CallsAndSms";
// import CallsAndSmsLayout from "../components/CallsAndSmsLayout";
import Layout from "../components/Layout";
import AdminLayout from "../components/AdminLayout";
import BranchBatch from "../components/BranchBatch"
import Admission from "../components/Admission"
import Enquiry from "../components/Enquiry"
import Invoice from "../components/Invoice"
import Page404 from "../components/404"
import Followup from "../components/Followup"
import Notintrested from "../components/Notintrested"
import Insights from "../components/Insights"
import AdminFollowups from "../components/AdminFollowups"
import AdminNotintresteds from "../components/AdminNotintresteds"
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import SetPassword from '../components/SetPassword'
import SuperAdminTeam from '../components/SuperAdminTeam'
import SuperAdminUser from '../components/SuperAdminUser'
import MobileAppRelease from '../components/MobileAppRelease'
// import EdexBatch from '../components/EdexBatch'

import SuperAdminLayout from '../components/SuperAdminLayout'
import User from '../components/User';

import HRLayout from '../layout/HRLayout';
import Employee from '../components/Employee';
import Attendance from '../components/Attendance';
import AttendanceReport from '../components/AttendanceReport'
import AttendanceExcelExport from '../components/AttendanceExcelExport'

import UserAttendance from '../components/UserAttendance'
import BookCourier from '../components/BookCourier'
import ChangePassword from '../components/ChangePassword'
import Leads from '../components/Leads'
import CounsellorEnquiries from '../components/CounsellorEnquiries'
import Campaign from '../components/Campaign'
import BookBundle from '../components/BookBundle'
import AdminLeads from '../components/AdminLeads'
import CallLogs from '../components/CallLogs'
import CustomField from '../components/CustomField'
import FollowupCustomField from '../components/FollowupCustomField'
import Office from '../components/Office'
import OfficeDetails from '../components/OfficeDetails'
import EmployeeAttendance from '../components/EmployeeAttendance'
import AllAttendancePage from '../components/AllAttendancePage'
import PaymentFailurePage from '../components/PaymentFailurePage'
import PaymentSuccessPage from '../components/PaymentSuccessPage'
import BookPaymentSuccess from '../components/BookPaymentSuccess'
import BookPurchaseSuccess from '../components/BookPurchaseSuccess'
import TermsAndService from '../components/TermsAndService'
import Policy from '../components/Policy'
import Cookies from '../components/Cookies'

Vue.use(VueMoment, {
  moment,
})
Vue.use(VueRouter);

const routes = [

  {
    path: "/user",
    name: "UserLayout",
    component: Layout,
    meta: {
      requiresAuth: true,
      is_telecaller: true,
      is_admin: true
    },

    children: [
      {
        path: "/user/attendance",
        name: "UserAttendance",
        component: UserAttendance,
        meta: {
          requiresAuth: true,
          is_user: true
        },
      },
      {
        path: "/bookCourier",
        name: "BookCourier",
        component: BookCourier,
        meta: {
          requiresAuth: true,
          is_courier: true,
          is_super_admin: true
        },
      },
      {
        path: "/user/leads",
        name: "Leads",
        component: Leads,
        meta: {
          requiresAuth: true,
          is_telecaller: true
        },
      },
      {
        path: "/user/calls",
        name: "Calls",
        component: Calls,
        meta: {
          requiresAuth: true,
          is_telecaller: true
        },
      },
      {
        path: "/user/bookOrders",
        name: "BookOrders",
        component: BookOrders,
        meta: {
          requiresAuth: true,
          is_telecaller: true
        },
      },
      {
        path: "/user/insights",
        name: "Insights",
        component: Insights,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/user/branchBatch",
        name: "BranchBatch",
        component: BranchBatch,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
          is_supervisor : true,
          is_admin_supervisor : true,
        },
      },
      {
        path: "/user/admission",
        name: "Admission",
        component: Admission,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/user/enquiry",
        name: "Enquiry",
        component: Enquiry,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/user/invoice",
        name: "Invoice",
        component: Invoice,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/user/followup",
        name: "Followup",
        component: Followup,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/user/notintrested",
        name: "Notintrested",
        component: Notintrested,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_counsellor :true,
        },
      },
      {
        path: "/callsandsms",
        name: "CallsAndSms",
        component: CallsAndSms,
        meta: {
          requiresAuth: true,
          is_telecaller: true
        },
      },
      {
        path: "/user/tutor",
        name: "TutorAdminSupervisor",
        component: TutorAdminSupervisor,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_admin_supervisor : true,
        },
      },
      {
        path: "/user/report",
        name: "ClassReportAdminSupervisor",
        component: ClassReportAdminSupervisor,
        meta: {
          requiresAuth: true,
          is_telecaller: true,
          is_admin_supervisor : true,
        },
      },
      {
        path: "/user/counsellor",
        name: "CounsellorEnquiries",
        component: CounsellorEnquiries,
        meta: {
          requiresAuth: true,
          is_admin_supervisor : true,
        },
      },
    ],
  },
  {
    path: "/super-admin",
    name: "SuperAdminLayout",
    component: SuperAdminLayout,
    meta: {
      requiresAuth: true,
      is_super_admin: true
    },
    children: [{
      path: "/super-admin/team",
      name: "SuperAdminTeam",
      component: SuperAdminTeam,
        meta: {
          requiresAuth: true,
          is_super_admin: true
        },
      },
      {
        path: "/super-admin/user",
        name: "SuperAdminUser",
        component: SuperAdminUser,
        meta: {
          requiresAuth: true,
          is_super_admin: true
        },
      },
      {
        path: "/super-admin/mobileAppRelease",
        name: "MobileAppRelease",
        component: MobileAppRelease,
        meta: {
          requiresAuth: true,
          is_super_admin: true
        },
      },
      // {
      //   path: "/super-admin/Edex",
      //   name: "EdexBatch",
      //   component: EdexBatch,
      //   meta: {
      //     requiresAuth: true,
      //     is_super_admin: true
      //   },
      // },
    ]
  },
  {
    path: "/admin",
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      requiresAuth: true,
      is_admin: true
    },
    children: [{
        path: "/admin/calls",
        name: "AdminCalls",
        component: AdminCalls,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/admin/log",
        name: "CallLogs",
        component: CallLogs,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/admin/bookOrders",
        name: "AdminBookOrders",
        component: AdminBookOrders,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
     
      {
        path: "/admin/admission",
        name: "AdminAdmission",
        component: AdminAdmissions,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/admin/enquiry",
        name: "AdminEnquiry",
        component: AdminEnquiries,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/admin/followup",
        name: "AdminFollowup",
        component: AdminFollowups,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/admin/notintrested",
        name: "AdminNotintrested",
        component: AdminNotintresteds,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
    ],
  },
  {
    path: "/hr",
    name: "HRLayout",
    component: HRLayout,
    meta: {
      requiresAuth: true,
      is_hr: true,
    },
    children: [
      {
        path: "/hr/user",
        name: "User",
        component: User,
        meta: {
          requiresAuth: true,
          is_hr: true
        },
      },
      {
        path: "/hr/employees",
        name: "Employee",
        component: Employee,
        meta: {
          requiresAuth: true,
          is_hr: true,
        },
      },
      {
        path: "/hr/attendance",
        name: "Attendance",
        component: Attendance,
        meta: {
          requiresAuth: true,
          is_hr: true,
        },
      },
      {
        path: "/hr/report",
        name: "AttendanceReport",
        component: AttendanceReport,
        meta: {
          requiresAuth: true,
          is_hr: true,
        },
      },
      {
        path: "/hr/excel/export",
        name: "AttendanceExcelExport",
        component: AttendanceExcelExport,
        meta: {
          requiresAuth: true,
          is_hr: true,
        },
      },
    ],
  },

  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
  },
 
  {
    path: "/terms",
    name: "terms",
    component: TermsAndService,
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
    
  },
  {
    path: "/courierPrint/:orderId",
    name: "CourierPrint",
    component: CourierPrint,
    meta: {
      requiresAuth: true,
      is_courier: true,
    },
  },
  {
    path: "/set_password",
    name: "set_password",
    component: SetPassword,
    props: true,
  },
  {
    path: "/change_password",
    name: "ChangePassword",
    component: ChangePassword,
    props: true,
  },
  {
    path: "/pay_failed",
    name: 'PaymentFailurePage',
    component: PaymentFailurePage,
    props: true,
  },
  {
    path: "/pay_success",
    name: 'PaymentSuccessPage',
    component: PaymentSuccessPage,
    props: true,
  },
  {
    path: "/successPage/:linkBookOrderId",
    name: 'BookPaymentSuccess',
    component: BookPaymentSuccess,
    props: true,
  },
  {
    path: "/bookPurchased",
    name: 'BookPurchaseSuccess',
    component: BookPurchaseSuccess
  },
  {
    path: "/404",
    name: "Page404",
    component: Page404,
  },
  {
    path: "/settingslayout",
    name: "SettingsLayout",
    component: SettingsLayout,
    meta: {
      requiresAuth: true,
      is_admin: true
    },
    children: [{
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },

      {
        path: "/settings/user",
        name: "User",
        component: User,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/branch",
        name: "Branch",
        component: Branch,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },

      {
        path: "/settings/agent",
        name: "Agent",
        component: Agent,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },

      {
        path: "/settings/tutor",
        name: "Tutor",
        component: Tutor,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/source",
        name: "Source",
        component: Source,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/course",
        name: "Course",
        component: Course,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/batch",
        name: "Batch",
        component: Batch,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/classReport",
        name: "batchStatus",
        component: ClassReport,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/editsyllabus/:syllabusId",
        name: "EditSyllabus",
        component: EditSyllabus,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/createsyllabus",
        name: "CreateSyllabus",
        component: CreateSyllabus,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/syllabus",
        name: "Syllabus",
        component: Syllabus,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/campaign",
        name: "Campaign",
        component: Campaign,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/bookbundle",
        name: "BookBundle",
        component: BookBundle,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/leads",
        name: "AdminLeads",
        component: AdminLeads,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/customfield",
        name: "CustomField",
        component: CustomField,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/office",
        name: "Office",
        component: Office,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/followupcustomfield",
        name: "FollowupCustomField",
        component: FollowupCustomField,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/OfficeDetails/:data",
        name: "OfficeDetails",
        component: OfficeDetails,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/employeeAttendance/:data",
        name: "EmployeeAttendance",
        component: EmployeeAttendance,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
      {
        path: "/settings/allAttendancePage",
        name: "AllAttendancePage",
        component: AllAttendancePage,
        meta: {
          requiresAuth: true,
          is_admin: true
        },
      },
    
    ],

    

  },


];



const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "active",
});


router.beforeEach((to, from, next) => {
  if(to.path === "/pay_success") {
    localStorage.clear();
  }
  next();

  var currentUser = JSON.parse(localStorage.getItem("current_user"));

  let is_super_admin = false;
  let is_admin = false;
  let is_hr = false;
  let is_admin_supervisor = false;
  let is_supervisor = false;
  let is_counsellor = false;
  let is_telecaller = false;
  let is_courier=false;
  let is_user = false;
  // let Counsellor = JSON.parse(localStorage.getItem('ROLE_COUNSELLOR'));
  // let supervisor = JSON.parse(localStorage.getItem('ROLE_SUPERVISOR'));
  // let adminSupervisor = JSON.parse(localStorage.getItem('ROLE_ADMIN_SUPERVISOR'));
  // let superAdmin = JSON.parse(localStorage.getItem('ROLE_SUPER_ADMIN'));
  // let hr = JSON.parse(localStorage.getItem('ROLE_HR'));

  // if(superAdmin) {
  //   is_super_admin = true;
  // }
  // if(adminSupervisor) {
  //   is_telecaller = false;
  //   is_admin_supervisor = true;
  // }
  // if(supervisor) {
  //   is_telecaller = false;
  //   is_supervisor = true;
  // }
  // if(Counsellor){
  //   is_telecaller = false;
  //   is_counsellor =true;
  // }
  // if(hr) {
  //   is_hr = true;
  // }
  // else{
  //   is_telecaller = true;
  // }

  if (currentUser != null) {
    let roles = currentUser.roles
    roles.forEach(e => {
      if (e.roleName == 'ROLE_SUPER_ADMIN')
        is_super_admin = true;
      if (e.roleName == 'ROLE_ADMIN')
        is_admin = true;
      if (e.roleName == 'ROLE_HR')
        is_hr = true;
      if (e.roleName == 'ROLE_ADMIN_SUPERVISOR')
        is_admin_supervisor = true;
      if (e.roleName == 'ROLE_SUPERVISOR')
        is_supervisor = true;
      if (e.roleName == 'ROLE_COUNSELLOR')
        is_counsellor = true;
      if (e.roleName == 'ROLE_TELECALLER')
        is_telecaller = true;
      if (e.roleName == 'ROLE_COURIER')
      is_courier = true;
      if (e.roleName == 'ROLE_USER')
        is_user = true;
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(localStorage.getItem("accessToken") == null || localStorage.getItem("accessToken") == "") {
      next({
        path: '/',
        query: {
            redirect: to.fullPath
        }
      });
    }
    if (to.matched.some(record => record.meta.is_super_admin)) {
      if (is_super_admin) {
        // is_telecaller=false;
        next()
      }
    }
    if (to.matched.some(record => record.meta.is_counsellor)) {
      if (is_counsellor) {
        // is_telecaller=false;
        next()
      }
    }
    if (to.matched.some(record => record.meta.is_telecaller)) {
      if (is_telecaller || is_admin) {
        next()
      }
    }

    if (to.matched.some(record => record.meta.is_admin)) {
      if (is_admin) {
        next()
      }
    }

    if (to.matched.some(record => record.meta.is_hr)) {
      if (is_hr) {
        next()
      }
    }

    if (to.matched.some(record => record.meta.is_supervisor)) {
      if (is_supervisor) {
        is_telecaller=false;
        next()
      }
    }

    if (to.matched.some(record => record.meta.is_admin_supervisor)) {
      if (is_admin_supervisor) {
        is_telecaller=false;
        next()
      }
    }
    if (to.matched.some(record => record.meta.is_courier)) {
      if (is_courier) {
        next()
      }
    }

    if (to.matched.some(record => record.meta.is_user)) {
      if (is_user) {
        next()
      }
    }
  }
   else {
    if(localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_ADMIN'){
      router.push({
        path: "/settings"
      })
    }
    else if(localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_ADMIN_SUPERVISOR'){
      router.push({
        path: "/user/branchBatch"
      })
    }
    else if(localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_SUPERVISOR'){
      router.push({
        path: "/user/branchBatch"
      })
    }
    else if(localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_COUNSELLOR'){
      router.push({
        path: "/user/enquiry"
      })
    }
    else if (localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_TELECALLER') {
      router.push({
        path: "/user/calls"
      })
    } else if (localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_HR') {
      router.push({
        path: "/hr/employees"
      })
    }
     else if (localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_COURIER') {
      router.push({
        path: "/bookCourier"
      })
    }
    else if (localStorage.getItem("accessToken") && localStorage.getItem('roles') == 'ROLE_USER') {
      router.push({
        path: "/user/attendance"
      })
    }
    else {
      next();
    }
  }
});


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth )               ) {
//     console.log("Level 1 ");
//     if(to.matched.some(record => record.meta.is_telecaller)){
//       console.log("Level 2 ");
//       var currentUser=JSON.parse(localStorage.getItem("current_user"));
//       let r=currentUser.roles;
//       if(currentUser != null){
//       for (let i = 0; i < r.length; i++) {
//         if (r[i].roleName == 'ROLE_TELECALLER') {
//           console.log("MAIN ELSE ROLE_TELECALLER CHECK");
//           next();
//       }
//       if (r[i].roleName == 'ROLE_TELECALLER'||r[i].roleName == 'ADMIN {
//         console.log("MAIN ELSE ROLE_TELECALLER OR ADMIN");
//         next();
//     }
//       }
//     }

//      }else{
//       console.log("Level 2 ELSE");
//       next({
//         path: '/',

//     });
//     }
//    }
//   else{
//     if (localStorage.getItem("accessToken")) {
//            router.push({
//               path: "/user/calls"
//              })
//           } else {
//              next();
//         }

//   }
// });


export default router;
