<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- for loader end -->
    <b-container>
        <!-- <div>
            <b-row class="my-4">
                <b-col col lg="10" style="text-align: right">
                    <b-button id="calls" size="sm" variant="outline-primary" @click="showCalls()" v-bind:class="{ active: callsActive }">
                        Calls</b-button>
                </b-col>
                <b-col col lg="2">
                    <b-button size="sm" id="calls" variant="outline-primary" @click="showClosed()" v-bind:class="{ active: closedCallsActive }">Closed Calls</b-button>
                </b-col>
            </b-row>
        </div> -->

        <div class="top">
            <!-- modal form call details insert -->
            <b-modal id="addCall" title="Add Calls" ref="modal" no-close-on-backdrop hide-footer>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input type="text" v-model="call.name" :class="{
                  'is-invalid': $v.call.name.$error,
                  'is-valid': !$v.call.name.$invalid,
                }" class="form-control" placeholder="Enter student name"></b-form-input>
                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.call.name.required">Name is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Call Type</label>
                        <b-form-radio-group v-model="call.type" :options="callTypeOptions" class="mb-3 form-control" value-field="item" text-field="name" :class="{
                        'is-invalid': $v.call.type.$error,
                        'is-valid': !$v.call.type.$invalid,
                        }">
                        </b-form-radio-group>
                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.call.type.required">Name is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact</label>
                        <b-form-input type="number" v-model="call.contact" :class="{
                  'is-invalid': $v.call.contact.$error,
                  'is-valid': !$v.call.contact.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>

                        <div class="invalid-feedback">
                            <span v-if="!$v.call.contact.required">contact is required</span>
                        </div>
                        <b-form-checkbox v-if="call.type=='book'" style="color:#00629f" id="checkbox-1" v-model="call.whatsapp" name="checkbox-1" :value="call.contact" :unchecked-value="null">
                            &nbsp; Same as whatsapp number
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="call.type=='book'">
                    <div class="form-group my-2">
                        <label>Whatsapp Number</label>
                        <b-form-input type="number" v-model="call.whatsapp" class="form-control" placeholder="Enter Whatsapp Number"></b-form-input>
                        <!-- <div class="valid-feedback">Your AlternativeContact is valid!</div>
              <div class="invalid-feedback">

                  <span v-if="!$v.call.alternativeContact.optionalField"
                  >contact is required</span
                >

                  <span v-if="!$v.call.alternativeContact.$error || $v.call.alternativeContact.$invalid"
                  >Invalid contact</span>
              </div> -->
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="call.type!='book'">
                    <div class="form-group my-2">
                        <label>Alternative Contact</label>
                        <b-form-input type="number" v-model="call.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number"></b-form-input>
                        <!-- <div class="valid-feedback">Your AlternativeContact is valid!</div>
              <div class="invalid-feedback">

                  <span v-if="!$v.call.alternativeContact.optionalField"
                  >contact is required</span
                >

                  <span v-if="!$v.call.alternativeContact.$error || $v.call.alternativeContact.$invalid"
                  >Invalid contact</span>
              </div> -->
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Age</label>
                        <b-form-input type="number" v-model="call.age" :class="{
                  'is-invalid': $v.call.age.$error,
                  'is-valid': !$v.call.age.$invalid,
                }" class="form-control" placeholder="Enter student age"></b-form-input>
                        <div class="valid-feedback">Your Age is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.call.age.required">Age is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Qualification</label>
                        <b-form-select class="form-control" v-model="call.qualification" :class="{
                  'is-invalid': $v.call.qualification.$error,
                  'is-valid': !$v.call.qualification.$invalid,
                }">
                            <option value="null" disabled>Select Qualification</option>
                            <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                <strong>{{ q.text }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Course</label>
                        <b-form-select class="form-control" v-model="call.course" :class="{
                  'is-invalid': $v.call.course.$error,
                  'is-valid': !$v.call.course.$invalid,
                }">
                            <option value="null" disabled>Select Course</option>
                            <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="call.type=='book'">
                    <div class="form-group my-2">
                        <label>BookBundle type</label>
                        <b-form-select class="form-control" v-model="call.bookBundleId">
                            <option value="null" disabled>Select BookBundle</option>
                            <option v-for="item in allBookBundles" :value="item.bookId" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Source</label>
                        <b-form-select class="form-control" v-model="call.source" :class="{
                  'is-invalid': $v.call.source.$error,
                  'is-valid': !$v.call.source.$invalid,
                }">
                            <option value="null" disabled>Select Source</option>
                            <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Mode</label>
                        <b-form-select class="form-control" v-model="call.mode" :class="{
                  'is-invalid': $v.call.mode.$error,
                  'is-valid': !$v.call.mode.$invalid,
                }" :options="options"></b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-form-select class="form-control" v-model="call.branch" :class="{
                  'is-invalid': $v.call.branch.$error,
                  'is-valid': !$v.call.branch.$invalid,
                }">
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Followup</label>
                        <div>
                            <b-form-input format="DD-MM-YYYY h:i:s" v-model="call.followupDate" :class="{
                    'is-invalid': $v.call.followupDate.$error,
                    'is-valid': !$v.call.followupDate.$invalid,
                  }" type="datetime-local" min="new Time()"></b-form-input>
                            <div class="valid-feedback">date time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.call.followupDate.required">date or time is invalid!</span>
                            </div>
                        </div>
                        <div>
                            <b-form-textarea class="form-control" v-model="call.remark" :class="{
                    'is-invalid': $v.call.remark.$error,
                    'is-valid': !$v.call.remark.$invalid,
                  }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                            <div class="valid-feedback">remark is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.call.remark.required">remark is invalid!</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit" :disabled="disabledButton" @click="insert()" class="" variant="outline-primary">Register</b-button>
                    <b-button type="submit" @click="resetForm()" class="mx-3" variant="outline-danger">Reset</b-button>
                </div>
            </b-modal>
            <!-- modal form call details insert end -->
        </div>
        <!-- Calls Table -->
        <!-- <div v-if="isShowCall"> -->
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Calls" active>
                <!-- <b-collapse id="my-collapse1"> -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!--add Call button start-->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCall @click="resetForm()">Add Call</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCallSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="
                          getAllSortCount(
                            selectedCourse,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="
                          getAllSortCount(
                            selectedCourse,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="
                          getAllSortCount(
                            selectedCourse,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Branch">
                                                    <p>Branches</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" size="sm" @change="
                          getAllSortCount(
                            selectedCourse,
                            selectedSource,
                            selectedBranch,
                            selectedDates
                          )
                        " :options="dateSelect">
                                            </b-form-select>
                                        </b-col>

                                        <!-- <b-col col lg="2" md="2" id="r1" sm="4">
                                        <b-form-select class="bnclr " v-model="selectedBranch" size="sm">
                                            <b-form-select-option disabled value="Branch">
                                                Branches
                                            </b-form-select-option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{item.name}}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col> -->
                                        <!-- <b-col col lg="3" md="2" id="r1" sm="4">
                                        <b-form-select class=" bnclr " v-model="selectedDate" size="sm">
                                            <b-form-select-option disabled value="Date">
                                                Date
                                            </b-form-select-option>
                                            <option>
                                                <strong>Today</strong>
                                            </option>
                                            <option>
                                                <strong>Yesterday</strong>
                                            </option>
                                            <option>
                                                <strong>YesterMonth</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col> -->
                                    </b-row>
                                </b-container>
                            </div>
                            <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                                <div>
                                    <label for="from-datepicker">Choose The From date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                      'is-invalid': $v.from.$error,
                      'is-valid': !$v.from.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div>
                                    <label for="to-datepicker">Choose The To date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                      'is-invalid': $v.to.$error,
                      'is-valid': !$v.to.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div class="text-center mt-3">
                                    <b-button variant="outline-success" type="submit" @click="
                      getAllSortCount(
                        selectedCourse,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">Submit</b-button>
                                </div>
                            </b-modal>
                            <hr />
                            <b-table hover responsive :items="calls" :fields="fields" :per-page="calls.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact}}<span v-if="data.item.alternativeContact != ''">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <!-- Using value -->
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewcalldetail @click="getCall(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update" @click="getCall(data.item)" v-b-modal.updateCall>
                                        <font-awesome-icon icon="edit" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="secondary" @click="notintrestedClosed(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Notintrested">
                                        <font-awesome-icon icon="window-close" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="success" v-b-modal.closeBook @click="getBookBundleCall(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Book">
                                        <font-awesome-icon icon="book-open" />
                                    </b-button>
                                    <!-- <b-button
                    class="far fa-file-edit px-2"
                    variant="warning"
                    v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                    title="Followup"
                    @click="getCall(data.item)"
                    v-b-modal.followInsert
                  ></b-button> -->
                                    <!-- <b-button
                    class="fas fa-window-close px-2"
                    variant="secondary"
                    :disabled="disabledButton"
                    v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                    title="Closed"
                    @click="getCall(data.item)"
                    v-b-modal.callClosed
                  ></b-button> -->
                                    <!-- <b-button
                    class="fa fa-trash px-2"
                    variant="danger"
                    v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                    title="Delete"
                    @click="deleteCall(data.item.callId)"
                  ></b-button> -->
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </b-collapse> -->
                <!-- </div> -->
            </b-tab>
            <!-- </b-tabs> -->
            <!-- <b-button v-if="showClosed" size="sm" variant="outline-primary" class="mt-5" v-b-toggle.my-collapse @click="show()">Closed Calls</b-button> -->
            <!-- <b-modal id="edit" size="xl" hide-footer> -->
            <!-- <div v-if="isShowClosed"> -->
            <b-tab title="Closed Calls">
                <!-- <b-collapse  id="my-collapse">             -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!--Closed Call button start-->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-toggle.my-collapse disabled>Closed</b-button>
                                <!-- <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCall @click="resetForm()">Add Call</b-button> -->
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="closedKeyword" placeholder="Type a name" v-on:keyup.enter="getClosedCallSearchCount(closedKeyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getClosedCallSearchCount(closedKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="closedCurrentPage" :total-rows="closedTotalCount" :per-page="closedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="closedSwitchPage(closedCurrentPage, closedPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select-closed" v-model="closedPageCount" @input="closedSwitchPage(closedCurrentPage, closedPageCount)" :options="closedPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="closedRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedCourse" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Courses">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedSource" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Sources">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="4" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedBranch" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Branches">
                                                    <p>Branches</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedDates" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        " :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                        <!-- <b-col col lg="2" md="2" id="r1" sm="4">
                                        <b-form-select class="bnclr " v-model="selectedBranch" size="sm">
                                            <b-form-select-option disabled value="Branch">
                                                Branches
                                            </b-form-select-option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{item.name}}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col> -->
                                        <!-- <b-col col lg="2" md="2" id="r1" sm="4">
                                        <b-form-select class=" bnclr " v-model="selectedDate" size="sm">
                                            <b-form-select-option disabled value="Date">
                                                Date
                                            </b-form-select-option>
                                            <option>
                                                <strong>Today</strong>
                                            </option>
                                            <option>
                                                <strong>Yesterday</strong>
                                            </option>
                                            <option>
                                                <strong>YesterMonth</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col> -->
                                    </b-row>
                                </b-container>
                            </div>
                            <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                                <div>
                                    <label for="from-datepicker">Choose The From date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                      'is-invalid': $v.from.$error,
                      'is-valid': !$v.from.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div>
                                    <label for="to-datepicker">Choose The To date</label>
                                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                      'is-invalid': $v.to.$error,
                      'is-valid': !$v.to.$invalid,
                    }" type="date"></b-form-input>
                                </div>
                                <div class="text-center mt-3">
                                    <b-button variant="outline-success" type="submit" @click="
                      getAllSortClosedCount(
                        selectedClosedCourse,
                        selectedClosedSource,
                        selectedClosedBranch,
                        selectedClosedDates
                      )
                    ">Submit</b-button>
                                </div>
                            </b-modal>
                            <hr />
                            <!--Closed Calls Table -->
                            <b-table hover responsive :items="closedCalls" :fields="closefields" :current-page="closedCurrentPage" :per-page="closedCalls.closedPageCount">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <!-- Using value -->
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewcalldetail @click="getCall(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </b-collapse>        -->
                <div></div>
                <!-- </div> -->
            </b-tab>
        </b-tabs>
        <div>
            <!--popup start for View Call details-->
            <!-- The modal -->
            <b-modal id="viewcalldetail" no-close-on-backdrop hide-footer title="ViewCall">
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="update.name" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input v-model="update.contact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input v-model="update.alternativeContact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input v-model="update.age" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-input class="form-control" disabled v-model="update.qualification">
                                <option value="null" disabled>Select Qualification</option>
                                <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index" disabled>
                                    <strong disabled>{{ q.text }}</strong>
                                </option>
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-select class="form-control" v-model="update.course" disabled>
                                <option value="null" disabled>Select Course</option>
                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-select class="form-control" v-model="update.source" disabled>
                                <option value="null" disabled>Select Source</option>
                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-select class="form-control" v-model="update.mode" :options="options" disabled></b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-select class="form-control" v-model="update.branch" disabled>
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                        <template v-slot:cell(callId)="data">
                            <span class="title"> {{ data.item.callId }}</span>
                        </template>
                        <!-- <template v-slot:cell(callId)="data">
                  {{ callDate(data.item.followup, "fullDate") }}
                </template> -->
                        <template v-slot:cell(createdAt)="data">
                            {{ callDate(data.item.createdAt, "fullDate") }}
                        </template>
                        <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template>
                    </b-table>
                </div>
            </b-modal>
            <!--popup end for View Call details-->

            <!-- popup start for call details Update -->
            <!-- Using value -->

            <!-- The modal -->
            <b-modal id="updateCall" ref="modal" no-close-on-backdrop hide-footer title="Update Call">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input type="text" v-model="update.name" :class="{
                  'is-invalid': $v.update.name.$error,
                  'is-valid': !$v.update.name.$invalid,
                }" class="form-control" placeholder="Enter student name"></b-form-input>
                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.update.name.required">Name is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact</label>
                        <b-form-input type="number" v-model="update.contact" :class="{
                  'is-invalid': $v.update.contact.$error,
                  'is-valid': !$v.update.contact.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>
                        <div class="valid-feedback">Your contact is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.update.contact.required">contact is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Alternative Contact</label>
                        <b-form-input type="number" v-model="update.alternativeContact" :disabled="alternative" class="form-control" placeholder="Enter contact number"></b-form-input>
                    </div>
                </div>

                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Age</label>
                        <b-form-input type="number" v-model="update.age" :class="{
                  'is-invalid': $v.update.age.$error,
                  'is-valid': !$v.update.age.$invalid,
                }" class="form-control" placeholder="Enter student age"></b-form-input>
                        <div class="valid-feedback">Your Age is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.update.age.required">Age is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Qualification</label>
                        <b-form-select class="form-control" v-model="update.qualification" :class="{
                  'is-invalid': $v.update.qualification.$error,
                  'is-valid': !$v.update.qualification.$invalid,
                }">
                            <option value="null" disabled>Select Qualification</option>
                            <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                <strong>{{ q.text }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Course</label>
                        <b-form-select class="form-control" v-model="update.course" :class="{
                  'is-invalid': $v.update.course.$error,
                  'is-valid': !$v.update.course.$invalid,
                }">
                            <option value="null" disabled>Select Course</option>
                            <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Source</label>
                        <b-form-select class="form-control" v-model="update.source" :class="{
                  'is-invalid': $v.update.source.$error,
                  'is-valid': !$v.update.source.$invalid,
                }">
                            <option value="null" disabled>Select Source</option>
                            <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Mode</label>
                        <b-form-select class="form-control" v-model="update.mode" :class="{
                  'is-invalid': $v.update.mode.$error,
                  'is-valid': !$v.update.mode.$invalid,
                }" :options="options"></b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-form-select class="form-control" v-model="update.branch" :class="{
                  'is-invalid': $v.update.branch.$error,
                  'is-valid': !$v.update.branch.$invalid,
                }">
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit" @click="updateCall()" class="" variant="outline-primary">Update</b-button>
                </div>
            </b-modal>
            <!-- popup start for update details Update end-->
            <!-- popup start for follow insert -->
            <b-modal id="followInsert" title="Follow Update" ref="modal" hide-footer no-close-on-backdrop>
                <h5 class="px-5">Name : {{ update.name }}</h5>
                <h5 class="px-5">Contact : {{ update.contact }}</h5>
                <div class="form-row mt-3 px-5">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Followup</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="callFollowup.followupDate" :class="{
                      'is-invalid': $v.callFollowup.followupDate.$error,
                      'is-valid': !$v.callFollowup.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.callFollowup.followupDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                            <div>
                                <b-form-textarea class="form-control" v-model="callFollowup.remark" :class="{
                      'is-invalid': $v.callFollowup.remark.$error,
                      'is-valid': !$v.callFollowup.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                <div class="valid-feedback">remark is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.callFollowup.remark.required">remark is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit" @click="callFollowupUpdate()" class="px-3" variant="primary">Update</b-button>
                </div>
            </b-modal>
            <!-- popup start for follow insert end -->
        </div>
        <div>
            <!-- popup start closed call -->
            <b-modal id="callClosed" title="Call Closed" no-close-on-backdrop ref="modal" hide-footer>
                <label class="mt-3">Closed for</label>
                <b-form-select class="form-control" v-model="closed" :options="closedOption"></b-form-select>
                <!-- closed for follow insert -->
                <!-- <div v-if="closed === 'enquiry'" class="px-2">
                    <h5 class="px-5">Name : {{ update.name }}</h5>
                    <h5 class="px-5">Contact : {{ update.contact }}</h5>
                    <div class="form-row mt-3 px-5">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Followup</label>
                                <div>
                                    <b-form-input format="DD-MM-YYYY h:i:s" v-model="enquiryAs.followupDate" :class="{
                        'is-invalid': $v.enquiryAs.followupDate.$error,
                        'is-valid': !$v.enquiryAs.followupDate.$invalid,
                      }" type="datetime-local" min="new Time()"></b-form-input>
                                    <div class="valid-feedback">date time is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryAs.followupDate.required">date or time is invalid!</span>
                                    </div>
                                </div>
                                <div>
                                    <b-form-textarea class="form-control" v-model="enquiryAs.remark" :class="{
                        'is-invalid': $v.enquiryAs.remark.$error,
                        'is-valid': !$v.enquiryAs.remark.$invalid,
                      }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                    <div class="valid-feedback">remark is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryAs.remark.required">remark is invalid!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- closed for follow insert end -->

                <div class="mt-3 mt-2 text-center">
                    <b-button type="submit" @click="callClosed()" :disabled="disabledButton" class="px-3" variant="primary">closed</b-button>
                </div>
            </b-modal>
            <!-- popup start closed call end -->

            <b-modal id="closeBook" ref="modal" no-close-on-backdrop hide-footer title="Close to Book">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input disabled type="text" v-model="bookBundle.name" :class="{
                  'is-invalid': $v.bookBundle.name.$error,
                  'is-valid': !$v.bookBundle.name.$invalid,
                }" class="form-control" placeholder="Enter student name"></b-form-input>
                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.name.required">Name is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>whatsapp</label>
                        <b-form-input type="text" v-model="bookBundle.whatsapp" :class="{
                  'is-invalid': $v.bookBundle.whatsapp.$error,
                  'is-valid': !$v.bookBundle.whatsapp.$invalid,
                }" class="form-control" placeholder="Enter whatsapp number"></b-form-input>
                        <div class="valid-feedback">Your whatsapp number is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.whatsapp.required">whatsapp number is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>BookBundle type</label>
                        <!-- {{ allBookBundles }} -->
                        <b-form-select class="form-control" v-model="bookBundle.type" :class="{
                        'is-invalid': $v.bookBundle.type.$error,
                        'is-valid': !$v.bookBundle.type.$invalid,
                        }">
                            <option value="null" disabled>Select type</option>
                            <option v-for="b in allBookBundles" :value="b" v-bind:key="b.bookId">
                                <strong @click="this.currentBundle=b.books">{{ b.name }}</strong>
                            </option>
                        </b-form-select>
                        <div>
                            <!-- {{ currentBundle }} -->
                            <!-- <span v-for="c in currentBundle.books" :key="c.bookid">{{ c.name }}</span> -->
                        </div>
                    </div>
                </div>

                
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Address</label>
                        <b-form-input type="text" style="height:80px" v-model="bookBundle.doorNo" :class="{
                  'is-invalid': $v.bookBundle.doorNo.$error,
                  'is-valid': !$v.bookBundle.doorNo.$invalid,
                }" class="form-control" placeholder="Enter address with door number"></b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.doorNo.required">Address is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Landmark</label>
                        <b-form-input type="text" v-model="bookBundle.landmark" :class="{
                  'is-invalid': $v.bookBundle.landmark.$error,
                  'is-valid': !$v.bookBundle.landmark.$invalid,
                }" class="form-control" placeholder="Enter student landmark"></b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.landmark.required">landmark is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>District</label>
                        <b-form-input type="text" v-model="bookBundle.district" :class="{
                  'is-invalid': $v.bookBundle.district.$error,
                  'is-valid': !$v.bookBundle.district.$invalid,
                }" class="form-control" placeholder="Enter student district"></b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.district.required">district is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>State</label>
                        <b-form-input type="text" v-model="bookBundle.state" :class="{
                  'is-invalid': $v.bookBundle.state.$error,
                  'is-valid': !$v.bookBundle.state.$invalid,
                }" class="form-control" placeholder="Enter student state"></b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.state.required">state is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Pincode</label>
                        <!-- @keydown.enter.prevent="GetDetailsByPincode()" -->
                        <b-form-input type="text" v-model="bookBundle.pincode" :class="{
                  'is-invalid': $v.bookBundle.pincode.$error,
                  'is-valid': !$v.bookBundle.pincode.$invalid,
                }" class="form-control" placeholder="Enter pincode"></b-form-input>
                        <div class="valid-feedback">Your Pincode is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.pincode.required">Pincode is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Payment Method</label>
                        <b-form-select class="form-control" v-model="bookBundle.payment" :class="{
                  'is-invalid': $v.bookBundle.payment.$error,
                  'is-valid': !$v.bookBundle.payment.$invalid,
                }">
                            <option v-for="q in paymentMethods" :value="q.value" v-bind:key="q.index">
                                <strong>{{ q.text }}</strong>
                            </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.payment.required">Payment method is required</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Payment Method</label>
                        <b-form-input type="text" v-model="bookBundle.payment" class="form-control" :class="{
                  'is-invalid': $v.bookBundle.payment.$error,
                  'is-valid': !$v.bookBundle.payment.$invalid,
                }" placeholder="Enter Payment Method"></b-form-input>
                        <div class="valid-feedback">Your state is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.bookBundle.payment.required">Payment method is required</span>
                        </div>

                    </div>
                </div> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Reference Number</label>
                        <b-form-input type="text" v-model="bookBundle.refNumber" class="form-control" placeholder="Enter payment Reference Number"></b-form-input>

                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Remark</label>
                        <b-form-input type="text" v-model="bookBundle.remarks" class="form-control" placeholder="Enter payment Reference Number"></b-form-input>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit"   @click="orderValidation()" class="" variant="outline-primary">Close to Book</b-button>
                </div>
            </b-modal>
            <b-modal id="closeBookConfirm" title="Book Order Confirmation" ref="confirm" hide-footer>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input disabled type="text" v-model="bookBundle.name" class="form-control" placeholder="Enter student name"></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>whatsapp</label>
                        <b-form-input type="number" disabled v-model="bookBundle.whatsapp" class="form-control" placeholder="Enter whatsapp number"></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>BookBundle type</label>
                        <b-form-select class="form-control" v-model="bookBundle.type" disabled>
                            <option v-for="b in allBookBundles" :value="b" v-bind:key="b.bookId">
                                <strong @click="this.currentBundle=b.books">{{ b.name }}</strong>
                            </option>
                        </b-form-select>
                        <div>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" >
                    <label>Address:</label>
                    <div style="padding-left:10px;background-color:#e9ecef;border:1px solid #ced4da">
                        {{bookBundle.doorNo}},
                        <br>
                        {{bookBundle.landmark}},
                        <br>
                        {{bookBundle.district}},
                        <br>
                        {{bookBundle.state}} - {{bookBundle.pincode}}
                    </div>

                </div>
                <div class="mt-3 mt-2 text-center">
                    <b-button type="submit" @click="getOrganization(),orderDisabled=true" :disabled="disabledButton" class="px-3" variant="primary">closed</b-button>
                </div>
            </b-modal>
        </div>
    </b-container>
</div>
</template>

<script>
import CallService from "../service/CallService";
import TeamService from "../service/TeamService";
// call modal form select options data render
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import NotintrestedsService from "../service/NotintrestedsService";
// import AgentService from "../service/AgentService";
import axios from "axios";
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import qualification from "../assets/json_file/qualification.json";
import moment from "moment-timezone";
import FollowupService from "../service/FollowupService";
import Swal from 'sweetalert2';
import Account from '../service/Account.js';
import BookBundleService from '../service/BookBundleService';
// const optionalField = (value) => !helpers.req(value) || value.includes(Number) || value.includes(minLength(10) && maxLength(10))
// const optionalField = (value) => !helpers.req(value) || ( value.minLength  && value.maxLength) ;
export default {
    name: "Calls",
    components: {},
    data() {
        return {
            organization:"",
            paymentMethods: [{
                    value: null,
                    text: "Select Payment Method",
                    disabled: true,
                },
                {
                    value: 'link',
                    text: 'Payment Via Link',
                },
                {
                    value: 'card',
                    text: 'Card',
                },
                {
                    value: 'upi',
                    text: 'UPI',
                },
                {
                    value: 'cash',
                    text: 'Cash',
                },
                {
                    value: 'cod',
                    text: 'Cash On Delivery',
                }
            ],
            allBookBundles: [],
            currentBundle: [],
            paginationCalls: true,
            allSortCalls: false,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            disabledButton: false,
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",

            selectedClosedCourse: "Courses",
            selectedClosedSource: "Sources",
            selectedClosedBranch: "Branches",

            // selectedDate: "Date",
            call: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
                whatsapp: "",
                type: "",
                bookBundleId:null
            },
            callTypeOptions: [{
                    item: 'course',
                    name: 'Course'
                },
                {
                    item: 'book',
                    name: 'Book'
                }
            ],
            update: {
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            orderDisabled: false,
            bookBundle: {
                name: "",
                whatsapp: null,
                doorNo: null,
                landmark: null,
                district: null,
                state: null,
                type: null,
                payment: "",
                refNumber: "",
                remarks: "",
                pincode: null
            },
            callFollowup: {
                followupDate: "",
                remark: "",
            },
            enquiryAs: {
                followupDate: "",
                remark: "",
            },
            updateNumber: null,
            updatealternative: null,
            alternative: false,
            selectedDates: null,
            selectedClosedDates: null,

            from: "",
            to: "",
            fromValue: "",
            toValue: "",
            data: "",
            paginationAdmissions: false,
            searchPaginationAdmissions: false,
            sortAgentPaginationAdmissions: false,
            sortCoursePaginationAdmissions: false,
            sortSourcePaginationAdmissions: false,
            sortBranchPaginationAdmissions: false,
            sortDatePaginationAdmissions: false,
            // enquiry: {
            //   name: "",
            //   contact: "",
            //   age: "",
            //   qualification: null,
            //   course: null,
            //   source: null,
            //   mode: null,
            //   branch: null,
            //   followupDate: "",
            //   remark: "",
            // },
            submitStatus: "",
            agentId: "",
            agentDetails: "",
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            qualificationList: qualification,
            //All Courses data render in table
            allCourses: "",
            //All Sources data render in table
            allSources: "",
            //All Branches data render in table
            allBranches: "",
            // for search in table
            keyword: "",
            //for closedcall search
            closedKeyword: "",
            // for loader
            loading: false,
            // Call table show
            // isShowCall: true,
            // Call table hidr and Closed table show
            // isShowClosed: false,
            // Call all data render in table
            calls: [],
            fields: [{
                    key: "createdAt",
                    label: "Date",
                    tdClass: "cellSize",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            // Closed Call all data render in table
            // closedCalls: '',
            closedCalls: [],
            closefields: [{
                    key: "createdAt",
                    label: "Date",
                    tdClass: "cellSize",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            // followup details render in view modal table
            followup: [],
            followupFields: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            followupDateRemark: [],
            callView: "",
            // for calls pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            callsActive: true,
            closedCallsActive: false,
            // for closed pagination
            closedRows: 100,
            closedCurrentPage: 1,
            closedPageCount: 5,
            closedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            closedTotalCount: 0,
            closed: null,
            closedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "enquiry",
                    text: "Enquiry",
                },
                {
                    value: "admission",
                    text: "Admission",
                },
            ],
        };
    },
    validations: {
        bookBundle: {
            name: {
                required,
            },
            type: {
                required,
            },
            whatsapp: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            doorNo: {
                required,
            },
            landmark: {
                required,
            },
            district: {
                required,
            },
            state: {
                required,
            },
            payment: {
                required,
            },
            pincode: {
                required,
                Number,
            }
        },
        from: {
            required,
        },
        to: {
            required,
        },
        call: {
            name: {
                required,
                minLength: minLength(3),
            },
            type: {
                required
            },
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            alternativeContact: {
                //   optionalField,
                //   minLength: minLength(10),
                // maxLength: maxLength(10),
                // required: requiredIf(value => value.length == 10 ),
            },
            age: {
                required,
                Number,
                minLength: minLength(2),
                maxLength: maxLength(2),
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            branch: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        update: {
            name: {
                required,
                minLength: minLength(3),
            },
            contact: {
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },

            age: {
                required,
                Number,
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            branch: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
        },
        callFollowup: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        enquiryAs: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
    },
    // mounted() {
    //   let self = this;
    //   return new Promise((resolve, reject) => {
    //     Account.getAccessToken(localStorage.getItem("refreshToken"))
    //       .then((response) => {
    //         this.agentId = localStorage.getItem("agentId");
    //         // self.getAgent();
    //         // self.getAllAgents();
    //         self.getAllCourses();
    //         self.getAllSources();
    //         self.getAllBranches();
    //         self.getAllCalls();
    //         self.getCount();
    //         self.closedGetCount();
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         reject(err);
    //       });
    //   });
    // },
    mounted() {
        if (localStorage.getItem("agentId") != null && localStorage.getItem("agentId") != "") {
            return new Promise((resolve, reject) => {
                this.global()
                    .then((response) => {
                        // this.getAllCalls();
                        // this.closedGetCount();
                        this.getCount();
                        this.closedGetCount();
                        this.getAllCourses();
                        this.getAllSources();
                        this.getAllBranches();
                        this.getAllBookBundles();
                        this.loading = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.userRoleCheck();
                        reject(err);
                    });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Your role has been Removed',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
        closedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.closedGetCount();
            }
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        localStorage.removeItem("agentId");
                        reject(err);
                    });
            });
        },
        getAllBookBundles(){
            axios.defaults.headers.common["tenant-uuid"] = ""
            return new Promise((resolve, reject) => {
                BookBundleService.getAll()
                    .then((response) => {
                        this.allBookBundles = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
                axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
            });
        },
        getOrganization() {
            this.disabledButton=true;
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        this.organization=response.data.organization;
                        this.bookOrder(response.data.organization);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.disabledButton=true;
                        reject(err);
                    });
            });
        },
        orderValidation(){
            this.disabledButton=false;
            this.$v.bookBundle.$touch();
            if (this.$v.bookBundle.$invalid) {
                // alert("insert submit failed");
                this.orderDisabled = false;
                this.loading=false;
            } else {
                this.$refs.confirm.show();
            }
        },
        bookOrder(org) {
            this.loading=true;
                console.log("sfadfhksjdfa", org)
                let currentUser = JSON.parse(localStorage.getItem("current_user"))
                console.log("currentUser", currentUser);
                let data = {
                    name: this.bookBundle.name,
                    contact: this.bookBundle.contact,
                    type: this.bookBundle.type,
                    whatsapp: this.bookBundle.whatsapp,
                    doorNo: this.bookBundle.doorNo,
                    landmark: this.bookBundle.landmark,
                    district: this.bookBundle.district,
                    state: this.bookBundle.state,
                    pincode: this.bookBundle.pincode,
                    remarks: this.bookBundle.remarks,
                    paymentMethod: this.bookBundle.payment,
                    referenceNumber: this.bookBundle.refNumber,
                    agentId: this.agentId,
                    agentName: currentUser.firstName + " " + currentUser.lastName,
                    teamId: localStorage.getItem('tenant-uuid'),
                    organization: org,
                    bookBundleName: this.bookBundle.type.name,
                    price: this.bookBundle.type.price,
                    byAgent:true,
                    teamName:org,
                    callId:this.call.callId
                }
                console.log(data);
                return new Promise((resolve, reject) => {
                    BookBundleService.saveBookOrder(data)
                        .then((response) => {
                            this.$refs.confirm.hide();
                            this.$swal({
                                title: "Order Booked",
                                text: "BookBundle order created successfully",
                                icon: "success",
                                button: "ok",
                            });

                            this.bookBundle = {
                                    name: "",
                                    whatsapp: null,
                                    doorNo: null,
                                    landmark: null,
                                    district: null,
                                    state: null,
                                    type: null,
                                    payment: "",
                                    refNumber: "",
                                    remarks: "",
                                    pincode: null
                                },
                                this.disabledButton=false;
                                this.$refs.modal.hide();
                                this.loading=false;
                                // this.publicBookOrder(data);
                                resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            
        },
        // publicBookOrder(data){
        //     axios.defaults.headers.common["tenant-uuid"] =""
        //     return new Promise((resolve, reject) => {
        //         BookBundleService.saveBookOrder(data)
        //             .then((response) => {
        //                 this.getCount();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //         axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
        //         });
        // },
        // async GetDetailsByPincode() {
        //     const apiKey = 'AIzaSyCYZ_IpuxhRS09FPMY2lfwYAxsii62f3g8';
        //     const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.bookBundle.pincode}&key=${apiKey}`;

        //     try {
        //         const response = await axios.get(apiUrl);
        //         const result = response.data.results[0];

        //         if (result) {
        //             const addressComponents = result.address_components;
        //             // results[0].address_components[results[0].address_components.length - 2].long_name;
        //             var state = addressComponents[addressComponents.length - 2].long_name;
        //             var city = addressComponents[addressComponents.length - 3].long_name;
        //             self.bookBundle.district = city;
        //             self.bookBundle.state = state;
        //             console.log("state", addressComponents[addressComponents.length - 3]);
        //         }
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // },
        GetDetailsByPincode() {
            console.log("ASDF");
            var geocoder = new window.google.maps.Geocoder();
            var postalCode = this.bookBundle.pincode;
            let self = this
            geocoder.geocode({
                'address': postalCode
            }, function (results, status) {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    var state = results[0].address_components[results[0].address_components.length - 2].long_name;
                    var city = results[0].address_components[results[0].address_components.length - 3].long_name;
                    self.bookBundle.district = city;
                    self.bookBundle.state = state;
                    // document.getElementById('txtCountry').value = country;
                    // document.getElementById('txtState').value = state;
                    // document.getElementById('txtCity').value = city;
                }
            });
        },
        getBookBundleCall(call) {
            this.orderDisabled = false
            this.call = call;
            this.bookBundle.name = call.name;
            this.bookBundle.whatsapp=call.whatsapp;
            this.bookBundle.contact = call.contact
            
        },
        getAllSortCount: function (
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/call/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/call/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.calls = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        //=================================CALL NOTINTRESTED CLOSED START========================================================

        notintrestedClosed: function (callId) {
            this.getCall(callId);
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You are closed the Call to Not intrested!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, close!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.updateStatusClosed(this.agentId, this.call.callId);
                        //console.log("first",this.agentId);
                        return new Promise((resolve, reject) => {
                            this.$swal({
                                title: "Notintrested Successful",
                                text: "Call closed to Notintrested",
                                icon: "success",
                                button: "ok",
                            });
                            NotintrestedsService.notintrestedClosed(this.agentId, this.call)
                                .then((response) => {
                                    this.getCount();
                                    this.closedGetCount();
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                });
        },
        //=================================CALL NOTINTRESTED CLOSED END========================================================

        //================================= CLOSED CALL========================================================
        getAllSortClosedCount: function (
            selectedClosedCourse,
            selectedClosedSource,
            selectedClosedBranch,
            selectedClosedDates
        ) {
            // console.log("selectedClosedCourse=== ", selectedClosedCourse);
            // console.log("selectedClosedSource=== ", selectedClosedSource);
            // console.log("selectedClosedBranch=== ", selectedClosedBranch);
            // console.log("selectedClosedDates=== ", selectedClosedDates);

            if (selectedClosedDates == "dateRange") {
                this.getClosedDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedClosedDates = "dateBetween";
            } else {
                if (
                    selectedClosedCourse == "Courses" ||
                    selectedClosedCourse == "AllClosedCourses"
                ) {
                    selectedClosedCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedClosedSource == "Sources" ||
                    selectedClosedSource == "AllClosedSources"
                ) {
                    selectedClosedSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedClosedBranch == "Branches" ||
                    selectedClosedBranch == "AllClosedBranches"
                ) {
                    selectedClosedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedClosedDates != null) {
                    var date = new Date();
                    if (selectedClosedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedClosedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedClosedDates == null ||
                    selectedClosedDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedClosedCourse.courseId,
                    sourceId: selectedClosedSource.sourceId,
                    branchId: selectedClosedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.closedCurrentPage = 1;
                this.closedKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/call/closedCall/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedCount======", response.data);
                            this.closedTotalCount = response.data;
                            this.getAllSortClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/call/closedCall/all/sort/" +
                        closedCurrentPage +
                        "/" +
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedCalls = response.data;
                        // console.log("getAllSortClosedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // getAllSortCount: function(
        //   selectedCourse,
        //   selectedSource,
        //   selectedBranch,
        //   selectedDates
        // ) {
        //   console.log("selectedCourse=== ", selectedCourse);
        //   console.log("selectedSource=== ", selectedSource);
        //   console.log("selectedBranch=== ", selectedBranch);
        //   console.log("selectedDates=== ", selectedDates);

        //   if (selectedCourse == "Course") {
        //     selectedCourse = {
        //       courseId: "",
        //     };
        //   }
        //   if (selectedSource == "Source") {
        //     selectedSource = {
        //       sourceId: "",
        //     };
        //   }
        //   if (selectedBranch == "Branch") {
        //     selectedBranch = {
        //       branchId: "",
        //     };
        //   }
        //   var from;
        //   var to;
        //   if (selectedDates != null) {
        //     var date = new Date();
        //     if (selectedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //       from= this.fromDateFormat;
        //       to= this.toDateFormat;
        //   } else if (selectedDates == null) {
        //     from = "";
        //     to = "";
        //   }
        //   let data = {
        //     agentId:this.agentId,
        //     courseId: selectedCourse.courseId,
        //     sourceId: selectedSource.sourceId,
        //     branchId: selectedBranch.branchId,
        //     from: from,
        //     to: to,
        //   };
        //   console.log("data====== ", data);
        //   return new Promise((resolve, reject) => {
        //     axios
        //       .post("/call/all/sort",data)
        //       .then((response) => {
        //         console.log("getAllSortCount======", response.data);
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        // showCalls() {
        //     this.isShowCall = true;
        //     this.getCount();
        //     this.keyword = "";
        //     this.isShowClosed = false;
        //     (this.callsActive = true),
        //     (this.closedCallsActive = false),
        //     (this.selectedCourse = "Course");
        //     this.selectedSource = "Source";
        //     this.selectedBranch = "Branch";

        //     this.selectedDates = null;
        // },
        // showClosed() {
        //     this.isShowCall = false;
        //     this.closedGetCount();
        //     this.closedKeyword = "";
        //     this.isShowClosed = true;
        //     (this.callsActive = false),
        //     (this.closedCallsActive = true),
        //     (this.selectedClosedCourse = "Courses");
        //     this.selectedClosedSource = "Sources";
        //     this.selectedClosedBranch = "Branches";

        //     this.selectedClosedDates = null;
        // },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        insert: function () {
            let isBook= this.call.type=='book'?true:false;
            this.call.whatsapp=this.call.whatsapp==undefined?null:this.call.whatsapp  
            this.$v.call.$touch();
            if (this.$v.call.$invalid) {
                this.submitStatus = "FAIL";
                // alert("insert submit failed");
            } else {

                if ((isBook)&&(this.call.whatsapp==null)) {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Oops...",
                        text: "Book call must required a whatsapp number",
                    });
                }
                else if ((isBook)&&(this.call.bookBundleId==null)) {
                    this.$swal.fire({
                        icon: "warning",
                        title: "Oops...",
                        text: "Book call must required a type of BookBundle",
                    });
                }
                else{
                    this.disabledButton = true;
                return new Promise((resolve, reject) => {
                    CallService.getContact(this.call.contact)
                        .then((response) => {
                            if (response.data == true) {
                                this.loading = true;
                                if (this.call.alternativeContact == "") {
                                    this.submitStatus = "SUCCESS";
                                    let data = {
                                        name: this.call.name,
                                        contact: this.call.contact,
                                        alternativeContact: this.call.alternativeContact,
                                        age: this.call.age,
                                        qualification: this.call.qualification,
                                        course: this.call.course,
                                        source: this.call.source,
                                        mode: this.call.mode,
                                        branch: this.call.branch,
                                        followupDate: this.call.followupDate,
                                        remark: this.call.remark,
                                        teamId: axios.defaults.headers.common["tenant-uuid"],
                                        is_book:isBook,
                                        whatsapp:this.call.whatsapp,
                                        bookBundle_id:this.call.bookBundleId
                                    }
                                    console.log("getContact", data);
                                    return new Promise((resolve, reject) => {
                                        // //console.log(this.agentId);
                                        CallService.putCall(this.agentId, data)
                                            .then((response) => {
                                                this.$swal({
                                                    title: "Good job!",
                                                    text: response.data,
                                                    icon: "success",
                                                    button: "ok",
                                                });
                                                this.loading = false;
                                                this.resetForm();
                                                this.$refs.modal.hide();
                                                // this.getAllCalls();
                                                this.getCount();
                                                // this.getAllPageable();
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                this.loading = false;
                                                reject(err);
                                            });
                                    });
                                } else {
                                    return new Promise((resolve, reject) => {
                                        CallService.getalternativeContact(
                                                this.call.alternativeContact
                                            )
                                            .then((response) => {
                                                //console.log("Response====",response.data)
                                                if (response.data == true) {
                                                    this.submitStatus = "SUCCESS";
                                                    //console.log("alternativeContact calling");
                                                    return new Promise((resolve, reject) => {
                                                        // //console.log(this.agentId);
                                                        let data = {
                                                            name: this.call.name,
                                                            contact: this.call.contact,
                                                            alternativeContact: this.call.alternativeContact,
                                                            age: this.call.age,
                                                            qualification: this.call.qualification,
                                                            course: this.call.course,
                                                            source: this.call.source,
                                                            mode: this.call.mode,
                                                            branch: this.call.branch,
                                                            followupDate: this.call.followupDate,
                                                            remark: this.call.remark,
                                                            teamId: axios.defaults.headers.common["tenant-uuid"],
                                                            is_book:isBook,
                                                            whatsapp:this.call.whatsapp,
                                                            bookBundle_id:this.call.bookBundleId
                                                        }
                                                        console.log("getalternativeContact", data);
                                                        console.log(resolve);
                                                        console.log(reject);
                                                        CallService.putCall(this.agentId, data)
                                                            .then((response) => {
                                                                this.$swal({
                                                                    title: "Good job!",
                                                                    text: response.data,
                                                                    icon: "success",
                                                                    button: "ok",
                                                                });
                                                                this.loading = false;
                                                                this.resetForm();
                                                                this.$refs.modal.hide();
                                                                // this.getAllCalls();
                                                                this.getCount();
                                                                // this.getAllPageable();
                                                                this.disabledButton = false;
                                                                resolve(response);
                                                            })
                                                            .catch((err) => {
                                                                this.loading = false;
                                                                reject(err);
                                                            });
                                                    });
                                                } else
                                                    this.$swal.fire({
                                                        icon: "error",
                                                        title: "Oops...",
                                                        text: "AlternativeContact is already exist!",
                                                    });
                                                this.disabledButton = false;
                                                this.loading = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                reject(err);
                                                this.disabledButton = false;
                                                this.loading = false;
                                            });
                                    });
                                }
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Mobile Number is already exist!",
                                });
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
                }
                
            }
        },
        getAllCalls: function () {
            // this.showCalls();
            this.loading = true;
            return new Promise((resolve, reject) => {
                CallService.getAllCalls(this.agentId)
                    .then((response) => {
                        this.calls = response.data;
                        this.getCount();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // getCall: function (call) {
        //    return new Promise((resolve, reject) => {
        //     CallService.getCall(call.callId, this.agentId)
        //       .then((response) => {
        //   // this.call = call;
        //   this.update = {
        //     callId: response.data.callId,
        //     name: response.data.name,
        //     contact: call.contact,
        //     alternativeContact: call.alternativeContact,
        //     age: response.data.age,
        //     qualification: response.data.qualification,
        //     course: response.data.course,
        //     source: response.data.source,
        //     mode: response.data.mode,
        //     branch: response.data.branch,
        //     followup: response.data.followup
        //   };
        //   this.updateNumber = call.contact;
        //   this.updatealternative = call.alternativeContact;
        //   // //console.log(this.updatealternative)
        //   if (this.updatealternative == call.alternativeContact) {
        //     // //console.log(this.updatealternative)
        //     this.alternative = true;
        //   }
        //   if (this.updatealternative == null) {
        //     this.alternative = false;
        //   }
        //   // //console.log(this.call);

        //         // this.followup = response.data;

        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getCall: function (call) {
            this.call = call;
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(call.callId)
                FollowupService.getFollowupCreatedDate(call.callId)
                    .then((response) => {
                        this.update = {
                            callId: call.callId,
                            name: call.name,
                            contact: call.contact,
                            alternativeContact: call.alternativeContact,
                            age: call.age,
                            qualification: call.qualification,
                            course: call.course,
                            source: call.source,
                            mode: call.mode,
                            branch: call.branch,
                        };
                        this.updateNumber = call.contact;
                        this.updatealternative = call.alternativeContact;
                        // //console.log(this.updatealternative)
                        if (this.updatealternative == call.alternativeContact) {
                            // //console.log(this.updatealternative)
                            this.alternative = true;
                        }
                        if (this.updatealternative == null) {
                            this.alternative = false;
                        }
                        this.followupDateRemark = response.data;

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteCall: function (callId) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085D6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        return new Promise((resolve, reject) => {
                            CallService.deleteCall(callId)
                                .then((response) => {
                                    this.$swal.fire(
                                        "Deleted!",
                                        "Your file has been deleted.",
                                        "success"
                                    );
                                    this.getAllCalls();
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                });
        },
        updateCall: function () {
            this.$v.update.$touch();
            if (this.$v.update.$invalid) {
                this.submitStatus = "FAIL";
            } else if (this.updateNumber != this.update.contact) {
                // //console.log("Not same")
                this.$swal("Mobile number is not same");
            } else {
                return new Promise((resolve, reject) => {
                    CallService.updateCall(this.agentId, this.update.callId, this.update)
                        .then((response) => {
                            this.$swal(response.data);
                            this.$refs.modal.hide();
                            // this.getAllCalls();
                            this.getCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
                // return new Promise((resolve, reject) => {
                //   //console.log("if contact calling")
                //   CallService.getUpdateContact(this.callId,this.update)
                // .then((response) => {
                //       if (response.data == true) {
                //         //console.log("Update contact calling")
                // this.submitStatus = "SUCCESS";

                //       } else
                //       //console.log("else contact calling")
                //         this.$swal.fire({
                //           icon: "error",
                //           title: "Oops...",
                //           text: "Mobile Number is already exist!",
                //         });
                //       resolve(response);
                //     })
                //     .catch((err) => {
                //       reject(err);
                //     });
                // });
            }
        },
        // updateCall: function () {
        //   this.$v.update.$touch();
        //   if (this.$v.update.$invalid) {
        //     this.submitStatus = "FAIL";
        //     // alert("update submit failed");
        //   } else {
        //     this.submitStatus = "SUCCESS";
        //     return new Promise((resolve, reject) => {
        //       CallService.updateCall(this.agentId, this.update.callId, this.update)
        //         .then((response) => {
        //           this.$swal(response.data);
        //           this.$refs.modal.hide();
        //           this.getAllCalls();
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        callFollowupUpdate: function () {
            this.$v.callFollowup.$touch();
            if (this.$v.callFollowup.$invalid) {
                this.submitStatus = "FAIL";
            } else {
                return new Promise((resolve, reject) => {
                    CallService.callFollowupUpdate(
                            this.agentId,
                            this.update.callId,
                            this.callFollowup
                        )
                        .then((response) => {
                            this.$swal(response.data);
                            this.$refs.modal.hide();
                            // this.getAllCalls();
                            this.getCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        archive: function (callId) {
            return new Promise((resolve, reject) => {
                CallService.archive(callId)
                    .then((response) => {
                        this.$swal(response.data);
                        this.getAllCalls();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unArchive: function (callId) {
            return new Promise((resolve, reject) => {
                CallService.unArchive(callId)
                    .then((response) => {
                        this.$swal(response.data);
                        this.getAllCalls();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.allSortCalls = false;
            this.paginationCalls = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                CallService.getCount(this.agentId)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        // this.sortCoursePaginationCalls = false;
                        // this.sortSourcePaginationCalls = false;
                        // this.sortBranchPaginationCalls = false;
                        // this.sortDatePaginationCalls = false;
                        // this.searchPagination = false;
                        // this.paginationCalls = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllPageable(currentPage, pageCount);
                // console.log(this.paginationCalls, "this.paginationCalls");
                // console.log(this.allSortCalls, "this.allSortCalls");
            } else if (this.allSortCalls) {
                this.getAllSortPageable(currentPage, pageCount, this.data);
                // console.log(this.allSortCalls, "this.allSortCalls");
                // console.log(this.paginationCalls, "this.paginationCalls");
            } else if (this.searchPagination) {
                this.getAllCallSearchPageable(currentPage, pageCount, this.keyword);
                // console.log(this.searchPagination, "searchPagination");
            }
            // if (this.paginationCalls) {
            //   this.getAllPageable(currentPage, pageCount);
            //   //console.log(this.paginationCalls,"callspagination")
            // } else if (this.sortCoursePaginationCalls) {
            //   this.getAllCoursePageable(currentPage, pageCount, this.data);
            //   //console.log(this.sortCoursePaginationCalls,"sortCoursePaginationCalls")
            // } else if (this.sortSourcePaginationCalls) {
            //   this.getAllSourcePageable(currentPage, pageCount, this.data);
            //   //console.log(this.sortSourcePaginationCalls,"sortSourcePaginationCalls")
            // } else if (this.sortBranchPaginationCalls) {
            //   this.getAllBranchPageable(currentPage, pageCount, this.data);
            //   //console.log(this.sortBranchPaginationCalls,"sortBranchPaginationCalls")
            // } else if (this.sortDatePaginationCalls) {
            //   this.getAllDatePageable(currentPage, pageCount, this.data);
            //   // console.log(this.sortDatePaginationCalls,"sortDatePaginationCalls")
            // } else if (this.searchPagination) {
            //   this.getAllCallSearchPageable(currentPage, pageCount, this.keyword);
            //   //console.log(this.searchPagination,"searchPagination")
            // }
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                CallService.getAllPageable(currentPage, pageCount, this.agentId)
                    .then((response) => {
                        this.calls = response.data;
                        this.loading = false;
                        //console.log(response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // searchCall: function (keyword) {
        //   return new Promise((resolve, reject) => {
        //     CallService.searchCall(keyword.toLowerCase(), this.agentId)
        //       .then((response) => {
        //         this.calls = response.data;
        //         this.getCallSearchCount(keyword.toLowerCase());
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getCallSearchCount: function (keyword) {
            //console.log("search pagecount", keyword);
            return new Promise((resolve, reject) => {
                CallService.getCallSearchCount(this.agentId, keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        //console.log("search pagecount", response.data);
                        this.getAllCallSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        // this.sortCoursePaginationCalls = false;
                        // this.sortSourcePaginationCalls = false;
                        // this.sortBranchPaginationCalls = false;
                        // this.sortDatePaginationCalls = false;
                        // this.searchPagination = true;
                        // this.paginationCalls = false;
                        this.searchPagination = true;
                        this.paginationCalls = false;
                        this.allSortCalls = false;
                        this.currentPage = 1;
                        this.selectedCourse = "Course";
                        this.selectedSource = "Source";
                        this.selectedBranch = "Branch";
                        this.selectedAgent = "Agent";
                        this.selectedDates = null;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCallSearchPageable: function (currentPage, pageCount, keyword) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                CallService.getAllCallSearchPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        //console.log(response.data,"search page");
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getCourseDetail: function (course) {
        //   if (this.selectedCourse == "AllCourses") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", this.selectedCourse);
        //   } else {
        //     //console.log("Course Calling");
        //     //console.log(this.selectedCourse);
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/call/sort/course/" + this.agentId, data)
        //         .then((response) => {
        //           //console.log(response.data);
        //           this.calls = response.data;
        //           this.getCourseCount(data);
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.selectedDates = "Dates";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getCourseCount: function (selectedCourse) {
        //   //console.log("coursecount", this.data);
        //   if (selectedCourse == "AllCourses") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", selectedCourse);
        //   } else {
        //     //console.log("Course Calling");
        //     //console.log(this.selectedCourse);
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       CallService.getCourseCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllCoursePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePaginationCalls = true;
        //           this.sortSourcePaginationCalls = false;
        //           this.sortBranchPaginationCalls = false;
        //           this.sortDatePaginationCalls = false;
        //           this.searchPagination = false;
        //           this.paginationCalls = false;
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.selectedDates = null;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllCoursePageable: function (currentPage, pageCount, data) {
        //   //console.log("coursepageable calling");
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllCoursePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        // getSourceDetail: function (source) {
        //   if (this.selectedSource == "AllSources") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", this.selectedSource);
        //   } else {
        //     //console.log("source Calling");
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/call/sort/source/" + this.agentId, data)
        //         .then((response) => {
        //           //console.log(response.data);
        //           this.calls = response.data;
        //           this.getSourceCount(data);
        //           this.selectedCourse = "Course";
        //           this.selectedBranch = "Branch";
        //           this.selectedDates = "Dates";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getSourceCount: function (selectedSource) {
        //   //console.log("sourcecount", this.data);
        //   if (this.selectedSource == "AllSources") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", this.selectedSource);
        //   } else {
        //     //console.log("source Calling");
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       CallService.getSourceCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllSourcePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePaginationCalls = false;
        //           this.sortSourcePaginationCalls = true;
        //           this.sortBranchPaginationCalls = false;
        //           this.sortDatePaginationCalls = false;
        //           this.searchPagination = false;
        //           this.paginationCalls = false;
        //           this.selectedCourse = "Course";
        //           this.selectedBranch = "Branch";
        //           this.selectedDates = null;
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllSourcePageable: function (currentPage, pageCount, data) {
        //   //console.log("sourcepageable calling");
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllSourcePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getBranchDetail: function (branch) {
        //   if (this.selectedBranch == "AllBranches") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", this.selectedBranch);
        //   } else {
        //     //console.log("branch Calling");
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/call/sort/branch/" + this.agentId, data)
        //         .then((response) => {
        //           //console.log(response.data);
        //           this.calls = response.data;
        //           this.getBranchCount(data);
        //           this.selectedCourse = "Course";
        //           this.selectedSource = "Source";
        //           this.selectedDates = null;
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getBranchCount: function (selectedBranch) {
        //   if (this.selectedBranch == "AllBranches") {
        //     //console.log("course if");
        //     this.getCount();
        //     //console.log("SELECTED COURSE", this.selectedBranch);
        //   } else {
        //     //console.log("branch Calling");
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     this.loading = true;
        //     //console.log("branchcount", this.data);
        //     return new Promise((resolve, reject) => {
        //       CallService.getBranchCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllBranchPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePaginationCalls = false;
        //           this.sortSourcePaginationCalls = false;
        //           this.sortBranchPaginationCalls = true;
        //           this.sortDatePaginationCalls = false;
        //           this.searchPagination = false;
        //           this.paginationCalls = false;
        //           this.selectedCourse = "Course";
        //           this.selectedSource = "Source";
        //           this.selectedDates = null;
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllBranchPageable: function (currentPage, pageCount, data) {
        //   //console.log("branchpageable calling");
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllBranchPageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getDateRange: function() {
        // //   let from=new Date(this.fromDate)
        // //  //console.log(from.setHours(0,0,0,0),"00000000")
        //   // let to=new Date(this.toDate);
        //   let data={
        //     fromDate:new Date(this.fromDate),
        //     toDate:new Date(this.toDate)
        //   }
        //   //console.log(data);
        //  return new Promise((resolve, reject) => {
        //     this.$refs.dateRange.hide();
        //       this.loading = true;
        //       //console.log("calling Sort By Date");

        //       //console.log("Selected Date Range", this.selectedDates);
        //       CallService.getDateRange(this.agentId,data)
        //         .then((response) => {

        //           this.calls = response.data;
        //           //console.log(this.calls, "calls By Dates");

        //           this.loading = false;

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        // },
        // getCallsBetweenDates: function () {
        //   return new Promise((resolve, reject) => {
        //     if (this.selectedDates == "dateRange") {
        //       this.$refs.dateRange.show();
        //     } else {
        //       this.loading = true;
        //       //console.log("calling Sort By Date");

        //       //console.log("Selected Date Range", this.selectedDates);
        //       CallService.getByDate(this.agentId, this.selectedDates)
        //         .then((response) => {
        //           //console.log(response.data, " calls By Dates");
        //           this.calls = response.data;
        //           this.getDateCount();
        //           this.selectedCourse = "Course";
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     }
        //   });
        // },

        // getDateRange: function () {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       CallService.getDateCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getDateRangeReset();
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getClosedDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getAllDatePageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                CallService.getAllDatePageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.calls = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getDateCount: function (selectedDates) {
        //   //console.log("Datecount");

        //     return new Promise((resolve, reject) => {
        //       if (this.selectedDates == "dateRange") {
        //          this.$refs.dateRange.show();
        //       }
        //       else{
        //     this.loading = true;
        //     CallService.getDateCount(this.agentId, selectedDates)
        //       .then((response) => {
        //         this.totalCount = response.data;
        //         //console.log(response.data, " Calls Count By Dates");
        //         this.getAllDatePageable(this.currentPage,this.pageCount,this.selectedDates,this.data);
        //         this.sortCoursePaginationCalls = false;
        //         this.sortSourcePaginationCalls = false;
        //         this.sortBranchPaginationCalls = false;
        //         this.sortDatePaginationCalls = true;
        //         this.searchPagination = false;
        //         this.paginationCalls = false;
        //           this.selectedCourse="Course";
        //           this.selectedSource="Source";
        //           this.selectedBranch="Branch";
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //       }
        //   });
        // },

        // getAllDatePageable: function (currentPage,pageCount,selectedDates,data) {
        //   //console.log("Datepageable calling");
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllDatePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       selectedDates,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //        this.calls = response.data;
        //        //console.log(response.data)
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        resetForm() {
            this.call = {
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        refresh: function () {
            this.getCount();
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            // this.selectedAgent = "Agent";
            this.selectedDates = null;
            this.keyword = "";
        },
        // =================================================Closed Call Methods==========================================================
        getAllClosed: function () {
            this.loading = true;
            // this.showClosed();
            return new Promise((resolve, reject) => {
                CallService.getAllClosed(this.agentId)
                    .then((response) => {
                        this.closedCalls = response.data;
                        this.closedGetCount();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closedGetCount: function () {
            this.loading = true;
            this.closedCurrentPage = 1;
            return new Promise((resolve, reject) => {
                CallService.closedGetCount(this.agentId)
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        this.closedGetAllPageable(
                            this.closedCurrentPage,
                            this.closedPageCount
                        );
                        this.sortCoursePaginationCalls = false;
                        this.sortSourcePaginationCalls = false;
                        this.sortBranchPaginationCalls = false;
                        this.sortDatePaginationCalls = false;
                        this.searchPagination = false;
                        this.paginationCalls = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closedSwitchPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.closedGetAllPageable(closedCurrentPage, closedPageCount);
                // console.log("this.paginationCalls===", this.paginationCalls);
                // console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.allSortCalls) {
                this.getAllSortClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
                // console.log("this.paginationCalls===", this.paginationCalls);
                // console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.searchPagination) {
                this.getAllClosedCallSearchPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.closedKeyword
                );
                // console.log(this.searchPagination, "getAllClosedCallSearchPageable");
            }
            // if (this.paginationCalls) {
            //   this.closedGetAllPageable(closedCurrentPage, closedPageCount);
            //   //console.log(this.paginationCalls,"closedGetAllPageable")
            // } else if (this.sortCoursePaginationCalls) {
            //   this.getAllClosedCoursePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   //console.log(this.sortCoursePaginationCalls,"getAllClosedCoursePageable")
            // } else if (this.sortSourcePaginationCalls) {
            //   this.getAllClosedSourcePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   //console.log(this.sortSourcePaginationCalls,"getAllClosedSourcePageable")
            // } else if (this.sortBranchPaginationCalls) {
            //   this.getAllClosedBranchPageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   //console.log(this.sortBranchPaginationCalls,"getAllClosedBranchPageable")
            // } else if (this.sortDatePaginationCalls) {
            //   this.getAllClosedDatePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // console.log(this.sortDatePaginationCalls,"getAllClosedDatePageable")
            // } else if (this.searchPagination) {
            //   this.getAllClosedCallSearchPageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.keyword
            //   );
            //   //console.log(this.searchPagination,"getAllClosedCallSearchPageable")
            // }
        },
        closedGetAllPageable: function (closedCurrentPage, closedPageCount) {
            return new Promise((resolve, reject) => {
                CallService.closedGetAllPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId
                    )
                    .then((response) => {
                        this.closedCalls = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        searchCallclosed: function (closedKeyword) {
            return new Promise((resolve, reject) => {
                CallService.searchCallclosed(closedKeyword.toLowerCase(), this.agentId)
                    .then((response) => {
                        this.closedCalls = response.data;
                        this.getClosedCallSearchCount(closedKeyword.toLowerCase());
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getClosedCallSearchCount: function (closedKeyword) {
            //console.log("search pagecount", closedKeyword);
            return new Promise((resolve, reject) => {
                CallService.getClosedCallSearchCount(
                        this.agentId,
                        closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        //console.log("search pagecount", response.data);
                        this.getAllClosedCallSearchPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            closedKeyword.toLowerCase()
                        );
                        this.searchPagination = true;
                        this.paginationCalls = false;
                        this.allSortCalls = false;
                        this.closedCurrentPage = 1;
                        this.loading = true;
                        // this.sortCoursePaginationCalls = false;
                        // this.sortSourcePaginationCalls = false;
                        // this.sortBranchPaginationCalls = false;
                        // this.sortDatePaginationCalls = false;
                        // this.searchPagination = true;
                        // this.paginationCalls = false;
                        // this.closedCurrentPage = 1;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedCallSearchPageable: function (
            closedCurrentPage,
            closedPageCount,
            closedKeyword
        ) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                CallService.getAllClosedCallSearchPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getClosedCourseDetail: function (course) {
            if (this.selectedClosedCourse == "AllClosedCourses") {
                //console.log("course if");
                this.getAllClosed();
                //console.log("SELECTED COURSE", this.selectedCourse);
            } else {
                //console.log("closed Course Calling");
                let data = {
                    courseId: course.courseId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    axios
                        .post("/call/sortClosedCall/course/" + this.agentId, data)
                        .then((response) => {
                            //console.log(response.data);
                            this.closedCalls = response.data;
                            this.getCourseClosedCount(data);
                            this.selectedClosedSource = "Sources";
                            this.selectedClosedBranch = "Branches";
                            this.selectedClosedDates = "Dates";
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getCourseClosedCount: function (selectedCourse) {
            if (this.selectedClosedCourse == "AllClosedCourses") {
                //console.log("course if");
                this.closedGetCount();
                //console.log("SELECTED COURSE", this.selectedCourse);
            } else {
                //console.log("closed Course Calling");
                this.data = {
                    courseId: selectedCourse.courseId,
                };
                //console.log("closedcoursecount", this.data);
                this.loading = true;
                return new Promise((resolve, reject) => {
                    CallService.getClosedCourseCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getAllClosedCoursePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.sortCoursePaginationCalls = true;
                            this.sortSourcePaginationCalls = false;
                            this.sortBranchPaginationCalls = false;
                            this.sortDatePaginationCalls = false;
                            this.searchPagination = false;
                            this.paginationCalls = false;
                            this.selectedClosedSource = "Sources";
                            this.selectedClosedBranch = "Branches";
                            this.selectedClosedDates = null;
                            this.closedCurrentPage = 1;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllClosedCoursePageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            //console.log("coursepageable calling");
            return new Promise((resolve, reject) => {
                CallService.getAllClosedCoursePageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getClosedSourceDetail: function (source) {
            if (this.selectedClosedSource == "AllClosedSources") {
                //console.log("Source if");
                this.getAllClosed();
                //console.log("SELECTED Source", this.selectedSource);
            } else {
                //console.log("source Calling");
                let data = {
                    sourceId: source.sourceId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    axios
                        .post("/call/sortClosedCall/source/" + this.agentId, data)
                        .then((response) => {
                            //console.log(response.data);
                            this.closedCalls = response.data;
                            this.getSourceClosedCount(data);
                            this.selectedClosedCourse = "Courses";
                            this.selectedClosedBranch = "Branches";
                            this.selectedClosedDates = "Dates";
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getSourceClosedCount: function (selectedSource) {
            if (this.selectedClosedSource == "AllClosedSources") {
                //console.log("Source if");
                this.closedGetCount();
                //console.log("SELECTED Source", this.selectedSource);
            } else {
                //console.log("source Calling");
                this.data = {
                    sourceId: selectedSource.sourceId,
                };
                this.loading = true;
                //console.log("closedSourcecount", this.data);
                return new Promise((resolve, reject) => {
                    CallService.getClosedSourceCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getAllClosedSourcePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.sortCoursePaginationCalls = false;
                            this.sortSourcePaginationCalls = true;
                            this.sortBranchPaginationCalls = false;
                            this.sortDatePaginationCalls = false;
                            this.searchPagination = false;
                            this.paginationCalls = false;
                            this.selectedClosedCourse = "Courses";
                            this.selectedClosedBranch = "Branches";
                            this.selectedClosedDates = null;
                            this.closedCurrentPage = 1;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllClosedSourcePageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            //console.log("Sourcepageable calling");
            return new Promise((resolve, reject) => {
                CallService.getAllClosedSourcePageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getClosedBranchDetail: function (branch) {
            if (this.selectedClosedBranch == "AllClosedBranches") {
                //console.log("Branch if");
                this.getAllClosed();
                //console.log("SELECTED Branch", this.selectedBranch);
            } else {
                //console.log("branch Calling");
                let data = {
                    branchId: branch.branchId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    axios
                        .post("/call/sortClosedCall/branch/" + this.agentId, data)
                        .then((response) => {
                            // //console.log(response.data);
                            this.closedCalls = response.data;
                            this.getBranchClosedCount(data);
                            this.selectedClosedCourse = "Courses";
                            this.selectedClosedSource = "Sources";
                            this.selectedClosedDates = "Dates";
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getBranchClosedCount: function (selectedBranch) {
            if (this.selectedClosedBranch == "AllClosedBranches") {
                // //console.log("Branch if");
                this.closedGetCount();
                // //console.log("SELECTED Branch", this.selectedBranch);
            } else {
                // //console.log("branch Calling");
                this.data = {
                    branchId: selectedBranch.branchId,
                };
                this.loading = true;
                // //console.log("closedbranchcount", this.data);
                return new Promise((resolve, reject) => {
                    CallService.getClosedBranchCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getAllClosedBranchPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.sortCoursePaginationCalls = false;
                            this.sortSourcePaginationCalls = false;
                            this.sortBranchPaginationCalls = true;
                            this.sortDatePaginationCalls = false;
                            this.searchPagination = false;
                            this.paginationCalls = false;
                            this.selectedClosedCourse = "Courses";
                            this.selectedClosedSource = "Sources";
                            this.selectedClosedDates = null;
                            this.closedCurrentPage = 1;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllClosedBranchPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // //console.log("branchpageable calling");
            return new Promise((resolve, reject) => {
                CallService.getAllClosedBranchPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //  getClosedDateRange: function() {
        //   let data={
        //     fromDate:new Date(this.fromDate),
        //     toDate:new Date(this.toDate)
        //   }
        //   // //console.log(data);
        //  return new Promise((resolve, reject) => {
        //     this.$refs.dateRange.hide();
        //       this.loading = true;
        //       // //console.log("Closed calling Sort By Date");

        //       // //console.log("Selected Closed Date Range", this.selectedClosedDates);
        //       CallService.getClosedDateRange(this.agentId,data)
        //         .then((response) => {

        //           this.closedCalls = response.data;
        //           // //console.log(this.closedCalls, "Closed calls By Dates");

        //           this.loading = false;

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        // },
        // getClosedCallsBetweenDates: function () {
        //   if (this.selectedClosedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //   } else {
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       // //console.log("closed calling Sort By Date");
        //       // AdmissionService.getToday()
        //       // //console.log("Selected Date Range", this.selectedClosedDates);
        //       CallService.getByClosedDate(this.agentId, this.selectedClosedDates)
        //         .then((response) => {
        //           // //console.log(response.data, "closed calls By Dates");
        //           this.closedCalls = response.data;
        //           this.getDateClosedCount();
        //           this.selectedClosedCourse = "Courses";
        //           this.selectedClosedSource = "Sources";
        //           this.selectedClosedBranch = "Branches";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getDateClosedCount: function (selectedClosedDates) {
        //   this.sortCoursePaginationCalls = false;
        //   this.sortSourcePaginationCalls = false;
        //   this.sortBranchPaginationCalls = false;
        //   this.sortDatePaginationCalls = true;
        //   this.searchPagination = false;
        //   this.paginationCalls = false;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";

        //   this.closedCurrentPage = 1;
        //   if (selectedClosedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedClosedDates = null;
        //     this.getClosedDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedClosedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedClosedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       CallService.getDateClosedCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,

        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedDateRange: function () {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       CallService.getDateClosedCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getClosedDateRangeReset();
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,

        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedDateRangeReset: function () {
        //   this.$refs.dateRange.hide();
        //   (this.from = ""), (this.to = ""), this.$v.from.$reset();
        //   this.$v.to.$reset();
        // },
        // getAllClosedDatePageable: function (
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllClosedDatePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedCalls = response.data;
        //         // console.log(this.closedcalls);
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getDateClosedCount: function () {

        //    if (this.selectedClosedDates == "dateRange") {
        //          this.$refs.dateRange.show();
        //       }
        //       else{
        //   // //console.log("Closed Datecount");
        //   this.loading=true
        //   return new Promise((resolve, reject) => {
        //     CallService.getDateClosedCount(this.agentId, this.selectedClosedDates)
        //       .then((response) => {
        //         this.closedTotalCount = response.data;
        //         // //console.log(response.data, " Calls Count By Dates");
        //         this.getAllClosedDatePageable(this.closedCurrentPage,this.closedPageCount,this.selectedClosedDates,this.data);
        //          this.sortCoursePaginationCalls = false;
        //         this.sortSourcePaginationCalls = false;
        //         this.sortBranchPaginationCalls = false;
        //         this.sortDatePaginationCalls = true;
        //         this.searchPagination = false;
        //         this.paginationCalls = false;
        //            this.selectedClosedCourse = "Courses";
        //           this.selectedClosedSource = "Sources";
        //           this.selectedClosedBranch = "Branches";
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        //       }
        // },

        // getAllClosedDatePageable: function (closedCurrentPage,closedPageCount,selectedClosedDates,data) {
        //   // //console.log("Closed Datepageable calling");
        //   return new Promise((resolve, reject) => {
        //     CallService.getAllClosedDatePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       this.agentId,
        //       selectedClosedDates,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.closedCalls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        resetFollowup() {
            this.enquiryAs = {
                remark: "",
                followupDate: "",
            };
        },

        callClosed: function () {
            this.disabledButton = true;
            // let closedDatas = {
            //     callId: this.update.callId,
            //     name: this.update.name,
            //     contact: this.update.contact,
            //     age: this.update.contact,
            //     qualification: this.update.qualification,
            //     course: this.update.course,
            //     source: this.update.source,
            //     mode: this.update.mode,
            //     branch: this.update.branch,
            //     followupDate: this.enquiryAs.followupDate,
            //     remark: this.enquiryAs.remark,
            // };
            if (this.closed == "enquiry") {
                // if (this.$v.enquiryAs.$invalid) {
                //     this.submitStatus = "FAIL";
                //     this.$swal("Select for next Enquiry followupdate");
                // } else {
                // }
                return new Promise((resolve, reject) => {
                    axios
                        .post("/enquiry/insert/" + this.agentId, this.update)
                        .then((response) => {
                            this.$swal(response.data);
                            this.closed = "";
                            // this.resetFollowup();
                            this.$refs.modal.hide();
                            this.getCount();
                            this.disabledButton = false;

                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else if (this.closed == "admission") {
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admission/insert/" + this.agentId, this.update)
                        .then((response) => {
                            this.$swal(response.data);
                            this.closed = "";
                            this.$refs.modal.hide();
                            this.getCount();
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.$swal("Closed option invalid");
                this.disabledButton = false;
            }
        },

        // updateStatusClosed: function () {
        //   return new Promise((resolve, reject) => {
        //     CallService.updateStatusClosed(this.agentId, this.update.callId)
        //       .then((response) => {
        //         this.$swal(response.data);
        //         this.$refs.modal.hide();
        //         this.getCount();
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        updateStatusClosed(agentId, callId) {
            return new Promise((resolve, reject) => {
                //console.log("3rd",this.agentId);
                NotintrestedsService.updateStatusClosed(agentId, callId)
                    .then((response) => {
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        closedRefresh: function () {
            this.closedGetCount();
            (this.selectedClosedCourse = "Courses"),
            (this.selectedClosedSource = "Sources"),
            (this.selectedClosedBranch = "Branches"),
            // (this.selectedClosedAgent = "Agent"),
            (this.selectedClosedDates = null);
            this.closedKeyword = "";
            this.closedCurrentPage = 1;
        },

        // getAllAgents: function(){
        //   return new Promise((resolve, reject) => {
        //     AgentService.getAllAgents()
        //     .then((response) => {
        //       var agent = response.data;
        //       agent.forEach(e => {
        //         this.agentDetails = e;
        //         // //console.log(e);
        //         this.agentId = e.agentId;
        //         // //console.log(this.agentId);
        //       })
        //       this.loading = false;
        //       resolve(response);
        //     })
        //     .catch((err) => {
        //       reject(err);
        //     });
        //   });
        // },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 120px;
}

.custom-radio {
    display: inline;
}
</style>
