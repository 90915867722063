<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container class="mt-2">
        <b-tabs pills justified card>
            <b-tab title="Agent" @click="showAgents()">
                <b-container>
                    <b-row class="my-1" align-v="right">
                        <!-- <b-col col lg="6" md="2" sm="6" style="text-align: right">
                            <b-button size="sm" id="agents" variant="outline-primary" @click="agentMigrationShow()">Agent Migration</b-button>
                        </b-col> -->
                        <b-col col lg="12" md="2" sm="6" style="text-align: right">
                            <!-- <b-button size="sm" id="agents" variant="outline-primary" @click="showArchiveAgent()" v-bind:class="{ active: archiveAgentActivate }">Archive Agent</b-button> -->
                            <b-button size="sm" id="agents" variant="outline-primary" @click="agentMigrationShow()">Agent Migration</b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <div class="mt-2">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addagent @click="resetTelecaller()" disabled>Add Agent</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Email/Contact/Name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <p>Agent</p>
                            <hr />
                            <b-table responsive hover :items="agents" :fields="agentFields" :current-page="currentPage" :per-page="agents.pageCount" ref="table">
                                <!-- <template v-slot:cell(status)="data">
                                    <img v-if="data.item.isOnline" src="../../public/online.png" width="10">
                                </template> -->
                                <template v-slot:cell(firstName)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                    <sup>
                                        <img v-if="data.item.isIdle" src="../../public/idle-2.png" width="9">
                                        <img v-else-if="data.item.isAway" src="../../public/away.png" width="9">
                                        <img v-else-if="data.item.isOffline" src="../../public/offline.png" width="9">
                                        <img v-else-if="data.item.isOnline" src="../../public/online.png" width="10">
                                    </sup>
                                </template>
                                <template v-slot:cell(contact)="data">
                                    {{ data.item.contact }}
                                </template>
                                <template v-slot:cell(lastSeen)="data">
                                    <span v-if="data.item.lastSeen != null">
                                        {{ dateFormat( data.item.lastSeen, "fullDate" ) }}
                                    </span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Agent Details" v-b-modal.viewagent @click="getAgent(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Agent Details" variant="success" @click="agentArchiveUpdate(data.item.agentId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Agent Details" v-if="data.item.archive == true" variant="warning" @click="agentUnarchiveUpdate(data.item.agentId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Supervisor" @click="showSupervisors()">
                <div class="mt-5">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addcounsellor @click="resetForm()" disabled>Supervisor</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="supervisorKeyword" v-on:keyup.enter="getSupervisorSearchCount(supervisorKeyword)" placeholder="Email/Contact/Name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSupervisorSearchCount(supervisorKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="supervisorCurrentPage" :total-rows="totalSupervisorCount" :per-page="supervisorPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="supervisorSwitchPage(supervisorCurrentPage,supervisorPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="supervisorPageCount" @input="supervisorSwitchPage(supervisorCurrentPage,supervisorPageCount)" :options="supervisorPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refreshSupervisor()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <p>Supervisor</p>
                            <hr />
                            <b-table responsive hover :items="supervisors" :fields="supervisorFields" :current-page="supervisorCurrentPage" :per-page="supervisors.supervisorPageCount" ref="table">
                                <template v-slot:cell(agentId)="data">
                                    <span class="title"> {{ data.item.agentId }}</span>
                                </template>
                                <template v-slot:cell(firstName)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    {{ data.item.contact }}
                                </template>
                                <template v-slot:cell(email)="data">
                                    {{ data.item.email }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Agent Details" v-b-modal.viewagent @click="getAgent(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Admin Supervisor" @click="showAdminSupervisors()">
                <div class="mt-5">
                    <b-container>
                        <b-row align-v="center">
                            <!--add Agent button start-->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addcounsellor @click="resetForm()" disabled>Supervisor</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="adminSupervisorKeyword" v-on:keyup.enter="getAdminSupervisorSearchCount(adminSupervisorKeyword)" placeholder="Email/Contact/Name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAdminSupervisorSearchCount(adminSupervisorKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="adminSupervisorCurrentPage" :total-rows="totalAdminSupervisorCount" :per-page="adminSupervisorPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="adminSupervisorSwitchPage(adminSupervisorCurrentPage,adminSupervisorPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="supervisorPageCount" @input="adminSupervisorSwitchPage(adminSupervisorCurrentPage,adminSupervisorPageCount)" :options="adminSupervisorPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refreshAdminSupervisor()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <p>Admin Supervisor</p>
                            <hr />
                            <b-table responsive hover :items="adminSupervisors" :fields="adminSupervisorFields" :current-page="adminSupervisorCurrentPage" :per-page="adminSupervisors.adminSupervisorPageCount" ref="table">
                                <template v-slot:cell(firstName)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    {{ data.item.contact }}
                                </template>
                                <template v-slot:cell(email)="data">
                                    {{ data.item.email }}
                                </template>
                                <template v-slot:cell(branches)="data">
                                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', 'sans-serif';" pill v-for="item in data.item.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;
                                    </b-badge>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Agent Details" @click="viewAdminSupervisor(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Counsellor" @click="showCounsellors()">
                <div class="mt-5">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addcounsellor @click="resetForm()" disabled>Counsellor</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getCounsellorSearchCount(keyword)" placeholder="Email/Contact/Name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCounsellorSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="counsellorCurrentPage" :total-rows="totalCounsellorCount" :per-page="counsellorPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="counsellorSwitchPage(counsellorCurrentPage,counsellorPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="counsellorPageCount" @input="counsellorSwitchPage(counsellorCurrentPage,counsellorPageCount)" :options="counsellorPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refreshCounsellor()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <p>Counsellor</p>
                            <hr />
                            <b-table responsive hover :items="counsellors" :fields="counsellorfields" :current-page="counsellorCurrentPage" :per-page="counsellors.counsellorPageCount" ref="table">
                                <template v-slot:cell(agentId)="data">
                                    <span class="title"> {{ data.item.agentId }}</span>
                                </template>
                                <template v-slot:cell(firstName)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    {{ data.item.contact }}
                                </template>
                                <template v-slot:cell(email)="data">
                                    {{ data.item.email }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Agent Details" v-b-modal.viewagent @click="getCounsellor(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Archive Agent" @click="showArchiveAgent()">
                <div class="mt-5">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Add Agent</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="archiveAgentKeyword" v-on:keyup.enter="getArchiveAgentSearchCount(archiveAgentKeyword)" placeholder="Email/Contact/Name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getArchiveAgentSearchCount(archiveAgentKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="archiveAgentCurrentPage" :total-rows="totalArchiveAgentsCount" :per-page="archiveAgentPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="archiveAgentSwitchPage(archiveAgentCurrentPage,archiveAgentPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="archiveAgentPageCount" @input="archiveAgentSwitchPage(archiveAgentCurrentPage,archiveAgentPageCount)" :options="archiveAgentPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refreshArchiveAgent()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <p>Archive Agent</p>
                            <hr />
                            <b-table responsive hover :items="archiveAgents" :fields="archiveAgentFields" :current-page="archiveAgentCurrentPage" :per-page="archiveAgents.archiveAgentPageCount" ref="table">
                                <template v-slot:cell(agentId)="data">
                                    <span class="title"> {{ data.item.agentId }}</span>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.firstName }}&nbsp;{{ data.item.lastName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    {{ data.item.contact }}
                                </template>
                                <template v-slot:cell(email)="data">
                                    {{ data.item.email }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    <span v-if="data.item.branch != null">
                                        {{ data.item.branch.name }}
                                    </span>
                                    <span v-else>
                                        No branch
                                    </span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Agent Details" v-b-modal.viewagent @click="getAgent(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Agent Details" variant="success" @click="agentArchiveUpdate(data.item.agentId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                    <b-button size="sm" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Agent Details" v-if="data.item.archive == true" variant="warning" @click="agentUnarchiveUpdate(data.item.agentId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
        </b-tabs>
        <!-- <div>
            <b-row class="my-4 pt-3" align-v="center">
                <b-col col lg="3" md="2" sm="6" style="text-align: center" v-if="!archiveAgentActivate">
                    <b-button id="agents" size="sm" variant="outline-primary" @click="showAgents()">
                        Agents</b-button>
                </b-col>
                <b-col col lg="3" md="2" sm="6" style="text-align: center" v-else>
                    <b-button id="agents" size="sm" variant="outline-primary" @click="showAgents()">
                        Agents</b-button>
                </b-col>
                <b-col col lg="3" md="2" sm="6" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="showSupervisors()">Supervisors</b-button>
                </b-col>
                <b-col col lg="3" md="2" sm="6" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="showAdminSupervisors()">Admin Supervisors</b-button>
                </b-col>
                <b-col col lg="3" md="2" sm="6" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="showCounsellors()">Counsellors</b-button>
                </b-col>
            </b-row>
        </div> -->
        <!--popup start for Agent Register-->
        <b-modal id="addagent" title="Agent" hide-footer ref="modal">
            <div class="form-row">
                <div class="form-group">
                    <label>First Name:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="$v.telecaller.firstName.$model" :class="{
                'is-invalid': $v.telecaller.firstName.$error,
                'is-valid': !$v.telecaller.firstName.$invalid,
              }" placeholder="Enter your First Name">
                    </b-form-input>
                    <div class="valid-feedback">Your First Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.telecaller.firstName.required">First Name is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Last Name:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="telecaller.lastName" placeholder="Enter your Last Name">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Username:</label>
                    <b-form-input class="form-control" v-model.trim="$v.telecaller.username.$model" :class="{
                'is-invalid': $v.telecaller.username.$error,
                'is-valid': !$v.telecaller.username.$invalid,
              }" placeholder="Enter your username">
                    </b-form-input>
                    <div class="valid-feedback">Your username is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.telecaller.username.required">username is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Mail Id:</label>
                    <b-form-input v-model.trim="$v.telecaller.contact.$model" :class="{
                'is-invalid': $v.telecaller.contact.$error,
                'is-valid': !$v.telecaller.contact.$invalid,
              }" placeholder="Enter your email">
                    </b-form-input>
                    <div class="valid-feedback">Your email is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.telecaller.contact.required">Email is required</span>
                        <span v-if="!$v.telecaller.contact.isUnique">Enter your proper email</span>
                    </div>
                </div>
                <br />
                <div class="text-center">
                    <b-button variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,putAgent()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetTelecaller()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!--popup end for Agent Register-->

        <!--popup start for Agent followups migrate to another agent-->
        <b-modal title="Agent Migration" ref="agentMigrationModal" no-close-on-backdrop hide-footer>
            <div class="form-row mt-3">
                <div class="form-group my-2 px-5">
                    <label>Dispatch agent:</label>
                    <b-form-select class="form-control my-1" v-model="migration.selectedDispatchAgent" :class="{'is-invalid': $v.migration.selectedDispatchAgent.$error,'is-valid': !$v.migration.selectedDispatchAgent.$invalid,}" @change="selectAgentRemove()">
                        <option value="null" disabled>Select Dispatch Agent</option>
                        <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                            <strong>{{ item.firstName }}</strong> <strong>{{ item.lastName }}</strong>
                        </option>
                    </b-form-select>
                </div>
                <div class="form-group my-2 px-5">
                    <label>Beneficiary Agent:</label>
                    <b-form-select class="form-control my-1" v-model="migration.selectedAgent" :class="{'is-invalid': $v.migration.selectedAgent.$error,'is-valid': !$v.migration.selectedAgent.$invalid,}">
                        <option value="null" disabled>Select Beneficiary Agent</option>
                        <option v-for="item in newAllAgents" :value="item" v-bind:key="item.index">
                            <strong>{{ item.firstName }}</strong> <strong>{{ item.lastName }}</strong>
                        </option>
                    </b-form-select>
                </div>
                <div class="text-center py-2">
                    <b-button variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,agentMigrate()">Migrate</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetMigration()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!--popup end for Agent followups migrate to another agent-->
    </b-container>

    <!-- View Agent Start -->
    <b-modal id="viewagent" ref="viewAgent" title="View Agent" hide-footer>
        <div class="control">
            <b-table-simple>
                <b-tr>
                    <b-th>Name :</b-th>
                    <b-td>{{ agent.firstName }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th>Contact :</b-th>
                    <b-td>{{ agent.contact }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th>Email-id :</b-th>
                    <b-td>{{ agent.email }}</b-td>
                </b-tr>
            </b-table-simple>
            <div class="form-row mt-3 px-5" v-if="adminSupervisorActivate">
                <div class="form-group my-2">
                    <label>Branch :</label>
                    <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="agent.branches != null && agent.branches.length != 0">
                        <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in agent.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;
                        </b-badge>
                    </b-card>
                </div>
            </div>
        </div>
    </b-modal>
    <!-- View Agent End-->

    <!-- Update Agent Start -->
    <b-modal id="updateAgentModal" title="Update Agent" ref="updateAgentModal" hide-footer>
        <div class="form-row">
            <div class="form-group">
                <label>Name:</label>
                <b-form-input type="text" class="form-control" v-model.trim="$v.agent.name.$model" :class="{
              'is-invalid': $v.agent.name.$error,
              'is-valid': !$v.agent.name.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Contact:</label>
                <b-form-input type="text" class="form-control" v-model.trim="$v.agent.contact.$model" :class="{
              'is-invalid': $v.agent.contact.$error,
              'is-valid': !$v.agent.contact.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Mail Id:</label>
                <b-form-input type="email" v-model.trim="$v.agent.email.$model" :class="{
              'is-invalid': $v.agent.email.$error,
              'is-valid': !$v.agent.email.$invalid,
            }">
                </b-form-input>
            </div>
            <br />
            <div v-if="adminSupervisorActivate" class="form-group my-2">
                <label>Branch:</label>
                <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.branches.$error,'is-valid': !$v.branches.$invalid,}">
                    <option value="null" disabled>Select Branch</option>
                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                        <strong>{{item.name}}</strong>
                    </option>
                </b-form-select>&nbsp;&nbsp;
                <div v-if="selectedBranch!=null" class="text-center">
                    <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                </div>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="branches.length != 0">
                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                    </b-badge>
                </b-card>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                    <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                </b-card>
            </div>
            <div class="text-center">
                <span v-if="agentsActive">
                    <b-button variant="outline-primary" id="submit" class="mx-5 mt-2" size="sm" @click="updateAgent()">Update</b-button>
                </span>
                <span v-if="adminSupervisorActivate">
                    <b-button variant="outline-primary" id="submit" class="mx-5 mt-2" size="sm" @click="adminSupervisorUpdate()">Update</b-button>
                </span>
                <span v-if="supervisorActivate">
                    <b-button variant="outline-primary" id="submit" class="mx-5 mt-2" size="sm" @click="supervisorUpdate()">Update</b-button>
                </span>
                <span v-if="counsellorsActive">
                    <b-button variant="outline-primary" id="submit" class="mx-5 mt-2" size="sm" @click="counsellorUpdate()">Update</b-button>
                </span>
            </div>
            <!-- Role activate as User table -->
            <!-- <div class="text-center pt-2">
                <span v-if="agentsActive">
                    <div>
                        <b-button class="" variant="outline-success" size="sm" v-b-modal.activateCounsellor @click="resetBranch()">Activate Counsellor</b-button>
                    </div>
                    <div>
                        <b-button class="my-1" variant="outline-success" size="sm" v-b-modal.activateSupervisor @click="resetBranch()">Activate Supervisor</b-button>
                    </div>
                    <div>
                        <b-button class="" variant="outline-success" size="sm" @click="resetBranches()">Activate AdminSupervisor</b-button>
                    </div>
                </span>
                <span v-if="counsellorsActive">
                    <b-button class="mx-2" variant="outline-danger" size="sm" @click="removeCounsellorRole()">Deactivate Counsellor</b-button>
                </span>
                <span v-if="supervisorActivate">
                    <b-button class="mx-2" variant="outline-danger" size="sm" @click="deactivateSupervisorRole()">Deactivate Supervisor</b-button>
                </span>
                <span v-if="adminSupervisorActivate">
                    <b-button class="mx-2" variant="outline-danger" size="sm" @click="removeAdminSupervisorRole()">Deactivate Admin Supervisor</b-button>
                </span>
            </div> -->
        </div>
    </b-modal>
    <!-- Update Agent End  -->

    <!-- Update Counsellor Start -->
    <!-- <b-modal id="updatecounsellor" title="Update Counselllor" ref="modal" hide-footer>
        <div class="form-row">
            <div class="form-group">
                <label>Name:</label>
                <b-form-input type="text" class="form-control" v-model.trim="$v.agent.name.$model" :class="{
              'is-invalid': $v.agent.name.$error,
              'is-valid': !$v.agent.name.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Contact:</label>
                <b-form-input type="number" class="form-control" v-model.trim="$v.agent.contact.$model" :class="{
              'is-invalid': $v.agent.contact.$error,
              'is-valid': !$v.agent.contact.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Mail Id:</label>
                <b-form-input type="email" v-model.trim="$v.agent.email.$model" :class="{
              'is-invalid': $v.agent.email.$error,
              'is-valid': !$v.agent.email.$invalid,
            }">
                </b-form-input>
            </div>
            <br />
            <div class="text-center pt-3">
                <b-button variant="outline-danger" id="submit" class="mx-5" size="sm" @click="updateAgent()">Update</b-button>
            </div>
        </div>
    </b-modal> -->
    <!-- Update Counsellor End  -->
    <!-- Counsellor Popup Start -->

    <b-modal id="activateCounsellor" title="Activate As Counsellor" ref="modal" hide-footer>
        <div class="form-row">
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label> Select Branch</label>
                    <b-form-select class="form-control" v-model="agent.branch" :class="{
                'is-invalid': $v.agent.branch.$error,
                'is-valid': !$v.agent.branch.$invalid,
              }">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <br />
            <div class="text-center pt-3">
                <b-button variant="outline-success" size="sm" @click="activateCounsellorRole()">
                    Activate</b-button>
                <b-button variant="outline-danger" id="submit" class="mx-5" size="sm" @click="cancel()">Cancel</b-button>
            </div>
        </div>
    </b-modal>

    <!-- Counsellor Popup End -->

    <!-- Supervisor activate modal -->
    <b-modal id="activateSupervisor" title="Activate As Supervisor" ref="activateSupervisor" hide-footer>
        <div class="form-row">
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label> Select Branch</label>
                    <b-form-select class="form-control" v-model="agent.branch" :class="{
                'is-invalid': $v.agent.branch.$error,
                'is-valid': !$v.agent.branch.$invalid,
              }">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <br />
            <div class="text-center pt-3">
                <b-button variant="outline-success" size="sm" @click="activateSupervisorRole()">
                    Activate</b-button>
                <b-button variant="outline-danger" id="submit" class="mx-5" size="sm" @click="cancel()">Cancel</b-button>
            </div>
        </div>
    </b-modal>

    <!-- Admin Supervisor activate modal -->
    <b-modal id="activateAdminSupervisorModal" title="Activate Admin Supervisor" ref="activateAdminSupervisorModal" hide-footer>
        <div class="form-row">
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Branch:</label>
                    <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.branches.$error,'is-valid': !$v.branches.$invalid,}">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                            <strong>{{item.name}}</strong>
                        </option>
                    </b-form-select>&nbsp;&nbsp;
                    <div v-if="selectedBranch!=null" class="text-center">
                        <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                    </div>
                    <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="branches.length != 0">
                        <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                        </b-badge>
                    </b-card>
                    <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                        <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                    </b-card>
                </div>
            </div>
            <br />
            <div class="text-center pt-3">
                <b-button variant="outline-success" size="sm" @click="activateAdminSupervisorRole()">
                    Activate</b-button>
                <b-button variant="outline-danger" id="submit" class="mx-5" size="sm" @click="resetAdminSupervisorForm()">Cancel</b-button>
            </div>
        </div>
    </b-modal>
    <!-- Admin Supervisor activate modal end-->

    <!-- Update Supervisor Start -->
    <!-- <b-modal id="updateSupervisor" title="Update Supervisor" ref="updateSupervisor" hide-footer>
        <div class="form-row">
            <div class="form-group">
                <label>Name:</label>
                <b-form-input type="text" class="form-control" v-model.trim="$v.agent.name.$model" :class="{
              'is-invalid': $v.agent.name.$error,
              'is-valid': !$v.agent.name.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Contact:</label>
                <b-form-input type="number" class="form-control" v-model.trim="$v.agent.contact.$model" :class="{
              'is-invalid': $v.agent.contact.$error,
              'is-valid': !$v.agent.contact.$invalid,
            }">
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Mail Id:</label>
                <b-form-input type="email" v-model.trim="$v.agent.email.$model" :class="{
              'is-invalid': $v.agent.email.$error,
              'is-valid': !$v.agent.email.$invalid,
            }">
                </b-form-input>
            </div>
            <br />
            <div class="text-center">
                <b-button variant="outline-primary" id="submit" class="mx-5 mt-2" size="sm" @click="updateAgent()">Update</b-button>

            </div>
            <div class="text-center pt-3">
                <b-button class="mx-2" variant="outline-danger" size="sm" @click="deactivateSupervisor()">Deactivate Supervisor</b-button>
            </div>
        </div>
    </b-modal> -->

    <!-- Update Supervisor End  -->

    <!-- <Loader /> -->
</div>
</template>

<script>
// import Loader from  './Loader';
import moment from "moment-timezone";
import BranchService from "../service/BranchService";
import AgentService from "../service/AgentService";
import Account from '../service/Account.js';
import axios from "axios";
import {
    required,
    minLength,
    maxLength,
    email,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
    name: "Agent",
    components: {},
    data() {
        return {
            paginationAgents: false,
            searchPaginationAgents: false,
            paginationCounsellors: false,
            searchPaginationCounsellors: false,
            paginationSupervisor: false,
            searchPaginationSupervisor: false,
            paginationAdminSupervisor: false,
            searchPaginationAdminSupervisor: false,
            paginationArchiveAgent: false,
            searchPaginationArchiveAgent: false,
            loading: true,
            rows: 100,
            currentPage: 1,
            counsellorCurrentPage: 1,
            supervisorCurrentPage: 1,
            adminSupervisorCurrentPage: 1,
            archiveAgentCurrentPage: 1,
            pageCount: 5,
            counsellorPageCount: 5,
            supervisorPageCount: 5,
            adminSupervisorPageCount: 5,
            archiveAgentPageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            counsellorPageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            supervisorPageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            adminSupervisorPageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            archiveAgentPageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            totalCount: 0,
            agentsActive: true,
            counsellorsActive: false,
            supervisorActivate: false,
            adminSupervisorActivate: false,
            archiveAgentActivate: false,
            keyword: "",
            supervisorKeyword: "",
            adminSupervisorKeyword: "",
            archiveAgentKeyword: "",
            agent: {
                agentId: "",
                name: "",
                contact: "",
                email: "",
                branches: [],
            },
            agents: [],
            agentFields: [
                // {
                //     key: "status",
                //     label: ""
                // },
                {
                    key: "firstName",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "email",
                    label: "E-mail"
                },
                {
                    key: "status",
                    label: "Status"
                },
                {
                    key: "lastSeen",
                    label: "last Seen"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            counsellor: {
                name: "",
                contact: "",
                email: "",
                branch: "",
            },
            counsellors: [],
            totalCounsellorCount: 0,
            counsellorfields: [{
                    key: "firstName",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "email",
                    label: "E-mail"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            supervisors: [],
            totalSupervisorCount: 0,
            supervisorFields: [{
                    key: "firstName",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "email",
                    label: "E-mail"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            adminSupervisors: [],
            branches: [],
            selectedBranch: null,
            totalAdminSupervisorCount: 0,
            adminSupervisorFields: [{
                    key: "firstName",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "email",
                    label: "E-mail"
                },
                {
                    key: "branches",
                    label: "Branches"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            allBranches: [],
            branch: [],
            archiveAgents: [],
            totalArchiveAgentsCount: 0,
            archiveAgentFields: [{
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "email",
                    label: "E-mail"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            telecaller: {
                firstName: "",
                lastName: "",
                username: "",
                contact: "",
            },
            user: null,
            disabledButton: false,
            migration: {
                selectedDispatchAgent: null,
                selectedAgent: null,
            },
            allAgents: [],
            newAllAgents: [],
            userId: "",
            token: "",
        };
    },
    validations: {
        agent: {
            name: {
                required,
                minLength: minLength(3),
            },
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },

            email: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            },
            branch: {
                required,
            },
        },
        telecaller: {
            firstName: {
                required,
                minLength: minLength(3),
            },
            username: {
                required,
                minLength: minLength(5),
            },
            contact: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            }
        },
        branches: {
            required
        },
        migration: {
            selectedDispatchAgent: {
                required
            },
            selectedAgent: {
                required
            }
        }
    },
    mounted() {
        this.loading = true;
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    // self.getAllAgents();
                    self.getAllBranches();
                    // self.getAllPageable();
                    self.getCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        // this.getAllAgents();
        // Global.refreshToken();
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
                this.getCounsellorCount();
            }
        },
        supervisorKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getSupervisorCount();
            }
        },
        adminSupervisorKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAdminSupervisorCount();
            }
        }
    },
    methods: {
        selectAgentRemove() {
            console.log("========selectAgentRemove()======",this.migration.selectedDispatchAgent);
            if(this.migration.selectedDispatchAgent != null) {
                this.newAllAgents = this.allAgents;
                let agent = this.migration.selectedDispatchAgent;
                console.log("if======");
                this.newAllAgents = this.newAllAgents.filter(function (e) {
                return e !== agent
                })
            }
        },
        putAgent: function () {
            this.disabledButton = true;
            this.$v.telecaller.$touch();
            if (this.$v.telecaller.$invalid) {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            } else {
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    Account.telecallerAndUserRoleCreate(this.telecaller)
                        .then((response) => {
                            this.$refs.modal.hide();
                            this.user = response.data;
                            if (this.user != null) {
                                this.getUserInfo(this.user.username);
                            } else {
                                console.log("putAgent=====error");
                            }
                            // this.getCount();
                            // this.getAllPageable();
                            // this.getAllAgents();
                            // this.getCounsellorCount();
                            // this.getAllCounsellorPageable();
                            resolve(response);
                        })
                        .catch((err) => {
                            if (err.response.status == 409) {
                                // console.log("if=========");
                                this.$swal({
                                    title: "username or email already exist!",
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            reject(err);
                        });
                });
            }
        },
        getUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        this.user = response.data;
                        this.agentCreate(this.user);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentCreate: function (user) {
            let agent = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.username,
                email: user.email[0],
            }
            return new Promise((resolve, reject) => {
                AgentService.teamAgentCreate(agent)
                    .then((response) => {
                        this.$swal({
                            title: "Account Created",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        this.disabledButton = false;
                        this.resetTelecaller();
                        this.getCount();
                        this.getAllPageable();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getAllAgents: function () {
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //         AgentService.getAllAdminAgents()
        //             .then((response) => {
        //                 this.agents = response.data;
        //                 // this.agent.forEach(e => {
        //                 //   console.log(e);
        //                 // })
        //                 resolve(response);
        //                 this.loading = false;
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllCounsellors: function () {
        //    this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AgentService.getAllAdminAgents()
        //       .then((response) => {
        //         this.agents = response.data;
        //         // this.agent.forEach(e => {
        //         //   console.log(e);
        //         // })
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);

        //       });
        //   });
        // },

        deleteAgent: function (agentId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            AgentService.deleteAgent(agentId).then((response) => {
                                this.$swal("Deleted!", "Agent has been deleted.", "success");
                                // this.getAllAgents();
                                this.getCount();
                                this.getAllPageable();
                                this.getAllCounsellorPageable();
                                resolve(response);
                            });
                        }
                    })

                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAgent: function (agent) {
            // console.log("Agent : ", agent)
            this.resetForm();
            this.getAllBranches();
            this.agent = agent;
        },
        async viewAdminSupervisor(agent) {
            // await this.getAllBranches();
            this.agent = agent;
            this.$refs.viewAgent.show();
        },
        async updateAdminSupervisorModal(agent) {
            await this.getAllBranches();
            await this.resetAdminSupervisorForm();
            this.agent = agent;
            for (var i = 0; i < agent.branches.length; i++) {
                for (var j = 0; j < this.allBranches.length; j++) {
                    if (agent.branches[i].branchId == this.allBranches[j].branchId) {
                        // console.log("=======if");
                        this.allBranches.splice([j], 1);
                    }
                }
            }
            this.agent.agentId = agent.agentId;
            this.agent.name = agent.name;
            this.agent.contact = agent.contact;
            this.agent.email = agent.email;
            this.branches = agent.branches;
            this.$refs.updateAgentModal.show();
        },
        getCounsellor: function (agent) {
            // console.log("Agent : ", agent)
            this.resetForm();
            this.agent = agent;
        },
        updateAgent: function () {
            this.$v.$touch();
            if (!this.$v.agent.name.$invalid && !this.$v.agent.contact.$invalid && !this.$v.agent.email.$invalid) {
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    AgentService.updateAgent(this.agent)
                        .then((response) => {
                            this.$swal("Agent details updated successfully!!!!!");
                            // this.$refs.modal.hide();
                            this.$refs.updateAgentModal.hide();
                            if (this.archiveAgentActivate) {
                                this.getArchiveAgentCount();
                            } else if (this.supervisorActivate) {
                                this.getSupervisorCount();
                            } else if (this.adminSupervisorActivate) {
                                this.getAdminSupervisorCount();
                            } else if (this.counsellorsActive) {
                                this.getCounsellorCount();
                            } else if (this.agentsActive) {
                                this.getCount();
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.submitstatus = "FAIL";
            }
        },
        adminSupervisorUpdate: function () {
            this.$v.$touch();
            if (!this.$v.agent.name.$invalid && !this.$v.agent.contact.$invalid && !this.$v.agent.email.$invalid && !this.$v.branches.$invalid) {
                let data = {
                    agentId: this.agent.agentId,
                    name: this.agent.name,
                    contact: this.agent.contact,
                    email: this.agent.email,
                    branches: this.branches,
                }
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    axios.post("/agent/admin/supervisor/update", data)
                        .then((response) => {
                            this.$swal("Admin Supervisor updated successfully!!!!!");
                            this.$refs.updateAgentModal.hide();
                            this.getAdminSupervisorCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                console.log("else======= ");
                this.submitstatus = "FAIL";
            }
        },
        supervisorUpdate: function () {
            this.$v.$touch();
            if (!this.$v.agent.name.$invalid && !this.$v.agent.contact.$invalid && !this.$v.agent.email.$invalid) {
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    AgentService.updateSupervisor(this.agent)
                        // axios.post("/agent/supervisor/update", data)
                        .then((response) => {
                            this.$swal("Supervisor details updated successfully!!!!!");
                            // this.$refs.modal.hide();
                            this.$refs.updateAgentModal.hide();
                            this.getSupervisorCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                console.log("else======= ");
                this.submitstatus = "FAIL";
            }
        },
        counsellorUpdate: function () {
            this.$v.$touch();
            if (!this.$v.agent.name.$invalid && !this.$v.agent.contact.$invalid && !this.$v.agent.email.$invalid) {
                this.submitstatus = "SUCCESS";
                return new Promise((resolve, reject) => {
                    AgentService.updateCounsellor(this.agent)
                        // axios.post("/agent/supervisor/update", data)
                        .then((response) => {
                            this.$swal("Counsellor details updated successfully!!!!!");
                            // this.$refs.modal.hide();
                            this.$refs.updateAgentModal.hide();
                            this.getSupervisorCount();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                console.log("else======= ");
                this.submitstatus = "FAIL";
            }
        },
        removeBranches: function (item) {
            const langIndex = this.branches.findIndex(x => x === item);
            if (langIndex !== undefined)
                this.branches.splice(langIndex, 1);
            this.allBranches.push(item);
        },
        saveBranches: function () {
            if (!this.branches.some(entry => entry === this.selectedBranch)) {
                for (var i = 0; i < this.allBranches.length; i++) {
                    if (this.allBranches[i] == this.selectedBranch) {
                        this.branches.push(this.allBranches[i]);
                        this.allBranches.splice([i], 1);
                    }
                }
            }
            this.selectedBranch = null;
        },
        agentArchiveUpdate: function (id) {
            return new Promise((resolve, reject) => {
                AgentService.archive(id)
                    .then((response) => {
                        this.$swal("Archive successfully!!!!!");
                        this.getUserToken(response.data);
                        if (this.archiveAgentActivate) {
                            this.getArchiveAgentCount();
                        } else if (this.supervisorActivate) {
                            this.getSupervisorCount();
                        } else if (this.adminSupervisorActivate) {
                            this.getAdminSupervisorCount();
                        } else if (this.counsellorsActive) {
                            this.getCounsellorCount();
                        } else if (this.agentsActive) {
                            this.getCount();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserToken: function (user) {
            console.log("getUserToken :", user);
        },
        agentUnarchiveUpdate: function (id) {
            return new Promise((resolve, reject) => {
                AgentService.unarchive(id)
                    .then((response) => {
                        this.$swal("UnArchive successfully!!!!!");
                        if (this.archiveAgentActivate) {
                            this.getArchiveAgentCount();
                        } else if (this.supervisorActivate) {
                            this.getSupervisorCount();
                        } else if (this.counsellorsActive) {
                            this.getCounsellorCount();
                        } else if (this.agentsActive) {
                            this.getCount();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        removeCounsellorRole: function () {
            let data = {
                roleName: "ROLE_COUNSELLOR",
                userId: this.agent.userId
            }
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to deactivate the counsellor",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Deactivate!'
            }).then((result) => {
                if (result.isConfirmed) {
                    return new Promise((resolve, reject) => {
                        Account.removeUserRoles(data)
                            .then((response) => {
                                console.log("response.data====", response.data);
                                this.deactivateCounsellor();
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                }
            })
        },
        deactivateCounsellor: function () {
            let data = {
                agentId: this.agent.agentId
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("agent/counsellor/deactivate", data)
                    .then((response) => {
                        Swal.fire(
                            'Deactivated!',
                            'This Counsellor has been deactivated.',
                            'success'
                        )
                        this.$refs.updateAgentModal.hide();
                        this.resetForm();
                        // this.getAllAgents();
                        this.getCounsellorCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deactivateSupervisorRole: function () {
            let data = {
                roleName: "ROLE_SUPERVISOR",
                userId: this.agent.userId
            }
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to deactivate the supervisor",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Deactivate!'
            }).then((result) => {
                if (result.isConfirmed) {
                    return new Promise((resolve, reject) => {
                        Account.removeUserRoles(data)
                            .then((response) => {
                                console.log("response.data====", response.data);
                                this.deactivateSupervisor();
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                }
            })
        },
        deactivateSupervisor: function () {
            let data = {
                agentId: this.agent.agentId
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("agent/supervisor/deactivate", data)
                    .then((response) => {
                        Swal.fire(
                            'Deactivated!',
                            'This Supervisor has been deactivated.',
                            'success'
                        )
                        this.$refs.updateAgentModal.hide();
                        this.resetForm();
                        // this.getAllAgents();
                        this.getSupervisorCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.paginationAgents = true;
            this.searchPaginationAgents = false;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AgentService.getCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function () {
            // console.log("currentPage",this.currentPage)
            //       console.log("pageCount",this.pageCount)

            return new Promise((resolve, reject) => {
                AgentService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        // console.log("Response",response.data)
                        this.agents = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorCount: function () {
            this.loading = true;
            this.paginationCounsellors = true;
            this.searchPaginationCounsellors = false;
            this.counsellorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AgentService.getCounsellorCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCounsellorCount = response.data;
                        this.getAllCounsellorPageable(
                            this.counsellorCurrentPage,
                            this.counsellorPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorPageable: function () {
            // console.log("currentPage",this.currentPage)
            //       console.log("pageCount",this.pageCount)

            return new Promise((resolve, reject) => {
                AgentService.getAllCounsellorPageable(
                        this.counsellorCurrentPage,
                        this.counsellorPageCount
                    )
                    // console.log(this.counsellorCurrentPage, this.counsellorPageCount)
                    .then((response) => {
                        this.counsellors = response.data;
                        // console.log("Response",response.data)
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSupervisorCount: function () {
            this.loading = true;
            this.paginationSupervisor = true;
            this.searchPaginationSupervisor = false;
            this.supervisorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get('agent/get/supervisor/count')
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalSupervisorCount = response.data;
                        this.getAllSupervisorPageable(
                            this.supervisorCurrentPage,
                            this.supervisorPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSupervisorPageable: function () {
            return new Promise((resolve, reject) => {
                axios.post('/agent/supervisor/all/' + (this.supervisorCurrentPage - 1) + "/" + this.supervisorPageCount)
                    .then((response) => {
                        this.supervisors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAdminSupervisorCount: function () {
            this.loading = true;
            this.paginationAdminSupervisor = true;
            this.searchPaginationAdminSupervisor = false;
            this.adminSupervisorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get('agent/get/admin/supervisor/count')
                    .then((response) => {
                        this.totalAdminSupervisorCount = response.data;
                        this.getAllAdminSupervisorPageable(
                            this.adminSupervisorCurrentPage,
                            this.adminSupervisorPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAdminSupervisorPageable: function () {
            return new Promise((resolve, reject) => {
                axios.post('/agent/admin/supervisor/getAll/' + (this.adminSupervisorCurrentPage - 1) + "/" + this.adminSupervisorPageCount)
                    .then((response) => {
                        this.adminSupervisors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getArchiveAgentCount: function () {
            this.loading = true;
            this.paginationArchiveAgent = true;
            this.searchPaginationArchiveAgent = false;
            return new Promise((resolve, reject) => {
                axios.get('agent/archive/get/count')
                    .then((response) => {
                        this.totalArchiveAgentsCount = response.data;
                        this.getAllArchiveAgentPageable(
                            this.archiveAgentCurrentPage,
                            this.archiveAgentPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchiveAgentPageable: function () {
            return new Promise((resolve, reject) => {
                axios.post('/agent/archive/getAll/' + (this.archiveAgentCurrentPage - 1) + "/" + this.archiveAgentPageCount)
                    .then((response) => {
                        this.archiveAgents = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            this.allAgents.splice(0);
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            this.allBranches.splice(0);
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            // this.loading = true;
            // this.getAllPageable(pageCount);
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationAgents) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPaginationAgents) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            }
        },
        counsellorSwitchPage: function (
            counsellorCurrentPage,
            counsellorPageCount
        ) {
            // this.loading = true;
            // this.getAllCounsellorPageable(counsellorPageCount)
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationCounsellors) {
                this.getAllCounsellorPageable(
                    counsellorCurrentPage,
                    counsellorPageCount
                );
            } else if (this.searchPaginationCounsellors) {
                this.getAllCounsellorSearchPageable(
                    counsellorCurrentPage,
                    counsellorPageCount,
                    this.keyword
                );
            }
        },
        supervisorSwitchPage: function (
            supervisorCurrentPage,
            supervisorPageCount
        ) {
            // this.loading = true;
            // this.getAllCounsellorPageable(counsellorPageCount)
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationSupervisor) {
                this.getAllSupervisorPageable(
                    supervisorCurrentPage,
                    supervisorPageCount
                );
            } else if (this.searchPaginationSupervisor) {
                this.getAllSupervisorSearchPageable(
                    supervisorCurrentPage,
                    supervisorPageCount,
                    this.supervisorKeyword
                );
            }
        },
        adminSupervisorSwitchPage: function (
            adminSupervisorCurrentPage,
            adminSupervisorPageCount
        ) {
            // this.loading = true;
            // this.getAllCounsellorPageable(counsellorPageCount)
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationAdminSupervisor) {
                this.getAllAdminSupervisorPageable(
                    adminSupervisorCurrentPage,
                    adminSupervisorPageCount
                );
            } else if (this.searchPaginationAdminSupervisor) {
                this.getAllAdminSupervisorSearchPageable(
                    adminSupervisorCurrentPage,
                    adminSupervisorPageCount,
                    this.adminSupervisorKeyword
                );
            }
        },
        archiveAgentSwitchPage: function (
            archiveAgentCurrentPage,
            archiveAgentPageCount
        ) {
            // this.loading = true;
            // this.getAllCounsellorPageable(counsellorPageCount)
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationArchiveAgent) {
                this.getAllArchiveAgentPageable(
                    archiveAgentCurrentPage,
                    archiveAgentPageCount
                );
            } else if (this.searchPaginationArchiveAgent) {
                this.getAllArchiveAgentSearchPageable(
                    archiveAgentCurrentPage,
                    archiveAgentPageCount,
                    this.archiveAgentKeyword
                );
            }
        },
        getSearchCount: function (keyword) {
            this.paginationAgents = false;
            this.searchPaginationAgents = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AgentService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                AgentService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        //   this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.agents = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorSearchCount: function (keyword) {
            this.paginationCounsellors = false;
            this.searchPaginationCounsellors = true;
            this.counsellorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AgentService.getCounsellorSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCounsellorCount = response.data;
                        console.log("Count", response.data);
                        this.getAllCounsellorSearchPageable(
                            this.counsellorCurrentPage,
                            this.counsellorPageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorSearchPageable: function (
            counsellorCurrentPage,
            counsellorPageCount,
            keyword
        ) {
            return new Promise((resolve, reject) => {
                AgentService.getAllCounsellorSearchPageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        //   this.agentId,
                        keyword.toLowerCase(),
                    )
                    .then((response) => {
                        this.counsellors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getSupervisorSearchCount: function (supervisorKeyword) {
            this.paginationSupervisor = false;
            this.searchPaginationSupervisor = true;
            this.supervisorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get("agent/supervisor/search/count/" + supervisorKeyword.toLowerCase())
                    .then((response) => {
                        this.totalSupervisorCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSupervisorSearchPageable(
                            this.supervisorCurrentPage,
                            this.supervisorPageCount,
                            supervisorKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSupervisorSearchPageable: function (
            supervisorCurrentPage,
            supervisorPageCount,
            supervisorKeyword
        ) {
            return new Promise((resolve, reject) => {
                axios.post("agent/supervisor/search/" + supervisorKeyword.toLowerCase() + "/" + (supervisorCurrentPage - 1) + "/" + supervisorPageCount)
                    .then((response) => {
                        this.supervisors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAdminSupervisorSearchCount: function (adminSupervisorKeyword) {
            this.paginationAdminSupervisor = false;
            this.searchPaginationAdminSupervisor = true;
            this.adminSupervisorCurrentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get("agent/admin/supervisor/search/count/" + adminSupervisorKeyword.toLowerCase())
                    .then((response) => {
                        this.totalAdminSupervisorCount = response.data;
                        this.getAllAdminSupervisorSearchPageable(
                            this.adminSupervisorCurrentPage,
                            this.adminSupervisorPageCount,
                            adminSupervisorKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAdminSupervisorSearchPageable: function (
            adminSupervisorCurrentPage,
            adminSupervisorPageCount,
            adminSupervisorKeyword
        ) {
            return new Promise((resolve, reject) => {
                axios.post("agent/admin/supervisor/search/" + adminSupervisorKeyword.toLowerCase() + "/" + (adminSupervisorCurrentPage - 1) + "/" + adminSupervisorPageCount)
                    .then((response) => {
                        this.adminSupervisors = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getArchiveAgentSearchCount: function (archiveAgentKeyword) {
            this.paginationArchiveAgent = false;
            this.searchPaginationArchiveAgent = true;
            this.archiveAgentCurrentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get("agent/archive/search/count/" + archiveAgentKeyword.toLowerCase())
                    .then((response) => {
                        this.totalArchiveAgentCount = response.data;
                        this.getAllArchiveAgentSearchPageable(
                            this.archiveAgentCurrentPage,
                            this.archiveAgentPageCount,
                            archiveAgentKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchiveAgentSearchPageable: function (
            archiveAgentCurrentPage,
            archiveAgentPageCount,
            archiveAgentKeyword
        ) {
            return new Promise((resolve, reject) => {
                axios.post("agent/archive/search/getAll/" + archiveAgentKeyword.toLowerCase() + "/" + (archiveAgentCurrentPage - 1) + "/" + archiveAgentPageCount)
                    .then((response) => {
                        this.archiveAgents = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        agentMigrate: function () {
            console.log("agentMigrate========= ", this.migration);
            this.$v.migration.$touch();
            if (!this.$v.migration.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    let data = {
                        dispatchAgentId: this.migration.selectedDispatchAgent.agentId,
                        agentId: this.migration.selectedAgent.agentId
                    }
                    this.userId = this.migration.selectedDispatchAgent.userId;
                    axios.post("agent/migrate", data)
                        .then((response) => {
                            this.userLogoutProcess();
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        async userLogoutProcess() {
            console.log("===userLogoutProcess===", this.userId);
            await this.getUserByUserId();
            await this.getUserAccessToken();
            await this.userTokenRevoke();
        },
        getUserByUserId: function () {
            return new Promise((resolve, reject) => {
                Account.getUserByUserId(this.userId)
                    .then((response) => {
                        this.user = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getUserAccessToken: function () {
            return new Promise((resolve, reject) => {
                Account.getUserAccessToken(this.user.username)
                    .then((response) => {
                        console.log("===getUserAccessToken---", response.data);
                        this.token = response.data[0];
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        userTokenRevoke: function () {
            return new Promise((resolve, reject) => {
                Account.userTokenRevoke(this.token)
                    .then((response) => {
                        this.loading = false;
                        this.$refs.agentMigrationModal.hide();
                        this.disabledButton = false;
                        this.$swal({
                            title: "Agent Migrate",
                            text: "Agent followups migrated successfully",
                            icon: "success",
                            button: "ok",
                        });
                        this.resetMigration();
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        resetMigration() {
            this.disabledButton = false;
            this.migration = {
                selectedDispatchAgent: null,
                selectedAgent: null
            }
            this.$nextTick(() => {
                this.$v.migration.$reset();
            });
        },
        refresh() {
            this.getCount();
            this.keyword = "";
        },
        refreshCounsellor() {
            this.getCounsellorCount();
            this.keyword = "";
        },
        refreshSupervisor() {
            this.getSupervisorCount();
            this.supervisorKeyword = "";
        },
        refreshAdminSupervisor() {
            if (this.adminSupervisorKeyword == "") {
                this.getAdminSupervisorCount();
            }
            this.adminSupervisorKeyword = "";
        },
        refreshArchiveAgent() {
            this.archiveAgentKeyword = "";
            this.getArchiveAgentCount();
            this.archiveAgentCurrentPage = 1;
        },
        showAgents() {
            this.agentsActive = true;
            this.counsellorsActive = false;
            this.supervisorActivate = false;
            this.adminSupervisorActivate = false;
            this.archiveAgentActivate = false;
            this.getCount();
            // this.keyword = "";
        },
        showCounsellors() {
            this.agentsActive = false;
            this.counsellorsActive = true;
            this.supervisorActivate = false;
            this.adminSupervisorActivate = false;
            this.archiveAgentActivate = false;
            this.getCounsellorCount();
            // this.keyword = "";
        },
        showSupervisors() {
            this.agentsActive = false;
            this.counsellorsActive = false;
            this.supervisorActivate = true;
            this.adminSupervisorActivate = false;
            this.archiveAgentActivate = false;
            this.supervisorCurrentPage = 1;
            if (this.supervisorKeyword == "") {
                this.getSupervisorCount();
            }
            this.supervisorKeyword = "";
        },
        showAdminSupervisors() {
            this.agentsActive = false;
            this.counsellorsActive = false;
            this.supervisorActivate = false;
            this.adminSupervisorActivate = true;
            this.archiveAgentActivate = false;
            this.adminSupervisorCurrentPage = 1;
            if (this.adminSupervisorKeyword == "") {
                this.getAdminSupervisorCount();
            }
            this.adminSupervisorKeyword = "";
        },
        agentMigrationShow() {
            this.resetMigration();
            this.getAllAgents();
            this.$refs.agentMigrationModal.show();
        },
        showArchiveAgent() {
            this.agentsActive = false;
            this.counsellorsActive = false;
            this.supervisorActivate = false;
            this.adminSupervisorActivate = false;
            this.archiveAgentActivate = true;
            this.archiveAgentCurrentPage = 1;
            if (this.archiveAgentKeyword == "") {
                this.getArchiveAgentCount();
            }
            this.archiveAgentKeyword = "";
        },
        // activateCounsellorRole: function() {
        //     let data = {
        //         roleName: "ROLE_COUNSELLOR",
        //         userId: this.agent.userId
        //     }
        //     return new Promise((resolve, reject) => {
        //         Account.addUserRoles(data)
        //             .then((response) => {
        //                 console.log("response.data====", response.data);
        //                 this.activateCounsellor();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // activateCounsellor: function () {
        //     let data = {
        //         branch: this.agent.branch,
        //         agentId: this.agent.agentId,
        //     };
        //     // this.$v.agent.$touch();
        //     // if (this.$v.agent.$invalid) {
        //     //     this.submitStatus = "FAIL";
        //     //     // this.resetForm();
        //     //     // alert("insert submit failed");
        //     // } else {
        //         this.disabledButton = true;
        //         return new Promise((resolve, reject) => {
        //             axios
        //                 .post("agent/counsellor/activate", data)
        //                 .then((response) => {
        //                     this.$swal("Activate As Counsellor successfully!!!!!");
        //                     this.resetForm();
        //                     this.$refs.updateAgentModal.hide();
        //                     this.$refs.modal.hide();
        //                     this.getCount();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     // }
        // },
        // activateSupervisorRole: function () {
        //     let data = {
        //         roleName: "ROLE_SUPERVISOR",
        //         userId: this.agent.userId
        //     }
        //     return new Promise((resolve, reject) => {
        //         Account.addUserRoles(data)
        //             .then((response) => {
        //                 console.log("response.data====", response.data);
        //                 this.activateSupervisor();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // activateSupervisor: function () {
        //     console.log("=======activateSupervisor=======");
        //     let data = {
        //         branch: this.agent.branch,
        //         agentId: this.agent.agentId,
        //     };
        //     // this.$v.agent.$touch();
        //     // if (this.$v.agent.$invalid) {
        //     //     this.submitStatus = "FAIL";
        //     //     // this.resetForm();
        //     //     // alert("insert submit failed");
        //     // } else {
        //     this.disabledButton = true;
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post("agent/supervisor/activate", data)
        //             .then((response) => {
        //                 this.$swal("Activate As Supervisor successfully!!!!!");
        //                 this.$refs.updateAgentModal.hide();
        //                 this.$refs.activateSupervisor.hide();
        //                 this.resetForm();
        //                 // this.getAllAgents();
        //                 this.getCount();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        //     // }
        // },
        // activateAdminSupervisorRole: function () {
        //     let data = {
        //         roleName: "ROLE_ADMIN_SUPERVISOR",
        //         userId: this.agent.userId
        //     }
        //     return new Promise((resolve, reject) => {
        //         Account.addUserRoles(data)
        //             .then((response) => {
        //                 console.log("response.data====", response.data);
        //                 this.activateAdminSupervisor();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // activateAdminSupervisor: function () {
        //     let data = {
        //         agentId: this.agent.agentId,
        //         branches: this.branches,
        //     };
        //     // this.$v.branches.$touch();
        //     // if (!this.$v.branches.$invalid) {
        //         this.disabledButton = true;
        //         return new Promise((resolve, reject) => {
        //             axios
        //                 .post("agent/admin/supervisor/activate", data)
        //                 .then((response) => {
        //                     this.$swal("Activate Admin Supervisor successfully!!!!!");
        //                     this.$refs.modal.hide();
        //                     this.$refs.updateAgentModal.hide();
        //                     this.resetAdminSupervisorForm();
        //                     // this.getAllAgents();
        //                     this.getCount();
        //                     this.disabledButton = false;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     // } else {
        //     //     this.submitStatus = "FAIL";
        //     // }
        // },
        // removeAdminSupervisorRole: function () {
        //     let data = {
        //         roleName: "ROLE_ADMIN_SUPERVISOR",
        //         userId: this.agent.userId
        //     }
        //     return new Promise((resolve, reject) => {
        //         Account.removeUserRoles(data)
        //             .then((response) => {
        //                 console.log("response.data====", response.data);
        //                 this.deactivateAdminSupervisor();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // deactivateAdminSupervisor: function () {
        //     let data = {
        //         agentId: this.agent.agentId,
        //     };
        //     // this.$v.branches.$touch();
        //     // if (!this.$v.branches.$invalid) {
        //     this.disabledButton = true;
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post("agent/admin/supervisor/deactivate", data)
        //             .then((response) => {
        //                 this.$swal("Deactivate Admin Supervisor successfully!!!!!");
        //                 this.resetAdminSupervisorForm();
        //                 this.$refs.updateAgentModal.hide();
        //                 // this.getAllAgents();
        //                 this.getAdminSupervisorCount();
        //                 this.disabledButton = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        //     // } else {
        //     //     this.submitStatus = "FAIL";
        //     // }
        // },
        // updateCounsellor: function () {
        //       return new Promise((resolve, reject) => {
        //         AgentService.updateCounsellor()
        //           .then((response) => {
        //             this.$swal("Counsellor details updated successfully!!!!!");
        //               this.$refs.modal.hide();
        //               this.getAllAgents();
        //             resolve(response);
        //           })
        //           .catch((err) => {
        //             reject(err);
        //           });
        //       });
        //     },
        cancel() {
            this.$refs.modal.hide();
        },
        resetForm() {
            this.agent = {
                name: "",
                contact: "",
                email: "",
                branch: null,
            };
            if (this.adminSupervisorActivate) {
                this.selectedBranch = null;
                this.branches.splice(0);
            }
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        resetTelecaller() {
            this.telecaller = {
                    firstName: "",
                    lastName: "",
                    username: "",
                    contact: "",
                },
                this.$nextTick(() => {
                    this.$v.telecaller.$reset();
                });
        },
        resetAdminSupervisorForm() {
            this.selectedBranch = null;
            this.branches.splice(0);
            this.$nextTick(() => {
                this.$v.$reset();
            });
            this.$refs.activateAdminSupervisorModal.hide();
        },
        resetBranch() {
            this.agent.branch = null
        },
        async resetBranches() {
            await this.getAllBranches();
            this.$refs.activateAdminSupervisorModal.show();
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
