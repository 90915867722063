import axios from 'axios';
export default{
    getAllFollowups: function(agentId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/getAll/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/count/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    saveAllCustomFields(data) {
       
        return new Promise((resolve, reject) => {
            axios.post("/followup/template/create",data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getFollowupFields() {
       
        return new Promise((resolve, reject) => {
            axios.get("/followup/template/get/current")
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getFollowupDateRemark : function(callId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/get/followups/'+ callId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getFollowupCreatedDate : function(callId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/get/followupDate/'+ callId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    search: function(keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/search/'+ keyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchCount: function(keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/search/count/"+keyword+"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/search/all/" + (currentPage-1) + "/" + pageCount +"/"+ keyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortCourse: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/course/" + agentId, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getSortCourseCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/course/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortCourseAllPageable: function(currentPage,pageCount,agentId,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/course/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortSource: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/source/" + agentId, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getSortSourceCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/source/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortSourceAllPageable: function(currentPage,pageCount,agentId,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/source/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortBranch: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/branch/" + agentId, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getSortBranchCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/branch/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortBranchAllPageable: function(currentPage,pageCount,agentId,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/branch/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortDate: function(agentId,selectDate){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/date/" + agentId+"/"+selectDate)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getSortDateCount: function(agentId,selectDate){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/date/count/"+ agentId+"/"+selectDate)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortDateAllPageable: function(currentPage,pageCount,agentId,selectDate){
        return new Promise((resolve, reject) => {
            axios.post("/followups/sort/date/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId+"/"+selectDate)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
///expired followups
    getAllExpiredFollowups: function(agentId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/expired/getAll/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getExpiredFollowupsCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/count/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getExpiredFollowupsPageable: function(currentPage,pageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/all/" + (currentPage-1) + "/" + pageCount +"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredFollowupsSearch: function(expiredSearchKeyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/expired/search/'+ expiredSearchKeyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getExpiredSearchCount: function(keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/search/count/"+keyword+"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllExpiredSearchPageable: function(currentPage,pageCount,keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/search/all/" + (currentPage-1) + "/" + pageCount +"/"+ keyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortCourse: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/course/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortCourseCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/course/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortCourseAllPageable: function(expiredCurrentPage,expiredPageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/course/all/" + (expiredCurrentPage-1) + "/" + expiredPageCount +"/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortSource: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/source/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortSourceCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/source/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortSourceAllPageable: function(expiredCurrentPage,expiredPageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/source/all/" + (expiredCurrentPage-1) + "/" + expiredPageCount +"/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortBranch: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/branch/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortBranchCount: function(agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/branch/count/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredSortBranchAllPageable: function(expiredCurrentPage,expiredPageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/expired/sort/branch/all/" + (expiredCurrentPage-1) + "/" + expiredPageCount +"/"+ agentId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
     // ==========================Counsellor================================
     getCounsellorFollowupCount: function(data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/count" ,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },   
    getAllCounsellorPageable: function(currentPage,pageCount,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/all/" + (currentPage-1) + "/" + pageCount ,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllClosedFollowupCounsellorCount: function(data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/closed/counsellor/count" ,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },   
    getAllClosedFollowupCounsellorPageable: function(currentPage,pageCount,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/closed/counsellor/all/" + (currentPage-1) + "/" + pageCount ,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getEnquiryFollowupDateRemark : function(enquiryId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/get/enquiryFollowups/'+ enquiryId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getEnquiryFollowupCreatedDateRemark : function(enquiryId){
        return new Promise((resolve, reject) => {
            axios.post('/followups/get/enquiryFollowupDate/'+ enquiryId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    enquiryCounsellorFollowupUpdate: function(agentId,enquiryId,enquiryFollowup){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/followup/update/"+agentId+"/"+ enquiryId, enquiryFollowup)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getCounsellorSearchCount: function(keyword,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/search/count/"+keyword,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorSearchPageable: function(currentPage,pageCount,keyword,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/search/all/" + (currentPage-1) + "/" + pageCount + "/" + keyword ,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSortSourceCount: function(branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/sort/source/count/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortCounsellorSourceAllPageable: function(currentPage,pageCount,branchId,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/sort/source/all/" + (currentPage-1) + "/" + pageCount +"/"+ branchId,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSortCourseCount: function(branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/sort/course/count/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    sortCounsellorCourseAllPageable: function(currentPage,pageCount,branchId,pageableData){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/sort/course/all/" + (currentPage-1) + "/" + pageCount +"/"+ branchId,pageableData)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorExpiredSearchCount: function(keyword,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/search/count/"+keyword,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorExpiredSearchPageable: function(currentPage,pageCount,keyword,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/search/all/" + (currentPage-1) + "/" + pageCount +"/"+ keyword ,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },   
    expiredCounsellorSortCourseCount: function(branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/sort/course/count/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredCounsellorSortCourseAllPageable: function(expiredCurrentPage,expiredPageCount,branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/sort/course/all/" + (expiredCurrentPage-1) + "/" + expiredPageCount +"/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredCounsellorSortSourceCount: function(branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/sort/source/count/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    expiredCounsellorSortSourceAllPageable: function(expiredCurrentPage,expiredPageCount,branchId,data){
        return new Promise((resolve, reject) => {
            axios.post("/followups/counsellor/expired/sort/source/all/" + (expiredCurrentPage-1) + "/" + expiredPageCount +"/"+ branchId,data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
}
