<template>
<div>
    <!-- <h6>PasswordChange</h6> -->

    <div>
        <!-- <b-button v-b-modal.passwordChange>Change Password</b-button> -->

        <b-modal size="lg" header-bg-variant="primary" id="passwordChange" title="User Setting" ref="modal" hide-footer>
            <b-container>
                <div>
                    <div class="row">
                        <div class="col">
                            <!-- <h1>Image</h1> -->
                            <b-avatar src="" size="15rem" id="avatar"></b-avatar>
                            <h6 id="details">{{firstname}}{{lastname}}</h6>

                        </div>
                        <div class="col">
                            <!-- <b-card no-body> -->
                            <b-tabs card>
                                <b-tab title="Profile" active>
                                    <!-- <div class="form-control"> -->
                                    <div class="form-row mt-3 px-5">
                                        <label>First Name:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>First Name</label> -->
                                            <b-form-input id="input" type="text" v-model="firstname" class="form-control" disabled></b-form-input>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="form-row mt-3 px-5">
                                        <label>Last Name:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>Last Name:</label> -->
                                            <b-form-input type="text" v-model="lastname" class="form-control" disabled></b-form-input>
                                        </div>
                                    </div>
                                    <br />
                                    <!-- </div> -->
                                </b-tab>
                                <b-tab title="Security  ">
                                    <!-- <div class="form-control"> -->
                                    <div class="form-row mt-3 px-5">
                                        <label>User Name:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>User Name</label> -->
                                            <b-form-input type="text" v-model="username" class="form-control" disabled></b-form-input>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <label>Old Password:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>Old Password:</label> -->
                                            <b-form-input type="password" class="form-control" v-model.trim="$v.user.oldPassword.$model" :class="{
                        'is-invalid': $v.user.oldPassword.$error,
                        'is-valid': !$v.user.oldPassword.$invalid,
                      }">
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <label>New Password:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>New Password:</label> -->
                                            <b-form-input type="password" class="form-control" v-model.trim="$v.user.newPassword.$model" :class="{
                        'is-invalid': $v.user.newPassword.$error,
                        'is-valid': !$v.user.newPassword.$invalid,
                      }">
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <label>Confirm Password:</label>
                                        <div class="section form-group my-2">
                                            <!-- <label>Confirm Password:</label> -->
                                            <b-form-input type="password" class="form-control" v-model.trim="$v.user.confirmPassword.$model" :class="{
                        'is-invalid': $v.user.confirmPassword.$error,
                        'is-valid': !$v.user.confirmPassword.$invalid,
                      }">
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="text-center mt-3 mt-2">
                                        <b-button pill type="submit" @click="changePassword(user)" class="" variant="outline-primary">Submit</b-button>
                                        <b-button pill type="submit" @click="resetForm()" class="mx-3" variant="outline-danger">Reset</b-button>
                                    </div>
                                    <br>
                                    <!-- </div> -->
                                </b-tab>
                            </b-tabs>
                            <!-- </b-card> -->
                        </div>
                    </div>
                </div>
            </b-container>
        </b-modal>
    </div>
</div>
</template>

<script>
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import Account from '../service/Account.js';
// import axios from "axios";

export default {
    name: "PasswordChange",
    components: {},
    data() {
        return {
            loading: false,
            currentUserId: null,
            // user: {
            // userId: "",
            firstname: "",
            lastname: "",
            username: "",
            password: "",
            passcode: "",
            // },

            user: {
                oldPassword: '',
                newPassword: "",
                confirmPassword: "",
                token: ""
            },
            data: "",
            users: [],
            submitstatus: "",
        };
    },
    validations: {
        user: {
            oldPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(16),
            },
            newPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(16),
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(16),
            },
        },
    },
    computed: {
        Required() {
            return [(v) => !!v || '']
        }
    },

    async mounted() {
        if (localStorage.getItem("current_user") != null)
            // Account.getAccessToken(localStorage.getItem("refreshToken"))
            // this.global()
            this.$nextTick(() => {
                this.firstname = currentUser.firstName;
                this.lastname = currentUser.lastName;
                this.username = currentUser.username;
                this.userId = currentUser.id;
            });

        var currentUser = JSON.parse(localStorage.getItem("current_user"));
    },

    methods: {

        changePassword: function (user) {

            this.$v.user.$touch();
            if (this.$v.user.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal({
                    title: "Please Enter Valid Password!!!",
                    icon: "error",
                    button: "ok",
                });
                // this.resetForm();
                // alert("Password Submit Failed");
            } else {
            // var ax = axios.create({
            //     baseURL: "http://localhost:9088",
            // });
            if (user.newPassword != user.confirmPassword) {

                this.$swal({
                    title: "Password Not Match!",
                    icon: "error",
                    button: "ok",
                });

                // console.log("newPassword", user.newPassword);
                // console.log("confirmPassword", user.confirmPassword);
                // console.log("Password Not matched");
                // this.resetForm();
            }
            else if (user.oldPassword == user.newPassword) {

                this.$swal({
                    title: "Old Password Can't Be the New One!",
                    icon: "error",
                    button: "ok",
                });

                // console.log("newPassword", user.newPassword);
                // console.log("confirmPassword", user.confirmPassword);
                // console.log("Password Not matched");
                // this.resetForm();
            } else {
                // console.log("Password matched ");
                this.data = {
                    userId: this.userId,
                    oldPassword: user.oldPassword,
                    newPassword: user.newPassword,
                };
                // let userId = this.userId;
                this.enable = false;

                // console.log("DATA=====", this.data)

                return new Promise((resolve, reject) => {
                    // ax.post("/setting/security/", this.data)
                    Account.resetPassword(this.data)
                        .then(response => {
                            this.$swal({
                                // title: "Good job!",
                                title: response.data,
                                // icon: "success",
                                button: "ok",
                            });
                            this.resetForm();
                            this.$refs.modal.hide();
                            resolve(response);
                        })
                        .catch(err => {
                            reject(err);
                            console.log(err);
                        });
                });
            }
        }
        },

        resetForm() {
            this.user = {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.section {
    position: relative;
    height: 45px;
    width: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.94);
    border-radius: 20px;
}

input {
    font-size: small;
    border-radius: 25px;
}

#avatar {
    margin-left: 18%;
    margin-right: 25%;
}

#details {

    /* margin-left: 45%; */
    margin-top: -15%;
    text-align: center;
}
</style>
