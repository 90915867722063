import { render, staticRenderFns } from "./AdminAdmissions.vue?vue&type=template&id=fdc255f6&scoped=true&"
import script from "./AdminAdmissions.vue?vue&type=script&lang=js&"
export * from "./AdminAdmissions.vue?vue&type=script&lang=js&"
import style0 from "./AdminAdmissions.vue?vue&type=style&index=0&id=fdc255f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc255f6",
  null
  
)

export default component.exports