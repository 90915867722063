<template>
<div>
<b-container>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- for loader end -->
    <div v-if="isRoleCounsellor == false">
        <b-container>
            <!-- <div>
                <b-row class="my-4">
                    <b-col col lg="10" style="text-align: right">
                        <b-button id="calls" size="sm" variant="outline-primary" @click="showFollowups()" v-bind:class="{ active: isFollowups }">
                            Followup</b-button>
                    </b-col>
                    <b-col col lg="2">
                        <b-button size="sm" id="calls" variant="outline-primary" @click="showExpiredFollowups()" v-bind:class="{ active: isExpiredFollowups }">Expired Followup</b-button>
                    </b-col>
                </b-row>
            </div> -->
        </b-container>
        <!-- <div v-if="isFollowups"> -->
            <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Followups" active>
            <!-- <div class="mt-5"> -->
                <div>
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="4" id="r1">
                            <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="followupsRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- for sort dropdown start-->
                <b-container>
                    <b-card class="card mt-1">
                        <b-container>
                            <b-row>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedCourse" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch
                        )
                      ">
                                        <option value="Courses" disabled>Courses</option>
                                        <option value="AllCourses">All Courses</option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedSource" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch
                        )
                      ">
                                        <option value="Sources" disabled>Sources</option>
                                        <option value="AllSources">All Sources</option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedBranch" @change="
                        getAllSortCount(
                          selectedCourse,
                          selectedSource,
                          selectedBranch
                        )
                      ">
                                        <option value="Branches" disabled>Branches</option>
                                        <option value="AllBranches">All Branches</option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <!-- <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="selectDate"
                    @change="sortDate(selectDate)"
                    :options="dateRange"
                    >>
                  </b-form-select>
                </b-col> -->
                            </b-row>
                        </b-container>
                        <!-- for sort dropdown start-->
                        <br />
                        <hr />
                        <b-table responsive bordered striped hover :items="followups" :fields="followupItems" :current-page="currentPage" :per-page="followups.pageCount">
                            <template v-slot:cell(followupDate)="data">
                                <div class="dateCell">
                                    <span v-if="expiredDate" style="color: #ff0000">
                                        {{
                        dateConvert(data.item.followupDate, "fullDate")
                      }}</span>
                                    <span v-else>{{
                      dateConvert(data.item.followupDate, "fullDate")
                    }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(type)="data">
                                {{ data.item.type }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                </div>
                                <div>
                                    {{ data.item.alternativeContact }}
                                </div>
                            </template>
                            <template v-slot:cell(course)="data">
                                {{ data.item.course.name }}
                            </template>
                            <template v-slot:cell(source)="data">
                                {{ data.item.source.name }}
                            </template>
                            <template v-slot:cell(branch)="data">
                                {{ data.item.branch.name }}
                            </template>
                            <template v-slot:cell(remark)="data">
                                {{ data.item.remark }}
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getFollowup(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getFollowup(data.item)" v-b-modal.followInsert>
                                    <font-awesome-icon icon="file-signature" />
                                </b-button>
                                <!-- <b-button
                  class="fas fa-window-close px-2"
                  variant="secondary"
                  v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                  title="Closed"
                  @click="getFollowup(data.item)"
                  v-b-modal.closed
                ></b-button> -->
            </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </div>
            </b-tab>
        <!-- </div> -->
            <b-tab title="Expired Followups">
        <!-- <div v-if="isExpiredFollowups"> -->
            <!-- <div class="mt-5"> -->
                <div>
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="4" id="r1">
                            <input type="search" class="searchbar" v-model="expiredSearchKeyword" v-on:keyup.enter="getExpiredSearchCount(expiredSearchKeyword)" placeholder="Type a name" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getExpiredSearchCount(expiredSearchKeyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="expiredCurrentPage" :total-rows="expiredTotalCount" :per-page="expiredPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    expiredFollowupsSwitchPage(
                      expiredCurrentPage,
                      expiredPageCount
                    )
                  "></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="expiredPageCount" @input="
                    expiredFollowupsSwitchPage(
                      expiredCurrentPage,
                      expiredPageCount
                    )
                  " :options="expiredPageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="expiredRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- for sort dropdown start-->
                <b-container>
                    <b-card class="card mt-1">
                        <b-container>
                            <b-row>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedExpiredCourse" @change="
                        getAllSortExpiredCount(
                          selectedExpiredCourse,
                          selectedExpiredSource,
                          selectedExpiredBranch
                        )
                      ">
                                        <option value="Courses" disabled>Courses</option>
                                        <option value="AllCourses">All Courses</option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedExpiredSource" @change="
                        getAllSortExpiredCount(
                          selectedExpiredCourse,
                          selectedExpiredSource,
                          selectedExpiredBranch
                        )
                      ">
                                        <option value="Sources" disabled>Sources</option>
                                        <option value="AllSources">All Sources</option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedExpiredBranch" @change="
                        getAllSortExpiredCount(
                          selectedExpiredCourse,
                          selectedExpiredSource,
                          selectedExpiredBranch
                        )
                      ">
                                        <option value="Branches" disabled>Branches</option>
                                        <option value="AllBranches">All Branches</option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <!-- <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="expiredSelectDate"
                    @change="expiredSortDate(expiredSelectedDate)"
                    :options="dateRange"
                    >>
                  </b-form-select>
                </b-col> -->
                            </b-row>
                        </b-container>
                        <!-- for sort dropdown start-->
                        <br />
                        <hr />
                        <b-table responsive bordered striped hover :items="expiredFollowups" :fields="followupItems" :current-page="expiredCurrentPage" :per-page="expiredFollowups.expiredPageCount">
                            <template v-slot:cell(createdAt)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(type)="data">
                                {{ data.item.type }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                </div>
                                <div>
                                    {{ data.item.alternativeContact }}
                                </div>
                            </template>
                            <template v-slot:cell(course)="data">
                                {{ data.item.course.name }}
                            </template>
                            <template v-slot:cell(source)="data">
                                {{ data.item.source.name }}
                            </template>
                            <template v-slot:cell(branch)="data">
                                {{ data.item.branch.name }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.followupDate, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(remark)="data">
                                {{ data.item.remark }}
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getFollowup(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getFollowup(data.item)" v-b-modal.followInsert>
                                    <font-awesome-icon icon="file-signature" />
                                </b-button>
                                <!-- <b-button
                  class="fas fa-window-close px-2"
                  variant="secondary"
                  v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                  title="Closed"
                  @click="getFollowup(data.item)"
                  v-b-modal.closed
                ></b-button> -->
            </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </div>
        <!-- </div> -->
        </b-tab>
            </b-tabs>
        <div>
            <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
            <!-- <b-modal ref="dateRange" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal> -->
        </div>
        <b-container>
            <!--popup start for View Call details-->
            <!-- The modal -->
            <b-modal id="viewDetail" title="View Details" no-close-on-backdrop hide-footer>
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="view.name" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input v-model="view.contact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input type="number" v-model="view.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input v-model="view.age" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-input v-model="view.qualification" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-input v-model="view.course" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-input v-model="view.source" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-input v-model="view.mode" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-input v-model="view.branch" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                        <template v-slot:cell(followupDate)="data">
                            {{ dateConvert(data.item.followupDate, "fullDate") }}
                        </template>
                        <template v-slot:cell(remark)="data">
                            {{ data.item.remark }}
                        </template>
                        <template v-slot:cell(createdAt)="data">
                            <div class="dateCell">
                                {{ dateConvert(data.item.createdAt, "fullDate") }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-modal>
            <!--popup end for View Call details-->
        </b-container>
        <b-container>
            <!-- popup start for follow insert -->
            <b-modal id="followInsert" title="Followup Update" ref="modal" no-close-on-backdrop hide-footer>
                <!-- <h5 class="px-5">Name : {{ update.name }}</h5>
          <h5 class="px-5">Contact : {{ update.contact }}</h5> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Followup Date and Remark</label>
                            <div class="py-2">
                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="followup.followupDate" :class="{
                      'is-invalid': $v.followup.followupDate.$error,
                      'is-valid': !$v.followup.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.followup.followupDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                            <div>
                                <b-form-textarea class="form-control" v-model="followup.remark" :class="{
                      'is-invalid': $v.followup.remark.$error,
                      'is-valid': !$v.followup.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                <div class="valid-feedback">remark is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.followup.remark.required">remark is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit" @click="followupUpdate()" :disabled="disabledButton" class="px-3" variant="primary">Update</b-button>
                </div>
            </b-modal>
            <!-- popup start for closed start -->
            <b-modal id="closed" title="Call Closed" ref="modal" no-close-on-backdrop hide-footer>
                <label class="mt-3">Closed for</label>
                <b-form-select class="form-control" v-model="closed" :options="closedOption" :class="{
              'is-invalid': $v.closed.$error,
              'is-valid': !$v.closed.$invalid,
            }"></b-form-select>
                <div class="mt-3 mt-2 text-center">
                    <b-button type="submit" @click="closedTo()" class="px-3" variant="primary" :disabled="disabledButton">closed</b-button>
                </div>
            </b-modal>
            <!-- popup start for closed start end -->
        </b-container>
    </div>
    <div v-else>
        <!-- counsellor -->
        <b-container>
            <!-- <div>
                <b-row class="my-4">
                    <b-col col lg="10" style="text-align: right">
                        <b-button id="calls" size="sm" variant="outline-primary" @click="showCounsellorFollowups()" v-bind:class="{ active: isCounsellorFollowups }">
                            Followup</b-button>
                    </b-col>
                    <b-col col lg="2">
                        <b-button size="sm" id="calls" variant="outline-primary" @click="showCounsellorExpiredFollowups()" v-bind:class="{ active: isCounsellorExpiredFollowups }">Expired Followup</b-button>
                    </b-col>
                </b-row>
            </div> -->
        </b-container>
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Followups" active>
        <!-- <div v-if="isCounsellorFollowups"> -->
            <!-- <div class="mt-5"> -->
                <div>
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="4" id="r1">
                            <input type="search" class="searchbar" v-model="counsellorKeyword" v-on:keyup.enter="getCounsellorSearchCount(counsellorKeyword)" placeholder="Type a name" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCounsellorSearchCount(counsellorKeyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="counsellorSwitchPage(currentPage, pageCount)"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="counsellorSwitchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="counsellorFollowupsRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- for sort dropdown start-->
                <b-container>
                    <b-card class="card mt-1">
                        <b-container>
                            <b-row>
                                <b-col col lg="6" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectCourse" @change="getCounsellorSortCourseCount(selectCourse)">
                                        <option value="courses" disabled>Courses</option>
                                        <option value="allCourses">All Courses</option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="6" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="selectSource" @change="getCounsellorSortSourceCount(selectSource)">
                                        <option value="sources" disabled>Sources</option>
                                        <option value="allSources">All Sources</option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <!-- <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="selectBranch"
                    @change="getSortBranchCount(selectBranch)"
                  >
                    <option value="branches" disabled>Branches</option>
                    <option value="allBranches">All Branches</option>
                    <option
                      v-for="item in allBranches"
                      :value="item"
                      v-bind:key="item.index"
                    >
                      <strong>{{ item.name }}</strong>
                    </option>
                  </b-form-select>
                </b-col> -->
                                <!-- <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="selectDate"
                    @change="sortDate(selectDate)"
                    :options="dateRange"
                    >>
                  </b-form-select>
                </b-col> -->
                            </b-row>
                        </b-container>
                        <!-- for sort dropdown start-->
                        <br />
                        <hr />
                        <b-table responsive bordered striped hover :items="followups" :fields="followupItems" :current-page="currentPage" :per-page="followups.pageCount">
                            <template v-slot:cell(followupDate)="data">
                                <div class="dateCell">
                                    <span v-if="expiredDate" style="color: #ff0000">
                                        {{
                        dateConvert(data.item.followupDate, "fullDate")
                      }}</span>
                                    <span v-else>{{
                      dateConvert(data.item.followupDate, "fullDate")
                    }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(type)="data">
                                {{ data.item.type }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                    }}<span v-if="
                        data.item.alternativeContact != null &&
                        data.item.alternativeContact != ''
                      ">,</span>
                                </div>
                                <div>
                                    {{ data.item.alternativeContact }}
                                </div>
                            </template>
                            <template v-slot:cell(course)="data">
                                {{ data.item.course.name }}
                            </template>
                            <template v-slot:cell(source)="data">
                                {{ data.item.source.name }}
                            </template>
                            <template v-slot:cell(branch)="data">
                                {{ data.item.branch.name }}
                            </template>
                            <template v-slot:cell(remark)="data">
                                {{ data.item.remark }}
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getEnquiryFollowup(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getEnquiryFollowup(data.item)" v-b-modal.followInsert>
                                    <font-awesome-icon icon="file-signature" />
                                </b-button>
                                <!-- <b-button
                  class="fas fa-window-close px-2"
                  variant="secondary"
                  v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                  title="Closed"
                  @click="getFollowup(data.item)"
                  v-b-modal.closed
                ></b-button> -->
            </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </div>
        <!-- </div> -->
        </b-tab>
            <b-tab title="Expired Followups">
        <!-- <div v-if="isCounsellorExpiredFollowups"> -->
            <!-- <div class="mt-5"> -->
                <div>
                <b-container>
                    <b-row align-v="center">
                        <b-col col sm="12" md="3" lg="4" id="r1">
                            <input type="search" class="searchbar" v-model="expiredCounsellorSearchKeyword" v-on:keyup.enter="
                    getCounsellorExpiredSearchCount(
                      expiredCounsellorSearchKeyword
                    )
                  " placeholder="Type a name" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="
                    getCounsellorExpiredSearchCount(
                      expiredCounsellorSearchKeyword
                    )
                  ">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="expiredCurrentPage" :total-rows="expiredTotalCount" :per-page="expiredPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    counsellorExpiredSwitchPage(
                      expiredCurrentPage,
                      expiredPageCount
                    )
                  "></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="expiredPageCount" @input="
                    counsellorExpiredSwitchPage(
                      expiredCurrentPage,
                      expiredPageCount
                    )
                  " :options="expiredPageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="expiredCounsellorRefresh()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- for sort dropdown start-->
                <b-container>
                    <b-card class="card mt-1">
                        <b-container>
                            <b-row>
                                <b-col col lg="6" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="expiredSelectedCourse" @change="
                        expiredCounsellorSortCourseCount(expiredSelectedCourse)
                      ">
                                        <option value="courses" disabled>Courses</option>
                                        <option value="allCourses">All Courses</option>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="6" md="2" id="r1" sm="6" class="sort">
                                    <b-form-select class="bnclr" v-model="expiredSelectedSource" @change="
                        expiredCounsellorSortSourceCount(expiredSelectedSource)
                      ">
                                        <option value="sources" disabled>Sources</option>
                                        <option value="allSources">All Sources</option>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <!-- <b-col col lg="4" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="expiredSelectedBranch"
                    @change="expiredSortBranchCount(expiredSelectedBranch)"
                  >
                    <option value="branches" disabled>Branches</option>
                    <option value="allBranches">All Branches</option>
                    <option
                      v-for="item in allBranches"
                      :value="item"
                      v-bind:key="item.index"
                    >
                      <strong>{{ item.name }}</strong>
                    </option>
                  </b-form-select>
                </b-col> -->
                                <!-- <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="expiredSelectDate"
                    @change="expiredSortDate(expiredSelectedDate)"
                    :options="dateRange"
                    >>
                  </b-form-select>
                </b-col> -->
                            </b-row>
                        </b-container>
                        <!-- for sort dropdown start-->
                        <br />
                        <hr />
                        <b-table responsive bordered striped hover :items="expiredFollowups" :fields="followupItems" :current-page="expiredCurrentPage" :per-page="expiredFollowups.expiredPageCount">
                            <template v-slot:cell(createdAt)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(type)="data">
                                {{ data.item.type }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div>
                                    {{ data.item.contact
                    }}<span v-if="
                        data.item.alternativeContact != null &&
                        data.item.alternativeContact != ''
                      ">,</span>
                                </div>
                                <div>
                                    {{ data.item.alternativeContact }}
                                </div>
                            </template>
                            <template v-slot:cell(course)="data">
                                {{ data.item.course.name }}
                            </template>
                            <template v-slot:cell(source)="data">
                                {{ data.item.source.name }}
                            </template>
                            <template v-slot:cell(branch)="data">
                                {{ data.item.branch.name }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                <div class="dateCell">
                                    {{ dateConvert(data.item.followupDate, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(remark)="data">
                                {{ data.item.remark }}
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getEnquiryFollowup(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getEnquiryFollowup(data.item)" v-b-modal.followInsert>
                                    <font-awesome-icon icon="file-signature" />
                                </b-button>
                                <!-- <b-button
                  class="fas fa-window-close px-2"
                  variant="secondary"
                  v-b-tooltip.hover.bottomright="{ variant: 'secondary' }"
                  title="Closed"
                  @click="getFollowup(data.item)"
                  v-b-modal.closed
                ></b-button> -->
            </div>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </div>
        <!-- </div> -->
        </b-tab>
        </b-tabs>
        <div>
            <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
            <!-- <b-modal ref="dateRange" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal> -->
        </div>
        <b-container>
            <!--popup start for View Call details-->
            <!-- The modal -->
            <b-modal id="viewDetail" title="View Details" no-close-on-backdrop hide-footer>
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="view.name" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input v-model="view.contact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input type="number" v-model="view.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input v-model="view.age" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-input v-model="view.qualification" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-input v-model="view.course" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-input v-model="view.source" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-input v-model="view.mode" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-input v-model="view.branch" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                        <template v-slot:cell(createdAt)="data">
                            <div class="dateCell">
                                {{ dateConvert(data.item.createdAt, "fullDate") }}
                            </div>
                        </template>
                        <template v-slot:cell(remark)="data">
                            {{ data.item.remark }}
                        </template>
                        <template v-slot:cell(followupDate)="data">
                            {{ dateConvert(data.item.followupDate, "fullDate") }}
                        </template>
                    </b-table>
                </div>
            </b-modal>
            <!--popup end for View Call details-->
        </b-container>
        <b-container>
            <!-- popup start for follow insert -->
            <b-modal id="followInsert" title="Followup Update" ref="modal" no-close-on-backdrop hide-footer>
                <!-- <h5 class="px-5">Name : {{ update.name }}</h5>
          <h5 class="px-5">Contact : {{ update.contact }}</h5> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Followup Date and Remark</label>
                            <div class="py-2">
                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="followup.followupDate" :class="{
                      'is-invalid': $v.followup.followupDate.$error,
                      'is-valid': !$v.followup.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.followup.followupDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                            <div>
                                <b-form-textarea class="form-control" v-model="followup.remark" :class="{
                      'is-invalid': $v.followup.remark.$error,
                      'is-valid': !$v.followup.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                <div class="valid-feedback">remark is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.followup.remark.required">remark is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 mt-2">
                    <b-button type="submit" @click="followupUpdate()" :disabled="disabledButton" class="px-3" variant="primary">Update</b-button>
                </div>
            </b-modal>
            <!-- popup start for closed start -->
            <b-modal id="closed" title="Call Closed" ref="modal" no-close-on-backdrop hide-footer>
                <label class="mt-3">Closed for</label>
                <b-form-select class="form-control" v-model="closed" :options="closedOption" :class="{
              'is-invalid': $v.closed.$error,
              'is-valid': !$v.closed.$invalid,
            }"></b-form-select>
                <div class="mt-3 mt-2 text-center">
                    <b-button type="submit" @click="closedTo()" class="px-3" variant="primary" :disabled="disabledButton">closed</b-button>
                </div>
            </b-modal>
            <!-- popup start for closed start end -->
        </b-container>
    </div>
</b-container>
</div>
</template>

<script>
import Account from '../service/Account.js';
import FollowupService from "../service/FollowupService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import CallService from "../service/CallService";
import EnquiryService from "../service/EnquiryService";
import axios from "axios";
import moment from "moment-timezone";
import Swal from 'sweetalert2';

export default {
    name: "Followup",
    components: {},
    data() {
        return {
            modelOpen: false, //for date sorting
            moment: "",
            selectedFollowup: null,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            totalCount: 0,
            //search
            keyword: "",
            //toggle button active
            followupsActive: true,
            expiredFollowupsActive: false,
            expiredDate: false,
            //dropdown render
            allCourses: [],
            allSources: [],
            allBranches: [],
            //id set as Object for send URL to the API
            data: "",
            // sorting dropdown select
            selectedCourse: "Courses",
            selectedSource: "Sources",
            selectedBranch: "Branches",
            selectDate: null,
            selectCourse: "courses",
            selectSource: "sources",
            //for followups switch page pagintion method call as condition check
            paginationFollowups: false,
            sortCoursePaginationFollowups: false,
            sortSourcePaginationFollowups: false,
            sortBranchPaginationFollowups: false,
            searchPagination: false,
            dateRange: [{
                    value: null,
                    text: "Date",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Last Week"
                },
                {
                    value: "lastMonth",
                    text: "Last Month"
                },
                {
                    value: "dateRange",
                    text: "Range Select"
                },
            ],
            //toggle to show expiry followups table
            expiredSearchKeyword: "",
            expiredCurrentPage: 1,
            expiredPageCount: 5,
            expiredPageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            expiredTotalCount: 0,
            // isFollowups: true,
            // isExpiredFollowups: false,
            //Expiry sorting dropdown select
            selectedExpiredCourse: "Courses",
            selectedExpiredSource: "Sources",
            selectedExpiredBranch: "Branches",
            expiredSelectedCourse: "courses",
            expiredSelectedSource: "sources",
            // expiredSelectedDate: null,
            //for expired followups switch page pagintion method call as condition check
            expiredPaginationFollowups: false,
            expiredSortCoursePaginationFollowups: false,
            expiredSortSourcePaginationFollowups: false,
            expiredSortBranchPaginationFollowups: false,
            expiredSearchPagination: false,
            followupItems: [{
                    key: "followupDate",
                    label: "Followup_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "remark",
                    label: "Remark"
                },
                {
                    key: "type",
                    label: "Type"
                },
                {
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "action",
                    label: "Action",
                    tdClass: "cellSize"
                },
            ],
            closed: null,
            closedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "enquiry",
                    text: "Enquiry",
                },
                {
                    value: "admission",
                    text: "Admission",
                },
            ],
            agentId: "",
            view: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            //this day to next followups
            followups: [],
            followupDateRemark: [],
            //expired followups
            expiredFollowups: [],
            followupFields: [
                {
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            followup: {
                followupDate: "",
                remark: "",
            },
            submitStatus: "",
            disabledButton: false,

            isRoleCounsellor: false,
            isShowCounsellor: true,
            // isShowCounsellorClosed: false,
            branch: "",
            // isCounsellorFollowups: true,
            // isCounsellorExpiredFollowups: false,
            updateFor: null,
            counsellorKeyword: "",
            expiredCounsellorSearchKeyword: "",
        };
    },
    validations: {
        closed: {
            required,
        },
        followup: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
    },
    /*
    async mounted() {
      await this.global();
      // await this.methodCalling();
      await this.getAllFollowups();
      await this.getAllCourses();
      await this.getAllSources();
      await this.getAllBranches();
    },
    */
    mounted() {
        if (localStorage.getItem("agentId") != null) {
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            this.counsellorData();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            localStorage.removeItem("agentId");
                            reject(err);
                        });
                });
            } else {
                this.isRoleCounsellor = false;
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            // this.getAllFollowups();
                            this.getCount();
                            this.getExpiredFollowupsCount();
                            // this.getAllPageable(this.currentPage,this.pageCount);
                            this.getAllCourses();
                            this.getAllSources();
                            this.getAllBranches();
                            // this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            localStorage.removeItem("agentId");
                            reject(err);
                        });
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Your role has been Removed',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    // mounted() {
    //   let self = this;
    //   return new Promise((resolve, reject) => {
    //     Account.getAccessToken(localStorage.getItem("refreshToken"))
    //       .then((response) => {
    //         this.loading = true;
    //         this.agentId = localStorage.getItem("agentId");
    //         self.getAllFollowups();
    //         self.getAllCourses();
    //         self.getAllSources();
    //         self.getAllBranches();

    //         this.loading = false;
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         reject(err);
    //       });
    //   });
    // },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllFollowups();
                this.getCount();
            }
        },
        expiredSearchKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllExpiredFollowups();
                this.getExpiredFollowupsCount();
            }
        },
        counsellorKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllFollowups();
                this.getCounsellorFollowupCount();
            }
        },
        expiredCounsellorSearchKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllFollowups();
                this.getAllClosedFollowupCounsellorCount();
            }
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async counsellorData() {
            await this.setCounsellor();
            //  console.log("counsellor count callng")
            await this.getCounsellorFollowupCount();
            await this.getAllClosedFollowupCounsellorCount();
            //  console.log("getAllCourses callng")
            await this.getAllCourses();
            //  console.log("getAllSources callng")
            await this.getAllSources();
        },
        setCounsellor() {
            this.branch = JSON.parse(localStorage.getItem("branch"));
            this.followup.branch = this.branch;
            //  console.log(this.followup.branch);
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                this.isRoleCounsellor = true;
            } else {
                this.isRoleCounsellor = false;
            }
        },
        // async methodCalling() {
        //   await this.getAllFollowups();
        //   await this.getAllCourses();
        //   await this.getAllSources();
        //   await this.getAllBranches();
        // },
        // showFollowups() {
        //     // this.getAllFollowups();
        //     this.getCount();
        //     (this.keyword = ""), (this.isFollowups = true);
        //     this.isExpiredFollowups = false;
        //     this.selectedCourse = "Courses";
        //     this.selecedSource = "Sources";
        //     this.selectedBranch = "Branches";
        // },
        // showExpiredFollowups() {
        //     // this.getAllExpiredFollowups();
        //     this.getExpiredFollowupsCount();
        //     (this.expiredSearchKeyword = ""), (this.isFollowups = false);
        //     this.isExpiredFollowups = true;
        //     this.selectedExpiredCourse = "Courses";
        //     this.selectedExpiredSource = "Sources";
        //     this.selectedExpiredBranch = "Branches";
        // },

        getAllSortCount: function (
            selectedCourse,
            selectedSource,
            selectedBranch
            // selectedDates
        ) {
            // if (selectedDates == "dateRange") {
            //   this.getDateRangeReset();
            //   this.$refs.dateRange.show();
            //   this.selectedDates = "dateBetween";
            // } else {
            if (selectedCourse == "Courses" || selectedCourse == "AllCourses") {
                selectedCourse = {
                    courseId: "",
                };
            }
            if (selectedSource == "Sources" || selectedSource == "AllSources") {
                selectedSource = {
                    sourceId: "",
                };
            }
            if (selectedBranch == "Branches" || selectedBranch == "AllBranches") {
                selectedBranch = {
                    branchId: "",
                };
            }
            // var from;
            // var to;
            // if (selectedDates != null) {
            //   var date = new Date();
            //   if (selectedDates == "today") {
            //     this.fromDate = new Date(date.setHours(0, 0, 0, 0));
            //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
            //   } else if (selectedDates == "yesterday") {
            //     this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
            //     this.toDate = new Date(date.setHours(23, 59, 59, 0));
            //   } else if (selectedDates == "lastWeek") {
            //     this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
            //     this.toDate = new Date(date.setHours(167, 59, 59, 0));
            //   } else if (selectedDates == "lastMonth") {
            //     this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
            //     this.toDate = new Date(date.setHours(719, 59, 59, 0));
            //   } else if (
            //     this.from != "" &&
            //     this.to != "" &&
            //     selectedDates == "dateBetween"
            //   ) {
            //     this.$refs.dateRange.hide();
            //     var fromDateObject = new Date(this.from);
            //     var toDateObject = new Date(this.to);
            //     this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
            //     this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
            //   } else if (
            //     this.from == "" &&
            //     this.to == "" &&
            //     selectedDates == "dateBetween"
            //   ) {
            //     this.$refs.dateRange.hide();
            //     this.fromDate = "2021-01-01 00:01";
            //     this.toDate = new Date();
            //   }
            //   this.fromDateFormat = moment(String(this.fromDate)).format(
            //     "yyyy-MM-DD HH:mm"
            //   );
            //   this.toDateFormat = moment(String(this.toDate)).format(
            //     "yyyy-MM-DD HH:mm"
            //   );
            //   from = this.fromDateFormat;
            //   to = this.toDateFormat;
            // } else if (selectedDates == null || selectedDates == "dateBetween") {
            //   let fromDate = "2021-01-01 00:01";
            //   let toDate = new Date();
            //   let fromDateFormat = moment(String(fromDate)).format(
            //     "yyyy-MM-DD HH:mm"
            //   );
            //   let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
            //   from = fromDateFormat;
            //   to = toDateFormat;
            // }
            this.data = {
                agentId: this.agentId,
                courseId: selectedCourse.courseId,
                sourceId: selectedSource.sourceId,
                branchId: selectedBranch.branchId,
                // from: from,
                // to: to,
            };
            this.loading = true;
            this.allSortFollowups = true;
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.currentPage = 1;
            this.keyword = "";
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/followups/all/sort/count", this.data)
                    .then((response) => {
                        // console.log("getAllSortCount======", response.data);
                        this.totalCount = response.data;
                        this.getAllSortPageable(
                            this.currentPage,
                            this.pageCount,
                            this.data
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
            // }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/followups/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        //=================================EXPIRED FOLLOWUP========================================================

        getAllSortExpiredCount: function (
            selectedExpiredCourse,
            selectedExpiredSource,
            selectedExpiredBranch,
            selectedExpiredDates
        ) {
            // console.log("selectedExpiredAgent=== ", selectedExpiredAgent);
            // console.log("selectedExpiredCourse=== ", selectedExpiredCourse);
            // console.log("selectedExpiredSource=== ", selectedExpiredSource);
            // console.log("selectedExpiredBranch=== ", selectedExpiredBranch);
            // console.log("selectedExpiredDates=== ", selectedExpiredDates);

            if (selectedExpiredDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedExpiredDates = "dateBetween";
            } else {
                if (
                    selectedExpiredCourse == "Courses" ||
                    selectedExpiredCourse == "AllCourses"
                ) {
                    selectedExpiredCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedExpiredSource == "Sources" ||
                    selectedExpiredSource == "AllSources"
                ) {
                    selectedExpiredSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedExpiredBranch == "Branches" ||
                    selectedExpiredBranch == "AllBranches"
                ) {
                    selectedExpiredBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedExpiredDates != null) {
                    var date = new Date();
                    if (selectedExpiredDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedExpiredDates == null ||
                    selectedExpiredDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedExpiredCourse.courseId,
                    sourceId: selectedExpiredSource.sourceId,
                    branchId: selectedExpiredBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortFollowups = true;
                this.paginationFollowups = false;
                this.searchPagination = false;
                this.expiredCurrentPage = 1;
                this.expiredSearchKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/followups/expiredFollowup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortExpiredCount======", response.data);
                            this.expiredTotalCount = response.data;
                            this.getAllSortExpiredPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortExpiredPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/followups/expiredFollowup/all/sort/" +
                        expiredCurrentPage +
                        "/" +
                        expiredPageCount,
                        data
                    )
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        // console.log("getAllSortExpiredPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        dateConvert: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D-MM-YYYY hh:mm a");
            }
        },
        followupsRefresh: function () {
            // this.getAllFollowups();
            this.getCount();
            this.keyword = "";
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            // this.selectDate = null;
        },
        expiredRefresh: function () {
            // this.getAllExpiredFollowups();
            this.getExpiredFollowupsCount();
            this.selectedExpiredCourse = "Courses";
            this.selectedExpiredSource = "Sources";
            this.selectedExpiredBranch = "Branches";
            // this.expiredSelectedDate = null;
            this.expiredSearchKeyword = "";
        },
        // getAllFollowups: function () {
        //   this.loading = true;
        //   this.expiredDate = false;
        //   return new Promise((resolve, reject) => {
        //     FollowupService.getAllFollowups(this.agentId)
        //       .then((response) => {
        //         this.followups = response.data;
        //         console.log("getAllFollowups===this.pageCount===", this.pageCount);
        //         console.log("getAllFollowups===this.currentPage===", this.currentPage);
        //         // this.getCount();
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getCount: function () {
            this.loading = true;
            this.expiredDate = false;
            this.allSortFollowups = false;
            this.paginationFollowups = true;
            this.searchPagination = false;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.keyword = "";
            this.currentPage = 1;
            // this.expiredDate = false;
            // this.paginationFollowups = true;
            // this.searchPagination = false;
            // this.sortCoursePaginationFollowups = false;
            // this.sortSourcePaginationFollowups = false;
            // this.sortBranchPaginationFollowups = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                FollowupService.getCount(this.agentId)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationFollowups) {
                // console.log(this.paginationFollowups, "paginationFollowups");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortFollowups) {
                // console.log(this.allSortFollowups, "allSortFollowups");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
            // if(this.paginationFollowups){
            //   this.getAllPageable(currentPage,pageCount);
            // }
            // else if(this.sortCoursePaginationFollowups){
            //   this.sortCourseAllPageable(currentPage,pageCount,this.data);
            // }
            // else if(this.sortSourcePaginationFollowups){
            //   this.sortSourceAllPageable(currentPage,pageCount,this.data);
            // }
            // else if(this.sortBranchPaginationFollowups){
            //   this.sortBranchAllPageable(currentPage,pageCount,this.data);
            // }
            // else if(this.searchPagination){
            //   this.getAllSearchPageable(currentPage,pageCount,this.keyword);
            // }
        },
        getAllPageable: function (currentPage, pageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                FollowupService.getAllPageable(currentPage, pageCount, this.agentId)
                    .then((response) => {
                        this.followups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // search: function (keyword) {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     FollowupService.search(keyword.toLowerCase(), this.agentId)
        //       .then((response) => {
        //         this.followups = response.data;
        //         this.followups.forEach((e) => {
        //           const followupDate = new Date(e.followupDate);
        //           // console.log("followupDate", followupDate);
        //           const today = new Date();
        //           const zeroStart = new Date(today.setHours(0, 0, 0, 0));
        //           // console.log("zeroStart", zeroStart);
        //           if (followupDate > zeroStart) {
        //             this.expiredDate = false;
        //             this.loading = false;
        //             console.log("followupDate is greater then today");
        //           } else if (followupDate < zeroStart) {
        //             this.expiredDate = true;
        //             this.loading = false;
        //             console.log("followupDate is lesser then today");
        //           } else {
        //             console.log("no compare");
        //             this.loading = false;
        //           }
        //         });
        //         this.getSearchCount(keyword.toLowerCase());
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getSearchCount: function (keyword) {
            this.loading = true;
            this.searchPagination = true;
            this.paginationFollowups = false;
            this.allSortFollowups = false;
            this.currentPage = 1;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            // this.paginationFollowups = false;
            // this.searchPagination = true;
            // this.sortCoursePaginationFollowups = false;
            // this.sortSourcePaginationFollowups = false;
            // this.sortBranchPaginationFollowups = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                FollowupService.getSearchCount(keyword.toLowerCase(), this.agentId)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                FollowupService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase(),
                        this.agentId
                    )
                    .then((response) => {
                        this.followups = response.data;
                        this.followups.forEach((e) => {
                            const followupDate = new Date(e.followupDate);
                            const today = new Date();
                            const zeroStart = new Date(today.setHours(0, 0, 0, 0));
                            if (followupDate > zeroStart) {
                                this.expiredDate = false;
                                this.loading = false;
                            } else if (followupDate < zeroStart) {
                                this.expiredDate = true;
                                this.loading = false;
                            } else {
                                this.loading = false;
                            }
                            this.loading = false;
                        });
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getFollowup: function (data) {
            this.resetFollowupUpdate();
            this.updateFor = data;
            // console.log(this.updateFor)
            this.getData(this.updateFor);
        },
        getData: function (updateFor) {
            if (updateFor.type == "call") {
                return new Promise((resolve, reject) => {
                    // FollowupService.getFollowupDateRemark(updateFor.id)
                    FollowupService.getFollowupCreatedDate(this.updateFor.id)
                        .then((response) => {
                            this.view = {
                                name: updateFor.name,
                                contact: updateFor.contact,
                                alternativeContact: updateFor.alternativeContact,
                                age: updateFor.age,
                                qualification: updateFor.qualification,
                                course: updateFor.course.name,
                                source: updateFor.source.name,
                                mode: updateFor.mode,
                                branch: updateFor.branch.name,
                            };
                            this.followupDateRemark = response.data;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            if (updateFor.type == "enquiry") {
                return new Promise((resolve, reject) => {
                    EnquiryService.getEnquiry(updateFor.id)
                        .then((response) => {
                            this.view = {
                                name: response.data.name,
                                contact: response.data.contact,
                                age: response.data.age,
                                qualification: response.data.qualification,
                                alternativeContact: response.data.alternativeContact,
                                course: response.data.course.name,
                                source: response.data.source.name,
                                mode: response.data.mode,
                                branch: response.data.branch.name,
                                followups: response.data.followup,
                            };
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        followupUpdate: function () {
            this.$v.followup.$touch();
            if (this.$v.followup.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select next followup Date and time",
                });
            } else {
                if (this.updateFor.type == "call") {
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        CallService.callFollowupUpdate(
                                this.agentId,
                                this.updateFor.id,
                                this.followup
                            )
                            .then((response) => {
                                this.$swal(response.data);
                                this.$refs.modal.hide();
                                this.getCount();
                                this.getExpiredFollowupsCount();
                                this.loading = false;
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                        // this.$nextTick(function () {
                        //   this.fields.reset();
                        //   this.errors.clear();
                        // });
                    });
                } else if (this.updateFor.type == "enquiry") {
                    // console.log("followup update ==",this.updateFor)
                    this.counsellorKeyword = "";
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        FollowupService.enquiryCounsellorFollowupUpdate(
                                this.agentId,
                                this.updateFor.enquiryId,
                                this.followup
                            )
                            .then((response) => {
                                this.$swal(response.data);
                                this.$refs.modal.hide();
                                this.getCounsellorFollowupCount();
                                this.getAllClosedFollowupCounsellorCount();
                                // this.getCount();
                                // this.getExpiredFollowupsCount();
                                this.loading = false;
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                }
            }
        },
        resetFollowupUpdate: function () {
            this.followup = {
                followupDate: null,
                remark: null,
            };
            this.closed = null;
            this.$nextTick(() => {
                this.$v.followup.$reset();
                this.$v.closed.$reset();
                // this.$v.followup.errors.$clear();
            });
        },
        closedTo: function () {
            this.$v.closed.$touch();
            if (this.$v.closed.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select an enquiry or admission",
                });
            } else {
                var closedDatas = {
                    callId: this.updateFor.id,
                    name: this.updateFor.name,
                    contact: this.updateFor.contact,
                    age: this.updateFor.age,
                    qualification: this.updateFor.qualification,
                    course: this.updateFor.course,
                    source: this.updateFor.source,
                    mode: this.updateFor.mode,
                    branch: this.updateFor.branch,
                };
                if (this.closed == "enquiry") {
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/enquiry/insert/" + this.agentId, closedDatas)
                            .then((response) => {
                                this.$swal(response.data);
                                this.$refs.modal.hide();
                                // this.getAllFollowups();
                                this.getCount();
                                // this.getAllExpiredFollowups();
                                this.getExpiredFollowupsCount();
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                } else if (this.closed == "admission") {
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/admission/insert/" + this.agentId, closedDatas)
                            .then((response) => {
                                this.$swal(response.data);
                                this.$refs.modal.hide();
                                // this.getAllFollowups();
                                this.getCount();
                                // this.getAllExpiredFollowups();
                                this.getExpiredFollowupsCount();
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                } else {
                    this.$swal("Closed option invalid");
                }
            }
        },
        // sortCourse: function (selectCourse) {
        //   this.selectSource = "sources";
        //   this.selectBranch = "branches";
        //   if (selectCourse == "allCourses") {
        //     this.getAllFollowups();
        //   } else {
        //     let data = {
        //       courseId: selectCourse.courseId,
        //     };
        //     // console.log("selectSortCourse===", selectCourse);
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       // this.loading = true;
        //       FollowupService.sortCourse(this.agentId, data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           // console.log("sortCourse",response.data)
        //           this.getSortCourseCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getSortCourseCount: function (selectCourse) {
            this.selectSource = "sources";
            this.selectBranch = "branches";
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = true;
            this.sortSourcePaginationFollowups = false;
            this.sortBranchPaginationFollowups = false;
            this.currentPage = 1;
            if (selectCourse == "allCourses") {
                this.getCount();
            } else {
                this.data = {
                    courseId: selectCourse.courseId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.getSortCourseCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.sortCourseAllPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        sortCourseAllPageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                FollowupService.sortCourseAllPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // sortSource: function (selectSource) {
        //   this.selectCourse = "courses";
        //   this.selectBranch = "branches";
        //   if (selectSource == "allSources") {
        //     this.getAllFollowups();
        //   } else {
        //     let data = {
        //       sourceId: selectSource.sourceId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       axios;
        //       FollowupService.sortSource(this.agentId, data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           this.getSortSourceCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getSortSourceCount: function (selectSource) {
            this.selectCourse = "courses";
            this.selectBranch = "branches";
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = false;
            this.sortSourcePaginationFollowups = true;
            this.sortBranchPaginationFollowups = false;
            this.currentPage = 1;
            if (selectSource == "allSources") {
                this.getCount();
            } else {
                this.data = {
                    sourceId: selectSource.sourceId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.getSortSourceCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.sortSourceAllPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        sortSourceAllPageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                FollowupService.sortSourceAllPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // sortBranch: function (selectBranch) {
        //   this.selectCourse = "courses";
        //   this.selectSource = "sources";
        //   if (selectBranch == "allBranches") {
        //     this.getAllFollowups();
        //   } else {
        //     let data = {
        //       branchId: selectBranch.branchId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       axios;
        //       FollowupService.sortBranch(this.agentId, data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           // console.log("sortBranch",response.data);
        //           this.getSortBranchCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getSortBranchCount: function (selectBranch) {
            this.selectCourse = "courses";
            this.selectSource = "sources";
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = false;
            this.sortSourcePaginationFollowups = false;
            this.sortBranchPaginationFollowups = true;
            this.currentPage = 1;
            if (selectBranch == "allBranches") {
                this.getCount();
            } else {
                this.data = {
                    branchId: selectBranch.branchId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.getSortBranchCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.sortBranchAllPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        sortBranchAllPageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                FollowupService.sortBranchAllPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        //======================Expired followups=========================
        // getAllExpiredFollowups: function () {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     this.loading = true;
        //     FollowupService.getAllExpiredFollowups(this.agentId)
        //       .then((response) => {
        //         this.expiredFollowups = response.data;
        //         // console.log("getAllExpiredFollowups", response.data);
        //         this.getExpiredFollowupsCount();
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getExpiredFollowupsCount: function () {
            this.loading = true;
            this.paginationFollowups = true;
            this.searchPagination = false;
            this.allSortFollowups = false;
            this.expiredCurrentPage = 1;
            this.selectedExpiredCourse = "Courses";
            this.selectedExpiredSource = "Sources";
            this.selectedExpiredBranch = "Branches";
            this.loading = true;
            // this.loading = true;
            // this.expiredPaginationFollowups = true;
            // this.expiredSearchPagination = false;
            // this.expiredSortCoursePaginationFollowups = false;
            // this.expiredSortSourcePaginationFollowups = false;
            // this.expiredSortBranchPaginationFollowups = false;
            // this.expiredCurrentPage = 1;
            return new Promise((resolve, reject) => {
                FollowupService.getExpiredFollowupsCount(this.agentId)
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        this.getExpiredFollowupsPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        expiredFollowupsSwitchPage: function (
            expiredCurrentPage,
            expiredPageCount
        ) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationFollowups) {
                this.getExpiredFollowupsPageable(expiredCurrentPage, expiredPageCount);
                // console.log("this.paginationFollowups===", this.paginationFollowups);
                // console.log("this.allSortFollowups===", this.allSortFollowups);
            } else if (this.allSortFollowups) {
                this.getAllSortExpiredPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.data
                );
                // console.log("this.paginationFollowups===", this.paginationFollowups);
                // console.log("this.allSortFollowups===", this.allSortFollowups);
            } else if (this.searchPagination) {
                this.getAllExpiredSearchPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.expiredSearchKeyword
                );
                // console.log(this.searchPagination, "getAllExpiredSearchPageable");
            }

            // if (this.expiredPaginationFollowups) {
            //   this.getExpiredFollowupsPageable(expiredCurrentPage, expiredPageCount);
            // } else if (this.expiredSortCoursePaginationFollowups) {
            //   this.expiredSortCourseAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSortSourcePaginationFollowups) {
            //   this.expiredSortSourceAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSortBranchPaginationFollowups) {
            //   this.expiredSortBranchAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSearchPagination) {
            //   this.getAllExpiredSearchPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.expiredSearchKeyword
            //   );
            // }
        },
        getExpiredFollowupsPageable: function (
            expiredCurrentPage,
            expiredPageCount
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.getExpiredFollowupsPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.agentId
                    )
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // expiredFollowupsSearch: function (expiredSearchKeyword) {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     FollowupService.expiredFollowupsSearch(
        //       expiredSearchKeyword.toLowerCase(),
        //       this.agentId
        //     )
        //       .then((response) => {
        //         this.expiredFollowups = response.data;
        //         this.loading = false;
        //         this.getExpiredSearchCount(expiredSearchKeyword.toLowerCase());
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getExpiredSearchCount: function (expiredSearchKeyword) {
            this.paginationFollowups = false;
            this.searchPagination = true;
            this.allSortFollowups = false;
            this.expiredCurrentPage = 1;
            this.selectedExpiredCourse = "Courses";
            this.selectedExpiredSource = "Sources";
            this.selectedExpiredBranch = "Branches";
            // this.expiredSearchKeyword = "";
            this.loading = true;
            // this.expiredPaginationFollowups = false;
            // this.expiredSearchPagination = true;
            // this.expiredSortCoursePaginationFollowups = false;
            // this.expiredSortSourcePaginationFollowups = false;
            // this.expiredSortBranchPaginationFollowups = false;
            // this.expiredCurrentPage = 1;
            return new Promise((resolve, reject) => {
                FollowupService.getExpiredSearchCount(
                        expiredSearchKeyword.toLowerCase(),
                        this.agentId
                    )
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        // console.log("this.expiredTotalCount===", this.expiredTotalCount);
                        this.getAllExpiredSearchPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount,
                            expiredSearchKeyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllExpiredSearchPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            expiredSearchKeyword
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.getAllExpiredSearchPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        expiredSearchKeyword.toLowerCase(),
                        this.agentId
                    )
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // expiredSortCourse: function (expiredSelectedCourse) {
        //   this.expiredSelectedSource = "sources";
        //   this.expiredSelectedBranch = "branches";
        //   if (expiredSelectedCourse == "allCourses") {
        //     this.getAllExpiredFollowups();
        //   } else {
        //     let data = {
        //       courseId: expiredSelectedCourse.courseId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       FollowupService.expiredSortCourse(this.agentId, data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.expiredSortCourseCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        expiredSortCourseCount: function (expiredSelectedCourse) {
            this.expiredSelectedSource = "sources";
            this.expiredSelectedBranch = "branches";
            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = true;
            this.expiredSortSourcePaginationFollowups = false;
            this.expiredSortBranchPaginationFollowups = false;
            this.expiredCurrentPage = 1;
            if (expiredSelectedCourse == "allCourses") {
                this.getExpiredFollowupsCount();
            } else {
                this.data = {
                    courseId: expiredSelectedCourse.courseId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.expiredSortCourseCount(this.agentId, this.data)
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            this.expiredSortCourseAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredSortCourseAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.expiredSortCourseAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // expiredSortSource: function (expiredSelectedSource) {
        //   this.expiredSelectedCourse = "courses";
        //   this.expiredSelectedBranch = "branches";
        //   if (expiredSelectedSource == "allSources") {
        //     this.getAllExpiredFollowups();
        //   } else {
        //     let data = {
        //       sourceId: expiredSelectedSource.sourceId,
        //     };
        //     this.loading = true;
        //     // console.log("expiredSortSourcedata", data);
        //     return new Promise((resolve, reject) => {
        //       FollowupService.expiredSortSource(this.agentId, data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.expiredSortSourceCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        expiredSortSourceCount: function (expiredSelectedSource) {
            this.expiredSelectedCourse = "courses";
            this.expiredSelectedBranch = "branches";
            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = false;
            this.expiredSortSourcePaginationFollowups = true;
            this.expiredSortBranchPaginationFollowups = false;
            this.expiredCurrentPage = 1;
            if (expiredSelectedSource == "allSources") {
                this.getExpiredFollowupsCount();
            } else {
                this.data = {
                    sourceId: expiredSelectedSource.sourceId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.expiredSortSourceCount(this.agentId, this.data)
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            this.expiredSortSourceAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredSortSourceAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.expiredSortSourceAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // expiredSortBranch: function (expiredSelectedBranch) {
        //   this.expiredSelectedCourse = "courses";
        //   this.expiredSelectedSource = "sources";
        //   if (expiredSelectedBranch == "allBranches") {
        //     this.getExpiredFollowupsCount();
        //   } else {
        //     this.data = {
        //       branchId: expiredSelectedBranch.branchId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       FollowupService.expiredSortBranch(this.agentId, this.data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.expiredSortBranchCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        expiredSortBranchCount: function (expiredSelectedBranch) {
            this.expiredSelectedCourse = "courses";
            this.expiredSelectedSource = "sources";
            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = false;
            this.expiredSortSourcePaginationFollowups = false;
            this.expiredSortBranchPaginationFollowups = true;
            this.expiredCurrentPage = 1;
            if (expiredSelectedBranch == "allBranches") {
                this.getExpiredFollowupsCount();
            } else {
                this.data = {
                    branchId: expiredSelectedBranch.branchId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.expiredSortBranchCount(this.agentId, this.data)
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            this.expiredSortBranchAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredSortBranchAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.expiredSortBranchAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // ==========================Counsellor================================
        // showCounsellorFollowups() {
        //     //  console.log("showCounsellorFollowups")
        //     this.isShowCounsellor = true;
        //     this.isShowCounsellorClosed = false;
        //     this.isCounsellorFollowups = true;
        //     this.isCounsellorExpiredFollowups = false;
        //     this.getCounsellorFollowupCount();
        // },
        // showCounsellorExpiredFollowups() {
        //     // console.log("showCounsellorExpiredFollowups")
        //     this.isShowCounsellor = false;
        //     this.isShowCounsellorClosed = true;
        //     this.isCounsellorFollowups = false;
        //     this.isCounsellorExpiredFollowups = true;
        //     this.getAllClosedFollowupCounsellorCount();
        // },
        getCounsellorFollowupCount: function () {
            this.expiredDate = false;
            this.paginationFollowups = true;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = false;
            this.sortSourcePaginationFollowups = false;
            this.currentPage = 1;
            //  console.log("getCounsellorCount Calling")

            //  console.log(this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            //  console.log("Data",this.data)
            this.loading = true;
            return new Promise((resolve, reject) => {
                FollowupService.getCounsellorFollowupCount(this.data)
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log(this.totalCount)
                        this.getAllCounsellorPageable(
                            this.currentPage,
                            this.pageCount,
                            this.data
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllCounsellorPageable: function (currentPage, pageCount, data) {
            // console.log("CounsellorPageable calling")
            return new Promise((resolve, reject) => {
                FollowupService.getAllCounsellorPageable(currentPage, pageCount, data)
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        // console.log(response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        counsellorSwitchPage: function (currentPage, pageCount) {
            //  console.log("counsellorSwitchPage")
            this.loading = true;
            // this.getAllCounsellorPageable(currentPage,pageCount,this.data);
            if (this.paginationFollowups) {
                this.getAllCounsellorPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllCounsellorSearchPageable(
                    currentPage,
                    pageCount,
                    this.counsellorKeyword,
                    this.data
                );
            } else if (this.sortSourcePaginationFollowups) {
                this.sortCounsellorSourceAllPageable(currentPage, pageCount, this.data);
            } else if (this.sortCoursePaginationFollowups) {
                this.sortCounsellorCourseAllPageable(currentPage, pageCount, this.data);
            }
        },
        getAllClosedFollowupCounsellorCount: function () {
            this.expiredPaginationFollowups = true;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = false;
            this.expiredSortSourcePaginationFollowups = false;

            this.expiredCurrentPage = 1;
            //  console.log("getAllClosedFollowupCounsellorCount Calling")

            //  console.log(this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            //  console.log("Data",this.data)
            this.loading = true;
            return new Promise((resolve, reject) => {
                FollowupService.getAllClosedFollowupCounsellorCount(this.data)
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        // console.log(this.expiredTotalCount)
                        this.getAllClosedFollowupCounsellorPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount,
                            this.data
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllClosedFollowupCounsellorPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            //  console.log("ClosedFollowupCounsellorPageable Calling")
            return new Promise((resolve, reject) => {
                FollowupService.getAllClosedFollowupCounsellorPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        // console.log("this.expiredFollowups",response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getEnquiryFollowup: function (data) {
            // this.resetFollowupUpdate();
            this.updateFor = data;
            // console.log("getEnquiryFollowup",this.updateFor)
            this.getEnquiryFollowupData(this.updateFor);
        },
        getEnquiryFollowupData: function (updateFor) {
            return new Promise((resolve, reject) => {
                // console.log("Call")
                FollowupService.getEnquiryFollowupDateRemark(updateFor.enquiryId)
                    .then((response) => {
                        this.view = {
                            name: updateFor.name,
                            contact: updateFor.contact,
                            alternativeContact: updateFor.alternativeContact,
                            age: updateFor.age,
                            qualification: updateFor.qualification,
                            course: updateFor.course.name,
                            source: updateFor.source.name,
                            mode: updateFor.mode,
                            branch: updateFor.branch.name,
                            // followupDate:updateFor.followupDate,
                            // remark:updateFor.remark
                        };
                        this.followupDateRemark = response.data;
                        // console.log("===",response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorSearchCount: function (counsellorKeyword) {
            this.expiredDate = false;
            this.paginationFollowups = false;
            this.searchPagination = true;
            this.sortCoursePaginationFollowups = false;
            this.sortSourcePaginationFollowups = false;
            this.currentPage = 1;
            // console.log("getCounsellorSearchCount Calling")

            //  console.log(this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            //  console.log("Data",this.data)
            return new Promise((resolve, reject) => {
                FollowupService.getCounsellorSearchCount(
                        counsellorKeyword.toLowerCase(),
                        this.data
                    )
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllCounsellorSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.counsellorKeyword.toLowerCase(),
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorSearchPageable: function (
            currentPage,
            pageCount,
            counsellorKeyword,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.getAllCounsellorSearchPageable(
                        currentPage,
                        pageCount,
                        counsellorKeyword.toLowerCase(),
                        data
                    )
                    .then((response) => {
                        this.followups = response.data;
                        this.followups.forEach((e) => {
                            const followupDate = new Date(e.followupDate);
                            const today = new Date();
                            const zeroStart = new Date(today.setHours(0, 0, 0, 0));
                            if (followupDate > zeroStart) {
                                this.expiredDate = false;
                                this.loading = false;
                            } else if (followupDate < zeroStart) {
                                this.expiredDate = true;
                                this.loading = false;
                            } else {
                                this.loading = false;
                            }
                            this.loading = false;
                        });
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorSortSourceCount: function (selectSource) {
            this.selectCourse = "courses";
            this.selectBranch = "branches";
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = false;
            this.sortSourcePaginationFollowups = true;
            this.sortBranchPaginationFollowups = false;
            this.currentPage = 1;
            // console.log("getCounsellorSortSourceCount calling")
            if (selectSource == "allSources") {
                this.getCounsellorFollowupCount();
            } else {
                this.data = {
                    sourceId: selectSource.sourceId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.getCounsellorSortSourceCount(
                            this.branch.branchId,
                            this.data
                        )
                        .then((response) => {
                            this.totalCount = response.data;
                            this.sortCounsellorSourceAllPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        sortCounsellorSourceAllPageable: function (currentPage, pageCount, data) {
            // console.log("sortCounsellorSourceAllPageable calling")
            return new Promise((resolve, reject) => {
                FollowupService.sortCounsellorSourceAllPageable(
                        currentPage,
                        pageCount,
                        this.branch.branchId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        // console.log(response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorSortCourseCount: function (selectCourse) {
            this.selectSource = "sources";
            this.selectBranch = "branches";
            this.paginationFollowups = false;
            this.searchPagination = false;
            this.sortCoursePaginationFollowups = true;
            this.sortSourcePaginationFollowups = false;
            this.sortBranchPaginationFollowups = false;
            this.currentPage = 1;
            if (selectCourse == "allCourses") {
                this.getCounsellorFollowupCount();
            } else {
                this.data = {
                    courseId: selectCourse.courseId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.getCounsellorSortCourseCount(
                            this.branch.branchId,
                            this.data
                        )
                        .then((response) => {
                            this.totalCount = response.data;
                            this.sortCounsellorCourseAllPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        sortCounsellorCourseAllPageable: function (currentPage, pageCount, data) {
            return new Promise((resolve, reject) => {
                FollowupService.sortCounsellorCourseAllPageable(
                        currentPage,
                        pageCount,
                        this.branch.branchId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.followups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        counsellorFollowupsRefresh: function () {
            // this.getAllFollowups();
            this.getCounsellorFollowupCount();
            this.counsellorKeyword = "";
            this.selectCourse = "courses";
            this.selectSource = "sources";
            this.selectBranch = "branches";
            // this.selectDate = null;
        },
        expiredCounsellorRefresh: function () {
            // this.getAllExpiredFollowups();
            this.getAllClosedFollowupCounsellorCount();
            this.expiredSelectedCourse = "courses";
            this.expiredSelectedSource = "sources";
            this.expiredSelectedBranch = "branches";
            // this.expiredSelectedDate = null;
            this.expiredCounsellorSearchKeyword = "";
        },
        counsellorExpiredSwitchPage: function (
            expiredCurrentPage,
            expiredPageCount
        ) {
            //  console.log("counsellorClosedSwitchPage")
            this.loading = true;

            if (this.expiredPaginationFollowups) {
                this.getAllClosedFollowupCounsellorPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.data
                );
            } else if (this.expiredSortCoursePaginationFollowups) {
                this.expiredCounsellorSortCourseAllPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.data
                );
            } else if (this.expiredSortSourcePaginationFollowups) {
                this.expiredCounsellorSortSourceAllPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.data
                );
            } else if (this.expiredSearchPagination) {
                this.getAllCounsellorExpiredSearchPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.expiredCounsellorSearchKeyword,
                    this.data
                );
            }
        },
        getCounsellorExpiredSearchCount: function (expiredCounsellorSearchKeyword) {
            //  console.log("getCounsellorExpiredSearchCount")
            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = true;
            this.expiredSortCoursePaginationFollowups = false;
            this.expiredSortSourcePaginationFollowups = false;

            this.expiredCurrentPage = 1;
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                FollowupService.getCounsellorExpiredSearchCount(
                        expiredCounsellorSearchKeyword.toLowerCase(),
                        this.data
                    )
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        this.getAllCounsellorExpiredSearchPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount,
                            expiredCounsellorSearchKeyword.toLowerCase(),
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorExpiredSearchPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            expiredCounsellorSearchKeyword,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.getAllCounsellorExpiredSearchPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        expiredCounsellorSearchKeyword.toLowerCase(),
                        data
                    )
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        expiredCounsellorSortCourseCount: function (expiredSelectedCourse) {
            this.expiredSelectedSource = "sources";

            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = true;
            this.expiredSortSourcePaginationFollowups = false;

            this.expiredCurrentPage = 1;
            if (expiredSelectedCourse == "allCourses") {
                this.getAllClosedFollowupCounsellorPageable();
            } else {
                this.data = {
                    courseId: expiredSelectedCourse.courseId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.expiredCounsellorSortCourseCount(
                            this.branch.branchId,
                            this.data
                        )
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            this.expiredCounsellorSortCourseAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredCounsellorSortCourseAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.expiredCounsellorSortCourseAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.branch.branchId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        expiredCounsellorSortSourceCount: function (expiredSelectedSource) {
            this.expiredSelectedCourse = "courses";

            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = false;
            this.expiredSortSourcePaginationFollowups = true;
            this.expiredSortBranchPaginationFollowups = false;
            this.expiredCurrentPage = 1;
            if (expiredSelectedSource == "allSources") {
                this.getAllClosedFollowupCounsellorPageable();
            } else {
                this.data = {
                    sourceId: expiredSelectedSource.sourceId,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    FollowupService.expiredCounsellorSortSourceCount(
                            this.branch.branchId,
                            this.data
                        )
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            this.expiredCounsellorSortSourceAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredCounsellorSortSourceAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                FollowupService.expiredCounsellorSortSourceAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        this.branch.branchId,
                        data
                    )
                    .then((response) => {
                        resolve(response);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
    },
};
</script>

<style scoped>

::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.cellSize {
    min-width: 120px;
}

.dateCell {
    /* min-width: 80px; */
    max-width: 125px;
}
</style>
