import axios from 'axios';

export default {
  getAllFollowups: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/getAllFollowups')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          this.loading = false;
          reject(err);
        });
    });
  },
  getCount: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/count')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllPageable: function (currentPage, pageCount) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/all/' + (currentPage - 1) + "/" + pageCount)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEnquiryCount: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/enquiryCount')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllEnquiryPageable: function (currentEnquiryPage, pageEnquiryCount) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/allEnquiry/' + (currentEnquiryPage - 1) + "/" + pageEnquiryCount)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTableCount: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/tableCount')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllTablePageable: function (currentPage, pageCount) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/tableAll/' + (currentPage - 1) + "/" + pageCount)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  search: function (keyword) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/search/' + keyword)
        .then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  getSearchCount: function (keyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/search/count/" + keyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllSearchPageable: function (currentPage, pageCount, keyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/search/all/" + (currentPage - 1) + "/" + pageCount + "/" + keyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getSearchEnquiryCount: function (enquiryKeyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/enquiry/search/count/" + enquiryKeyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllEnquirySearchPageable: function (currentEnquiryPage, pageEnquiryCount, enquiryKeyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/enquiry/search/all/" + (currentEnquiryPage - 1) + "/" + pageEnquiryCount + "/" + enquiryKeyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sortCourse: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/course", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSortCourseCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/course/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortCourseAllPageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/course/all/" + (currentPage - 1) + "/" + pageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortSource: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/source", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSortSourceCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/source/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortSourceAllPageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/source/all/" + (currentPage - 1) + "/" + pageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortBranch: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/branch", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSortBranchCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/branch/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortBranchAllPageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/branch/all/" + (currentPage - 1) + "/" + pageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortAgent: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/agent", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSortAgentFollowupsCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/agent/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortAgentFollowupsAllPageable: function (currentPage, pageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/sort/agent/all/" + (currentPage - 1) + "/" + pageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //==============================EXPIRED FOLLOWUP=======================================================
  getAllExpiredFollowups: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/expired/getAll')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          this.loading = false;
          reject(err);
        });
    });
  },
  getExpiredCount: function () {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/expired/count')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredFollowupsPageable: function (currentPage, pageCount) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/expired/all/' + (currentPage - 1) + "/" + pageCount)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredFollowupsSearch: function (expiredKeyword) {
    return new Promise((resolve, reject) => {
      axios.post('/adminFollowups/expired/search/' + expiredKeyword)
        .then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredFollowupsSearchCount: function (expiredKeyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/search/count/" + expiredKeyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllExpiredSearchPageable: function (currentPage, pageCount, keyword) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/search/all/" + (currentPage - 1) + "/" + pageCount + "/" + keyword)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortExpiredCourse: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/course", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredSortCourseCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/course/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  expiredSortCourseAllPageable: function (expiredCurrentPage, expiredPageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/course/all/" + (expiredCurrentPage - 1) + "/" + expiredPageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortExpiredSource: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/source", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredSortSourceCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/source/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  expiredSortSourceAllPageable: function (expiredCurrentPage, expiredPageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/source/all/" + (expiredCurrentPage - 1) + "/" + expiredPageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortExpiredBranch: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/branch", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getExpiredSortBranchCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/branch/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  expiredSortBranchAllPageable: function (expiredCurrentPage, expiredPageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/branch/all/" + (expiredCurrentPage - 1) + "/" + expiredPageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortAgentExpiredFollowups: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/agent", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortAgentExpiredFollowupsCount: function (data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/agent/count", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortAgentExpiredFollowupsAllPageable: function (expiredCurrentPage, expiredPageCount, data) {
    return new Promise((resolve, reject) => {
      axios.post("/adminFollowups/expired/sort/agent/all/" + (expiredCurrentPage - 1) + "/" + expiredPageCount, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
}