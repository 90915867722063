<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
body{
  margin: 0;
  padding: 0;
  height:100%;
}

</style>
