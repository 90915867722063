<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
<div>
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
    <b-container>
        <b-modal id="attendanceModal" title="Attendance" ref="attendanceModal" no-close-on-backdrop hide-footer>
            <div class="form-group mb-3">
                <label>Date:</label>
                <div>
                    <b-form-input format="DD-MM-YYYY" v-model="date" type="date" disabled></b-form-input>
                </div>
            </div>
            <div class="form-group mb-3">
                <label>Employees:</label>
                <b-form-select class="edit shadow-sm form-control" v-model="selectedEmployee" :class="{
              'is-invalid': $v.markEmployees.$error,
              'is-valid': !$v.markEmployees.$invalid,
            }">
                    <option value="null" disabled>Select Employee</option>
                    <option v-for="item in unMarkEmployees" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>&nbsp;&nbsp;
                <div v-if="selectedEmployee != null" class="text-center">
                    <b-button style="
                border: 1px solid #406ab2ff;
                border-radius: 1em;
                background-color: #406ab2ff;
              " class="btn-sm shadow-sm rounded-0" @click="saveEmployee"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                </div>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2); border-radius: 1em" class="shadow-sm mt-2" v-if="markEmployees.length != 0">
                    <b-badge style="
                background-color: #ffc107;
                color: #212529;
                padding: 0.5em;
                margin-right: 1em;
                font-family: 'Pavanam', sans-serif;
              " pill v-for="item in markEmployees" v-bind:key="item.index" variant="warning">{{ item.firstName }}&nbsp;<b-icon icon="x" @click="removeEmployee(item)" style="font-weight: bold; cursor: pointer" variant="dark"></b-icon>
                    </b-badge>
                </b-card>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2); border-radius: 1em" class="shadow-sm mt-2" v-else>
                    <div class="text-center text-secondary" style="font-weight: bold">
                        None Of The Branches Added!
                    </div>
                </b-card>
            </div>
            <div class="form-group mt-3">
                <div class="text-center">
                    <div v-if="!markOption">
                        <b-button variant="outline-primary" :disabled="disabledButton" @click="(disabledButton = true), markAllAbsentOtherPresent()">Mark all ABSENT other PRESENT</b-button>
                    </div>
                    <div class="mt-2" v-if="!markOption">
                        <b-button variant="outline-primary" :disabled="disabledButton" @click="(disabledButton = true), markAllAbsent()">Mark all ABSENT</b-button>
                    </div>
                    <div class="mt-2">
                        <b-button variant="outline-primary" :disabled="disabledButton" @click="markOption = !markOption" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="click to toggle options">Mark all as</b-button>
                        <div v-if="markOption">
                            <div class="form-group mt-2">
                                <!-- <label>Status:</label> -->
                                <!-- <b-form-input class="form-control" v-model="attendance.status" disabled>
                                </b-form-input> -->
                                <b-form-select class="edit shadow-sm form-control" v-model="attendance.status" :class="{
                      'is-invalid': $v.attendance.status.$error,
                      'is-valid': !$v.attendance.status.$invalid,
                    }">
                                    <option value="null" disabled>Status</option>
                                    <option value="ABSENT">ABSENT</option>
                                    <option value="PRESENT">PRESENT</option>
                                    <option value="FULLDAYPRESENT">FULLDAYPRESENT</option>
                                    <option value="HALFDAYPRESENT">HALFDAYPRESENT</option>
                                </b-form-select>
                            </div>
                            <div class="mt-2" v-if="attendance.status != null">
                                <b-button variant="outline-primary" :disabled="disabledButton" @click="(disabledButton = true), markAllAsOption()">{{ attendance.status }}</b-button>
                            </div>
                        </div>
                    </div>
                    <!-- <b-button variant="outline-danger" class="mx-3" @click="resetUser()">Reset</b-button> -->
                </div>
            </div>
        </b-modal>
        <b-modal id="viewAttendanceModal" title="View Attendance" ref="viewAttendanceModal" no-close-on-backdrop hide-footer>
            <div class="form-group">
                <label>Employee:</label>
                <b-form-input class="form-control" v-model="attendance.firstName" disabled>
                </b-form-input>
            </div>
            <div class="form-group mt-2">
                <label>Status:</label>
                <b-form-input class="form-control" v-model="attendance.status" disabled>
                </b-form-input>
            </div>
        </b-modal>
        <b-modal id="attendanceUpdateModal" title="Attendance Update" ref="attendanceUpdateModal" no-close-on-backdrop hide-footer>
            <div class="form-group">
                <label>Employee:</label>
                <b-form-input class="form-control" v-model="attendance.firstName" disabled>
                </b-form-input>
            </div>
            <div class="form-group">
                <label>Status:</label>
                <!-- <b-form-input class="form-control" v-model="attendance.status" disabled>
                </b-form-input> -->
                <b-form-select class="edit shadow-sm form-control" v-model="attendance.status" :class="{
              'is-invalid': $v.attendance.status.$error,
              'is-valid': !$v.attendance.status.$invalid,
            }">
                    <!-- <option value="null" disabled>Change Status</option> -->
                    <option value="ABSENT">Absent</option>
                    <option value="PRESENT" disabled>Present</option>
                    <option value="FULLDAYPRESENT">Full day present</option>
                    <option value="HALFDAYPRESENT">Half day present</option>
                    <option value="OUTDOORDUTY">Outdoor Duty</option>
                    <option value="OVERTIME">Overtime</option>
                    <option value="HOLIDAY">Holiday</option>
                </b-form-select>
            </div>
            <div class="form-group mt-3">
                <div class="text-center">
                    <b-button variant="outline-primary" :disabled="disabledButton" @click="(disabledButton = true), attendanceUpdate()">Update</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="$refs.attendanceUpdateModal.hide()">Cancel</b-button>
                </div>
            </div>
        </b-modal>
    </b-container>
    <div class="mt-2">
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="GRAPHS" active>
                GRAPHS
                <b-container class="sticky-top" id="sticky">
                    <b-row>
                        <b-col col lg="2" md="1" id="r1" sm="3">
                            <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getAllSortCount(selectedDates)" :options="dateSelect">
                            </b-form-select>
                        </b-col>
                        <b-col class="text-end" col lg="10" md="1" id="r1" sm="3">
                            <span style="color: #0974ff; cursor: pointer" @click="refreshGraph()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <br />
                <br />
                <b-container style="width: 100%; padding: 0 0 0 0">
                    <b-row class="justify-content-md-center">
                        <b-col>
                            <h6>Employee Present Count</h6>
                            <br />
                            <div v-if="loadAttendancePresentChart" col lg="2">
                                <!-- <div col lg="2"> -->
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData1" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                        <br />
                        <b-col>
                            <h6>Employee Absent Count</h6>
                            <br />
                            <div v-if="loadAttendanceAbsentChart" cols="2" md="auto">
                                <!-- <div col lg="2"> -->
                                <div style="position: relative; height: 80%; width: 80%" id="graph">
                                    <line-chart :chartdata="chartData2" :options="chartOptions" />
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

                <b-modal ref="AttendancedateRange" hide-footer id="dateRange" title="Select Date">
                    <div>
                        <label for="from-datepicker">Choose The From date</label>
                        <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
                    </div>
                    <div>
                        <label for="to-datepicker">Choose The To date</label>
                        <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date" v-on:keyup.enter="doSortingProcess"></b-form-input>
                    </div>
                    <div class="text-center mt-3">
                        <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedDates
            )
          ">Submit</b-button>
                    </div>
                </b-modal>

                <!-- <br />
                <br />
                <h6>Employee Attendance Count</h6>
                <br />

                <div v-if="loadAgentAttendanceChart" col lg="2">
                    <div style="position: relative; height: 100%; width: 100%">
                        <line-chart :chartdata="chartData1" :options="chartOptions1" />
                    </div>
                </div> -->
            </b-tab>
            <b-tab title="TABLE">
                <div class="mt-5">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.attendanceModal @click="resetAttendance" disabled>Attendance</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getAttendanceSearchCount(keyword)" placeholder="Type Name/Code to search" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAttendanceSearchCount(keyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <hr />
                            <b-table responsive hover :items="attendances" :fields="attendanceFields" :current-page="currentPage" :per-page="attendances.pageCount" ref="table">
                                <template v-slot:cell(date)="data">
                                    {{ dateFormat(data.item.date, "date") }}
                                </template>
                                <template v-slot:cell(employee)="data">
                                    {{ data.item.employee.firstName }}
                                    <!-- <font-awesome-icon icon="fa-solid fa-user-secret" /> -->
                                </template>
                                <template v-slot:cell(code)="data">
                                    {{ data.item.employee.code }}
                                </template>
                                <template v-slot:cell(status)="data">
                                    <b-form-select class="edit shadow-sm form-control" v-model="data.value" :value="data.value" @change="userAttendanceUpdate(data.item,data.value)">
                                        <option value="null">Status</option>
                                        <option value="ABSENT">ABSENT</option>
                                        <option value="PRESENT">PRESENT</option>
                                        <option value="FULLDAYPRESENT">FULLDAYPRESENT</option>
                                        <option value="HALFDAYPRESENT">HALFDAYPRESENT</option>
                                    </b-form-select>
                                    <!-- <span v-if="data.item.status == 'ABSENT'">
                                        <b-badge style="
                          background-color: #ff0000;
                          color: #fff;
                          padding: 0.5em;
                          margin-right: 1em;
                          font-family: 'Pavanam', sans-serif;
                        " variant="warning">{{ data.item.status }}
                                        </b-badge>
                                    </span>
                                    <span v-else>
                                        <b-badge style="
                          background-color: #228b22;
                          color: #fff;
                          padding: 0.5em;
                          margin-right: 1em;
                          font-family: 'Pavanam', sans-serif;
                        " variant="warning">{{ data.item.status }}
                                        </b-badge>
                                    </span> -->
                                </template>
                                <template v-slot:cell(present)="data">
                                    <span v-if="data.item.present">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(absent)="data">
                                    <span v-if="data.item.absent">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(fullDayPresent)="data">
                                    <span v-if="data.item.fullDayPresent">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(halfDayPresent)="data">
                                    <span v-if="data.item.halfDayPresent">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(outdoorDuty)="data">
                                    <span v-if="data.item.outdoorDuty">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(overTime)="data">
                                    <span v-if="data.item.overTime">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(holiday)="data">
                                    <span v-if="data.item.holiday">
                                        <svg style="width: 20px; fill: green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                        </svg>
                                    </span>
                                    <span v-else>
                                        <svg style="width: 20px; fill: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                        </svg>
                                    </span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Attendance" @click="viewAttendanceShow(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Attendance" @click="attendanceUpdateShow(data.item)">
                                        <font-awesome-icon icon="edit" />
                                    </b-button> -->
                                    <!-- <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button>
                                    <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                                        <font-awesome-icon icon="archive" />
                                    </b-button> -->
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</div>
</template>

<script>
import AttendanceService from "../service/AttendanceService";
import Account from "../service/Account";
import moment from "moment-timezone";
import LineChart from "../Charts/LineChart";
import axios from "axios";
import {
    required,
    // minLength,
    // maxLength,
    // email,
} from "vuelidate/lib/validators";

export default {
    name: "Attendance",
    components: {
        LineChart,
    },
    data() {
        return {
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            totalCount: 0,
            keyword: "",
            attendance: {
                attendanceId: "",
                firstName: "",
                status: null,
            },
            attendances: [],
            attendanceFields: [{
                    key: "date",
                    label: "Date",
                },
                {
                    key: "employee",
                    label: "Employee",
                },
                {
                    key: "code",
                    label: "code",
                },
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "present",
                    label: "Present",
                },
                {
                    key: "absent",
                    label: "Absent",
                },
                {
                    key: "fullDayPresent",
                    label: "FullDay",
                },
                {
                    key: "halfDayPresent",
                    label: "HalfDay",
                },
                // {
                //     key: "outdoorDuty",
                //     label: "OD",
                // },
                // {
                //     key: "overTime",
                //     label: "OT",
                // },
                // {
                //     key: "holiday",
                //     label: "Holiday",
                // },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],
            date: "",
            selectedEmployee: null,
            markEmployees: [],
            unMarkEmployees: [],
            disabledButton: false,
            markOption: false,
            attendancePageable: false,
            attendanceSearchPageable: false,
            selectedDates: null,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                // {
                //     value: "today",
                //     text: "Today",
                // },
                // {
                //     value: "yesterday",
                //     text: "Yesterday",
                // },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "lastThreeMonths",
                    text: "Past 90 days",
                },
                {
                    value: "allData",
                    text: "Past all days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true,
                },
            ],
            from: "",
            to: "",

            loadAttendancePresentChart: false,
            loadAttendanceAbsentChart: false,
            // loadAgentAttendanceChart: false,

            chartData1: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartData2: {
                labels: [],
                datasets: [{
                    label: "Data One",
                    backgroundColor: "#0dcaf0",
                    data: [],
                }, ],
            },

            chartOptions: {
                responsive: true,
            },
            status: "",
            attendanceStatus: [{
                    text: "Status",
                    value: "null",
                    disabled: true
                },
                {
                    text: "ABSENT",
                    value: "ABSENT"
                },
                {
                    text: "PRESENT",
                    value: "PRESENT"
                },
                {
                    text: "FULLDAYPRESENT",
                    value: "FULLDAYPRESENT"
                },
                {
                    text: "HALFDAYPRESENT",
                    value: "HALFDAYPRESENT"
                },
            ],
            //   chartData1: {
            //     labels: [],
            //     datasets: [
            //       {
            //         label: "Data One",
            //         backgroundColor: "#0dcaf0",
            //         data: [],
            //       },
            //     ],
            //   },

            //   chartOptions1: {
            //     responsive: true,
            //     maintainAspectRatio: false,
            //     // legend: {
            //     //     display: true,
            //     //     position: 'top',
            //     //     labels: {
            //     //         fontColor: '#333'
            //     //     }
            //     // }
            //     legend: {
            //       position: "top",
            //       labels: {
            //         // fontColor: 'rgb(255, 99, 132)'
            //         fontColor: "#333",
            //       },
            //       // onHover: function() {
            //       //   document.getElementById("canvas").style.cursor = 'pointer';
            //       // },
            //       onClick: function (e, legendItem) {
            //         var index = legendItem.datasetIndex;
            //         var ci = this.chart;
            //         var alreadyHidden =
            //           ci.getDatasetMeta(index).hidden === null
            //             ? false
            //             : ci.getDatasetMeta(index).hidden;

            //         ci.data.datasets.forEach(function (e, i) {
            //           var meta = ci.getDatasetMeta(i);

            //           if (i !== index) {
            //             if (!alreadyHidden) {
            //               meta.hidden = meta.hidden === null ? !meta.hidden : null;
            //             } else if (meta.hidden === null) {
            //               meta.hidden = true;
            //             }
            //           } else if (i === index) {
            //             meta.hidden = null;
            //           }
            //         });

            //         ci.update();
            //       },
            //     },
            //   },
        };
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAttendanceCount();
            }
        },
        date(newVal, oldVal) {
            if (oldVal !== !newVal) {
                // this.getAllUnmarkEmployee();
            }
        },
    },
    validations: {
        markEmployees: {
            required,
        },
        attendance: {
            status: {
                required,
            },
        },
        date: {
            required,
        },
        from: {
            required,
        },
        to: {
            required,
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    var currentDate = new Date();
                    this.date = moment(currentDate).format("YYYY-MM-DD");
                    this.getGraphAndTableData();
                    // this.getAgentAttendanceGraphCount();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        async getGraphAndTableData() {
            await this.markAllWithFullDayPresent();
            await this.getAttendanceCount();
            await this.getAttendancePresentGraphCount();
            await this.getAttendanceAbsentGraphCount();
        },
        markAllWithFullDayPresent: function() {
            console.log("markAllWithFullDayPresent======",this.date);
            let data = {
                date: this.date,
            };
            return new Promise((resolve, reject) => {
                AttendanceService.markAllWithFullDayPresent(data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        userAttendanceUpdate(data, status) {
            let present = false;
            let absent = false;
            let fullDayPresent = false;
            let halfDayPresent = false;
            let attendanceId = data.attendanceId;
            let date = data.date;
            if (status == "PRESENT") {
                present = true;
                fullDayPresent = false;
                halfDayPresent = false
            } else if (status == "ABSENT") {
                absent = true;
                // present = false;
                // fullDayPresent = false;
                // halfDayPresent = false
            } else if (status == "FULLDAYPRESENT") {
                present = true;
                fullDayPresent = true;
            } else if (status == "HALFDAYPRESENT") {
                present = true;
                halfDayPresent = true;
            }
            for (var i = 0; i < this.attendances.length; i++) {
                if (this.attendances[i].attendanceId == data.attendanceId) {
                    this.attendances[i].status = status,
                    this.attendances[i].fullDayPresent = fullDayPresent,
                    this.attendances[i].halfDayPresent = halfDayPresent,
                    this.attendances[i].present = present
                    this.attendances[i].absent = absent
                }
            }
            let updateData = {
                attendanceId: attendanceId,
                date: date,
                status: status,
                absent: absent,
                present: present,
                fullDayPresent: fullDayPresent,
                halfDayPresent: halfDayPresent,
                outdoorDuty: false,
                overTime: false,
                holiday: false,
            };
            return new Promise((resolve, reject) => {
                AttendanceService.updateAttendance(updateData)
                    .then((response) => {
                        // this.$refs.attendanceUpdateModal.hide();
                        // this.disabledButton = false;
                        // this.getAttendanceCount();
                        this.$swal({
                            title: "Attendance Updated",
                            text: "Employee Attendance Updated successfully...",
                            icon: "success",
                            button: "ok",
                        });
                        // this.resetAttendance();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllUnmarkEmployee: function () {
            this.$v.date.$touch();
            if (!this.$v.date.$invalid) {
                this.unMarkEmployees.splice(0);
                let data = {
                    date: this.date,
                };
                return new Promise((resolve, reject) => {
                    AttendanceService.getAllUnmarkEmployee(data)
                        .then((response) => {
                            this.unMarkEmployees = response.data;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "date is invalid..!",
                });
            }
        },
        viewAttendanceShow(attendance) {
            this.$refs.viewAttendanceModal.show();
            this.attendance = {
                attendanceId: attendance.attendanceId,
                firstName: attendance.employee.firstName,
                status: attendance.status,
            };
        },
        attendanceUpdateShow(attendance) {
            this.$refs.attendanceUpdateModal.show();
            this.attendance = {
                attendanceId: attendance.attendanceId,
                firstName: attendance.employee.firstName,
                status: attendance.status,
            };
        },
        // attendanceUpdate() {
        //     this.$v.date.$touch();
        //     this.$v.attendance.status.$touch();
        //     if (!this.$v.attendance.status.$invalid && !this.$v.date.$invalid) {
        //         console.log("attendanceUpdate==========");
        //         let absent = false;
        //         let present = false;
        //         let fullDayPresent = false;
        //         let halfDayPresent = false;
        //         let outdoorDuty = false;
        //         let overTime = false;
        //         let holiday = false;
        //         if (this.attendance.status == "ABSENT") {
        //             absent = true;
        //         } else if (this.attendance.status == "PRESENT") {
        //             present = true;
        //         } else if (this.attendance.status == "FULLDAYPRESENT") {
        //             fullDayPresent = true;
        //         } else if (this.attendance.status == "HALFDAYPRESENT") {
        //             halfDayPresent = true;
        //         } else if (this.attendance.status == "OUTDOORDUTY") {
        //             outdoorDuty = true;
        //         } else if (this.attendance.status == "OVERTIME") {
        //             overTime = true;
        //         } else if (this.attendance.status == "HOLIDAY") {
        //             holiday = true;
        //         }
        //         let data = {
        //             attendanceId: this.attendance.attendanceId,
        //             date: this.date,
        //             absent: absent,
        //             present: present,
        //             fullDayPresent: fullDayPresent,
        //             halfDayPresent: halfDayPresent,
        //             outdoorDuty: outdoorDuty,
        //             overTime: overTime,
        //             holiday: holiday,
        //         };
        //         return new Promise((resolve, reject) => {
        //             AttendanceService.updateAttendance(data)
        //                 .then((response) => {
        //                     this.$refs.attendanceUpdateModal.hide();
        //                     this.disabledButton = false;
        //                     this.getAttendanceCount();
        //                     this.$swal({
        //                         title: "Attendance Updated",
        //                         text: "Employee Attendance Updated successfully...",
        //                         icon: "success",
        //                         button: "ok",
        //                     });
        //                     this.resetAttendance();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     }
        //     this.disabledButton = false;
        // },
        markAllAbsentOtherPresent: function () {
            this.$v.date.$touch();
            this.$v.markEmployees.$touch();
            if (!this.$v.markEmployees.$invalid && !this.$v.date.$invalid) {
                let users = [];
                this.markEmployees.forEach((e) => {
                    users.push(e.userId);
                });
                let data = {
                    date: this.date,
                    users: users,
                };
                return new Promise((resolve, reject) => {
                    AttendanceService.markAllAbsentOtherPresent(data)
                        .then((response) => {
                            this.$refs.attendanceModal.hide();
                            this.disabledButton = false;
                            this.getAttendanceCount();
                            this.$swal({
                                title: "Attendance Generate",
                                text: "Mark Employees as ABSENT other PRESENT",
                                icon: "success",
                                button: "ok",
                            });
                            this.resetAttendance();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        markAllAbsent: function () {
            this.$v.date.$touch();
            this.$v.markEmployees.$touch();
            if (!this.$v.markEmployees.$invalid && !this.$v.date.$invalid) {
                let users = [];
                this.markEmployees.forEach((e) => {
                    users.push(e.userId);
                });
                let data = {
                    date: this.date,
                    users: users,
                };
                return new Promise((resolve, reject) => {
                    AttendanceService.markAllAbsent(data)
                        .then((response) => {
                            this.$refs.attendanceModal.hide();
                            this.disabledButton = false;
                            this.getAttendanceCount();
                            this.$swal({
                                title: "Attendance Generate",
                                text: "Mark Employees as ABSENT",
                                icon: "success",
                                button: "ok",
                            });
                            this.resetAttendance();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        markAllAsOption: function () {
            this.$v.markEmployees.$touch();
            this.$v.attendance.status.$touch();
            if (
                !this.$v.markEmployees.$invalid &&
                !this.$v.attendance.status.$invalid
            ) {
                console.log("attendanceUpdate==========");
                let absent = false;
                let present = false;
                let fullDayPresent = false;
                let halfDayPresent = false;
                let outdoorDuty = false;
                let overTime = false;
                let holiday = false;
                if (this.attendance.status == "ABSENT") {
                    absent = true;
                } else if (this.attendance.status == "PRESENT") {
                    present = true;
                } else if (this.attendance.status == "FULLDAYPRESENT") {
                    fullDayPresent = true;
                } else if (this.attendance.status == "HALFDAYPRESENT") {
                    halfDayPresent = true;
                } else if (this.attendance.status == "OUTDOORDUTY") {
                    outdoorDuty = true;
                } else if (this.attendance.status == "OVERTIME") {
                    overTime = true;
                } else if (this.attendance.status == "HOLIDAY") {
                    holiday = true;
                }
                let users = [];
                this.markEmployees.forEach((e) => {
                    users.push(e.userId);
                });
                let data = {
                    users: users,
                    date: this.date,
                    absent: absent,
                    present: present,
                    fullDayPresent: fullDayPresent,
                    halfDayPresent: halfDayPresent,
                    outdoorDuty: outdoorDuty,
                    overTime: overTime,
                    holiday: holiday,
                };
                return new Promise((resolve, reject) => {
                    AttendanceService.markAllAsOption(data)
                        .then((response) => {
                            this.$refs.attendanceModal.hide();
                            this.disabledButton = false;
                            this.getAttendanceCount();
                            this.$swal({
                                title: "Attendance Generate",
                                text: "Mark Employees as " + this.attendance.status,
                                icon: "success",
                                button: "ok",
                            });
                            this.resetAttendance();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        getAttendanceCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.attendancePageable = true;
            this.attendanceSearchPageable = false;
            return new Promise((resolve, reject) => {
                AttendanceService.getAttendanceCount(this.date)
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAttendancePageable(
                            this.currentPage,
                            this.pageCount,
                            this.date
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            if (this.attendancePageable) {
                this.getAllAttendancePageable(currentPage, pageCount, this.date);
            } else if (this.attendanceSearchPageable) {
                this.getAllAttendanceSearchPageable(
                    currentPage,
                    pageCount,
                    this.date,
                    this.keyword.toLowerCase()
                );
            }
        },
        getAllAttendancePageable: function (currentPage, pageCount, date) {
            return new Promise((resolve, reject) => {
                AttendanceService.getAllAttendancePageable(currentPage, pageCount, date)
                    .then((response) => {
                        this.attendances = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAttendanceSearchCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.attendancePageable = false;
            this.attendanceSearchPageable = true;
            return new Promise((resolve, reject) => {
                AttendanceService.getAttendanceSearchCount(
                        this.date,
                        this.keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAttendanceSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.date,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAttendanceSearchPageable: function (
            currentPage,
            pageCount,
            date,
            keyword
        ) {
            return new Promise((resolve, reject) => {
                AttendanceService.getAllAttendanceSearchPageable(
                        currentPage,
                        pageCount,
                        date,
                        keyword
                    )
                    .then((response) => {
                        this.attendances = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        resetAttendance() {
            this.getAllUnmarkEmployee();
            this.markEmployees = [];
            this.selectedEmployee = null;
            this.disabledButton = false;
            this.markOption = false;
            this.attendance = {
                status: null,
                date: this.date,
            };
            this.$nextTick(() => {
                this.$v.markEmployees.$reset();
                this.$v.attendance.status.$reset();
            });
        },
        refresh() {
            this.currentPage = 1;
            if (this.keyword != "") {
                this.keyword = "";
            } else {
                this.getAttendanceCount();
            }
        },
        removeEmployee: function (item) {
            const langIndex = this.markEmployees.findIndex((x) => x === item);
            if (langIndex !== undefined) this.markEmployees.splice(langIndex, 1);
            this.unMarkEmployees.push(item);
        },
        saveEmployee: function () {
            if (
                !this.markEmployees.some((entry) => entry === this.selectedEmployee)
            ) {
                let employee = null;
                for (var i = 0; i < this.unMarkEmployees.length; i++) {
                    if (this.unMarkEmployees[i] == this.selectedEmployee) {
                        this.markEmployees.push(this.unMarkEmployees[i]);
                        employee = this.unMarkEmployees[i];
                        // this.unMarkEmployees.splice([i], 1);
                    }
                }
                this.unMarkEmployees = this.unMarkEmployees.filter(function (e) {
                    return e !== employee;
                });
            }
            this.selectedEmployee = null;
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "date") {
                return moment(date1).format("D / MM / YYYY");
            }
        },

        getAllSortCount: function (selectedDates) {
            this.getAllSortPresentCount(selectedDates);
            this.getAllSortAbsentCount(selectedDates);
        },

        getAttendancePresentGraphCount: function () {
            this.loadAttendancePresentChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/attendance/present/count", this.data)
                    .then((response) => {
                        // console.log("getPresentDateSortCount======", response.data);
                        this.totalGraphCount = response.data;
                        // this.getAllAttendancePageable(this.currentPage, this.pageCount);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Attendance Present",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "rgba(0, 200, 255, 0.2)",
                                borderColor: "rgb(0,200,255, 0.6)",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData1 = null;
                        // console.log("The insight attendance data is ", insightData);
                        this.chartData1 = insightData;
                        // console.log("The attendance chart data is ", this.chartData1);
                        this.loadAttendancePresentChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAttendanceAbsentGraphCount: function () {
            this.loadAttendanceAbsentChart = false;
            var from;
            var to;
            var date = new Date();
            this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
            this.toDate = new Date(date.setHours(167, 59, 59, 0));

            this.fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            this.toDateFormat = moment(String(this.toDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let fromDateFormat = moment(String(this.fromDate)).format(
                "yyyy-MM-DD HH:mm"
            );
            let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
            from = fromDateFormat;
            to = toDateFormat;
            var dateOffset = 6;
            this.data = {
                from: from,
                to: to,
                offset: dateOffset,
            };
            this.loading = true;
            // console.log("this.data====== ", this.data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/attendance/absent/count", this.data)
                    .then((response) => {
                        // console.log("getAbsentDateSortCount======", response.data);
                        this.totalGraphCount = response.data;
                        // this.getAllAttendancePageable(this.currentPage, this.pageCount);
                        let insightData = {
                            labels: [],
                            datasets: [{
                                label: "Attendance Absent",
                                // backgroundColor: "#f87979",
                                // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                // borderColor: "rgb(0,200,255, 0.6)",
                                backgroundColor: "rgba(255,0,0,0.2)",
                                borderColor: "rgb(255,0,0, 0.6)",
                                data: [],
                            }, ],
                        };
                        response.data.forEach((e) => {
                            // console.log(e);
                            insightData.labels.push(e[0]);
                            insightData.datasets[0].data.push(e[1]);
                        });
                        // this.datacollection.datasets = null;
                        // console.log("Bar Chart", insightData);
                        this.chartData2 = null;
                        // console.log("The insight attendance data is ", insightData);
                        this.chartData2 = insightData;
                        // console.log("The attendance chart data is ", this.chartData2);
                        this.loadAttendanceAbsentChart = true;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        //         getAgentAttendanceGraphCount: function () {
        //             this.loadAgentAttendanceChart = false;
        //             var from;
        //             var to;
        //             var date = new Date();
        //             this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
        //             this.toDate = new Date(date.setHours(167, 59, 59, 0));

        //             this.fromDateFormat = moment(String(this.fromDate)).format(
        //                 "yyyy-MM-DD HH:mm"
        //             );
        //             this.toDateFormat = moment(String(this.toDate)).format(
        //                 "yyyy-MM-DD HH:mm"
        //             );
        //             let fromDateFormat = moment(String(this.fromDate)).format(
        //                 "yyyy-MM-DD HH:mm"
        //             );
        //             let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
        //             from = fromDateFormat;
        //             to = toDateFormat;
        //             var dateOffset = 6;
        //             this.data = {
        //                 from: from,
        //                 to: to,
        //                 offset: dateOffset,
        //             };
        //             this.loading = true;
        //             // console.log("this.data====== ", this.data);
        //             return new Promise((resolve, reject) => {
        //                 axios
        //                     .post("/attendance/agentAttendance/sort/count", this.data)
        //                     .then((response) => {
        //                         // console.log("getAgentAttendanceGraphCount======", response.data);
        //                         this.totalGraphCount = response.data;

        //                         // let insightData = {
        //                         //   labels: response.data.employeeNames,
        //                         //   datasets: [
        //                         //     {
        //                         //       label: [],
        //                         //       // backgroundColor: "#f87979",
        //                         //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
        //                         //       // borderColor: "rgb(0,200,255, 0.6)",
        //                         //       // backgroundColor: "rgba(0, 200, 255, 0.2)",
        //                         //       fill: false,
        //                         //       borderColor: "rgb(0,200,255, 0.6)",
        //                         //       data: [],
        //                         //     },
        //                         //   ],
        //                         // };

        //                         let insightData = {
        //                             labels: response.data.dates,
        //                             datasets: [],
        //                         };

        //                         for (let i = 0; i < response.data.employeeNames.length; i++) {
        //                             let attendanceData = [];

        //                             response.data.employeesData[i].forEach((e) => {
        //                                 attendanceData.push(e[0]);
        //                             });

        //                             let gdata = {
        //                                 fill: false,
        //                                 borderColor: this.generateRandomColor(),
        //                                 label: response.data.employeeNames[i],
        //                                 data: attendanceData,
        //                             };
        //                             //  console.log(this.generateRandomColor(),"=======");
        //                             //        this.randomColor = this.generateRandomColor();
        //                             // console.log(this.randomColor,"=======");
        //                             // if(gdata.borderColor != null){
        //                             //   // insightData.datasets.push(gdata);

        //                             // }
        //                             insightData.datasets.push(gdata);
        //                             // console.log(insightData, "=======");
        //                             // let color = this.generateRandomColor();
        //                             // let color = gdata.borderColor;
        //                             // console.log(color, "=======");
        //                             // this.randomColor.push(color);

        //                             // console.log(color,"=======");
        //                         }

        //                         /*
        //             response.data.forEach((e) => {
        //               console.log(e[1]);
        //               insightData.labels.push(e[0]);
        //               // insightData.datasets[0].label.push(e[1]);
        //               insightData.datasets[0].data.push(e[1]);
        //             });
        //             // this.datacollection.datasets = null;
        // */
        //                         // console.log("Bar Chart", insightData);
        //                         this.chartData1 = null;
        //                         // console.log("The insight Employee data is ", insightData);
        //                         this.chartData1 = insightData;
        //                         // console.log("The Attendance Employee data is ", this.chartData1);
        //                         this.loadAgentAttendanceChart = true;
        //                         this.loading = false;
        //                         resolve(response);
        //                     })
        //                     .catch((err) => {
        //                         reject(err);
        //                     });
        //             });
        //         },

        generateRandomColor: function () {
            let maxVal = 0xffffff; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            // this.randomColor = randColor;
            // console.log(this.randomColor,"=======");
            return `#${randColor.toUpperCase()}`;
        },
        refreshGraph: function () {
            this.selectedDates = null;
            this.getAttendancePresentGraphCount();
            this.getAttendanceAbsentGraphCount();
        },

        getAllSortPresentCount: function (
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.AttendancedateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.AttendancedateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.AttendancedateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }
                this.data = {
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAttendancePresentChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/attendance/present/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortPresentCount======", response.data);
                            this.totalGraphCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Attendance Present",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    borderColor: "rgb(0,200,255, 0.6)",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Line Chart", insightData);
                            this.chartData1 = null;
                            // console.log("The insight Present data is ", insightData);
                            this.chartData1 = insightData;
                            // console.log("The Present chart data is ", this.chartData1);
                            this.loadAttendancePresentChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortAbsentCount: function (
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.AttendancedateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-143, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-695, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (selectedDates == "lastThreeMonths") {
                        this.fromDate = new Date(date.setHours(-2135, -59, -59, 0));
                        this.toDate = new Date(date.setHours(2159, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.AttendancedateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.AttendancedateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                var d1 = new Date("01/12/2021");
                var d2 = new Date();
                // console.log("this Current date====== ", new Date());
                var diff = d2.getTime() - d1.getTime();
                this.daydiff = (diff / 315360000).toFixed(0);
                // console.log("this diffrent days====== ", this.daydiff);

                var dateOffset = 0;
                if (selectedDates == "lastWeek") {
                    dateOffset = 6;
                } else if (selectedDates == "lastMonth") {
                    dateOffset = 29;
                } else if (selectedDates == "lastThreeMonths") {
                    dateOffset = 89;
                } else if (selectedDates == "allData") {
                    let fromDate = "2021-12-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                    dateOffset = this.daydiff;
                    // console.log("this diffrent days====== ", this.daydiff);
                }
                this.data = {
                    from: from,
                    to: to,
                    offset: dateOffset,
                };
                this.loading = true;
                this.loadAttendanceAbsentChart = false;
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/attendance/absent/all/sort/count", this.data)
                        .then((response) => {
                            //   console.log("getAllSortAbsentCount======", response.data);
                            this.totalGraphCount = response.data;
                            let insightData = {
                                labels: [],
                                datasets: [{
                                    label: "Attendance Absent",
                                    // backgroundColor: "#f87979",
                                    // backgroundColor: "rgba(0, 200, 255, 0.2)",
                                    // borderColor: "rgb(0,200,255, 0.6)",
                                    backgroundColor: "rgba(255,0,0,0.2)",
                                    borderColor: "rgb(255,0,0, 0.6)",
                                    data: [],
                                }, ],
                            };
                            response.data.forEach((e) => {
                                // console.log(e);
                                insightData.labels.push(e[0]);
                                insightData.datasets[0].data.push(e[1]);
                            });
                            // this.datacollection.datasets = null;
                            // console.log("Line Chart", insightData);
                            this.chartData2 = null;
                            // console.log("The insight Absent data is ", insightData);
                            this.chartData2 = insightData;
                            // console.log("The Absent chart data is ", this.chartData2);
                            this.loadAttendanceAbsentChart = true;
                            this.loading = false;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getDateRangeReset: function () {
            this.$refs.AttendancedateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}

#sticky {
    /* position: fixed; */
    /* top: 0; */
    width: 100%;
    /* padding-left: 10%; */
    /* margin-right: -10%; */
    background-color: rgba(197, 197, 197, 0.664);
}

@media screen and (max-width: 749.98px) {
    #graph {
        padding-left: 8%;
    }
}
</style>
