<template>
<div>
    <div>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
    </div>
    <b-container>
        <!-- Popup modal for user role privileges START -->
        <b-modal hide-footer hide-header size="md" id="multiTeamPrivilegeModal" ref="multiTeamPrivilegeModal" no-close-on-backdrop>
            <b-container fluid>
                <div class="text-center" style="color:#4274d6;"><i class="fa fa-user-plus fa-1x" aria-hidden="true"></i></div>
                <div class="text-center" style="color:#4274d6; font-weight:bold;">Multi team previleges</div><br>
                <b-tabs pills card content-class="mt-3" justified>
                    <b-tab title="Add">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Team</label>
                                <b-form-select class="form-control" v-model="selectedTeam" :class="{'is-invalid': $v.selectedTeam.$error,'is-valid': !$v.selectedTeam.$invalid,}">
                                    <option value="null" disabled>Select Team</option>
                                    <option v-for="t in userNotAddedTeams" :value="t.teamId" v-bind:key="t.teamId">
                                        <strong>{{ t.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <b-button variant="success" :disabled="disabledButton" @click="disabledButton = true,userAddedTeam()">Add</b-button>
                            <b-button class="mx-2" variant="warning" @click="$refs.multiTeamPrivilegeModal.hide()">Cancel</b-button>
                        </div>
                    </b-tab>
                    <b-tab title="Remove">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Team</label>
                                <b-form-select class="form-control" v-model="selectedTeam" :class="{'is-invalid': $v.selectedTeam.$error,'is-valid': !$v.selectedTeam.$invalid,}">
                                    <option value="null" disabled>Select Team</option>
                                    <option v-for="t in userAddedTeams" :value="t.teamId" v-bind:key="t.teamId">
                                        <strong>{{ t.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <b-button variant="danger" :disabled="disabledButton" @click="disabledButton = true,userRemoveTeam()">Remove</b-button>
                            <b-button class="mx-2" variant="warning" @click="$refs.multiTeamPrivilegeModal.hide()">Cancel</b-button>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-container>
        </b-modal>
        <!-- Popup modal for user role privileges END-->
    </b-container>
    <!-- User Table Start -->

    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!--add Tutor button start-->

                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" v-b-modal.addUserModal @click="resetUser(),getAllRoles(),getAllBranches()" disabled><span class="px-3">User</span></b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="allUserSearch(keyword)" placeholder="Email/Mobile/Username to search" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="allUserSearch(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage()"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage()" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <b-col col sm="6" md="2" lg="2" id="r1">
                    <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="primary">
                        <font-awesome-icon icon="user" /> Total Users : {{totalCount}}
                    </b-btn>
                </b-col>
                <hr>
                <b-table responsive hover :items="users" :fields="userFields" :current-page="currentPage" :per-page="users.pageCount" ref="table">
                    <template v-slot:cell(firstName)="data">
                        <span>{{ data.item.firstName }}</span>&nbsp;<span>{{ data.item.lastName }}</span>
                    </template>
                    <template v-slot:cell(username)="data">
                        {{ data.item.username }}
                    </template>
                    <template v-slot:cell(createdAt)="data">
                        <div class="cellSize">
                            {{ formatDate(data.item.createdAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(email)="data">
                        {{ data.item.email[0] }}
                    </template>
                    <template v-slot:cell(mobile)="data">
                        {{ data.item.mobile[0] }}
                    </template>
                    <template v-slot:cell(archive)="data">
                        <div class="cellSize">
                            <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="success" v-if="!data.item.archive">
                                <font-awesome-icon icon="check" /> Activated
                            </b-btn>
                            <b-btn class="btn-sm activate-btn shadow irresponsive_action" variant="danger" v-if="data.item.archive">
                                <font-awesome-icon icon="cross" /> Deactivated
                            </b-btn>
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" class="mx-1" variant="outline-secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Multi team" @click="multiTeamPrivilegeModalShow(data.item),resetBranchSelect()">
                            <font-awesome-icon icon="user" />
                        </b-button>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>
</div>
</template>

<script>
import Account from '../service/Account.js';
import moment from "moment-timezone";
import {
    required,
    minLength,
    // maxLength,
    email
} from "vuelidate/lib/validators";
import AgentService from "../service/AgentService";
import BranchService from "../service/BranchService";
import axios from "axios";

export default {
    name: "User",
    components: {},
    data() {
        return {
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalCount: 0,
            keyword: "",
            user: {
                firstName: "",
                lastName: "",
                username: "",
                contact: "",
                mobile: "",
                role: null,
            },
            allBranches: [],
            allBranchesBranch: [],
            selectedBranch: null,
            branch: null,
            branches: [],
            users: [],
            userFields: [{
                    key: "firstName",
                    label: "Name",
                },
                {
                    key: "username",
                    label: "Username",
                },
                {
                    key: "createdAt",
                    label: "CreatedAt",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "mobile",
                    label: "Mobile",
                },
                {
                    key: "archive",
                    label: "status",
                },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],
            disabledButton: false,
            allRoles: [],
            addedUserRoles: [],
            selectedUserId: "",
            notAddedUserRoles: [],
            addSelectedRoles: [],
            deleteSelectedRoles: [],
            updateUser: {
                userId: "",
                firstName: "",
                lastName: "",
                contact: "",
                mobile: "",
            },
            teams: [],
            selectedTeam: null,
            userAddedTeams: [],
            userNotAddedTeams: [],
        };
    },
    validations: {
        user: {
            firstName: {
                required,
                minLength: minLength(3),
            },
            username: {
                required,
                minLength: minLength(6),
            },
            mobile: {
                // Number,
                // minLength: minLength(10),
                // maxLength: maxLength(10),
            },
            contact: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            },
            role: {
                required
            }
        },
        branch: {
            required
        },
        branches: {
            required
        },
        updateUser: {
            firstName: {
                required,
                minLength: minLength(3),
            },
        },
        selectedTeam: {
            required
        }
    },
    mounted() {
        this.loading = true;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.getAllUserCount();
                    // this.getAllRoles();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllUserCount();
            }
        },
    },
    methods: {
        teamUserCreate: function () {
            this.disabledButton = true;
            this.$v.user.$touch();
            this.$v.branch.$touch();
            this.$v.branches.$touch();
            if (!this.$v.user.$invalid) {
                console.log("if===========");
                if (this.user.role.roleName == 'ROLE_COUNSELLOR' || this.user.role.roleName == 'ROLE_SUPERVISOR') {
                    if (!this.$v.branch.$invalid) {
                        return new Promise((resolve, reject) => {
                            Account.teamUserCreate(this.user)
                                .then((response) => {
                                    this.disabledButton = false;
                                    if (response.data != null) {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    }
                                    resolve(response);
                                })
                                .catch((err) => {
                                    if (err.response.status == 409) {
                                        console.log("if=========", err.response);
                                        this.$swal({
                                            title: err.response.data,
                                            icon: "error",
                                            button: "ok",
                                        });
                                    }
                                    this.disabledButton = false;
                                    reject(err);
                                });
                        });
                    }
                } else if (this.user.role.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    if (!this.$v.branches.$invalid) {
                        return new Promise((resolve, reject) => {
                            Account.teamUserCreate(this.user)
                                .then((response) => {
                                    this.disabledButton = false;
                                    if (response.data != null) {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    }
                                    resolve(response);
                                })
                                .catch((err) => {
                                    if (err.response.status == 409) {
                                        // console.log("if=========");
                                        this.$swal({
                                            title: err.response.data,
                                            icon: "error",
                                            button: "ok",
                                        });
                                    }
                                    this.disabledButton = false;
                                    reject(err);
                                });
                        });
                    }
                } else {
                    return new Promise((resolve, reject) => {
                        Account.teamUserCreate(this.user)
                            .then((response) => {
                                this.disabledButton = false;
                                if (response.data != null) {
                                    if (this.user.role.roleName == 'ROLE_TELECALLER' || this.user.role.roleName == 'ROLE_ADMIN') {
                                        this.getUserInfo(response.data.username);
                                        this.$refs.addUserModal.hide();
                                    } else {
                                        this.$refs.addUserModal.hide();
                                        this.$swal({
                                            title: "Account Created",
                                            text: "Confirmation mail send to the email",
                                            icon: "success",
                                            button: "ok",
                                        });
                                        this.disabledButton = false;
                                        this.resetUser();
                                        this.getAllUserCount();
                                    }
                                }
                                resolve(response);
                            })
                            .catch((err) => {
                                if (err.response.status == 409) {
                                    this.$swal({
                                        title: err.response.data,
                                        icon: "error",
                                        button: "ok",
                                    });
                                }
                                this.disabledButton = false;
                                reject(err);
                            });
                    });
                }
                this.disabledButton = false;
            }
            this.disabledButton = false;
        },
        getUserInfo: function (contact) {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(contact)
                    .then((response) => {
                        let user = response.data;
                        this.agentCreate(user);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentCreate: function (user) {
            console.log("agentCreate=====", this.user.role);
            let roleCounsellor = false;
            let roleSupervisor = false;
            let roleAdminSupervisor = false;
            let branch = null;
            let branches = [];
            if (this.user.role != null) {
                console.log("this.user.role-====", this.user.role);
                if (this.user.role.roleName == 'ROLE_COUNSELLOR') {
                    roleCounsellor = true;
                    branch = this.branch
                }
                if (this.user.role.roleName == 'ROLE_SUPERVISOR') {
                    roleSupervisor = true;
                    branch = this.branch
                }
                if (this.user.role.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                    roleAdminSupervisor = true;
                    branches = this.branches;
                }
            }
            if (this.addSelectedRoles.roleName == 'ROLE_COUNSELLOR') {
                roleCounsellor = true;
                branch = this.branch
            }
            if (this.addSelectedRoles.roleName == 'ROLE_SUPERVISOR') {
                roleSupervisor = true;
                branch = this.branch
            }
            if (this.addSelectedRoles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                roleAdminSupervisor = true;
                branches = this.branches;
            }
            let agent = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                name: user.username,
                email: user.email[0],
                contact: user.mobile[0],
                roleCounsellor: roleCounsellor,
                roleSupervisor: roleSupervisor,
                roleAdminSupervisor: roleAdminSupervisor,
                branch: branch,
                branches: branches
            }
            return new Promise((resolve, reject) => {
                AgentService.teamAgentCreate(agent)
                    .then((response) => {
                        this.$swal({
                            title: "Account Created",
                            text: "Confirmation mail send to the email",
                            icon: "success",
                            button: "ok",
                        });
                        this.disabledButton = false;
                        this.addUserRoles(agent.userId);
                        if (this.addSelectedRoles != []) {
                            this.getAllUserCount();
                        }
                        this.resetBranchSelect();
                        this.getAllBranches();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllUserCount: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.getAllUserCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        if (this.currentPage != 1) {
                            this.currentPage = 1;
                        } else {
                            this.getAllUserPageable();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage() {
            this.loading = true;
            this.getAllUserPageable();
        },
        getAllUserPageable: function () {
            this.users.splice(0);
            return new Promise((resolve, reject) => {
                Account.getAllUserPageable(this.pageCount, this.currentPage)
                    .then((response) => {
                        this.users = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        userUpdateModalShow(user) {
            this.updateUser = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                contact: user.email[0],
                mobile: user.mobile[0],
            }
            this.$refs.userUpdateModal.show();
        },
        userDetailUpdate: function () {
            console.log("==this.user===", this.updateUser);
            this.$v.updateUser.firstName.$touch();
            if (!this.$v.updateUser.firstName.$invalid) {
                return new Promise((resolve, reject) => {
                    Account.nameUpdate(this.updateUser)
                        .then((response) => {
                            console.log("====userDetailUpdate----", response.data);
                            this.userDetailUpdateRest();
                            resolve(response);
                        })
                        .catch((err) => {
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        async userDetailUpdateRest() {
            await this.userUpdateWithAgent();
            this.disabledButton = false;
            this.$refs.userUpdateModal.hide();
            this.$swal({
                title: "Update",
                text: "User details updated successfully...",
                icon: "success",
                button: "ok",
            });
            this.getAllUserCount();
        },
        userUpdateWithAgent: function () {
            return new Promise((resolve, reject) => {
                AgentService.userUpdateWithAgent(this.updateUser)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async multiTeamPrivilegeModalShow(user) {
            await this.multiTeamPrevilegesReset();
            this.loading = true;
            this.user = user;
            await this.getAllTeam();
            await this.getUserAddedTeam();
            console.log("multiTeamPrivilegeModalShow");
            this.loading = false;
            this.$refs.multiTeamPrivilegeModal.show();
        },
        getAllTeam() {
            return new Promise((resolve, reject) => {
                axios.post("/team/getAll")
                    .then((response) => {
                        this.teams.splice(0);
                        this.teams = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserAddedTeam() {
            return new Promise((resolve, reject) => {
                Account.getTeam(this.user.id)
                    .then((response) => {
                        if (response.data != null) {
                            this.userAddedTeams.splice(0);
                            this.userNotAddedTeams.splice(0);
                            for (var i = 0; i < this.teams.length; i++) {
                                let isEqual = false;
                                response.data.forEach(e => {
                                    if (e.teamId == this.teams[i].teamId) {
                                        isEqual = true;
                                    }
                                });
                                if (isEqual) {
                                    this.userAddedTeams.push(this.teams[i]);
                                } else {
                                    this.userNotAddedTeams.push(this.teams[i]);
                                }
                            }
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        userAddedTeam() {
            console.log("userAddedNewTeam==call");
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                return new Promise((resolve, reject) => {
                    Account.userAddedTeam(this.selectedTeam, this.user.id)
                        .then(response => {
                            axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                            this.teamAgentAdd();
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        userRemoveTeam() {
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                return new Promise((resolve, reject) => {
                    Account.userRemoveTeam(this.selectedTeam, this.user.id)
                        .then(response => {
                            this.$swal({
                                type: "success",
                                title: "User team removed Successfully!"
                            });
                            this.multiTeamPrevilegesReset();
                            this.$refs.multiTeamPrivilegeModal.hide();
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        teamAgentAdd() {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(this.user.id)
                    .then(response => {
                        if (response.data == "") {
                            this.newAgentCreate();
                        } else {
                            this.$swal({
                                type: "success",
                                title: "User team removed Successfully!"
                            });
                            this.multiTeamPrevilegesReset();
                            this.$refs.multiTeamPrivilegeModal.hide();
                            this.disabledButton = false;
                        }
                        axios.defaults.headers.common["tenant-uuid"] = null;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        newAgentCreate() {
            let agent = {
                userId: this.user.id,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                name: this.user.username,
                email: this.user.email[0],
                contact: this.user.mobile[0],
                roleCounsellor: false,
                roleSupervisor: false,
                roleAdminSupervisor: false,
                branch: null,
                branches: null
            }
            return new Promise((resolve, reject) => {
                AgentService.teamAgentCreate(agent)
                    .then((response) => {
                        this.$swal({
                            type: "success",
                            title: "User team Added Successfully!"
                        });
                        this.multiTeamPrevilegesReset();
                        this.$refs.multiTeamPrivilegeModal.hide();
                        this.disabledButton = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        multiTeamPrevilegesReset() {
            this.selectedTeam = null;
            this.$nextTick(() => {
                this.$v.selectedTeam.$reset();
            });
        },
        getUserAddedRoles: function (userId) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.getUserAddedRoles(userId)
                    .then(response => {
                        this.addedUserRoles.splice(0);
                        this.addedUserRoles = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        getUserNotAddedRoles: function (userId) {
            this.isLoading = true;
            return new Promise((resolve, reject) => {
                Account.getUserNotAddedRoles(userId)
                    .then(response => {
                        this.notAddedUserRoles = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        addRolesModal: function (roles, userId) {
            if (roles.length != 0) {
                if (!this.roleValidation(roles)) {
                    this.$v.branch.$touch();
                    this.$v.branches.$touch();
                    if (this.addSelectedRoles.roleName == 'ROLE_COUNSELLOR') {
                        if (!this.$v.branch.$invalid) {
                            this.agentCounsellorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_SUPERVISOR') {
                        if (!this.$v.branch.$invalid) {
                            this.agentSupervisorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        if (!this.$v.branches.$invalid) {
                            this.agentAdminSupervisorActivate(userId);
                        }
                    } else if (this.addSelectedRoles.roleName == 'ROLE_TELECALLER') {
                        this.telecallerRoleActivate(userId);
                    } else {
                        this.addUserRoles(userId);
                    }
                }
            }
        },
        agentCounsellorActivate: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branch: this.branch,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateCounsellor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentSupervisorActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branch: this.branch,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateSupervisor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        agentAdminSupervisorActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            let data = {
                                branches: this.branches,
                                agentId: response.data.agentId,
                            };
                            return new Promise((resolve, reject) => {
                                AgentService.activateAdminSupervisor(data)
                                    .then((response) => {
                                        this.addUserRoles(userId);
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        telecallerRoleActivate(userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            this.addUserRoles(userId);
                            this.resetBranchSelect();
                            this.getAllBranches();
                        } else {
                            this.getUserInfo(this.user.username);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        roleValidation(roles) {
            let roleTelecaller = false;
            let roleCounsellor = false;
            let roleSupervisor = false;
            let roleAdminSupervisor = false;
            let roleName = "";
            let roleError = false;
            if (roles.roleName == 'ROLE_TELECALLER') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                        roleName = 'ROLE_COUNSELLOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                        roleName = 'ROLE_SUPERVISOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleCounsellor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_COUNSELLOR') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                        roleName = 'ROLE_SUPERVISOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_SUPERVISOR') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                        roleName = 'ROLE_COUNSELLOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleCounsellor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleAdminSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else if (roles.roleName == 'ROLE_ADMIN_SUPERVISOR') {
                this.addedUserRoles.forEach(e => {
                    if (e.roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                        roleName = 'ROLE_COUNSELLOR';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_TELECALLER') {
                        roleTelecaller = true;
                        roleName = 'ROLE_TELECALLER';
                        roleError = true;
                    }
                    if (e.roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                        roleName = 'ROLE_ADMIN_SUPERVISOR';
                        roleError = true;
                    }
                });
                if (roleCounsellor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleTelecaller) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
                if (roleSupervisor) {
                    this.$swal({
                        title: "please remove " + roleName,
                        icon: "error",
                        button: "ok",
                    });
                }
            } else {
                roleError = false;
            }
            return roleError;
        },
        addUserRoles: function (userId) {
            let data = {
                roles: [],
            };
            data.roles.push({
                roleId: this.addSelectedRoles.roleId,
            });
            return new Promise((resolve, reject) => {
                Account.addSelectedUserRoles(userId, data)
                    .then(response => {
                        this.addedUserRoles = [];
                        this.notAddedUserRoles = [];
                        this.addSelectedRoles = [];
                        this.deleteSelectedRoles = [];
                        this.$swal({
                            type: "success",
                            title: "Selected roles added Successfully!"
                        });
                        this.getUserAddedRoles(userId);
                        this.getUserNotAddedRoles(userId);
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        this.$swal({
                            type: "error",
                            title: "Selected roles adding failed!"
                        });
                        reject(err);
                    });
            });
            // }
        },
        removeUserRoles: function (userId) {
            if (this.deleteSelectedRoles.length !== 0) {
                let data = {
                    roles: [],
                };
                let roleCounsellor = false;
                let roleSupervisor = false;
                let roleAdminSupervisor = false;
                for (let i = 0; i < this.deleteSelectedRoles.length; i++) {
                    data.roles.push({
                        roleId: this.deleteSelectedRoles[i].roleId,
                    });
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_COUNSELLOR') {
                        roleCounsellor = true;
                    }
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_SUPERVISOR') {
                        roleSupervisor = true;
                    }
                    if (this.deleteSelectedRoles[i].roleName == 'ROLE_ADMIN_SUPERVISOR') {
                        roleAdminSupervisor = true;
                    }
                }
                return new Promise((resolve, reject) => {
                    Account.removeSelectedUserRoles(userId, data)
                        .then(response => {
                            if (roleCounsellor) {
                                this.deactivateCounsellor(userId);
                            }
                            if (roleSupervisor) {
                                this.deactivateSupervisor(userId);
                            }
                            if (roleAdminSupervisor) {
                                this.deactivateAdminSupervisor(userId);
                            }
                            this.addedUserRoles = [];
                            this.notAddedUserRoles = [];
                            this.addSelectedRoles = [];
                            this.deleteSelectedRoles = [];
                            this.$swal({
                                type: "success",
                                title: "Selected roles removed Successfully!"
                            });
                            this.getUserAddedRoles(userId);
                            this.getUserNotAddedRoles(userId);
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);
                            this.$swal({
                                type: "error",
                                title: "Selected roles removing failed!"
                            });
                            reject(err);
                        });
                });
            }
        },
        deactivateCounsellor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateCounsellor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deactivateSupervisor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateSupervisor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deactivateAdminSupervisor: function (userId) {
            return new Promise((resolve, reject) => {
                AgentService.getAgentData(userId)
                    .then((response) => {
                        resolve(response);
                        if (response.data != null) {
                            return new Promise((resolve, reject) => {
                                AgentService.deactivateAdminSupervisor(response.data.agentId)
                                    .then((response) => {
                                        this.resetBranchSelect();
                                        this.getAllBranches();
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        allUserSearch(keyword) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Account.allUserSearch(keyword.toLowerCase())
                    .then((response) => {
                        this.users.splice(0);
                        if (response.data != null && response.data != "") {
                            this.users.push(response.data)
                        } else {
                            this.users = [];
                        }
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllRoles() {
            return new Promise((resolve, reject) => {
                Account.getAllRoles()
                    .then((response) => {
                        if (response.data != null) {
                            this.allRoles = response.data.filter(function (e) {
                                return e.roleName !== 'ROLE_USER' && e.roleName !== 'ROLE_SUPER_ADMIN'
                            })
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        formatDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        refresh() {
            if (this.keyword != "") {
                this.keyword = "";
            } else {
                this.getAllUserCount();
            }
        },
        resetBranchSelect() {
            this.allBranches = [],
                this.allBranchesBranch = [],
                this.selectedBranch = null,
                this.branch = null,
                this.branches = [],
                this.$nextTick(() => {
                    this.$v.branch.$reset();
                    this.$v.branches.$reset();
                });
        },
        resetUser() {
            this.disabledButton = false;
            this.user = {
                    firstName: "",
                    lastName: "",
                    username: "",
                    contact: "",
                },
                this.allBranches = [],
                this.allBranchesBranch = [],
                this.selectedBranch = null,
                this.branch = null,
                this.branches = [],
                this.$nextTick(() => {
                    this.$v.user.$reset();
                    this.$v.branch.$reset();
                    this.$v.branches.$reset();
                    // this.$v.selectedBranch.$reset();
                });
        },
        getAllBranches: function () {
            this.allBranches.splice(0);
            this.allBranchesBranch.splice(0);
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranchesBranch = response.data;
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addUserModalClose() {
            // this.$refs.rolePrevilegeModal.hide()
            this.resetBranchSelect();
            this.selectedUserId = null;
            this.addedUserRoles = [];
            this.notAddedUserRoles = [];
            this.addSelectedRoles = [];
            this.deleteSelectedRoles = [];
        },
        removeBranches: function (item) {
            const langIndex = this.branches.findIndex(x => x === item);
            if (langIndex !== undefined)
                this.branches.splice(langIndex, 1);
            this.allBranches.push(item);
        },
        saveBranches: function () {
            if (!this.branches.some(entry => entry === this.selectedBranch)) {
                let branch = null;
                for (var i = 0; i < this.allBranches.length; i++) {
                    if (this.allBranches[i] == this.selectedBranch) {
                        this.branches.push(this.allBranches[i]);
                        branch = this.allBranches[i];
                        // this.allBranches.splice([i], 1);
                    }
                }
                this.allBranches = this.allBranches.filter(function (e) {
                    return e !== branch
                })
            }
            this.selectedBranch = null;
        },
    }
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}

.cellSize {
    max-width: 120px;
}
</style>
