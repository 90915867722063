import axios from "axios";

export default {
    appVersionUnique: function(appRelease) {
		return new Promise((resolve, reject) => {
            axios.post("/mobileApp/release/unique",appRelease)
			.then((response) => {
                resolve(response);
            })
			.catch((err) => {
                reject(err);
            });
        });
	},
	createAppRelease: function(appRelease) {
		console.log("createAppRelease service-----",appRelease);
		return new Promise((resolve, reject) => {
            axios.post("/mobileApp/release/save",appRelease)
			.then((response) => {
                resolve(response);
            })
			.catch((err) => {
                reject(err);
            });
        });
	},
    deleteAppRelease: function(appRelease) {
		console.log("createAppRelease service-----",appRelease);
        let data = {
            releaseId: appRelease.releaseId,
            appName: appRelease.appName
        }
		return new Promise((resolve, reject) => {
            axios.post("/mobileApp/release/delete",data)
			.then((response) => {
                resolve(response);
            })
			.catch((err) => {
                reject(err);
            });
        });
	},
	getCount: function(){
        return new Promise((resolve, reject) => {
            axios({method: 'get',url: "/mobileApp/release/total"})
			.then((response) => {
                resolve(response);
            })
			.catch((err) => {
                reject(err);
            });
        });
    },
	getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios({method: 'get',url: '/mobileApp/release/all/'+ (currentPage-1) + "/" + pageCount})
			.then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchCount: function(keyword) {
        let data = {
            version: keyword
        }
        return new Promise((resolve, reject) => {
            axios.post('/mobileApp/release/count/search',data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        let data = {
            version: keyword
        }
        return new Promise((resolve, reject) => {
            axios.post('/mobileApp/release/all/search/'+ (currentPage-1) + "/" + pageCount,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
}