<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container>
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Enquiries" active @click="openEnquiryCall">
                <!-- counsellor -->
                <b-container>
                    <div>
                        <b-row align-v="center">
                            <!-- Enquiry disabled -->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getEnquirySearchCount(keyword)" placeholder="Type a name or contact" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getEnquirySearchCount(keyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage,pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage,pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="closedRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="3" md="3" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Course</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="3" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Source</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="4" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Branch">
                                                    <p>Branch</p>
                                                </b-form-select-option>
                                                <!-- <b-form-select-option value="AllClosedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option> -->
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="2" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedDates" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <b-modal ref="enquiryDateRange" hide-footer id="dateRange" title="Select Date">
                                <template>
                                    <div>
                                        <label for="from-datepicker">Choose The From date</label>
                                        <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid}" type="date"></b-form-input>
                                    </div>
                                </template>
                                <template>
                                    <div>
                                        <label for="to-datepicker">Choose The To date</label>
                                        <b-form-input format="DD-MM-YYYY" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid}" type="date"></b-form-input>
                                    </div>
                                </template>
                                <br />
                                <template>
                                    <div class="text-center">
                                        <b-button variant="outline-success" type="submit" @click="dateValidation(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                            Submit
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>
                            <hr />
                            <b-table bordered responsive hover :items="allEnquiries" :fields="fields" :current-page="currentPage" :per-page="allEnquiries.pageCount">
                                <template v-slot:cell(enquiryId)="data">
                                    <span class="title"> {{ data.item.enquiryId }}</span>
                                </template>
                                <template v-slot:cell(callCreatedAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                        }}<span v-if="
                            data.item.alternativeContact != null &&
                            data.item.alternativeContact != ''
                        ">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getCounsellorEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" variant="warning" v-b-modal.followInsert @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'warning' }" title="Update Followup">
                                            <font-awesome-icon icon="edit" />
                                        </b-button> -->
                                    </div>
                                </template>
                                <template>
                                    <b-icon icon="arrow-up"></b-icon>
                                    <b-icon icon="exclamation-triangle"></b-icon>
                                </template>
                            </b-table>
                        </b-card>
                    </div>
                    <!-- Enquiry table and details end -->
                    <!-- </div> -->
                </b-container>
            </b-tab>
            <b-tab title="Closed Enquiries" @click="closedEnquiryCall()">
                <!-- <div v-if="isShowCounsellorClosed"> -->
                <!-- Enquiry closed details table start -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!-- Enquiry disabled -->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getEnquirySearchCount(keyword)" placeholder="Type a name or contact" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getEnquirySearchCount(keyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="closedSwitchPage(currentPage,pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage,pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="3" md="3" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Course</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="3" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Source</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="4" id="r1" sm="3" class="sort">
                                            <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Branch">
                                                    <p>Branch</p>
                                                </b-form-select-option>
                                                <!-- <b-form-select-option value="AllClosedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option> -->
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="3" md="2" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedDates" @change="getAllEnquirySortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />
                            <b-table bordered responsive hover :items="allEnquiries" :fields="fields" :current-page="currentPage" :per-page="allEnquiries.pageCount">
                                <template v-slot:cell(enquiryId)="data">
                                    <span class="title"> {{ data.item.enquiryId }}</span>
                                </template>
                                <template v-slot:cell(callCreatedAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                        }}<span v-if="
                            data.item.alternativeContact != null &&
                            data.item.alternativeContact != ''
                        ">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    <div class="cellSize">
                                        {{ data.item.course.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(source)="data">
                                    <div class="cellSize">
                                        {{ data.item.source.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(branch)="data">
                                    <div class="cellSize">
                                        {{ data.item.branch.name }}
                                    </div>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getCounsellorEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- Enquiry closed details table end -->
                <!-- </div> -->
            </b-tab>
        </b-tabs>

        <b-container>
            <!-- Data render in table -->
            <div class="top"></div>
            <!-- Data render in table -->
            <div>
                <!--popup start for View Enquiry details-->
                <b-modal id="enquiryView" title="View Enquiry" hide-footer>
                    <div class="control">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model="enquiry.name" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input type="number" v-model="enquiry.contact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Alternative Contact</label>
                                <b-form-input type="number" v-model="enquiry.alternativeContact" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input type="number" v-model="enquiry.age" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-input v-model="enquiry.qualification" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-select class="form-control" v-model="enquiry.course" disabled>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-select class="form-control" v-model="enquiry.source" disabled>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-input class="form-control" v-model="enquiry.mode" :options="options" disabled></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-input class="form-control" v-model="enquiry.branch.name" disabled></b-form-input>
                            </div>
                        </div>
                        <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupDateAndRemark">
                            <template v-slot:cell(remark)="data">
                                <span class="title"> {{ data.item.remark }}</span>
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                {{ callDate(data.item.createdAt, "fullDate") }}
                            </template>
                            <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template>
                        </b-table>
                    </div>
                </b-modal>
                <!--popup end for View Enquiry details-->
            </div>
        </b-container>

        <b-modal ref="dateClosedRange" hide-footer id="dateRange" title="Select Date">
            <div>
                <label for="from-datepicker">Choose The From date</label>
                <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                'is-invalid': $v.from.$error,
                'is-valid': !$v.from.$invalid,
                }" type="date"></b-form-input>
            </div>
            <div>
                <label for="to-datepicker">Choose The To date</label>
                <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                'is-invalid': $v.to.$error,
                'is-valid': !$v.to.$invalid,
                }" type="date"></b-form-input>
            </div>
            <div class="text-center mt-3">
                <b-button variant="outline-success" type="submit" @click="getClosedDateRange()">Submit</b-button>
            </div>
        </b-modal>
    </b-container>

</div>
</template>

<script>
import qualification from "../assets/json_file/qualification.json";
import axios from "axios";
import Account from '../service/Account.js';
// import EnquiryService from "../service/EnquiryService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
// import BranchService from "../service/BranchService";
import FollowupService from "../service/FollowupService";
// import AgentService from "../service/AgentService"
import {
    required,
    // minLength,
    // maxLength
} from "vuelidate/lib/validators";
import moment from "moment-timezone";
import Swal from 'sweetalert2';

export default {
    name: "Enquiry",
    components: {},
    data() {
        return {
            followupDateRemark: [],
            fromDate: "",
            toDate: "",
            agentId: "",
            // Enquiry table show
            // isShowEnquiry: true,
            // Enquiry table hide and closed tables show
            // isShowClosed: false,
            // Enquiry details render in update model form
            enquiry: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: {
                    name: ""
                },
                createdAt: "",
                callId: "",
            },
            enquiryFollowup: {
                followupDate: "",
                remark: "",
            },
            // Closed data render in table
            // closedEnquiries: [],
            // closedFields: [{
            //         key: "callCreatedAt",
            //         label: "Call Date",
            //     },
            //     {
            //         key: "name",
            //         label: "Name",
            //     },
            //     {
            //         key: "contact",
            //         label: "Contact",
            //     },
            //     {
            //         key: "course",
            //         label: "Course",
            //     },
            //     {
            //         key: "source",
            //         label: "Source",
            //     },
            //     {
            //         key: "mode",
            //         label: "Mode",
            //     },
            //     {
            //         key: "branch",
            //         label: "Branch",
            //     },
            //     {
            //         key: "mode",
            //         label: "Mode",
            //     },
            //     {
            //         key: "createdAt",
            //         label: "Enquiry Date",
            //     },
            //     {
            //         key: "action",
            //         label: "Action",
            //     },
            // ],
            // submitstatus: "",
            qualificationList: qualification,
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            // Enqruiries all data render in table
            allEnquiries: [],
            fields: [{
                    key: "callCreatedAt",
                    label: "Call Date",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "createdAt",
                    label: "Enquiry Date",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],

            from: "",
            to: "",
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                {
                    value: "today",
                    text: "Today",
                },
                {
                    value: "yesterday",
                    text: "Yesterday",
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            //All Courses data render in table
            allCourses: "",
            //All Sources data render in table
            allSources: "",
            //All Branches data render in table
            allBranches: "",
            // Enquiry followup details render in view modal table
            followup: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            // for loader
            loading: false,
            // for enquiry pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                1,
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            // for closed pagination
            // for enquiry search in table
            keyword: "",
            // for closed enquiry search in table
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedDates: null,
            selectedDate: null,
            branch: "",
            data: "",
            sortPagination: false,
            searchPagination: false,
            closedEnquiryShow: false,
        };
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllEnquirySortCount("Course", "Source", this.allBranches[0], null);
            }
        },
        // counsellorClosedKeyword(newVal, oldVal) {
        //     if (oldVal && !newVal) {
        //         this.getCounsellorClosedCount();
        //     }
        // },
    },
    mounted() {
        if (localStorage.getItem("agentId") != null) {
            return new Promise((resolve, reject) => {
                this.global()
                    .then((response) => {
                        this.counsellorData();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Account Deactivated',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async counsellorData() {
            this.allBranches = JSON.parse(localStorage.getItem('branches'));
            this.selectedBranch = this.allBranches[0];
            await this.getAllCourses();
            await this.getAllSources();
            await this.getAllEnquirySortCount("Course", "Source", this.selectedBranch, null)
        },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getDateRangeReset: function () {
            this.$refs.enquiryDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        dateValidation(selectedCourse, selectedSource, selectedBranch, selectedDates) {
            this.$v.from.$touch();
            this.$v.to.$touch();
            if (!this.$v.from.$invalid && !this.$v.to.$invalid) {
                this.getAllEnquirySortCount(selectedCourse, selectedSource, selectedBranch, selectedDates)
            }
        },
        getAllEnquirySortCount: function (
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            console.log();
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.enquiryDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.enquiryDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.enquiryDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.sortPagination = true;
                this.searchPagination = false;
                this.currentPage = 1;
                // this.keyword = "";
                if (!this.closedEnquiryShow) {
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/enquiry/counsellor/all/sort/count", this.data)
                            .then((response) => {
                                this.totalCount = response.data;
                                this.getAllSortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                                this.loading = false;
                                resolve(response);
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                } else if (this.closedEnquiryShow) {
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/enquiry/closed/counsellor/all/sort/count", this.data)
                            .then((response) => {
                                this.totalCount = response.data;
                                this.getAllSortPageable(
                                    this.currentPage,
                                    this.pageCount,
                                    this.data
                                );
                                this.loading = false;
                                resolve(response);
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                }
            }
        },
        switchPage(currentPage, pageCount) {
            this.currentPage = currentPage;
            this.pageCount = pageCount;
            if (this.sortPagination) {
                this.getAllSortPageable(this.currentPage, this.pageCount, this.data);
            } else if (this.searchPagination) {
                this.getEnquirySearchPageable(this.currentPage, this.pageCount, this.data);
            }
        },
        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            this.allEnquiries.splice(0);
            if (!this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/counsellor/all/sort/" + currentPage + "/" + pageCount,
                            data
                        )
                        .then((response) => {
                            this.allEnquiries = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else if (this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/closed/counsellor/all/sort/" + currentPage + "/" + pageCount,
                            data
                        )
                        .then((response) => {
                            this.allEnquiries = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getEnquirySearchCount: function () {
            this.loading = true;
            this.sortPagination = false;
            this.searchPagination = true;
            let data = {
                branchId: this.selectedBranch.branchId
            }
            if (!this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/counsellor/search/count/" + this.keyword.toLowerCase(), data
                        )
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getEnquirySearchPageable(this.currentPage, this.pageCount)
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else if (this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/counsellor/closedSearch/count/" + this.keyword.toLowerCase(), data
                        )
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getEnquirySearchPageable(this.currentPage, this.pageCount)
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getEnquirySearchPageable: function (currentPage, pageCount) {
            this.loading = true;
            this.sortPagination = false;
            this.searchPagination = true;
            let data = {
                branchId: this.selectedBranch.branchId
            }
            if (!this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/counsellor/search/" + (currentPage - 1) + "/" + pageCount + "/" + this.keyword.toLowerCase(), data
                        )
                        .then((response) => {
                            this.allEnquiries = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else if (this.closedEnquiryShow) {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            "enquiry/counsellor/closedSearch/" + (currentPage - 1) + "/" + pageCount + "/" + this.keyword.toLowerCase(), data
                        )
                        .then((response) => {
                            this.allEnquiries = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getCounsellorEnquiry: function (enquiry) {
            this.enquiry = enquiry;
            // console.log("getCounsellorEnquiry", enquiry);
            // console.log(this.enquiry.callId)
            if (this.enquiry.callId == null) {
                console.log("callId");
                return new Promise((resolve, reject) => {
                    //   console.log("Call")
                    FollowupService.getEnquiryFollowupDateRemark(enquiry.enquiryId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            //   console.log(response.data)
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                return new Promise((resolve, reject) => {
                    // FollowupService.getFollowupDateRemark(enquiry.callId)
                    FollowupService.getFollowupCreatedDate(enquiry.callId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            // console.log(this.followupDateRemark);
                            // this.followup.forEach(element => {
                            //   //console.log(element);
                            // });
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        openEnquiryCall() {
            this.closedEnquiryShow = false;
            this.getAllEnquirySortCount("Course", "Source", this.allBranches[0], null)
        },
        closedEnquiryCall() {
            this.closedEnquiryShow = true;
            this.getAllEnquirySortCount("Course", "Source", this.allBranches[0], null)
        },
        refresh() {
            if (this.keyword != "") {
                this.keyword = "";
            } else {
                this.selectedBranch = this.allBranches[0];
                this.getAllEnquirySortCount("Course", "Source", this.selectedBranch, null)
            }
        },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
    }
}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

/* .active {
    background: rgb(0, 97, 243) !important;
} */

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 100px;
    max-width: 100px;
    margin-left: 20px;
}
</style>
