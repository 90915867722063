<template>
<div class="bg">
    <nav class="navbar navbar-light bg-light">
      <span class="navbar-brand px-3">
        <span class="">tele</span> <span class="fiesta">caller</span>
      </span>
    </nav>
    <div class="container content" >
        <h4><span class="text-uppercase" style="text-transform: uppercase;"><strong> Encore telecaller TERMS OF SERVICE</strong></span></h4>
        <br>
        <br>
        <div class="paragraph"> Encore Telecaller (“<strong>Encore Telecaller</strong>” or “<strong>we</strong>”) offers users (“<strong>Users</strong>” or “<strong>You</strong>”) global phone community services transacted through various software applications and services, including but not limited to the “<strong>Encore Telecaller</strong>” application. These Terms of Service and the Encore Telecaller  <strong @click="privacy()" style="color:#1976D2">Privacy Policy.</strong> (the “<strong>Terms</strong>”) will govern Your use of Encore Telecaller’s software applications, websites, web service API, tools or other services and features provided by Encore Telecaller from time to time (collectively “Services”). By accessing or using any of the Services, You agree to be bound by these Terms. By using the Encore Telecaller application You confirm that You are a resident of the country outside Europe to which the international dialing code of the phone number that you have registered for access to our Services belongs. Where other third party services are made available as part of our Services, then the respective third party terms of service and privacy policy shall apply to any such use by You. If You are registering with Encore Telecaller as a business entity, You represent that You have the authority to legally bind that entity. For Encore Telecaller SDK and Encore Telecaller Priority Users specific terms apply in accordance with the respective agreements for such services. For API Users, additional provisions apply in accordance with the API License Addendum (which is incorporated by reference into these Terms).
            <br><br>

            Certain features of the Services may be subject to additional guidelines, terms or rules, which will be posted in or on the Services in connection with such features. All such additional terms, guidelines and rules are incorporated by reference into these Terms. </div>

        <br>
        <br>
        <span class="text-h5" style="text-transform: uppercase;"><strong> 1. PERSONAL INFORMATION</strong></span>
        <br>
        <br>
        <div class="paragraph"> We are firmly committed to the security and protection of personal information of our Users and the Services. The Encore Telecaller Privacy Policy describes how we collect, use, share and process personal information and You acknowledge and agree that Encore Telecaller may collect, use, share and process personal information as described therein.

            <br><br>

        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong> 2. GRANT OF RIGHTS</strong></span>
        <br>
        <br>
        <div class="paragraph"> Except as otherwise agreed separately in writing between us or as set forth in the API License Addendum and subject to Your compliance with these Terms at all times, Encore Telecaller grants You a personal, non-exclusive, non-transferable and limited right to use the Services for Your own personal, non-commercial use. You are not allowed to use the Encore Telecaller Services on any device that You do not own or control. You may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Encore Telecaller software applications, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent expressly permitted by these Terms or may be permitted by the licensing terms governing use of any open source components included in the Encore Telecaller software applications).
            <br><br>
            You are not granted any right to use Encore Telecaller’s name, trademarks or other commercial symbols. All rights not expressly granted to You under these Terms shall be retained by Encore Telecaller.
            <br><br>
            In case You wish to use any part of the Services as a part of Your own application or modify any part of the Services, such use is subject to Encore Telecaller’s prior written approval and that You enter into a separate license agreement with Encore Telecaller. Any API, commercial or enterprise use of the Services shall be governed by the API License Addendum or such other separate agreement as may be required by Encore Telecaller.
            <br><br>
        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong>3. SUPPORT</strong></span>
        <br>
        <br>
        <div class="paragraph"> Encore Telecaller strives to provide adequate and efficient technical support, upgrades and updates for the Services. Encore Telecaller shall, however, not be under any obligation to provide support or maintenance for the Services under these Terms and reserves the right to limit or discontinue the support, upgrades and updates provided from time to time.

            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>4. USER OBLIGATIONS AND RESTRICTIONS</strong></span>
        <br>
        <br>
        <div class="paragraph"> You guarantee that any information and other content, such as Your profile information and information regarding the contacts contained in Your device’s phone book, that You may share with Encore Telecaller as a User of the Services (together “Content”), to the best of Your knowledge, is correct, not in violation of applicable law, will not corrupt or disrupt the Services, and that you have the right to share the Content with Encore Telecaller in order for Encore Telecaller to provide the Services and share the Content with other Users.
            <br><br>
            Except as otherwise agreed separately in writing between us, or as set forth in the API License Addendum, You shall not make any commercial use of the Services or the Content or otherwise transfer for value the Services or the Content. You agree not to challenge Encore Telecaller's rights in, or otherwise attempt to assert any rights in, the Services or any Content provided by other Users, except those rights explicitly granted under these Terms. You agree to use the Services and Content only as expressly permitted under these Terms.
            <br><br>
            You agree not to use the Services, Content or information to attempt to circumvent the regular operation of the Services, or reduce the fees or consideration that we may derive from the Services by any means including by creating multiple accounts, redirecting traffic, following other fraudulent or deceptive practices, creating a parallel repository thereof, or seeking to by-pass the Services or compete with us.
            <br><br>
            Scraping of any information contained in the Services, by use of automated systems or software to extract data, including any Content and any third party information accessible via the Services, is strictly prohibited.
            <br><br>
            You may not use the Services or the Content in any way, which is illegal, harmful, or may be considered offensive by Encore Telecaller, other Users or third parties. You agree not to exploit the Services or the Content in any unauthorized way whatsoever, including but not limited to, trespassing or burdening network capacity. You further agree not to use our Services or Content in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and You acknowledge and agree that Encore Telecaller is not in any way responsible for any such use by You, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that You may receive as a result of using the Services. Notwithstanding any other remedies available to Encore Telecaller, You agree that Encore Telecaller may suspend or terminate Your use of the Services without notice if You use the Services or the Content in any prohibited manner, and that such use will be deemed a material breach of these Terms.
            <br><br>
            You may not use or otherwise export or re-export the Services except as authorized by United States law and the laws of the jurisdiction in which the Services were obtained. In particular, but without limitation, the Services may not be exported, re-exported or otherwise made available (a) into any country or region embargoed by the U.S. Government, or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Services, you represent and warrant that you are not located in any such country or on any such list. If You obtain access to any subscription based Services in breach of these Terms, we reserve the right to cancel such subscription without any refund of fees in accordance with applicable law.
            <br><br>
            You may contact Encore Telecaller at support@Encore Telecaller.com to report any violation or infringement of Your rights by Users of the Services.
            <br><br>

        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong>5. PROPRIETARY RIGHTS</strong></span>
        <br>
        <br>
        <div class="paragraph"> The Services are protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. Encore Telecaller and its licensors shall retain ownership in and to the Services and to all related intellectual property rights, including without limitation copyrights, trademarks, trade names, database rights and patents. You are granted only a limited right to use the Services subject to these Terms and no intellectual property rights are or will deemed to be transferred or licensed to You except as contemplated herein.

            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>6. CONTENT</strong></span>
        <br>
        <br>
        <div class="paragraph"> You are solely liable for any content that You post or communicate directly in or on the Services and You agree to only post or communicate content that:
            <br><br>
            <ul><strong>a.</strong>
                is true and not false or misleading;

            </ul>
            <ul><strong>b.</strong>
                is not likely to be deemed threatening, disparaging, defamatory, pornographic, racially or ethnically offensive, discriminatory, insulting, slanderous or otherwise illegal or inappropriate;

            </ul>
            <ul><strong>c.</strong>
                belongs to You, or which You have a right to distribute;
            </ul>
            <ul><strong>d.</strong>
                does not constitute an infringement of the intellectual property or privacy rights of any third party;

            </ul>
            <ul><strong>e.</strong>
                does not constitute information that you are not legally entitled to distribute (such as insider information or confidential information);

            </ul>
            <ul><strong>f.</strong>
                does not contain any unsolicited or unauthorised advertising, promotional material, “junk mail”, “spam”, “chain letters”, “pyramid schemes” or any other form of solicitation; and

            </ul>
            <ul><strong>g.</strong>
                does not contain software virus or any other technology that may harm the Services, or the interests or property of our Services or the other Users of the Services.

            </ul>
            <ul><strong>h.</strong>
                is accurate, fair, and is not disparaging of us, the Services or other Users. Further, You agree to be fair, accurate and non-disparaging while reporting numbers, leaving comments, suggestions, feedback, testimonials and reviews on or about the Services.

            </ul>
            Encore Telecaller appreciates the opportunity to be notified of any objectionable user generated content posted or communicated by a User directly in or on the Services. Please contact us at support@Encore Telecaller.com to report any objectionable user generated content. Encore Telecaller hereby reserves the right in its absolute discretion to remove any user generated content from the Services.

            <br><br>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>7. THIRD PARTY CONTENT</strong></span>
        <br>
        <br>
        <div class="paragraph">The Services may contain links to external content provided by third party websites and services. Such third party content, websites and services may be subject to the respective third party terms and conditions and Encore Telecaller will not be liable for any such third party content, websites or services.

            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>8. DISCLAIMER OF WARRANTIES</strong></span>
        <br>
        <br>
        <div class="paragraph">The Services may contain links to external content provided by third party websites and services. Such third party content, websites and services may be subject to the respective third party terms and conditions and Encore Telecaller will not be liable for any such third party content, websites or services.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Encore Telecaller MAKES NO WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED WITH RESPECT TO THE SERVICES, THEIR QUALITY, PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. THE SERVICES ARE PROVIDED “AS IS” AND YOU AGREE THAT THE SERVICES ARE USED AT YOUR OWN RISK.
            <br><br>
            You understand and acknowledge that the Services may be unavailable from time to time and that Encore Telecaller will not be liable for Your inability to use the Services for whatever reason.
            <br><br>
            Encore Telecaller makes no warranty or representation that the Services are available for use in any particular location. To the extent You choose to access and use the Services, You do so at Your own initiative and are responsible for compliance with any applicable laws in connection with such access and use of the Services.

            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>9. LIMITATION OF LIABILITY</strong></span>
        <br>
        <br>
        <div class="paragraph">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT Encore Telecaller SHALL IN NO EVENT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, DATA AND GOODWILL, ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES OR THE CONTENT, even if advised of the possibility of such damages. In particular, and without limitation, Encore Telecaller shall have no liability for any information stored or processed within the Services, including the costs of recovering such information. Your only right or remedy with respect to any problems or dissatisfaction with the Services, is to uninstall the Encore Telecaller applications and cease to use the Services.
            <br><br>
            Encore Telecaller SHALL NOT BE LIABLE FOR THE VALIDITY, RELIABILITY OR CORRECTNESS OF THE CONTENT AND INFORMATION PROVIDED THROUGH AND IN CONNECTION WITH USE OF THE SERVICES. ANY USE OF THE CONTENT AND INFORMATION OBTAINED THROUGH THE USE OF THE SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK.

            <br><br>
            Nothing in these Terms shall limit or exclude our liability for any liability that mandatorily cannot be limited or excluded by law, including any rights You may have as a consumer under mandatory consumer law.

            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>10. FEES</strong></span>
        <br>
        <br>
        <div class="paragraph">Encore Telecaller offers certain features or services for which a fee will be payable (“<strong>Paid Services</strong>”). Your purchase of the Paid Services may be subject to foreign exchange fees or differences in prices based on location. If You purchase or subscribe to such Paid Services, You agree to pay us the applicable fees (“<strong>Encore Telecaller Fees</strong>”) and taxes in accordance with the applicable third party payment and billing terms, based on the platform You are using, which are incorporated herein by reference. You will be charged the applicable fees and taxes during the subscription period unless You cancel the Paid Service, in which case You agree to still pay these fees through the end of the applicable subscription period. We may change the Encore Telecaller Fees from time to time by posting the changes in or on the Services or by notifying You in advance. All fees are, except as otherwise expressly provided herein or as required by applicable law, non-refundable. Failure to pay these fees may result in suspension or termination of your Service or subscription.

            <br>
            <br>
            Depending on what platform You are using, prepaid fees for the Services may be connected to Your device as well as its phone number. Therefore, in some cases, if You change the device or its SIM-card, You cannot transfer the balance to a new device or SIM-card and no refund will be available in such cases. Any remaining balance of prepaid fees not used within twelve (12) months from the purchase will expire without any right of refund.
            <br><br>
            You acknowledge that You are fully responsible for the Internet connection and/or mobile charges that You may incur for using our Services. Please consult Your carrier, mobile operator, etc. for further information.
            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>11. TERMINATION</strong></span>
        <br>
        <br>
        <div class="paragraph">Your right to use the Services continues until these Terms are terminated. Encore Telecaller may terminate the Terms and Your use of the Services at any time with thirty (30) days’ advance notice. You may terminate the Terms at any time by uninstalling the Encore Telecaller applications and ceasing the use of the Services. These Terms will automatically terminate if You fail to comply with them. Upon any termination, You agree to cease using the Services. Upon termination by You, or by Encore Telecaller due to Your breach of these Terms, You will not be refunded any license fees or other prepaid fees, if any. Upon termination by Encore Telecaller without cause, You will be refunded any unused prepaid fees upon Your written request, provided a receipt of such fees and a clear payment instruction are included in Your request.

            <br><br>
            Provisions of sections Disclaimer of Warranties, Limitation of Liability, Termination and Governing Law shall survive any termination of these Terms.
            <br><br>
        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong>12. ASSIGNMENT</strong></span>
        <br>
        <br>
        <div class="paragraph">Encore Telecaller reserves the right, at its own discretion, to freely assign and transfer the rights and obligations under these Terms to any third party.
            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>13. ADDITIONAL TERMS AND CHANGES</strong></span>
        <br>
        <br>
        <div class="paragraph">As Encore Telecaller provides global Services, additional Terms of Services may apply to Users in certain jurisdictions and will in such cases be made available in appendices and incorporated hereto.

            <br><br>
            Encore Telecaller may change the Services at any time, such as by adding or removing features or discontinuing the Services. Encore Telecaller also reserves the right to modify these Terms at any time by providing revised Terms to the User or by publishing the revised Terms within the Services. In case of material changes, the User shall always be notified thereof and provided the option to immediately terminate the Services. If You choose to terminate the Services, You will be refunded any unused prepaid fees upon Your written request, provided a receipt of such fees and a clear payment instruction are included in Your request. The revised Terms shall become effective upon such publishing or notification to the User. Any continued use by You of the Services following publication or notification of revised Terms shall constitute Your acceptance to the revised Terms.
            <br><br>
        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong>14. GOVERNING LAW</strong></span>
        <br>
        <br>
        <div class="paragraph">These Terms shall be governed and construed in accordance with the laws of India. You agree that any legal action or proceedings may may be brought exclusively in the competent courts/tribunals having jurisdiction in New Delhi, India and both Encore Telecaller and You irrevocably submit to the jurisdiction of such courts/tribunals. <br><br>
            <br>
            <br>
        </div>
    </div>
</div>
</template>

<script>
export default ({
    data() {
        return {

        }
    },
    methods:{
        privacy(){
            this.$router.push('/policy')
        }
    }
})
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.bg {
    background-color: rgb(240, 252, 254);
}
.content {
 text-align: left;
    margin-top: 5%;
}

.paragraph {
    color: rgb(96, 93, 93);
}

.tab-style {
    background-color: white;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: 'Noto Sans Avestan', sans-serif;
    font-weight: 200;
    font-size: 25px;
    color: #3c7ef7;
}

.fiesta {
    font-family: "Pacifico", cursive;

    position: relative;
    top: 1px;
    right: 6px;
    font-size: 27px;
    color: #3c7ef7;
}

</style>
