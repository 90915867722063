import axios from "axios"

export default{
    getAllEnquiries: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get("/enquiry/getAgentEnquiries/"+agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getEnquiry: function(enquiryId){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/get/'+ enquiryId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    updateEnquiry: function(agentId,enquiry){
        return new Promise((resolve, reject) => {
            axios.post("/enquiry/update/"+ agentId, enquiry)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    enquiryFollowupUpdate: function(agentId,enquiryId,enquiryFollowup){
        return new Promise((resolve, reject) => {
            axios.post("/enquiry/followup/update/"+agentId+"/"+enquiryId, enquiryFollowup)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    enquiryClosed: function(agentId,enquiry) {
        return new Promise((resolve, reject) => {
            axios.post('/admission/insert/'+ agentId, enquiry)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        })
    },
    getByDate: function (agentId,selectedDates) {
        return new Promise((resolve, reject) => {
            axios
                .post('/enquiry/sort/date/'+ agentId +"/"+selectedDates)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // getDateCount: function (agentId, selectedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/enquiry/datesort/count/' + agentId +"/"+ selectedDates)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllDatePageable: function (currentPage, pageCount, agentId,selectedDates, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/enquiry/datesort/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId + "/" +selectedDates, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    getByClosedDate: function (agentId,selectedClosedDate) {
        return new Promise((resolve, reject) => {
            axios
                .post('/enquiry/closed/sort/date/'+ agentId +"/"+selectedClosedDate)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // getClosedDateCount: function (agentId, selectedClosedDate) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/enquiry/closed/datesort/count/' + agentId +"/"+ selectedClosedDate)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllClosedDatePageable: function (currentPage, pageCount, agentId,selectedClosedDate, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/enquiry/closed/datesort/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId + "/" +selectedClosedDate, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    updateStatusClosed(agentId,enquiryId){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closed/'+ agentId +"/"+ enquiryId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        })
    },
    getCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get('/enquiry/count/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchName: function(keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.get('/enquiry/search/'+ keyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllClosed: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get('/enquiry/getAgentClosedEnquiries/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        })
    },
    closedGetCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get('/enquiry/closed/count/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    closedGetAllPageable: function(closedCurrentPage,closedPageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closed/all/'+ (closedCurrentPage-1) + "/" + closedPageCount +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    closedSearchName: function(closedKeyword,agentId){
        return new Promise((resolve, reject) => {
            axios.get('/enquiry/closed/search/'+ closedKeyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    
    getCourseCount: function(agentId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/coursesort/count/'+ agentId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCoursePageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/coursesort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getBranchCount: function(agentId,branch){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/branchsort/count/'+ agentId,branch)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBranchPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/branchsort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSourceCount: function(agentId,source){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/sourcesort/count/'+ agentId,source)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSourcePageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/sourcesort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCourseClosedCount: function(agentId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortcourse/count/'+ agentId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCourseClosedPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortcourse/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getBranchClosedCount: function(agentId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortbranch/count/'+ agentId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBranchClosedPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortbranch/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSourceClosedCount: function(agentId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortsource/count/'+ agentId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSourceClosedPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsortsource/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchCount: function(agentId,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/search/count/'+ agentId + "/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,agentId,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/search/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchClosedCount: function(agentId,closedKeyword){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsearch/count/'+ agentId + "/" + closedKeyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSearchClosedPageable: function(closedCurrentPage,closedPageCount,agentId,closedKeyword){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedsearch/'+ (closedCurrentPage-1) + "/" + closedPageCount +"/"+ agentId +"/" + closedKeyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getDateRange: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/enquiry/sort/dateRange/'+ agentId,data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });  
    }, 
    getDateCount: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/sortDate/count/'+ agentId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllDatePageable: function (currentPage, pageCount,agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/sortDate/all/' + (currentPage - 1) + "/" + pageCount + "/" +agentId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getClosedDateRange: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/enquiry/closed/sort/dateRange/'+ agentId,data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });  
    },
    getClosedDateCount: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closed/sortDate/count/'+ agentId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedDatePageable: function (closedCurrentPage, closedPageCount,agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closed/sortDate/all/' + (closedCurrentPage - 1) + "/" + closedPageCount + "/" +agentId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // =============================Counsellor=======================================
    insert: function(agentId,call){
        return new Promise((resolve, reject) => {
            axios.post("/enquiry/counsellor/insert/"+ agentId,call)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCounsellorCount: function(branch){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/count',branch)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorPageable: function(currentPage,pageCount,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/all/'+ (currentPage-1) + "/" + pageCount , data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorClosedCount: function(data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closed/counsellor/count',data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorClosedPageable: function(currentPage,pageCount,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/closedCounsellor/all/'+ (currentPage-1) + "/" + pageCount, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSearchCount: function(keyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/search/count/'+ keyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorSearchPageable: function(currentPage,pageCount,keyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/search/'+ (currentPage-1) + "/" + pageCount  + "/" + keyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSourceCount: function(branchId,source){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/sourceSort/count/'+ branchId,source)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorSourcePageable: function(currentPage,pageCount,branchId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/sourceSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ branchId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorCourseCount: function(branchId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/courseSort/count/'+ branchId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorCoursePageable: function(currentPage,pageCount,branchId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/courseSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ branchId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorDateCount: function (branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/sortDate/count/'+ branchId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorDatePageable: function (currentPage, pageCount,branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/sortDate/all/' + (currentPage - 1) + "/" + pageCount + "/" +branchId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCounsellorClosedCourseCount: function(branchId,course){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSortCourse/count/'+ branchId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorCourseClosedPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSortCourse/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSourceClosedCount: function(branchId,source){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSortSource/count/'+ branchId,source)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorSourceClosedPageable: function(currentPage,pageCount,branchId,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSortSource/all/'+ (currentPage-1) + "/" + pageCount +"/"+ branchId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorClosedDateCount: function (branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closed/sortDate/count/'+ branchId,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllCounsellorClosedDatePageable: function (closedCurrentPage, closedPageCount,branchId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closed/sortDate/all/' + (closedCurrentPage - 1) + "/" + closedPageCount + "/" + branchId, data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getCounsellorSearchClosedCount: function(closedKeyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSearch/count/'+  closedKeyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },   
    getAllCounsellorSearchClosedPageable: function(closedCurrentPage,closedPageCount,closedKeyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/counsellor/closedSearch/'+ (closedCurrentPage-1) + "/" + closedPageCount +"/"+ closedKeyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getContact: function(contact){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/enquiry/contact/' + contact,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getalternativeContact: function(alternativeContact){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/enquiry/alternativeContact/' + alternativeContact,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getContactSearchCount: function(keyword,data){
        return new Promise((resolve, reject) => {
            axios.post('/enquiry/contact/search/'+ keyword,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

}