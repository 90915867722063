import axios from 'axios';
export default{
    putBranch: function(branch){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branch/insert", branch, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAllAdminBranches: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/getAll',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                           
    },
    getAllBranches: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/getAllUnarchive',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                           
    },
    deleteBranch: function(branchId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/delete/' + branchId,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    },
    getBranch: function(branchId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/get/' + branchId,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    },
    updateBranch: function(branch){
        //var ax = axios.create({
        //    baseURL: "http://localhost:8088",
        //});
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branch/update", branch, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    archive : function(branch){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branch/archive/"+ branch, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
     unarchive: function(branch){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/branch/unarchive/"+ branch, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    getCount: function() {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/branch/count" 
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllPageable: function(currentPage, pageCount){
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/all/'+ (currentPage-1) +"/"+ pageCount ,   
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    // searchBranch: function(keyword){
    //     // var ax = axios.create({
    //     //     baseURL: "http://localhost:8088",
    //     // });
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             method: 'get',
    //             url: '/branch/search/' + keyword,
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },
    getSearchCount: function(keyword) {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/branch/search/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/branch/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getNumber: function(contact){       
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/branch/contact/' + contact,
                }).then((response) => {                     
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
               
            });
        },
}