<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- for loader end -->
    <b-container>
        <!-- <div>
            <b-row class="my-4">
                <b-col col lg="7" style="text-align: right">
                    <b-button id="calls" size="sm" variant="outline-primary" @click="showCalls()" v-bind:class="{ active: callsActive }">
                        Calls</b-button>
                </b-col>
                <b-col col lg="2.5">
                    <b-button size="sm" id="calls" variant="outline-primary" @click="showClosed()" v-bind:class="{ active: closedCallsActive }">Closed Calls</b-button>
                </b-col>
                <b-col col lg="2">
                    <b-button size="sm" id="calls" variant="outline-primary" @click="showArchived()" v-bind:class="{ active: archivedCallsActive }">Archived Calls</b-button>
                </b-col>
            </b-row>
        </div> -->
        <!--Archived Calls Table Start-->

        <b-tabs pills card content-class="mt-3" justified>

            <b-modal ref="archivedDateRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button variant="outline-success" type="submit" @click="
              getAllSortArchivedCount(
                selectedArchivedAgent,
                selectedArchivedCourse,
                selectedArchivedSource,
                selectedArchivedBranch,
                selectedArchivedDates
              )
            ">Submit</b-button>
                </div>
            </b-modal>
            <!--Archived Calls Table End-->

            <!-- Calls Table -->
            <!-- <div v-if="isShowCall"> -->
            <b-tab title="Calls" active @click="getAllSortCount('Agent','Course','mode','Source','Branch',null)">
                <!-- <b-collapse id="my-collapse1"> -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!--add Call button start-->
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addCall @click="resetForm()" disabled>Add Call</b-button>
                        </b-col> -->
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCallSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <div class="mb-3 text-end mx-3">
                                    <span v-if="bookmarkPagination" class="text-primary mx-2">Bookmark is enable</span>
                                    <b-button v-if="!bookmarkPagination" @click="bookmarkPagination = true,getAllBookmarkSortCount('Agent','Course','mode','Source','Branch',null)" size="sm" class="" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="click to bookmark show">
                                        <font-awesome-icon icon="star" />
                                    </b-button>
                                    <b-button v-else @click="bookmarkPagination = false,refresh()" size="sm" class="" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="disable bookmark">
                                        <font-awesome-icon icon="star" />
                                    </b-button>
                                </div>
                                <b-container v-if="bookmarkPagination">
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedCourse" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="1" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedMode" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="mode">
                                                    <p>Mode</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="allModes">
                                                    <p>All Modes</p>
                                                </b-form-select-option>
                                                <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                                    <strong>{{ item.text }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedSource" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Agent">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Branch">
                                                    Branches
                                                </b-form-select-option>
                                                <b-form-select-option value="AllBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                                <b-container v-else>
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedCourse" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="1" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedMode" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="mode">
                                                    <p>Mode</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="allModes">
                                                    <p>All Modes</p>
                                                </b-form-select-option>
                                                <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                                    <strong>{{ item.text }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedSource" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Agent">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Branch">
                                                    Branches
                                                </b-form-select-option>
                                                <b-form-select-option value="AllBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />
                            <b-table hover responsive :items="calls" :fields="fields" :per-page="calls.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <!-- <span class="text-primary contact" @click="callHistoryModalShow(data.item.contact)">{{ data.item.contact }}</span> -->
                                    <div class="text-primary contact" @click="callHistoryModalShow(data.item.contact)">
                                        {{ data.item.contact }}<span v-if="data.item.alternativeContact != null && data.item.alternativeContact != ''">,</span>
                                    </div>
                                    <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div>
                                    <!-- <a href="">{{ data.item.contact }}</a> -->
                                </template>
                                <!-- <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template> -->
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewcalldetail @click="getCall(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Closed" @click="getCall(data.item)" v-b-modal.callClosed>
                                            <font-awesome-icon icon="window-close" />
                                        </b-button>
                                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button>
                                        <!-- <b-button
                    v-b-tooltip.hover.bottomright="{ variant: 'warning' }"
                    title="Unarchive"
                    variant="warning"
                    class="px-2"
                    @click="unArchive(data.item.callId)"
                  >
                    <font-awesome-icon icon="archive" />
                  </b-button> -->
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </b-collapse> -->
                <!-- </div> -->
            </b-tab>
            <!-- </b-tabs> -->
            <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button v-if="bookmarkPagination" variant="outline-success" type="submit" @click="getAllBookmarkSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">Submit</b-button>
                    <b-button v-else variant="outline-success" type="submit" @click="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">Submit</b-button>
                </div>
            </b-modal>
            <!-- popup start closed call -->
            <b-modal id="callClosed"  title="Call Closed" no-close-on-backdrop ref="modal" hide-footer>
                <label class="mt-3">Closed for</label>
                <b-form-select class="form-control" v-model="closed" :options="closedOption"></b-form-select>
                <!-- closed for follow insert -->
                <div v-if="closed === 'enquiry'" class="px-2 pt-2">
                    <h5 class="px-5">Name : {{ update.name }}</h5>
                    <h5 class="px-5">Contact : {{ update.contact }}</h5>
                    <div class="form-row mt-3 px-5">
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Followup</label>
                                <div>
                                    <b-form-input format="DD-MM-YYYY h:i:s" v-model="enquiryAs.followupDate" :class="{
                      'is-invalid': $v.enquiryAs.followupDate.$error,
                      'is-valid': !$v.enquiryAs.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                    <div class="valid-feedback">date time is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryAs.followupDate.required">date or time is invalid!</span>
                                    </div>
                                </div>
                                <div>
                                    <b-form-textarea class="form-control" v-model="enquiryAs.remark" :class="{
                      'is-invalid': $v.enquiryAs.remark.$error,
                      'is-valid': !$v.enquiryAs.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                    <div class="valid-feedback">remark is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryAs.remark.required">remark is invalid!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="closed === 'admission'" class="px-2 pt-2">
                    <h5 class="px-5">Name : {{ update.name }}</h5>
                    <h5 class="px-5">Contact : {{ update.contact }}</h5>
                    <div class="form-row mt-3 px-5">
                        <div class="form-row mt-3 px-3">
                            <div class="form-group my-2">
                                <label>CourseType</label>
                                <div>
                                    <b-form-select class="form-control" v-model="courseType" :options="courseOptions" :class="{'is-invalid': $v.courseType.$error,'is-valid': !$v.courseType.$invalid,}"></b-form-select>
                                    <div class="valid-feedback">course type is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.courseType.required">please select a course type!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- closed for follow insert end -->

                <div class="mt-3 mt-2 text-center">
                    <b-button type="submit" @click="disabledButton = true,callClosed()" :disabled="disabledButton" class="px-3" variant="primary">closed To {{closed}}</b-button> &nbsp;
                    <!-- <b-button variant="primary" @click="invoiceAdd()">Add</b-button> -->
                    <!-- <b-button @click="modalShow = !modalShow , getAdminTeam()">Open Modal</b-button> -->
                    <!-- <b-button @click="callClosed()"  /> -->
                </div>
            </b-modal>
            <!-- popup start closed call end -->
            <!--popup start for View Call details-->
            <!-- The modal -->
            <b-modal id="viewcalldetail" no-close-on-backdrop hide-footer title="ViewCall">
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="update.name" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input v-model="update.contact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input v-model="update.alternativeContact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input v-model="update.age" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-input class="form-control" disabled v-model="update.qualification">
                                <option value="null" disabled>Select Qualification</option>
                                <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index" disabled>
                                    <strong disabled>{{ q.text }}</strong>
                                </option>
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-select class="form-control" v-model="update.course" disabled>
                                <option value="null" disabled>Select Course</option>
                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-select class="form-control" v-model="update.source" disabled>
                                <option value="null" disabled>Select Source</option>
                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-select class="form-control" v-model="update.mode" :options="options" disabled></b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-select class="form-control" v-model="update.branch" disabled>
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                        <template v-slot:cell(callId)="data">
                            <span class="title"> {{ data.item.callId }}</span>
                        </template>
                        <!-- <template v-slot:cell(callId)="data">
                  {{ callDate(data.item.followup, "fullDate") }}
                </template> -->
                        <template v-slot:cell(createdAt)="data">
                            {{ callDate(data.item.createdAt, "fullDate") }}
                        </template>
                        <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template>
                    </b-table>
                </div>
            </b-modal>
            <!--popup end for View Call details-->
            <!-- Closed Calls Table -->
            <!-- <div v-if="isShowClosed"> -->
            <b-tab title="Closed Calls" @click="getAllSortClosedCount('Agent','Courses','mode','Sources','Branches',null)">
                <!-- <b-collapse  id="my-collapse">             -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!--Closed Call button start-->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-toggle.my-collapse disabled>Closed</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="closedKeyword" placeholder="Type a name" v-on:keyup.enter="getClosedCallSearchCount(closedKeyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getClosedCallSearchCount(closedKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="closedCurrentPage" :total-rows="closedTotalCount" :per-page="closedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchClosedPage(closedCurrentPage, closedPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select-closed" v-model="closedPageCount" :options="closedPageOptions" @input="switchClosedPage(closedCurrentPage, closedPageCount)" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="closedRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedCourse" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Courses">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="1" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedClosedMode" size="sm" @change="
              getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
            ">
                                                <b-form-select-option disabled value="mode">
                                                    <p>Mode</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="allModes">
                                                    <p>All Modes</p>
                                                </b-form-select-option>
                                                <option v-for="item in modeClosedOptions" :value="item.value" v-bind:key="item.index">
                                                    <strong>{{ item.text }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedSource" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Sources">
                                                    <p>Source</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedClosedAgent" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Agent">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedBranch" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Branches">
                                                    <p>Branches</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllClosedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedClosedDates" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedAgent,
                            selectedClosedCourse,
                            selectedClosedMode,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        " :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />

                            <!--Closed Calls Table -->
                            <b-table hover responsive :items="closedCalls" :fields="closefields" :current-page="closedCurrentPage" :per-page="closedCalls.closedPageCount">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <!-- <span class="text-primary contact" @click="callHistoryModalShow(data.item.contact)">{{ data.item.contact }}</span> -->
                                    <div class="text-primary contact" @click="callHistoryModalShow(data.item.contact)">
                                        {{ data.item.contact }}<span v-if="data.item.alternativeContact != null && data.item.alternativeContact != ''">,</span>
                                    </div>
                                    <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div>
                                    <!-- <a href="">{{ data.item.contact }}</a> -->
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewcalldetail @click="getCall(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="closedArchive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </b-collapse>        -->
                <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
                    <div>
                        <label for="from-datepicker">Choose The From date</label>
                        <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
                'is-invalid': $v.from.$error,
                'is-valid': !$v.from.$invalid,
              }" type="date"></b-form-input>
                    </div>
                    <div>
                        <label for="to-datepicker">Choose The To date</label>
                        <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
                'is-invalid': $v.to.$error,
                'is-valid': !$v.to.$invalid,
              }" type="date"></b-form-input>
                    </div>
                    <div class="text-center mt-3">
                        <b-button variant="outline-success" type="submit" @click="
                getAllSortClosedCount(
                  selectedClosedAgent,
                  selectedClosedCourse,
                  selectedClosedMode,
                  selectedClosedSource,
                  selectedClosedBranch,
                  selectedClosedDates
                )
              ">Submit</b-button>
                    </div>
                </b-modal>
                <!-- </div> -->
            </b-tab>
            <b-tab title="Archived Calls" @click="getAllSortArchivedCount('Agent','Courses','Sources','Branches',null)">
                <!-- <div v-if="isShowArchived"> -->
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!--Closed Call button start-->
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" v-b-toggle.my-collapse disabled>Archived</b-button>
                            </b-col>
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="archivedKeyword" placeholder="Type a name" v-on:keyup.enter="getArchivedCallSearchCount(archivedKeyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getArchivedCallSearchCount(archivedKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="archivedCurrentPage" :total-rows="archivedTotalCount" :per-page="archivedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    switchArchivedPage(archivedCurrentPage, archivedPageCount)
                  "></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select-closed" v-model="archivedPageCount" :options="archivedPageOptions" @input="
                    switchArchivedPage(archivedCurrentPage, archivedPageCount)
                  " size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="archivedRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedArchivedCourse" size="sm" @change="
                          getAllSortArchivedCount(
                            selectedArchivedAgent,
                            selectedArchivedCourse,
                            selectedArchivedSource,
                            selectedArchivedBranch,
                            selectedArchivedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Courses">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllArchivedCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedArchivedSource" size="sm" @change="
                          getAllSortArchivedCount(
                            selectedArchivedAgent,
                            selectedArchivedCourse,
                            selectedArchivedSource,
                            selectedArchivedBranch,
                            selectedArchivedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Sources">
                                                    <p>Source</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllArchivedSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="4" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedArchivedAgent" size="sm" @change="
                          getAllSortArchivedCount(
                            selectedArchivedAgent,
                            selectedArchivedCourse,
                            selectedArchivedSource,
                            selectedArchivedBranch,
                            selectedArchivedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Agent">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllArchivedAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedArchivedBranch" size="sm" @change="
                          getAllSortArchivedCount(
                            selectedArchivedAgent,
                            selectedArchivedCourse,
                            selectedArchivedSource,
                            selectedArchivedBranch,
                            selectedArchivedDates
                          )
                        ">
                                                <b-form-select-option disabled value="Branches">
                                                    <p>Branches</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllArchivedBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedArchivedDates" size="sm" @change="
                          getAllSortArchivedCount(
                            selectedArchivedAgent,
                            selectedArchivedCourse,
                            selectedArchivedSource,
                            selectedArchivedBranch,
                            selectedArchivedDates
                          )
                        " :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />

                            <!--Closed Calls Table -->
                            <b-table hover responsive :items="archivedCalls" :fields="archivedfields" :current-page="archivedCurrentPage" :per-page="archivedCalls.archivedPageCount">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewcalldetail @click="getCall(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive" variant="warning" size="sm" class="mx-1" @click="unArchive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </div> -->
            </b-tab>
            <!-- Leads show -->
            <!-- <b-tab title="Leads" @click="leadShow = true">
            <leads v-if="leadShow == true" />
        </b-tab> -->
            <!-- Leads show end-->
            <!-- </b-tabs> -->
        </b-tabs>
    </b-container>
    <b-modal ref="callHistory" hide-footer hide-header size="md">
        <h5>Call History</h5>
        <div>{{currentContact}}</div>
        <hr>
        <div class="text-center" v-if="callHistoryLogs.length == 0">
            No History Found...
        </div>
        <div v-else>
            <div v-for="c in callHistoryLogs" :key="c.callLogId" class="py-1">
                <div class="row">
                    <div class="col-2 d-flex justify-content-center align-items-center">
                        <span v-if="c.type == 1">
                            <img src="../../public/incoming-call.png" width="30">
                        </span>
                        <span v-else-if="c.type == 2">
                            <img src="../../public/outgoing-call.png" width="30">
                        </span>
                        <span v-else-if="c.type == 3">
                            <img src="../../public/missed-call.png" width="30">
                        </span>
                        <span v-else-if="c.type == 4">
                            <img src="../../public/unknown-call.png" width="45">
                        </span>
                        <span v-else>
                            <img src="../../public/call-other.png" width="40">
                        </span>
                    </div>
                    <div class="col-10">
                        <div>
                            <span>
                                {{ callDate(c.date,"fullDate") }}
                            </span>
                        </div>
                        <div class="text-secondary">

                            <span v-if="c.type == 1">
                                <!-- incoming <span>{{ c.duration }}</span> seconds -->
                                incoming <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                            </span>
                            <span v-else-if="c.type == 2">
                                <!-- outgoing <span>{{ c.duration }}</span> seconds -->
                                outgoing <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                            </span>
                            <span v-else-if="c.type == 3">
                                missed call
                            </span>
                            <span v-else-if="c.type == 4">
                                <!-- <span>{{ c.duration }}</span> seconds -->
                                unknown call <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                            </span>
                            <span v-else>
                                <span v-if="c.duration != 0">
                                    <span v-if="c.duration != 0">{{countdown(c.duration)}}</span><span v-else>not connected</span>
                                </span>
                                <span v-else>
                                    no seconds
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <!-- < ==================================== invoice =======================================> -->

    <!-- <b-modal id="modal-1"  hide-footer> -->
    <b-modal v-model="admissionModal" size="lg" hide-footer hide-header>
        <invoice-apply  :disableButton = "false" :invoicedata="update" :courseType="courseType"   :organization="organization"   @close-modal="closeModal" @close-icon="closeIcon" ></invoice-apply>
    </b-modal>
    <!-- </b-modal> -->
</div>
</template>

<script>
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import AdminService from "../service/AdminService";
import CallService from "../service/CallService";
import FollowupService from "../service/FollowupService";
import Account from '../service/Account.js';
import moment from "moment-timezone";
import axios from "axios";
import qualification from "../assets/json_file/qualification.json";
import TeamService from "../service/TeamService";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
// import Leads from './Leads.vue';
export default {
    name: "AdminCalls",
    components: {
        // Leads
    },
    data() {
        return {
            callHistoryLogs: [],
            currentContact: "",
            leadShow: false,
            selectedMode: "mode",
            //loading: false,
            disableButton: false,
            admissionModal: false,
            isApply: false,
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],

            selectedClosedMode: "mode",
            //loading: false,
            //disableButton: false,
            modeClosedOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],

            paginationCalls: true,
            allSortCalls: false,
            bookmarkPagination: false,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            keyword: "",
            //for closedcall search
            closedKeyword: "",
            archivedKeyword: "",
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            selectedClosedCourse: "Courses",
            selectedClosedSource: "Sources",
            selectedClosedBranch: "Branches",
            selectedClosedAgent: "Agent",
            selectedDates: null,
            selectedArchivedCourse: "Courses",
            selectedArchivedSource: "Sources",
            selectedArchivedBranch: "Branches",
            selectedArchivedAgent: "Agent",
            selectedArchivedDates: null,
            from: "",
            to: "",
            fromValue: "",
            toValue: "",
            data: null,
            paginationAdmissions: false,
            searchPaginationAdmissions: false,
            sortAgentPaginationAdmissions: false,
            sortCoursePaginationAdmissions: false,
            sortSourcePaginationAdmissions: false,
            sortBranchPaginationAdmissions: false,
            sortDatePaginationAdmissions: false,
            // for loader
            loading: false,
            // Call table show
            // isShowCall: true,
            // isShowClosed: false,
            // isShowArchived: false,
            callsActive: true,
            closedCallsActive: false,
            archivedCallsActive: false,
            // isShowFollowup : false,
            calls: [],
            fields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },

                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            update: {
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            closed: null,
            enquiryAs: {
                followupDate: "",
                remark: "",
            },
            qualificationList: qualification,
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            // followup details render in view modal table
            followup: [],
            followupFields: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            followupDateRemark: [],
            // Closed Call all data render in table
            closedCalls: [],
            closefields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            archivedCalls: [],
            invoice: {
                userName: "",
                contact: "",
                courseName: "",
                price: "",
                alternativeContact: "",
                mode: "",
            },
            archivedfields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            // for calls pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            // for closed pagination
            closedRows: 100,
            closedCurrentPage: 1,
            closedPageCount: 5,
            closedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            disabledButton: false,
            // closed: null,
            closedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "enquiry",
                    text: "Enquiry",
                },
                {
                    value: "admission",
                    text: "Admission",
                },
                {
                    value: "notintrested",
                    text: "Notintrested",
                },
            ],
            closedTotalCount: 0,
            archivedTotalCount: 0,
            archivedCurrentPage: 1,
            archivedPageCount: 5,
            organization: "",
            archivedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            selectedClosedDates: null,
            courseType: null,
            courseOptions: [{
                    value: null,
                    text: "select course type",
                    disabled: true,
                },
                {
                    value: 'Online Test',
                    text: 'Online Test',
                },
                {
                    value: 'Offline Test',
                    text: 'Offline Test',
                },
                {
                    value: 'Online Course',
                    text: 'Online Course',
                },
                {
                    value: 'Offline Course',
                    text: 'Offline Course',
                },
                {
                    value: 'Online Book',
                    text: 'Online Book',
                },
                {
                    value: 'Offline Book',
                    text: 'Offline Book',
                },
            ]
        };
    },
    validations: {
        enquiryAs: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        // closed: {
        //   required,

        // },
        from: {
            required,
        },
        to: {
            required,
        },
        courseType: {
            required
        }
    },

    // mounted() {
    //   let self = this;
    //   return new Promise((resolve, reject) => {
    //     Account.getAccessToken(localStorage.getItem("refreshToken"))
    //       .then((response) => {
    //           // this.agentId = localStorage.getItem("agentId");
    //         // self.getAllCalls();
    //         self.getCount();
    //         self.getAllBranches();
    //         self.getAllCourses();
    //         self.getAllSources();
    //         self.getAllAgents();
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         reject(err);
    //       });
    //   });
    // },
    mounted() {
        //console.log('o===============', this.update)
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    // this.getAllCalls();
                    // this.closedGetCount();
                    // this.getCount();
                    this.getAllSortCount("Agent", "Course", "mode", "Source", "Branch", null);
                    // this.getAllSortClosedCount("Agent","Courses","mode","Sources","Branches",null);
                    // this.getAllSortArchivedCount("Agent","Courses","Sources","Branches",null);
                    // this.getClosedCount();
                    // this.getArchivedCount();
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    this.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
        closedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getClosedCount();
            }
        },
        archivedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getArchivedCount();
            }
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBookmarkSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            //console.log("getAllSortCount------", selectedMode);
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = false;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.bookmarkPagination = true;
                this.currentPage = 1;
                this.keyword = "";
                // //console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/call/bookmark/sort/count", this.data)
                        .then((response) => {
                            // //console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllBookmarkSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllBookmarkSortPageable: function (currentPage, pageCount, data) {
            //console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/admin/call/bookmark/sort/pageable/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.calls = response.data;
                        // //console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        callHistoryModalShow(contact) {
            this.loading = true;
            let data = {
                contact: contact,
            }
            this.currentContact = contact;
            return new Promise((resolve, reject) => {
                axios
                    .post("admin/callLog/get/call/history", data)
                    .then((response) => {
                        this.callHistoryLogs = response.data;
                        this.$refs.callHistory.show();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        countdown(s) {
            const d = Math.floor(s / (3600 * 24));
            s -= d * 3600 * 24;
            const h = Math.floor(s / 3600);
            s -= h * 3600;
            const m = Math.floor(s / 60);
            s -= m * 60;
            const tmp = [];
            (d) && tmp.push(d + 'ds');
            (d || h) && tmp.push(h + 'h');
            (d || h || m) && tmp.push(m + 'm');
            tmp.push(s + 's');
            return tmp.join(' ');
        },
        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            //console.log("getAllSortCount------", selectedMode);
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.bookmarkPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // //console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/call/all/sort/count", this.data)
                        .then((response) => {
                            // //console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortPageable: function (currentPage, pageCount, data) {
            //console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/admin/call/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.calls = response.data;
                        // //console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // showFollowup() {
        //   this.isShowFollowup = true;
        // },

        // getCallsBetweenDates: function (range) {
        //     let from = new Date();
        //     let to = new Date;
        //     if(range=='yesterday'){
        //        from = from.setDate(from.getDate() - 1);
        //        to = to.setDate(to.getDate() - 1);
        //     }else if(range=='yesterweek'){

        //     }
        //     else if(range=='yestermonth'){

        //     }
        //     return new Promise((resolve, reject) => {
        //         this.loading = true
        //         AdminService.getToday()
        //             .then((response) => {
        //                 ////console.log(response.data)
        //                 this.calls = response.data;
        //                 this.loading = false
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        // getTodayCalls: function () {
        //     return new Promise((resolve, reject) => {
        //         this.loading = true
        //         AdminService.getToday()
        //             .then((response) => {
        //                 ////console.log(response.data)
        //                 this.calls = response.data;
        //                 this.loading = false
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        //  getCall: function (call) {
        //       // this.call = call;
        //       return new Promise((resolve, reject) => {
        //         CallService.getCall(call.callId)
        //           .then((response) => {
        //       this.update = {
        //          callId: response.data.callId,
        //         name: response.data.name,
        //         contact: response.data.contact,
        //         alternativeContact: response.data.alternativeContact,
        //         age: response.data.age,
        //         qualification: response.data.qualification,
        //         course: response.data.course,
        //         source: response.data.source,
        //         mode: response.data.mode,
        //         branch: response.data.branch,
        //         followup: response.data.followup
        //       };
        //       // ////console.log(this.call);
        //             this.resetFollowupUpdate();
        //             this.agentId = response.data.agent.agentId;
        //             ////console.log(response.data);

        //             resolve(response);
        //           })
        //           .catch((err) => {
        //             reject(err);
        //           });
        //       });
        //     },
        getCall: function (call) {
            this.call = call;
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(call.callId)
                FollowupService.getFollowupCreatedDate(call.callId)
                    .then((response) => {
                        this.update = {
                            callId: call.callId,
                            name: call.name,
                            contact: call.contact,
                            alternativeContact: call.alternativeContact,
                            age: call.age,
                            qualification: call.qualification,
                            course: call.course,
                            source: call.source,
                            mode: call.mode,
                            branch: call.branch,

                            // followup: response.data.followup
                        };
                        this.resetFollowupUpdate();
                        this.followupDateRemark = response.data;
                        this.agentId = call.agent.agentId;
                        this.disableButton = false;
                        // //console.log(this.agentId)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        // getCourseCount: function(selectedCourse) {
        //   if (this.selectedCourse == "AllCourses") {
        //     ////console.log("course if");
        //     this.getCount();
        //     ////console.log("SELECTED COURSE", this.selectedCourse);
        //   } else {
        //     ////console.log("Course Calling");
        //     ////console.log("SELECTED COURSE", this.selectedCourse);
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     this.loading = true;
        //     ////console.log("coursecount",this.data);
        //     return new Promise((resolve, reject) => {
        //       AdminService.getCourseCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllCoursePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePaginationCalls = true;
        //           this.sortSourcePaginationCalls = false;
        //           this.sortBranchPaginationCalls = false;
        //           this.sortAgentPaginationCalls = false;
        //           this.sortDatePaginationCalls = false;
        //           this.searchPagination = false;
        //           this.paginationCalls = false;
        //           this.selectedSource = "Source";
        //           this.selectedBranch = "Branch";
        //           this.selectedAgent = "Agent";
        //           this.selectedDates = null;

        //           this.currentPage = 1;
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getCallSearchCount: function (keyword) {
            ////console.log("search pagecount", keyword);
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            this.searchPagination = true;
            this.paginationCalls = false;
            this.allSortCalls = false;
            this.bookmarkPagination = false;
            this.currentPage = 1;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            return new Promise((resolve, reject) => {
                AdminService.getCallSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        ////console.log("search pagecount", response.data);
                        this.getAllCallSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCallSearchPageable: function (currentPage, pageCount, keyword) {
            ////console.log("search page calling");
            return new Promise((resolve, reject) => {
                AdminService.getAllCallSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getAllCoursePageable: function(currentPage, pageCount, data) {
        //   ////console.log("coursepageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllCoursePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getSourceCount: function(selectedSource) {
        //   this.sortCoursePaginationCalls = false;
        //   this.sortSourcePaginationCalls = true;
        //   this.sortBranchPaginationCalls = false;
        //   this.sortAgentPaginationCalls = false;
        //   this.sortDatePaginationCalls = false;
        //   this.searchPagination = false;
        //   this.paginationCalls = false;
        //   this.selectedCourse = "Course";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.selectedDates = null;
        //   this.currentPage = 1;

        //   if (this.selectedSource == "AllSources") {
        //     ////console.log("course if");
        //     this.getCount();
        //     ////console.log("SELECTED COURSE", this.selectedSource);
        //   } else {
        //     ////console.log("source Calling");
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     this.loading = true;
        //     ////console.log("Sourcecount", this.data);
        //     return new Promise((resolve, reject) => {
        //       AdminService.getSourceCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllSourcePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );

        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllSourcePageable: function(currentPage, pageCount, data) {
        //   ////console.log("Sourcepageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllSourcePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getBranchCount: function(selectedBranch) {
        //   if (this.selectedBranch == "AllBranches") {
        //     ////console.log("course if");
        //     this.getCount();
        //     ////console.log("SELECTED COURSE", this.selectedBranch);
        //   } else {
        //     ////console.log("branch Calling");
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     this.sortCoursePaginationCalls = false;
        //     this.sortSourcePaginationCalls = false;
        //     this.sortBranchPaginationCalls = true;
        //     this.sortAgentPaginationCalls = false;
        //     this.sortDatePaginationCalls = false;
        //     this.searchPagination = false;
        //     this.paginationCalls = false;
        //     this.selectedCourse = "Course";
        //     this.selectedSource = "Source";
        //     this.selectedAgent = "Agent";
        //     this.selectedDates = null;
        //     this.currentPage = 1;

        //     this.loading = true;
        //     ////console.log("branchcount", this.data);
        //     return new Promise((resolve, reject) => {
        //       AdminService.getBranchCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllBranchPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );

        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllBranchPageable: function(currentPage, pageCount, data) {
        //   ////console.log("Branchpageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllBranchPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAgentCount: function(selectedAgent) {
        //   if (this.selectedAgent == "AllAgents") {
        //     ////console.log("course if");
        //     this.getCount();
        //     ////console.log("SELECTED COURSE", this.selectedAgent);
        //   } else {
        //     ////console.log("agent Calling");
        //     this.data = {
        //       agentId: selectedAgent.agentId,
        //     };
        //     ////console.log("Agentcount", this.data);
        //     this.sortCoursePaginationCalls = false;
        //     this.sortSourcePaginationCalls = false;
        //     this.sortBranchPaginationCalls = false;
        //     this.sortDatePaginationCalls = false;
        //     this.sortAgentPaginationCalls = true;
        //     this.searchPagination = false;
        //     this.paginationCalls = false;
        //     this.selectedCourse = "Course";
        //     this.selectedSource = "Source";
        //     this.selectedBranch = "Branch";
        //     this.selectedDates = null;
        //     this.currentPage = 1;

        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminService.getAgentCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllAgentPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );

        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllAgentPageable: function(currentPage, pageCount, data) {
        //   ////console.log("Agentpageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllAgentPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         this.calls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getCourseDetail: function(course) {
        //   if (this.selectedCourse == "AllCourses") {
        //     ////console.log("course if");
        //     this.getAllCalls();
        //     ////console.log("SELECTED COURSE", this.selectedCourse);
        //   } else {
        //     ////console.log("Course Calling");
        //     ////console.log("SELECTED COURSE", this.selectedCourse);
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/call/sort/course", data)
        //         .then((response) => {
        //           ////console.log(response.data);
        //           this.calls = response.data;
        //           this.getCourseCount(data);
        //           this.selectedSource = "AllSources";
        //           this.selectedBranch = "AllBranches";
        //           this.selectedAgent = "AllAgents";
        //           this.selectedDates = null;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getSourceDetail: function(source) {
        //   if (this.selectedSource == "AllSources") {
        //     ////console.log("course if");
        //     this.getAllCalls();
        //     ////console.log("SELECTED COURSE", this.selectedSource);
        //   } else {
        //     ////console.log("source Calling");
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/call/sort/source", data)
        //         .then((response) => {
        //           ////console.log(response.data);
        //           this.calls = response.data;
        //           this.getSourceCount(data);
        //           this.selectedCourse = "Courses";
        //           this.selectedBranch = "Branches";
        //           this.selectedAgent = "Agents";
        //           this.selectedDates = "Dates";
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getBranchDetail: function(branch) {
        //   if (this.selectedBranch == "AllBranches") {
        //     ////console.log("course if");
        //     this.getAllCalls();
        //     ////console.log("SELECTED COURSE", this.selectedBranch);
        //   } else {
        //     ////console.log("branch Calling");
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/call/sort/branch", data)
        //         .then((response) => {
        //           ////console.log(response.data);
        //           this.calls = response.data;
        //           this.getBranchCount(data);
        //           this.selectedCourse = "AllCourses";
        //           this.selectedSource = "AllSources";
        //           this.selectedAgent = "AllAgents";
        //           this.selectedDates = "Dates";
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAgentDetail: function(agent) {
        //   if (this.selectedAgent == "AllAgents") {
        //     ////console.log("course if");
        //     this.getAllCalls();
        //     ////console.log("SELECTED COURSE", this.selectedAgent);
        //   } else {
        //     ////console.log("agent Calling");
        //     let data = {
        //       agentId: agent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/call/sort/agent", data)
        //         .then((response) => {
        //           ////console.log(response.data);
        //           this.calls = response.data;
        //           this.getAgentCount(data);
        //           this.selectedCourse = "AllCourses";
        //           this.selectedSource = "AllSources";
        //           this.selectedBranch = "AllBranches";
        //           this.selectedDates = "Dates";
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllCalls: function () {
            // ////console.log("getall");
            this.showCalls();
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminService.getAllCalls()
                    .then((response) => {
                        this.calls = response.data;
                        this.getCount();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCount: function () {
            // this.showCalls();
            this.loading = true;
            this.allSortCalls = false;
            this.paginationCalls = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            this.currentPage = 1;

            return new Promise((resolve, reject) => {
                AdminService.getCallCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllCallPageable(currentPage, pageCount)
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        // //console.log(response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllPageable(currentPage, pageCount);
                // //console.log(this.paginationCalls, "this.paginationCalls");
                // //console.log(this.allSortCalls, "this.allSortCalls");
            } else if (this.allSortCalls) {
                this.getAllSortPageable(currentPage, pageCount, this.data);
                // //console.log(this.allSortCalls, "this.allSortCalls");
                // //console.log(this.paginationCalls, "this.paginationCalls");
            }
            // else if (this.sortCoursePaginationCalls) {
            //   this.getAllCoursePageable(currentPage, pageCount, this.data);
            //   ////console.log(this.sortCoursePaginationCalls,"sortCoursePaginationCalls")
            // } else if (this.sortSourcePaginationCalls) {
            //   this.getAllSourcePageable(currentPage, pageCount, this.data);
            //   ////console.log(this.sortSourcePaginationCalls,"sortSourcePaginationCalls")
            // } else if (this.sortBranchPaginationCalls) {
            //   this.getAllBranchPageable(currentPage, pageCount, this.data);
            //   ////console.log(this.sortBranchPaginationCalls,"sortBranchPaginationCalls")
            // } else if (this.sortAgentPaginationCalls) {
            //   this.getAllAgentPageable(currentPage, pageCount, this.data);
            //   ////console.log(this.sortAgentPaginationCalls,"sortAgentPaginationCalls")
            // } else if (this.sortDatePaginationCalls) {
            //   this.getAllDatePageable(currentPage, pageCount, this.data);
            //   ////console.log(this.sortDatePaginationCalls,"sortDatePaginationCalls")
            // }
            else if (this.searchPagination) {
                this.getAllCallSearchPageable(currentPage, pageCount, this.keyword);
                ////console.log(this.searchPagination,"searchPagination")
            } else if (this.bookmarkPagination) {
                this.getAllBookmarkSortPageable(currentPage, pageCount, this.data);
            }
        },
        searchCall: function (keyword) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                AdminService.searchCall(keyword.toLowerCase())
                    .then((response) => {
                        this.calls = response.data;
                        this.getCallSearchCount(keyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        //=================================ADMIN CLOSED CALL========================================================
        getAllSortClosedCount: function (
            selectedClosedAgent,
            selectedClosedCourse,
            selectedMode,
            selectedClosedSource,
            selectedClosedBranch,
            selectedClosedDates
        ) {
            // //console.log("selectedClosedAgent=== ", selectedClosedAgent);
            // //console.log("selectedClosedCourse=== ", selectedClosedCourse);
            // //console.log("selectedClosedSource=== ", selectedClosedSource);
            // //console.log("selectedClosedBranch=== ", selectedClosedBranch);
            // //console.log("selectedClosedDates=== ", selectedClosedDates);

            if (selectedClosedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedClosedDates = "dateBetween";
            } else {
                if (
                    selectedClosedAgent == "Agent" ||
                    selectedClosedAgent == "AllClosedAgents"
                ) {
                    selectedClosedAgent = {
                        agentId: "",
                    };
                }
                if (
                    selectedClosedCourse == "Courses" ||
                    selectedClosedCourse == "AllClosedCourses"
                ) {
                    selectedClosedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (
                    selectedClosedSource == "Sources" ||
                    selectedClosedSource == "AllClosedSources"
                ) {
                    selectedClosedSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedClosedBranch == "Branches" ||
                    selectedClosedBranch == "AllClosedBranches"
                ) {
                    selectedClosedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedClosedDates != null) {
                    var date = new Date();
                    if (selectedClosedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedClosedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedClosedDates == null ||
                    selectedClosedDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedClosedAgent.agentId,
                    courseId: selectedClosedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedClosedSource.sourceId,
                    branchId: selectedClosedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;

                this.closedCurrentPage = 1;
                this.closedKeyword = "";
                // //console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/closedCall/all/sort/count", this.data)
                        .then((response) => {
                            // //console.log("getAllSortClosedCount======", response.data);
                            this.closedTotalCount = response.data;
                            this.getAllSortClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // //console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/closedCall/all/sort/" +
                        closedCurrentPage +
                        "/" +
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedCalls = response.data;
                        // //console.log("getAllSortClosedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // getClosedCourseCount: function(selectedCourse) {
        //   if (this.selectedClosedCourse == "AllClosedCourses") {
        //     ////console.log("course if");
        //     this.getClosedCount();
        //     ////console.log("SELECTED COURSE", this.selectedCourse);
        //   } else {
        //     ////console.log("closed Course Calling");
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     ////console.log("closedcoursecount", this.data);
        //     this.sortCoursePaginationCalls = true;
        //     this.sortSourcePaginationCalls = false;
        //     this.sortBranchPaginationCalls = false;
        //     this.sortAgentPaginationCalls = false;
        //     this.sortDatePaginationCalls = false;
        //     this.searchPagination = false;
        //     this.paginationCalls = false;
        //     this.closedCurrentPage = 1;

        //     this.loading = true;

        //     return new Promise((resolve, reject) => {
        //       AdminService.getClosedCourseCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedCoursePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getClosedCallSearchCount: function (closedKeyword) {
            ////console.log("Closedsearch pagecount", closedKeyword);
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            this.searchPagination = true;
            this.paginationCalls = false;
            this.allSortCalls = false;
            this.bookmarkPagination = false;
            this.closedCurrentPage = 1;
            this.loading = true;

            return new Promise((resolve, reject) => {
                AdminService.getClosedCallSearchCount(closedKeyword.toLowerCase())
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        ////console.log("Closedsearch pagecount", response.data);
                        this.getAllClosedCallSearchPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            closedKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedCallSearchPageable: function (
            closedCurrentPage,
            closedPageCount,
            closedKeyword
        ) {
            ////console.log("Closedsearch page calling");
            return new Promise((resolve, reject) => {
                AdminService.getAllClosedCallSearchPageable(
                        closedCurrentPage,
                        closedPageCount,
                        closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // getAllClosedCoursePageable: function(
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   ////console.log("Closedcoursepageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllClosedCoursePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.closedCalls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedSourceCount: function(selectedSource) {
        //   if (this.selectedClosedSource == "AllClosedSources") {
        //     ////console.log("Source if");
        //     this.getClosedCount();
        //     ////console.log("SELECTED Source", this.selectedSource);
        //   } else {
        //     ////console.log("source Calling");
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };

        //     this.sortCoursePaginationCalls = false;
        //     this.sortSourcePaginationCalls = true;
        //     this.sortBranchPaginationCalls = false;
        //     this.sortAgentPaginationCalls = false;
        //     this.sortDatePaginationCalls = false;
        //     this.searchPagination = false;
        //     this.paginationCalls = false;
        //     this.selectedClosedCourse = "Courses";
        //     this.selectedClosedBranch = "Branches";
        //     this.selectedClosedAgent = "Agent";
        //     this.selectedClosedDates = null;
        //     this.closedCurrentPage = 1;

        //     this.loading = true;
        //     ////console.log("ClosedSourcecount", this.data);
        //     return new Promise((resolve, reject) => {
        //       AdminService.getClosedSourceCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedSourcePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedSourcePageable: function(
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   ////console.log("ClosedSourcepageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllClosedSourcePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.closedCalls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedBranchCount: function(selectedBranch) {
        //   if (this.selectedClosedBranch == "AllClosedBranches") {
        //     ////console.log("Branch if");
        //     this.getClosedCount();
        //     ////console.log("SELECTED Branch", this.selectedBranch);
        //   } else {
        //     // ////console.log("branch Calling");
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     this.loading = true;
        //     this.sortCoursePaginationCalls = false;
        //     this.sortSourcePaginationCalls = false;
        //     this.sortBranchPaginationCalls = true;
        //     this.sortDatePaginationCalls = false;
        //     this.sortAgentPaginationCalls = false;
        //     this.searchPagination = false;
        //     this.paginationCalls = false;
        //     this.selectedClosedCourse = "Courses";
        //     this.selectedClosedSource = "Sources";
        //     this.selectedClosedAgent = "Agent";
        //     this.selectedClosedDates = null;
        //     this.closedCurrentPage = 1;

        //     // ////console.log("BranchClosedcount", this.data);
        //     return new Promise((resolve, reject) => {
        //       AdminService.getClosedBranchCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedBranchPageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );

        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllClosedBranchPageable: function(
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   // ////console.log("BranchClosedpageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllClosedBranchPageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.closedCalls = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedAgentCount: function(selectedClosedAgent) {
        //   if (this.selectedClosedAgent == "AllClosedAgents") {
        //     // ////console.log("Agent if");
        //     this.getClosedCount();
        //     // ////console.log("SELECTED Agent", this.selectedClosedAgent);
        //   }
        //   // ////console.log("agent closedCall");
        //   this.data = {
        //     agentId: selectedClosedAgent.agentId,
        //   };
        //   this.loading = true;
        //   this.sortCoursePaginationCalls = false;
        //   this.sortSourcePaginationCalls = false;
        //   this.sortBranchPaginationCalls = false;
        //   this.sortDatePaginationCalls = false;
        //   this.sortAgentPaginationCalls = true;
        //   this.searchPagination = false;
        //   this.paginationCalls = false;
        //   this.selectedClosedCourse = "Courses";
        //   this.selectedClosedSource = "Sources";
        //   this.selectedClosedBranch = "Branches";
        //   this.selectedClosedDates = null;
        //   this.closedCurrentPage = 1;

        //   // ////console.log("ClosedAgentcount", this.data);
        //   return new Promise((resolve, reject) => {
        //     AdminService.getClosedAgentCount(this.data)
        //       .then((response) => {
        //         this.closedTotalCount = response.data;
        //         this.getAllClosedAgentPageable(
        //           this.closedCurrentPage,
        //           this.closedPageCount,
        //           this.data
        //         );

        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAllClosedAgentPageable: function(
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   // ////console.log("ClosedAgentpageable calling");
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllClosedAgentPageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         resolve(response);
        //         this.closedCalls = response.data;
        //         // ////console.log(response.data)
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedCourseDetail: function (course) {
        //   if (this.selectedClosedCourse == "AllClosedCourses") {
        //     // ////console.log("course if");
        //     this.getAllClosedCalls();
        //     // ////console.log("SELECTED COURSE", this.selectedClosedCourse);
        //   } else {
        //     // ////console.log("Course closedCall");
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/closedCall/sort/course", data)
        //         .then((response) => {
        //           // ////console.log(response.data);
        //           this.closedCalls = response.data;
        //           this.getClosedCourseCount(data);
        //           this.selectedClosedSource = "AllClosedSources";
        //           this.selectedClosedBranch = "AllClosedBranches";
        //           this.selectedClosedAgent = "AllClosedAgents";
        //           this.selectedClosedDates = null;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedSourceDetail: function (source) {
        //   if (this.selectedClosedSource == "AllClosedSources") {
        //     // ////console.log("Source if");
        //     this.getAllClosedCalls();
        //     // ////console.log("SELECTED Source", this.selectedClosedSource);
        //   } else {
        //     // ////console.log("source closedCall");
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("admin/closedCall/sort/source", data)
        //         .then((response) => {
        //           // ////console.log(response.data);
        //           this.closedCalls = response.data;
        //           this.getClosedSourceCount(data);
        //           this.selectedClosedCourse = "Courses";
        //           this.selectedClosedBranch = "Branches";
        //           this.selectedClosedAgent = "Agents";
        //           this.selectedClosedDates = null;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedBranchDetail: function (branch) {
        //   if (this.selectedClosedBranch == "AllClosedBranches") {
        //     // ////console.log("Branch if");
        //     this.getAllClosedCalls();
        //     // ////console.log("SELECTED Branch", this.selectedClosedBranch);
        //   }
        //   // ////console.log("branch closedCall");
        //   let data = {
        //     branchId: branch.branchId,
        //   };
        //   return new Promise((resolve, reject) => {
        //     axios
        //       .post("admin/closedCall/sort/branch", data)
        //       .then((response) => {
        //         // ////console.log(response.data);
        //         this.closedCalls = response.data;
        //         this.getClosedBranchCount(data);
        //         this.selectedClosedCourse = "AllClosedCourses";
        //         this.selectedClosedSource = "AllClosedSources";
        //         this.selectedClosedAgent = "AllClosedAgents";
        //         this.selectedClosedDates = null;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getClosedAgentDetail: function (agent) {
        //   if (this.selectedClosedAgent == "AllClosedAgents") {
        //     // ////console.log("Agent if");
        //     this.getAllClosedCalls();
        //     // ////console.log("SELECTED Agent", this.selectedClosedAgent);
        //   }
        //   // ////console.log("agent closedCall");
        //   let data = {
        //     agentId: agent.agentId,
        //   };
        //   return new Promise((resolve, reject) => {
        //     axios
        //       .post("admin/closedCall/sort/agent", data)
        //       .then((response) => {
        //         // ////console.log(response.data);
        //         this.closedCalls = response.data;
        //         this.getClosedAgentCount(data);
        //          this.selectedClosedCourse = "AllClosedCourses";
        //         this.selectedClosedSource = "AllClosedSources";
        //         this.selectedClosedBranch = "AllClosedBranches";
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getAllClosedCourses: function () {
        //     return new Promise((resolve, reject) => {
        //         CourseService.getAllCourses()
        //             .then((response) => {
        //                 this.allCourses = response.data;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllClosedAgents: function () {
        //     return new Promise((resolve, reject) => {
        //         AgentService.getAllAgents()
        //             .then((response) => {
        //                 this.allAgents = response.data;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllClosedSources: function () {
        //     return new Promise((resolve, reject) => {
        //         SourceService.getAllSources()
        //             .then((response) => {
        //                 this.allSources = response.data;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllClosedBranches: function () {
        //     return new Promise((resolve, reject) => {
        //         BranchService.getAllBranches()
        //             .then((response) => {
        //                 this.allBranches = response.data;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        getAllClosedCalls: function () {
            // ////console.log("getall");
            this.showClosed();
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminService.getAllClosedCalls()
                    .then((response) => {
                        this.closedCalls = response.data;
                        this.getClosedCount();
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getClosedCount: function () {
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            this.paginationCalls = true;
            this.searchPagination = false;
            this.allSortCalls = false;
            this.bookmarkPagination = false;
            this.closedCurrentPage = 1;
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedAgent = "Agent";
            this.selectedClosedDates = null;
            this.closedKeyword = "";
            this.loading = true;

            return new Promise((resolve, reject) => {
                AdminService.getClosedCallCount()
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        this.getAllClosedPageable(
                            this.closedCurrentPage,
                            this.closedPageCount
                        );

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllClosedPageable: function (closedCurrentPage, closedPageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllClosedCallPageable(
                        closedCurrentPage,
                        closedPageCount
                    )
                    .then((response) => {
                        resolve(response);
                        this.closedCalls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchClosedPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllClosedPageable(closedCurrentPage, closedPageCount);
                // //console.log("this.paginationCalls===", this.paginationCalls);
                // //console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.allSortCalls) {
                this.getAllSortClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
                // //console.log("this.paginationCalls===", this.paginationCalls);
                // //console.log("this.allSortCalls===", this.allSortCalls);
            }
            // else if (this.sortCoursePaginationCalls) {
            //   this.getAllClosedCoursePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // ////console.log(this.sortCoursePaginationCalls,"getAllClosedCoursePageable")
            // } else if (this.sortSourcePaginationCalls) {
            //   this.getAllClosedSourcePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // ////console.log(this.sortSourcePaginationCalls,"getAllClosedSourcePageable")
            // } else if (this.sortBranchPaginationCalls) {
            //   this.getAllClosedBranchPageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // ////console.log(this.sortBranchPaginationCalls,"getAllClosedBranchPageable")
            // } else if (this.sortAgentPaginationCalls) {
            //   this.getAllClosedAgentPageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // ////console.log(this.sortAgentPaginationCalls,"getAllClosedAgentPageable")
            // } else if (this.sortDatePaginationCalls) {
            //   this.getAllClosedDatePageable(
            //     closedCurrentPage,
            //     closedPageCount,
            //     this.data
            //   );
            //   // ////console.log(this.sortDatePaginationCalls,"getAllClosedDatePageable")
            // }
            else if (this.searchPagination) {
                this.getAllClosedCallSearchPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.closedKeyword
                );
                // ////console.log(this.searchPagination,"getAllClosedCallSearchPageable")
            }
        },
        searchClosedCall: function (closedKeyword) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                AdminService.searchClosedCall(closedKeyword.toLowerCase())
                    .then((response) => {
                        this.closedCalls = response.data;
                        this.getClosedCallSearchCount(closedKeyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        //=================================ADMIN ARCHIVED CALL========================================================

        getAllSortArchivedCount: function (
            selectedArchivedAgent,
            selectedArchivedCourse,
            selectedArchivedSource,
            selectedArchivedBranch,
            selectedArchivedDates
        ) {
            // //console.log("selectedArchivedAgent=== ", selectedArchivedAgent);
            // //console.log("selectedArchivedCourse=== ", selectedArchivedCourse);
            // //console.log("selectedArchivedSource=== ", selectedArchivedSource);
            // //console.log("selectedArchivedBranch=== ", selectedArchivedBranch);
            // //console.log("selectedArchivedDates=== ", selectedArchivedDates);

            if (selectedArchivedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.archivedDateRange.show();
                this.selectedArchivedDates = "dateBetween";
            } else {
                if (
                    selectedArchivedAgent == "Agent" ||
                    selectedArchivedAgent == "AllArchivedAgents"
                ) {
                    selectedArchivedAgent = {
                        agentId: "",
                    };
                }
                if (
                    selectedArchivedCourse == "Courses" ||
                    selectedArchivedCourse == "AllArchivedCourses"
                ) {
                    selectedArchivedCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedArchivedSource == "Sources" ||
                    selectedArchivedSource == "AllArchivedSources"
                ) {
                    selectedArchivedSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedArchivedBranch == "Branches" ||
                    selectedArchivedBranch == "AllArchivedBranches"
                ) {
                    selectedArchivedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedArchivedDates != null) {
                    var date = new Date();
                    if (selectedArchivedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedArchivedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedArchivedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedArchivedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedArchivedDates == "dateBetween"
                    ) {
                        this.$refs.archivedDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedArchivedDates == "dateBetween"
                    ) {
                        this.$refs.archivedDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedArchivedDates == null ||
                    selectedArchivedDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedArchivedAgent.agentId,
                    courseId: selectedArchivedCourse.courseId,
                    sourceId: selectedArchivedSource.sourceId,
                    branchId: selectedArchivedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.bookmarkPagination = false;
                this.archivedCurrentPage = 1;
                this.archivedKeyword = "";
                // //console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/archivedCall/all/sort/count", this.data)
                        .then((response) => {
                            // //console.log("getAllSortArchivedCount======", response.data);
                            this.archivedTotalCount = response.data;
                            this.getAllSortArchivedPageable(
                                this.archivedCurrentPage,
                                this.archivedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortArchivedPageable: function (
            archivedCurrentPage,
            archivedPageCount,
            data
        ) {
            // //console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/admin/archivedCall/all/sort/" +
                        archivedCurrentPage +
                        "/" +
                        archivedPageCount,
                        data
                    )
                    .then((response) => {
                        this.archivedCalls = response.data;
                        // //console.log("getAllSortArchivedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        closeModal: function () {
            this.admissionModal = false;
            this.getCount();
        },
        closeIcon : function(){
            this.admissionModal = false;
            this.courseType = null;
            this.$refs.modal.hide();
        },
        getArchivedCount: function () {
            this.paginationCalls = true;
            this.searchPagination = false;
            this.allSortCalls = false;
            this.bookmarkPagination = false;
            this.closedCurrentPage = 1;
            this.selectedArchivedCourse = "Courses";
            this.selectedArchivedSource = "Sources";
            this.selectedArchivedBranch = "Branches";
            this.selectedArchivedAgent = "Agent";
            this.selectedArchivedDates = null;
            this.ArchivedKeyword = "";
            this.loading = true;

            return new Promise((resolve, reject) => {
                AdminService.getArchivedCallCount()
                    .then((response) => {
                        this.archivedTotalCount = response.data;
                        //  //console.log("archivedTotalCount ======", response.data);
                        this.getAllArchivedPageable(
                            this.archivedCurrentPage,
                            this.archivedPageCount
                        );

                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllArchivedPageable: function (archivedCurrentPage, archivedPageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllArchivedCallPageable(
                        archivedCurrentPage,
                        archivedPageCount
                    )
                    .then((response) => {
                        resolve(response);
                        this.archivedCalls = response.data;
                        // //console.log("archivedCalls ======", response.data);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getArchivedCallSearchCount: function (archivedKeyword) {
            this.searchPagination = true;
            this.paginationCalls = false;
            this.allSortCalls = false;
            this.bookmarkPagination = false;
            this.archivedCurrentPage = 1;
            this.loading = true;

            return new Promise((resolve, reject) => {
                AdminService.getArchivedCallSearchCount(archivedKeyword.toLowerCase())
                    .then((response) => {
                        this.archivedTotalCount = response.data;
                        // //console.log("Archivedsearch pagecount", response.data);
                        this.getAllArchivedCallSearchPageable(
                            this.archivedCurrentPage,
                            this.archivedPageCount,
                            archivedKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchivedCallSearchPageable: function (
            archivedCurrentPage,
            archivedPageCount,
            archivedKeyword
        ) {
            ////console.log("Closedsearch page calling");
            return new Promise((resolve, reject) => {
                AdminService.getAllArchivedCallSearchPageable(
                        archivedCurrentPage,
                        archivedPageCount,
                        archivedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.archivedCalls = response.data;
                        // //console.log("ArchivedCalls", response.data);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchArchivedPage: function (archivedCurrentPage, archivedPageCount) {
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllArchivedPageable(archivedCurrentPage, archivedPageCount);
                // //console.log("this.paginationCalls===", this.paginationCalls);
                // //console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.allSortCalls) {
                this.getAllSortArchivedPageable(
                    archivedCurrentPage,
                    archivedPageCount,
                    this.data
                );
                // //console.log("this.paginationCalls===", this.paginationCalls);
                // //console.log("this.allSortCalls===", this.allSortCalls);
            } else if (this.searchPagination) {
                this.getAllArchivedCallSearchPageable(
                    archivedCurrentPage,
                    archivedPageCount,
                    this.archivedKeyword
                );
                // ////console.log(this.searchPagination,"getAllClosedCallSearchPageable")
            }
        },

        getCallsBetweenDates: function () {
            return new Promise((resolve, reject) => {
                this.loading = true;
                // ////console.log("Admin calling Sort By Date");

                // ////console.log("Selected Date Range", this.selectedDates);
                AdminService.getByDate(this.selectedDates)
                    .then((response) => {
                        // ////console.log(response.data, "Admin calls By Dates");
                        this.calls = response.data;
                        this.getDateCount();
                        this.selectedCourse = "Course";
                        this.selectedSource = "Source";
                        this.selectedBranch = "Branch";
                        this.selectedAgent = "Agent";
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getDateCount: function(selectedDates) {
        //   this.sortCoursePaginationCalls = false;
        //   this.sortSourcePaginationCalls = false;
        //   this.sortBranchPaginationCalls = false;
        //   this.sortAgentPaginationCalls = false;
        //   this.sortDatePaginationCalls = true;
        //   this.searchPagination = false;
        //   this.paginationCalls = false;
        //   this.selectedCourse = "Course";
        //   this.selectedSource = "Source";
        //   this.selectedBranch = "Branch";
        //   this.selectedAgent = "Agent";
        //   this.currentPage = 1;

        //   if (selectedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedDates = null;
        //     this.getDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getDateRange: function() {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminService.getDateCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getDateRangeReset();
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        // getAllDatePageable: function(currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllDatePageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.calls = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },

        // getDateClosedCount: function(selectedClosedDates) {
        //   this.sortCoursePaginationCalls = false;
        //   this.sortSourcePaginationCalls = false;
        //   this.sortBranchPaginationCalls = false;
        //   this.sortDatePaginationCalls = true;
        //   this.searchPagination = false;
        //   this.paginationCalls = false;
        //   this.selectedClosedCourse = "Courses";
        //   this.selectedClosedSource = "Sources";
        //   this.selectedClosedBranch = "Branches";
        //   this.selectedClosedAgent = "Agent";
        //   this.closedCurrentPage = 1;

        //   if (selectedClosedDates == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedClosedDates = null;
        //     this.getClosedDateRangeReset();
        //   } else {
        //     var date = new Date();
        //     if (selectedClosedDates == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDates == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedClosedDates == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedClosedDates == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminService.getDateClosedCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedDateRange: function() {
        //   this.$v.from.$touch();
        //   this.$v.to.$touch();
        //   if (this.$v.from.$invalid && this.$v.to.$invalid) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "select from date and to date",
        //     });
        //   } else {
        //     var fromDateObject = new Date(this.from);
        //     var toDateObject = new Date(this.to);
        //     var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
        //     var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
        //     var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
        //     var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
        //     this.data = {
        //       from: from,
        //       to: to,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminService.getDateClosedCount(this.data)
        //         .then((response) => {
        //           this.closedTotalCount = response.data;
        //           this.getClosedDateRangeReset();
        //           this.getAllClosedDatePageable(
        //             this.closedCurrentPage,
        //             this.closedPageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getClosedDateRangeReset: function () {
            this.$refs.dateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        // getAllClosedDatePageable: function(
        //   closedCurrentPage,
        //   closedPageCount,
        //   data
        // ) {
        //   // //console.log("closedpageable calling")
        //   return new Promise((resolve, reject) => {
        //     AdminService.getAllClosedDatePageable(
        //       closedCurrentPage,
        //       closedPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.closedCalls = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getAdminTeam() {
        //     return new Promise((resolve, reject) => {
        //         // const teamId = "d8eabeea-ff60-4ed1-9b68-3e23c6fff723";
        //       AdminAdmissionsService.getAdmissionTeam(localStorage.getItem('tenant-uuid'))
        //             .then((response) => {
        //                 resolve(response);
        //                 //console.log("============getAdminTeam=======",response.data)
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        getAdminTeam: function () {
            //console.log('indb======', this.update)

            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        //console.log("============getAdminTeam=======", response.data.organization)
                        this.organization = response.data.organization;
                        this.admissionModal = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        callClosed: function () {
            this.disabledButton = true;
            var closedDatas = {
                callId: this.call.callId,
                name: this.call.name,
                contact: this.call.contact,
                age: this.call.age,
                qualification: this.call.qualification,
                course: this.call.course,
                source: this.call.source,
                mode: this.call.mode,
                branch: this.call.branch,
                followupDate: this.enquiryAs.followupDate,
                remark: this.enquiryAs.remark,
            };
            //console.log('cldate========',closedDatas)
            //  this.$v.closed.$touch();
            // if (this.$v.closed.$invalid,this.$v.closed.followupDate.$invalid,this.$v.closed.remark.$invalid) {
            //   this.submitStatus = "FAIL";
            //   this.$swal.fire({
            //     icon: "error",
            //     title: "Oops...",
            //     text: "select an enquiry or admission",
            //   });
            // }
            if (this.closed == "enquiry") {
                if (this.$v.enquiryAs.$invalid) {
                    this.submitStatus = "FAIL";
                    this.$swal("Select for next Enquiry followupdate");
                    this.disabledButton = false;
                } else {
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/enquiry/insert/" + this.agentId, closedDatas)
                            .then((response) => {
                                this.$swal(response.data);
                                this.closed = "";
                                // this.followupDate = "";
                                // this.remark = "";
                                // this.resetFollowup();
                                this.$refs.modal.hide();
                                this.getCount();
                                this.disabledButton = false;

                                resolve(response);
                            })
                            .catch((err) => {
                                this.disabledButton = false;
                                reject(err);
                            });
                    });
                }
            } else if (this.closed == "admission") {
                this.$v.courseType.$touch();
                if (!this.$v.courseType.$invalid) {       
                    let data = {
                        callId: this.update.callId,
                        name: this.update.name,
                        contact: this.update.contact,
                        alternativeContact: this.update.alternativeContact,
                        age: this.update.age,
                        qualification: this.update.qualification,
                        course: this.update.course,
                        source: this.update.source,
                        mode: this.update.mode,
                        branch: this.update.branch,
                        courseType: this.courseType,
                        // followup: response.data.followup
                    };
                    return new Promise((resolve, reject) => {
                        axios
                            .post("/admission/insert/" + this.agentId, data)
                            .then((response) => {
                                this.$swal(response.data);
                                this.closed = "";
                                this.$refs.modal.hide();
                                this.getCount();
                                this.disabledButton = false;
                                this.modalShow = true;
                                resolve(response);
                            })
                            .catch((err) => {
                                this.disabledButton = false;
                                reject(err);
                            });
                    });
                }
                this.disabledButton = false;
            } else if (this.closed == "notintrested") {
                return new Promise((resolve, reject) => {
                    axios
                        .post("/notintrested/insert/" + this.agentId, this.update)
                        .then((response) => {
                            this.$swal(response.data);
                            this.closed = "";
                            this.$refs.modal.hide();
                            this.getCount();
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            } else {
                this.$swal("Closed option invalid");
                this.disabledButton = false;
            }
        },
        resetFollowupUpdate: function () {
            this.closed = null;
            this.$nextTick(() => {
                this.enquiryAs = {
                    remark: "",
                    followupDate: "",
                };
                // this.$v.closed.$reset();
            });
        },
        //  archive: function (callId) {
        //       return new Promise((resolve, reject) => {
        //         CallService.archive(callId)
        //           .then((response) => {
        //             this.$swal(response.data);
        //             this.getCount();
        //             resolve(response);
        //           })
        //           .catch((err) => {
        //             reject(err);
        //           });
        //       });
        //     },
        archive: function (callId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to archieve this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, archive it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            CallService.archive(callId).then((response) => {
                                this.$swal("Archived!", response.data, "success");
                                this.getCount();
                                this.getArchivedCount();
                                resolve(response);
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closedArchive: function (callId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to archive this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, archive it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            CallService.closedArchive(callId).then((response) => {
                                this.$swal("Archived!", response.data, "success");
                                this.getClosedCount();
                                this.getArchivedCount();
                                resolve(response);
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unArchive: function (callId) {
            this.archivedKeyword = "";
            return new Promise((resolve, reject) => {
                CallService.unArchive(callId)
                    .then((response) => {
                        this.$swal(response.data);
                        this.getArchivedCount();
                        this.getCount();
                        this.getClosedCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //=================================ADMIN CLOSED CALL END========================================================
        refresh: function () {
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedMode = "mode";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.bookmark = false;
            // this.getCount();
            this.getAllSortCount("Agent", "Course", "mode", "Source", "Branch", null);
        },
        closedRefresh: function () {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedMode = "mode";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedAgent = "Agent";
            this.selectedClosedDates = null;
            this.closedKeyword = "";
            // this.getClosedCount();
            this.getAllSortClosedCount("Agent", "Courses", "mode", "Sources", "Branches", null);
        },
        archivedRefresh: function () {
            this.selectedArchivedCourse = "Courses";
            this.selectedArchivedSource = "Sources";
            this.selectedArchivedBranch = "Branches";
            this.selectedArchivedAgent = "Agent";
            this.selectedArchivedDates = null;
            this.archivedKeyword = "";
            // this.getArchivedCount();
            this.getAllSortArchivedCount("Agent", "Courses", "Sources", "Branches", null);
        },
        // showCalls() {
        //     this.isShowCall = true;
        //     this.getCount();
        //     this.keyword = "";
        //     this.isShowClosed = false;
        //     this.isShowArchived = false;
        //     (this.callsActive = true),
        //     (this.closedCallsActive = false),
        //     (this.archivedCallsActive = false),
        //     (this.selectedCourse = "Course");
        //     this.selectedSource = "Source";
        //     this.selectedMode = "mode";
        //     this.selectedBranch = "Branch";
        //     this.selectedAgent = "Agent";
        //     this.selectedDates = null;
        // },
        // showClosed() {
        //     this.isShowCall = false;
        //     this.getClosedCount();
        //     this.closedKeyword = "";
        //     this.isShowClosed = true;
        //     this.isShowArchived = false;
        //     (this.callsActive = false),
        //     (this.closedCallsActive = true),
        //     (this.archivedCallsActive = false),
        //     (this.selectedClosedCourse = "Courses"),
        //     (this.selectedClosedSource = "Sources");
        //     this.selectedClosedMode = "mode";
        //     this.selectedClosedBranch = "Branches";
        //     this.selectedClosedAgent = "Agent";
        //     this.selectedClosedDates = null;
        // },
        // showArchived() {
        //     this.isShowCall = false;
        //     this.getArchivedCount();
        //     this.archivedKeyword = "";
        //     this.isShowClosed = false;
        //     this.isShowArchived = true;
        //     (this.callsActive = false),
        //     (this.closedCallsActive = false),
        //     (this.archivedCallsActive = true),
        //     (this.selectedArchivedCourse = "Courses");
        //     this.selectedArchivedSource = "Sources";
        //     this.selectedArchivedBranch = "Branches";
        //     this.selectedArchivedAgent = "Agent";
        //     this.selectedArchivedDates = null;
        // },
    },
};
</script>

<style scoped>
.contact:hover {
    text-decoration: underline;
    cursor: pointer;
}

::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 125px;
}
</style>
