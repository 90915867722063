<template>
<div class="mt-5">
    <div>
        <!-- for loader -->
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
        <!-- for loader end -->
        <div v-else></div>
    </div>

    <b-container>
        <div>
            <b-container>
                <b-row align-v="center">
                    <!--add branch button start-->
                    <b-col col lg="1" md="1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="resetForm()" v-b-modal.branchregister>Branch</b-button>
                    </b-col>
                    <!--add branch button end-->
                    <!--search name input start-->
                    <b-col col sm="12" md="3" lg="3">
                        <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getSearchCount(keyword)" />
                    </b-col>
                    <!--search name input end-->
                    <!--search button start-->
                    <b-col col lg="1" md="1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <!--search name input end-->
                    <!--pageanation name start -->
                    <b-col sm="12" md="3" lg="4" class="my-1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <!--pageanation name end-->
                    <!--filter count start-->
                    <b-col sm="6" md="3" lg="2" class>
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm"></b-form-select>
                    </b-col>
                    <!--filter count end-->

                    <b-col col lg="1" md="1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh
                        </span>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-container>
            <!--popup start for Branch Register-->
            <b-modal id="branchregister" title="Branch" hide-footer ref="modal1">
                <div class="form-row">
                    <div class="form-group">
                        <label>Name:</label>
                        <b-form-input type="text" class="form-control" v-model="$v.branch.name.$model" :class="{
                  'is-invalid': $v.branch.name.$error,
                  'is-valid': !$v.branch.name.$invalid,
                }" placeholder="Enter your Name"></b-form-input>

                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.name.required">Name is required</span>
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <label>Code:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.branch.code.$model" :class="{
                  'is-invalid': $v.branch.code.$error,
                  'is-valid': !$v.branch.code.$invalid,
                }" placeholder="Enter your code"></b-form-input>

                        <div class="valid-feedback">Your code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.code.required">code is required</span>
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <label>Course Discount Price:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.branch.discountPrice.$model" placeholder="Enter Course discount price" :class="{
                            'is-invalid': $v.branch.discountPrice.$error,
                            'is-valid': !$v.branch.discountPrice.$invalid,
                            }"></b-form-input>

                        <div class="valid-feedback">Your Discount Price is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.discountPrice.required">Discount Price is required</span>
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <label>MapLink:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.branch.mapLink.$model" :class="{'is-invalid': $v.branch.mapLink.$error,'is-valid': !$v.branch.mapLink.$invalid,}" placeholder="Enter the map link"></b-form-input>
                        <div class="valid-feedback">Your mapLink is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.mapLink.required">mapLink is required</span>
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <strong>Address:</strong><br />
                        <label>Street:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.line1.$model" :class="{
                  'is-invalid': $v.branch.line1.$error,
                  'is-valid': !$v.branch.line1.$invalid,
                }" placeholder="Enter your Street"></b-form-input>
                        <div class="valid-feedback">Your Street is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.line1.required">Street is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>Area:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.line2.$model" :class="{
                  'is-invalid': $v.branch.line2.$error,
                  'is-valid': !$v.branch.line2.$invalid,
                }" placeholder="Enter your Area"></b-form-input>
                        <div class="valid-feedback">Your Area is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.line2.required">Area is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>City:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.city.$model" :class="{
                  'is-invalid': $v.branch.city.$error,
                  'is-valid': !$v.branch.city.$invalid,
                }" placeholder="Enter your City"></b-form-input>
                        <div class="valid-feedback">Your city is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.city.required">City is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>Pincode:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.pincode.$model" :class="{
                  'is-invalid': $v.branch.pincode.$error,
                  'is-valid': !$v.branch.pincode.$invalid,
                }" placeholder="Enter your State"></b-form-input>
                        <div class="valid-feedback">Your pincode is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.pincode.required">Pincode is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>State:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.state.$model" :class="{
                  'is-invalid': $v.branch.state.$error,
                  'is-valid': !$v.branch.state.$invalid,
                }" placeholder="Enter your State"></b-form-input>
                        <div class="valid-feedback">Your State is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.state.required">State is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>Country:</label>
                        <b-form-input cols="35" rows="4" class="form-control" v-model.trim="$v.branch.country.$model" :class="{
                  'is-invalid': $v.branch.country.$error,
                  'is-valid': !$v.branch.country.$invalid,
                }" placeholder="Enter your Country"></b-form-input>
                        <div class="valid-feedback">Your Country is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.country.required">Country is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <label>Contact Number</label>
                        <b-form-input type="number" class="form-control" v-model.trim="$v.branch.contact.$model" :class="{
                  'is-invalid': $v.branch.contact.$error,
                  'is-valid': !$v.branch.contact.$invalid,
                }" placeholder="Enter your Contact Number"></b-form-input>
                        <div class="valid-feedback">Your number is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.contact.required">Contact number is required and it must be 10 numbers</span>
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <label>Mail Id:</label>
                        <b-form-input type="email" class="form-control" v-model.trim="$v.branch.email.$model" :class="{
                  'is-invalid': $v.branch.email.$error,
                  'is-valid': !$v.branch.email.$invalid,
                }" placeholder="Enter your email"></b-form-input>

                        <div class="valid-feedback">Your email is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.branch.email.required">Email is required</span>
                            <span v-if="!$v.branch.email.isUnique">Enter your proper email</span>
                        </div>
                    </div>
                    <br />

                    <div class="text-center">
                        <b-button variant="outline-success" @click="disabledButton = true,putBranch()" type="submit" :disabled="disabledButton">Submit</b-button>
                        <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                    </div>
                </div>
                <!-- <b-table responsive bordered striped hover :items="enquiryFollowUp" :fields="followUpDateAndRemark">
                    <template v-slot:cell(enquiryId)="data">
                        <span class="title"> {{data.item.enquiryId}}</span>
                    </template>
                    <template v-slot:cell(enquiryFollowUp)="data">
                        {{data.item.followUpDate}}
                    </template>
                </b-table> -->
            </b-modal>
        </b-container>
        <!--popup end for Branch Register-->

        <!-- Branch details table start-->
        <b-container>
            <b-card class="card mt-1">
                <p>Branch</p>
                <hr />
                <b-table hover responsive :items="branches" :fields="fields" :current-page="currentPage" :per-page="branches.pageCount">
                    <template v-slot:cell(name)="b">{{ b.item.name }}</template>
                    <template v-slot:cell(code)="b">{{ b.item.code }}</template>
                    <template v-slot:cell(contact)="b">{{ b.item.contact }}</template>
                    <template v-slot:cell(discountPrice)="b">{{ b.item.discountPrice }}</template>
                    <template v-slot:cell(action)="b">
                        <div>
                            <b-button size="sm" class="mx-1" v-b-tooltip.hover.leftbottom="{ variant: 'primary' }" title="View Branch Details" variant="primary" @click="getBranch(b.item)" v-b-modal.viewBranch>
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <!-- <b-button class="fa fa-trash px-3" v-b-tooltip.hover.leftbottom="{ variant: 'danger' }" title="Delete Branch Details" variant="danger" @click="deleteBranch(b.item.branchId)"></b-button> -->
                            <b-button size="sm" class="mx-1" v-b-tooltip.hover.leftbottom="{ variant: 'info' }" title="Update Branch Details" variant="info" @click="getBranch(b.item)" v-b-modal.editBranch>
                                <font-awesome-icon icon="edit" />
                            </b-button>
                            <b-button size="sm" class="mx-1" v-if="b.item.archive == false" v-b-tooltip.hover.leftbottom="{ variant: 'success' }" title="Archive Branch Details" variant="success" @click="archive(b.item.branchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button>
                            <b-button v-if="b.item.archive == true" v-b-tooltip.hover.leftbottom="{ variant: 'warning' }" title="UnArchive Branch Details" variant="warning" @click="unarchive(b.item.branchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-container>
        <!-- Branch details table end-->

        <!--popup start for View Branch details-->
        <b-container>
            <div>
                <b-modal id="viewBranch" title="View Branch" hide-footer>
                    <div class="form-row">
                        <div class="form-group">
                            <label>Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="branch.name" disabled></b-form-input>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Code:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="branch.code" disabled></b-form-input>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Course Discount Price:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="branch.discountPrice" disabled></b-form-input>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>MapLink:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="branch.mapLink" disabled></b-form-input>
                        </div>
                        <br />

                        <div class="form-group">
                            <strong>Address:</strong><br />
                            <!-- <b-table responsive bordered striped hover :items="branch.branchAddress" :fields="branchAddressfields">
                                <template v-slot:cell(branchId)="b">
                                    <span class="title"> {{b.item.branchId}}</span>
                                </template>
                            </b-table> -->
                        </div>

                        <div class="form-group">
                            <label>Street</label>
                            <b-form-input type="text" class="form-control" v-model="branch.line1" disabled></b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Area</label>
                            <b-form-input type="text" class="form-control" v-model="branch.line2" disabled></b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Pincode</label>
                            <b-form-input type="text" class="form-control" v-model="branch.pincode" disabled></b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>State</label>
                            <b-form-input type="text" class="form-control" v-model="branch.state" disabled></b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Country</label>
                            <b-form-input type="text" class="form-control" v-model="branch.country" disabled></b-form-input>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Contact Number</label>
                            <b-form-input type="number" class="form-control" v-model="branch.contact" disabled></b-form-input>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Mail Id:</label>
                            <b-form-input type="email" class="form-control" v-model.trim="branch.email" disabled></b-form-input>
                        </div>
                        <br />
                    </div>
                </b-modal>
            </div>
        </b-container>
        <b-container>
            <!--popup end for View Branch details-->

            <!--popup start for Branch details Update-->
            <div>
                <b-modal id="editBranch" title="Edit Branch" hide-footer ref="modal">
                    <div class="form-row">
                        <div class="form-group">
                            <label>Name:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.branch.name.$model" :class="{
                    'is-invalid': $v.branch.name.$error,
                    'is-valid': !$v.branch.name.$invalid,
                  }"></b-form-input>

                            <div class="valid-feedback">Your Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.name.required">Name is required</span>
                            </div>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Code:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.branch.code.$model" :class="{
                    'is-invalid': $v.branch.code.$error,
                    'is-valid': !$v.branch.code.$invalid,
                  }"></b-form-input>

                            <div class="valid-feedback">Your code is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.code.required">code is required</span>
                            </div>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Course Discount Price:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.branch.discountPrice.$model" :class="{
                    'is-invalid': $v.branch.discountPrice.$error,
                    'is-valid': !$v.branch.discountPrice.$invalid,
                  }"></b-form-input>

                            <div class="valid-feedback">Your Discount Price is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.discountPrice.required">Discount Price is required</span>
                            </div>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>MapLink:</label>
                            <b-form-input type="text" class="form-control" v-model.trim="$v.branch.mapLink.$model" :class="{'is-invalid': $v.branch.mapLink.$error,'is-valid': !$v.branch.mapLink.$invalid,}"></b-form-input>
                            <div class="valid-feedback">Your mapLink is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.mapLink.required">mapLink is required</span>
                            </div>
                        </div>
                        <br />

                        <div class="form-group">
                            <strong>Address:</strong><br />
                            <label>Street:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.line1" :class="{
                    'is-invalid': $v.branch.line1.$error,
                    'is-valid': !$v.branch.line1.$invalid,
                  }" placeholder="Enter your Street"></b-form-input>
                            <div class="valid-feedback">Your Street is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.line1.required">Street is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Area:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.line2" :class="{
                    'is-invalid': $v.branch.line2.$error,
                    'is-valid': !$v.branch.line2.$invalid,
                  }" placeholder="Enter your Area"></b-form-input>
                            <div class="valid-feedback">Your Area is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.line2.required">Area is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>City:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.city" :class="{
                    'is-invalid': $v.branch.city.$error,
                    'is-valid': !$v.branch.city.$invalid,
                  }" placeholder="Enter your City"></b-form-input>
                            <div class="valid-feedback">Your city is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.city.required">City is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Pincode:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.pincode" :class="{
                    'is-invalid': $v.branch.pincode.$error,
                    'is-valid': !$v.branch.pincode.$invalid,
                  }" placeholder="Enter your City"></b-form-input>
                            <div class="valid-feedback">Your pincode is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.pincode.required">Pincode is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>State:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.state" :class="{
                    'is-invalid': $v.branch.state.$error,
                    'is-valid': !$v.branch.state.$invalid,
                  }" placeholder="Enter your State"></b-form-input>
                            <div class="valid-feedback">Your State is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.state.required">State is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Country:</label>
                            <b-form-input cols="35" rows="4" class="form-control" v-model="branch.country" :class="{
                    'is-invalid': $v.branch.country.$error,
                    'is-valid': !$v.branch.country.$invalid,
                  }" placeholder="Enter your Country"></b-form-input>
                            <div class="valid-feedback">Your Country is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.country.required">Country is required</span>
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Contact Number</label>
                            <b-form-input type="number" class="form-control" v-model="$v.branch.contact.$model" :class="{
                    'is-invalid': $v.branch.contact.$error,
                    'is-valid': !$v.branch.contact.$invalid,
                  }"></b-form-input>
                            <div class="valid-feedback">Your number is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.contact.required">Contact number is required and it must be 10 numbers</span>
                            </div>
                        </div>
                        <br />

                        <div class="form-group">
                            <label>Mail Id:</label>
                            <b-form-input type="email" class="form-control" v-model.trim="$v.branch.email.$model" :class="{
                    'is-invalid': $v.branch.email.$error,
                    'is-valid': !$v.branch.email.$invalid,
                  }"></b-form-input>
                            <div class="valid-feedback">Your email is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.branch.email.required">Email is required</span>
                                <span v-if="!$v.branch.email.isUnique">Enter your proper email</span>
                            </div>
                        </div>
                        <br />

                        <div class="text-center">
                            <b-button variant="outline-success" @click="disabledButton = true,updateBranch()" type="submit" :disabled="disabledButton">Update</b-button>
                        </div>
                    </div>
                </b-modal>
            </div>
        </b-container>
        <!--popup end for Branch details update-->
    </b-container>
</div>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    email,
} from "vuelidate/lib/validators";
import BranchService from "../service/BranchService";
import Account from '../service/Account.js';
// import axios from 'axios';

export default {
    name: "Branch",
    components: {},
    data() {
        return {
            disabledButton: false,
            paginationBranches: false,
            searchPaginationBranches: false,
            //cronItems: [],
            branchModal: false,
            fields: [{
                    key: "name",
                    label: "Name",
                },
                {
                    key: "code",
                    label: "Code",
                    class: "text-center",
                },
                {
                    key: "mapLink",
                    label: "Map Link",
                },
                {
                    key: "contact",
                    label: "Contact",
                    class: "text-center",
                },
                {
                    key: "discountPrice",
                    label: "Discount Price",
                    class: "text-center",
                },
                {
                    key: "action",
                    label: "Action",
                    class: "text-center",
                },
            ],
            loading: false,
            name: "",
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            totalCount: 0,
            pageOptions: [
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show a lot",
                },
            ],
            branches: [],
            keyword: "",
            branchAddress: [],
            branchAddressfields: [{
                    key: "line1",
                    label: "Street",
                },
                {
                    key: "line2",
                    label: "Area",
                },
                {
                    key: "city",
                    label: "City",
                },
                {
                    key: "state",
                    label: "State",
                },
                {
                    key: "country",
                    label: "Country",
                },
            ],
            branch: {
                name: "",
                code: "",
                discountPrice:"",
                mapLink: "",
                line1: "",
                line2: "",
                city: "",
                state: "",
                country: "",
                contact: "",
                email: "",
            },
            //branches: "",
            submitstatus: "",
        };
    },
    validations: {
        branch: {
            name: {
                required,
                minLength: minLength(3),
            },
            code: {
                required,
                minLength: minLength(1),
            },
            discountPrice:{
                required,
            },
            mapLink: {
                required,
                minLength: minLength(1),
            },
            line1: {
                required,
                minLength: minLength(5),
            },
            line2: {
                required,
                minLength: minLength(5),
            },
            city: {
                required,
                minLength: minLength(5),
            },
            state: {
                required,
                minLength: minLength(5),
            },
            country: {
                required,
                minLength: minLength(5),
            },
            pincode: {
                required
            },
            contact: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            email: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            },
        },
    },

    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    // self.getAllBranches();
                    // self.getAllPageable();
                    self.getCount();
                    this.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    watch: {
        keyword() {
            if (this.keyword == "") {
                this.getCount();
            }
        },
    },
    methods: {
        // openModal: function () {
        //     console.log("Call openModal")
        //     this.branch = {
        //         name: null,
        //         code: null,
        //         line1: "",
        //         line2: "",
        //         city: "",
        //         state: "",
        //         country: "",
        //         number: null,
        //         email: null
        //     };
        //     this.$nextTick(() => {
        //         this.$v.$reset();
        //     });
        //     this.$refs['modal1'].show()

        // },
        putBranch: function () {
            this.$v.$touch();
            // if (this.$v.$invalid) {
            //   this.submitstatus = "FAIL";
            // } else {
            if (!this.$v.$invalid) {
                this.disabledButton = true;
                console.log("Number ====", this.branch.contact);
                return new Promise((resolve, reject) => {
                    BranchService.getNumber(this.branch.contact)
                        .then((response) => {
                            if (response.data == true) {
                                this.submitstatus = "SUCCESS";
                                return new Promise((resolve, reject) => {
                                    BranchService.putBranch(this.branch)
                                        .then((response) => {
                                            this.$swal({
                                                title: "Good job!",
                                                text: "Branch Added successfully!!!!!",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.modal1.hide();
                                            // this.getAllBranches();
                                            // this.getAllPageable();
                                            this.getCount();
                                            this.disabledButton = false;
                                            resolve(response);

                                            this.$nextTick(() => {
                                                this.$v.$reset();
                                            });
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Mobile Number is already exist!",
                                });

                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
                // this.submitstatus = "SUCCESS";
                // return new Promise((resolve, reject) => {
                //     BranchService.putBranch(this.branch)
                //         .then(response => {
                //             this.$swal({
                //                 title: "Good job!",
                //                 text: "Branch Added successfully!!!!!",
                //                 icon: "success",
                //                 button: "ok",
                //             });
                //             this.$refs.modal1.hide();
                //             this.getAllBranches();
                //             this.getAllPageable();
                //             this.getCount();
                //             this.getNumber();
                //             resolve(response);

                //             this.$nextTick(() => {
                //                 this.$v.$reset();
                //             });
                //             resolve(response);
                //         })
                //         .catch(err => {
                //             reject(err);
                //         });
                // });
            } else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },
        // getAllBranches: function () {
        //     return new Promise((resolve, reject) => {
        //         this.loading = true;
        //         BranchService.getAllAdminBranches()
        //             .then((response) => {
        //                 this.branches = response.data;
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },

        deleteBranch: function (branchId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085D6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            BranchService.deleteBranch(branchId).then((response) => {
                                //  this.$swal('Deleted successfully!!!!!');
                                this.$swal("Deleted!", "Branch has been deleted.", "success");
                                this.getAllBranches();
                                this.getCount();
                                this.getAllPageable();
                                resolve(response);
                            });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBranch: function (branch) {
            console.log("Branch : ", branch);
            // this.branchAddress = {
            //     line1: branch.branchAddress[0].line1,
            //     line2: branch.branchAddress[0].line2,
            //     city: branch.branchAddress[0].city,
            //     state: branch.branchAddress[0].state,
            //     country: branch.branchAddress[0].country,
            // };

            return new Promise((resolve, reject) => {
                BranchService.getBranch(branch.branchId)
                    .then((response) => {
                        console.log(response.data);
                        this.branch = {
                            branchId: branch.branchId,
                            name: branch.name,
                            code: branch.code,
                            discountPrice: branch.discountPrice,
                            mapLink: branch.mapLink,
                            line1: response.data[0].line1,
                            line2: response.data[0].line2,
                            city: response.data[0].city,
                            state: response.data[0].state,
                            country: response.data[0].country,
                            pincode: response.data[0].pincode,
                            contact: branch.contact,
                            email: branch.email,
                        };
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        updateBranch: function () {
            this.$v.$touch();
            // if (this.$v.$invalid) {
            //   this.submitstatus = "FAIL";
            // } else {
            if (!this.$v.$invalid) {
                // console.log("Number ====", this.branch.contact);
                // this.disabledButton = false;
                // return new Promise((resolve, reject) => {
                    // BranchService.getNumber(this.branch.contact)
                    //     .then((response) => {
                    //         resolve(response);
                            // if (response.data == true) {
                                this.submitstatus = "SUCCESS";
                                this.disabledButton = true;
                                return new Promise((resolve, reject) => {
                                    BranchService.updateBranch(this.branch)
                                        .then((response) => {
                                            if(response.status != 206) {
                                                console.log("response",response);
                                                this.$swal("Branch details updated successfully!!!!!");
                                                // this.getAllBranches();
                                                this.getCount();
                                                this.disabledButton = false;
                                                this.$refs.modal.hide();
                                            } else {
                                                this.$swal.fire({
                                                    icon: "error",
                                                    title: "Oops...",
                                                    text: "Mobile Number is already exist!",
                                                });
                                                this.disabledButton = false;
                                            }
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            alert("catch",err.staus);
                                            this.disabledButton = false;
                                            reject(err);
                                        });
                                });
                            // } else
                            //     this.$swal.fire({
                            //         icon: "error",
                            //         title: "Oops...",
                            //         text: "Mobile Number is already exist!",
                            //     });

                        // })
                        // .catch((err) => {
                        //     reject(err);
                        // });
            //     });
            }
            //  else {
            //     this.submitstatus = "FAIL";
            //     this.disabledButton = false;
            // }
            this.disabledButton = false;
        },
        archive: function (branchId) {
            return new Promise((resolve, reject) => {
                BranchService.archive(branchId)
                    .then((response) => {
                        this.$swal("Branch details archived!!!!!");
                        this.getCount();
                        // console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unarchive: function (branchId) {
            return new Promise((resolve, reject) => {
                BranchService.unarchive(branchId)
                    .then((response) => {
                        this.$swal("Branch details unarchived!!!!!");
                        this.getCount();
                        // console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        resetForm() {
            this.branch = {
                name: null,
                code: null,
                mapLink: "",
                discountPrice: "",
                line1: "",
                line2: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                contact: null,
                email: null,
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        getCount: function () {
            this.loading = true;
            this.paginationBranches = true;
            this.searchPaginationBranches = false;
            this.currentPage = 1;
            this.keyword = "";
            return new Promise((resolve, reject) => {
                BranchService.getCount()
                    .then((response) => {
                        // console.log("Count", response.data);
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function () {
            // console.log("currentPage", this.currentPage);
            // console.log("pageCount", this.pageCount);
            return new Promise((resolve, reject) => {
                BranchService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        this.branches = response.data;
                        // console.log("Response", response.data);
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            //   console.log("switchPage calling");
            if (this.paginationBranches) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPaginationBranches) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            }
            // this.loading = true
            // this.getAllPageable(pageCount);
        },
        // searchBranch: function (keyword) {
        //     return new Promise((resolve, reject) => {
        //         BranchService.searchBranch(keyword.toLowerCase())
        //         // this.getSearchCount( )
        //             .then(response => {
        //                 this.branches = response.data;
        //                 this.getAllSearchPageable(
        //                 this.currentPage,
        //                 this.pageCount,
        //                 keyword.toLowerCase()
        //     );
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        getSearchCount: function (keyword) {
            this.paginationBranches = false;
            this.searchPaginationBranches = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                BranchService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                BranchService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        //   this.agentId,
                        keyword
                    )
                    .then((response) => {
                        this.branches = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getNumber: function (contact) {
            return new Promise((resolve, reject) => {
                BranchService.getNumber(contact)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        refresh: function () {
            this.getCount();
            this.keyword = "";
        },
    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
