<template>
<div class="body">
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div class="printcontainer" style="width: 100vw;height: 100vh;z-index: 99999999;background-color: white;position: absolute;margin-top: -140px;color:black" v-if="printPage">
        <div>
            <div class="row delivery ">
                <div class="col mt-5">
                    <img height="60" :src="logo" style="margin-left: 0;" />
                </div>
                <div class="col-4 mt-5 order-nu" style="text-align:right">
                    <span class="">order number#</span> <span class=""> {{ booked.orderNumber }} </span>
                </div>
                <div class="col-12 mt-5">
                    <h1 class="a"> {{ booked.type.name }} full pack</h1>
                </div>
            </div>
            <div class="row delivery">

                <div class="col-1 mt-5 ">
                </div>
            </div>
        </div>
        <div class="px-3">
            <div class="row user_to">
                <div class="col mt-2" style="font-size: 1rem;text-transform:uppercase">
                    <div style="font-weight: bold;">
                        <span> To :</span>
                    </div>
                    <div>
                        <span class="user_to">{{ booked.name }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ booked.doorNo }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ booked.landmark }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ booked.district }},</span>
                    </div>
                    <div>
                        <span class="user_to">{{ booked.state }} - {{ booked.pincode }}.</span>
                    </div>
                    <div>
                       PHONE : <span class="user_to"> {{ booked.contact }}<span v-if="booked.contact != booked.whatsapp">, {{ booked.whatsapp }}</span></span>
                    </div>
                </div>
                
                <div class="col-12 mt-2" style="font-size: 1rem;text-transform:uppercase">
                    <div style="font-weight: bold;">
                        <span> From :</span>
                    </div>
                    <div>
                        {{organization}},
                    </div>
                    <div>
                        <span class="user_to">48-1-26, Annai Therasa Street,</span>
                    </div>
                    <div>
                        <span class="user_to">Bethaniapuram, Madurai,</span>
                    </div>
                    <div>
                        <span class="user_to">Tamil Nadu 625016.</span>
                    </div>
                    <div>
                       PHONE : <span class="user_to"> 7550008885</span>
                    </div>
                </div>

            </div>
        </div><br /><br />
        <div style="color:grey;position: absolute;bottom:10px;left: 25%;">

            Thank you for your business
        </div>
    </div>
    <b-container class="table-page">
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Booked" active @click="tabChange('booked')">
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="search" v-on:keyup.enter="getBookedSearchCount()" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBookedSearchCount()">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount,'booked')"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount,'booked')" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="getAllBookedCount()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allBooked" :fields="bookedFields" :per-page="allBooked.pageCount" :current-page="currentPage" ref="table">

                            <template v-slot:cell(date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(book)="data">
                                {{ data.item.type.name }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div class="text-primary contact">
                                    {{ data.item.contact }}
                                </div>
                                <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize" style="min-width:150px">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item,booked=data.item">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <!-- @click="print(data.item,'booked')" -->
                                    <!-- <b-button @click="print(data.item)" v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Print">
                                        <font-awesome-icon icon="print" />
                                    </b-button> -->
                                    <b-button v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Dispatched" @click="booked=data.item,booked.trackingId='',booked.courierOffice=null,partial.pendingBooks=''" v-b-modal.dispatchedBook>
                                        <font-awesome-icon icon="truck" />
                                    </b-button>
                                    <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->

                                </div>
                            </template>
                        </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="COD" @click="tabChange('COD')">
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="search" v-on:keyup.enter="getBookedSearchCount()" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBookedSearchCount()">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount,'booked')"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount,'booked')" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="getAllBookedCount()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allBooked" :fields="bookedFields" :per-page="allBooked.pageCount" :current-page="currentPage" ref="table">

                            <template v-slot:cell(date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(book)="data">
                                {{ data.item.type.name }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div class="text-primary contact">
                                    {{ data.item.contact }}
                                </div>
                                <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize" style="min-width:150px">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item,booked=data.item">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <!-- @click="print(data.item,'booked')" -->
                                    <!-- <b-button @click="print(data.item)" v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Print">
                                        <font-awesome-icon icon="print" />
                                    </b-button> -->
                                    <b-button v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Dispatched" @click="booked=data.item,booked.trackingId='',booked.courierOffice=null,partial.pendingBooks=''" v-b-modal.dispatchedBook>
                                        <font-awesome-icon icon="truck" />
                                    </b-button>
                                    <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->

                                </div>
                            </template>
                        </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Partial Dispatched" @click="tabChange('partial')">
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="2" lg="2" id="r1">
                                <input type="search" class="searchbar" v-model="partialKeyword" placeholder="search" v-on:keyup.enter="getPartialSearchCount()"/>
                            </b-col>
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getPartialSearchCount()">Search</b-button>
                            </b-col> -->
                            <b-col col sm="12" md="3" lg="3" id="r1">
                                <input type="search" class="searchbar" v-model="pendingKeyword" placeholder="Pending Search" v-on:keyup.enter="getPendingSearchCount()"/>
                            </b-col>
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getPartialSearchCount()">Search</b-button>
                            </b-col> -->

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="partialCurrentPage" :total-rows="totalCount" :per-page="partialPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPartialDispatchedPage(partialCurrentPage, partialPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="partialPageCount" @input="switchPartialDispatchedPage(partialCurrentPage, partialPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="getAllPartialDispatchedCount()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allPartialDispatched" :fields="partialFields" :per-page="allPartialDispatched.partialPageCount" :current-page="partialCurrentPage" ref="table">
                              
                            <template v-slot:cell(date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.dispatched_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(book)="data">
                                {{ data.item.type.name }}
                            </template>
                            <template v-slot:cell(remarks)="data">
                                {{ data.item.dispatchedCourierName }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div class="text-primary contact">
                                    {{ data.item.contact }}
                                </div>
                                <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize" style="min-width: 130px;">
                                    <b-button  size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item,booked=data.item,getCurrentPartial()">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <!-- <b-button v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Print" @click="print(data.item,'partial')">
                                        <font-awesome-icon icon="print" />
                                    </b-button> -->
                                    <b-button v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Dispatched" @click="partial.pendingBooks='', booked=data.item,booked.trackingId='',booked.courierOffice=null" v-b-modal.dispatchedBook>
                                        <font-awesome-icon icon="truck" />
                                    </b-button>
                                    <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->

                                </div>
                            </template>
                        </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Dispatched" @click="tabChange('dispatched')">
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="dispatchedKeyword" placeholder="search" v-on:keyup.enter="getDispatchedSearchCount()"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getDispatchedSearchCount()">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="dispatchedCurrentPage" :total-rows="totalCount" :per-page="dispatchedPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchDispatchedPage(dispatchedCurrentPage, dispatchedPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="dispatchedPageCount" @input="switchDispatchedPage(dispatchedCurrentPage, dispatchedPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="getAllDispatchedCount()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allDispatched" :fields="dispatchedFields" :per-page="allDispatched.dispatchedPageCount" :current-page="dispatchedCurrentPage" ref="table">
                              
                            <template v-slot:cell(date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.dispatched_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(book)="data">
                                {{ data.item.type.name }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div class="text-primary contact">
                                    {{ data.item.contact }}
                                </div>
                                <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                    <b-button  size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item,booked=data.item,getCurrentPartial()">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <b-button v-if="role!='ROLE_SUPER_ADMIN'" size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Delivered" @click="booked=data.item,getOrganization('delivered',data.item)">
                                        <font-awesome-icon icon="shopping-basket" />
                                    </b-button>
                                    <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->

                                </div>
                            </template>
                        </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Delivered" @click="tabChange('delivered')">

                <div>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="deliveredKeyword" placeholder="search" v-on:keyup.enter="getDeliveredSearchCount()"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getDeliveredSearchCount()">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="deliveredCurrentPage" :total-rows="totalCount" :per-page="deliveredPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchDeliveredPage(deliveredCurrentPage, deliveredPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="deliveredPageCount" @input="switchDeliveredPage(deliveredCurrentPage, deliveredPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="getAllDeliveredCount()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allDelivered" :fields="deliveredFields" :per-page="allDelivered.deliveredPageCount" :current-page="deliveredCurrentPage" ref="table">
                               
                            <template v-slot:cell(date)="data">
                                <div class="cellSize">
                                    {{ callDate(data.item.delivered_at, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(book)="data">
                                {{ data.item.type.name }}
                            </template>
                            <template v-slot:cell(name)="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:cell(contact)="data">
                                <div class="text-primary contact">
                                    {{ data.item.contact }}
                                </div>
                                <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                            </template>

                            <template v-slot:cell(action)="data">
                                <div class="cellSize">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item,booked=data.item,getCurrentPartial()">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <!-- <b-button size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Delivered" @click="delivered(data.item)">
                                            <font-awesome-icon icon="shopping-basket" />
                                        </b-button> -->
                                    <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" size="sm" class="mx-1" @click="archive(data.item.callId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->

                                </div>
                            </template>
                        </b-table>
                    </b-container>
                </div>
            </b-tab>
        </b-tabs>
    </b-container>
    <b-modal id="dispatchedBook" title="Book Order" ref="modal" hide-footer>
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Dispatched" active >
                <div class="form-row">
                   
                    <div class="form-group" v-if="booked!=null">
                        <label>Courier Office:</label>
                        <b-form-select class="form-control" v-model="booked.courierOffice" :class="{
                            'is-invalid': $v.booked.courierOffice.$error,
                            'is-valid': !$v.booked.courierOffice.$invalid,
                            }">
                            <option v-for="q in courierOffice" :value="q.value" v-bind:key="q.index">
                                <strong>{{ q.text }}</strong>
                            </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                            <span v-if="!$v.booked.courierOffice.required">courier Office is required</span>
                        </div>
                    </div>
                    <div class="form-group" v-if="booked!=null">
                        <label>Tracking Id:</label>
                        <b-form-input :disabled="booked.courierOffice==null" @keydown.enter.prevent="getOrganization('dispatched')" :class="{
                        'is-invalid': $v.booked.trackingId.$error,
                        'is-valid': !$v.booked.trackingId.$invalid,
                        }" style="margin-top: 10px;" class="form-control" placeholder="Enter your Tracking Id" v-model.trim="booked.trackingId">
                        </b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.booked.trackingId.required">Tracking Id is required</span>
                        </div>
                    </div>
                    <br />

                    <div class="text-center">
                        <b-button variant="outline-success" type="submit" :disabled="disabledButton" @click="getOrganization('dispatched')">Submit</b-button>
                        <!-- <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button> -->
                    </div>
                </div>
            </b-tab>
            <b-tab title="partial Dispatched" >
                <div class="form-row">
                    <div class="form-group" v-if="booked!=null">
                        <label>Courier Office:</label>
                        <b-form-select class="form-control" v-model="booked.courierOffice" :class="{
                            'is-invalid': $v.booked.courierOffice.$error,
                            'is-valid': !$v.booked.courierOffice.$invalid,
                            }">
                            <option v-for="q in courierOffice" :value="q.value" v-bind:key="q.index">
                                <strong>{{ q.text }}</strong>
                            </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                            <span v-if="!$v.booked.courierOffice.required">courier Office is required</span>
                        </div>
                    </div>
                    <div class="form-group" v-if="booked!=null">
                        <label>Tracking Id:</label>
                        <b-form-input @keydown.enter.prevent="partialDispatched('dispatched')" :class="{
                        'is-invalid': $v.booked.trackingId.$error,
                        'is-valid': !$v.booked.trackingId.$invalid,
                        }" style="margin-top: 10px;" class="form-control" placeholder="Enter your Tracking Id" v-model.trim="booked.trackingId">
                        </b-form-input>
                        <div class="invalid-feedback">
                            <span v-if="!$v.booked.trackingId.required">Tracking Id is required</span>
                        </div>
                    </div>
                    <br />
                    <div class="form-group" v-if="booked!=null">
                        <label>Pending Books:</label>
                        <b-form-textarea rows="3" @keydown.enter.prevent="partialDispatched()" :class="{
                        'is-invalid': $v.partial.pendingBooks.$error,
                        'is-valid': !$v.partial.pendingBooks.$invalid,
                        }" style="margin-top: 10px;" class="form-control" placeholder="Enter Pending Books" v-model.trim="partial.pendingBooks">
                        </b-form-textarea>
                        <div class="invalid-feedback">
                            <span v-if="!$v.partial.pendingBooks.required">Pending Books required</span>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <b-button variant="outline-success" type="submit" :disabled="disabledButton" @click="partialDispatched()">Submit</b-button>
                        <!-- <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button> -->
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </b-modal>
    <b-container>
        <b-modal size="lg" id="viewbooked" title="View Detailed Data" ref="view" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Course Name" v-model=bookOrder.name disabled>
                    </b-form-input>

                </div>
               
                <br>
                <div class="form-group">
                    <label>Order Number:</label>
                    <b-form-input class="form-control" v-model=bookOrder.orderNumber disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                
                <div class="form-group">
                    <label>Agent Name:</label>
                    <b-form-input class="form-control" v-model="bookOrder.agentName" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Contact Number:</label>
                    <b-form-input class="form-control" v-model=bookOrder.contact disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Whatsapp:</label>
                    <b-form-input class="form-control" v-model="bookOrder.whatsapp" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                
                <div class="form-group" >
                    <label>Address:</label>
                    <div style="padding-left:10px;background-color:#e9ecef;border:1px solid #ced4da">
                        {{bookOrder.doorNo}},
                        <br>
                        {{bookOrder.landmark}},
                        <br>
                        {{bookOrder.district}},
                        <br>
                        {{bookOrder.state}} - {{bookOrder.pincode}}
                    </div>

                </div>
                <br />
                <div class="form-group">
                    <label>Payment Method:</label>
                    <b-form-input class="form-control" v-model=bookOrder.paymentMethod disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                
                <br />
                <div class="form-group">
                    <label>BookBundle Name:</label>
                    <b-form-input class="form-control" v-model="bookOrder.type.name" disabled>
                    </b-form-input>
                </div>
                <br />
                <div class="form-group" >
                    <label>Remarks:</label>
                    <div style="padding-left:10px;background-color:#e9ecef;border:1px solid #ced4da">
                        {{ bookOrder.remarks }}
                    </div>

                </div>
                <!-- <div class="form-group">
                    <label>Remarks:</label>
                    <b-form-input class="form-control" v-model="bookOrder.remarks" disabled placeholder="update your description">
                    </b-form-input>
                </div> -->
                <br />
                <div class="form-group">
                    <label>BookBundle Price:</label>
                    <b-form-input class="form-control" v-model="bookOrder.type.price" disabled>
                    </b-form-input>
                </div>
                <br />
                <div class="form-group" v-if="bookOrder.dispatched">
                    <label>Tracking Id:</label>
                    <b-form-input class="form-control" v-model="bookOrder.trackingId" disabled>
                    </b-form-input>
                </div>
                <br>
                <div v-if="(bookOrder.dispatched||bookOrder.partialDispatched||bookOrder.delivered)&&(currentPartial.length!=0)">
                    <b-table hover responsive :items="currentPartial" :fields="pendingFields"  ref="table" class="partialTable" style="font-size: 85%;">
                               
                               <template v-slot:cell(date)="data">
                                   <div class="cellSize" >
                                       {{ callDate(data.item.createdAt, "fullDate") }}
                                   </div>
                               </template>
                               <template v-slot:cell(pending)="data">
                                   {{ data.item.pendingBooks }}
                                </template>
                                <template v-slot:cell(trackingId)="data" >
                                    <div style="min-width: 120px;">

                                        {{ data.item.trackingId}}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data" >
                                    <b-button size="sm" class="mx-1" variant="success" v-if="data.item.delivered" >
                                        <font-awesome-icon icon="check-circle" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="primary" v-else  @click="bookOrder=partialDelivered(data.item.partialId)">
                                        <font-awesome-icon icon="shopping-basket" />
                                    </b-button>
                                </template>
                           </b-table>
                </div>
                <div class="mt-3 mt-2 text-center" v-if="!bookOrder.dispatched">
                    <b-button type="submit" v-if="bookOrder.partialDispatched" @click="print(booked,'partial')" class="px-3 mx-3" variant="warning"><font-awesome-icon icon="print" /> Print</b-button>
                    <b-button type="submit" v-if="!bookOrder.partialDispatched" @click="print(booked,'booked')" class="px-3 mx-3" variant="warning"><font-awesome-icon icon="print" /> Print</b-button>
                    <b-button type="submit" v-b-modal.dispatchedBook @click="partial.pendingBooks='',booked.trackingId='',booked.courierOffice=null" class="px-3 mx-3" variant="primary"> <font-awesome-icon icon="truck" /> Dispatched</b-button>
                </div>
                <div class="mt-3 mt-2 text-center" v-if="(bookOrder.dispatched)&&(!bookOrder.delivered)">
                    <b-button type="submit"  @click="getOrganization('delivered')" class="px-3 mx-3" variant="success"> <font-awesome-icon icon="shopping-basket" /> Delivered</b-button>
                </div>
                <!-- <div class="form-group">
                    <label>BookBundle Books:</label>
                    <br>
                    <div class="" style="padding-left: 15px;font-weight: bold;" v-for="b in bookBundle.books" :key="b.bookId">
                        {{ b.name }}
                    </div>
                </div> -->
                <br />
            </div>

        </b-modal>
    </b-container>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";
import TeamService from "../service/TeamService";
import axios from 'axios';
import moment from "moment-timezone";
import Account from '../service/Account.js';
import BookBundleService from '../service/BookBundleService';
export default {
    name: "BookCourier",
    data() {
        return {
            pendingSearch:false,
            order: null,
            organization:"",
            logo:"",
            isCod:false,
            printPage:false,
            partial:{
                pendingBooks:""
            },
            currentTab:"booked",
            role: localStorage.getItem("roles"),
            disabledButton: false,
            loading: true,
            currentUser: JSON.parse(localStorage.getItem("current_user")),
            searchPagination:false,
            currentPage: 1,
            pageCount:10,
            dispatchedPageCount:10,
            dispatchedCurrentPage:1,
            partialPageCount:10,
            partialCurrentPage:1,
            pendingPageCount:10,
            pendingCurrentPage:1,
            currentPartial:[],
            deliveredPageCount:10,
            deliveredCurrentPage:1,
            keyword: "",
            dispatchedKeyword: "",
            pendingKeyword:"",
            partialKeyword: "",
            deliveredKeyword: "",
            totalCount: 0,
            booked: null,
            allBooked: [],
            allDispatched: [],
            allPartialDispatched:[],
            allDelivered: [],
            bookOrder: {
                agentName: "",
                booked: null,
                contact: "",
                createdAt: "",
                delivered: null,
                dispatched: null,
                district: "",
                doorNo: "",
                email: null,
                landmark: "",
                name: "",
                orderId: "",
                orderNumber: "",
                partialDispatched: null,
                paymentMethod: "",
                pincode: "",
                referenceNumber: "",
                remarks: "",
                state: "",
                trackingId: "",
                type: {
                    name: "",
                    price:0
                },
                whatsapp: ""
            },
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            courierOffice: [{
                    value: null,
                    text: "Select Courier",
                    disabled: true,
                },
                {
                    value: 'st_courier',
                    text: 'ST Courier',
                },
                {
                    value: 'post_office',
                    text: 'Post Office',
                }
            ],
            bookedFields: [{
                    key: "date",
                    label: "Booked Date",
                },
                {
                    key: "orderNumber",
                    label: "Order Number",
                },
                {
                    key: "book",
                    label: "BookBundle Name",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                // {
                //     key: "whatsapp",
                //     label: "Whatsapp",
                // },

                {
                    key: "action",
                    label: "Action",
                },
            ],
            dispatchedFields: [{
                    key: "date",
                    label: "Dispatched Date",
                },
                {
                    key: "orderNumber",
                    label: "Order Number",
                },
                {
                    key: "book",
                    label: "BookBundle Name",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                // {
                //     key: "whatsapp",
                //     label: "Whatsapp",
                // },

                {
                    key: "action",
                    label: "Action",
                },
            ],
            partialFields: [{
                    key: "date",
                    label: "Dispatched Date",
                },
                {
                    key: "orderNumber",
                    label: "Order Number",
                },
                {
                    key: "remarks",
                    label: "Pending Books",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                // {
                //     key: "whatsapp",
                //     label: "Whatsapp",
                // },

                {
                    key: "action",
                    label: "Action",
                },
            ],
            deliveredFields: [{
                    key: "date",
                    label: "Delivered Date",
                },
                {
                    key: "orderNumber",
                    label: "Order Number",
                },
                {
                    key: "book",
                    label: "BookBundle Name",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                // {
                //     key: "whatsapp",
                //     label: "Whatsapp",
                // },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            pendingFields: [{
                    key: "date",
                    label: "Date",
                },
                {
                    key: "pending",
                    label: "Pending",
                },
                {
                    key: "trackingId",
                    label: "Tracking Id",
                },
                {
                    key: "action",
                    label: "Delivered",
                }
            ],
            count:1,
        }
    },
    validations: {
        booked: {
            trackingId: {
                required,
            },
            courierOffice:{
                required
            }

        },
        partial: {
            pendingBooks: {
                required
            },

        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllBookedCount();
            }
        },
        dispatchedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllDispatchedCount();
            }
        },
        deliveredKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllDeliveredCount();
            }
        },
        partialKeyword(newVal , oldVal) {
            if (oldVal && !newVal) {
                if (!this.pendingSearch) {
                    this.getAllPartialDispatchedCount();
                }
            }
        },
        pendingKeyword(newVal , oldVal) {
            if (oldVal && !newVal) {
                if (this.pendingSearch) {
                    this.getAllPartialDispatchedCount();
                }
                
            }
        }
        // allDispatched(){
        //     if (this.allDispatched.length==0) {
        //         if (this.dispatchedCurrentPage!=1) {
        //             this.dispatchedCurrentPage=this.dispatchedCurrentPage-1;
        //             this.getAllDispatched(this.dispatchedCurrentPage, this.dispatchedPageCount);
        //         }
        //     }
        // }

    },
    beforeDestroy() {
        axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
    },
    mounted() {
        console.log(this.currentUser);
        window.onafterprint = ()=>{
            this.printPage=false;     
            this.$refs.view.hide();
            console.log("Printing completed...",this.printPage);
        }

            console.log("else");
            return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    this.getAllBookedCount();
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
        

        

        

    },
    methods: {
        
        print(booked){
            this.booked=booked
            this.getOrganization('print')
            this.printPage=true;
        },
        // getAgentOrders() {
        //     return new Promise((resolve, reject) => {
        //         let data = {
        //             agentId: this.agentId
        //         }
        //         this.loading = true
        //         BookBundleService.getAgentOrders(this.currentPage, this.pageCount, data)
        //             .then((response) => {
        //                 this.booked = response.data;
        //                 this.loading = false
        //                 resolve(response);

        //             })
        //             .catch((err) => {
        //                 reject(err);

        //             });
        //     });
        // },
        tabChange(tab) {
            this.currentPage = 1;
            this.totalCount = 0;
            this.currentTab=tab;
            if (tab=='partial') {
                this.getAllPartialDispatchedCount()
            } else if (tab == 'dispatched') {
                this.getAllDispatchedCount()
            } else if (tab == 'delivered') {
                this.getAllDeliveredCount()
            }
            else if (tab == 'COD') {
                this.isCod=true;
                this.getAllBookedCount()
            } else {
                this.isCod=false;
                this.getAllBookedCount()
            }
        },
        getOrganization(action) {
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(this.booked.teamId)
                    .then((response) => {
                        this.organization=response.data.organization;
                        if (action=="dispatched") {
                            this.dispatched(response.data.organization)
                        }
                        if (action=="delivered") {
                            this.delivered(response.data.organization)
                        }
                        if (action=='print') {
                            if(this.organization=="examsdaily")
                            this.logo="https://push-imgcdn.win/a0f3cf29-b1b9-4b4b-98da-7e139be8424c.png";
                            else if(this.organization=="edextech") {
                                this.logo="https://push-imgcdn.win/dade8093-6d06-41f4-aeb1-b246fcc729a2.png";
                            }
                            else if(this.organization=="dexter_academy") {
                                this.logo="https://push-imgcdn.win/68ed3f72-f732-4ae6-ad09-7fa1b26ab0e1.png";
                            }
                        setTimeout(() => {

                            window.print();
                        }, 300);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        partialDispatched: function () {
            this.$v.partial.$touch();
            this.$v.booked.$touch();
            if (this.$v.partial.$invalid||this.$v.booked.$invalid) {
                // alert("insert submit failed");
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        text: "You are Changed the Status Booked to Partial Dispatched!",
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Change",
                        allowEnterKey:true
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.disabledButton = true
                            this.$refs.modal.hide();
                            let data = {
                                trackingId: this.booked.trackingId,
                                orderId: this.booked.id,
                                orderNumber:this.booked.orderNumber,
                                teamId:this.booked.teamId,
                                agentId:this.booked.agentId,
                                remarks:this.partial.pendingBooks,
                                dispatchedCourierId:this.currentUser.id,
                                dispatchedCourierName:this.currentUser.username,
                                courierOffice:this.booked.courierOffice
                            }
                            console.log(data);
                            axios.defaults.headers.common["tenant-uuid"] = this.booked.teamId
                            return new Promise((resolve, reject) => {
                                BookBundleService.savePartialDispatched(data)
                                    .then((response) => {
                                        axios.defaults.headers.common["tenant-uuid"] = ""
                                        // this.dispatchedTenent();
                                        // this.getAllBookedCount();
                                        if (this.currentTab=='partial') {
                                                console.log("this.currentTab=='partial'");
                                                if (this.searchPagination) {
                                                    if (!this.pendingSearch) {
                                                        this.getPartialSearch(this.partialCurrentPage,this.partialPageCount);
                                                    }
                                                    else{
                                                        this.getPendingSearch(this.partialCurrentPage,this.partialPageCount)
                                                    }
                                                    
                                                }else{
                                                    this.getAllPartialDispatched(this.partialCurrentPage,this.partialPageCount);
                                                }
                                            }else{
                                                if (this.searchPagination) {
                                                    this.getBookedSearch(this.currentPage, this.pageCount);
                                                }else{
                                                    this.getAllBooked(this.currentPage, this.pageCount);
                                                }
                                            }
                                        this.$refs.view.hide();
                                        this.disabledButton = false;
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
            }
        },
        dispatched: function (org) {
            this.$v.booked.$touch();
            if (this.$v.booked.$invalid) {
                // alert("insert submit failed");
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        text: "You are Changed the Status Booked to Dispatched!",
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Change",
                        allowEnterKey:true
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.disabledButton = true
                            this.$refs.modal.hide();
                            let data = {
                                trackingId: this.booked.trackingId,
                                orderId: this.booked.id,
                                bookBundleName:this.booked.type.name,
                                courierOffice:this.booked.courierOffice,
                                organization:org,
                                dispatchedCourierId:this.currentUser.id,
                                dispatchedCourierName:this.currentUser.username
                            }
                            console.log(data);
                            axios.defaults.headers.common["tenant-uuid"] = this.booked.teamId
                            return new Promise((resolve, reject) => {
                                BookBundleService.saveDispatched(data)
                                    .then((response) => {
                                        axios.defaults.headers.common["tenant-uuid"] = ""
                                        // this.dispatchedTenent();
                                        // this.getAllBookedCount();
                                        // if (this.searchPagination) {
                                        //     console.log("this.searchPagination");
                                        //     // this.getAllBooked(this.currentPage, this.pageCount);
                                        //     this.getAllBookedCount()
                                        // }else{
                                            // if (this.currentTab=='partial') {
                                            //     console.log("this.currentTab=='partial'");
                                            //     if (this.searchPagination) {
                                            //         this.pendingSearch()
                                            //     }else{
                                            //         this.getAllPartialDispatched(this.partialCurrentPage,this.partialPageCount);
                                            //     }
                                            // }else{
                                            //     this.getAllBooked(this.currentPage, this.pageCount);
                                            // }
                                            if (this.currentTab=='partial') {
                                                console.log("this.currentTab=='partial'");
                                                if (this.searchPagination) {
                                                    if (!this.pendingSearch) {
                                                        this.getPartialSearch(this.partialCurrentPage,this.partialPageCount);
                                                    }
                                                    else{
                                                        this.getPendingSearch(this.partialCurrentPage,this.partialPageCount)
                                                    }
                                                    
                                                }else{
                                                    this.getAllPartialDispatched(this.partialCurrentPage,this.partialPageCount);
                                                }
                                            }else{
                                                if (this.searchPagination) {
                                                    this.getBookedSearch(this.currentPage, this.pageCount);
                                                }else{
                                                    this.getAllBooked(this.currentPage, this.pageCount);
                                                }
                                            }
                                        // }
                                        this.$refs.view.hide();
                                        this.disabledButton = false;
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        reject(err);
                                    });
                            });
                        }
                    })
            }
        },
        delivered: function (org) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You are Changed the Status Dispatched to Delivered!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Change",
                })
                .then((result) => {
                    console.log(this.booked);
                    if (result.isConfirmed) {
                        this.$refs.modal.hide();
                        let data = {
                            orderId: this.booked.id,
                            organization:org,
                            deliveredCourierId:this.currentUser.id,
                            deliveredCourierName:this.currentUser.username
                        }
                        console.log(data);
                        axios.defaults.headers.common["tenant-uuid"] = this.booked.teamId
                        return new Promise((resolve, reject) => {
                            BookBundleService.saveDelivered(data)
                                .then((response) => {
                                    this.$refs.view.hide();
                                    axios.defaults.headers.common["tenant-uuid"] = ""
                                    // this.dispatchedTenent();
                                    // this.getAllDispatchedCount();
                                    if (this.searchPagination) {
                                        this.getDispatchedSearch(this.dispatchedCurrentPage, this.dispatchedPageCount);
                                        }else{
                                            this.getAllDispatched(this.dispatchedCurrentPage, this.dispatchedPageCount);
                                        }
                                    this.disabledButton = false;
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                })

        },
        partialDelivered: function (partialId) {
            console.log("sdf");
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You are Changed the Status Delivered!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Change",
                })
                .then((result) => {
                    console.log(partialId);
                    if (result.isConfirmed) {
                        // this.$refs.modal.hide();
                        let data = {
                            partialId: partialId
                        }
                        console.log(data);
                        axios.defaults.headers.common["tenant-uuid"] = ""
                        return new Promise((resolve, reject) => {
                            BookBundleService.savePartialDelivered(data)
                                .then((response) => {
                                    this.$refs.view.hide();
                                    this.$swal({
                                        title: "Success",
                                        text: "Partial delivered successfully",
                                        icon: "success",
                                        button: "ok",
                                    });
                                    this.disabledButton = false;
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                })

        },
        // dispatchedTenent: function() {
        //     axios.defaults.headers.common["tenant-uuid"] = this.booked.teamId
        //     this.$refs.modal.hide();
        //     let data = {
        //         trackingId:this.booked.trackingId,
        //         orderId:this.booked.orderId,
        //     }
        //     return new Promise((resolve, reject) => {
        //             BookBundleService.saveDispatched(data)
        //                 .then((response) => {
        //                     axios.defaults.headers.common["tenant-uuid"] =""
        //                     this.getAllBookedCount();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     axios.defaults.headers.common["tenant-uuid"] =""
        //                     reject(err);
        //                 });
        //         });
        // },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        switchPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllBooked(currentPage, pageCount);
            }
            else{
                this.getBookedSearch(currentPage, pageCount)
            }
        },
        switchPartialDispatchedPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (this.searchPagination) {
                if (!this.pendingSearch) {
                    this.getPartialSearch(currentPage, pageCount);
                }
                else{
                    this.getPendingSearch(currentPage, pageCount)
                }
                
            }else{
                this.getAllPartialDispatched(currentPage, pageCount);
            }
        },
        switchDispatchedPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllDispatched(currentPage, pageCount);
            }
            else{
                this.getDispatchedSearch(currentPage, pageCount)
            }
        },
        switchDeliveredPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllDelivered(currentPage, pageCount);
            }
            else{
                this.getDeliveredSearch(currentPage, pageCount)
            }
        },
        getAllBookedCount: function () {
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.currentPage = 1;
            this.keyword = "";
            this.searchPagination=false;
            let data = {
                agentId: 'admin',
                archive:false,
                paymentMethod:this.isCod?'cod':'others'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getAllBookedCount(data)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllBooked(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBooked(currentPage, pageCount) {
            let data = {
                agentId: 'admin',
                archive:false,
                paymentMethod:this.isCod?'cod':'others'
            }
            
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getAllBooked(currentPage, pageCount, data)
                    .then((response) => {
                       
                        this.allBooked = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getBookedSearchCount: function () {
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.searchPagination=true;
            this.currentPage = 1;

            let data = {
                agentId: 'admin',
                paymentMethod:this.isCod?'cod':'others'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getBookedSearchCount(data, this.keyword.toLowerCase())
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getBookedSearch(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBookedSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin',
                paymentMethod:this.isCod?'cod':'others'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getBookedSearch(currentPage, pageCount, data, this.keyword.toLowerCase())
                    .then((response) => {
                        this.allBooked = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getAllPartialDispatchedCount: function () {
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.searchPagination=false;
            this.partialKeyword = "";
            this.pendingKeyword = "";
            this.partialCurrentPage = 1;
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getAllPartialDispatchedCount(data)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllPartialDispatched(this.partialCurrentPage, this.partialPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPartialDispatched(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getAllPartialDispatched(currentPage, pageCount, data)
                    .then((response) => {
                        this.allPartialDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getCurrentPartial() {
            return new Promise((resolve, reject) => {
                BookBundleService.getCurrentPartial(this.booked.orderNumber)
                    .then((response) => {
                        this.currentPartial=response.data;
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getAllDispatchedCount: function () {
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.searchPagination=false;
            this.dispatchedCurrentPage = 1;
            this.dispatchedKeyword = "";
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getAllDispatchedCount(data)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllDispatched(this.dispatchedCurrentPage, this.dispatchedPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllDispatched(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getAllDispatched(currentPage, pageCount, data)
                    .then((response) => {
                        this.allDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getDispatchedSearchCount: function () {
            this.searchPagination=true;
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.pendingSearch=false;
            this.loading = true;
            this.dispatchedCurrentPage = 1;
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getDispatchedSearchCount(data, this.dispatchedKeyword.toLowerCase())
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getDispatchedSearch(this.dispatchedCurrentPage, this.dispatchedPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getDispatchedSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getDispatchedSearch(currentPage, pageCount, data, this.dispatchedKeyword.toLowerCase())
                    .then((response) => {
                        this.allDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getPartialSearchCount: function () {
            this.pendingSearch=false;
            this.searchPagination=true;
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            
            this.pendingKeyword = "";
            this.partialCurrentPage = 1;
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getPartialSearchCount(data, this.partialKeyword.toLowerCase())
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getPartialSearch(this.partialCurrentPage, this.partialPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getPartialSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getPartialSearch(currentPage, pageCount, data, this.partialKeyword.toLowerCase())
                    .then((response) => {
                        this.allPartialDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },

        getPendingSearchCount: function () {
            this.searchPagination=true;
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.partialKeyword = "";
            this.partialCurrentPage = 1;
            this.pendingSearch=true;
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getPendingSearchCount(data, this.pendingKeyword.toLowerCase())
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getPendingSearch(this.partialCurrentPage, this.partialPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getPendingSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getPendingSearch(currentPage, pageCount, data, this.pendingKeyword.toLowerCase())
                    .then((response) => {
                        this.allPartialDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getAllDeliveredCount: function () {
            let data = {
                agentId: 'admin'
            }
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.searchPagination=false;
            this.deliveredCurrentPage = 1;
            this.deliveredKeyword = "";
            return new Promise((resolve, reject) => {
                BookBundleService.getAllDeliveredCount(data)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllDelivered(this.deliveredCurrentPage, this.deliveredPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllDelivered(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getAllDelivered(currentPage, pageCount, data)
                    .then((response) => {
                        this.allDelivered = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getDeliveredSearchCount: function () {
            // this.deliveredKeyword = keyword.toLowerCase();
            this.searchPagination=true;
            axios.defaults.headers.common["tenant-uuid"] = ""
            this.loading = true;
            this.deliveredCurrentPage = 1;
            this.keyword = "";
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getDeliveredSearchCount(data,  this.deliveredKeyword.toLowerCase())
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getDeliveredSearch(this.deliveredCurrentPage, this.deliveredPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getDeliveredSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getDeliveredSearch(currentPage, pageCount, data, this.deliveredKeyword.toLowerCase())
                    .then((response) => {
                        this.allDelivered = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
    }
}
</script>

<style scoped>
.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media print {
        body{
            width: 80%;
        }
}
.printcontainer .bottom {
        background-color: #e6e6e6;
    }

   .printcontainer h1.a {
        letter-spacing: 3px;
    }

    .printcontainer .order-nu {
        box-shadow: inset 0 0 0 1000px #000000;
        border-radius: o 20px 0 20px;
        background-color: #0183ff;
        color: white;
        font-weight: bold;
        text-align: start;
        font-size: 17px;
    }

    .printcontainer .order-nu-1 {
        position: relative;
        top: 10px;
    }
    .printcontainer .delivery {
        margin:  0 3px 0 3px ;
        background-color: #e6e6e6;
        box-shadow: inset 0 0 0 1000px #e6e6e6;
    }

    .printcontainer {
        border: 2px solid black;
        
        width: 100%;
        height: 100vh;
    }

    .printcontainer .user_to {
        text-align: left;
        font-size: 110%;
        /* margin-left: 60px; */
    }
    @media print {
  /* Set the page size to A4 */

  
  /* Hide overflow content to fit within one page */
  
  .table-page{
    display: none;
  }
  /* Specify that only the first page should be printed */

}
</style>
