<template>
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<div>
	<div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!--add Branch Batch button start-->

                <b-col col sm="12" md="4" lg="2" id="r1">
                    <!-- <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" /> -->
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <!-- <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button> -->
                </b-col>
                <b-col sm="12" md="3" lg="3" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="3" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col sm="6" md="1" lg="2" id="r1">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="md" variant="success" class="bnclr" @click="exportExcellSheet()">Download</b-button>
                </b-col>

            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <div>
                    <b-container>
                        <b-row>
                            <b-col col lg="3" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="branchSorting = true,getAllReportSortCount()">
                                    <b-form-select-option disabled value="branch">
                                        <p>Branches</p>
                                    </b-form-select-option>
                                    <!-- <b-form-select-option value="allBranches">
                                        <p>All Branch</p>
                                    </b-form-select-option> -->
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="3" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedCode" size="sm" @change="getAllReportSortCount()">
                                    <b-form-select-option disabled value="code">
                                        <p>Codes</p>
                                    </b-form-select-option>
                                    <!-- <b-form-select-option value="allBatchCodes">
                                        <p>All Code</p>
                                    </b-form-select-option> -->
                                    <option v-for="item in allBatches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.code }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="3" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedTutor" size="sm" @change="getAllReportSortCount()">
                                    <b-form-select-option disabled value="tutor">
                                        Tutors
                                    </b-form-select-option>
                                    <!-- <b-form-select-option value="allTutors">
                                        <p>All Tutors</p>
                                    </b-form-select-option> -->
                                    <option v-for="item in allTutors" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.userName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="3" md="2" id="r1" sm="4" class="sort">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" size="sm" @change="getAllReportSortCount()" :options="dateSelect">
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <!-- <hr />
                <p>Class Report</p>-->
                <hr />
                <b-table responsive hover :items="classReports" :fields="classReportFields" :current-page="currentPage" :per-page="classReports.pageCount" ref="table">
                    <template v-slot:cell(createdAt)="data">
                        <div class="cellSize">
                            {{ dateTimeFormat(data.item.createdAt, "dateTime") }}
                        </div>
                    </template>
                    <template v-slot:cell(classDate)="data">
                        <div class="cellSize">
                            {{ dateTimeFormat(data.item.classDate, "date") }}
                        </div>
                    </template>
                    <template v-slot:cell(branch)="data">
                        {{ data.item.branch.name }}
                    </template>
                    <template v-slot:cell(code)="data">
                        {{ data.item.code }}
                    </template>
                    <template v-slot:cell(tutor)="data">
                        {{ data.item.tutor.userName }}
                    </template>
                    <!-- <template v-slot:cell(fees)="data">
                        ₹ {{ data.item.fees }}
                    </template> -->
                    <template v-slot:cell(syllabus)="data">
                        {{ data.item.syllabus.name }}
                    </template>
                    <template v-slot:cell(studentCount)="data">
                        {{ data.item.studentCount }}
                    </template>
                    <template v-slot:cell(startTime)="data">
                        {{ dateTimeFormat(data.item.startTime, "time") }}
                    </template>
                    <template v-slot:cell(endTime)="data">
                        <div class="timeCell">
                        {{ dateTimeFormat(data.item.endTime, "time") }}
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>
    <b-modal ref="reportDateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="getAllReportSortCount()">Submit</b-button>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios';
import Account from '../service/Account.js';
import BranchService from "../service/BranchService"
// import BatchService from "../service/BatchService"
// import TutorService from "../service/TutorService"
import moment from "moment-timezone";
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'ClassReportAdminSupervisor',
    data() {
        return {
            loading: false,
            keyword: "",
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            classReports: [],
            classReportFields: [{
                    key: "createdAt",
                    label: "CreatedAt"
                },
                {
                    key: "classDate",
                    label: "Class Date"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "code",
                    label: "Code"
                },
                {
                    key: "tutor",
                    label: "Tutor"
                },
                // {
                //     key: "fees",
                //     label: "Fees"
                // },
                {
                    key: "syllabusLevel2",
                    label: "Syllabus"
                },
                {
                    key: "studentCount",
                    label: "Student",
                    tdClass: 'text-center'
                },
                {
                    key: "startTime",
                    label: "StartTime",
                    // tdClass: 'text-center'
                },
                {
                    key: "endTime",
                    label: "EndTime",
                    // tdClass: 'text-center'
                },
            ],
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            allBranches: [],
            allBatches: [],
            allTutors: [],
            selectedBranch: "branch",
            selectedCode: "code",
            selectedTutor: "tutor",
            selectedDates: null,
            data: null,
            from: "",
            to: "",
            resourceLocation: "https://api-encore.examsdaily.in/uploads/reports/",
            // resourceLocation: "http://localhost:8088/uploads/reports/",
            branchSorting: false,
        }
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
    },
	watch: {
		selectedBranch(newVal, oldVal) {
			if (oldVal.branchId != newVal.branchId) {
				this.selectedCode = "code";
				this.selectedTutor = "tutor";
				console.log("if=====");
				this.getBranchTutors(newVal);
				this.getAllBatchCodes(newVal);
			}
        },
	},
    mounted() {
        // let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    this.loading = true;
                    this.getAllBranchWise();
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
		async getAllBranchWise() {
			this.allBranches = JSON.parse(localStorage.getItem('branches'));
			this.selectedBranch = this.allBranches[0];
			await this.getAllReportSortCount();
		},
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBatchCodes: function (branch) {
			this.allBatches.splice(0);
			let data = {
				branch : branch
			}
            return new Promise((resolve, reject) => {
                axios.post("batch/admin/supervisor/branch/getAll",data)
                    .then((response) => {
                        this.allBatches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllTutors: function () {
			this.allTutors.splice(0);
			let data = {
				branchObj : this.selectedBranch
			}
            return new Promise((resolve, reject) => {
                axios.post("tutor/admin/supervisor/branch/getAll",data)
                    .then((response) => {
                        this.allTutors = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllReportSortCount: function () {
			this.loading = true;
            this.currentPage = 1
            var branch = this.selectedBranch;
            var batch = this.selectedCode;
            var tutor = this.selectedTutor;
            if (this.selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.reportDateRange.show();
                this.selectedDates = "dateBetween";
                this.loading = false;
            } else {
                if (branch == "branch" || branch == "allBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                if (batch == "code" || batch == "allBatchCodes") {
                    batch = {
                        batchId: "",
                    };
                }
                if (tutor == "tutor" || tutor == "allTutors") {
                    tutor = {
                        tutorId: "",
                    };
                }
                var from;
                var to;
                if (this.selectedDates != null) {
                    var date = new Date();
                    if (this.selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (this.selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (this.selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (this.selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        this.selectedDates == "dateBetween"
                    ) {
                        this.$refs.reportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        this.selectedDates == "dateBetween"
                    ) {
                        this.$v.from.$touch();
                        this.$v.to.$touch();
                        if (this.$v.from.$invalid || this.$v.to.$invalid)
                            return 0;
                        // this.$refs.reportDateRange.hide();
                        // this.fromDate = "2021-01-01 00:01";
                        // this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (this.selectedDates == null || this.selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    branchId: branch.branchId,
                    batchId: batch.batchId,
                    tutorId: tutor.tutorId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios.post("/branchBatch/class/report/sort/count", this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getAllSortReportPageable();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllSortReportPageable: function () {
            return new Promise((resolve, reject) => {
                axios.post("/branchBatch/class/report/sort/" + this.currentPage + "/" + this.pageCount, this.data)
                    .then((response) => {
                        this.classReports = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            console.log("======switchPage======");
            this.loading = true;
            this.currentPage = currentPage;
            this.pageCount = pageCount;
            this.getAllSortReportPageable();
        },
        exportExcellSheet: function () {
            var branch = this.selectedBranch;
            var batch = this.selectedCode;
            var tutor = this.selectedTutor;
            if (this.selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.reportDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (branch == "branch" || branch == "allBranches") {
                    branch = {
                        branchId: "",
                    };
                }
                if (batch == "code" || batch == "allBatchCodes") {
                    batch = {
                        batchId: "",
                    };
                }
                if (tutor == "tutor" || tutor == "allTutors") {
                    tutor = {
                        tutorId: "",
                    };
                }
                var from;
                var to;
                if (this.selectedDates != null) {
                    var date = new Date();
                    if (this.selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (this.selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (this.selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (this.selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        this.selectedDates == "dateBetween"
                    ) {
                        this.$refs.reportDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        this.selectedDates == "dateBetween"
                    ) {
                        this.$v.from.$touch();
                        this.$v.to.$touch();
                        if (this.$v.from.$invalid || this.$v.to.$invalid)
                            return 0;
                        // this.$refs.reportDateRange.hide();
                        // this.fromDate = "2021-01-01 00:01";
                        // this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (this.selectedDates == null || this.selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    branchId: branch.branchId,
                    batchId: batch.batchId,
                    tutorId: tutor.tutorId,
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    axios.post("/branchBatch/class/report/export/excel", this.data)
                        .then((response) => {
                            console.log("exportExcelSheet==== ", response.data);
                            // var url = response.data;
                            this.download(response.data);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },
        getBranchTutors: function (branch) {
            if(branch != "branch" && branch != "allBranches") {
                let data = {
                    branchObj: branch
                }
                return new Promise((resolve, reject) => {
                    axios.post("/tutor/branch/getAll", data)
                        .then((response) => {
                            // this.allTutors.splice(0);
                            this.allTutors = response.data;
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        // getAllReportCount: function () {
        //     return new Promise((resolve, reject) => {
        //         axios.post("/branchBatch/class/report/count")
        //             .then((response) => {
        //                 this.totalCount = response.data;
        //                 this.getAllReportPageable();
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        // getAllReportPageable: function () {
        //     return new Promise((resolve, reject) => {
        //         axios.post("/branchBatch/class/report/getAll/" + (this.currentPage - 1) + "/" + this.pageCount)
        //             .then((response) => {
        //                 this.classReports = response.data;
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        refresh: function () {
            this.selectedBranch = this.allBranches[0];
            this.selectedCode = "code";
            this.selectedTutor = "tutor";
            this.keyword = "";
            this.selectedDates = null;
            this.getAllBranchWise();
        },
        dateTimeFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "dateTime") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            } else if (dateType === "date") {
                return moment(date1).format("D / MM / YYYY ");
            }
            else if (dateType === "time") {
                const timeString12hr = new Date('1970-01-01T' + date + 'Z')
                    .toLocaleTimeString('en-US', {
                        timeZone: 'UTC',
                        hour12: true,
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                return timeString12hr;
            }
        },
        getDateRangeReset: function () {
            this.$refs.reportDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
    }
}
</script>

<style scoped>
.cellSize {
    min-width: 100px;
    max-width: 100px;
    margin-left: 20px;
}
.timeCell {
    min-width: 100px;
    max-width: 100px;
    margin-left: 10px;
}
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 1% 1%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
