<template>
<div>
    <b-container>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
        <!-- <div>
            <b-row class="my-4">
                <b-col col lg="2" style="text-align: right">
                    <b-button id="calls" size="sm" class="buttonActive" variant="outline-primary" @click="showEnquiryFollowups()" v-bind:class="{ active: isEnquiryFollowups }">
                        Enquiry Followup</b-button>
                </b-col>
                <b-col col lg="8" style="text-align: right">
                    <b-button id="calls" size="sm" class="buttonActive" variant="outline-primary" @click="showFollowups()" v-bind:class="{ active: isFollowups }">
                        Call Followup</b-button>
                </b-col>
                <b-col col lg="2">
                    <b-button size="sm" id="calls" class="buttonActive" variant="outline-primary" @click="showExpiredFollowups()" v-bind:class="{ active: isExpiredFollowups }">Call Expired Followup</b-button>
                </b-col>
            </b-row>
        </div> -->
        <!-- <div v-if="isFollowups"> -->
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Followups" active @click="getAllSortCount('Agents','Courses','Sources','Branches',null)">
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
              <b-button size="sm" variant="outline-primary" class="bnclr"  disabled @click="resetForm()">Add </b-button>
            </b-col> -->
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container> </b-container>
                    <!-- for sort dropdown start-->
                    <b-container>
                        <b-card class="card mt-1">
                            <div class="mb-3 text-end mx-3">
                                <span v-if="bookmarkPagination" class="text-primary mx-2">Bookmark is enable</span>
                                <b-button v-if="!bookmarkPagination" @click="bookmarkPagination = true,getAllBookmarkSortCount('Agents','Courses','Sources','Branches',null)" size="sm" class="" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="click to bookmark show">
                                    <font-awesome-icon icon="star" />
                                </b-button>
                                <b-button v-else @click="bookmarkPagination = false,refresh()" size="sm" class="" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="disable bookmark">
                                    <font-awesome-icon icon="star" />
                                </b-button>
                            </div>
                            <b-container>
                                <b-row>
                                    <b-col col lg="3" md="2" id="r1" sm="6">
                                        <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                            <option value="Agents" disabled>Agents</option>
                                            <option value="AllAgents">All Agents</option>
                                            <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.firstName }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedCourse" @change="getAllSortCount(selectedAgent,selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                            <option value="Courses" disabled>Courses</option>
                                            <option value="AllCourses">All Courses</option>
                                            <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedSource" @change="getAllSortCount(selectedAgent,selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                            <option value="Sources" disabled>Sources</option>
                                            <option value="AllSources">All Sources</option>
                                            <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedBranch" @change="getAllSortCount(selectedAgent,selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                            <option value="Branches" disabled>Branches</option>
                                            <option value="AllBranches">All Branches</option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                                <!-- <b-button variant="primary" @click="sorting()">sort</b-button> -->
                            </b-container>
                            <!-- for sort dropdown start-->
                            <hr />
                            <b-table responsive bordered striped hover :items="followups" :fields="followupItems" :current-page="currentPage" :per-page="followups.pageCount">
                                <template v-slot:cell(followupDate)="data">
                                    <div class="dateCell">
                                        <span v-bind:style="{color: dateConvertAndExpiredValidation(data.item.followupDate) ? 'red' : 'black'}">{{ dateConvert(data.item.followupDate, "fullDate") }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="dateCell">
                                        {{ dateConvert(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(type)="data">
                                    {{ data.item.type }}
                                </template>
                                <template v-slot:cell(remark)="data">
                                    {{ data.item.remark }}
                                </template>

                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getFollowup(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getFollowup(data.item)" v-b-modal.followInsert>
                                            <font-awesome-icon icon="file-signature" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Closed" @click="getFollowup(data.item)" v-b-modal.closed>
                                            <font-awesome-icon icon="window-close" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </div> -->
            </b-tab>

            <!-- =============================================Expired Followup======================================= -->

            <!-- <div v-if="isExpiredFollowups"> -->
            <b-tab title="Expired Followups" @click="getAllSortExpiredCount('Agents','Courses','Sources','Branches',null)">
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
              <b-button size="sm" variant="outline-primary" class="bnclr"  disabled @click="resetForm()">Add </b-button>
            </b-col> -->
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="expiredKeyword" v-on:keyup.enter="
                    getExpiredFollowupsSearchCount(expiredKeyword)
                  " placeholder="Type a name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getExpiredFollowupsSearchCount(expiredKeyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="expiredCurrentPage" :total-rows="expiredTotalCount" :per-page="expiredPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    switchExpiredPage(expiredCurrentPage, expiredPageCount)
                  "></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="expiredPageCount" @input="
                    switchExpiredPage(expiredCurrentPage, expiredPageCount)
                  " :options="expiredPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="expiredRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>

                    <!-- for sort dropdown start-->
                    <b-container>
                        <b-card class="card mt-1">
                            <div class="mb-3 text-end mx-3">
                                <span v-if="bookmarkPagination" class="text-primary mx-2">Bookmark is enable</span>
                                <b-button v-if="!bookmarkPagination" @click="bookmarkPagination = true,getAllSortBookmarkExpiredCount('Agents','Courses','Sources','Branches',null)" size="sm" class="" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="click to bookmark show">
                                    <font-awesome-icon icon="star" />
                                </b-button>
                                <b-button v-else @click="bookmarkPagination = false,expiredRefresh()" size="sm" class="" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="disable bookmark">
                                    <font-awesome-icon icon="star" />
                                </b-button>
                            </div>
                            <b-container>
                                <b-row>
                                    <b-col col lg="3" md="2" id="r1" sm="6">
                                        <b-form-select class="bnclr" v-model="selectedExpiredAgent" size="sm" @change="getAllSortExpiredCount(selectedExpiredAgent,selectedExpiredCourse,selectedExpiredSource,selectedExpiredBranch,selectedExpiredDates)">
                                            <option value="Agents" disabled>Agents</option>
                                            <option value="allAgents">All Agents</option>
                                            <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.firstName }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedExpiredCourse" @change="getAllSortExpiredCount(selectedExpiredAgent,selectedExpiredCourse,selectedExpiredSource,selectedExpiredBranch,selectedExpiredDates)">
                                            <option value="Courses" disabled>Courses</option>
                                            <option value="allCourses">All Courses</option>
                                            <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedExpiredSource" @change="getAllSortExpiredCount(selectedExpiredAgent,selectedExpiredCourse,selectedExpiredSource,selectedExpiredBranch,selectedExpiredDates)">
                                            <option value="Sources" disabled>Sources</option>
                                            <option value="allSources">All Sources</option>
                                            <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedExpiredBranch" @change="getAllSortExpiredCount(selectedExpiredAgent,selectedExpiredCourse,selectedExpiredSource,selectedExpiredBranch,selectedExpiredDates)">
                                            <option value="Branches" disabled>Branches</option>
                                            <option value="allBranches">All Branches</option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <!-- <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                  <b-form-select
                    class="bnclr"
                    v-model="selectDate"
                    @change="sortDate(selectDate)"
                    :options="dateRange"
                    >>
                  </b-form-select>
                </b-col> -->
                                </b-row>
                            </b-container>
                            <!-- for sort dropdown start-->
                            <hr />
                            <b-table responsive bordered striped hover :items="expiredFollowups" :fields="followupItems" :current-page="expiredCurrentPage" :per-page="expiredFollowups.expiredPageCount">
                                <template v-slot:cell(followupDate)="data">
                                    <div class="dateCell">
                                        <span>{{ dateConvert(data.item.followupDate, "fullDate") }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="dateCell">
                                        {{ dateConvert(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(type)="data">
                                    {{ data.item.type }}
                                </template>
                                <template v-slot:cell(remark)="data">
                                    {{ data.item.remark }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewDetail @click="getFollowup(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getFollowup(data.item)" v-b-modal.followInsert>
                                            <font-awesome-icon icon="file-signature" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Closed" @click="getFollowup(data.item)" v-b-modal.closed>
                                            <font-awesome-icon icon="window-close" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </div> -->
            </b-tab>

            <!-- =============================================Enquiry Followup======================================= -->

            <!-- <div v-if="isEnquiryFollowups"> -->
            <b-tab title="Enquiry Followups" @click="getAllSortEnquiryCount('Agents','Courses','Sources','Branches',null)">
                <!-- <div class="mt-5"> -->
                <div>
                    <b-container>
                        <b-row align-v="center">
                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
              <b-button size="sm" variant="outline-primary" class="bnclr"  disabled @click="resetForm()">Add </b-button>
            </b-col> -->
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="enquiryKeyword" v-on:keyup.enter="getEnquirySearchCount(enquiryKeyword)" placeholder="Type a name" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getEnquirySearchCount(enquiryKeyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentEnquiryPage" :total-rows="totalEnquiryCount" :per-page="pageEnquiryCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchEnquiryPage(currentEnquiryPage, pageEnquiryCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageEnquiryCount" @input="switchEnquiryPage(currentEnquiryPage, pageEnquiryCount)" :options="enquiryPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="enquiryRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container> </b-container>
                    <!-- for sort dropdown start-->
                    <b-container>
                        <b-card class="card mt-1">
                            <b-container>
                                <b-row>
                                    <b-col col lg="3" md="2" id="r1" sm="6">
                                        <b-form-select class="bnclr" v-model="selectedEnquiryAgent" size="sm" @change="getAllSortEnquiryCount(selectedEnquiryAgent,selectedEnquiryCourse,selectedEnquirySource,selectedEnquiryBranch,selectedEnquiryDates)">
                                            <option value="Agents" disabled>Agents</option>
                                            <option value="AllAgents">All Agents</option>
                                            <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.firstName }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedEnquiryCourse" @change="getAllSortEnquiryCount(selectedEnquiryAgent,selectedEnquiryCourse,selectedEnquirySource,selectedEnquiryBranch,selectedEnquiryDates)">
                                            <option value="Courses" disabled>Courses</option>
                                            <option value="AllCourses">All Courses</option>
                                            <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedEnquirySource" @change="getAllSortEnquiryCount(selectedEnquiryAgent,selectedEnquiryCourse,selectedEnquirySource,selectedEnquiryBranch,selectedEnquiryDates)">
                                            <option value="Sources" disabled>Sources</option>
                                            <option value="AllSources">All Sources</option>
                                            <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                    <b-col col lg="3" md="2" id="r1" sm="6" class="sort">
                                        <b-form-select class="bnclr" v-model="selectedEnquiryBranch" @change="getAllSortEnquiryCount(selectedEnquiryAgent,selectedEnquiryCourse,selectedEnquirySource,selectedEnquiryBranch,selectedEnquiryDates)">
                                            <option value="Branches" disabled>Branches</option>
                                            <option value="AllBranches">All Branches</option>
                                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                <strong>{{ item.name }}</strong>
                                            </option>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                                <!-- <b-button variant="primary" @click="sorting()">sort</b-button> -->
                            </b-container>
                            <!-- for sort dropdown start-->
                            <br />
                            <hr />
                            <b-table responsive bordered striped hover :items="enquiryFollowups" :fields="followupItems" :current-page="currentEnquiryPage" :per-page="enquiryFollowups.pageEnquiryCount">
                                <template v-slot:cell(followupDate)="data">
                                    <div class="dateCell">
                                        <span v-bind:style="{color: dateConvertAndExpiredValidation(data.item.followupDate) ? 'red' : 'black'}">{{ dateConvert(data.item.followupDate, "fullDate") }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="dateCell">
                                        {{ dateConvert(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(type)="data">
                                    {{ data.item.type }}
                                </template>
                                <template v-slot:cell(remark)="data">
                                    {{ data.item.remark }}
                                </template>

                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="ViewEnquiryDetails" v-b-modal.viewEnquiryDetail @click="getFollowup(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="EnquiryFollowup" @click="getFollowup(data.item)" v-b-modal.enquiryFollowInsert>
                                            <font-awesome-icon icon="file-signature" />
                                        </b-button>
                                        <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="Closed To" @click="getFollowup(data.item)" v-b-modal.enquiryClosed>
                                            <font-awesome-icon icon="window-close" />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
                <!-- </div> -->
            </b-tab>
        </b-tabs>
    </b-container>
    <b-container>
        <!--popup start for View Call details Followups details -->
        <!-- The modal -->
        <b-modal id="viewDetail" title="View Details" no-close-on-backdrop hide-footer>
            <div class="form-control">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input type="text" v-model="view.name" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact</label>
                        <b-form-input v-model="view.contact" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Alternative Contact</label>
                        <b-form-input v-model="view.alternativeContact" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Age</label>
                        <b-form-input v-model="view.age" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Qualification</label>
                        <b-form-input v-model="view.qualification" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Course</label>
                        <b-form-input v-model="view.course" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Source</label>
                        <b-form-input v-model="view.source" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Mode</label>
                        <b-form-input v-model="view.mode" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-form-input v-model="view.branch" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                    <!-- <template v-slot:cell(createdAt)="data">
              {{ dateConvert(data.item.createdAt, "fullDate") }}
            </template> -->
                    <template v-slot:cell(createdAt)="data">
                        <div class="dateCell">
                            {{ dateConvert(data.item.createdAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(remark)="data">
                        {{ data.item.remark }}
                    </template>
                    <template v-slot:cell(followupDate)="data">
                        {{ dateConvert(data.item.followupDate, "fullDate") }}
                    </template>
                </b-table>
            </div>
        </b-modal>
        <!--popup end for View Call details and Followups details-->

        <!--popup start for View Enquiry details Followups details -->
        <!-- The modal -->
        <b-modal id="viewEnquiryDetail" title="View Details" no-close-on-backdrop hide-footer>
            <div class="form-control">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input type="text" v-model="view.name" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact</label>
                        <b-form-input v-model="view.contact" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Alternative Contact</label>
                        <b-form-input v-model="view.alternativeContact" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Age</label>
                        <b-form-input v-model="view.age" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Qualification</label>
                        <b-form-input v-model="view.qualification" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Course</label>
                        <b-form-input v-model="view.course" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Source</label>
                        <b-form-input v-model="view.source" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Mode</label>
                        <b-form-input v-model="view.mode" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-form-input v-model="view.branch" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupFields">
                    <!-- <template v-slot:cell(createdAt)="data">
              {{ dateConvert(data.item.createdAt, "fullDate") }}
            </template> -->
                    <template v-slot:cell(createdAt)="data">
                        <div class="dateCell">
                            {{ dateConvert(data.item.createdAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(remark)="data">
                        {{ data.item.remark }}
                    </template>
                    <template v-slot:cell(followupDate)="data">
                        {{ dateConvert(data.item.followupDate, "fullDate") }}
                    </template>
                </b-table>
            </div>
        </b-modal>
        <!--popup end for View Enquiry details and Followups details-->

        <!-- popup start for next follow add -->
        <b-modal id="followInsert" title="Followup Update" ref="modal" no-close-on-backdrop hide-footer>
            <!-- <h5 class="px-5">Name : {{ update.name }}</h5>
          <h5 class="px-5">Contact : {{ update.contact }}</h5> -->
            <div class="form-row mt-3 px-5">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Followup Date and Remark</label>
                        <div class="py-2">
                            <b-form-input format="DD-MM-YYYY h:i:s" v-model="followup.followupDate" :class="{
                    'is-invalid': $v.followup.followupDate.$error,
                    'is-valid': !$v.followup.followupDate.$invalid,
                  }" type="datetime-local" min="new Time()"></b-form-input>
                            <div class="valid-feedback">date time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.followup.followupDate.required">date or time is invalid!</span>
                            </div>
                        </div>
                        <div>
                            <b-form-textarea class="form-control" v-model="followup.remark" :class="{
                    'is-invalid': $v.followup.remark.$error,
                    'is-valid': !$v.followup.remark.$invalid,
                  }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                            <div class="valid-feedback">remark is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.followup.remark.required">remark is invalid!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-3 mt-2">
                <b-button type="submit" @click="followupUpdate()" class="px-3" variant="primary" no-close-on-backdrop :disabled="disabledButton">Update</b-button>
            </div>
        </b-modal>
        <!-- popup end for next follow add -->

        <!-- popup start for next Enquiry follow add -->
        <b-modal id="enquiryFollowInsert" title="Enquiry Followup Update" ref="modal" no-close-on-backdrop hide-footer>
            <!-- <h5 class="px-5">Name : {{ update.name }}</h5>
          <h5 class="px-5">Contact : {{ update.contact }}</h5> -->
            <div class="form-row mt-3 px-5">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Followup Date and Remark</label>
                        <div class="py-2">
                            <b-form-input format="DD-MM-YYYY h:i:s" v-model="followup.followupDate" :class="{
                    'is-invalid': $v.followup.followupDate.$error,
                    'is-valid': !$v.followup.followupDate.$invalid,
                  }" type="datetime-local" min="new Time()"></b-form-input>
                            <div class="valid-feedback">date time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.followup.followupDate.required">date or time is invalid!</span>
                            </div>
                        </div>
                        <div>
                            <b-form-textarea class="form-control" v-model="followup.remark" :class="{
                    'is-invalid': $v.followup.remark.$error,
                    'is-valid': !$v.followup.remark.$invalid,
                  }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                            <div class="valid-feedback">remark is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.followup.remark.required">remark is invalid!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-3 mt-2">
                <b-button type="submit" @click="enquiryFollowupUpdate()" class="px-3" variant="primary" no-close-on-backdrop :disabled="disabledButton">Update</b-button>
            </div>
        </b-modal>
        <!-- popup end for next enquiry follow add -->

        <!-- popup start for closed -->
        <b-modal id="closed" title="Call Closed" ref="modal" no-close-on-backdrop hide-footer>
            <label class="mt-3">Closed To</label>
            <b-form-select class="form-control" v-model="closed" :options="closedOption" :class="{
            'is-invalid': $v.closed.$error,
            'is-valid': !$v.closed.$invalid,
          }"></b-form-select>

            <!-- closed for follow insert -->

            <div v-if="closed === 'enquiry'" class="px-2">
                <h5 class="px-5">Name : {{ view.name }}</h5>
                <h5 class="px-5">Contact : {{ view.contact }}</h5>
                <div class="form-row mt-3 px-5">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Followup</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="enquiryAs.followupDate" :class="{
                      'is-invalid': $v.enquiryAs.followupDate.$error,
                      'is-valid': !$v.enquiryAs.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.enquiryAs.followupDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                            <div>
                                <b-form-textarea class="form-control" v-model="enquiryAs.remark" :class="{
                      'is-invalid': $v.enquiryAs.remark.$error,
                      'is-valid': !$v.enquiryAs.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                <div class="valid-feedback">remark is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.enquiryAs.remark.required">remark is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- closed for follow insert end -->

            <div class="mt-3 mt-2 text-center">
                <b-button type="submit" @click="closedTo()" class="px-3" variant="primary" :disabled="disabledButton">closed</b-button>
            </div>
        </b-modal>
        <!-- popup end for closed -->

        <!-- popup start for enquiry closed -->
        <b-modal id="enquiryClosed" title="Enquiry Closed" ref="modal" no-close-on-backdrop hide-footer>
            <label class="mt-3">Closed To</label>
            <b-form-select class="form-control" v-model="enquiryClosed" :options="enquiryClosedOption" :class="{
            'is-invalid': $v.enquiryClosed.$error,
            'is-valid': !$v.enquiryClosed.$invalid,
          }"></b-form-select>
            <div class="mt-3 mt-2 text-center">
                <b-button type="submit" @click="enquiryClosedTo()" class="px-3" variant="primary" :disabled="disabledButton">closed</b-button>
            </div>
        </b-modal>
        <!-- popup end for closed -->

    </b-container>
</div>
</template>

<script>
import Account from '../service/Account.js';
import AgentService from "../service/AgentService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import axios from "axios";
import moment from "moment-timezone";
import AdminFollowupService from "../service/AdminFollowupService.js";
import FollowupService from "../service/FollowupService";
import CallService from "../service/CallService";
import EnquiryService from "../service/EnquiryService";
import {
    required,
    minLength
} from "vuelidate/lib/validators";

export default {
    name: "AdminFollowups",
    components: {},
    data() {
        return {
            moment: "",
            selectedFollowup: null,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            totalCount: 0,
            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            keyword: "",
            //toggle button active
            followupsActive: true,
            expiredFollowupsActive: false,
            enquiryFollowupsActive: false,
            //id set as Object for send URL to the API
            data: "",
            expiredDate: false,
            //for followups switch page pagintion method call as condition check
            paginationFollowups: false,
            searchPagination: false,
            bookmarkPagination: false,
            sortCoursePaginationFollowups: false,
            sortSourcePaginationFollowups: false,
            sortBranchPaginationFollowups: false,
            sortAgentPaginationFollowups: false,
            ///Enquiry followups
            currentEnquiryPage: 1,
            pageEnquiryCount: 5,
            enquiryPageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            totalEnquiryCount: 0,
            enquiryKeyword: "",
            //enquiry followups
            enquiryFollowups: [],
            //for enquiry followups switch page pagintion method call as condition check
            paginationEnquiryFollowups: false,
            SearchEnquiryPagination: false,
            enquirySortCoursePaginationFollowups: false,
            enquirySortSourcePaginationFollowups: false,
            enquirySortBranchPaginationFollowups: false,
            enquirySortAgentPaginationFollowups: false,
            ///expired followups
            expiredCurrentPage: 1,
            expiredPageCount: 5,
            expiredPageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            expiredTotalCount: 0,
            expiredKeyword: "",
            //for expired followups switch page pagintion method call as condition check
            expiredPaginationFollowups: false,
            expiredSearchPagination: false,
            expiredSortCoursePaginationFollowups: false,
            expiredSortSourcePaginationFollowups: false,
            expiredSortBranchPaginationFollowups: false,
            expiredSortAgentPaginationFollowups: false,
            //toggle to show table
            // isFollowups: true,
            // isExpiredFollowups: false,
            // isEnquiryFollowups: false,
            followupItems: [{
                    key: "followupDate",
                    label: "Followup_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "agent",
                    label: "Agent Name"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "remark",
                    label: "Remark"
                },
                {
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "type",
                    label: "Type"
                },
                {
                    key: "action",
                    label: "Action",
                    tdClass: "cellSize"
                },
            ],
            closed: null,
            closedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "enquiry",
                    text: "Enquiry",
                },
                {
                    value: "admission",
                    text: "Admission",
                },
                {
                    value: "notintrested",
                    text: "Notintrested",
                },
            ],
            enquiryClosed: null,
            enquiryClosedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "admission",
                    text: "Admission",
                },
                {
                    value: "notintrested",
                    text: "Notintrested",
                },
            ],
            agentId: "",
            // sort selected
            selectedCourse: "Courses",
            selectedSource: "Sources",
            selectedBranch: "Branches",
            selectedAgent: "Agents",
            selectedDates: null,
            selectedEnquiryCourse: "Courses",
            selectedEnquirySource: "Sources",
            selectedEnquiryBranch: "Branches",
            selectedEnquiryAgent: "Agents",

            // selectedAgent: "agents",
            // selectedCourse: "courses",
            // selectedSource: "sources",
            // selectedBranch: "branches",
            selectedExpiredAgent: "Agents",
            selectedExpiredCourse: "Courses",
            selectedExpiredSource: "Sources",
            selectedExpiredBranch: "Branches",
            selectedExpiredDates: null,
            dateRange: [{
                    value: null,
                    text: "Date",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Last Week"
                },
                {
                    value: "lastMonth",
                    text: "Last Month"
                },
            ],
            //dropdown render
            // allAgents: [],
            // allCourses: [],
            // allSources: [],
            // allBranches: [],

            // allAgents: "",
            // allCourses: "",
            // allSources: "",
            // allBranches: "",
            //view model data render
            view: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followups: null,
            },
            update: {
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            enquiryAs: {
                followupDate: "",
                remark: "",
            },
            followupDateRemark: [],
            followups: [],
            followupFields: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },

            ],
            followup: {
                followupDate: "",
                remark: "",
            },
            submitStatus: "",
            //submit after disabled
            disabledButton: false,
            //expired followups
            expiredFollowups: [],
        };
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllFollowups();
                // this.getCount();
                this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
            }
        },
        enquiryKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllEnquiryFollowups();
                // this.getEnquiryCount();
                this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null);
            }
        },
        expiredKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getAllExpiredFollowups();
                // this.getExpiredCount();
                this.getAllSortExpiredCount('Agents', 'Courses', 'Sources', 'Branches', null);
            }
        },
    },
    validations: {
        enquiryAs: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        closed: {
            required,
        },
        enquiryClosed: {
            required,
        },
        followup: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    // this.getAllFollowups();
                    // this.getCount();
                    // this.getExpiredCount();
                    // this.getEnquiryCount();
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                    // this.loading = false;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBookmarkSortCount: function (selectedAgent, selectedCourse, selectedSource, selectedBranch, selectedDates) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agents" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Courses" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Sources" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branches" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortFollowups = false;
                this.paginationFollowups = false;
                this.searchPagination = false;
                this.bookmarkPagination = true;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminFollowups/followup/bookmark/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllBookmarkSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllBookmarkSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/adminFollowups/followup/bookmark/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agents" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Courses" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Sources" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branches" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortFollowups = true;
                this.paginationFollowups = false;
                this.searchPagination = false;
                this.bookmarkPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminFollowups/followup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminFollowups/followup/all/sort/" +
                        currentPage +
                        "/" +
                        pageCount,
                        data
                    )
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortEnquiryCount: function (
            selectedEnquiryAgent,
            selectedEnquiryCourse,
            selectedEnquirySource,
            selectedEnquiryBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedEnquiryAgent == "Agents" || selectedEnquiryAgent == "AllAgents") {
                    selectedEnquiryAgent = {
                        agentId: "",
                    };
                }
                if (selectedEnquiryCourse == "Courses" || selectedEnquiryCourse == "AllCourses") {
                    selectedEnquiryCourse = {
                        courseId: "",
                    };
                }
                if (selectedEnquirySource == "Sources" || selectedEnquirySource == "AllSources") {
                    selectedEnquirySource = {
                        sourceId: "",
                    };
                }
                if (selectedEnquiryBranch == "Branches" || selectedEnquiryBranch == "AllBranches") {
                    selectedEnquiryBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedEnquiryAgent.agentId,
                    courseId: selectedEnquiryCourse.courseId,
                    sourceId: selectedEnquirySource.sourceId,
                    branchId: selectedEnquiryBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortEnquiryFollowups = true;
                this.paginationEnquiryFollowups = false;
                this.searchEnquiryPagination = false;
                this.currentEnquiryPage = 1;
                this.enquiryKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminFollowups/enquiry/followup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortEnquiryCount======", response.data);
                            this.totalEnquiryCount = response.data;
                            this.getAllSortEnquiryPageable(
                                this.currentEnquiryPage,
                                this.pageEnquiryCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortEnquiryPageable: function (currentEnquiryPage, pageEnquiryCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminFollowups/enquiry/followup/all/sort/" +
                        currentEnquiryPage +
                        "/" +
                        pageEnquiryCount,
                        data
                    )
                    .then((response) => {
                        this.enquiryFollowups = response.data;
                        // console.log("getAllSortEnquiryPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // async methodCalling() {
        //   await this.getAllFollowups();
        //   await this.getAllAgents();
        //   await this.getAllCourses();
        //   await this.getAllSources();
        //   await this.getAllBranches();
        // },
        dateConvert: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D-MM-YYYY hh:mm a");
            }
        },
        dateConvertAndExpiredValidation: function (date) {
            console.log("==========date=========", new Date(date).setHours(0, 0, 0, 0));
            console.log("==========new date=========", new Date().setHours(0, 0, 0, 0));
            if (new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                return true;
            } else {
                return false;
            }
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                // AgentService.getAllCounsellorAgents()
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getAllFollowups: function () {
        //   this.loading = true;
        //   this.expiredDate = false;
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.getAllFollowups()
        //       .then((response) => {
        //         this.followups = response.data;
        //         // console.log("followups", this.followups);
        //         this.getCount();
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        // getCount: function () {
        //     this.loading = true;
        //     this.expiredDate = false;
        //     this.allSortFollowups = false;
        //     this.paginationFollowups = true;
        //     this.searchPagination = false;
        //     this.selectedAgent = "Agents";
        //     this.selectedCourse = "Courses";
        //     this.selectedSource = "Sources";
        //     this.selectedBranch = "Branches";
        //     this.keyword = "";
        //     this.selectedDates = null;
        //     this.currentPage = 1;
        //     // this.loading = true;
        //     // //for followups search expired followups mark as red color
        //     // this.expiredDate = false;
        //     // //for pagintion switch conditon check
        //     // this.paginationFollowups = true;
        //     // this.searchPagination = false;
        //     // this.sortCoursePaginationFollowups = false;
        //     // this.sortSourcePaginationFollowups = false;
        //     // this.sortBranchPaginationFollowups = false;
        //     // this.sortAgentPaginationFollowups = false;
        //     // this.currentPage = 1;
        //     return new Promise((resolve, reject) => {
        //         AdminFollowupService.getCount()
        //             .then((response) => {
        //                 this.totalCount = response.data;
        //                 this.getAllPageable(this.currentPage, this.pageCount);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        // getEnquiryCount: function () {
        //     this.loading = true;
        //     this.expiredDate = false;
        //     this.allSortEnquiryFollowups = false;
        //     this.paginationEnquiryFollowups = true;
        //     this.searchEnquiryPagination = false;
        //     this.selectedEnquiryAgent = "Agents";
        //     this.selectedEnquiryCourse = "Courses";
        //     this.selectedEnquirySource = "Sources";
        //     this.selectedEnquiryBranch = "Branches";
        //     this.enquiryKeyword = "";
        //     this.selectedEnquiryDates = null;
        //     this.currentEnquiryPage = 1;
        //     return new Promise((resolve, reject) => {
        //         AdminFollowupService.getEnquiryCount()
        //             .then((response) => {
        //                 this.totalEnquiryCount = response.data;
        //                 // console.log("totalEnquiryCount" , response.data);
        //                 this.getAllEnquiryPageable(this.currentEnquiryPage, this.pageEnquiryCount);
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationFollowups) {
                // console.log(this.paginationFollowups, "paginationFollowups");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortFollowups) {
                // console.log(this.allSortFollowups, "allSortFollowups");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            } else if (this.bookmarkPagination) {
                this.getAllBookmarkSortPageable(currentPage, pageCount, this.data)
            }
            // if (this.paginationFollowups) {
            //   this.getAllPageable(currentPage, pageCount);
            // } else if (this.searchPagination) {
            //   this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            // } else if (this.sortCoursePaginationFollowups) {
            //   this.sortCourseAllPageable(currentPage, pageCount, this.data);
            // } else if (this.sortSourcePaginationFollowups) {
            //   this.sortSourceAllPageable(currentPage, pageCount, this.data);
            // } else if (this.sortBranchPaginationFollowups) {
            //   this.sortBranchAllPageable(currentPage, pageCount, this.data);
            // } else if (this.sortAgentPaginationFollowups) {
            //   this.sortAgentFollowupsAllPageable(currentPage, pageCount, this.data);
            // }
        },

        switchEnquiryPage: function (currentEnquiryPage, pageEnquiryCount) {
            this.loading = true;
            // console.log("switchEnquiryPage calling");
            if (this.paginationEnquiryFollowups) {
                // console.log(this.paginationEnquiryFollowups, "paginationEnquiryFollowups");
                this.getAllEnquiryPageable(currentEnquiryPage, pageEnquiryCount);
            } else if (this.allSortEnquiryFollowups) {
                // console.log(this.allSortEnquiryFollowups, "allSortEnquiryFollowups");
                this.getAllSortEnquiryPageable(currentEnquiryPage, pageEnquiryCount, this.data);
            } else if (this.searchEnquiryPagination) {
                this.getAllEnquirySearchPageable(
                    currentEnquiryPage,
                    pageEnquiryCount,
                    this.enquiryKeyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
        },

        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllPageable(currentPage, pageCount)
                    .then((response) => {
                        this.followups = response.data;
                        // console.log("getAllPageable", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllEnquiryPageable: function (currentEnquiryPage, pageEnquiryCount) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllEnquiryPageable(currentEnquiryPage, pageEnquiryCount)
                    .then((response) => {
                        this.enquiryFollowups = response.data;
                        // console.log("getAllEnquiryPageable", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // search: function (keyword) {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.search(keyword.toLowerCase())
        //       .then((response) => {
        //         this.followups = response.data;
        //         this.followups.forEach((e) => {
        //           const followupDate = new Date(e.followupDate);
        //           // console.log("followupDate", followupDate);
        //           const today = new Date();
        //           const zeroStart = new Date(today.setHours(0, 0, 0, 0));
        //           // console.log("zeroStart", zeroStart);
        //           if (followupDate > zeroStart) {
        //             this.expiredDate = false;
        //             this.loading = false;
        //             // console.log("followupDate is greater then today");
        //           } else if (followupDate < zeroStart) {
        //             this.expiredDate = true;
        //             this.loading = false;
        //             // console.log("followupDate is lesser then today");
        //           } else {
        //             this.loading = false;
        //             // console.log("no compare");
        //           }
        //         });
        //         this.getSearchCount(keyword.toLowerCase());
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getSearchCount: function (keyword) {
            this.loading = true;
            this.searchPagination = true;
            this.paginationFollowups = false;
            this.allSortFollowups = false;
            this.bookmarkPagination = false;
            this.currentPage = 1;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgent = "Agents";
            this.selectedDates = null;
            //for pagintion switch conditon check
            // this.paginationFollowups = false;
            // this.searchPagination = true;
            // this.sortCoursePaginationFollowups = false;
            // this.sortSourcePaginationFollowups = false;
            // this.sortBranchPaginationFollowups = false;
            // this.sortAgentPaginationFollowups = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdminFollowupService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("getSearch count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        // console.log("getAllSearchPageable", response.data);
                        this.followups = response.data;
                        // console.log("getAllSearchPageable", response.data);
                        this.followups.forEach((e) => {
                            const followupDate = new Date(e.followupDate);
                            // console.log("followupDate", followupDate);
                            const today = new Date();
                            const zeroStart = new Date(today.setHours(0, 0, 0, 0));
                            // console.log("zeroStart", zeroStart);
                            if (followupDate > zeroStart) {
                                this.expiredDate = false;
                                this.loading = false;
                                // console.log("followupDate is greater then today");
                            } else if (followupDate < zeroStart) {
                                this.expiredDate = true;
                                this.loading = false;
                                // console.log("followupDate is lesser then today");
                            } else {
                                this.loading = false;
                                // console.log("no compare");
                            }
                        });
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getEnquirySearchCount: function (enquiryKeyword) {
            this.loading = true;
            this.searchEnquiryPagination = true;
            this.paginationEnquiryFollowups = false;
            this.allSortEnquiryFollowups = false;
            this.currentEnquiryPage = 1;
            this.selectedEnquiryCourse = "Courses";
            this.selectedEnquirySource = "Sources";
            this.selectedEnquiryBranch = "Branches";
            this.selectedEnquiryAgent = "Agents";
            this.selectedEnquiryDates = null;
            return new Promise((resolve, reject) => {
                AdminFollowupService.getSearchEnquiryCount(enquiryKeyword.toLowerCase())
                    .then((response) => {
                        this.totalEnquiryCount = response.data;
                        // console.log("getEnquirySearch count", response.data);
                        this.getAllEnquirySearchPageable(
                            this.currentEnquiryPage,
                            this.pageEnquiryCount,
                            this.enquiryKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllEnquirySearchPageable: function (currentEnquiryPage, pageEnquiryCount, enquiryKeyword) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllEnquirySearchPageable(
                        currentEnquiryPage,
                        pageEnquiryCount,
                        enquiryKeyword.toLowerCase()
                    )
                    .then((response) => {
                        // console.log("getAllEnquirySearchPageable", response.data);
                        this.enquiryFollowups = response.data;
                        // console.log("getAllEnquirySearchPageable", response.data);
                        this.followups.forEach((e) => {
                            const followupDate = new Date(e.followupDate);
                            // console.log("followupDate", followupDate);
                            const today = new Date();
                            const zeroStart = new Date(today.setHours(0, 0, 0, 0));
                            // console.log("zeroStart", zeroStart);
                            if (followupDate > zeroStart) {
                                this.expiredDate = false;
                                this.loading = false;
                                // console.log("followupDate is greater then today");
                            } else if (followupDate < zeroStart) {
                                this.expiredDate = true;
                                this.loading = false;
                                // console.log("followupDate is lesser then today");
                            } else {
                                this.loading = false;
                                // console.log("no compare");
                            }
                        });
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        // sortCourse: function (selectedCourse) {
        //   this.selectedSource = "sources";
        //   this.selectedBranch = "branches";
        //   this.selectedAgent = "agents";
        //   if (selectedCourse == "allCourses") {
        //     this.getAllFollowups();
        //   } else {
        //     let data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortCourse(data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           this.loading = false;
        //           this.getSortCourseCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSortCourseCount: function (selectedCourse) {
        //   this.selectedSource = "sources";
        //   this.selectedBranch = "branches";
        //   this.selectedAgent = "agents";
        //   this.keyword = "";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = true;
        //   this.sortSourcePaginationFollowups = false;
        //   this.sortBranchPaginationFollowups = false;
        //   this.sortAgentPaginationFollowups = false;
        //   this.currentPage = 1;
        //   if (selectedCourse == "allCourses") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getSortCourseCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           // console.log("getSortCourseCount",this.totalCount);
        //           this.sortCourseAllPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // sortCourseAllPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.sortCourseAllPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         // console.log("sortCourseAllPageable",response.data);
        //         this.followups = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sortSource: function (selectedSource) {
        //   this.selectedCourse = "courses";
        //   this.selectedBranch = "branches";
        //   this.selectedAgent = "agents";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = false;
        //   this.sortSourcePaginationFollowups = true;
        //   this.sortBranchPaginationFollowups = false;
        //   this.sortAgentPaginationFollowups = false;
        //   if (selectedSource == "allSources") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortSource(this.data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           this.loading = false;
        //           this.getSortSourceCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSortSourceCount: function (selectedSource) {
        //   this.selectedCourse = "courses";
        //   this.selectedBranch = "branches";
        //   this.selectedAgent = "agents";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = false;
        //   this.sortSourcePaginationFollowups = true;
        //   this.sortBranchPaginationFollowups = false;
        //   this.sortAgentPaginationFollowups = false;
        //   this.currentPage = 1;
        //   if (selectedSource == "allSources") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getSortSourceCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           // console.log("getSortSourceCount",this.totalCount);
        //           this.sortSourceAllPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // sortSourceAllPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.sortSourceAllPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         resolve(response);
        //         this.followups = response.data;
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sortBranch: function (selectedBranch) {
        //   this.selectedCourse = "courses";
        //   this.selectedSource = "sources";
        //   this.selectedAgent = "agents";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = false;
        //   this.sortSourcePaginationFollowups = false;
        //   this.sortBranchPaginationFollowups = true;
        //   this.sortAgentPaginationFollowups = false;
        //   if (selectedBranch == "allBranches") {
        //     this.getCount();
        //   } else {
        //     let data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortBranch(data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           this.getSortBranchCount(data);
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSortBranchCount: function (selectedBranch) {
        //   this.selectedCourse = "courses";
        //   this.selectedSource = "sources";
        //   this.selectedAgent = "agents";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = false;
        //   this.sortSourcePaginationFollowups = false;
        //   this.sortBranchPaginationFollowups = true;
        //   this.sortAgentPaginationFollowups = false;
        //   this.currentPage = 1;
        //   if (selectedBranch == "allBranches") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getSortBranchCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortBranchAllPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // sortBranchAllPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.sortBranchAllPageable(currentPage, pageCount, data)
        //       .then((response) => {
        //         this.followups = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sortAgent: function (selectedAgent) {
        //   this.selectedCourse = "courses";
        //   this.selectedSource = "sources";
        //   this.selectedBranch = "branches";
        //   if (selectedAgent == "allAgents") {
        //     this.getAllFollowups();
        //   } else {
        //     let data = {
        //       agentId: selectedAgent.agentId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortAgent(data)
        //         .then((response) => {
        //           this.followups = response.data;
        //           this.loading = false;
        //           this.getSortAgentFollowupsCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getSortAgentFollowupsCount: function (selectedAgent) {
        //   this.selectedCourse = "courses";
        //   this.selectedSource = "sources";
        //   this.selectedBranch = "branches";
        //   this.loading = true;
        //   //for pagintion switch conditon check
        //   this.paginationFollowups = false;
        //   this.searchPagination = false;
        //   this.sortCoursePaginationFollowups = false;
        //   this.sortSourcePaginationFollowups = false;
        //   this.sortBranchPaginationFollowups = false;
        //   this.sortAgentPaginationFollowups = true;
        //   this.currentPage = 1;
        //   if (selectedAgent == "allAgents") {
        //     this.getCount();
        //   } else {
        //     this.data = {
        //       agentId: selectedAgent.agentId,
        //     };
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getSortAgentFollowupsCount(this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.sortAgentFollowupsAllPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // sortAgentFollowupsAllPageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.sortAgentFollowupsAllPageable(
        //       currentPage,
        //       pageCount,
        //       data
        //     )
        //       .then((response) => {
        //         this.followups = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        getFollowup: function (data) {
            this.resetUpdateForm();
            this.resetFollowupUpdate();
            this.updateFor = data;
            // console.log("agentId",this.updateFor.agent.agentId);
            // console.log("updateFor",this.updateFor);
            this.getData();
        },
        getData: function () {
            // console.log(this.updateFor);
            if (this.updateFor.type == "call") {
                return new Promise((resolve, reject) => {
                    // FollowupService.getFollowupDateRemark(this.updateFor.id)
                    FollowupService.getFollowupCreatedDate(this.updateFor.id)
                        .then((response) => {
                            // console.log("getData", response.data);
                            this.view = {
                                name: this.updateFor.name,
                                contact: this.updateFor.contact,
                                alternativeContact: this.updateFor.alternativeContact,
                                age: this.updateFor.age,
                                qualification: this.updateFor.qualification,
                                course: this.updateFor.course.name,
                                source: this.updateFor.source.name,
                                mode: this.updateFor.mode,
                                branch: this.updateFor.branch.name,
                                createdAt: this.update.createdAt
                            };
                            this.followupDateRemark = response.data;
                            // console.log("followupDateRemark", response.data);
                            // console.log(this.view);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            if (this.updateFor.type == "enquiry") {
                // console.log("Enquiry type");
                return new Promise((resolve, reject) => {
                    // FollowupService.getEnquiryFollowupDateRemark(this.updateFor.id)
                    FollowupService.getEnquiryFollowupCreatedDateRemark(this.updateFor.id)
                        .then((response) => {
                            this.view = {
                                name: this.updateFor.name,
                                contact: this.updateFor.contact,
                                alternativeContact: this.updateFor.alternativeContact,
                                age: this.updateFor.age,
                                qualification: this.updateFor.qualification,
                                course: this.updateFor.course.name,
                                source: this.updateFor.source.name,
                                mode: this.updateFor.mode,
                                branch: this.updateFor.branch.name,
                            };
                            this.followupDateRemark = response.data;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        followupUpdate: function () {
            this.$v.followup.$touch();
            if (this.$v.followup.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select next followup Date and time",
                });
            } else {
                if (this.updateFor.type == "call") {
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        CallService.callFollowupUpdate(
                                this.updateFor.agent.agentId,
                                this.updateFor.id,
                                this.followup
                            )
                            .then((response) => {
                                this.$swal(response.data);
                                this.$refs.modal.hide();
                                // this.resetFollowupUpdate();
                                // this.getAllFollowups();
                                // this.getCount();
                                this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                // this.getEnquiryCount();
                                this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                // this.getAllExpiredFollowups();
                                this.getExpiredCount();
                                this.loading = false;
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                } else {
                    this.$swal("error");
                }
                // else if (this.updateFor.type == "enquiry") {
                //   this.disabledButton = true;
                //   return new Promise((resolve, reject) => {
                //     EnquiryService.enquiryFollowupUpdate(
                //       this.updateFor.agent.agentId,
                //       this.updateFor.id,
                //       this.followup
                //     )
                //       .then((response) => {
                //         this.$refs.modal.hide();
                //         this.$swal(response.data);
                //         this.getAllFollowups();
                //         this.getAllExpiredFollowups();
                //         this.loading = false;
                //         resolve(response);
                //         this.disabledButton = false;
                //       })
                //       .catch((err) => {
                //         this.loading = false;
                //         reject(err);
                //       });
                //   });
                // }
            }
        },

        enquiryFollowupUpdate: function () {
            this.$v.followup.$touch();
            if (this.$v.followup.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select next followup Date and time",
                });
            } else {
                // if (this.updateFor.type == "call") {
                //     this.disabledButton = true;
                //     return new Promise((resolve, reject) => {
                //         CallService.callFollowupUpdate(
                //                 this.updateFor.agent.agentId,
                //                 this.updateFor.id,
                //                 this.followup
                //             )
                //             .then((response) => {
                //                 this.$swal(response.data);
                //                 this.$refs.modal.hide();
                //                 // this.resetFollowupUpdate();
                //                 // this.getAllFollowups();
                //                 this.getCount();
                //                 // this.getAllExpiredFollowups();
                //                 this.getExpiredCount();
                //                 this.loading = false;
                //                 resolve(response);
                //                 this.disabledButton = false;
                //             })
                //             .catch((err) => {
                //                 this.loading = false;
                //                 reject(err);
                //             });
                //     });
                // }
                if (this.updateFor.type == "enquiry") {
                    this.disabledButton = true;
                    return new Promise((resolve, reject) => {
                        EnquiryService.enquiryFollowupUpdate(
                                this.updateFor.agent.agentId,
                                this.updateFor.id,
                                this.followup
                            )
                            .then((response) => {
                                this.$refs.modal.hide();
                                this.$swal(response.data);
                                // this.getEnquiryCount();
                                this.getExpiredCount();
                                // this.getCount();
                                this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                // this.getAllFollowups();
                                // this.getAllExpiredFollowups();
                                this.loading = false;
                                resolve(response);
                                this.disabledButton = false;
                            })
                            .catch((err) => {
                                this.loading = false;
                                reject(err);
                            });
                    });
                } else {
                    this.$swal("error");
                }
            }
        },
        resetUpdateForm: function () {
            this.followup = {
                followupDate: null,
                remark: null,
            };
            this.closed = null;
            this.$nextTick(() => {
                this.$v.followup.$reset();
                this.$v.closed.$reset();
                // this.$v.followup.errors.$clear();
            });
        },
        resetFollowupUpdate: function () {

            this.closed = null;
            this.$nextTick(() => {
                this.enquiryAs = {
                    remark: "",
                    followupDate: "",
                };
                // this.$v.closed.$reset();
            });

            // this.followup = {
            //   followupDate: null,
            //   remark: null,
            // };
            // this.closed = null;
            // this.$nextTick(() => {
            //   this.$v.followup.$reset();
            //   this.$v.closed.$reset();
            //   // this.$v.followup.errors.$clear();
            // });
        },
        closedTo: function () {
            if (this.updateFor.type == "call") {
                this.$v.closed.$touch();
                if (this.$v.closed.$invalid) {
                    this.submitStatus = "FAIL";
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "select an enquiry or admission or notintrested",
                    });
                } else {
                    // if (this.updateFor.type == "call"){
                    var closedDatas1 = {
                        callId: this.updateFor.id,
                        // enquiryId: this.updateFor.id,
                        name: this.updateFor.name,
                        contact: this.updateFor.contact,
                        alternativeContact: this.updateFor.alternativeContact,
                        age: this.updateFor.age,
                        qualification: this.updateFor.qualification,
                        course: this.updateFor.course,
                        source: this.updateFor.source,
                        mode: this.updateFor.mode,
                        branch: this.updateFor.branch,
                        followupDate: this.enquiryAs.followupDate,
                        remark: this.enquiryAs.remark,
                    };
                    // }
                    // else if(this.updateFor.type == "enquiry") {
                    // var closedDatas2 = {
                    //   // callId: this.updateFor.id,
                    //   enquiryId: this.updateFor.id,
                    //   name: this.updateFor.name,
                    //   contact: this.updateFor.contact,
                    //   alternativeContact: this.updateFor.alternativeContact,
                    //   age: this.updateFor.age,
                    //   qualification: this.updateFor.qualification,
                    //   course: this.updateFor.course,
                    //   source: this.updateFor.source,
                    //   mode: this.updateFor.mode,
                    //   branch: this.updateFor.branch,
                    // };
                    // }
                    if (this.closed == "enquiry") {
                        // if (this.$v.enquiryAs.$invalid) {
                        //   this.submitStatus = "FAIL";
                        //   this.$swal("Select for next Enquiry followupdate");
                        //   this.disabledButton = false;
                        // } else {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/enquiry/insert/" + this.updateFor.agent.agentId,
                                    closedDatas1
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.closed = "";
                                    // this.resetFollowup();
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    // this.getEnquiryCount();
                                    this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                        // }
                    } else if (this.closed == "admission") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/admission/insert/" + this.updateFor.agent.agentId,
                                    closedDatas1
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    // this.getEnquiryCount();
                                    this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    } else if (this.closed == "notintrested") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/notintrested/insert/" + this.updateFor.agent.agentId,
                                    closedDatas1
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    // this.getEnquiryCount();
                                    this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                }
            }

            if (this.updateFor.type == "enquiry") {
                this.$v.closed.$touch();
                if (this.$v.closed.$invalid) {
                    this.submitStatus = "FAIL";
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "select an enquiry or admission or notintrested",
                    });
                } else {
                    // if (this.updateFor.type == "call"){
                    // console.log('updateFor======',this.updateFor)
                    var closedDatas2 = {
                        callId: this.updateFor.callId,
                        enquiryId: this.updateFor.id,
                        name: this.updateFor.name,
                        contact: this.updateFor.contact,
                        alternativeContact: this.updateFor.alternativeContact,
                        age: this.updateFor.age,
                        qualification: this.updateFor.qualification,
                        course: this.updateFor.course,
                        source: this.updateFor.source,
                        mode: this.updateFor.mode,
                        branch: this.updateFor.branch,
                    };
                    // }
                    // else if(this.updateFor.type == "enquiry") {
                    // var closedDatas2 = {
                    //   // callId: this.updateFor.id,
                    //   enquiryId: this.updateFor.id,
                    //   name: this.updateFor.name,
                    //   contact: this.updateFor.contact,
                    //   alternativeContact: this.updateFor.alternativeContact,
                    //   age: this.updateFor.age,
                    //   qualification: this.updateFor.qualification,
                    //   course: this.updateFor.course,
                    //   source: this.updateFor.source,
                    //   mode: this.updateFor.mode,
                    //   branch: this.updateFor.branch,
                    // };
                    // }
                    if (this.closed == "enquiry") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/enquiry/insert/" + this.updateFor.agent.agentId,
                                    closedDatas2
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    // this.resetFollowup();
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    } else if (this.closed == "admission") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/admission/insert/" + this.updateFor.agent.agentId,
                                    closedDatas2
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    } else if (this.closed == "notintrested") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/notintrested/insert/" + this.updateFor.agent.agentId,
                                    closedDatas2
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                }
            }
        },
        // Admin enquiry Closed Start ============ >>>>>>>>>>>

        enquiryClosedTo: function () {
            if (this.updateFor.type == "enquiry") {
                this.$v.enquiryClosed.$touch();
                if (this.$v.enquiryClosed.$invalid) {
                    this.submitStatus = "FAIL";
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "select admission or notintrested",
                    });
                } else {
                    // if (this.updateFor.type == "call"){
                    // console.log('updateFor======',this.updateFor)
                    var closedDatas2 = {
                        callId: this.updateFor.callId,
                        enquiryId: this.updateFor.id,
                        name: this.updateFor.name,
                        contact: this.updateFor.contact,
                        alternativeContact: this.updateFor.alternativeContact,
                        age: this.updateFor.age,
                        qualification: this.updateFor.qualification,
                        course: this.updateFor.course,
                        source: this.updateFor.source,
                        mode: this.updateFor.mode,
                        branch: this.updateFor.branch,
                    };

                    if (this.enquiryClosed == "admission") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/admission/insert/" + this.updateFor.agent.agentId,
                                    closedDatas2
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    this.getExpiredCount();
                                    // this.getEnquiryCount();
                                    this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    } else if (this.enquiryClosed == "notintrested") {
                        this.disabledButton = true;
                        return new Promise((resolve, reject) => {
                            axios
                                .post(
                                    "/notintrested/insert/" + this.updateFor.agent.agentId,
                                    closedDatas2
                                )
                                .then((response) => {
                                    this.$swal(response.data);
                                    this.$refs.modal.hide();
                                    // this.getAllFollowups();
                                    // this.getCount();
                                    this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null);
                                    // this.getEnquiryCount();
                                    this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
                                    // this.getAllExpiredFollowups();
                                    this.getExpiredCount();
                                    resolve(response);
                                    this.disabledButton = false;
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                }
            }
        },

        //=================================ADMIN CLOSED ENQUIRY========================================================

        getAllSortBookmarkExpiredCount: function (
            selectedExpiredAgent,
            selectedExpiredCourse,
            selectedExpiredSource,
            selectedExpiredBranch,
            selectedExpiredDates
        ) {
            // console.log("selectedExpiredAgent=== ", selectedExpiredAgent);
            // console.log("selectedExpiredCourse=== ", selectedExpiredCourse);
            // console.log("selectedExpiredSource=== ", selectedExpiredSource);
            // console.log("selectedExpiredBranch=== ", selectedExpiredBranch);
            // console.log("selectedExpiredDates=== ", selectedExpiredDates);

            if (selectedExpiredDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedExpiredDates = "dateBetween";
            } else {
                if (
                    selectedExpiredAgent == "Agents" ||
                    selectedExpiredAgent == "allAgents"
                ) {
                    selectedExpiredAgent = {
                        agentId: "",
                    };
                }
                if (
                    selectedExpiredCourse == "Courses" ||
                    selectedExpiredCourse == "allCourses"
                ) {
                    selectedExpiredCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedExpiredSource == "Sources" ||
                    selectedExpiredSource == "allSources"
                ) {
                    selectedExpiredSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedExpiredBranch == "Branches" ||
                    selectedExpiredBranch == "allBranches"
                ) {
                    selectedExpiredBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedExpiredDates != null) {
                    var date = new Date();
                    if (selectedExpiredDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedExpiredDates == null ||
                    selectedExpiredDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedExpiredAgent.agentId,
                    courseId: selectedExpiredCourse.courseId,
                    sourceId: selectedExpiredSource.sourceId,
                    branchId: selectedExpiredBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortFollowups = false;
                this.paginationFollowups = false;
                this.searchPagination = false;
                this.bookmarkPagination = true;
                this.expiredCurrentPage = 1;
                this.expiredKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminFollowups/expiredFollowup/bookmark/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortExpiredCount======", response.data);
                            this.expiredTotalCount = response.data;
                            this.getAllSortBookmarkExpiredPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortBookmarkExpiredPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/adminFollowups/expiredFollowup/bookmark/sort/pageable/" + expiredCurrentPage + "/" + expiredPageCount, data)
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        // console.log("getAllSortExpiredPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getAllSortExpiredCount: function (
            selectedExpiredAgent,
            selectedExpiredCourse,
            selectedExpiredSource,
            selectedExpiredBranch,
            selectedExpiredDates
        ) {
            // console.log("selectedExpiredAgent=== ", selectedExpiredAgent);
            // console.log("selectedExpiredCourse=== ", selectedExpiredCourse);
            // console.log("selectedExpiredSource=== ", selectedExpiredSource);
            // console.log("selectedExpiredBranch=== ", selectedExpiredBranch);
            // console.log("selectedExpiredDates=== ", selectedExpiredDates);

            if (selectedExpiredDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedExpiredDates = "dateBetween";
            } else {
                if (
                    selectedExpiredAgent == "Agents" ||
                    selectedExpiredAgent == "allAgents"
                ) {
                    selectedExpiredAgent = {
                        agentId: "",
                    };
                }
                if (
                    selectedExpiredCourse == "Courses" ||
                    selectedExpiredCourse == "allCourses"
                ) {
                    selectedExpiredCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedExpiredSource == "Sources" ||
                    selectedExpiredSource == "allSources"
                ) {
                    selectedExpiredSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedExpiredBranch == "Branches" ||
                    selectedExpiredBranch == "allBranches"
                ) {
                    selectedExpiredBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedExpiredDates != null) {
                    var date = new Date();
                    if (selectedExpiredDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedExpiredDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedExpiredDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedExpiredDates == null ||
                    selectedExpiredDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedExpiredAgent.agentId,
                    courseId: selectedExpiredCourse.courseId,
                    sourceId: selectedExpiredSource.sourceId,
                    branchId: selectedExpiredBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortFollowups = true;
                this.paginationFollowups = false;
                this.searchPagination = false;
                this.bookmarkPagination = false;
                this.expiredCurrentPage = 1;
                this.expiredKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminFollowups/expiredFollowup/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortExpiredCount======", response.data);
                            this.expiredTotalCount = response.data;
                            this.getAllSortExpiredPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortExpiredPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminFollowups/expiredFollowup/all/sort/" +
                        expiredCurrentPage +
                        "/" +
                        expiredPageCount,
                        data
                    )
                    .then((response) => {
                        this.expiredFollowups = response.data;
                        // console.log("getAllSortExpiredPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        //===================Admin Expired Followups==================
        // getAllExpiredFollowups: function () {
        //   this.loading = true;
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.getAllExpiredFollowups()
        //       .then((response) => {
        //         this.expiredFollowups = response.data;
        //         this.getExpiredCount();
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },
        getExpiredCount: function () {
            this.loading = true;
            this.paginationFollowups = true;
            this.searchPagination = false;
            this.allSortFollowups = false;
            this.bookmarkPagination = false;
            this.expiredCurrentPage = 1;
            this.selectedExpiredCourse = "courses";
            this.selectedExpiredSource = "sources";
            this.selectedExpiredBranch = "branches";
            this.selectedExpiredAgent = "agents";
            this.selectedExpiredDates = null;
            this.loading = true;
            // this.expiredPaginationFollowups = true;
            // this.expiredSearchPagination = false;
            // this.expiredSortCoursePaginationFollowups = false;
            // this.expiredSortSourcePaginationFollowups = false;
            // this.expiredSortBranchPaginationFollowups = false;
            // this.expiredSortAgentPaginationFollowups = false;
            // this.expiredCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminFollowupService.getExpiredCount()
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        // console.log("getExpiredCount", response.data);
                        this.getExpiredFollowupsPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchExpiredPage: function (expiredCurrentPage, expiredPageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationFollowups) {
                this.getExpiredFollowupsPageable(expiredCurrentPage, expiredPageCount);
                // console.log("this.paginationFollowups===", this.paginationFollowups);
                // console.log("this.allSortFollowups===", this.allSortFollowups);
            } else if (this.allSortFollowups) {
                this.getAllSortExpiredPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.data
                );
                // console.log("this.paginationFollowups===", this.paginationFollowups);
                // console.log("this.allSortFollowups===", this.allSortFollowups);
            } else if (this.searchPagination) {
                this.getAllExpiredSearchPageable(
                    expiredCurrentPage,
                    expiredPageCount,
                    this.expiredKeyword
                );
                // console.log(this.searchPagination, "getAllExpiredSearchPageable");
            } else if (this.bookmarkPagination) {
                this.getAllExpiredSearchPageable(expiredCurrentPage, expiredPageCount, this.data);
            }
            // this.loading = true;
            // if (this.expiredPaginationFollowups) {
            //   this.getExpiredFollowupsPageable(expiredCurrentPage, expiredPageCount);
            // } else if (this.expiredSearchPagination) {
            //   this.getAllExpiredSearchPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.expiredKeyword
            //   );
            // } else if (this.expiredSortCoursePaginationFollowups) {
            //   this.expiredSortCourseAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSortSourcePaginationFollowups) {
            //   this.expiredSortSourceAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSortBranchPaginationFollowups) {
            //   this.expiredSortBranchAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // } else if (this.expiredSortAgentPaginationFollowups) {
            //   this.sortAgentExpiredFollowupsAllPageable(
            //     expiredCurrentPage,
            //     expiredPageCount,
            //     this.data
            //   );
            // }
        },
        getExpiredFollowupsPageable: function (
            expiredCurrentPage,
            expiredPageCount
        ) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getExpiredFollowupsPageable(
                        expiredCurrentPage,
                        expiredPageCount
                    )
                    .then((response) => {
                        // console.log("getExpiredFollowupsPageable", response.data);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // expiredFollowupsSearch: function (expiredKeyword) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.expiredFollowupsSearch(
        //       expiredKeyword.toLowerCase()
        //     )
        //       .then((response) => {
        //         this.expiredFollowups = response.data;
        //         this.getExpiredSearchCount(expiredKeyword.toLowerCase());
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        getExpiredFollowupsSearchCount: function (expiredKeyword) {
            this.paginationFollowups = false;
            this.searchPagination = true;
            this.allSortFollowups = false;
            this.bookmarkPagination = false;
            this.expiredCurrentPage = 1;
            this.selectedExpiredCourse = "courses";
            this.selectedExpiredSource = "sources";
            this.selectedExpiredBranch = "branches";
            this.selectedExpiredAgent = "agents";
            this.selectedExpiredDates = null;
            this.loading = true;
            // this.loading = true;
            // this.expiredPaginationFollowups = false;
            // this.expiredSearchPagination = true;
            // this.expiredSortCoursePaginationFollowups = false;
            // this.expiredSortSourcePaginationFollowups = false;
            // this.expiredSortBranchPaginationFollowups = false;
            // this.expiredSortAgentPaginationFollowups = false;
            // this.expiredCurrentPage = 1;
            return new Promise((resolve, reject) => {
                AdminFollowupService.getExpiredFollowupsSearchCount(
                        expiredKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.expiredTotalCount = response.data;
                        // console.log("getSearch count", response.data);
                        this.getAllExpiredSearchPageable(
                            this.expiredCurrentPage,
                            this.expiredPageCount,
                            expiredKeyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllExpiredSearchPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            expiredKeyword
        ) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.getAllExpiredSearchPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        expiredKeyword.toLowerCase()
                    )
                    .then((response) => {
                        // console.log("getAllExpiredSearchPageable", response.data);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // sortExpiredCourse: function (selectedExpiredCourse) {
        //   this.selectedExpiredSource = "sources";
        //   this.selectedExpiredBranch = "branches";
        //   this.selectedExpiredAgent = "agents";
        //   if (selectedExpiredCourse == "allCourses") {
        //     this.getExpiredCount();
        //   } else {
        //     this.data = {
        //       courseId: selectedExpiredCourse.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortExpiredCourse(this.data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           // console.log("sortExpiredCourse",response.date);
        //           this.getExpiredSortCourseCount(this.data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        getExpiredSortCourseCount: function (selectedExpiredCourse) {
            this.loading = true;
            this.selectedExpiredSource = "sources";
            this.selectedExpiredBranch = "branches";
            this.selectedExpiredAgent = "agents";
            this.expiredPaginationFollowups = false;
            this.expiredSearchPagination = false;
            this.expiredSortCoursePaginationFollowups = true;
            this.expiredSortSourcePaginationFollowups = false;
            this.expiredSortBranchPaginationFollowups = false;
            this.expiredSortAgentPaginationFollowups = false;
            this.expiredCurrentPage = 1;
            if (selectedExpiredCourse == "allCourses") {
                this.getExpiredCount();
            } else {
                this.data = {
                    courseId: selectedExpiredCourse.courseId,
                };
                return new Promise((resolve, reject) => {
                    AdminFollowupService.getExpiredSortCourseCount(this.data)
                        .then((response) => {
                            this.expiredTotalCount = response.data;
                            // console.log("getExpiredSortCourseCount", this.expiredTotalCount);
                            this.expiredSortCourseAllPageable(
                                this.expiredCurrentPage,
                                this.expiredPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        expiredSortCourseAllPageable: function (
            expiredCurrentPage,
            expiredPageCount,
            data
        ) {
            return new Promise((resolve, reject) => {
                AdminFollowupService.expiredSortCourseAllPageable(
                        expiredCurrentPage,
                        expiredPageCount,
                        data
                    )
                    .then((response) => {
                        // console.log("expiredSortCourseAllPageable", response.data);
                        this.expiredFollowups = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // sortExpiredSource: function (selectedExpiredSource) {
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredBranch = "branches";
        //   this.selectedExpiredAgent = "agents";
        //   if (selectedExpiredSource == "allSources") {
        //     this.getAllExpiredFollowups();
        //   } else {
        //     let data = {
        //       sourceId: selectedExpiredSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortExpiredSource(data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.getExpiredSortSourceCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getExpiredSortSourceCount: function (selectedExpiredSource) {
        //   this.loading = true;
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredBranch = "branches";
        //   this.selectedExpiredAgent = "agents";
        //   this.expiredPaginationFollowups = false;
        //   this.expiredSearchPagination = false;
        //   this.expiredSortCoursePaginationFollowups = false;
        //   this.expiredSortSourcePaginationFollowups = true;
        //   this.expiredSortBranchPaginationFollowups = false;
        //   this.expiredSortAgentPaginationFollowups = false;
        //   this.expiredCurrentPage = 1;
        //   if (selectedExpiredSource == "allSources") {
        //     this.getExpiredCount();
        //   } else {
        //     this.data = {
        //       sourceId: selectedExpiredSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getExpiredSortSourceCount(this.data)
        //         .then((response) => {
        //           this.expiredTotalCount = response.data;
        //           // console.log("getExpiredSortSourceCount", this.expiredTotalCount);
        //           this.expiredSortSourceAllPageable(
        //             this.expiredCurrentPage,
        //             this.expiredPageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // expiredSortSourceAllPageable: function (
        //   expiredCurrentPage,
        //   expiredPageCount,
        //   data
        // ) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.expiredSortSourceAllPageable(
        //       expiredCurrentPage,
        //       expiredPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         // console.log("expiredSortCourseAllPageable", response.data);
        //         this.expiredFollowups = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sortExpiredBranch: function (selectedExpiredBranch) {
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredSource = "sources";
        //   this.selectedExpiredAgent = "agents";
        //   if (selectedExpiredBranch == "allBranches") {
        //     this.getAllExpiredFollowups();
        //   } else {
        //     let data = {
        //       branchId: selectedExpiredBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortExpiredBranch(data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.getExpiredSortBranchCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // getExpiredSortBranchCount: function (selectedExpiredBranch) {
        //   this.loading = true;
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredSource = "sources";
        //   this.selectedExpiredAgent = "agents";
        //   this.expiredPaginationFollowups = false;
        //   this.expiredSearchPagination = false;
        //   this.expiredSortCoursePaginationFollowups = false;
        //   this.expiredSortSourcePaginationFollowups = false;
        //   this.expiredSortBranchPaginationFollowups = true;
        //   this.expiredSortAgentPaginationFollowups = false;
        //   this.expiredCurrentPage = 1;
        //   if (selectedExpiredBranch == "allBranches") {
        //     this.getExpiredCount();
        //   } else {
        //     this.data = {
        //       branchId: selectedExpiredBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.getExpiredSortBranchCount(this.data)
        //         .then((response) => {
        //           this.expiredTotalCount = response.data;
        //           // console.log("getExpiredSortBranchCount", this.expiredTotalCount);
        //           this.expiredSortBranchAllPageable(
        //             this.expiredCurrentPage,
        //             this.expiredPageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // expiredSortBranchAllPageable: function (
        //   expiredCurrentPage,
        //   expiredPageCount,
        //   data
        // ) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.expiredSortBranchAllPageable(
        //       expiredCurrentPage,
        //       expiredPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         // console.log("expiredSortCourseAllPageable", response.data);
        //         this.expiredFollowups = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sortAgentExpiredFollowups: function (selectedExpiredAgent) {
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredSource = "sources";
        //   this.selectedExpiredBranch = "branches";
        //   if (selectedExpiredAgent == "allAgents") {
        //     this.getAllExpiredFollowups();
        //   } else {
        //     let data = {
        //       agentId: selectedExpiredAgent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortAgentExpiredFollowups(data)
        //         .then((response) => {
        //           this.expiredFollowups = response.data;
        //           this.sortAgentExpiredFollowupsCount(data);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        // sortAgentExpiredFollowupsCount: function (selectedExpiredAgent) {
        //   this.loading = true;
        //   this.selectedExpiredCourse = "courses";
        //   this.selectedExpiredSource = "sources";
        //   this.selectedExpiredBranch = "branches";
        //   this.expiredPaginationFollowups = false;
        //   this.expiredSearchPagination = false;
        //   this.expiredSortCoursePaginationFollowups = false;
        //   this.expiredSortSourcePaginationFollowups = false;
        //   this.expiredSortBranchPaginationFollowups = false;
        //   this.expiredSortAgentPaginationFollowups = true;
        //   this.expiredCurrentPage = 1;
        //   if (selectedExpiredAgent == "allAgents") {
        //     this.getExpiredCount();
        //   } else {
        //     this.data = {
        //       agentId: selectedExpiredAgent.agentId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       AdminFollowupService.sortAgentExpiredFollowupsCount(this.data)
        //         .then((response) => {
        //           this.expiredTotalCount = response.data;
        //           // console.log(
        //           //   "sortAgentExpiredFollowupsCount",
        //           //   this.expiredTotalCount
        //           // );
        //           this.sortAgentExpiredFollowupsAllPageable(
        //             this.expiredCurrentPage,
        //             this.expiredPageCount,
        //             this.data
        //           );
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // sortAgentExpiredFollowupsAllPageable: function (
        //   expiredCurrentPage,
        //   expiredPageCount,
        //   data
        // ) {
        //   return new Promise((resolve, reject) => {
        //     AdminFollowupService.sortAgentExpiredFollowupsAllPageable(
        //       expiredCurrentPage,
        //       expiredPageCount,
        //       data
        //     )
        //       .then((response) => {
        //         // console.log("expiredSortCourseAllPageable", response.data);
        //         this.expiredFollowups = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        // sorting() {
        //   if (this.selectedCourse == "Course") {
        //     let data = {
        //       sourceId: this.selectedSource.sourceId,
        //       branchId: this.selectedBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/adminFollowup/sortByAll", data)
        //         .then((response) => {
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   } else if (this.selectedSource == "Source") {
        //     let data = {
        //       courseId: this.selectedCourse.courseId,
        //       branchId: this.selectedBranch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/adminFollowup/sortByAll", data)
        //         .then((response) => {
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   } else if (this.selectedBranch == "Branch") {
        //     let data = {
        //       courseId: this.selectedCourse.courseId,
        //       sourceId: this.selectedSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       axios
        //         .post("/adminFollowup/sortByAll", data)
        //         .then((response) => {
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   } else {
        //     alert("sorting else block");
        //   }
        // },
        // showFollowups() {
        //     this.getCount();
        //     this.keyword = "";
        //     this.isFollowups = true;
        //     this.isExpiredFollowups = false;
        //     this.isEnquiryFollowups = false;
        //     this.selectedAgent = "Agents";
        //     this.selectedCourse = "Courses";
        //     this.selectedSource = "Sources";
        //     this.selectedBranch = "Branches";
        //     // this.getAllFollowups();
        // },
        // showEnquiryFollowups() {
        //     this.getEnquiryCount();
        //     this.enquiryKeyword = "";
        //     this.isFollowups = false;
        //     this.isExpiredFollowups = false;
        //     this.isEnquiryFollowups = true;
        //     this.selectedEnquiryAgent = "Agents";
        //     this.selectedEnquiryCourse = "Courses";
        //     this.selectedEnquirySource = "Sources";
        //     this.selectedEnquiryBranch = "Branches";
        //     // this.getAllFollowups();
        // },
        // showExpiredFollowups() {
        //     this.expiredKeyword = "";
        //     this.getExpiredCount();
        //     this.isFollowups = false;
        //     this.isExpiredFollowups = true;
        //     this.isEnquiryFollowups = false;
        //     this.selectedExpiredAgent = "agents";
        //     this.selectedExpiredCourse = "courses";
        //     this.selectedExpiredSource = "sources";
        //     this.selectedExpiredBranch = "branches";
        //     // this.getAllExpiredFollowups();
        // },
        refresh() {
            // this.getCount();
            this.getAllSortCount('Agents', 'Courses', 'Sources', 'Branches', null)
            this.keyword = "";
            this.selectedAgent = "Agents";
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            // this.getAllFollowups();
        },
        enquiryRefresh() {
            // this.getEnquiryCount();
            this.getAllSortEnquiryCount('Agents', 'Courses', 'Sources', 'Branches', null)
            this.enquiryKeyword = "";
            this.selectedEnquiryAgent = "Agents";
            this.selectedEnquiryCourse = "Courses";
            this.selectedEnquirySource = "Sources";
            this.selectedEnquiryBranch = "Branches";
            // this.getAllFollowups();
        },
        expiredRefresh() {
            // this.getExpiredCount();
            this.expiredKeyword = "";
            this.selectedExpiredAgent = "Agents";
            this.selectedExpiredCourse = "Courses";
            this.selectedExpiredSource = "Sources";
            this.selectedExpiredBranch = "Branches";
            // this.getAllExpiredFollowups();
            this.getAllSortExpiredCount('Agents', 'Courses', 'Sources', 'Branches', null)
        },
    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 125px;
}

.dateCell {
    /* min-width: 80px; */
    max-width: 125px;
}
</style>
