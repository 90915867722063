<template>
<div>
    <div>

        <div class="form-group">
            <label>Course Name:</label>
            <b-form-input class="form-control" placeholder="Enter your Course Name" v-model.trim="syllabus.name">
            </b-form-input>
        </div>
        <br />
        <div class="form-group">
            <label>Course Code:</label>
            <b-form-input class="form-control" v-model.trim="syllabus.code" placeholder="Enter your Code">
            </b-form-input>

        </div>
        <br />

    </div>
    <div title="Edit Course Content" icon="fa fa-th-list">
        <b-row class="text-center mb-3" align-v="center" v-if="(!importContent ) && (syllabus.content.childrens.length == 0)">
            <b-col align="right">
                <b-button variant="light" v-if="importContent" class="import-btn" style="background-color: #1091c9;color: #fff;">Import</b-button>
                <b-button variant="light" v-else @click="openImportContent" class="import-btn" style="background-color: #fff;color: #1091c9;">Import</b-button>
            </b-col>
            <b-col cols="1"> OR </b-col>
            <b-col align="left">
                <b-button variant="light" class="import-btn" style="background-color: #fff;color: #1091c9;">Create</b-button>
            </b-col>
        </b-row>

        <b-card v-if=" syllabus.contentFile == null && importContent" class="shadow border-0 mx-auto" style="background:#BFDCFA;border:2px solid grey;border-radius:25px;width:70%;margin-bottom:2em;">
            <b-row style="padding-bottom:1.5em;">
                <b-col cols="4">
                    <span class="text-info" style="font-weight:bold;font-size:1.2em;">Course Content<span style="color:red;">*</span></span>
                    <span class="pull-right text-info" style="font-weight:bold;font-size:1.2em;">:</span>
                </b-col>
                <b-col>
                    <b-form-file v-model="syllabus.contentFile" :state="Boolean(syllabus.contentFile)" placeholder="Choose a file or drop it here to add syllabus content..." drop-placeholder="Drop file here..." accept=".txt, .csv" @input="syllabusContentFileSelected(syllabus.contentFile)"></b-form-file>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-button class="shadow" variant="danger">Cancel</b-button>
            </b-row>
        </b-card>
        <!-- {{syllabus.content}} -->
        <!-- =================================================================================== -->
        <b-card v-if="syllabus.content!=null && syllabus.content.childrens.length != 0" class="shadow border-0 mx-auto" style="background:#d0dcf5;border:2px solid grey;border-radius:25px;width:90%;margin-bottom:2em;">
            <div role="tablist">
                <b-card no-body class="mb-1" v-for="(l1, l1index) in syllabus.content.childrens" v-bind:key="l1index">
                    <b-card-header header-tag="header" style="cursor:pointer;border-bottom:1px solid #d0dcf5" role="tab" v-if="l1index != syllabus.content.childrens.length" v-b-toggle="'accordion-1'+l1index">
                        <b-row class="card-header" style="padding:5px;">
                            <b-col class="d-flex justify-content-start ml-4">
                                <strong style="color:grey;">{{l1.word}}</strong>
                            </b-col>
                            <b-col class="d-flex justify-content-end ">
                                <i class="fa fa-pencil ml-4 mr-4 mt-1" aria-hidden="true" @click="editCourseContent(l1,l1index)" style="font-size:1.2em;cursor:pointer;color:blue;"></i>
                                <i class="fa fa-trash ml-4 mr-4 mt-1" aria-hidden="true" @click="removeCourseContent(l1index)" style="font-size:1.2em;cursor:pointer;color:red;"></i>
                            </b-col>
                        </b-row>

                    </b-card-header>
                    <b-collapse :id="'accordion-1'+l1index" visible role="tabpanel">
                        <b-card-body>
                            <b-card no-body class="mb-1" v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index">
                                <b-card-header header-tag="header" style="cursor:pointer;" role="tab" v-b-toggle="'accordion-2'+l1index+l2index">
                                    <b-row class="card-header" style="padding:5px;">
                                        <b-col class="d-flex justify-content-start ml-4">
                                            <strong style="color:grey;">{{l2.word}}</strong>
                                        </b-col>
                                        <b-col class="d-flex justify-content-end ">
                                            <i class="fa fa-pencil ml-4 mr-4 mt-1" aria-hidden="true" @click="editCourseContent1(l2,l2index,l1index)" style="font-size:1.2em;cursor:pointer;color:blue;"></i>
                                            <i class="fa fa-trash ml-4 mr-4 mt-1" aria-hidden="true" @click="removeCourseContent1(l1index , l2index)" style="font-size:1.2em;cursor:pointer;color:red;"></i>
                                        </b-col>
                                    </b-row>
                                </b-card-header>

                                <b-collapse :id="'accordion-2'+l1index+l2index" visible role="tabpanel">
                                    <b-card-body>
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered table-space">
                                                <tr v-for="(l3, l3index) in l2.childrens" v-bind:key="l3index">
                                                    <td>
                                                        <span v-if="l3.video" style="color:#00BFFF;">
                                                            <font-awesome-icon icon="video" /></span>
                                                        <span v-if="l3.resource" style="color:#00FF7F;">
                                                            <font-awesome-icon icon="file" /></span>
                                                        <span v-if="l3.quiz" style="color:#DC143C;">
                                                            <font-awesome-icon icon="edit" /></span>
                                                    </td>
                                                    <!-- <td>{{l3.word}}</td> -->
                                                    <td>
                                                        <span v-if="l3.archived==null || (l3.archived!=null && l3.archived=='false')">{{l3.word}}</span>
                                                        <span v-else style="color:red;" v-b-tooltip.hover title="Archived">{{l3.word}}</span>
                                                        <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 14px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row>
                                                    </td>
                                                    <td>
                                                        <span v-if="l3.quiz">
                                                            <span v-if="l3.quizDetails != null">{{l3.quizDetails.testName}}</span>
                                                            <span v-if="l3.liveVideo && l3.liveVideoState=='expired' && (l3.quizDetails==undefined || (l3.quizDetails!=undefined && l3.quizDetails.testName==undefined))">
                                                                <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                            </span>
                                                        </span>
                                                        <span v-else-if="l3.video">
                                                            <span v-if="l3.videoNode != null && l3.videoNode.videoTitle != null">{{l3.videoNode.videoTitle}}</span>
                                                            <span v-if="l3.liveVideo && l3.liveVideoState=='expired' && (l3.videoNode==undefined || (l3.videoNode!=undefined && l3.videoNode.videoTitle==undefined))">
                                                                <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                            </span>
                                                        </span>
                                                        <span v-else-if="l3.resource">
                                                            <!-- <span v-if="l3.resourceUrl != null">{{l3.resourceUrl}}</span> -->
                                                            <span v-if="l3.resourceUrl != null">File uploaded.</span>
                                                            <span v-if="l3.resourceUrl == null && !l3.liveVideo" style="color:red;font-size:0.8em">Please Upload File!!</span>
                                                            <span v-if="l3.liveVideo && l3.liveVideoState=='expired' && (l3.resourceUrl==undefined || l3.resourceUrl==null)">
                                                                <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="l3.syllabusInstructorName!=null">{{l3.syllabusInstructorName}}</span>
                                                        <span v-else>--</span>
                                                    </td>

                                                </tr>
                                                <tr class="border-bottom:1px solid #d0dcf5;">
                                                    <td colspan="4" style="text-align:center"> <i class="fa fa-plus mr-4 mt-1" style="cursor:pointer;font-size:1em;" @click="openThirdLevel(l1index,l2index)">&nbsp;Add Level 2</i></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" style="cursor:pointer;" class="p-1" role="tab">
                                    <div class="d-flex justify-content-center" style="margin:10px">
                                        <i class="fa fa-plus mr-4 mt-1 mb-2" style="cursor:pointer;color:grey;font-size:1em;" @click="addSecondLevel(l1index)">&nbsp;Add Level 1</i>
                                    </div>

                                </b-card-header>
                            </b-card>
                        </b-card-body>
                    </b-collapse>

                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" style="cursor:pointer;" class="p-1" role="tab">
                        <div class="d-flex justify-content-center" style="margin:10px">
                            <i class="fa fa-plus mr-4 mt-1 mb-2" style="cursor:pointer;color:#4274d6;font-size:1em;">&nbsp;Add Level</i>
                        </div>

                    </b-card-header>
                </b-card>
            </div>
        </b-card>
    </div>
    <div class="text-center">
        <b-button variant="outline-success" @click="insertSyllabus()">Submit</b-button>
        <b-button variant="outline-danger" class="mx-3">Reset</b-button>
    </div>
</div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import Account from '../service/Account.js';

export default {
    data() {
        return {
            importContent: false,
            syllabus: {
                name: "",
                code: "",
                syllabusDescription: "",
                published: false,
                permaLink: "",
                archived: false,
                locked: false,
                duration: 0,
                comingAt: null,
                features: "",
                totalSyllabus: 0,
                detailedDescription: "",
                synopsis: "",
                contentFile: null,
                content: {
                    childrens: [],
                    word: "",
                    quiz: false,
                    resource: false,
                    video: false,
                    thumbnail: false,
                    thumbnailUrl: null,
                    resourceUrl: null,
                    videoNode: null,
                    quizDetails: null,
                    syllabusId: "",
                    syllabusInstructorId: null,
                    syllabusInstructorName: null,
                },
                syllabusContent: {
                    firstSyllabusLevel: [],
                },
            }
        }
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSyllabus(syllabusId) {
            return new Promise((resolve, reject) => {
                axios
                    .post("syllabus/get/" + syllabusId)
                    .then(response => {
                        this.syllabus.name = response.data.name;
                        this.syllabus.code = response.data.code;
                        this.syllabus.content = response.data.syllabusContent;
                        console.log(this.syllabus.code);
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);

                        reject(err);
                    });
            });
        },
        openImportContent: function () {
            this.importContent = true;
            this.syllabus.contentFile = null;
        },
        syllabusContentFileSelected: function (e) {
            this.syllabus.contentFile = e;
            let formData = new FormData();
            formData.append("file", this.syllabus.contentFile);
            //    formData.append("liveCourse", this.syllabus.liveCourse);
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                axios
                    .post("syllabus/content/upload", formData, config)
                    .then(response => {
                        console.log("fsd");
                        // swal(
                        //     'Updated!',
                        //     'Course content uploaded successfully.',
                        //     'success'
                        // );
                        this.syllabus.content = response.data;
                        console.log(this.syllabus.content, "this.syllabus.content")
                        if (this.syllabus.liveCourse) {
                            this.checkContentError();
                        }
                        resolve(response);
                        this.isLoading = false;
                    })
                    .catch(err => {
                        swal({
                            title: 'Upload Error!',
                            text: "Are you uploading a proper file format!",
                            type: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        })
                        this.isLoading = false;
                        reject(err);
                    });
            });
        },
        insertSyllabus: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post("syllabus/insert", this.syllabus)
                    .then(response => {
                        this.getSyllabus(response.data.syllabusId);
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);

                        reject(err);
                    });
            });
        },

    }
}
</script>

<style scoped>
.card-header:focus {
    outline: none;
    cursor: pointer;
}

.card-header {
    /* background-color: rgba(0, 0, 0, 0.03); */
    border-bottom: none;

}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: left;
}
</style>
