<template>
<div>
    <b-container>
        <div v-if="loading" class="loader">
            <img src="../../public/encore.svg" class="spinner" width="30" height="30">
        </div>
        <div v-if="isRoleCounsellor == false">
            <!-- for loader -->
            <!-- for loader end -->
            <b-container>
                <!-- <div>
                <b-row class="my-4">
                    <b-col col lg="10" style="text-align: right">
                        <b-button variant="outline-primary" v-bind:class="{ active: isShowEnquiry }" size="sm" @click="showEnquiry()">
                            Enquiries</b-button>
                    </b-col>
                    <b-col col lg="2">
                        <b-button variant="outline-primary" v-bind:class="{ active: isShowClosed }" size="sm" @click="showClosed()">
                            Closed Enquiries</b-button>
                    </b-col>
                </b-row>
            </div> -->
            </b-container>

            <!-- <div v-if="isShowEnquiry"> -->
            <b-tabs pills card content-class="mt-3" justified>
                <b-tab title="Enquiries" active>
                    <!-- Enquiry table and details start -->
                    <!-- <div class="mt-5"> -->
                    <div>
                        <b-container>
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                                </b-col>
                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                                    <!-- <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="pageCount"
                align="fill"
                prev-text="Prev"
                next-text="Next"
                size="sm"
                class="my-0 mx-2"
                @change="switchPage(pageCount)"
              ></b-pagination> -->
                                    <!-- <b-pagination
                v-model="searchcurrentPage"
                :total-rows="totalSearchCount"
                :per-page="searchpageCount"
                align="fill"
                prev-text="Prev"
                next-text="Next"
                size="sm"
                class="my-0 mx-2"
                @input="searchswitchPage(searchpageCount)"
              ></b-pagination> -->
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container>

                            <b-card class="card mt-1">
                                <div>
                                    <b-container>
                                        <b-row>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="getAllSortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                    <b-form-select-option disabled value="Courses">
                                                        <p>Courses</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllCourses">
                                                        <p>All Courses</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="getAllSortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                    <b-form-select-option disabled value="Sources">
                                                        <p>Sources</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllSources">
                                                        <p>All Sources</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="getAllSortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                    <b-form-select-option disabled value="Branches">
                                                        <p>Branches</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllBranches">
                                                        <p>All Branch</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" @change="getAllSortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-modal ref="enquiryDateRange" hide-footer id="dateRange" title="Select Date">
                                    <template>
                                        <div>
                                            <label for="from-datepicker">Choose The From date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="from" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div>
                                            <label for="to-datepicker">Choose The To date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="to" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <br />
                                    <template>
                                        <div class="text-center">
                                            <b-button variant="outline-success" type="submit" @click="getAllSortCount(selectedCourse,selectedSource,selectedBranch,selectedDates)">
                                                Submit
                                            </b-button>
                                        </div>
                                    </template>
                                </b-modal>
                                <hr />
                                <b-table bordered responsive hover :items="allEnquiries" :fields="fields" :current-page="currentPage" :per-page="allEnquiries.pageCount">
                                    <template v-slot:cell(enquiryId)="data">
                                        <span class="title"> {{ data.item.enquiryId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact}}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>
                                            {{ data.item.alternativeContact }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        {{ data.item.course.name }}
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        {{ data.item.source.name }}
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        {{ data.item.branch.name }}
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <div class="cellSize">
                                            <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                                <font-awesome-icon icon="eye" />
                                            </b-button>
                                            <!-- <b-button
                  class="fa fa-pencil"
                  variant="info"
                  v-b-modal.editEnquiry
                  @click="getEnquiry(data.item)"
                  v-b-tooltip.hover.topright="{ variant: 'info' }"
                  title="Update Enquiry"
                ></b-button> -->
                                            <!-- <b-button
                  class="far fa-file-edit"
                  variant="warning"
                  v-b-modal.followInsert
                  @click="getEnquiry(data.item)"
                  v-b-tooltip.hover.topright="{ variant: 'warning' }"
                  title="Update Followup"
                ></b-button> -->
                                            <!-- <b-button class="fas fa-window-close" variant="secondary" @click="enquiryClosed(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" title="Closed Enquiry"></b-button> -->
                                        </div>
                                    </template>
                                    <template>
                                        <b-icon icon="arrow-up"></b-icon>
                                        <b-icon icon="exclamation-triangle"></b-icon>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-container>
                    </div>
                    <!-- Enquiry table and details end -->
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Closed Enquiries">
                    <!-- <div v-if="isShowClosed"> -->
                    <!-- Enquiry closed details table start -->
                    <!-- <div class="mt-5"> -->
                    <div>
                        <b-container>
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="closedKeyword" v-on:keyup.enter="getSearchClosedCount(closedKeyword)" placeholder="Type a name" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchClosedCount(closedKeyword)">Search</b-button>
                                </b-col>
                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="closedCurrentPage" :total-rows="closedTotalCount" :per-page="closedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="closedSwitchPage(closedCurrentPage, closedPageCount)"></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="closedPageCount" :options="closedPageOptions" @input="closedSwitchPage(closedCurrentPage, closedPageCount)" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="closedRefresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container>
                            <b-card class="card mt-1">
                                <div>
                                    <b-container>
                                        <b-row>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedCourse" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Courses">
                                                        <p>Courses</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllClosedCourses">
                                                        <p>All Courses</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedSource" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Sources">
                                                        <p>Sources</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllClosedSources">
                                                        <p>All Sources</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedBranch" size="sm" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        ">
                                                    <b-form-select-option disabled value="Branches">
                                                        <p>Branches</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllClosedBranches">
                                                        <p>All Branches</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>

                                            <b-col col lg="2" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedDates" @change="
                          getAllSortClosedCount(
                            selectedClosedCourse,
                            selectedClosedSource,
                            selectedClosedBranch,
                            selectedClosedDates
                          )
                        " :options="dateSelect">>
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-modal ref="dateRangeClosed" hide-footer id="dateRangeClosed" title="Select Date">
                                    <template>
                                        <div>
                                            <label for="from-datepicker">Choose The From date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="from" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div>
                                            <label for="to-datepicker">Choose The To date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="to" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <br />
                                        <div class="text-center">
                                            <b-button variant="outline-success" type="submit" @click="
                        getAllSortClosedCount(
                          selectedClosedCourse,
                          selectedClosedSource,
                          selectedClosedBranch,
                          selectedClosedDates
                        )
                      ">Submit</b-button>
                                        </div>
                                    </template>
                                </b-modal>
                                <hr />
                                <b-table bordered responsive hover :items="closedEnquiries" :fields="closedFields" :current-page="closedCurrentPage" :per-page="closedEnquiries.closedPageCount">
                                    <template v-slot:cell(enquiryId)="data">
                                        <span class="title"> {{ data.item.enquiryId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                    }}<span v-if="data.item.alternativeContact != null">,</span>
                                        </div>
                                        <div>
                                            {{ data.item.alternativeContact }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        <div class="cellSize">
                                            {{ data.item.course.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        {{ data.item.source.name }}
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        <div class="cellSize">
                                            {{ data.item.branch.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getCounsellorEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-container>
                    </div>
                    <!-- Enquiry closed details table end -->
                    <!-- </div> -->
                </b-tab>
            </b-tabs>
            <b-container>
                <!-- Data render in table -->
                <div class="top"></div>
                <!-- Data render in table -->
                <div>
                    <!--popup start for View Enquiry details-->
                    <b-modal id="enquiryView" title="View Enquiry" hide-footer>
                        <div class="control">
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Name</label>
                                    <b-form-input type="text" v-model="enquiry.name" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Contact</label>
                                    <b-form-input type="number" v-model="enquiry.contact" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Alternative Contact</label>
                                    <b-form-input type="number" v-model="enquiry.alternativeContact" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Age</label>
                                    <b-form-input type="number" v-model="enquiry.age" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Qualification</label>
                                    <b-form-input v-model="enquiry.qualification" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Course</label>
                                    <b-form-select class="form-control" v-model="enquiry.course" disabled>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Source</label>
                                    <b-form-select class="form-control" v-model="enquiry.source" disabled>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Mode</label>
                                    <b-form-input class="form-control" v-model="enquiry.mode" :options="options" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Branch</label>
                                    <b-form-select class="form-control" v-model="enquiry.branch" disabled>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupDateAndRemark">
                                <template v-slot:cell(remark)="data">
                                    <span class="title"> {{ data.item.remark }}</span>
                                </template>
                                <!-- <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template> -->
                                <template v-slot:cell(createdAt)="data">
                                    {{ callDate(data.item.createdAt, "fullDate") }}
                                </template>
                                <template v-slot:cell(followupDate)="data">
                                    {{ callDate(data.item.followupDate, "fullDate") }}
                                </template>
                            </b-table>
                        </div>
                    </b-modal>
                    <!--popup end for View Enquiry details-->
                </div>
                <div>
                    <!-- popup start for Enquiry details Update -->
                    <b-modal id="editEnquiry" title="update Enquiry" ref="modal" hide-footer>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model.trim="$v.enquiry.name.$model" class="form-control" placeholder="Enter student name" :class="{
                    'is-invalid': $v.enquiry.name.$error,
                    'is-valid': !$v.enquiry.name.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input type="number" v-model.number.lazy="$v.enquiry.contact.$model" class="form-control" placeholder="Enter contact number" :class="{
                    'is-invalid': $v.enquiry.contact.$error,
                    'is-valid': !$v.enquiry.contact.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input type="number" v-model.number.lazy="$v.enquiry.age.$model" class="form-control" placeholder="Enter student age" :class="{
                    'is-invalid': $v.enquiry.age.$error,
                    'is-valid': !$v.enquiry.age.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.qualification.$model" :class="{
                    'is-invalid': $v.enquiry.qualification.$error,
                    'is-valid': !$v.enquiry.qualification.$invalid,
                  }">
                                    <option value="null" disabled>Select Qualification</option>
                                    <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                        <strong>{{ q.text }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.course.$model" :class="{
                    'is-invalid': $v.enquiry.course.$error,
                    'is-valid': !$v.enquiry.course.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.source.$model" :class="{
                    'is-invalid': $v.enquiry.source.$error,
                    'is-valid': !$v.enquiry.source.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.mode.$model" :options="options" :class="{
                    'is-invalid': $v.enquiry.mode.$error,
                    'is-valid': !$v.enquiry.mode.$invalid,
                  }"></b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.branch.$model" :class="{
                    'is-invalid': $v.enquiry.mode.$error,
                    'is-valid': !$v.enquiry.mode.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" @click="updateEnquiry()" class="px-3" variant="primary">Update</b-button>
                        </div>
                    </b-modal>
                    <!-- popup start for Enquiry details Update end-->
                </div>
                <div>
                    <!-- popup start for follow insert -->
                    <b-modal id="followInsert" title="Follow Update" ref="modal" hide-footer>
                        <h5 class="px-5">Name : {{ enquiry.name }}</h5>
                        <h5 class="px-5">Contact : {{ enquiry.contact }}</h5>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Followup</label>
                                <div>
                                    <b-form-input format="DD-MM-YYYY h:i:s" v-model="enquiryFollowup.followupDate" :class="{
                      'is-invalid': $v.enquiryFollowup.followupDate.$error,
                      'is-valid': !$v.enquiryFollowup.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                    <div class="valid-feedback">date time is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryFollowup.followupDate.required">date or time is invalid!</span>
                                    </div>
                                </div>
                                <div>
                                    <b-form-textarea class="form-control" v-model="enquiryFollowup.remark" :class="{
                      'is-invalid': $v.enquiryFollowup.remark.$error,
                      'is-valid': !$v.enquiryFollowup.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                    <div class="valid-feedback">remark is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryFollowup.remark.required">remark is invalid!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" @click="enquiryFollowupUpdate()" class="px-3" variant="primary">Update</b-button>
                        </div>
                    </b-modal>
                    <!-- popup start for follow insert end-->
                </div>
            </b-container>
            <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button variant="outline-success" type="submit" @click="getDateRange()">Submit</b-button>
                </div>
            </b-modal>

            <b-modal ref="dateClosedRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button variant="outline-success" type="submit" @click="getClosedDateRange()">Submit</b-button>
                </div>
            </b-modal>
        </div>
        <div v-else>
            <b-tabs pills card content-class="mt-3" justified>
                <b-tab title="Enquiries" active>
                    <!-- counsellor -->
                    <b-container>
                        <!-- <div>
                <b-row class="my-4">
                    <b-col col lg="10" style="text-align: right">
                        <b-button variant="outline-primary" v-bind:class="{ active: counsellorEnquiryActive }" size="sm" @click="showEnquiryCounsellor()">
                            Enquiries</b-button>
                    </b-col>
                    <b-col col lg="2">
                        <b-button variant="outline-primary" v-bind:class="{ active: counsellorClosedEnquiryActive }" size="sm" @click="showClosedCounsellor()">
                            Closed Enquiries</b-button>
                    </b-col>
                </b-row>
            </div> -->
                        <!-- <div v-if="isShowCounsellor"> -->
                        <!-- Enquiry table and details start -->
                        <!-- <div class="mt-5"> -->
                        <div>
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addEnquiry @click="resetForm()">AddEnquiry</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="counsellorKeyword" v-on:keyup.enter="getCounsellorSearchCount(counsellorKeyword)" placeholder="Type a name" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCounsellorSearchCount(counsellorKeyword)">Search</b-button>
                                </b-col>
                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="counsellorCurrentPage" :total-rows="counsellorTotalCount" :per-page="counsellorPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    counsellorSwitchPage(
                      counsellorCurrentPage,
                      counsellorPageCount
                    )
                  "></b-pagination>
                                    <!-- <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="pageCount"
                align="fill"
                prev-text="Prev"
                next-text="Next"
                size="sm"
                class="my-0 mx-2"
                @change="switchPage(pageCount)"
              ></b-pagination> -->
                                    <!-- <b-pagination
                v-model="searchcurrentPage"
                :total-rows="totalSearchCount"
                :per-page="searchpageCount"
                align="fill"
                prev-text="Prev"
                next-text="Next"
                size="sm"
                class="my-0 mx-2"
                @input="searchswitchPage(searchpageCount)"
              ></b-pagination> -->
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="counsellorPageCount" @input="
                    counsellorSwitchPage(
                      counsellorCurrentPage,
                      counsellorPageCount
                    )
                  " :options="counsellorPageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="counsellorRefresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                            <!-- </b-container>
            <b-container> -->
                            <div class="top">
                                <!-- modal form call details insert -->
                                <b-modal id="addEnquiry" title="Add Enquiry" ref="modal" no-close-on-backdrop hide-footer>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Name</label>
                                            <b-form-input type="text" v-model="call.name" :class="{
                        'is-invalid': $v.call.name.$error,
                        'is-valid': !$v.call.name.$invalid,
                      }" class="form-control" placeholder="Enter student name"></b-form-input>
                                            <div class="valid-feedback">Your Name is valid!</div>
                                            <div class="invalid-feedback">
                                                <span v-if="!$v.call.name.required">Name is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Contact</label>
                                            <b-form-input type="number" v-on:keyup.enter="getContactSearchCount(call.contact)" v-model="call.contact" :class="{
                        'is-invalid': $v.call.contact.$error,
                        'is-valid': !$v.call.contact.$invalid,
                      }" class="form-control" placeholder="Enter contact number"></b-form-input>
                                            <div class="valid-feedback">Your contact is valid!</div>
                                            <div class="invalid-feedback">
                                                <span v-if="!$v.call.contact.required">contact is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span>
                                        <b-button style="margin-left: 180px" variant="primary" @click="getContactSearchCount(call.contact)">Search</b-button>
                                    </span>

                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Alternative Contact</label>
                                            <b-form-input type="number" v-model="call.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number"></b-form-input>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Age</label>
                                            <b-form-input type="number" v-model="call.age" :class="{
                        'is-invalid': $v.call.age.$error,
                        'is-valid': !$v.call.age.$invalid,
                      }" class="form-control" placeholder="Enter student age"></b-form-input>
                                            <div class="valid-feedback">Your Age is valid!</div>
                                            <div class="invalid-feedback">
                                                <span v-if="!$v.call.age.required">Age is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Qualification</label>
                                            <b-form-select class="form-control" v-model="call.qualification" :class="{
                        'is-invalid': $v.call.qualification.$error,
                        'is-valid': !$v.call.qualification.$invalid,
                      }">
                                                <option value="null" disabled>
                                                    Select Qualification
                                                </option>
                                                <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                                    <strong>{{ q.text }}</strong>
                                                </option>
                                            </b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Course</label>
                                            <b-form-select class="form-control" v-model="call.course" :class="{
                        'is-invalid': $v.call.course.$error,
                        'is-valid': !$v.call.course.$invalid,
                      }">
                                                <option value="null" disabled>Select Course</option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Source</label>
                                            <b-form-select class="form-control" v-model="call.source" :class="{
                        'is-invalid': $v.call.source.$error,
                        'is-valid': !$v.call.source.$invalid,
                      }">
                                                <option value="null" disabled>Select Source</option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Mode</label>
                                            <b-form-select class="form-control" v-model="call.mode" :class="{
                        'is-invalid': $v.call.mode.$error,
                        'is-valid': !$v.call.mode.$invalid,
                      }" :options="options"></b-form-select>
                                        </div>
                                    </div>
                                    <!-- <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
              <label>Branch</label>
              <b-form-select
                class="form-control"
                v-model="call.branch"
               :class="{
                  'is-invalid': $v.call.branch.$error,
                  'is-valid': !$v.call.branch.$invalid,
                }"
              >
                <option value="null" disabled>Select Branch</option>
                <option
                  v-for="item in allBranches"
                  :value="item"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.name }}</strong>
                </option>
              </b-form-select>
            </div>
          </div> -->
                                    <div class="form-row mt-3 px-5">
                                        <div class="form-group my-2">
                                            <label>Followup</label>
                                            <div>
                                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="call.followupDate" :class="{
                          'is-invalid': $v.call.followupDate.$error,
                          'is-valid': !$v.call.followupDate.$invalid,
                        }" type="datetime-local" min="new Time()"></b-form-input>
                                                <div class="valid-feedback">date time is valid!</div>
                                                <div class="invalid-feedback">
                                                    <span v-if="!$v.call.followupDate.required">date or time is invalid!</span>
                                                </div>
                                            </div>
                                            <div>
                                                <b-form-textarea class="form-control" v-model="call.remark" :class="{
                          'is-invalid': $v.call.remark.$error,
                          'is-valid': !$v.call.remark.$invalid,
                        }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                                <div class="valid-feedback">remark is valid!</div>
                                                <div class="invalid-feedback">
                                                    <span v-if="!$v.call.remark.required">remark is invalid!</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mt-3 mt-2">
                                        <b-button type="submit" :disabled="disabledButton" @click="insert()" class="" variant="outline-primary">Register</b-button>
                                        <b-button type="submit" @click="resetForm()" class="mx-3" variant="outline-danger">Reset</b-button>
                                    </div>
                                </b-modal>
                                <!-- modal form call details insert end -->
                            </div>
                            <b-card class="card mt-1">
                                <div>
                                    <b-container>
                                        <b-row>
                                            <b-col col lg="4" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="getCounsellorCourseCount(selectedCourse)">
                                                    <b-form-select-option disabled value="Courses">
                                                        <p>Courses</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllCourse">
                                                        <p>All Courses</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="4" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="getCounsellorSourceCount(selectedSource)">
                                                    <b-form-select-option disabled value="Sources">
                                                        <p>Sources</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllSource">
                                                        <p>All Sources</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <!-- <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="getBranchCount(selectedBranch)">
                                        <b-form-select-option disabled value="Branches">
                                            <p>Branches</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllBranch">
                                            <p>All Branch</p>
                                        </b-form-select-option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col> -->
                                            <b-col col lg="4" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedDate" @change="getCounsellorDateCount(selectedDate)" :options="dateSelect">>
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-modal ref="counsellorDateRange" hide-footer id="counsellorDateRange" title="Select counsellor Date">
                                    <template>
                                        <div>
                                            <label for="from-datepicker">Choose The From date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="fromCounsellorDate" :class="{
                        'is-invalid': $v.fromCounsellorDate.$error,
                        'is-valid': !$v.fromCounsellorDate.$invalid,
                      }" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div>
                                            <label for="to-datepicker">Choose The To date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="toCounsellorDate" :class="{
                        'is-invalid': $v.toCounsellorDate.$error,
                        'is-valid': !$v.toCounsellorDate.$invalid,
                      }" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div class="text-center">
                                            <b-button variant="outline-success" type="submit" @click="getCounsellorDateRange()">Submit</b-button>
                                        </div>
                                    </template>
                                </b-modal>
                                <hr />
                                <b-table bordered responsive hover :items="allEnquiries" :fields="fields" :current-page="counsellorCurrentPage" :per-page="allEnquiries.counsellorPageCount">
                                    <template v-slot:cell(enquiryId)="data">
                                        <span class="title"> {{ data.item.enquiryId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                    }}<span v-if="
                        data.item.alternativeContact != null &&
                        data.item.alternativeContact != ''
                      ">,</span>
                                        </div>
                                        <div>
                                            {{ data.item.alternativeContact }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        {{ data.item.course.name }}
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        {{ data.item.source.name }}
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        {{ data.item.branch.name }}
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <div>
                                            <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getCounsellorEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                                <font-awesome-icon icon="eye" />
                                            </b-button>
                                            <b-button size="sm" class="mx-1" variant="warning" v-b-modal.followInsert @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'warning' }" title="Update Followup">
                                                <font-awesome-icon icon="edit" />
                                            </b-button>
                                            <b-button size="sm" variant="primary" v-b-tooltip.hover.topright="{ variant: 'secondary' }"    v-b-modal.editEnquiry    @click="getEnquiry(data.item)" title="Edit Enquiry">
                                                <font-awesome-icon icon="edit" />
                                            </b-button>&nbsp;
                                            <b-button size="sm" class="mt-1 fas fa-window-close" variant="secondary" @click="getEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'secondary' }" v-b-modal.enquiryClosed title="Closed Enquiry"></b-button>
                                        </div>
                                    </template>
                                    <template>
                                        <b-icon icon="arrow-up"></b-icon>
                                        <b-icon icon="exclamation-triangle"></b-icon>
                                    </template>
                                </b-table>
                            </b-card>
                        </div>

                        <!-- Edit Enquiry -->



                        <!-- Enquiry table and details end -->
                        <!-- </div> -->
                    </b-container>
                </b-tab>
                <b-tab title="Closed Enquiries">
                    <!-- <div v-if="isShowCounsellorClosed"> -->
                    <!-- Enquiry closed details table start -->
                    <!-- <div class="mt-5"> -->
                    <div>
                        <b-container>
                            <b-row align-v="center">
                                <!-- Enquiry disabled -->
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Enquiry</b-button>
                                </b-col>
                                <b-col col sm="12" md="3" lg="3" id="r1">
                                    <input type="search" class="searchbar" v-model="counsellorClosedKeyword" v-on:keyup.enter="
                    getCounsellorSearchClosedCount(counsellorClosedKeyword)
                  " placeholder="Type a name" />
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="
                    getCounsellorSearchClosedCount(counsellorClosedKeyword)
                  ">Search</b-button>
                                </b-col>
                                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                    <b-pagination v-model="closedCurrentPage" :total-rows="closedTotalCount" :per-page="closedPageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                    counsellorClosedSwitchPage(
                      closedCurrentPage,
                      closedPageCount
                    )
                  "></b-pagination>
                                </b-col>
                                <b-col sm="6" md="3" lg="2" class="" id="r1">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="closedPageCount" :options="closedPageOptions" @input="
                    counsellorClosedSwitchPage(
                      closedCurrentPage,
                      closedPageCount
                    )
                  " size="sm">
                                    </b-form-select>
                                </b-col>
                                <b-col col lg="1" md="1" id="r1" sm="6">
                                    <span style="color: #0974ff; cursor: pointer" @click="counsellorClosedRefresh()">
                                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container>
                            <b-card class="card mt-1">
                                <div>
                                    <b-container>
                                        <b-row>
                                            <b-col col lg="4" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedCourse" size="sm" @change="
                          getCounsellorClosedCourseCount(selectedClosedCourse)
                        ">
                                                    <b-form-select-option disabled value="Courses">
                                                        <p>Courses</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllCourse">
                                                        <p>All Courses</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <b-col col lg="4" md="3" id="r1" sm="4" class="sort">
                                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedClosedSource" size="sm" @change="
                          getCounsellorSourceClosedCount(selectedClosedSource)
                        ">
                                                    <b-form-select-option disabled value="Sources">
                                                        <p>Sources</p>
                                                    </b-form-select-option>
                                                    <b-form-select-option value="AllSource">
                                                        <p>All Sources</p>
                                                    </b-form-select-option>
                                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                        <strong>{{ item.name }}</strong>
                                                    </option>
                                                </b-form-select>
                                            </b-col>
                                            <!-- <b-col col lg="3" md="3" id="r1" sm="4" class="sort">
                                    <b-form-select class="bnclr" v-model="selectedClosedBranch" size="sm" @change="getBranchClosedCount(selectedClosedBranch)">
                                        <b-form-select-option disabled value="Branches">
                                            <p>Branches</p>
                                        </b-form-select-option>
                                        <b-form-select-option value="AllBranch">
                                            <p>All Branches</p>
                                        </b-form-select-option>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </b-col> -->

                                            <b-col col lg="4" md="2" id="r1" sm="4">
                                                <b-form-select class="bnclr" v-model="selectedClosedDate" @change="
                          getCounsellorClosedDateCount(selectedClosedDate)
                        " :options="dateSelect">>
                                                </b-form-select>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-modal ref="dateCounsellorClosedRange" hide-footer id="counsellorDateRange" title="Select counsellor Date">
                                    <template>
                                        <div>
                                            <label for="from-datepicker">Choose The From date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="fromCounsellorDate" :class="{
                        'is-invalid': $v.fromCounsellorDate.$error,
                        'is-valid': !$v.fromCounsellorDate.$invalid,
                      }" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div>
                                            <label for="to-datepicker">Choose The To date</label>
                                            <b-form-input format="DD-MM-YYYY" v-model="toCounsellorDate" :class="{
                        'is-invalid': $v.toCounsellorDate.$error,
                        'is-valid': !$v.toCounsellorDate.$invalid,
                      }" type="date"></b-form-input>
                                        </div>
                                    </template>
                                    <template>
                                        <div class="text-center">
                                            <b-button variant="outline-success" type="submit" @click="getCounsellorClosedDateRange()">Submit</b-button>
                                        </div>
                                    </template>
                                </b-modal>
                                <hr />
                                <b-table bordered responsive hover :items="closedEnquiries" :fields="closedFields" :current-page="closedCurrentPage" :per-page="closedEnquiries.closedPageCount">
                                    <template v-slot:cell(enquiryId)="data">
                                        <span class="title"> {{ data.item.enquiryId }}</span>
                                    </template>
                                    <template v-slot:cell(callCreatedAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:cell(contact)="data">
                                        <div>
                                            {{ data.item.contact
                    }}<span v-if="
                        data.item.alternativeContact != null &&
                        data.item.alternativeContact != ''
                      ">,</span>
                                        </div>
                                        <div>
                                            {{ data.item.alternativeContact }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(course)="data">
                                        <div class="cellSize">
                                            {{ data.item.course.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(source)="data">
                                        <div class="cellSize">
                                            {{ data.item.source.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(branch)="data">
                                        <div class="cellSize">
                                            {{ data.item.branch.name }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <div class="cellSize">
                                            {{ callDate(data.item.createdAt, "fullDate") }}
                                        </div>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-modal.enquiryView @click="getCounsellorEnquiry(data.item)" v-b-tooltip.hover.topright="{ variant: 'primary' }" title="View Enquiry">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-container>
                    </div>
                    <!-- Enquiry closed details table end -->
                    <!-- </div> -->
                </b-tab>
            </b-tabs>

            <b-container>
                <!-- Data render in table -->
                <div class="top"></div>
                <!-- Data render in table -->
                <div>
                    <!--popup start for View Enquiry details-->
                    <b-modal id="enquiryView" title="View Enquiry" hide-footer>
                        <div class="control">
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Name</label>
                                    <b-form-input type="text" v-model="enquiry.name" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Contact</label>
                                    <b-form-input type="number" v-model="enquiry.contact" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Alternative Contact</label>
                                    <b-form-input type="number" v-model="enquiry.alternativeContact" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Age</label>
                                    <b-form-input type="number" v-model="enquiry.age" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Qualification</label>
                                    <b-form-input v-model="enquiry.qualification" class="form-control" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Course</label>
                                    <b-form-select class="form-control" v-model="enquiry.course" disabled>
                                        <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Source</label>
                                    <b-form-select class="form-control" v-model="enquiry.source" disabled>
                                        <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Mode</label>
                                    <b-form-input class="form-control" v-model="enquiry.mode" :options="options" disabled></b-form-input>
                                </div>
                            </div>
                            <div class="form-row mt-3 px-5">
                                <div class="form-group my-2">
                                    <label>Branch</label>
                                    <b-form-select class="form-control" v-model="enquiry.branch" disabled>
                                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                            <strong>{{ item.name }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <b-table responsive bordered striped hover :items="followupDateRemark" :fields="followupDateAndRemark">
                                <template v-slot:cell(remark)="data">
                                    <span class="title"> {{ data.item.remark }}</span>
                                </template>
                                <!-- <template v-slot:cell(followupDate)="data">
                                {{ callDate(data.item.followupDate, "fullDate") }}
                            </template> -->
                                <template v-slot:cell(createdAt)="data">
                                    {{ callDate(data.item.createdAt, "fullDate") }}
                                </template>
                                <template v-slot:cell(followupDate)="data">
                                    {{ callDate(data.item.followupDate, "fullDate") }}
                                </template>
                            </b-table>
                        </div>
                    </b-modal>
                    <!--popup end for View Enquiry details-->
                </div>
                <div>
                    <!-- popup start for Enquiry details Update -->
                    <b-modal id="editEnquiry" title="update Enquiry" ref="modal" hide-footer>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Name</label>
                                <b-form-input type="text" v-model.trim="$v.enquiry.name.$model" class="form-control" placeholder="Enter student name" :class="{
                    'is-invalid': $v.enquiry.name.$error,
                    'is-valid': !$v.enquiry.name.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Contact</label>
                                <b-form-input type="number" v-model.number.lazy="$v.enquiry.contact.$model" class="form-control" placeholder="Enter contact number" :class="{
                    'is-invalid': $v.enquiry.contact.$error,
                    'is-valid': !$v.enquiry.contact.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Age</label>
                                <b-form-input type="number" v-model.number.lazy="$v.enquiry.age.$model" class="form-control" placeholder="Enter student age" :class="{
                    'is-invalid': $v.enquiry.age.$error,
                    'is-valid': !$v.enquiry.age.$invalid,
                  }"></b-form-input>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Qualification</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.qualification.$model" :class="{
                    'is-invalid': $v.enquiry.qualification.$error,
                    'is-valid': !$v.enquiry.qualification.$invalid,
                  }">
                                    <option value="null" disabled>Select Qualification</option>
                                    <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                        <strong>{{ q.text }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.course.$model" :class="{
                    'is-invalid': $v.enquiry.course.$error,
                    'is-valid': !$v.enquiry.course.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Source</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.source.$model" :class="{
                    'is-invalid': $v.enquiry.source.$error,
                    'is-valid': !$v.enquiry.source.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Mode</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.mode.$model" :options="options" :class="{
                    'is-invalid': $v.enquiry.mode.$error,
                    'is-valid': !$v.enquiry.mode.$invalid,
                  }"></b-form-select>
                            </div>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Branch</label>
                                <b-form-select class="form-control" v-model="$v.enquiry.branch.$model" :class="{
                    'is-invalid': $v.enquiry.mode.$error,
                    'is-valid': !$v.enquiry.mode.$invalid,
                  }">
                                    <option value="null" disabled>Select Branch</option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" @click="updateEnquiry()" class="px-3" variant="primary">Update</b-button>
                        </div>
                    </b-modal>
                    <!-- popup start for Enquiry details Update end-->
                </div>
                <div>
                    <!-- popup start for follow insert -->
                    <b-modal id="followInsert" title="Follow Update" ref="modal" hide-footer>
                        <h5 class="px-5">Name : {{ enquiry.name }}</h5>
                        <h5 class="px-5">Contact : {{ enquiry.contact }}</h5>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Followup</label>
                                <div>
                                    <b-form-input format="DD-MM-YYYY h:i:s" v-model="enquiryFollowup.followupDate" :class="{
                      'is-invalid': $v.enquiryFollowup.followupDate.$error,
                      'is-valid': !$v.enquiryFollowup.followupDate.$invalid,
                    }" type="datetime-local" min="new Time()"></b-form-input>
                                    <div class="valid-feedback">date time is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryFollowup.followupDate.required">date or time is invalid!</span>
                                    </div>
                                </div>
                                <div>
                                    <b-form-textarea class="form-control" v-model="enquiryFollowup.remark" :class="{
                      'is-invalid': $v.enquiryFollowup.remark.$error,
                      'is-valid': !$v.enquiryFollowup.remark.$invalid,
                    }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                    <div class="valid-feedback">remark is valid!</div>
                                    <div class="invalid-feedback">
                                        <span v-if="!$v.enquiryFollowup.remark.required">remark is invalid!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3 mt-2">
                            <b-button type="submit" @click="enquiryFollowupUpdate()" class="px-3" variant="primary">Update</b-button>
                        </div>
                    </b-modal>
                    <!-- popup start for follow insert end-->
                </div>
            </b-container>

            <b-modal ref="dateClosedRange" hide-footer id="dateRange" title="Select Date">
                <div>
                    <label for="from-datepicker">Choose The From date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
              'is-invalid': $v.from.$error,
              'is-valid': !$v.from.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div>
                    <label for="to-datepicker">Choose The To date</label>
                    <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
              'is-invalid': $v.to.$error,
              'is-valid': !$v.to.$invalid,
            }" type="date"></b-form-input>
                </div>
                <div class="text-center mt-3">
                    <b-button variant="outline-success" type="submit" @click="getClosedDateRange()">Submit</b-button>
                </div>
            </b-modal>
        </div>
        <b-modal id="enquiryClosed" title="Enquiry Closed" no-close-on-backdrop ref="modal" hide-footer>
            <label class="mt-3">Closed for</label>
            <b-form-select class="form-control" v-model="closed" :options="closedOption"></b-form-select>
            <!-- closed for follow insert -->

            <div v-if="closed === 'admission'" class="px-2 pt-2">
                <h5 class="px-5">Name : {{ update.name }}</h5>
                <h5 class="px-5">Contact : {{ update.contact }}</h5>
                <div class="form-row mt-3 px-5">
                    <div class="form-row mt-3 px-3">
                        <div class="form-group my-2">
                            <label>CourseType</label>
                            <div>
                                <b-form-select class="form-control" v-model="courseType" :options="courseOptions" :class="{'is-invalid': $v.courseType.$error,'is-valid': !$v.courseType.$invalid,}"></b-form-select>
                                <div class="valid-feedback">course type is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.courseType.required">please select a course type!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- closed for follow insert end -->

            <div class="mt-3 mt-2 text-center">
                <b-button type="submit" @click="disabledButton = true,enquiryClosed()" :disabled="disabledButton" class="px-3" variant="primary">closed To {{closed}}</b-button> &nbsp;

            </div>
        </b-modal>
        <b-modal v-model="admissionModal" size="lg" hide-footer hide-header>
            <invoice-apply :disableButton="false" :invoicedata="update" :courseType="courseType" :organization="organization" @close-modal="closeModal" @close-icon="closeIcon"></invoice-apply>
        </b-modal>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
import EnquiryService from "../service/EnquiryService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import FollowupService from "../service/FollowupService";
// import AgentService from "../service/AgentService"
import InvoiceApply from '@/components/InvoiceApply';
import TeamService from "../service/TeamService";
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import qualification from "../assets/json_file/qualification.json";
import moment from "moment-timezone";
import Swal from 'sweetalert2';
// import AdminEnquiryService from '../service/AdminEnquiryService';
export default {
    name: "Enquiry",
    components: {
        InvoiceApply
    },
    data() {
        return {
            followupDateRemark: [],
            fromDate: "",
            toDate: "",
            agentId: "",
            // Enquiry table show
            // isShowEnquiry: true,
            // Enquiry table hide and closed tables show
            // isShowClosed: false,
            // Enquiry details render in update model form
            enquiry: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                createdAt: "",
                callId: "",
            },
            enquiryFollowup: {
                followupDate: "",
                remark: "",
            },
            // Closed data render in table
            closedEnquiries: [],
            closedFields: [{
                    key: "callCreatedAt",
                    label: "Call Date",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "createdAt",
                    label: "Enquiry Date",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            submitstatus: "",
            qualificationList: qualification,
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            // Enqruiries all data render in table
            allEnquiries: [],
            fields: [{
                    key: "callCreatedAt",
                    label: "Call Date",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "createdAt",
                    label: "Enquiry Date",
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],

            from: "",
            to: "",
            fromClosed: "",
            toClosed: "",
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true,
                },
                {
                    value: "today",
                    text: "Today",
                },
                {
                    value: "yesterday",
                    text: "Yesterday",
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days",
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days",
                },
                {
                    value: "dateRange",
                    text: "Select Range",
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            //   dateClosedSelect: [
            //     {
            //       value: null,
            //       text: "Dates",
            //       disabled: true,
            //     },
            //     {
            //       value: "today",
            //       text: "Today",
            //     },
            //     {
            //       value: "yesterday",
            //       text: "Yesterday",
            //     },
            //     {
            //       value: "lastWeek",
            //       text: "Past 7 days",
            //     },
            //     {
            //       value: "lastMonth",
            //       text: "Past 30 days",
            //     },
            //     {
            //       value: "dateRange",
            //       text: "Select Range",
            //     },
            //   ],
            //All Courses data render in table
            allCourses: "",
            //All Sources data render in table
            allSources: "",
            //All Branches data render in table
            allBranches: "",
            // Enquiry followup details render in view modal table
            followup: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            // for loader
            loading: false,
            // for enquiry pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            // for closed pagination
            closedRows: 100,
            closedCurrentPage: 1,
            closedPageCount: 5,
            closedPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            closedTotalCount: 0,
            // for enquiry search in table
            keyword: "",
            searchRows: 100,
            searchCurrentPage: 1,
            searchpageCount: 5,
            searchPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalsearchCount: 0,
            // for closed enquiry search in table
            closedKeyword: "",
            selectedCourse: "Courses",
            selectedSource: "Sources",
            selectedBranch: "Branches",
            selectedDates: null,
            selectedDate: null,
            // selectedSource: null,
            selectedClosedCourse: "Courses",
            selectedClosedSource: "Sources",
            selectedClosedBranch: "Branches",
            selectedClosedDates: null,
            selectedClosedDate: null,

            sortCourseRows: 100,
            sortCourseurrentPage: 1,
            sortCoursepageCount: 5,
            sortCoursePageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCourseCount: 0,
            isRoleCounsellor: false,
            isShowCounsellor: true,
            isShowCounsellorClosed: false,
            branch: "",
            data: "",
            counsellorTotalCount: 0,
            counsellorCurrentPage: 1,
            counsellorPageCount: 5,
            counsellorPageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "show lot",
                },
            ],
            counsellorEnquiryActive: true,
            counsellorClosedEnquiryActive: false,
            counsellorKeyword: "",
            counsellorClosedKeyword: "",
            call: {
                callId: "",
                agent: null,
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            },
            disabledButton: false,
            fromCounsellorDate: "",
            toCounsellorDate: "",
            fromCounsellor: "",
            toCounsellor: "",
            admissionModal: false,
            invoice: {
                userName: "",
                contact: "",
                courseName: "",
                price: "",
                alternativeContact: "",
                mode: "",
                organization: "",
            },
            closedOption: [{
                    value: null,
                    text: "select an option",
                    disabled: true,
                },
                {
                    value: "admission",
                    text: "Admission",
                },
                {
                    value: "notintrested",
                    text: "Notintrested",
                },
            ],
            closed: null,
            update: {
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
            },
            courseType: null,
            courseOptions: [{
                    value: null,
                    text: "select course type",
                    disabled: true,
                },
                {
                    value: 'Online Test',
                    text: 'Online Test',
                },
                {
                    value: 'Offline Test',
                    text: 'Offline Test',
                },
                {
                    value: 'Online Course',
                    text: 'Online Course',
                },
                {
                    value: 'Offline Course',
                    text: 'Offline Course',
                },
                {
                    value: 'Online Book',
                    text: 'Online Book',
                },
                {
                    value: 'Offline Book',
                    text: 'Offline Book',
                },
            ],
            organization: "",
        };
    },
    //  async mounted(){
    //     await this.global()
    //     await this.getCount();
    //     await this.getAllCourses();
    //     await this.getAllSources();
    //     await this.getAllBranches();
    //     this.loading = false;
    //   },
    mounted() {

        if (localStorage.getItem("agentId") != null) {
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            this.counsellorData();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            } else {
                this.isRoleCounsellor = false;
                return new Promise((resolve, reject) => {
                    this.global()
                        .then((response) => {
                            this.getCount();
                            this.getClosedCount();
                            this.getAllCourses();
                            this.getAllSources();
                            this.getAllBranches();
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Account Deactivated',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },

    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        },
        closedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getClosedCount();
            }
        },
        counsellorKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCounsellorCount();
            }
        },
        counsellorClosedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCounsellorClosedCount();
            }
        },
    },

    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
        fromCounsellorDate: {
            required,
        },
        toCounsellorDate: {
            required,
        },
        call: {
            name: {
                required,
                minLength: minLength(3),
            },

            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            alternativeContact: {
                //   optionalField,
                //   minLength: minLength(10),
                // maxLength: maxLength(10),
                // required: requiredIf(value => value.length == 10 ),
            },
            age: {
                required,
                Number,
                minLength: minLength(2),
                maxLength: maxLength(2),
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            branch: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        enquiry: {
            name: {
                required,
                minLength: minLength(3),
            },
            contact: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(10),
            },
            age: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(2),
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
            branch: {
                required,
            },
        },
        enquiryFollowup: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        courseType: {
            required
        }
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async counsellorData() {
            await this.setCounsellor();
            //    console.log("counsellor count callng")
            await this.getCounsellorCount();
            //    console.log("counsellor closed count callng")
            await this.getCounsellorClosedCount();
            //    console.log("getAllCourses callng")
            await this.getAllCourses();
            //    console.log("getAllSources callng")
            await this.getAllSources();
            await this.getAllBranches();
        },
        setCounsellor() {
            this.branch = JSON.parse(localStorage.getItem("branch"));
            this.call.branch = this.branch;
            //  console.log(this.call.branch)
            if (localStorage.getItem("ROLE_COUNSELLOR")) {
                this.isRoleCounsellor = true;
            } else {
                this.isRoleCounsellor = false;
            }
        },
        //==============OPEN ENQUIRY=================

        // getAllEnquiries: function () {
        //     this.showEnquiry();
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //         EnquiryService.getAllEnquiries(this.agentId)
        //             .then((response) => {
        //                 this.allEnquiries = response.data;
        //                 this.getCount();
        //                 this.loading = false;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 this.loading = false;
        //                 reject(err);
        //             });
        //     });
        // },

        getAllSortCount: function (
            selectedCourse,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            // console.log("selectedCourse=== ", selectedCourse);
            // console.log("selectedSource=== ", selectedSource);
            // console.log("selectedBranch=== ", selectedBranch);
            // console.log("selectedDates=== ", selectedDates);

            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.enquiryDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedCourse == "Courses" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedSource == "Sources" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branches" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.enquiryDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.enquiryDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedCourse.courseId,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortEnquiries = true;
                this.paginationEnquiries = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/enquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/enquiry/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.allEnquiries = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        //=================================CLOSED ENQUIRY========================================================

        getAllSortClosedCount: function (
            selectedClosedCourse,
            selectedClosedSource,
            selectedClosedBranch,
            selectedClosedDates
        ) {
            // console.log("selectedClosedCourse=== ", selectedClosedCourse);
            // console.log("selectedClosedSource=== ", selectedClosedSource);
            // console.log("selectedClosedBranch=== ", selectedClosedBranch);
            // console.log("selectedClosedDates=== ", selectedClosedDates);

            if (selectedClosedDates == "dateRange") {
                this.getClosedDateRangeReset();
                this.$refs.dateRangeClosed.show();
                this.selectedClosedDates = "dateBetween";
            } else {
                if (
                    selectedClosedCourse == "Courses" ||
                    selectedClosedCourse == "AllClosedCourses"
                ) {
                    selectedClosedCourse = {
                        courseId: "",
                    };
                }
                if (
                    selectedClosedSource == "Sources" ||
                    selectedClosedSource == "AllClosedSources"
                ) {
                    selectedClosedSource = {
                        sourceId: "",
                    };
                }
                if (
                    selectedClosedBranch == "Branches" ||
                    selectedClosedBranch == "AllClosedBranches"
                ) {
                    selectedClosedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedClosedDates != null) {
                    var date = new Date();
                    if (selectedClosedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedClosedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedClosedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRangeClosed.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedClosedDates == "dateBetween"
                    ) {
                        this.$refs.dateRangeClosed.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (
                    selectedClosedDates == null ||
                    selectedClosedDates == "dateBetween"
                ) {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: this.agentId,
                    courseId: selectedClosedCourse.courseId,
                    sourceId: selectedClosedSource.sourceId,
                    branchId: selectedClosedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortEnquiries = true;
                this.paginationEnquiries = false;
                this.searchPagination = false;
                this.closedCurrentPage = 1;
                this.closedKeyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/enquiry/closedEnquiry/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortClosedCount======", response.data);
                            this.closedTotalCount = response.data;
                            this.getAllSortClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/enquiry/closedEnquiry/all/sort/" +
                        closedCurrentPage +
                        "/" +
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        // console.log("getAllSortClosedPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getEnquiry: function (enquiry) {
            this.enquiry = enquiry;
            this.closed = null;
            this.courseType = null;
            // console.log("enquiry");
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(enquiry.callId)
                FollowupService.getFollowupCreatedDate(enquiry.callId)
                    .then((response) => {
                        this.update = {
                            enquiryId: enquiry.enquiryId,
                            callId: enquiry.callId,
                            name: enquiry.name,
                            contact: enquiry.contact,
                            alternativeContact: enquiry.alternativeContact,
                            age: enquiry.age,
                            qualification: enquiry.qualification,
                            course: enquiry.course,
                            source: enquiry.source,
                            mode: enquiry.mode,
                            branch: enquiry.branch,

                            // followup: response.data.followup
                        };
                        this.followupDateRemark = response.data;
                        // console.log(this.followupDateRemark);
                        // this.followup.forEach(element => {
                        //   //console.log(element);
                        // });
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCount: function () {
            this.loading = true;
            this.allSortEnquiries = false;
            this.paginationEnquiries = true;
            this.searchPagination = false;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                EnquiryService.getCount(this.agentId)
                    .then((response) => {
                        this.totalCount = response.data;
                        // //console.log("this.totalCount",this.totalCount);
                        // this.sortCoursePagination = false;
                        // this.sortSourcePagination = false;
                        // this.sortBranchPagination = false;
                        // this.sortDatePagination = false;
                        // this.searchPagination = false;
                        // this.paginationEnquiries = true;
                        // this.currentPage = 1;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllPageable(currentPage, pageCount, this.agentId)
                    .then((response) => {
                        this.allEnquiries = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationEnquiries) {
                // console.log(this.paginationEnquiries, "paginationEnquiries");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortEnquiries) {
                // console.log(this.allSortEnquiries, "allSortEnquiries");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
            //   if (this.paginationEnquiries) {
            //     //   //console.log(this.paginationEnquiries,"paginationEnquiries")
            //     this.getAllPageable(currentPage, pageCount);
            //   } else if (this.sortCoursePagination) {
            //     this.getAllCoursePageable(currentPage, pageCount, this.data);
            //   } else if (this.sortSourcePagination) {
            //     this.getAllSourcePageable(currentPage, pageCount, this.data);
            //   } else if (this.sortBranchPagination) {
            //     this.getAllBranchPageable(currentPage, pageCount, this.data);
            //   } else if (this.sortDatePagination) {
            //     this.getAllDatePageable(currentPage, pageCount, this.data);
            //   } else if (this.searchPagination) {
            //     this.getAllSearchPageable(
            //       currentPage,
            //       pageCount,
            //       this.keyword.toLowerCase()
            //     );
            //   }
        },
        searchName: function (keyword) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                EnquiryService.searchName(keyword.toLowerCase(), this.agentId)
                    .then((response) => {
                        this.getSearchCount(keyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSearchCount: function () {
            this.searchPagination = true;
            this.paginationEnquiries = false;
            this.allSortEnquiries = false;
            this.currentPage = 1;
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            //   this.selectedSource = "Sources";
            //   this.selectedCourse = "Courses";
            //   this.selectedBranch = "Branches";
            //   this.selectedDate = null;
            // //console.log("search pagecount", this.keyword)
            return new Promise((resolve, reject) => {
                EnquiryService.getSearchCount(this.agentId, this.keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // //console.log("search pagecount", response.data)
                        // this.sortCoursePagination = false;
                        // this.sortSourcePagination = false;
                        // this.sortBranchPagination = false;
                        // this.sortDatePagination = false;
                        // this.searchPagination = true;
                        // this.paginationEnquiries = false;
                        // this.currentPage = 1;
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            this.loading = true;
            // //console.log("search page calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        this.agentId,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //=============OPEN ENQUIRY SORTING=================

        // getCourseDetail: function (course) {
        //   if (this.selectedCourse == "AllCourse") {
        //     // //console.log("All Course Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("Course Calling")
        //     let data = {
        //       courseId: course.courseId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/enquiry/sort/course/" + this.agentId, data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getCourseCount(data);
        //           // //console.log("====", response.data);
        //           this.selectedSource = "Sources";
        //           this.selectedBranch = "Branches";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getBranchDetail: function (branch) {
        //   if (this.selectedBranch == "AllBranch") {
        //     // //console.log("All Branch Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("Branch Calling")
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/enquiry/sort/branch/" + this.agentId, data)
        //         .then((response) => {
        //           this.allEnquiries = response.data;
        //           this.getBranchCount(data);
        //           // //console.log(response.data);
        //           this.selectedCourse = "Courses";
        //           this.selectedSource = "Sources";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getSourceDetail: function (source) {
        //   if (this.selectedSource == "AllSource") {
        //     // //console.log("AllSource Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("Source Calling")
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/enquiry/sort/source/" + this.agentId, data)
        //         .then((response) => {
        //           // //console.log(response.data);
        //           this.allEnquiries = response.data;
        //           this.getSourceCount(data);
        //           this.selectedCourse = "Courses";
        //           this.selectedBranch = "Branches";
        //           this.selectedDate = "Date";
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getEnquiriesBetweenDates: function () {
        //   this.selectedCourse = "Courses";
        //   this.selectedSource = "Sources";
        //   this.selectedBranch = "Branches";
        //   if (this.selectedDate == "dateRange") {
        //     // this.modalRender = true;
        //     this.$refs.dateRange.show();
        //     // AdmissionService.getByDate(this.agentId, this.fromDate,this.toDate)
        //   } else {
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       // AdmissionService.getToday()
        //       EnquiryService.getByDate(this.agentId, this.selectedDate)
        //         .then((response) => {
        //           this.getDateCount();
        //           this.loading = false;

        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        //     getDateRange: function() {
        //   let data={
        //     fromDate:new Date(this.fromDate),
        //     toDate:new Date(this.toDate)
        //   }
        // //   //console.log(data);
        //  return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       // AdmissionService.getToday()
        //     //   //console.log("Selected Date Range",data);
        //       EnquiryService.getDateRange(this.agentId,data)
        //         .then((response) => {
        //           // this.$refs.dateRange.hide();

        //           this.allEnquiries = response.data;
        //         //   //console.log(this.allEnquiries, "Admissions By Dates");
        //           // this.getDateCount();
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        // },

        //==========OPEN ENQUIRY SORTING PAGINATION=================

        // getCourseCount: function (selectedCourse) {
        //       //console.log("coursecount", this.data);
        //       if (selectedCourse == "AllCourses") {
        //         //console.log("course if");
        //         this.getCount();
        //         //console.log("SELECTED COURSE", selectedCourse);
        //       } else {
        //         //console.log("Course Calling");
        //         //console.log(this.selectedCourse);
        //          this.data = {
        //           courseId: selectedCourse.courseId,
        //         };
        //         this.loading = true;
        //       return new Promise((resolve, reject) => {
        //         CallService.getCourseCount(this.agentId, this.data)
        //           .then((response) => {
        //             this.totalCount = response.data;
        //             this.getAllCoursePageable(this.currentPage,this.pageCount,this.data);
        //             this.sortCoursePaginationCalls = true;
        //             this.sortSourcePaginationCalls = false;
        //             this.sortBranchPaginationCalls = false;
        //             this.searchPagination = false;
        //             this.paginationCalls = false;
        //             resolve(response);
        //           })
        //           .catch((err) => {
        //             reject(err);
        //           });
        //       });
        //       }
        //     },

        // getCourseCount: function (selectedCourse) {
        //   this.selectedSource = "Sources";
        //   this.selectedBranch = "Branches";
        //   this.selectedDate = null;
        //   if (selectedCourse == "AllCourse") {
        //     // //console.log("All Course Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("Course Calling")
        //     this.data = {
        //       courseId: selectedCourse.courseId,
        //     };
        //     // //console.log("coursecount", this.data)
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       EnquiryService.getCourseCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllCoursePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePagination = true;
        //           this.sortSourcePagination = false;
        //           this.sortBranchPagination = false;
        //           this.searchPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllCoursePageable: function (currentPage, pageCount, data) {
        //   // //console.log("coursepageable calling")
        //   return new Promise((resolve, reject) => {
        //     EnquiryService.getAllCoursePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getBranchCount: function (selectedBranch) {
        //   this.selectedCourse = "Courses";
        //   this.selectedSource = "Sources";
        //   this.selectedDate = null;
        //   if (selectedBranch == "AllBranch") {
        //     // //console.log("All branch Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("branch Calling")
        //     this.data = {
        //       branchId: selectedBranch.branchId,
        //     };
        //     // //console.log("branchcount", this.data)
        //     this.loading = true;
        //     return new Promise((resolve, reject) => {
        //       EnquiryService.getBranchCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllBranchPageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePagination = false;
        //           this.sortSourcePagination = false;
        //           this.sortBranchPagination = true;
        //           this.sortDatePagination = false;
        //           this.searchPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllBranchPageable: function (currentPage, pageCount, data) {
        //   // //console.log("BranchPageable calling")
        //   return new Promise((resolve, reject) => {
        //     EnquiryService.getAllBranchPageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getSourceCount: function (selectedSource) {
        //   this.selectedCourse = "Courses";
        //   this.selectedBranch = "Branches";
        //   this.selectedDate = null;
        //   if (selectedSource == "AllSource") {
        //     // //console.log("AllSource Calling")
        //     this.getCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("Source Calling")
        //     this.data = {
        //       sourceId: selectedSource.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       EnquiryService.getSourceCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllSourcePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           this.sortCoursePagination = false;
        //           this.sortSourcePagination = true;
        //           this.sortBranchPagination = false;
        //           this.sortDatePagination = false;
        //           this.searchPagination = false;
        //           this.paginationEnquiries = false;
        //           this.currentPage = 1;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getAllSourcePageable: function (currentPage, pageCount, data) {
        //   // //console.log("SourcePageable calling")
        //   return new Promise((resolve, reject) => {
        //     EnquiryService.getAllSourcePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         resolve(response);
        //         this.loading = false;
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        // },
        // getDateCount: function (selectedDate) {
        //   this.selectedCourse = "Courses";
        //   this.selectedSource = "Sources";
        //   this.selectedBranch = "Branches";

        //   this.sortCoursePagination = false;
        //   this.sortSourcePagination = false;
        //   this.sortBranchPagination = false;
        //   this.sortDatePagination = true;
        //   this.searchPagination = false;
        //   this.paginationEnquiries = false;
        //   this.currentPage = 1;
        //   this.loading = true;
        //   if (selectedDate == "dateRange") {
        //     this.$refs.dateRange.show();
        //     this.selectedDate = null;
        //     this.getDateRangeReset();
        //     this.loading = false;
        //   } else {
        //     var date = new Date();
        //     if (selectedDate == "today") {
        //       this.fromDate = new Date(date.setHours(0, 0, 0, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDate == "yesterday") {
        //       this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(23, 59, 59, 0));
        //     } else if (selectedDate == "lastWeek") {
        //       this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(167, 59, 59, 0));
        //     } else if (selectedDate == "lastMonth") {
        //       this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
        //       this.toDate = new Date(date.setHours(719, 59, 59, 0));
        //     }
        //     this.fromDateFormat = moment(String(this.fromDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.toDateFormat = moment(String(this.toDate)).format(
        //       "yyyy-MM-DD HH:mm"
        //     );
        //     this.data = {
        //       from: this.fromDateFormat,
        //       to: this.toDateFormat,
        //     };
        //     return new Promise((resolve, reject) => {
        //       EnquiryService.getDateCount(this.agentId, this.data)
        //         .then((response) => {
        //           this.totalCount = response.data;
        //           this.getAllDatePageable(
        //             this.currentPage,
        //             this.pageCount,
        //             this.data
        //           );
        //           resolve(response);
        //           this.loading = false;
        //         })
        //         .catch((err) => {
        //           this.loading = false;
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        getDateRange: function () {
            this.$v.from.$touch();
            this.$v.to.$touch();
            if (this.$v.from.$invalid && this.$v.to.$invalid) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
                var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                this.data = {
                    from: from,
                    to: to,
                };
                this.loading = true;
                return new Promise((resolve, reject) => {
                    EnquiryService.getDateCount(this.agentId, this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.getDateRangeReset();
                            this.getAllDatePageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getDateRangeReset: function () {
            this.$refs.enquiryDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        // getAllDatePageable: function (currentPage, pageCount, data) {
        //   return new Promise((resolve, reject) => {
        //     EnquiryService.getAllDatePageable(
        //       currentPage,
        //       pageCount,
        //       this.agentId,
        //       data
        //     )
        //       .then((response) => {
        //         this.allEnquiries = response.data;
        //         this.loading = false;
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         this.loading = false;
        //         reject(err);
        //       });
        //   });
        // },

        //==================DROPDOWN RENDER=======================
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //========================================================

        //=======================================*****CLOSED ENQUIRIES*****========================================

        closedSwitchPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            // console.log("switchPage calling");

            if (this.paginationEnquiries) {
                this.closedGetAllPageable(closedCurrentPage, closedPageCount);
                // console.log("this.paginationEnquiries===", this.paginationEnquiries);
                // console.log("this.allSortEnquiries===", this.allSortEnquiries);
            } else if (this.allSortEnquiries) {
                this.getAllSortClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
                // console.log("this.paginationEnquiries===", this.paginationEnquiries);
                // console.log("this.allSortEnquiries===", this.allSortEnquiries);
            } else if (this.searchPagination) {
                this.getAllSearchClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.closedKeyword
                );
                // console.log(this.searchPagination, "getAllClosedSearchPageable");
            }
            //   if (this.paginationClosedEnquiries) {
            //     //   //console.log(this.paginationClosedEnquiries,"paginationClosedEnquiries")
            //     this.closedGetAllPageable(ClosedCurrentPage, closedPageCount);
            //   } else if (this.sortClosedCoursePagination) {
            //     //   //console.log(this.sortClosedCoursePagination,"sortClosedCoursePagination")
            //     this.getAllCourseClosedPageable(
            //       ClosedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedSourcePagination) {
            //     this.getAllSourceClosedPageable(
            //       ClosedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedBranchPagination) {
            //     this.getAllBranchClosedPageable(
            //       ClosedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.sortClosedDatePagination) {
            //     this.getAllClosedDatePageable(
            //       ClosedCurrentPage,
            //       closedPageCount,
            //       this.data
            //     );
            //   } else if (this.searchClosedPagination) {
            //     this.getAllSearchClosedPageable(
            //       ClosedCurrentPage,
            //       closedPageCount,
            //       this.closedKeyword.toLowerCase()
            //     );
            //   }
        },

        // getAllClosed: function () {
        //         this.loading = true;
        //         this.showClosed();
        //         return new Promise((resolve, reject) => {
        //             EnquiryService.getAllClosed(this.agentId)
        //                 .then((response) => {
        //                     this.closedEnquiries = response.data;
        //                     this.closedGetCount();
        //                     this.loading = false;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     this.loading = false;
        //                     reject(err);
        //                 });
        //         });
        //     },
        // getCount: function () {
        //         return new Promise((resolve, reject) => {
        //             EnquiryService.getCount(this.agentId)
        //                 .then((response) => {
        //                     this.totalCount = response.data;
        //                     //console.log("this.totalCount",this.totalCount);
        //                     this.sortCoursePagination = false;
        //                     this.sortSourcePagination = false;
        //                     this.sortBranchPagination = false;
        //                     this.searchPagination = false;
        //                     this.paginationEnquiries = true;
        //                     this.getAllPageable(this.currentPage,this.pageCount);
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     },
        //     getAllPageable: function (currentPage,pageCount) {
        //         this.loading = true;
        //         return new Promise((resolve, reject) => {
        //             EnquiryService.getAllPageable(
        //                     currentPage,
        //                     pageCount,
        //                     this.agentId
        //                 )
        //                 .then((response) => {
        //                     this.allEnquiries=response.data;
        //                     this.loading = false;
        //                     resolve(response);

        //                 })
        //                 .catch((err) => {
        //                     this.loading = false;
        //                     reject(err);
        //                 });
        //         });
        //     },
        getClosedCount: function () {
            return new Promise((resolve, reject) => {
                this.paginationEnquiries = true;
                this.searchPagination = false;
                this.allSortEnquiries = false;
                this.closedCurrentPage = 1;
                this.selectedClosedCourse = "Courses";
                this.selectedClosedSource = "Sources";
                this.selectedClosedBranch = "Branches";
                this.selectedClosedAgent = "Agent";
                this.selectedClosedDates = null;
                this.closedKeyword = "";
                this.loading = true;
                EnquiryService.closedGetCount(this.agentId)
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        // this.sortClosedCoursePagination = false;
                        // this.sortClosedSourcePagination = false;
                        // this.sortClosedBranchPagination = false;
                        // this.sortClosedDatePagination = false;
                        // this.searchClosedPagination = false;
                        // this.paginationClosedEnquiries = true;
                        // this.closedCurrentPage = 1;
                        this.closedGetAllPageable(
                            this.closedCurrentPage,
                            this.closedPageCount
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closedGetAllPageable: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                EnquiryService.closedGetAllPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closedSearchName: function (closedKeyword) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                EnquiryService.closedSearchName(
                        closedKeyword.toLowerCase(),
                        this.agentId
                    )
                    .then((response) => {
                        this.getSearchClosedCount(closedKeyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSearchClosedCount: function () {
            this.searchPagination = true;
            this.paginationEnquiries = false;
            this.allSortEnquiries = false;
            this.closedCurrentPage = 1;
            this.loading = true;
            (this.selectedclosedCourse = "Courses"),
            (this.selectedclosedSource = "Sources"),
            (this.selectedclosedBranch = "Branches");
            this.selectedClosedAgent = "Agents";
            this.selectedClosedDates = null;
            //   this.selectedClosedCourse = "Courses";
            //   this.selectedClosedSource = "Sources";
            //   this.selectedClosedBranch = "Branches";
            //   this.selectedClosedDate = null;
            // //console.log("search pagecount", this.closedKeyword)
            return new Promise((resolve, reject) => {
                EnquiryService.getSearchClosedCount(
                        this.agentId,
                        this.closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        // //console.log("SearchClosedCount", response.data)
                        // this.sortClosedCoursePagination = false;
                        // this.sortClosedSourcePagination = false;
                        // this.sortClosedBranchPagination = false;
                        // this.sortClosedDatePagination = false;
                        // this.searchClosedPagination = true;
                        // this.paginationClosedEnquiries = false;
                        // this.closedCurrentPage = 1;
                        this.getAllSearchClosedPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            this.closedKeyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            closedKeyword
        ) {
            // //console.log("closed search page calling")
            this.loading = true;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllSearchClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        closedKeyword.toLowerCase()
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        //===============CLOSED ENQUIRIES SORT===================

        // getCourseClosedDetail: function (course) {
        //     if (this.selectedClosedCourse == 'AllCourse') {
        //         //console.log("All Course Calling")
        //         this.getAllClosed();
        //         // this.getCount();
        //         // this.getAllPageable();
        //     } else {
        //         //console.log("closedsortCourse Calling")
        //         let data = {
        //             courseId: course.courseId
        //         }
        //         return new Promise((resolve, reject) => {
        //             this.loading = true;
        //             axios
        //                 .post("/enquiry/closedsort/course/" + this.agentId, data)
        //                 .then((response) => {
        //                     this.closedEnquiries = response.data;
        //                     //console.log("====", response.data);
        //                     this.getCourseClosedCount(data);
        //                     this.selectedClosedSource = "Sources",
        //                         this.selectedClosedBranch = "Branches",
        //                         this.selectedClosedDate = "Date",
        //                         this.loading = false;
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     reject(err);
        //                 });
        //         });
        //     }
        // },
        // getBranchClosedDetail: function (branch) {
        //   if (this.selectedClosedBranch == "AllBranch") {
        //     // //console.log("All Branch Calling")
        //     this.getClosedCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     // //console.log("closedsortBranch Calling")
        //     let data = {
        //       branchId: branch.branchId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/enquiry/closedsort/branch/" + this.agentId, data)
        //         .then((response) => {
        //           this.closedEnquiries = response.data;
        //           this.getBranchClosedCount(data);
        //           // //console.log(response.data);
        //           (this.selectedClosedCourse = "Courses"),
        //             (this.selectedClosedSource = "Sources"),
        //             (this.selectedClosedDate = "Date"),
        //             (this.loading = false);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getSourceClosedDetail: function (source) {
        //   if (this.selectedClosedSource == "AllSource") {
        //     //console.log("Closed All Source Calling")
        //     this.getClosedCount();
        //     // this.getCount();
        //     // this.getAllPageable();
        //   } else {
        //     //console.log("closedsortSource Calling")
        //     let data = {
        //       sourceId: source.sourceId,
        //     };
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       axios
        //         .post("/enquiry/closedsort/source/" + this.agentId, data)
        //         .then((response) => {
        //           //console.log(response.data);
        //           this.closedEnquiries = response.data;
        //           this.getSourceClosedCount(data);
        //           (this.selectedClosedCourse = "Courses"),
        //             (this.selectedClosedBranch = "Branches"),
        //             (this.selectedClosedDate = "Date"),
        //             (this.loading = false);
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },
        // getClosedEnquiriesBetweenDates: function () {
        //   this.selectedClosedCourse = "Courses";
        //   this.selectedClosedSource = "Sources";
        //   this.selectedClosedBranch = "Branches";
        //   if (this.selectedClosedDate == "dateRange") {
        //     // this.modalRender = true;
        //     //console.log("closed date range")
        //     this.$refs.dateRangeClosed.show();
        //     // AdmissionService.getByDate(this.agentId, this.fromDate,this.toDate)
        //   } else {
        //     return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       //console.log("calling Sort By Date");
        //       // AdmissionService.getToday()
        //       //console.log("Selected Date Range", this.selectedClosedDate);
        //       EnquiryService.getByClosedDate(this.agentId, this.selectedClosedDate)
        //         .then((response) => {
        //           //console.log(response.data, " Enquriys By Dates");
        //           this.closedEnquiries = response.data;
        //           this.getClosedDateCount();
        //           this.loading = false;
        //           (this.selectedClosedCourse = "Courses"),
        //             (this.selectedClosedSource = "Sources"),
        //             (this.selectedClosedBranch = "Branches"),
        //             resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        //   }
        // },

        //      getClosedDateRange: function() {
        //   let data={
        //     fromDate:new Date(this.fromDate),
        //     toDate:new Date(this.toDate)
        //   }
        //   //console.log(data);
        //  return new Promise((resolve, reject) => {
        //       this.loading = true;
        //       // AdmissionService.getToday()
        //       //console.log("Selected Date Range",data);
        //       EnquiryService.getClosedDateRange(this.agentId,data)
        //         .then((response) => {
        //           // this.$refs.dateRange.hide();

        //           this.closedEnquiries = response.data;
        //           // this.getDateCount();
        //           this.loading = false;
        //           resolve(response);
        //         })
        //         .catch((err) => {
        //           reject(err);
        //         });
        //     });
        // },
        //=============CLOSED ENQUIRY SORTING PAGINATION=======================

        getClosedDateCount: function (selectedClosedDate) {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";

            this.sortClosedCoursePagination = false;
            this.sortClosedSourcePagination = false;
            this.sortClosedBranchPagination = false;
            this.sortClosedAgentPagination = false;
            this.sortClosedDatePagination = true;
            this.searchClosedPagination = false;
            this.paginationClosedEnquiries = false;
            this.closedCurrentPage = 1;
            if (selectedClosedDate == "dateRange") {
                this.$refs.dateClosedRange.show();
                this.selectedClosedDate = null;
                this.getClosedDateRangeReset();
            } else {
                var date = new Date();
                if (selectedClosedDate == "today") {
                    this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedClosedDate == "yesterday") {
                    this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedClosedDate == "lastWeek") {
                    this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedClosedDate == "lastMonth") {
                    this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toDate = new Date(date.setHours(719, 59, 59, 0));
                }
                this.fromDateFormat = moment(String(this.fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.toDateFormat = moment(String(this.toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    from: this.fromDateFormat,
                    to: this.toDateFormat,
                };
                return new Promise((resolve, reject) => {
                    EnquiryService.getClosedDateCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getAllClosedDatePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getClosedDateRange: function () {
            this.$v.from.$touch();
            this.$v.to.$touch();
            if (this.$v.from.$invalid && this.$v.to.$invalid) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var from = moment(String(fromDate)).format("yyyy-MM-DD HH:mm");
                var to = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                this.data = {
                    from: from,
                    to: to,
                };
                return new Promise((resolve, reject) => {
                    EnquiryService.getClosedDateCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getClosedDateRangeReset();
                            this.getAllClosedDatePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getClosedDateRangeReset: function () {
            this.$refs.dateRangeClosed.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getAllClosedDatePageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            // console.log("getAllClosedDatePageable")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllClosedDatePageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        // console.log("===========", this.closedEnquiries);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCourseClosedCount: function (selectedClosedCourse) {
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedDate = null;
            if (selectedClosedCourse == "AllCourse") {
                //console.log("All Course Calling")
                this.getClosedCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                //console.log("closedsortCourse Calling")
                this.data = {
                    courseId: selectedClosedCourse.courseId,
                };
                return new Promise((resolve, reject) => {
                    EnquiryService.getCourseClosedCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.sortClosedCoursePagination = true;
                            this.sortClosedSourcePagination = false;
                            this.sortClosedBranchPagination = false;
                            this.sortClosedDatePagination = false;
                            this.searchClosedPagination = false;
                            this.paginationClosedEnquiries = false;
                            this.closedCurrentPage = 1;
                            this.getAllCourseClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllCourseClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            //console.log("coursepageable calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCourseClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBranchClosedCount: function (selectedClosedBranch) {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedDate = null;
            if (selectedClosedBranch == "AllBranch") {
                //console.log("All Branch Calling")
                this.getClosedCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                //console.log("closedsortBranch Calling")
                this.data = {
                    branchId: selectedClosedBranch.branchId,
                };
                return new Promise((resolve, reject) => {
                    EnquiryService.getBranchClosedCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.sortClosedCoursePagination = false;
                            this.sortClosedSourcePagination = false;
                            this.sortClosedBranchPagination = true;
                            this.sortClosedDatePagination = false;
                            this.searchClosedPagination = false;
                            this.paginationClosedEnquiries = false;
                            this.closedCurrentPage = 1;
                            this.getAllBranchClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllBranchClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            //console.log("coursepageable calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllBranchClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSourceClosedCount: function (selectedClosedSource) {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedDate = null;
            if (selectedClosedSource == "AllSource") {
                //console.log("All Source Calling")
                this.getClosedCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                //console.log("closedsortSource Calling")
                this.data = {
                    sourceId: selectedClosedSource.sourceId,
                };
                return new Promise((resolve, reject) => {
                    EnquiryService.getSourceClosedCount(this.agentId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.sortClosedCoursePagination = false;
                            this.sortClosedSourcePagination = true;
                            this.sortClosedBranchPagination = false;
                            this.sortClosedDatePagination = false;
                            this.searchClosedPagination = false;
                            this.paginationClosedEnquiries = false;
                            this.closedCurrentPage = 1;
                            this.getAllSourceClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllSourceClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            //console.log("Closed coursepageable calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllSourceClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        this.agentId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        //===========================================================================================
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },

        updateEnquiry: function () {
          this.$v.enquiry.$touch();
          if (this.$v.enquiry.$invalid) {
            this.submitstatus = "FAIL";
          } else {
            this.submitstatus = "SUCCESS";
            return new Promise((resolve, reject) => {
                console.log('================>',this.enquiry)
              EnquiryService.updateEnquiry(this.agentId, this.enquiry)
                .then((response) => {
                  this.$swal({
                    title: "Enquiry update!",
                    text: response.data,
                    icon: "success",
                    button: "ok",
                  });
                  this.$refs.modal.hide();
                  this.getCount();
                  resolve(response);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
        },
        enquiryFollowupUpdate: function () {
            this.$v.enquiryFollowup.$touch();
            if (this.$v.enquiryFollowup.$invalid) {
                this.submitStatus = "FAIL";
            } else {
                this.keyword = "";
                this.disabledButton = true;
                // console.log("followup update");
                return new Promise((resolve, reject) => {
                    FollowupService.enquiryCounsellorFollowupUpdate(
                            this.agentId,
                            this.enquiry.enquiryId,
                            this.enquiryFollowup
                        )
                        .then((response) => {
                            this.$swal(response.data);
                            this.$refs.modal.hide();
                            this.getCounsellorCount();
                            resolve(response);
                            this.disabledButton = false;
                        })
                        .catch((err) => {
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            }
        },
        enquiryClosed: function (enquiryId) {
            console.log(enquiryId);
            if (this.closed == "admission") {
                if (this.$v.courseType.$invalid) {
                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please Select CourseType",
                    });
                } else {
                    this.invoiceAdd();
                }
                console.log(this.closed);
                this.disabledButton = false;
            } else if (this.closed == "notintrested") {
                console.log(this.closed);
                this.disabledButton = false;
            } else {
                this.$swal("Closed option invalid");
                this.disabledButton = false;
            }
            // this.getEnquiry(enquiryId);
            // this.$swal
            //     .fire({
            //         title: "Are you sure?",
            //         text: "You are closed the Enquiry to Admission!",
            //         icon: "info",
            //         showCancelButton: true,
            //         confirmButtonColor: "#3085d6",
            //         cancelButtonColor: "#d33",
            //         confirmButtonText: "Yes, close!",
            //     })
            //     .then((result) => {
            //         if (result.isConfirmed) {
            //             this.updateStatusClosed(this.agentId, this.enquiry.enquiryId);
            //             return new Promise((resolve, reject) => {
            //                 this.$swal({
            //                     title: "Admission Successful",
            //                     text: "Enquiry closed to Admission",
            //                     icon: "success",
            //                     button: "ok",
            //                 });
            //                 EnquiryService.enquiryClosed(this.agentId, this.enquiry)
            //                     .then((response) => {
            //                         this.$refs.modal.hide();
            //                         this.getCount();
            //                         resolve(response);
            //                     })
            //                     .catch((err) => {
            //                         reject(err);
            //                     });
            //             });
            //         }
            //     });
        },
        invoiceAdd: function () {
            return new Promise((resolve, reject) => {
                TeamService.getCurrentTeam(localStorage.getItem('tenant-uuid'))
                    .then((response) => {
                        //console.log("============getAdminTeam=======", response.data)
                        this.organization = response.data.organization;
                        this.admissionModal = true
                        this.$refs.modal.hide();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        closeModal: function () {
            console.log("=======closeIcon=======");
            this.admissionModal = false;
            this.counsellorData()
        },
        closeIcon: function () {
            console.log("=======closeIcon=======");
            this.admissionModal = false;
            this.courseType = null;
            this.$refs.modal.hide();
            this.counsellorData()
        },
        updateStatusClosed(agentId, enquiryId) {
            return new Promise((resolve, reject) => {
                EnquiryService.updateStatusClosed(agentId, enquiryId)
                    .then((response) => {
                        this.$refs.modal.hide();
                        this.getCount;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // showEnquiry() {
        //     this.isShowEnquiry = true;
        //     this.isShowClosed = false;
        //     this.selectedCourse = "Courses";
        //     this.selectedSource = "Sources";
        //     this.selectedBranch = "Branches";
        //     this.selectedDates = null;
        //     this.getCount();
        //     this.keyword = "";
        // },
        // showClosed() {
        //     this.isShowEnquiry = false;
        //     this.isShowClosed = true;
        //     (this.selectedClosedCourse = "Courses"),
        //     (this.selectedClosedSource = "Sources"),
        //     (this.selectedClosedBranch = "Branches"),
        //     (this.selectedClosedDate = null);
        //     this.closedKeyword = "";
        //     this.getClosedCount();
        // },

        closedRefresh: function () {
            this.getClosedCount();
            this.closedKeyword = "";
            (this.selectedClosedCourse = "Courses"),
            (this.selectedClosedSource = "Sources"),
            (this.selectedClosedBranch = "Branches"),
            (this.selectedClosedDate = null);
        },
        refresh: function () {
            this.getCount();
            this.keyword = "";
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedDates = null;
        },
        // ====================================Counsellor==============================================
        getCounsellorCount: function () {
            // console.log("getCounsellorCount Calling")
            this.branch = JSON.parse(localStorage.getItem("branch"));
            //  console.log(this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            // console.log("Data",this.data)
            this.loading = true;
            return new Promise((resolve, reject) => {
                EnquiryService.getCounsellorCount(this.data)
                    .then((response) => {
                        this.counsellorTotalCount = response.data;
                        // console.log("Coounsellor count",this.counsellorTotalCount )
                        this.counsellorCurrentPage = 1;
                        this.getPageable(
                            this.counsellorCurrentPage,
                            this.counsellorPageCount,
                            this.data
                        );
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getPageable: function (counsellorCurrentPage, counsellorPageCount, data) {
            // console.log("CounsellorPageable calling")
            this.sortCoursePagination = false;
            this.sortSourcePagination = false;
            this.sortBranchPagination = false;
            this.sortDatePagination = false;
            this.searchPagination = false;
            this.paginationEnquiries = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorPageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        // console.log(response.data)
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        counsellorSwitchPage: function (
            counsellorCurrentPage,
            counsellorPageCount
        ) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationEnquiries) {
                //   console.log(this.paginationEnquiries,"paginationEnquiries")
                this.getPageable(counsellorCurrentPage, counsellorPageCount, this.data);
            } else if (this.searchPagination) {
                // console.log(this.searchPagination,"searchPagination")
                this.getAllCounsellorSearchPageable(
                    counsellorCurrentPage,
                    counsellorPageCount,
                    this.counsellorKeyword.toLowerCase(),
                    this.data
                );
            } else if (this.sortSourcePagination) {
                this.getAllCounsellorSourcePageable(
                    counsellorCurrentPage,
                    counsellorPageCount,
                    this.data
                );
            } else if (this.sortCoursePagination) {
                //  console.log(this.sortCoursePagination,"sortCoursePagination")
                this.getAllCounsellorCoursePageable(
                    counsellorCurrentPage,
                    counsellorPageCount,
                    this.data
                );
            } else if (this.sortDatePagination) {
                // console.log(this.sortDatePagination,"sortDatePagination")
                this.getAllCounsellorDatePageable(
                    counsellorCurrentPage,
                    counsellorPageCount,
                    this.data
                );
            }

            // } else if (this.searchPagination) {
            //     this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            // }
        },
        showEnquiryCounsellor() {
            (this.isShowCounsellor = true),
            (this.isShowCounsellorClosed = false),
            (this.counsellorEnquiryActive = true),
            (this.counsellorClosedEnquiryActive = false),
            this.getCounsellorCount();
        },
        showClosedCounsellor() {
            (this.isShowCounsellor = false),
            (this.isShowCounsellorClosed = true),
            (this.counsellorEnquiryActive = false),
            (this.counsellorClosedEnquiryActive = true),
            (this.closedKeyword = "");
            this.getCounsellorClosedCount();
        },
        counsellorRefresh: function () {
            this.getCounsellorCount();
            this.counsellorKeyword = "";
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedDate = null;
        },
        counsellorClosedRefresh: function () {
            this.getCounsellorClosedCount();
            this.counsellorClosedKeyword = "";
            (this.selectedClosedCourse = "Courses"),
            (this.selectedClosedSource = "Sources"),
            (this.selectedClosedBranch = "Branches"),
            (this.selectedClosedDate = null);
        },
        insert() {
            //  console.log(this.call)
            this.$v.call.$touch();
            if (this.$v.call.$invalid) {
                this.submitStatus = "FAIL";
            } else {
                this.disabledButton = true;
                // console.log(this.call.branch);
                return new Promise((resolve, reject) => {
                    EnquiryService.getContact(this.call.contact)
                        .then((response) => {
                            if (response.data == true) {
                                if (this.call.alternativeContact == "") {
                                    this.submitStatus = "SUCCESS";
                                    return new Promise((resolve, reject) => {
                                        // //console.log(this.agentId);
                                        EnquiryService.insert(this.agentId, this.call)
                                            .then((response) => {
                                                this.$swal({
                                                    title: "Good job!",
                                                    text: response.data,
                                                    icon: "success",
                                                    button: "ok",
                                                });
                                                this.getCounsellorCount();
                                                // this.resetForm();
                                                this.$refs.modal.hide();
                                                // this.getAllCalls();

                                                // this.getAllPageable();
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                this.loading = false;
                                                reject(err);
                                            });
                                    });
                                } else {
                                    return new Promise((resolve, reject) => {
                                        EnquiryService.getalternativeContact(
                                                this.call.alternativeContact
                                            )
                                            .then((response) => {
                                                //console.log("Response====",response.data)
                                                if (response.data == true) {
                                                    this.submitStatus = "SUCCESS";
                                                    //   console.log("alternativeContact calling");
                                                    return new Promise((resolve, reject) => {
                                                        // //console.log(this.agentId);
                                                        EnquiryService.insert(this.agentId, this.call)
                                                            .then((response) => {
                                                                this.$swal({
                                                                    title: "Good job!",
                                                                    text: response.data,
                                                                    icon: "success",
                                                                    button: "ok",
                                                                });
                                                                this.getCounsellorCount();
                                                                // this.resetForm();
                                                                this.$refs.modal.hide();
                                                                // this.getAllCalls();

                                                                // this.getAllPageable();
                                                                this.disabledButton = false;
                                                                resolve(response);
                                                            })
                                                            .catch((err) => {
                                                                this.loading = false;
                                                                reject(err);
                                                            });
                                                    });
                                                } else
                                                    this.$swal.fire({
                                                        icon: "error",
                                                        title: "Oops...",
                                                        text: "AlternativeContact is already exist!",
                                                    });
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                reject(err);
                                                this.disabledButton = false;
                                                this.loading = false;
                                            });
                                    });
                                }
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Mobile Number is already exist!",
                                });
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        resetForm() {
            this.call = {
                callId: "",
                name: "",
                contact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: this.branch,
                followupDate: "",
                remark: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        getCounsellorClosedCount: function () {
            // console.log("closedCounsellor Enquiry Calling")
            this.data = {
                branchId: this.branch.branchId,
            };
            this.sortClosedCoursePagination = false;
            this.sortClosedSourcePagination = false;

            this.sortClosedDatePagination = false;
            this.searchClosedPagination = false;
            this.paginationClosedEnquiries = true;
            this.closedCurrentPage = 1;
            return new Promise((resolve, reject) => {
                EnquiryService.getCounsellorClosedCount(this.data)
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        // console.log(response.data)
                        this.closedCurrentPage = 1;
                        this.getAllCounsellorClosedPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            // console.log("coursepageable calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        counsellorClosedSwitchPage: function (closedCurrentPage, closedPageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationClosedEnquiries) {
                //   console.log(this.paginationClosedEnquiries,"paginationClosedEnquiries")
                this.getAllCounsellorClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
            } else if (this.sortClosedCoursePagination) {
                //   console.log(this.sortClosedCoursePagination,"sortClosedCoursePagination")
                this.getAllCounsellorCourseClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
            } else if (this.sortClosedSourcePagination) {
                this.getAllCounsellorSourceClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
            } else if (this.sortClosedDatePagination) {
                // console.log(this.sortClosedDatePagination,"sortClosedDatePagination")
                this.getAllCounsellorClosedDatePageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.data
                );
            } else if (this.searchClosedPagination) {
                // console.log(this.searchClosedPagination,"searchClosedPagination")
                this.getAllCounsellorSearchClosedPageable(
                    closedCurrentPage,
                    closedPageCount,
                    this.counsellorClosedKeyword,
                    this.data
                );
            }
        },
        getCounsellorEnquiry: function (enquiry) {
            this.enquiry = enquiry;
            // console.log("getCounsellorEnquiry", enquiry);
            // console.log(this.enquiry.callId)
            if (this.enquiry.callId == null) {
                console.log("callId");
                return new Promise((resolve, reject) => {
                    //   console.log("Call")
                    FollowupService.getEnquiryFollowupDateRemark(enquiry.enquiryId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            //   console.log(response.data)
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            } else {
                return new Promise((resolve, reject) => {
                    // FollowupService.getFollowupDateRemark(enquiry.callId)
                    FollowupService.getFollowupCreatedDate(enquiry.callId)
                        .then((response) => {
                            this.followupDateRemark = response.data;
                            // console.log(this.followupDateRemark);
                            // this.followup.forEach(element => {
                            //   //console.log(element);
                            // });
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getCounsellorSearchCount: function () {
            this.selectedSource = "Sources";
            this.selectedCourse = "Courses";
            this.selectedBranch = "Branches";
            this.selectedDate = null;
            // console.log("search pagecount", this.counsellorKeyword)
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                EnquiryService.getCounsellorSearchCount(
                        this.counsellorKeyword.toLowerCase(),
                        this.data
                    )
                    .then((response) => {
                        this.counsellorTotalCount = response.data;
                        // console.log("search pagecount", response.data)
                        this.sortCoursePagination = false;
                        this.sortSourcePagination = false;
                        this.sortBranchPagination = false;
                        this.sortDatePagination = false;
                        this.searchPagination = true;
                        this.paginationEnquiries = false;
                        this.currentPage = 1;
                        this.getAllCounsellorSearchPageable(
                            this.counsellorCurrentPage,
                            this.counsellorPageCount,
                            this.counsellorKeyword.toLowerCase(),
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllCounsellorSearchPageable: function (
            counsellorCurrentPage,
            counsellorPageCount,
            counsellorKeyword,
            data
        ) {
            this.loading = true;
            // console.log("search page calling")
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorSearchPageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        counsellorKeyword.toLowerCase(),
                        data
                    )
                    .then((response) => {
                        // console.log(response.data)
                        this.allEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorSourceCount: function (selectedSource) {
            this.selectedCourse = "Courses";
            this.selectedBranch = "Branches";
            this.selectedDate = null;
            if (selectedSource == "AllSource") {
                // console.log("AllSource Calling")
                this.getCounsellorCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                // //console.log("Source Calling")
                this.data = {
                    sourceId: selectedSource.sourceId,
                };
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorSourceCount(branchId, this.data)
                        .then((response) => {
                            this.counsellorTotalCount = response.data;
                            this.getAllCounsellorSourcePageable(
                                this.counsellorCurrentPage,
                                this.counsellorPageCount,
                                this.data
                            );
                            this.sortCoursePagination = false;
                            this.sortSourcePagination = true;

                            this.sortDatePagination = false;
                            this.searchPagination = false;
                            this.paginationEnquiries = false;
                            this.currentPage = 1;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorSourcePageable: function (
            counsellorCurrentPage,
            counsellorPageCount,
            data
        ) {
            // console.log("SourcePageable calling")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorSourcePageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorCourseCount: function (selectedCourse) {
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";
            this.selectedDate = null;
            if (selectedCourse == "AllCourse") {
                // console.log("All Course Calling")
                this.getCounsellorCount();
            } else {
                // console.log("Course Calling")
                this.data = {
                    courseId: selectedCourse.courseId,
                };
                // //console.log("coursecount", this.data)
                var branchId = this.branch.branchId;
                this.loading = true;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorCourseCount(branchId, this.data)
                        .then((response) => {
                            this.counsellorTotalCount = response.data;
                            this.getAllCounsellorCoursePageable(
                                this.counsellorCurrentPage,
                                this.counsellorPageCount,
                                this.data
                            );
                            this.sortCoursePagination = true;
                            this.sortSourcePagination = false;
                            this.sortDatePagination = false;
                            this.searchPagination = false;
                            this.paginationEnquiries = false;
                            this.currentPage = 1;
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorCoursePageable: function (
            counsellorCurrentPage,
            counsellorPageCount,
            data
        ) {
            // console.log("coursepageable calling")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorCoursePageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorDateRangeReset: function () {
            this.$refs.counsellorDateRange.hide();
            (this.fromCounsellorDate = ""),
            (this.toCounsellorDate = ""),
            this.$v.fromCounsellorDate.$reset();
            this.$v.toCounsellorDate.$reset();
        },
        getCounsellorDateCount: function (selectedDate) {
            this.selectedCourse = "Courses";
            this.selectedSource = "Sources";
            this.selectedBranch = "Branches";

            this.sortCoursePagination = false;
            this.sortSourcePagination = false;
            this.sortBranchPagination = false;
            this.sortDatePagination = true;
            this.searchPagination = false;
            this.paginationEnquiries = false;
            this.currentPage = 1;
            this.loading = true;
            if (selectedDate == "dateRange") {
                this.$refs.counsellorDateRange.show();
                this.selectedDate = null;
                this.getCounsellorDateRangeReset();
                this.loading = false;
            } else {
                var date = new Date();
                if (selectedDate == "today") {
                    this.fromCounsellorDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDate == "yesterday") {
                    this.fromCounsellorDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDate == "lastWeek") {
                    this.fromCounsellorDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedDate == "lastMonth") {
                    this.fromCounsellorDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(719, 59, 59, 0));
                }
                this.fromDateFormat = moment(String(this.fromCounsellorDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.toDateFormat = moment(String(this.toCounsellorDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    fromCounsellor: this.fromDateFormat,
                    toCounsellor: this.toDateFormat,
                };
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorDateCount(branchId, this.data)
                        .then((response) => {
                            this.counsellorTotalCount = response.data;
                            this.getAllCounsellorDatePageable(
                                this.counsellorCurrentPage,
                                this.counsellorPageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorDatePageable: function (
            counsellorCurrentPage,
            counsellorPageCount,
            data
        ) {
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorDatePageable(
                        counsellorCurrentPage,
                        counsellorPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.allEnquiries = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCounsellorDateRange: function () {
            this.$v.fromCounsellorDate.$touch();
            this.$v.toCounsellorDate.$touch();
            if (
                this.$v.fromCounsellorDate.$invalid &&
                this.$v.toCounsellorDate.$invalid
            ) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.fromCounsellorDate);
                var toDateObject = new Date(this.toCounsellorDate);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var fromCounsellorDate = moment(String(fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                var toCounsellorDate = moment(String(toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    fromCounsellor: fromCounsellorDate,
                    toCounsellor: toCounsellorDate,
                };
                this.loading = true;
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorDateCount(branchId, this.data)
                        .then((response) => {
                            this.counsellorTotalCount = response.data;
                            this.getCounsellorDateRangeReset();
                            this.getAllCounsellorDatePageable(
                                this.counsellorCurrentPage,
                                this.counsellorPageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getCounsellorClosedCourseCount: function (selectedClosedCourse) {
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedDate = null;
            if (selectedClosedCourse == "AllCourse") {
                //console.log("All Course Calling")
                this.getCounsellorClosedCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                //console.log("closedsortCourse Calling")
                this.data = {
                    courseId: selectedClosedCourse.courseId,
                };
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorClosedCourseCount(branchId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.sortClosedCoursePagination = true;
                            this.sortClosedSourcePagination = false;

                            this.sortClosedDatePagination = false;
                            this.searchClosedPagination = false;
                            this.paginationClosedEnquiries = false;
                            this.closedCurrentPage = 1;
                            this.getAllCounsellorCourseClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorCourseClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            // console.log("coursepageable calling")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorCourseClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorSourceClosedCount: function (selectedClosedSource) {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedDate = null;
            if (selectedClosedSource == "AllSource") {
                //console.log("All Source Calling")
                this.getCounsellorClosedCount();
                // this.getCount();
                // this.getAllPageable();
            } else {
                //console.log("closedsortSource Calling")
                this.data = {
                    sourceId: selectedClosedSource.sourceId,
                };
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorSourceClosedCount(branchId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.sortClosedCoursePagination = false;
                            this.sortClosedSourcePagination = true;
                            this.sortClosedBranchPagination = false;
                            this.sortClosedDatePagination = false;
                            this.searchClosedPagination = false;
                            this.paginationClosedEnquiries = false;
                            this.closedCurrentPage = 1;
                            this.getAllCounsellorSourceClosedPageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorSourceClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            // console.log("Closed coursepageable calling")
            var branchId = this.branch.branchId;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorSourceClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounselloClosedDateRangeReset: function () {
            this.$refs.dateCounsellorClosedRange.hide();
            (this.fromCounsellorDate = ""),
            (this.toCounsellorDate = ""),
            this.$v.fromCounsellorDate.$reset();
            this.$v.toCounsellorDate.$reset();
        },
        getCounsellorClosedDateCount: function (selectedClosedDate) {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";

            this.sortClosedCoursePagination = false;
            this.sortClosedSourcePagination = false;

            this.sortClosedAgentPagination = false;
            this.sortClosedDatePagination = true;
            this.searchClosedPagination = false;
            this.paginationClosedEnquiries = false;
            this.closedCurrentPage = 1;
            if (selectedClosedDate == "dateRange") {
                this.$refs.dateCounsellorClosedRange.show();
                this.selectedClosedDate = null;
                this.getCounselloClosedDateRangeReset();
                this.loading = false;
            } else {
                var date = new Date();
                if (selectedClosedDate == "today") {
                    this.fromCounsellorDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedClosedDate == "yesterday") {
                    this.fromCounsellorDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedClosedDate == "lastWeek") {
                    this.fromCounsellorDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedClosedDate == "lastMonth") {
                    this.fromCounsellorDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toCounsellorDate = new Date(date.setHours(719, 59, 59, 0));
                }
                return new Promise((resolve, reject) => {
                    this.fromDateFormat = moment(String(this.fromCounsellorDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toCounsellorDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.data = {
                        fromCounsellor: this.fromDateFormat,
                        toCounsellor: this.toDateFormat,
                    };
                    var branchId = this.branch.branchId;
                    EnquiryService.getCounsellorClosedDateCount(branchId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            // console.log(this.closedTotalCount)
                            this.getAllCounsellorClosedDatePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllCounsellorClosedDatePageable: function (
            closedCurrentPage,
            closedPageCount,
            data
        ) {
            this.loading = true;
            var branchId = this.branch.branchId;
            // console.log("getAllCounsellorClosedDatePageable",closedCurrentPage, closedPageCount,branchId, data)
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorClosedDatePageable(
                        closedCurrentPage,
                        closedPageCount,
                        branchId,
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        // console.log("===========", this.closedEnquiries);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getCounsellorClosedDateRange: function () {
            this.$v.fromCounsellorDate.$touch();
            this.$v.toCounsellorDate.$touch();
            if (
                this.$v.fromCounsellorDate.$invalid &&
                this.$v.toCounsellorDate.$invalid
            ) {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select from date and to date",
                });
            } else {
                var fromDateObject = new Date(this.fromCounsellorDate);
                var toDateObject = new Date(this.toCounsellorDate);
                var fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                var toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                var fromCounsellorDate = moment(String(fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                var toCounsellorDate = moment(String(toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.data = {
                    fromCounsellor: fromCounsellorDate,
                    toCounsellor: toCounsellorDate,
                };
                this.loading = true;
                var branchId = this.branch.branchId;
                return new Promise((resolve, reject) => {
                    EnquiryService.getCounsellorClosedDateCount(branchId, this.data)
                        .then((response) => {
                            this.closedTotalCount = response.data;
                            this.getCounselloClosedDateRangeReset();
                            this.getAllCounsellorClosedDatePageable(
                                this.closedCurrentPage,
                                this.closedPageCount,
                                this.data
                            );
                            resolve(response);
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getCounsellorSearchClosedCount: function () {
            this.selectedClosedCourse = "Courses";
            this.selectedClosedSource = "Sources";
            this.selectedClosedBranch = "Branches";
            this.selectedClosedDate = null;
            // //console.log("search pagecount", this.counsellorClosedKeyword)
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                EnquiryService.getCounsellorSearchClosedCount(
                        this.counsellorClosedKeyword.toLowerCase(),
                        this.data
                    )
                    .then((response) => {
                        this.closedTotalCount = response.data;
                        // //console.log("SearchClosedCount", response.data)
                        this.sortClosedCoursePagination = false;
                        this.sortClosedSourcePagination = false;
                        this.sortClosedBranchPagination = false;
                        this.sortClosedDatePagination = false;
                        this.searchClosedPagination = true;
                        this.paginationClosedEnquiries = false;
                        this.closedCurrentPage = 1;
                        this.getAllCounsellorSearchClosedPageable(
                            this.closedCurrentPage,
                            this.closedPageCount,
                            this.counsellorClosedKeyword.toLowerCase(),
                            this.data
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCounsellorSearchClosedPageable: function (
            closedCurrentPage,
            closedPageCount,
            counsellorClosedKeyword,
            data
        ) {
            // console.log("closed search page calling")
            this.loading = true;
            return new Promise((resolve, reject) => {
                EnquiryService.getAllCounsellorSearchClosedPageable(
                        closedCurrentPage,
                        closedPageCount,
                        counsellorClosedKeyword.toLowerCase(),
                        data
                    )
                    .then((response) => {
                        this.closedEnquiries = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getContactSearchCount: function () {
            // console.log("Contact search count", this.call.contact)
            // console.log("BranchId", this.branch.branchId)
            this.data = {
                branchId: this.branch.branchId,
            };
            return new Promise((resolve, reject) => {
                EnquiryService.getContactSearchCount(this.call.contact, this.data)
                    .then((response) => {
                        if (response.data != "") {
                            this.call = response.data;
                            this.agentId = this.call.agent.agentId;
                            //  console.log("agentid", this.agentId)
                            // console.log("search contact", response.data)
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Mobile Number doesnot exist!",
                            });
                            // this.call.contact ="";
                            this.resetForm();
                        }

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

    },
};
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

/* .active {
    background: rgb(0, 97, 243) !important;
} */

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 100px;
    max-width: 100px;
    margin-left: 20px;
}
</style>
