import axios from "axios";

export default {
	getAllUnmarkEmployee: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/get/unMark/employee",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	markAllAbsentOtherPresent: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/markAll/absent/other/present",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	markAllAbsent: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/markAll/absent",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	markAllAsOption: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/markAll/option",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	markAllWithFullDayPresent: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/markAll/fullDay/present",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	updateAttendance: function(data) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/update",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAttendanceCount: function(date) {
		let data = {
			date: date
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/get/count",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAllAttendancePageable: function(currentPage,pageCount,date) {
		let data = {
			page : currentPage - 1,
			count : pageCount,
			date : date,
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/getAll/pageable",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAttendanceSearchCount: function(date,keyword) {
		let data = {
			date: date,
			keyword:keyword
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/get/search/count",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAllAttendanceSearchPageable: function(currentPage,pageCount,date,keyword) {
		let data = {
			page : currentPage,
			count : pageCount,
			date : date,
			keyword : keyword
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/getAll/search/pageable",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getUserAttendanceCount: function(date,user) {
		let data = {
			date: date,
			userId: user.id,
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/user/get/count",data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getUserAttendancePageable: function(currentPage,pageCount,date,user) {
		let data = {
			date: date,
			userId: user.id,
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/user/get/pageable/"+(currentPage - 1)+"/"+pageCount,data)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getSortAttendanceCount: function(data) {
		let sortData = {
			userId : data.userId,
			from : data.from,
			to : data.to,
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/get/sort/count",sortData)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	getAllSortAttendancePageable: function(currentPage, pageCount,data) {
		let sortData = {
			page : currentPage,
			count : pageCount,
			userId : data.userId,
			from : data.from,
			to : data.to,
		}
		return new Promise((resolve, reject) => {
			axios.post("attendance/getAll/sort/pageable",sortData)
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
		});
	},
	exportToExcel: function(sortData) {
		return new Promise((resolve, reject) => {
			axios.post("attendance/sort/export/excel",sortData)
				.then((response) => {
					this.attendances = response.data;
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
			});
	},
}