import axios from 'axios';
export default{
    teamAgentCreate: function(agent) {
        return new Promise((resolve, reject) => {
            axios.post("/agent/insert",agent)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getAgentData: function(userId){
        return new Promise((resolve, reject) => {
            axios.get("/agent/getAgent/" + userId)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getOnlineStatus :function(){

        return new Promise((resolve, reject) => {
            axios.post("agent/status/online" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },

    getAgentLastCallLog :function(){

        return new Promise((resolve, reject) => {
            axios
            .get("agent/last/calllog" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },


    getOfflineStatus :function(){

        return new Promise((resolve, reject) => {
            axios.post("agent/status/offline" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getIdleStatus :function(){

        return new Promise((resolve, reject) => {
            axios.post("agent/status/idle" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getAwayStatus :function(){

        return new Promise((resolve, reject) => {
            axios.post("agent/status/away" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    getAllStatus:function(){

        return new Promise((resolve, reject) => {
            axios.post("agent/all/status" )

            .then(response => {

                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    insertAgent: function(agent){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/insert", agent, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAllAgents: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/getAllAsc',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorAgents: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/counsellor/getAll',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // getAllCounsellors: function(){
    //     // var ax = axios.create({
    //     //     baseURL: "http://localhost:9093",
    //     // });
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             method: 'get',
    //             url: '/agent/getAllAsc',
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },
    getAllAdminAgents: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/getAll',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    deleteAgent: function(agentId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/delete/' + agentId,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAgent: function(agentId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/get/' + agentId,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    updateAgent: function(update){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/update", update, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    userUpdateWithAgent: function(user){
        let data = {
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            contact: user.mobile,
        }
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/user/update", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSupervisor: function(update){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/supervisor/update", update, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateCounsellor: function(update){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/counsellor/update", update, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    archive: function(agent){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/archive/"+ agent, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function(agent){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/agent/unarchive/"+ agent, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    getCount: function(){

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/agent/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorCount: function(){

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "agent/counsellor/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount){

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/all/'+ (currentPage-1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllAgentPageable: function(currentPage,pageCount){

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/all/'+ (currentPage-1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCounsellorPageable: function(counsellorCurrentPage,counsellorPageCount){

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'agent/counsellor/all/'+ (counsellorCurrentPage-1) + "/" + counsellorPageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    activateCounsellor: function(data) {
        return new Promise((resolve, reject) => {
            axios.post("agent/counsellor/activate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    activateSupervisor: function(data) {
        return new Promise((resolve, reject) => {
            axios.post("agent/supervisor/activate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    activateAdminSupervisor: function(data) {
        return new Promise((resolve, reject) => {
            axios.post("agent/admin/supervisor/activate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deactivateCounsellor: function (agentId) {
        let data = {
            agentId: agentId
        };
        return new Promise((resolve, reject) => {
            axios
                .post("agent/counsellor/deactivate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deactivateSupervisor: function (agentId) {
        let data = {
            agentId: agentId
        };
        return new Promise((resolve, reject) => {
            axios
                .post("agent/supervisor/deactivate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deactivateAdminSupervisor: function (agentId) {
        let data = {
            agentId: agentId
        };
        return new Promise((resolve, reject) => {
            axios
                .post("agent/admin/supervisor/deactivate", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // updateCounsellor: function () {
    //     let data ={
    //         branch:this.branch,
    //         agentId:this.agent.agentId
    //       }
    //     return new Promise((resolve, reject) => {
    //     axios({
    //         method: 'post',
    //         url: '/agent/counsellor/activate',data
    //     }).then((response) => {
    //         resolve(response);
    //     }).catch((err) => {
    //         reject(err);
    //     });
    // });
    // },
    // searchAgent: function(keyword){
    //     // var ax = axios.create({
    //     //     baseURL: "http://localhost:9093",
    //     // });
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             method: 'get',
    //             url: '/agent/search/'+ keyword,
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },
    getSearchCount: function(keyword) {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/agent/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchCounsellor: function(keyword){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: 'agent/counsellor/search/'+ keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getCounsellorSearchCount: function(keyword) {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/agent/counsellor/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },
    getAllCounsellorSearchPageable: function(counsellorCurrentPage,counsellorPageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/agent/counsellor/search/'+ (counsellorCurrentPage-1) + "/" + counsellorPageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
}