<template>
  <div>
    <div>
      <HRNavbar />
    </div>
    <div>
      <router-view />
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HRNavbar from "../navbar/HRNavbar";
import Footer from "../components/Footer.vue";
export default {
  name: "HRLayout",
  data() {
    return {};
  },
  components: {
    HRNavbar,
    Footer,
  },
  methods: {},
};
</script>

<style scoped></style>
