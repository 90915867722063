import axios from 'axios';
// // var restServerLocation = 'https://api-encore.examsdaily.in';
// var restServerLocation = 'http://localhost:8088';

export default{

    // officeEmployeeCreate: function(employee) {
    //     return new Promise((resolve, reject) => {
    //         axios.post("/user/insert",employee)
    //         .then(response => {
    //             resolve(response);
    //         })
    //         .catch(err => {
    //             reject(err);
    //         });
    //     });
    // },

    officeEmployeeCreate: function(employee) {
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.post("/user/insert",employee,config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },

    bulkOfficeEmployeeCreate: function(employee) {
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.post("/user/userDetails",employee,config)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },


    getCount: function(officeId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/user/count/"+ officeId,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    getAllPageable: function(currentPage,pageCount,officeId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/user/all/" + (currentPage-1) +"/"+ pageCount+"/"+ officeId,config)
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    //User search pagination Start
    getUserSearchCount: function(officeId,keyword){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            axios.get('/user/search/count/'+ officeId + "/" + keyword,config)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAllUserSearchPageable: function(currentPage,pageCount,officeId,keyword){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            axios.get('/user/search/'+ (currentPage-1) + "/" + pageCount +"/"+ officeId +"/" + keyword,config)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    //User search pagination End

    //Disabled Users Pagination Start

    getDisabeldCount: function(officeId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/user/disabledcount/"+ officeId,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    getAllDisabledPageable: function(currentPage,pageCount,officeId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/user/allDisabled/" + (currentPage-1) +"/"+ pageCount+"/"+ officeId,config)
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    //Disabled User Pagination End

    //User search pagination Start
    getDisabledUserSearchCount: function(officeId,keyword){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            axios.get('/user/disabledSearch/count/'+ officeId + "/" + keyword,config)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAllDisabledUserSearchPageable: function(currentPage,pageCount,officeId,keyword){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            axios.get('/user/disabledSearch/'+ (currentPage-1) + "/" + pageCount +"/"+ officeId +"/" + keyword,config)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    //User search pagination End

    getAttendanceCount: function(data){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': localStorage.getItem("tenant-uuid"),
                //   'tenant-uuid': '',
            },
        };

        return new Promise((resolve, reject) => {
            axios.post("/check/count",data,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    getAllAttendancePageable: function(currentPage,pageCount,data){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': localStorage.getItem("tenant-uuid"),
            }
        };

        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/check/all/' + (currentPage-1) +"/"+ pageCount,data,config,
            })
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    getAllSortAttendancePageable: function(currentPage,pageCount,data){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': localStorage.getItem("tenant-uuid"),
            }
        };

        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/check/all/sort/' + (currentPage-1) +"/"+ pageCount,data,config,
            })
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    // GET ATTENDANCE DETAILS
    AttendanceDetails(data){
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: 'check/details',
                data: data
            })
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

// GET ATTENDANCE EXPORT DETAILS
    AttendanceExportDetails(data){
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: 'check/employeeCheckin/report/export/excel',
                data: data
            })
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    AllAttendanceExportDetails: function(data){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': localStorage.getItem("tenant-uuid"),
                //   'tenant-uuid': '',
            },
        };

        return new Promise((resolve, reject) => {
            axios.post("/check/allCheckin/report/export/excel",data,config)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

updateEmployee: function(employeeId,update){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });
  
      let config = {
          headers: {
              "Content-Type": "application/json",
              'tenant-uuid': '',
          }
      };
    console.log("=====EmployeeId-2=====", employeeId,);
    return new Promise((resolve, reject) => {
        axios.post("/user/update/"+employeeId, update,config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
    });
},

employeeOfficeMigration: function(employeeId,data){ 
    console.log("=====Data=====",data);
      let config = {
          headers: {
              "Content-Type": "application/json",
              'tenant-uuid': '',
          }
      };
    return new Promise((resolve, reject) => {
        axios.post("/user/officeMigration/"+employeeId, data,config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
    });
},

employeeDisabled: function(employeeId){
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };

    return new Promise((resolve, reject) => {
        axios.get("/user/disableEmployee/"+ employeeId,config)
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
},

employeeEnabled: function(employeeId){
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };

    return new Promise((resolve, reject) => {
        axios.get("/user/enableEmployee/"+ employeeId,config)
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
},


getEmployeeUserDetails: function(userId){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });
  
      let config = {
          headers: {
              "Content-Type": "application/json",
              'tenant-uuid': '',
          }
      };
    return new Promise((resolve, reject) => {
        axios.get("/user/getEmployeeDetails/"+userId,config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
    });
},

updateEmployeeDetails: function(userId,update){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });
  
      let config = {
          headers: {
              "Content-Type": "application/json",
              'tenant-uuid': '',
          }
      };
    return new Promise((resolve, reject) => {
        axios.post("/user/updateEmployeeDetails/"+userId, update,config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
    });
},

getEmployeeDetails: function(employeeId){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });
  
      let config = {
          headers: {
              "Content-Type": "application/json",
              'tenant-uuid': '',
          }
      };
    return new Promise((resolve, reject) => {
        axios.get("/user/getEmployeeSalary/"+employeeId,config)
        .then(response => {
            resolve(response);
        })
        .catch(err => {
            reject(err);
        });
    });
},

getEmployeeCode: function(employeeCode){
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };
    return new Promise((resolve, reject) => {
        // axios({
        //     method: 'get',
        //     url: '/user/employeeCode/' + employeeCode,
        // })
        axios.get("/user/employeeCode/"+employeeCode,config)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

getAllTeamEmployeeCount(teamId) {
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };
    return new Promise((resolve, reject) => {
        // let data = {
        //     teamId: teamId
        // }
        // axios({
        //     method: 'post',
        //     url: 'user/teamEmployee/count',data,
        // })
        axios.get("user/teamEmployee/count/"+ teamId,config)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

getAllTeamEmployeePageable: function(currentPage,pageCount,teamId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };

        return new Promise((resolve, reject) => {
            axios.get("/user/allEmployee/" + (pageCount-1) +"/"+ currentPage+"/"+ teamId,config)
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

//Employee search pagination

getEmployeeSearchCount: function(teamId,keyword){
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };
    return new Promise((resolve, reject) => {
        axios.post("/user/search/count/"+ teamId + "/" + keyword,config)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllEmployeeSearchPageable: function(currentPage,pageCount,teamId,keyword){
    let config = {
        headers: {
            "Content-Type": "application/json",
            'tenant-uuid': '',
        }
    };
    return new Promise((resolve, reject) => {
        axios.post('/user/search/'+ (currentPage-1) + "/" + pageCount +"/"+ teamId +"/" + keyword,config)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

// exportOfficeUserExcellSheet: function(officeId){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });

//       let config = {
//           headers: {
//               "Content-Type": "application/json",
//               'tenant-uuid': '',
//           }
//       };

//     return new Promise((resolve, reject) => {
//         axios.post("/user/report/export/excel/" + officeId,config)
//         .then((response) => {

//             resolve(response);
//         })
//         .catch((err) => {
//             reject(err);
//         });
//     });
// },

// exportOfficeUserExcellSheet: function(officeId){
    // var ax = axios.create({
    //     baseURL: restServerLocation,
    //   });

//       let config = {
//           headers: {
//               "Content-Type": "application/json",
//               'tenant-uuid': '',
//           }
//       };

//     return new Promise((resolve, reject) => {
//         axios.get("/user/report/export/excel/" + officeId,config)
//         .then((response) => {
//             resolve(response);
//         })
//         .catch((err) => {
//             reject(err);
//         });
//     });
// },

}