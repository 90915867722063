<template>
<div>
    <div>
        <Navbar />
    </div>
    <div>
        <router-view />
    </div>
    <div>
        <Footer />
    </div>
</div>
</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
export default {
    name: "Layout",
    data() {
        return {};
    },
    components: {
        Navbar,
        Footer,
    },
    methods: {},
};
</script>

<style scoped></style>