<template>
<div class="bg">
    <nav class="navbar navbar-light bg-light">
      <span class="navbar-brand px-3">
        <span class="">tele</span> <span class="fiesta">caller</span>
      </span>
    </nav>
    <div class="container content " style="text-align: left;">
       <h4> <span class="" style="text-transform: uppercase;"><strong>Telecaller Cookie Policy</strong></span></h4>
        <br>
        <br>
        <div class="paragraph">
            This policy is designed to help you understand more about how and when we use cookies on our applications, sites, services and tools and the choices you have. This cookie policy applies to any Telecaller product or service that links to this policy or incorporates it by reference. <br><br>
        </div>
        <span class="text-h5 " style="text-transform: uppercase;"><strong>1. Cookies</strong></span>
        <br>
        <br>
        <div class="paragraph">
            We use cookies, web beacons, flash cookies, HTML 5 cookies, pixel tags and other web application software methods (collectively “cookies”) that are essentially small data files to store information in your web browser or on your mobile phone, tablet, computer, or other devices (collectively "devices") that allow us to store and receive certain pieces of information whenever you use or interact with our applications, services, sites and tools.
            <br><br>
            We use the following types of cookies:
            <ul><strong>a.</strong>

                Session cookies expire at the end of your browser session and allow us to link your actions during that particular browser session.
            </ul>
            <ul><strong>b.</strong>
                Persistent cookies are stored on your device in between browser sessions, allowing us to remember your preferences or actions.

            </ul>
            <ul><strong>c.</strong>
                First-party cookies are set by Telecaller
            </ul>
            <ul><strong>d.</strong>
                Third-party cookies are set by a third party, i.e. an authorized service provider of Telecaller.

            </ul>
        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>2. Telecaller use of cookies</strong></span>
        <br>
        <br>
        <div class="paragraph">
            As we update and improve our services the specific cookies we use may change from time to time, but they will generally fall into one of the below categories:
            <br><br>
            <strong>Authentication :</strong>
            <ul>
                If you're signed in to Telecaller, cookies help us show you the right information and personalize your experience.
            </ul>
            <strong>Security :</strong>
            <ul>
                We use cookies to enable and support our security features such as identifying irregular site behavior, prevent fraudulent activity and improve security and violations of our Terms of Service. </ul>
            <strong>Preferences, features and services :</strong>
            <ul>
                These cookies can tell us which language you prefer and what your communications preferences are and store such preferences and other information. They can also help you fill out forms on the Telecaller service more easily.
            </ul>
            <strong>Advertising :</strong>
            <ul>
                We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests, on our sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.
            </ul>
            <strong>Performance, Analytics and Research :</strong>
            <ul>
                Cookies help us learn how well our site, services and tools perform in different locations. We also use cookies to understand, improve, and research products, features, and services, including when you access Telecaller from other websites, applications, or devices such as your work computer or your mobile device.
            </ul>
            When you visit our website, your prior consent is always requested before replacing any cookies except for the ones that are required for the operation of our website. If you do not consent, only limited visit statistics will be gathered without installing any cookies via our audience measurement tool which we use for the proper administration of our website in accordance with the guidelines published by data protection authorities in the EU.

            <br><br>
        </div>

        <span class="text-h5" style="text-transform: uppercase;"><strong>3. Third party use of cookies</strong></span>
        <br>
        <br>
        <div class="paragraph">
            We may work with third-party companies, commonly known as service providers, who are authorized to place third-party cookies for storing information on our sites or in our services, applications, and tools with our permission. These service providers help us deliver our own content and advertising, and compile anonymous site metrics and analytics and to provide you with a better, faster, and safer experience.

            <br><br>
            We may also use third parties, such as advertising networks and exchanges, to allow us to serve you advertisements. These third-party ad networks and exchange providers may use third-party cookies to collect information. They may also collect your device identifier, IP address, or identifier for advertising. The information that these third parties collect may be used to assist us in providing you with more relevant advertising that we serve on our sites or elsewhere on the web. Third-party cookies are covered by the third parties' privacy policy.
            <br><br>
            These service providers are subject to confidentiality agreements with us and other legal restrictions on their use or collection of any personal information. Third-party cookies are covered by the third parties' privacy policy.
            <br><br>

        </div>
        <span class="text-h5" style="text-transform: uppercase;"><strong>4. Your cookie options</strong></span>
        <br>
        <br>
        <div class="paragraph">
           
            Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may limit your user experience as it may stop you from saving customized settings like login information and other personalized preferences. Please note that opting out will not remove advertising from the pages you visit; it will mean that the ads you see may not be matched to your interests. In addition most browsers also provide functionality that lets you review and erase cookies, including Telecaller cookies. Please note that some Telecaller functionality may stop working properly without cookies.            <br><br>
            <br>
        <br>  <br>
        <br>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        service() {
            this.$router.push('/termsAndService');
        },
        cookie() {
            this.$router.push('/cookies');
        },
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.bg {
    background-color: rgb(240, 252, 254);
    
}

.content {
    margin-top: 5%;
}

.paragraph {
    color: rgb(96, 93, 93);
}

.tab-style {
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: 'Noto Sans Avestan', sans-serif;
    font-weight: 200;
    font-size: 25px;
    color: #3c7ef7;
}

.fiesta {
    font-family: "Pacifico", cursive;

position: relative;
top: 1px;
right: 6px;
font-size: 27px;
color: #3c7ef7;
}

.white-bg {
    background-color: white;
    flex-grow: 1;
    width: 100%;
}
</style>
