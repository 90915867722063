<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container>
        <b-modal title="File Upload" ref="leadFileUploadModal" no-close-on-backdrop hide-footer>
            <div style=";font-size:13px;" class="text-primary">
                <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                <span class="px-1">Please check the contacts are 10 digits only, then upload the excel sheet</span>
            </div>
            <div class="text-center">
                <b-form-file v-model="file" class="mt-3" plain></b-form-file>
                <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
            </div>
            <div class="text-center py-3">
                <b-button class="mx-2" variant="primary" :disabled="file == null" @click="leadFileUpload()">Upload</b-button>
                <b-button class="mx-2" variant="danger" @click="$refs.leadFileUploadModal.hide(),file=null,disabledButton = false">Cancel</b-button>
            </div>
        </b-modal>
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Assigned" active @click="getAllAssignedLeadCount()">
                <div class="text-end pb-3">
                    <b-button variant="outline-primary" @click="fileUploadModaShow()">File Upload</b-button>
                </div>
                <b-modal title="Lead Assign" ref="modal" no-close-on-backdrop hide-footer>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="call.name" :class="{
                  'is-invalid': $v.call.name.$error,
                  'is-valid': !$v.call.name.$invalid,
                }" class="form-control" placeholder="Enter student name"></b-form-input>
                            <div class="valid-feedback">Your Name is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.call.name.required">Name is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input type="number" v-model="call.contact" :class="{
                  'is-invalid': $v.call.contact.$error,
                  'is-valid': !$v.call.contact.$invalid,
                }" class="form-control" placeholder="Enter contact number"></b-form-input>
                            <div class="valid-feedback">Your contact is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.call.contact.required">contact is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input type="number" v-model="call.alternativeContact" class="form-control" placeholder="Enter Alternative Contact number"></b-form-input>
                            <!-- <div class="valid-feedback">Your AlternativeContact is valid!</div>
              <div class="invalid-feedback">

                  <span v-if="!$v.call.alternativeContact.optionalField"
                  >contact is required</span
                >

                  <span v-if="!$v.call.alternativeContact.$error || $v.call.alternativeContact.$invalid"
                  >Invalid contact</span>
              </div> -->
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input type="number" v-model="call.age" :class="{
                  'is-invalid': $v.call.age.$error,
                  'is-valid': !$v.call.age.$invalid,
                }" class="form-control" placeholder="Enter student age"></b-form-input>
                            <div class="valid-feedback">Your Age is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.call.age.required">Age is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-select class="form-control" v-model="call.qualification" :class="{
                  'is-invalid': $v.call.qualification.$error,
                  'is-valid': !$v.call.qualification.$invalid,
                }">
                                <option value="null" disabled>Select Qualification</option>
                                <option v-for="q in qualificationList" :value="q.value" v-bind:key="q.index">
                                    <strong>{{ q.text }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-select class="form-control" v-model="call.course" :class="{
                  'is-invalid': $v.call.course.$error,
                  'is-valid': !$v.call.course.$invalid,
                }">
                                <option value="null" disabled>Select Course</option>
                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-select class="form-control" v-model="call.source" :class="{
                  'is-invalid': $v.call.source.$error,
                  'is-valid': !$v.call.source.$invalid,
                }">
                                <option value="null" disabled>Select Source</option>
                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-select class="form-control" v-model="call.mode" :class="{
                  'is-invalid': $v.call.mode.$error,
                  'is-valid': !$v.call.mode.$invalid,
                }" :options="options"></b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-select class="form-control" v-model="call.branch" :class="{
                  'is-invalid': $v.call.branch.$error,
                  'is-valid': !$v.call.branch.$invalid,
                }">
                                <option value="null" disabled>Select Branch</option>
                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.name }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Followup</label>
                            <div>
                                <b-form-input format="DD-MM-YYYY h:i:s" v-model="call.followupDate" :class="{
                    'is-invalid': $v.call.followupDate.$error,
                    'is-valid': !$v.call.followupDate.$invalid,
                  }" type="datetime-local" min="new Time()"></b-form-input>
                                <div class="valid-feedback">date time is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.call.followupDate.required">date or time is invalid!</span>
                                </div>
                            </div>
                            <div>
                                <b-form-textarea class="form-control" v-model="call.remark" :class="{
                    'is-invalid': $v.call.remark.$error,
                    'is-valid': !$v.call.remark.$invalid,
                  }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                                <div class="valid-feedback">remark is valid!</div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.call.remark.required">remark is invalid!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3 mt-2">
                        <b-button type="submit" :disabled="disabledButton" @click="callInsert()" class="" variant="outline-primary">Register</b-button>
                        <b-button type="submit" @click="resetForm()" class="mx-3" variant="outline-danger">Reset</b-button>
                    </div>
                </b-modal>
                <b-container class="mt-2">
                    <b-row align-v="center">
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" /> -->
                            <b-button variant="outline-primary" size="sm" @click="leadCreateModalShow">
                                <i class="fa fa-plus-square" aria-hidden="true"></i>
                                <span class="mx-1">Lead</span>
                            </b-button>
                        </b-col>
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getAllAssignedSearchCount(keyword)" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllAssignedSearchCount(keyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" @input="switchPage(currentPage, pageCount)" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" @input="switchPage(currentPage, pageCount)" v-model="pageCount" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="getAllAssignedLeadCount()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <!-- =======leads selected======
                    <span v-for="s in selected" :key="s.contact">{{ s.contact }}, </span> -->
                    <b-card class="card mt-1">
                        <b-checkbox v-if="selectAllLeads == true" v-model="selectAllLeads" class="text-start" @change="selectAllLeadsTrue()">
                            <span class="px-2">Select All</span>
                        </b-checkbox>
                        <b-checkbox v-if="selectAllLeads == false" v-model="selectAllLeads" @change="selectAllLeadsFalse()" class="text-start">
                            <span class="px-2">Select All</span>
                        </b-checkbox>
                        <div class="text-end" v-if="selected.length != 0">
                            <b-button variant="primary" @click="multipleLeadAssigModalShow()">{{selected.length}} Leads Assign</b-button>
                        </div>
                        <b-table hover responsive :items="leads" :fields="fields" :per-page="leads.pageCount" :current-page="currentPage" ref="table">
                            <template v-slot:cell(select)="data">
                                <b-form-checkbox-group id="check" name="selectLead" v-model="selected">
                                    <b-form-checkbox :value="data.item"></b-form-checkbox>
                                </b-form-checkbox-group>
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="cellSize">
                                    {{ dateFormat(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(assignTo)="data">
                                <span v-if="data.item.assignTo != null && (data.item.multiAssignedTo == null || data.item.multiAssignedTo.length == 0)">{{data.item.assignTo.firstName}} {{data.item.assignTo.lastName}}</span>
                                <!-- <b-badge v-else style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill><span>No Agent Assign</span></b-badge> -->
                                <span v-else >
                                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', 'sans-serif';" pill v-for="item in data.item.multiAssignedTo" v-bind:key="item.index" variant="warning">{{item.firstName}}{{item.lastName}}&nbsp;
                                    </b-badge>
                                </span>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View leads" variant="primary" @click="leadViewModalShow(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button v-if="data.item.callId == null && selected.length == 0" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Lead assign" variant="success" @click="leadAssignToAgentModalShow(data.item)">
                                    <font-awesome-icon icon="envelope-open-text" />
                                </b-button>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
                <b-modal title="New Lead" ref="newLeadModal" no-close-on-backdrop hide-footer>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="lead.name" class="form-control" placeholder="Enter name"></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input type="number" v-model="lead.contact" :class="{'is-invalid': $v.lead.contact.$error,'is-valid': !$v.lead.contact.$invalid,}" class="form-control" placeholder="Enter contact name"></b-form-input>
                            <div class="valid-feedback">Your Contact is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.lead.contact.required">Contact is required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-input type="text" v-model="lead.course" class="form-control" placeholder="Enter Course name (Optional)"></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Comment</label>
                            <b-form-input type="text" v-model="lead.comment" class="form-control" placeholder="Enter comment (Optional)"></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Agent</label>
                            <div style="color:#00000099;font-size:12px;" class="pb-1">
                                <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                <span class="px-1">if you have to assign the lead to the agent, please select</span>
                            </div>
                            <b-form-select class="form-control" v-model="selectedAgent">
                                <option value="null" disabled>Select Agent</option>
                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                    <strong>{{ item.firstName }}</strong>
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="text-center pt-4 pb-2">
                        <b-button type="submit" :disabled="disabledButton" @click="newLeadCreate()" class="" variant="outline-primary">Create</b-button>
                        <b-button @click="$refs.newLeadModal.hide(),resetLeadForm()" class="mx-3" variant="outline-danger">Cancel</b-button>
                    </div>
                </b-modal>

            </b-tab>
            <b-tab title="Not Assigned" @click="getAllLeadNotAssignCount()">
                <div class="text-end pb-3">
                    <b-button variant="outline-primary" @click="fileUploadModaShow()">File Upload</b-button>
                </div>
                <b-container class="mt-2">
                    <b-row align-v="center">
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" /> -->
                            <b-button variant="outline-primary" size="sm" @click="leadCreateModalShow">
                                <i class="fa fa-plus-square" aria-hidden="true"></i>
                                <span class="mx-1">Lead</span>
                            </b-button>
                        </b-col>
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getAllLeadNotAssignSearchCount(keyword)" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllLeadNotAssignSearchCount(keyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="notAssignedCurrentPage" :total-rows="totalCount" :per-page="notAssignedPageCount" @input="switchPage(notAssignedCurrentPage, notAssignedPageCount)" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" @input="switchPage(notAssignedCurrentPage, notAssignedPageCount)" v-model="notAssignedPageCount" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="getAllLeadNotAssignCount()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1">
                        <b-checkbox v-if="selectAllLeads == true" v-model="selectAllLeads" class="text-start" @change="selectAllLeadsTrue()">
                            <span class="px-2">Select All</span>
                        </b-checkbox>
                        <b-checkbox v-if="selectAllLeads == false" v-model="selectAllLeads" @change="selectAllLeadsFalse()" class="text-start">
                            <span class="px-2">Select All</span>
                        </b-checkbox>
                        <div class="text-end" v-if="selected.length != 0">
                            <b-button variant="primary" @click="multipleLeadAssigModalShow()">{{selected.length}} Leads Assign</b-button>
                        </div>
                        <b-table hover responsive :items="leads" :fields="fields" :per-page="leads.notAssignedPageCount" :current-page="notAssignedCurrentPage" ref="table">
                            <template v-slot:cell(select)="data">
                                <b-form-checkbox-group id="check" name="selectLead" v-model="selected">
                                    <b-form-checkbox :value="data.item"></b-form-checkbox>
                                </b-form-checkbox-group>
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <div class="cellSize">
                                    {{ dateFormat(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <!-- <template v-slot:cell(assignTo)="data">
                                <span v-if="data.item.assignTo != null">{{data.item.assignTo.firstName}} {{data.item.assignTo.lastName}}</span>
                                <b-badge v-else style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill><span>No Agent Assign</span></b-badge>
                            </template> -->
                            <template v-slot:cell(assignTo)="">
                                <!-- <span v-if="data.item.multiAssignedTo != null">
                                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', 'sans-serif';" pill v-for="item in data.item.multiAssignedTo" v-bind:key="item.index" variant="warning">{{item.firstName}}{{item.lastName}}&nbsp;
                                    </b-badge>
                                </span>
                                <b-badge v-else style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill><span>No Agent Assign</span></b-badge> -->
                                <b-badge style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill><span>No Agent Assign</span></b-badge>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View leads" variant="primary" @click="leadViewModalShow(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button v-if="data.item.callId == null" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Lead assign" variant="success" @click="leadAssignToAgentModalShow(data.item)">
                                    <font-awesome-icon icon="envelope-open-text" />
                                </b-button>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </b-tab>
            <b-tab title="Lead To Call" @click="getAllLeadConvertCallCount()">
                <b-container class="mt-2">
                    <b-row align-v="center">
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" /> -->
                            <b-button variant="outline-primary" size="sm" @click="leadCreateModalShow" disabled>
                                <i class="fa fa-plus-square" aria-hidden="true"></i>
                                <span class="mx-1">Lead</span>
                            </b-button>
                        </b-col>
                        <b-col col sm="12" md="3" lg="3" id="r1">
                            <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getAllLeadConvertCallSearchCount(keyword)" />
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllLeadConvertCallSearchCount(keyword)">Search</b-button>
                        </b-col>
                        <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                            <b-pagination v-model="leadConvertCallCurrentPage" :total-rows="totalCount" :per-page="leadConvertCallPageCount" @input="switchPage(leadConvertCallCurrentPage, leadConvertCallPageCount)" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2"></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="2" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" @input="switchPage(leadConvertCallCurrentPage, leadConvertCallPageCount)" v-model="leadConvertCallPageCount" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col col lg="1" md="1" id="r1" sm="6">
                            <span style="color: #0974ff; cursor: pointer" @click="getAllLeadConvertCallCount()">
                                <font-awesome-icon icon="sync-alt" />Refresh</span>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-card class="card mt-1">
                        <b-table hover responsive :items="leads" :fields="fields" :per-page="leads.leadConvertCallPageCount" :current-page="leadConvertCallCurrentPage" ref="table">
                            <template v-slot:cell(createdAt)="data">
                                <div class="cellSize">
                                    {{ dateFormat(data.item.createdAt, "fullDate") }}
                                </div>
                            </template>
                            <template v-slot:cell(assignTo)="data">
                                <span v-if="data.item.assignTo != null">{{data.item.assignTo.firstName}} {{data.item.assignTo.lastName}}</span>
                                <b-badge v-else style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill><span>No Agent Assign</span></b-badge>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View leads" variant="primary" @click="leadViewModalShow(data.item)">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button v-if="data.item.callId == null" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Lead assign" variant="success" @click="leadAssignToAgentModalShow(data.item)">
                                    <font-awesome-icon icon="envelope-open-text" />
                                </b-button>
                            </template>
                        </b-table>
                    </b-card>
                </b-container>
            </b-tab>
        </b-tabs>
    </b-container>
    <b-modal title="Leads Upload Details" ref="leadUploadDetails" no-close-on-backdrop hide-footer>
        <div v-if="leadsExcelSheet.length - (alreadyExistContacts.length + invalidLeads.length) != 0" class="text-center">
            <h6><span class="text-primary">{{leadsExcelSheet.length - (alreadyExistContacts.length + invalidLeads.length)}}</span> leads uploaded successfully..</h6>
            <hr>
        </div>
        <div v-if="alreadyExistContacts.length != 0">
            <h5 class="text-primary">This contacts are already exist</h5>
            <b-table hover responsive :items="alreadyExistContacts" :fields="invalidContactFields" :per-page="alreadyExistContacts.length" :current-page="currentPage" ref="table">
            </b-table>
            <hr>
        </div>
        <div v-if="invalidLeads.length != 0">
            <h5 class="text-primary">This contacts are invalid</h5>
            <b-table hover responsive :items="invalidLeads" :fields="invalidContactFields" :per-page="invalidLeads.length" :current-page="currentPage" ref="table">
            </b-table>
        </div>
    </b-modal>
    <b-modal title="Invalid Excel Sheet Contacts" ref="invalidContactModal" no-close-on-backdrop hide-footer>
        <div v-if="invalidContactRows != []">
            <div>
                <!-- <h6>Invalid contact excel sheet rows</h6> -->
                <h6>Excel sheet invalid contact row numbers </h6>
                <!-- <span v-for="(i,index) in invalidContactRows" v-bind:key="i">{{i}}<span v-if="index != invalidContactRows.length-1">,</span> </span> -->
                <b-badge style="background-color: #ffc107;color: #212529;padding: 0.5em;margin-right: 1em;font-family: 'Pavanam', sans-serif;" pill v-for="i in invalidContactRows" v-bind:key="i">
                    {{i}}
                    <!-- <span v-if="index != invalidContactRows.length-1">,</span> -->
                </b-badge>
            </div>
            <!-- <div>
                <span>This rows are invalid contacts</span>
            </div> -->
        </div>
    </b-modal>
    <b-modal title="Agent Assign" ref="leadsAgentAssign" no-close-on-backdrop hide-footer>
        <div class="text-center" v-if="leadsExcelSheet != []">
            <h5>Totally <span class="text-primary">{{ leadsExcelSheet.length }}</span> contacts</h5>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Agent</label>
                <b-form-select class="form-control" v-model="selectedAgent">
                    <option value="null" disabled>Select Agent</option>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>
                <br>
                <div v-if="selectedAgent!=null" class="text-center">
                    <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveAgents"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                </div>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="agents.length != 0">
                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in agents" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeAgents(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                    </b-badge>
                </b-card>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                    <div class="text-center text-secondary" style="font-weight:bold;">None Of The Agents Added!</div>
                </b-card>
                <div style="color:#00000099;font-size:12px;" class="pt-1">
                    <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    <span class="px-1">if you have to assign the {{leadsExcelSheet.length}} contacts to the agent, please select</span>
                </div>
            </div>
        </div>
        <div class="text-center py-3">
            <b-button class="mx-2" variant="primary" :disabled="disabledButton" @click="disabledButton = true,fileDataApiSend(leadsExcelSheet)">Upload</b-button>
            <b-button class="mx-2" variant="danger" @click="$refs.leadsAgentAssign.hide(),selectedAgent=null,disabledButton = false">Cancel</b-button>
        </div>
    </b-modal>
    <b-modal title="Lead" ref="leadView" no-close-on-backdrop hide-footer>
        <div class="form-row mt-3 px-5" v-if="assignLead.agent != null">
            <div class="form-group my-2">
                <label>Call Convert Agent Name</label>
                <b-form-input type="text" v-model="assignLead.agent.firstName" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Name</label>
                <b-form-input type="text" v-model="assignLead.name" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Contact</label>
                <b-form-input type="number" v-model="assignLead.contact" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Course</label>
                <b-form-input type="text" v-model="assignLead.course" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Comment</label>
                <b-form-input type="text" v-model="assignLead.comment" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="mt-3 px-5" v-if="assignLead.assignedAgent != null">
            <div class="form-group my-2">
                <label>Assigned Agents</label>
                <b-table responsive bordered striped hover :items="assignLead.assignedAgent" :fields="assignedAgentFields">
                    <template v-slot:cell(firstName)="data">
                        <span class="title">{{ data.item.firstName }} {{ data.item.lastName }}</span>
                    </template>
                    <template v-slot:cell(assignDate)="data">
                        {{ dateFormat(data.item.assignDate, "dateConvert") }}
                    </template>
                </b-table>
            </div>
        </div>
    </b-modal>
    <b-modal title="Lead assign to agent" ref="leadAssignToAgent" no-close-on-backdrop hide-footer>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Name</label>
                <b-form-input type="text" v-model="assignLead.name" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Contact</label>
                <b-form-input type="number" v-model="assignLead.contact" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Course</label>
                <b-form-input type="text" v-model="assignLead.course" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Comment</label>
                <b-form-input type="text" v-model="assignLead.comment" class="form-control" disabled></b-form-input>
            </div>
        </div>
        <div class="mt-3 px-5" v-if="assignLead.assignedAgent != null">
            <div class="form-group my-2">
                <label>Assigned Agents</label>
                <b-table responsive bordered striped hover :items="assignLead.assignedAgent" :fields="assignedAgentFields">
                    <template v-slot:cell(firstName)="data">
                        <span class="title">{{ data.item.firstName }} {{ data.item.lastName }}</span>
                    </template>
                    <template v-slot:cell(assignDate)="data">
                        {{ dateFormat(data.item.assignDate, "dateConvert") }}
                    </template>
                </b-table>
            </div>
        </div>
        <div class="mt-3 px-5">
            <div class="form-group my-2">
                <label>Agent</label>
                <div style="color:#00000099;font-size:12px;" class="pb-1">
                    <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    <span class="px-1">if you have to assign the lead to the agent, please select</span>
                </div>
                <b-form-select class="form-control" v-model="leadAssignAgent" :class="{'is-invalid': $v.leadAssignAgent.$error,'is-valid': !$v.leadAssignAgent.$invalid,}">
                    <option value="null" disabled>Select Agent</option>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>
                <div class="valid-feedback">This lead is assigned to this agent</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.leadAssignAgent.required">Please select the agent is required</span>
                </div>
            </div>
        </div>
        <div class="text-center py-3">
            <b-button class="mx-2" variant="primary" :disabled="disabledButton" @click="disabledButton = true,leadAssignToAgentApi()">Assign</b-button>
            <b-button class="mx-2" variant="danger" @click="resetLeadAssignToAgent()">Cancel</b-button>
        </div>
    </b-modal>
    <b-modal title="Multi Lead Assign" ref="multiLeadAssign" no-close-on-backdrop hide-footer>
        <!-- <div class="text-center">
            <h6><span class="text-primary">{{ selected.length }}</span> leads assing to the agent</h6>
        </div>
        <div class="mt-3 px-5">
            <div class="form-group my-2">
                <label>Agent</label>
                <div style="color:#00000099;font-size:12px;" class="pb-1">
                    <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    <span class="px-1">if you have to assign the lead to the agent, please select</span>
                </div>
                <b-form-select class="form-control" v-model="leadAssignAgent" :class="{'is-invalid': $v.leadAssignAgent.$error,'is-valid': !$v.leadAssignAgent.$invalid,}">
                    <option value="null" disabled>Select Agent</option>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>
                <div class="valid-feedback">This lead is assigned to this agent</div>
                <div class="invalid-feedback">
                    <span v-if="!$v.leadAssignAgent.required">Please select the agent is required</span>
                </div>
            </div>
        </div>
        <div class="text-center py-3">
            <b-button class="mx-2" variant="primary" :disabled="disabledButton" @click="disabledButton = true,multiLeadAssignToAgentApi()">Assign</b-button>
            <b-button class="mx-2" variant="danger" @click="resetLeadAssignToAgent()">Cancel</b-button>
        </div> -->
        <div class="text-center">
            <h5>Totally <span class="text-primary">{{ selected.length }}</span> contacts</h5>
        </div>
        <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
                <label>Agent</label>
                <b-form-select class="form-control" v-model="leadAssignAgent">
                    <option value="null" disabled>Select Agent</option>
                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                        <strong>{{ item.firstName }}</strong>
                    </option>
                </b-form-select>
                <br>
                <div v-if="leadAssignAgent!=null" class="text-center">
                    <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveAssignedAgents"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                </div>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="agents.length != 0">
                    <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in agents" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeAgents(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                    </b-badge>
                </b-card>
                <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                    <div class="text-center text-secondary" style="font-weight:bold;">None Of The Agents Added!</div>
                </b-card>
                <!-- <div style="color:#00000099;font-size:12px;" class="pt-1">
                    <span><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    <span class="px-1">if you have to assign the {{leadsExcelSheet.length}} contacts to the agent, please select</span>
                </div> -->
            </div>
        </div>
        <div class="text-center py-3">
            <b-button class="mx-2" variant="primary" :disabled="disabledButton" @click="disabledButton = true,multiLeadAssignToAgentApi()">Assign</b-button>
            <b-button class="mx-2" variant="danger" @click="resetLeadAssignToAgent">Cancel</b-button>
        </div>
    </b-modal>
</div>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";
import Account from '../service/Account.js';
import LeadService from "../service/LeadsService"
import moment from "moment-timezone";
import {
    required,
    minLength,
    maxLength
} from "vuelidate/lib/validators";
import CallService from "../service/CallService";
import CourseService from "../service/CourseService";
import SourceService from "../service/SourceService";
import BranchService from "../service/BranchService";
import qualification from "../assets/json_file/qualification.json";
import AgentService from '../service/AgentService.js';
export default {
    name: 'AdminLeads',
    data() {
        return {
            lead: {
                name: '',
                contact: '',
                course: '',
                comment: ''
            },
            leads: [],
            fields: [{
                    key: 'select',
                    label: ''
                },
                {
                    key: 'allSelect',
                    label: ''
                },
                {
                    key: "createdAt",
                    label: "Created At",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "comment",
                    label: "Comment",
                },
                {
                    key: "assignTo",
                    label: "Assign To",
                },
                {
                    key: "actions",
                    label: "Action",
                },
            ],
            invalidContactFields: [{
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "comment",
                    label: "Comment",
                }
            ],
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            notAssignedCurrentPage: 1,
            notAssignedPageCount: 5,
            leadConvertCallCurrentPage: 1,
            leadConvertCallPageCount: 5,
            pageOptions: [
                1,
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalCount: 0,
            keyword: "",
            loading: false,
            call: {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            },
            qualificationList: qualification,
            options: [{
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                {
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            allBranches: [],
            allSources: [],
            allCourses: [],
            disabledButton: false,
            leadId: "",
            agentId: "",
            leadAssignedPageable: false,
            leadsAssignedSearchPageable: false,
            leadNotAssignPagination: false,
            leadNotAssignSearchPagination: false,
            file: null,
            leadFileList: [{
                name: '',
                contact: '',
                course: '',
                comment: ''
            }],
            fileListData: [],
            invalidLeads: [],
            alreadyExistContacts: [],
            invalidContactRows: [],
            selectedAgent: null,
            allAgents: [],
            leadsExcelSheet: [],
            assignLead: {
                name: '',
                contact: '',
                course: '',
                comment: '',
                agent: null,
                callId: '',
                assignedAgent: [],
                assignTo: null,
            },
            assignedAgentFields: [{
                    key: "firstName",
                    label: "Name",
                },
                // {
                //     key: "name",
                //     label: "username",
                // },
                {
                    key: "assignDate",
                    label: "Assign Date",
                }
            ],
            leadAssignAgent: null,
            selected: [],
            selectAllLeads: false,
            agents: [],
        }
    },
    validations: {
        call: {
            name: {
                required,
                minLength: minLength(3),
            },

            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            alternativeContact: {
                //   optionalField,
                //   minLength: minLength(10),
                // maxLength: maxLength(10),
                // required: requiredIf(value => value.length == 10 ),
            },
            age: {
                required,
                Number,
                minLength: minLength(2),
                maxLength: maxLength(2),
            },
            qualification: {
                required,
            },
            course: {
                required,
            },
            branch: {
                required,
            },
            source: {
                required,
            },
            mode: {
                required,
            },
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
        lead: {
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
        },
        leadAssignAgent: {
            required
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                if (this.leadsAssignedSearchPageable) {
                    this.getAllAssignedLeadCount();
                } else if (this.leadNotAssignSearchPagination) {
                    this.getAllLeadNotAssignCount();
                } else if (this.leadConvertCallSearchPagination) {
                    this.getAllLeadConvertCallCount();
                }
            }
        },
        selected() {
            var existCount = 0;
            this.leads.forEach(e => {
                for (const uniqueObj of this.selected) {
                    if (e.contact === uniqueObj.contact) {
                        existCount++;
                    }
                }
            })
            if (existCount != this.leads.length) {
                this.selectAllLeads = false;
            } else {
                this.selectAllLeads = true;
            }
        },
        leads() {
            var existCount = 0;
            this.leads.forEach(e => {
                for (const uniqueObj of this.selected) {
                    if (e.contact === uniqueObj.contact) {
                        existCount++;
                    }
                }
            })
            if (this.leads.length != 0 && existCount == this.leads.length) {
                this.selectAllLeads = true;
            } else {
                this.selectAllLeads = false;
            }
        },
        // selectAllLeads() {
        //     console.log("watch====selectAllLeads====");
        // }
    },
    mounted() {
        if (localStorage.getItem("agentId") != null && localStorage.getItem("agentId") != "") {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        this.getAllAssignedLeadCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } else {
            this.$swal.fire({
                icon: 'error',
                title: 'Your role has been Removed',
                text: 'Please contact your administration!',
                // footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    },
    methods: {
        selectAllLeadsTrue() {
            if (this.selectAllLeads) {
                this.leads.forEach(e => {
                    let isDuplicate = false;

                    for (const uniqueObj of this.selected) {
                        if (e.contact === uniqueObj.contact) {
                            console.log("if (e.contact === uniqueObj.contact) {");
                            isDuplicate = true;
                            break;
                        }
                    }

                    if (!isDuplicate) {
                        this.selected.push(e);
                    }

                });
            }
        },
        selectAllLeadsFalse() {
            if (!this.selectAllLeads) {
                this.leads.forEach(e => {
                    const index = this.selected.findIndex(obj => obj.contact === e.contact);
                    if (index !== -1) {
                        this.selected.splice(index, 1);
                    }
                });
            }
        },
        multiLeadAssignToAgentApi: function () {
            // console.log("=====MultiLeadAssignToAgentApi Method Calling=====");
            // this.$v.leadAssignAgent.$touch();
            // console.log("=====LeadAssignAgent=====",this.agents);
            // if (!this.$v.leadAssignAgent.$invalid) {
            // if (this.agents != null) {
            if (this.agents.length != 0) {
                // console.log("=====If Method Calling=====");
                return new Promise((resolve, reject) => {
                    LeadService.multiLeadAssignToAgentApi(this.selected, this.agents)
                        .then((response) => {
                            var length = this.selected.length;
                            this.$swal({
                                title: "Success",
                                text: length + " Leads assigned to the agent successfully",
                                icon: "success",
                                button: "ok",
                            });
                            this.$refs.multiLeadAssign.hide();
                            this.resetLeadAssignToAgent();
                            this.selected.splice(0);
                            if (this.leadAssignedPageable || this.leadsAssignedSearchPageable) {
                                this.getAllAssignedLeadCount();
                            } else if (this.leadNotAssignPagination || this.leadNotAssignSearchPagination) {
                                this.getAllLeadNotAssignCount();
                            } else if (this.leadConvertCallPagination || this.leadConvertCallSearchPagination) {
                                this.getAllLeadConvertCallCount();
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            }
            this.$swal({
                title: "Please Select Agent",
                text: "Assigned Agent Value Is Empty",
                icon: "warning",
                button: "ok",
            });
            // console.log("=====Else Method Calling=====");
            this.disabledButton = false;
        },
        async multipleLeadAssigModalShow() {
            this.agents.splice(0);
            this.resetLeadAssignToAgent();
            await this.getAllAgents();
            this.$refs.multiLeadAssign.show();
        },
        getAllLeadConvertCallCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.leadNotAssignPagination = false;
            this.leadNotAssignSearchPagination = false;
            this.leadAssignedPageable = false;
            this.leadsAssignedSearchPageable = false;
            this.leadConvertCallPagination = true;
            this.leadConvertCallSearchPagination = false;
            this.leads.splice(0);
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadConvertCallCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllLeadConvertCallPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadConvertCallPageable: function (page, count) {
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadConvertCallPageable(page, count)
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadConvertCallSearchCount: function (keyword) {
            this.loading = true;
            this.currentPage = 1;
            this.leadNotAssignPagination = false;
            this.leadNotAssignSearchPagination = false;
            this.leadAssignedPageable = false;
            this.leadsAssignedSearchPageable = false;
            this.leadConvertCallPagination = false;
            this.leadConvertCallSearchPagination = true;
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadConvertCallSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllLeadConvertCallSearchPageable(this.currentPage, this.pageCount, this.keyword);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadConvertCallSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadConvertCallSearchPageable(currentPage, pageCount, keyword.toLowerCase())
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        leadAssignToAgentApi: function () {
            this.$v.leadAssignAgent.$touch();
            if (!this.$v.leadAssignAgent.$invalid) {
                return new Promise((resolve, reject) => {
                    LeadService.leadAssignToAgentApi(this.assignLead.leadId, this.leadAssignAgent)
                        .then((response) => {
                            this.$swal({
                                title: "Success",
                                text: "Leads assigned to the agent successfully",
                                icon: "success",
                                button: "ok",
                            });
                            this.resetLeadAssignToAgent();
                            if (this.leadAssignedPageable || this.leadsAssignedSearchPageable) {
                                this.getAllAssignedLeadCount();
                            } else if (this.leadNotAssignPagination || this.leadNotAssignSearchPagination) {
                                this.getAllLeadNotAssignCount();
                            } else if (this.leadConvertCallPagination || this.leadConvertCallSearchPagination) {
                                this.getAllLeadConvertCallCount();
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });

            }
            this.disabledButton = false;
        },
        async leadAssignToAgentModalShow(lead) {
            this.resetLeadAssignToAgent();
            await this.getAllAgents();
            this.assignLead = lead;
            this.$refs.leadAssignToAgent.show();
        },
        leadViewModalShow(lead) {
            this.assignLead = lead;
            this.$refs.leadView.show();
        },
        fileUploadModaShow() {
            this.file = null;
            this.$refs.leadFileUploadModal.show();
        },
        leadFileUpload() {
            var self = this;
            this.agents.splice(0);
            let fileReader = new FileReader();
            fileReader.readAsArrayBuffer(this.file);
            fileReader.onload = () => {
                //     console.log(e);
                this.arrayBuffer = fileReader.result;
                var data = new Uint8Array(this.arrayBuffer);
                var arr = new Array();
                for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLSX.read(bstr, {
                    type: "binary"
                });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                console.log(XLSX.utils.sheet_to_json(worksheet, {
                    raw: true
                }));
                var arraylist = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true
                });
                // this.filelist = [];
                self.leadsAgentAssignModalShow(arraylist);
            }
        },
        async leadsAgentAssignModalShow(arraylist) {
            this.leadsExcelSheet.splice(0);
            if (arraylist.length != 0) {
                this.$refs.leadFileUploadModal.hide();
                await this.getAllAgents();
                this.leadsExcelSheet = arraylist;
                this.selectedAgent = null;
                this.$refs.leadsAgentAssign.show();
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Invalid sheet',
                    text: 'Please fill the details!',
                })
            }
        },
        async fileDataApiSend(excelList) {
            this.loading = true;
            var pattern = new RegExp("^[0-9]{10}$");
            if (excelList.length != 0) {
                this.fileListData = [];
                this.invalidContactRows = [];
                excelList.forEach(e => {
                    var name = e.Name != undefined ? e.Name : '';
                    var course = e.Course != undefined ? e.Course : '';
                    var command = e.Command != undefined ? e.Command : '';
                    if (pattern.test(e.Contact) && e.Contact != undefined && e.Contact != "") {
                        // console.log(pattern.test(e.Contact));
                        this.lead = {
                            name: name,
                            contact: e.Contact,
                            course: course,
                            command: command
                        }
                        this.fileListData.push(this.lead);
                    } else {
                        this.lead = {
                            name: name,
                            contact: e.Contact,
                            course: course,
                            command: command
                        }
                        this.invalidLeads.push(this.lead);
                    }
                });
                if (this.fileListData.length != 0) {
                    let data = {
                        fileList: this.fileListData,
                        // agent: this.selectedAgent,
                        agents: this.agents
                    }
                    return new Promise((resolve, reject) => {
                        console.log("=====Data=====", data);
                        axios.post('/admin/lead/file', data)
                            .then((response) => {
                                this.$refs.leadFileUploadModal.hide();
                                this.$refs.leadsAgentAssign.hide();
                                this.file = null;
                                this.disabledButton = false;
                                this.loading = false;
                                if (response.data == 0 && this.invalidLeads.length == 0) {
                                    this.$swal({
                                        title: "Success",
                                        text: "Leads created successfully",
                                        icon: "success",
                                        button: "ok",
                                    });
                                } else {
                                    this.$refs.leadUploadDetails.show();
                                    if (response.data != "" && response.data != []) {
                                        this.alreadyExistContacts = response.data;
                                    }
                                }
                                if (this.leadAssignedPageable || this.leadsAssignedSearchPageable) {
                                    this.getAllAssignedLeadCount();
                                } else if (this.leadNotAssignPagination || this.leadNotAssignSearchPagination) {
                                    this.getAllLeadNotAssignCount();
                                } else if (this.leadConvertCallPagination || this.leadConvertCallSearchPagination) {
                                    this.getAllLeadConvertCallCount();
                                }
                                resolve(response);
                            })
                            .catch((err) => {
                                this.loading = false;
                                if (err.response.status == 409)
                                    this.$swal.fire({
                                        icon: 'error',
                                        title: 'Invalid sheet',
                                        text: 'No leads created!',
                                    })
                                reject(err);
                            });
                    });
                } else {
                    this.$refs.leadFileUploadModal.hide();
                    this.$refs.leadsAgentAssign.hide();
                    this.file = null;
                    this.$refs.leadUploadDetails.show();
                    this.disabledButton = false;
                    this.loading = false;
                }
            } else {
                this.loading = false;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Invalid sheet',
                    text: 'Please fill the details!',
                });
            }
        },
        getAllAgents: function () {
            this.allAgents.splice(0);
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllLeadNotAssignCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.leadNotAssignPagination = true;
            this.leadNotAssignSearchPagination = false;
            this.leadAssignedPageable = false;
            this.leadsAssignedSearchPageable = false;
            this.selected.length = 0;
            this.leads.splice(0);
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadNotAssignCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllLeadNotAssignPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadNotAssignPageable: function (page, count) {
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadNotAssignPageable(page, count)
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadNotAssignSearchCount: function (keyword) {
            this.loading = true;
            this.currentPage = 1;
            this.leadNotAssignPagination = false;
            this.leadNotAssignSearchPagination = true;
            this.leadAssignedPageable = false;
            this.leadsAssignedSearchPageable = false;
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadNotAssignSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllLeadNotAssignSearchPageable(this.currentPage, this.pageCount, this.keyword);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllLeadNotAssignSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                LeadService.getAllLeadNotAssignSearchPageable(currentPage, pageCount, keyword.toLowerCase())
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        newLeadCreate: function () {
            this.disabledButton = true;
            this.$v.lead.$touch();
            if (!this.$v.lead.contact.$invalid) {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    LeadService.isContactUnique(this.lead.contact)
                        .then((response) => {
                            resolve(response)
                            if (response.data == true) {
                                return new Promise((resolve, reject) => {
                                    CallService.getContact(this.lead.contact)
                                        .then((response) => {
                                            resolve(response)
                                            if (response.data == true) {
                                                var agentId;
                                                if (this.selectedAgent == null) {
                                                    agentId = null
                                                } else {
                                                    agentId = this.selectedAgent.agentId
                                                }
                                                return new Promise((resolve, reject) => {
                                                    LeadService.manualLeadCreate(this.lead, agentId)
                                                        .then((response) => {
                                                            this.$swal({
                                                                title: "Good job!",
                                                                text: "New lead created..!",
                                                                icon: "success",
                                                                button: "ok",
                                                            });
                                                            if (this.leadAssignedPageable || this.leadsAssignedSearchPageable) {
                                                                this.getAllAssignedLeadCount();
                                                            } else if (this.leadNotAssignPagination || this.leadNotAssignSearchPagination) {
                                                                this.getAllLeadNotAssignCount();
                                                            }
                                                            this.$refs.newLeadModal.hide();
                                                            this.resetLeadForm();
                                                            this.disabledButton = false;
                                                            this.loading = false
                                                            resolve(response);
                                                        })
                                                        .catch((err) => {
                                                            this.loading = false;
                                                            reject(err);
                                                        });
                                                });
                                            } else {
                                                this.loading = false;
                                                this.disabledButton = false;
                                                this.$swal.fire({
                                                    icon: "error",
                                                    title: "Oops...",
                                                    text: "Contact is already exist in calls!",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.loading = false;
                                this.disabledButton = false;
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Contact is already exist in leads!",
                                });
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            this.disabledButton = false;
        },
        async leadCreateModalShow() {
            this.resetLeadForm();
            await this.getAllAgents();
            this.$refs.newLeadModal.show();
        },
        getAllAssignedSearchCount: function (keyword) {
            this.loading = true;
            this.leadsAssignedSearchPageable = true;
            this.leadAssignedPageable = false;
            this.leadNotAssignPagination = false;
            this.leadNotAssignSearchPagination = false;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                LeadService.getAllAssignedSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAssignedSearchPageable(this.currentPage, this.pageCount, this.keyword);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllAssignedSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                LeadService.getAllAssignedSearchPageable(currentPage, pageCount, keyword.toLowerCase())
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        // async callModalShow(lead) {
        //     await this.resetForm();
        //     await this.getAllCourses();
        //     await this.getAllSources();
        //     await this.getAllBranches();
        //     console.log("leadAgentAssign========== ", lead.leadId);
        //     this.leadId = lead.leadId;
        //     this.call = {
        //         name: lead.name,
        //         contact: lead.contact,
        //         alternativeContact: "",
        //         age: "",
        //         qualification: null,
        //         course: null,
        //         source: null,
        //         mode: null,
        //         branch: null,
        //         followupDate: "",
        //         remark: lead.comment,
        //     }
        //     this.$refs.modal.show();
        // },
        callInsert: function () {
            this.$v.call.$touch();
            if (this.$v.call.$invalid) {
                this.submitStatus = "FAIL";
                // alert("insert submit failed");
            } else {
                return new Promise((resolve, reject) => {
                    CallService.getContact(this.call.contact)
                        .then((response) => {
                            if (response.data == true) {
                                if (this.call.alternativeContact == "") {
                                    let data = {
                                        name: this.call.name,
                                        contact: this.call.contact,
                                        alternativeContact: this.call.alternativeContact,
                                        age: this.call.age,
                                        qualification: this.call.qualification,
                                        course: this.call.course,
                                        source: this.call.source,
                                        mode: this.call.mode,
                                        branch: this.call.branch,
                                        followupDate: this.call.followupDate,
                                        remark: this.call.remark,
                                        teamId: axios.defaults.headers.common["tenant-uuid"]
                                    }
                                    return new Promise((resolve, reject) => {
                                        // //console.log(this.agentId);
                                        CallService.putCall(this.agentId, data)
                                            .then((response) => {
                                                this.$swal({
                                                    title: "Good job!",
                                                    text: response.data,
                                                    icon: "success",
                                                    button: "ok",
                                                });
                                                this.LeadAssignToAgent();
                                                this.resetForm();
                                                this.$refs.modal.hide();
                                                // this.getAllPageable();
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                this.loading = false;
                                                reject(err);
                                            });
                                    });
                                } else {
                                    return new Promise((resolve, reject) => {
                                        CallService.getalternativeContact(
                                                this.call.alternativeContact
                                            )
                                            .then((response) => {
                                                //console.log("Response====",response.data)
                                                if (response.data == true) {
                                                    this.submitStatus = "SUCCESS";
                                                    //console.log("alternativeContact calling");
                                                    return new Promise((resolve, reject) => {
                                                        // //console.log(this.agentId);
                                                        let data = {
                                                            name: this.call.name,
                                                            contact: this.call.contact,
                                                            alternativeContact: this.call.alternativeContact,
                                                            age: this.call.age,
                                                            qualification: this.call.qualification,
                                                            course: this.call.course,
                                                            source: this.call.source,
                                                            mode: this.call.mode,
                                                            branch: this.call.branch,
                                                            followupDate: this.call.followupDate,
                                                            remark: this.call.remark,
                                                            teamId: axios.defaults.headers.common["tenant-uuid"]
                                                        }
                                                        CallService.putCall(this.agentId, data)
                                                            .then((response) => {
                                                                this.$swal({
                                                                    title: "Good job!",
                                                                    text: response.data,
                                                                    icon: "success",
                                                                    button: "ok",
                                                                });
                                                                this.LeadAssignToAgent();
                                                                this.resetForm();
                                                                this.$refs.modal.hide();
                                                                // this.getAllPageable();
                                                                this.disabledButton = false;
                                                                resolve(response);
                                                            })
                                                            .catch((err) => {
                                                                this.loading = false;
                                                                reject(err);
                                                            });
                                                    });
                                                } else
                                                    this.$swal.fire({
                                                        icon: "error",
                                                        title: "Oops...",
                                                        text: "AlternativeContact is already exist!",
                                                    });
                                                this.disabledButton = false;
                                                resolve(response);
                                            })
                                            .catch((err) => {
                                                reject(err);
                                                this.disabledButton = false;
                                                this.loading = false;
                                            });
                                    });
                                }
                            } else
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Mobile Number is already exist!",
                                });
                            this.disabledButton = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        LeadAssignToAgent: function () {
            return new Promise((resolve, reject) => {
                LeadService.leadAgentAssign(this.leadId)
                    .then((response) => {
                        this.getAllAssignedLeadCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        resetForm() {
            this.call = {
                name: "",
                contact: "",
                alternativeContact: "",
                age: "",
                qualification: null,
                course: null,
                source: null,
                mode: null,
                branch: null,
                followupDate: "",
                remark: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        getAllAssignedLeadCount: function () {
            this.leadAssignedPageable = true;
            this.leadsAssignedSearchPageable = false;
            this.leadNotAssignPagination = false;
            this.leadNotAssignSearchPagination = false;
            this.currentPage = 1;
            this.loading = true;
            this.selected.length = 0;
            this.leads.splice(0);
            return new Promise((resolve, reject) => {
                LeadService.getAllAssignedLeadCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllAssignedLeadPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage(page, count) {
            this.loading = true;
            // this.selectAllLeads = false;
            if (this.leadAssignedPageable) {
                this.getAllAssignedLeadPageable(page, count);
            } else if (this.leadsAssignedSearchPageable) {
                this.getAllAssignedSearchPageable(page, count);
            } else if (this.leadNotAssignPagination) {
                this.getAllLeadNotAssignPageable(page, count);
            } else if (this.leadNotAssignSearchPagination) {
                this.getAllLeadNotAssignSearchPageable(page, count, this.keyword);
            } else if (this.leadConvertCallPagination) {
                this.getAllLeadConvertCallPageable(page, count);
            } else if (this.leadConvertCallPagination) {
                this.getAllLeadConvertCallSearchPageable(page, count, this.keyword);
            }
        },
        getAllAssignedLeadPageable: function (page, count) {
            return new Promise((resolve, reject) => {
                LeadService.getAllAssignedLeadPageable(page, count)
                    .then((response) => {
                        this.leads = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "dateConvert") {
                var convertDate = new Date(date.replace('IST', ''));
                return moment(convertDate).format("D / MM / YYYY hh:mm a");
            }
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        resetLeadForm() {
            this.selectedAgent = null;
            this.lead = {
                name: '',
                contact: '',
                course: '',
                comment: ''
            };
            this.$nextTick(() => {
                this.$v.lead.$reset();
            });
        },
        resetLeadAssignToAgent() {
            this.$refs.leadAssignToAgent.hide();
            this.disabledButton = false;
            this.leadAssignAgent = null;
            this.$nextTick(() => {
                this.$v.leadAssignAgent.$reset();
            });
        },

        saveAgents: function () {
            console.log("=====Save Agents Method Calling=====");
            console.log("=====If Condition=====", !this.agents.some(entry => entry === this.selectedAgent));
            if (!this.agents.some(entry => entry === this.selectedAgent)) {
                let agent = null;
                for (var i = 0; i < this.allAgents.length; i++) {
                    if (this.allAgents[i] == this.selectedAgent) {
                        this.agents.push(this.allAgents[i]);
                        agent = this.allAgents[i];
                        // this.allAgents.splice([i], 1);
                    }
                }
                this.allAgents = this.allAgents.filter(function (e) {
                    return e !== agent
                })
            }
            this.selectedAgent = null;
        },

        removeAgents: function (item) {
            const langIndex = this.agents.findIndex(x => x === item);
            if (langIndex !== undefined)
                this.agents.splice(langIndex, 1);
            this.allAgents.push(item);
        },

        saveAssignedAgents: function () {
            // console.log("=====Save Assigned Agents Method Calling=====");
            // console.log("=====If Condition=====", !this.agents.some(entry => entry === this.leadAssignAgent));
            if (!this.agents.some(entry => entry === this.leadAssignAgent)) {
                let agent = null;
                for (var i = 0; i < this.allAgents.length; i++) {
                    if (this.allAgents[i] == this.leadAssignAgent) {
                        this.agents.push(this.allAgents[i]);
                        agent = this.allAgents[i];
                        // this.allAgents.splice([i], 1);
                    }
                }
                this.allAgents = this.allAgents.filter(function (e) {
                    return e !== agent
                })
            }
            this.leadAssignAgent = null;
        },

    }
}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    min-width: 125px;
}
</style>
