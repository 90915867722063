<template>
<div class="Navbar">
    <div id="" class="">
        <b-navbar toggleable="lg" class="nav-sty">
            <b-container fluid>
                <b-navbar-nav id="navbrand" href="#" class="mx-5">
                    <b-nav>
                        <img class="d-inline-block mt-1 logo" src="../../public/encore.svg" width="60" height="60" alt="" loading="lazy" />
                    </b-nav>
                    <b-nav class="my-3 mx-2 tc">
                        <b class="text-animation">ENCORE</b>
                    </b-nav>
                </b-navbar-nav>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-nav class="ms-auto" tabs justified>

                        <b-nav-item href="#" id="navitem" router-link to="/hr/excel/export">
                            <font-awesome-icon icon="file-excel" /><br />
                            <b>Export</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/hr/report">
                            <font-awesome-icon icon="file-import" /><br />
                            <b>Report</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/hr/attendance">
                            <font-awesome-icon icon="clipboard" /><br />
                            <b>Attendance</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/hr/employees">
                            <font-awesome-icon icon="users" /><br />
                            <b>Employee</b>
                        </b-nav-item>

                        <b-nav-item href="#" id="navitem" router-link to="/hr/user">
                            <font-awesome-icon icon="users" /><br />
                            <b>User</b>
                        </b-nav-item>

                        <b-nav-item-dropdown right menu-class="minw-none" class="lang-box" style="color: #ffff" id="navitem">
                            <template slot="button-content">
                                <font-awesome-icon icon="user" /><br /><b>{{ firstname }} {{ lastname }}<span v-if="lastname == null">&nbsp;</span></b>
                            </template>

                            <span style="font-size:15px">
                                <!-- <b-dropdown-item v-if="!isUserSelected && IS_USER">
                                    <span @click="loginas('ROLE_USER')">Login As User</span>
                                </b-dropdown-item> -->

                                <b-dropdown-item v-if="!isHRSelected && IS_HR">
                                    <span @click="loginas('ROLE_HR')">Login As HR</span>
                                </b-dropdown-item>

                                <!-- <b-dropdown-item v-if="IS_TELECALLER && isAdminSelected" @click="loginas('ROLE_TELECALLER')">
                                    <span>TELECALLER</span>
                                </b-dropdown-item> -->
                                <!-- <b-dropdown-item v-if="(IS_TELECALLER && IS_ADMIN && !isAdminSelected)" @click="loginas('ROLE_ADMIN')">
                                    <span>ADMIN</span>
                                </b-dropdown-item> -->
                                <!-- <b-dropdown-item v-if="(IS_ADMIN && isSuperAdminSelected) || (IS_TELECALLER && IS_ADMIN && !isAdminSelected)" @click="loginas('ROLE_ADMIN')">
                                    <span>ADMIN</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="(IS_SUPER_ADMIN && !isSuperAdminSelected) && isAdminSelected" @click="loginas('ROLE_SUPER_ADMIN')">
                                    <span>SUPER ADMIN</span>
                                </b-dropdown-item> -->
                                <b-dropdown-item v-b-modal.passwordChange>
                                    <span>Settings</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="revoke()">
                                    <span style="color: #134f70"><i class="fa fa-sign-out" aria-hidden="true"></i>Logout</span>
                                </b-dropdown-item>
                            </span>
                        </b-nav-item-dropdown>
                    </b-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
    <div id="nav2">
        <b-navbar>
            <b-navbar-nav href="#" class="ms-auto">
                <b-nav class="">
                    <div class="p-3 mb-2 mt-2 text-white arrow-pointer call fa fa-phone" @click="call()" style="color: #fff">
                        CLICK TO CALL
                    </div>
                </b-nav>
            </b-navbar-nav>
        </b-navbar>
    </div>
    <PasswordChangeAccount />
    <div>
        <b-modal id="selectTeam" title="Select Team" ref="selectTeamModal" no-close-on-backdrop hide-footer>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Team</label>
                    <b-form-select class="form-control" v-model="selectedTeam" :class="{'is-invalid': $v.selectedTeam.$error,'is-valid': !$v.selectedTeam.$invalid,}">
                        <option value="null" disabled>Select Team</option>
                        <option v-for="t in teams" :value="t.teamId" v-bind:key="t.teamId">
                            <strong>{{ t.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="text-center mt-3 mt-2">
                <b-button type="submit" @click="adminLogin()" class="" variant="outline-primary">Login</b-button>
            </div>
        </b-modal>
    </div>
</div>
</template>

<script>
import axios from 'axios';
// import Swal from 'sweetalert2';
import {
    required,
} from "vuelidate/lib/validators";
import Account from '../service/Account.js';
import PasswordChangeAccount from "../components/PasswordChange.vue";

export default {
    name: "Navbar1",
    data() {
        return {
            firstname: "",
            lastname: "",
            publisher: false,
            user: false,
            roleName: "",
            currentRole: "",
            r: "",
            allRoles: [],
            IS_TELECALLER: false,
            IS_ADMIN: false,
            IS_SUPER_ADMIN: false,
            IS_HR: false,
            IS_USER: false,
            teams: {
                teamId: "",
                name: "",
            },
            selectedTeam: null,
            roleAssign:'',
        };
    },
    computed: {
        // isSuperAdminSelected: function () {
        //     if (localStorage.getItem('roles') == "ROLE_SUPER_ADMIN") return true;
        //     else return false;
        // },
        // isAdminSelected: function () {
        //     if (localStorage.getItem('roles') == "ROLE_ADMIN") return true;
        //     else return false;
        // },
        // isCounsellorSelected: function () {
        //     if (localStorage.getItem("ROLE_COUNSELLOR")) return true;
        //     else return false;
        // },
        // isSupervisorSelected: function () {
        //     if (localStorage.getItem("ROLE_SUPERVISOR")) return true;
        //     else return false;
        // },
        // isAdminSupervisorSelected: function () {
        //     if (localStorage.getItem("ROLE_ADMIN_SUPERVISOR")) return true;
        //     else return false;
        // },
        isHRSelected: function() {
            if (localStorage.getItem('roles') == "ROLE_HR") return true;
            else return false;
        },
        isUserSelected: function() {
            if (localStorage.getItem('roles') == "ROLE_USER") return true;
            else return false;
        }
    },
    components: {
        PasswordChangeAccount
    },
    validations: {
        selectedTeam: {
            required
        }
    },
    mounted() {
        if (localStorage.getItem("current_user") != null)
            this.$nextTick(() => {
                this.firstname = currentUser.firstName;
                this.lastname = currentUser.lastName;
            });

        var currentUser = JSON.parse(localStorage.getItem("current_user"));

        if (currentUser != null) {
            let roles = currentUser.roles;
            roles.forEach((e) => {
                // if (e.roleName == "ROLE_TELECALLER") this.IS_TELECALLER = true;

                // if (e.roleName == "ROLE_ADMIN") this.IS_ADMIN = true;

                // if (e.roleName == "ROLE_SUPER_ADMIN") this.IS_SUPER_ADMIN = true;

                if (e.roleName == "ROLE_HR") this.IS_HR = true;

                if (e.roleName == "ROLE_USER") this.IS_USER = true;
            });
        }
    },

    methods: {
        call: function () {
            this.$swal("Hello Vue world!!!");
        },
        adminLogin() {
            this.$v.selectedTeam.$touch();
            if (!this.$v.selectedTeam.$invalid) {
                this.$refs.selectTeamModal.hide();
                if(this.roleAssign == 'ROLE_ADMIN') {
                    localStorage.setItem("tenant-uuid", this.selectedTeam);
                    axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                    localStorage.setItem("roles", "ROLE_ADMIN");
                    this.$router.push("/settings");
                } else if(this.roleAssign == 'ROLE_TELECALLER') {
                    localStorage.setItem("tenant-uuid", this.selectedTeam);
                    axios.defaults.headers.common["tenant-uuid"] = this.selectedTeam;
                    localStorage.setItem("roles", "ROLE_TELECALLER");
                    // var currentUser = JSON.parse(localStorage.getItem("current_user"));

                    // if (currentUser != null) {
                    //     let roles = currentUser.roles;
                    //     roles.forEach((e) => {
                    //         if (e.roleName == "ROLE_SUPERVISOR") {
                    //             localStorage.setItem("ROLE_SUPERVISOR", true)
                    //             this.$router.push("user/branchBatch");
                    //             return;
                    //         } else if(e.roleName == "ROLE_ADMIN_SUPERVISOR") {
                    //             localStorage.setItem("ROLE_ADMIN_SUPERVISOR", true)
                    //             this.$router.push("user/branchBatch");
                    //             return
                    //         } else if(e.roleName == "ROLE_COUNSELLOR") {
                    //             localStorage.setItem("ROLE_COUNSELLOR", true)
                    //             this.$router.push("user/enquiry");
                    //             return;
                    //         } else if(e.roleName == "ROLE_TELECALLER") {
                    //             this.$router.push("user/calls");
                    //             return;
                    //         }
                    //     });
                    // }
                }
            }
        },
        loginas: function (role) {
            // if (role == "ROLE_SUPER_ADMIN") {
            //     localStorage.setItem("tenant-uuid", null);
            //     axios.defaults.headers.common["tenant-uuid"] = null;
            //     localStorage.setItem("roles", "ROLE_SUPER_ADMIN");
            //     this.$router.push("/super-admin/team");
            // } else if (role == "ROLE_ADMIN") {
            //     return new Promise((resolve, reject) => {
            //         Account.getTeamId(JSON.parse(localStorage.getItem('current_user')).id)
            //             .then((response) => {
            //                 if (response.data.length == 1) {
            //                     console.log("if=============");
            //                     localStorage.setItem("tenant-uuid", response.data[0].teamId);
            //                     axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
            //                     localStorage.setItem("roles", "ROLE_ADMIN");
            //                     this.$router.push("/settings");
            //                 } else {
            //                     console.log("else=============");
            //                     this.selectedTeam = null
            //                     this.$nextTick(() => {
            //                         this.$v.selectedTeam.$reset();
            //                     });
            //                     return new Promise((resolve, reject) => {
            //                         axios.post("/team/getAll")
            //                             .then((response) => {
            //                                 this.teams = response.data;
            //                                 this.$refs.selectTeamModal.show();
            //                                 this.roleAssign = role;
            //                                 resolve(response);
            //                             })
            //                             .catch((err) => {
            //                                 reject(err);
            //                             });
            //                     });

            //                     // console.log("==inputOptions===",input);
            //                 }
            //                 resolve(response);
            //             })
            //             .catch((err) => {
            //                 reject(err);
            //             });
            //     });
            // } else if (role == "ROLE_TELECALLER") {
            //     return new Promise((resolve, reject) => {
            //         Account.getTeamId(JSON.parse(localStorage.getItem('current_user')).id)
            //             .then((response) => {
            //                 if (response.data.length == 1) {
            //                     console.log("if=============");
            //                     localStorage.setItem("tenant-uuid", response.data[0].teamId);
            //                     axios.defaults.headers.common["tenant-uuid"] = response.data[0].teamId;
            //                     if (JSON.parse(localStorage.getItem('ROLE_ADMIN_SUPERVISOR'))) {
            //                         localStorage.setItem("roles", "ROLE_TELECALLER");
            //                         this.$router.push("user/branchBatch");
            //                     } else if (JSON.parse(localStorage.getItem('ROLE_SUPERVISOR'))) {
            //                         localStorage.setItem("roles", "ROLE_TELECALLER");
            //                         this.$router.push("user/branchBatch");
            //                     } else {
            //                         localStorage.setItem("roles", "ROLE_TELECALLER");
            //                         this.$router.push("user/calls");
            //                     }
            //                 } else {
            //                     console.log("else=============");
            //                     this.selectedTeam = null
            //                     this.$nextTick(() => {
            //                         this.$v.selectedTeam.$reset();
            //                     });
            //                     return new Promise((resolve, reject) => {
            //                         axios.post("/team/getAll")
            //                             .then((response) => {
            //                                 this.teams = response.data;
            //                                 this.$refs.selectTeamModal.show();
            //                                 this.roleAssign = role;
            //                                 resolve(response);
            //                             })
            //                             .catch((err) => {
            //                                 reject(err);
            //                             });
            //                     });

            //                     // console.log("==inputOptions===",input);
            //                 }
            //                 resolve(response);
            //             })
            //             .catch((err) => {
            //                 reject(err);
            //             });
            //     });
            // }
            if (role == "ROLE_USER") {
                // alert("role====",role)
                localStorage.setItem("roles", role);
                this.$router.push("/user/attendance");
            } else if(role == "ROLE_HR") {
                localStorage.setItem("roles", role);
                this.$router.push("/hr/user");
            }
            window.location.reload();
        },

        revoke: function () {
            var accessToken = localStorage.getItem("accessToken");
            Account.logout(accessToken);
            // localStorage.removeItem('accessToken')
            // localStorage.removeItem('refreshToken')
            // localStorage.removeItem('current_user')
            // localStorage.removeItem('contact')
            // localStorage.removeItem('roles');
            // localStorage.removeItem('restrict')
            // localStorage.removeItem('login')
            // localStorage.removeItem('agentId')
            localStorage.clear();
            this.$router.push({
                name: "Login",
            });
            window.location.reload();
        },
        // navto: function (nav) {

        //     if (nav == 'DASHBOARD') {

        //         this.$router.push({
        //             path: "/dashboard"
        //         })
        //     }
        //     if (nav == 'SETTINGS') {

        //         this.$router.push({
        //             path: "/settings"
        //         })
        //     }
        //     if (nav == 'CALLS') {

        //         this.$router.push({
        //             path: "/user/calls"
        //         })
        //     }
        //     if (nav == 'ADMISSION') {

        //         this.$router.push({
        //             path: "/user/admission"
        //         })
        //     }
        //     if (nav == 'ENQUIRY') {

        //         this.$router.push({
        //             path: "/user/enquiry"
        //         })
        //     }
        // }
    },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Dosis:wght@600&family=Monoton&family=Rampart+One&display=swap"); */
.logo {
    transition: transform .7s ease-in-out;
}
.logo:hover {
    transform: rotate(360deg);
}
.text-animation {
  background: 50% 100%/50% 50% no-repeat radial-gradient(ellipse at bottom, rgb(255, 255, 255), transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* font-size: 10vw; */
  font-family: "Source Sans Pro", sans-serif;
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
          animation: reveal 3000ms ease-in-out forwards 200ms, glow 2500ms linear infinite 2000ms;
}
@-webkit-keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@-webkit-keyframes glow {
  40% {
    text-shadow: 0 0 8px rgb(255, 255, 255);
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px rgb(255, 255, 255);
  }
}

.nav-sty {
    background-color: rgba(4, 142, 255, 0.87);
}

#nav2 .navbar {
    background-color: rgba(197, 197, 197, 0.664);
    height: 80%;
}

#navitem {
    border-right: 1px solid white;
}

.navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.tc {
    color: white;
    font-size: x-large;
}

.arrow-pointer {
    width: 180px;
    height: 50px;
    position: relative;
    background-color: #00aeffb9;
    /* For browsers that do not support gradients */
    background-image: linear-gradient(to right,
            rgba(4, 142, 255, 0.87),
            rgba(88, 255, 219, 0.788));
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #3094fa3d;
}

.arrow-pointer:after {
    content: "";
    position: absolute;
    left: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 25px solid rgba(4, 142, 255, 0.87);
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

#navbrand {
    margin-right: 150px;
}

/* ::v-deep .dropdown-menu-right{
   margin:-13px;
   text-align: right;

} */
::v-deep .dropdown-menu {
    min-width: 8.5rem;
    margin: -20px;
}

::v-deep .nav-link {
    padding: 1.5rem 1rem;
    color: #fff;
}
</style>
