<template>
<div>
    <!-- Loader started -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- Loader Ended -->
    <b-container>
        <!-- pop start for Team register -->
        <b-modal id="addTeam" title="Team" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Team Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Team Name" v-model.trim="$v.team.name.$model" :class="{
                  'is-invalid': $v.team.name.$error,
                  'is-valid': !$v.team.name.$invalid,
                }">
                    </b-form-input>
                    <div class="valid-feedback">Team Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.team.name.required">Team Name is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Organization:</label>
                    <b-form-select class="form-control" v-model="team.organization" :class="{'is-invalid': $v.team.organization.$error,'is-valid': !$v.team.organization.$invalid,}">
                        <option value="null" disabled>Select Organization</option>
                        <option v-for="item in allOrganization" :value="item.value" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                    <div class="valid-feedback">Team Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.team.organization.required">Organization is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Team Description:</label>
                    <b-form-input class="form-control" v-model.trim="$v.team.description.$model" :class="{
                  'is-invalid': $v.team.description.$error,
                  'is-valid': !$v.team.description.$invalid,
                }" placeholder="Enter your Description">
                    </b-form-input>
                    <div class="valid-feedback">Your Description is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.team.description.required">Description is required</span>
                    </div>
                </div>
                <br />

                <div class="text-center">
                    <b-button variant="outline-success" :disabled="disabled" @click="disabled = true,putTeam()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!-- popup end for Team Register -->
    </b-container>
    <!-- Team Table -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!--add branch button start-->
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addTeam @click="resetForm()">Add Team</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class=" searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1 ">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color:#0974ff;cursor:pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <p>Team</p>
                <hr>
                <b-table responsive hover :items="teams" :fields="fields" :current-page="currentPage" :per-page="teams.pageCount" ref="table">
                    <template v-slot:cell(teamId)="data">
                        <span class="title"> {{data.item.teamId}}</span>
                    </template>
                    <template v-slot:cell(name)="data">
                        {{data.item.name}}
                    </template>
                    <template v-slot:cell(description)="data">
                        {{data.item.description}}
                    </template>
                    <template v-slot:cell(organization)="data">
                        <span>{{ data.item.organization }}</span>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button variant="primary" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Team Details" v-b-modal.viewteam @click="getTeam(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button>
                        <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Team Details" v-b-modal.updateTeam @click="getTeam(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button>
                        <b-button variant="danger" size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'danger' }" title="Delete Team" @click="deleteTeam(data.item.teamId)">
                            <font-awesome-icon icon="trash" />
                        </b-button>
                        <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Team Details" v-if="data.item.archive==false" variant="success" @click="archive(data.item.teamId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Unarchive Team Details" v-if="data.item.archive==true" variant="warning" @click="unarchive(data.item.teamId)">
                            <font-awesome-icon icon="archive" />
                        </b-button> -->
                    </template>

                </b-table>
            </b-card>
        </b-container>
    </div>
    <!-- Team Table End -->
    <!-- View Team Start -->
    <b-container>
        <b-modal id="viewteam" title="View team" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Team Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Team Name" v-model="team.name" disabled>
                    </b-form-input>

                </div>
                <br />
                <div class="form-group">
                    <label>Team Description:</label>
                    <b-form-input class="form-control" v-model="team.description" disabled placeholder="Enter your Description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Organization:</label>
                    <b-form-input class="form-control" v-model="team.organization" disabled placeholder="please update an organization">
                    </b-form-input>
                </div>
            </div>

        </b-modal>
    </b-container>
    <!-- View Team End -->
    <!-- Update Team Start -->
    <b-container>
        <b-modal id="updateTeam" title="Update Team" ref="modal" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Name:</label>
                    <b-form-input class="form-control" v-model.trim="$v.team.name.$model" :class="{
                  'is-invalid': $v.team.name.$error,
                  'is-valid': !$v.team.name.$invalid,
                }">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Organization:</label>
                    <b-form-select class="form-control" v-model="team.organization" :class="{'is-invalid': $v.team.organization.$error,'is-valid': !$v.team.organization.$invalid,}">
                        <option value="null" disabled>Select Organization</option>
                        <option v-for="item in allOrganization" :value="item.value" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                    <div class="valid-feedback">Team Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.team.organization.required">Organization is required</span>
                    </div>
                </div>
                <br />
                <div class="form-group">
                    <label>Description:</label>
                    <b-form-input class="form-control" v-model.trim="$v.team.description.$model" :class="{
                  'is-invalid': $v.team.description.$error,
                  'is-valid': !$v.team.description.$invalid,
                }">
                    </b-form-input>
                </div>
                <br />
                <div class="text-center pt-3">
                    <b-button size="sm" id="submit" variant="outline-danger" @click="updateTeam()">Update</b-button><br />
                </div>
            </div>
        </b-modal>
        <b-modal ref="loaderModal" hide-footer>
            <div class="col-sm-6 text-center">
                <p>loader 4</p>
                <div class="loader4"></div>
            </div>
        </b-modal>
    </b-container>
    <!-- Update Team End -->
</div>
</template>

<script>
import {
    required,
    minLength,

} from "vuelidate/lib/validators";
import TeamService from "../service/TeamService";
import Account from '../service/Account.js';
import axios from 'axios';
import AgentService from '../service/AgentService';
import Swal from 'sweetalert2';

export default {
    name: "SuperAdminTeam",
    components: {

    },
    data() {
        return {
            paginationTeams: false,
            searchPaginationTeams: false,
            //  Loader
            loading: false,
            // Pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            keyword: "",
            //  team  data render in table
            team: {
                name: "",
                description: "",
                organization: null
            },
            teams: [],
            fields: [
                // { key:'teamId'},
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'organization',
                    label: 'Organization'
                },
                {
                    key: 'actions',
                    label: 'Actions'
                },
            ],
            teamObject: null,
            adminUsers: [],
            loadingStatus: "",
            disabled: false,
            allOrganization: [
                {
                    name:'Examsdaily',
                    value:'examsdaily'
                },
                {
                    name:'Dexter Academy',
                    value:'dexter_academy'
                },
                {
                    name:'EdexTech',
                    value:'edextech'
                }
            ]
        };
    },
    validations: {
        team: {
            name: {
                required,
                minLength: minLength(3),
            },
            description: {
                required,
                minLength: minLength(5),

            },
            organization: {
                required
            }
        },

    },
    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem('refreshToken'))
                .then((response) => {
                    self.getCount();
                    this.loading = true;
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
            }
        }
    },

    methods: {

        // insert method
        showLoading(loadingStatus) {
            Swal.fire({
                title: loadingStatus,
                onBeforeOpen() {
                    Swal.showLoading()
                },
                onAfterClose() {
                    Swal.hideLoading()
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            })
        },
        putTeam: function () {
            this.disabled = true;
            // console.log("Call Team :",this.team)
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitstatus = "FAIL";
                this.disabled = false;
            } else {
                this.showLoading("Team Create");
                this.submitstatus = "SUCCESS";
                let team = {
                    name: this.team.name.replace(/\s/g, ''),
                    description: this.team.description,
                    organization: this.team.organization,
                }
                return new Promise((resolve, reject) => {
                    TeamService.insertTeam(team)
                        .then((response) => {
                            this.$refs.modal.hide();
                            this.teamObject = response.data;
                            if (this.teamObject != null) {
                                this.teamAdminCreate(this.teamObject)
                            } else {
                                console.log("error show=====");
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            if (err.response.status == 409) {
                                // console.log("if=========");
                                this.$swal({
                                    title: "The team name already exist!",
                                    icon: "error",
                                    button: "ok",
                                });
                            }
                            reject(err);
                        });
                });
            }
        },
        teamAdminCreate: function (team) {
            this.showLoading("Team Admin Create");
            return new Promise((resolve, reject) => {
                Account.teamAdminCreate(team)
                    .then((response) => {
                        this.adminUsers = response.data;
                        if (this.adminUsers != []) {
                            this.adminUsers.forEach(e => {
                                axios.defaults.headers.common["tenant-uuid"] = team.teamId;
                                this.teamAdminAgentCreate(e);
                            });
                        } else {
                            console.log("teamAdminCreate error show");
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        teamAdminAgentCreate: function (adminUser) {
            this.showLoading("Team Admin Agent Create");
            let agent = {
                userId: adminUser.userId,
                firstName: adminUser.firstName,
                lastName: adminUser.lastName,
                name: adminUser.username,
                contact: adminUser.username,
                roleCounsellor: false,
                roleSupervisor: false,
                roleAdminSupervisor: false,
                branch: null,
                branches: [],
            }
            console.log("====teamAdminAgentCreate===", adminUser);
            return new Promise((resolve, reject) => {
                AgentService.teamAgentCreate(agent)
                    .then((response) => {
                        if (adminUser.registrationMode == "SUPER_ADMIN") {
                            Swal.close();
                            this.$swal("Team and Admin created!", "Admin username and password is " + adminUser.username);
                            this.getCount();
                            this.getUserInfo();
                            this.disabled = false;
                        }
                        axios.defaults.headers.common["tenant-uuid"] = null;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUserInfo() {
            return new Promise((resolve, reject) => {
                Account.getUserInfo(JSON.parse(localStorage.getItem('current_user')).username)
                    .then((response) => {
                        localStorage.setItem("current_user", JSON.stringify(response.data));
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteTeam: function (teamId) {
            return new Promise((resolve, reject) => {
                this.$swal({
                        title: "Are you sure?",
                        text: "You want to delete this team!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                    })
                    .then((result) => {
                        if (result.value) {
                            TeamService.deleteTeam(teamId)
                                .then((response) => {
                                    this.teamUserDelete(teamId);
                                    resolve(response);
                                });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        teamUserDelete: function (teamId) {
            return new Promise((resolve, reject) => {
                Account.teamUserDelete(teamId)
                    .then((response) => {
                        this.$swal("Deleted!", "Teams and users has been deleted.", "success");
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getTeam: function (team) {
            // console.log("Team : ", team)
            this.team = team;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        updateTeam: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitstatus = "FAIL";
            } else {
                this.submitstatus = "SUCCESS";
                console.log("updateTeam=========== ",this.team);
                return new Promise((resolve, reject) => {
                    TeamService.updateTeam(this.team)
                        .then((response) => {
                            this.$swal('Team updated successfully!!!!!');
                            this.$refs.modal.hide();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        // archive: function (id) {

        //     return new Promise((resolve, reject) => {
        //         TeamService.archive(id)
        //             .then(response => {
        //                 this.$swal('Archive successfully!!!!!');
        //                 this.getCount()
        //                 //  console.log(response.data);
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        // unarchive: function (id) {

        //     return new Promise((resolve, reject) => {
        //         TeamService.unarchive(id)
        //             .then(response => {
        //                 this.$swal('UnArchive successfully!!!!!');
        //                 this.getCount()
        //                 //  console.log(response.data);
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        getCount: function () {
            this.loading = true;
            this.paginationTeams = true;
            this.searchPaginationTeams = false;
            return new Promise((resolve, reject) => {
                TeamService.getCount()
                    .then((response) => {
                        //   console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function () {
            // console.log("currentPage",this.currentPage)
            // console.log("pageCount",this.pageCount)
            return new Promise((resolve, reject) => {
                TeamService.getAllPageable(this.currentPage, this.pageCount)
                    .then((response) => {
                        this.teams = response.data;
                        // console.log("Teams",response.data)
                        resolve(response);
                        this.loading = false
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            // this.loading = true
            // this.getAllPageable(pageCount);
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationTeams) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.searchPaginationTeams) {
                this.getAllSearchPageable(currentPage, pageCount, this.keyword);
            }
        },

        getSearchCount: function (keyword) {
            this.paginationTeams = false;
            this.searchPaginationTeams = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                TeamService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        //   console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                TeamService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.teams = response.data;
                        console.log("Teams", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        refresh() {

            this.getCount();
            this.keyword = "";
            this.currentPage = 1;

        },
        resetForm() {
            this.team = {
                name: "",
                description: "",
                organization: null
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
};
</script>

<style scoped>
.loader4 {
    width: 45px;
    height: 45px;
    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 5px solid;
    border-top-color: rgba(246, 36, 89, 1);
    border-bottom-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(246, 36, 89, 1);
    border-right-color: rgba(255, 255, 255, 0.3);
    -webkit-animation: loader4 1s ease-in-out infinite;
    animation: loader4 1s ease-in-out infinite;
}

@keyframes loader4 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader4 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09A5FF;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09A5FF;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09A5FF;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09A5FF;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
