<template>
<div>
    <h1>User Attendance</h1>
    <b-tabs pills card content-class="mt-3" justified>
        <b-tab title="January" @click="getDetail('01')" :active="selectedTab === 'jan'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="February" @click="getDetail('02')" :active="selectedTab === 'feb'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="March" @click="getDetail('03')" :active="selectedTab === 'mar'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="April" @click="getDetail('04')" :active="selectedTab === 'apr'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="May" @click="getDetail('05')" :active="selectedTab === 'may'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="June" @click="getDetail('06')" :active="selectedTab === 'jun'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="July" @click="getDetail('07')" :active="selectedTab === 'jul'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="August" @click="getDetail('08')" :active="selectedTab === 'aug'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="September" @click="getDetail('09')" :active="selectedTab === 'sep'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="October" @click="getDetail('10')" :active="selectedTab === 'oct'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="November" @click="getDetail('11')" :active="selectedTab === 'nov'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
        <b-tab title="December" @click="getDetail('12')" :active="selectedTab === 'dec'">
            <!-- <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
            </b-table> -->
        </b-tab>
    </b-tabs>
    <b-container>
        <div>
            <b-container>
                <b-card class="card mt-1">
                    <b-row align-v="center">
                        <!-- <b-col sm="12" md="3" lg="6" class="my-1" id="r1">
                            <b-pagination v-model="currentPage" :total-rows="totalAttendanceCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="
                        switchAttendancePage(currentPage, pageCount)
                      "></b-pagination>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" class="" id="r1">
                            <label class="font mx-1">Per Page</label>
                            <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="
                        switchAttendancePage(currentPage, pageCount)
                      " :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" class="" id="r1">
                            <label class="mx-2">Total Count:</label>
                            <b-button variant="danger">{{
                      totalAttendanceCount
                    }}</b-button>
                        </b-col> -->

                        <!-- <b-col sm="6" md="3" lg="2" class="" id="r1"> -->
                        <!-- <b-form-datepicker v-model="selectedDate" class="mb-2" @input="getAttendanceCountByDate()"></b-form-datepicker> -->
                        <!-- <p>Value: '{{ attendanceDate(selectedDate, "fullDate") }}'</p> -->
                        <!-- <p>{{ selectedDate }}</p> -->
                        <!-- </b-col> -->

                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button v-if="hideData!=true" variant="success" @click="showFullDetails()">ShowDetails</b-button>
                            <b-button v-if="hideData==true" variant="danger" @click="hideFullDetails()">HideDetails</b-button>
                        </b-col>
                        <b-col>
                            <b-button variant="primary" @click="exportDetails()">Export Data</b-button>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-table hover responsive :items="employeeAttendance" :fields="fields" :per-page="employeeAttendance.pageCount" :current-page="currentPage" ref="table">
                        <template v-slot:cell(createdAt)="data">
                            <div class="cellSize">
                                <!-- {{ attendanceDate(data.item.createdAt, "fullDate") }} -->
                                <b>{{ data.item.createdAt.slice(8, 10) }} {{
                  getDay(data.item.createdAt)
                }}</b>
                            </div>
                        </template>
                        <!-- ===========checkin============-->
                        <template v-slot:cell(checkIn)="data">
                            <div :class="inTimeValid(data.item.checkIn) ? '' : 'invalid-time'">{{ showInTime(data.item.checkIn) }}</div>
                            <div v-if="hideData"><b>
                                    <b-icon icon="geo-alt" variant="danger"></b-icon>
                                </b> : {{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                            <div v-if="hideData"><b>
                                    <b-icon icon="clock" variant="danger"></b-icon>
                                </b> : {{ data.item.checkinTimeDif }}&nbsp;hrs</div>
                        </template>
                        <!--- ==============checkOut===========-->
                        <template v-slot:cell(checkOut)="data">
                            <div :class="outTimeValid(data.item.checkOut) ? '' : 'invalid-time'">{{ showInTime(data.item.checkOut) }}</div>
                            <div v-if="hideData"><b>
                                    <b-icon icon="geo-alt" variant="danger"></b-icon>
                                </b> : {{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                            <div v-if="hideData"><b>
                                    <b-icon icon="clock" variant="danger"></b-icon>
                                </b> : {{ data.item.checkoutTimeDif }}&nbsp;hrs</div>
                        </template>
                        <!---============status==============-->
                        <template v-slot:cell(status)="data">
                            <div v-if="getDay(data.item.createdAt) == 'Sun'" class="text-black text-center bg-warning"><b>Week End</b></div>
                            <div v-else-if="
                data.item.checkIn == null && data.item.checkOut == null
              " style="color: red">ABSENT</div>
                            <div v-else style="color: green"><b>PRESENT</b></div>
                        </template>

                        <template v-slot:cell(checkinDistanceDif)="data">
                            <!-- {{ data.item.checkinDistanceDif }}&nbsp;mtrs -->
                            <div>{{ formatDistance(data.item.checkinDistanceDif) }} mtrs</div>
                        </template>

                        <template v-slot:cell(checkoutDistanceDif)="data">
                            <!-- {{ data.item.checkoutDistanceDif }}&nbsp;mtrs -->
                            <div>{{ formatDistance(data.item.checkoutDistanceDif) }} mtrs</div>
                        </template>

                        <template v-slot:cell(checkinTimeDif)="data">
                            {{ data.item.checkinTimeDif }}&nbsp;hrs
                        </template>

                        <template v-slot:cell(checkoutTimeDif)="data">
                            {{ data.item.checkoutTimeDif }}&nbsp;hrs
                        </template>

                        <!-- <template v-slot:cell(distance)="data">
                            <div>{{ distanceChecking(data.item.latitude, data.item.longtitude) }} mtrs</div>
                        </template> -->

                        <!-- <template v-slot:cell(distanceDifftence)="data">
                            {{ data.item.distanceDifftence }}
                        </template> -->

                        <!-- <template v-slot:cell(checkIn)="data">
                            <div class="cellSize" v-if="data.item.checkIn === ' 10:00 AM'"><span>{{ data.item.checkIn }}</span></div>
                            <div v-else>
                                <span :class="!inTimeValid(data.item.checkIn) ? ' ' : 'invalid-time'">{{ data.item.checkIn }}</span>
                            </div>
                        </template>
                        <template v-slot:cell(checkOut)="data">
                            <div :class="!outTimeValid(data.item.checkOut) ? '' : 'invalid-time'">{{ data.item.checkOut }}</div>
                        </template>
                        <template v-slot:cell(status)="data">
                            <div v-if="getDay(data.item.createdAt) == 'Sun'" class="text-black text-center bg-warning"><b>Week End</b></div>
                            <div v-else-if="
                data.item.checkIn == null && data.item.checkOut == null
              " style="color: red">ABSENT</div>
                            <div v-else style="color: green"><b>PRESENT</b></div>
                        </template> -->
                        <!-- <template v-slot:cell(status)="data">
                            <b :class="getTextColorClass(data.item.status)">{{ data.item.status }}</b>
                        </template> -->
                    </b-table>
                </b-card>
            </b-container>
        </div>
    </b-container>
</div>
</template>

<script>
import Account from '../service/Account.js';
import moment from "moment-timezone";
import AttendanceEmployeeService from "../service/AttendanceEmployeeService"
import OfficeService from "../service/OfficeService"
export default {
    name: "EmployeeAttendance",
    components: {},
    data() {
        return {            
            resourceLocation: "https://api-encore.examsdaily.in/uploads/reports/",
            // resourceLocation: "http://localhost:8088/uploads/reports/",
            hideData: false,
            officeLatitude: "",
            officeLongitude: "",
            employeeOfficeLatitude: "",
            employeeOfficeLongitude: "",
            employeeLatitude: "",
            employeeLongitude: "",
            distanceDifftence: [],
            cuerrentMonth:"",
            officeId: "",
            data: "",
            selectedTab: "",
            totalAttendanceCount: 0,
            // for EmployeeAttendance User pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],

            employeeAttendance: [],
            fields: [{
                    key: "createdAt",
                    label: "Date",
                    tdClass: "cellSize",
                },
                {
                    key: "checkIn",
                    label: "CheckIn",
                },
                {
                    key: "checkOut",
                    label: "CheckOut",
                },
                // {
                //     key: "checkinLat",
                //     label: "Latitude",
                // },
                // {
                //     key: "checkinLong",
                //     label: "Longitude",
                // },
                // {
                //     key: "checkinDistanceDif",
                //     label: "CheckinDistance",
                // },
                // {
                //     key: "checkoutDistanceDif",
                //     label: "CheckOutDistance",
                // },
                // {
                //     key: "checkinTimeDif",
                //     label: "CheckinTimeDif",
                // },
                // {
                //     key: "checkoutTimeDif",
                //     label: "CheckoutTimeDif",
                // },
                {
                    key: "status",
                    label: "Status",
                },
            ],

        };
    },

    computed: {

    },

    // created() {
    //     this.username = this.$route.params.data;
    //     this.showCurrentMonthDetails();
    // },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.username = this.$route.params.data;
                    this.officeId = localStorage.getItem("officeId");
                    // console.log("=====OfficeId======", this.officeId);
                    this.getOfficeDetails();
                    this.showCurrentMonthDetails();
                    // this.getOfficeDetails();
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },

    watch: {

    },

    methods: {

        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getDetail(month) {
            this.cuerrentMonth = month;
            // console.log("=====Current Month=====",this.cuerrentMonth);
            this.getAttendanceDetails(this.username, month);
        },

        attendanceDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D-MM-YYYY hh:mm a");
            }
            if (dateType === "fullTime") {
                return moment(date1).format("hh:mm a");
            }
        },

        // getTextColorClass(value) {
        //     // console.log("=====Value=====",value);
        //     // Define your conditions and corresponding CSS classes here
        //     if (value === 'Absent') {
        //         return 'red-text';
        //     } else if (value === 'Present') {
        //         return 'green-text';
        //     } else {
        //         return 'default-text';
        //     }
        // },

        // distanceChecking(lat, long) {
        //     // console.log('_____________lat' + lat + long + this.officeLatitude + this.officeLongitude);
        //     const lat1 = parseFloat(lat);
        //     const lon1 = parseFloat(long);
        //     const lat2 = parseFloat(this.officeLatitude);
        //     const lon2 = parseFloat(this.officeLongitude);

        //     const R = 6371; // Radius of the Earth in kilometers

        //     const dLat = this.deg2rad(lat2 - lat1);
        //     const dLon = this.deg2rad(lon2 - lon1);

        //     const a =
        //         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        //         Math.cos(this.deg2rad(lat1)) *
        //         Math.cos(this.deg2rad(lat2)) *
        //         Math.sin(dLon / 2) *
        //         Math.sin(dLon / 2);
        //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        //     const distance = R * c;

        //     const value = distance;
        //     const roundedValue = value.toFixed(2); // Round to 2 decimal places

        //     // console.log(roundedValue); // Output: 10.95

        //     // console.log("_____________Differnece" + roundedValue);
        //     return roundedValue;
        // },

        formatDistance(value) {

            let roundedValue = Math.round(value * 100) / 100;

            // console.log(roundedValue);

            return roundedValue;
        },

        getAttendanceDetails(name, month) {
            // console.log("=====Name=====", name);
            // console.log("=====Month=====", month);
            this.data = {
                username: name,
                month: month
            }
            AttendanceEmployeeService.AttendanceDetails(this.data)
                .then((res) => {
                    this.employeeAttendance = res.data;
                    this.totalAttendanceCount = this.employeeAttendance.length
                    // console.log("=====this.employeeAttendance=====", this.employeeAttendance);

                    for (var i = 0; i < this.employeeAttendance.length; i++) {
                        this.employeeLatitude = this.employeeAttendance[i].latitude;
                        this.employeeLongitude = this.employeeAttendance[i].longtitude;
                        this.employeeOfficeLatitude = this.officeLatitude
                        this.employeeOfficeLongitude = this.officeLongitude
                        // console.log("=====this.employeeOfficeLatitude=====", this.employeeOfficeLatitude);
                        // console.log("=====this.employeeOfficeLongitude=====", this.employeeOfficeLongitude);
                        // this.officeLatitude = this.officeLatitude
                        // this.officeLongitude = this.officeLongitude
                        // console.log("=====this.employeeLatitude=====", this.employeeLatitude);
                        // console.log("=====this.employeeLongitude=====", this.employeeLongitude);
                        // this.calculateDistance(this.employeeAttendance[i].latitude, this.employeeAttendance[i].longtitude, this.employeeOfficeLatitude, this.employeeOfficeLongitude);
                    }

                })
                .catch((err) => {
                    console.log("Error Occured", err);
                });
        },

        // calculateDistance(empLat, empLon, empOfLat, empOfLon) {
        //     const lat1 = parseFloat(empLat);
        //     const lon1 = parseFloat(empLon);
        //     const lat2 = parseFloat(empOfLat);
        //     const lon2 = parseFloat(empOfLon);

        //     const R = 6371; // Radius of the Earth in kilometers

        //     const dLat = this.deg2rad(lat2 - lat1);
        //     const dLon = this.deg2rad(lon2 - lon1);

        //     const a =
        //         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        //         Math.cos(this.deg2rad(lat1)) *
        //         Math.cos(this.deg2rad(lat2)) *
        //         Math.sin(dLon / 2) *
        //         Math.sin(dLon / 2);
        //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        //     const distance = R * c;

        //     this.distance = distance;
        //     // for (var i = 0; i < this.employeeAttendance.length; i++) {
        //     // //     this.employeeAttendance[i].push({
        //     // //     distance:this.distance
        //     // // })
        //     // console.log("======distance======",this.employeeAttendance[i].push(this.distance));
        //     // }
        //     // // this.employeeAttendance.push({
        //     // //     distance:this.distance
        //     // // })
        //     this.distanceDifftence.push(this.distance)
        //     // console.log("=====Distance=====",this.distance);
        //     console.log("=====DistanceDifftence=====", this.distanceDifftence);

        //     // },
        //     // deg2rad(deg) {
        //     //   return deg * (Math.PI / 180);
        //     // }

        //     // console.log("=====this.employeeOfficeLatitude=====",empLat);
        //     // console.log("=====this.employeeOfficeLongitude=====",empLon);
        //     // console.log("=====this.employeeLatitude=====",empOfLat);
        //     // console.log("=====this.employeeLongitude=====",empOfLon);

        //     // console.log("=====lat1=====", lat1);
        //     // console.log("=====lon1=====", lon1);
        //     // console.log("=====lat2=====", lat2);
        //     // console.log("=====lon2=====", lon2);
        // },

        // deg2rad(deg) {
        //     return deg * (Math.PI / .180);
        // },
        showCurrentMonthDetails() {
            const date = new Date();
            const month = date.getMonth();
            const monthparam = (date.getMonth() + 1).toString().padStart(2, "0");
            this.getDetail(monthparam);
            // this.cuerrentMonth = monthparam;
            // console.log("=====Current Month=====",this.cuerrentMonth);
            switch (month) {
                case 0:
                    return (this.selectedTab = "jan");
                case 1:
                    return (this.selectedTab = "feb");
                case 2:
                    return (this.selectedTab = "mar");
                case 3:
                    return (this.selectedTab = "apr");
                case 4:
                    return (this.selectedTab = "may");
                case 5:
                    return (this.selectedTab = "jun");
                case 6:
                    return (this.selectedTab = "jul");
                case 7:
                    return (this.selectedTab = "aug");
                case 8:
                    return (this.selectedTab = "sep");
                case 9:
                    return (this.selectedTab = "oct");
                case 10:
                    return (this.selectedTab = "nov");
                case 11:
                    return (this.selectedTab = "dec");
                default:
                    break;
            }
        },

        // inTimeValid(time) {
        //     if (time != null) {
        //         const timeParts = time.split(/[:\s]/);
        //         const hour = parseInt(timeParts[0]);
        //         const minute = parseInt(timeParts[1]);
        //         if (timeParts[2] != "PM") {
        //             console.log(hour > 9 || (hour === 9 && minute >= 0));
        //             return hour > 10 || (hour === 10 && minute >= 0);
        //         } else {
        //             return true;
        //         }
        //     }
        // },
        // outTimeValid(time) {
        //     if (time != null) {
        //         const timeParts = time.split(/[:\s]/);
        //         const hour = parseInt(timeParts[0]);
        //         return hour < 7;
        //     }
        // },

        getOfficeDetails() {
            OfficeService.OfficeDetail(this.officeId)
                .then((res) => {
                    // console.log("before asign", this.selectedRow);
                    // console.log("=====Response Data=====", res.data);
                    this.officeLatitude = res.data.latitude;
                    this.officeLongitude = res.data.longitude;
                    // console.log("=====officeLatitude=====", this.officeLatitude);
                    // console.log("=====officeLongitude=====", this.officeLongitude);
                    // this.selectedRow.branch = res.data.branch;
                    // this.selectedRow = res.data;
                    // console.log("after asign", this.selectedRow);
                    // const values = Object.values(this.selectedRow);
                    // const values = Object.values(this.selectedRow.officeId);
                    // this.qrCodeData = values.join('');
                })
                .catch((err) => {
                    console.log("Error Occured", err);
                });
        },

        showInTime(time) {
            // console.log("__________________________________" + time);
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const timeFormat = timeObj.format("h:mm A");
                return timeFormat;
            } else {
                return "N/A";
            }
        },
        inTimeValid(time) {
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const hour = timeObj.format("H");
                const minute = timeObj.format("m");
                if (hour <= 10) {
                    return hour < 10 || (hour == 9 && minute <= 0);
                } else {
                    return false;
                }
            }
            // console.log("_______________IN______________" + this.showInTime);
        },
        outTimeValid(time) {
            if (time != null) {
                const timeObj = moment(time, "HH:mm:ss");
                const hour = timeObj.format("H");
                // console.log("++++++++++M++++" + hour);
                if (hour < 19) {
                    return false;
                } else {
                    return true;
                }
            }
            // console.log("_______________Out______________" + this.showOutTime);
        },

        getDay(dateString) {
            const dateParts = dateString.split(" ")[0].split("-");
            const year = parseInt(dateParts[0], 10);
            const month = parseInt(dateParts[1], 10) - 1;
            const day = parseInt(dateParts[2], 10);
            const date = new Date(year, month, day);
            const dayOfWeek = date.getDay();
            switch (dayOfWeek) {
                case 0:
                    return "Sun";
                case 1:
                    return "Mon";
                case 2:
                    return "Tue";
                case 3:
                    return "Wed";
                case 4:
                    return "Thu";
                case 5:
                    return "Fri";
                case 6:
                    return "Sat";
                default:
                    break;
            }
        },

        showFullDetails() {
            this.hideData = true;
        },
        hideFullDetails() {
            this.hideData = false;
        },
        exportDetails: function () {
            // console.log("=====Export Excel Method Calling=====");
            // console.log("=====Name=====", this.username);
            // console.log("=====Month=====", this.cuerrentMonth);
            this.data = {
                username: this.username,
                month: this.cuerrentMonth
            }
            AttendanceEmployeeService.AttendanceExportDetails(this.data)
                .then((response) => {
                    // console.log("=====AttendanceExportDetails=====",response.data);
                    this.download(response.data);
                    this.loading = false;

                })
                .catch((err) => {
                    console.log("Error Occured", err);
                });

        },

        download(fileName) {
            window.open(this.resourceLocation + fileName);
        },


    },

}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 120px;
}

.red-text {
    color: red;
}

.green-text {
    color: green;
}

.default-text {
    color: black;
}

.invalid-time {
    color: red;
}
</style>
