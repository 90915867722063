<template>
<div>
    <div style="margin: auto;width: 90%;">
        <b-card class="box mt-5" border-variant="primary" header="Syllabus" header-bg-variant="primary" header-text-variant="white" align="center">
            <div>
                <div>
                    <label>Syllabus Name:</label>
                    <!-- <b-form-input class="form-control" placeholder="Enter your Syllabus Name" v-model.trim="syllabus.name">
            </b-form-input> -->
                    <b-form-input class="inputBox" v-model.trim="syllabus.name" :class="{
                  'is-invalid': $v.syllabus.name.$error,
                  'is-valid': !$v.syllabus.name.$invalid,
                }" placeholder="Enter your Syllabus Name" />
                    <div class="valid-feedback">Your Syllabus Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.syllabus.name.required">Syllabus Name is required</span>
                    </div>
                </div>
                <br />
                <div>
                    <label>Syllabus Code:</label>
                    <!-- <b-form-input class="form-control" v-model.trim="syllabus.code" placeholder="Enter your Code">
            </b-form-input> -->
                    <b-form-input class="inputBox" v-model.trim="syllabus.code" :class="{
                  'is-invalid': $v.syllabus.code.$error,
                  'is-valid': !$v.syllabus.code.$invalid,
                }" placeholder="Enter your Syllabus Code" />
                    <div class="valid-feedback">Your Syllabus Code is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.syllabus.code.required">Syllabus Code is required</span>
                    </div>

                </div>
                <b-row class="text-center mt-4 mb-2" align-v="center" v-if="(!importContent && !createContent) && (syllabus.content.childrens.length == 0)">
                    <b-col align="right">
                        <b-button variant="light" v-if="importContent" class="import-btn" style="background-color: #1091c9;color: #fff;">Import</b-button>
                        <b-button variant="light" v-else @click="openImportContent" class="import-btn" style="background-color: #fff;color: #1091c9;">Import</b-button>
                    </b-col>
                    <b-col cols="1"> OR </b-col>
                    <b-col align="left">
                        <b-button variant="light" v-if="createContent" class="import-btn" style="background-color: #fff;color: #1091c9;">Create</b-button>
                        <b-button variant="light" v-else @click="openCreateContent" class="import-btn" style="background-color: #fff;color: #1091c9;">Create</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <br />
    </div>
    <div title="Edit Syllabus Content" icon="fa fa-th-list">
        <!-- <b-row class="text-center mb-3" align-v="center" v-if="(!importContent ) && (syllabus.content.childrens.length == 0)">
            <b-col align="right">
                <b-button variant="light" v-if="importContent" class="import-btn" style="background-color: #1091c9;color: #fff;">Import</b-button>
                <b-button variant="light" v-else @click="openImportContent" class="import-btn" style="background-color: #fff;color: #1091c9;">Import</b-button>
            </b-col>
            <b-col cols="1"> OR </b-col>
            <b-col align="left">
                <b-button variant="light" class="import-btn" style="background-color: #fff;color: #1091c9;">Create</b-button>
            </b-col>
        </b-row> -->

        <!-- <div class="valid-feedback">Your ContentFile is Selected!</div>
                <div class="invalid-feedback">
                  <span v-if="!$v.syllabus.contentFile.required">ContentFile is required</span>
                </div> -->
        <b-card v-if=" syllabus.contentFile == null && importContent" class="shadow border-0 mx-auto" style="background:#BFDCFA;border:2px solid grey;border-radius:25px;width:70%;margin-bottom:2em;">
            <b-row style="padding-bottom:1.5em;">
                <b-col cols="4">
                    <span class="text-info" style="font-weight:bold;font-size:1.2em;">Syllabus Content<span style="color:red;">*</span></span>
                    <span class="pull-right text-info" style="font-weight:bold;font-size:1.2em;">:</span>
                </b-col>
                <b-col>
                    <b-form-file v-model="syllabus.contentFile" :state="Boolean(syllabus.contentFile)" placeholder="Choose a file or drop it here to add syllabus content..." drop-placeholder="Drop file here..." accept=".txt, .csv" @input="syllabusContentFileSelected(syllabus.contentFile)"></b-form-file>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-button class="shadow" variant="danger" @click="closeImportContent()">Cancel</b-button>
            </b-row>
        </b-card>
        <!-- {{syllabus.content}} -->
        <!-- =================================================================================== -->
        <b-card v-if="syllabus.content!=null && syllabus.content.childrens.length != 0" class="shadow border-0 mx-auto" style="background:#d0dcf5;border:2px solid grey;border-radius:25px;width:90%;margin-bottom:2em;">
            <div role="tablist">
                <b-card no-body class="mb-1" v-for="(l1, l1index) in syllabus.content.childrens" v-bind:key="l1index">
                    <b-card-header header-tag="header" style="cursor:pointer;border-bottom:1px solid #d0dcf5" role="tab" v-if="l1index != syllabus.content.childrens.length" v-b-toggle="'accordion-1'+l1index">
                        <b-row class="card-header" style="padding:5px;">
                            <b-col class="d-flex justify-content-start ml-4">
                                <strong style="color:grey;">{{l1.word}}</strong>
                            </b-col>
                            <b-col class="d-flex justify-content-end ">
                                <!-- <font-awesome-icon v-if="!batchAdded" icon="edit" class="ml-4 mr-4 mt-1" @click="editSyllabusContent1(l1,l1index)" style="font-size:1.2em;cursor:pointer;color:blue;margin-right:3%" />
                                <font-awesome-icon v-if="!batchAdded" icon="trash" class="ml-4 mr-4 mt-1" @click="removeSyllabusContent(l1index)" style="font-size:1.2em;cursor:pointer;color:red;" /> -->
                                <font-awesome-icon icon="edit" class="ml-4 mr-4 mt-1" @click="editSyllabusContent1(l1,l1index)" style="font-size:1.2em;cursor:pointer;color:blue;margin-right:3%" />
                                <font-awesome-icon icon="trash" class="ml-4 mr-4 mt-1" @click="removeSyllabusContent(l1index)" style="font-size:1.2em;cursor:pointer;color:red;" />
                                
                                <!-- <i class="fa fa-pencil ml-4 mr-4 mt-1" aria-hidden="true" @click="editSyllabusContent(l1,l1index)" style="font-size:1.2em;cursor:pointer;color:blue;"></i>
                                <i class="fa fa-trash ml-4 mr-4 mt-1" aria-hidden="true" @click="removeCourseContent(l1index)" style="font-size:1.2em;cursor:pointer;color:red;"></i> -->
                            </b-col>
                        </b-row>

                    </b-card-header>
                    <b-collapse :id="'accordion-1'+l1index" visible role="tabpanel">
                        <b-card-body>
                            <div class="table-responsive">
                                <table class="table table-hover table-bordered table-space">
                                    <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index">
                                        <td>
                                            <span v-if="l2.video" style="color:#00BFFF;">
                                                <font-awesome-icon icon="video" /></span>
                                            <span v-if="l2.resource" style="color:#00FF7F;">
                                                <font-awesome-icon icon="file" /></span>
                                            <span v-if="l2.quiz" style="color:#DC143C;">
                                                <font-awesome-icon icon="edit" /></span>
                                        </td>
                                        <!-- <td>{{l2.word}}</td> -->
                                        <td>
                                            <span v-if="l2.archived==null || (l2.archived!=null && l2.archived=='false')">{{l2.word}}</span>
                                            <span v-else style="color:red;" v-b-tooltip.hover title="Archived">{{l2.word}}</span>
                                            <b-row v-if="l2.liveVideo" class="ml-0"><span style="font-size: 14px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l2.releaseOn}}</span></span></b-row>
                                        </td>
                                        <td>
                                            <span v-if="l2.quiz">
                                                <span v-if="l2.quizDetails != null">{{l2.quizDetails.testName}}</span>
                                                <span v-if="l2.liveVideo && l2.liveVideoState=='expired' && (l2.quizDetails==undefined || (l2.quizDetails!=undefined && l2.quizDetails.testName==undefined))">
                                                    <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                </span>
                                            </span>
                                            <span v-else-if="l2.video">
                                                <span v-if="l2.videoNode != null && l2.videoNode.videoTitle != null">{{l2.videoNode.videoTitle}}</span>
                                                <span v-if="l2.liveVideo && l2.liveVideoState=='expired' && (l2.videoNode==undefined || (l2.videoNode!=undefined && l2.videoNode.videoTitle==undefined))">
                                                    <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                </span>
                                            </span>
                                            <span v-else-if="l2.resource">
                                                <!-- <span v-if="l2.resourceUrl != null">{{l2.resourceUrl}}</span> -->
                                                <span v-if="l2.resourceUrl != null">File uploaded.</span>
                                                <!-- <span v-if="l2.resourceUrl == null && !l2.liveVideo" style="color:red;font-size:0.8em">Please Upload File!!</span> -->
                                                <span v-if="l2.liveVideo && l2.liveVideoState=='expired' && (l2.resourceUrl==undefined || l2.resourceUrl==null)">
                                                    <span class="ml-2 mt-2" style="color:red;font-size:0.8em">Can't upload. Expired!</span>
                                                </span>
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="l2.syllabusInstructorName!=null">{{l2.syllabusInstructorName}}</span>
                                            <span v-else>--</span>
                                        </td>

                                    </tr>
                                    <!-- <tr class="border-bottom:1px solid #d0dcf5;">
                                                    <td colspan="4" style="text-align:center"> <i class="fa fa-plus mr-4 mt-1" style="cursor:pointer;font-size:1em;" @click="openThirdLevel(l1index,l2index)">&nbsp;Add Level 2</i></td>
                                                </tr> -->
                                </table>
                            </div>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" style="cursor:pointer;" class="p-1" role="tab">
                                    <div class="d-flex justify-content-center" style="margin:10px">
                                        <i class="fa fa-plus mr-4 mt-1 mb-2" style="cursor:pointer;color:grey;font-size:1em;" @click="openThirdLevel(l1index)">&nbsp;Add Level 1</i>
                                    </div>

                                </b-card-header>
                            </b-card>
                        </b-card-body>
                    </b-collapse>

                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" style="cursor:pointer;" class="p-1" role="tab">
                        <div class="d-flex justify-content-center" style="margin:10px">
                            <i class="fa fa-plus mr-4 mt-1 mb-2" style="cursor:pointer;color:#4274d6;font-size:1em;" @click="addFirstLevel">&nbsp;Add Level</i>
                        </div>

                    </b-card-header>
                </b-card>
            </div>
        </b-card>
    </div>
    <div class="text-center mt-3">
        <!-- <span v-if="!batchAdded">
            <b-button variant="outline-success" v-if="insert" @click="insertSyllabusProcess()">Submit</b-button>
            <b-button variant="outline-success" v-else @click="updateSyllabus()">Update</b-button>
        </span> -->

        <span>
            <b-button variant="outline-success" v-if="insert" :disabled="disabledButton" @click="disabledButton = true,insertSyllabusProcess()">Submit</b-button>
            <b-button variant="outline-success" v-else :disabled="disabledButton" @click="disabledButton = true,updateSyllabus()">Update</b-button>
        </span>

        <!-- <b-button variant="outline-danger" class="mx-3">Reset</b-button> -->
        <b-button variant="outline-primary" class="mx-3" @click="backToSyllabus()">Back</b-button>
    </div>
    <div>
        <b-modal id="viewThirdLevel" size="lg" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc v-model="showThirdLevelModal">
            <b-row style="background:#efefef;margin-top:-15px;padding:9px;">
                <b-col cols="11" style="  font-weight:Bold;font-size:1.3em;color:#4274d6;">Add Third Level Data</b-col>
                <b-col>
                    <button type="button" class="close staffmodal-close" data-dismiss="modal" aria-label="Close" style="color:red; font-size: 30px;" @click="cancelThirdLevel">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </b-col>
            </b-row>
            <br>
            <b-row style="padding-bottom:1.5em;">
                <b-col cols="4">
                    <span class="" style="color:#4274d6;font-weight:bold;font-size:1.2em;">Content<span style="color:red;">*</span></span>
                    <span class="pull-right " style="color:#4274d6;font-weight:bold;font-size:1.2em;">:</span>
                </b-col>
                <b-col>
                    <b-form-input v-model.trim="thirdLevel.word" class="edit shadow-sm" type="text" placeholder="Enter Content" required></b-form-input>&nbsp;

                </b-col>
            </b-row>
            <b-row style="padding-bottom:1.5em;">
                <b-col cols="4">
                    <span style="color:#4274d6;font-weight:bold;font-size:1.2em;">Select Types<span style="color:red;">*</span></span>
                    <span class="pull-right " style="color:#4274d6;font-weight:bold;font-size:1.2em;">:</span>
                </b-col>
                <b-col cols="8">
                    <!-- <b-form-group> -->
                    <!-- <b-form-radio-group style="" id="radio-group-2" v-model="thirdLevel.type" name="radio-sub-component">
                        <b-form-radio value="quiz">Quiz</b-form-radio>
                        <b-form-radio value="video">Video</b-form-radio>
                        <b-form-radio value="file">File</b-form-radio>
                    </b-form-radio-group> -->
                    <b-form-select style="width:90%;" v-model="thirdLevel.type" :options="courseOptions">
                    </b-form-select>
                    <!-- </b-form-group> -->
                </b-col>
            </b-row>
            <br>
            <!-- <div>
                <b-row style="padding-bottom:1.5em;">
                    <b-col cols="4">
                        <span style="color:#4274d6;font-weight:bold;font-size:1.2em;">Syllabus Content<span style="color:red;">*</span></span>
                        <span class="pull-right " style="color:#4274d6;font-weight:bold;font-size:1.2em;">:</span>
                    </b-col>
                    <b-col>
                        <b-form-file choose-label="Upload File" v-model="file" :state="Boolean(file)" @input="createFile(file)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
                    </b-col>
                </b-row>
            </div> -->
            <b-button class="pull-right" @click="addThirdLevel" style="background-color:#4274d6;border: 2px solid #4274d6;padding:0px 15px 0px 15px;float:right;margin-right:5px">
                <i class="fa fa-plus mr-2"></i><a style="font-weight:bold;">Add</a>
            </b-button>
        </b-modal>
    </div>

    <!-- ========================================= -->
    <div>
        <b-modal id="viewSyllabusContent" size="lg" hide-footer hide-header no-close-on-backdrop no-close-on-esc v-model="showSyllabusContent">
            <form>
                <b-list-group>
                    <b-list-group-item>
                        <b-form-input v-model="firstLevel.word" />
                    </b-list-group-item>
                </b-list-group>
                <b-row align-h="between">
                    <b-col cols="2" class="ml-5">
                        <b-button @click="cancelUpdate" variant="primary" style="margin-top:12px;">Cancel</b-button>
                    </b-col>
                    <b-col cols="2">
                        <b-button @click="editFirstLevel" class="pull-right" variant="primary" style="margin-top:12px;">Save</b-button>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </div>
    <!-- <div>
        <b-modal id="viewSyllabusContent" size="lg" hide-footer hide-header no-close-on-backdrop no-close-on-esc v-model="showSyllabusContent1">
            <form>
                <b-list-group>
                    <b-list-group-item>
                        <b-form-input v-model="secondLevel.word" />
                    </b-list-group-item>
                </b-list-group>
                <b-row>
                    <b-col>
                        <b-button @click="cancelUpdate1" variant="primary" style="margin-top:12px;">Cancel</b-button>
                    </b-col>
                    <b-col>
                        <b-button @click="editSecondLevel" class="pull-right" variant="primary" style="margin-top:12px;">Save</b-button>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </div> -->
    <!-- ========================================================== -->
    <div>
        <b-modal id="viewSyllabusContent" size="xl" hide-footer hide-header no-close-on-backdrop no-close-on-esc v-model="showSyllabusContent1">
            <form>
                <b-row>
                    <b-col cols=11 style="font-weight:Bold;font-size:1.3em;color:#2eb1c2;" v-if="clickedSyllabusContent1 != null">{{clickedSyllabusContent1}}</b-col>
                    <b-col>
                        <!-- <button class="close staffmodal-close" variant="light" aria-label="Close" style="color:red; font-size: 30px;" @click="cancelUpdate1">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                        <b-button class="close staffmodal-close" variant="light" style="color:red; font-size: 30px;" @click="cancelUpdate1"><span aria-hidden="true">&times;</span></b-button>
                    </b-col>
                </b-row>
                <br>
                <b-table striped hover responsive :items="syllabusContentItems1" :fields="syllabusFields">
                    <template v-slot:cell(actions)="data">
                        <div class="text-nowrap">
                            <!-- <b-form-radio class="mt-4" v-model="selectedSyllabusContentTab" :value="data.item"></b-form-radio> -->
                            <b-form-radio class="mt-4" v-model="selectedSyllabusContentTab" :value="data.item" @change="showButton()"></b-form-radio>
                        </div>
                    </template>
                    <template v-slot:cell(icons)="data">
                        <div class="text-nowrap">
                            <b-form-select class="mt-3" style="font-size:14px;margin-left:-5px;min-width:6em;" v-model="data.item.icon" :options="courseOptions" @change="selectedOption(data.item)">
                            </b-form-select>
                        </div>
                    </template>
                    <template v-slot:cell(word)="data">
                        <div class="text-nowrap">
                            <b-form-input class="mt-3" size="sm" style="margin-left:-5px;min-width:12em;" v-model="data.item.word"></b-form-input>
                        </div>
                    </template>
                    <template v-slot:cell(action)="data">
                        <div class="text-nowrap">
                            <font-awesome-icon icon="trash" class="ml-4 mr-4 mt-1" @click="removeSyllabusContent2(data.item.index)" style="font-size:1.2em;cursor:pointer;color:red;" />
                        </div>
                    </template>

                </b-table>
                <b-col>
                    <b-button id="save" @click="updateSyllabusContent1" class="pull-right mr-5" variant="primary">Save</b-button>
                    <!-- <div class="h2 mb-0"> -->
                    <div v-if="moveButton">
                        <b-button id="up" class="h2"  variant="success"  @click="moveContentUp()" :disabled="disabledUpButton"><b-icon icon="arrow-up" variant="white"/></b-button>
                        <!-- <b-icon id="up" class="h2" icon="arrow-up-square-fill" variant="success" @click="moveContentUp()"></b-icon> -->
                        <!-- <b-icon id="down" class="h2" icon="arrow-down-square-fill" variant="danger" @click="moveContentDown()"></b-icon> -->
                        <b-button id="down" class="h2"  variant="danger"  @click="moveContentDown()" :disabled="disabledDownButton"><b-icon icon="arrow-down" variant="white"/></b-button>
                    </div>
                    <!-- </div> -->
                </b-col>
            </form>

        </b-modal>
    </div>
</div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import Account from '../service/Account.js';
import {
    required,
    minLength
} from "vuelidate/lib/validators";
export default {
    name: "EditSyllabus",
    data() {
        return {
            disabledButton: false,
            disabledUpButton: false,
            disabledDownButton: false,
            counter: 0,
            moveButton: false,
            batchAdded: false,
            insert: true,
            update: false,
            importContent: false,
            createContent: false,
            currentSyllabusId: null,
            showThirdLevelModal: false,
            showSyllabusContent: false,
            showSyllabusContent1: false,
            clickedSyllabusContent1: null,
            selectedSyllabusContentTab: '',
            syllabusContentItems1: [],
            quizSelected: null,
            syllabusFields: [{
                    key: 'actions',
                    label: ''
                },
                {
                    key: 'icons',
                    label: 'Icons'
                },
                {
                    key: 'word',
                    label: 'Word'
                },
                {
                    key: 'action',
                    label: 'Action'
                }
            ],
            courseOptions: [{
                    text: 'Video',
                    value: 'video'
                },
                {
                    text: ' File',
                    value: 'file'
                },
                {
                    text: 'Quiz',
                    value: 'quiz'
                }
            ],
            file: null,
            syllabus: {
                name: "",
                code: "",
                f1Index: null,
                f2Index: null,
                f3Index: null,
                syllabusDescription: "",
                published: false,
                permaLink: "",
                archived: false,
                locked: false,
                duration: 0,
                comingAt: null,
                features: "",
                totalSyllabus: 0,
                detailedDescription: "",
                synopsis: "",
                contentFile: null,
                content: {
                    childrens: [],
                    word: "",
                    quiz: false,
                    resource: false,
                    video: false,
                    thumbnail: false,
                    thumbnailUrl: null,
                    resourceUrl: null,
                    videoNode: null,
                    quizDetails: null,
                    lectureId: "",
                    syllabusInstructorId: null,
                    syllabusInstructorName: null,
                },
                syllabusContent: {
                    firstSyllabusLevel: [],
                },
            },
            thirdLevel: {
                word: '',
                type: 'video',
                resourceUrl: null,
                childrens: [],
                liveVideo: false,
                releaseOn: null,
                SyllabusInstructorId: null,
                SyllabusInstructorName: null,
            },
            firstLevel: {
                word: null,
            },
            secondLevel: {
                word: null,
            }
        }
    },
    validations: {
        syllabus: {
            name: {
                required,
                minLength: minLength(3),
            },
            code: {
                required,
                minLength: minLength(3),
            },
            // contentFile:{
            //     required,
            // }
        }
    },

    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    if (this.$route.params.syllabusId != "create") {
                        this.update = true;
                        this.insert = false;
                        this.currentSyllabusId = this.$route.params.syllabusId;
                        this.getSyllabus(this.$route.params.syllabusId);
                        this.syllabusEditValidation();
                    } else {
                        this.insert = true;
                    }
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        this.agentId = localStorage.getItem("agentId");
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        syllabusEditValidation: function () {
            return new Promise((resolve, reject) => {
                axios.post("syllabus/update/validation/" + this.currentSyllabusId)
                    .then(response => {
                        if (response.data == true) {
                            this.batchAdded = false
                        } else {
                            this.batchAdded = true;
                        }
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);

                        reject(err);
                    });
            });
        },
        addFirstLevel: function () {
            swal.fire({
                    title: "Enter First Level Name",
                    input: "text",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                })
                .then((value) => {
                    if (value.value == "" || value.dismiss == "cancel") {
                        console.log("error");
                    } else {
                        let firstLevelName = value.value;
                        let data = {
                            quiz: false,
                            video: false,
                            resource: false,
                            resourceUrl: null,
                            childrens: [],
                            word: firstLevelName
                        }
                        this.syllabus.content.childrens.push(data);
                    }
                    this.showCollapse = true;
                })
        },
        openCreateContent: function () {
            this.syllabus.content.childrens.splice(0);
            let data = {
                quiz: false,
                video: false,
                resource: false,
                resourceUrl: null,
                childrens: [],
                word: this.syllabus.name
            }

            let data1 = {
                quiz: false,
                video: false,
                resource: true,
                resourceUrl: null,
                lectureId: uuid,
                childrens: [],
                word: "Introduction"
            }
            var uuid;
            uuid = function uuidv4() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            }
            data1.lectureId = uuid();
            console.log("uuid", uuid());
            data.childrens.push(data1)

            this.syllabus.content.childrens.push(data);
            console.log(this.syllabus);
            this.createContent = true;
            this.importContent = false;
        },
        getSyllabus(syllabusId) {
            return new Promise((resolve, reject) => {
                axios
                    .post("syllabus/get/" + syllabusId)
                    .then(response => {
                        this.syllabus.syllabusId = response.data.syllabusId;
                        this.syllabus.name = response.data.name;
                        this.syllabus.code = response.data.code;
                        this.syllabus.content = response.data.syllabusContent;
                        // console.log("getSyllabus", this.syllabus.code);
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        openImportContent: function () {
            this.importContent = true;
            this.syllabus.contentFile = null;
        },
        closeImportContent: function () {
            this.importContent = false;
            this.syllabus.contentFile = null;
        },
        syllabusContentFileSelected: function (e) {
            this.syllabus.contentFile = e;
            let formData = new FormData();
            formData.append("file", this.syllabus.contentFile);
            //    formData.append("liveCourse", this.syllabus.liveCourse);
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                axios
                    .post("syllabus/content/upload", formData, config)
                    .then(response => {
                        console.log("fsd");
                        // swal(
                        //     'Updated!',
                        //     'Course content uploaded successfully.',
                        //     'success'
                        // );
                        this.syllabus.content = response.data;
                        console.log(this.syllabus.content, "this.syllabus.content")
                        resolve(response);
                        this.isLoading = false;
                    })
                    .catch(err => {
                        if (err.response.status == 409) {
                            // console.log("if=========");
                            this.$swal({
                                title: "The file is not readable",
                                icon: "error",
                                button: "ok",
                            });
                            this.isLoading = false;
                            this.importContent = true;
                            this.syllabus.contentFile = null;
                        }
                        reject(err);
                    });
            });
        },
        validateThirdLevel: function () {
            if (this.syllabus.content.childrens[0].childrens.length != 0) {
                for (var i = 0; i < this.syllabus.content.childrens.length; i++) {
                    for (var j = 0; j < this.syllabus.content.childrens[i].childrens.length; j++) {
                        console.log("validateThirdLevel 2 for", this.syllabus.content.childrens[i].childrens.length);
                        // for (var k = 0; k < this.syllabus.content.childrens[i].childrens[j].childrens.length; k++) {
                        var uuid;
                        uuid = function uuidv4() {
                            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                                var r = Math.random() * 16 | 0,
                                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                                return v.toString(16);
                            });
                        }
                        console.log(this.syllabus.content.childrens[i].childrens[j]);
                        this.syllabus.content.childrens[i].childrens[j].lectureId = uuid();
                        console.log(this.syllabus.content.childrens[i].childrens[j].lectureId);

                        // }
                    }
                }
                // return new Promise((resolve) => {
                //     setTimeout(() => {
                //         resolve(true)
                //     }, 1000)
                //     this.updateSyllabus();
                // })
            }
        },

        backToSyllabus() {
            // let syllabusId = "create";
            this.$router.push('/settings/syllabus/')
            // this.$router.push('/user/createsyllabus')
        },
        async insertSyllabusProcess() {
            await this.validateThirdLevel();
            this.$v.syllabus.$touch();
            // if (this.$v.syllabus.$invalid) {
            //     this.submitStatus = "FAIL";
            //     // alert("insert submit failed");
            // } else {
                if (!this.$v.syllabus.$invalid) {
                this.disabledButton = true;
                return new Promise((resolve, reject) => {
                    axios
                        .post("syllabus/insert", this.syllabus)
                        .then(response => {
                            this.$swal({
                                title: "Good job!",
                                text: "Syllabus Added successfully!!!!!",
                                icon: "success",
                                button: "ok",
                            });
                            this.currentSyllabusId = response.data.syllabusId
                            this.getSyllabus(this.currentSyllabusId);
                            this.disabledButton = false;
                            this.insert = false;
                            this.update = true;
                            // this.validateThirdLevel();
                            this.syllabusEditValidation();
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err);

                            reject(err);
                        });
                });
            }
            else{
                this.submitStatus = "FAIL";
                this.disabledButton = false;
                // alert("insert submit failed");
            }
        },
        updateSyllabus: function () {
            this.disabledButton = true;
            return new Promise((resolve, reject) => {
                axios
                    .post("syllabus/update/" + this.currentSyllabusId, this.syllabus)
                    .then(response => {
                        this.updateBatchSyllabus();
                        this.backToSyllabus();
                        this.disabledButton = false;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        updateBatchSyllabus: function () {
            console.log(this.currentSyllabusId, this.syllabus);
            return new Promise((resolve, reject) => {
                axios
                    .post("branchBatch/update/" + this.currentSyllabusId, this.syllabus)
                    .then(response => {
                        console.log(response.data, "updateBatchSyllabus");
                        this.$swal({
                            title: "Good job!",
                            text: response.data + "!!!!!",
                            icon: "success",
                            button: "ok",
                        });
                        // this.backToSyllabus();
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);

                        reject(err);
                    });
            });
        },
        syllabusAddedBatchUpdate: function (word, content) {
            let data = {
                syllabusId: this.syllabus.syllabusId,
                syllabusContent: this.syllabus.content,
                word: word,
                content: content,
            }
            return new Promise((resolve, reject) => {
                axios
                    .post("syllabus/batch/update", data)
                    .then(response => {
                        swal.fire({
                            type: "success",
                            text: "Content added syllabus and batches successfully"
                        })
                        this.$swal({
                            title: "Good job!",
                            text: "Content added syllabus and batches successfully",
                            icon: "success",
                            button: "ok",
                        });
                        // this.backToSyllabus();
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
        cancelUpdate: function () {
            this.showSyllabusContent = false;
            this.firstLevel.word = null;
        },
        cancelUpdate1: function () {
            this.showSyllabusContent1 = false;
            this.secondLevel.word = null;
        },
        editFirstLevel: function () {
            if (this.firstLevel.word == "") {
                console.log("ds");
            } else {
                this.syllabus.content.childrens[this.f1Index].word = this.firstLevel.word;
                this.showSyllabusContent = false;

            }

        },
        editSecondLevel: function () {
            if (this.secondLevel.word == "") {
                console.log("ds");
            } else {
                console.log();

                this.syllabus.content.childrens[this.f1Index].childrens[this.f2Index].word = this.secondLevel.word;
                console.log(this.syllabus.content.childrens[this.f1Index].childrens[this.f2Index].word, '===========' + this.secondLevel.word);
                this.showSyllabusContent1 = false;

            }
        },
        removeSyllabusContent: function (index) {
            swal.fire({
                title: 'Are you sure?',
                text: "You chosen to remove this syllabus Content Permanently! This action can't be Reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Remove Permanently!'
            }).then((result) => {
                if (result.value) {
                    this.syllabus.content.childrens.splice(index, 1)
                    if (this.syllabus.content.childrens.length == 0) {
                        this.syllabus.contentFile = null;
                    }
                }
            });
        },
        removeSyllabusContent1: function (index1, index2) {

            swal.fire({
                title: 'Are you sure?',
                text: "You chosen to remove this Syllabus Content Permanently! This action can't be Reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Remove Permanently!'
            }).then((result) => {
                if (result.value) {
                    // console.log(this.syllabus.content.childrens[index1].childrens[index2]);
                    this.syllabus.content.childrens[index1].childrens.splice(index2, 1);
                }
            });
        },
        removeSyllabusContent2: function (index2) {
            console.log(this.syllabus.content.childrens[this.f1Index].childrens[index2]);
            swal.fire({
                title: 'Are you sure?',
                text: "You chosen to remove this Syllabus Content Permanently! This action can't be Reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Remove Permanently!'
            }).then((result) => {
                if (result.value) {
                    this.syllabus.content.childrens[this.f1Index].childrens.splice(index2, 1);
                    this.showSyllabusContent1 = false;
                }
            });
        },

        showButton: function () {
            this.moveButton = true;
            this.movingButtonCondition()
        },
        updateSyllabusContent2: function (index2) {
            console.log(this.syllabus.content.childrens[this.f1Index].childrens[index2]);
        },
        moveContentUp: function () {

            // console.log("SelectedSyllabus", (this.selectedSyllabusContentTab))
            // console.log("syllabusContentItems1",this.syllabusContentItems1);

            // this.syllabusContentItems1.reverse();

            // console.log("SelectedSyllabus", (this.selectedSyllabusContentTab.index))
            // console.log("SyllabusContentItems1", (this.syllabusContentItems1.length))

            // var fromIndex = this.selectedSyllabusContentTab.index;
            // // for (let i = 0; i < this.selectedSyllabusContentTab.index; i++) {
            // //     var toIndex = this.selectedSyllabusContentTab.index -= 1;
            // // }
            // var toIndex = this.selectedSyllabusContentTab.index -= 1;
            // var element = this.syllabusContentItems1[fromIndex];
            // this.syllabusContentItems1.splice(fromIndex, 1);
            // this.syllabusContentItems1.splice(toIndex, 0, element);

            var old_index = this.selectedSyllabusContentTab.index;
            var new_index = this.selectedSyllabusContentTab.index -= 1;
            var arr = this.syllabusContentItems1;

            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }

            if(new_index <= 0){
                this.disabledUpButton = true;
            }else if(new_index <= arr.length-1){
                this.disabledDownButton = false;
            }

            // if (new_index >= arr.length) {
            //     var k = new_index - arr.length + 1;
            //     while (k--) {
            //         arr.push(undefined);
            //         console.log("===arr.length=",arr.length);
            //         console.log("Move Up Calling");
            //     }
            // }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            // console.log(arr);
            // console.log("new_index",new_index);
            // console.log("old_index",old_index);
            // console.log("==array==",arr);

            // let first = this.syllabusContentItems1.shift()
            // this.syllabusContentItems1.push(first); // add to end
            // return this.syllabusContentItems1;

            // this.syllabusContentItems1.unshift(this.syllabusContentItems1[this.syllabusContentItems1.length - 1]);
            // this.syllabusContentItems1.splice(this.syllabusContentItems1.length - 1);

        },

        moveContentDown: function () {

            // console.log("SelectedSyllabus", (this.selectedSyllabusContentTab.index))
            // console.log("SyllabusContentItems1", (this.syllabusContentItems1.length))

            // var fromIndex = this.selectedSyllabusContentTab.index;
            // // for (let i = this.selectedSyllabusContentTab.index; i >=0; i--) {
            // //     var toIndex = this.selectedSyllabusContentTab.index += 1;
            // // }
            // var toIndex = this.selectedSyllabusContentTab.index += 1;
            // var element = this.syllabusContentItems1[fromIndex];
            // this.syllabusContentItems1.splice(fromIndex, 1);
            // this.syllabusContentItems1.splice(toIndex, 0, element);

            var old_index = this.selectedSyllabusContentTab.index;
            var new_index = this.selectedSyllabusContentTab.index += 1;
            var arr = this.syllabusContentItems1;

            while (old_index < 0) {
                old_index += arr.length;
            }
            while (new_index < 0) {
                new_index += arr.length;
            }

            if(new_index >= arr.length-1){
                this.disabledDownButton = true;
            }else if(new_index >0){
                this.disabledUpButton = false;
            }

            // if (new_index >= arr.length) {
            //     var k = new_index - arr.length + 1;
            //     while (k--) {
            //         arr.push(undefined);
            //         console.log("===arr.length=",arr.length);
            //         console.log("Move Down Calling");
            //     }
            // }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            // console.log(arr);
            // console.log("new_index",new_index);
            // console.log("old_index",old_index);
            // console.log("==array==",arr);

            // var element = arr[old_index];
            // arr.splice(old_index, 1);
            // arr.splice(new_index, 0, element);

            // let last = this.syllabusContentItems1.pop()
            // this.syllabusContentItems1.unshift(last); // add to front
            // return this.syllabusContentItems1;

            // this.syllabusContentItems1.unshift(this.syllabusContentItems1[this.syllabusContentItems1.length - 1]);
            // this.syllabusContentItems1.splice(this.syllabusContentItems1.length - 1)
        },

        movingButtonCondition: function () {

            var old_index = this.selectedSyllabusContentTab.index;
            // var new_index = this.selectedSyllabusContentTab.index += 1;
            var arr = this.syllabusContentItems1;

             if(old_index == arr.length-1){
                this.disabledDownButton = true;
                this.disabledUpButton = false;
            }else if(old_index == 0){
                this.disabledUpButton = true;
                this.disabledDownButton = false;
            }else{
                this.disabledUpButton = false;
                this.disabledDownButton = false;
            }
        },

        addSecondLevel: function (index) {
            swal.fire({
                    title: "Enter Second Level Name",
                    input: "text",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                })
                .then((value) => {
                    if (value.value == "" || value.dismiss == "cancel") {
                        console.log("ds");
                    } else {
                        let secondLevelName = value.value;
                        let data = {
                            quiz: false,
                            video: false,
                            resource: false,
                            resourceUrl: null,
                            childrens: [],
                            word: secondLevelName
                        }
                        this.syllabus.content.childrens[index].childrens.push(data);

                    }

                })
        },
        editSyllabusContent: function (content, index) {
            this.f1Index = index;
            // console.log("index", index);
            this.firstLevel.word = content.word;
            // console.log(this.firstLevel);
            this.showSyllabusContent = true;
            // console.log("editSyllabusContent");
            // this.level1Index = index;
        },
        // editSyllabusContent1: function (content,index2,index1) {
        //     this.f1Index=index1;
        //     this.f2Index=index2;
        //     console.log(this.f1Index,this.f2Index);
        //     this.secondLevel.word=content.word;
        //     console.log(this.secondLevel);
        //     this.showSyllabusContent1 = true;
        //     console.log("editSyllabusContent");
        // },
        editSyllabusContent1: function (content, index1) {
            // console.log(index1);
            // console.log(content, "contenteditSyllabusContent1");
            this.showSyllabusContent1 = true;
            this.f1Index = index1;
            // this.f2Index = index2;
            this.syllabusContentItems1.splice(0);
            for (var i = 0; i < content.childrens.length; i++) {
                let data = {
                    lectureId: content.childrens[i].lectureId,
                    childrens: content.childrens[i].childrens,
                    word: content.childrens[i].word,
                    resource: content.childrens[i].resource,
                    resourceUrl: content.childrens[i].resourceUrl,
                    thumbnail: content.childrens[i].thumbnail,
                    thumbnailUrl: content.childrens[i].thumbnailUrl,
                    video: content.childrens[i].video,
                    videoNode: content.childrens[i].videoNode,
                    index: i,
                    iValue: i,
                    quiz: content.childrens[i].quiz,
                    quizDetails: content.childrens[i].quizDetails,
                    icon: '',
                    // clickedImport: false,
                    free: content.childrens[i].free,
                    syllabusInstructorName: content.childrens[i].syllabusInstructorName,
                    liveVideo: content.childrens[i].liveVideo,
                    releaseOn: content.childrens[i].releaseOn == undefined ? null : content.childrens[i].releaseOn,
                    liveVideoState: content.childrens[i].liveVideoState,
                    archived: content.childrens[i].archived
                }

                if (content.childrens[i].quiz) {
                    data.icon = 'quiz';
                    if (data.quizDetails != null) {
                        // data.clickedImport = true;
                    }
                } else if (content.childrens[i].video)
                    data.icon = 'video';
                else {
                    data.icon = 'file';
                }
                this.syllabusContentItems1.push(data);
                // console.log("this.syllabusContentItems1", this.syllabusContentItems1);

            }
            this.clickedSyllabusContent1 = content.word;
        },
        openThirdLevel(index1) {
            this.f1Index = index1;
            // this.f2Index = index2;
            console.log(this.f1Index, this.f2Index);
            this.showThirdLevelModal = true;
        },
        cancelThirdLevel: function () {
            this.showThirdLevelModal = false;
            this.thirdLevel.childrens = [];
            this.thirdLevel.word = null;
            this.thirdLevel.quiz = false;
            this.thirdLevel.video = false;
            this.thirdLevel.resource = false;
            this.thirdLevel.resourceUrl = null;
            this.thirdLevel.liveVideo = false;
            this.thirdLevel.releaseOn = null;
            //    this.thirdLevel.courseInstructorId = null;
            //    this.thirdLevel.courseInstructorName = null;
        },
        addThirdLevel: function () {
            let data = {
                word: this.thirdLevel.word,
                quiz: false,
                video: false,
                resource: false,
                childrens: [],
                resourceUrl: null,
                lectureId: null,
                videoNode: null,
                quizDetails: null,
                // clickedImport: false,
                //  courseInstructorId: this.thirdLevel.courseInstructorId,
                //  courseInstructorName: this.thirdLevel.courseInstructorName,
                liveVideo: this.thirdLevel.liveVideo,
                releaseOn: this.thirdLevel.releaseOn,
                liveVideoState: ''
            }
            console.log(data.lectureId);
            var uuid;
            uuid = function uuidv4() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            }
            data.lectureId = uuid();
            if (this.thirdLevel.type == 'file') {
                data.resource = true;
            } else if (this.thirdLevel.type == 'video') {
                data.video = true;
            } else {
                data.quiz = true;
            }

            let allCheck = true;

            if (allCheck) {
                console.log("[this.f1Index]=== ", this.syllabus.content.childrens[this.f1Index].word);
                this.syllabus.content.childrens[this.f1Index].childrens.push(data);
                if (this.batchAdded) {
                    this.syllabusAddedBatchUpdate(this.syllabus.content.childrens[this.f1Index].word, data)
                } else {
                    swal.fire({
                        type: "success",
                        text: " Syllabus Content added successfully!!"
                    })
                }
                this.cancelThirdLevel();
            }

        },
        createFile: function (data) {
            this.files = data;
            let formData = new FormData();
            formData.append("file", this.files);
            return new Promise((resolve, reject) => {
                let config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                axios.post("syllabus/uploadFile", formData, config)
                    .then((response) => {
                        this.file = response.data;
                        this.thirdLevel.resourceUrl = this.file;
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);

                        reject(err);
                    });

            })

        },
        selectedOption: function (data) {
            if (data.icon == 'file') {
                this.syllabusContentItems1[data.index].video = false;
                this.syllabusContentItems1[data.index].quiz = false;
                this.syllabusContentItems1[data.index].resource = true;
            } else if (data.icon == 'quiz') {
                this.syllabusContentItems1[data.index].video = false;
                this.syllabusContentItems1[data.index].quiz = true;
                this.syllabusContentItems1[data.index].resource = false;
            } else if (data.icon == 'video') {
                this.syllabusContentItems1[data.index].video = true;
                this.syllabusContentItems1[data.index].quiz = false;
                this.syllabusContentItems1[data.index].resource = false;
            }
            console.log(this.syllabusContentItems1);
        },
        updateSyllabusContent1: function () {
            for (var i = 0; i < this.syllabusContentItems1.length; i++) {

                this.syllabus.content.childrens[this.f1Index].childrens[i].word = this.syllabusContentItems1[i].word;
                this.syllabus.content.childrens[this.f1Index].childrens[i].quiz = this.syllabusContentItems1[i].quiz;
                this.syllabus.content.childrens[this.f1Index].childrens[i].video = this.syllabusContentItems1[i].video;
                this.syllabus.content.childrens[this.f1Index].childrens[i].resource = this.syllabusContentItems1[i].resource;
                this.syllabus.content.childrens[this.f1Index].childrens[i].resourceUrl = this.syllabusContentItems1[i].resourceUrl;
                if (this.syllabusContentItems1[i].videoNode == undefined) {
                    this.syllabus.content.childrens[this.f1Index].childrens[i].videoNode = {};
                } else {
                    this.syllabus.content.childrens[this.f1Index].childrens[i].videoNode = this.syllabusContentItems1[i].videoNode;
                }
                if (this.syllabusContentItems1[i].quizDetails == undefined) {
                    this.syllabus.content.childrens[this.f1Index].childrens[i].quizDetails = {};
                } else {
                    this.syllabus.content.childrens[this.f1Index].childrens[i].quizDetails = this.syllabusContentItems1[i].quizDetails;
                }
                // this.syllabus.content.childrens[this.f1Index].childrens[i].quizDetails = this.syllabusContentItems1[i].quizDetails;
                this.syllabus.content.childrens[this.f1Index].childrens[i].free = this.syllabusContentItems1[i].free;
                this.syllabus.content.childrens[this.f1Index].childrens[i].thumbnail = this.syllabusContentItems1[i].thumbnail;
                this.syllabus.content.childrens[this.f1Index].childrens[i].thumbnailUrl = this.syllabusContentItems1[i].thumbnailUrl;
                this.syllabus.content.childrens[this.f1Index].childrens[i].lectureId = this.syllabusContentItems1[i].lectureId;
                this.syllabus.content.childrens[this.f1Index].childrens[i].syllabusInstructorId = this.syllabusContentItems1[i].syllabusInstructorId;
                this.syllabus.content.childrens[this.f1Index].childrens[i].syllabusInstructorName = this.syllabusContentItems1[i].syllabusInstructorName;
                this.syllabus.content.childrens[this.f1Index].childrens[i].archived = this.syllabusContentItems1[i].archived;
            }
            console.log(this.syllabus.content);
            this.showSyllabusContent1 = false;
        },

    }
}
</script>

<style scoped>
#up {
    margin-left: 40%;
}

#down {
    margin-left: 2%;
}

#save {
    margin-left: 85%;
}

.card-header:focus {
    outline: none;
    cursor: pointer;
}

.card-header {
    /* background-color: rgba(0, 0, 0, 0.03); */
    border-bottom: none;

}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: left;
}

/* .inputBox { */
/* border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 0.5% 0.5%; */
/* } */
.box {
    margin-left: 20%;
    margin-right: 20%;
}
</style>
