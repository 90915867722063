<template>
<div>
    <!-- for loader -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <!-- <h6>Admin Not Intrested</h6> -->
    <!-- Notintrested table and details start -->
    <div class="mt-5">
        <b-container>
            <b-row align-v="center">
                <!-- notintrested disabled -->
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" disabled>Notintrested</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" align="fill" prev-text="Prev" next-text="Next" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <div>
                    <b-container>
                        <b-row>
                            <b-col col lg="2" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedCourse" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                    <b-form-select-option disabled value="Course">
                                        <p>Courses</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllCourses">
                                        <p>All Courses</p>
                                    </b-form-select-option>
                                    <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>

                            <b-col col lg="2" md="1" id="r1" sm="3">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedMode" size="sm" @change="
              getAllSortCount(
                selectedAgent,
                selectedCourse,
                selectedMode,
                selectedSource,
                selectedBranch,
                selectedDates
              )
            ">
                                    <b-form-select-option disabled value="mode">
                                        <p>Mode</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="allModes">
                                        <p>All Modes</p>
                                    </b-form-select-option>
                                    <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                        <strong>{{ item.text }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>

                            <b-col col lg="2" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedSource" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                    <b-form-select-option disabled value="Source">
                                        <p>Sources</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllSources">
                                        <p>All Sources</p>
                                    </b-form-select-option>
                                    <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="4" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedAgent" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                    <b-form-select-option disabled value="Agent">
                                        <p>Agents</p>
                                    </b-form-select-option>
                                    <b-form-select-option value="AllAgents">
                                        <p>All Agents</p>
                                    </b-form-select-option>
                                    <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.firstName }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedBranch" size="sm" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    ">
                                    <b-form-select-option disabled value="Branch">
                                        Branches
                                    </b-form-select-option>
                                    <b-form-select-option value="AllBranches">
                                        <p>All Branches</p>
                                    </b-form-select-option>
                                    <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                        <strong>{{ item.name }}</strong>
                                    </option>
                                </b-form-select>
                            </b-col>
                            <b-col col lg="2" md="2" id="r1" sm="4">
                                <b-form-select class="bnclr" id="bnclr2" v-model="selectedDates" @change="
                      getAllSortCount(
                        selectedAgent,
                        selectedCourse,
                        selectedMode,
                        selectedSource,
                        selectedBranch,
                        selectedDates
                      )
                    " :options="dateSelect">>
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <br />
                <b-table responsive hover :items="allNotintresteds" :fields="fields" :current-page="currentPage" :per-page="allNotintresteds.pageCount" ref="table">
                    <template v-slot:cell(notintrestedId)="data">
                        <span class="title"> {{ data.item.notintrestedId }}</span>
                    </template>
                    <template v-slot:cell(callCreatedAt)="data">
                        <div class="dateCell">
                            {{ callDate(data.item.callCreatedAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(agent)="data">
                        {{ data.item.agent.firstName }}
                    </template>
                    <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                    </template>
                    <template v-slot:cell(contact)="data">
                        {{ data.item.contact }}
                        <span v-if="data.item.alternativeContact != null">,</span>
                        {{ data.item.alternativeContact }}
                    </template>
                    <template v-slot:cell(course)="data">
                        {{ data.item.course.name }}
                    </template>
                    <template v-slot:cell(source)="data">
                        {{ data.item.source.name }}
                    </template>
                    <template v-slot:cell(branch)="data">
                        {{ data.item.branch.name }}
                    </template>
                    <template v-slot:cell(createdAt)="data">
                        <div class="dateCell">
                            {{ callDate(data.item.createdAt, "fullDate") }}
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <div class="cellSize">
                        <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Notintrested Details" v-b-modal.viewNotintrested @click="getFollowups(data.item)" size="sm" class="mx-1">
                            <font-awesome-icon icon="eye" />
                        </b-button>
                        <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="Followup" @click="getNotintrested(data.item) && resetForm()" v-b-modal.followInsert>
                            <font-awesome-icon icon="file-signature" />
                        </b-button>

                        <!-- <b-button
                        class="px-2"
                        variant="secondary"
                        @click="notintrestedClosed(data.item)"
                        v-b-tooltip.hover.topright="{ variant: 'secondary' }"
                        title="Closed Notintrested"
                        ><font-awesome-icon icon="window-close"
                      /></b-button> -->
                    </div>
                    </template>
                </b-table>
            </b-card>
        </b-container>
        <!-- View Notintrested Start -->
        <b-container>
            <b-modal id="viewNotintrested" no-close-on-backdrop hide-footer title="View Notintrested">
                <div class="form-control">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Name</label>
                            <b-form-input type="text" v-model="notintrested.name" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Contact</label>
                            <b-form-input v-model="notintrested.contact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Alternative Contact</label>
                            <b-form-input v-model="notintrested.alternativeContact" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Age</label>
                            <b-form-input v-model="notintrested.age" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Qualification</label>
                            <b-form-input class="form-control" disabled v-model="notintrested.qualification">
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course</label>
                            <b-form-input class="form-control" v-model="notintrested.course.name" disabled>
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Source</label>
                            <b-form-input class="form-control" v-model="notintrested.source.name" disabled>
                            </b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Mode</label>
                            <b-form-input class="form-control" v-model="notintrested.mode" disabled></b-form-input>
                        </div>
                    </div>
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Branch</label>
                            <b-form-input class="form-control" v-model="notintrested.branch.name" disabled>
                            </b-form-input>
                        </div>
                    </div>
                    <b-table responsive bordered striped hover :items="followups" :fields="followupDateAndRemark">
                        <template v-slot:cell(remark)="data">
                            <span class="title"> {{ data.item.remark }}</span>
                        </template>
                        <!-- <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template> -->
                        <template v-slot:cell(createdAt)="data">
                            {{ callDate(data.item.createdAt, "fullDate") }}
                        </template>
                        <template v-slot:cell(followupDate)="data">
                            {{ callDate(data.item.followupDate, "fullDate") }}
                        </template>
                    </b-table>
                </div>
            </b-modal>
        </b-container>
        <!-- View Notintrested End -->
    </div>
    <!-- Notintrested table and details end -->

    <!-- popup start for next follow add -->
    <b-modal id="followInsert" title="Followup Update" ref="modal" no-close-on-backdrop hide-footer>
        <!-- <h5 class="px-5">Name : {{ update.name }}</h5>
          <h5 class="px-5">Contact : {{ update.contact }}</h5> -->
        <div class="form-row mt-3 px-5">
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Followup Date and Remark</label>
                    <div class="py-2">
                        <b-form-input format="DD-MM-YYYY h:i:s" v-model="followup.followupDate" :class="{
                  'is-invalid': $v.followup.followupDate.$error,
                  'is-valid': !$v.followup.followupDate.$invalid,
                }" type="datetime-local" min="new Time()"></b-form-input>
                        <div class="valid-feedback">date time is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.followup.followupDate.required">date or time is invalid!</span>
                        </div>
                    </div>
                    <div>
                        <b-form-textarea class="form-control" v-model="followup.remark" :class="{
                  'is-invalid': $v.followup.remark.$error,
                  'is-valid': !$v.followup.remark.$invalid,
                }" placeholder="*Date mandatory and feedback"></b-form-textarea>
                        <div class="valid-feedback">remark is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.followup.remark.required">remark is invalid!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-3 mt-2">
            <b-button type="submit" @click="updateFollowup()" class="px-3" variant="primary" no-close-on-backdrop :disabled="disabledButton">Update</b-button>
        </div>
    </b-modal>
    <!-- popup end for next follow add -->

    <!-- data range selece modal start-->
    <b-modal ref="dateRange" hide-footer id="dateRange" title="Select Date">
        <div>
            <label for="from-datepicker">Choose The From date</label>
            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{
            'is-invalid': $v.from.$error,
            'is-valid': !$v.from.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div>
            <label for="to-datepicker">Choose The To date</label>
            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{
            'is-invalid': $v.to.$error,
            'is-valid': !$v.to.$invalid,
          }" type="date"></b-form-input>
        </div>
        <div class="text-center mt-3">
            <b-button variant="outline-success" type="submit" @click="
            getAllSortCount(
              selectedAgent,
              selectedCourse,
              selectedMode,
              selectedSource,
              selectedBranch,
              selectedDates
            )
          ">Submit</b-button>
        </div>
    </b-modal>
    <!-- data range selece modal end-->
</div>
</template>

<script>
import axios from "axios";
import Account from '../service/Account.js';
import AdminNotintrestedsService from "../service/AdminNotintrestedsService";
import FollowupService from "../service/FollowupService";
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
// import CallService from "../service/CallService";
import NotintrestedsService from "../service/NotintrestedsService";
import moment from "moment-timezone";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
export default {
    name: "AdminNotintresteds",
    data() {
        return {

            selectedMode: "mode",
            //loading: false,
            disableButton: false,
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],

            allCourses: "",
            allSources: "",
            allBranches: "",
            allAgents: "",
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            selectedDates: null,
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            from: "",
            to: "",
            allNotintresteds: [],
            fromValue: "",
            toValue: "",
            data: "",
            //switchPage condition check
            paginationNotintresteds: false,
            searchPaginationNotintresteds: false,
            sortAgentPaginationNotintresteds: false,
            sortCoursePaginationNotintresteds: false,
            sortSourcePaginationNotintresteds: false,
            sortBranchPaginationNotintresteds: false,
            sortDatePaginationNotintresteds: false,
            fields: [{
                    key: "callCreatedAt",
                    label: "Call_Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "Agent",
                    label: "AgentName"
                },
                {
                    key: "name",
                    label: "Name"
                },
                {
                    key: "contact",
                    label: "Contact"
                },
                {
                    key: "course",
                    label: "Course"
                },
                {
                    key: "source",
                    label: "Source"
                },
                {
                    key: "mode",
                    label: "Mode"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "createdAt",
                    label: "Notintrested_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],
            notintrested: {
                callCreatedAt: "",
                name: "",
                contact: "",
                course: "",
                source: "",
                mode: "",
                agent: "",
                branch: "",
                createdAt: "",
            },
            // update: {
            //   name: "",
            //   contact: "",
            //   age: "",
            //   qualification: null,
            //   course: null,
            //   source: null,
            //   mode: null,
            //   branch: null,
            // },
            disabledButton: false,
            followup: {
                followupDate: "",
                remark: "",
            },
            followups: [],
            followupDateAndRemark: [{
                    key: "createdAt",
                    label: "Entry_Date",
                    tdClass: "dateCell"
                },
                {
                    key: "remark",
                    label: "Remarks"
                },
                {
                    key: "followupDate",
                    label: "Next Followup Date",
                    sortable: true
                },
            ],
            // for loader
            loading: false,
            // for pagination
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [3, 5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }],
            totalCount: 0,
            // for search in table
            keyword: "",
        };
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
        },
        followup: {
            followupDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (selectedDT > currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            remark: {
                required,
                minLength: minLength(5),
            },
        },
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    this.getCount();
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    // this.loading = false;
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getCount();
                // this.getAllPageable();
            }
        },
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },

        updateFollowup: function () {
            // console.log("The current data is : " + this.notintrested.notintrestedId + " " + this.agentId)

            this.followupUpdate(this.notintrested);
            this.updateStatusAsClosed(this.agentId, this.notintrested);
        },
        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.dateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.dateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortNotintresteds = true;
                this.paginationNotintresteds = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/adminNotintrested/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/adminNotintrested/all/sort/" + currentPage + "/" + pageCount,
                        data
                    )
                    .then((response) => {
                        this.allNotintresteds = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        notintrestedClosed: function (notintrestedId) {
            this.getNotintrested(notintrestedId);
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You are closed the Notintrested to Call!",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, close!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.updateStatusAsClosed(
                            this.agentId,
                            this.notintrested.notintrestedId
                        );
                        //console.log("first",this.agentId);
                        return new Promise((resolve, reject) => {
                            this.$swal({
                                title: "Call Successful",
                                text: "Notintrested closed to Call",
                                icon: "success",
                                button: "ok",
                            });
                            // CallService.putCall(this.agentId, this.notintrested)
                            // NotintrestedsService.updateStatusAsClosed(this.agentId, this.notintrested)
                            this.updateStatusAsClosed(
                                    this.agentId,
                                    this.notintrested.notintrestedId
                                )
                                .then((response) => {
                                    this.getCount();
                                    resolve(response);
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }
                });
        },

        getNotintrested: function (notintrested) {
            this.notintrested = notintrested;
            // console.log(this.notintrested.agent.agentId);
            // console.log("Notintrested");
            this.agentId = this.notintrested.agent.agentId;
            this.callId = this.notintrested.callId;
            // console.log(this.notintrested.callId);
            // if (this.notintrested.callId == null) {
            //   return new Promise((resolve, reject) => {
            //     // console.log("Counsellor notintrested");
            //     NotintrestedsService.getEnquiryFollowupDateRemark(notintrested.notintrestedId)
            //       .then((response) => {
            //         this.followupDateRemark = response.data;
            //         // console.log(response.data);

            //         resolve(response);
            //       })
            //       .catch((err) => {
            //         reject(err);
            //       });
            //   });
            // } else {
            return new Promise((resolve, reject) => {
                NotintrestedsService.getFollowupDateRemark(
                        notintrested.agent.agentId,
                        notintrested.callId
                    )
                    .then((response) => {
                        this.followupDateRemark = response.data;
                        // console.log(response.data);

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            // }
        },

        updateStatusAsClosed(agentId, notintrestedId) {
            this.getNotintrested(notintrestedId);
            return new Promise((resolve, reject) => {
                //console.log("3rd",this.agentId);
                NotintrestedsService.updateStatusAsClosed(agentId, notintrestedId)
                    .then((response) => {
                        this.getCount();
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getFollowups: function (notintrested) {
            this.notintrested = notintrested;
            // console.log("notintrested", notintrested);
            return new Promise((resolve, reject) => {
                // FollowupService.getFollowupDateRemark(notintrested.callId)
                FollowupService.getFollowupCreatedDate(notintrested.callId)
                    .then((response) => {
                        this.followups = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getFollowup: function (data) {
            this.resetFollowupUpdate();
            this.updateFor = data;
            // console.log("agentId",this.updateFor.agent.agentId);
            // console.log("updateFor",this.updateFor);
            this.getData();
        },

        resetFollowupUpdate: function () {
            this.closed = null;
            this.$nextTick(() => {
                this.enquiryAs = {
                    remark: "",
                    followupDate: "",
                };
                // this.$v.closed.$reset();
            });

            // this.followup = {
            //   followupDate: null,
            //   remark: null,
            // };
            // this.closed = null;
            // this.$nextTick(() => {
            //   this.$v.followup.$reset();
            //   this.$v.closed.$reset();
            //   // this.$v.followup.errors.$clear();
            // });
        },

        resetForm() {
            this.followup = {
                followupDate: "",
                remark: "",
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        followupUpdate: function (callId, notintrestedId) {
            this.getNotintrested(callId, notintrestedId);
            this.$v.followup.$touch();
            if (this.$v.followup.$invalid) {
                this.submitStatus = "FAIL";
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "select next followup Date and time",
                });
            } else {
                // if (this.updateFor.type == "call") {
                this.disabledButton = true;
                return new Promise((resolve, reject) => {
                    // NotintrestedsService.updateStatusAsClosed(this.agentId, this.notintrestedId)
                    NotintrestedsService.callFollowupUpdate(
                            this.agentId,
                            this.callId,
                            this.followup
                        )
                        .then((response) => {
                            this.$swal(response.data);
                            this.$refs.modal.hide();
                            // this.resetFollowupUpdate();
                            // this.getAllFollowups();
                            this.getCount();
                            // this.getAllExpiredFollowups();
                            // this.getExpiredCount();
                            this.loading = false;
                            resolve(response);
                            this.disabledButton = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },

        getData: function () {
            // console.log(this.updateFor);
            // if (this.updateFor.type == "call") {
            return new Promise((resolve, reject) => {
                FollowupService.getFollowupDateRemark(this.updateFor.id)
                    .then((response) => {
                        // console.log("getData", response.data);
                        this.view = {
                            name: this.updateFor.name,
                            contact: this.updateFor.contact,
                            alternativeContact: this.updateFor.alternativeContact,
                            age: this.updateFor.age,
                            qualification: this.updateFor.qualification,
                            course: this.updateFor.course.name,
                            source: this.updateFor.source.name,
                            mode: this.updateFor.mode,
                            branch: this.updateFor.branch.name,
                        };
                        this.followupDateRemark = response.data;
                        // console.log(this.view);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            // }
            // if (this.updateFor.type == "enquiry") {
            //   // console.log("Enquiry type");
            //   return new Promise((resolve, reject) => {
            //     FollowupService.getEnquiryFollowupDateRemark(this.updateFor.id)
            //       .then((response) => {
            //         this.view = {
            //           name: this.updateFor.name,
            //           contact: this.updateFor.contact,
            //           alternativeContact: this.updateFor.alternativeContact,
            //           age: this.updateFor.age,
            //           qualification: this.updateFor.qualification,
            //           course: this.updateFor.course.name,
            //           source: this.updateFor.source.name,
            //           mode: this.updateFor.mode,
            //           branch: this.updateFor.branch.name,
            //         };
            //         this.followupDateRemark = response.data;
            //         resolve(response);
            //       })
            //       .catch((err) => {
            //         reject(err);
            //       });
            //   });
            // }
        },

        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                // AgentService.getAllCounsellorAgents()
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            // console.log("switchPage calling");
            if (this.paginationNotintresteds) {
                // console.log(this.paginationNotintresteds, "paginationNotintresteds");
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortNotintresteds) {
                // console.log(this.allSortNotintresteds, "allSortNotintresteds");
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllSearchPageable(
                    currentPage,
                    pageCount,
                    this.keyword.toLowerCase()
                );
                // console.log(this.searchPagination, "searchPagination");
            }
        },
        getCount: function () {
            this.loading = true;
            this.allSortNotintresteds = false;
            this.paginationNotintresteds = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getNotintrestedCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("this.totalCount", this.totalCount);
                        this.getAllPageable(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getAllNotintrestedPageable(
                        currentPage,
                        pageCount
                    )
                    .then((response) => {
                        this.allNotintresteds = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getDateRangeReset: function () {
            this.$refs.dateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },

        getSearchCount: function (keyword) {
            this.loading = true;
            this.searchPagination = true;
            this.paginationNotintresteds = false;
            this.allSortNotintresteds = false;
            this.currentPage = 1;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("this.totalCount", this.totalCount);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            this.keyword.toLowerCase()
                        );
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                AdminNotintrestedsService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        this.allNotintresteds = response.data;
                        // console.log("this.totalCount", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        refresh: function () {
            this.getCount();
            this.selectedCourse = "Course";
            this.selectedMode = "mode";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            // this.pageCount = "5";
            this.keyword = "";
        },
    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}
#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}
.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation:spin 0.5s linear infinite;
    animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.dateCell {
    /* min-width: 80px; */
    max-width: 125px;
}
.cellSize {
    min-width: 125px;
}
</style>
