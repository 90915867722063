<template>
  <div class="Dashboard">
    <b-container class="bv-example-row mt-5">
      <b-row class="text-center mb-5">
        <div class="col-xs-8">
          <input type="search" class="form-control searchbar " placeholder="    Search Knowledge Base" />
        </div>
      </b-row>

      <b-row>
        <b-col col lg="3">
          <div class="crd">
            <b-card>
              <b-card-text>
                <img src="admission.png" height="100" alt="" /><br />
                ADMISSION
              </b-card-text>
            </b-card>
          </div>
        </b-col>
        <b-col col lg="3">
          <div class="crd">
            <b-card>
              <b-card-text>
                <img
                  src="enquiry.png"
                  height="100"
                  alt=""
                /><br />
                ENQUIRY
              </b-card-text>
            </b-card>
          </div>
        </b-col>
        <b-col col lg="3">
          <div class="crd">
            <b-card>
              <b-card-text>
                <img
                  src="calls.png"
                  height="100"
                  alt=""
                /><br />
                CALLS
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row class="mt-4">
        <b-col col lg="3">
          <div class="crd">
            <b-card>
              <b-card-text>
                <img
                  src="insights.png"
                  height="100"
                  alt=""
                /><br />
                INSIGHTS
              </b-card-text>
            </b-card>
          </div>
        </b-col>
        <b-col col lg="3">
          <div class="crd">
            <b-card>
              <b-card-text>
                <img
                  src="callFlow.png"
                  height="100"
                  alt=""
                /><br />
                CALL FLOW
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Dashboard",

  components: {
  },

  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.searchbar {
  width: 73%;
  border-radius: 26px;
  border: 1px solid #ada9a9;
  padding: 1% 5%;
  margin: 1% 1%;
  background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
  background-size: contain;
  background-repeat: no-repeat;
  outline: 6;
  background-position: 12px;
  background-size: 16px;
}
.card {
  border: 1px solid rgba(0, 118, 148, 0.87);
}
a {
    color: #000;
    text-decoration: none;
}
</style>
