var oauthServerLocation = "https://auth-encore.examsdaily.in";
//  var oauthServerLocation = 'http://localhost:9088';
//  var oauthServerLocation = 'http://51.15.69.75:9088';

import axios from 'axios'
import btoa from "btoa";
export default {
    bulkUserVerify: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("admin/userDetailsVerify", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    bulkUserCreate: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("admin/userDetailsCreate", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    teamAdminCreate: function(team) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("team/admin/create", team)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    teamUserCreate: function(user) {
        let data = {
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            contact: user.contact,
            mobile: user.mobile,
            role: user.role,
            teamId: localStorage.getItem('tenant-uuid')
        }
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("admin/user/create", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    bulkTeamUserCreate: function(user) {
        console.log("=====User============",user);
        let data = {
            // firstName: user.firstName,
            // lastName: user.lastName,
            // username: user.username,
            // contact: user.contact,
            // mobile: user.mobile,
            // role: user.role,
            // office: user.office,
            teamId: localStorage.getItem('tenant-uuid')
        }
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("admin/userDetails", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // employeePasswordChange(userId) {
    //     var authAxios = axios.create({
    //         baseURL: oauthServerLocation,
    //     });
    //     return new Promise((resolve, reject) => {
    //         // authAxios({
    //         //     method: 'post',
    //         //     url: '/admin/updatePassword',
    //         // })
    //         authAxios
    //         .post("admin/updatePassword/"+ userId)
    //         .then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },

    // employeePasswordChange(data) {
    //     var authAxios = axios.create({
    //         baseURL: oauthServerLocation,
    //     });
    //     return new Promise((resolve, reject) => {
    //         authAxios({
    //             method: 'post',
    //             url: '/admin/updatePassword',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             data: {
    //                 userId: data.userId,
    //                 // firstName: data.firstName,
    //                 // lastName: data.lastName,
    //                 // mobile: data.mobile
    //             },
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },

    employeePasswordSend(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });

        // console.log("======Data======",data);
        return new Promise((resolve, reject) => {
            // authAxios({
            //     method: 'post',
            //     url: '/admin/updatePassword',
            // })
            authAxios
            .post("admin/updatePasswordSend/"+ data.userId,data)

            // authAxios({
            //     method: 'post',
            //     url: 'admin/updatePasswordSend/'+ data.userId,
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     data: {
            //         userId: data.userId,
            //         firstName: data.firstName,
            //         lastName: data.lastName,
            //         mobile: data.mobile
            //     },
            // })
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    telecallerAndUserRoleCreate: function(telecaller) {
        let data = {
            firstName: telecaller.firstName,
            lastName: telecaller.lastName,
            username: telecaller.username,
            contact: telecaller.contact,
            teamId: localStorage.getItem('tenant-uuid')
        }
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .post("admin/telecaller/create", data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getTeam(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let data = {
            userId: userId
        }
        return new Promise((resolve, reject) => {
            authAxios.post("team/get/team", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    userAddedTeam(teamId,userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let data = {
            teamId: teamId,
            userId: userId
        }
        return new Promise((resolve, reject) => {
            authAxios.post('admin/team/user/add',data)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
        });
    },
    userRemoveTeam(teamId,userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let data = {
            teamId: teamId,
            userId: userId
        }
        return new Promise((resolve, reject) => {
            authAxios.post('admin/team/user/remove',data)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
        });
    },
    getUserByUserId: function (userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let data = {
            userId: userId
        }
        return new Promise((resolve, reject) => {
            authAxios({
                    method: "post",
                    url: "admin/find/user",data,
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getUserAccessToken(username) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/get/token/' + username,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    userTokenRevoke(accessToken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token/revokeById/' + accessToken,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getUserInfo: function (contact) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                    method: "get",
                    url: "manage/user/find/" + contact,
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    teamUserDelete: function(teamId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let data = {
            teamId: teamId
        }
        return new Promise((resolve, reject) => {
            authAxios.post("/team/delete/user",data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    addSelectedUserRoles(userId, data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'role/add/user/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    roles: data.roles,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    removeSelectedUserRoles(userId, data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'role/remove/user/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    roles: data.roles,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    googleStatus: function(gtoken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .get("account/google/status/" + gtoken)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    googleTapStatus: function(gtoken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .get("account/googletap/status/" + gtoken)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    googleRegister: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            authAxios
                .post("account/google", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    googleTapRegister: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            authAxios
                .post("account/googletap", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    faceBookStatus: function(ftoken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
                .get("account/facebook/status/" + ftoken)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    faceBookRegister: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            authAxios
                .post("account/facebook", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    faceBookMobile: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            authAxios
                .post("account/facebook/mobile", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    fbMobile: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            authAxios
                .post("account/facebookMobile", data, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    setContact: function(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/contact',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: data.contact,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    login(user) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token',
                headers: {
                    'Authorization': 'Basic ' + btoa("barClientIdPassword:password"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    'grant_type': 'password',
                    'username': user.contact,
                    'password': user.password
                }
            }).then((response) => {
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    userLogin(user) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token',
                headers: {
                    'Authorization': 'Basic ' + btoa("barClientIdPassword:password"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    'grant_type': 'password',
                    'username': user.contact,
                    'password': user.password
                }
            }).then((response) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    register(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/register',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: data.name,
                    contact: data.contact,
                    password: data.password,
                    mobile: data.mobile,
                    registerMode: "DIRECTUSER"
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    checkUser(contact) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/userCheck',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: contact,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    contactCheck(contact) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/contactCheck',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: contact,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    checkUserPassword(password) {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/userCheckPassword',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    password: password,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    checkUsername(username) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/userCheck',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: username,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    userRegister(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/userRegister',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: data.username,
                    contact: data.contact,
                    firstName: data.firstName,
                    lastName: data.lastName
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllRoles() {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/role/getAll',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    sendRequest(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/manage/tutor/sendRequest',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: data.contact,
                    userInstructorId: data.userInstructorId,
                    instituteName: data.instituteName,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    userRoleRequest(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/role/sendRequest',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: data.contact,
                    userId: data.userId,
                    userName: data.userName,
                    roleId: data.roleId,
                    roleName: data.roleName
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    bulkImport(dataItems) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/register/bulk',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: dataItems,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllTeamUserCount(teamId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            let data = {
                teamId: teamId
            }
            authAxios({
                method: 'post',
                url: 'manage/user/teamUser/count',data,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllTeamUserPageable(pageCount, currentPage, teamId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            let data = {
                teamId: teamId
            }
            authAxios({
                method: 'post',
                url: 'manage/user/teamUser/pageable/' + currentPage + '/' + pageCount,data,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllUserCount() {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/all/count',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllUserPageable(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/all/pageable/' + (currentPage - 1) + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // totalUsers() {
    //     var authAxios = axios.create({
    //         baseURL: oauthServerLocation,
    //     });
    //     return new Promise((resolve, reject) => {
    //         authAxios({
    //             method: 'get',
    //             url: 'manage/user/all',
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });

    // },
    /*usersMail(productName, permaLink) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/packReleaseMail/' + productName,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    productName: productName,
                    permaLink: permaLink
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });

    },*/

    getTodayUsersCount() {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/todayCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });

    },
    getThisMonthUsersCount() {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/thisMonthCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },


    getLastMonthUsersCount() {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastMonthCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastOneHourUsersCount() {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastOneHourCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastTfHourUsersCount() {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastTfHourCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getSevenDaysUsersCount() {

        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/sevenDaysCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    addUserRoles(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'admin/user/role/add',data,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // addSelectedUserRoles(userId, data) {
    //     var authAxios = axios.create({
    //         baseURL: oauthServerLocation,
    //     });
    //     return new Promise((resolve, reject) => {
    //         authAxios({
    //             method: 'post',
    //             url: 'role/add/user/' + userId,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             data: {
    //                 roles: data.roles,
    //             },
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },

    acceptUserRoles(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'role/acceptByUser',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    roleId: data.roleId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    rejectUserRoles(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'role/rejectByUser',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    roleId: data.roleId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    removeUserRoles(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'admin/user/role/remove',data,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getUserAddedRoles(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'role/added/user/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getUserNotAddedRoles(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'role/notAdded/user/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getTodayUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/today/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getThisMonthUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/thisMonth/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastMonthUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastMonth/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastOneHourUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastOneHour/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastTfHourUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastTfHour/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getLastSevenDaysUsers(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/lastSevDays/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getCustomizeUsers(data, pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'Post',
                url: 'manage/user/findUser/' + currentPage + '/' + pageCount,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    fromDate: data.fromDate,
                    toDate: data.toDate
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    teamUserSearch(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/team/search',
                data: {
                    contact: data,
                    teamId: localStorage.getItem('tenant-uuid')
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    teamUserSearchByName(keyword) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        this.teamId= localStorage.getItem('tenant-uuid')
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/searchByName/'+ this.teamId + "/" + keyword,
                // data: {
                //     contact: data,
                //     teamId: localStorage.getItem('tenant-uuid')
                // },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    teamUserSearchByContact(keyword) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        this.teamId= localStorage.getItem('tenant-uuid')
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/searchByContact/'+ this.teamId + "/" + keyword,
                // data: {
                //     contact: data,
                //     teamId: localStorage.getItem('tenant-uuid')
                // },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    allUserSearch(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/all/search',
                data: {
                    contact: data,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // searchUser(data, pageCount, currentPage) {
    //     var authAxios = axios.create({
    //         baseURL: oauthServerLocation,
    //     });
    //     return new Promise((resolve, reject) => {
    //         authAxios({
    //             method: 'post',
    //             url: 'manage/user/userSearch/' + currentPage + '/' + pageCount,
    //             data: {
    //                 username: data.username,
    //             },
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },

    getTutors(pageCount, currentPage) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/allTutors/' + currentPage + '/' + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    totalTutors() {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/allTutorsCount',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });

    },
    getAllUsers() {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/find/all',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    activateUser(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/activate/' + userId,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    deactivateUser(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/deactivate/' + userId,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    deleteSelectedUser(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'manage/user/delete/selected',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    users: data.users,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    deleteUser(userId) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'manage/user/delete/' + userId,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAccessToken(refresh_token) {
        // console.log("=======refresh_token========",refresh_token);
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token',
                headers: {
                    'Authorization': 'Basic ' + btoa("barClientIdPassword:password"),
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: {
                    'grant_type': 'refresh_token',
                    'client_id': 'barClientIdPassword',
                    'refresh_token': refresh_token
                }
            }).then((response) => {
                localStorage.setItem('accessToken',response.data.access_token)
                localStorage.setItem('refreshToken',response.data.refresh_token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid');
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    passwordReset(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'put',
                url: 'account/password/reset',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    contact: data.contact,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getContactByToken(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'account/password/reset?token=' + data.token,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    changePassword(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/password/reset',
                data: {
                    confirmationToken: data.token,
                    password: data.password
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    nameUpdate(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'setting/profile',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    mobile: data.mobile
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    resetPassword(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'setting/security',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    oldPassword: data.oldPassword,
                    newPassword: data.newPassword
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    contactUpdate(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'setting/contact',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    contact: data.contact
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getUserDetails(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: 'account/tokenVerify?token=' + data.token,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getTutorDetails(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'get',
                url: '/manage/tutor/tokenVerify?token=' + data.token,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getInstituteDetails(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/manage/tutor/get/contactDetail',
                data: {
                    contact: data,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    updateTutorDetail(userId, cToken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/manage/tutor/updateUser',
                data: {
                    userId: userId,
                    confirmationToken: cToken,

                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    acceptRequest(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: '/manage/tutor/acceptTutor',
                data: {
                    userId: data.userId,
                    contact: data.contact,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    instituteName: data.instituteName
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    setPassword(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/tokenVerify',
                data: {
                    verificationToken: data.verificationToken,
                    contact: data.contact,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    otpCheck(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/otpCheck',
                data: {
                    verifyId: data.verifyId,
                    generatedOtp: data.generatedOtp,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    removeUser(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'contact/removeUser',
                data: {
                    userId: data.userId,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    otpVerify(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/otpVerify',
                data: {
                    verifyId: data.verifyId,
                    generatedOtp: data.generatedOtp,
                    contact: data.contact,
                    username: data.username,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    password: data.password
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    mobilePasswordCheck(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/mobilePassword/otpCheck',
                data: {
                    contact: data.contact,
                    generatedOtp: data.generatedOtp,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    mobilePasswordReset(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/mobilePassword/otpVerify',
                data: {
                    'password': data.password,
                    'userId': data.userId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getRank(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'test/rank',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    testId: data.testId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    menuInsert(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'menu/create',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    menuName: data.name,
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    reportSend(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/reportSend',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    logout(accessToken) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'oauth/token/revokeById/' + accessToken,
            }).then((response) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ';
                axios.defaults.headers.common["tenant-uuid"] = '';
                localStorage.removeItem("tenant-uuid");
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    settingPassword(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'account/setPassword',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: data.username,
                    newPassword: data.newPassword
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    sendOtp(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'contact/mobile',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    userId: data.userId,
                    contact: data.contact
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    otpValidate(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'post',
                url: 'contact/otpverify',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    generatedOtp: data.generatedOtp,
                    verifyId: data.verifyId
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSortingUsers(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios({
                method: 'Post',
                url: 'manage/user/sortingUserData',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    fromDate: data.fromDate,
                    toDate: data.toDate
                },
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    employeeDeactivate(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
            .post("admin/disableUser/"+ data.userId,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    employeeActivate(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
            .post("admin/enableUser/"+ data.userId,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getUserData(data) {
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios
            .post("admin/getUser/"+ data.userId,data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    UserTeamMigration: function(userId,data){
        var authAxios = axios.create({
            baseURL: oauthServerLocation,
        });
        return new Promise((resolve, reject) => {
            authAxios.post("/admin/teamMigration/"+userId, data)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },

};