import axios from 'axios';
export default{
    getContact: function(contact){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/call/contact/' + contact,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getalternativeContact: function(alternativeContact){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/call/alternativeContact/' + alternativeContact,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getDateRange: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/call/sort/dateRange/'+ agentId,data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getClosedDateRange: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/call/sort/closedDateRange/'+ agentId,data)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    saveAllCustomFields(data) {
       
        return new Promise((resolve, reject) => {
            axios.post("/call/template/create",data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getFields() {
       
        return new Promise((resolve, reject) => {
            axios.get("/call/template/get/current")
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getByDate: function (agentId,selectedDates) {
        return new Promise((resolve, reject) => {
            axios
                .post('/call/sort/date/'+ agentId +"/"+selectedDates)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getDateCount: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/call/sortDate/count/'+ agentId ,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllDatePageable: function (currentPage, pageCount,agentId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/call/sortDate/all/' + (currentPage - 1) + "/" + pageCount  + "/" + agentId , data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // getDateCount: function (agentId, selectedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/call/datesort/count/' + agentId +"/"+ selectedDates)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllDatePageable: function (currentPage, pageCount, agentId,selectedDates, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/call/datesort/all/' + (currentPage - 1) + "/" + pageCount + "/" + agentId + "/" +selectedDates, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },

    getByClosedDate: function (agentId, selectedClosedDates) {
        return new Promise((resolve, reject) => {
            axios.post('/call/sort/closedDate/' + agentId +"/"+ selectedClosedDates)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getDateClosedCount: function (agentId,data) {
        return new Promise((resolve, reject) => {
            axios.post('/call/sortClosedDate/count/'+ agentId ,data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    getAllClosedDatePageable: function (closedCurrentPage, closedPageCount,agentId, data) {
        return new Promise((resolve, reject) => {
            axios.post('/call/sortClosedDate/all/' + (closedCurrentPage - 1) + "/" + closedPageCount  + "/" + agentId , data)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },
    // getDateClosedCount: function (agentId,selectedClosedDates) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .get('/call/sort/closedDate/count/'+ agentId +"/"+selectedClosedDates)
    //             .then(response => {
    //                 resolve(response);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getAllClosedDatePageable: function (closedCurrentPage, closedPageCount, agentId,selectedClosedDates, data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post('/call/closed/datesort/all/' + (closedCurrentPage - 1) + "/" + closedPageCount + "/" + agentId + "/" +selectedClosedDates, data)
    //             .then((response) => {
    //                 resolve(response);
    //             }).catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    // getUContact: function(callId,alternativeContact,contact){
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             method: 'get',
    //             url: '/call/contact/' + callId + "/" + alternativeContact + "/" + contact
    //         }).then((response) => {
    //             resolve(response);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },
    getUpdateContact: function(callId,contact){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/call/updatecontact' ,callId,contact
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    putCall: function(agentId,call){
        return new Promise((resolve, reject) => {
            axios.post("/call/insert/"+ agentId,call)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAllCalls: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get("/call/getAgentCalls/"+agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    deleteCall: function(callId){
        return new Promise((resolve, reject) => {
            axios.get('/call/delete/'+ callId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    // getCall: function(callId){
    //     return new Promise((resolve, reject) => {
    //         axios.get('/call/get/'+ callId )
    //         .then((response) => {
    //             resolve(response);
    //         })
    //         .catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },
    getCall: function(callId){
        return new Promise((resolve, reject) => {
            axios.get('/call/get/'+ callId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    updateCall: function(agentId,callId,update){
        return new Promise((resolve, reject) => {
            axios.post("/call/update/"+agentId+"/"+callId, update)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    callFollowupUpdate: function(agentId,callId,callFollowup){
        return new Promise((resolve, reject) => {
            axios.post("/call/followup/update/"+agentId+"/"+callId, callFollowup)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    archive: function(call){
        return new Promise((resolve, reject) => {
            axios.post("/call/archive/"+ call)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    closedArchive: function(call){
        return new Promise((resolve, reject) => {
            axios.post("/call/closedCalls/archive/"+ call)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    unArchive: function(call){
        return new Promise((resolve, reject) => {
            axios.post("/call/unArchive/"+ call)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get("/call/count/" + agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.post("/call/all/" + (currentPage-1) +"/"+ pageCount +"/"+ agentId)
            .then((response) => {

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    closedGetCount: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get('/call/closedCount/'+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    closedGetAllPageable: function(closedCurrentPage,closedPageCount,agentId){
        return new Promise((resolve, reject) => {
            axios.get('/call/closedAll/'+ (closedCurrentPage-1) + "/" + closedPageCount +"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllClosed: function(agentId){
        return new Promise((resolve, reject) => {
            axios.get("/call/closedCalls/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchCall: function(keyword,agentId){
        return new Promise((resolve, reject) => {
            axios.get('/call/search/'+keyword+"/"+ agentId)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchCallclosed: function(closedKeyword,agentId){
        return new Promise((resolve, reject) => {
            axios.get('/call/searchclosed/'+ closedKeyword +"/"+ agentId)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

//sort call pagination
    getCourseCount: function(agentId,course){
        return new Promise((resolve, reject) => {
            axios.post('/call/courseSort/count/'+ agentId,course)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllCoursePageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/call/courseSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSourceCount: function(agentId,source){
        return new Promise((resolve, reject) => {
            axios.post('/call/sourceSort/count/'+ agentId,source)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllSourcePageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/call/sourceSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getBranchCount: function(agentId,branch){
        return new Promise((resolve, reject) => {
            axios.post('/call/branchSort/count/'+ agentId,branch)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllBranchPageable: function(currentPage,pageCount,agentId,data){
        return new Promise((resolve, reject) => {
            axios.post('/call/branchSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

//sort closed call pagination
getClosedCourseCount: function(agentId,course){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/courseSort/count/'+ agentId,course)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllClosedCoursePageable: function(currentPage,pageCount,agentId,data){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/courseSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getClosedSourceCount: function(agentId,source){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/sourceSort/count/'+ agentId,source)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllClosedSourcePageable: function(currentPage,pageCount,agentId,data){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/sourceSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getClosedBranchCount: function(agentId,branch){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/branchSort/count/'+ agentId,branch)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllClosedBranchPageable: function(currentPage,pageCount,agentId,data){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/branchSort/all/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId, data)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

//Call search pagination
getCallSearchCount: function(agentId,keyword){
    return new Promise((resolve, reject) => {
        axios.post('/call/search/count/'+ agentId + "/" + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllCallSearchPageable: function(currentPage,pageCount,agentId,keyword){
    return new Promise((resolve, reject) => {
        axios.post('/call/search/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId +"/" + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
//closed Call search pagination
getClosedCallSearchCount: function(agentId,closedKeyword){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/search/count/'+ agentId + "/" + closedKeyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllClosedCallSearchPageable: function(currentPage,pageCount,agentId,closedKeyword){
    return new Promise((resolve, reject) => {
        axios.post('/call/closed/search/'+ (currentPage-1) + "/" + pageCount +"/"+ agentId +"/" + closedKeyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

}
