<template>
<div>
    <b-container class="bv-example-row mt-5">
        <div class="px-2">
            <b-row class="Settings">
                <b-col sm="12" lg="6">
                    <div class="crd">
                        <router-link to="/settings/user">
                            <b-card>
                                <b-card-text>
                                    <img src="user.png" height="100" alt="" /><br />
                                    USER
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="12" lg="6">
                    <div class="crd">
                        <router-link to="settings/customfield">
                            <b-card>
                                <b-card-text>
                                    <img src="custom.png" height="100" alt="" /><br />
                                  CUSTOM FIELDS
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="px-2 pt-2 mt-2">
            <b-row class="Settings">
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/agent">
                            <b-card>
                                <b-card-text>
                                    <img src="agent1.png" height="100" alt="" /><br />
                                    AGENT
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/branch">
                            <b-card>
                                <b-card-text>
                                    <img src="branch.png" height="100" alt="" /><br />
                                    BRANCH
                                    <!-- https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-and-shapes-6/177800/253-512.png -->
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/course">
                            <b-card>
                                <b-card-text>
                                    <img src="course.jpg" height="100" alt="" /><br />
                                    COURSE
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/source">
                            <b-card>
                                <b-card-text>
                                    <img src="source.png" height="100" alt="" /><br />
                                    SOURCE
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="px-2 pt-2">
            <b-row class="Settings mt-2">
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/tutor">
                            <b-card>
                                <b-card-text>
                                    <img src="tutor.png" height="100" alt="" /><br />
                                    TUTOR
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/batch">
                            <b-card>
                                <b-card-text>
                                    <img src="Batch.png" height="100" alt="" /><br />
                                    BATCH
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/syllabus">
                            <b-card>
                                <b-card-text>
                                    <img src="Syllabus.png" height="100" alt="" /><br />
                                    SYLLABUS
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/classReport">
                            <b-card>
                                <b-card-text>
                                    <img src="report(1).png" height="100" alt="" /><br />
                                    CLASS REPORT
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="px-2 pt-2">
            <b-row class="Settings mt-2">
                <b-col sm="3">
                    <div class="crd">
                        <router-link to="settings/bookbundle">
                            <b-card>
                                <b-card-text>
                                    <img src="bookbundle.png" height="100" alt="" /><br />
                                    Book Bundle
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <!-- <b-col sm="6" lg="3"> -->
                    <b-col sm="3">
                    <div class="crd">
                        <router-link to="settings/campaign">
                            <b-card>
                                <b-card-text>
                                    <img src="campaign.png" height="100" alt="" /><br />
                                    CAMPAIGN
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <!-- <b-col sm="6" lg="3"> -->
                    <b-col sm="3">
                    <div class="crd">
                        <router-link to="settings/leads">
                            <b-card>
                                <b-card-text>
                                    <img src="leads.png" height="100" alt="" /><br />
                                    LEADS
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <!-- <b-col sm="6" lg="3"> -->
                    <b-col sm="3">
                    <div class="crd">
                        <router-link to="settings/office">
                            <b-card>
                                <b-card-text>
                                    <img src="calendar.png" height="100" alt="" /><br />
                                    Office Attendance
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col>
                <!-- <b-col sm="6" lg="3">
                    <div class="crd">
                        <router-link to="settings/allAttendancePage">
                            <b-card>
                                <b-card-text>
                                    <img src="allCheck.png" height="100" alt="" /><br />
                                    All Attendance
                                </b-card-text>
                            </b-card>
                        </router-link>
                    </div>
                </b-col> -->
            </b-row>
        </div>
    </b-container>
</div>
</template>

<script>
export default {
    name: "Settings",

    components: {},

    mounted() {},

    methods: {
        
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.card {
    /* border: none; */
}

.card:hover {
    /* border: 1px solid rgba(0, 118, 148, 0.87); */
    /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

}

a {
    color: #000;
    text-decoration: none;
}

/* .top {
    margin-top: 7rem;
} */
</style>
