<template>
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<div>
    <b-container>
    <!-- <h6>BATCH</h6> -->
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container>
        <!-- <div>
            <b-row class="my-4 pt-3">
                <b-col col lg="4" style="text-align: center">
                    <b-button id="agents" size="sm" variant="outline-primary" @click="batchShow()" v-bind:class="{ active: batchActive }">Batch</b-button>
                </b-col>
                <b-col col lg="4" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="finishedBatchShow()" v-bind:class="{ active: finishedActive }">Finished Batch</b-button>
                </b-col>
                <b-col col lg="4" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="archivedBatchShow()" v-bind:class="{ active: archivedActivate }">Archived Batch</b-button>
                </b-col>
            </b-row>
        </div> -->
        <!--popup start for Batch Register-->
        <b-modal id="addBatch" title="Batch" hide-footer ref="modal" no-close-on-backdrop>
            <div class="form-row">
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Syllabus:</label>
                        <b-form-select class="form-control" v-model="batch.syllabus" :class="{
                  'is-invalid': $v.batch.syllabus.$error,
                  'is-valid': !$v.batch.syllabus.$invalid,
                }">
                            <option value="null" disabled>Select Syllabus</option>
                            <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Batch Code:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.batch.code.$model" :class="{
                  'is-invalid': $v.batch.code.$error,
                  'is-valid': !$v.batch.code.$invalid,
                }" placeholder="Enter your Code">
                        </b-form-input>
                        <div class="valid-feedback">Your Code is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batch.code.required">Code is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Batch Type: </label>
                        <!-- <b-button class="text-center float-end" variant="outline-primary" size="sm" @click="batchtTypeCreate = true">Create</b-button> -->
                        <b-form-select class="form-control" v-model="batch.batchType" :class="{'is-invalid': $v.batch.batchType.$error,'is-valid': !$v.batch.batchType.$invalid,}">
                            <option value="null" disabled>Select Batch Type</option>
                            <option v-for="item in allBatchType" :value="item.type" v-bind:key="item.index">
                                <strong>{{ item.type }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="!batchtTypeCreate">
                    <div class="form-group my-2">
                        <b-button class="text-center" variant="outline-primary" size="sm" @click="batchtTypeCreate = true">Create</b-button>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                    <div class="form-group my-2">
                        <!-- <label>Batch Type:</label> -->
                        <b-form-input type="text" class="form-control" v-model.trim="$v.batchType.$model" :class="{
                  'is-invalid': $v.batchType.$error,
                  'is-valid': !$v.batchType.$invalid,
                }" placeholder="Enter batch type">
                        </b-form-input>
                        <div class="valid-feedback">Batch Type is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batchType.required">Batch Type is required</span>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                    <div class="form-group my-2">
                        <b-button class="text-center" variant="outline-primary" size="sm" @click="createBatchType()">Add</b-button>
                        <b-button class="text-center mx-2" variant="outline-danger" size="sm" @click="resetBatchType()">cancel</b-button>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>StudentCount:</label>
                        <b-form-input type="number" class="form-control" v-model.trim="$v.batch.studentCount.$model" :class="{
                  'is-invalid': $v.batch.studentCount.$error,
                  'is-valid': !$v.batch.studentCount.$invalid,
                }" placeholder="Enter your StudentCount">
                        </b-form-input>
                        <div class="valid-feedback">Your StudentCount is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batch.studentCount.required">StudentCount is required</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch:</label>
                        <b-form-select class="form-control" v-model="batch.branch" :class="{
                  'is-invalid': $v.batch.branch.$error,
                  'is-valid': !$v.batch.branch.$invalid,
                }">
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Start Date:</label>
                        <div>
                            <b-form-input format="DD-MM-YYYY" v-model="batch.startDate" :class="{
                    'is-invalid': $v.batch.startDate.$error,
                    'is-valid': !$v.batch.startDate.$invalid,
                  }" type="date"></b-form-input>
                            <div class="valid-feedback">date time is valid!</div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.batch.startDate.required">date or time is invalid!</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Batch Timing:</label>
                        <br />
                        <label>From:</label>
                        <!-- <b-form-timepicker v-model="batch.startTime" :class="{
                  'is-invalid': $v.batch.startTime.$error,
                  'is-valid': !$v.batch.startTime.$invalid,
                }" locale="en"></b-form-timepicker> -->
                        <b-form-input v-model="batch.startTime" :class="{
                    'is-invalid': $v.batch.startTime.$error,
                    'is-valid': !$v.batch.startTime.$invalid,
                  }" type="time"></b-form-input>
                        <div class="valid-feedback">Start Time is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batch.startTime.required">Start Time invalid!</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>To:</label>
                        <!-- <b-form-timepicker v-model="batch.endTime" :class="{
                  'is-invalid': $v.batch.endTime.$error,
                  'is-valid': !$v.batch.endTime.$invalid,
                }" locale="en"></b-form-timepicker> -->
                        <b-form-input v-model="batch.endTime" :class="{
                    'is-invalid': $v.batch.endTime.$error,
                    'is-valid': !$v.batch.endTime.$invalid,
                  }" type="time"></b-form-input>
                        <div class="valid-feedback">End Time is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batch.endTime.required">End Time invalid!</span>
                        </div>
                    </div>
                </div>
                <br />
                <div class="text-center">
                    <b-button variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,putBatch()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!--popup end for Batch Register-->
    </b-container>

    <!-- <b-button
              size="sm"
              variant="outline-primary"
              class="bnclr"
              v-b-modal.addBatch
              @click="resetForm()"
              >Add Batch</b-button
            > -->

    <!-- Batch Table Start -->
    <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Batch" @click="batchShow()">
    <!-- <div v-if="batchActive"> -->
        <!-- <div class="mt-5"> -->
            <div>
            <b-container>
                <b-row align-v="center">
                    <!--add Batch button start-->

                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBatch @click="resetForm()">Add Batch</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <p>Batch</p>
                    <hr />
                    <b-table responsive hover :items="batches" :fields="fields" :current-page="currentPage" :per-page="batches.pageCount" ref="table">
                        <!-- <template v-slot:cell(code)="data">
                        <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report">
                            {{ data.item.code }}
                        </span>
                    </template> -->
                        <template v-slot:cell(code)="data">
                            {{ data.item.code }}
                        </template>
                        <template v-slot:cell(syllabus)="data">
                            {{ data.item.syllabus.name }}
                        </template>
                        <template v-slot:cell(studentCount)="data">
                            {{ data.item.studentCount }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" v-b-modal.viewBatch @click="getBatch(data.item)" size="sm" class="mx-1">
                                <font-awesome-icon icon="eye" />
                            </b-button>

                            <!-- <b-button
                variant="danger"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Batch Details"
                @click="deleteBatch(data.item.batchId)"
              ><font-awesome-icon icon="trash" />
              </b-button> -->

                            <b-button size="sm" class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBatch(data.item)">
                                <font-awesome-icon icon="edit" />
                            </b-button>
                            <b-button size="sm" class="mx-1" v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button>
                            <!-- <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Batch Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button> -->
                            <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="View Batch Report" @click="batchStatus(data.item)">
                                <font-awesome-icon icon="file" />
                            </b-button>
                            <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Batch Complete" @click="batchFinish(data.item)">
                                <font-awesome-icon icon="check" />
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
        </div>
    <!-- </div> -->
    </b-tab>

    <!-- Batch Table End-->

    <!-- Batch Finish Table Start -->
            <b-tab title="Finished Batches" @click="finishedBatchShow()">
    <!-- <div v-if="finishedActive"> -->
        <!-- <div class="mt-5"> -->
            <div>
            <b-container>
                <b-row align-v="center">
                    <!--add Batch button start-->

                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBatch @click="resetForm()" disabled>Add Batch</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getAllFinishedBatchSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllFinishedBatchSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <p>Finished Batch</p>
                    <hr />
                    <b-table responsive hover :items="batches" :fields="fields" :current-page="currentPage" :per-page="batches.pageCount" ref="table">
                        <!-- <template v-slot:cell(code)="data">
                        <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report">
                            {{ data.item.code }}
                        </span>
                    </template> -->
                        <template v-slot:cell(code)="data">
                            {{ data.item.code }}
                        </template>
                        <template v-slot:cell(syllabus)="data">
                            {{ data.item.syllabus.name }}
                        </template>
                        <template v-slot:cell(studentCount)="data">
                            {{ data.item.studentCount }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" v-b-modal.viewBatch @click="getBatch(data.item)" size="sm" class="mx-1">
                                <font-awesome-icon icon="eye" />
                            </b-button>

                            <!-- <b-button
                variant="danger"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Batch Details"
                @click="deleteBatch(data.item.batchId)"
              ><font-awesome-icon icon="trash" />
              </b-button> -->

                            <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBatch(data.item)">
                                <font-awesome-icon icon="edit" />
                            </b-button> -->
                            <!-- <b-button class="mx-1" v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button>
                            <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Batch Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button> -->
                            <b-button size="sm" class="mx-1" variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="View Batch Report" @click="batchStatus(data.item)">
                                <font-awesome-icon icon="file" />
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-container>
        </div>
    <!-- </div> -->
    </b-tab>

    <!-- Batch Finish Table End-->

    <!-- Batch Finish Table Start -->
            <b-tab title="Archived Batches" @click="archivedBatchShow()">
    <!-- <div v-if="archivedActivate"> -->
        <!-- <div class="mt-5"> -->
            <div>
            <b-container>
                <b-row align-v="center">
                    <!--add Batch button start-->

                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBatch @click="resetForm()" disabled>Add Batch</b-button>
                    </b-col>
                    <b-col col sm="12" md="3" lg="3" id="r1">
                        <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getAllArchivedBatchSearchCount(keyword)" placeholder="Type a name" />
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <b-button size="sm" variant="outline-primary" class="bnclr" @click="getAllArchivedBatchSearchCount(keyword)">Search</b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                        <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" class="" id="r1">
                        <label class="font mx-1">Per Page</label>
                        <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col col lg="1" md="1" id="r1" sm="6">
                        <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                            <font-awesome-icon icon="sync-alt" />Refresh</span>
                    </b-col>
                </b-row>
            </b-container>
            <b-container>
                <b-card class="card mt-1">
                    <p>Archived Batch</p>
                    <hr />
                    <b-table responsive hover :items="batches" :fields="fields" :current-page="currentPage" :per-page="batches.pageCount" ref="table">
                        <!-- <template v-slot:cell(code)="data">
                        <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report">
                            {{ data.item.code }}
                        </span>
                    </template> -->
                        <template v-slot:cell(code)="data">
                            {{ data.item.code }}
                        </template>
                        <template v-slot:cell(syllabus)="data">
                            {{ data.item.syllabus.name }}
                        </template>
                        <template v-slot:cell(studentCount)="data">
                            {{ data.item.studentCount }}
                        </template>
                        <template v-slot:cell(branch)="data">
                            {{ data.item.branch.name }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" v-b-modal.viewBatch @click="getBatch(data.item)" size="sm" class="mx-1">
                                <font-awesome-icon icon="eye" />
                            </b-button>

                            <!-- <b-button
                variant="danger"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Batch Details"
                @click="deleteBatch(data.item.batchId)"
              ><font-awesome-icon icon="trash" />
              </b-button> -->

                            <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBatch(data.item)">
                                <font-awesome-icon icon="edit" />
                            </b-button> -->
                            <!-- <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button> -->
                            <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Batch Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.batchId)">
                                <font-awesome-icon icon="archive" />
                            </b-button>
                            <!-- <b-button variant="secondary" v-b-tooltip.hover.bottomright="{ variant: 'secondary' }" title="View Batch Report" @click="batchStatus(data.item)">
                                <font-awesome-icon icon="file" />
                            </b-button> -->
                            <!-- <b-button class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Batch Complete" @click="batchFinish(data.item)">
                                <font-awesome-icon icon="check" />
                            </b-button> -->
                        </template>
                    </b-table>
                </b-card>
            </b-container>
        </div>
    <!-- </div> -->
    </b-tab>
    </b-tabs>

    <!-- Batch Finish Table End-->

    <!-- View Batch Start -->

    <b-container>
        <b-modal id="viewBatch" no-close-on-backdrop hide-footer title="View Batch">
            <div class="form-control">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Syllabus</label>
                        <b-form-select class="form-control" v-model="batch.syllabus" disabled>
                            <option value="null" disabled>Select Syllabus</option>
                            <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Batch Code</label>
                        <b-form-input v-model="batch.code" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Student Count</label>
                        <b-form-input v-model="batch.studentCount" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-form-select class="form-control" v-model="batch.branch" disabled>
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{ item.name }}</strong>
                            </option>
                        </b-form-select>
                    </div>
                </div>

                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Start Date</label>
                        <b-form-input v-model="batch.startDate" class="form-control" disabled></b-form-input>
                    </div>
                </div>

                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Start Time</label>
                        <b-form-input v-model="batch.startTime" class="form-control" disabled></b-form-input>
                    </div>
                </div>

                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>End Time</label>
                        <b-form-input v-model="batch.endTime" class="form-control" disabled></b-form-input>
                    </div>
                </div>

                <br />
            </div>
        </b-modal>
    </b-container>

    <!-- View Batch End-->

    <!-- Update Batch Start -->

    <b-modal id="updateBatch" title="Update Batch" ref="modal" hide-footer>
        <div class="form-row">
            <!-- <div class="form-group"> -->
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Batch Code:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="$v.batch.code.$model" :class="{
                'is-invalid': $v.batch.code.$error,
                'is-valid': !$v.batch.code.$invalid,
              }">
                    </b-form-input>
                </div>
            </div>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Syllabus:</label>
                    <b-form-select class="form-control" v-model="batch.syllabus" :class="{
                'is-invalid': $v.batch.syllabus.$error,
                'is-valid': !$v.batch.syllabus.$invalid,
              }">
                        <option value="null" disabled>Select Syllabus</option>
                        <option v-for="item in allSyllabus" :value="item" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Batch Type:</label>
                    <b-form-select class="form-control" v-model="batch.batchType" :class="{'is-invalid': $v.batch.batchType.$error,'is-valid': !$v.batch.batchType.$invalid,}">
                        <option value="null" disabled>Select Batch Type</option>
                        <option v-for="item in allBatchType" :value="item.type" v-bind:key="item.index">
                            <strong>{{ item.type }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="form-row mt-3 px-5" v-if="!batchtTypeCreate">
                <div class="form-group my-2">
                    <b-button class="text-center" variant="primary" size="sm" @click="batchtTypeCreate = true">Create</b-button>
                </div>
            </div>
            <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                <div class="form-group my-2">
                    <!-- <label>Batch Type:</label> -->
                    <b-form-input type="text" class="form-control" v-model.trim="$v.batchType.$model" :class="{'is-invalid': $v.batchType.$error,'is-valid': !$v.batchType.$invalid,}" placeholder="Enter batch type">
                    </b-form-input>
                    <div class="valid-feedback">Batch Type is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.batchType.required">Batch Type is required</span>
                    </div>
                </div>
            </div>
            <div class="form-row mt-3 px-5" v-if="batchtTypeCreate">
                <div class="form-group my-2">
                    <b-button class="text-center" variant="outline-primary" size="sm" @click="createBatchType()">Add</b-button>
                    <b-button class="text-center mx-2" variant="outline-danger" size="sm" @click="resetBatchType()">cancel</b-button>
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Student Count:</label>
                    <b-form-input type="number" class="form-control" v-model.trim="$v.batch.studentCount.$model" :class="{
                'is-invalid': $v.batch.studentCount.$error,
                'is-valid': !$v.batch.studentCount.$invalid,
              }">
                    </b-form-input>
                </div>
            </div>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Branch</label>
                    <b-form-select class="form-control" v-model="batch.branch" :class="{
                'is-invalid': $v.batch.branch.$error,
                'is-valid': !$v.batch.branch.$invalid,
              }">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </div>
            </div>
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Start Date:</label>
                    <div>
                        <b-form-input format="DD-MM-YYYY" v-model="batch.startDate" :class="{
                    'is-invalid': $v.batch.startDate.$error,
                    'is-valid': !$v.batch.startDate.$invalid,
                  }" type="date"></b-form-input>
                        <div class="valid-feedback">date time is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.batch.startDate.required">date or time is invalid!</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Batch Timing:</label>
                    <br />
                    <label>From:</label>
                    <b-form-input v-model="batch.startTime" :class="{
                    'is-invalid': $v.batch.startTime.$error,
                    'is-valid': !$v.batch.startTime.$invalid,
                  }" type="time"></b-form-input>
                    <div class="valid-feedback">Start Time is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.batch.startTime.required">Start Time invalid!</span>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>To:</label>
                    <b-form-input v-model="batch.endTime" :class="{
                    'is-invalid': $v.batch.endTime.$error,
                    'is-valid': !$v.batch.endTime.$invalid,
                  }" type="time"></b-form-input>
                    <div class="valid-feedback">End Time is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.batch.endTime.required">End Time invalid!</span>
                    </div>
                </div>
            </div>
            <div class="text-center pt-3">
                <b-button size="sm" id="submit" variant="outline-success" :disabled="disabledButton" @click="disabledButton = true,updateBatch()">Update</b-button>
            </div>
            <br />
        </div>
    </b-modal>
    <!-- Update Batch End  -->
    <!-- selectedCourseContentNode -->

    <!-- selected user detailed history -->
    <b-modal id="showBatchStatusModal" scrollable size="lg" hide-footer header-bg-variant="primary" no-close-on-backdrop no-close-on-esc centered v-model="showBatchStatusModal">
        <template slot="modal-header">
            <b-col class="mt-1"><a style="color:white;font-weight:bold;font-size:1.4rem;text-align:center;" v-if="batchDetails!=null">Code : {{batchDetails.code}}</a></b-col>
            <b-col cols="1">
                <button type="button" class="close fa-solid fa-xmark" data-dismiss="modal" aria-label="Close" @click="showBatchStatusModal = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </b-col>
        </template>

        <b-tabs pills justified card align="center">
            <b-tab title="Syllabus Content" active>
                <b-card-text>
                    <b-container>
                        <b-card v-if="selectedCourseContentNode!=null && selectedCourseContentNode.childrens.length != 0" class="shadow border-0 mx-auto" style="background:#d0dcf5;border:2px solid grey;border-radius:25px;width:100%;margin-bottom:2em;">
                            <div role="tablist">
                                <b-row class="mb-3" align-v="center">
                                    <b-col cols="2">
                                        <b-dropdown variant="warning" size="sm" class="shadow" style="">
                                            <template slot="button-content"><span>{{contentType}}</span></template>
                                            <b-dropdown-item @click="filterByContentType('ALL')">All</b-dropdown-item>
                                            <b-dropdown-item @click="filterByContentType('Video')">Video</b-dropdown-item>
                                            <b-dropdown-item @click="filterByContentType('Quiz')">Quiz</b-dropdown-item>
                                            <b-dropdown-item @click="filterByContentType('Material')">Material</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                    <!-- content type -->
                                    <b-col class="d-flex justify-content-center">
                                        <a style="color:#4274d6;font-weight:bold;font-size:1.2rem;text-align:center;" v-if="contentType=='ALL'">Overall Content</a>
                                        <a style="color:#4274d6;font-weight:bold;font-size:1.2rem;text-align:center;" v-else>{{contentType}} Content</a>
                                    </b-col>
                                    <!-- progress bar -->
                                    <!-- <b-col class="pull-right" cols="2">
                            <span v-if="selectedUserDetail!=null && selectedCourseContentInfo!=null && contentType=='ALL'">
                                <span style="color: #4274d6;font-size: 14px;">{{selectedUserDetail.progress}}%</span>
                                <span style="font-size: 13px;">&nbsp;({{selectedUserDetail.overall}} / {{selectedCourseContentInfo.totalContent}})</span>
                                <b-progress :max="progressMaxValue">
                                    <b-progress-bar :value="selectedUserDetail.progress" striped></b-progress-bar>
                                    <b-progress-bar :value="100 - selectedUserDetail.progress" variant="secondary"></b-progress-bar>
                                </b-progress>
                            </span>
                            <span v-if="selectedUserDetail!=null && selectedCourseContentInfo!=null && contentType=='Video'">
                                <span style="color: #4274d6;font-size: 14px;">{{selectedUserDetail.videoProgress}}%</span>
                                <span style="font-size: 13px;">&nbsp;({{selectedUserDetail.video}} / {{selectedCourseContentInfo.totalVideo}})</span>
                                <b-progress :max="progressMaxValue">
                                    <b-progress-bar :value="selectedUserDetail.videoProgress" striped></b-progress-bar>
                                    <b-progress-bar :value="100 - selectedUserDetail.videoProgress" variant="secondary"></b-progress-bar>
                                </b-progress>
                            </span>
                            <span v-if="selectedUserDetail!=null && selectedCourseContentInfo!=null && contentType=='Quiz'">
                                <span style="color: #4274d6;font-size: 14px;">{{selectedUserDetail.quizProgress}}%</span>
                                <span style="font-size: 13px;">&nbsp;({{selectedUserDetail.quiz}} / {{selectedCourseContentInfo.totalQuiz}})</span>
                                <b-progress :max="progressMaxValue">
                                    <b-progress-bar :value="selectedUserDetail.quizProgress" striped></b-progress-bar>
                                    <b-progress-bar :value="100 - selectedUserDetail.quizProgress" variant="secondary"></b-progress-bar>
                                </b-progress>
                            </span>
                            <span v-if="selectedUserDetail!=null && selectedCourseContentInfo!=null && contentType=='Material'">
                                <span style="color: #4274d6;font-size: 14px;">{{selectedUserDetail.resourceProgress}}%</span>
                                <span style="font-size: 13px;">&nbsp;({{selectedUserDetail.resource}} / {{selectedCourseContentInfo.totalResource}})</span>
                                <b-progress :max="progressMaxValue">
                                    <b-progress-bar :value="selectedUserDetail.resourceProgress" striped></b-progress-bar>
                                    <b-progress-bar :value="100 - selectedUserDetail.resourceProgress" variant="secondary"></b-progress-bar>
                                </b-progress>
                            </span>
                        </b-col> -->
                                </b-row>

                                <b-card no-body class="mb-1" v-for="(l1, l1index) in selectedCourseContentNode.childrens" v-bind:key="l1index">
                                    <!-- {{l1index}}====={{selectedCourseContentNode.childrens.length}} -->
                                    <b-card-header header-tag="header" style="cursor:pointer;border-bottom:1px solid #d0dcf5" role="tab" v-if="l1index != selectedCourseContentNode.childrens.length" v-b-toggle="'accordion-1'+l1index">
                                        <i style="color:#2780B2;" icon="fa-solid fa-minus" class="when-opened float-right mt-2 mr-4"></i> <i style="color:#2780B2;" icon="fa-solid fa-plus" class="when-closed  when-closed float-right mt-2 mr-4"></i>
                                        <span class="when-opened float-end mt-2 mr-4">
                                            <font-awesome-icon icon="minus" style="color:#2780B2;" />
                                        </span>
                                        <span class="when-closed  when-closed float-end mt-2 mr-4">
                                            <font-awesome-icon icon="plus" style="color:#2780B2;" />
                                        </span>
                                        <b-row class="card-header" style="padding:5px;">
                                            <b-col class="d-flex justify-content-start ml-4">
                                                <strong style="color:grey;">{{l1.word}}</strong>
                                            </b-col>
                                        </b-row>
                                    </b-card-header>
                                    <b-collapse :id="'accordion-1'+l1index" visible role="tabpanel">
                                        <b-card-body>
                                            <!-- <b-card no-body class="mb-1" v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index"> -->
                                            <!-- <b-card-header header-tag="header" style="cursor:pointer;" role="tab" v-b-toggle="'accordion-2'+l1index+l2index"> -->
                                            <!-- <i style="color:#2780B2;" class="fa fa-chevron-circle-up when-opened float-right mt-2 mr-2"></i> <i style="color:#2780B2;" class=" fa fa-chevron-circle-down  when-closed float-right mt-2 mr-2"></i> -->
                                            <!-- <span class="when-opened float-end mt-2 mr-4"> -->
                                            <!-- <font-awesome-icon icon="minus" style="color:#2780B2;" /> -->
                                            <!-- <b-icon icon="arrow-up-circle-fill" style="color: #2780B2;"></b-icon>
                                        </span>
                                        <span class="when-closed  when-closed float-end mt-2 mr-4"> -->
                                            <!-- <font-awesome-icon icon="plus" style="color:#2780B2;" /> -->
                                            <!-- <b-icon icon="arrow-down-circle-fill" style="color: #2780B2;"></b-icon>
                                        </span>
                                        <b-row class="card-header" style="padding:5px;">
                                            <b-col class="d-flex justify-content-start ml-4">
                                                <strong style="color:grey;">{{l2.word}}</strong>
                                            </b-col>
                                        </b-row>
                                    </b-card-header> -->

                                            <b-collapse :id="'accordion-2'+l1index" visible role="tabpanel">
                                                <b-card-body>
                                                    <div class="table-responsive" v-if="hasContent(l1index)">
                                                        <table class="table table-hover table-bordered table-space">
                                                            <!-- All content only -->
                                                            <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='ALL' && (l2.video || l2.quiz || l2.resource)">
                                                                <td style="width:6%">
                                                                    <span v-if="l2.video">
                                                                        <font-awesome-icon icon="video" /></span>
                                                                    <span v-if="l2.resource">
                                                                        <font-awesome-icon icon="file" /></span>
                                                                    <span v-if="l2.quiz">
                                                                        <font-awesome-icon icon="pen" /></span>
                                                                </td>
                                                                <td>
                                                                    <span>{{l2.word}}</span>
                                                                    <!-- <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row> -->
                                                                </td>
                                                                <td style="width:5%">
                                                                    <span>
                                                                        <span v-if="l2.complete == true">
                                                                            <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                        <span v-if="l2.complete == undefined || l2.complete == false">
                                                                            <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <!-- Video content only -->
                                                            <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Video' && l2.video">
                                                                <td style="width:5%">
                                                                    <span v-if="l2.video">
                                                                        <font-awesome-icon icon="video" /></span>
                                                                </td>
                                                                <td>
                                                                    <span>{{l2.word}}</span>
                                                                    <!-- <b-row v-if="l3.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l3.releaseOn}}</span></span></b-row> -->
                                                                </td>
                                                                <td style="width:5%">
                                                                    <span>
                                                                        <span v-if="l2.complete == true">
                                                                            <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                        <span v-if="l2.complete == undefined || l2.complete == false">
                                                                            <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <!-- quiz content only -->
                                                            <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Quiz' && l2.quiz">
                                                                <td style="width:5%">
                                                                    <span v-if="l2.quiz">
                                                                        <font-awesome-icon icon="pen" /></span>
                                                                </td>
                                                                <td>
                                                                    <span>{{l2.word}}</span>
                                                                    <b-row v-if="l2.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l2.releaseOn}}</span></span></b-row>
                                                                </td>
                                                                <td style="width:5%">
                                                                    <span>
                                                                        <span v-if="l2.complete == true">
                                                                            <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                        <span v-if="l2.complete == undefined || l2.complete == false">
                                                                            <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <!-- resource content only -->
                                                            <tr v-for="(l2, l2index) in l1.childrens" v-bind:key="l2index" v-if="contentType=='Material' && l2.resource">
                                                                <td style="width:5%">
                                                                    <span v-if="l2.resource">
                                                                        <font-awesome-icon icon="file" /></span>
                                                                </td>
                                                                <td>
                                                                    <span>{{l2.word}}</span>
                                                                    <b-row v-if="l2.liveVideo" class="ml-0"><span style="font-size: 12px;color:steelblue;">Release Date : <span style="font-weight:bold;">{{l2.releaseOn}}</span></span></b-row>
                                                                </td>
                                                                <td style="width:5%">
                                                                    <span>
                                                                        <span v-if="l2.complete == true">
                                                                            <font-awesome-icon icon="check" style="color: green;" /></span>
                                                                        <span v-if="l2.complete == undefined || l2.complete == false">
                                                                            <b-icon icon="x-circle" style="color: red;"></b-icon>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="text-center" v-else>
                                                        <span class="text-secondary" style="font-weight:bold;font-size:1.2em;">No {{contentType}} !</span>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                            <!-- </b-card> -->
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                        </b-card>
                        <b-card v-else class="text-center">
                            <span>No data found</span>
                        </b-card>
                    </b-container>
                </b-card-text>
            </b-tab>
            <b-tab title="Table" @click="getAllBatchDetailCount()">
                <b-card-text>
                    <b-container>
                        <b-row align-v="center">
                            <!--add Branch Batch button start-->

                            <!-- <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" v-b-modal.addBranchBatch @click="resetForm()">Add Batch</b-button>
                </b-col> -->
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="batchKeyword" v-on:keyup.enter="getBatchSearchCount(batchKeyword)" placeholder="Type a name" />
                            </b-col>
                            <b-col col lg="2" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBatchSearchCount(batchKeyword)">Search</b-button>
                            </b-col>
                            <b-col sm="8" md="3" lg="6" class="my-1" id="r1">
                                <b-pagination v-model="batchCurrentPage" :total-rows="totalBatchCount" :per-page="batchPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="
                          switchBatchPage(batchCurrentPage, batchPageCount)
                        "></b-pagination>
                            </b-col>
                        </b-row>
                        <b-row align-v="center" id="r2">
                            <b-col sm="6" md="3" lg="4" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="batchPageCount" @input="
                          switchBatchPage(batchCurrentPage, batchPageCount)
                        " :options="batchPageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col sm="6" md="2" lg="8" id="r1" class="text-center">
                                <span style="color: #0974ff; cursor: pointer" @click="refreshBatch()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <!-- <p class="text-center">Batch</p> -->
                            <hr />
                            <b-table responsive hover :items="branchBatcheDetails" :fields="branchBatchField" :current-page="batchCurrentPage" :per-page="branchBatcheDetails.batchPageCount" ref="table">
                                <!-- <template v-slot:cell(branchBatchId)="data">
                        <span class="title"> {{ data.item.branchBatchId }}</span>
                    </template> -->
                                <template v-slot:cell(code)="data">
                                    <!-- <span style="cursor:pointer;border-bottom: 2px solid #0dcaf0;" @click="batchStatus(data.item)" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="View Batch Report"> -->
                                    {{ data.item.code }}
                                    <!-- </span> -->
                                </template>
                                <template v-slot:cell(syllabusLevel2)="data">
                                    {{ data.item.syllabusLevel2 }}
                                </template>
                                <template v-slot:cell(tutor)="data">
                                    {{ data.item.tutor.userName }}
                                </template>
                                <template v-slot:cell(studentCount)="data">
                                    {{ data.item.studentCount }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ dateTimeFormat(data.item.createdAt, "dateTime") }}
                                    </div>
                                </template>
                                <!-- <template v-slot:cell(actions)="data">
                                        <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Batch Details" v-b-modal.viewBranchBatch @click="getBranchBatch(data.item)">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <b-button variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Batch Details" v-b-modal.updateBatch @click="getBranchBatch(data.item)">
                                            <font-awesome-icon icon="edit" />
                                        </b-button>
                                        <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Batch Details" variant="success" @click="archive(data.item.batchId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button>
                                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Batch Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.batchId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button>
                                    </template> -->
                            </b-table>
                        </b-card>
                    </b-container>
                </b-card-text>
            </b-tab>
        </b-tabs>
    </b-modal>
</b-container>
</div>
</template>

<script>
import axios from "axios";
import BatchService from "../service/BatchService";
import BranchService from "../service/BranchService";
import Account from '../service/Account.js';
import BranchBatchService from "../service/BranchBatchService";
import moment from "moment-timezone";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
    name: "Batch",
    components: {},
    data() {
        return {
            disabledButton: false,
            paginationBatches: true,
            searchPagination: false,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show lot"
            }],
            totalCount: 0,
            keyword: "",
            batch: {
                startDate: "",
                code: "",
                batchType: null,
                // timing: "",
                syllabus: null,
                studentCount: "",
                branch: null,
            },
            batches: [],
            fields: [{
                    key: "code",
                    label: "Code"
                },
                {
                    key: "batchType",
                    label: "Batch Type",
                },
                {
                    key: "syllabus",
                    label: "Syllabus"
                },
                {
                    key: "studentCount",
                    label: "StudentCount"
                },
                {
                    key: "branch",
                    label: "Branch"
                },
                {
                    key: "actions",
                    label: "Actions"
                },
            ],

            // Branch Batch Table
            paginationBranchBatches: true,
            searchBatchPagination: false,
            batchCurrentPage: 1,
            batchPageCount: 5,
            batchPageOptions: [
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            totalBatchCount: 0,
            batchKeyword: "",
            branchBatcheDetails: [],
            branchBatchField: [{
                    key: "code",
                    label: "Code",
                },
                {
                    key: "syllabusLevel2",
                    label: "Syllabus",
                },
                {
                    key: "tutor",
                    label: "Tutor",
                },
                {
                    key: "studentCount",
                    label: "Student",
                    tdClass: "text-center",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                {
                    key: "createdAt",
                    label: "createdAt",
                },
            ],

            selectedBranch: "Branch",
            allBranches: "",
            selectedSyllabus: "Syllabus",
            allSyllabus: "",
            startTime: "",
            endTime: "",
            showBatchStatusModal: false,
            batchDetails: null,
            contentType: 'ALL',
            selectedCourseContentInfo: null,
            selectedCourseContentNode: null,
            batchActive: true,
            finishedActive: false,
            archivedActivate: false,
            allBatchType: [],
            batchType: "",
            batchtTypeCreate: false,
        };
    },

    validations: {
        batchType: {
            required,
        },
        batch: {
            // timing: {
            //   required,
            //   minLength: minLength(3),
            // },
            code: {
                required,
                minLength: minLength(3),
            },
            studentCount: {
                required,
                minLength: minLength(1),
            },
            batchType: {
                required
            },
            syllabus: {
                required,
            },
            branch: {
                required,
            },
            startTime: {
                required,
            },
            endTime: {
                required,
                minValue(endTime, {
                    startDate,
                    startTime
                }) {
                    let startDateTime = new Date(startDate + " " + startTime);
                    let DateOfStart = new Date(startDateTime.getTime());
                    let endDateTime = new Date(startDate + " " + endTime);
                    let DateOfEnd = new Date(endDateTime.getTime());
                    if (DateOfStart < DateOfEnd) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            startDate: {
                required,
                minValue: (value) => {
                    let selectedDT = new Date(value);
                    let currentDT = new Date();
                    if (new Date(selectedDT.setTime(currentDT.getTime())) >= currentDT) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
        },
    },

    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    self.getAllBranches();
                    // self.getAllBatches();
                    self.getAllSyllabus();
                    self.getCount();
                    self.getAllBatchType();
                    this.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                if (this.batchActive) {
                    this.getCount();
                } else if (this.finishedActive) {
                    this.getFinishedBatchCount();
                } else if (this.archivedActivate) {
                    this.getArchivedBatchCount();
                }
            }
        },
        batchKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllBatchDetailCount();
            }
        },
    },
    methods: {
        createBatchType: function () {
            this.$v.batchType.$touch();
            if (!this.$v.batchType.$invalid) {
                this.allBatchType.splice(0);
                let data = {
                    type: this.batchType
                }
                return new Promise((resolve, reject) => {
                    axios
                        .post("batchType/create", data)
                        .then((response) => {
                            this.allBatchType = response.data;
                            this.batchtTypeCreate = false;
                            this.resetBatchType();
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        getAllBatchType: function () {
            this.allBatchType.splice(0);
            return new Promise((resolve, reject) => {
                axios
                    .post("batchType/getAll")
                    .then((response) => {
                        this.allBatchType = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        batchShow: function () {
            this.loading = true;
            this.batchActive = true;
            this.finishedActive = false;
            this.archivedActivate = false;
            this.currentPage = 1;
            //keyword value as any string else call for clear and getCount method call from watch
            if (this.keyword == "") {
                this.getCount();
            } else {
                this.keyword = "";
            }
        },
        finishedBatchShow: function () {
            // this.loading = true;
            this.batchActive = false;
            this.finishedActive = true;
            this.archivedActivate = false;
            this.currentPage = 1;
            //keyword value as any string else call for clear and getFinishedBatchCount method call from watch
            if (this.keyword == "") {
                this.getFinishedBatchCount();
            } else {
                this.keyword = "";
            }
        },
        archivedBatchShow: function () {
            // this.loading = true;
            this.batchActive = false;
            this.finishedActive = false;
            this.archivedActivate = true;
            this.currentPage = 1;
            //keyword value as any string else call for clear and getArchivedBatchCount method call from watch
            if (this.keyword == "") {
                this.getArchivedBatchCount();
            } else {
                this.keyword = "";
            }
        },
        putBatch: function () {
            this.$v.batch.$touch();
            // if (this.$v.batch.$invalid) {
            //     this.submitstatus = "FAIL";
            // } else {
                if (!this.$v.batch.$invalid) {
                var data = {
                    code: this.batch.code
                }
                this.disabledButton = true;
                return new Promise((resolve, reject) => {
                    axios.post("batch/code/unique", data)
                        .then((response) => {
                            if (response.data == true) {
                                this.submitstatus = "SUCCESS";
                                var batch = {
                                    code: this.batch.code,
                                    batchType: this.batch.batchType,
                                    startTime: this.batch.startTime + ":00",
                                    endTime: this.batch.endTime + ":00",
                                    startDate: this.batch.startDate,
                                    studentCount: this.batch.studentCount,
                                    syllabus: this.batch.syllabus,
                                    branch: this.batch.branch
                                }
                                return new Promise((resolve, reject) => {
                                    BatchService.insertBatch(batch)
                                        .then((response) => {
                                            this.$swal({
                                                title: "Good job!",
                                                text: "Batch Added successfully!!!!!",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.modal.hide();
                                            this.getCount();
                                            this.disabledButton = false;
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            } else {
                                this.$swal({
                                    title: "BatchCode is not unique",
                                    icon: "error",
                                    button: "ok",
                                });
                                this.disabledButton = false;
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
            else {
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },
        batchStatus: function (item) {
            console.log("batchStatus=======", item);
            this.batchDetails = {
                syllabusId: item.syllabus.syllabusId,
                batchId: item.batchId,
                name: item.branch.name,
                code: item.code,
                startTime: item.startTime,
                endTime: item.endTime,
            }
            return new Promise((resolve, reject) => {
                axios
                    .post("/branchBatch/get/node", this.batchDetails)
                    .then((response) => {
                        this.selectedCourseContentNode = response.data.syllabusContent;
                        this.showBatchStatusModal = true;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllBatchDetailCount: function () {
            this.loading = true;
            this.paginationBranchBatches = true;
            this.searchBatchPagination = false;
            this.batchKeyword = "";
            this.batchCurrentPage = 1;
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchDetailCount(this.batchDetails)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalBatchCount = response.data;
                        this.getAllBatchDetailPageable(
                            this.batchCurrentPage,
                            this.batchPageCount
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBatchDetailPageable: function (batchCurrentPage, batchPageCount) {
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchDetailPageable(
                        batchCurrentPage,
                        batchPageCount,
                        this.batchDetails
                    )
                    .then((response) => {
                        // console.log("branchBatcheDetails",response.data)
                        this.branchBatcheDetails = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // getCourseContentInfo: function () {
        //     let data = {
        //         courseId: this.selectedCourseId
        //     }
        //     return new Promise((resolve, reject) => {
        //         axios.post("/branchBatch/getContentInfo", data)
        //             .then(response => {
        //                 this.selectedCourseContentInfo = response.data;
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             })
        //     })
        // },
        filterByContentType: function (type) {
            this.contentType = type;
        },
        checkViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++) {
                    if (lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId) {
                        return true;
                    }
                }
            }
            return false;
        },
        videoViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++) {
                    if (this.selectedCourseHistory.nodeHistory[i].node.video && lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId) {
                        return true;
                    }
                }
            }
            return false;
        },
        quizViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++) {
                    if (this.selectedCourseHistory.nodeHistory[i].node.quiz && lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId) {
                        return true;
                    }
                }
            }
            return false;
        },
        resourceViewedStatus: function (lectureId) {
            if (this.selectedCourseHistory != null) {
                for (var i = 0; i < this.selectedCourseHistory.nodeHistory.length; i++) {
                    if (this.selectedCourseHistory.nodeHistory[i].node.resource && lectureId == this.selectedCourseHistory.nodeHistory[i].lectureId) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasContent(l1Index) {
            if (this.contentType == 'ALL') {
                return true;
            } else if (this.contentType == 'Video') {
                for (var i = 0; i < this.selectedCourseContentNode.childrens[l1Index].childrens.length; i++) {
                    if (this.selectedCourseContentNode.childrens[l1Index].childrens[i].video) {
                        return true;
                    }
                }
            } else if (this.contentType == 'Quiz') {
                for (var j = 0; j < this.selectedCourseContentNode.childrens[l1Index].childrens.length; j++) {
                    if (this.selectedCourseContentNode.childrens[l1Index].childrens[j].quiz) {
                        return true;
                    }
                }
            } else if (this.contentType == 'Material') {
                for (var k = 0; k < this.selectedCourseContentNode.childrens[l1Index].childrens.length; k++) {
                    if (this.selectedCourseContentNode.childrens[l1Index].childrens[k].resource) {
                        return true;
                    }
                }
            }
        },
        updateBatch: function () {
            console.log("startTime============ ",this.batch.startTime);
            var startTime = this.batch.startTime.charAt(5)+this.batch.startTime.charAt(6)+this.batch.startTime.charAt(7);
            var endTime = this.batch.endTime.charAt(5)+this.batch.endTime.charAt(6)+this.batch.endTime.charAt(7);
            if(startTime == "") {
                console.log("if =====startTime============ ",startTime);
                startTime = this.batch.startTime +":00";
            } else {
                startTime = this.batch.startTime;
                console.log("else =====startTime============ ",startTime);
            }
            if(endTime == "") {
                console.log("if ====endTime============ ",endTime);
                endTime = this.batch.endTime +":00";
            } else {
                endTime = this.batch.endTime;
            }
            console.log("data=====startTime",startTime);
            console.log("data=====endTime",endTime);
            this.disabledButton = false;
            this.$v.batch.$touch();
            if (!this.$v.batch.$invalid) {
                this.submitstatus = "SUCCESS";
                var batch = {
                    batchId: this.batch.batchId,
                    code: this.batch.code,
                    batchType: this.batch.batchType,
                    // startTime: this.batch.startTime,
                    // endTime: this.batch.endTime,
                    startTime: startTime,
                    endTime: endTime,
                    startDate: this.batch.startDate,
                    studentCount: this.batch.studentCount,
                    syllabus: this.batch.syllabus,
                    branch: this.batch.branch,
                    branches: [],
                }
                // console.log("BatcheDetails",batch)
                this.disabledButton = true;
                return new Promise((resolve, reject) => {
                    BatchService.updateBatch(batch)
                        .then((response) => {
                            if (response.data == "") {
                                this.$swal({
                                    title: "BatchCode is not unique",
                                    icon: "error",
                                    button: "ok",
                                });
                                this.disabledButton = false;
                            } else {
                                this.$swal("Batch details updated successfully!!!!!");
                                this.$refs.modal.hide();
                                this.getCount();
                                this.disabledButton = false;
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            this.disabledButton = false;
                            reject(err);
                        });
                });
            } else {
                console.log("Update Failed")
                this.submitstatus = "FAIL";
                this.disabledButton = false;
            }
        },
        batchFinish: function (batch) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Have you finish the batch",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Finished!'
            }).then((result) => {
                if (result.isConfirmed) {
                    return new Promise((resolve, reject) => {
                        axios
                            .post("batch/finish", batch)
                            .then((response) => {
                                Swal.fire(
                                    'Finished!',
                                    'This batch has been finished.',
                                    'success'
                                )
                                this.getCount();
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                }
            })
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSyllabus: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post("/syllabus/getAll")
                    .then((response) => {
                        this.allSyllabus = response.data;
                        // console.log("AllSyllabus",response.data)
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getBatch: function (batch) {
            this.resetForm();
            this.batch = batch;
        },

        getCount: function () {
            this.loading = true;
            this.paginationBatches = true;
            this.searchPagination = false;
            this.keyword = ""
            return new Promise((resolve, reject) => {
                BatchService.getBatchCount()
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllPageable: function (currentPage, pageCount) {
            this.batches.splice(0);
            // console.log("currentPage",this.currentPage)
            //       console.log("pageCount",this.pageCount)

            return new Promise((resolve, reject) => {
                BatchService.getAllBatchPageable(currentPage, pageCount)
                    .then((response) => {
                        this.batches = response.data;
                        // console.log("Response",response.data)
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSearchCount: function (keyword) {
            this.paginationBatches = false;
            this.searchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                BatchService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            return new Promise((resolve, reject) => {
                BatchService.getAllSearchPageable(currentPage, pageCount, keyword.toLowerCase())
                    .then((response) => {
                        this.batches = response.data;
                        // console.log("Count", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        getBatchSearchCount: function (batchKeyword) {
            this.paginationBranchBatches = false;
            this.searchBatchPagination = true;
            this.batchCurrentPage = 1;
            return new Promise((resolve, reject) => {
                BranchBatchService.getBatchSearchCount(batchKeyword.toLowerCase())
                    .then((response) => {
                        this.totalBatchCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllBatchSearchPageable(
                            this.batchCurrentPage,
                            this.batchPageCount,
                            batchKeyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllBatchSearchPageable: function (batchCurrentPage, batchPageCount, batchKeyword) {
            return new Promise((resolve, reject) => {
                BranchBatchService.getAllBatchSearchPageable(
                        batchCurrentPage,
                        batchPageCount,
                        // this.tutorId,
                        batchKeyword
                    )
                    .then((response) => {
                        this.branchBatcheDetails = response.data;
                        // console.log("branchBatcheDetails", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },

        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            if (this.batchActive) {
                if (this.paginationBatches) {
                    this.getAllPageable(currentPage, pageCount);
                } else if (this.searchPagination) {
                    this.getAllSearchPageable(currentPage, pageCount, this.keyword);
                }
            } else if (this.finishedActive) {
                if (this.paginationBatches) {
                    this.getAllFinishedBatchPageable(currentPage, pageCount);
                } else if (this.searchPagination) {
                    this.getAllFinishedBatchSearchPageable(currentPage, pageCount, this.keyword);
                }
            } else if (this.archivedActivate) {
                if (this.paginationBatches) {
                    this.getAllArchivedBatchPageable(currentPage, pageCount);
                } else if (this.searchPagination) {
                    this.getAllArchivedBatchSearchPageable(currentPage, pageCount, this.keyword);
                }
            }
        },
        switchBatchPage: function (batchCurrentPage, batchPageCount) {
            this.loading = true;
            if (this.paginationBranchBatches) {
                this.getAllBatchDetailPageable(batchCurrentPage, batchPageCount);
            } else if (this.searchBatchPagination) {
                this.getAllBatchSearchPageable(
                    batchCurrentPage,
                    batchPageCount,
                    this.batchKeyword
                );
            }
        },
        getAllBatches: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                BatchService.getAllBatches()
                    .then((response) => {
                        this.allBatches = response.data;
                        // console.log("allBatches", response.data);
                        // this.batch.forEach(e => {
                        //   console.log(e);
                        // })
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getFinishedBatchCount: function () {
            this.loading = true;
            this.paginationBatches = true;
            this.searchPagination = false;
            // this.keyword = "";
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get('batch/finish/get/count')
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data;
                        this.getAllFinishedBatchPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllFinishedBatchPageable: function (currentPage, pageCount) {
            this.batches.splice(0);
            // console.log("currentPage",this.currentPage)
            //       console.log("pageCount",this.pageCount)

            return new Promise((resolve, reject) => {
                axios.post('batch/finish/getAll/' + (currentPage - 1) + "/" + pageCount)
                    .then((response) => {
                        this.batches = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllFinishedBatchSearchCount: function (keyword) {
            this.paginationBatches = false;
            this.searchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get('batch/finish/search/count/' + keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllFinishedBatchSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllFinishedBatchSearchPageable: function (currentPage, pageCount, keyword) {
            this.batches.splice(0);
            return new Promise((resolve, reject) => {
                axios.post('batch/finish/search/getAll/' + (currentPage - 1) + "/" + pageCount + "/" + keyword.toLowerCase())
                    .then((response) => {
                        this.batches = response.data;
                        // console.log("Count", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getArchivedBatchCount: function () {
            this.loading = true;
            this.paginationBatches = true;
            this.searchPagination = false;
            this.keyword = "";
            return new Promise((resolve, reject) => {
                axios.get('batch/archive/get/count')
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data;
                        this.getAllArchivedBatchPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchivedBatchPageable: function (currentPage, pageCount) {
            this.batches.splice(0);
            // console.log("currentPage",this.currentPage)
            //       console.log("pageCount",this.pageCount)

            return new Promise((resolve, reject) => {
                axios.post('batch/archive/getAll/' + (currentPage - 1) + "/" + pageCount)
                    .then((response) => {
                        this.batches = response.data;
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchivedBatchSearchCount: function (keyword) {
            this.paginationBatches = false;
            this.searchPagination = true;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get('batch/archive/search/count/' + keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllArchivedBatchSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchivedBatchSearchPageable: function (currentPage, pageCount, keyword) {
            this.batches.splice(0);
            return new Promise((resolve, reject) => {
                axios.post('batch/archive/search/getAll/' + (currentPage - 1) + "/" + pageCount + "/" + keyword.toLowerCase())
                    .then((response) => {
                        this.batches = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        dateTimeFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "dateTime") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },

        archive: function (id) {
            return new Promise((resolve, reject) => {
                BatchService.archive(id)
                    .then((response) => {
                        this.$swal("Archive successfully!!!!!");
                        if (this.batchActive) {
                            this.getCount();
                        } else if (this.finishedActive) {
                            this.getFinishedBatchCount();
                        } else if (this.archivedActivate) {
                            this.getArchivedBatchCount();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unarchive: function (id) {
            return new Promise((resolve, reject) => {
                BatchService.unarchive(id)
                    .then((response) => {
                        this.$swal("UnArchive successfully!!!!!");
                        if (this.batchActive) {
                            this.getCount();
                        } else if (this.finishedActive) {
                            this.getFinishedBatchCount();
                        } else if (this.archivedActivate) {
                            this.getArchivedBatchCount();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        refresh() {
            this.keyword = "";
            this.currentPage = 1;
            if (this.batchActive) {
                this.getCount();
            } else if (this.finishedActive) {
                this.getFinishedBatchCount();
            } else if (this.archivedActivate) {
                this.getArchivedBatchCount();
            }
        },

        refreshBatch() {
            this.getAllBatchDetailCount();
            this.batchKeyword = "";
            this.batchCurrentPage = 1;
        },

        resetForm() {
            this.batch = {
                // timing: "",
                startDate: "",
                code: "",
                batchType: null,
                // timing: "",
                syllabus: null,
                studentCount: "",
                branch: null,
            };
            this.batchtTypeCreate = false;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        resetBatchType() {
            this.batchType = "";
            this.batchtTypeCreate = false;
            this.$nextTick(() => {
                this.$v.batchType.$reset();
            });
        }
    },
};
</script>

<style scoped>
::v-deep [data-v-ab43ce90] .nav-pills .nav-link.active,
.nav-pills .show>.nav-link[data-v-ab43ce90] {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 1rem;
}

.card-header:focus {
    outline: none;
    cursor: pointer;
}

.card-header {
    /* background-color: rgba(0, 0, 0, 0.03); */
    border-bottom: none;
}

/* .borderless td,
.borderless td {
    border: none;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: lightblue;
}

.table-space tbody td {
    white-space: nowrap;
} */

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation:spin 0.5s linear infinite;
    animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
