import axios from 'axios';
export default {

    // getTutorData: function(userId){
    //     return new Promise((resolve, reject) => {
    //         axios.get("/tutor/getTutor/" + userId)
    //         .then(response => {
    //             resolve(response);
    //         })
    //         .catch(err => {
    //             reject(err);
    //         });
    //     });
    // },

    insertTutor: function (tutor) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/tutor/insert", tutor, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateTutor: function (update) {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/tutor/update", update, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // updateTutor: function(tutorId,update){
    //     return new Promise((resolve, reject) => {
    //         axios.post("/tutor/update/"+tutorId, update)
    //         .then(response => {
    //             resolve(response);
    //         })
    //         .catch(err => {
    //             reject(err);
    //         });
    //     });
    // },

    getTutorCount: function () {

        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/tutor/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllTutorPageable: function (currentPage, pageCount) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/tutor/all/' + (currentPage - 1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchCount: function (keyword) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/tutor/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },

    getAllSearchPageable: function (currentPage, pageCount, keyword) {
        return new Promise((resolve, reject) => {
            axios.post('/tutor/search/' + (currentPage - 1) + "/" + pageCount + "/" + keyword)
                .then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    getAllTutors: function () {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/tutor/getAll',
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    archive: function (tutor) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/tutor/archive/" + tutor, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function (tutor) {

        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/tutor/unarchive/" + tutor, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },


}