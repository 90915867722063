import axios from 'axios';
export default{
    insertTeam: function(team){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/team/create", team, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    deleteTeam: function(teamId){
        let data = {
            teamId:teamId
        }
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: '/team/delete',data,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    updateTeam: function(team){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/team/update", team, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    archive: function(team){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/team/archive/"+ team, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function(team){
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/team/unarchive/"+ team, config)
                .then(response => {
                    resolve(response);
                })

                .catch(err => {
                    reject(err);
                });
        });
    },
    getCount: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "team/get/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: 'team/getAll/'+ (currentPage-1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchTeam: function(keyword){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/team/search/'+ keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getSearchCount: function(keyword) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/team/get/search/count/' + keyword,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });

        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/team/getAll/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getAllTeams: function(){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            // axios({
            //     method: 'get',
            //     url: '/team/getAllTeams',
            // })
            axios.get("/team/getAllTeams",config)
            .then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },

    getCurrentTeam: function(teamId){
        let config = {
            headers: {
                "Content-Type": "application/json",
                'tenant-uuid': '',
            }
        };
        return new Promise((resolve, reject) => {
            // axios({
            //     method: 'get',
            //     url: '/team/getCurrentTeam'+"/"+teamId
            // })
            axios.get("/team/getCurrentTeam"+"/"+teamId,config)
            .then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },


}