<template>
    <div>
        <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container>
        <b-tabs pills card content-class="mt-3" justified>
            <b-tab title="Booked" active @click="tabChange('booked')">
                <div class="card">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="search" v-on:keyup.enter="getBookedSearchCount(keyword)"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBookedSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount,'booked')"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="tabChange('booked')">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allBooked" :fields="bookedFields" :per-page="allBooked.pageCount" :current-page="currentPage" ref="table">
                               
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(orderNumber)="data">
                                    {{ data.item.orderNumber }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agentName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(bookBundle)="data">
                                    {{ data.item.type.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div class="text-primary contact" >
                                        {{ data.item.contact }}
                                    </div>
                                    <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                                </template>
                            
                                
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" @click="archive(data.item.orderId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->
                                    </div>
                                </template>
                            </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="COD"  @click="tabChange('cod')">
                <div class="card">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="search" v-on:keyup.enter="getBookedSearchCount(keyword)"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getBookedSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount,'booked')"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="tabChange('cod')">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allBooked" :fields="bookedFields" :per-page="allBooked.pageCount" :current-page="currentPage" ref="table">
                               
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(orderNumber)="data">
                                    {{ data.item.orderNumber }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agentName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(bookBundle)="data">
                                    {{ data.item.type.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div class="text-primary contact" >
                                        {{ data.item.contact }}
                                    </div>
                                    <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                                </template>
                            
                                
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive" variant="success" @click="archive(data.item.orderId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->
                                    </div>
                                </template>
                            </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="archiveOrder" @click="tabChange('archiveOrder')">
                <div class="card">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" disabled class="searchbar" v-model="keyword" placeholder="search" v-on:keyup.enter="getBookedSearchCount(keyword)"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" disabled variant="outline-primary" class="bnclr" @click="getBookedSearchCount(keyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount,'booked')"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="tabChange('archiveOrder')">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allBooked" :fields="bookedFields" :per-page="allBooked.pageCount" :current-page="currentPage" ref="table">
                               
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(orderNumber)="data">
                                    {{ data.item.orderNumber }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agentName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(bookBundle)="data">
                                    {{ data.item.type.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div class="text-primary contact" >
                                        {{ data.item.contact }}
                                    </div>
                                    <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                                </template>
                            
                                
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                        <!-- <b-button size="sm" class="mx-1" variant="warning" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="Edit Address" v-b-modal.editbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="edit" />
                                        </b-button> -->
                                        <!-- <b-button size="sm" class="mx-1" v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive" variant="warning" @click="unarchive(data.item.orderId)">
                                            <font-awesome-icon icon="archive" />
                                        </b-button> -->
                                    </div>
                                </template>
                            </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Dispatched"  @click="tabChange('dispatched')">
                <div class="card">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="dispatchedKeyword" placeholder="search" v-on:keyup.enter="getDispatchedSearchCount(dispatchedKeyword)"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getDispatchedSearchCount(dispatchedKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="dispatchedCurrentPage" :total-rows="totalCount" :per-page="dispatchedPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchDispatchedPage(dispatchedCurrentPage, dispatchedPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="dispatchedPageCount" @input="switchDispatchedPage(dispatchedCurrentPage, dispatchedPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="tabChange('dispatched')">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allDispatched" :fields="bookedFields" :per-page="allDispatched.dispatchedPageCount" :current-page="dispatchedCurrentPage" ref="table">
                               
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(orderNumber)="data">
                                    {{ data.item.orderNumber }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agentName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(bookBundle)="data">
                                    {{ data.item.type.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div class="text-primary contact" >
                                        {{ data.item.contact }}
                                    </div>
                                    <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                                </template>
                            
                                
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="eye" />
                                        </b-button>
                                                                             
                                    </div>
                                </template>
                            </b-table>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Delivered"  @click="tabChange('delivered')">
              
                <div class="card">
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="deliveredKeyword" placeholder="search" v-on:keyup.enter="getDeliveredSearchCount(deliveredKeyword)"/>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getDeliveredSearchCount(deliveredKeyword)">Search</b-button>
                            </b-col>

                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="deliveredCurrentPage" :total-rows="totalCount" :per-page="deliveredPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchDeliveredPage(deliveredCurrentPage, deliveredPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="deliveredPageCount" @input="switchDeliveredPage(deliveredCurrentPage, deliveredPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="tabChange('delivered')">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                        <b-table hover responsive :items="allDelivered" :fields="bookedFields" :per-page="allDelivered.deliveredPageCount" :current-page="deliveredCurrentPage" ref="table">
                               
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(orderNumber)="data">
                                    {{ data.item.orderNumber }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agentName }}
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(bookBundle)="data">
                                    {{ data.item.type.name }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div class="text-primary contact" >
                                        {{ data.item.contact }}
                                    </div>
                                    <!-- <div class="text-primary contact" @click="callHistoryModalShow(data.item.alternativeContact)">
                                        {{ data.item.alternativeContact }}
                                    </div> -->
                                </template>
                            
                                
                                <template v-slot:cell(action)="data">
                                    <div class="cellSize">
                                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View" v-b-modal.viewbooked @click="bookOrder=data.item">
                                            <font-awesome-icon icon="eye" />
                                        </b-button> 
                                    </div>
                                </template>
                            </b-table>
                    </b-container>
                </div>
            </b-tab>
        </b-tabs>
    </b-container>
    <b-container>
        <b-modal id="viewbooked" title="View Detailed Data" hide-footer>
            <div class="form-row">
                <div class="form-group">
                    <label>Name:</label>
                    <b-form-input class="form-control" placeholder="Enter your Course Name" v-model=bookOrder.name disabled>
                    </b-form-input>

                </div>
                <br />
                <div class="form-group">
                    <label>Order Number:</label>
                    <b-form-input class="form-control" v-model=bookOrder.orderId disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Agent Name:</label>
                    <b-form-input class="form-control" v-model="bookOrder.agentName" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Contact Number:</label>
                    <b-form-input class="form-control" v-model=bookOrder.contact disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Whatsapp:</label>
                    <b-form-input class="form-control" v-model="bookOrder.whatsapp" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>BookBundle Name:</label>
                    <b-form-input class="form-control" v-model="bookOrder.type.name" disabled>
                    </b-form-input>
                </div>
                <div class="form-group">
                    <label>BookBundle Price:</label>
                    <b-form-input class="form-control" v-model="bookOrder.type.price" disabled>
                    </b-form-input>
                </div>
                <br>
                <div class="form-group" >
                    <label>Address:</label>
                    <div style="padding-left:10px;background-color:#e9ecef;border:1px solid #ced4da">
                        {{bookOrder.doorNo}},
                        <br>
                        {{bookOrder.landmark}},
                        <br>
                        {{bookOrder.district}},
                        <br>
                        {{bookOrder.state}} - {{bookOrder.pincode}}
                    </div>

                </div>
                <br />
                <div class="form-group">
                    <label>Payment Method:</label>
                    <b-form-input class="form-control" v-model=bookOrder.paymentMethod disabled placeholder="Enter your Code">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group">
                    <label>Remarks:</label>
                    <b-form-input class="form-control" v-model="bookOrder.remarks" disabled placeholder="update your description">
                    </b-form-input>
                </div>
                <br />
                <div class="form-group" v-if="bookOrder.dispatchedCourierName!=null">
                    <label>Dispatched by:</label>
                    <b-form-input class="form-control" v-model="bookOrder.dispatchedCourierName" disabled>
                    </b-form-input>
                </div>
                <br>
                <div class="form-group" v-if="bookOrder.deliveredCourierName!=null">
                    <label>Delivered by:</label>
                    <b-form-input class="form-control" v-model="bookOrder.deliveredCourierName" disabled>
                    </b-form-input>
                </div>
                <br>
                <div class="form-group" v-if="bookOrder.dispatched">
                    <label>Tracking Id:</label>
                    <b-form-input class="form-control" v-model="bookOrder.trackingId" disabled>
                    </b-form-input>
                </div>
                <br>
                <!-- <div class="form-group">
                    <label>BookBundle Books:</label>
                    <br>
                    <div class="" style="padding-left: 15px;font-weight: bold;" v-for="b in bookBundle.books" :key="b.bookId">
                        {{ b.name }}
                    </div>
                </div> -->
                <br />
            </div>

        </b-modal>
    </b-container>
    <!-- <b-modal id="dispatchedBook" title="Book Bundle" ref="modal" hide-footer>
                <div class="form-row">
                    <div class="form-group" v-if="booked!=null">
                        <label>Tracking Id:</label>
                        <b-form-input style="margin-top: 10px;" class="form-control" placeholder="Enter your Course Name" v-model.trim="booked.trackingId">
                        </b-form-input>
                    </div>
                    <br />
                        
                    <div class="text-center">
                        <b-button variant="outline-success" type="submit" :disabled="disabledButton" @click="disabledButton = true,dispatched()">Submit</b-button>
                    </div>
                </div>
            </b-modal> -->
    </div>
</template>
<script>
import moment from "moment-timezone";
import Account from '../service/Account.js';
import BookBundleService from '../service/BookBundleService';
export default {
    name:"BookCourier",
    data(){
        return{
            archivedOrderTable:false,
            disabledButton:false,
            loading:true,
            agentId:localStorage.getItem("agentId"),
            searchPagination:false,
            currentPage:1,
            pageCount:3,
            dispatchedPageCount:3,
            dispatchedCurrentPage:1,
            deliveredPageCount:3,
            deliveredCurrentPage:1,
            keyword:"",
            dispatchedKeyword: "",
            deliveredKeyword: "",
            totalCount: 0,
            booked:null, 
            isCod:false,
            allBooked:[],
            bookOrder: {
                agentName: "",
                booked: null,
                contact: "",
                createdAt: "",
                delivered: null,
                dispatched: null,
                district: "",
                doorNo: "",
                email: null,
                landmark: "",
                name: "",
                orderId: "",
                orderNumber: "",
                partialDispatched: null,
                paymentMethod: "",
                pincode: "",
                referenceNumber: "",
                remarks: "",
                state: "",
                trackingId: "",
                type: {
                    name: "",
                    price:0
                },
                whatsapp: ""
            },
            allDispatched:[],
            allDelivered:[],
            pageOptions: [
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            bookedFields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "agent",
                    label: "Agent Name",
                },
                {
                    key: "orderNumber",
                    label: "Order Number",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "bookBundle",
                    label: "BookBundle",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "whatsapp",
                    label: "Whatsapp",
                },
                
                {
                   key: "action",
                   label: "Action",
                },
            ],
        }
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllBookedCount('unarchive');
            }
        },
        dispatchedKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllDispatchedCount();
            }
        },
        deliveredKeyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.getAllDeliveredCount();
            }
        }
    },
    // beforeDestroy(){
    //         axios.defaults.headers.common["tenant-uuid"] = localStorage.getItem('tenant-uuid')
    //     },
    mounted(){
        return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem('refreshToken'))
                    .then((response) => {
                        this.getAllBookedCount('unarchive');
                        resolve(response);
                    }).catch((err) => {
                        reject(err);
                    });
        });
        
    },
    methods:{
        getAgentOrders(){
            return new Promise((resolve, reject) => {
                let data={
                    agentId:this.agentId
                }
                    this.loading = true
                    BookBundleService.getAgentOrders(this.currentPage, this.pageCount,data,this.archivedOrderTable)
                        .then((response) => {
                            this.booked = response.data;
                            this.loading = false
                            resolve(response);
    
                        })
                        .catch((err) => {
                            reject(err);
    
                        });
                });
        },
        tabChange(tab){
            this.currentPage=1;
            this.totalCount=0;
            if (tab=='dispatched') {
                this.getAllDispatchedCount() 
            }
            else if(tab=='delivered'){
                this.getAllDeliveredCount()
            }
            else if(tab=='archiveOrder'){
                this.archivedOrderTable=true;
                this.getAllBookedCount('archive')
            }
            else if (tab == 'cod') {
                this.isCod=true;
                this.archivedOrderTable=false;
                this.getAllBookedCount('unarchive')
            } else {
                this.isCod=false;
                this.archivedOrderTable=false;
                this.getAllBookedCount('unarchive')
            }
            
        },
        dispatched: function() {
            this.$refs.modal.hide();
            let data = {
                trackingId:this.booked.trackingId,
                orderId:this.booked.id,
            }
            return new Promise((resolve, reject) => {
                    BookBundleService.saveDispatched(data)
                        .then((response) => {
                            // this.dispatchedTenent();
                            this.getAllBookedCount('unarchive');
                            this.disabledButton=false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
        },
        delivered: function(delivered) {
            this.$refs.modal.hide();
            let data = {
                orderId:delivered.id,
            }
            return new Promise((resolve, reject) => {
                    BookBundleService.saveDelivered(data)
                        .then((response) => {
                            // this.dispatchedTenent();
                            this.getAllDispatchedCount();
                            this.disabledButton=false;
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
        },
        // dispatchedTenent: function() {
        //     axios.defaults.headers.common["tenant-uuid"] = this.booked.teamId
        //     this.$refs.modal.hide();
        //     let data = {
        //         trackingId:this.booked.trackingId,
        //         orderId:this.booked.orderId,
        //     }
        //     return new Promise((resolve, reject) => {
        //             BookBundleService.saveDispatched(data)
        //                 .then((response) => {
        //                     axios.defaults.headers.common["tenant-uuid"] =""
        //                     this.getAllBookedCount();
        //                     resolve(response);
        //                 })
        //                 .catch((err) => {
        //                     axios.defaults.headers.common["tenant-uuid"] =""
        //                     reject(err);
        //                 });
        //         });
        // },
        getAllBookedCount: function (archive) {
                this.loading = true;
                this.currentPage = 1;
                this.searchPagination=false;
                this.keyword = "";
                 
                if (archive=='archive') {
                    this.archivedOrderTable=true
                }else{
                    this.archivedOrderTable=false
                }
                let data={
                    agentId:"admin",
                    archive:this.archivedOrderTable,
                    paymentMethod:this.isCod?'cod':'others'
                }
                return new Promise((resolve, reject) => {
                    BookBundleService.getAllBookedCount(data)
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.totalCount = response.data
                            this.getAllBooked(this.currentPage, this.pageCount,this.archivedOrderTable);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
        },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        switchPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllBooked(currentPage, pageCount,this.archivedOrderTable);
            }
            else{
                this.getBookedSearch(currentPage, pageCount)
            }
        },
        switchDispatchedPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllDispatched(currentPage, pageCount);
            }
            else{
                this.getDispatchedSearch(currentPage, pageCount)
            }
        },
        switchDeliveredPage: function (currentPage, pageCount) {
            console.log("dsaf");
            this.loading = true;
            if (!this.searchPagination) {
                this.getAllDelivered(currentPage, pageCount);
            }
            else{
                this.getDeliveredSearch(currentPage, pageCount)
            }
        },
        getAllBooked(currentPage, pageCount,archive){
            let data={
                    agentId:"admin",
                    archive:archive,
                    paymentMethod:this.isCod?'cod':'others'
                }
            return new Promise((resolve, reject) => {
                    this.loading = true
                    BookBundleService.getAllBooked(currentPage, pageCount,data)
                        .then((response) => {
                            this.allBooked = response.data;
                            this.loading = false
                            resolve(response);
    
                        })
                        .catch((err) => {
                            reject(err);
    
                        });
                });
        },
        // archive: function (orderId) {
        //     let data={
        //         orderId:orderId
        //     }
        //     return new Promise((resolve, reject) => {
        //         BookBundleService.archiveOrder(data)
        //             .then(response => {
        //                 this.$swal('Archive successfully!!!!!');
        //                 this.getAllBookedCount('unarchive');

        //                 //  console.log(response.data);
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        // unarchive: function (orderId) {
        //     let data={
        //         orderId:orderId
        //     }
        //     return new Promise((resolve, reject) => {
        //         BookBundleService.unArchiveOrder(data)
        //             .then(response => {
        //                 this.$swal('UnArchive successfully!!!!!');
        //                 this.getAllBookedCount('archive');

        //                 //  console.log(response.data);
        //                 resolve(response);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },
        getBookedSearchCount: function (keyword) {
            this.keyword = keyword;
            this.searchPagination=true;
            console.log("keyword", this.keyword, " ==", keyword);
            this.loading = true;
            this.currentPage = 1;

            let data = {
                agentId: 'admin',
                paymentMethod:this.isCod?'cod':'others'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getBookedSearchCount(data, keyword)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getBookedSearch(this.currentPage, this.pageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getBookedSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin',
                paymentMethod:this.isCod?'cod':'others'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getBookedSearch(currentPage, pageCount, data, this.keyword)
                    .then((response) => {
                        this.allBooked = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getAllDispatchedCount: function () {
                this.loading = true;
                this.searchPagination=false;
                this.dispatchedCurrentPage = 1;
                this.keyword = "";
                let data={
                    agentId:'admin'
                }
                return new Promise((resolve, reject) => {
                    BookBundleService.getAllDispatchedCount(data)
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.totalCount = response.data
                            this.getAllDispatched(this.dispatchedCurrentPage, this.dispatchedPageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            },
        getAllDispatched(currentPage, pageCount){
            let data={
                    agentId:'admin'
                }
            return new Promise((resolve, reject) => {
                    this.loading = true
                    BookBundleService.getAllDispatched(currentPage, pageCount,data)
                        .then((response) => {
                            this.allDispatched = response.data;
                            this.loading = false
                            resolve(response);
    
                        })
                        .catch((err) => {
                            reject(err);
    
                        });
                });
        },
        getDispatchedSearchCount: function (keyword) {
            this.searchPagination=true;
            this.dispatchedKeyword = keyword;
            console.log("keyword", this.dispatchedKeyword, " ==", keyword);
            this.loading = true;
            this.dispatchedCurrentPage = 1;
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getDispatchedSearchCount(data, keyword)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getDispatchedSearch(this.dispatchedCurrentPage, this.dispatchedPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getDispatchedSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getDispatchedSearch(currentPage, pageCount, data, this.dispatchedKeyword)
                    .then((response) => {
                        this.allDispatched = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
        getAllDeliveredCount: function () {
            this.searchPagination=false;
            let data={
                    agentId:'admin'
                }
                this.loading = true;
                this.deliveredCurrentPage = 1;
                this.keyword = "";
                return new Promise((resolve, reject) => {
                    BookBundleService.getAllDeliveredCount(data)
                        .then((response) => {
                            // console.log("Count",response.data)
                            this.totalCount = response.data
                            this.getAllDelivered(this.deliveredCurrentPage, this.deliveredPageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
        },
        getAllDelivered(currentPage, pageCount){
            let data={
                    agentId:'admin'
                }
            return new Promise((resolve, reject) => {
                    this.loading = true
                    BookBundleService.getAllDelivered(currentPage, pageCount,data)
                        .then((response) => {
                            this.allDelivered = response.data;
                            this.loading = false
                            resolve(response);
    
                        })
                        .catch((err) => {
                            reject(err);
    
                        });
                });
        },
        getDeliveredSearchCount: function (keyword) {
            this.searchPagination=true;
            this.deliveredKeyword = keyword;
            console.log("keyword", this.deliveredKeyword, " ==", keyword);
            this.loading = true;
            this.deliveredCurrentPage = 1;
            this.keyword = "";
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                BookBundleService.getDeliveredSearchCount(data, keyword)
                    .then((response) => {
                        // console.log("Count",response.data)
                        this.totalCount = response.data
                        this.getDeliveredSearch(this.deliveredCurrentPage, this.deliveredPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getDeliveredSearch(currentPage, pageCount) {
            let data = {
                agentId: 'admin'
            }
            return new Promise((resolve, reject) => {
                this.loading = true
                BookBundleService.getDeliveredSearch(currentPage, pageCount, data, this.deliveredKeyword)
                    .then((response) => {
                        this.allDelivered = response.data;
                        this.loading = false
                        resolve(response);

                    })
                    .catch((err) => {
                        reject(err);

                    });
            });
        },
    }
}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.cellSize {
    max-width: 120px;
}
</style>