import axios from "axios";
export default {

    getRazorpayPaymentCount: function () {
		return new Promise((resolve, reject) => {
			axios.get("razorpay/razorpayPayments/count")
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					this.loading = false;
					reject(err);
				});
		});
	},

    getAllRazorpayPaymentPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios.get('/razorpay/razorpayPayments/all/'+ (currentPage-1) + "/" + pageCount)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    getRazorpayPaymentSearchCount: function(keyword) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/razorpay/search/razorpayPayments/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },

    getAllRazorpayPaymentSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/razorpay/search/razorpayPayments/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
}