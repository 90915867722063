<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container class="my-4 pt-3">
        <!-- <div>
            <b-row class="my-4 pt-3">
                <b-col col lg="6" style="text-align: center">
                    <b-button id="agents" size="sm" variant="outline-primary" @click="tutorShow()" v-bind:class="{ active: tutorActive }">Tutor</b-button>
                </b-col>
                <b-col col lg="6" style="text-align: center">
                    <b-button size="sm" id="agents" variant="outline-primary" @click="archivedTutorShow()" v-bind:class="{ active: archivedTutorActive }">Archived Tutor</b-button>
                </b-col>
            </b-row>
        </div> -->
        <!--popup start for Tutor Register-->
        <b-modal id="addTutor" title="Tutor" hide-footer ref="addTutorModal" no-close-on-backdrop>
            <div class="form-row">
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Tutor Name:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.userName.$model" :class="{
                  'is-invalid': $v.tutor.userName.$error,
                  'is-valid': !$v.tutor.userName.$invalid,
                }" placeholder="Enter your Name">
                        </b-form-input>
                        <div class="valid-feedback">Your Name is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.tutor.userName.required">Name is required</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Tutor Code:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.code.$model" :class="{
                  'is-invalid': $v.tutor.code.$error,
                  'is-valid': !$v.tutor.code.$invalid,
                }" placeholder="Enter your Code">
                        </b-form-input>
                        <div v-if="uniqueCode" style="font-size: 0.875em;color: #dc3545;">
                            <span>{{tutorUnique.code}}</span>
                        </div>
                        <!-- <div v-else> -->
                        <!-- <div class="valid-feedback">Your Code is valid!</div> -->
                        <div class="invalid-feedback">
                            <span v-if="!$v.tutor.code.required">Code is required</span>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Salary Per hr:</label>
                        <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.salary.$model" :class="{
                  'is-invalid': $v.tutor.salary.$error,
                  'is-valid': !$v.tutor.salary.$invalid,
                }" placeholder="Enter your Salary">
                        </b-form-input>
                        <div class="valid-feedback">Your Salary is valid!</div>
                        <div class="invalid-feedback">
                            <span v-if="!$v.tutor.salary.required">Salary is required</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact:</label>
                        <b-form-input type="number" class="form-control" v-model.trim="$v.tutor.contact.$model" :class="{
                  'is-invalid': $v.tutor.contact.$error,
                  'is-valid': !$v.tutor.contact.$invalid,
                }" placeholder="Enter your contact">
                        </b-form-input>
                        <div v-if="uniqueContact" style="font-size: 0.875em;color: #dc3545;">
                            <span>{{tutorUnique.contact}}</span>
                        </div>
                        <!-- <div v-else> -->
                        <!-- <div class="valid-feedback">Your contact is valid!</div> -->
                        <div class="invalid-feedback">
                            <span v-if="!$v.tutor.contact.required">contact is required</span>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group"> -->
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Mail Id:</label>
                        <b-form-input v-model.trim="$v.tutor.email.$model" :class="{
                  'is-invalid': $v.tutor.email.$error,
                  'is-valid': !$v.tutor.email.$invalid,
                }" placeholder="Enter your email">
                        </b-form-input>
                        <div v-if="uniqueEmail" style="font-size: 0.875em;color: #dc3545;">
                            <span>{{tutorUnique.email}}</span>
                        </div>
                        <!-- <div v-else> -->
                        <!-- <div class="valid-feedback">Your email is valid!</div> -->
                        <div class="invalid-feedback">
                            <span v-if="!$v.tutor.email.required">Email is required</span>
                            <span v-if="!$v.tutor.email.isUnique">Enter your proper email</span>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch:</label>
                        <!-- <span class="text-info float-end" v-b-tooltip.hover.top="{ variant: 'info' }" title="Press ctrl to select multiple branch">
                            <font-awesome-icon icon="info-circle" /></span> -->
                        <!-- <b-form-select
                class="form-control"
                v-model="tutor.branches"
                multiple
                :select-size="3"
                :class="{
                  'is-invalid': $v.tutor.branches.$error,
                  'is-valid': !$v.tutor.branches.$invalid,
                }"
              >
                <option value="null" disabled>Select Branch</option>
                <option
                  v-for="item in allBranches"
                  :value="name"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.name }}</strong>
                </option>
              </b-form-select> -->
                        <!-- <b-col> -->
                        <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.tutor.branches.$error,'is-valid': !$v.tutor.branches.$invalid,}">
                            <option value="null" disabled>Select Branch</option>
                            <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                <strong>{{item.name}}</strong>
                            </option>
                        </b-form-select>&nbsp;&nbsp;
                        <div v-if="selectedBranch!=null" class="text-center">
                            <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                        </div>
                        <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="tutor.branches.length != 0">
                            <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in tutor.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                            </b-badge>
                            <!-- <b-badge pill variant="warning" v-for="item in tutor.brancheses" v-bind:key="item.index">{{item.name}}</b-badge> -->
                        </b-card>
                        <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                            <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                        </b-card>
                        <!-- </b-col> -->
                    </div>
                </div>
                <br />
                <div class="text-center">
                    <b-button variant="outline-success" @click="putTutor()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div>
            </div>
        </b-modal>
        <!--popup end for Tutor Register-->
    </b-container>

    <!-- Tutor Table Start -->

    <div class="mt-5" v-if="tutorActive">
        <b-container>
            <b-row align-v="center">
                <!--add Tutor button start-->

                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="addTutorModal()">Add Tutor</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                    <b-form-select class="bnclr" id="bnclr2" v-model="sortSelectedBranch" size="sm" @change="
                            getAllBranchTutor()">
                        <b-form-select-option disabled value="branch">
                            <p>Branches</p>
                        </b-form-select-option>
                        <!-- <b-form-select-option value="allBranches">
                            <p>All Branches</p>
                        </b-form-select-option> -->
                        <option v-for="item in sortAllBranches" :value="item" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select>
                </b-col>
                <!-- <hr>
                <p>Tutor</p> -->
                <hr />
                <b-table responsive hover :items="tutors" :fields="fields" :current-page="currentPage" :per-page="tutors.pageCount" ref="table">
                    <template v-slot:cell(code)="data">
                        {{ data.item.code }}
                    </template>
                    <template v-slot:cell(tutorId)="data">
                        <span class="title"> {{ data.item.tutorId }}</span>
                    </template>
                    <template v-slot:cell(userName)="data">
                        {{ data.item.userName }}
                    </template>
                    <template v-slot:cell(contact)="data">
                        {{ data.item.contact }}
                    </template>
                    <template v-slot:cell(branches)="data">
                        <!-- {{ data.item.branches }} -->
                        <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in data.item.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;
                        </b-badge>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Tutor Details" v-b-modal.viewTutor @click="viewTutor(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button>

                        <!-- <b-button
                variant="danger"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Tutor Details"
                @click="deleteTutor(data.item.tutorId)"
              ><font-awesome-icon icon="trash" />
              </b-button> -->

                        <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Tutor Details" @click="updateModalShow(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button>
                        <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button> -->
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>

    <!-- Tutor Table End-->

    <!-- Archived tutor table -->

    <div class="mt-5" v-if="archivedTutorActive">
        <b-container>
            <b-row align-v="center">
                <!--add Tutor button start-->

                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="addTutorModal()" disabled>Add Tutor</b-button>
                </b-col>
                <b-col col sm="12" md="3" lg="3" id="r1">
                    <input type="search" class="searchbar" v-model="keyword" v-on:keyup.enter="getArchiveTutorSearchCount(keyword)" placeholder="Type a name" />
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <b-button size="sm" variant="outline-primary" class="bnclr" @click="getArchiveTutorSearchCount(keyword)">Search</b-button>
                </b-col>
                <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                    <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                </b-col>
                <b-col sm="6" md="3" lg="2" class="" id="r1">
                    <label class="font mx-1">Per Page</label>
                    <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                    </b-form-select>
                </b-col>
                <b-col col lg="1" md="1" id="r1" sm="6">
                    <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                        <font-awesome-icon icon="sync-alt" />Refresh</span>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-card class="card mt-1">
                <p>Archived Tutor</p>
                <hr />
                <b-table responsive hover :items="tutors" :fields="fields" :current-page="currentPage" :per-page="tutors.pageCount" ref="table">
                    <template v-slot:cell(code)="data">
                        {{ data.item.code }}
                    </template>
                    <template v-slot:cell(tutorId)="data">
                        <span class="title"> {{ data.item.tutorId }}</span>
                    </template>
                    <template v-slot:cell(userName)="data">
                        {{ data.item.userName }}
                    </template>
                    <template v-slot:cell(contact)="data">
                        {{ data.item.contact }}
                    </template>
                    <template v-slot:cell(branches)="data">
                        <!-- {{ data.item.branches }} -->
                        <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in data.item.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;
                        </b-badge>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <!-- <b-button variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View Tutor Details" v-b-modal.viewTutor @click="viewTutor(data.item)">
                            <font-awesome-icon icon="eye" />
                        </b-button> -->

                        <!-- <b-button
                variant="danger"
                v-b-tooltip.hover.bottomright="{ variant: 'danger' }"
                title="Delete Tutor Details"
                @click="deleteTutor(data.item.tutorId)"
              ><font-awesome-icon icon="trash" />
              </b-button> -->

                        <!-- <b-button class="mx-1" variant="info" v-b-tooltip.hover.bottomright="{ variant: 'info' }" title="Update Tutor Details" @click="updateModalShow(data.item)">
                            <font-awesome-icon icon="edit" />
                        </b-button> -->
                        <b-button v-if="data.item.archive == false" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="Archive Tutor Details" variant="success" @click="archive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                        <b-button v-b-tooltip.hover.bottomright="{ variant: 'warning' }" title="UnArchive Tutor Details" v-if="data.item.archive == true" variant="warning" @click="unarchive(data.item.tutorId)">
                            <font-awesome-icon icon="archive" />
                        </b-button>
                    </template>
                </b-table>
            </b-card>
        </b-container>
    </div>

    <!-- Archived tutor table end -->

    <!-- View Tutor Start -->

    <b-container>
        <b-modal id="viewTutor" no-close-on-backdrop hide-footer title="View Tutor">
            <div class="form-control">
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Name</label>
                        <b-form-input type="text" v-model="tutor.userName" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Contact</label>
                        <b-form-input v-model="tutor.contact" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Code</label>
                        <b-form-input v-model="tutor.code" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Salary</label>
                        <b-form-input v-model="tutor.salary" class="form-control" disabled></b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Email</label>
                        <b-form-input class="form-control" disabled v-model="tutor.email">
                        </b-form-input>
                    </div>
                </div>
                <div class="form-row mt-3 px-5">
                    <div class="form-group my-2">
                        <label>Branch</label>
                        <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="tutor.branches.length != 0">
                            <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in tutor.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;
                            </b-badge>
                            <!-- <b-badge pill variant="warning" v-for="item in tutor.branch" v-bind:key="item.index">{{item.name}}</b-badge> -->
                        </b-card>
                    </div>
                </div>
                <br>
                <!-- <div class="form-row mt-3 px-5">
              <div class="form-group my-2">
                <label>Branch</label>
                <b-form-input
                  class="form-control"
                  v-model="tutor.branches"
                  disabled
                >
                </b-form-input>
              </div>
            </div> -->

                <!-- <div class="form-row mt-3 px-5">
            <div class="form-group my-2">
              <label>Branch</label>
              <b-form-select
                class="form-control"
                v-model="tutor.branches"
                disabled
              >
                <option value="null" disabled>Select Branch</option>
                <option
                  v-for="item in allBranches"
                  :value="item"
                  v-bind:key="item.index"
                >
                  <strong>{{ item.name }}</strong>
                </option>
              </b-form-select>
            </div>
          </div> -->

            </div>
        </b-modal>
    </b-container>

    <!-- <b-modal id="viewTutor" ref="my-modal" title="View Tutor" hide-footer>
      <div class="control">
        <b-table-simple>
          <b-tr>
            <b-th>Name :</b-th>
            <b-td>{{ tutor.userName }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Contact :</b-th>
            <b-td>{{ tutor.contact }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Code :</b-th>
            <b-td>{{ tutor.code }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Salary :</b-th>
            <b-td>{{ tutor.salary }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Email-id :</b-th>
            <b-td>{{ tutor.email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Branch :</b-th>
            <b-td>{{ tutor.branches }}</b-td>
          </b-tr>
        </b-table-simple>
      </div>
    </b-modal> -->

    <!-- View Tutor Table End-->

    <!-- Update Tutor Start -->

    <b-modal id="updateTutor" title="Tutor Update" hide-footer ref="updateModal">
        <div class="form-row">
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Tutor Name:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.userName.$model" :class="{
                'is-invalid': $v.tutor.userName.$error,
                'is-valid': !$v.tutor.userName.$invalid,
              }" placeholder="Enter your Name">
                    </b-form-input>
                    <div class="valid-feedback">Your Name is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.tutor.userName.required">Name is required</span>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Tutor Code:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.code.$model" :class="{
                'is-invalid': $v.tutor.code.$error,
                'is-valid': !$v.tutor.code.$invalid,
              }" placeholder="Enter your Code">
                    </b-form-input>
                    <div v-if="uniqueCode" style="font-size: 0.875em;color: #dc3545;">
                        <span>{{tutorUnique.code}}</span>
                    </div>
                    <!-- <div v-else> -->
                    <!-- <div class="valid-feedback">Your Code is valid!</div> -->
                    <div class="invalid-feedback">
                        <span v-if="!$v.tutor.code.required">Code is required</span>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Salary Per hr:</label>
                    <b-form-input type="text" class="form-control" v-model.trim="$v.tutor.salary.$model" :class="{
                'is-invalid': $v.tutor.salary.$error,
                'is-valid': !$v.tutor.salary.$invalid,
              }" placeholder="Enter your Salary">
                    </b-form-input>
                    <div class="valid-feedback">Your Salary is valid!</div>
                    <div class="invalid-feedback">
                        <span v-if="!$v.tutor.salary.required">Salary is required</span>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Contact:</label>
                    <b-form-input type="number" class="form-control" v-model.trim="$v.tutor.contact.$model" :class="{
                'is-invalid': $v.tutor.contact.$error,
                'is-valid': !$v.tutor.contact.$invalid,
              }" placeholder="Enter your contact">
                    </b-form-input>
                    <div v-if="uniqueContact" style="font-size: 0.875em;color: #dc3545;">
                        <span>{{tutorUnique.contact}}</span>
                    </div>
                    <!-- <div v-else> -->
                    <!-- <div class="valid-feedback">Your contact is valid!</div> -->
                    <div class="invalid-feedback">
                        <span v-if="!$v.tutor.contact.required">contact is required</span>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Mail Id:</label>
                    <b-form-input v-model.trim="$v.tutor.email.$model" :class="{
                'is-invalid': $v.tutor.email.$error,
                'is-valid': !$v.tutor.email.$invalid,
              }" placeholder="Enter your email">
                    </b-form-input>
                    <div v-if="uniqueEmail && $v.tutor.email.required" style="font-size: 0.875em;color: #dc3545;">
                        <span>{{tutorUnique.email}}</span>
                    </div>
                    <!-- <div v-else> -->
                    <!-- <div class="valid-feedback">Your email is valid!</div> -->
                    <div class="invalid-feedback">
                        <span v-if="!$v.tutor.email.required">Email is required</span>
                        <span v-if="!$v.tutor.email.isUnique">Enter your proper email id</span>
                    </div>
                    <!-- </div> -->
                </div>
            </div>

            <div class="form-row mt-3 px-5">
                <div class="form-group my-2">
                    <label>Branch:</label>
                    <!-- <span class="text-info float-end" v-b-tooltip.hover.top="{ variant: 'info' }" title="Press ctrl to select multiple branch">
                        <font-awesome-icon icon="info-circle" /></span>
                    <b-form-select class="form-control" v-model="tutor.branches" multiple :select-size="3" :class="{
                'is-invalid': $v.tutor.branches.$error,
                'is-valid': !$v.tutor.branches.$invalid,
              }">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item.branchesId" v-bind:key="item.index">
                            <strong>{{ item.name }}</strong>
                        </option>
                    </b-form-select> -->
                    <b-form-select class="edit shadow-sm form-control" v-model="selectedBranch" :class="{'is-invalid': $v.tutor.branches.$error,'is-valid': !$v.tutor.branches.$invalid,}">
                        <option value="null" disabled>Select Branch</option>
                        <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                            <strong>{{item.name}}</strong>
                        </option>
                    </b-form-select>&nbsp;&nbsp;
                    <div v-if="selectedBranch!=null" class="text-center">
                        <b-button style="border: 1px solid #406ab2ff;border-radius: 1em;background-color:#406ab2ff;" class="btn-sm shadow-sm rounded-0" @click="saveBranches"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Add</b-button>&nbsp;
                    </div>
                    <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-if="tutor.branches.length != 0">
                        <b-badge style="background-color:#ffc107;color:#212529;padding:.5em;margin-right:1em;font-family: 'Pavanam', sans-serif;" pill v-for="item in tutor.branches" v-bind:key="item.index" variant="warning">{{item.name}}&nbsp;<b-icon icon="x" @click="removeBranches(item)" style="font-weight: bold;cursor:pointer;" variant="dark"></b-icon>
                        </b-badge>
                        <!-- <b-badge pill variant="warning" v-for="item in tutor.branches" v-bind:key="item.index">{{item.name}}</b-badge> -->
                    </b-card>
                    <b-card style="border: 1px solid rgba(0, 47, 52, 0.2);border-radius: 1em;" class="shadow-sm mt-2" v-else>
                        <div class="text-center text-secondary" style="font-weight:bold;">None Of The Branches Added!</div>
                    </b-card>
                </div>
            </div>

            <!-- <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Branch</label>
            <b-form-select
              class="form-control"
              v-model="tutor.branches"
              :class="{
                'is-invalid': $v.tutor.branches.$error,
                'is-valid': !$v.tutor.branches.$invalid,
              }"
            >
              <option value="null" disabled>Select Branch</option>
              <option
                v-for="item in allBranches"
                :value="item"
                v-bind:key="item.index"
              >
                <strong>{{ item.name }}</strong>
              </option>
            </b-form-select>
          </div>
        </div> -->

            <br />
            <div class="text-center pt-3">
                <b-button size="sm" id="submit" variant="outline-danger" @click="updateTutor()">Update</b-button><br />
            </div>
            <!-- <div class="text-center">
                    <b-button variant="outline-success" @click="putTutor()">Submit</b-button>
                    <b-button variant="outline-danger" class="mx-3" @click="resetForm()">Reset</b-button>
                </div> -->
        </div>
    </b-modal>

    <!-- <b-modal id="updateTutors" title="Update Tutor" ref="modal" hide-footer>
      <div class="form-row">
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Name:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model.trim="$v.tutor.userName.$model"
              :class="{
                'is-invalid': $v.tutor.userName.$error,
                'is-valid': !$v.tutor.userName.$invalid,
              }"
            >
            </b-form-input>
          </div>
        </div>
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Tutor Code:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model.trim="$v.tutor.code.$model"
              :class="{
                'is-invalid': $v.tutor.code.$error,
                'is-valid': !$v.tutor.code.$invalid,
              }"
            >
            </b-form-input>
          </div>
        </div>
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Salary Per hr:</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model.trim="$v.tutor.salary.$model"
              :class="{
                'is-invalid': $v.tutor.salary.$error,
                'is-valid': !$v.tutor.salary.$invalid,
              }"
            >
            </b-form-input>
          </div>
        </div>
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Contact:</label>
            <b-form-input
              type="number"
              class="form-control"
              v-model.trim="$v.tutor.contact.$model"
              :class="{
                'is-invalid': $v.tutor.contact.$error,
                'is-valid': !$v.tutor.contact.$invalid,
              }"
            >
            </b-form-input>
          </div>
        </div>
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Mail Id:</label>
            <b-form-input
              type="email"
              v-model.trim="$v.tutor.email.$model"
              :class="{
                'is-invalid': $v.tutor.email.$error,
                'is-valid': !$v.tutor.email.$invalid,
              }"
            >
            </b-form-input>
          </div>
        </div>
        <div class="form-row mt-3 px-5">
          <div class="form-group my-2">
            <label>Branch</label>
            <b-form-select
              class="form-control"
              v-model="tutor.branches"
              :class="{
                'is-invalid': $v.tutor.branches.$error,
                'is-valid': !$v.tutor.branches.$invalid,
              }"
            >
              <option value="null" disabled>Select Branch</option>
              <option
                v-for="item in allBranches"
                :value="item"
                v-bind:key="item.index"
              >
                <strong>{{ item.name }}</strong>
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="text-center pt-3">
          <b-button
            size="sm"
            id="submit"
            variant="outline-danger"
            @click="updateTutor()"
            >Update</b-button
          ><br />
        </div>
      </div>
    </b-modal> -->

    <!-- Update Tutor End  -->

    <!-- <Loader /> -->
</div>
</template>

<script>
// import Loader from  './Loader';

import TutorService from "../service/TutorService";
import BranchService from "../service/BranchService";
// import AgentService from "../service/AgentService";
import Account from '../service/Account.js';
import {
    required,
    minLength,
    maxLength,
    email,
} from "vuelidate/lib/validators";
import axios from 'axios';
// import axios from 'axios'

export default {
    name: "TutorAdminSupervisor",
    components: {},
    data() {
        return {
            paginationTutors: true,
            searchPaginationTutors: false,
            sortBranchTutor: false,
            loading: false,
            rows: 100,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [5, 10, 15, {
                value: 100,
                text: "Show a lot"
            }, ],
            totalCount: 0,
            keyword: "",
            tutor: {
                userName: "",
                code: "",
                salary: "",
                contact: "",
                email: "",
                branches: [],
            },
            tutors: [],
            fields: [{
                    key: "code",
                    label: "code",
                },
                {
                    key: "userName",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "email",
                    label: "E-mail",
                },
                {
                    key: "branches",
                    label: "Branches",
                },
                {
                    key: "actions",
                    label: "Actions",
                },
            ],
            selectedBranch: "Branch",
            allBranches: [],
            uniqueCode: false,
            uniqueContact: false,
            uniqueEmail: false,
            tutorUnique: null,
            tutorActive: true,
            archivedTutorActive: false,
            tutorDetails : [],
            sortAllBranches: [],
            sortSelectedBranch:"branch",
        };
    },
    validations: {
        tutor: {
            userName: {
                required,
                minLength: minLength(3),
            },
            code: {
                required,
                minLength: minLength(3),
            },
            salary: {
                required,
                minLength: minLength(3),
            },
            contact: {
                required,
                Number,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            email: {
                required,
                email,
                isUnique(value) {
                    if (value === "") return true;
                    var email_regex =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(email_regex.test(value));
                        }, 350 + Math.random() * 300);
                    });
                },
            },
            branches: {
                required,
            },
        },

        //  update: {
        //     userName: {
        //         required,
        //         minLength: minLength(3),
        //     },
        //     code: {
        //         required,
        //         minLength: minLength(3),
        //     },
        //     salary: {
        //         required,
        //         minLength: minLength(3),
        //     },
        //     contact: {
        //         required,
        //         Number,
        //         minLength: minLength(10),
        //         maxLength: maxLength(10),
        //     },

        //     email: {
        //         required,
        //         email,
        //         isUnique(value) {
        //             if (value === "") return true;
        //             var email_regex =
        //                 /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //             return new Promise((resolve) => {
        //                 setTimeout(() => {
        //                     resolve(email_regex.test(value));
        //                 }, 350 + Math.random() * 300);
        //             });
        //         },
        //     },
        //     branch: {
        //         required,
        //     },
        // },
    },
    mounted() {
        let self = this;
        return new Promise((resolve, reject) => {
            Account.getAccessToken(localStorage.getItem("refreshToken"))
                .then((response) => {
                    // self.getAllTutors();
                    self.getSortAllBranches();
                    self.getAllBranchTutor();
                    self.loading = true;
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        // this.getAllTutors();
        // Global.refreshToken();
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                if (this.tutorActive) {
                    this.getAllBranchTutor();
                } else if (this.archivedTutorActive) {
                    this.getArchiveTutorCount();
                }
            }
        },
    },
    methods: {
        getAllBranchTutor: function() {
            this.loading = true;
            this.paginationTutors = false;
            this.searchPaginationTutors = false;
            this.sortBranchTutor = true;
            this.currentPage = 1;
            if(this.sortSelectedBranch == "allBranches") {
                // console.log("getAllBranchTutor======= ",branch);
                this.getCount();
            } else {
                // console.log("getAllBranchTutor======= ",branch);
                let data = {
                    branchObj : this.sortSelectedBranch
                }
                return new Promise((resolve, reject) => {
                    axios.post("tutor/branch/getAll",data)
                        .then((response) => {
                            this.tutorDetails = response.data;
                            this.totalCount = this.tutorDetails.length;
                            this.tutors = this.tutorDetails.slice(0, this.pageCount);
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        tutorShow() {
            this.tutorActive = true;
            this.archivedTutorActive = false;
            this.sortSelectedBranch = "branch";
            if (this.keyword == "") {
                this.getAllBranchTutor();
            } else {
                this.keyword = "";
            }
        },
        archivedTutorShow() {
            this.tutorActive = false;
            this.archivedTutorActive = true;
            if (this.keyword == "") {
                this.getArchiveTutorCount();
            } else {
                this.keyword = "";
            }
        },
        async addTutorModal() {
            await this.resetForm();
            await this.getAllBranches();
            this.$refs.addTutorModal.show();
        },
        putTutor: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitstatus = "FAIL";
            } else {
                return new Promise((resolve, reject) => {
                    axios.post("tutor/insert/unique", this.tutor)
                        .then((response) => {
                            console.log("response.data===== ", response.data);
                            this.tutorUnique = response.data;
                            if (this.tutorUnique.code != null) {
                                this.uniqueCode = true;
                                console.log("this.tutorUnique.code=== ", this.tutorUnique.code);
                            } else if (this.tutorUnique.contact != null) {
                                this.uniqueContact = true;
                                console.log("this.tutorUnique.contact=== ", this.tutorUnique.contact);
                            } else if (this.tutorUnique.email != null) {
                                this.uniqueEmail = true;
                                console.log("this.tutorUnique.email=== ", this.tutorUnique.email);
                            } else {
                                console.log("========else========");
                                this.uniqueCode = false;
                                this.uniqueContact = false;
                                this.uniqueEmail = false;
                                this.submitstatus = "SUCCESS";
                                return new Promise((resolve, reject) => {
                                    TutorService.insertTutor(this.tutor)
                                        .then((response) => {
                                            this.$swal({
                                                title: "Good job!",
                                                text: "Tutor Added successfully!!!!!",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.addTutorModal.hide();
                                            this.getAllBranchTutor();
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        removeBranches: function (item) {
            const langIndex = this.tutor.branches.findIndex(x => x === item);
            if (langIndex !== undefined)
                this.tutor.branches.splice(langIndex, 1);
            this.allBranches.push(item);
        },
        saveBranches: function () {
            // console.log("======");
            if (!this.tutor.branches.some(entry => entry === this.selectedBranch)) {
                // console.log("if==");
                for (var i = 0; i < this.allBranches.length; i++) {
                    if (this.allBranches[i] == this.selectedBranch) {
                        this.tutor.branches.push(this.allBranches[i]);
                        this.allBranches.splice([i], 1);
                    }
                }
            }
            this.selectedBranch = null;
        },
        async updateModalShow(tutor) {
            this.selectedBranch = null;
            await this.resetForm();
            await this.getAllBranches();
            await this.viewTutor(tutor);
            this.$refs.updateModal.show();
        },
        updateTutor: function () {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitstatus = "FAIL";
            } else {
                this.uniqueCode = false;
                this.uniqueContact = false;
                this.uniqueEmail = false;
                return new Promise((resolve, reject) => {
                    axios.post("tutor/update/unique", this.tutor)
                        .then((response) => {
                            console.log("update unique===== ", response.data);
                            this.tutorUnique = response.data;
                            if (this.tutorUnique.code != null) {
                                this.uniqueCode = true;
                                console.log("this.tutorUnique.code=== ", this.tutorUnique.code);
                            } else if (this.tutorUnique.contact != null) {
                                this.uniqueContact = true;
                                console.log("this.tutorUnique.contact=== ", this.tutorUnique.contact);
                            } else if (this.tutorUnique.email != null) {
                                this.uniqueEmail = true;
                                console.log("this.tutorUnique.email=== ", this.tutorUnique.email);
                            } else {
                                console.log("========else========");
                                this.uniqueCode = false;
                                this.uniqueContact = false;
                                this.uniqueEmail = false;
                                this.submitstatus = "SUCCESS";
                                return new Promise((resolve, reject) => {
                                    TutorService.updateTutor(this.tutor)
                                        .then((response) => {
                                            console.log("updateTutor Calling");
                                            // this.$swal("Tutor details updated successfully!!!!!");
                                            this.$swal({
                                                title: "Good job!",
                                                text: "Tutor updated successfully!!!!!",
                                                icon: "success",
                                                button: "ok",
                                            });
                                            this.$refs.updateModal.hide();
                                            this.getCount();
                                            resolve(response);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                });
                            }
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },

        // updateTutor: function () {
        //   //   this.$v.update.$touch();
        //   //   if (this.$v.update.$invalid) {
        //   //     this.submitStatus = "FAIL";
        //   //   }
        //   //   else if (this.updateNumber != this.update.contact) {
        //   //     // //console.log("Not same")
        //   //     this.$swal("Mobile number is not same");
        //   //   }
        //   //   else {
        //   return new Promise((resolve, reject) => {
        //     TutorService.updateTutor(this.update.tutorId, this.update)
        //       .then((response) => {
        //         console.log("updateTutor Calling");
        //         this.$swal("Tutor details updated successfully!!!!!");
        //         this.$swal(response.data);
        //         this.$refs.modal.hide();
        //         // this.getAllCalls();
        //         this.getCount();
        //         resolve(response);
        //       })
        //       .catch((err) => {
        //         reject(err);
        //       });
        //   });
        //   //   }
        // },
        getSortAllBranches: function () {
			this.sortAllBranches = JSON.parse(localStorage.getItem('branches'));
			this.sortSelectedBranch = this.sortAllBranches[0];
            // this.allBranches.splice(0);
            // return new Promise((resolve, reject) => {
            //     BranchService.getAllBranches()
            //         .then((response) => {
            //             this.sortAllBranches = response.data;
            //             resolve(response);
            //         })
            //         .catch((err) => {
            //             reject(err);
            //         });
            // });
        },
        getAllBranches: function () {
            this.allBranches.splice(0);
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // getTutor: function (tutor) {
        //     this.tutor.branches.splice(0);
        //     this.$refs.updateModal.show();
        //     // this.getAllBranches();
        //     return new Promise((resolve, reject) => {
        //         axios.post("/tutor/get/" + tutor.code)
        //             .then((response) => {
        //                 // this.allBranches = response.data;
        //                 let tutor = response.data;
        //                 for (var i = 0; i < tutor.length; i++) {
        //                     this.tutor.tutorId = tutor[i].tutorId;
        //                     this.tutor.userName = tutor[i].userName;
        //                     this.tutor.code = tutor[i].code;
        //                     this.tutor.salary = tutor[i].salary;
        //                     this.tutor.contact = tutor[i].contact;
        //                     this.tutor.email = tutor[i].email;
        //                     this.tutor.branches.push(tutor[i].branches);
        //                     for (var j = 0; j < this.allBranches.length; j++) {
        //                         if (tutor[i].branches.branchId == this.allBranches[j].branchId) {
        //                             this.allBranches.splice([j], 1);
        //                         }
        //                     }
        //                 }
        //                 this.selectedBranch = null;
        //                 resolve(response);
        //             })
        //             .catch((err) => {
        //                 reject(err);
        //             });
        //     });
        // },
        viewTutor: function (tutor) {
            this.tutor = tutor;
            for (var i = 0; i < tutor.branches.length; i++) {
                for (var j = 0; j < this.allBranches.length; j++) {
                    if (tutor.branches[i].branchId == this.allBranches[j].branchId) {
                        // console.log("=======if");
                        this.allBranches.splice([j], 1);
                    }
                }
            }
            // this.tutor.branches.splice(0);
            // // this.$refs.updateModal.show();
            // // this.getAllBranches();
            // return new Promise((resolve, reject) => {
            //     axios.post("/tutor/get/" + tutor.tutorId)
            //         .then((response) => {
            //             this.tutor = response.data;
            //             this.selectedBranch = null;
            //             resolve(response);
            //         })
            //         .catch((err) => {
            //             reject(err);
            //         });
            // });
        },
        getCount: function () {
            this.loading = true;
            this.paginationTutors = true;
            this.searchPaginationTutors = false;
            this.sortBranchTutor = false;
            // this.keyword = "";
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                TutorService.getTutorCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            this.tutors.splice(0);
            return new Promise((resolve, reject) => {
                TutorService.getAllTutorPageable(currentPage, pageCount)
                    .then((response) => {
                        if (response.data != null) {
                            this.tutors = response.data;
                            // for(var i=0;i<response.data.length;i++) {
                            //     let tutor = {
                            //         code : response.data[i].code,
                            //         archive : response.data[i].archive,
                            //         contact : response.data[i].contact,
                            //         createdAt : response.data[i].createdAt,
                            //         email : response.data[i].email,
                            //         salary : response.data[i].salary,
                            //         tutorId : response.data[i].tutorId,
                            //         updatedAt : response.data[i].updatedAt,
                            //         userName : response.data[i].userName,
                            //         branches:[],
                            //     }
                            //     for(var j=0;j<response.data[i].branches.length;j++) {
                            //         tutor.branches.push(response.data[i].branches[j]);
                            //     }
                            //     this.tutors.push(tutor);
                            // }
                        }
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getSearchCount: function (keyword) {
            this.paginationTutors = false;
            this.searchPaginationTutors = true;
            this.sortBranchTutor = false;
            this.currentPage = 1;
            return new Promise((resolve, reject) => {
                TutorService.getSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("Count", response.data);
                        this.getAllSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getAllSearchPageable: function (currentPage, pageCount, keyword) {
            this.tutors.splice(0);
            return new Promise((resolve, reject) => {
                TutorService.getAllSearchPageable(
                        currentPage,
                        pageCount,
                        // this.tutorId,
                        keyword
                    )
                    .then((response) => {
                        if (response.data != null) {
                            this.tutors = response.data;
                        }
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            this.loading = true;
            if (this.tutorActive) {
                if (this.paginationTutors) {
                    this.getAllPageable(currentPage, pageCount);
                } else if (this.searchPaginationTutors) {
                    this.getAllSearchPageable(currentPage, pageCount, this.keyword);
                } else if (this.sortBranchTutor) {
                    this.tutors = this.tutorDetails.slice((this.currentPage-1)*this.pageCount, ((this.currentPage-1)*this.pageCount)+this.pageCount);
                    this.loading = false;
                }
            } else if (this.archivedTutorActive) {
                if (this.paginationTutors) {
                    this.getAllArchiveTutorPageable(currentPage, pageCount);
                } else if (this.searchPaginationTutors) {
                    this.getArchiveTutorSearchPageable(currentPage, pageCount, this.keyword);
                }
            }
        },
        getArchiveTutorCount: function () {
            this.loading = true;
            this.paginationTutors = true;
            this.searchPaginationTutors = false;
            this.sortBranchTutor = false;
            // this.keyword = "";
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get("tutor/archive/count")
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllArchiveTutorPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllArchiveTutorPageable: function (currentPage, pageCount) {
            this.tutors.splice(0);
            return new Promise((resolve, reject) => {
                axios.post("tutor/archive/getAll/" + (currentPage - 1) + "/" + pageCount)
                    .then((response) => {
                        if (response.data != null) {
                            this.tutors = response.data;
                        }
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getArchiveTutorSearchCount: function (keyword) {
            this.loading = true;
            this.paginationTutors = false;
            this.searchPaginationTutors = true;
            this.sortBranchTutor = false;
            // this.currentPage = 1;
            return new Promise((resolve, reject) => {
                axios.get("tutor/archive/search/count/" + keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        // console.log("Count", response.data);
                        this.getArchiveTutorSearchPageable(this.currentPage, this.pageCount, keyword.toLowerCase());
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getArchiveTutorSearchPageable: function (currentPage, pageCount, keyword) {
            this.tutors.splice(0);
            return new Promise((resolve, reject) => {
                axios.post("tutor/archive/search/getAll/" + (currentPage - 1) + "/" + pageCount + "/" + keyword.toLowerCase())
                    .then((response) => {
                        if (response.data != null) {
                            this.tutors = response.data;
                        }
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getAllTutors: function () {
            this.loading = true;
            return new Promise((resolve, reject) => {
                TutorService.getAllTutors()
                    .then((response) => {
                        this.tutors = response.data;
                        // this.tutor.forEach(e => {
                        //   console.log(e);
                        // })
                        resolve(response);
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        archive: function (id) {
            return new Promise((resolve, reject) => {
                TutorService.archive(id)
                    .then((response) => {
                        this.$swal("Archive successfully!!!!!");
                        if (this.tutorActive) {
                            this.getCount();
                        } else if (this.archivedTutorActive) {
                            this.getArchiveTutorCount();
                        }
                        //  console.log(response.data);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        unarchive: function (id) {
            return new Promise((resolve, reject) => {
                TutorService.unarchive(id)
                    .then((response) => {
                        this.$swal("UnArchive successfully!!!!!");
                        if (this.tutorActive) {
                            this.getCount();
                        } else if (this.archivedTutorActive) {
                            this.getArchiveTutorCount();
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        refresh() {
            this.keyword = "";
            this.currentPage = 1;
            if (this.tutorActive) {
                this.getAllBranchTutor();
                this.sortSelectedBranch = this.allBranches[0];
            } else if (this.archivedTutorActive) {
                this.getArchiveTutorCount();
            }
        },
        resetForm() {
            this.tutor = {
                userName: "",
                code: "",
                salary: "",
                contact: "",
                email: "",
                branches: [],
            };
            this.tutor.branches.splice(0);
            this.getAllBranches();
            this.selectedBranch = null;
            this.uniqueCode = false;
            this.uniqueContact = false;
            this.uniqueEmail = false;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
};
</script>

<style scoped>
.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
}

#bnclr2 {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 2% 5%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin:-60px 0 0 -60px;
  -webkit-animation:spin 0.5s linear infinite;
  -moz-animation:spin 0.5s linear infinite;
  animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.px-3 {
    margin-left: 2px;
    height: 33px;
    margin-bottom: 2px;
}
</style>
