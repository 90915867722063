<template>
<div>
    <div v-if="loading" class="loader">
        <img src="../../public/encore.svg" class="spinner" width="30" height="30">
    </div>
    <b-container>
        <b-tabs pills card justified>
            <b-tab title="Campaign" active @click="getAllSortCount('Agent', 'Course', 'online', 'Source', 'Branch', null)">
                <div class="mt-3">
                    <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                        <div>
                            <label for="from-datepicker">Choose The From date</label>
                            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid,}" type="date"></b-form-input>
                        </div>
                        <div>
                            <label for="to-datepicker">Choose The To date</label>
                            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid,}" type="date"></b-form-input>
                        </div>
                        <div class="text-center mt-3">
                            <b-button variant="outline-success" type="submit" @click="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">Submit</b-button>
                        </div>
                    </b-modal>
                    <b-modal ref="showCampaign" hide-footer title="Campaign Details">
                        <div class="text-center px-2">
                            <h6>The campaign is delivered to <span class="text-primary text-bold">{{totalCount}} Candidates</span></h6>
                            <hr>
                        </div>
                        <div class="form-row mt-3 px-5">
                            <div class="form-group my-2">
                                <label>Course </label>
                                <b-form-input type="text" v-model="showCampaignData.course" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>Source </label>
                                <b-form-input type="text" v-model="showCampaignData.source" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>Mode </label>
                                <b-form-input type="text" v-model="showCampaignData.mode" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>Agent </label>
                                <b-form-input type="text" v-model="showCampaignData.agent" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>Branch </label>
                                <b-form-input type="text" v-model="showCampaignData.branch" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>Start Date </label>
                                <b-form-input type="text" v-model="showCampaignData.from" class="form-control" disabled></b-form-input>
                            </div>
                            <div class="form-group my-2">
                                <label>End Date </label>
                                <b-form-input type="text" v-model="showCampaignData.to" class="form-control" disabled></b-form-input>
                            </div>
                        </div>
                        <!-- <b-row>
                            <b-col class="col-5 text-end">
                                <div class="">Course</div>
                                <div class="">Source</div>
                                <div class="">Mode</div>
                                <div class="">Agent</div>
                                <div class="">Branch</div>
                                <div class="">Start Date</div>
                                <div class="">End Date</div>
                            </b-col>
                            <b-col class="col-2 text-center">
                                <div>:</div>
                                <div>:</div>
                                <div>:</div>
                                <div>:</div>
                                <div>:</div>
                                <div>:</div>
                                <div>:</div>
                            </b-col>
                            <b-col class="col-5 text-start">
                                <div class="">{{showCampaignData.course}}</div>
                                <div class="">{{showCampaignData.source}}</div>
                                <div class="">{{showCampaignData.mode}}</div>
                                <div class="">{{showCampaignData.agent}}</div>
                                <div class="">{{showCampaignData.branch}}</div>
                                <div class="">{{showCampaignData.from}}</div>
                                <div class="">{{showCampaignData.to}}</div>
                            </b-col>
                        </b-row> -->
                        <div class="pt-2">
                            <!-- header="Examsdaily Online" -->
                            <!-- header-text-variant="white" -->
                            <div class="form-row px-5">
                                <div class="form-group">
                                    <b-form-select v-model="selectTemplate" :class="{'is-invalid': $v.selectTemplate.$error,'is-valid': !$v.selectTemplate.$invalid,}" size="sm" class=" form-control">
                                        <option value="null" disabled>Select Template</option>
                                        <option v-for="w in whatsappTemplates" :value="w" v-bind:key="w.index">
                                            <strong>{{ w.templateName }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div>
                            <!-- <div class="form-row px-5">
                                <div class="form-group">
                                    <label>Select Template</label>
                                    <b-form-select v-if="examsdailyTemp" v-model="selectTemplate" :class="{'is-invalid': $v.selectTemplate.$error,'is-valid': !$v.selectTemplate.$invalid,}" size="sm" class=" form-control">
                                        <option value="null" disabled>Select Template</option>
                                        <option v-for="q in examsdailyOnlineTemplate" :value="q.value" v-bind:key="q.index">
                                            <strong>{{ q.text }}</strong>
                                        </option>
                                    </b-form-select>
                                    <b-form-select v-if="dexterTemp && dexterOnline" v-model="selectTemplate" :class="{'is-invalid': $v.selectTemplate.$error,'is-valid': !$v.selectTemplate.$invalid,}" size="sm" class=" form-control">
                                        <option value="null" disabled>Select Template</option>
                                        <option v-for="q in dexterOnlineTemplate" :value="q.value" v-bind:key="q.index">
                                            <strong>{{ q.text }}</strong>
                                        </option>
                                    </b-form-select>
                                    <b-form-select v-if="dexterTemp && dexterOffline" v-model="selectTemplate" :class="{'is-invalid': $v.selectTemplate.$error,'is-valid': !$v.selectTemplate.$invalid,}" size="sm" class=" form-control">
                                        <option value="null" disabled>Select Template</option>
                                        <option v-for="q in dexterOfflineTemplate" :value="q.value" v-bind:key="q.index">
                                            <strong>{{ q.text }}</strong>
                                        </option>
                                    </b-form-select>
                                </div>
                            </div> -->
                            <b-card v-if="selectTemplate != null" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <!-- {{ selectTemplate }} -->
                                        <p v-if="'name' in selectTemplate.value.bodyValues">Hi, <span class="text-primary">Name</span></p>
                                        <p v-if="selectTemplate.description != null" v-html="selectTemplate.description"></p>
                                        <span v-if="'headerValues' in selectTemplate.value">
                                            <!-- <p v-if="'media_name' in selectTemplate.value.headerValues"><span class="text-primary">Course Name</span></p> -->
                                            <p>You have enquired with us for the course <span class="text-primary" v-if="'media_name' in selectTemplate.value.headerValues">Course Name</span>.
                                             You can find the details of the online courses in the below link.</p>
                                            <a v-if="'media_url' in selectTemplate.value.headerValues" href="https://learn.examsdaily.in/Courses">Course Link</a>
                                        </span>
                                        <span v-else>
                                            <p v-if="'course' in selectTemplate.value.bodyValues">You have enquired with us for the course <span class="text-primary" v-if="'course' in selectTemplate.value.bodyValues || 'headerValues' in selectTemplate.value">Course Name</span>.</p>
                                            <!-- <span v-else><p>You can find the details of the online courses in the below link.</p>
                                            <a href="https://learn.examsdaily.in/Courses">Course Link</a>
                                            </span> -->
                                        </span>

                                        <p v-if="'center_address' in selectTemplate.value.bodyValues">You can find the location of your nearby center below:</p>
                                        <p v-if="'center_address' in selectTemplate.value.bodyValues"><span class="text-primary">Center address</span>,</p>
                                        <!-- <p><span class="text-primary">Landmark</span>,</p> -->
                                        <p><span v-if="'city' in selectTemplate.value.bodyValues" class="text-primary">City,</span> <span v-if="'state' in selectTemplate.value.bodyValues" class="text-primary">State</span><span v-if="'pincode' in selectTemplate.value.bodyValues" class="text-primary"> - Pincode.</span></p>
                                        <p v-if="'gmaps_link' in selectTemplate.value.bodyValues"><img src="../../public/location-pin.png" alt=""> Location</p>
                                        <p v-if="'gmaps_link' in selectTemplate.value.bodyValues"><span class="text-primary">Map link</span></p>

                                        <p>For more details call you Advisor.</p>
                                        <p v-if="'agent_name' in selectTemplate.value.bodyValues"><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p v-if="'agent_contact' in selectTemplate.value.bodyValues"><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                            <!-- <b-card v-if="selectTemplate == 'edWelcomeOnline'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Examsdaily Online</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>*Welcome to Examsdaily*</p>
                                        <p>Hi, <span class="text-primary">Name</span></p>
                                        <p>Thankyou for contacting Examsdaily.</p>
                                        <p>You have enquired with us for the <span class="text-primary">Course name</span>. You can find the details of the course in the below link.</p>
                                        <p><span class="text-primary">Course Link</span></p>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card> -->

                            <b-card v-if="selectTemplate == 'dexterOnline'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Dexter Online</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>*Welcome to Dexter Academy*</p>
                                        <p>Hi, <span class="text-primary">Name</span></p>
                                        <p>Thankyou for contacting Dexter Academy.</p>
                                        <p>You have enquired with us for the <span class="text-primary">Course name</span>. You can find the details of the course in the below link.</p>
                                        <p><span class="text-primary">Course Link</span></p>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                            <b-card v-if="selectTemplate == 'dexterOffline'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Dexter Offline</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>*Welcome to Dexter Academy*</p>
                                        <p>Hi, <span class="text-primary">name</span></p>
                                        <p>Thankyou for contacting Dexter Academy.</p>
                                        <p>You have enquired with us for the <span class="text-primary">Course Name</span>. You can find the location of your nearby center below.</p>
                                        <p><span class="text-primary">Center address</span>,</p>
                                        <p><span class="text-primary">Landmark</span>,</p>
                                        <p><span class="text-primary">City</span>, <span class="text-primary">State</span> - <span class="text-primary">Pincode</span></p>
                                        <p><img src="../../public/location-pin.png" alt=""> Location</p>
                                        <p><span class="text-primary">Map link</span></p>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                            <b-card v-if="selectTemplate == 'dexterOfferMar2023Online'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Dexter Offer Mar 2023 Online</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>Hi, <span class="text-primary">Name</span></p>
                                        <p>A <img src="../../public/spark.png" alt="">Limited time offer<img src="../../public/spark.png" alt="">, only for you. Valid from March 1 to 7, 2023</p>
                                        <p>*Get any online course on flat Rs.4999/- only*.</p>
                                        <p>Don't miss this <img src="../../public/gift.png" alt=""><img src="../../public/sparkles.png" alt="">.</p>
                                        <p>You can find the details of the online courses in the link below.</p>
                                        <a href="https://learn.examsdaily.in/Courses">https://learn.examsdaily.in/Courses</a>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                            <b-card v-if="selectTemplate == 'dexterOfferMar2023Offline'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Dexter Offer Mar 2023 Offline</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>Hi, <span class="text-primary">name</span></p>
                                        <p>A <img src="../../public/spark.png" alt="">Limited time offer<img src="../../public/spark.png" alt="">, only for you. Valid from March 1 to 7, 2023</p>
                                        <p>*Get any course on flat Rs.9999/- only*.</p>
                                        <p>Don't miss this <img src="../../public/gift.png" alt=""><img src="../../public/sparkles.png" alt="">.</p>
                                        <p>Location of your nearby center below:</p>
                                        <p><span class="text-primary">Center address</span>,</p>
                                        <p><span class="text-primary">City</span>, <span class="text-primary">State</span></p>
                                        <p><img src="../../public/location-pin.png" alt="">Location Link:</p>
                                        <p><span class="text-primary">Map link</span></p>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                            <b-card v-if="selectTemplate == 'dexterOfferMay2023Offline'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Dexter Offer Mar 2023 Offline</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>Hi, <span class="text-primary">name</span></p>
                                        <p>A <img src="../../public/spark.png" alt="">Limited time offer<img src="../../public/spark.png" alt="">, only for you. Valid from May 25 to 31, 2023</p>
                                        <p>*Get any course on flat Rs.9999/- only*.</p>
                                        <p>Don't miss this <img src="../../public/gift.png" alt=""><img src="../../public/sparkles.png" alt="">.</p>
                                        <p>Location of your nearby center below:</p>
                                        <p><span class="text-primary">Center address</span>,</p>
                                        <p><span class="text-primary">City</span>, <span class="text-primary">State</span>
                                        <p><img src="../../public/location-pin.png" alt="">Location Link:</p>
                                        <p><span class="text-primary">Map link</span></p>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>                            

                            <b-card v-if="selectTemplate == 'edOfferMar2023'" border-variant="primary" header-bg-variant="primary" class="mt-2">
                                <b-card-text class="text-center">
                                    <span class="text-primary">Examsdaily Offer Mar 2023</span>
                                    <img class="float-end" src="../../public/tick.png" alt="" width="25">
                                    <hr>
                                </b-card-text>
                                <b-card-text>
                                    <div>
                                        <p>Hi, <span class="text-primary">Name</span></p>
                                        <p>A <img src="../../public/spark.png" alt="">Limited time offer<img src="../../public/spark.png" alt="">, only for you. Valid from March 1 to 7, 2023</p>
                                        <p>*Get any course on flat Rs.4999/- only*.</p>
                                        <p>Don't miss this <img src="../../public/gift.png" alt=""><img src="../../public/sparkles.png" alt="">.</p>
                                        <p>You can find the details of the online courses in the link below.</p>
                                        <a href="https://learn.examsdaily.in/Courses">https://learn.examsdaily.in/Courses</a>
                                        <br>
                                        <p>For more details call you Advisor.</p>
                                        <p><img src="../../public/women-emoji.png" alt=""> <span class="text-primary">Agent name</span></p>
                                        <p><img src="../../public/hand-call-emoji.png" alt=""> <span class="text-primary">Agent contact</span></p>
                                    </div>
                                </b-card-text>
                            </b-card>

                        </div>
                        <div class="my-3 text-center">
                            <b-button variant="primary" :disabled="disabled" @click="disabled = true,startCampaign()" class="mx-1">Start Campaign</b-button>
                            <b-button variant="danger" class="mx-1" @click="$refs.showCampaign.hide()">Cancel</b-button>
                        </div>
                    </b-modal>
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="4" id="r1">
                                <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" />
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCallSearchCount(keyword)">Search</b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="currentPage" :total-rows="totalCount" :per-page="pageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="switchPage(currentPage, pageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="pageCount" @input="switchPage(currentPage, pageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="refresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <div class="text-end mb-2">
                                    <b-button class="mb-3" variant="primary" :disabled="this.keyword != ''" @click="createCampaignShow(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)" >Start Campaign<i class="fa fa-play-circle px-2" aria-hidden="true"></i></b-button>
                                </div>
                                <b-container>
                                    <b-row>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedCourse" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Course">
                                                    <p>Courses</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllCourses">
                                                    <p>All Courses</p>
                                                </b-form-select-option>
                                                <option v-for="item in allCourses" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="1" id="r1" sm="3">
                                            <b-form-select class="bnclr" v-model="selectedMode" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="mode">
                                                    <p>Mode</p>
                                                </b-form-select-option>
                                                <!-- <b-form-select-option value="allModes">
                                            <p>All Modes</p>
                                        </b-form-select-option> -->
                                                <option v-for="item in modeOptions" :value="item.value" v-bind:key="item.index">
                                                    <strong>{{ item.text }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>

                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedSource" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Source">
                                                    <p>Sources</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllSources">
                                                    <p>All Sources</p>
                                                </b-form-select-option>
                                                <option v-for="item in allSources" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="4" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedAgent" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Agent">
                                                    <p>Agents</p>
                                                </b-form-select-option>
                                                <b-form-select-option value="AllAgents">
                                                    <p>All Agents</p>
                                                </b-form-select-option>
                                                <option v-for="item in allAgents" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.firstName }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4">
                                            <b-form-select class="bnclr" v-model="selectedBranch" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,  selectedBranch,selectedDates)">
                                                <b-form-select-option disabled value="Branch">
                                                    Branches
                                                </b-form-select-option>
                                                <b-form-select-option value="AllBranches">
                                                    <p>All Branches</p>
                                                </b-form-select-option>
                                                <option v-for="item in allBranches" :value="item" v-bind:key="item.index">
                                                    <strong>{{ item.name }}</strong>
                                                </option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col col lg="2" md="2" id="r1" sm="4" class="sort">
                                            <b-form-select class="bnclr" v-model="selectedDates" size="sm" @change="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)" :options="dateSelect">
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <hr />
                            <b-table hover responsive :items="calls" :fields="fields" :per-page="calls.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(callId)="data">
                                    <span class="title"> {{ data.item.callId }}</span>
                                </template>
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ callDate(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(name)="data">
                                    {{ data.item.name }}
                                </template>
                                <template v-slot:cell(agent)="data">
                                    {{ data.item.agent.firstName }}
                                </template>
                                <template v-slot:cell(contact)="data">
                                    <div>
                                        {{ data.item.contact }}<span v-if="data.item.alternativeContact != null && data.item.alternativeContact != ''">,</span>
                                    </div>
                                    <div>
                                        {{ data.item.alternativeContact }}
                                    </div>
                                </template>
                                <template v-slot:cell(course)="data">
                                    {{ data.item.course.name }}
                                </template>
                                <template v-slot:cell(source)="data">
                                    {{ data.item.source.name }}
                                </template>
                                <template v-slot:cell(branch)="data">
                                    {{ data.item.branch.name }}
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
            <b-tab title="Completed" @click="getAllCampaignCount">
                <b-modal ref="viewCompleteCampaign" hide-footer title="View Campaign">
                    <div class="form-row mt-3 px-5">
                        <div class="form-group my-2">
                            <label>Course </label>
                            <b-form-input type="text" v-model="completeCampaign.course" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>Source </label>
                            <b-form-input type="text" v-model="completeCampaign.source" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>Mode </label>
                            <b-form-input type="text" v-model="completeCampaign.mode" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>Agent </label>
                            <b-form-input type="text" v-model="completeCampaign.agent" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>Branch </label>
                            <b-form-input type="text" v-model="completeCampaign.branch" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>Start Date </label>
                            <b-form-input type="text" v-model="completeCampaign.startDate" class="form-control" disabled></b-form-input>
                        </div>
                        <div class="form-group my-2">
                            <label>End Date </label>
                            <b-form-input type="text" v-model="completeCampaign.endDate" class="form-control" disabled></b-form-input>
                        </div>
                    </div>
                </b-modal>
                <b-modal ref="viewUserStatus" size="lg" hide-footer title="User status">
                    <div class="">
                        <div class="text-end">
                            <b-row>
                                <b-col sm="9" md="9" lg="9">
                                </b-col>
                                <b-col sm="3" md="3" lg="3" class="text-end">
                                    <label class="font mx-1">Per Page</label>
                                    <b-form-select class="br" id="per-page-select" v-model="userStatusPageCount" :options="pageOptions" size="sm">
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </div>
                        <hr>
                        <b-table hover responsive :items="completeCampaign.userStatus" :fields="campaignUserStatus" :per-page="userStatusPageCount" :current-page="userStatusCurrentPage">
                            <template v-slot:cell(messageStatus)="data">
                                <span v-if="data.item.messageStatus == 'Forbidden'">
                                    <b-badge style="background-color:#d9534f;color:#fff;padding:.5em;font-family: 'Pavanam', sans-serif;" pill variant="danger">{{data.item.messageStatus}}&nbsp;
                                    </b-badge>
                                </span>
                                <span v-else-if="data.item.messageStatus == 'Accepted'">
                                    <b-badge style="background-color:#5cb85c;color:#fff;padding:.5em;font-family: 'Pavanam', sans-serif;" pill variant="danger">{{data.item.messageStatus}}&nbsp;
                                    </b-badge>
                                </span>
                                <span v-else>
                                    {{data.item.messageStatus}}
                                </span>
                            </template>
                        </b-table>
                        <b-pagination v-model="userStatusCurrentPage" :total-rows="completeCampaign.userStatus.length" :per-page="userStatusPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-5"></b-pagination>
                    </div>
                    <!-- <div class="list-group px-5">
                        <label>User status</label>
                        <button type="button" class="list-group-item list-group-item-action" v-for="u in completeCampaign.userStatus" :key="u.callId">
                            <div>
                                Name : {{u.name}}
                            </div>
                            <div>
                                contact : {{u.contact}}
                            </div>
                            <div>
                                Response : {{u.messageResponse}}
                            </div>
                            <div>
                               Status : {{u.messageStatus}}
                            </div>
                        </button>
                    </div> -->
                </b-modal>
                <div class="mt-3">
                    <!-- <b-modal ref="callDateRange" hide-footer id="dateRange" title="Select Date">
                        <div>
                            <label for="from-datepicker">Choose The From date</label>
                            <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid,}" type="date"></b-form-input>
                        </div>
                        <div>
                            <label for="to-datepicker">Choose The To date</label>
                            <b-form-input format="DD-MM-YYYY" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid,}" type="date"></b-form-input>
                        </div>
                        <div class="text-center mt-3">
                            <b-button variant="outline-success" type="submit" @click="getAllSortCount(selectedAgent,selectedCourse,selectedMode,selectedSource,selectedBranch,selectedDates)">Submit</b-button>
                        </div>
                    </b-modal> -->
                    <b-container>
                        <b-row align-v="center">
                            <b-col col sm="12" md="4" lg="2" id="r1" class="px-2 text-start">
                                <label>From :</label>
                                <!-- <input type="search" class="searchbar" v-model="keyword" placeholder="Type a name" v-on:keyup.enter="getCallSearchCount(keyword)" /> -->
                                <b-form-input format="DD-MM-YYYY" v-model="from" :class="{'is-invalid': $v.from.$error,'is-valid': !$v.from.$invalid,}" type="date"></b-form-input>
                            </b-col>
                            <b-col col sm="12" md="1" lg="2" id="r1" class="px-2 text-start">
                                <label>To :</label>
                                <b-form-input format="DD-MM-YYYY" @change="campaignSortCount" v-model="to" :class="{'is-invalid': $v.to.$error,'is-valid': !$v.to.$invalid,}" type="date"></b-form-input>
                                <!-- <b-button size="sm" variant="outline-primary" class="bnclr" @click="getCallSearchCount(keyword)">Search</b-button> -->
                            </b-col>
                            <b-col col sm="12" md="1" lg="1" id="r1" class="py-2">
                                <!-- <b-button pill variant="outline-primary" style="font-size: 0.8rem;">
                                    Sort
                                    <b-icon icon="filter-square-fill" variant="outline-light"></b-icon>
                                </b-button> -->
                                <b-button pill variant="outline-primary" style="font-size: 0.8rem;" @click="sortDatereset">
                                    <!-- <b-icon icon="arrow-clockwise" variant="outline-light"></b-icon> -->Reset
                                </b-button>
                            </b-col>
                            <b-col sm="12" md="3" lg="4" class="my-1" id="r1">
                                <b-pagination v-model="compaignCurrentPage" :total-rows="totalCount" :per-page="compaignPageCount" prev-text="Prev" next-text="Next" align="fill" size="sm" class="my-0 mx-2" @input="campaignSwitchPage(compaignCurrentPage, compaignPageCount)"></b-pagination>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" class="" id="r1">
                                <label class="font mx-1">Per Page</label>
                                <b-form-select class="br" id="per-page-select" v-model="compaignPageCount" @input="campaignSwitchPage(compaignCurrentPage, compaignPageCount)" :options="pageOptions" size="sm">
                                </b-form-select>
                            </b-col>
                            <b-col col lg="1" md="1" id="r1" sm="6">
                                <span style="color: #0974ff; cursor: pointer" @click="campaignRefresh()">
                                    <font-awesome-icon icon="sync-alt" />Refresh</span>
                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container>
                        <b-card class="card mt-1">
                            <div>
                                <div class="mb-2">
                                    Complete Campaigns
                                </div>
                            </div>
                            <hr />
                            <b-table hover responsive :items="campaigns" :fields="campaignFields" :per-page="calls.pageCount" :current-page="currentPage" ref="table">
                                <template v-slot:cell(createdAt)="data">
                                    <div class="cellSize">
                                        {{ dateFormat(data.item.createdAt, "fullDate") }}
                                    </div>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button size="sm" class="mx-1" variant="primary" v-b-tooltip.hover.bottomright="{ variant: 'primary' }" title="View campaign" @click="viewCampaignDetails(data.item)">
                                        <font-awesome-icon icon="eye" />
                                    </b-button>
                                    <b-button size="sm" class="mx-1" variant="success" v-b-tooltip.hover.bottomright="{ variant: 'success' }" title="View user status" @click="getUserStatus(data.item)">
                                        <font-awesome-icon icon="file" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-card>
                    </b-container>
                </div>
            </b-tab>
        </b-tabs>
    </b-container>
</div>
</template>

<script>
import AdminService from "../service/AdminService";
import Account from '../service/Account.js';
import BranchService from "../service/BranchService";
import CourseService from "../service/CourseService";
import AgentService from "../service/AgentService";
import SourceService from "../service/SourceService";
import moment from "moment-timezone";
import axios from "axios";
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Campaign',
    data() {
        return {
            whatsappTemplates: [],
            calls: [],
            fields: [{
                    key: "createdAt",
                    label: "Date",
                },
                {
                    key: "Agent",
                    label: "Agent Name",
                },
                {
                    key: "name",
                    label: "Name",
                },

                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                // {
                //     key: "action",
                //     label: "Action",
                // },
            ],
            allCourses: [],
            allSources: [],
            allBranches: [],
            allAgents: [],
            keyword: "",
            totalCount: 0,
            currentPage: 1,
            pageCount: 5,
            pageOptions: [
                1,
                3,
                5,
                10,
                15,
                {
                    value: 100,
                    text: "Show lot",
                },
            ],
            modeOptions: [{
                    value: "online",
                    text: "Online",
                },
                {
                    value: "offline",
                    text: "Offline",
                },
            ],
            dateSelect: [{
                    value: null,
                    text: "Dates",
                    disabled: true
                },
                {
                    value: "today",
                    text: "Today"
                },
                {
                    value: "yesterday",
                    text: "Yesterday"
                },
                {
                    value: "lastWeek",
                    text: "Past 7 days"
                },
                {
                    value: "lastMonth",
                    text: "Past 30 days"
                },
                {
                    value: "dateRange",
                    text: "Select Range"
                },
                {
                    value: "dateBetween",
                    text: "Range select",
                    disabled: true
                },
            ],
            selectedCourse: "Course",
            selectedSource: "Source",
            selectedBranch: "Branch",
            selectedAgent: "Agent",
            selectedMode: "online",
            selectedDates: null,
            from: "",
            to: "",
            loading: false,
            allSortCalls: false,
            paginationCalls: false,
            searchPagination: false,
            disabled: false,
            campaigns: [],
            campaignFields: [{
                    key: "createdAt",
                    label: "Created At",
                },
                {
                    key: "agent",
                    label: "Agent",
                },
                {
                    key: "mode",
                    label: "Mode",
                },
                {
                    key: "course",
                    label: "Course",
                },
                {
                    key: "source",
                    label: "Source",
                },
                {
                    key: "branch",
                    label: "Branch",
                },
                // {
                //     key: "startDate",
                //     label: "Calls Sort From",
                // },
                // {
                //     key: "endDate",
                //     label: "Calls Sort To",
                // },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            campaignPagination: false,
            campaignSortPagination: false,
            compaignCurrentPage: 1,
            compaignPageCount: 5,
            examsdailyTemp: false,
            dexterTemp: false,
            dexterOnline: false,
            dexterOffline: false,
            data: null,
            showCampaignData: {
                agentId: "",
                courseId: "",
                mode: "",
                sourceId: "",
                branchId: "",
                from: "",
                to: "",
            },
            examsdailyOnlineTemplate: [{
                    text: 'Examsdaily Welcome online',
                    value: 'edWelcomeOnline'
                },
                {
                    text: 'Examsdaily Offer March 2023',
                    value: 'edOfferMar2023'
                },
            ],
            dexterOnlineTemplate: [{
                    text: 'Dexter Online',
                    value: 'dexterOnline'
                },
                {
                    text: 'Dexter Offer Mar 2023 Online',
                    value: 'dexterOfferMar2023Online'
                },
            ],
            dexterOfflineTemplate: [
                {
                    text: 'Dexter Offline',
                    value: 'dexterOffline'
                },
                {
                    text: 'Dexter Offer Mar 2023 Offline',
                    value: 'dexterOfferMar2023Offline'
                },
                {
                    text: 'Dexter Offer May 2023 Offline',
                    value: 'dexterOfferMay2023Offline'
                }                              
            ],
            selectTemplate: null,
            completeCampaign: {
                course: "",
                source: "",
                mode: "",
                agent: "",
                branch: "",
                from: "",
                to: "",
                userStatus: []
            },
            campaignUserStatus: [{
                    key: "name",
                    label: "Name",
                },
                {
                    key: "contact",
                    label: "Contact",
                },
                {
                    key: "messageResponse",
                    label: "Response",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    key: "messageStatus",
                    label: "Status",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            userStatusCurrentPage: 1,
            userStatusPageCount: 5
        }
    },
    mounted() {
        return new Promise((resolve, reject) => {
            this.global()
                .then((response) => {
                    // this.getAllCalls();
                    // this.closedGetCount();
                    this.loading = true;
                    this.getAllCourses();
                    this.getAllSources();
                    this.getAllBranches();
                    this.getAllAgents();
                    this.getAllSortCount("Agent", "Course", "online", "Source", "Branch", null);
                    // this.getCount();
                    resolve(response);
                })
                .catch((err) => {
                    this.loading = false;
                    reject(err);
                });
        });
    },
    watch: {
        keyword(newVal, oldVal) {
            if (oldVal && !newVal) {
                // this.getCount();
                this.getAllSortCount("Agent", "Course", "online", "Source", "Branch", null);
            }
        },
    },
    validations: {
        from: {
            required,
        },
        to: {
            required,
            // minValue: (value) => {
            //     let selectedDT = new Date(value);
            //     let currentDT = new Date();
            //     if (selectedDT > currentDT) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            // },
        },
        selectTemplate: {
            required
        }
    },
    methods: {
        global() {
            return new Promise((resolve, reject) => {
                Account.getAccessToken(localStorage.getItem("refreshToken"))
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCourses: function () {
            return new Promise((resolve, reject) => {
                CourseService.getAllCourses()
                    .then((response) => {
                        this.allCourses = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllAgents: function () {
            return new Promise((resolve, reject) => {
                AgentService.getAllAgents()
                    .then((response) => {
                        this.allAgents = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllSources: function () {
            return new Promise((resolve, reject) => {
                SourceService.getAllSources()
                    .then((response) => {
                        this.allSources = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllBranches: function () {
            return new Promise((resolve, reject) => {
                BranchService.getAllBranches()
                    .then((response) => {
                        this.allBranches = response.data;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // showCampaignModal() {
        //     this.$refs.showCampaign.show();
        // },
        createCampaignShow: function (selectedAgent, selectedCourse, selectedMode, selectedSource, selectedBranch, selectedDates) {
            this.resetCampaignDetails();
            // this.loading = true;
            if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                selectedAgent = {
                    agentId: "",
                };
            }
            if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                selectedCourse = {
                    courseId: "",
                };
            }
            if (selectedMode == "mode" || selectedMode == "allModes") {
                selectedMode = "";
            }
            if (selectedSource == "Source" || selectedSource == "AllSources") {
                selectedSource = {
                    sourceId: "",
                };
            }
            if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                selectedBranch = {
                    branchId: "",
                };
            }
            var from;
            var to;
            if (selectedDates != null) {
                var date = new Date();
                if (selectedDates == "today") {
                    this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDates == "yesterday") {
                    this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                    this.toDate = new Date(date.setHours(23, 59, 59, 0));
                } else if (selectedDates == "lastWeek") {
                    this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                    this.toDate = new Date(date.setHours(167, 59, 59, 0));
                } else if (selectedDates == "lastMonth") {
                    this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                    this.toDate = new Date(date.setHours(719, 59, 59, 0));
                } else if (
                    this.from != "" &&
                    this.to != "" &&
                    selectedDates == "dateBetween"
                ) {
                    this.$refs.callDateRange.hide();
                    var fromDateObject = new Date(this.from);
                    var toDateObject = new Date(this.to);
                    this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                    this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                } else if (
                    this.from == "" &&
                    this.to == "" &&
                    selectedDates == "dateBetween"
                ) {
                    this.$refs.callDateRange.hide();
                    this.fromDate = "2021-01-01 00:01";
                    this.toDate = new Date();
                }
                this.fromDateFormat = moment(String(this.fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                this.toDateFormat = moment(String(this.toDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                from = this.fromDateFormat;
                to = this.toDateFormat;
            } else if (selectedDates == null || selectedDates == "dateBetween") {
                let fromDate = "2021-01-01 00:01";
                let toDate = new Date();
                let fromDateFormat = moment(String(fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                from = fromDateFormat;
                to = toDateFormat;
            }
            this.data = {
                agentId: selectedAgent.agentId,
                courseId: selectedCourse.courseId,
                mode: selectedMode,
                sourceId: selectedSource.sourceId,
                branchId: selectedBranch.branchId,
                from: from,
                to: to,
                teamId: localStorage.getItem('tenant-uuid')
            };
            var agent;
            var course;
            var source;
            var branch;
            if (selectedAgent.agentId == "") {
                agent = "All Agents"
            } else {
                agent = selectedAgent.firstName
            }
            if (selectedCourse.courseId == "") {
                course = "All Courses"
            } else {
                course = selectedCourse.name
            }
            if (selectedSource.sourceId == "") {
                source = "All Sources"
            } else {
                source = selectedSource.name
            }
            if (selectedBranch.branchId == "") {
                branch = "All Branches"
            } else {
                branch = selectedBranch.name
            }
            return new Promise((resolve, reject) => {
                axios.post('/campaign/get/organization', this.data)
                    .then((response) => {
                        if(response.data != "") {
                            this.getAllTemplates(response.data);
                        }
                        if (response.data == 'examsdaily') {
                            this.examsdailyTemp = true;
                        } else if (response.data == 'dexter_academy') {
                            this.dexterTemp = true;
                            if (this.data.mode == 'online') {
                                this.dexterOnline = true;
                                this.dexterOffline = false;
                            } else if (this.data.mode == 'offline') {
                                this.dexterOffline = true;
                                this.dexterOnline = false;
                            }
                        }
                        this.showCampaignData = {
                            agent: agent,
                            course: course,
                            mode: selectedMode,
                            source: source,
                            branch: branch,
                            from: from,
                            to: to,
                        }
                        this.$refs.showCampaign.show();
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.disabled = false;
                        reject(err);
                    });
            });
        },
        getAllTemplates:function(organization) {
            let data = {
                organization: organization,
                mode: this.data.mode
            }
            return new Promise((resolve, reject) => {
                axios.post('/whatsapp/template/sort/organization',data)
                    .then((response) => {
                        this.whatsappTemplates = response.data;
                        resolve(response);
                    })
                    .catch((err) => {

                        reject(err);
                    });
            });
        },
        startCampaign: function () {
            this.$v.selectTemplate.$touch();
            if (!this.$v.selectTemplate.$invalid) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to send message to ' + this.totalCount + ' candidates?',
                    showDenyButton: true,
                    confirmButtonText: 'Send',
                    denyButtonText: `Cancel`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.loading = true;
                        return new Promise((resolve, reject) => {
                            let campaign = {
                                agentId: this.data.agentId,
                                courseId: this.data.courseId,
                                mode: this.data.mode,
                                sourceId: this.data.sourceId,
                                branchId: this.data.branchId,
                                from: this.data.from,
                                to: this.data.to,
                                teamId: localStorage.getItem('tenant-uuid'),
                                templateId: this.selectTemplate.templateId
                            }
                            axios.post('/campaign/create', campaign)
                                .then((response) => {
                                    this.$swal({
                                        title: "Good job!",
                                        text: "Campaign created successfully",
                                        icon: "success",
                                        button: "ok",
                                    });
                                    this.$refs.showCampaign.hide();
                                    this.loading = false;
                                    this.disabled = false;
                                    resolve(response);
                                })
                                .catch((err) => {
                                    this.loading = false;
                                    this.disabled = false;
                                    reject(err);
                                });
                        });
                    } else if (result.isDenied) {
                        this.$refs.showCampaign.hide();
                    }
                })

            }
            this.disabled = false
        },
        getAllCampaignCount: function () {
            this.loading = true;
            this.currentPage = 1;
            this.allSortCalls = false;
            this.paginationCalls = false;
            this.searchPagination = false;
            this.campaignPagination = true;
            this.campaignSortPagination = false;
            return new Promise((resolve, reject) => {
                axios.get('/campaign/count')
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllCampaignPageable(this.compaignCurrentPage, this.compaignPageCount);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        campaignSwitchPage: function (currentPage, pageCount) {
            // this.currentPage = currentPage;
            // this.pageCount = pageCount;
            console.log("======campaignSwitchPage===========");
            if (this.campaignPagination) {
                console.log("======campaignSwitchPage=====if======");
                this.getAllCampaignPageable(currentPage, pageCount)
            } else if (this.campaignSortPagination) {
                console.log("======campaignSwitchPage=====else if======");
                this.campaignSortPageable(currentPage, pageCount);
            }
        },
        getAllCampaignPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                axios.post('/campaign/getAll/pageable/' + (currentPage - 1) + "/" + pageCount)
                    .then((response) => {
                        resolve(response);
                        this.campaigns = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        viewCampaignDetails(campaign) {
            this.completeCampaign = {
                agent: campaign.agent,
                course: campaign.course,
                source: campaign.source,
                branch: campaign.branch,
                mode: campaign.mode,
                startDate: campaign.startDate,
                endDate: campaign.endDate,
                userStatus: campaign.userStatus
            };
            this.$refs.viewCompleteCampaign.show();
        },
        getUserStatus(campaign) {
            this.completeCampaign = {
                agent: campaign.agent,
                course: campaign.course,
                source: campaign.source,
                branch: campaign.branch,
                mode: campaign.mode,
                startDate: campaign.startDate,
                endDate: campaign.endDate,
                userStatus: campaign.userStatus
            };
            this.$refs.viewUserStatus.show();

        },
        campaignSortCount() {
            this.$v.from.$touch();
            this.$v.to.$touch();
            if (!this.$v.from.$invalid && !this.$v.to.$invalid) {
                this.campaignPagination = false;
                this.campaignSortPagination = true;
                this.loading = true;
                this.currentPage = 1;
                var fromDateObject = new Date(this.from);
                var toDateObject = new Date(this.to);
                this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                let fromDateFormat = moment(String(this.fromDate)).format(
                    "yyyy-MM-DD HH:mm"
                );
                let toDateFormat = moment(String(this.toDate)).format("yyyy-MM-DD HH:mm");
                this.data = {
                    from: fromDateFormat,
                    to: toDateFormat
                }
                return new Promise((resolve, reject) => {
                    axios.post('/campaign/date/sort/count', this.data)
                        .then((response) => {
                            this.totalCount = response.data;
                            this.campaignSortPageable(this.currentPage, this.pageCount);
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            }
        },
        campaignSortPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                axios.post('/campaign/date/sort/pageable/' + (currentPage - 1) + "/" + pageCount, this.data)
                    .then((response) => {
                        this.campaigns = response.data;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        sortDatereset() {
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        getCount: function () {
            // this.showCalls();
            this.loading = true;
            this.allSortCalls = false;
            this.paginationCalls = true;
            this.searchPagination = false;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.keyword = "";
            this.selectedDates = null;
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            this.currentPage = 1;

            return new Promise((resolve, reject) => {
                AdminService.getCallCount()
                    .then((response) => {
                        this.totalCount = response.data;
                        this.getAllPageable(this.currentPage, this.pageCount);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllPageable: function (currentPage, pageCount) {
            return new Promise((resolve, reject) => {
                AdminService.getAllCallPageable(currentPage, pageCount)
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        // console.log(response.data)
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        switchPage: function (currentPage, pageCount) {
            console.log("======switchPage======paginationCalls=====", this.paginationCalls);
            console.log("======switchPage======allSortCalls=====", this.allSortCalls);
            console.log("======switchPage======searchPagination=====", this.searchPagination);
            this.loading = true;
            if (this.paginationCalls) {
                this.getAllPageable(currentPage, pageCount);
            } else if (this.allSortCalls) {
                this.getAllSortPageable(currentPage, pageCount, this.data);
            } else if (this.searchPagination) {
                this.getAllCallSearchPageable(currentPage, pageCount, this.keyword);
            }
        },
        getAllSortCount: function (
            selectedAgent,
            selectedCourse,
            selectedMode,
            selectedSource,
            selectedBranch,
            selectedDates
        ) {
            if (selectedDates == "dateRange") {
                this.getDateRangeReset();
                this.$refs.callDateRange.show();
                this.selectedDates = "dateBetween";
            } else {
                if (selectedAgent == "Agent" || selectedAgent == "AllAgents") {
                    selectedAgent = {
                        agentId: "",
                    };
                }
                if (selectedCourse == "Course" || selectedCourse == "AllCourses") {
                    selectedCourse = {
                        courseId: "",
                    };
                }
                if (selectedMode == "mode" || selectedMode == "allModes") {
                    selectedMode = "";
                }
                if (selectedSource == "Source" || selectedSource == "AllSources") {
                    selectedSource = {
                        sourceId: "",
                    };
                }
                if (selectedBranch == "Branch" || selectedBranch == "AllBranches") {
                    selectedBranch = {
                        branchId: "",
                    };
                }
                var from;
                var to;
                if (selectedDates != null) {
                    var date = new Date();
                    if (selectedDates == "today") {
                        this.fromDate = new Date(date.setHours(0, 0, 0, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "yesterday") {
                        this.fromDate = new Date(date.setHours(-23, -59, -59, 0));
                        this.toDate = new Date(date.setHours(23, 59, 59, 0));
                    } else if (selectedDates == "lastWeek") {
                        this.fromDate = new Date(date.setHours(-167, -59, -59, 0));
                        this.toDate = new Date(date.setHours(167, 59, 59, 0));
                    } else if (selectedDates == "lastMonth") {
                        this.fromDate = new Date(date.setHours(-719, -59, -59, 0));
                        this.toDate = new Date(date.setHours(719, 59, 59, 0));
                    } else if (
                        this.from != "" &&
                        this.to != "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        var fromDateObject = new Date(this.from);
                        var toDateObject = new Date(this.to);
                        this.fromDate = new Date(fromDateObject.setHours(0, 0, 0, 0));
                        this.toDate = new Date(toDateObject.setHours(23, 59, 59, 0));
                    } else if (
                        this.from == "" &&
                        this.to == "" &&
                        selectedDates == "dateBetween"
                    ) {
                        this.$refs.callDateRange.hide();
                        this.fromDate = "2021-01-01 00:01";
                        this.toDate = new Date();
                    }
                    this.fromDateFormat = moment(String(this.fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    this.toDateFormat = moment(String(this.toDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    from = this.fromDateFormat;
                    to = this.toDateFormat;
                } else if (selectedDates == null || selectedDates == "dateBetween") {
                    let fromDate = "2021-01-01 00:01";
                    let toDate = new Date();
                    let fromDateFormat = moment(String(fromDate)).format(
                        "yyyy-MM-DD HH:mm"
                    );
                    let toDateFormat = moment(String(toDate)).format("yyyy-MM-DD HH:mm");
                    from = fromDateFormat;
                    to = toDateFormat;
                }
                this.data = {
                    agentId: selectedAgent.agentId,
                    courseId: selectedCourse.courseId,
                    mode: selectedMode,
                    sourceId: selectedSource.sourceId,
                    branchId: selectedBranch.branchId,
                    from: from,
                    to: to,
                };
                this.loading = true;
                this.allSortCalls = true;
                this.paginationCalls = false;
                this.searchPagination = false;
                this.currentPage = 1;
                this.keyword = "";
                // console.log("this.data====== ", this.data);
                return new Promise((resolve, reject) => {
                    axios
                        .post("/admin/call/all/sort/count", this.data)
                        .then((response) => {
                            // console.log("getAllSortCount======", response.data);
                            this.totalCount = response.data;
                            this.getAllSortPageable(
                                this.currentPage,
                                this.pageCount,
                                this.data
                            );
                            this.loading = false;
                            resolve(response);
                        })
                        .catch((err) => {
                            this.loading = false;
                            reject(err);
                        });
                });
            }
        },
        getAllSortPageable: function (currentPage, pageCount, data) {
            // console.log("data=======", data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/admin/call/all/sort/" + currentPage + "/" + pageCount, data)
                    .then((response) => {
                        this.calls = response.data;
                        // console.log("getAllSortPageable===== ", response.data);
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCallSearchCount: function (keyword) {
            //console.log("search pagecount", keyword);
            // this.sortCoursePaginationCalls = false;
            // this.sortSourcePaginationCalls = false;
            // this.sortBranchPaginationCalls = false;
            // this.sortDatePaginationCalls = false;
            // this.sortAgentPaginationCalls = false;
            this.searchPagination = true;
            this.paginationCalls = false;
            this.allSortCalls = false;
            this.currentPage = 1;
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            return new Promise((resolve, reject) => {
                AdminService.getCallSearchCount(keyword.toLowerCase())
                    .then((response) => {
                        this.totalCount = response.data;
                        //console.log("search pagecount", response.data);
                        this.getAllCallSearchPageable(
                            this.currentPage,
                            this.pageCount,
                            keyword.toLowerCase()
                        );

                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAllCallSearchPageable: function (currentPage, pageCount, keyword) {
            //console.log("search page calling");
            return new Promise((resolve, reject) => {
                AdminService.getAllCallSearchPageable(
                        currentPage,
                        pageCount,
                        keyword.toLowerCase()
                    )
                    .then((response) => {
                        resolve(response);
                        this.calls = response.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        resetCampaignDetails() {
            this.showCampaignData = {
                agentId: "",
                courseId: "",
                mode: "",
                sourceId: "",
                branchId: "",
                from: "",
                to: "",
            };
            this.selectTemplate = null;
            this.$v.selectTemplate.$reset();
        },
        campaignRefresh() {
            this.getDateRangeReset();
            this.getAllCampaignCount();
        },
        refresh: function () {
            this.selectedCourse = "Course";
            this.selectedSource = "Source";
            this.selectedMode = "online";
            this.selectedBranch = "Branch";
            this.selectedAgent = "Agent";
            this.selectedDates = null;
            if (this.keyword != "") {
                this.keyword = "";
            } else {
                // this.getCount();
                this.getAllSortCount("Agent", "Course", "online", "Source", "Branch", null);
            }
        },
        dateFormat: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
        getDateRangeReset: function () {
            this.$refs.callDateRange.hide();
            (this.from = ""), (this.to = ""), this.$v.from.$reset();
            this.$v.to.$reset();
        },
        callDate: function (date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("D / MM / YYYY hh:mm a");
            }
        },
    }
}
</script>

<style scoped>
::v-deep .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd;
}

::v-deep .nav-link {
    color: #0d6efd;
    padding: 0.5rem 0.5rem;
}

.card {
    border: 3px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 4px 10px 0 #1e3a4b7a;
}

.bnclr {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    text-align: center;
    width: 80%;
}

.searchbar {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #09a5ff;
    margin: 1% 1%;
    padding: 1.5% 4%;
    background-image: url(https://cdn1.iconfinder.com/data/icons/color-bold-style/21/01-512.png);
    background-repeat: no-repeat;
    background-position: 12px;
    background-size: 16px;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    /* padding-bottom: 0px; */
    margin-bottom: 0px;
    list-style: none;
}

.font {
    font-size: 14px;
}

#per-page-select {
    border: 1px solid #09a5ff;
}

input {
    font-size: small;
}

.br {
    border: 1px solid #09a5ff;
    border-radius: 5px;
    padding: 3% 5%;
}

.row {
    --bs-gutter-x: 0rem;
}

select {
    background-color: #fff;
}

#calls:focus-within {
    background-color: #3177d8;
    color: #fff;
}

/* #calls {
  outline-color: blue;
  color: #fff;
  background: #09a5ff;
}  */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: 50% 50% no-repeat rgba(249, 249, 249, 0.5);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
