import axios from 'axios';
export default{   
    insertCourse: function(course){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/course/insert", course, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    getAllAdminCourses: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/getAll',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },
    getAllCourses: function(){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/getAllUnarchive',
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },
    deleteCourse: function(courseId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/delete/' + courseId,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    },
    getCourse: function(courseId){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/get/' + courseId,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });             
    }, 
    updateCourse: function(course){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9090",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/course/update", course, config)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    archive: function(course){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/course/archive/"+ course, config)
                .then(response => {
                    resolve(response);
                })
                
                .catch(err => {
                    reject(err);
                });
        });
    },
    unarchive: function(course){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // });
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        return new Promise((resolve, reject) => {
            axios
                .post("/course/unarchive/"+ course, config)
                .then(response => {
                    resolve(response);
                })
                
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCount: function(){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: "/course/count"
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    getAllPageable: function(currentPage,pageCount){
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/all/'+ (currentPage-1) + "/" + pageCount,
            }).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    searchCourse: function(keyword){
        // var ax = axios.create({
        //     baseURL: "http://localhost:9093",
        // }); 
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/search/'+ keyword,
            }).then((response) => {                 
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });                    
    },
    getSearchCount: function(keyword) {
        // var ax = axios.create({
        //     baseURL: "http://localhost:8088",
        // });
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: '/course/search/count/' + keyword,
            }).then((response) => {                     
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
           
        });
    },
    getAllSearchPageable: function(currentPage,pageCount,keyword){
        return new Promise((resolve, reject) => {
            axios.post('/course/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            });
        });
    },
    
}