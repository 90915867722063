var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loader"},[_c('img',{staticClass:"spinner",attrs:{"src":require("../../public/encore.svg"),"width":"30","height":"30"}}),_c('div',{staticStyle:{"color":"black","font-weight":"bold","position":"absolute","top":"55%","width":"150px","left":"25%"}},[_vm._v(" Please Wait..! ")])]):_vm._e(),_c('h1',{staticClass:"my-5"},[_vm._v("Postal Address")]),_c('div',{staticStyle:{"margin":"15px","box-shadow":"0px 13px 27px -5px #979797 , 0px 8px 16px -8px #979797 , 0px 6px 16px -6px #979797"}},[_c('div',{staticClass:"form-row pt-3 px-3"},[_c('div',{staticClass:"form-group py-2"},[_c('label',[_vm._v("Door number")]),_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.bookOrder.doorNo.$error,
                'is-valid': !_vm.$v.bookOrder.doorNo.$invalid,
            },staticStyle:{"height":"80px"},attrs:{"type":"text","placeholder":"Enter door number with Street"},model:{value:(_vm.bookOrder.doorNo),callback:function ($$v) {_vm.$set(_vm.bookOrder, "doorNo", $$v)},expression:"bookOrder.doorNo"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bookOrder.doorNo.required)?_c('span',[_vm._v("Address is required")]):_vm._e()])],1)]),_c('div',{staticClass:"form-row mt-3 px-3"},[_c('div',{staticClass:"form-group my-2"},[_c('label',[_vm._v("Landmark")]),_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.bookOrder.landmark.$error,
                'is-valid': !_vm.$v.bookOrder.landmark.$invalid,
            },attrs:{"type":"text","placeholder":"Enter your landmark"},model:{value:(_vm.bookOrder.landmark),callback:function ($$v) {_vm.$set(_vm.bookOrder, "landmark", $$v)},expression:"bookOrder.landmark"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bookOrder.landmark.required)?_c('span',[_vm._v("landmark is required")]):_vm._e()])],1)]),_c('div',{staticClass:"form-row mt-3 px-3"},[_c('div',{staticClass:"form-group my-2"},[_c('label',[_vm._v("District")]),_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.bookOrder.district.$error,
                'is-valid': !_vm.$v.bookOrder.district.$invalid,
            },attrs:{"type":"text","placeholder":"Enter your district"},model:{value:(_vm.bookOrder.district),callback:function ($$v) {_vm.$set(_vm.bookOrder, "district", $$v)},expression:"bookOrder.district"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bookOrder.district.required)?_c('span',[_vm._v("district is required")]):_vm._e()])],1)]),_c('div',{staticClass:"form-row mt-3 px-3"},[_c('div',{staticClass:"form-group my-2"},[_c('label',[_vm._v("State")]),_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.bookOrder.state.$error,
                'is-valid': !_vm.$v.bookOrder.state.$invalid,
            },attrs:{"type":"text","placeholder":"Enter your state"},model:{value:(_vm.bookOrder.state),callback:function ($$v) {_vm.$set(_vm.bookOrder, "state", $$v)},expression:"bookOrder.state"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bookOrder.state.required)?_c('span',[_vm._v("state is required")]):_vm._e()])],1)]),_c('div',{staticClass:"form-row mt-3 px-3"},[_c('div',{staticClass:"form-group my-2"},[_c('label',[_vm._v("Pincode")]),_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.$v.bookOrder.pincode.$error,
                'is-valid': !_vm.$v.bookOrder.pincode.$invalid,
            },attrs:{"type":"text","placeholder":"Enter pincode"},model:{value:(_vm.bookOrder.pincode),callback:function ($$v) {_vm.$set(_vm.bookOrder, "pincode", $$v)},expression:"bookOrder.pincode"}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.bookOrder.pincode.required)?_c('span',[_vm._v("Pincode is required")]):_vm._e()])],1)]),_c('div',{staticClass:"text-center py-5"},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.disabledButton,"variant":"outline-primary"},on:{"click":function($event){return _vm.saveOrder()}}},[_vm._v(" Close to Book ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }