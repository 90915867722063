import axios from 'axios';
export default{
getNotintrestedCount: function(){
    return new Promise((resolve, reject) => {
        axios.get('adminNotintrested/notintrestedCount')
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

getAllNotintrestedPageable: function(currentPage,pageCount){
    return new Promise((resolve, reject) => {
        axios.get('adminNotintrested/notintrestedAll/'+ (currentPage-1) + "/" + pageCount)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},

getSearchCount: function(keyword){
    return new Promise((resolve, reject) => {
        axios.post('/adminNotintrested/search/count/' + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
getAllSearchPageable: function(currentPage,pageCount,keyword){
    return new Promise((resolve, reject) => {
        axios.post('/adminNotintrested/search/'+ (currentPage-1) + "/" + pageCount  +"/" + keyword)
        .then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
},
}